import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {TaskType} from "../../../redux/Task/Task/types";
import {handleTaskEdit} from "../../Task/Task/TaskActions";
import {ProductType} from "../../../redux/Sale/Product/types";

export const handleCreateNewTaskByProduct = (entity: ProductType, dispatch: any) => {
    let newTask:TaskType = NewTaskItem;

    newTask = {
        ...newTask,
        text: entity?.name && entity.name
    }

    handleTaskEdit(newTask, dispatch);
}