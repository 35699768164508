import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {showRightModalSupportTicket} from "../../../redux/Support/Ticket/actions";
import {NewTicketItem} from "../../../redux/Support/Ticket/reducer";
import {useRedux} from "../../../hooks";
import BreadCrumb from "../../../components/Common/BreadCrumb";


const mainLogo = require('../../../resources/images/mclogo.svg');
const mainPic = require('../../../resources/images/about.png');

const MainAboutIndex = () => {
    const { t } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    const handleNewSupportTicket = () => {
        dispatch(showRightModalSupportTicket({data:NewTicketItem, modalEditTitle: 'newSupportTicket'}));
    }

    return (
        <>
        <BreadCrumb title={t('menu:about')} pageTitle={t('menu:main')} />
        <Row className="justify-content-center">
            <Col className="col-lg-10">
                <Card>
                    <div className="bg-soft-info position-relative">
                        <CardBody className="card-body p-5">
                            <Row className="justify-content-center">
                            <div className="col-lg-6">
                                <img src={mainLogo.default} alt="Marketcells"/>
                            </div>
                            </Row>
                            <div className="text-center">

                            {/*    <h3>Term & Conditions</h3>*/}
                            {/*    <p className="mb-0 text-primary">*/}
                            {/*        Smart marketing platform*/}
                            {/*    </p>*/}
                            </div>
                        </CardBody>
                        <div className="shape">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                // xmlnsSvgjs="http://svgjs.com/svgjs"
                                width="100%"
                                height="60"
                                preserveAspectRatio="none"
                                viewBox="0 0 1440 60"
                            >
                                <g mask='url("#SvgjsMask1001")' fill="none">
                                    <path
                                        d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                        style={{ fill: "var(--vz-card-bg-custom)" }}
                                    ></path>
                                </g>
                                <defs>
                                    <mask id="SvgjsMask1001">
                                        <rect width="1440" height="60" fill="#ffffff"></rect>
                                    </mask>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <CardBody className="card-body p-4">
                        <Row className="justify-content-center">
                            <div className="col-lg-8">
                                <div>
                                    <img src={mainPic} className="mw-100 pb-5" alt="We wish everyone a good game!"/>
                                </div>
                            </div>
                        </Row>


                        <div>
                            <h5 className="mb-4">{t('menu:about')}</h5>

                            <h6 className="mb-4">{t('page:aboutPageHead1')}</h6>
                            <div className="text-muted" style={{whiteSpace: "pre-wrap"}}>{t('page:aboutPageText1')}</div>
                            <h6 className="mb-4">{t('page:aboutPageHead2')}</h6>
                            <div className="text-muted" style={{whiteSpace: "pre-wrap"}}>{t('page:aboutPageText2')}</div>


                            <div className="text-center">
                                <button onClick={handleNewSupportTicket}
                                        type="button"
                                        className="btn btn-primary btn-label waves-effect waves-light">
                                    <i className="ri-user-smile-line label-icon align-middle fs-16 me-2"/> {t('common:joinUs')}
                                </button>
                            </div>
                        </div>

                        <div className="text-end">
                            <small className="text-muted">
                                <i className="ri-code-line pe-2 align-bottom"/> {process.env.REACT_APP_VERSION}
                            </small>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default MainAboutIndex;