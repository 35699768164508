import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    hideRightModalSaleCurrencyRate,
    onSendDataSaleCurrencyRate
} from "../../../redux/Sale/CurrencyRate/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';
import moment from 'moment';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {CurrencyRateType} from "../../../redux/Sale/CurrencyRate/types";
import {SaleCurrencyRateValidatorSchema} from "./validator";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import InputSelect from "../../../components/InputSelect";
import InputDateTimePicker from "../../../components/InputDateTimePicker";
import {isNumeric} from "../../../helpers/functions";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);

    const {
        saleCurrencyRateState,
        isSending,
        saleCurrency
    } = useAppSelector(state => ({
        saleCurrencyRateState: state.SaleCurrencyRate,
        isSending: state.SaleCurrencyRate.isSending,
        saleCurrency: state.SaleCurrency,
    }));

    const uuid = saleCurrencyRateState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<any>(null);

    const setCurrentFormValues = (item: CurrencyRateType)=> {
        let frmValues:any = {...item};

        if (item?.dateTime && item.dateTime !== null){
            frmValues['dateTime']=moment(item.dateTime).utc().unix();
        }

        setFormValues(frmValues);
    }

    useEffect(() => {

        setCurrentFormValues(saleCurrencyRateState.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    useEffect(() => {

        if ((saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleCurrency?.items]);

    const handleClose = () => dispatch(hideRightModalSaleCurrencyRate());

    const onDataSubmit = (formData:any) => {

        let params: CurrencyRateType = {
            uuid: uuid && uuid,
            enabled: formData.enabled ? formData.enabled : false,
            order: formData.order && formData.order,
            baseRate: formData.baseRate && formData.baseRate,
            rate: formData.rate && formData.rate,
            dateTime: formData.dateTime && formData.dateTime
        };

        if (formData['currencyFirst']['value'] !== undefined){
            params={...params,
                currencyFirst:{
                    uuid: formData['currencyFirst']['value']
                }
            }
        } else if (formData['currencyFirst'] !== undefined) {
            params={...params,
                currencyFirst:formData['currencyFirst']
            }
        }

        if (formData['currencySecond']['value'] !== undefined){
            params={...params,
                currencySecond:{
                    uuid: formData['currencySecond']['value']
                }
            }
        } else if (formData['currencySecond'] !== undefined) {
            params={...params,
                currencySecond:formData['currencySecond']
            }
        }

        if (isNumeric(params.dateTime)){
            params = {
                ...params,
                dateTime: moment(params.dateTime,'X').toISOString()
            }
        }



        dispatch(onSendDataSaleCurrencyRate(params));

    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleCurrencyRateState.rightModalShow}
                    id="saleCurrencyRateEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleCurrencyRateValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('currency:'+saleCurrencyRateState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched['dateTime'] ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-activeFrom-input">{t('common:date')}</Label>
                                            <Field
                                                id="form-activeFrom-input"
                                                name="activeFrom"
                                                component={InputDateTimePicker}
                                                value={getFieldProps('dateTime').value}
                                                onChange={(value: number) => {
                                                    setFieldValue('dateTime', value);
                                                }}
                                                className={errors['dateTime'] && touched['dateTime'] && 'form-control is-invalid'}
                                            />
                                            {errors['dateTime'] && touched['dateTime'] ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors['dateTime'])}
                                                </div>
                                            ) :  touched['dateTime'] && !errors['dateTime'] ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.currencyFirst ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-currency-input"}>{t('currency:baseCurrency')}</Label>
                                            <Field
                                                id="choices-currencyFirst-input"
                                                name={"currencyFirst"}
                                                placeholder={t('currency:chooseBaseCurrency')}
                                                component={InputSelect}
                                                value={getFieldProps('currencyFirst').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("currencyFirst",value);
                                                }}
                                                items={currencies || []}
                                                className={"flex-grow-1 "+ (errors.currencyFirst && touched.currencyFirst ? 'form-control is-invalid' : '')}
                                            />

                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.baseRate ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('currency:baseRate')}</Label>
                                            <Field
                                                id="form-baseRate-input"
                                                name="baseRate"
                                                placeholder={t('currency:enterBaseRate')}
                                                component={InputText}
                                                value = {getFieldProps('baseRate').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('baseRate', value);
                                                }}
                                                className={errors.baseRate && touched.baseRate && 'form-control is-invalid'}
                                            />
                                            {errors.baseRate && touched.baseRate ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.baseRate)}
                                                </div>
                                            ) :  touched.baseRate && !errors.baseRate ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.currencySecond ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-currencySecond-input"}>{t('currency:quoteCurrency')}</Label>
                                            <Field
                                                id="choices-currencySecond-input"
                                                name={"currencySecond"}
                                                placeholder={t('currency:chooseQuoteCurrency')}
                                                component={InputSelect}
                                                value={getFieldProps('currencySecond').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("currencySecond",value);
                                                }}
                                                items={currencies || []}
                                                className={"flex-grow-1 "+ (errors.currencySecond && touched.currencySecond ? 'form-control is-invalid' : '')}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.rate ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-rate-input">{t('currency:rate')}</Label>
                                            <Field
                                                id="form-rate-input"
                                                name="rate"
                                                placeholder={t('currency:enterRate')}
                                                component={InputText}
                                                value = {getFieldProps('rate').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('rate', value);
                                                }}
                                                className={errors.rate && touched.rate && 'form-control is-invalid'}
                                            />
                                            {errors.rate && touched.rate ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.rate)}
                                                </div>
                                            ) :  touched.rate && !errors.rate ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>


                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;