import {ContentActivitiesFilterActionTypes, ContentActivitiesFilterState} from "./types";

export const INIT_STATE_CONTENT_ACTIVITIES_FILTER: ContentActivitiesFilterState = {
  projects: [],
  modes: [],
  types: [],
  channels: [],
  isSet: false
};

const ContentActivitiesFilter = (state = INIT_STATE_CONTENT_ACTIVITIES_FILTER, action: any) => {
  switch (action.type) {
    case ContentActivitiesFilterActionTypes.CLEAR_ALL:
          return {
            ...state,
            projects: [],
            modes: [],
            types: [],
            channels: [],
            isSet: false
          };
    case ContentActivitiesFilterActionTypes.SET_MODES:
        if (state.modes.includes(action.payload.uuid)){
          return {
            ...state,
            modes: [...state.modes.filter((item:number)=>{return item !== action.payload.uuid})],
            isSet: state.modes.length > 1
          }
        }
      return {
        ...state,
        modes: [...state.modes, action.payload.uuid],
        isSet: true
      }
    case ContentActivitiesFilterActionTypes.SET_TYPES:
      if (state.types.includes(action.payload.uuid)){
        return {
          ...state,
          types: [...state.types.filter((item:number)=>{return item !== action.payload.uuid})],
          isSet: state.types.length > 1
        }
      }
      return {
        ...state,
        types: [...state.types, action.payload.uuid],
        isSet: true
      }
    case ContentActivitiesFilterActionTypes.SET_PROJECTS:
      if (state.projects.includes(action.payload.uuid)){
        return {
          ...state,
          projects: [...state.projects.filter((item:number)=>{return item !== action.payload.uuid})],
          isSet: state.projects.length > 1
        }
      }
      return {
        ...state,
        projects: [...state.projects, action.payload.uuid],
        isSet: true
      }
    case ContentActivitiesFilterActionTypes.SET_CHANNELS:
      if (state.channels.includes(action.payload.uuid)){
        return {
          ...state,
          channels: [...state.channels.filter((item:number)=>{return item !== action.payload.uuid})],
          isSet: state.channels.length > 1
        }
      }
      return {
        ...state,
        channels: [...state.channels, action.payload.uuid],
        isSet: true
      }
    default:
      return { ...state };
  }
};

export default ContentActivitiesFilter;
