import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row, Form } from 'reactstrap';
import LeftbarTitle from "./LeftbarTitle";
import {useRedux} from "../hooks";
import '../i18/config';
import {useTranslation} from "react-i18next";
import ButtonSpinner from "./ButtonSpinner";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../data/settings";
import {GroupPayloadType, MainMetaType} from "../redux/Main/common";

interface ItemProps {
    items: any[];
    historyHash: HistoryHashType;
    isOpen: boolean;
    isSending: boolean;
    onClick: (data: any, dispatch: any) => void;
    onClose: (dispatch: any) => void;
    modalTitle: string;
    modalText: string;
    modalActionButtonTitle: string;
    modalActionButtonClass?: string;
}

const GroupActionModal = ({ onClick, items, historyHash, isOpen, modalTitle, modalText, modalActionButtonTitle, onClose, isSending = false, modalActionButtonClass='btn-primary' }: ItemProps) => {

    const { t, i18n } = useTranslation();

    const { dispatch } = useRedux();
    const namespaces = ['connector', 'counter', 'comment', 'activity', 'project', 'target', 'peerActivityType', 'group', 'user', 'channel', 'statCounter', 'template', 'quickAnswer', 'support', 'currency', 'price', 'payment', 'delivery', 'store', 'category', 'product', 'integration', 'customer', 'webhook', 'orderStatus', 'sale'];
    const [isModalShow, setModalShow] = useState<boolean>(isOpen);
    const [title, setTitle] = useState<string>(modalTitle);

    useEffect(() => {
        setModalShow(isOpen);
    }, [isOpen]);

    useEffect(() => {
        const namespace = namespaces.find(ns => i18n.exists(modalTitle, { ns })); // Extract Function
        setTitle(namespace ? t(modalTitle, { ns: namespace }) : modalTitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalTitle]);

    const handleClick = () => {
        let mainMeta : MainMetaType = {}
        if (historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true) {
            mainMeta = {
                maxResults: historyHash.maxResults ? historyHash.maxResults : PAGINATION_DEFAULT_ITEMS_PER_PAGE,
                searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length > 0 && historyHash.searchPhrase
            }
        }
        const _uuids = items?.filter(item => item?.uuid).map(item => item.uuid) ?? []; // Use Map/Filter Instead of For Loops
        let groupPayload:GroupPayloadType = {}
        if (_uuids.length>0){
            groupPayload = {
                ...groupPayload,
                uuids: _uuids
            }
        }
        onClick({'items':[groupPayload], 'meta':mainMeta}, dispatch);
    }

    return (
        <>
            <Modal show={isModalShow} onHide={()=>onClose(dispatch)}>
                        <Modal.Header closeButton>
                            <LeftbarTitle title={title}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column align-items-center me-5">
                                        {modalText} {(items || []).length}?
                                    </div>
                                </div>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={()=>onClose(dispatch)}>
                                {t('common:cancel')}
                            </Button>
                            <Button variant="primary"
                                    onClick={()=>handleClick()}
                                    disabled={isSending}
                                    className={"btn waves-effect waves-light "+modalActionButtonClass}
                            >
                                {isSending &&
                                    <ButtonSpinner className={"ms-0 me-2"}/>
                                }
                                {modalActionButtonTitle}
                            </Button>
                        </Modal.Footer>
            </Modal>
        </>
    );
}

export default GroupActionModal;