import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const sendDataSaleOrderCustomer = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_ORDER_CUSTOMER, data);
};

const removeSaleOrderCustomer = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_ORDER_CUSTOMER, data);
};

export {
    sendDataSaleOrderCustomer,
    removeSaleOrderCustomer
};
