import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Label} from "reactstrap";
import {Link, useParams} from 'react-router-dom'
import CoverAuth from "./CoverAuth";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import * as route from "../../../api/routes"
import {useRedux} from "../../../hooks";
import Loader from "../../../components/Loader";
import {socialLogin} from "../../../redux/Main/Login/actions";
import {getParentPartner} from "../../../helpers/functions";
import {LoginSocialType} from "../../../redux/Main/Login/types";

const CoverSocialLogin = () => {
    const { token } = useParams();
    const { t } = useTranslation(['auth']);
    const { dispatch, useAppSelector } = useRedux();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(!(token && token.length>0));

    const {
        loginState,
    } = useAppSelector(state => ({
        loginState: state.Login,
    }));

    useEffect(()=>{
        if (!isError){

            let params : LoginSocialType = {
                token:token
            }

            const parentPartnerMark = getParentPartner();

            if (parentPartnerMark?.partnerCode && parentPartnerMark?.partnerCode.length>0){
                params = {
                    ...params,
                    partnerCode: parentPartnerMark.partnerCode
                }
            }

            dispatch(socialLogin(params));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {

        if (loginState.isUserLogin === true){
            window.location.href = route.MAIN_CABINET_LIST;
        } else if (loginState.isUserDisabled){
            window.location.href = route.MAIN_AUTH_LOGIN;
        }

    }, [dispatch, loginState]);

    return (
        <>
            <CoverAuth title={t('auth:emailConfirmSuccess')+' | Marketcells.io - digital marketing platform'}>
                <Col lg={6}>
                    <CoverLangSwitch/>
                    <div className="p-lg-5 p-4 text-center">
                        <h4>{t('socialLogin')}</h4>

                        { isError ?
                            <>
                                <div className="avatar-lg mx-auto mt-2">
                                    <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                                        <i className="ri-checkbox-circle-fill"/>
                                    </div>
                                </div>
                                <div className="mt-4 pt-2">
                                    <h4>{t('error')}!</h4>
                                    <p className="text-muted mx-4">{t('auth:errorInLogin')}</p>
                                    <div className="mt-4">
                                        <Link to={route.MAIN_AUTH_LOGIN} className="btn btn-success w-100">{t('auth:backToLogin')}</Link>
                                    </div>
                                </div>
                            </>
                            :
                         isLoading ?
                            <>
                                <Loader/>
                                <p className="text-muted mx-4">{t('auth:loginInProgress')}</p>
                                <div className="mt-4">
                                    <Link to={route.MAIN_AUTH_LOGIN} className="btn btn-success w-100">{t('auth:backToLogin')}</Link>
                                </div>
                            </>
                            :
                            <>
                                <div className="avatar-lg mx-auto mt-2">
                                    <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                        <i className="ri-checkbox-circle-fill"/>
                                    </div>
                                </div>
                                <div className="mt-4 pt-2">
                                    <h4>{t('wellDone')}!</h4>
                                    <p className="text-muted mx-4">{t('auth:emailConfirmSuccessMessage1')}</p>
                                    <div className="mt-4">
                                    <Link to={route.MAIN_AUTH_LOGIN} className="btn btn-success w-100">{t('auth:backToLogin')}</Link>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Col>
            </CoverAuth>
        </>
    );
};

export default CoverSocialLogin;