import React, { useEffect, useState } from "react";
import { BrowserRouter } from 'react-router-dom'
import { Row,Col } from 'reactstrap';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useRedux } from "../../../hooks";

import {
    getContentProjects,
} from "../../../redux/actions";

import {ContentProjectType} from "../../../redux/Content/Project/types";
import ListItem from "./ListItem";
import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    ContentProjectHideRemoveModal,
    ContentProjectOnRemove,
    ContentProjectOpenModal,
    ContentProjectOpenRemoveModal
} from "./ContentProjectActions";
import RemoveModal from "../../../components/RemoveForm";
import BreadCrumb from "../../../components/Common/BreadCrumb";

const ProjectIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const {
        contentProjects,
    } = useAppSelector(state => ({
        contentProjects: state.ContentProjects,
    }));

    useEffect(() => {
        dispatch(getContentProjects());
    }, [dispatch]);

    return (
        <>
            <BreadCrumb title={t('menu:projects')} pageTitle={t('menu:main')} />
                <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary" onClick={(event)=>ContentProjectOpenModal(event, dispatch)} className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>
                <div className="row">
            {(contentProjects.items || []).map((item: ContentProjectType, key: number) => (
                        <Col xxl={3} xl={4} sm={6} className="project-card" key={'contentProject_' + item.uuid}>
                            <ListItem
                                item={item}
                                onEdit={(event) => ContentProjectOpenModal(event, dispatch)}
                                onRemove={(event) => ContentProjectOpenRemoveModal(event, dispatch)}
                            />
                        </Col>
            ))}
                </div>
                <RightModal/>
                <RemoveModal
                    onRemove={(event)=>ContentProjectOnRemove(event, dispatch)}
                    handleClose={(event)=>ContentProjectHideRemoveModal(dispatch)}
                    removeItem={contentProjects.selectedItem}
                    isOpen={contentProjects.removeModalShow}
                    modalTitle={contentProjects.modalEditTitle}/>

        </>
    );
};

export default ProjectIndex;

