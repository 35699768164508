import React from 'react';
import * as Yup from 'yup';

export const ContentModalTwoStepValidatorSchema = Yup.object().shape({
    codeInput0: Yup.string()
        .required('required'),
    codeInput1: Yup.string()
        .required('required'),
    codeInput2: Yup.string()
        .required('required'),
    codeInput3: Yup.string()
        .required('required'),
    codeInput4: Yup.string()
        .required('required'),
});