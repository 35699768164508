import React from 'react';
import * as Yup from 'yup';

export const ContentNewStatCounterValidatorSchema = Yup.object().shape({
    name: Yup.object()
        .required('required'),
    project: Yup.object()
        .required('required'),
});

export const ContentEditStatCounterValidatorSchema = Yup.object().shape({
    project: Yup.object()
        .required('required'),
});