import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import {
    hideRightModalSaleOrderPayment,
    onSendDataSaleOrderPayment
} from "../../../redux/Sale/Payment/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {PaymentSystemType} from "../../../redux/Sale/PaymentSystem/types";
import InputSelect from "../../../components/InputSelect";
import {PaymentStatus, PaymentType} from "../../../redux/Sale/Payment/types";
import InputEmojiArea from "../../../components/InputEmojiArea";
import InputDateTimePicker from "../../../components/InputDateTimePicker";
import {SaleOrderPaymentValidatorSchema} from "./paymentValidator";
import moment from "moment/moment";
import {isNumeric} from "../../../helpers/functions";
import {CURRENCY_SYMBOL_EXTRACT_REGEX} from "../../../data/settings";
import InputTextAddon from "../../../components/InputTextAddon";
interface ItemProps {
    paymentSystems: PaymentSystemType[];
    currencies: CurrencyType[];
}

const ModalPaymentForm = ({paymentSystems, currencies}:ItemProps) => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [paymentStatusOptions, setPaymentStatusOptions] = useState<any>([]);

    const {
        saleOrderPaymentState,
        isSending
    } = useAppSelector(state => ({
        saleOrderPaymentState: state.SaleOrderPayment,
        isSending: state.SaleOrderPayment.isSending
    }));

    const uuid = saleOrderPaymentState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<PaymentType>(saleOrderPaymentState.selectedItem);

    useEffect(() => {

        if (saleOrderPaymentState?.rightModalShow && saleOrderPaymentState.rightModalShow) {
            setCurrentFormValues(saleOrderPaymentState.selectedItem);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, saleOrderPaymentState?.rightModalShow]);

    const setCurrentFormValues = (item: PaymentType) => {
        let _formValues : any = item;

        if (item?.status && item.status.length>0){
            let statusKey = Object.keys(PaymentStatus).filter((statusItemKey:string)=>{
                return PaymentStatus[statusItemKey].status === item.status;
            }).shift();

            if (statusKey !== undefined && statusKey !== null){
                _formValues = {
                    ..._formValues,
                    status: {
                        uuid: PaymentStatus[statusKey].status,
                        value: t('payment:paymentStatus'+PaymentStatus[statusKey].label)
                    }
                }
            }
        }
        if (item?.paymentDate !== undefined && item.paymentDate !== null){
            _formValues = {
                ..._formValues,
                paymentDate: moment(item.paymentDate).unix()
            }
        }

        setFormValues(_formValues);
    }

    useEffect(() => {

        let _paymentStatusOptions=[];

        Object.keys(PaymentStatus).map((item:string)=>{
            _paymentStatusOptions.push({uuid: PaymentStatus[item].status, value: t('payment:paymentStatus'+PaymentStatus[item].label)});
            return item;
        })

        setPaymentStatusOptions([..._paymentStatusOptions]);

    },[dispatch,t]);

    const handleClose = () => dispatch(hideRightModalSaleOrderPayment());

    const onDataSubmit = (formData:PaymentType) => {

        let params: PaymentType = {
            uuid: (uuid && uuid !== null) && uuid,
            amount: formData.amount ? formData.amount : 0,
            comment: formData.comment && formData.comment,
            idPayment: formData.idPayment && formData.idPayment,
            paymentDate: formData.paymentDate && isNumeric(formData.paymentDate) && moment(formData.paymentDate,'X').utc().toString(),
        };

        if (uuid === null && saleOrderPaymentState?.selectedItem?.saleOrder?.uuid){
            params={...params,
                saleOrder:{
                    uuid: saleOrderPaymentState.selectedItem.saleOrder.uuid
                }
            }
        }

        // if (formData['currency']['value'] !== undefined){
        //     params={...params,
        //         currency:{
        //             uuid: formData['currency']['value']
        //         }
        //     }
        // } else if (formData['currency'] !== undefined) {
        //     params={...params,
        //         currency:formData['currency']
        //     }
        // }

        if (formData['paymentSystem']['value'] !== undefined){
            params={...params,
                paymentSystem:{
                    uuid: formData['paymentSystem']['value']
                }
            }
        } else if (formData['paymentSystem'] !== undefined) {
            params={...params,
                paymentSystem:formData['paymentSystem']
            }
        }

        if (formData['status']['uuid'] !== undefined) {
            params={...params,
                status:formData['status']['uuid']
            }
        } else if (formData['status']['value'] !== undefined){
            params={...params,
                status:formData['status']['value']
            }
        } else if (formData['status'] !== undefined) {
            params={...params,
                status:formData['status']
            }
        }

        dispatch(onSendDataSaleOrderPayment(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleOrderPaymentState.rightModalShow}
                    id="saleOrderPaymentEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleOrderPaymentValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('payment:'+saleOrderPaymentState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                                    <Row>
                                        <div className={ "mb-3"+ (touched.currency ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-status-input"}>{t('common:status')}</Label>
                                            <Field
                                                id="choices-status-input"
                                                name={"status"}
                                                placeholder={t('common:chooseStatus')}
                                                component={InputSelect}
                                                value={getFieldProps('status').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("status",value);
                                                }}
                                                items={paymentStatusOptions || []}
                                                className={"flex-grow-1 "+ (errors.status && touched.status ? 'form-control is-invalid' : '')}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.paymentSystem ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-paymentSystem-input"}>{t('payment:paymentSystem')}</Label>
                                            <Field
                                                id="choices-paymentSystem-input"
                                                name={"paymentSystem"}
                                                placeholder={t('payment:choosePaymentSystem')}
                                                component={InputSelect}
                                                value={getFieldProps('paymentSystem').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("paymentSystem",value);
                                                }}
                                                items={paymentSystems || []}
                                                className={"flex-grow-1 "+ (errors.paymentSystem && touched.paymentSystem ? 'form-control is-invalid' : '')}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.amount ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('sale:amount')}</Label>
                                            <Field
                                                id="form-amount-input"
                                                name="amount"
                                                placeholder={t('sale:enterAmount')}
                                                component={InputTextAddon}
                                                value = {getFieldProps('amount').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('amount', value);
                                                }}
                                                addon={getFieldProps('currency')?.value?.currencyFormat?.replace(CURRENCY_SYMBOL_EXTRACT_REGEX,'')}
                                                className={errors.amount && touched.amount && 'form-control is-invalid'}
                                            />
                                            {errors.amount && touched.amount ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.amount)}
                                                </div>
                                            ) :  touched.amount && !errors.amount ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    {/*<Row>*/}
                                    {/*    <div className={ "mb-3"+ (touched.currency ? " was-validated" : '')}>*/}
                                    {/*        <Label className="form-label" htmlFor={"choices-currency-input"}>{t('currency:currency')}</Label>*/}
                                    {/*        <Field*/}
                                    {/*            id="choices-currency-input"*/}
                                    {/*            name={"currency"}*/}
                                    {/*            placeholder={t('currency:chooseCurrency')}*/}
                                    {/*            component={InputSelect}*/}
                                    {/*            value={getFieldProps('currency').value}*/}
                                    {/*            onChange={(value: any) => {*/}
                                    {/*                setFieldValue("currency",value);*/}
                                    {/*            }}*/}
                                    {/*            items={currencies || []}*/}
                                    {/*            className={"flex-grow-1 "+ (errors.currency && touched.currency ? 'form-control is-invalid' : '')}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</Row>*/}
                                    <Row>
                                        <div className={ "mb-3"+ (touched.idPayment ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-idPayment-input">{t('payment:idPayment')}</Label>
                                            <Field
                                                id="form-idPayment-input"
                                                name="idPayment"
                                                placeholder={t('payment:enterIdPayment')}
                                                component={InputText}
                                                value = {getFieldProps('idPayment').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('idPayment', value);
                                                }}
                                                className={errors.idPayment && touched.idPayment && 'form-control is-invalid'}
                                            />
                                            {errors.idPayment && touched.idPayment ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.idPayment)}
                                                </div>
                                            ) :  touched.idPayment && !errors.idPayment ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.paymentDate ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-paymentDate-input">{t('payment:paymentDate')}</Label>
                                            <Field
                                                id="form-paymentDate-input"
                                                name="paymentDate"
                                                component={InputDateTimePicker}
                                                value={getFieldProps('paymentDate').value}
                                                onChange={(value: number) => {
                                                    setFieldValue('paymentDate', value);
                                                }}
                                                placeholder={t('payment:choosePaymentDate')}
                                                className={errors.paymentDate && touched.paymentDate && 'form-control is-invalid'}
                                            />
                                            {errors.paymentDate && touched.paymentDate ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.paymentDate)}
                                                </div>
                                            ) :  touched.paymentDate && !errors.paymentDate ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-comment-input">{t('common:comment')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-comment-input"
                                                    name="comment"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('comment').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('comment', text);
                                                    }}
                                                    required={false}
                                                    placeholder={t('common:enterComment')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default ModalPaymentForm;