import {EventUserBadgeActionTypes, EventUserBadgeType} from "./types";

export const eventUserBadgeApiResponseSuccess = (actionType: string, data: any) => ({
  type: EventUserBadgeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const eventUserBadgeApiResponseError = (actionType: string, error: string) => ({
  type: EventUserBadgeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getEventUserBadges = () => ({
  type: EventUserBadgeActionTypes.GET_USER_BADGES,
});
export const getSupportUserBadges = () => ({
  type: EventUserBadgeActionTypes.GET_SUPPORT_USER_BADGES,
});
export const getSupportServiceBadges = () => ({
  type: EventUserBadgeActionTypes.GET_SUPPORT_SERVICE_BADGES,
});