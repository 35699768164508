import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getStatTasksTiles = (data:any) => {

    const sendData = {
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid,
        memberType: data.memberType && data.memberType
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_TASK_TILES_LIST, sendData);
};

export {
    getStatTasksTiles
};
