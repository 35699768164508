import {CommentActionTypes, CommentPayload} from "./types";
import {RewardType} from "../../../data/reward";
import {MainMetaType} from "../../Main/common";

export const commentApiResponseSuccess = (actionType: string, data: any) => ({
  type: CommentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const commentApiResponseError = (actionType: string, error: string) => ({
  type: CommentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const onSendDataComment = (data: any) => ({
  type: CommentActionTypes.UPDATE_COMMENT,
  payload: data,
});

