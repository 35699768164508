import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const sendDataSaleOrderDeliveryItem = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_ORDER_DELIVERY_ITEM, data);
};

const removeSaleOrderDeliveryItem = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_ORDER_DELIVERY_ITEM, data);
};

export {
    sendDataSaleOrderDeliveryItem,
    removeSaleOrderDeliveryItem
};
