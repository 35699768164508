import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentPeerTargetActionTypes } from "./types";
import { contentPeerTargetApiResponseSuccess, contentPeerTargetApiResponseError } from "./actions";

import {
  getContentPeerTargets as getContentPeerTargetsApi,
  sendDataContentPeerTarget,
  removeContentPeerTarget
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getContentPeerTargets() {
  try {
    const response: Promise<any> = yield call(getContentPeerTargetsApi);
    yield put(contentPeerTargetApiResponseSuccess(ContentPeerTargetActionTypes.GET_CONTENT_PEER_TARGETS, response));
  } catch (error: any) {
    yield put(contentPeerTargetApiResponseError(ContentPeerTargetActionTypes.GET_CONTENT_PEER_TARGETS, error));
  }
}

function* onSendDataContentPeerTarget({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentPeerTarget, data);
    yield put(
        contentPeerTargetApiResponseSuccess(ContentPeerTargetActionTypes.UPDATE_CONTENT_PEER_TARGET, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentPeerTargetApiResponseError(ContentPeerTargetActionTypes.UPDATE_CONTENT_PEER_TARGET, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveContentPeerTarget({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeContentPeerTarget, {uuid: data.data.uuid});
    yield put(
        contentPeerTargetApiResponseSuccess(ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentPeerTargetApiResponseError(ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetContentPeerTargets() {
  yield takeEvery(ContentPeerTargetActionTypes.GET_CONTENT_PEER_TARGETS, getContentPeerTargets);
}
export function* watchOnSendDataContentPeerTarget() {
  yield takeEvery(ContentPeerTargetActionTypes.UPDATE_CONTENT_PEER_TARGET, onSendDataContentPeerTarget);
}
export function* watchOnRemoveContentPeerTarget() {
  yield takeEvery(ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET, onRemoveContentPeerTarget);
}

function* contentPeerTargetSaga() {
  yield all([
    fork(watchGetContentPeerTargets),
    fork(watchOnSendDataContentPeerTarget),
    fork(watchOnRemoveContentPeerTarget)
  ]);
}

export default contentPeerTargetSaga;
