import {API_DOMAIN, config} from "../config";
//auth
export const API_PREFIX = '/api';
export const API_VERSION_PREFIX = '/v1';

export const GET_SEARCH_LIST = API_PREFIX+API_VERSION_PREFIX+"/main/search/list";
export const GET_USER_CABINETS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/cabinet/list";
export const UPDATE_USER_CABINET_SETTINGS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/cabinet/settings/update";
export const CREATE_USER_CABINET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/cabinet/create";
export const UPDATE_USER_CABINET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/cabinet/update";
export const CABINET_SET_LASTLOGIN = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/cabinet/lastlogin/update";
export const RESUME_USER_CABINET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/cabinet/resume";

export const GET_EVENT_NOTIFICATIONS = API_PREFIX+API_VERSION_PREFIX+"/event/eventmessage/list";
export const READ_EVENT_NOTIFICATIONS = API_PREFIX+API_VERSION_PREFIX+"/event/eventmessage/read";

export const GET_CURRENT_PLAN = API_PREFIX+API_VERSION_PREFIX+"/sale/plan/current";
export const GET_PLANS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/product/list";
export const GET_PLAN_ORDERS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/order/list";
export const GET_PLAN_ORDER_DETAIL = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/order/detail";
export const CANCEL_PLAN_ORDER = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/order/cancel";
export const CREATE_PLAN_ORDER = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/order/create";
export const GET_PAYMENT_SYSTEMS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/payment/system/list";
export const ACTIVATE_LICENSE_KEY = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/license/activate";
export const M9S_COUPON_CHECK = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/m9s-sales/coupon/check";
export const GET_JWT_KEY_AFTER_LOGIN = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/jwt/get";
export const JWT_CHECK = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/jwt/check";
export const POST_DEVICE_TOKEN = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/device/check";
export const SOCIAL_CHECK = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/social/check";
export const POST_REFRESH_JWT_TOKEN = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/jwt/refresh";
export const AUTH_LOGOUT = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/logout";
export const MAIN_AUTH_SIGNUP_CREATE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/signup/create";
export const MAIN_AUTH_SIGNUP_CONFIRM_EMAIL = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/signup/confirm/email";
export const MAIN_AUTH_PASSWORD_RESET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/reset";
export const MAIN_AUTH_CHANGE_PASSWORD = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/change-pwd";
export const MAIN_AUTH_CHANGE_EMAIL = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/change-email";
export const GET_MAIN_ROLES = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/role/list";
export const GET_MAIN_USERS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/user/list";
export let GET_MAIN_CURRENT_USER = API_PREFIX+API_VERSION_PREFIX+"/main/user/current";
export let GET_MAIN_CURRENT_USER_PROFILE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/user/current/profile";
export const UPDATE_MAIN_USER = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/user/update";
export const UPDATE_MAIN_USER_AVATAR = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/user/update/avatar";
export const REMOVE_MAIN_USER  = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/user/remove";
export const MAIN_USER_SET_LANGUAGE  = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/user/language/update";
export const GET_MAIN_VERSION_HISTORY_LIST = API_PREFIX+API_VERSION_PREFIX+"/main/version/list";

export const GET_USER_EVENT_SUBSCRIPTIONS = API_PREFIX+API_VERSION_PREFIX+"/event/usersubscription/list";
export const UPDATE_USER_EVENT_SUBSCRIPTION = API_PREFIX+API_VERSION_PREFIX+"/event/usersubscription/update";

export const GET_STAT_CHANNEL_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/channels_tiles";
export const GET_STAT_CHANNEL_ACTIVITIES = API_PREFIX+API_VERSION_PREFIX+"/stat/channel/activities";

export const GET_STAT_PEER_TARGETS = API_PREFIX+API_VERSION_PREFIX+"/stat/peertarget/list";
export const GET_STAT_TOP_PEER_USERS = API_PREFIX+API_VERSION_PREFIX+"/stat/peeruser/top";
export const GET_STAT_COUNTER_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/counters_tiles";
export const GET_STAT_COUNTERS_DATA = API_PREFIX+API_VERSION_PREFIX+"/stat/counters/data";

export const GET_STAT_ACTIVITY_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/activities/tiles";
export const GET_STAT_ACTIVITY_ACTIVITIES_DATA = API_PREFIX+API_VERSION_PREFIX+"/stat/activities/activities";
export const GET_STAT_ACTIVITY_ENGAGEMENTS_DATA = API_PREFIX+API_VERSION_PREFIX+"/stat/activities/engagements";

export const GET_CONTENT_PROJECTS = API_PREFIX+API_VERSION_PREFIX+"/content/project/list";
export const UPDATE_CONTENT_PROJECTS = API_PREFIX+API_VERSION_PREFIX+"/content/project/update";
export const REMOVE_CONTENT_PROJECTS = API_PREFIX+API_VERSION_PREFIX+"/content/project/remove";

export const GET_CONTENT_TEMPLATES = API_PREFIX+API_VERSION_PREFIX+"/content/template/list";
export const UPDATE_CONTENT_TEMPLATES = API_PREFIX+API_VERSION_PREFIX+"/content/template/update";
export const REMOVE_CONTENT_TEMPLATES = API_PREFIX+API_VERSION_PREFIX+"/content/template/remove";

export const GET_CONTENT_QUICK_ANSWER = API_PREFIX+API_VERSION_PREFIX+"/content/quick_answer/list";
export const UPDATE_CONTENT_QUICK_ANSWER = API_PREFIX+API_VERSION_PREFIX+"/content/quick_answer/update";
export const REMOVE_CONTENT_QUICK_ANSWER = API_PREFIX+API_VERSION_PREFIX+"/content/quick_answer/remove";

export const GET_CONTENT_PEER_ACTIVITY_TYPES = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivitytype/list";
export const UPDATE_CONTENT_DATAITEMTYPE = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivitytype/update";
export const REMOVE_CONTENT_DATAITEMTYPE  = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivitytype/remove";

export const GET_CONTENT_PEER_ACTIVITY_MODES = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivitymode/list";

export const GET_CONTENT_PEER_TARGET_TYPES = API_PREFIX+API_VERSION_PREFIX+"/content/peertargettype/list";
export const GET_CONTENT_CONNECTOR_HANDLERS = API_PREFIX+API_VERSION_PREFIX+"/content/connectorhandler/list";

export const GET_CONTENT_CONNECTORS = API_PREFIX+API_VERSION_PREFIX+"/content/connector/list";
export const UPDATE_CONTENT_CONNECTOR = API_PREFIX+API_VERSION_PREFIX+"/content/connector/update";
export const REMOVE_CONTENT_CONNECTOR  = API_PREFIX+API_VERSION_PREFIX+"/content/connector/remove";
export const CONNECT_CONTENT_CONNECTOR  = API_PREFIX+API_VERSION_PREFIX+"/content/connector/connect";
export const IS_CONNECTED_CONTENT_CONNECTOR  = API_PREFIX+API_VERSION_PREFIX+"/content/connector/isconnected";
export const SEARCH_CHANNEL_CONTENT_CONNECTOR = API_PREFIX+API_VERSION_PREFIX+"/content/connector/channel/search";
export const GET_MANAGED_CHANNELS_CONTENT_CONNECTOR = API_PREFIX+API_VERSION_PREFIX+"/content/connector/channel/managed/get";
export const GET_CONTENT_CONNECTORS_BY_TYPE = API_PREFIX+API_VERSION_PREFIX+"/content/connector/type/:handlerType/list";

export const GET_CONTENT_CONNECTOR_DATA_PARAMS  = API_PREFIX+API_VERSION_PREFIX+"/content/connectordataparam/list";

export const GET_CONTENT_PEER_CHANNELS = API_PREFIX+API_VERSION_PREFIX+"/content/peerchannel/list";
export const UPDATE_CONTENT_PEER_CHANNEL = API_PREFIX+API_VERSION_PREFIX+"/content/peerchannel/update";
export const REMOVE_CONTENT_PEER_CHANNEL  = API_PREFIX+API_VERSION_PREFIX+"/content/peerchannel/remove";
export const CONNECT_CONTENT_PEER_CHANNEL  = API_PREFIX+API_VERSION_PREFIX+"/content/peerchannel/connect";
export const GET_CONTENT_STAT_COUNTERS = API_PREFIX+API_VERSION_PREFIX+"/content/statcounter/list";
export const UPDATE_CONTENT_STAT_COUNTER = API_PREFIX+API_VERSION_PREFIX+"/content/statcounter/update";
export const REMOVE_CONTENT_STAT_COUNTER  = API_PREFIX+API_VERSION_PREFIX+"/content/statcounter/remove";

export const GET_CONTENT_PEER_USERS  = API_PREFIX+API_VERSION_PREFIX+"/content/peeruser/list";
export const UPDATE_CONTENT_PEER_USERS  = API_PREFIX+API_VERSION_PREFIX+"/content/peeruser/update";

export const GET_CONTENT_PEER_ACTIVITIES = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivity/list";
export const UPDATE_CONTENT_PEER_ACTIVITY = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivity/update";
export const PUBLISH_CONTENT_PEER_ACTIVITY = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivity/publish";
export const PIN_CONTENT_PEER_ACTIVITY = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivity/pin";
export const COPY_CONTENT_PEER_ACTIVITY = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivity/copy/:uuid";
export const REMOVE_CONTENT_PEER_ACTIVITY = API_PREFIX+API_VERSION_PREFIX+"/content/peeractivity/remove/:uuid";
export const GET_CONTENT_PEER_TARGETS = API_PREFIX+API_VERSION_PREFIX+"/content/peertarget/list";
export const UPDATE_CONTENT_PEER_TARGET = API_PREFIX+API_VERSION_PREFIX+"/content/peertarget/update";
export const REMOVE_CONTENT_PEER_TARGET  = API_PREFIX+API_VERSION_PREFIX+"/content/peertarget/remove";

export const AI_TEXT_REQUEST  = API_PREFIX+API_VERSION_PREFIX+"/ai/text/request";

//webSocket
export const WEB_SOCKET = API_DOMAIN+'/wsock';

// contacts
export const GET_CONTACTS = API_PREFIX+API_VERSION_PREFIX+"/messenger/contact/user/list";
export const INVITE_CONTACT = API_PREFIX+API_VERSION_PREFIX+"/messenger/contact/invite";

// chats
export const GET_FAVOURITES = API_PREFIX+API_VERSION_PREFIX+"/messenger/channel/favourites/list";
//export const GET_DIRECT_MESSAGES = API_PREFIX+API_VERSION_PREFIX+"/messenger/get-direct-messages";
export const GET_CHANNELS = API_PREFIX+API_VERSION_PREFIX+"/messenger/channel/list";
//export const ADD_CONTACTS = API_PREFIX+API_VERSION_PREFIX+"/messenger/add-contact";
//export const CREATE_CHANNEL = API_PREFIX+API_VERSION_PREFIX+"/messenger/channel/create";
export const GET_CHAT_USER_DETAILS = API_PREFIX+API_VERSION_PREFIX+"/messenger/user/details/get";
export const GET_CHAT_USER_CONVERSATIONS = API_PREFIX+API_VERSION_PREFIX+"/messenger/conversation/list";
export const SEND_MESSAGE = API_PREFIX+API_VERSION_PREFIX+"/messenger/message/send";
export const RECEIVE_MESSAGE = API_PREFIX+API_VERSION_PREFIX+"/messenger/message/receive";
export const GET_MESSAGE = API_PREFIX+API_VERSION_PREFIX+"/messenger/message/get";
//export const RECEIVE_MESSAGE_FROM_USER = API_PREFIX+API_VERSION_PREFIX+"/messenger/receive-message-from-user";
export const DELETE_MESSAGE = API_PREFIX+API_VERSION_PREFIX+"/messenger/message/delete";
export const FORWARD_MESSAGE = API_PREFIX+API_VERSION_PREFIX+"/messenger/message/forward";
//export const DELETE_USER_MESSAGES = API_PREFIX+API_VERSION_PREFIX+"/messenger/delete-user-messages";
export const TOGGLE_FAVOURITE_CONTACT = API_PREFIX+API_VERSION_PREFIX+"/messenger/contact/favourite/toggle";
export const GET_ARCHIVE_CONTACT = API_PREFIX+API_VERSION_PREFIX+"/messenger/contact/archive/list";
export const TOGGLE_ARCHIVE_CONTACT = API_PREFIX+API_VERSION_PREFIX+"/messenger/contact/archive/toggle";
export const READ_CONVERSATION = API_PREFIX+API_VERSION_PREFIX+"/messenger/conversation/read";
export const DELETE_IMAGE = API_PREFIX+API_VERSION_PREFIX+"/messenger/message/file/delete";

//comments
export const GET_COMMENTS_CHANNELS = API_PREFIX+API_VERSION_PREFIX+"/messenger/comment/channel/list";
export const GET_ARCHIVE_COMMENTS_CONTACT = API_PREFIX+API_VERSION_PREFIX+"/messenger/comment/contact/archive/list";
export const GET_COMMENTS_CHANNEL_DETAILS = API_PREFIX+API_VERSION_PREFIX+"/messenger/channel/details/get";

// groups
export const GET_CHANNEL_DETAILS = API_PREFIX+API_VERSION_PREFIX+"/messenger/channel/details/get";

export const POST_FAKE_JWT_LOGIN = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/login_check";

export const MAIN_AUTH_SIGNUP_CHECK_EMAIL = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/signup/checkEmail";

export const GET_BADGES_SUMMARY = API_PREFIX+API_VERSION_PREFIX+"/event/badge/summary";

export const GET_SUPPORT_TICKETS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/list";
export const GET_SUPPORT_TICKET_DETAIL = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/detail/:uuid";
export const REMOVE_SUPPORT_TICKET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/remove";
export const UPDATE_SUPPORT_TICKET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/update";
export const GET_SUPPORT_TICKET_MESSAGES = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/:uuid/message/list";
export const REMOVE_SUPPORT_TICKET_TICKET_MESSAGE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/message/remove";
export const UPDATE_SUPPORT_TICKET_MESSAGE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/message/update";
export const SEND_REWARD_SUPPORT_TICKET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/reward/update";

export const SEND_REWARD_SUPPORT_TICKET_MESSAGE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/message/reward/update";
export const GET_SUPPORT_SERVICE_TICKETS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/list";
export const GET_SUPPORT_SERVICE_TICKET_DETAIL = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/detail/:uuid";
export const REMOVE_SUPPORT_SERVICE_TICKET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/remove";
export const UPDATE_SUPPORT_SERVICE_TICKET = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/update";
export const GET_SUPPORT_SERVICE_TICKET_MESSAGES = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/:uuid/message/list";
export const REMOVE_SUPPORT_SERVICE_TICKET_TICKET_MESSAGE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/message/remove";
export const UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/message/update";

export const GET_SUPPORT_BADGES_SUMMARY = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/badge/summary";
export const GET_SUPPORT_SERVICE_BADGES_SUMMARY = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/badge/summary";

export const READ_SUPPORT_TICKET_MESSAGES = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/ticket/:uuid/message/read";
export const READ_SUPPORT_SERVICE_TICKET_MESSAGES = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/support/service/ticket/:uuid/message/read";

export const GET_MAIN_CONTACTS = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/contact/list";

export const GET_SALE_STORES = API_PREFIX+API_VERSION_PREFIX+"/sale/store/list";
export const UPDATE_SALE_STORE = API_PREFIX+API_VERSION_PREFIX+"/sale/store/update";
export const REMOVE_SALE_STORE  = API_PREFIX+API_VERSION_PREFIX+"/sale/store/remove";

export const GET_SALE_CURRENCIES = API_PREFIX+API_VERSION_PREFIX+"/sale/currency/list";
export const UPDATE_SALE_CURRENCY = API_PREFIX+API_VERSION_PREFIX+"/sale/currency/update";
export const REMOVE_SALE_CURRENCY = API_PREFIX+API_VERSION_PREFIX+"/sale/currency/remove";

export const GET_SALE_CURRENCY_RATES = API_PREFIX+API_VERSION_PREFIX+"/sale/currencyrate/list";
export const UPDATE_SALE_CURRENCY_RATE = API_PREFIX+API_VERSION_PREFIX+"/sale/currencyrate/update";
export const REMOVE_SALE_CURRENCY_RATE = API_PREFIX+API_VERSION_PREFIX+"/sale/currencyrate/remove";

export const GET_SALE_PRICE_TYPES = API_PREFIX+API_VERSION_PREFIX+"/sale/pricetype/list";
export const UPDATE_SALE_PRICE_TYPE = API_PREFIX+API_VERSION_PREFIX+"/sale/pricetype/update";
export const REMOVE_SALE_PRICE_TYPE = API_PREFIX+API_VERSION_PREFIX+"/sale/pricetype/remove";

export const GET_SALE_PAYMENT_SYSTEMS = API_PREFIX+API_VERSION_PREFIX+"/sale/paymentsystem/list";
export const UPDATE_SALE_PAYMENT_SYSTEM = API_PREFIX+API_VERSION_PREFIX+"/sale/paymentsystem/update";
export const REMOVE_SALE_PAYMENT_SYSTEM = API_PREFIX+API_VERSION_PREFIX+"/sale/paymentsystem/remove";

export const GET_SALE_DELIVERY_SYSTEMS = API_PREFIX+API_VERSION_PREFIX+"/sale/deliverysystem/list";
export const UPDATE_SALE_DELIVERY_SYSTEM = API_PREFIX+API_VERSION_PREFIX+"/sale/deliverysystem/update";
export const REMOVE_SALE_DELIVERY_SYSTEM = API_PREFIX+API_VERSION_PREFIX+"/sale/deliverysystem/remove";

export const GET_SALE_CATEGORIES = API_PREFIX+API_VERSION_PREFIX+"/sale/category/list";
export const UPDATE_SALE_CATEGORY = API_PREFIX+API_VERSION_PREFIX+"/sale/category/update";
export const REMOVE_SALE_CATEGORY = API_PREFIX+API_VERSION_PREFIX+"/sale/category/remove";

export const GET_SALE_PRODUCT_PARAMS = API_PREFIX+API_VERSION_PREFIX+"/sale/productparam/list";
export const UPDATE_SALE_PRODUCT_PARAM = API_PREFIX+API_VERSION_PREFIX+"/sale/productparam/update";
export const REMOVE_SALE_PRODUCT_PARAM = API_PREFIX+API_VERSION_PREFIX+"/sale/productparam/remove";

export const GET_SALE_PRODUCTS = API_PREFIX+API_VERSION_PREFIX+"/sale/product/list";
export const UPDATE_SALE_PRODUCT = API_PREFIX+API_VERSION_PREFIX+"/sale/product/update";
export const REMOVE_SALE_PRODUCT = API_PREFIX+API_VERSION_PREFIX+"/sale/product/remove";

export const COPY_SALE_PRODUCT = API_PREFIX+API_VERSION_PREFIX+"/sale/product/copy/:uuid";

export const UPDATE_SALE_PRODUCT_INTEGRATION = API_PREFIX+API_VERSION_PREFIX+"/sale/product/integration/update";
export const UPDATE_SALE_PRODUCT_GROUP_INTEGRATION = API_PREFIX+API_VERSION_PREFIX+"/sale/product/integration/group/update";
export const UPDATE_SALE_PRODUCT_PROPERTIES = API_PREFIX+API_VERSION_PREFIX+"/sale/product/properties/update";
export const SET_SALE_PRODUCT_GROUP = API_PREFIX+API_VERSION_PREFIX+"/sale/product/group/set";
export const UNSET_SALE_PRODUCT_GROUP = API_PREFIX+API_VERSION_PREFIX+"/sale/product/group/unset";
export const REMOVE_SALE_PRODUCT_GROUP = API_PREFIX+API_VERSION_PREFIX+"/sale/product/group/remove";
export const GET_REMOTE_SALE_PRODUCT_INTEGRATION_PARAM = API_PREFIX+API_VERSION_PREFIX+"/sale/product/integration/remoteparams";

export const GET_SALE_INTEGRATIONS = API_PREFIX+API_VERSION_PREFIX+"/sale/integration/list";
export const UPDATE_SALE_INTEGRATION = API_PREFIX+API_VERSION_PREFIX+"/sale/integration/update";
export const REQUEST_SALE_INTEGRATION_IMPORT = API_PREFIX+API_VERSION_PREFIX+"/sale/integration/import";
export const REMOVE_SALE_INTEGRATION = API_PREFIX+API_VERSION_PREFIX+"/sale/integration/remove";
export const INVALIDATE_CACHE_SALE_INTEGRATION = API_PREFIX+API_VERSION_PREFIX+"/sale/integration/cache/invalidate";

export const GET_SALE_INTEGRATION_HANDLERS = API_PREFIX+API_VERSION_PREFIX+"/sale/integrationhandler/list";
export const GET_SALE_CUSTOMERS = API_PREFIX+API_VERSION_PREFIX+"/sale/customer/list";
export const UPDATE_SALE_CUSTOMER = API_PREFIX+API_VERSION_PREFIX+"/sale/customer/update";
export const REMOVE_SALE_CUSTOMER = API_PREFIX+API_VERSION_PREFIX+"/sale/customer/remove";
export const SEARCH_SALE_CUSTOMER = API_PREFIX+API_VERSION_PREFIX+"/sale/customer/search";

export const GET_SALE_ORDER_STATUSES = API_PREFIX+API_VERSION_PREFIX+"/sale/orderstatus/list";
export const UPDATE_SALE_ORDER_STATUS = API_PREFIX+API_VERSION_PREFIX+"/sale/orderstatus/update";
export const REMOVE_SALE_ORDER_STATUS = API_PREFIX+API_VERSION_PREFIX+"/sale/orderstatus/remove";
export const GET_SALE_ORDERS = API_PREFIX+API_VERSION_PREFIX+"/sale/order/list";
export const GET_SALE_ORDER_DETAIL = API_PREFIX+API_VERSION_PREFIX+"/sale/order/:uuid/detail";

export const CANCEL_SALE_ORDER = API_PREFIX+API_VERSION_PREFIX+"/sale/order/:uuid/cancel";
export const UPDATE_SALE_ORDER = API_PREFIX+API_VERSION_PREFIX+"/sale/order/update";
export const REMOVE_SALE_ORDER = API_PREFIX+API_VERSION_PREFIX+"/sale/order/remove";
export const UPDATE_SALE_ORDER_DELIVERY_ITEM = API_PREFIX+API_VERSION_PREFIX+"/sale/order/delivery/update";
export const REMOVE_SALE_ORDER_DELIVERY_ITEM = API_PREFIX+API_VERSION_PREFIX+"/sale/order/delivery/remove";
export const UPDATE_SALE_ORDER_PAYMENT = API_PREFIX+API_VERSION_PREFIX+"/sale/order/payment/update";
export const REMOVE_SALE_ORDER_PAYMENT = API_PREFIX+API_VERSION_PREFIX+"/sale/order/payment/remove";
export const UPDATE_SALE_ORDER_CUSTOMER = API_PREFIX+API_VERSION_PREFIX+"/sale/order/customer/update";
export const REMOVE_SALE_ORDER_CUSTOMER = API_PREFIX+API_VERSION_PREFIX+"/sale/order/customer/remove";
export const CHANGE_SALE_ORDER_STATUS = API_PREFIX+API_VERSION_PREFIX+"/sale/order/status/update";
export const CHANGE_SALE_ORDER_ITEM = API_PREFIX+API_VERSION_PREFIX+"/sale/order/item/update";
export const REMOVE_SALE_ORDER_GROUP = API_PREFIX+API_VERSION_PREFIX+"/sale/order/group/remove";
export const GET_EVENT_WEBHOOKS = API_PREFIX+API_VERSION_PREFIX+"/event/webhook/list";
export const UPDATE_EVENT_WEBHOOK = API_PREFIX+API_VERSION_PREFIX+"/event/webhook/update";
export const REMOVE_EVENT_WEBHOOK = API_PREFIX+API_VERSION_PREFIX+"/event/webhook/remove";

export const GET_EVENT_TYPES = API_PREFIX+API_VERSION_PREFIX+"/event/type/list";

export const GET_EVENT_TYPES_WEBHOOK = API_PREFIX+API_VERSION_PREFIX+"/event/type/webhook/list";

export const GET_STAT_SALE_ORDER_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/tiles";

export const GET_STAT_SALE_ORDER_COUNTRY_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/country/tiles";
export const GET_STAT_SALE_ORDER_CURRENCY_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/currency/tiles";

export const GET_STAT_SALE_ORDER_STATUS_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/status/tiles";

export const GET_STAT_SALE_ORDER_BEST_PRODUCTS_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/best/product/tiles";

export const GET_STAT_SALE_ORDER_BEST_CHANNELS_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/best/channel/tiles";

export const GET_STAT_SALE_ORDER_BEST_CUSTOMERS_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/best/customer/tiles";

export const GET_STAT_SALE_ORDER_LAST_12MONTH_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/year/tiles";
export const GET_STAT_SALE_ORDER_WEEKDAYS_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/order/weekday/tiles";

export const CHECK_URL_SHORTENER = API_PREFIX+API_VERSION_PREFIX+"/shortener/check";


export const GET_TASKS = API_PREFIX+API_VERSION_PREFIX+"/task/list";
export const GET_TASK_DETAIL = API_PREFIX+API_VERSION_PREFIX+"/task/detail/:uuid";
export const GET_TASK_DETAIL_COMMENTS = API_PREFIX+API_VERSION_PREFIX+"/task/detail/:uuid/comment/list";
export const READ_TASK_DETAIL_COMMENTS = API_PREFIX+API_VERSION_PREFIX+"/task/detail/:uuid/comment/read";
export const CHANGE_TASK_STATUS = API_PREFIX+API_VERSION_PREFIX+"/task/status";
export const REMOVE_TASK = API_PREFIX+API_VERSION_PREFIX+"/task/remove";
export const REMOVE_TASK_GROUP = API_PREFIX+API_VERSION_PREFIX+"/task/group/remove";
export const CLOSE_TASK_GROUP = API_PREFIX+API_VERSION_PREFIX+"/task/group/close";
export const UPDATE_TASK = API_PREFIX+API_VERSION_PREFIX+"/task/update";
export const COPY_TASK = API_PREFIX+API_VERSION_PREFIX+"/task/copy/:uuid";
export const GET_TASK_BADGES_SUMMARY = API_PREFIX+API_VERSION_PREFIX+"/task/badge/summary";

export const GET_STAT_TASK_TILES_LIST = API_PREFIX+API_VERSION_PREFIX+"/stat/task/tiles";

export const GET_TASK_TIMES = API_PREFIX+API_VERSION_PREFIX+"/task/detail/:uuid/time/list";
export const REMOVE_TASK_TIME = API_PREFIX+API_VERSION_PREFIX+"/task/detail/:uuid/time/remove";
export const UPDATE_TASK_TIME = API_PREFIX+API_VERSION_PREFIX+"/task/detail/:uuid/time/update";

export const GET_COMMENTS = API_PREFIX+API_VERSION_PREFIX+"/comment/list";
export const UPDATE_COMMENT = API_PREFIX+API_VERSION_PREFIX+"/comment/update";
export const REMOVE_COMMENT = API_PREFIX+API_VERSION_PREFIX+"/comment/remove";
export const GET_CABINET_CONTACTS = API_PREFIX+API_VERSION_PREFIX+"/main/contact/list";


export const GET_USER_DEVICE_LIST = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/device/list";
export const APPROVE_USER_DEVICE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/device/approve";
export const REMOVE_USER_DEVICE = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/main/device/remove";
export const GET_DEVICE_USERS_LIST = config.ID_API_URL+API_PREFIX+API_VERSION_PREFIX+"/auth/device/user/list";