import React, { useState, useEffect } from 'react';
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Collapse
} from "reactstrap";

//redux
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarImageType,
    saveSettingsValues, toggleOpenSettings
    // changePreLoader
    // resetValue
} from "../../../redux/actions";

//import Constant
import {
    layoutTypes,
    leftSidebarTypes,
    layoutModeTypes,
    layoutWidthTypes,
    layoutPositionTypes,
    topbarThemeTypes,
    leftsidbarSizeTypes,
    leftSidebarViewTypes,
    leftSidebarImageTypes,
    //preloaderTypes
} from "../../../constants/Layout";

//SimpleBar
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { useLocation } from 'react-router-dom';
import {useRedux} from "../../../hooks";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

//import Images
const img01 = require("../../../resources/images/sidebar/img-1.jpg");
const img02 = require("../../../resources/images/sidebar/img-2.jpg");
const img03 = require("../../../resources/images/sidebar/img-3.jpg");
const img04 = require("../../../resources/images/sidebar/img-4.jpg");

const RightSidebar = () => {
    const { dispatch, useAppSelector } = useRedux();
    const { t } = useTranslation(['settings']);

    const [show, setShow] = useState(false);
    function tog_show() {
        setShow(!show);
        dispatch(changeSidebarTheme("gradient"));
    }

    useEffect(() => {
        if (show && document.getElementById("sidebar-color-dark") && document.getElementById("sidebar-color-light")) {
            let sidebarColorDark = document.getElementById("sidebar-color-dark") as HTMLInputElement;
                sidebarColorDark.checked = false

            let sidebarColorLight = document.getElementById("sidebar-color-light") as HTMLInputElement;
                sidebarColorLight.checked = false
        }
    });

    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        preloader,
        stateLayout,
        isOpen
    } = useAppSelector (state => ({
        layoutType: state.Layout.layoutType,
        leftSidebarType: state.Layout.leftSidebarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidthType: state.Layout.layoutWidthType,
        layoutPositionType: state.Layout.layoutPositionType,
        topbarThemeType: state.Layout.topbarThemeType,
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        leftSidebarViewType: state.Layout.leftSidebarViewType,
        leftSidebarImageType: state.Layout.leftSidebarImageType,
        preloader: state.Layout.preloader,
        stateLayout: state.Layout,
        isOpen: state.Layout.isOpen
    }));

    const toggleLeftCanvas = () => {
        dispatch(toggleOpenSettings());
    };

    window.onscroll = function () {
        scrollFunction();
    };

    const scrollFunction = () => {
        const element = document.getElementById("back-to-top");
        if (element) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    };

    const toTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const pathName = useLocation().pathname;

    useEffect(() => {
        const preloader = document.getElementById("preloader");
        if (preloader) {
            document.getElementById("preloader").style.opacity = "1";
            document.getElementById("preloader").style.visibility = "visible";
            setTimeout(function () {
                document.getElementById("preloader").style.opacity = "0";
                document.getElementById("preloader").style.visibility = "hidden";
            }, 1000);
        }
    }, [preloader, pathName]);

    return (
        <React.Fragment>
            <button
                onClick={() => toTop()}
                className="btn btn-danger btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>

            {/*{preloader === "enable" && <div id="preloader">*/}
            {/*    <div id="status">*/}
            {/*        <div className="spinner-border text-primary avatar-sm" role="status">*/}
            {/*            <span className="visually-hidden">!#Loading...</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>}*/}

            <div>
                {/*<div className="customizer-setting d-none d-md-block">*/}
                {/*    <div onClick={toggleLeftCanvas} className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2">*/}
                {/*        <i className='mdi mdi-spin mdi-cog-outline fs-22'></i>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Offcanvas isOpen={isOpen} toggle={toggleLeftCanvas} direction="end" className="offcanvas-end border-0">
                    <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={toggleLeftCanvas}>
                        <span className="m-0 me-2 text-white">{t('settings:themesAndStyle')}</span>
                    </OffcanvasHeader>
                    <OffcanvasBody className="p-0">
                        <SimpleBar className="h-100">
                            <div className="p-4">
                                <h6 className="mt-0 mb-0 fw-semibold text-uppercase">{t('settings:styleMode')}</h6>
                                <p className="text-muted">{t('settings:chooseMode')}</p>

                                <div className="colorscheme-cardradio">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-check card-radio">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="data-layout-mode"
                                                    id="layout-mode-light"
                                                    value={layoutModeTypes.LIGHTMODE}
                                                    checked={layoutModeType === layoutModeTypes.LIGHTMODE}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            dispatch(changeLayoutMode(e.target.value));
                                                            dispatch(saveSettingsValues());
                                                        }
                                                    }}
                                                />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="layout-mode-light">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"></span>
                                                                <span className="bg-light d-block p-1 mt-auto"></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <h5 className="fs-13 text-center mt-2">{t('settings:light')}</h5>
                                        </div>

                                        <div className="col-4">
                                            <div className="form-check card-radio dark">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="data-layout-mode"
                                                    id="layout-mode-dark"
                                                    value={layoutModeTypes.DARKMODE}
                                                    checked={layoutModeType === layoutModeTypes.DARKMODE}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            dispatch(changeLayoutMode(e.target.value));
                                                            dispatch(saveSettingsValues());
                                                        }
                                                    }}
                                                />
                                                <label className="form-check-label p-0 avatar-md w-100 bg-dark" htmlFor="layout-mode-dark">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-soft-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-soft-light d-block p-1"></span>
                                                                <span className="bg-soft-light d-block p-1 mt-auto"></span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <h5 className="fs-13 text-center mt-2">{t('settings:dark')}</h5>
                                        </div>
                                    </div>
                                </div>

                                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:layout')}</h6>
                                <p className="text-muted">{t('settings:chooseYourLayout')}</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input
                                                id="customizer-layout01"
                                                name="data-layout"
                                                type="radio"
                                                value={layoutTypes.VERTICAL}
                                                checked={layoutType === layoutTypes.VERTICAL}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        dispatch(changeLayout(e.target.value));
                                                        dispatch(saveSettingsValues());
                                                    }
                                                }}
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout01">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"></span>
                                                            <span className="bg-light d-block p-1 mt-auto"></span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">{t('settings:vertical')}</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input
                                                id="customizer-layout02"
                                                name="data-layout"
                                                type="radio"
                                                value={layoutTypes.HORIZONTAL}
                                                checked={layoutType === layoutTypes.HORIZONTAL}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        dispatch(changeLayout(e.target.value));
                                                        dispatch(saveSettingsValues());
                                                    }
                                                }}
                                                className="form-check-input" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout02">
                                                <span className="d-flex h-100 flex-column gap-1">
                                                    <span className="bg-light d-flex p-1 gap-1 align-items-center">
                                                        <span className="d-block p-1 bg-soft-primary rounded me-1"></span>
                                                        <span className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"></span>
                                                        <span className="d-block p-1 pb-0 px-2 bg-soft-primary"></span>
                                                    </span>
                                                    <span className="bg-light d-block p-1"></span>
                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">{t('settings:horizontal')}</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input
                                                id="customizer-layout03"
                                                name="data-layout"
                                                type="radio"
                                                value={layoutTypes.TWOCOLUMN}
                                                checked={layoutType === layoutTypes.TWOCOLUMN}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        dispatch(changeLayout(e.target.value));
                                                        dispatch(saveSettingsValues());
                                                    }
                                                }}
                                                className="form-check-input" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout03">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1">
                                                            <span className="d-block p-1 bg-soft-primary mb-2"></span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"></span>
                                                            <span className="bg-light d-block p-1 mt-auto"></span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">{t('settings:twoColumn')}</h5>
                                    </div>
                                </div>

                                {layoutType !== layoutTypes.TWOCOLUMN && (
                                    <React.Fragment>
                                        <div id="layout-width">
                                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:layoutWidth')}</h6>
                                            <p className="text-muted">{t('settings:chooseLayoutWidth')}</p>

                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-check card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-layout-width"
                                                            id="layout-width-fluid"
                                                            value={layoutWidthTypes.FLUID}
                                                            checked={layoutWidthType === layoutWidthTypes.FLUID}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLayoutWidth(e.target.value));
                                                                    dispatch(changeLeftsidebarSizeType("lg"));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="layout-width-fluid">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                        <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:fluid')}</h5>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-layout-width"
                                                            id="layout-width-boxed"
                                                            value={layoutWidthTypes.BOXED}
                                                            checked={layoutWidthType === layoutWidthTypes.BOXED}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLayoutWidth(e.target.value));
                                                                    dispatch(changeLeftsidebarSizeType("sm-hover"));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100 px-2" htmlFor="layout-width-boxed">
                                                            <span className="d-flex gap-1 h-100 border-start border-end">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                        <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:boxed')}</h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="layout-position">
                                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:layoutPosition')}</h6>
                                            <p className="text-muted">{t('settings:chooseLayoutPosition')}</p>

                                            <div className="btn-group radio" role="group">
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="data-layout-position"
                                                    id="layout-position-fixed"
                                                    value={layoutPositionTypes.FIXED}
                                                    checked={layoutPositionType === layoutPositionTypes.FIXED}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            dispatch(changeLayoutPosition(e.target.value));
                                                            dispatch(saveSettingsValues());
                                                        }
                                                    }}
                                                />
                                                <label className="btn btn-light w-sm" htmlFor="layout-position-fixed">{t('settings:fixed')}</label>

                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="data-layout-position"
                                                    id="layout-position-scrollable"
                                                    value={layoutPositionTypes.SCROLLABLE}
                                                    checked={layoutPositionType === layoutPositionTypes.SCROLLABLE}
                                                    onChange={e => {
                                                        if (e.target.checked) {
                                                            dispatch(changeLayoutPosition(e.target.value));
                                                            dispatch(saveSettingsValues());
                                                        }
                                                    }}
                                                />
                                                <label className="btn btn-light w-sm ms-0" htmlFor="layout-position-scrollable">{t('settings:scrollable')}</label>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}


                                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:topbarColor')}</h6>
                                <p className="text-muted">{t('settings:chooseTopbarColor')}</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="data-topbar"
                                                id="topbar-color-light"
                                                value={topbarThemeTypes.LIGHT}
                                                checked={topbarThemeType === topbarThemeTypes.LIGHT}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        dispatch(changeTopbarTheme(e.target.value));
                                                        dispatch(saveSettingsValues());
                                                    }
                                                }}
                                            />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="topbar-color-light">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"></span>
                                                            <span className="bg-light d-block p-1 mt-auto"></span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">{t('settings:light_f')}</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="data-topbar"
                                                id="topbar-color-dark"
                                                value={topbarThemeTypes.DARK}
                                                checked={topbarThemeType === topbarThemeTypes.DARK}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        dispatch(changeTopbarTheme(e.target.value));
                                                        dispatch(saveSettingsValues());
                                                    }
                                                }}
                                            />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="topbar-color-dark">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-primary d-block p-1"></span>
                                                            <span className="bg-light d-block p-1 mt-auto"></span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">{t('settings:dark_f')}</h5>
                                    </div>
                                </div>

                                {layoutType === "vertical" && (
                                    <React.Fragment>

                                        <div id="sidebar-size">
                                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:sidebarSize')}</h6>
                                            <p className="text-muted">{t('settings:chooseSidebarSize')}</p>

                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-sidebar-size"
                                                            id="sidebar-size-default"
                                                            value={leftsidbarSizeTypes.DEFAULT}
                                                            checked={leftsidbarSizeType === leftsidbarSizeTypes.DEFAULT}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLeftsidebarSizeType(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-default">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                        <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:default_f')}</h5>
                                                </div>

                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-sidebar-size"
                                                            id="sidebar-size-compact"
                                                            value={leftsidbarSizeTypes.COMPACT}
                                                            checked={leftsidbarSizeType === leftsidbarSizeTypes.COMPACT}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLeftsidebarSizeType(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-compact">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                        <span className="d-block p-1 bg-soft-primary rounded mb-2"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:compact')}</h5>
                                                </div>

                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-sidebar-size"
                                                            id="sidebar-size-small"
                                                            value={leftsidbarSizeTypes.SMALLICON}
                                                            checked={leftsidbarSizeType === leftsidbarSizeTypes.SMALLICON}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLeftsidebarSizeType(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                        <span className="d-block p-1 bg-soft-primary mb-2"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:iconView')}</h5>
                                                </div>

                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-sidebar-size"
                                                            id="sidebar-size-small-hover"
                                                            value={leftsidbarSizeTypes.SMALLHOVER}
                                                            checked={leftsidbarSizeType === leftsidbarSizeTypes.SMALLHOVER}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLeftsidebarSizeType(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}

                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small-hover">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                        <span className="d-block p-1 bg-soft-primary mb-2"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:hoverView')}</h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="sidebar-view">
                                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:sidebarView')}</h6>
                                            <p className="text-muted">{t('settings:chooseSidebarView')}</p>

                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-layout-style"
                                                            id="sidebar-view-default"
                                                            value={leftSidebarViewTypes.DEFAULT}
                                                            checked={leftSidebarViewType === leftSidebarViewTypes.DEFAULT}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLeftsidebarViewType(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}

                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-view-default">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                        <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:default')}</h5>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-layout-style"
                                                            id="sidebar-view-detached"
                                                            value={leftSidebarViewTypes.DETACHED}
                                                            checked={leftSidebarViewType === leftSidebarViewTypes.DETACHED}
                                                            onChange={e => {
                                                                if (e.target.checked) {
                                                                    dispatch(changeLeftsidebarViewType(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-view-detached">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-flex p-1 gap-1 align-items-center px-2">
                                                                    <span className="d-block p-1 bg-soft-primary rounded me-1"></span>
                                                                    <span className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"></span>
                                                                    <span className="d-block p-1 pb-0 px-2 bg-soft-primary"></span>
                                                                </span>
                                                                <span className="d-flex gap-1 h-100 p-1 px-2">
                                                                    <span className="flex-shrink-0">
                                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                                <span className="bg-light d-block p-1 mt-auto px-2"></span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:detached')}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}

                                {layoutType !== "horizontal" && (
                                    <React.Fragment>
                                        <div id="sidebar-color">
                                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:sidebarColor')}</h6>
                                            <p className="text-muted">{t('settings:chooseSidebarColor')}</p>

                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-sidebar"
                                                            id="sidebar-color-light"
                                                            value={leftSidebarTypes.LIGHT}
                                                            checked={leftSidebarType === leftSidebarTypes.LIGHT}
                                                            onChange={e => {
                                                                setShow(false);
                                                                if (e.target.checked) {
                                                                    dispatch(changeSidebarTheme(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-color-light">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="border-end d-flex h-100 flex-column gap-1 p-1">
                                                                        <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-primary"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:light_f')}</h5>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="data-sidebar"
                                                            id="sidebar-color-dark"
                                                            value={leftSidebarTypes.DARK}
                                                            checked={leftSidebarType === leftSidebarTypes.DARK}
                                                            onChange={e => {
                                                                setShow(false);
                                                                if (e.target.checked) {
                                                                    dispatch(changeSidebarTheme(e.target.value));
                                                                    dispatch(saveSettingsValues());
                                                                }
                                                            }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-color-dark">
                                                            <span className="d-flex gap-1 h-100">
                                                                <span className="flex-shrink-0">
                                                                    <span className="bg-primary d-flex h-100 flex-column gap-1 p-1">
                                                                        <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                    </span>
                                                                </span>
                                                                <span className="flex-grow-1">
                                                                    <span className="d-flex h-100 flex-column">
                                                                        <span className="bg-light d-block p-1"></span>
                                                                        <span className="bg-light d-block p-1 mt-auto"></span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:dark_f')}</h5>
                                                </div>
                                                <div className="col-4">
                                                    <button
                                                        className={classnames(
                                                            "btn btn-link avatar-md w-100 p-0 overflow-hidden border ",
                                                            { collapsed: !show, active: show === true }
                                                        )}
                                                        type="button"
                                                        data-bs-target="#collapseBgGradient"
                                                        data-bs-toggle="collapse"
                                                        aria-controls="collapseBgGradient"
                                                        // checked={leftSidebarType === leftSidebarTypes.GRAD}
                                                        onClick={tog_show}
                                                    >
                                                        <span className="d-flex gap-1 h-100">
                                                            <span className="flex-shrink-0">
                                                                <span className="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                                                                    <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                                                </span>
                                                            </span>
                                                            <span className="flex-grow-1">
                                                                <span className="d-flex h-100 flex-column">
                                                                    <span className="bg-light d-block p-1"></span>
                                                                    <span className="bg-light d-block p-1 mt-auto"></span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <h5 className="fs-13 text-center mt-2">{t('settings:gradient')}</h5>
                                                </div>
                                            </div>
                                            <Collapse
                                                isOpen={show}
                                                className="collapse"
                                                id="collapseBgGradient"
                                            >
                                                <div className="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input className="form-check-input"
                                                               type="radio"
                                                               name="data-sidebar"
                                                               id="sidebar-color-gradient"
                                                               value={leftSidebarTypes.GRADIENT}
                                                               checked={leftSidebarType === leftSidebarTypes.GRADIENT}
                                                               onChange={e => {
                                                                   if (e.target.checked) {
                                                                       dispatch(changeSidebarTheme(e.target.value));
                                                                       dispatch(saveSettingsValues());
                                                                   }
                                                               }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient">
                                                            <span className="avatar-title rounded-circle bg-vertical-gradient"></span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input className="form-check-input"
                                                               type="radio"
                                                               name="data-sidebar"
                                                               id="sidebar-color-gradient-2"
                                                               value={leftSidebarTypes.GRADIENT_2}
                                                               checked={leftSidebarType === leftSidebarTypes.GRADIENT_2}
                                                               onChange={e => {
                                                                   if (e.target.checked) {
                                                                       dispatch(changeSidebarTheme(e.target.value));
                                                                       dispatch(saveSettingsValues());
                                                                   }
                                                               }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient-2">
                                                            <span className="avatar-title rounded-circle bg-vertical-gradient-2"></span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input className="form-check-input"
                                                               type="radio"
                                                               name="data-sidebar"
                                                               id="sidebar-color-gradient-3"
                                                               value={leftSidebarTypes.GRADIENT_3}
                                                               checked={leftSidebarType === leftSidebarTypes.GRADIENT_3}
                                                               onChange={e => {
                                                                   if (e.target.checked) {
                                                                       dispatch(changeSidebarTheme(e.target.value));
                                                                       dispatch(saveSettingsValues());
                                                                   }
                                                               }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient-3">
                                                            <span className="avatar-title rounded-circle bg-vertical-gradient-3"></span>
                                                        </label>
                                                    </div>
                                                    <div className="form-check sidebar-setting card-radio">
                                                        <input className="form-check-input"
                                                               type="radio"
                                                               name="data-sidebar"
                                                               id="sidebar-color-gradient-4"
                                                               value={leftSidebarTypes.GRADIENT_4}
                                                               checked={leftSidebarType === leftSidebarTypes.GRADIENT_4}
                                                               onChange={e => {
                                                                   if (e.target.checked) {
                                                                       dispatch(changeSidebarTheme(e.target.value));
                                                                       dispatch(saveSettingsValues());
                                                                   }
                                                               }}
                                                        />
                                                        <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient-4">
                                                            <span className="avatar-title rounded-circle bg-vertical-gradient-4"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div id="sidebar-img">
                                            <h6 className="mt-4 mb-0 fw-semibold text-uppercase">{t('settings:sidebarWallpapers')}</h6>
                                            <p className="text-muted">{t('settings:chooseSidebarWallpapers')}</p>

                                            <div className="d-flex gap-2 flex-wrap img-switch">
                                                <div className="form-check sidebar-setting card-radio">
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           name="data-sidebar-image"
                                                           id="sidebarimg-none"
                                                           value={leftSidebarImageTypes.NONE}
                                                           checked={leftSidebarImageType === leftSidebarImageTypes.NONE}
                                                           onChange={e => {
                                                               if (e.target.checked) {
                                                                   dispatch(changeSidebarImageType(e.target.value));
                                                                   dispatch(saveSettingsValues());
                                                               }
                                                           }}
                                                    />
                                                    <label className="form-check-label p-0 avatar-sm h-auto" htmlFor="sidebarimg-none">
                                                        <span className="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
                                                            <i className="ri-close-fill fs-20"></i>
                                                        </span>
                                                    </label>
                                                </div>

                                                <div className="form-check sidebar-setting card-radio">
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           name="data-sidebar-image"
                                                           id="sidebarimg-01"
                                                           value={leftSidebarImageTypes.IMG1}
                                                           checked={leftSidebarImageType === leftSidebarImageTypes.IMG1}
                                                           onChange={e => {
                                                               if (e.target.checked) {
                                                                   dispatch(changeSidebarImageType(e.target.value));
                                                                   dispatch(saveSettingsValues());
                                                               }
                                                           }}
                                                    />
                                                    <label className="form-check-label p-0 avatar-sm h-auto" htmlFor="sidebarimg-01">
                                                        <img src={img01} alt="" className="avatar-md w-auto object-cover" />
                                                    </label>

                                                </div>

                                                <div className="form-check sidebar-setting card-radio">
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           name="data-sidebar-image"
                                                           id="sidebarimg-02"
                                                           value={leftSidebarImageTypes.IMG2}
                                                           checked={leftSidebarImageType === leftSidebarImageTypes.IMG2}
                                                           onChange={e => {
                                                               if (e.target.checked) {
                                                                   dispatch(changeSidebarImageType(e.target.value));
                                                                   dispatch(saveSettingsValues());
                                                               }
                                                           }}
                                                    />
                                                    <label className="form-check-label p-0 avatar-sm h-auto" htmlFor="sidebarimg-02">
                                                        <img src={img02} alt="" className="avatar-md w-auto object-cover" />
                                                    </label>
                                                </div>
                                                <div className="form-check sidebar-setting card-radio">
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           name="data-sidebar-image"
                                                           id="sidebarimg-03"
                                                           value={leftSidebarImageTypes.IMG3}
                                                           checked={leftSidebarImageType === leftSidebarImageTypes.IMG3}
                                                           onChange={e => {
                                                               if (e.target.checked) {
                                                                   dispatch(changeSidebarImageType(e.target.value));
                                                                   dispatch(saveSettingsValues());
                                                               }
                                                           }}
                                                    />
                                                    <label className="form-check-label p-0 avatar-sm h-auto" htmlFor="sidebarimg-03">
                                                        <img src={img03} alt="" className="avatar-md w-auto object-cover" />
                                                    </label>
                                                </div>
                                                <div className="form-check sidebar-setting card-radio">
                                                    <input className="form-check-input"
                                                           type="radio"
                                                           name="data-sidebar-image"
                                                           id="sidebarimg-04"
                                                           value={leftSidebarImageTypes.IMG4}
                                                           checked={leftSidebarImageType === leftSidebarImageTypes.IMG4}
                                                           onChange={e => {
                                                               if (e.target.checked) {
                                                                   dispatch(changeSidebarImageType(e.target.value));
                                                                   dispatch(saveSettingsValues());
                                                               }
                                                           }}
                                                    />
                                                    <label className="form-check-label p-0 avatar-sm h-auto" htmlFor="sidebarimg-04">
                                                        <img src={img04} alt="" className="avatar-md w-auto object-cover" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </SimpleBar>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </React.Fragment>
    );
};

export default RightSidebar;