import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row, Form } from 'reactstrap';
import LeftbarTitle from "../../../components/LeftbarTitle";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../../../hooks";
import {PlanOrderType} from "../../../redux/Sale/PlanOrder/types";


interface ItemProps {
    onCancel: (data: any, dispatch: any) => void;
    handleClose: (dispatch: any) => void;
    cancelItem: PlanOrderType;
    isOpen: boolean;
}

const CancelPlanModal = ({ onCancel, cancelItem, handleClose, isOpen }: ItemProps) => {

    const { t, i18n } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();

    const [isModalShow, setModalShow] = useState<boolean>(isOpen);
    const [title, setTitle] = useState<string>(t('sale:cancelOrder2')+' #'+cancelItem.code);

    useEffect(() => {
        setModalShow(isOpen);
    }, [isOpen]);

    const onDataSubmit = (e:any) => {
        e.preventDefault();
        onCancel(cancelItem, dispatch);
    };

    return (
        <>
            <Modal show={isModalShow} onHide={()=>handleClose(dispatch)}>
                <Form noValidate={true} onSubmit={onDataSubmit}>
                    <Modal.Header closeButton>
                        <LeftbarTitle title={title}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <div className="d-flex">
                                <div className="mb-3 d-flex flex-column align-items-center me-5">
                                    {t('sale:cancelOrderDescription')} #{cancelItem.code}?
                                </div>
                            </div>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={handleClose}>
                            {t('common:no')}
                        </Button>
                        <Button variant="primary"
                                type="submit"
                                className="btn btn-danger chat-send waves-effect waves-light"
                        >
                            {t('common:yes')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default CancelPlanModal;