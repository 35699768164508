import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {TaskType} from "../../../redux/Task/Task/types";
import {handleTaskEdit} from "../../Task/Task/TaskActions";
import {OrderType} from "../../../redux/Sale/Order/types";


export const handleCreateNewTaskByOrder = (entity: OrderType, dispatch: any) => {
    let newTask:TaskType = NewTaskItem;

    newTask = {
        ...newTask,
        text: entity?.code && entity.code
    }

    handleTaskEdit(newTask, dispatch);
}