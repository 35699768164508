import React from "react";

// component
//import ChatUser from "./ChatUser";
import ChatChannel from "./ChatChannel";


import '../../../i18/config';
import {useTranslation} from "react-i18next";

interface ArchiveProps {
  archiveContacts: any;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const Archive = ({
  archiveContacts,
  selectedChat,
  onSelectChat,
}: ArchiveProps) => {

    const { t } = useTranslation(['common']);

  return (
    <>
        <div className="d-flex align-items-center px-4 mt-4 pt-2 mb-2">
            <div className="flex-grow-1">
                <h4 className="mb-0 fs-11 text-muted text-uppercase">
          {t('common:archived')}
                </h4>
            </div>
        </div>

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {(archiveContacts || []).map((chat: any, key: number) => {
            if (chat.isChannel) {
              return (
                <ChatChannel
                  channel={chat}
                  key={key}
                  selectedChat={selectedChat}
                  onSelectChat={onSelectChat}
                />
              );
            } else {
              // return (
              //   <ChatUser
              //     user={chat}
              //     key={key}
              //     selectedChat={selectedChat}
              //     onSelectChat={onSelectChat}
              //   />
              // );
            }
            return (<></>);
          })}
        </ul>
      </div>
    </>
  );
};

export default Archive;
