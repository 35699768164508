import {ContentPeerTargetActionTypes, ContentPeerTargetPayload} from "./types";

export const contentPeerTargetApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentPeerTargetActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentPeerTargetApiResponseError = (actionType: string, error: string) => ({
  type: ContentPeerTargetActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentPeerTargets = () => ({
  type: ContentPeerTargetActionTypes.GET_CONTENT_PEER_TARGETS,
});

export const showRightModalContentPeerTarget= (data: ContentPeerTargetPayload) => ({
  type: ContentPeerTargetActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalContentPeerTarget= () => ({
  type: ContentPeerTargetActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataContentPeerTarget = (data: any) => ({
  type: ContentPeerTargetActionTypes.UPDATE_CONTENT_PEER_TARGET,
  payload: data,
});

export const editDataContentPeerTarget = (data: ContentPeerTargetPayload) => ({
  type: ContentPeerTargetActionTypes.EDIT_CONTENT_PEER_TARGET,
  payload: data,
});

export const removeContentPeerTargetModalShow = (data: ContentPeerTargetPayload) => ({
  type: ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET_MODAL_SHOW,
  payload: data,
});

export const removeContentPeerTargetModalHide = () => ({
  type: ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET_MODAL_HIDE,
});

export const onRemoveContentPeerTarget = (data: ContentPeerTargetPayload) => ({
  type: ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET,
  payload: data,
});