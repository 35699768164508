import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    Card,
    Label,
    CardBody,
    Col,
    Row,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {useRedux} from "../../../hooks";
import {
    Formik,
    Form,
    Field
} from 'formik';
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {
    newProductItem,
    ProductPrivacyStatusValues,
    ProductPublishStatusValues,
    ProductStoreValue,
    ProductType,
    ProductTypeStatusValues, SaleProductPayload
} from "../../../redux/Sale/Product/types";
import {SaleCategoryType} from "../../../redux/Sale/Category/types";
import {MainFileType} from "../../../redux/Main/File/types";
import {
    editDataSaleCategory,
    getSaleCategories,
    showRightModalSaleCategory
} from "../../../redux/Sale/Category/actions";
import {
    clearProductFetched,
    editDataSaleProduct,
    editSaleProductIntegrationChannel, editSaleProductProperties,
    getSaleEditProduct,
    onSendDataSaleProduct
} from "../../../redux/Sale/Product/actions";
import InputSelect from "../../../components/InputSelect";
import InputSwitch from "../../../components/InputSwitch";
import InputText from "../../../components/InputText";
import InputEmojiArea from "../../../components/InputEmojiArea";
import InputEditorSwitch from "../../../components/InputEditorSwitch";
import InputFile from "../../../components/InputFile";
import classnames from "classnames";
import {
    convertToMomentLocale,
    isNumeric,
    makeSelectCategoryItem,
    prepareSelectCategories
} from "../../../helpers/functions";
import {getSaleStores} from "../../../redux/Sale/Store/actions";
import {SaleStoreType} from "../../../redux/Sale/Store/types";
import StoreItemLine from "../Store/StoreItemLine";
import {getSaleCurrencies} from "../../../redux/Sale/Currency/actions";
import {getSalePriceTypes} from "../../../redux/Sale/PriceType/actions";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import PriceTypeItemLine from "../PriceType/PriceTypeItemLine";
import {ProductPriceType} from "../../../redux/Sale/Price/types";
import {
    editDataSaleProductParam,
    getSaleProductParams,
    showRightModalSaleProductParam
} from "../../../redux/Sale/ProductParam/actions";
import {ProductParamListValueType, ProductParamType, ProductParamTypes} from "../../../redux/Sale/ProductParam/types";
import {ProductParamValueType} from "../../../redux/Sale/ProductParamValue/types";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import ChannelItemProductIntegration from "../../Content/PeerChannel/channelItemProductIntegration";
import RightModalProductIntegrationChannel from "./modalIntegrationChannelForm";
import {ProductIntegrationType} from "../../../redux/Sale/ProductIntegration/types";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import {newMetaItem} from "../../../redux/Main/Meta/reducer";
import RightModalProductProperties from "./modalPropertiesForm";
import InputTextAddon from "../../../components/InputTextAddon";
import {CURRENCY_SYMBOL_EXTRACT_REGEX} from "../../../data/settings";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import UpgradeFarePage from "../../../components/UpgradeFarePage";
import * as route from "../../../api/routes";
import RightModal from "../Category/modalForm";
import {default as RightModalProductPropertiesTypes} from "../ProductParam/modalForm";
import {SaleProductValidatorSchema} from "./validator";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {getSaleIntegrations} from "../../../redux/Sale/Integration/actions";
import {SaleIntegrationHandlerCodes, SaleIntegrationHandlerTypes} from "../../../redux/Sale/IntegrationHandler/types";
import SaleIntegrationItemProductIntegration from "../Integration/SaleIntegrationItemProductIntegration";

const ProductDetailEditIndex = () => {

    const { t, i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));
    const navigate = useNavigate();

    const priceTab = 'priceTab';
    const storeTab = 'storeTab';
    const integrationTab = 'integrationTab';
    const propertiesTab = 'propertiesTab';

    const [product, setProduct] = useState<ProductType>(null);

    const { uuid } = useParams();

    const { dispatch, useAppSelector } = useRedux();

    const [activeTab, setActiveTab] = useState(priceTab);
    const [categoryTree, setCategoryTree] = useState<any>([]);
    const [isFetched, setFetched] = useState<boolean>(false);
    const [stores, setStores] = useState<SaleStoreType[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
    const [priceTypes, setPriceTypes] = useState<PriceTypeType[]>([]);
    const [productParams, setProductParams] = useState<ProductParamType[]>([]);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [productParamValues, setProductParamValues] = useState<ProductParamValueType[]>([]);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [saleIntegrations, setSaleIntegrations] = useState<SaleIntegrationType[]>([]);


    const productPrivacyStatusValuesLang = ProductPrivacyStatusValues.map((item)=>{
       return {
           ...item,
           name: t('product:productPrivacyStatus'+item.name)
       }
    });

    const productPublishStatusValuesLang = ProductPublishStatusValues.map((item)=>{
        return {
            ...item,
            name: t('product:productPublishStatus'+item.name)
        }
    });

    const productTypeStatusValuesLang = ProductTypeStatusValues.map((item)=>{
        return {
            ...item,
            name: t('product:productTypeStatus'+item.name)
        }
    });

    const {
        saleProduct,
        saleCategory,
        saleStore,
        saleCurrency,
        salePriceType,
        saleProductParam,
        contentPeerChannels,
        salePlan,
        saleIntegration
    } = useAppSelector(state => ({
        saleProduct: state.SaleProduct,
        saleCategory: state.SaleCategory,
        saleStore: state.SaleStore,
        saleCurrency: state.SaleCurrency,
        salePriceType: state.SalePriceType,
        saleProductParam: state.SaleProductParam,
        contentPeerChannels: state.ContentPeerChannel,
        salePlan: state.SalePlan,
        saleIntegration: state.SaleIntegration
    }));

    let selectCategoryData=[{uuid:null, name:t('category:rootCategory')}];

    const [formValues, setFormValues] = useState<ProductType>(saleProduct.selectedItem);

    const checkIsAllFetched = () => {

        if (
            saleCategory.isFetched
            &&
            saleStore.isFetched
            &&
            saleCurrency.isFetched
            &&
            salePriceType.isFetched
            &&
            saleProductParam.isFetched
            &&
            contentPeerChannels.isFetched
            &&
            saleIntegration.isFetched
            &&
            (
                (
                    saleProduct.isFetched
                    &&
                    uuid === saleProduct?.selectedItem?.uuid
                )
                ||
                uuid === 'new'
            )
        ){
            setFetched(true);
        }
    }

    useEffect(() => {
        dispatch(getSaleCategories({maxResults:1000}));
    }, [dispatch, saleCategory.isNeedReload]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_PRODUCTS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    useEffect(() => {

        // if (!(saleProduct.isFetched
        //     &&
        //     uuid === saleProduct?.selectedItem?.uuid)){
        //
        // }
        dispatch(clearProductFetched());
        dispatch(getSaleCategories({maxResults:1000}));
        dispatch(getSaleStores({maxResults:1000}));
        dispatch(getSaleCurrencies({maxResults:1000}));
        dispatch(getSalePriceTypes({maxResults:1000}));
        dispatch(getSaleProductParams({maxResults:1000}));
        dispatch(getSaleIntegrations({maxResults: 1000}));
        dispatch(getContentPeerChannels());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (uuid !== 'new' && uuid !== null){

            const requestData = [];
            requestData['uuid']=uuid;

            const saleProductPayload: SaleProductPayload = {
                data: requestData,
                modalEditTitle: '!productEdit'
            }
            dispatch(getSaleEditProduct(saleProductPayload));

        } else {

            dispatch(editDataSaleProduct({
                data: Object(newProductItem),
                modalEditTitle: '!productCreate'
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCurrentFormValues = (product : ProductType) => {

        let productFormValues = product;

        (product.storeValues || []).map((storeValue:ProductStoreValue)=>{
            productFormValues['store_'+storeValue.uuid]=storeValue.value;
            return storeValue;
        });

        (product.productPrices || []).map((productPrice:ProductPriceType)=>{
            productFormValues['price_'+productPrice.priceType.uuid+'_currency_'+productPrice.currency.uuid]=productPrice.price;
            return productPrice;
        });

        (product.integrations || []).map((integration:ProductIntegrationType)=>{

            if (integration?.channel) {
                productFormValues['integration_priceType_' + integration.priceType.uuid + '_channel_' + integration.channel.uuid + '_enabled'] = integration.enabled;
                productFormValues['integration_priceType_' + integration.priceType.uuid + '_channel_' + integration.channel.uuid + '_currency'] = integration.currency;
            } else if (integration?.saleIntegration)  {
                productFormValues['integration_priceType_' + integration.priceType.uuid + '_channel_' + integration.saleIntegration.uuid + '_enabled'] = integration.enabled;
                productFormValues['integration_priceType_' + integration.priceType.uuid + '_channel_' + integration.saleIntegration.uuid + '_currency'] = integration.currency;
            }
            return integration;
        });

        if (productFormValues && productFormValues !== null && (Object.keys(productFormValues) || []).length>0) {
            setFormValues({...productFormValues});
        } else {
            setFormValues({...newProductItem});
        }
    }

    useEffect(() => {

        setCurrentFormValues({...product});

        let tempProductParamValues = [];

        (productParams || []).map((productParam:ProductParamType)=> {
            productParam.enabled
            &&
            (product.productParamValues !== undefined)
            &&
            (product.productParamValues !== null)
            &&
            (product.productParamValues || []).map((productParamValue: ProductParamValueType) => {
                if (productParamValue?.value && productParamValue.value !== null && productParamValue.productParam.uuid === productParam.uuid) {

                    if (productParam.type === ProductParamTypes.LIST){

                        let currentListValue = (productParam.values || []).filter((valueItem:ProductParamListValueType)=>{
                            return valueItem.uuid === productParamValue.value
                        }).shift();

                        if (currentListValue !== undefined && currentListValue?.value && currentListValue?.value !==null){
                            productParamValue.value = currentListValue?.value;
                        }
                    }

                    tempProductParamValues.push(productParamValue);
                }
                return productParamValue;
            });

            return productParam;
        })

        setProductParamValues([...tempProductParamValues]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    useEffect(() => {

        if (saleProduct?.selectedItem){
            setProduct({...saleProduct.selectedItem})
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProduct?.selectedItem?.uuid, saleProduct?.selectedItem?.eTag, saleProduct?.isFetched]);

    useEffect(() => {

        if (saleStore?.items && (saleStore?.items || []).length>0){
            setStores([...saleStore.items]);
        } else {
            setStores([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleStore?.items]);

    useEffect(() => {

        if (contentPeerChannels?.items && (contentPeerChannels?.items || []).length>0){
            setChannels([...contentPeerChannels.items]);
        } else {
            setChannels([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerChannels?.items]);

    useEffect(() => {

        if (saleIntegration?.items && (saleIntegration?.items || []).length>0){
            setSaleIntegrations([...saleIntegration.items]);
        } else {
            setSaleIntegrations([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleIntegration?.items]);

    useEffect(() => {

        if (saleCurrency?.items && (saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleCurrency?.items]);

    useEffect(() => {

        if (salePriceType?.items && (salePriceType?.items || []).length>0){
            setPriceTypes([...salePriceType.items]);
        } else {
            setPriceTypes([]);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePriceType?.items]);

    useEffect(() => {

        if (saleProductParam?.items && (saleProductParam?.items || []).length>0){
            setProductParams([...saleProductParam.items]);
        } else {
            setProductParams([]);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProductParam?.items]);

    const makeSelectCategoryData = (tree: any, depthLevel : number) => {
        (tree || []).map((item)=>{
            makeSelectCategoryItem(selectCategoryData, item, depthLevel);
            return item;
        });

        setCategoryTree([...selectCategoryData]);
    }

    useEffect(()=>{
        if (saleCategory?.items && (saleCategory.items || []).length>0) {
            let tree = prepareSelectCategories(saleCategory.items);
            makeSelectCategoryData(tree,0);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[saleCategory?.items]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const onDataSubmit = (formData:any) => {

        let attachmentFiles=[];

        if (formData['privacyStatus'] && formData['privacyStatus']['value']){
            formData['privacyStatus']=formData['privacyStatus']['value'];
        }
        if (formData['status'] && formData['status']['value']){
            formData['status']=formData['status']['value'];
        }
        if (formData['type'] && formData['type']['value']){
            formData['type']=formData['type']['value'];
        }

        let params: ProductType = {
            uuid: formData.uuid && formData.uuid,
            enabled: formData.enabled ? formData.enabled : false,
            name: formData.name && formData.name,
            order: formData.order && formData.order,
            idRemote: formData.idRemote && formData.idRemote,
            detailText: formData.detailText && formData.detailText,
            description: formData.description && formData.description,
            media: formData.media && formData.media,
            article: formData.article && formData.article,
            barCode: formData.barCode && formData.barCode,
            status: formData.status && formData.status,
            privacyStatus: formData.privacyStatus && formData.privacyStatus,
            isHtml: formData.isHtml ? formData.isHtml : false,
            externalLink: formData.externalLink && formData.externalLink,
            type: formData.type && formData.type,
            width: formData.width && formData.width,
            height: formData.height && formData.height,
            depth: formData.depth && formData.depth,
            weight: formData.weight && formData.weight,
            storeValues: [],
            productPrices: []
        };

        let storeValues:Array<ProductStoreValue> = [];

        (stores || []).map((store:SaleStoreType)=>{
            if (formData['store_'+store.uuid] && formData['store_'+store.uuid]!==null){
                storeValues.push({uuid:store.uuid, value:formData['store_'+store.uuid]});
            }
            return store;
        })

        if (storeValues.length>0){
            params = {
                ...params,
                storeValues: storeValues
            }
        }

        let productPrices:Array<ProductPriceType> = [];

        (priceTypes || []).map((priceType:PriceTypeType)=>{
            (currencies || []).map((currency:CurrencyType)=>{
                if (formData['price_'+priceType.uuid+'_currency_'+currency.uuid] && formData['price_'+priceType.uuid+'_currency_'+currency.uuid]!==null) {
                    productPrices.push(
                            {
                                priceType: { uuid: priceType.uuid},
                                currency: { uuid: currency.uuid},
                                price: formData['price_'+priceType.uuid+'_currency_'+currency.uuid]
                            });
                }
                return currency;
            })
            return priceType;
        })

        if (productPrices.length>0){
            params = {
                ...params,
                productPrices: productPrices
            }
        }

        if (formData['category'] && formData['category'] !== null){
            if (formData['category']['value'] && formData['category']['value']!==null) {
                const category = (saleCategory.items || []).filter((item: SaleCategoryType) => {
                      return item.uuid === formData['category']['value']
                }).shift();

                if (category?.uuid && category.uuid !== null) {
                     params = {
                         ...params,
                         category: {
                             uuid: category.uuid,
                             name: category.name
                         }
                     };
                }
            } else if (formData['category']?.uuid && formData['category'].uuid.length>0) {
                params = {
                    ...params,
                    category: {
                        uuid: formData['category'].uuid,
                        name: formData['category'].name
                    }
                };
            } else {
                params = {
                    ...params,
                    category: {
                        uuid: null,
                        name: null
                    }
                };
            }
        }

        (formData.media|| []).map((fileItem:MainFileType)=>{
            if (fileItem.uuid === undefined){
                attachmentFiles.push(fileItem);
            }
            return fileItem;
        });

        if (attachmentFiles.length>0){
            params['attachmentFiles']=attachmentFiles;
        }

        dispatch(onSendDataSaleProduct(params));
    };

    const handlePropertiesEditClick = () => {
        dispatch(editSaleProductProperties({data: saleProduct.selectedItem?.productParamValues || [], modalEditTitle:'!#PropertiesEdit'}));
    }
    const handleIntegrationChannelClick = (priceType: PriceTypeType, channel : ContentPeerChannelType) => {

        const currentIntegration = (saleProduct.selectedItem.integrations || []).filter((integration: ProductIntegrationType)=>{
            return integration.priceType.uuid === priceType.uuid && integration?.channel?.uuid === channel.uuid;
        }).shift();

        if (currentIntegration?.uuid && currentIntegration?.uuid !== null){

            if (!currentIntegration.meta || currentIntegration.meta === null ){
                currentIntegration.meta = newMetaItem;
            }

            dispatch(editSaleProductIntegrationChannel({data: currentIntegration, modalEditTitle:'!#IntegrationEdit'}));
        } else {
            const newIntegrationItem: ProductIntegrationType = {
                priceType: priceType,
                channel: channel,
                enabled : true,
                currency: (currencies || []).filter((currency:CurrencyType)=>{return currency.isDefault}).shift(),
                meta: newMetaItem
            }

            dispatch(editSaleProductIntegrationChannel({data: Object(newIntegrationItem), modalEditTitle:'!#IntegrationAdd'}));
        }
    }

    const handleIntegrationSaleIntegrationClick = (priceType: PriceTypeType, saleIntegration: SaleIntegrationType) => {

        const currentIntegration = (saleProduct.selectedItem.integrations || []).filter((integration: ProductIntegrationType)=>{
            return integration.priceType.uuid === priceType.uuid && integration?.saleIntegration?.uuid === saleIntegration.uuid;
        }).shift();

        if (currentIntegration?.uuid && currentIntegration?.uuid !== null){

            if (!currentIntegration.meta || currentIntegration.meta === null ){
                currentIntegration.meta = newMetaItem;
            }

            dispatch(editSaleProductIntegrationChannel({data: currentIntegration, modalEditTitle:'!#IntegrationEdit'}));
        } else {
            const newIntegrationItem: ProductIntegrationType = {
                priceType: priceType,
                enabled : true,
                currency: saleIntegration.currency,
                saleIntegration: saleIntegration,
                meta: newMetaItem
            }

            dispatch(editSaleProductIntegrationChannel({data: Object(newIntegrationItem), modalEditTitle:'!#IntegrationAdd'}));
        }

    }

    useEffect(() => {

        if (saleProduct?.selectedItem?.uuid && saleProduct?.selectedItem?.uuid !== null && saleProduct.isNeedReload){

            const saleProductPayload: SaleProductPayload = {
                data: saleProduct.selectedItem,
                modalEditTitle: '!productEdit'
            }

            dispatch(getSaleEditProduct(saleProductPayload));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saleProduct.isNeedReload]);

    useEffect(() => {
        if (saleProductParam?.isNeedReload && saleProductParam?.isNeedReload){
            dispatch(getSaleProductParams({maxResults:1000}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saleProductParam?.isNeedReload]);

    const openRightModalProductCategory = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(editDataSaleCategory({data:event, modalEditTitle: 'changeCategory'}));
        } else {
            dispatch(showRightModalSaleCategory({data:[], modalEditTitle: 'newCategory'}));
        }
    };

    const openRightModalProductPropertiesTypes = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(editDataSaleProductParam({data:event, modalEditTitle: 'changeProperty'}));
        } else {
            dispatch(showRightModalSaleProductParam({data:[], modalEditTitle: 'newProperty'}));
        }
    };

    return (
        <>
            <BreadCrumb title={t('product:product')} pageTitle={t('menu:main')} />
            {isFetched ?
                <>
                    { upgradeFare ?
                        <>
                            <div className="d-flex flex-row flex-grow-1">
                                <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                    <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionProductsText')}
                                </div>
                            </div>
                            <UpgradeFarePage/>
                        </>
                        :
                        <>

                    <Formik
                        enableReinitialize={true}
                        initialValues={formValues}
                        validationSchema={SaleProductValidatorSchema}
                        onSubmit={(values, actions) => {
                            onDataSubmit(values);
                            actions.setSubmitting(false);
                        }}
                    >
                        {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {
                            return (
                                <Form noValidate={true} className="customValidation">
                                    <Row>
                                        <Col lg={8}>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col lg={6} className="mb-3">
                                                            <div className={touched.name ? " was-validated" : ''}>
                                                                <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                                                <Field
                                                                    id="form-name-input"
                                                                    name="name"
                                                                    placeholder={t('common:enterNameTitle')}
                                                                    component={InputText}
                                                                    value = {getFieldProps('name').value}
                                                                    onChange={(name:string) => {
                                                                        setFieldValue('name', name);
                                                                    }}
                                                                    className={errors.name && touched.name && 'form-control is-invalid'}
                                                                />
                                                                {errors.name && touched.name ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.name)}
                                                                    </div>
                                                                ) :  touched.name && !errors.name ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Row>
                                                                <Col lg={6} className="mb-3">
                                                                    <div className={touched.article ? " was-validated" : ''}>
                                                                        <Label className="form-label" htmlFor="form-article-input">{t('product:article')}</Label>
                                                                        <Field
                                                                            id="form-article-input"
                                                                            name="article"
                                                                            placeholder={t('product:enterArticle')}
                                                                            component={InputText}
                                                                            value = {getFieldProps('article').value}
                                                                            onChange={(value:string) => {
                                                                                setFieldValue('article', value);
                                                                            }}
                                                                            className={errors.article && touched.article && 'form-control is-invalid'}
                                                                        />
                                                                        {errors.article && touched.article ? (
                                                                            <div className="invalid-feedback">
                                                                                {t('validator:'+errors.article)}
                                                                            </div>
                                                                        ) :  touched.article && !errors.article ?
                                                                            <div className="valid-feedback">
                                                                                {t('common:looksGood')}
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6} className="mb-3">
                                                                    <div className={touched.order ? " was-validated" : ''}>
                                                                        <Label className="form-label" htmlFor="form-order-input">{t('common:order')}</Label>
                                                                        <Field
                                                                            id="form-order-input"
                                                                            name="order"
                                                                            placeholder={t('common:enterOrder')}
                                                                            component={InputText}
                                                                            value = {getFieldProps('order').value}
                                                                            onChange={(value:string) => {
                                                                                setFieldValue('order', value);
                                                                            }}
                                                                            className={errors.order && touched.order && 'form-control is-invalid'}
                                                                        />
                                                                        {errors.order && touched.order ? (
                                                                            <div className="invalid-feedback">
                                                                                {t('validator:'+errors.order)}
                                                                            </div>
                                                                        ) :  touched.order && !errors.order ?
                                                                            <div className="valid-feedback">
                                                                                {t('common:looksGood')}
                                                                            </div>
                                                                            : ''
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg={4} className="mb-3">
                                                            <Label className="form-label" htmlFor="form-description-input">{t('common:shortDescription')}</Label>

                                                            <Field
                                                                id="form-description-input"
                                                                name="description"
                                                                component={InputEmojiArea}
                                                                value = {getFieldProps('description').value}
                                                                onChange={(text:string) => {
                                                                    setFieldValue('description', text);
                                                                }}
                                                                isShowEmoji={true}
                                                                isShowAITextGenerate={true}
                                                                required={false}
                                                                placeholder={t('common:enterShortDescription')}
                                                            />
                                                        </Col>
                                                        <Col lg={8} className="mb-3">
                                                            <div>
                                                                <div className="d-flex flex-row">
                                                                    <div className="d-flex flex-grow-1">
                                                                        <Label className="form-label" htmlFor="form-detailText-input">{t('common:fullText')}</Label>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="mb-2 d-flex flex-column align-items-center">
                                                                            <Field
                                                                                id="form-isHtml-input"
                                                                                placeholder={t('common:Html')}
                                                                                name="isHtml"
                                                                                component={InputSwitch}
                                                                                value = {getFieldProps('isHtml').value}
                                                                                onChange={(enabled:boolean) => {
                                                                                    setFieldValue('isHtml', !getFieldProps('isHtml').value);
                                                                                }}
                                                                                className="sm"
                                                                                required={false}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="textAreaEmojiBlock">
                                                                    <Field
                                                                        id="form-detailText-input"
                                                                        name="detailText"
                                                                        component={InputEditorSwitch}
                                                                        value = {getFieldProps('detailText').value}
                                                                        onChange={(text:string) => {
                                                                            setFieldValue('detailText', text);
                                                                        }}
                                                                        isShowEmoji={true}
                                                                        isShowAITextGenerate={true}
                                                                        required={false}
                                                                        placeholder={t('common:enterFullText')}
                                                                        isHtml = {getFieldProps('isHtml').value}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>


                                                    </Row>

                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader>
                                                    <h5 className="card-title mb-0">{t('common:photoGallery')}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Field
                                                        id={"form-attachmentFiles-input"}
                                                        name={'media'}
                                                        placeholder={t('product:productPhotos')}
                                                        isMultiple={true}
                                                        component={InputFile}
                                                        value={getFieldProps('media').value}
                                                        onChange={(value: MainFileType[]) => {
                                                            setFieldValue('media', value);
                                                        }}
                                                        required={false}
                                                        acceptedFiles=".jpg, .jpeg, .png"
                                                    />
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardHeader>
                                                    <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: activeTab === priceTab,
                                                                })}
                                                                onClick={() => {
                                                                    toggleTab(priceTab);
                                                                }}
                                                            >
                                                                {t('price:prices')}
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: activeTab === storeTab,
                                                                })}
                                                                onClick={() => {
                                                                    toggleTab(storeTab);
                                                                }}
                                                            >
                                                                {t('store:stores')}
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: activeTab === propertiesTab,
                                                                })}
                                                                onClick={() => {
                                                                    toggleTab(propertiesTab);
                                                                }}
                                                            >
                                                                {t('product:productProperties')}
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: activeTab === integrationTab,
                                                                })}
                                                                onClick={() => {
                                                                    toggleTab(integrationTab);
                                                                }}
                                                            >
                                                                {t('integration:integrations')}
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </CardHeader>

                                                <CardBody>
                                                    <TabContent activeTab={activeTab}>
                                                        <TabPane id="product-price-info" tabId={priceTab}>
                                                            {
                                                                (priceTypes || []).map((priceType:PriceTypeType)=>(
                                                                    priceType.enabled &&
                                                                    <div key={'productPriceCont_'+priceType.uuid}>
                                                                        <div className="mb-1">
                                                                            <PriceTypeItemLine item={priceType}/>
                                                                        </div>
                                                                        <Row>
                                                                            {(currencies || []).map((currency:CurrencyType)=>(
                                                                                currency.enabled &&
                                                                                <Col md={3} key={'productPriceContItem_'+priceType.uuid+'_currency_'+currency.uuid} className={ "mb-3"+ (touched["price_"+priceType.uuid+'_currency_'+currency.uuid] ? " was-validated" : '')}>
                                                                                    <Field
                                                                                        id={"form-price_"+priceType.uuid+'_currency_'+currency.uuid}
                                                                                        name={"price_"+priceType.uuid+'_currency_'+currency.uuid}
                                                                                        placeholder={t('price:enterPrice')}
                                                                                        component={InputTextAddon}
                                                                                        value = {getFieldProps("price_"+priceType.uuid+'_currency_'+currency.uuid).value}
                                                                                        onChange={(value:string) => {
                                                                                            setFieldValue("price_"+priceType.uuid+'_currency_'+currency.uuid, value);
                                                                                        }}
                                                                                        className={"text-end "+ (errors["price_"+priceType.uuid+'_currency_'+currency.uuid] && touched["price_"+priceType.uuid+'_currency_'+currency.uuid] ? 'form-control is-invalid' : '')}
                                                                                        addon={currency.currencyFormat.replace(CURRENCY_SYMBOL_EXTRACT_REGEX,'')}
                                                                                    />
                                                                                </Col>
                                                                            ))}
                                                                        </Row>
                                                                    </div>
                                                                ))
                                                            }
                                                        </TabPane>
                                                        <TabPane id="product-store-info" tabId={storeTab}>
                                                            {
                                                                (stores || []).map((store:SaleStoreType)=>(
                                                                    store.enabled &&
                                                                    <Row key={'productStoreCont_' + store.uuid} className="mb-3">
                                                                        <Col md={8} className="d-flex justify-content-start">
                                                                            <StoreItemLine item={store}/>
                                                                        </Col>
                                                                        <Col md={4} className={(touched['store_' + store.uuid] ? " was-validated" : '')}>
                                                                            <Field
                                                                                id={"form-store-input_" + store.uuid}
                                                                                name={"store_" + store.uuid}
                                                                                placeholder={t('store:enterBalance')}
                                                                                component={InputText}
                                                                                value={getFieldProps('store_' + store.uuid).value}
                                                                                onChange={(value: string) => {
                                                                                    setFieldValue('store_' + store.uuid, value);
                                                                                }}
                                                                                className={"text-end " + (errors['store_' + store.uuid] && touched['store_' + store.uuid] ? 'form-control is-invalid' : '')}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </TabPane>
                                                        <TabPane id="product-properties-info" tabId={propertiesTab}>
                                                            {
                                                                (!product?.uuid || product.uuid === null) ?
                                                                    <div className="d-flex flex-row flex-grow-1">
                                                                        <div className="alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{" "}{t('product:createProductFirst')}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className="mb-3">
                                                                            <Link className="btn btn-primary btn-sm" to="#" onClick={()=>{handlePropertiesEditClick()}}>
                                                                                <i className="ri-edit-line align-bottom me-1"/> {t('common:edit')}
                                                                            </Link>
                                                                        </div>
                                                                        {productParamValues && (productParamValues || []).length>0 &&
                                                                            <div className="table-responsive">
                                                                                <table className="table mb-0">
                                                                                    <tbody>
                                                                                    {productParamValues.map((item:ProductParamValueType, key:number)=>{
                                                                                        return (
                                                                                            <tr key={"productParamValue_"+item.uuid}>
                                                                                                <th scope="row" style={{ width: "200px" }} className={key===productParamValues.length-1 ? "border-bottom-0": ''}>
                                                                                                    {item.productParam.name}
                                                                                                </th>
                                                                                                <td className={key===productParamValues.length-1 ? "border-bottom-0": ''}>
                                                                                                    {
                                                                                                        (
                                                                                                            item.productParam.type === ProductParamTypes.STRING
                                                                                                            ||
                                                                                                            item.productParam.type === ProductParamTypes.LIST
                                                                                                        ) &&
                                                                                                        <span className="text-center">{item.value}</span>
                                                                                                    }
                                                                                                    {item.productParam.type === ProductParamTypes.NUMBER &&
                                                                                                        <span className="text-end">{item.value}</span>
                                                                                                    }
                                                                                                    {item.productParam.type === ProductParamTypes.BOOLEAN &&
                                                                                                        <>
                                                                                                        {item.value ?
                                                                                                                <i className="ri-checkbox-circle-fill text-success"/>
                                                                                                                :
                                                                                                                <i className="ri-indeterminate-circle-line text-primary"/>
                                                                                                        }
                                                                                                        </>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        }
                                                                    </>}
                                                        </TabPane>
                                                        <TabPane id="product-integration-info" tabId={integrationTab}>
                                                            {
                                                                (!product?.uuid || product.uuid === null) ?
                                                                    <div className="d-flex flex-row flex-grow-1">
                                                                        <div className="alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{" "}{t('product:createProductFirst')}
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {
                                                                            (priceTypes || []).map((priceType:PriceTypeType)=>(
                                                                                    priceType.enabled &&
                                                                                    <div key={'integrationPriceCont_'+priceType.uuid} className="mb-3">
                                                                                        <div className="mb-1">
                                                                                            <PriceTypeItemLine item={priceType}/>
                                                                                        </div>
                                                                                        <div className="d-flex flex-row gap-3">
                                                                                            {(channels || []).map((channel:ContentPeerChannelType)=>(
                                                                                                channel.connector.handler.canECommerce && channel.isManaged &&
                                                                                                <ChannelItemProductIntegration
                                                                                                    item={channel}
                                                                                                    isEnabled={
                                                                                                        getFieldProps('integration_priceType_'+priceType.uuid+'_channel_'+channel.uuid+'_enabled').value || false
                                                                                                    }
                                                                                                    currency={getFieldProps('integration_priceType_'+priceType.uuid+'_channel_'+channel.uuid+'_currency').value || null}
                                                                                                    onChange={()=>{handleIntegrationChannelClick(priceType, channel)}}
                                                                                                    key={'integrationPriceCont_'+priceType.uuid+'_channel_'+channel.uuid}
                                                                                                />
                                                                                            ))
                                                                                            }
                                                                                            {(saleIntegrations || []).map((saleIntegration:SaleIntegrationType)=>(
                                                                                                (saleIntegration.handler.type === SaleIntegrationHandlerTypes.OUTBOUND &&
                                                                                                    (
                                                                                                        saleIntegration.handler.code === SaleIntegrationHandlerCodes.WIDGET_WEB
                                                                                                        ||
                                                                                                        saleIntegration.handler.code === SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                                                                                    )) &&
                                                                                                <SaleIntegrationItemProductIntegration
                                                                                                    item={saleIntegration}
                                                                                                    isEnabled={
                                                                                                        getFieldProps('integration_priceType_'+priceType.uuid+'_channel_'+saleIntegration.uuid+'_enabled').value || false
                                                                                                    }
                                                                                                    currency={
                                                                                                        getFieldProps('integration_priceType_'+priceType.uuid+'_channel_'+saleIntegration.uuid+'_currency').value || null
                                                                                                    }
                                                                                                    onChange={()=>{handleIntegrationSaleIntegrationClick(priceType, saleIntegration)}}
                                                                                                    key={'integrationPriceCont_'+priceType.uuid+'_channel_'+saleIntegration.uuid}
                                                                                                />
                                                                                            ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        }
                                                                    </>
                                                            }
                                                        </TabPane>
                                                    </TabContent>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={4}>
                                            <Card>
                                                <CardBody>
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column align-items-center me-5 justify-content-center">
                                                            <Field
                                                                id="form-enabled-input"
                                                                placeholder={t('common:active')}
                                                                name="enabled"
                                                                component={InputSwitch}
                                                                value = {getFieldProps('enabled').value}
                                                                onChange={(enabled:boolean) => {
                                                                    setFieldValue('enabled', !getFieldProps('enabled').value);
                                                                }}
                                                                required={false}
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-row flex-grow-1">
                                                            <Field
                                                                id="choices-type-input"
                                                                name={"type"}
                                                                placeholder={t('product:chooseProductType')}
                                                                component={InputSelect}
                                                                value={getFieldProps('type').value}
                                                                onChange={(value: any) => {
                                                                    setFieldValue("type",value);
                                                                }}
                                                                items={productTypeStatusValuesLang || []}
                                                                className={"flex-grow-1 "+ (errors.type && touched.type ? 'form-control is-invalid' : '')}
                                                            />
                                                        </div>

                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader>
                                                    <h5 className="card-title mb-0">{t('product:publishSettings')}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="choices-publish-status-input"
                                                            className="form-label">
                                                            {t('product:publishStatus')}
                                                        </Label>
                                                        <Field
                                                            id="choices-publish-status-input"
                                                            name={"status"}
                                                            placeholder={t('product:choosePublishStatus')}
                                                            component={InputSelect}
                                                            value={getFieldProps('status').value}
                                                            onChange={(value: any) => {
                                                                setFieldValue("status",value);
                                                            }}
                                                            items={productPublishStatusValuesLang || []}
                                                            className={"z-index-auto "+ (errors.status && touched.status && 'form-control is-invalid')}
                                                        />

                                                    </div>
                                                    <div>
                                                        <Label
                                                            htmlFor="choices-privacy-status-input"
                                                            className="form-label">
                                                            {t('product:privacyStatus')}
                                                        </Label>
                                                        <Field
                                                            id="choices-privacy-status-input"
                                                            name={"privacyStatus"}
                                                            placeholder={t('product:choosePrivacyStatus')}
                                                            component={InputSelect}
                                                            value={getFieldProps('privacyStatus').value}
                                                            onChange={(value: any) => {
                                                                setFieldValue("privacyStatus",value);
                                                            }}
                                                            items={productPrivacyStatusValuesLang || []}
                                                            className={"z-index-auto "+ (errors.privacyStatus && touched.privacyStatus && 'form-control is-invalid')}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader>
                                                    <div className="d-flex flex-row flex-nowrap align-items-center">
                                                        <h5 className="card-title mb-0 flex-grow-1 d-flex">
                                                            {t('category:category')}
                                                        </h5>
                                                        <div className="d-flex flex-row flex-grow-0">
                                                            <a type="button" target="_blank" rel="noreferrer"
                                                               onClick={openRightModalProductCategory}
                                                               className="btn btn-sm btn-outline-primary btn-icon waves-effect waves-light"><i className="ri-add-line"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <Field
                                                        id="choices-category-input"
                                                        name={"category"}
                                                        placeholder={t('category:chooseCategory')}
                                                        component={InputSelect}
                                                        value={getFieldProps('category').value}
                                                        onChange={(value: any) => {
                                                            setFieldValue("category",value);
                                                        }}
                                                        items={categoryTree || []}
                                                        className={errors.category && touched.category && 'form-control is-invalid'}
                                                    />

                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader>
                                                    <h5 className="card-title mb-0">{t('common:externalProperties')}</h5>
                                                </CardHeader>
                                                <CardBody>

                                                    <div className="mb-3">
                                                        <div className={touched.idRemote ? " was-validated" : ''}>
                                                            <Label className="form-label" htmlFor="form-idRemote-input">{t('common:idRemote')}</Label>
                                                            <Field
                                                                id="form-idRemote-input"
                                                                name="idRemote"
                                                                placeholder={t('common:enterIdRemote')}
                                                                component={InputText}
                                                                value = {getFieldProps('idRemote').value}
                                                                onChange={(value:string) => {
                                                                    setFieldValue('idRemote', value);
                                                                }}
                                                                className={errors.idRemote && touched.idRemote && 'form-control is-invalid'}
                                                            />
                                                            {errors.idRemote && touched.idRemote ? (
                                                                <div className="invalid-feedback">
                                                                    {t('validator:'+errors.idRemote)}
                                                                </div>
                                                            ) :  touched.idRemote && !errors.idRemote ?
                                                                <div className="valid-feedback">
                                                                    {t('common:looksGood')}
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={touched.externalLink ? " was-validated" : ''}>
                                                            <Label className="form-label" htmlFor="form-externalLink-input">{t('common:externalLink')}</Label>
                                                            <Field
                                                                id="form-externalLink-input"
                                                                name="externalLink"
                                                                placeholder={t('common:enterExternalLink')}
                                                                component={InputText}
                                                                value = {getFieldProps('externalLink').value}
                                                                onChange={(value:string) => {
                                                                    setFieldValue('externalLink', value);
                                                                }}
                                                                className={errors.externalLink && touched.externalLink && 'form-control is-invalid'}
                                                            />
                                                            {errors.externalLink && touched.externalLink ? (
                                                                <div className="invalid-feedback">
                                                                    {t('validator:'+errors.externalLink)}
                                                                </div>
                                                            ) :  touched.externalLink && !errors.externalLink ?
                                                                <div className="valid-feedback">
                                                                    {t('common:looksGood')}
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardHeader>
                                                    <h5 className="card-title mb-0">
                                                        {t('common:dimensionsTitle')}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col md={6} className={"mb-3"}>
                                                            <div className={touched.width ? " was-validated" : ''}>
                                                                <Field
                                                                    id="form-width-input"
                                                                    name="width"
                                                                    placeholder={t('common:enterWidth')}
                                                                    component={InputTextAddon}
                                                                    value = {getFieldProps('width').value}
                                                                    onChange={(value:string) => {
                                                                        setFieldValue('width', value);
                                                                    }}
                                                                    className={errors.width && touched.width && 'form-control is-invalid'}
                                                                    addon={<i className={"mdi mdi-axis-x-arrow"}/> }
                                                                />
                                                                {errors.width && touched.width ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.width)}
                                                                    </div>
                                                                ) :  touched.width && !errors.width ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className={"mb-3"}>
                                                            <div className={touched.height ? " was-validated" : ''}>
                                                                <Field
                                                                    id="form-height-input"
                                                                    name="height"
                                                                    placeholder={t('common:enterHeight')}
                                                                    component={InputTextAddon}
                                                                    value = {getFieldProps('height').value}
                                                                    onChange={(value:string) => {
                                                                        setFieldValue('height', value);
                                                                    }}
                                                                    className={errors.height && touched.height && 'form-control is-invalid'}
                                                                    addon={<i className={"mdi mdi-axis-z-arrow"}/> }
                                                                />
                                                                {errors.height && touched.height ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.height)}
                                                                    </div>
                                                                ) :  touched.height && !errors.height ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6} className={"mb-3"}>
                                                            <div className={touched.depth ? " was-validated" : ''}>
                                                                <Field
                                                                    id="form-depth-input"
                                                                    name="depth"
                                                                    placeholder={t('common:enterDepth')}
                                                                    component={InputTextAddon}
                                                                    value = {getFieldProps('depth').value}
                                                                    onChange={(value:string) => {
                                                                        setFieldValue('depth', value);
                                                                    }}
                                                                    className={errors.depth && touched.depth && 'form-control is-invalid'}
                                                                    addon={<i className={"mdi mdi-axis-y-arrow"}/> }
                                                                />
                                                                {errors.depth && touched.depth ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.depth)}
                                                                    </div>
                                                                ) :  touched.depth && !errors.depth ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className={"mb-3"}>
                                                            <div className={touched.weight ? " was-validated" : ''}>
                                                                <Field
                                                                    id="form-weight-input"
                                                                    name="weight"
                                                                    placeholder={t('common:enterWeight')}
                                                                    component={InputTextAddon}
                                                                    value = {getFieldProps('weight').value}
                                                                    onChange={(value:string) => {
                                                                        setFieldValue('weight', value);
                                                                    }}
                                                                    className={errors.weight && touched.weight && 'form-control is-invalid'}
                                                                    addon={<i className={"mdi mdi-weight-gram"}/>}
                                                                />
                                                                {errors.weight && touched.weight ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.weight)}
                                                                    </div>
                                                                ) :  touched.weight && !errors.weight ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </CardBody>
                                            </Card>

                                            <Card>
                                                <CardHeader>
                                                    <h5 className="card-title mb-0"><i className="ri-barcode-fill align-bottom text-muted me-2"/>{t('product:barCode')}</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className={touched.barCode ? " was-validated" : ''}>
                                                        <Field
                                                            id="form-barCode-input"
                                                            name="barCode"
                                                            placeholder={t('product:enterBarCode')}
                                                            component={InputText}
                                                            value = {getFieldProps('barCode').value}
                                                            onChange={(value:string) => {
                                                                setFieldValue('barCode', value);
                                                            }}
                                                            className={errors.barCode && touched.barCode && 'form-control is-invalid'}
                                                        />
                                                        {errors.barCode && touched.barCode ? (
                                                            <div className="invalid-feedback">
                                                                {t('validator:'+errors.barCode)}
                                                            </div>
                                                        ) :  touched.barCode && !errors.barCode ?
                                                            <div className="valid-feedback">
                                                                {t('common:looksGood')}
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>


                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col md={8}>
                                            <div className="d-flex align-items-start gap-3">
                                                <Link
                                                    to={route.SALE_PRODUCTS_LIST}
                                                    className={"btn btn-primary waves-effect waves-light btn-label"}>
                                                    <i className={"ri-arrow-left-line align-middle fs-16 me-2 label-icon"}/>
                                                    {t('product:backToProducts')}
                                                </Link>

                                                <button type="submit" className="btn btn-success btn-label left ms-auto">
                                                    <i className="ri-check-line label-icon align-middle fs-16 me-2"/>
                                                    {t('common:save')}
                                                </button>
                                            </div>
                                        </Col>
                                        <Col md={4}>

                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>
                    <RightModal/>
                    <RightModalProductPropertiesTypes/>
                    <RightModalProductProperties/>
                    <RightModalProductIntegrationChannel/>
                            </>
                    }
                </>
                :
                <>
                    <Loader/>
                </>
            }
        </>
    );
};

export default ProductDetailEditIndex;


