import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSaleCategories = (data:MainMetaType) => {
    const query = createPaginationQuery(data)
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_CATEGORIES+"?"+query);
};

const sendDataSaleCategory = (data: any) => {

    if (data.attachmentFiles !== undefined){
        return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_CATEGORY, data);
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_CATEGORY, data);
};

const removeSaleCategory = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_CATEGORY, data);
};

export {
    getSaleCategories,
    sendDataSaleCategory,
    removeSaleCategory
};
