import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row, Form } from 'reactstrap';
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {MainDeviceType} from "../../redux/Main/Device/types";
import LeftbarTitle from "../LeftbarTitle";
import {useRedux} from "../../hooks";
import ButtonSpinner from "../ButtonSpinner";
import {LordIcon} from "../LordIcon";

interface ItemProps {
    onUpdate: (data: any, dispatch: any) => void;
    handleClose: (dispatch: any) => void;
    item: MainDeviceType;
    isOpen: boolean;
    modalTitle: string;
    isSending: boolean;
}

const ApproveDeviceModal = ({ onUpdate, item, handleClose, isOpen, modalTitle, isSending }: ItemProps) => {

    const { t, i18n } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();



    const onDataSubmit = (e:any) => {
        e.preventDefault();
        onUpdate(item, dispatch);
    };

    return (
        <>
            <Modal
                centered = {true}
                show={isOpen}
                onHide={()=>handleClose(dispatch)}>
                    <Form noValidate={true} onSubmit={onDataSubmit}>
                        <Modal.Header closeButton>
                            <LeftbarTitle title={t('device:newDeviceApproval')}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <div className="mt-2 text-center">
                                    <LordIcon
                                        src="https://cdn.lordicon.com/xirobkro.json"
                                        trigger="loop"
                                        colors={{primary: '#0ab39c',secondary:'#32b67d'}}
                                        size={120}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="mt-4">
                                    <p className="text-muted mb-2">{t('device:newDeviceApprovalText')}</p>
                                        <table className="table table-borderless mb-3">
                                            <tbody>
                                            <tr>
                                                <td className="fw-semibold text-nowrap">{t('common:platform')}</td>
                                                <td>{item.fingerPrintData.platform}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-semibold text-nowrap">{t('common:timeZone')}</td>
                                                <td>{item.fingerPrintData.timezone}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-semibold text-nowrap">{t('common:vendor')}</td>
                                                <td>{item.fingerPrintData.vendor}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-semibold text-nowrap">{t('common:userAgent')}</td>
                                                <td>{item.fingerPrintData.userAgent}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    <div className="hstack gap-2 justify-content-center">

                                        <Button variant="primary"
                                                type="button"
                                                onClick={()=>handleClose(dispatch)}
                                                className="btn btn-label btn-light waves-effect waves-light"
                                        ><i
                                            className="ri-close-line align-bottom label-icon"/>
                                            {t('common:cancel')}
                                        </Button>

                                        {/*<a className="btn btn-link link-success fw-medium"*/}
                                        {/*   data-bs-dismiss="modal" onClick={()=>handleClose(dispatch)}><i*/}
                                        {/*    className="ri-close-line me-1 align-middle"></i> {t('common:cancel')}</a>*/}

                                        <Button variant="primary"
                                                type="submit"
                                                disabled={isSending}
                                                className="btn btn-success btn-label waves-effect waves-light"
                                        ><i
                                            className="ri-check-double-line align-bottom label-icon"/>
                                            {t('common:approve')}
                                            {isSending &&
                                                <ButtonSpinner/>
                                            }
                                        </Button>

                                    </div>
                                </div>
                            </Row>





                        </Modal.Body>
                    </Form>
            </Modal>
        </>
    );
}

export default ApproveDeviceModal;