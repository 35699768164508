import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleOrderCustomerActionTypes } from "./types";
import { saleOrderCustomerApiResponseSuccess, saleOrderCustomerApiResponseError } from "./actions";

import {
  sendDataSaleOrderCustomer as sendDataSaleOrderCustomerApi,
  removeSaleOrderCustomer as removeSaleOrderCustomerApi,
  searchSaleCustomerFromRemote as searchSaleCustomerFromRemoteApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* onSendDataSaleOrderCustomer({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleOrderCustomerApi, data);
    yield put(
        saleOrderCustomerApiResponseSuccess(SaleOrderCustomerActionTypes.UPDATE_SALE_ORDER_CUSTOMER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderCustomerApiResponseError(SaleOrderCustomerActionTypes.UPDATE_SALE_ORDER_CUSTOMER, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleOrderCustomer({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleOrderCustomerApi, {uuid: data.data.uuid});
    yield put(
        saleOrderCustomerApiResponseSuccess(SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderCustomerApiResponseError(SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER, error));
    yield call(showErrorNotification, error);
  }
}

function* onSearchSaleOrderCustomer({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(searchSaleCustomerFromRemoteApi, data);
    yield put(
        saleOrderCustomerApiResponseSuccess(SaleOrderCustomerActionTypes.SEARCH_SALE_CUSTOMERS, response)
    );
  } catch (error: any) {
    yield put(saleOrderCustomerApiResponseError(SaleOrderCustomerActionTypes.SEARCH_SALE_CUSTOMERS, error));
  }
}

export function* watchOnSendDataSaleOrderCustomer() {
  yield takeEvery(SaleOrderCustomerActionTypes.UPDATE_SALE_ORDER_CUSTOMER, onSendDataSaleOrderCustomer);
}
export function* watchOnRemoveSaleOrderCustomer() {
  yield takeEvery(SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER, onRemoveSaleOrderCustomer);
}

export function* watchOnSearchSaleOrderCustomer() {
  yield takeEvery(SaleOrderCustomerActionTypes.SEARCH_SALE_CUSTOMERS, onSearchSaleOrderCustomer);
}

function* saleOrderCustomerSaga() {
  yield all([
      fork(watchOnSendDataSaleOrderCustomer),
      fork(watchOnRemoveSaleOrderCustomer),
      fork(watchOnSearchSaleOrderCustomer)
  ]);
}

export default saleOrderCustomerSaga;
