import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleOrderDeliveryItemActionTypes } from "./types";
import { saleOrderDeliveryItemApiResponseSuccess, saleOrderDeliveryItemApiResponseError } from "./actions";

import {
  sendDataSaleOrderDeliveryItem as sendDataSaleOrderDeliveryItemApi,
  removeSaleOrderDeliveryItem as removeSaleOrderDeliveryItemApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* onSendDataSaleOrderDeliveryItem({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleOrderDeliveryItemApi, data);
    yield put(
        saleOrderDeliveryItemApiResponseSuccess(SaleOrderDeliveryItemActionTypes.UPDATE_SALE_ORDER_DELIVERY_ITEM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderDeliveryItemApiResponseError(SaleOrderDeliveryItemActionTypes.UPDATE_SALE_ORDER_DELIVERY_ITEM, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleOrderDeliveryItem({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleOrderDeliveryItemApi, {uuid: data.data.uuid});
    yield put(
        saleOrderDeliveryItemApiResponseSuccess(SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderDeliveryItemApiResponseError(SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchOnSendDataSaleOrderDeliveryItem() {
  yield takeEvery(SaleOrderDeliveryItemActionTypes.UPDATE_SALE_ORDER_DELIVERY_ITEM, onSendDataSaleOrderDeliveryItem);
}
export function* watchOnRemoveSaleOrderDeliveryItem() {
  yield takeEvery(SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM, onRemoveSaleOrderDeliveryItem);
}

function* saleOrderDeliveryItemSaga() {
  yield all([
    fork(watchOnSendDataSaleOrderDeliveryItem),
    fork(watchOnRemoveSaleOrderDeliveryItem)
  ]);
}

export default saleOrderDeliveryItemSaga;
