import {ContentQuickAnswerActionTypes, ContentQuickAnswerPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const contentQuickAnswerApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentQuickAnswerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentQuickAnswerApiResponseError = (actionType: string, error: string) => ({
  type: ContentQuickAnswerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentQuickAnswers = (data: MainMetaType) => ({
  type: ContentQuickAnswerActionTypes.GET_CONTENT_QUICK_ANSWER,
  payload: data
});

export const showRightModalContentQuickAnswer= (data: ContentQuickAnswerPayload) => ({
  type: ContentQuickAnswerActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalContentQuickAnswer= () => ({
  type: ContentQuickAnswerActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataContentQuickAnswer = (data: any) => ({
  type: ContentQuickAnswerActionTypes.UPDATE_CONTENT_QUICK_ANSWER,
  payload: data,
});

export const editDataContentQuickAnswer = (data: ContentQuickAnswerPayload) => ({
  type: ContentQuickAnswerActionTypes.EDIT_CONTENT_QUICK_ANSWER,
  payload: data,
});

export const removeContentQuickAnswerModalShow = (data: ContentQuickAnswerPayload) => ({
  type: ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER_MODAL_SHOW,
  payload: data,
});

export const removeContentQuickAnswerModalHide = () => ({
  type: ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER_MODAL_HIDE,
});

export const onRemoveContentQuickAnswer = (data: ContentQuickAnswerPayload) => ({
  type: ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER,
  payload: data,
});