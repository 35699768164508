import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import {useRedux} from "../../../hooks";

import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import {getContentPeerTargetTypes} from "../../../redux/Content/PeerTargetType/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getContentProjects} from "../../../redux/Content/Project/actions";
import {getContentConnectors} from "../../../redux/Content/Connector/actions";
import ComparePeerChannelItem from "./ComparePeerChannelItem";
import ComparePeerChannelItemChart from "./ComparePeerChannelItemChart";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {isNumeric} from "../../../helpers/functions";
import UpgradeFarePage from "../../../components/UpgradeFarePage";


const ComparePeerChannelIndex = () => {

    const { t } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);

    const {
        salePlan,
        isPlanFetched
    } = useAppSelector(state => ({
        salePlan: state.SalePlan,
        isPlanFetched: state.SalePlan.isFetched
    }));

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.STAT_COMPARE_COMPETITORS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }


    useEffect(() => {
        dispatch(getContentProjects());
        dispatch(getContentConnectors());
        dispatch(getContentPeerChannels());
        dispatch(getContentPeerTargetTypes());
    },[dispatch]);

    return (
        <>
            <BreadCrumb title={t('menu:compareChannels')} pageTitle={t('menu:main')} />

            {isPlanFetched &&
                <>
                { upgradeFare ?
                    <>
                        <UpgradeFarePage/>
                    </>
                    :
                <>
                    <Row className="mb-0 g-3">
                        <div className="col-sm-auto border-end">
                           <ComparePeerChannelItem index={0}/>
                        </div>
                        <div className="col-sm-auto border-end">
                            <ComparePeerChannelItem index={1}/>
                        </div>
                        <div className="col-sm-auto border-end">
                            <ComparePeerChannelItem index={2}/>
                        </div>
                        <div className="col-sm-auto">
                            <ComparePeerChannelItem index={3}/>
                        </div>
                    </Row>
                    <Row className="mb-0 g-3">
                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">{t('compare:competitiveAnalysisCharts')}</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <ComparePeerChannelItemChart index={0} group={'channelActivities'} id={'channelActivitiesChart_'+0}/>
                                    <ComparePeerChannelItemChart index={1} group={'channelActivities'} id={'channelActivitiesChart_'+1}/>
                                    <ComparePeerChannelItemChart index={2} group={'channelActivities'} id={'channelActivitiesChart_'+2}/>
                                    <ComparePeerChannelItemChart index={3} group={'channelActivities'} id={'channelActivitiesChart_'+3}/>
                                </div>
                            </CardBody>
                        </Card>
                    </Row>
                </>
                }
                </>
            }
        </>
    );
};

export default ComparePeerChannelIndex;


