import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentProjectsActionTypes } from "./types";
import { contentProjectsApiResponseSuccess, contentProjectsApiResponseError } from "./actions";

import {
  getContentProjects as getContentProjectsApi,
  sendDataContentProjects,
    removeContentProject as removeContentProjectApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {contentPeerTargetApiResponseError, contentPeerTargetApiResponseSuccess} from "../PeerTarget/actions";
import {ContentPeerTargetActionTypes} from "../PeerTarget/types";


function* getContentProjects() {
  try {
    const response: Promise<any> = yield call(getContentProjectsApi);
    yield put(contentProjectsApiResponseSuccess(ContentProjectsActionTypes.GET_CONTENT_PROJECTS, response));
  } catch (error: any) {
    yield put(contentProjectsApiResponseError(ContentProjectsActionTypes.GET_CONTENT_PROJECTS, error));
  }
}

function* onSendDataContentProjects({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentProjects, data);
    yield put(
        contentProjectsApiResponseSuccess(ContentProjectsActionTypes.UPDATE_CONTENT_PROJECTS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentProjectsApiResponseError(ContentProjectsActionTypes.UPDATE_CONTENT_PROJECTS, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveContentProject({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeContentProjectApi, {uuid: data.data.uuid});
    yield put(
        contentProjectsApiResponseSuccess(ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentProjectsApiResponseError(ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetContentProjects() {
  yield takeEvery(ContentProjectsActionTypes.GET_CONTENT_PROJECTS, getContentProjects);
}
export function* watchOnSendDataContentProjects() {
  yield takeEvery(ContentProjectsActionTypes.UPDATE_CONTENT_PROJECTS, onSendDataContentProjects);
}
export function* watchOnRemoveContentProject() {
  yield takeEvery(ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT, onRemoveContentProject);
}

function* contentProjectsSaga() {
  yield all([
    fork(watchGetContentProjects),
    fork(watchOnSendDataContentProjects),
    fork(watchOnRemoveContentProject)
  ]);
}

export default contentProjectsSaga;
