import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row } from 'reactstrap';
import LeftbarTitle from "../../../components/LeftbarTitle";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../../../hooks";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";


interface ItemProps {
    item: ContentPeerChannelType,
    handleClose: () => void;
    isOpen: boolean;
    modalTitle: string;
}

const ConnectModal = ({ item, handleClose, isOpen, modalTitle }: ItemProps) => {

    const { t, i18n } = useTranslation();
    const { dispatch } = useRedux();

    const [isModalShow, setModalShow] = useState<boolean>(isOpen);
    const [title, setTitle] = useState<string>(t('channel:channelConnection').replace(/%channelName%/g,item.name.charAt(0).toUpperCase() + item.name.slice(1)));

    useEffect(() => {
        setModalShow(isOpen);
    }, [isOpen]);

    const handleClick = (item: ContentPeerChannelType) => {
            setTimeout(()=>{
                dispatch(getContentPeerChannels());
            }, 3000);
    }

    return (
        <>
            <Modal show={isModalShow} onHide={()=>handleClose()}>
                        <Modal.Header closeButton>
                            <LeftbarTitle title={title}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column align-items-center me-5">
                                        {t('channel:channelConnectionDescription').replace(/%channelName%/g,item.name.charAt(0).toUpperCase() + item.name.slice(1))}
                                    </div>
                                </div>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleClose}>
                                {t('common:cancel')}
                            </Button>
                            <a href={item.accessTokenOAuthUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-success chat-send waves-effect waves-light"
                                    onClick={()=>handleClick(item)}
                            >
                                {t('connector:connect')}
                            </a>
                        </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConnectModal;