import {ContentProjectType} from "../../Content/Project/types";
import {StatPeerChannelDataType, StatPeerChannelTileType} from "../PeerChannel/types";
import {ContentPeerTargetType} from "../../Content/PeerTarget/types";
import {PeerActivityType} from "../../Content/PeerActivity/types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {ContentPeerUserType} from "../../Content/PeerUser/types";
import {StatStatCounterDataType, StatStatCounterTileType} from "../StatCounter/types";

export enum DashboardContentActionTypes {
  API_RESPONSE_SUCCESS = "@@dashboardContent/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@dashboardContent/API_RESPONSE_ERROR",
  GET_STAT_CHANNELS_TILES = "@@dashboardContent/GET_STAT_CHANNELS_TILES",
  GET_STAT_COUNTERS_TILES = "@@dashboardContent/GET_STAT_COUNTERS_TILES",
  GET_STAT_COUNTERS_DATA = "@@dashboardContent/GET_STAT_COUNTERS_DATA",
  GET_STAT_CHANNELS_ACTIVITIES_DATA = "@@dashboardContent/GET_STAT_CHANNELS_ACTIVITIES_DATA",
  GET_STAT_TARGETS = "@@dashboardContent/GET_STAT_TARGETS",
  GET_STAT_ACTIVITIES = "@@dashboardContent/GET_STAT_ACTIVITIES",
  GET_SCHEDULE_STAT_ACTIVITIES = "@@dashboardContent/GET_SCHEDULE_STAT_ACTIVITIES",
  GET_STAT_CHANNELS = "@@dashboardContent/GET_STAT_CHANNELS",
  GET_STAT_TOP_USERS = "@@dashboardContent/GET_STAT_TOP_USERS",
  SET_PERIOD = "@@dashboardContent/SET_PERIOD",
}

export interface DashboardContentState {
  tiles: Array<StatProjectTilesType>
  channelsActivitiesData: Array<StatProjectChannelsDataType>,
  counterTiles: Array<StatProjectCounterTilesType>,
  countersData: Array<StatProjectCountersDataType>,
  targets: Array<ContentPeerTargetType>,
  activities: Array<StatProjectActivitiesType>,
  scheduleActivities: Array<StatProjectActivitiesType>,
  channels: Array<ContentPeerChannelType>,
  topusers: Array<StatProjectTopUsersType>,
  periodFrom: number,
  selectedDate?: number,
  periodTo?: number,
  eTagActivities?: string,
  isDashboardContentFetched: boolean
  isDashboardContentTargetFetched: boolean;
}

export interface StatProjectTilesType {
  project?: ContentProjectType,
  items: Array<StatPeerChannelTileType>,
  eTag?: string
}

export interface StatProjectCounterTilesType {
  project?: ContentProjectType,
  requestId? : string,
  items: Array<StatStatCounterTileType>,
  eTag?: string
}

export interface StatProjectCountersDataType {
  project: ContentProjectType,
  items: Array<StatStatCounterDataType>,
  eTag?: string
}

export interface StatProjectChannelsDataType {
  project: ContentProjectType,
  items: Array<StatPeerChannelDataType>,
  eTag?: string
}

export interface StatProjectActivitiesType {
  project: ContentProjectType,
  items: Array<PeerActivityType>,
  eTag?: string
}

export interface StatProjectTopUsersType {
  project: ContentProjectType,
  items: Array<ContentPeerUserType>,
  eTag?: string
}

export interface StatProjectTilesPayloadType {
  project: ContentProjectType,
  periodFrom: number,
  periodTo: number
}

export interface StatChannelTilesPayloadType {
  channel: ContentPeerChannelType,
  periodFrom: number,
  periodTo: number,
  requestUuid?: string
}

export interface StatProjectCounterTilesPayloadType {
  project: ContentProjectType,
  periodFrom: number,
  periodTo: number
}

export interface StatProjectActivitiesPayloadType {
  project: ContentProjectType,
  periodFrom: number,
  periodTo: number
}

export interface StatProjectTopUsersPayloadType {
  project: ContentProjectType,
  periodFrom: number,
  periodTo: number
}

export interface StatPeriodPayloadType {
  periodFrom: number,
  periodTo: number
}

export interface PeriodType {
  periodFrom: number,
  periodTo: number,
  selectedDate?: number
}

export enum PeriodDurationType {
  WEEK = 'weeks',
  MONTH = 'months',
  DATE_RANGE = 'date_range'
}