import { APIClient } from "../apiCore";
import * as url from "../urls";
import {MainDeviceType} from "../../redux/Main/Device/types";
import {getApprovedDeviceUsers} from "../../helpers/functions";
import {defaultBrowserFingerPrintInputProps, getBrowserFingerPrintData} from "../../components/FingerPrint/browser";
import {LoginByDeviceType} from "../../redux/Main/Login/types";
const api = new APIClient();

const getUserDevices = () => {
  return api.get(url.GET_USER_DEVICE_LIST);
};

const approveUserDevice = (data: MainDeviceType) => {
    return api.create(url.APPROVE_USER_DEVICE, data);
};

const removeUserDevice = (data: MainDeviceType) => {
    return api.create(url.REMOVE_USER_DEVICE, data);
};

const getDeviceUsers = () => {
    const approvedDeviceUsers = getApprovedDeviceUsers();
    const browserFingerPrint = getBrowserFingerPrintData(defaultBrowserFingerPrintInputProps);

    const sendData = {
        uuids: approvedDeviceUsers.join(','),
        fingerPrint: browserFingerPrint.fingerPrint
    }

    return api.create(url.GET_DEVICE_USERS_LIST, sendData);
};

const loginUserByDevice = (data: LoginByDeviceType) => {

    let sendData: LoginByDeviceType = {
        user: {
            uuid: data.user.uuid,
            email: data.user.email,
            name: data.user.name,
        },
        device: data.device
    };

    return api.create(url.POST_DEVICE_TOKEN, sendData);
};

export {
    getUserDevices,
    approveUserDevice,
    removeUserDevice,
    getDeviceUsers,
    loginUserByDevice
};
