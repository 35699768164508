import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { PlanOrderActionTypes } from "./types";
import { planOrderApiResponseSuccess, planOrderApiResponseError } from "./actions";

import {
  getPlanOrders as getPlanOrdersApi,
  getPlanOrderDetail as getPlanOrderDetailApi,
  cancelPlanOrder as cancelPlanOrderApi,
  createPlanOrder as createPlanOrderApi
} from "../../../api";
import {showSuccessNotification} from "../../../helpers/notifications";

function* getPlanOrders({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPlanOrdersApi, data);
    yield put(planOrderApiResponseSuccess(PlanOrderActionTypes.GET_ORDERS, response));
  } catch (error: any) {
    yield put(planOrderApiResponseError(PlanOrderActionTypes.GET_ORDERS, error));
  }
}

function* getPlanOrderDetail({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPlanOrderDetailApi, data);
    yield put(planOrderApiResponseSuccess(PlanOrderActionTypes.GET_ORDER_DETAIL, response));
  } catch (error: any) {
    yield put(planOrderApiResponseError(PlanOrderActionTypes.GET_ORDER_DETAIL, error));
  }
}

function* cancelPlanOrder({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(cancelPlanOrderApi, data);
    yield put(planOrderApiResponseSuccess(PlanOrderActionTypes.CANCEL_ORDER, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(planOrderApiResponseError(PlanOrderActionTypes.CANCEL_ORDER, error));
    yield call(showSuccessNotification, error);
  }
}
function* createPlanOrder({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(createPlanOrderApi, data);
    yield put(planOrderApiResponseSuccess(PlanOrderActionTypes.CREATE_ORDER, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(planOrderApiResponseError(PlanOrderActionTypes.CREATE_ORDER, error));
    yield call(showSuccessNotification, error);
  }
}

export function* watchGetPlanOrders() {
  yield takeEvery(PlanOrderActionTypes.GET_ORDERS, getPlanOrders);
}
export function* watchGetPlanOrderDetail() {
  yield takeEvery(PlanOrderActionTypes.GET_ORDER_DETAIL, getPlanOrderDetail);
}
export function* watchCancelPlanOrder() {
  yield takeEvery(PlanOrderActionTypes.CANCEL_ORDER, cancelPlanOrder);
}

export function* watchCreatePlanOrder() {
  yield takeEvery(PlanOrderActionTypes.CREATE_ORDER, createPlanOrder);
}

function* planOrderSaga() {
  yield all([
      fork(watchGetPlanOrders),
      fork(watchGetPlanOrderDetail),
      fork(watchCancelPlanOrder),
      fork(watchCreatePlanOrder)
  ]);
}

export default planOrderSaga;
