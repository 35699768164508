import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,} from 'reactstrap';

import InputText from "../../../components/InputText";
import InputPassword from "../../../components/InputPassword";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {MainUserResetType, MainUserSignUpType} from "../../../redux/Main/User/types";
import {MainUserReset} from "../../../redux/Main/User/reducer";
import {onChangeEmailMainUser, setSignUpStatus} from "../../../redux/Main/User/actions";
import {ChangeEmailValidatorSchema} from "./ChangeEmailFormValidator";

const ChangeEmailForm = () => {

    const { t } = useTranslation(['user','validator']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        currentUser,
    } = useAppSelector(state => ({
        currentUser: state.MainUser,
    }));

    const [formValues, setFormValues] = useState<MainUserResetType>(MainUserReset);
    const formikObject = useRef(null);

    useEffect(() => {

        if (currentUser !== undefined && currentUser.isSendChangeEmail !== undefined) {
            setDisabled(currentUser.isSendChangeEmail);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.isSendChangeEmail]);

    const onDataSubmit = (formData:MainUserSignUpType) => {
        let data: any = {};

        dispatch(setSignUpStatus(true));

        if (formData.email) {
            data["email"] = formData.email;
        }

        let params: MainUserSignUpType = {
            email: data.email && data.email,
        };

        dispatch(onChangeEmailMainUser(params));
    };

    return (
        <>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ChangeEmailValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    innerRef={formikObject}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) =>(
                    <Form noValidate={true} className="customValidation">
                            <Row>
                                <div className={ "mb-3"+ (touched.email ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-email-input">E-mail</Label>
                                    <Field
                                        id="form-email-input"
                                        name="email"
                                        placeholder={t('user:enterEmail')}
                                        component={InputText}
                                        value = {getFieldProps('email').value}
                                        onChange={(value:string) => {
                                            setFieldValue('email', value);
                                        }}
                                        className={errors.email && touched.email && 'form-control is-invalid'}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.email)}
                                        </div>
                                    ) :  touched.email && !errors.email ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>

                        <div className="mb-4">
                            <p className="mb-0 fs-12 text-muted fst-italic">{t('auth:byRegisteringYouAgree')} <a href="https://marketcells.io/license" className="text-primary text-decoration-underline fst-normal fw-medium">{t('auth:termsOfUse')}</a></p>
                        </div>

                        <div className="mt-4">
                            <Button
                                type="submit"
                                disabled={disabled}
                                color="success"
                                className="w-100 btn-success">{t('auth:changeEmail')}</Button>
                        </div>
                    </Form>
                        )}
                </Formik>
        </>
    );
}

export default ChangeEmailForm;