import {ConnectionLifecycle, wsMessageLifecycle, wsMessageType, wsState} from './webSocketTypes';

export const initialWSState: wsState = {
    ws: [],
    isConnected : false,
    isConnecting: false,
    messages:[]
};

export function webSocketReducer(
    state: wsState,
    { type, payload }: any
): wsState {

    switch (type) {
        case 'CONNECT': {
            return {
                ...state,
                isConnected: true,
                isConnecting: false
            };
        }
        case 'CONNECTING': {
            return {
                ...state,
                isConnected: false,
                isConnecting: true
            };
        }
        case 'DISCONNECT': {
            return {
                ...state,
                isConnected: false,
                isConnecting: false
            };
        }
        case 'NEW_MESSAGE': {

            const messages = (state.messages || []).filter((item:wsMessageType)=>{
               return  (item.status === undefined || item.status !== wsMessageLifecycle.FINAL)
            });

            return {
                ...state,
                messages: [...messages, JSON.parse(payload)]
            };
        }

        default: {
            return state;
        }
    }
}