import React, { useState } from "react";
import { Button, UncontrolledTooltip, PopoverBody, Popover } from "reactstrap";
import '../i18/config';
import {useTranslation} from "react-i18next";

interface InputProps {
    onChange: () => void
}

const TemplateButton = ({ onChange }: InputProps) => {
    const { t } = useTranslation(['template']);

  return (
      <>
      <button type="button"
              className="btn btn-outline-primary btn-icon waves-effect waves-light"
              onClick={onChange}
              tabIndex={-1}
              id="content-templates-btn"
      >
          <i className="ri-character-recognition-line"/>
      </button>
        <UncontrolledTooltip target="content-templates-btn" placement="top">
            {t('template:templates')}
        </UncontrolledTooltip>
      </>
  );
};

export default TemplateButton;
