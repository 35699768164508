import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum SaleProductParamActionTypes {
    API_RESPONSE_SUCCESS = "@@ProductParam/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@ProductParam/API_RESPONSE_ERROR",
    GET_PRODUCT_PARAMS = "@@ProductParam/GET_PRODUCT_PARAMS",
    UPDATE_PRODUCT_PARAM = "@@ProductParam/UPDATE_PRODUCT_PARAM",
    REMOVE_PRODUCT_PARAM_MODAL_SHOW = "@@ProductParam/REMOVE_PRODUCT_PARAM_MODAL_SHOW",
    REMOVE_PRODUCT_PARAM_MODAL_HIDE = "@@ProductParam/REMOVE_PRODUCT_PARAM_MODAL_HIDE",
    REMOVE_PRODUCT_PARAM = "@@ProductParam/REMOVE_PRODUCT_PARAM",
    EDIT_PRODUCT_PARAM = "@@ProductParam/EDIT_PRODUCT_PARAM",
    RIGHT_MODAL_SHOW = "@@ProductParam/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@ProductParam/RIGHT_MODAL_HIDE",
}

export interface SaleProductParamState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: ProductParamType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface SaleProductParamPayload {
    data: Array<any>;
    modalEditTitle : string;
}


export interface ProductParamType {
    uuid?: string;
    name?: string;
    code?: string;
    enabled?: boolean;
    module?: string;
    order?: number;
    type?: string;
    idRemote?: string;
    values?: Array<ProductParamListValueType>;
    actions?: EntityActionsType,
    isBrand?: boolean;
    isColor?: boolean;
    isSize?: boolean;
    isBarCode?: boolean;
    isCart?: boolean;
}

export enum ProductParamTypes {
    STRING = 'S',
    NUMBER = 'N',
    BOOLEAN = 'B',
    LIST = 'L'
}

export interface ProductParamListValueType {
    uuid?: string;
    value?: string;
    enabled: boolean;
    order?: number;
    idRemote?: string;
    color?: string;
}