import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SalePriceTypeActionTypes } from "./types";
import { salePriceTypeApiResponseSuccess, salePriceTypeApiResponseError } from "./actions";

import {
  getSalePriceTypes as getSalePriceTypesApi,
  sendDataSalePriceType as sendDataSalePriceTypeApi,
  removeSalePriceType as removeSalePriceTypeApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSalePriceTypes({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSalePriceTypesApi,data);
    yield put(salePriceTypeApiResponseSuccess(SalePriceTypeActionTypes.GET_SALE_PRICE_TYPES, response));
  } catch (error: any) {
    yield put(salePriceTypeApiResponseError(SalePriceTypeActionTypes.GET_SALE_PRICE_TYPES, error));
  }
}

function* onSendDataSalePriceType({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSalePriceTypeApi, data);
    yield put(
        salePriceTypeApiResponseSuccess(SalePriceTypeActionTypes.UPDATE_SALE_PRICE_TYPE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(salePriceTypeApiResponseError(SalePriceTypeActionTypes.UPDATE_SALE_PRICE_TYPE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSalePriceType({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSalePriceTypeApi, {uuid: data.data.uuid});
    yield put(
        salePriceTypeApiResponseSuccess(SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(salePriceTypeApiResponseError(SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSalePriceTypes() {
  yield takeEvery(SalePriceTypeActionTypes.GET_SALE_PRICE_TYPES, getSalePriceTypes);
}
export function* watchOnSendDataSalePriceType() {
  yield takeEvery(SalePriceTypeActionTypes.UPDATE_SALE_PRICE_TYPE, onSendDataSalePriceType);
}
export function* watchOnRemoveSalePriceType() {
  yield takeEvery(SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE, onRemoveSalePriceType);
}

function* salePriceTypeSaga() {
  yield all([
    fork(watchGetSalePriceTypes),
    fork(watchOnSendDataSalePriceType),
    fork(watchOnRemoveSalePriceType)
  ]);
}

export default salePriceTypeSaga;
