import React from "react";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {MainFileImageTypes, MainFileVideoTypes} from "../../../redux/Main/File/types";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {logoIdeaChannel} from "../../../data/images";
import {convertToMomentLocale} from "../../../helpers/functions";
import PeerActivityLink from "./PeerActivityLink";

interface ItemProps {
    item: PeerActivityType;
}
const PeerActivityLine = ({ item }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);

    moment.locale(convertToMomentLocale(i18n.language));

    const firstMedia = (item.media && item.media.length>0) && item.media[0];

    const activityName = item.text ? item.text : t('peerActivityMode:'+item.mode.code);

    const handleClick = (peerActivity:PeerActivityType) => {
        window.location.href=route.STAT_CONTENT_ACTIVITY.replace(/:uuidActivity/,peerActivity.uuid);
    }


    return(
        <>
            <div className="d-flex flex-shrink-0 align-items-center" onClick={()=>handleClick(item)}>
                <div className="avatar-xxs me-2 d-flex flex-shrink-0 position-relative flex-column">
                    { firstMedia ?
                        (Object.values(MainFileImageTypes) as string[]).includes(firstMedia.contentType.split('/').pop()) ?
                        <img src={ firstMedia.url } className="rounded-1 img-fluid" alt=""/>
                            :
                            (Object.values(MainFileVideoTypes) as string[]).includes(firstMedia.contentType.split('/').pop()) ?
                                <span className={"avatar-title rounded-circle"}>
                                    <i className="ri-video-line label-icon align-middle fs-16"/>
                                </span>
                                :
                                <span className={"avatar-title rounded-circle"}>
                                    <i className="ri-file-line label-icon align-middle fs-16"/>
                                </span>
                        :
                        item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS ?
                            <img src={logoIdeaChannel} className="img-fluid p-0 rounded-circle" alt=""/>
                            :
                        <span className={"avatar-title rounded-circle"}>{activityName[0]}</span>
                    }
                    { item.connector && item.connector.handler && item.connector.handler.icon &&
                    <div className="avatar-xs12 position-absolute handlerIconTopLeft">
                        <img src={item.connector.handler.icon} className="img-fluid p-1 rounded-circle" alt=""/>
                    </div>
                    }
                </div>
                <div className="d-flex flex-column flex-grow-1 mw-0">
                    <div className="flex-1 flex-row flex-nowrap text-nowrap text-truncate justify-content-center">
                        {activityName}
                        <PeerActivityLink item={item}/>
                    </div>
                    <div className="d-flex flex-column flex-nowrap">
                        {item.channel && item.channel.name &&
                            <div className="fs-10 align-items-center text-muted mb-0 d-flex flex-shrink-0 text-truncate mb-1">{item.channel.name}</div>
                        }
                        {item.activeFrom &&
                            <div className="d-inline-flex">
                        <div className="badge badge-soft-primary mb-2 text-truncate">{moment(item.activeFrom).format('ddd, DD MMM YYYY, HH:mm')}</div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default PeerActivityLine;