import {MainDeviceActionTypes, MainDeviceState, MainDeviceType, UserDeviceLocalStorageType} from "../Device/types";
import {
    addApprovedDeviceUser,
    addCancelledDevice,
    getCancelledDevices, removeApprovedDeviceUser,
    removeCancelledDevice
} from "../../../helpers/functions";
import {getLoggedinUser} from "../../../api/apiCore";


export const INIT_STATE_MAIN_DEVICE: MainDeviceState = {
    items: [],
    rightModalShow: false,
    removeModalShow: false,
    selectedItem: null,
    current: null,
    isSending: false,
    isLoading: false,
    isNeedReload: false
};

const MainDevice = (state = INIT_STATE_MAIN_DEVICE, action: any) => {

    const currentUser = getLoggedinUser();

    switch (action.type) {
        case MainDeviceActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MainDeviceActionTypes.GET_CURRENT_DEVICE:
                    return {
                        ...state,
                        current: action.payload.data
                    }
                case MainDeviceActionTypes.GET_MAIN_ALLOWED_DEVICES:

                    let isSuggestToApprove = (action.payload.data.items || []).filter((item:MainDeviceType)=>{
                        return item.fingerPrint === state.current.fingerPrint && item.isApproved
                    }).length<1

                    if (isSuggestToApprove){
                        const cancelledDevices = getCancelledDevices();

                        if ((cancelledDevices || []).filter((item:UserDeviceLocalStorageType)=>{
                            return item.userUuid === currentUser.uuid && item.deviceFingerPrint === state.current.fingerPrint
                        }).length>0){
                            isSuggestToApprove = false;
                        }
                    }

                    if ((action.payload.data.items || []).filter((item:MainDeviceType)=>{
                        return item.fingerPrint === state.current.fingerPrint && item.isApproved
                    }).length>0){
                        addApprovedDeviceUser(currentUser.uuid);
                    }

                    return {
                        ...state,
                        items: action.payload.data.items,
                        meta: action.payload.data.meta,
                        rightModalShow: isSuggestToApprove,
                        isLoading: false,
                        isNeedReload: false
                    }
                case MainDeviceActionTypes.UPDATE_MAIN_ALLOWED_DEVICE:

                    removeCancelledDevice({
                        userUuid: currentUser.uuid && currentUser.uuid,
                        deviceFingerPrint: state.current.fingerPrint
                    });

                    addApprovedDeviceUser(currentUser.uuid);

                    return {
                        ...state,
                        isSending: false,
                        isNeedReload: true,
                        rightModalShow: false
                    }
                case MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE:

                    removeApprovedDeviceUser(currentUser.uuid);

                    addCancelledDevice({
                        userUuid: currentUser.uuid && currentUser.uuid,
                        deviceFingerPrint: state.current.fingerPrint
                    });

                    return {
                        ...state,
                        removeModalShow: false,
                        isSending: false,
                        isNeedReload: true,
                    }
                default:
                    return { ...state };
            }

        case MainDeviceActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MainDeviceActionTypes.GET_MAIN_ALLOWED_DEVICES:
                    return {
                        ...state,
                        isLoading: false,
                        isNeedReload: false
                    }
                case MainDeviceActionTypes.UPDATE_MAIN_ALLOWED_DEVICE:
                    return {
                        ...state,
                        isSending: false,
                    }
                case MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE:
                    return {
                        ...state,
                        isSending: false,
                        removeModalShow: false
                    }
                default:
                    return { ...state };
            }
        case MainDeviceActionTypes.GET_CURRENT_DEVICE:
            return { ...state };
        case MainDeviceActionTypes.GET_MAIN_ALLOWED_DEVICES:
            return {
                ...state,
                isLoading: true,
            }
        case MainDeviceActionTypes.UPDATE_MAIN_ALLOWED_DEVICE:
            return {
                ...state,
                isSending: true,
            }
        case MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE:
            return {
                ...state,
                isSending: true,
            }
        case MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE_MODAL_SHOW:
            return {
                ...state,
                selectedItem: action.payload.data,
                modalEditTitle: action.payload.modalEditTitle,
                removeModalShow: true
            }
        case MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE_MODAL_HIDE:

            return {
                ...state,
                selectedItem: null,
                removeModalShow: false
            }
        case MainDeviceActionTypes.RIGHT_MODAL_SHOW:
            return {
                ...state,
                selectedItem: action.payload.data,
                modalEditTitle: action.payload.modalEditTitle,
                rightModalShow: true
            }
        case MainDeviceActionTypes.RIGHT_MODAL_HIDE:

            addCancelledDevice({
                userUuid: currentUser.uuid && currentUser.uuid,
                deviceFingerPrint: state.current.fingerPrint
            });

            return {
                ...state,
                rightModalShow: false
            }
        default:
            return { ...state };
    }
};

export default MainDevice;