import React, {useEffect, useState} from "react";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {getHandlerIconByCode} from "../../../helpers/functions";
import * as route from "../../../api/routes";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {logoIdeaChannel} from "../../../data/images";
import ConnectorConnectionStatus from "../Connector/ConnectorConnectionStatus";
import ChannelLink from "./ChannelLink";

interface ItemProps {
    item: ContentPeerChannelType;
    link?: string;
    onClick?: (item:ContentPeerChannelType)=>void;
}
const ChannelItemLine = ({ item, link, onClick }: ItemProps) => {

    const { t } = useTranslation(['common']);

    const [channelName, setChannelName] = useState<string>(item.name)

    useEffect(()=>{
        if (item?.connector?.handler?.code && item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS){
            setChannelName(t('channel:channelIdeas'));
        } else {
            setChannelName(item.name);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item.name])

    const handleClick = (event:any, peerChannel:ContentPeerChannelType) => {

        event.preventDefault();
        event.stopPropagation();

        if (link !== undefined && link !== null){
            window.location.href=link;
        } else {
            if (onClick !== undefined){
                onClick(peerChannel);
            } else {

                if (peerChannel?.connector?.handler?.code && peerChannel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS) {
                    window.location.href = route.CONTENT_ACTIVITY_IDEAS;
                }

                window.location.href = route.STAT_CONTENT_CHANNEL.replace(/:uuidChannel/, peerChannel.uuid);
            }
        }
    }

    return(
        <>
            <div className="d-flex flex-shrink-0 mw-0 overflowInitial">
                <div className="avatar-xs me-2 d-flex flex-shrink-0 position-relative overflowInitial">
                    {item.avatar ?
                        <img src={item.avatar} className="rounded-circle img-fluid" alt=""/>
                    :
                        item.connector && item.connector.handler && item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS ?
                            <img src={logoIdeaChannel} className="img-fluid p-0 rounded-circle" alt=""/>
                                :
                            <span className={"avatar-title rounded-circle"}>{item.name[0]}</span>
                    }
                    { item.connector && item.connector.handler && item.connector.handler.code &&
                    <div className="avatar-xs12 position-absolute handlerIconTopLeft">
                        <img src={getHandlerIconByCode(item.connector.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                    </div>
                    }
                </div>
                <div className="flex-1 justify-content-center flex-column d-flex mw-0 me-1">
                    <span className="text-truncate">
                         {channelName.substring(0, 23)}{channelName.length >= 23 && '...'}
                    </span>
                    {item?.connector?.name &&
                        <div className="d-flex flex-row position-relative align-items-center mw-0">
                            <ConnectorConnectionStatus item={item.connector}/>
                            <span className="text-muted text-truncate fs-10">{t('connector:service')}:
                                {item.connector.name.substring(0, 17)}{item.connector.name.length >= 17 && '...'}
                            </span>
                        </div>
                    }
                </div>
                <div className="flex-shrink-1 justify-content-start align-items-start flex-row d-flex">
                    <ChannelLink item={item}/>
                    <a
                        onClick={(e)=>handleClick(e, item)} className={"p-0 m-0 text-muted d-flex font-weight-normal"}>
                        <i className={"ri-share-box-line"}/>
                    </a>
                </div>
            </div>
        </>
    )
}

export default ChannelItemLine;