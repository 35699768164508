import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    hideRightModalSaleOrderStatus,
    onSendDataSaleOrderStatus
} from "../../../redux/Sale/OrderStatus/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {SaleOrderStatusType} from "../../../redux/Sale/OrderStatus/types";
import {SaleOrderStatusValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";
import NoticeTip from "../../../components/NoticeTip";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);

    const {
        saleOrderStatusState,
        isSending
    } = useAppSelector(state => ({
        saleOrderStatusState: state.SaleOrderStatus,
        isSending: state.SaleOrderStatus.isSending
    }));

    const uuid = saleOrderStatusState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<SaleOrderStatusType>(saleOrderStatusState.selectedItem);

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    useEffect(() => {

        setFormValues(saleOrderStatusState.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalSaleOrderStatus());

    const onDataSubmit = (formData:SaleOrderStatusType) => {

        let params: SaleOrderStatusType = {
            uuid: formData.uuid && formData.uuid,
            enabled: formData.enabled ? formData.enabled : false,
            name: formData.name && formData.name,
            description: formData.description && formData.description,
            order: formData.order && formData.order,
            isNew: formData.isNew ? formData.isNew : false,
            isSuccess: formData.isSuccess ? formData.isSuccess : false,
            isFailure: formData.isFailure ? formData.isFailure : false,
            isNotifyCustomer: formData.isNotifyCustomer ? formData.isNotifyCustomer : false
        };

        dispatch(onSendDataSaleOrderStatus(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleOrderStatusState.rightModalShow}
                    id="saleOrderStatusEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleOrderStatusValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('orderStatus:'+saleOrderStatusState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                    <Field
                                        id="form-name-input"
                                        name="name"
                                        placeholder={t('common:enterNameTitle')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-order-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-order-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isNew-input"
                                                    placeholder={t('orderStatus:orderStatusTypeNew_')}
                                                    name="isNew"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isNew').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isNew', !getFieldProps('isNew').value);
                                                        if (!getFieldProps('isNew').value){
                                                            setFieldValue('isFailure',false);
                                                            setFieldValue('isSuccess',false);
                                                        }
                                                    }}
                                                    className={"switch-sm"}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isSuccess-input"
                                                    placeholder={t('orderStatus:orderStatusTypeSuccess')}
                                                    name="isSuccess"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isSuccess').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isSuccess', !getFieldProps('isSuccess').value);
                                                        if (!getFieldProps('isSuccess').value){
                                                            setFieldValue('isFailure',false);
                                                            setFieldValue('isNew',false);
                                                        }
                                                    }}
                                                    className={"switch-sm"}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isFailure-input"
                                                    placeholder={t('orderStatus:orderStatusTypeFailure')}
                                                    name="isFailure"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isFailure').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isFailure', !getFieldProps('isFailure').value);

                                                        if (!getFieldProps('isFailure').value){
                                                            setFieldValue('isSuccess',false);
                                                            setFieldValue('isNew',false);
                                                        }
                                                    }}
                                                    className={"switch-sm"}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isNotifyCustomer-input"
                                                    placeholder={t('orderStatus:isNotifyCustomer')}
                                                    name="isNotifyCustomer"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isNotifyCustomer').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isNotifyCustomer', !getFieldProps('isNotifyCustomer').value);
                                                    }}
                                                    className={"switch-sm"}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-description-input">{t('common:description')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-description-input"
                                                    name="description"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('description').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('description', text);
                                                    }}
                                                    required={false}
                                                    placeholder={t('common:enterDescription')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <NoticeTip firstText={t('orderStatus:orderStatusEditText')}/>
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;