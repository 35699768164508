import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleCurrencyRateActionTypes } from "./types";
import { saleCurrencyRateApiResponseSuccess, saleCurrencyRateApiResponseError } from "./actions";

import {
  getSaleCurrencyRates as getSaleCurrencyRatesApi,
  sendDataSaleCurrencyRate as sendDataSaleCurrencyRateApi,
  removeSaleCurrencyRate as removeSaleCurrencyRateApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleCurrencyRates({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleCurrencyRatesApi, data);
    yield put(saleCurrencyRateApiResponseSuccess(SaleCurrencyRateActionTypes.GET_SALE_CURRENCY_RATES, response));
  } catch (error: any) {
    yield put(saleCurrencyRateApiResponseError(SaleCurrencyRateActionTypes.GET_SALE_CURRENCY_RATES, error));
  }
}

function* onSendDataSaleCurrencyRate({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleCurrencyRateApi, data);
    yield put(
        saleCurrencyRateApiResponseSuccess(SaleCurrencyRateActionTypes.UPDATE_SALE_CURRENCY_RATE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCurrencyRateApiResponseError(SaleCurrencyRateActionTypes.UPDATE_SALE_CURRENCY_RATE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleCurrencyRate({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleCurrencyRateApi, {uuid: data.data.uuid});
    yield put(
        saleCurrencyRateApiResponseSuccess(SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCurrencyRateApiResponseError(SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleCurrencyRates() {
  yield takeEvery(SaleCurrencyRateActionTypes.GET_SALE_CURRENCY_RATES, getSaleCurrencyRates);
}
export function* watchOnSendDataSaleCurrencyRate() {
  yield takeEvery(SaleCurrencyRateActionTypes.UPDATE_SALE_CURRENCY_RATE, onSendDataSaleCurrencyRate);
}
export function* watchOnRemoveSaleCurrencyRate() {
  yield takeEvery(SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE, onRemoveSaleCurrencyRate);
}

function* saleCurrencyRateSaga() {
  yield all([
    fork(watchGetSaleCurrencyRates),
    fork(watchOnSendDataSaleCurrencyRate),
    fork(watchOnRemoveSaleCurrencyRate)
  ]);
}

export default saleCurrencyRateSaga;
