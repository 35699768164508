import React, {useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";
import { Link } from 'react-router-dom';
import {m9sGlobals} from "../../../config";
import {getCurrentCabinet} from "../../../helpers/functions";
import {nameToEmoji} from "gemoji";

interface ItemProps {
    item: MainCabinetType;
    onClick: (item:MainCabinetType) => void,
}
const ListItem = ({ item, onClick }: ItemProps) => {

    const { t } = useTranslation(['common']);
    const currentCabinet = getCurrentCabinet();

    return (
        <>
        {
            item.uuid &&
                <div className={"text-reset cabinet-item d-block dropdown-item position-relative"+((item?.isSuspended === true) ? ' disabledBlock': '')}>
                    <div className="d-flex">
                        <div className="flex-1">
                            { (item?.uuid
                                &&
                                currentCabinet !== false
                                &&
                                currentCabinet?.uuid
                                &&
                                item.uuid === currentCabinet?.uuid) ?
                                <Link to='#'
                                      className="stretched-link"
                                >
                                    <h6 className="mt-0 mb-2 lh-basefw-semibold d-flex flex-row">
                                        <span className="text-info me-2 d-flex flex-row align-items-center">
                                            <i className="ri-star-fill"/>
                                        </span>
                                        <span className="d-flex flex-row">{item.name}</span>
                                    </h6>
                                </Link>
                                :
                                <Link to='#'
                                      className="stretched-link"
                                      rel="noreferrer"
                                      onClick={ () => {onClick(item)}}
                                >
                                    <h6 className="mt-0 mb-2 lh-basefw-semibold d-flex flex-row">
                                         <span className="d-flex flex-row pe-1">{item.name}</span>
                                        {item?.isSuspended && item.isSuspended===true && nameToEmoji.sleeping}
                                    </h6>
                                </Link>
                            }

                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span><i className="mdi mdi-link"/> {item.url}</span>
                            </p>
                        </div>
                    </div>
                </div>
        }
        </>
    );
};

export default ListItem;
