import React from 'react';
import * as Yup from 'yup';
import {PASSWORD_REGEX} from "../../../data/settings";

export const ChangePasswordFormValidatorSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, 'tooShort')
        .max(50, 'tooLong')
        .matches(
            PASSWORD_REGEX,
            "passwordPolicy"
        )
        .required('required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'passwordsMustMatch')
        .required('required')
        .matches(
            PASSWORD_REGEX,
            "passwordPolicy"
        )
});