import React, { useState } from "react";
import { Button, UncontrolledTooltip, PopoverBody, Popover } from "reactstrap";
import '../i18/config';
import {useTranslation} from "react-i18next";

interface InputProps {
    onChange: () => void
}

const AIGenerateButton = ({ onChange }: InputProps) => {
    const { t } = useTranslation(['template']);

  return (
      <>
          <button type="button"
                  className="btn btn-outline-primary btn-icon waves-effect waves-light"
                  onClick={onChange}
                  tabIndex={-1}
                  id="ai-magic-writer-btn"
          >
              <i className="mdi mdi-pencil-plus-outline"/>
          </button>
            <UncontrolledTooltip target="ai-magic-writer-btn" placement="top">
               Magic Writer
            </UncontrolledTooltip>
      </>
  );
};

export default AIGenerateButton;
