import React from "react";
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
interface ItemProps {
    item: PriceTypeType;
}
const PriceTypeItemLine = ({ item }: ItemProps) => {
    const { t } = useTranslation(['common']);

    return(
        <>
            <div className="d-flex flex-shrink-0">
                <span className="text-info me-1"><i className="ri-price-tag-2-line align-bottom"/></span>
                <span className="d-inline-flex text-muted">{t('price:priceType')}:</span>{" "}
                <div className="flex-1 justify-content-center flex-column d-flex text-truncate ms-1">
                    {item.name}
                </div>
            </div>
        </>
    )
}

export default PriceTypeItemLine;