import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { MainVersionHistoryActionTypes } from "./types";
import { mainVersionHistoryApiResponseSuccess, mainVersionHistoryApiResponseError } from "./actions";

import {
  getMainVersionHistories as getMainVersionHistoriesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getMainVersionHistories() {
  try {
    const response: Promise<any> = yield call(getMainVersionHistoriesApi);
    yield put(mainVersionHistoryApiResponseSuccess(MainVersionHistoryActionTypes.GET_VERSION_HISTORY_LIST, response));
  } catch (error: any) {
    yield put(mainVersionHistoryApiResponseError(MainVersionHistoryActionTypes.GET_VERSION_HISTORY_LIST, error));
  }
}

export function* watchGetMainVersionHistories() {
  yield takeEvery(MainVersionHistoryActionTypes.GET_VERSION_HISTORY_LIST, getMainVersionHistories);
}

function* mainVersionHistoriesSaga() {
  yield all([
    fork(watchGetMainVersionHistories),
  ]);
}

export default mainVersionHistoriesSaga;
