
import {EntityActionsType} from "../../types";
import {TaskType} from "../../Task/Task/types";
import {MainUserType} from "../../Main/User/types";

export interface TaskTimeType{
    uuid?: string;
    enabled?: boolean;
    minutes?: number;
    status?: string;
    task?: TaskType;
    text?:string;
    updatedAt?:string;
    createdAt?:string;
    actions?: EntityActionsType;
    owner?: MainUserType;
}

export const TaskTimeStatus = {
    ALL: {
        label: 'All',
        class: 'text-bg-info',
        status: '',
        enabled: false
    },
    PENDING: {
        label: 'Pending',
        class: 'text-bg-success',
        status: 'P',
        enabled: true
    },
    CANCELLED: {
        label: 'Cancelled',
        class: 'text-bg-danger',
        status: 'C',
        enabled: true
    },
    NEW: {
        label: 'New',
        class: 'text-bg-info',
        status: 'N',
        enabled: true
    },
    INPROGRESS: {
        label: 'InProgress',
        class: 'text-bg-secondary',
        status: 'I',
        enabled: true
    },
    RETURNS: {
        label: 'Returns',
        class: 'text-bg-primary',
        status: 'R',
        enabled: true
    },
    FINAL: {
        label: 'Final',
        class: 'badge-soft-dark',
        status: 'F',
        enabled: true
    },
}

export const NewTaskTimeItem:TaskTimeType = {
    enabled: true,
    minutes: null,
    status: TaskTimeStatus.NEW.status
}

export interface TaskTimePayload {
    data: TaskTimeType;
    removeTaskTimeModalTitle : string;
}

export interface TaskTimeMetaType{
    totalCount?: number;
    totalMinutes?: number
}