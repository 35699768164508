import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import { Card, CardBody, Col, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import {SupportTicketType, TicketStatus, TicketPriority} from "../../../redux/Support/Ticket/types";
import {useRedux} from "../../../hooks";
import {
    getSupportServiceTicketDetail,
    getSupportTicketDetail,
    hideRewardModalSupportTicket,
    onSendSupportTicketReward,
    readSupportServiceTicketMessages,
    readSupportTicketMessages,
    showRewardSupportTicket
} from "../../../redux/Support/Ticket/actions";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import Loader from "../../../components/Loader";
import {MainFileType} from "../../../redux/Main/File/types";
import FileItem from "./fileItem";
import {
    clearSupportTicketMessageStateItems,
    getSupportServiceTicketMessages,
    getSupportTicketMessages,
    hideEditSupportTicketMessage, onRemoveSupportServiceTicketMessage,
    onRemoveSupportTicketMessage,
    removeSupportTicketMessageModalHide,
    removeSupportTicketMessageModalShow,
    showEditSupportTicketMessage
} from "../../../redux/Support/TicketMessage/actions";
import TicketMessageForm from "../TicketMessage/ticketMessageForm";
import {SupportTicketMessageType, TicketMessageStatus} from "../../../redux/Support/TicketMessage/types";
import ListItem from "../TicketMessage/ListItem";
import RemoveModal from "../../../components/RemoveForm";
import RewardForm from "../../Main/Reward/RewardForm";
import * as route from "../../../api/routes";
import {useLocation} from "react-router-dom";
import {getSupportServiceBadges, getSupportUserBadges} from "../../../redux/Event/Badge/actions";
import BackButton from "../../../components/BackButton";
import {convertToMomentLocale} from "../../../helpers/functions";


const SupportTicketDetailIndex = () => {

    const { t, i18n } = useTranslation(['common']);

    moment.locale(convertToMomentLocale(i18n.language));

    const { uuid } = useParams();
    const { dispatch, useAppSelector } = useRedux();
    const [isSupportService, setIsSupportService] = useState<boolean>(false);

    const {
        isLoading,
        supportTicket,
        supportTicketMessage,
        isLoadingSupportTicketMessage,
        mainCabinet,
        editTicketMessage,
        stateContacts,
        isLoadingContacts,
        isRewardTicketShow
    } = useAppSelector(state => ({
        isLoading: state.SupportTicket.isLoading,
        supportTicket: state.SupportTicket,
        supportTicketMessage: state.SupportTicketMessage,
        isLoadingSupportTicketMessage: state.SupportTicketMessage.isLoading,
        mainCabinet: state.MainCabinet,
        editTicketMessage: state.SupportTicketMessage.editShow,
        stateContacts: state.MainUser.contacts,
        isLoadingContacts: state.MainUser.isLoading,
        isRewardTicketShow: state.SupportTicket.rewardModalShow
    }));

    const [ticket, setTicket] = useState<SupportTicketType>(null);
    const [ticketMessages, setTicketMessages] = useState<SupportTicketMessageType[]>([]);

    const location = useLocation();

    useEffect(() => {
        dispatch(clearSupportTicketMessageStateItems());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (location.pathname === route.SUPPORT_SERVICE_TICKET_DETAIL.replace(/:uuid/,uuid)) {
        if (!isSupportService) {
            setIsSupportService(true);
        }
    } else {
        if (isSupportService) {
            setIsSupportService(false);
        }
    }

    useEffect(() => {
        if (isSupportService) {
            dispatch(getSupportServiceTicketDetail({uuid: uuid}));
        } else {
            dispatch(getSupportTicketDetail({uuid: uuid}));
        }
    }, [dispatch, uuid, isSupportService]);


    useEffect(() => {
        if ((supportTicket.selectedItem !== undefined) && (supportTicket.selectedItem !== null) && (supportTicket.selectedItem.uuid !== undefined)){
            setTicket({...supportTicket.selectedItem});
        } else {
           // setTicket(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportTicket?.selectedItem])

    const loadTicketMessages = () => {
        if (isSupportService) {
            dispatch(getSupportServiceTicketMessages(ticket));
        } else {
            dispatch(getSupportTicketMessages(ticket));
        }
    }

    useEffect(() => {
        if (ticket?.uuid === uuid && uuid !== null) {
            loadTicketMessages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, ticket, isSupportService, uuid]);

    useEffect(() => {
        if ((supportTicketMessage?.items !== undefined) && (supportTicketMessage?.items !== null)){
            setTicketMessages([...supportTicketMessage.items]);
        } else {
            setTicketMessages([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportTicketMessage?.items, dispatch])


    const handleTicketMessageForm = () => {
        if (editTicketMessage) {
            dispatch(hideEditSupportTicketMessage());
        } else {
            dispatch(showEditSupportTicketMessage());
        }
    }

    const handlerTicketMessageRemoveModal = (item:SupportTicketMessageType) => {
        dispatch(removeSupportTicketMessageModalShow({data: item, modalEditTitle: t('support:deleteMessage')}));
    }
    const handlerTicketRewardModal = (item:SupportTicketType) => {
        dispatch(showRewardSupportTicket({data: item, modalEditTitle: t('support:rewardTitle')}));
    }
    const handlerTicketRewardCancel = (dispatch:any) =>{
        dispatch(hideRewardModalSupportTicket());
    }

    const handlerTicketMessageRemoveCancel = (dispatch:any) =>{
        dispatch(removeSupportTicketMessageModalHide());
    }

    const handlerTicketMessageRemove = (event:any, dispatch:any) =>{
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){

            if (isSupportService){
                dispatch(onRemoveSupportServiceTicketMessage({data: event, modalEditTitle: t('support:deleteMessage')}));
            } else {
                dispatch(onRemoveSupportTicketMessage({data: event, modalEditTitle: t('support:deleteMessage')}));
            }

            setTimeout(() => {
                loadTicketMessages();
            }, 1000);
        }
    }

    const handlerTicketScoreSend = (event:any, dispatch:any) =>{
        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(onSendSupportTicketReward(event));

            setTimeout(() => {
                dispatch(getSupportTicketDetail({uuid: uuid}));
            }, 1000);
        }
    }

    const handleReadMessages = () => {

        let isNeedRead = false;

        if (isSupportService){
            (ticketMessages || []).map((item:SupportTicketMessageType)=>{
                if (!item.isSupportAnswer && item.status === TicketMessageStatus.NEW.status){
                    isNeedRead = true;
                }
                return item;
            })

            if (isNeedRead){

                setTimeout(() => {
                    dispatch(readSupportServiceTicketMessages({uuid: uuid}));

                    setTimeout(() => {
                        dispatch(getSupportServiceTicketMessages(supportTicket.selectedItem));
                        dispatch(getSupportUserBadges());
                    }, 1000);

                }, 1000);
            }

        } else {
            (ticketMessages || []).map((item:SupportTicketMessageType)=>{
                if (item.isSupportAnswer && item.status === TicketMessageStatus.NEW.status){
                    isNeedRead = true;
                }
                return item;
            })

            if (isNeedRead){
                setTimeout(() => {
                    dispatch(readSupportTicketMessages({uuid: uuid}));

                    setTimeout(() => {
                        dispatch(getSupportTicketMessages(supportTicket.selectedItem));
                        dispatch(getSupportUserBadges());
                    }, 1000);

                }, 1000);
            }
        }
    }

    useEffect(()=>{
        if (ticketMessages!== undefined && ticketMessages.length>0){
            setTimeout(() => {
                handleReadMessages();
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ticketMessages])


    return (
        <>
            {isLoading ?
                <>
                    <BreadCrumb title={t('menu:supportTicket')} pageTitle={t('menu:main')} />
                    <Loader/>
                </>
                :
                ticket !== null ?
                    <>
                        <Row>
            <Col lg={12}>
                <Card className="mt-n4 mx-n4 mb-n5">
                    <div className="bg-soft-warning">
                        <CardBody className="pb-4 mb-5">
                            <Row>
                                <div className="col-md">
                                    <Row className="align-items-center">
                                        <div className="col-md-auto">
                                            <div className="avatar-md mb-md-0 mb-4">
                                                <div className="avatar-title rounded-circle">
                                                    {ticket?.owner &&  ticket.owner.avatar ?
                                                        <img src={ticket.owner.avatar} alt="" className="img-fluid p-1 rounded-circle" />
                                                        :
                                                        <span className="fs-23 text-uppercase">{ticket.owner.name ? ticket.owner.name[0] : '#'}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <h4 className="fw-semibold" id="ticket-title">{ticket.name}</h4>
                                            <div className="hstack gap-3 flex-wrap">
                                                <div className="text-muted"><i className="ri-at-line align-bottom me-1"/> <span id="ticket-client">
                                                    {ticket?.owner && ticket.owner.email && ticket.owner.email}</span></div>
                                                <div className="vr"></div>
                                                <div className="text-muted">
                                                    <i className="ri-hashtag align-bottom me-1"/> <span id="ticket-code">
                                                   {ticket.code}</span></div>
                                                <div className="vr"></div>
                                                <div className="text-muted">{t('support:createdAt')} : <span className="fw-medium" id="create-date">{moment(ticket.createdAt).format('llll')}</span></div>
                                                {ticket.updatedAt &&
                                                    <>
                                                        <div className="vr"></div>
                                                            <div className="text-muted">{t('support:updatedAt')} : <span className="fw-medium" id="due-date">
                                                                {
                                                                    moment(ticket.updatedAt).add('days', 1).isBefore(moment())?
                                                                        moment(ticket.updatedAt).format('llll')
                                                                        :
                                                                        moment(ticket.updatedAt).fromNow()
                                                                }
                                                            </span>
                                                        </div>
                                                    </>
                                                }
                                                <div className="vr"></div>
                                                {// eslint-disable-next-line
                                                    (Object.keys(TicketStatus) as Array<keyof typeof TicketStatus>).map((key) => {
                                                    if (TicketStatus[key].status===ticket.status){
                                                        return (
                                                            <span key={'tickerStatus_'+ticket.status} className={"badge rounded-pill fs-12 "+TicketStatus[key].class}> {t('support:ticketStatus'+TicketStatus[key].label)} </span>
                                                        );
                                                    }
                                                })
                                                }
                                                {// eslint-disable-next-line
                                                    (Object.keys(TicketPriority) as Array<keyof typeof TicketPriority>).map((key) => {
                                                    if (TicketPriority[key].status===ticket.tier){
                                                        return (
                                                            <span key={'ticketPriority_'+ticket.tier} className={"badge rounded-pill fs-12 "+TicketPriority[key].class}> {t('support:ticketPriority'+TicketPriority[key].label)} </span>
                                                        );
                                                    }
                                                })
                                                }
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                                {!isSupportService &&
                                    ticket.status !== TicketStatus.FINAL.status &&
                                    <div className="col-md-auto d-flex align-items-center">
                                        <button type="button"
                                                onClick={()=>{handlerTicketRewardModal(ticket)}}
                                                className="btn btn-success btn-label waves-effect waves-light"><i
                                            className="ri-check-double-line label-icon align-middle fs-16 me-2"/>{t('support:closeTicket')}
                                        </button>
                                    </div>
                                }
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
                        </Row>
                        <Row>

                            <Col xxl={9}>
                                <Card>
                                    <CardBody className="p-4">
                                        <h6 className="fw-bold text-uppercase mb-3">{t('support:ticketText')}</h6>
                                        <div style={{whiteSpace: "pre-wrap"}}>{ticket.text}</div>
                                    </CardBody>
                                </Card>
                                { ticket.media && ticket.media.length>0 &&
                                <Card>
                                    <CardHeader>
                                        <h6 className="card-title fw-semibold mb-0">{t('support:attachedFiles')}</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                        {(ticket.media || []).map((item:MainFileType, key:number)=>(
                                            <Col xxl={4} sm={4} key={'fileItem_'+key}>
                                                <FileItem file={item} />
                                            </Col>
                                        ))}
                                        </Row>
                                    </CardBody>
                                </Card>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={9}>
                                <Card>
                                    <CardHeader className="align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">{t('support:supportHistory')}</h4>
                                        <div className="flex-shrink-0">
                                            <button type="button"
                                                    onClick={handleTicketMessageForm}
                                                    className="btn btn-primary btn-label waves-effect waves-light"><i
                                                className="ri-edit-box-fill label-icon align-middle fs-16 me-2"/> {t('support:goOnWithSupport')}
                                            </button>
                                        </div>
                                    </CardHeader>

                                    {(editTicketMessage || (ticketMessages || []).length>0 || (isLoadingSupportTicketMessage && ((ticketMessages || []).length<1))) &&
                                    <CardBody>
                                        {editTicketMessage &&
                                            <div className={"mb-4"}>
                                            <TicketMessageForm isSupportService={isSupportService}/>
                                            </div>
                                        }
                                        {isLoadingSupportTicketMessage && ((ticketMessages || []).length<1) &&
                                            <Loader/>
                                        }
                                        {(ticketMessages || []).map((item:SupportTicketMessageType, key:number)=>(
                                                        <ListItem
                                                            item={item}
                                                            isReward={!isSupportService && key===0 && item.isSupportAnswer}
                                                            isSupportService={isSupportService}
                                                            onRemove={handlerTicketMessageRemoveModal} key={'ticketMessage_'+key}/>
                                            ))
                                        }
                                    </CardBody>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </>
                    :
                    t('support:ticketNotFound')
            }
            <Row>
                <Col xxl={9}>
                    <div className="pb-4">
                        <BackButton name={t('support:allTickets')}/>
                    </div>
                </Col>
            </Row>
            <RemoveModal
                onRemove={handlerTicketMessageRemove}
                handleClose={handlerTicketMessageRemoveCancel}
                removeItem={supportTicketMessage.selectedItem}
                isOpen={supportTicketMessage.removeModalShow}
                modalTitle={supportTicketMessage.modalEditTitle}/>
            <RewardForm
                isOpen={isRewardTicketShow}
                handleClose={handlerTicketRewardCancel}
                modalTitle={supportTicket.modalEditTitle}
                onSend={handlerTicketScoreSend}
                rewardItem={ticket}
            />
        </>
    );
};

export default SupportTicketDetailIndex;


