import React, { useEffect, useState } from "react";
import { Row, Button, Card, CardBody, Col } from 'reactstrap';
import { useLocation } from 'react-router-dom';

import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {nameToEmoji} from "gemoji";
// hooks
import { useRedux } from "../../../hooks";

import UpgradeFareModal from "../../../components/UpgradeFareModal";
import Calendar from "../../../components/Calendar";
import {getContentConnectorDataParams} from "../../../redux/Content/ConnectorDataParam/actions";

import {getPeerActivityTypes} from "../../../redux/Content/PeerActivityType/actions";
import {getPeerActivityModes} from "../../../redux/Content/PeerActivityMode/actions";
import {PeerActivityModeCode, PeerActivityModeType} from "../../../redux/Content/PeerActivityMode/types";
import {PeerActivityActionButton, PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";


import {ContentConnectorSocialCalendar} from "../../../redux/Content/Connector/types";
import CalendarFilterModal from "../../../components/CalendarFilter";
import {getContentProjects} from "../../../redux/Content/Project/actions";
import {getContentTemplates} from "../../../redux/Content/Template/actions";
import {getContentPeerTargetTypes} from "../../../redux/Content/PeerTargetType/actions";

import {
    setActivityMode,
    setEditPeerActivityItems,
    setPeerActivityInitialized, showPeerActivityEditModal,
} from "../../../redux/Content/PeerActivity/actions";
import {PeerActivityStatus} from "../../../redux/Content/PeerActivityStatus/types";
import Loader from "../../../components/Loader";
import KanBanActivities from "../KanBanPeerActivity/KanBan";
import {getContentConnectorHandlers} from "../../../redux/Content/ConnectorHandler/actions";
import {
    aiTextGenerateTitleOpenModal
} from "../../AI/TextGenerate/TextGenerateActions";
import {getPeerActivityIdeas} from "../../../redux/Content/PeerActivityIdea/actions";
import {Draggable} from "@fullcalendar/interaction";
import ExternalViewItems from "./ExternalViewItems";
import {getSaleIntegrations} from "../../../redux/Sale/Integration/actions";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {SaleIntegrationHandlerCodes, SaleIntegrationHandlerTypes} from "../../../redux/Sale/IntegrationHandler/types";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {convertToMomentLocale} from "../../../helpers/functions";

const CalendarIndex = () => {

    const { t, i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));

    const location = useLocation();
    const { hash, pathname, search } = location;

    const { dispatch, useAppSelector } = useRedux();
    const [showFilter, setShowFilter]  = useState(false);
    const [selectedPeerActivity, setSelectedPeerActivity] = useState<PeerActivityType>(null)
    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ isShowPeerChannelEmptyDanger, setShowPeerChannelEmptyDanger ] = useState<boolean>(false);
    const [ isFilterSet, setIsFilterSet ] = useState<boolean>(false);
    const [ isActivitiesLoading, setActivitiesLoading ] = useState<boolean>(false);
    const [ isCalendarView, setCalendarView ] = useState<boolean>(pathname === route.CONTENT_ACTIVITY_PLAN);
    const [ isKanBanView, setKanBanView ] = useState<boolean>(pathname === route.CONTENT_ACTIVITY_KANBAN);
    const [ peerActivityIdeas, setPeerActivityIdeas] = useState<PeerActivityType[]>([]);
    const [ peerActivityIdeaSearch, setPeerActivityIdeaSearch] = useState<string>('');
    const [ isShowIdeaBlock, setShowIdeaBlock ] = useState<boolean>(false);
    const [saleIntegrations, setSaleIntegrations] = useState<SaleIntegrationType[]>([]);

    const {
        peerActivity,
        peerActivityModes,
        peerActivityTypes,
        peerChannels,
        connectorDataParam,
        isFetched,
        contentActivitiesFilter,
        peerActivityIdea,
        saleIntegration
    } = useAppSelector(state => ({
        peerActivity: state.PeerActivity,
        peerActivityModes: state.PeerActivityMode,
        peerActivityTypes: state.PeerActivityType,
        peerChannels: state.ContentPeerChannel,
        connectorDataParam: state.ContentConnectorDataParam,
        isFetched: state.ContentPeerChannel.isFetched,
        contentActivitiesFilter: state.ContentActivitiesFilter,
        peerActivityIdea: state.PeerActivityIdea,
        saleIntegration: state.SaleIntegration
    }));

    const handleShowFilter = () => {
        setShowFilter(!showFilter);
    }

    useEffect(() => {
        setLoaded(peerActivity.isInitialized);
    },[peerActivity?.isInitialized]);

    useEffect(() => {
        setActivitiesLoading(peerActivity.getPeerActivityLoading);
    },[peerActivity?.getPeerActivityLoading]);

    useEffect(() => {
       if (contentActivitiesFilter?.isSet){
           setIsFilterSet(contentActivitiesFilter.isSet);
       } else {
           setIsFilterSet(false);
       }
    },[contentActivitiesFilter?.isSet]);

    useEffect(() => {
        dispatch(getPeerActivityTypes({maxResults:1000}));
        dispatch(getContentProjects());
        dispatch(getContentPeerChannels());
        dispatch(getPeerActivityModes());
        dispatch(getContentConnectorDataParams());
        dispatch(getContentTemplates({maxResults:1000}));
        dispatch(getContentPeerTargetTypes());
        dispatch(getContentConnectorHandlers());
        dispatch(getPeerActivityIdeas());
        dispatch(getSaleIntegrations({maxResults:1000}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        setSelectedPeerActivity(peerActivity.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [peerActivity?.selectedItem]);

    useEffect(()=>{
        if (checkInitialDataLoaded()){
           // handleNewActivity();
        } else {
            if (isFetched){
                if ((peerChannels.items || []).length<1) {
                    setShowPeerChannelEmptyDanger(true);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        peerActivityModes.items,
        peerActivityTypes.items,
        peerChannels.items,
        connectorDataParam.items
    ])

    useEffect(() => {
        if (saleIntegration?.items && (saleIntegration?.items || []).length>0){
            setSaleIntegrations((saleIntegration.items || []).filter((item:SaleIntegrationType)=>{return item.enabled && item?.handler?.code === SaleIntegrationHandlerCodes.WIDGET_TELEGRAM && item.handler.type === SaleIntegrationHandlerTypes.OUTBOUND}));
        } else {
            setSaleIntegrations([]);
        }
    },[saleIntegration?.items]);

    useEffect(()=>{

        if ( peerActivityIdea?.items &&  peerActivityIdea.items.length>0){

            const regEx = new RegExp( peerActivityIdeaSearch, 'g' );

// eslint-disable-next-line
            setPeerActivityIdeas([...peerActivityIdea.items.filter((item:PeerActivityType)=>{
                if (peerActivityIdeaSearch && peerActivityIdeaSearch.length>0){
                    if (item.text && item.text.length>0) {
                        return item.text.match(regEx);
                    }
                } else {
                    return item;
                }
            })]);
        } else {
            setPeerActivityIdeas([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        peerActivityIdea.items,
        peerActivityIdeaSearch
    ])

    useEffect(() => {
        if (isCalendarView && isShowIdeaBlock) {
            let draggableEl = document.getElementById("external-events");
            new Draggable(draggableEl, {
                 itemSelector: ".external-event",
                 eventData: function (eventEl) {
                     let uuid = eventEl.dataset.uuid

                     return {
                         id: uuid,
                         create: false
                     }
                 }
            });
        }
    },[isCalendarView, isShowIdeaBlock]);

    const checkInitialDataLoaded = () => {
        if ((peerActivityModes && peerActivityModes.items && peerActivityModes.items.length>0)
            &&
            (peerActivityTypes && peerActivityTypes.items && peerActivityTypes.items.length>0)
                &&
            (peerChannels && peerChannels.items && peerChannels.items.length>0)
                &&
            (connectorDataParam && connectorDataParam.items && connectorDataParam.items.length>0)
        ){
            dispatch(setPeerActivityInitialized());
            return true;
        }

        return false;
    }

     const handleNewActivity = (eventDate?:number, mode?: string) => {

        const selectedPeerActivityMode = mode ? mode : PeerActivityModeCode.ACTIVITY;

            const peerActivityMode = peerActivityModes.items.filter((item: PeerActivityModeType, key: number) => (item.code === selectedPeerActivityMode))[0];

            const peerActivityType = peerActivityTypes.items[0];
            const draftStatus = PeerActivityStatus.STATUS_NEW;

            const newPeerActivity: PeerActivityType = {
                enabled: true,
                type: peerActivityType,
                mode: peerActivityMode,
                name: peerActivityMode && peerActivityMode.code && peerActivityMode.code[0].toUpperCase() + peerActivityMode.code.slice(1),
                activeFrom: eventDate ? moment(eventDate,'X').add(30, 'minutes').format() : moment().add(30, 'minutes').format(),
            }

            let newPeerActivities=[];

            peerChannels.items.map((item: ContentPeerChannelType, key: number) => {
                if (
                    (item.isManaged)
                    &&
                    (item.connector.isConnected)
                    &&
                    (Object.values(ContentConnectorSocialCalendar) as string[]).includes(item.connector.handler.type))
                 {

                    let addPeerActivity = false;

                    if (selectedPeerActivityMode === PeerActivityModeCode.ACTIVITY && item.connector.handler && item.connector.handler.canAddPost === true) {
                        addPeerActivity = true;
                    } else if (selectedPeerActivityMode === PeerActivityModeCode.SHORTS && item.connector.handler && item.connector.handler.canAddShort === true) {
                        addPeerActivity = true;
                    } else if (selectedPeerActivityMode === PeerActivityModeCode.STORY && item.connector.handler && item.connector.handler.canAddStory === true) {
                        addPeerActivity = true;
                    } else if (selectedPeerActivityMode === PeerActivityModeCode.BIO && item.connector.handler && item.connector.handler.canChangeBio === true) {
                        addPeerActivity = true;
                    }

                    if ((item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT) && (item.type === 'personal')){
                        addPeerActivity = false;
                    }

                    if (addPeerActivity) {

                        let newActivityItem = {
                            ...newPeerActivity,
                            enabled: false,
                            channel: item,
                            status: draftStatus
                        }

                        newPeerActivities.push(newActivityItem);
                    }
                }
                return item;
            });

//IDEAS
             if (selectedPeerActivityMode === PeerActivityModeCode.ACTIVITY) {
                 peerChannels.items.map((item: ContentPeerChannelType, key: number) => {
                     if (item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS) {
                         let newActivityItem = {
                             ...newPeerActivity,
                             enabled: false,
                             channel: item,
                             status: draftStatus
                         }

                         newPeerActivities.push(newActivityItem);
                     }
                     return item;
                 });
             }
            if (newPeerActivities.length>0){
                dispatch(setActivityMode(mode));
                dispatch(setEditPeerActivityItems(newPeerActivities));
                dispatch(showPeerActivityEditModal());
            } else {
                dispatch(setActivityMode(mode));
                dispatch(setEditPeerActivityItems([]));
                dispatch(showPeerActivityEditModal());
            }
    }

    const handleAITextGenerate = () =>{
        aiTextGenerateTitleOpenModal(null,dispatch);
    }

    const handleToggleIdeaBlock = () =>{
        setShowIdeaBlock(!isShowIdeaBlock);
    }

    return (
        <>

                <Row className="g-4 mb-3">
                    {isShowPeerChannelEmptyDanger &&
                    <div className="d-flex flex-row flex-grow-1">
                        <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{" "}<span dangerouslySetInnerHTML={{__html: t('channel:peerChannelsAbsentError').replace(/%link%/,route.CONTENT_CHANNELS_LIST)}}/><span className="align-bottom ps-1">{nameToEmoji.upside_down_face}</span>
                        </div>
                    </div>
                    }

                        <div className="d-flex flex-row  flex-grow-1">
                            <div className="d-flex flex-row flex-grow-1">
                                <button className="btn btn-primary btn-label waves-effect waves-light me-2" onClick={()=>handleNewActivity(null,PeerActivityModeCode.ACTIVITY)} disabled={!isLoaded}>
                                    <i className="ri-article-line label-icon align-bottom fs-16 me-2"/> {t('peerActivity:newPost')}
                                </button>
                                <button className="btn btn-primary btn-label waves-effect waves-light me-2" onClick={()=>handleNewActivity(null,PeerActivityModeCode.SHORTS)} disabled={!isLoaded}>
                                    <i className="ri-video-line label-icon align-bottom fs-16 me-2"/> {t('peerActivity:newReel')}
                                </button>
                                <button className="btn btn-primary btn-label waves-effect waves-light me-2" onClick={()=>handleNewActivity(null,PeerActivityModeCode.STORY)} disabled={!isLoaded}>
                                    <i className="ri-star-smile-line label-icon align-bottom fs-16 me-2"/> {t('peerActivity:newStory')}
                                </button>

                                <button className="btn btn-primary btn-label waves-effect waves-light me-2" onClick={()=>handleNewActivity(null,PeerActivityModeCode.BIO)} disabled={!isLoaded}>
                                    <i className="mdi mdi-bio label-icon align-bottom fs-20 me-2"/> {t('peerActivity:newBIO')}
                                </button>

                                <button className="btn btn-outline-primary btn-label waves-effect waves-light me-2" onClick={handleAITextGenerate} disabled={!isLoaded}>
                                    <i className="mdi mdi-pencil-plus-outline label-icon align-bottom fs-20 me-2"/>Magic writer
                                </button>
                                { isActivitiesLoading &&
                                <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center" >
                                    <div className="d-flex justify-content-center align-items-center calendarLoader">
                                    <Loader addClassNames=""/>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="d-flex flex-row flex-shrink-0">

                                <button type="button" className="btn btn-primary btn-icon position-relative" onClick={()=>handleShowFilter()}><i className="ri-filter-3-line"/>
                                    { isFilterSet &&
                                    <span
                                        className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-1"><span
                                        className="visually-hidden"></span></span>
                                    }
                                </button>

                            </div>
                        </div>

                </Row>



            {isCalendarView ?
                <Row>
                    {isShowIdeaBlock &&
                    <Col className="smallIdeasBlock pe-0">
                        <ExternalViewItems items={peerActivityIdeas}/>
                    </Col>
                    }
                    <Col>
                        <Card className="card-h-100">
                            <CardBody>
                                <Calendar
                                    id={"eventsCalendar"}
                                    handleNewActivity={handleNewActivity}
                                    toggleIdeasBlock={handleToggleIdeaBlock}
                                    />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                :
                <Row>
                    <KanBanActivities
                        handleNewActivity={handleNewActivity}
                    />
                </Row>
            }

                <CalendarFilterModal isShow={showFilter} handleClose={handleShowFilter}/>

                {/*<RemoveModal*/}
                {/*    onRemove={onEntityRemove}*/}
                {/*    handleClose={onEntityRemoveModalHide}*/}
                {/*    removeItem={contentConnectors.selectedItem}*/}
                {/*    isOpen={contentConnectors.removeModalShow}*/}
                {/*    modalTitle={contentConnectors.modalEditTitle}/>*/}
                <UpgradeFareModal/>
        </>
    );
};

export default CalendarIndex;


