import React, { useState } from "react";
import {MediaFilesType, MediaFileType} from "../../../data";
import LightBox from "../../../components/LightBox";
import {useTranslation} from "react-i18next";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";

interface ImageMoreMenuProps {
    onDelete: () => void;
    isFromMe: boolean;
}

export const ImageMoreMenu = ({ onDelete, isFromMe }: ImageMoreMenuProps) => {

    const { t } = useTranslation(['common']);

    return (
        <div className="message-img-link">
            <ul className="list-inline mb-0">
                <UncontrolledDropdown
                    tag="li"
                    color="none"
                    className="list-inline-item dropdown"
                >
                    <DropdownToggle tag="a" role="button" className="dropdown-toggle">
                        <i className="ri-more-fill"/>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            to="#"
                        >
                            <i className="ri-download-2-line me-2 text-muted align-bottom"/>
                            {t('common:download')}
                        </DropdownItem>
                        {/*<DropdownItem*/}
                        {/*    className="reply-message"*/}
                        {/*    to="#"*/}
                        {/*    data-bs-toggle="collapse"*/}
                        {/*    data-bs-target=".replyCollapse"*/}
                        {/*>*/}
                        {/*    <i className="ri-reply-line me-2 text-muted align-bottom"/>*/}
                        {/*    {t('common:reply')}*/}
                        {/*</DropdownItem>*/}
                        { isFromMe ?
                            <DropdownItem
                                className="delete-item"
                                to="#"
                                onClick={onDelete}
                            >
                                <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"/>
                                {t('common:delete')}
                            </DropdownItem>
                            : '' }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </ul>
        </div>
    );
};

interface ImageProps {
    image: MediaFileType;
    onImageClick: (id: number) => void;
    index: number;
    onDeleteImg: (imageId: string | number) => void;
    isFromMe: boolean;
}
export const Image = ({ image, onImageClick, index, onDeleteImg, isFromMe }: ImageProps) => {
    const onDelete = () => {
        onDeleteImg(image.uuid);
    };
    return (
        <div className="message-img-list">
            <div>
                <Link
                    className="popup-img d-inline-block"
                    to={"#"}
                    onClick={() => onImageClick(index)}
                >
                    <img src={image.url} alt="" className="rounded border" />
                </Link>
            </div>
            <ImageMoreMenu onDelete={onDelete} isFromMe={isFromMe} />
        </div>
    );
};

interface ImagesProps {
    images: MediaFilesType;
    onDeleteImg: (imageId: string | number) => void;
    isFromMe: boolean;
}

export const Images = ({ images, onDeleteImg, isFromMe }: ImagesProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(0);
    const onImageClick = (id: number) => {
        setSelected(id);
        setIsOpen(true);
    };
    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="message-img mb-0">
                {(images.items || []).map((image: MediaFileType, key: number) => (
                    <Image
                        image={image}
                        key={key}
                        index={key}
                        onImageClick={onImageClick}
                        onDeleteImg={onDeleteImg}
                        isFromMe={isFromMe}
                    />
                ))}
            </div>
            {isOpen && (
                <LightBox
                    isOpen={isOpen}
                    images={images.items}
                    onClose={onClose}
                    defaultIdx={selected}
                />
            )}
        </>
    );
};

interface AttachmentsProps {
    attachments: MediaFilesType | undefined;
    isFromMe: boolean;
}

export const Attachments = ({ attachments, isFromMe }: AttachmentsProps) => {
    return (
        <>
            {(attachments.items || []).map((attachment: MediaFileType, key: number) => (
                <div
                    key={key}
                    className={classnames("p-3", "border-primary", "border rounded-3", {
                        "mt-2": key !== 0,
                    })}
                >
                    <div className="d-flex align-items-center attached-file">
                        <div className="flex-shrink-0 avatar-sm me-3 ms-0 attached-file-avatar">
                            <div className="avatar-title bg-soft-primary text-primary rounded-circle font-size-20">
                                <i className="ri-attachment-2"/>
                            </div>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                            <div className="text-start">
                                <h5 className="font-size-14 mb-1">{attachment.name}</h5>
                                {attachment.description &&
                                    <p className="text-muted text-truncate font-size-13 mb-0">
                                        {attachment.description}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="flex-shrink-0 ms-4">
                            <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
                                <div>
                                    <a
                                        href={attachment.url ? attachment.url : "#"}
                                        className="text-muted"
                                        target="_blank"
                                        rel="noreferrer"
                                        download={attachment.name ? attachment.name : "#"}
                                    >
                                        {/*<Link*/}
                                        {/*  to={attachment.downloadLink ? attachment.downloadLink : "#"}*/}
                                        {/*  className="text-muted"*/}
                                        {/*  target="_blank"*/}
                                        {/*  download={attachment.name ? attachment.name : "#"}*/}
                                        {/*>*/}
                                        <i className="bx bxs-download"/>
                                        {/*</Link>*/}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};