import React, {useEffect, useRef, useState} from "react";
import {MainFileImageTypes, MainFileType, MainFileVideoTypes} from "../../../redux/Main/File/types";
import Lightbox from "react-image-lightbox";
import InputSwitch from "../../../components/InputSwitch";
import {MediaDimensionType} from "../../../data";
import {ServiceMessageType, ServiceMessageTypes} from "../../../redux/Content/PeerActivity/types";
import {useTranslation} from "react-i18next";
import {UncontrolledTooltip} from "reactstrap";

interface ItemProps {
    item: MainFileType;
    isPopup: boolean;
    isShowSwitch: boolean;
    onChange?: (value:boolean)=>void;
    acceptedFiles?: string;
    acceptedDimensions?: MediaDimensionType;
    idSwitch?: string
    isMultiple?: boolean;
}

const MediaPreview = ({ item, isPopup = false, onChange, isShowSwitch= false, acceptedFiles, acceptedDimensions, isMultiple = true, idSwitch }: ItemProps) => {

    const { t, i18n } = useTranslation();

    const [mediaItem, setMediaItem] = useState<MainFileType>(null);
    const [isImage, setImage] = useState<boolean>(false);
    const [isVideo, setVideo] = useState<boolean>(false);
    const [isPopupShow, setPopupShow] = useState<boolean>(false);

    const fileRef  = useRef(null);

    const handleLoad = () => {

        let _mediaItem = mediaItem;

        if (fileRef?.current?.naturalWidth > 0) {
                _mediaItem.width = fileRef.current.naturalWidth;
        }
        if (fileRef?.current?.naturalHeight > 0) {
                _mediaItem.height = fileRef.current.naturalHeight;
        }
        if (fileRef?.current?.videoWidth > 0) {
            _mediaItem.width = fileRef.current.videoWidth;
        }
        if (fileRef?.current?.videoHeight > 0) {
            _mediaItem.height = fileRef.current.videoHeight;
        }

        _mediaItem.serviceMessages=[];

        if (acceptedFiles && acceptedFiles.length>0 &&
            (
                acceptedFiles.indexOf(_mediaItem.contentType.split('/').pop().toLowerCase())<0
                &&
                acceptedFiles.indexOf(_mediaItem.fileName.split('.').pop().toLowerCase())<0
            )
        ){
            _mediaItem.serviceMessages.push({
                type: ServiceMessageTypes.ERROR,
                message: t('notification:invalidFileType')
            });

            _mediaItem.enabled = false;
        }

        if (acceptedDimensions) {
            if (acceptedDimensions?.minWidth !== undefined
                && acceptedDimensions.minWidth !== null
                && _mediaItem?.width !== undefined
                && _mediaItem?.width !== null
                && acceptedDimensions.minWidth >= _mediaItem.width) {
                    _mediaItem.serviceMessages.push({
                        type: ServiceMessageTypes.ERROR,
                        message: t('notification:widthShouldMoreThan')+' '+acceptedDimensions.minWidth+t('common:px')
                    });
                    _mediaItem.enabled = false;
                }

            if (acceptedDimensions?.maxWidth !== undefined
                && acceptedDimensions.maxWidth !== null
                && _mediaItem?.width !== undefined
                && _mediaItem?.width !== null
                && acceptedDimensions.maxWidth <= _mediaItem.width) {
                _mediaItem.serviceMessages.push({
                    type: ServiceMessageTypes.ERROR,
                    message: t('notification:widthShouldLessThan')+' '+acceptedDimensions.maxWidth+t('common:px')
                });
                _mediaItem.enabled = false;
            }

            if (acceptedDimensions?.minHeight !== undefined
                && acceptedDimensions.minHeight !== null
                && _mediaItem?.height !== undefined
                && _mediaItem?.height !== null
                && acceptedDimensions.minHeight >= _mediaItem.height) {
                _mediaItem.serviceMessages.push({
                    type: ServiceMessageTypes.ERROR,
                    message: t('notification:heightShouldMoreThan')+' '+acceptedDimensions.minHeight+t('common:px')
                });
                _mediaItem.enabled = false;
            }

            if (acceptedDimensions?.maxHeight !== undefined
                && acceptedDimensions.maxHeight !== null
                && _mediaItem?.height !== undefined
                && _mediaItem?.height !== null
                && acceptedDimensions.maxHeight <= _mediaItem.height) {
                _mediaItem.serviceMessages.push({
                    type: ServiceMessageTypes.ERROR,
                    message: t('notification:heightShouldLessThan')+' '+acceptedDimensions.maxHeight+t('common:px')
                });
                _mediaItem.enabled = false;
            }
        }

        setMediaItem({..._mediaItem});

        if (isShowSwitch && mediaItem?.enabled === true && _mediaItem?.enabled === false){
            onChange(false);
        }
    }

    useEffect(()=>{

        if (
            (Object.values(MainFileImageTypes) as string[]).includes(item.contentType.split('/').pop().toLowerCase())
            ||
            (Object.values(MainFileImageTypes) as string[]).includes(item.fileName.split('.').pop().toLowerCase())
        ){
            setImage(true);
            setVideo(false);
        }
        if (
            (Object.values(MainFileVideoTypes) as string[]).includes(item.contentType.split('/').pop())
            ||
            (Object.values(MainFileVideoTypes) as string[]).includes(item.fileName.split('.').pop())
        ){
            setImage(false);
            setVideo(true);
        }

        setMediaItem({...item});

    },[item])

    useEffect(()=>{

        if (item?.url === mediaItem?.url && item?.enabled !== mediaItem?.enabled){
            setMediaItem({...mediaItem, enabled: item.enabled});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item?.enabled])


    const handleCloseMediaPopup = () => {
        setPopupShow(false);
    }
    const handleOpenMediaPopup = () => {
        setPopupShow(true);
    }

    return (
     <>
         {mediaItem?.url &&
             <div>
             <div className="d-flex flex-column align-items-center">
                 <div>
                     <span className="badge bg-primary fs-8 text-center">
                         {((mediaItem?.width && mediaItem?.width>0) || (mediaItem?.height && mediaItem?.height>0)) ?
                             <>
                                 {isImage &&
                                    <i className="ri-image-line fs-8 fw-normal align-bottom me-1"/>
                                 }
                                 {isVideo &&
                                     <i className="ri-movie-line fs-8 fw-normal align-bottom me-1"/>
                                 }
                                {(mediaItem?.width && mediaItem?.width>0) &&
                                    mediaItem.width
                                }
                                x
                                {(mediaItem?.height && mediaItem?.height>0) &&
                                    mediaItem.height
                                }
                             </>
                             :
                             <>
                                 <i className="ri-file-line fs-8 fw-normal align-bottom"/>
                             </>
                         }
                     </span>
                 </div>

                 <div>
                     <div className={(mediaItem.enabled?'':' disabledBlock')}>
                 { isImage &&
                     <img
                         data-dz-thumbnail=""
                         height="80"
                         className="avatar-sm rounded bg-light object-cover"
                         alt={mediaItem.fileName}
                         src={mediaItem.downLoadUrl ? mediaItem.downLoadUrl : mediaItem.url}
                         onLoad={handleLoad}
                         ref={fileRef}
                         onClick={()=>handleOpenMediaPopup()}
                     />
                 }
                 { isVideo &&
                    <div className="avatar-sm rounded overflow-hidden">
                        <video
                                className="avatar-sm rounded bg-light object-cover"
                               onLoadedData={handleLoad}
                               ref={fileRef}
                               onClick={()=>handleOpenMediaPopup()}
                        >
                            <source
                                src={mediaItem.downLoadUrl ? mediaItem.downLoadUrl : mediaItem.url}
                                type={"video/"+mediaItem.contentType.split('/').pop()}
                            />
                        </video>
                    </div>
                 }
                     {!isImage && !isVideo &&
                     <>
                         <span className="avatar-sm rounded bg-light ">
                            <i className="ri-file-line fs-12 fw-normal"/>
                         </span>
                     </>
                     }
                     </div>
                 </div>
                 { isPopup && isPopupShow &&
                     <Lightbox mainSrc={mediaItem.url} onCloseRequest={handleCloseMediaPopup} imageTitle={mediaItem.fileName}/>
                 }
             </div>
                 {isShowSwitch &&
                     <div className="d-flex flex-row justify-content-center">
                         {mediaItem?.serviceMessages && (mediaItem.serviceMessages || []).length>0 ?
                             <div>
                                 <i className="ri-alert-fill text-danger fs-12"
                                    id={'id_switch_media_'+idSwitch+'_info'}
                                 />
                                 <UncontrolledTooltip target={'id_switch_media_'+idSwitch+'_info'} placement="top">
                                     {(mediaItem.serviceMessages || []).map((serviceMessage:ServiceMessageType, i: number)=>{
                                         return (
                                             <div key={'key_switch_media_'+idSwitch+'_info_'+i}>{serviceMessage.message}</div>
                                         )
                                     })}
                                 </UncontrolledTooltip>
                             </div>
                             :
                             <InputSwitch
                                 id={'id_switch_media_'+idSwitch+'_enabled'}
                                 value={mediaItem.enabled}
                                 onChange={()=>{
                                     onChange(!mediaItem.enabled);
                                 }}
                                 disabled={mediaItem?.serviceMessages && (mediaItem.serviceMessages || []).length>0}
                                 className={"switch-sm"}
                             />

                         }
                     </div>
                 }
             </div>
         }
     </>
    );
};

export default MediaPreview;
