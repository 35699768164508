import {PeerActivityIdeaActionTypes} from "./types";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";

export const peerActivityIdeaApiResponseSuccess = (actionType: string, data: any) => ({
  type: PeerActivityIdeaActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const peerActivityIdeaApiResponseError = (actionType: string, error: string) => ({
  type: PeerActivityIdeaActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPeerActivityIdeas = () => ({
  type: PeerActivityIdeaActionTypes.GET_CONTENT_PEER_ACTIVITIES_IDEAS,
  payload: {channel: {idPeer: 'ideas', handlerCode: connectorHandlerCode.CONNECTOR_HANDLER_IDEAS}},
});

export const setNeedReloadPeerActivityIdeas = () => ({
  type: PeerActivityIdeaActionTypes.SET_NEED_RELOAD,
});

export const setPeerActivityIdeaInitialized = () => ({
  type: PeerActivityIdeaActionTypes.SET_INITIALIZED,
});

export const showPeerActivityIdeaEditModal = () => ({
  type: PeerActivityIdeaActionTypes.SHOW_MODAL,
});

export const hidePeerActivityIdeaEditModal = () => ({
  type: PeerActivityIdeaActionTypes.HIDE_MODAL,
});
