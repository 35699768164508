import React, { useEffect, useState } from "react";
import {CurrencyType} from "../../redux/Sale/Currency/types";
import {CURRENCY_SYMBOL_EXTRACT_REGEX} from "../../data/settings";


interface InputProps {
    items: Array<CurrencyType>;
    selectedCurrency: CurrencyType,
    onClick: (item:CurrencyType) => void
}
const CurrencySelect = ({items, onClick, selectedCurrency}:InputProps) => {

    return (
        <>
            <div className="btn-group mt-3" role="group" aria-label="Currency select">
            {(items || []).map((item:CurrencyType)=>{
                return(
                    item.enabled &&
                        <button key={"currencySelectButton_"+item.uuid} onClick={()=>{onClick(item)}} type="button" className={"btn "+(item.uuid === selectedCurrency?.uuid ?'btn-primary': 'btn-soft-primary')}>{item.currencyFormat.replace(CURRENCY_SYMBOL_EXTRACT_REGEX,'')}</button>
                )
            })}
            </div>
        </>
    );
}

export default CurrencySelect;