import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";

import {
    getContentQuickAnswers,
    hideRightModalContentQuickAnswer,
    onSendDataContentQuickAnswer
} from "../../../redux/Content/QuickAnswer/actions";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {ContentQuickAnswerType} from "../../../redux/Content/QuickAnswer/types";
import {ContentQuickAnswerValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";

import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import ButtonSpinner from "../../../components/ButtonSpinner";

const RightModal = () => {
    const { t } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        contentQuickAnswer,
        isSending
    } = useAppSelector(state => ({
        contentQuickAnswer: state.ContentQuickAnswer,
        isSending: state.ContentQuickAnswer.isSending
    }));

    const uuid = contentQuickAnswer.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<ContentQuickAnswerType>(contentQuickAnswer.selectedItem);

    useEffect(() => {

        setFormValues(contentQuickAnswer.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalContentQuickAnswer());

    const onDataSubmit = (formData:ContentQuickAnswerType) => {
        let data: any = {};

        if (formData.text) {
            data["text"] = formData.text;
        }
        if (formData.name) {
            data["name"] = formData.name;
        }

        data["enabled"] = formData.enabled;

        if (uuid) {
            data["uuid"] = uuid;
        }

        let params: ContentQuickAnswerType = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            name: data.name && data.name,
            text: data.text && data.text,
        };

        dispatch(onSendDataContentQuickAnswer(params));
    };

    return (
        <>
            <Offcanvas
                direction="end"
                isOpen={contentQuickAnswer.rightModalShow}
                id="quickAnswerEdit"
                toggle={handleClose}
            >

                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ContentQuickAnswerValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">

                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('quickAnswer:'+contentQuickAnswer.modalEditTitle)}
                        </OffcanvasHeader>

                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:name')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="name"
                                        placeholder={t('quickAnswer:enterQuickAnswerName')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>

                            <Row>
                                <div className={ "mb-3"+ (touched.text ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-description-input">{t('quickAnswer:quickAnswer')}</Label>
                                    <div className="textAreaEmojiBlock">

                                        <Field
                                            id="form-text-input"
                                            name="text"
                                            component={InputEmojiArea}
                                            value = {getFieldProps('text').value}
                                            onChange={(text:string) => {
                                                setFieldValue('text', text);
                                            }}
                                            isShowEmoji={true}
                                            isShowAITextGenerate={true}
                                            required={false}
                                            placeholder={t('quickAnswer:enterQuickAnswerText')}
                                        />
                                        {errors.text && touched.text ? (
                                            <div className="invalid-feedback">
                                                {t('validator:'+errors.text)}
                                            </div>
                                        ) :  touched.text && !errors.text ?
                                            <div className="valid-feedback">
                                                {t('common:looksGood')}
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                                <Button variant="light" onClick={handleClose} className="w-100">
                                    {t('common:close')}
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                        disabled={isSending}
                                        className="btn btn-primary w-100 chat-send waves-effect waves-light"
                                >
                                    {t('common:save')}
                                    {isSending &&
                                        <ButtonSpinner/>
                                    }
                                </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

            </Offcanvas>
        </>
    );
}

export default RightModal;