import { takeEvery, fork, put, all, call } from "typed-redux-saga";

import { taskApiResponseSuccess, taskApiResponseError } from "./actions";

import {
  getTasks as getTasksApi,
  removeTask as removeTaskApi,
  updateTask as updateTaskApi,
  getTaskDetail as getTaskDetailApi,
  updateComment as updateCommentApi,
  getComments as getCommentsApi,
  removeComment as removeCommentApi,
  updateTaskTime as updateTaskTimeApi,
  getTaskTimes as getTaskTimesApi,
  removeTaskTime as removeTaskTimeApi,
  copyTask as copyTaskApi,
  changeTaskStatus as changeTaskStatusApi,
  closeGroupTask as closeGroupTaskApi,
  removeGroupTask as removeGroupTaskApi,
  readTaskComments as readTaskCommentsApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {TaskActionTypes} from "./types";

function* getTasks({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTasksApi, data);
    yield put(taskApiResponseSuccess(TaskActionTypes.GET_TASKS, response));
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.GET_TASKS, error));
  }
}
function* getTaskDetail({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTaskDetailApi, data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.GET_TASK_DETAIL, response)
    );
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.GET_TASK_DETAIL, error));
  }
}
function* onSendDataTask({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateTaskApi, data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.UPDATE_TASK, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.UPDATE_TASK, error));
    yield call(showErrorNotification, error);
  }
}

function* onChangeTaskStatus({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(changeTaskStatusApi, data.data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.CHANGE_TASK_STATUS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.CHANGE_TASK_STATUS, error));
    yield call(showErrorNotification, error);
  }
}

function* onCopyDataTask({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(copyTaskApi, data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.COPY_TASK, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.COPY_TASK, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveTask({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeTaskApi, {uuid: data.data.uuid});
    yield put(
        taskApiResponseSuccess(TaskActionTypes.REMOVE_TASK, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.REMOVE_TASK, error));
    yield call(showErrorNotification, error);
  }
}

// function* onRewardTask({ payload: data }: any) {
//   try {
//     const response: Promise<any> = yield call(rewardTaskApi, data);
//     yield put(
//         taskApiResponseSuccess(TaskActionTypes.SEND_REWARD_TASK, response)
//     );
//     yield call(showSuccessNotification, response);
//   } catch (error: any) {
//     yield put(taskApiResponseError(TaskActionTypes.SEND_REWARD_TASK, error));
//     yield call(showErrorNotification, error);
//   }
// }

// function* onReadTaskMessages({ payload: data }: any) {
//   try {
//     const response: Promise<any> = yield call(readTaskMessagesApi, data);
//     yield put(
//         taskApiResponseSuccess(TaskActionTypes.READ_TASK_MESSAGES, response)
//     );
//   } catch (error: any) {
//     yield put(taskApiResponseError(TaskActionTypes.READ_TASK_MESSAGES, error));
//   }
// }

function* getTaskTimes({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTaskTimesApi, {task:data});
    yield put(
        taskApiResponseSuccess(TaskActionTypes.GET_TASK_TIMES, response)
    );
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.GET_TASK_TIMES, error));
  }
}

function* onSendDataTaskTime({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateTaskTimeApi, data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.UPDATE_TASK_TIME, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.UPDATE_TASK_TIME, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveTaskTime({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeTaskTimeApi, {uuid: data.data.uuid});
    yield put(
        taskApiResponseSuccess(TaskActionTypes.REMOVE_TASK_TIME, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.REMOVE_TASK_TIME, error));
    yield call(showErrorNotification, error);
  }
}

function* closeTaskGroup({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(closeGroupTaskApi, data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.CLOSE_TASK_GROUP, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.CLOSE_TASK_GROUP, error));
    yield call(showErrorNotification, error);
  }
}

function* removeTaskGroup({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeGroupTaskApi, data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.REMOVE_TASK_GROUP, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.REMOVE_TASK_GROUP, error));
    yield call(showErrorNotification, error);
  }
}

function* getComments({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getCommentsApi, {task:data});
    yield put(
        taskApiResponseSuccess(TaskActionTypes.GET_COMMENTS, response)
    );
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.GET_COMMENTS, error));
  }
}
function* onSendDataComment({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateCommentApi, data);
    yield put(
        taskApiResponseSuccess(TaskActionTypes.UPDATE_COMMENT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.UPDATE_COMMENT, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveTaskComment({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeCommentApi, {uuid: data.data.uuid});
    yield put(
        taskApiResponseSuccess(TaskActionTypes.REMOVE_COMMENT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.REMOVE_COMMENT, error));
    yield call(showErrorNotification, error);
  }
}


export function* watchGetTasks() {
  yield takeEvery(TaskActionTypes.GET_TASKS, getTasks);
}
export function* watchGetTaskDetail() {
  yield takeEvery(TaskActionTypes.GET_TASK_DETAIL, getTaskDetail);
}
export function* watchOnSendDataTask() {
  yield takeEvery(TaskActionTypes.UPDATE_TASK, onSendDataTask);
}
export function* watchOnChangeTaskStatus() {
  yield takeEvery(TaskActionTypes.CHANGE_TASK_STATUS, onChangeTaskStatus);
}

export function* watchOnCopyTask() {
  yield takeEvery(TaskActionTypes.COPY_TASK, onCopyDataTask);
}

export function* watchOnRemoveTask() {
  yield takeEvery(TaskActionTypes.REMOVE_TASK, onRemoveTask);
}
export function* watchOnCloseTaskGroup() {
  yield takeEvery(TaskActionTypes.CLOSE_TASK_GROUP, closeTaskGroup);
}

export function* watchOnRemoveTaskGroup() {
  yield takeEvery(TaskActionTypes.REMOVE_TASK_GROUP, removeTaskGroup);
}

export function* watchOnSendDataTaskTime() {
  yield takeEvery(TaskActionTypes.UPDATE_TASK_TIME, onSendDataTaskTime);
}
export function* watchOnGetTaskTimes() {
  yield takeEvery(TaskActionTypes.GET_TASK_TIMES, getTaskTimes);
}

export function* watchOnRemoveTaskTime() {
  yield takeEvery(TaskActionTypes.REMOVE_TASK_TIME, onRemoveTaskTime);
}


export function* watchOnSendDataComment() {
  yield takeEvery(TaskActionTypes.UPDATE_COMMENT, onSendDataComment);
}
export function* watchOnGetComments() {
  yield takeEvery(TaskActionTypes.GET_COMMENTS, getComments);
}

export function* watchOnRemoveTaskComment() {
  yield takeEvery(TaskActionTypes.REMOVE_COMMENT, onRemoveTaskComment);
}


// export function* watchOnRewardTask() {
//   yield takeEvery(TaskActionTypes.SEND_REWARD_TASK, onRewardTask);
// }

// export function* watchOnReadTaskMessages() {
//   yield takeEvery(TaskActionTypes.READ_TASK_MESSAGES, onReadTaskMessages);
// }

function* taskSaga() {
  yield all([
      fork(watchGetTasks),
      fork(watchGetTaskDetail),
      fork(watchOnSendDataTask),
      fork(watchOnCopyTask),
      fork(watchOnRemoveTask),
      fork(watchOnGetComments),
      fork(watchOnSendDataComment),
      fork(watchOnChangeTaskStatus),
      fork(watchOnRemoveTaskComment),
      fork(watchOnGetTaskTimes),
      fork(watchOnSendDataTaskTime),
      fork(watchOnRemoveTaskTime),
      fork(watchOnCloseTaskGroup),
      fork(watchOnRemoveTaskGroup)
  ]);
}

export default taskSaga;
