import React from "react";
import { Card, CardBody } from 'reactstrap';
import CountUp from "react-countup";
import {MainIcon} from "../redux/Main/icon";
import {formatNum} from "../helpers/functions"
import '../i18/config';
import {useTranslation} from "react-i18next";
import {TaskStatus} from "../redux/Task/Task/types";

interface StatTileProps {
    label: string,
    start: 0 | number,
    first?: number,
    last: number,
    lastSuffix?:  string,
    deltaLabel: string,
    isLoading: false | boolean,
    customIcon?: string;
    customLabel?: string;
    isFullNumber?:boolean;
}


const AR_DESCENT_SUCCESS = ['bounceRate', 'orderFailureCount', 'orderFailureAmount'];

function calculateDeltaPercent(first, last) {
    return first !== undefined ? (first !== 0 ? Math.ceil((last / first) * 100 - 100) : 0) : null;
}

function setDeltaClasses(deltaPercent, label, defaultTextClass = 'text-danger') {
    let deltaTextClass = defaultTextClass;
    let deltaIconClass = 'ri-arrow-down-line';

    if (deltaPercent !== null) {
        if (deltaPercent > 0) {
            deltaTextClass = AR_DESCENT_SUCCESS.includes(label) ? 'text-danger' : 'text-success';
            deltaIconClass = AR_DESCENT_SUCCESS.includes(label) ? 'ri-arrow-down-line' : 'ri-arrow-up-line';
        } else if (deltaPercent === 0) {
            deltaTextClass = 'text-muted';
            deltaIconClass = null;
        }
    }

    return { deltaTextClass, deltaIconClass };
}

const StatTile = ({ label, start, last, deltaLabel, lastSuffix, first, isLoading, isFullNumber=false, customIcon = null, customLabel= null }: StatTileProps) => {

    const { t, i18n } = useTranslation();
    const deltaPercent = calculateDeltaPercent(first, last);
    const { deltaTextClass, deltaIconClass } = setDeltaClasses(deltaPercent, label);

    let icon = customIcon;
    let iconLabel = customLabel;
    let iconBG = 'bg-info';

    switch (label) {
        case 'members' :
        case 'users' :
            icon = MainIcon.MEMBERS;
            iconBG = 'bg-success';
            break;
        case 'posts' :
            icon = MainIcon.POSTS; //???
            iconBG = 'bg-warning';
            break;
        case 'postsUGS' :
            icon = MainIcon.POSTS; //???
            iconBG = 'bg-warning';
            break;
        case 'views' :
            icon = MainIcon.VIEWS;
            iconBG = 'bg-success';
            break;
        case 'reposts' :
            icon = MainIcon.REPOSTS;
            iconBG = 'bg-success';
            break;
        case 'comments' :
            icon = MainIcon.COMMENTS;
            iconBG = 'bg-warning';
            break;
        case 'er' :
            iconLabel = 'ER';
            break;
        case 'erDay' :
            iconLabel = 'ERD';
            break;
        case 'erView' :
            iconLabel = 'ERV';
            break;
        case 'loveRate' :
            iconLabel = "LR";
            iconBG = 'bg-danger';
            break;
        case 'talkRate' :
            iconLabel = "TR";
            break;
        case 'likes' :
            icon = MainIcon.LIKES;
            iconBG = 'bg-danger';
            break;
        case 'visits' :
            icon = MainIcon.VISITS;
            iconBG = 'bg-success';
            break;
        case 'avgVisitDurationSeconds' :
            icon = MainIcon.AVG_VISIT_DURATION_SECONDS;
            break;
        case 'bounceRate' :
            icon = MainIcon.BOUNCE_RATE;
            break;
        case 'pageDepth' :
            icon = MainIcon.PAGE_DEPTH;
            break;
        case 'sumGoalReachesAny' :
            icon = MainIcon.GOAL_REACH;
            break;
        case 'orderTotalCount' :
            icon = MainIcon.ORDERS;
            break;
        case 'orderTotalAmount' :
            break;
        case 'orderSuccessCount' :
            icon = MainIcon.ORDERS;
            iconBG = 'bg-success';
            break;
        case 'orderSuccessAmount' :
            iconBG = 'bg-success';
            break;
        case 'orderFailureCount' :
            icon = MainIcon.ORDERS;
            iconBG = 'bg-danger';
            break;
        case 'orderFailureAmount' :
            iconBG = 'bg-danger';
            break;
        case 'orderNewCount' :
            icon = MainIcon.ORDERS;
            iconBG = 'bg-warning';
            break;
        case 'orderNewAmount' :
            iconBG = 'bg-warning';
            break;
        case 'taskNewCount' :
            icon = MainIcon.TASK_NEW;
            iconBG = TaskStatus.NEW.colorClass;
            break;
        case 'taskPendingCount' :
            icon = MainIcon.TASK_PENDING;
            iconBG = TaskStatus.PENDING.colorClass;
            break;
        case 'taskClosedCount' :
            icon = MainIcon.TASK_CLOSED;
            iconBG = TaskStatus.FINAL.colorClass;
            break;
        case 'taskOverdueCount' :
            icon = MainIcon.TASK_OVERDUE;
            iconBG = TaskStatus.OVERDUE.colorClass;
            break;
        case 'taskInProgressCount' :
            icon = MainIcon.TASK_INPROGRESS;
            iconBG = TaskStatus.INPROGRESS.colorClass;
            break;
        case 'taskReturnedCount' :
            icon = MainIcon.TASK_RETURNED;
            iconBG = TaskStatus.RETURNED.colorClass;
            break;
    }

            return (
                <Card className="card-animate">
                    <CardBody>
                        <div className="d-flex justify-content-between">
                            <div className="mw-0 d-flex flex-grow-1 flex-column">
                                <div className="d-flex text-nowrap flex-row">
                                    <div className="d-flex flex-grow-1 flex-row align-items-center pe-2 mw-0">
                                    <p className="fw-semibold text-muted mb-0 text-truncate">{t('stat:'+label.toString())}</p>
                                    </div>
                                    <div className="avatar-xs flex-shrink-0">
                                        <span className={"avatar-title rounded-circle fs-2 "+iconBG}>
                                            {icon ?
                                                <i className={"text-white fs-15 "+icon}/>
                                                :
                                                <span className={"text-white fs-12"}>{iconLabel}</span>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <h2 className={"mt-4 ff-secondary fw-semibold text-truncate"+(isLoading?" placeholder-glow":"")}>
                                    {isLoading ?
                                        <span className="placeholder col-6 rounded-2"/>
                                        :
                                        <>
                                        <span className="counter-value" data-target={last}>
                                            {isFullNumber
                                                ?
                                                <CountUp
                                                    start={0}
                                                    end={last}
                                                    separator="'"
                                                    // duration={3}
                                                    //decimals={2}
                                                />
                                                :
                                                <CountUp
                                                    start={0}
                                                    end={formatNum(last,2)[0]}
                                                    // separator="'"
                                                    // duration={3}
                                                    // decimals={2}
                                                />
                                            }
                                        </span>
                                        {isFullNumber ?
                                            <small className="text-muted fs-14 ms-1">{customLabel && customLabel}</small>
                                            :
                                            <small className="text-muted fs-14 ms-1">{lastSuffix && lastSuffix}{formatNum(last,2)[1]}</small>
                                        }
                                        </>
                                    }
                                </h2>
                                {deltaPercent !== undefined &&
                                    <>

                                        <p className={"mb-0 text-muted "+(isLoading?" placeholder-glow":"")}>
                                            {isLoading ?
                                                <span className="placeholder col-6 rounded-2"/>
                                                :
                                            <span className={"badge bg-light mb-0 "+deltaTextClass}>
                                                {deltaIconClass &&
                                                <i className={"align-middle " + deltaIconClass}/>
                                                }
                                                {deltaPercent} %
                                            </span>
                                            }
                                        </p>

                                        <div className={"text-truncate text-muted "+(isLoading?" placeholder-glow":"")}>
                                            {isLoading ?
                                                <span className="placeholder col-12 rounded-2"/>
                                                :
                                                <>
                                                    {t('common:vsPrevious')} {deltaLabel} {t('stat:days')}
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )

}

export default StatTile;