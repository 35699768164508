import {SaleStoreActionTypes, SaleStorePayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleStoreApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleStoreActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleStoreApiResponseError = (actionType: string, error: string) => ({
  type: SaleStoreActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleStores = (data: MainMetaType) => ({
  type: SaleStoreActionTypes.GET_SALE_STORES,
  payload: data,
});

export const showRightModalSaleStore= (data: SaleStorePayload) => ({
  type: SaleStoreActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleStore= () => ({
  type: SaleStoreActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleStore = (data: any) => ({
  type: SaleStoreActionTypes.UPDATE_SALE_STORE,
  payload: data,
});

export const editDataSaleStore = (data: SaleStorePayload) => ({
  type: SaleStoreActionTypes.EDIT_SALE_STORE,
  payload: data,
});

export const removeSaleStoreModalShow = (data: SaleStorePayload) => ({
  type: SaleStoreActionTypes.REMOVE_SALE_STORE_MODAL_SHOW,
  payload: data,
});

export const removeSaleStoreModalHide = () => ({
  type: SaleStoreActionTypes.REMOVE_SALE_STORE_MODAL_HIDE,
});

export const onRemoveSaleStore = (data: SaleStorePayload) => ({
  type: SaleStoreActionTypes.REMOVE_SALE_STORE,
  payload: data,
});