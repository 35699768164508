import {ContentStatCounterActionTypes, ContentStatCounterPayload} from "./types";

export const contentStatCounterApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentStatCounterActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentStatCounterApiResponseError = (actionType: string, error: string) => ({
  type: ContentStatCounterActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentStatCounters = () => ({
  type: ContentStatCounterActionTypes.GET_CONTENT_STAT_COUNTERS,
});

export const showRightModalContentStatCounter= (data: ContentStatCounterPayload) => ({
  type: ContentStatCounterActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalContentStatCounter= () => ({
  type: ContentStatCounterActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataContentStatCounter = (data: any) => ({
  type: ContentStatCounterActionTypes.UPDATE_CONTENT_STAT_COUNTER,
  payload: data,
});

export const editDataContentStatCounter = (data: ContentStatCounterPayload) => ({
  type: ContentStatCounterActionTypes.EDIT_CONTENT_STAT_COUNTER,
  payload: data,
});

export const removeContentStatCounterModalShow = (data: ContentStatCounterPayload) => ({
  type: ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER_MODAL_SHOW,
  payload: data,
});

export const removeContentStatCounterModalHide = () => ({
  type: ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER_MODAL_HIDE,
});

export const onRemoveContentStatCounter = (data: ContentStatCounterPayload) => ({
  type: ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER,
  payload: data,
});