import {ProductPriceType} from "../Price/types";
import {ProductParamValueType} from "../ProductParamValue/types";
import {MainFileType} from "../../Main/File/types";
import {EntityActionsType} from "../../types";
import {SaleCategoryType} from "../Category/types";
import {ProductIntegrationType} from "../ProductIntegration/types";
import {GroupActionEntityType, MainMetaType} from "../../Main/common";
export enum SaleProductActionTypes {
    API_RESPONSE_SUCCESS = "@@SaleProduct/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@SaleProduct/API_RESPONSE_ERROR",
    GET_PRODUCTS = "@@SaleProduct/GET_PRODUCTS",
    GET_PRODUCT = "@@SaleProduct/GET_PRODUCT",
    GET_EDIT_PRODUCT = "@@SaleProduct/GET_EDIT_PRODUCT",
    UPDATE_PRODUCT = "@@SaleProduct/UPDATE_PRODUCT",
    REMOVE_PRODUCT_MODAL_SHOW = "@@SaleProduct/REMOVE_PRODUCT_MODAL_SHOW",
    REMOVE_PRODUCT_MODAL_HIDE = "@@SaleProduct/REMOVE_PRODUCT_MODAL_HIDE",
    REMOVE_PRODUCT= "@@SaleProduct/REMOVE_PRODUCT",
    EDIT_PRODUCT = "@@SaleProduct/EDIT_PRODUCT",

    COPY_PRODUCT = "@@peerActivity/COPY_PRODUCT",
    RIGHT_MODAL_SHOW = "@@SaleProduct/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@SaleProduct/RIGHT_MODAL_HIDE",
    EDIT_PRODUCT_INTEGRATION_CHANNEL = "@@SaleProduct/EDIT_PRODUCT_INTEGRATION_CHANNEL",
    UPDATE_PRODUCT_INTEGRATION_CHANNEL = "@@SaleProduct/UPDATE_PRODUCT_INTEGRATION_CHANNEL",
    PRODUCT_INTEGRATION_CHANNEL_MODAL_HIDE = "@@SaleProduct/PRODUCT_INTEGRATION_CHANNEL_MODAL_HIDE",
    EDIT_PRODUCT_PROPERTIES = "@@SaleProduct/EDIT_PRODUCT_PROPERTIES",
    UPDATE_PRODUCT_PROPERTIES = "@@SaleProduct/UPDATE_PRODUCT_PROPERTIES",
    PRODUCT_PROPERTIES_MODAL_HIDE = "@@SaleProduct/PRODUCT_PROPERTIES_MODAL_HIDE",

    CLEAR_PRODUCT_FETCHED = "@@SaleProduct/CLEAR_PRODUCT_FETCHED",
    RIGHT_MODAL_GROUP_INTEGRATION_SHOW = "@@SaleProduct/RIGHT_MODAL_GROUP_INTEGRATION_SHOW",
    RIGHT_MODAL_GROUP_INTEGRATION_HIDE = "@@SaleProduct/RIGHT_MODAL_GROUP_INTEGRATION_HIDE",
    UPDATE_GROUP_PRODUCT_INTEGRATIONS = "@@SaleProduct/UPDATE_GROUP_PRODUCT_INTEGRATIONS",
    SET_GROUP_PRODUCTS= "@@SaleProduct/CREATE_GROUP_PRODUCTS",
    UNSET_GROUP_PRODUCTS= "@@SaleProduct/UNSET_GROUP_PRODUCTS",
    MODAL_SET_GROUP_PRODUCTS_SHOW = "@@SaleProduct/MODAL_SET_GROUP_PRODUCTS_SHOW",
    MODAL_UNSET_GROUP_PRODUCTS_SHOW = "@@SaleProduct/MODAL_UNSET_GROUP_PRODUCTS_SHOW",
    MODAL_GROUP_PRODUCTS_HIDE = "@@SaleProduct/MODAL_GROUP_PRODUCTS_HIDE",
    MODAL_REMOVE_PRODUCTS_SHOW = "@@SaleProduct/MODAL_REMOVE_PRODUCTS_SHOW",
    MODAL_REMOVE_PRODUCTS_HIDE = "@@SaleProduct/MODAL_REMOVE_PRODUCTS_HIDE",
    REMOVE_GROUP_PRODUCTS= "@@SaleProduct/REMOVE_GROUP_PRODUCTS",
}

export interface SaleProductState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    rightModalIntegrationChannelShow : boolean;
    rightModalPropertiesShow : boolean;
    rightModalGroupIntegration : boolean;
    isModalGroupProductsShow : boolean;
    isModalGroupRemoveProductsShow : boolean;
    isGroupProductsType : boolean;
    removeModalShow: boolean;
    selectedItem: ProductType;
    selectedIntegrationChannel?: ProductIntegrationType;
    selectedProductProperties?: Array<ProductParamValueType>;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}
export interface ProductType extends GroupActionEntityType{
    uuid?: string;
    enabled?: boolean;
    name?: string;
    code?: string;
    description?: string;
    detailText?: string;
    activeTo?: number;
    daysBeforeLicenseExpired?: number;
    productParamValues?: Array<ProductParamValueType>;
    productPrices?: Array<ProductPriceType>;
    integrations?: Array<ProductIntegrationType>
    idRemote?: string;
    type?: string;
    status?: string;
    privacyStatus?: string;
    article?: string;
    barCode?: string;
    order?: number;
    createdAt?: string;
    updatedAt?: string;
    media?: Array<MainFileType>
    actions?: EntityActionsType;
    category?: SaleCategoryType;
    isHtml?: boolean;
    link?: string;
    externalLink?: string;
    storeValues?: Array<ProductStoreValue>;
    eTag?: string;
    width?: number;
    height?: number;
    depth?: number;
    weight?: number;
    offers?: Array<ProductType>;
    groupHash?: string;
}

export interface SaleProductPayload {
    data: Array<any>;
    modalEditTitle : string;
}

export const ProductStatus = {
    ALL: {
        label: 'All',
        class: 'info',
        status: ''
    },
    READY: {
        label: 'Ready',
        class: 'success',
        status: 'R'
    },
    CANCELLED: {
        label: 'Cancelled',
        class: 'danger',
        status: 'C'
    },
    NEW: {
        label: 'New',
        class: 'info',
        status: 'N'
    },
    ERROR: {
        label: 'Error',
        class: 'danger',
        status: 'E'
    },
    FINAL: {
        label: 'Final',
        class: 'success',
        status: 'F'
    }
}

export enum ProductPublishStatus {
    STATUS_FINAL = 'F',
    STATUS_NEW= 'N',
    STATUS_ON_PUBLISH = 'OP',
    STATUS_PUBLISHING = 'PI',
    STATUS_UNPUBLISHED = 'UP',
    STATUS_ERROR = 'E',
    STATUS_READY = 'R'
}

export enum ProductPrivacyStatus {
    PRIVACY_STATUS_VISIBLE_ALL = 'A',
    PRIVACY_STATUS_HIDDEN = 'H',
}

export enum ProductTypeStatus {
    TYPE_PRODUCT = 'P',
    TYPE_SERVICE = 'S'
}

export interface ProductPrivacyStatusType{
    id: string;
    name: string;
    color: string;
}
export interface ProductPublishStatusType{
    id: string;
    name: string;
    color: string;
}
export interface ProductTypeStatusType{
    id: string;
    name: string;
}
export const ProductPrivacyStatusValues:Array<ProductPrivacyStatusType> = [
    {
        name: 'Visible',
        id: ProductPrivacyStatus.PRIVACY_STATUS_VISIBLE_ALL,
        color: "#8fdf82"
    },
    {
        name: 'Hidden',
        id: ProductPrivacyStatus.PRIVACY_STATUS_HIDDEN,
        color: "#e74c3c"
    }
];

export const ProductPublishStatusValues:Array<ProductPublishStatusType> = [
    {
        name: 'New',
        id: ProductPublishStatus.STATUS_NEW,
        color: "#e74c3c"
    },
    {
        name: 'Ready',
        id: ProductPublishStatus.STATUS_READY,
        color: "#8fdf82"
    }
];

export const ProductTypeStatusValues:Array<ProductTypeStatusType> = [
    {
        name: 'Product',
        id: ProductTypeStatus.TYPE_PRODUCT
    },
    {
        name: 'Service',
        id: ProductTypeStatus.TYPE_SERVICE
    }
];

export interface ProductStoreValue{
    uuid: string;
    value: number;
}

export const newProductItem: ProductType = {
    name:'',
    enabled: true,
    type: ProductTypeStatus.TYPE_PRODUCT,
    status: ProductPublishStatus.STATUS_NEW,
    privacyStatus: ProductPrivacyStatus.PRIVACY_STATUS_VISIBLE_ALL,
    order: 500,
}

export interface GroupProductPayloadType{
    productUuids?: Array<string>
}