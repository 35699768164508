import { APIClient } from "../apiCore";

const api = new APIClient();

const getCurrentCountry = () => {
  return api.get('https://ipapi.co/json/');
};

export {
    getCurrentCountry,
};
