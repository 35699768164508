import {EntityActionsType} from "../../types";
import {ContentPeerChannelType} from "../PeerChannel/types";
import {PeerActivityType} from "../PeerActivity/types";
import {ContentPeerTargetTypeType} from "../PeerTargetType/types";

export enum ContentPeerTargetActionTypes {
  API_RESPONSE_SUCCESS = "@@contentPeerTarget/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentPeerTarget/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_TARGETS = "@@contentPeerTarget/GET_CONTENT_PEER_TARGETS",
  UPDATE_CONTENT_PEER_TARGET = "@@contentPeerTarget/UPDATE_CONTENT_PEER_TARGET",
  ADD_CONTENT_PEER_TARGET = "@@contentPeerTarget/ADD_CONTENT_PEER_TARGET",
  REMOVE_CONTENT_PEER_TARGET_MODAL_SHOW = "@@contentPeerTarget/REMOVE_CONTENT_PEER_TARGET_MODAL_SHOW",
  REMOVE_CONTENT_PEER_TARGET_MODAL_HIDE = "@@contentPeerTarget/REMOVE_CONTENT_PEER_TARGET_MODAL_HIDE",
  REMOVE_CONTENT_PEER_TARGET = "@@contentPeerTarget/REMOVE_CONTENT_PEER_TARGET",
  EDIT_CONTENT_PEER_TARGET = "@@contentPeerTarget/EDIT_CONTENT_PEER_TARGET",

  RIGHT_MODAL_SHOW = "@@contentPeerTarget/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@contentPeerTarget/RIGHT_MODAL_HIDE",
}

export interface ContentPeerTargetState {
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: ContentPeerTargetType;
  modalEditTitle: string;
  isNeedReload: boolean;
  isSending: boolean;
  isContentPeerTargetFetched: boolean;
  getContentPeerTargetLoading: boolean;
}

export interface ContentPeerTargetType{
  uuid?: string,
  enabled: boolean,
  name: string,
  startDate: string,
  endDate?: string,
  targetDate?: string,
  targetType?: ContentPeerTargetTypeType,
  startValue?: number,
  endValue?: number,
  targetValue?: number,
  currentValue?: number,
  executedClass?: string,
  executedPercent?: number,
  plannedExecutedValue?: number,
  order?: number,
  targetStatus: string,
  recipientEmails?: string,
  channel?: ContentPeerChannelType
  activity?: PeerActivityType
  isUnread: boolean,
  isRecurrent: boolean,
  recurrentPeriod?: ContentPeerTargetRecurrentPeriodType,
  recurrentValue?: number,
  eTag?: string,
  actions?: EntityActionsType
}

export interface ContentPeerTargetPayload {
  data: Array<any>;
  modalEditTitle : string;
}

export enum ContentPeerTargetStatusTypes {
  PROGRESS= 'P',
  SUCCESS='S',
  FAIL='F'
}


export interface ContentPeerTargetRecurrentPeriodType{
  uuid: string;
  code?: string;
  name?: string;
}

export interface ContentPeerTargetRecurrentPeriodsType{
  items: Array<ContentPeerTargetRecurrentPeriodType> ;
}

export const ContentPeerTargetRecurrentPeriods:ContentPeerTargetRecurrentPeriodsType = {
    items:[
      {
        uuid: 'days',
        code: 'days',
      },
      {
        uuid: 'month',
        code: 'month',
      }
    ]
}


