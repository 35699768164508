import React, {useEffect, useState} from "react";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import {Pagination, Autoplay} from "swiper/modules";
import {convertToMomentLocale} from "../../../helpers/functions";

import {
    Card,
    CardBody,
    CardHeader
} from 'reactstrap';

import {PeerActivityActionButton, PeerActivityType} from "../../../redux/Content/PeerActivity/types";

import {MainFileImageTypes, MainFileType, MainFileVideoTypes} from "../../../redux/Main/File/types";
import TagItem from "../../../components/TagItem";
import {useRedux} from "../../../hooks";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {PeerActivityModeCode} from "../../../redux/Content/PeerActivityMode/types";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import ChannelAvatar from "../PeerChannel/channelAvatar";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {isHTML} from "../../../helpers/functions";

interface ItemProps {
    items: PeerActivityType[];
    isShowPreviewMobile: boolean;
}

interface PreviewItemTextProps {
    item: PeerActivityType;
}

const PreviewItemText = ({item}:PreviewItemTextProps) => {

    const { t, i18n } = useTranslation(['common']);
    const vkPostMaxShowLessSize = 90;
    const vkPostMaxShowLessLines = 2;
    const [showMore, setShowMore] = useState(false);
    const [text, setText] = useState<string>('');

    const handleShowMore = (value : boolean) => {
        setShowMore(value);
    }

    useEffect(()=>{

        if (item?.text && item?.text !== null && item.text.length>0) {
            if (item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_INSTAGRAM) {
                setText('<span class="fw-bold pe-1">' + item.channel.name + '</span>' + item.text.replace(/\n/g,'<br>'));
            } else {
                setText(item.text);
            }
        } else {
            setText('');
        }

    },[item?.text, item?.channel])

    return (
        <>
            {text && text.length>0 &&
                    <div className={"ps-3 pe-3 fs-12"}>
                        {isHTML(text) ?
                            <div
                                className={"card-text "+(item.text.length > vkPostMaxShowLessSize ? '' : ' pb-2')}
                                dangerouslySetInnerHTML={{__html: showMore?text:text.substring(0, vkPostMaxShowLessSize)+((text.length > vkPostMaxShowLessSize) && !showMore ? '...': '')}}
                            />
                            :
                            <div
                                className={"card-text "+(text.length > vkPostMaxShowLessSize ? '' : ' pb-2')}
                            >{showMore
                                ?
                                    <>
                                    {text.split('\n').map((str) => {
                                        return (<p className="mb-0">{str || '\u00A0'}</p>)})}
                                    </>
                                :
                                <>
                                    {
                                        text.substring(0, vkPostMaxShowLessSize).split('\n').slice(0, 2).map((str: string) => {
                                        return (<p className="mb-0">{str || '\u00A0'}</p>)})
                                    }
                                    {(text.length > vkPostMaxShowLessSize) && !showMore ? '...': ''}
                                </>
                                }
                            </div>
                        }

                        {((item.text.length > vkPostMaxShowLessSize) || (!isHTML(text) && text.split('\n').length>vkPostMaxShowLessLines)) &&
                            <div className="d-flex flex-row mt-1 mb-3">
                                {showMore
                                    ?
                                    <a href="#" onClick={()=>handleShowMore(false)}>
                                        {t('common:showLess')}
                                    </a>
                                    :
                                    <a href="#" onClick={()=>handleShowMore(true)}>
                                        {t('common:showMore')}
                                    </a>
                                }
                            </div>
                        }
                    </div>
            }
        {item.tags && item.tags.length>0 &&
        <div className={"ps-3 pe-3 fs-12 pb-2"}>
            {item.tags.split(/,/).map((tag: string, key: number)=>(
                <TagItem tag={tag} key={key}/>
            ))}
        </div>
        }
        </>
    );

}

interface PreviewItemPostProps {
    item: PeerActivityType;
    isTextOnTop: boolean
}

const PreviewItemPost = ({item, isTextOnTop = false}:PreviewItemPostProps) => {
    const { t, i18n } = useTranslation(['common']);

    return (
        <>
            <Card className="w-100 mb-2">
                {item?.channel?.connector?.handler?.code !== connectorHandlerCode.CONNECTOR_HANDLER_YOUTUBE &&
                    <CardHeader className="border-bottom-0 pb-2">
                        <div className="d-flex flex-row flex-nowrap flex-grow-1">
                            {item.channel &&
                                <div className="d-flex flex-row flex-nowrap">
                                    <ChannelAvatar item={item.channel}/>
                                    <div className="ps-2">
                                        <div className="fs-12 text-primary">
                                            {item.channel.name}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </CardHeader>
                }
                <CardBody className={"p-0"}>
                    {isTextOnTop &&
                        <div className="">
                            <PreviewItemText item={item}/>
                        </div>
                    }
                    {item.media && (item.media || []).filter((mediaItem:MainFileType)=>{return mediaItem.enabled === undefined || mediaItem.enabled}).length>0 &&
                        <Swiper pagination={{ clickable: true, dynamicBullets: true, }}
                                modules={[Pagination, Autoplay]}
                                slidesPerView={1}
                                loop={false}
                                autoplay={{ delay: 2500, disableOnInteraction: false }}
                                className="mySwiper swiper pagination-dynamic-swiper rounded" >

                            {item.media.filter((mediaItem:MainFileType)=>{return mediaItem.enabled === undefined || mediaItem.enabled}).map((mediaItem:MainFileType, key:number)=> (
                                <SwiperSlide key={"swiperActivitySlide"+mediaItem.uuid+"_"+key} className="text-center">
                                    {(Object.values(MainFileImageTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                        <img src={ mediaItem.downLoadUrl ? mediaItem.downLoadUrl : mediaItem.url } alt="" className="img-fluid"/>
                                    }
                                    {(Object.values(MainFileVideoTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                        <video controls width="auto" height="auto" style={{maxWidth:'100%'}} className="rounded">
                                            <source src={mediaItem.downLoadUrl ? mediaItem.downLoadUrl : mediaItem.url} type={"video/"+mediaItem.contentType.split('/').pop()}/>
                                        </video>
                                    }
                                </SwiperSlide>
                            ))}

                        </Swiper>
                    }
                    {!isTextOnTop &&
                        <div className="pt-2">
                            <PreviewItemText item={item}/>
                        </div>
                    }
                </CardBody>
            </Card>
            {item?.cta && (item.cta || []).length>0 &&
                <>
                    {(item.cta || []).filter((button:PeerActivityActionButton)=>button.enabled).map((button:PeerActivityActionButton, key:number)=>{
                            return(
                                <div className="p-2 text-center border-primary border rounded-2 border-opacity-50 mb-1 position-relative" key={'ctaPreview_'+item.channel.uuid+'_'+key}>
                                    <span className="text-primary text-opacity-75">
                                        {item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK ?
                                            t('engagement:button'+button.name)
                                            :
                                            (button.name && button.name.length>0 ? button.name : " ")
                                        }
                                    </span>
                                    <span className="ctaPreviewButtonIcon"><i className="ri-arrow-right-up-line fs-10 text-primary text-opacity-50"/></span>
                                </div>
                            )
                        })
                    }
                </>
            }
            {item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_YOUTUBE &&
                <div className="d-flex flex-row flex-nowrap flex-grow-1">
                    {item.channel &&
                        <div className="d-flex flex-row flex-nowrap">
                            <ChannelAvatar item={item.channel}/>
                            <div className="ps-2">
                                {item?.title &&
                                    <div className="fs-12 text-primary fw-bold mb-2">
                                        {item.title}
                                    </div>
                                }
                                <div className="fs-12 text-primary">
                                    {item.channel.name}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    );
}

const PreviewItemReel = ({item}:PreviewItemPostProps) => {

    return (
        <>
            <div className="position-relative">
                {item.media && item.media.length>0 &&
                    <>
                    <Swiper pagination={{ clickable: true, dynamicBullets: true, }}
                            modules={[Pagination, Autoplay]}
                            slidesPerView={1}
                            loop={false}
                            autoplay={{ delay: 2500, disableOnInteraction: false }}
                            className="mySwiper swiper pagination-dynamic-swiper rounded" >

                        { item.media.filter((mediaItem:MainFileType)=>{return mediaItem.enabled === undefined || mediaItem.enabled}).map((mediaItem:MainFileType, key:number)=> (
                            <SwiperSlide key={"swiperActivitySlide"+mediaItem.uuid+"_"+key} className="text-center">
                                {(Object.values(MainFileImageTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                    <img src={mediaItem.url} alt="" className="img-fluid"/>
                                }
                                {(Object.values(MainFileVideoTypes) as string[]).includes(mediaItem.fileName.split('.').pop().toLowerCase()) &&
                                    <video controls width="auto" height="auto" style={{maxWidth:'100%'}} className="rounded">
                                        <source src={mediaItem.downLoadUrl ? mediaItem.downLoadUrl : mediaItem.url} type={"video/"+mediaItem.contentType.split('/').pop()}/>
                                    </video>
                                }
                            </SwiperSlide>
                        ))}

                    </Swiper>
                    <div className="d-flex flex-row flex-nowrap flex-grow-1 position-absolute previewReelChannelCont">
                        {item.channel &&
                            <div className="d-flex flex-row flex-nowrap">
                                <ChannelAvatar item={item.channel}/>
                                <div className="ps-2 d-flex flex-row align-items-center">
                                    <div className="fs-12 text-primary text-white fw-bold">
                                        {item.channel.name}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    </>
                }
            </div>
        </>
    );
}

const PreviewItem = ({ items, isShowPreviewMobile = true}: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);
    const [item, setItem] = useState<PeerActivityType>(null);
    const [selectedChannel, setSelectedChannel] = useState<ContentPeerChannelType>(null);
    const [isTextOnTop, setTextOnTop] = useState<boolean>(false);

    moment.locale(convertToMomentLocale(i18n.language));

    const getItem = (items: PeerActivityType[], channel?: ContentPeerChannelType) => {
        let result;
        if (channel) {
            result = items.find((item:PeerActivityType) => item.enabled && item.channel.uuid === channel.uuid);
        }
        return result || items.find((item:PeerActivityType) => item.enabled);
    };

    useEffect(() => {
        const enabledItems = items.filter((item:PeerActivityType)=> item.enabled);
        let newItem = getItem(enabledItems, selectedChannel);
        if (!newItem) {
            newItem = getItem(enabledItems);
            setSelectedChannel(newItem?.channel);
        }

        setTextOnTop(newItem?.channel?.connector?.handler?.code ?
            [
                connectorHandlerCode.CONNECTOR_HANDLER_VK,
                connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK,
                connectorHandlerCode.CONNECTOR_HANDLER_LINKEDIN,
                connectorHandlerCode.CONNECTOR_HANDLER_TWITTER,
                connectorHandlerCode.CONNECTOR_HANDLER_OK,
                connectorHandlerCode.CONNECTOR_HANDLER_TIKTOK
            ].includes(newItem.channel.connector.handler.code)
            : false
        );

        setItem(newItem || null);

    },[items, selectedChannel]);

    return (
       <div className={"d-flex flex-column flex-grow-1 bg-light peerActivityPreviewCont"}>
           <div className="d-flex flex-column flex-grow-1 peerActivityPreviewWrapper p-3">
               <div>
                   <h6 className="text-center">{t('peerActivity:preview')}</h6>
               <div className="d-flex flex-row justify-content-center">
                   {(items && ((items || []).length>0)) &&
                       <div className={"d-flex flex-row pb-2"}>
                       { // eslint-disable-next-line
                           items.map((_item:PeerActivityType)=>{
                           if (_item.enabled && _item?.channel){
                               return(
                                    <a href={"#"} key={'previewTab_'+_item.channel.uuid} className={"p-2"+(_item.channel.uuid===selectedChannel?.uuid?'':' disabledBlock')} onClick={()=>{setSelectedChannel(_item.channel)}}>
                                        <ChannelAvatar item={_item.channel}/>
                                    </a>
                               )
                           }
                       })}
                       </div>
                   }
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className={(isShowPreviewMobile?' previewMobile': item?.mode?.code === PeerActivityModeCode.ACTIVITY ? ' previewDesktop':' previewMobile')}>
                    {(item !== undefined && item !== null) ?
                        <>
                            {item?.mode?.code === PeerActivityModeCode.ACTIVITY &&
                                <PreviewItemPost item={item} isTextOnTop={isTextOnTop}/>
                            }
                            {(item?.mode?.code === PeerActivityModeCode.SHORTS || item?.mode?.code === PeerActivityModeCode.STORY)&&
                                <PreviewItemReel item={item} isTextOnTop={isTextOnTop}/>
                            }
                        </>
                        :
                        <div className="d-flex justify-content-center">
                            <i className="ri-camera-off-line fs-24 text-muted"/>
                        </div>
                    }
                    </div>
                </div>
           </div>
           </div>
       </div>
    );
};

export default PreviewItem;
