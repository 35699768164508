import {MainVersionHistoryActionTypes} from "./types";

export const mainVersionHistoryApiResponseSuccess = (actionType: string, data: any) => ({
  type: MainVersionHistoryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const mainVersionHistoryApiResponseError = (actionType: string, error: string) => ({
  type: MainVersionHistoryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getMainVersionHistories = () => ({
  type: MainVersionHistoryActionTypes.GET_VERSION_HISTORY_LIST,
});