// types
import {EventSubscribeUserActionTypes, EventSubscribeUserState, EventSubscribeUserType} from "./types";

export const INIT_STATE_EVENT_USER_SUBSCRIPTIONS: EventSubscribeUserState = {
  items: [],
};

const EventUserSubscription = (state = INIT_STATE_EVENT_USER_SUBSCRIPTIONS, action: any) => {
  switch (action.type) {
    case EventSubscribeUserActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case EventSubscribeUserActionTypes.GET_USER_EVENT_SUBSCRIPTIONS:
          return {
            ...state,
            items: action.payload.data.items,
          };
        default:
          return { ...state };
      }

    case EventSubscribeUserActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case EventSubscribeUserActionTypes.GET_USER_EVENT_SUBSCRIPTIONS:
          return {
            ...state,
          };
        default:
          return { ...state };
      }

    default:
      return { ...state };
  }
};

export default EventUserSubscription;
