import React from 'react';
import * as Yup from 'yup';

export const ContentNewPeerChannelValidatorSchema = Yup.object().shape({
    name: Yup.lazy(value => {
        switch (typeof value) {
            case 'object':
                return Yup.object().when(['hashtag'], {
                    is: (hashtag: string) => {
                        if (hashtag === undefined) {
                            return true
                        }
                    },
                    then: (schema) =>
                        schema
                            .required("required")
                });
            default:
                return Yup.string().when(['hashtag'], {
                    is: (hashtag: string) => {
                        if (hashtag === undefined) {
                            return true
                        }
                    },
                    then: (schema) =>
                        schema
                            .required("required")
                });
        }
    }),
    hashtag: Yup.string().when(['name'], {
        is: (name: string) => {
            if (name === undefined) {
                return true
            }
            return false
        },
        then: (schema) =>
            schema
                .matches(/^#[A-Za-z]+$/, 'invalidValue')
                .required("required"),
        otherwise: (schema) => schema.max(0, 'shouldBeEmpty')
    }),
    project: Yup.object()
        .required('required'),
});

export const ContentEditPeerChannelValidatorSchema = Yup.object().shape({
    project: Yup.object()
        .required('required'),
});