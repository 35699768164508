//@ts-nocheck
import {MainDeviceFingerPrintType, MainDeviceType} from "../../redux/Main/Device/types";
import Sha256 from "../../helpers/sha256";
import {sha256} from "js-sha256";

interface BrowserFingerPrintInputProps {
    hardwareOnly?: boolean,
    enableWebgl?: boolean,
    enableScreen?: boolean,
    debug?:boolean
}

const defaultBrowserFingerPrintInputProps = {
    hardwareOnly: false,
    enableWebgl: true,
    enableScreen: true,
    debug: false
}

const getBrowserFingerPrintData = (param: BrowserFingerPrintInputProps): MainDeviceType => {
    const {
        cookieEnabled,
        doNotTrack,
        hardwareConcurrency,
        language,
        languages,
        maxTouchPoints,
        platform,
        userAgent,
        vendor,
    } = window.navigator;

    const {width, height, colorDepth, pixelDepth} = param.enableScreen ? window.screen : {};
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const touchSupport = 'ontouchstart' in window;
    const devicePixelRatio = window.devicePixelRatio;
    const canvas = getCanvasID(param.debug);
    const webgl = param.enableWebgl ? getWebglID(param.debug) : undefined;
    const webglInfo = param.enableWebgl ? getWebglInfo() : null;

    const mainDeviceFingerPrint = generateFingerPrintKeyPoints(
        canvas, colorDepth, cookieEnabled, devicePixelRatio, doNotTrack, hardwareConcurrency, height,
        width, language, languages, maxTouchPoints, pixelDepth, platform, timezone, timezoneOffset,
        touchSupport, userAgent, vendor, webgl, webglInfo
    );

    return generateMainDevice(mainDeviceFingerPrint, param.debug);
};

const generateFingerPrintKeyPoints = (canvas, colorDepth, cookieEnabled, devicePixelRatio, doNotTrack,
                                      hardwareConcurrency, height, width, language, languages, maxTouchPoints, pixelDepth, platform,
                                      timezone, timezoneOffset, touchSupport, userAgent, vendor, webgl, webglInfo): MainDeviceFingerPrintType => {

    const mainDeviceFingerPrint: MainDeviceFingerPrintType = {
        canvas,
        colorDepth,
        cookieEnabled,
        devicePixelRatio,
        doNotTrack,
        hardwareConcurrency,
        height,
        width,
        language,
        languages: [...languages],
        maxTouchPoints,
        pixelDepth,
        platform,
        timezone,
        timezoneOffset,
        touchSupport,
        userAgent,
        vendor,
        webgl,
        webglInfo
    };
    return mainDeviceFingerPrint;
};

const generateMainDevice = (mainDeviceFingerPrint: MainDeviceFingerPrintType, debug): MainDeviceType => {

    const mainDeviceFingerPrintSign: MainDeviceFingerPrintType = {
        ...mainDeviceFingerPrint,
        timezone: null,
        timezoneOffset: null,
        height: null,
        width: null,
        webgl: null
    };

    const datastring = JSON.stringify(mainDeviceFingerPrintSign);

    if (debug) {
        console.log('fingerprint data', datastring);
    }

    const mainDevice: MainDeviceType = {
        userAgent: mainDeviceFingerPrint.userAgent,
        timezone: mainDeviceFingerPrint.timezone,
        platform: mainDeviceFingerPrint.platform,
        vendor: mainDeviceFingerPrint.vendor,
        fingerPrint: sha256(datastring),
        fingerPrintData: mainDeviceFingerPrint
    };

    return mainDevice;
};

const createCanvasElement = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    return {canvas, ctx};
};

const setCanvasDesign = (ctx) => {
    const text = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~1!2@3#4$5%6^7&8*9(0)-_=+[{]}|;:',<.>/?";
    ctx.textBaseline = 'top';
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText(text, 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText(text, 4, 17);
};

const clearCanvas = (ctx, canvas) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
};

export const getCanvasID = (debug) => {
    try {
        const {canvas, ctx} = createCanvasElement();
        setCanvasDesign(ctx);

        const result = canvas.toDataURL();

        if (debug) {
            document.body.appendChild(canvas);
        } else {
            clearCanvas(ctx, canvas);
        }

        return sha256(result);
    } catch {
        return null;
    }
};

export const getWebglID = (debug) => {
    const width = 256;
    const height = 128;
    try {
        let canvas = document.createElement("canvas");
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        canvas.width = width;
        canvas.height = height;
        const ctx = getWebGlContext(canvas);
        try {
            const vertexShaderProgram = "attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}";
            const fragmentShaderProgram = "precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}";
            const buffer = initWebGlBuffer(ctx);
            const vertexShader = createShader(ctx, ctx.VERTEX_SHADER, vertexShaderProgram);
            const fragmentShader = createShader(ctx, ctx.FRAGMENT_SHADER, fragmentShaderProgram);
            const shaderProgram = createShaderProgram(ctx, vertexShader, fragmentShader);
            draw(ctx, shaderProgram, buffer);
            let result = readPixels(ctx, width, height);
            canvas = null;
            return sha256(result);
        } catch (e) {
        }
    } catch {
        return null;
    }
};

const getWebGlContext = (canvas) =>
    canvas.getContext("webgl2")
    || canvas.getContext("experimental-webgl2")
    || canvas.getContext("webgl")
    || canvas.getContext("experimental-webgl")
    || canvas.getContext("moz-webgl");

const initWebGlBuffer = (ctx) => {
    const buffer = ctx.createBuffer();
    ctx.bindBuffer(ctx.ARRAY_BUFFER, buffer);
    const vertices = new Float32Array([-.2, -.9, 0, .4, -.26, 0, 0, .7321, 0]);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    ctx.bufferData(ctx.ARRAY_BUFFER, vertices, ctx.STATIC_DRAW);
    buffer.itemSize = 3;
    buffer.numItems = 3;
    return buffer;
}

const createShader = (ctx, type, source) => {
    const shader = ctx.createShader(type);
    ctx.shaderSource(shader, source);
    ctx.compileShader(shader);
    return shader;
}

const createShaderProgram = (ctx, vertexShader, fragmentShader) => {
    const shaderProgram = ctx.createProgram();
    ctx.attachShader(shaderProgram, vertexShader);
    ctx.attachShader(shaderProgram, fragmentShader);
    ctx.linkProgram(shaderProgram);
    ctx.useProgram(shaderProgram);
    return shaderProgram;
}

const draw = (ctx, shaderProgram, buffer) => {
    shaderProgram.vertexPosAttrib = ctx.getAttribLocation(shaderProgram, "attrVertex");
    shaderProgram.offsetUniform = ctx.getUniformLocation(shaderProgram, "uniformOffset");
    ctx.enableVertexAttribArray(shaderProgram.vertexPosArray);
    ctx.vertexAttribPointer(shaderProgram.vertexPosAttrib, buffer.itemSize, ctx.FLOAT, !1, 0, 0);
    ctx.uniform2f(shaderProgram.offsetUniform, 1, 1);
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, buffer.numItems);
}

const readPixels = (ctx, width, height) => {
    const output = new Uint8Array(width * height * 4);
    ctx.readPixels(0, 0, width, height, ctx.RGBA, ctx.UNSIGNED_BYTE, output);
    return JSON.stringify(output).replace(/,?"[0-9]+":/g, "");
}

const fetchWebglParameter = (context, parameter) => String(context.getParameter(context[parameter]));

const getWebglInfo = () => {
    try {
        const canvasContext = document.createElement('canvas').getContext('webgl');

        return {
            VERSION: fetchWebglParameter(canvasContext, "VERSION"),
            SHADING_LANGUAGE_VERSION: fetchWebglParameter(canvasContext, "SHADING_LANGUAGE_VERSION"),
            VENDOR: fetchWebglParameter(canvasContext, "VENDOR"),
            SUPORTED_EXTENSIONS: canvasContext.getSupportedExtensions().join(", ")
        };
    } catch {
        return null;
    }
};


export {
    BrowserFingerPrintInputProps,
    defaultBrowserFingerPrintInputProps,
    getBrowserFingerPrintData
}