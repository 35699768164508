import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import '../../../i18/config';
import {useTranslation} from "react-i18next";

import { useRedux } from "../../../hooks";

import {
   getSalePlans,
} from "../../../redux/actions";
import {PlanRibbonType, SalePlanType} from "../../../redux/Sale/Plan/types";
import ListItem from "../Plan/ListItem";
import {LocaleCurrency} from "../../../redux/Sale/Currency/types";
import Loader from "../../../components/Loader";
import BreadCrumb from "../../../components/Common/BreadCrumb";


const PlanIndex = () => {

    const { dispatch, useAppSelector } = useRedux();
    const { t, i18n } = useTranslation(['common']);

    const {
        salePlan,
        isFetched,
    } = useAppSelector(state => ({
        salePlan: state.SalePlan,
        isFetched: state.SalePlan.isFetched
    }));

    const [plans, setPlans] = useState<SalePlanType[]>([]);

    const [activeTab, setActiveTab] = useState('_1m');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        dispatch(getSalePlans());
    }, [dispatch]);

    useEffect(() => {
        if ((salePlan.items !== undefined) && (salePlan.items.length>0)){
            setPlans([...salePlan.items]);
        } else {
            setPlans([]);
        }
    }, [salePlan?.items])

    const planRibbon:PlanRibbonType = {
        label: '',
        class: 'primary',
        icon: 'ri-star-fill'
    }

    return (
        <>
            <BreadCrumb title={t('menu:plans')} pageTitle={t('menu:main')} />
            <Row className="justify-content-center mt-4">
                <Col lg={5}>
                    <div className="text-center mb-4">
                        <h4 className="fw-bold fs-22">{t('plan:plansPricing')}</h4>
                        <p className="text-muted mb-4 fs-15">{t('plan:plansPricingDescription')}</p>

                        <div className="d-inline-flex">
                            <Nav className="nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" id="pills-tab" role="tablist">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '_1m' }, 'fw-semibold')}
                                        onClick={() => { toggleTab('_1m'); }}
                                    >
                                        {t('plan:monthly')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '_3m' }, 'fw-semibold')}
                                        onClick={() => { toggleTab('_3m'); }}
                                    >{t('plan:quarterly')} <span className="badge bg-success">{t('plan:quarterlyDiscount')}</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                </Col>
            </Row>
            {isFetched ?
                <Row>
                    {(plans || []).map((item:SalePlanType, key) => (
                        item.enabled === true && item.productPrices !== undefined && item.productPrices.length>0 &&
                        <Col lg={6} key={key}>
                            <ListItem item={item} priceType={activeTab} planRibbon={item.code === 'licExpert202201' && planRibbon }/>
                        </Col>
                    ))}
                </Row>
                :
            <Loader/>
            }
        </>
    );
};

export default PlanIndex;


