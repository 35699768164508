const flagUk = require("../resources/images/flags/uk.svg");
const flagRu = require("../resources/images/flags/ru.svg");
const flagKa = require("../resources/images/flags/ka.svg");
const flagTr = require("../resources/images/flags/tr.svg");
const flagUa = require("../resources/images/flags/ua.svg");
export interface LanguageOptionType {
    label?: string;
    flag?: any;
    code?: string
}

const languages = {
    en: {
        label: "English",
        flag: flagUk.default,
    },
    ru: {
        label: "Русский",
        flag: flagRu.default,
    },
    ua: {
        label: "Українська",
        flag: flagUa.default,
        code: "UA"
    },
    ka: {
        label: "ქართული",
        flag: flagKa.default,
    },
}

export const widgetLanguages = {
    en: {
        label: "English",
        flag: flagUk.default,
        code: "EN"
    },
    ru: {
        label: "Русский",
        flag: flagRu.default,
        code: "RU"
    },
    ka: {
        label: "ქართული",
        flag: flagKa.default,
        code: "GE"
    },
    ua: {
        label: "Українська",
        flag: flagUa.default,
        code: "UA"
    },
    tr: {
        label: "Türkçe",
        flag: flagTr.default,
        code: "TR"
    },
}

export default languages
