import React, {useEffect, useState} from 'react';
import {Alert, Col, Button} from "reactstrap";
import { Link } from 'react-router-dom'
import CoverAuth from "./CoverAuth";
import {useRedux} from "../../../hooks";
import LoginForm from "./LoginForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import * as route from "../../../api/routes";
import SignInDisabled from "../../../components/SignInDisabled";
import {LoginByTypes} from "../../../redux/Main/Login/types";
import LoginByDevice from "./LoginByDevice";
import {changeLoginBy} from "../../../redux/Main/Login/actions";

const CoverLogin = () => {

    const { t } = useTranslation(['user','validator']);

    const { dispatch, useAppSelector } = useRedux();

    const [complete, setComplete] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const {
        deviceUsers,
        loginState,
        loginBy,
    } = useAppSelector(state => ({
        deviceUsers: state.Login.deviceUsers,
        loginState: state.Login,
        loginBy: state.Login.loginBy
    }));

    useEffect(() => {
        setComplete(loginState.isLoading);
    }, [loginState.isLoading]);

    useEffect(() => {
        if (loginState?.error.message && loginState.error?.message) {
            setMessage(loginState.error.message);
        }
    }, [loginState.error]);

    useEffect(() => {

        if (loginState.isUserLogin === true){
            window.location.href = route.MAIN_CABINET_LIST;
        }

    }, [dispatch, loginState]);


    const facebookLoginUrl = 'https://www.facebook.com/v16.0/dialog/oauth?response_type=code&client_id=542684647025715&scope=email,public_profile&redirect_uri=https://api.marketcells.io/callback/connectors/oauthcb_ok&state=login-facebook';
    const googleLoginUrl = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=144592828722-11um92dt5m8mu7ruk7nnn66q0el63lgr.apps.googleusercontent.com&scope=email%20profile&redirect_uri=https://api.marketcells.io/callback/connectors/oauthcb_ok&state=login-google';
    const vkLoginUrl = 'https://oauth.vk.com/authorize?response_type=code&client_id=51618818&scope=nickname+photo_200+email+first_name+last_name&redirect_uri=https://api.marketcells.io/callback/connectors/oauthcb_ok&state=login-vk';

    const twitterLoginUrl = 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=NGg3RjB2dHhKUGdfM2hBSkc3NDU6MTpjaQ&redirect_uri=https://api.marketcells.io/callback/connectors/oauthcb_ok&scope=tweet.read%20users.read%20offline.access&state=login-twitter&code_challenge=challenge&code_challenge_method=plain'

    const handleBackToLoginByDevice = () => {
        dispatch(changeLoginBy(LoginByTypes.DEVICE));
    }

    return (
        <>
            <CoverAuth title={t('auth:SignIn')+' | Marketcells.io - digital marketing platform'}>
                <Col lg={6}>
                    <CoverLangSwitch/>

                    {loginState?.isUserDisabled && loginState.isUserDisabled === true ?
                        <SignInDisabled/>
                        :
                    <div className="p-lg-5 p-4">
                        <div>
                            <h5 className="text-primary">{t('common:welcome')}!</h5>
                            <p className="text-muted">{t('auth:signInToContinue')}</p>
                        </div>

                        {message && message.length > 0 &&
                        <Alert className="alert-borderless alert-danger text-center mb-2" role="alert">
                            {t('common:'+message)}
                        </Alert>
                        }

                        <div className="mt-4">
                            {loginBy === LoginByTypes.DEVICE ?
                                <LoginByDevice deviceUsers={deviceUsers}/>
                                :
                                <LoginForm/>
                            }

                        </div>

                        <div className="mt-4 text-center">
                            <div className="signin-other-title">
                                <h5 className="fs-13 mb-4 title">{t('auth:signInWith')}</h5>
                            </div>
                            <div>
                                <Button tag="a" color="primary" className="btn-icon me-1 mb-1" href={facebookLoginUrl} ><i className="ri-facebook-fill fs-16" /></Button>
                                <Button tag="a" color="danger" className="btn-icon me-1 mb-1" href={googleLoginUrl} ><i className="ri-google-fill fs-16" /></Button>
                                <Button tag="a"  color="info" className="btn-icon me-1 mb-1" href={twitterLoginUrl}><i className="ri-twitter-fill fs-16"></i></Button>
                                <Button tag="a" color="soft-primary" className="btn-icon mb-1" href={vkLoginUrl} ><i className="bx bxl-vk fs-20"/></Button>
                                {(deviceUsers || []).length>0 &&
                                    <Button tag="a" color="success"
                                            className="btn-icon ms-1 mb-1 bg-gradient"
                                            href={"#"}
                                            title={t('device:userDevice')}
                                            onClick={handleBackToLoginByDevice}>
                                        <i className="ri-device-line"/>
                                    </Button>
                                }
                                {/*<Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}*/}
                            </div>
                        </div>

                        <div className="mt-5 text-center">
                            <p className="mb-0">{t('auth:dontHaveAnAccount')}? <Link to={route.MAIN_AUTH_SIGN_UP}  className="fw-bold text-primary text-decoration-underline">{t('auth:signUp')}</Link>
                            </p>
                        </div>
                    </div>
                    }
                </Col>
            </CoverAuth>
        </>
    );
};
export default CoverLogin;