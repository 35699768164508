import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import {SaleLicenseActionTypes, SaleLicenseKeyActivatePayload} from "./types";
import { saleLicenseApiResponseSuccess, saleLicenseApiResponseError } from "./actions";

import {
  activateLicenseKey as activateLicenseKeyApi,
} from "../../../api";
import {showErrorNotification, showSuccessNotification} from "../../../helpers/notifications";

function* onActivateLicenseKey({payload: data}:any) {
  try {
    const response: Promise<any> = yield call(activateLicenseKeyApi,data);
    yield put(saleLicenseApiResponseSuccess(SaleLicenseActionTypes.ACTIVATE_LICENSE_KEY, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleLicenseApiResponseError(SaleLicenseActionTypes.ACTIVATE_LICENSE_KEY, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchOnActivateLicenseKey() {
  yield takeEvery(SaleLicenseActionTypes.ACTIVATE_LICENSE_KEY, onActivateLicenseKey);
}

function* saleLicenseSaga() {
  yield all([
    fork(watchOnActivateLicenseKey),
  ]);
}

export default saleLicenseSaga;
