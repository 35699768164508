import React from 'react';
import { Spinner } from 'reactstrap';

const ModalPreloader = () => {
    return (
        <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
            <Spinner color="primary"> Loading... </Spinner>
        </div>
    );
};

export default ModalPreloader;