import React from "react";
import {normalizeTag} from "../helpers/functions";

interface InputTagItemProps {
  tag: string;
}

const TagItem = ({ tag }: InputTagItemProps) => {
    const displayTag = normalizeTag(tag);

  return (
      <div className="badge badge-soft-primary me-2">{displayTag}</div>
  );
};
export default TagItem;
