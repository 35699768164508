import React from 'react';
import ProfileSwitch from "./ProfileSwitch";

const CoverProfileSwitch = () => {
    return (
        <>
            <div className="float-end me-3">
                <ProfileSwitch/>
            </div>
        </>
    )
}

export default CoverProfileSwitch