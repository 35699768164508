import React from "react";
import CalendarIndex from "../Calendar/CalendarIndex";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
const CalendarActivityIndex = () => {
    const { t,i18n } = useTranslation();

    return (
        <>
            <BreadCrumb title={t('menu:activitiesPlan')} pageTitle={t('menu:main')} />
            <CalendarIndex/>
        </>
    );
}
export default CalendarActivityIndex;