import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    hideRightModalSaleCategory,
    onSendDataSaleCategory
} from "../../../redux/Sale/Category/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {SaleCategoryType} from "../../../redux/Sale/Category/types";
import {SaleCategoryValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";
import InputFile from "../../../components/InputFile";
import {MainFileType} from "../../../redux/Main/File/types";
import InputSelect from "../../../components/InputSelect";
import {makeSelectCategoryItem, prepareSelectCategories} from "../../../helpers/functions";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [categoryTree, setCategoryTree] = useState<any>([]);
    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);

    const {
        saleCategoryState,
        isSending
    } = useAppSelector(state => ({
        saleCategoryState: state.SaleCategory,
        isSending: state.SaleCategory.isSending
    }));

    let selectData=[{uuid:null, name:t('category:rootCategory')}];

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    const makeSelectData = (tree: any, depthLevel : number) => {
        (tree || []).map((item)=>{
            makeSelectCategoryItem(selectData, item, depthLevel);
            return item;
        });

        setCategoryTree([...selectData]);
    }

    useEffect(()=>{

        if (saleCategoryState?.items && (saleCategoryState.items || []).length>0) {

            let tree = prepareSelectCategories(saleCategoryState.items);

            makeSelectData(tree,0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[saleCategoryState?.items]);

    const uuid = saleCategoryState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<SaleCategoryType>(saleCategoryState.selectedItem);

    useEffect(() => {

        setFormValues(saleCategoryState.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalSaleCategory());

    const onDataSubmit = (formData:SaleCategoryType) => {

        let attachmentFiles=[];

        let params: SaleCategoryType = {
            uuid: formData.uuid && formData.uuid,
            enabled: formData.enabled ? formData.enabled : false,
            name: formData.name && formData.name,
            order: formData.order && formData.order,
            idRemote: formData.idRemote && formData.idRemote,
            text: formData.text && formData.text,
            description: formData.description && formData.description,
            media: formData.media && formData.media
        };

        if (formData['parentCategory']!== undefined && formData['parentCategory']!== null){
            if (formData['parentCategory']['value'] !== undefined && formData['parentCategory']['value']!==null) {
                if (formData['parentCategory']['value'] !== formData.uuid) {

                    if (formData['parentCategory']['value'] === null) {
                        params = {
                            ...params,
                            parentCategory: {
                                uuid: null,
                                name: null
                            }
                        };
                    } else {

                        const parentCategory = (saleCategoryState.items || []).filter((item: SaleCategoryType) => {
                            return item.uuid === formData['parentCategory']['value']
                        }).shift();

                        if (parentCategory?.uuid && parentCategory.uuid !== null) {
                            params = {
                                ...params,
                                parentCategory: {
                                    uuid: parentCategory.uuid,
                                    name: parentCategory.name
                                }
                            };
                        }
                    }
                }
            } else {
                params = {
                    ...params,
                    parentCategory: {
                        uuid: null,
                        name: null
                    }
                };
            }
        }

        (formData.media|| []).map((fileItem:MainFileType)=>{
            if (fileItem.uuid === undefined){
                attachmentFiles.push(fileItem);
            }
            return fileItem;
        });

        if (attachmentFiles.length>0){
            params['attachmentFiles']=attachmentFiles;
        }

        dispatch(onSendDataSaleCategory(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleCategoryState.rightModalShow}
                    id="saleCategoryEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleCategoryValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {

                        return(
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('category:'+saleCategoryState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                    <Field
                                        id="form-name-input"
                                        name="name"
                                        placeholder={t('common:enterNameTitle')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row key={'parentCategory'} className="mb-3">
                                        <Label className="form-label" htmlFor="form-parentCategory-select">{t('category:parentCategory')}</Label>
                                        <Field
                                            id="form-parentCategory-select"
                                            name={"parentCategory"}
                                            placeholder={t('category:chooseParentCategory')}
                                            component={InputSelect}
                                            value={getFieldProps('parentCategory').value}
                                            onChange={(value: any) => {
                                                setFieldValue("parentCategory",value);
                                            }}
                                            items={categoryTree || []}
                                            className={errors["parentCategory"] && touched["parentCategory"] && 'form-control is-invalid'}
                                        />
                                    </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Field
                                                id={"form-attachmentFiles-input"}
                                                name={'media'}
                                                placeholder={t('category:categoryImagePlaceholder')}
                                                isMultiple={true}
                                                component={InputFile}
                                                value={getFieldProps('media').value}
                                                onChange={(value: MainFileType[]) => {
                                                    setFieldValue('media', value);
                                                }}
                                                required={false}
                                                acceptedFiles=".jpg, .jpeg, .png"
                                                maxFiles={1}
                                            />
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-order-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-order-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-description-input">{t('common:shortDescription')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-description-input"
                                                    name="description"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('description').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('description', text);
                                                    }}
                                                    isShowEmoji={true}
                                                    isShowAITextGenerate={true}
                                                    required={false}
                                                    placeholder={t('common:enterShortDescription')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-text-input">{t('common:fullText')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-text-input"
                                                    name="text"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('text').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('text', text);
                                                    }}
                                                    isShowEmoji={true}
                                                    isShowAITextGenerate={true}
                                                    required={false}
                                                    placeholder={t('common:enterFullText')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-0"}>
                                            <Label className="form-label" htmlFor="form-additionalSettings-input">{t('common:additionalSettings')} <a href="#" onClick={toggleShowAdditionalSettings}>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('ai:moreInfo')}
                                                        </Tooltip>
                                                    }
                                                ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                            </a></Label>
                                        </div>
                                    </Row>
                                    {isShowAdditionalSettings &&
                                    <Row>
                                        <div className={ "mb-3"}>
                                            <Label className="form-label" htmlFor="form-idRemote-input">{t('common:idRemote')}</Label>
                                            <Field
                                                id="form-idRemote-input"
                                                name="idRemote"
                                                placeholder={t('common:enterIdRemote')}
                                                component={InputText}
                                                value = {getFieldProps('idRemote').value}
                                                onChange={(order:string) => {
                                                    setFieldValue('idRemote', order);
                                                }}
                                            />
                                        </div>
                                    </Row>
                                    }
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;