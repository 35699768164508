import {ContentProjectsActionTypes, ContentProjectsPayload} from "./types";

export const contentProjectsApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentProjectsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentProjectsApiResponseError = (actionType: string, error: string) => ({
  type: ContentProjectsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentProjects= () => ({
  type: ContentProjectsActionTypes.GET_CONTENT_PROJECTS,
});

export const showRightModalContentProjects= (data: ContentProjectsPayload) => ({
  type: ContentProjectsActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalContentProjects= () => ({
  type: ContentProjectsActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataContentProjects = (data: any) => ({
  type: ContentProjectsActionTypes.UPDATE_CONTENT_PROJECTS,
  payload: data,
});

export const editDataContentProjects = (data: ContentProjectsPayload) => ({
  type: ContentProjectsActionTypes.EDIT_CONTENT_PROJECTS,
  payload: data,
});

export const removeContentProjectModalShow = (data: ContentProjectsPayload) => ({
  type: ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT_MODAL_SHOW,
  payload: data,
});

export const removeContentProjectModalHide = () => ({
  type: ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT_MODAL_HIDE,
});

export const onRemoveContentProject = (data: ContentProjectsPayload) => ({
  type: ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT,
  payload: data,
});