import {HelperItemType} from "../../Helper/HelperItem/types";
import {ContentPeerTargetType} from "../../Content/PeerTarget/types";
import {PeerActivityType} from "../../Content/PeerActivity/types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {ContentProjectType} from "../../Content/Project/types";
import {DataSetOrderType} from "../../Main/common";
import {StatProjectTilesType} from "../DashboardContent/types";

export enum StatPeerChannelContentActionTypes {
    API_RESPONSE_SUCCESS = "@@statContentPeerChannel/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@statContentPeerChannel/API_RESPONSE_ERROR",
    GET_STAT_CHANNEL_TILES = "@@statContentPeerChannel/GET_STAT_CHANNEL_TILES",
    GET_STAT_CHANNEL_ACTIVITIES_DATA = "@@statContentPeerChannel/GET_STAT_CHANNEL_ACTIVITIES_DATA",
    GET_STAT_CHANNEL_TARGETS = "@@statContentPeerChannel/GET_STAT_CHANNEL_TARGETS",
    GET_STAT_CHANNEL_ACTIVITIES = "@@statContentPeerChannel/GET_STAT_CHANNEL_ACTIVITIES",
    GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES = "@@statContentPeerChannel/GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES",
    SET_PERIOD = "@@statContentPeerChannel/SET_PERIOD",
}

export interface StatPeerChannelState {
    tiles: Array<StatPeerChannelTileType>
    activitiesData: Array<StatPeerChannelDataType>,
    targets: Array<ContentPeerTargetType>,
    activities: Array<PeerActivityType>,
    scheduleActivities: Array<PeerActivityType>,
    periodFrom: number,
    selectedDate?: number,
    periodTo?: number,
    deltaDays?: number,
    eTagActivities?: string,
    requestUuid?: string,
    isStatPeerChannelContentFetched: boolean,
    isContentPeerChannelTargetFetched: boolean
}

export interface StatPeerChannelTileType{
  label?: string;
  value?: StatPeerChannelTileValueType;
  helperItem?: HelperItemType;
  isLoading?: boolean;
}

export interface StatPeerChannelTileValueType{
    last?: number,
    first?: number,
    current?: number,
}

export interface StatPeerChannelDataType{
    comments?: number;
    likes?: number,
    memberJoin? : number,
    memberLeave? : number,
    posts?: number,
    postsUGS?: number,
    date?: string
}

export interface StatPeerChannelPayloadType{
    project?: ContentProjectType,
    channel?: ContentPeerChannelType
    periodFrom?: number,
    periodTo?: number,
    limit?: number,
    order?: DataSetOrderType,
    requestUuid?: string
}




export const emptyChannelsTiles: StatPeerChannelTileType[] =
    [
        {
            label: "members",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "posts",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "postsUGS",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "views",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "er",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "erDay",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "erView",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "loveRate",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "talkRate",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "likes",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "comments",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "reposts",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        }
    ];

export const emptyProjectChannelsTiles: StatProjectTilesType = {
    items: emptyChannelsTiles
};

export const emptyProjectsChannelsTiles: StatProjectTilesType[] = [emptyProjectChannelsTiles];