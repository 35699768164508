import { APIClient } from "../apiCore";
import * as url from "../urls";

const api = new APIClient();

const activateLicenseKey = (data:any) => {
    return api.create(url.ACTIVATE_LICENSE_KEY,data);
};

export {
    activateLicenseKey
}