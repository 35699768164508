//@ts-nocheck
import React, {useEffect, useState, useCallback} from "react";
import { NumericFormat } from 'react-number-format';
import EmojiButtons from "./EmojiButtons";
import {ContentTemplatePayload, ContentTemplateType} from "../redux/Content/Template/types";
import TemplateButton from "./TemplateButton";
import InputSelect from "./InputSelect";
import {useRedux} from "../hooks";
import {showRightModalContentTemplates} from "../redux/Content/Template/actions";
import '../i18/config';
import {useTranslation} from "react-i18next";
import AIGenerateButton from "./AIGenerateButton";
import {aiTextGenerateOpenModal} from "../blocks/AI/TextGenerate/TextGenerateActions";
import {MainUserType} from "../redux/Main/User/types";
import { MentionsInput, Mention } from 'react-mentions'

interface MentionType {
    id: string,
    display: string,
}

interface InputTextareaProps {
    id: string;
    placeholder: null | string
    value: null | string;
    onChange: (value: string) => void;
    maxLength?: number;
    templates?: Array<ContentTemplateType>
    isAddToTemplate?: true | boolean
    isShowEmoji?: true | boolean
    isShowAITextGenerate: false | boolean;
    contacts: Array<MainUserType>
}
const InputMentionEmojiArea = ({ id, placeholder, value, onChange, maxLength, templates, isAddToTemplate, isShowEmoji, isShowAITextGenerate, contacts }: InputTextareaProps) => {
    const { dispatch } = useRedux();
    const { t } = useTranslation(['template']);
    const [text, setText] = useState<string>(value || '');
    const [isShowTemplate, setShowTemplate] = useState<boolean>(false);
    const [isShowToolbar, setShowToolbar] = useState<boolean>(false);
    const [caretPosition, setCaretPosition] = useState<number>(0);

    const [mentionContacts, setMentionContacts] = useState<MentionType[]>([]);

    const initializeMentionContacts = useCallback(() => {
        let _mentionContacts: MentionType[] = [];
        (contacts || []).forEach((item: MainUserType) => {
            _mentionContacts.push({
                id: item.uuid,
                display: item.name ? item.name : item.email
            });
        });
        setMentionContacts(_mentionContacts);
    }, [contacts]);

    useEffect(initializeMentionContacts, [initializeMentionContacts]);

    const onChangeText = (text:string) => {
        setText(text);
        onChange(text);
    }

    useEffect(() => {
        setText(value);
    }, [value]);

    const onChangeShowTemplate = () => {
        setShowTemplate(!isShowTemplate);
    }

    const onTemplateSelect = (item: any) => {
        if (item.value !== undefined){
            (templates || []).map((templateItem: ContentTemplateType)=>{
                if (item.value === templateItem.uuid){
                    if (text !== undefined) {
                        onChangeText(text + ' ' + templateItem.text);
                    } else {
                        onChangeText(templateItem.text);
                    }
                }
                return templateItem;
            });
        }
    }

    useEffect(() => {

        if (isShowEmoji || isShowAITextGenerate || isShowTemplate){
            setShowToolbar(true);
        } else {
            setShowToolbar(false);
        }

    }, [isShowEmoji, isShowAITextGenerate, isShowTemplate]);


    const handleAddToTemplate = () =>{

        const newTemplate:ContentTemplateType ={
            enabled: true,
            name: '',
            text: text
        }

        const contentTemplatePayload :ContentTemplatePayload={
            data: newTemplate,
            modalEditTitle: 'newTemplate'
        }

        dispatch(showRightModalContentTemplates(contentTemplatePayload))
    }

    const handleAITextGenerate = () =>{
        aiTextGenerateOpenModal({text: text},dispatch);
    }

    const handleCaretPosition = (event:any)=>{
        setCaretPosition(event.target.selectionStart);
    }

  return (
      <>
          <div className="position-relative">
              {isShowToolbar &&
                  <>
                      <div className="rightToolBarCont d-flex flex-column">
                      {isShowEmoji &&
                          <div className="mb-1">
                              <EmojiButtons
                                  text={text}
                                  onChange={onChangeText}
                                  cursorPosition={caretPosition}
                              />
                          </div>
                      }
                      {templates && templates.length>0 &&
                          <div className="mb-1">
                          <TemplateButton onChange={onChangeShowTemplate} />
                          </div>
                      }
                      {isShowAITextGenerate &&
                          <div className="mb-1">
                          <AIGenerateButton onChange={handleAITextGenerate}/>
                          </div>
                      }
                      </div>
                  </>
              }
              <div className={""+(isShowToolbar?'rightToolBarEditorMargin':'')}>


                  <MentionsInput
                        className="mentions"
                      value={text || ''}
                      onChange={(e: any) => {
                          onChangeText(e.target.value);
                      }}
                      onKeyUp={(e: any) => {
                          handleCaretPosition(e);
                      }
                      }
                      onMouseUp={(e: any) => {
                          handleCaretPosition(e);
                      }
                      }
                  >
                      <Mention
                          trigger="@"
                          data={mentionContacts}
                          className="mentions__mention"
                      />
                  </MentionsInput>



                  {/*<Input*/}
                  {/*  type="textarea"*/}
                  {/*  id={ id }*/}
                  {/*  placeholder={ placeholder }*/}
                  {/*  value={text || ''}*/}
                  {/*  onChange={(e: any) => {*/}
                  {/*      onChangeText(e.target.value);*/}
                  {/*  }}*/}
                  {/*  onKeyUp={(e: any) => {*/}
                  {/*      handleCaretPosition(e);*/}
                  {/*      }*/}
                  {/*  }*/}
                  {/*  onMouseUp={(e: any) => {*/}
                  {/*      handleCaretPosition(e);*/}
                  {/*      }*/}
                  {/*  }*/}
                  {/*  autoComplete="off"*/}
                  {/*  rows={7}*/}
                  {/*/>*/}
                  <div className="lengthCount text-muted fs-10 d-flex flex-row flex-nowrap justify-content-end">
                      { maxLength ?
                          <>
                      <span className="colorBlue">
                          {text && text.length ?
                              <NumericFormat thousandSeparator={' '} displayType={'text'} value={text.length}/>
                              :
                              0
                          }
                      </span>
                              <span className="text-muted ms-2">
                          <NumericFormat thousandSeparator={' '} displayType={'text'} value={maxLength}/>
                      </span>
                          </>
                          :
                          <span className="colorBlue">
                      {text && text.length ?
                          <NumericFormat thousandSeparator={' '} displayType={'text'} value={text.length}/>
                          :
                          0
                      }
                  </span>
                      }
                  </div>
              </div>
          <div className="d-flex flex-row flex-nowrap flex-grow-1">
              <div className="d-flex flex-row flex-grow-1">
                  {isAddToTemplate &&
                  <a className="colorBlue fs-10" onClick={handleAddToTemplate}>+{" "}{t('template:toTemplates')}</a>
                  }
              </div>
          </div>
          {isShowTemplate &&
              <InputSelect
                  items={templates}
                  placeholder={t('template:chooseTemplate')}
                  onChange={onTemplateSelect}
                  required={false}
              />
          }
          </div>
      </>
  );
};
export default InputMentionEmojiArea;
