import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    hideRightModalEventWebhook,
    onSendDataEventWebhook
} from "../../../redux/Event/Webhook/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {EventWebhookType} from "../../../redux/Event/Webhook/types";
import {EventWebhookValidatorSchema} from "./validator";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {EventTypeType} from "../../../redux/Event/EventType/types";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {isNumeric} from "../../../helpers/functions";
import {ContentProjectType} from "../../../redux/Content/Project/types";
import UpgradeFareBlock from "../../../components/UpgradeFareBlock";
import NoticeTip from "../../../components/NoticeTip";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);
    const [availableEventTypes, setAvailableEventTypes] = useState<EventTypeType[]>([]);
    const [webhook, setWebhook] = useState<EventWebhookType>(null);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

    const {
        eventWebhookState,
        isSending,
        salePlan
    } = useAppSelector(state => ({
        eventWebhookState: state.EventWebhook,
        isSending: state.EventWebhook.isSending,
        salePlan: state.SalePlan

    }));

    const uuid = eventWebhookState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<EventWebhookType>(eventWebhookState.selectedItem);

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            setCurrentPlan({...salePlan.currentPlan});
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        checkFareRestriction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.MAIN_MAX_WEBHOOKS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(true);
        } else if (eventWebhookState) {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value) === (eventWebhookState.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    //edit only
                    if (uuid === undefined || uuid === null) {
                        setUpgradeFare(true);
                    } else {
                        setUpgradeFare(false);
                    }
                } else if (parseInt(restrictionValue.value) < (eventWebhookState.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    setUpgradeFare(true);
                } else {
                    setUpgradeFare(false);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    const setCurrentFormValues = (item: EventWebhookType) => {
        let formValues = item;

        if (!item?.url || (item?.url && item.url === null)){
            item.url = '';
        }

        if (item?.eventTypes && item.eventTypes !== null){
            (item.eventTypes || []).map((item:EventTypeType)=>{
                formValues['eventType_' + item.uuid]=item.enabled;
                return item;
            });
        }

        setFormValues(formValues);
    }

    useEffect(() => {
        setCurrentFormValues({...webhook});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webhook]);

    useEffect(() => {

        if (eventWebhookState?.selectedItem) {
            setWebhook({...eventWebhookState.selectedItem});
        } else {
            setWebhook(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventWebhookState?.selectedItem]);

    useEffect(() => {

        if (eventWebhookState?.availableEventTypes && (eventWebhookState?.availableEventTypes || []).length>0){
            setAvailableEventTypes([...eventWebhookState.availableEventTypes]);
        } else {
            setAvailableEventTypes([]);
        }

    }, [eventWebhookState?.availableEventTypes]);

    const handleClose = () => dispatch(hideRightModalEventWebhook());

    const onDataSubmit = (formData:EventWebhookType) => {

        let params: EventWebhookType = {
            uuid: formData.uuid && formData.uuid,
            enabled: formData.enabled ? formData.enabled : false,
            name: formData.name && formData.name,
            order: formData.order && formData.order,
            url: formData.url && formData.url,
            eventTypes: []
        };

        if (availableEventTypes !== null){
            (availableEventTypes || []).map((item:EventTypeType)=>{
                if (formData['eventType_' + item.uuid]){
                    params.eventTypes = [
                        ...params.eventTypes,
                        item
                    ];
                }
                return item;
            });
        }

        dispatch(onSendDataEventWebhook(params));

    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={eventWebhookState.rightModalShow}
                    id="eventWebhookEdit"
                    toggle={handleClose}
                >
                    { upgradeFare ?
                        <UpgradeFareBlock onClose={handleClose}/>
                        :
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={EventWebhookValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('webhook:'+eventWebhookState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                    <Field
                                        id="form-name-input"
                                        name="name"
                                        placeholder={t('common:enterNameTitle')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.url ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-url-input">{t('common:url')}</Label>
                                            <Field
                                                id="form-url-input"
                                                name="url"
                                                placeholder={t('common:enterUrl')}
                                                component={InputText}
                                                value = {getFieldProps('url').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('url', value);
                                                }}
                                                className={errors.url && touched.url && 'form-control is-invalid'}
                                            />
                                            {errors.url && touched.url ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.url)}
                                                </div>
                                            ) :  touched.url && !errors.url ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-order-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-order-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    {availableEventTypes &&
                                        <Row>
                                            <div>
                                                <Label className="form-label" htmlFor={"choices-eventTypes-input"}>{t('eventType:eventTypes')}</Label>

                                                { (availableEventTypes || []).map((item:EventTypeType)=>{
                                                    return (
                                                        <div key={"form-eventType-input_"+item.uuid}>
                                                            {item.enabled &&
                                                                <div className="ms-1 mb-3 d-flex flex-column">
                                                                    <Field
                                                                        id={"form-eventType-input_"+item.uuid}
                                                                        placeholder={t('eventType:'+item.code)}
                                                                        name={"eventType_"+item.uuid}
                                                                        component={InputSwitch}
                                                                        value = {getFieldProps("eventType_"+item.uuid).value}
                                                                        onChange={(enabled:boolean) => {
                                                                            setFieldValue("eventType_"+item.uuid, !getFieldProps("eventType_"+item.uuid).value);
                                                                        }}
                                                                        className={"switch-sm"}
                                                                        required={false}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Row>
                                    }
                                    {formValues?.uuid && formValues.uuid.length>0 &&
                                    <NoticeTip firstText={t('webhook:approveText').replace(/%uuid%/,formValues.uuid)}/>
                                    }
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>
                    }

                </Offcanvas>
        </>
    );
}

export default RightModal;