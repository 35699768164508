import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label,
    Input,
} from "reactstrap";
import '../../../i18/config';
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import { useRedux } from "../../../hooks";
import {LordIcon} from "../../../components/LordIcon";
import {PaymentSystemCode, PaymentSystemIcon, PaymentSystemType} from "../../../redux/Sale/PaymentSystem/types";
import {getPaymentSystems} from "../../../redux/Sale/PaymentSystem/actions";
import {CartPlanType, SalePlanPriceType} from "../../../redux/Sale/Plan/types";
import {clearM9SCartProduct, getCurrentCurrencyCode, getM9SCartProduct} from "../../../helpers/functions";
import {LocaleCurrencySymbol} from "../../../redux/Sale/Currency/types";
import Loader from "../../../components/Loader";
import {PlanOrderFormType, PlanOrderType} from "../../../redux/Sale/PlanOrder/types";
import {useProfile} from "../../../hooks/UserHooks";
import {Formik, Form, Field, useFormikContext} from 'formik';
import InputText from "../../../components/InputText";
import {PlanOrderFormValidatorSchema} from "./validator";
import * as route from "../../../api/routes";
import {onCreatePlanOrder} from "../../../redux/Sale/PlanOrder/actions";
import {Spinner} from "react-bootstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {CouponType} from "../../../redux/Sale/Coupon/types";
import {checkCoupon, clearCoupon} from "../../../redux/Sale/Coupon/actions";
import {DiscountTypeType} from "../../../redux/Sale/Discount/types";

const logo = require('../../../resources/images/mcLogoCircle.svg');

const CouponEdit = () =>{
    const { dispatch, useAppSelector } = useRedux();
    const {setFieldValue, getFieldProps, touched, errors} = useFormikContext();
    const [isShow, setShow] = useState<boolean>(false);

    const { t } = useTranslation(['common']);

    const {
        couponState,
        isSending
    } = useAppSelector(state => ({
        couponState: state.Coupon,
        isSending: state.Coupon.isSending
    }));

    const handleCouponCheck = (coupon: CouponType) => {
        if (coupon?.code && coupon.code.length>0){
            dispatch(checkCoupon(coupon));
        }
    }

    const handleCouponRemove = () => {
        setFieldValue('coupon', null);
        dispatch(clearCoupon());
    }

    useEffect(()=>{
        if (couponState?.selectedItem?.code.length>4) {
            setFieldValue('coupon', couponState?.selectedItem);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[couponState?.selectedItem])

    const handleToggleCoupon = () => {
        setShow(!isShow);
    }

    return (
        <Row>{
            isShow ?

            <div className={(touched['coupon'] ? " was-validated" : '')}>
                <div className="hstack gap-3 px-3 mx-n3">
                    <div className="d-flex flex-column flex-grow-1">
                        <Field
                            id="form-coupon-input"
                            name="coupon"
                            placeholder={t('sale:enterPromoCode')}
                            component={InputText}
                            disabled = {getFieldProps('coupon').value && getFieldProps('coupon').value?.discount?.type}
                            value = {getFieldProps('coupon').value && getFieldProps('coupon').value.code}
                            onChange={(value:string) => {
                                setFieldValue('coupon', {
                                    ...getFieldProps('coupon').value,
                                    code: value
                                });
                            }}
                            className={errors['coupon'] && touched['coupon'] && 'form-control is-invalid'}
                        />
                        {/*{errors['coupon'] && touched['coupon'] ? (*/}
                        {/*    <div className="invalid-feedback">*/}
                        {/*        {errors['coupon']['code'] !== undefined && errors['coupon']['code'] !==null ?*/}
                        {/*            t('validator:'+errors['coupon']['code'])*/}
                        {/*            :*/}
                        {/*            t('validator:'+errors['coupon'])*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*) :  touched['coupon'] && !errors['coupon'] ?*/}
                        {/*    <div className="valid-feedback">*/}
                        {/*        {t('common:looksGood')}*/}
                        {/*    </div>*/}
                        {/*    : ''*/}
                        {/*}*/}
                    </div>
                    <div className="d-flex flex-row flex-shrink-0">
                    {getFieldProps('coupon').value && getFieldProps('coupon').value?.discount?.type
                        ?
                        <button
                            onClick={()=>{handleCouponRemove()}}
                            title={t('common:delete')}
                            className="btn btn-outline-danger btn-icon waves-effect waves-light ms-2">
                            <i className="ri-delete-bin-5-line"/>
                        </button>
                        :
                        <button
                            onClick={()=>{
                                handleCouponCheck(getFieldProps('coupon').value)}
                            }
                            type="button"
                            disabled={isSending}
                            className="ms-1 btn btn-outline-primary btn-icon waves-effect waves-light text-align-center">
                            {isSending
                                ?
                                <Spinner size='sm'/>
                                :
                                <i className="ri-check-line"/>
                            }
                        </button>
                    }
                    </div>
                </div>
            </div>
                :
                <div className="d-flex flex-row flex-grow-1 justify-content-center">

                    <a href={"#"} onClick={handleToggleCoupon} className = "text-muted">
                        {t('sale:havePromoCode')}?
                    </a>
                </div>
            }
        </Row>
    )
}


const PlanCheckoutIndex = () => {

    const { dispatch, useAppSelector } = useRedux();
    const { t, i18n } = useTranslation(['common']);
    const { userProfile } = useProfile();

    const [createOrder, setCreateOrder] = useState<boolean>(false);
    const [coupon, setCoupon] = useState<CouponType>(null);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [paymentSystems, setPaymentSystems] = useState<PaymentSystemType[]>([]);
    const [cartProducts, setCartProducts] = useState<CartPlanType[]>(getM9SCartProduct());
    const currency = getCurrentCurrencyCode();

    const [order, setOrder] = useState<PlanOrderType>(null);

    const customerOrder: PlanOrderFormType = {
        customerName: userProfile.name,
        customerEmail: userProfile.email,
        customerCompany: userProfile.name,
        orderItems: cartProducts,
        currencyCode: currency,
    }

    const [formValues, setFormValues] = useState<PlanOrderFormType>(customerOrder);

    const {
        mainCabinet,
        paymentSystem,
        isPaymentSystemFetched,
        planOrder,
        isLoading,
        couponState
    } = useAppSelector(state => ({
        mainCabinet: state.MainCabinet,
        paymentSystem: state.PaymentSystem,
        isPaymentSystemFetched: state.PaymentSystem.isFetched,
        planOrder: state.PlanOrder,
        isLoading: state.PlanOrder.isFetched,
        couponState: state.Coupon,
    }));

    function toggleTab(tab) {
        if ((activeTab !== tab) && (activeTab!==3)){
            let modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 3) {
                setActiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    useEffect(() => {
        if (cartProducts === undefined){
            window.location.href = route.SALE_PLANS;
        } else {
            dispatch(getPaymentSystems({maxResults:1000}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (couponState?.selectedItem?.discount?.type !== undefined){
            setCoupon({...couponState?.selectedItem});
        } else {
            setCoupon(null);
        }
    }, [couponState?.selectedItem]);

    useEffect(() => {
        if ((paymentSystem.items !== undefined) && (paymentSystem.items.length>0)){
            setPaymentSystems([...paymentSystem.items]);
        } else {
            setPaymentSystems([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentSystem?.items]);

    useEffect(() => {
        if ((planOrder.current !== undefined) && (planOrder.current !== null) && (planOrder.current.uuid !== undefined)){
            setOrder({...planOrder.current});
            setActiveTab(3);
            setCreateOrder(false);
            dispatch(clearCoupon());
            clearM9SCartProduct();
        } else {
            setOrder(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planOrder?.current])

    let subTotalAmount = 0;
    let discountAmount = 0;
    let totalAmount = 0;

    const calculateOrder = () => {
        (cartProducts || []).map((item:CartPlanType)=> {
            const price = (item.product.productPrices.filter((price: SalePlanPriceType) => {
                    return price.priceType.code === item.priceType.code && price.currency.code === currency
                }
            )).pop();

            subTotalAmount += price.price * item.quantity;

            return item;
        });

        totalAmount = subTotalAmount;

        if (coupon !== null && coupon?.discount?.type !== null) {
            if (coupon?.discount?.type === DiscountTypeType.TYPE_FIXED){
                if (currency === coupon?.discount?.currency.code) {
                    totalAmount -= coupon?.discount?.value;
                }
            } else if (coupon?.discount?.type === DiscountTypeType.TYPE_PERCENT){
                let discountValue = subTotalAmount*(100 - coupon?.discount?.value)/100;
                if (coupon?.discount?.limit !== undefined && coupon?.discount?.limit !== null){
                    if (discountValue> coupon?.discount?.limit){
                        discountValue = coupon?.discount?.limit;
                    }
                }
                totalAmount -= discountValue;
            }
        }
    }

    calculateOrder();

    const onDataSubmit = (formData:PlanOrderFormType) => {

        setCreateOrder(true);
        formData.cabinet = mainCabinet.currentCabinet;

        if (couponState?.selectedItem?.discount?.type !== undefined && couponState?.selectedItem?.discount?.type !== null){
            formData.coupon = couponState.selectedItem;
        }

        dispatch(onCreatePlanOrder(formData));
    }

    return (
        <>
            <BreadCrumb title={t('menu:orderCheckout')} pageTitle={t('menu:main')} />
            <Formik
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={PlanOrderFormValidatorSchema}
                onSubmit={(values, actions) => {
                    onDataSubmit(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {
                    return(
                        <Form noValidate={true} className="customValidation">
            <Row>
                <Col xl={activeTab === 3 ? "12" : "8"}>
                    <Card>
                        <CardBody className="checkout-tab">
                                <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                    <Nav
                                        className="nav-pills nav-justified custom-nav"
                                        role="tablist"
                                    >
                                        <NavItem role="presentation">
                                            <NavLink href="#"
                                                     className={classnames({ active: activeTab === 1, done: (activeTab <= 3 && activeTab >= 0) }, "p-3 fs-15")}
                                                     onClick={() => { toggleTab(1); }}
                                            >
                                                <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                {t('sale:customerInformation')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem role="presentation">
                                            <NavLink href="#"
                                                     className={classnames({ active: activeTab === 2, done: activeTab <= 3 && activeTab > 2 }, "p-3 fs-15")}
                                                     onClick={() => { toggleTab(2); }}
                                            >
                                                <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                {t('sale:paymentInfo')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem role="presentation">
                                            <NavLink href="#"
                                                     className={classnames({ active: activeTab === 3, done: activeTab <= 3 && activeTab > 3 }, "p-3 fs-15")}
                                            >
                                                <i className="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                {t('sale:checkoutFinish')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1} id="pills-bill-info">
                                        <div>
                                            <h5 className="mb-1">{t('sale:yourData')}</h5>
                                            <p className="text-muted mb-4">
                                                {t('sale:fillAllInformation')}
                                            </p>
                                        </div>

                                        <div>
                                            <Row>
                                                <Col sm={4}>
                                                    <div className={ "mb-3"+ (touched.customerName ? " was-validated" : '')}>
                                                        <Label className="form-label" htmlFor="form-title-input">{t('customer:customerName')}</Label>
                                                        <Field
                                                            id="form-title-input"
                                                            name="customerName"
                                                            placeholder={t('customer:enterCustomerName')}
                                                            component={InputText}
                                                            value={getFieldProps('customerName').value}
                                                            onChange={(value: string) => {
                                                                if (getFieldProps('customerName').value!==value){
                                                                    setFieldValue('customerName', value);
                                                                }
                                                            }}
                                                            className={errors.customerName && touched.customerName && 'form-control is-invalid'}
                                                        />
                                                        {errors.customerName && touched.customerName ? (
                                                            <div className="invalid-feedback">
                                                                {t('validator:'+errors.customerName)}
                                                            </div>
                                                        ) : touched.customerName && !errors.customerName ?
                                                            <div className="valid-feedback">
                                                                {t('common:looksGood')}
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <div className={ "mb-3"+ (touched.customerEmail ? " was-validated" : '')}>
                                                        <Label className="form-label" htmlFor="form-title-input">{t('customer:customerEmail')}</Label>
                                                        <Field
                                                            id="form-title-input"
                                                            name="customerEmail"
                                                            placeholder={t('customer:enterCustomerEmail')}
                                                            component={InputText}
                                                            value={getFieldProps('customerEmail').value}
                                                            onChange={(value: string) => {
                                                                if (getFieldProps('customerEmail').value!==value){
                                                                    setFieldValue('customerEmail', value);
                                                                }
                                                            }}
                                                            className={errors.customerEmail && touched.customerEmail && 'form-control is-invalid'}
                                                        />
                                                        {errors.customerEmail && touched.customerEmail ? (
                                                            <div className="invalid-feedback">
                                                                {t('validator:'+errors.customerEmail)}
                                                            </div>
                                                        ) : touched.customerEmail && !errors.customerEmail ?
                                                            <div className="valid-feedback">
                                                                {t('common:looksGood')}
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <div className={ "mb-3"+ (touched.customerPhone ? " was-validated" : '')}>
                                                        <Label className="form-label" htmlFor="form-title-input">{t('customer:customerPhone')}</Label>
                                                        <Field
                                                            id="form-title-input"
                                                            name="customerPhone"
                                                            placeholder={t('customer:enterCustomerPhone')}
                                                            component={InputText}
                                                            value={getFieldProps('customerPhone').value}
                                                            onChange={(value: string) => {
                                                                if (getFieldProps('customerPhone').value!==value){
                                                                    setFieldValue('customerPhone', value);
                                                                }
                                                            }}
                                                            className={errors.customerPhone && touched.customerPhone && 'form-control is-invalid'}
                                                        />
                                                        {errors.customerPhone && touched.customerPhone ? (
                                                            <div className="invalid-feedback">
                                                                {t('validator:'+errors.customerPhone)}
                                                            </div>
                                                        ) : touched.customerPhone && !errors.customerPhone ?
                                                            <div className="valid-feedback">
                                                                {t('common:looksGood')}
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4}>
                                                    <div className={ "mb-3"+ (touched.customerCompany ? " was-validated" : '')}>
                                                        <Label className="form-label" htmlFor="form-title-input">{t('customer:customerCompany')}</Label>
                                                        <Field
                                                            id="form-title-input"
                                                            name="customerCompany"
                                                            placeholder={t('customer:enterCustomerCompany')}
                                                            component={InputText}
                                                            value={getFieldProps('customerCompany').value}
                                                            onChange={(value: string) => {
                                                                if (getFieldProps('customerCompany').value!==value){
                                                                    setFieldValue('customerCompany', value);
                                                                }
                                                            }}
                                                            className={errors.customerCompany && touched.customerCompany && 'form-control is-invalid'}
                                                        />
                                                        {errors.customerCompany && touched.customerCompany ? (
                                                            <div className="invalid-feedback">
                                                                {t('validator:'+errors.customerCompany)}
                                                            </div>
                                                        ) : touched.customerCompany && !errors.customerCompany ?
                                                            <div className="valid-feedback">
                                                                {t('common:looksGood')}
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Col>
                                                <Col sm={4}>
                                                    <div className={ "mb-3"+ (touched.customerTin ? " was-validated" : '')}>
                                                        <Label className="form-label" htmlFor="form-title-input">{t('customer:customerTin')}</Label>
                                                        <Field
                                                            id="form-title-input"
                                                            name="customerTin"
                                                            placeholder={t('customer:enterCustomerTin')}
                                                            component={InputText}
                                                            value={getFieldProps('customerTin').value}
                                                            onChange={(value: string) => {
                                                                if (getFieldProps('customerTin').value!==value){
                                                                    setFieldValue('customerTin', value);
                                                                }
                                                            }}
                                                            className={errors.customerTin && touched.customerTin && 'form-control is-invalid'}
                                                        />
                                                        {errors.customerTin && touched.customerTin ? (
                                                            <div className="invalid-feedback">
                                                                {t('validator:'+errors.customerTin)}
                                                            </div>
                                                        ) : touched.customerTin && !errors.customerTin ?
                                                            <div className="valid-feedback">
                                                                {t('common:looksGood')}
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>



                                            <div className="d-flex align-items-start gap-3 mt-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-label right ms-auto nexttab"
                                                    onClick={() => {
                                                        toggleTab(activeTab + 1);
                                                    }}
                                                >
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>
                                                    {t('sale:nextStep')}
                                                </button>
                                            </div>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId={2}>
                                        <div>
                                            <h5 className="mb-1">{t('sale:paymentSelection')}</h5>
                                            <p className="text-muted mb-4">
                                                {t('sale:pleaseSelect')}
                                            </p>
                                        </div>

                                        <Row className="g-4">
                                            {isPaymentSystemFetched ?
                                            (paymentSystems || []).map((item: PaymentSystemType, key: number)=> {
                                                let paymentSystemIcon  =   PaymentSystemIcon[item.code.toUpperCase()];

                                                if (key === 0){
                                                    if (getFieldProps('paymentSystem').value === undefined) {
                                                        setFieldValue('paymentSystem', item);
                                                    }
                                                }

                                                return (
                                                    <Col lg={4} sm={6} key={'paymentSystem_' + key}>
                                                        <div>
                                                            <div className="form-check card-radio">
                                                                <Input
                                                                    id={"paymentMethod"+key}
                                                                    name="paymentSystem"
                                                                    type="radio"
                                                                    checked={getFieldProps('paymentSystem').value===undefined?key===0:getFieldProps('paymentSystem').value===item}
                                                                    className="form-check-input"
                                                                    onChange={(event: any)=>{
                                                                        if (event.target.checked === true){
                                                                            setFieldValue('paymentSystem', item);
                                                                        }
                                                                    }}
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    htmlFor={"paymentMethod"+key}
                                                                >
                                                                  <span className="fs-16 text-muted me-2">
                                                                      {item.code=== PaymentSystemCode.BINANCE ?
                                                                            <i className={"align-middle binance-icon-diff " + paymentSystemIcon}/>
                                                                          :
                                                                            <i className={"align-bottom " + paymentSystemIcon}/>
                                                                      }
                                                                  </span>
                                                                    <span className="fs-14 text-wrap">
                                                                    {item.name}
                                                                  </span>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                                }
                                            )
                                                :
                                                <Loader/>
                                            }

                                        </Row>

                                        {/*<div*/}
                                        {/*    className="collapse show"*/}
                                        {/*    id="paymentmethodCollapse"*/}
                                        {/*>*/}

                                        {/*    <div className="text-muted mt-2 fst-italic">*/}
                                        {/*        <i*/}
                                        {/*            data-feather="lock"*/}
                                        {/*            className="text-muted icon-xs"*/}
                                        {/*        ></i>{" "}*/}
                                        {/*        !#After you order was completed you'll proceed to payment*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="d-flex align-items-start gap-3 mt-4">
                                            <button
                                                type="button"
                                                className="btn btn-light btn-label previestab"
                                                onClick={() => {
                                                    toggleTab(activeTab - 1);
                                                }}
                                            >
                                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                                                {t('sale:backToCustomerData')}
                                            </button>

                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-label right ms-auto nexttab"
                                                disabled={createOrder === true}
                                            >
                                                {createOrder === true?
                                                    <i className="label-icon align-middle ms-2">
                                                        <Spinner size='sm'/>
                                                    </i>
                                                    :
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>
                                                }
                                                {t('sale:nextStep')} {createOrder}
                                            </button>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId={3} id="pills-finish">
                                        {order &&
                                        <div className="text-center py-5">
                                            <div className="mb-4">
                                                <LordIcon
                                                    src="https://cdn.lordicon.com/lupuorrc.json"
                                                    trigger="loop"
                                                    colors={{primary: '#0ab39c',secondary:'#36c5f1'}}
                                                    size={120}
                                                />
                                            </div>
                                            <h5>{t('sale:orderCompletedTitle')}</h5>
                                            <p className="text-muted">
                                                {t('sale:orderCompletedDescription')}
                                            </p>
                                            <h3 className="fw-semibold">
                                                {t('sale:order')} {" "}
                                                <Link
                                                    to={route.PLAN_ORDER_DETAIL.replace(/:uuid/g, order.uuid)}
                                                    className="text-decoration-underline"
                                                >
                                                    #{order.code}
                                                </Link>
                                            </h3>
                                            <div className="mt-5">
                                                <a
                                                    href={order.paymentUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="btn btn-success btn-label right ms-auto nexttab"
                                                >
                                                    <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                                                    {t('sale:proceedToPayment')}
                                                </a>
                                            </div>
                                        </div>
                                        }
                                    </TabPane>
                                </TabContent>
                        </CardBody>
                    </Card>
                </Col>
                {activeTab !== 3 &&
                <Col xl={4}>
                    <Card>
                        <CardHeader>
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <h5 className="card-title mb-0">{t('sale:orderSummary')}</h5>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className="table-responsive table-card">
                                <table className="table table-borderless align-middle mb-0">
                                    <thead className="table-light text-muted">
                                    <tr>
                                        <th style={{ width: "90px" }} scope="col">
                                            {t('sale:product')}
                                        </th>
                                        <th scope="col"></th>
                                        <th scope="col" className="text-end">
                                            {t('sale:itemPrice')}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(cartProducts || []).map((item:CartPlanType, key: number)=>{
                                        const price = (item.product.productPrices.filter((price:SalePlanPriceType)=>{
                                                return price.priceType.code===item.priceType.code && price.currency.code === currency
                                            }
                                        )).pop();
                                            return(
                                        <React.Fragment key={'cartProductItem_'+key}>
                                                    <tr>
                                                        <td>
                                                            <div className="avatar-md bg-light rounded p-1 d-flex align-items-center justify-content-center">
                                                                <img
                                                                    width={35}
                                                                    src={logo.default}
                                                                    alt=""
                                                                    className="img-fluid d-block"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <h5 className="fs-15">
                                                                <a
                                                                    href="#"
                                                                    className="text-dark"
                                                                >
                                                                    <span className="text-muted">{t('sale:marketcellsLicense')}</span> {item.product.name}
                                                                </a>
                                                            </h5>
                                                            <p className="text-muted mb-0">
                                                                {t('sale:period')}: <span className="fw-medium">{t('sale:priceType_'+price.priceType.code)}</span>
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                                {LocaleCurrencySymbol[currency]} {price.price} x {item.quantity}
                                                            </p>
                                                        </td>
                                                        <td className="text-end">{LocaleCurrencySymbol[currency]} {price.price * item.quantity}</td>
                                                    </tr>
                                        </React.Fragment>)
                                    })}

                                    <tr>
                                        <td className="fw-semibold" colSpan={2}>
                                            {t('sale:subTotal')} :
                                        </td>
                                        <td className="fw-semibold text-end">{LocaleCurrencySymbol[currency]} {subTotalAmount}</td>
                                    </tr>
                                    {coupon !== null && coupon?.discount?.type !== undefined &&
                                        <tr>
                                            <td colSpan={2}>
                                                {t('sale:discount')}{" "}
                                                <span className="text-muted">({coupon.discount.name})</span>:{" "}
                                            </td>
                                            <td className="text-end">
                                                {coupon?.discount?.type === DiscountTypeType.TYPE_FIXED ?
                                                    <>
                                                        - {LocaleCurrencySymbol[coupon?.discount?.currency?.code]} {coupon?.discount.value}
                                                    </>
                                                    :
                                                    <>
                                                        - {coupon?.discount.value} %
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    }
                                    {/*<tr>*/}
                                    {/*    <td colSpan={2}>Shipping Charge :</td>*/}
                                    {/*    <td className="text-end">$ 24.99</td>*/}
                                    {/*</tr>*/}
                                    {/*<tr>*/}
                                    {/*    <td colSpan={2}>Estimated Tax (12%): </td>*/}
                                    {/*    <td className="text-end">$ 18.20</td>*/}
                                    {/*</tr>*/}
                                    <tr className="table-active">
                                        <th colSpan={2}>{t('sale:total')} ({currency}) :</th>
                                        <td className="text-end">
                                            <span className="fw-semibold">{LocaleCurrencySymbol[currency]} {totalAmount}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                        <CardHeader>
                            <CouponEdit/>
                        </CardHeader>

                    </Card>
                </Col>
                }
            </Row>
                        </Form>)}}
            </Formik>
        </>
    );
};

export default PlanCheckoutIndex;


