// types
import {ContentStatCounterActionTypes, ContentStatCounterState, ContentStatCounterType} from "./types";

export const NewContentStatCounterItem:ContentStatCounterType = {
  enabled: true,
  name: '',
  order:500,
}

export const INIT_STATE_CONTENT_STAT_COUNTER: ContentStatCounterState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewContentStatCounterItem,
  modalEditTitle: '',
  isSending: false
};

const ContentStatCounter = (state = INIT_STATE_CONTENT_STAT_COUNTER, action: any) => {
  switch (action.type) {
    case ContentStatCounterActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentStatCounterActionTypes.GET_CONTENT_STAT_COUNTERS:
          return {
            ...state,
            items: action.payload.data.items,
            isContentStatCounterFetched: true,
            getContentStatCounterLoading: false,
          };
        case ContentStatCounterActionTypes.UPDATE_CONTENT_STAT_COUNTER:
          return {
            ...state,
            rightModalShow: false,
            isSending: true
          };
        case ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER:
          return {
            ...state,
            selectedItem: NewContentStatCounterItem,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }

    case ContentStatCounterActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentStatCounterActionTypes.GET_CONTENT_STAT_COUNTERS:
          return {
            ...state,
            isContentStatCounterFetched: false,
            getContentStatCounterLoading: false,
          };
        case ContentStatCounterActionTypes.UPDATE_CONTENT_STAT_COUNTER:
          return {
            ...state,
            isSending: false
          };
        case ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER:
          return {
            ...state,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }
    case ContentStatCounterActionTypes.EDIT_CONTENT_STAT_COUNTER:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentStatCounterActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewContentStatCounterItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentStatCounterItem,
        removeModalShow: false
      }
    case ContentStatCounterActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case ContentStatCounterActionTypes.UPDATE_CONTENT_STAT_COUNTER:
      return {
        ...state,
        isSending: true
      };
    default:
      return { ...state };
  }
};

export default ContentStatCounter;
