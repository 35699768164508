// types
import { CommentsActionTypes, CommentsState } from "./types";
import {ChatsActionTypes, MessengerChannelType} from "../Chat/types";
import {indexOf} from "lodash";

export const INIT_STATE: CommentsState = {
//  favourites: [],
//  directMessages: [],
  channels: [],
  archiveContacts:[],
  selectedChat: null,
  chatUserDetails: {},
  chatUserConversations: {},
  isOpenUserDetails: false,
  channelDetails: {},
  isMessageSending: false
//  archiveContacts: [],
};

const MessengerComment = (state = INIT_STATE, action: any) => {
  let channelsUuids =[];

  switch (action.type) {
    case CommentsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CommentsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            favourites: action.payload.data,
            isFavouritesFetched: true,
            getFavouritesLoading: false,
          };
        case CommentsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            directMessages: action.payload.data,
            isDirectMessagesFetched: true,
            getDirectMessagesLoading: false,
            isContactsAdded: false,
          };
        case CommentsActionTypes.GET_COMMENTS_CHANNELS:

          state.channels.map((channel: MessengerChannelType) => {
            channelsUuids.push(channel.uuid);
            return channel;
          });

          if (action.payload.data?.meta?.page === 1) {
            state.channels = [...action.payload.data.items, ...state.channels.filter((channel: MessengerChannelType) => {
              return indexOf(channelsUuids, channel.uuid) === -1
            })];

            if (state.channelsMeta === undefined
                ||
                state.channelsMeta === null
                ||
                state.channelsMeta.page ===undefined){
              state.channelsMeta = action.payload.data.meta;
            }

          } else {
            state.channels.map((channel: MessengerChannelType) => {
              const foundChannel = action.payload.data.items.filter((channelResponse: MessengerChannelType) => {
                return channelResponse.uuid === channel.uuid
              }).shift();

              if (foundChannel?.uuid.length > 0) {
                return foundChannel;
              }
              return channel;
            });

            state.channelsMeta = action.payload.data.meta;
            state.channels = [...state.channels, ...action.payload.data.items.filter((channel: MessengerChannelType)=>{return indexOf(channelsUuids, channel.uuid) === -1}) ]
          }

          return {
            ...state,
            isChannelsFetched: true,
            getChannelsLoading: false,
            isChannelCreated: false,
          };

        case CommentsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: true,
            addContactsLoading: false,
          };
        case CommentsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: true,
            createChannelLoading: false,
          };
        case CommentsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            chatUserDetails: action.payload.data,
            isUserDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case CommentsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: action.payload.data,
            isUserConversationsFetched: true,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
            isMessageDeleted: false,
            isMessageForwarded: false,
          };
        case CommentsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: true,
            isMessageSending: false,
            chatUserConversations: [state.chatUserConversations, ...action.payload.data.items],
          };
        case CommentsActionTypes.RECEIVE_MESSAGE:
        case CommentsActionTypes.RECEIVE_MESSAGE_FROM_USER:
          return {
            ...state,
            chatUserConversations: action.payload.data,
          };
        case CommentsActionTypes.READ_MESSAGE:
          return {
            ...state,
            isMessageRead: true,
            chatUserConversations: [state.chatUserConversations, ...action.payload.data.items]
          };
        case CommentsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageSending: false,
            isMessageDeleted: true,
          };
        case CommentsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: true,
          };
        case CommentsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: true,
          };
        case CommentsActionTypes.GET_COMMENTS_CHANNEL_DETAILS:
          return {
            ...state,
            chatUserDetails: { ...action.payload.data, isChannel: true },
            isChannelDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case CommentsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: true,
          };
        case CommentsActionTypes.GET_ARCHIVE_CONTACT:
          state.archiveContacts.map((channel: MessengerChannelType) => {
            channelsUuids.push(channel.uuid);
            return channel;
          });

          if (action.payload.data?.meta?.page === 1) {
            state.archiveContacts = [...action.payload.data.items, ...state.archiveContacts.filter((channel: MessengerChannelType) => {
              return indexOf(channelsUuids, channel.uuid) === -1
            })];

            if (state.archiveContactsMeta === undefined
                ||
                state.archiveContactsMeta === null
                ||
                state.archiveContactsMeta.page ===undefined){
              state.archiveContactsMeta = action.payload.data.meta;
            }

          } else {
            state.archiveContacts.map((channel: MessengerChannelType) => {
              const foundChannel = action.payload.data.items.filter((channelResponse: MessengerChannelType) => {
                return channelResponse.uuid === channel.uuid
              }).shift();

              if (foundChannel?.uuid.length > 0) {
                return foundChannel;
              }
              return channel;
            });

            state.archiveContactsMeta = action.payload.data.meta;
            state.archiveContacts = [...state.archiveContacts, ...action.payload.data.items.filter((channel: MessengerChannelType)=>{return indexOf(channelsUuids, channel.uuid) === -1}) ]
          }

          return {
            ...state,
            isArchiveContactFetched: true,
            isContactArchiveToggled: false,
          };
        case CommentsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: true,
          };
        case CommentsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            channels: [...state.channels.map((channel:MessengerChannelType)=>{
              if (channel.uuid===action.payload.data.uuid){
                channel.unreadMessagesCount=0;
              }
              return channel;
            })],
            archiveContacts: [...state.archiveContacts.map((channel:MessengerChannelType)=>{
              if (channel.uuid===action.payload.data.uuid){
                channel.unreadMessagesCount=0;
              }
              return channel;
            })]
          };
        default:
          return { ...state };
      }

    case CommentsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CommentsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            isFavouritesFetched: false,
            getFavouritesLoading: false,
          };
        case CommentsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            isDirectMessagesFetched: false,
            getDirectMessagesLoading: false,
          };
        case CommentsActionTypes.GET_COMMENTS_CHANNELS:
          return {
            ...state,
            isChannelsFetched: false,
            getChannelsLoading: false,
          };
        case CommentsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: false,
            addContactsLoading: false,
          };
        case CommentsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case CommentsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            isUserDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case CommentsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: {},
            isUserConversationsFetched: false,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
          };
        case CommentsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isMessageSending: false,
            isUserMessageSent: false,
          };
        case CommentsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageSending: false,
            isMessageDeleted: false,
          };
        case CommentsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: false,
          };
        case CommentsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: false,
          };
        case CommentsActionTypes.GET_COMMENTS_CHANNEL_DETAILS:
          return {
            ...state,
            isChannelDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case CommentsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: false,
          };
        case CommentsActionTypes.GET_ARCHIVE_CONTACT:
          return {
            ...state,
            isArchiveContactFetched: false,
          };
        case CommentsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: false,
          };
        case CommentsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            isRead: false,
          };
        case CommentsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        default:
          return { ...state };
      }

    case CommentsActionTypes.GET_FAVOURITES: {
      return {
        ...state,
        getFavouritesLoading: true,
        isFavouritesFetched: false,
      };
    }
    case CommentsActionTypes.GET_DIRECT_MESSAGES:
      return {
        ...state,
        isDirectMessagesFetched: false,
        getDirectMessagesLoading: true,
      };
    case CommentsActionTypes.GET_COMMENTS_CHANNELS:
      return {
        ...state,
        isChannelsFetched: false,
        getChannelsLoading: true,
      };
    case CommentsActionTypes.ADD_CONTACTS:
      return {
        ...state,
        isContactsAdded: false,
        addContactsLoading: true,
      };
    case CommentsActionTypes.CREATE_CHANNEL:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case CommentsActionTypes.CHANGE_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      };
    case CommentsActionTypes.GET_CHAT_USER_DETAILS:
      return {
        ...state,
        isUserDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case CommentsActionTypes.GET_CHAT_USER_CONVERSATIONS:
      return {
        ...state,
        isUserConversationsFetched: false,
        getUserConversationsLoading: true,
        isUserMessageSent: false,
      };
    case CommentsActionTypes.TOGGLE_USER_DETAILS_TAB:
      return {
        ...state,
        isOpenUserDetails: action.payload,
      };
    case CommentsActionTypes.ON_SEND_MESSAGE:
      return {
        ...state,
        isMessageSending: true,
        isUserMessageSent: false,
      };
    case CommentsActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        isMessageSending: true,
        isMessageDeleted: false,
      };
    case CommentsActionTypes.FORWARD_MESSAGE:
      return {
        ...state,
        isMessageForwarded: false,
      };
    case CommentsActionTypes.DELETE_USER_MESSAGES:
      return {
        ...state,
        isUserMessagesDeleted: false,
      };
    case CommentsActionTypes.GET_COMMENTS_CHANNEL_DETAILS:
      return {
        ...state,
        isChannelDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case CommentsActionTypes.TOGGLE_FAVOURITE_CONTACT:
      return {
        ...state,
        isFavouriteContactToggled: false,
      };
    case CommentsActionTypes.GET_ARCHIVE_CONTACT:
      return {
        ...state,
        isArchiveContactFetched: false,
      };
    case CommentsActionTypes.TOGGLE_ARCHIVE_CONTACT:
      return {
        ...state,
        isContactArchiveToggled: false,
      };
    case CommentsActionTypes.DELETE_IMAGE:
      return {
        ...state,
        isImageDeleted: false,
      };
    default:
      return { ...state };
  }
};

export default MessengerComment;
