import React, { useEffect, useState } from "react";
import { Alert, Form } from "reactstrap";
import { Link } from "react-router-dom";
import {Collapse, Card, CardBody} from "reactstrap";
import Dropzone from 'react-dropzone';
import * as route from '../../../../api/routes';

// components
import StartButtons from "./StartButtons";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";

//i18
import '../../../../i18/config';
import {useTranslation} from "react-i18next";

import {MessengerMessageType} from "../../../../redux/Messenger/Chat/types";
import {showErrorNotification} from "../../../../helpers/notifications";
import {BiKey} from "react-icons/bi";
import {MainFileImageTypes} from "../../../../redux/Main/File/types";

interface IndexProps {
  onSend: (data: any) => void;
  replyData: null | MessengerMessageType | undefined;
  onSetReplyData: (reply: null | MessengerMessageType | undefined) => void;
  chatUserDetails: any;
}
const Index = ({
  onSend,
  replyData,
  onSetReplyData,
  chatUserDetails,
}: IndexProps) => {

  const [isNeedAccessToken, setIsNeedAccessToken] = useState<boolean>(false);

  useEffect(()=>{
    if (chatUserDetails?.contentChannel?.isNeedAccessToken === true){
      setIsNeedAccessToken(true);
    } else {
      setIsNeedAccessToken(false);
    }
  },[chatUserDetails])

  const { t } = useTranslation(['common']);

  /*
  more menu collapse
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isSending, setSending] = useState<boolean>(false);

  /*
  input text
  */
  const [text, setText] = useState<null | string>(null);
  const onChangeText = (value: string) => {
    setText(value);
  };

  /*
  images
  */
  const [images, setImages] = useState<Array<any> | null | undefined>();
  const onSelectImages = (images: Array<any>) => {
    setImages(images);
  };

  /*
  files
  */
  const [files, setFiles] = useState<Array<any> | null | undefined>();

  const onSelectFiles = (dzFiles: Array<any>) => {

      if ((files || []).length<1){
        setFiles([...dzFiles]);
      } else {

        const updatedFiles = (files || []).filter((item) => {
          return dzFiles.some((newItem) => {
            return !(newItem.name === item.name && newItem.size === item.size);
          });
        });

        setFiles([...updatedFiles]);
      }
  };
  useEffect(() => {
    if (text || images || files) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files]);

  const [dropZone, setDropZone] = useState(false);
  const toggleDropZone = () => {
    setDropZone(!dropZone);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    let rect = document.getElementById("idMessengerConversationInput").getBoundingClientRect();

    if(e.clientY < rect.top || e.clientY >= rect.bottom || e.clientX < rect.left || e.clientX >= rect.right) {
      if (dropZone){
        toggleDropZone();
      }
    }

  };
  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!dropZone){
      toggleDropZone();
    }
  };

  const onSubmit = () => {
    let data: any = {};
    if (text) {
      data["text"] = text;
    }

    if (images && images.length) {
      const imgs = (images || []).map((i: any, key: number) => {
        const src = URL.createObjectURL(i);
        return {
          id: key + 1,
          downloadLink: src,
        };
      });
      data["image"] = imgs;
    }

    if (files && files.length) {
      const fs = (files || []).map((f: any, key: number) => {
        const src = URL.createObjectURL(f);
        return {
          id: key + 1,
          name: f.name,
          downloadLink: src,
          desc: f.size,
        };
      });
      data["attachments"] = fs;
      data["attachmentFiles"]=files;
    }

    if (!isNeedAccessToken) {
      setText("");
      setImages(null);
      setFiles(null);

      onSend(data);
    } else {
      showErrorNotification(t('channel:channelKeyNeeded'));
    }
  };

  const onClearMedia = () => {
    setImages(null);
    setFiles(null);
  };

  return (
      <>
      {
        isNeedAccessToken ?
            <>
              <div className="ps-2 pe-2">
              <div className="d-flex flex-row flex-grow-1 mt-2">
                <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                  <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                  {t('channel:channelKeyNeeded')}
                  <span className="position-relative ms-2"><Link to={route.CONTENT_CHANNELS_LIST}><BiKey className="text-danger fs-16 me-2"/></Link></span>
                </div>
              </div>
              </div>
            </>
            :
            <div className="messengerInputSection position-relative">
              <div className="position-absolute bottom-100 w-100">
                <Reply
                    reply={replyData}
                    onSetReplyData={onSetReplyData}
                    chatUserDetails={chatUserDetails}
                />

                {((images && images.length) || (files && files.length)) && (
                      <Card className="mb-0">
                        <CardBody className="py-3 pb-0">
                      <Alert
                          isOpen={true}
                          toggle={onClearMedia}
                          color="secondary"
                          className="alert-info alert-border-left
                          font-size-12 mb-1 selected-media"
                          closeClassName="selected-media-close"
                      >

                        {
                          (files || []).filter((file)=>(((Object.values(MainFileImageTypes) as string[]).includes(file.name.split('.').pop())))).length>0 &&
                            <div className={"selected-media-images-cont"+((files || []).filter((file)=>((!(Object.values(MainFileImageTypes) as string[]).includes(file.name.split('.').pop())))).length>0)?'':' mb-n2'}>
                              {                        // eslint-disable-next-line
                                (files || []).map((file,key:number)=>{
                                  if ((Object.values(MainFileImageTypes) as string[]).includes(file.name.split('.').pop())){
                                    return(
                                        <span key={"selected-media-images-"+key} className="mb-n2">
                                                   <img
                                                       height="80"
                                                       className="avatar-sm rounded bg-light me-2 mb-2"
                                                       alt={file.name}
                                                       src={URL.createObjectURL(file)}
                                                   />
                                  </span>
                                    )
                                  }
                                })}
                            </div>
                        }
                        {// eslint-disable-next-line
                          (files || []).map((file,key:number)=>{
                          if (!(Object.values(MainFileImageTypes) as string[]).includes(file.name.split('.').pop())){
                            return(
                                <div className="text-muted" key={"selected-media-files-"+key}><span className="pe-1"><i className="ri-file-line align-bottom"/> </span><small>{file.name}</small></div>
                            )
                          }
                        })}
                      </Alert>
                        </CardBody>
                      </Card>

                ) }
              </div>
            <div className={"chat-input-section p-3 p-lg-3"} id="idMessengerConversationInput"
                 onDragEnter={handleDragEnter}
                 onDragLeave={handleDragLeave}
            >
              <Form
                  id="chatinput-form"
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    onSubmit();
                  }}
              >
                <Dropzone onDrop={acceptedFiles => {
                  toggleDropZone();
                  onSelectFiles(acceptedFiles);
                }}>
                  {({getRootProps, getInputProps}) => (
                      <div className={dropZone ? 'dropzoneCont active' : 'dropzoneCont'}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <i className="ri-upload-2-line"/>
                          <p>{t('common:uploadFileWelcome')}</p>
                        </div>
                      </div>
                  )}
                </Dropzone>


                <div className={"row g-0 align-items-center"}>
                  <div className="col-auto">
                    <StartButtons
                        onToggle={onToggle}
                        text={text}
                        onChange={onChangeText}
                    />
                  </div>
                  <div className="col">
                    <InputSection value={text} onChange={onChangeText}/>
                  </div>
                  <div className="col-auto">
                    <EndButtons onSubmit={onSubmit} disabled={disabled}/>
                  </div>
                </div>

              </Form>

              <MoreMenu
                  isOpen={isOpen}
                  onSelectImages={onSelectImages}
                  onSelectFiles={onSelectFiles}
                  onToggle={onToggle}
              />

            </div>
            </div>
      }
      </>
  );
};

export default Index;
