import React, {useEffect, useState} from 'react';
import {Button, Col, Alert} from "reactstrap";
import { useParams} from 'react-router-dom'
import CoverAuth from "./CoverAuth";
import {useRedux} from "../../../hooks";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangePasswordSuccess from "../../../components/ChangePasswordSuccess";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import * as route from "../../../api/routes";

const CoverChangePassword = () => {

    const { t } = useTranslation(['auth']);
    const {token} = useParams();
    const { dispatch, useAppSelector } = useRedux();

    const [complete, setComplete] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const {
        mainUser,
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
    }));

    useEffect(() => {
        if (mainUser?.error.message && mainUser.error?.message) {
            setMessage(mainUser.error.message);
        }
    }, [mainUser.error]);

    useEffect(() => {
        setComplete(mainUser.isChangePasswordComplete);
    }, [mainUser.isChangePasswordComplete]);

    if ((token === undefined) || (token.length<1)){
        window.location.href = route.MAIN_AUTH_LOGIN;
    }


    return (
        <>
            <CoverAuth title={t('auth:changePassword')+' | Marketcells.io - digital marketing platform'}>
                <Col lg={6}>
                    <CoverLangSwitch/>
                    <div className="p-lg-5 p-4">
                        <div>
                            <h5 className="text-primary">{t('auth:changePassword')}</h5>
                            <p className="text-muted">{t('auth:changePasswordMessage1')}</p>
                        </div>

                        {!complete ?
                            <>
                                {message && message.length > 0 &&
                                    <Alert className="alert-borderless alert-danger text-center mb-2" role="alert">
                                        {t('validator:'+message)}
                                    </Alert>
                                }
                                <div className="mt-4">
                                    {message && message.length && message==='passwordResetLinkExpired' ?
                                        <Button
                                            href={route.MAIN_AUTH_REQUEST_RESET_PASSWORD}
                                            tag="a"
                                            color="success"
                                            className="w-100 btn-success">{t('auth:sendResetLink')}</Button>
                                        :
                                        <ChangePasswordForm/>
                                    }
                                </div>
                            </>
                            :
                            <ChangePasswordSuccess/>
                        }
                    </div>
                </Col>
            </CoverAuth>
        </>
    );
};

export default CoverChangePassword;