import React from 'react';
import {Row, Col} from 'reactstrap';
import UpgradeFare from "./UpgradeFare";


import '../i18/config';
import {useTranslation} from "react-i18next";

const UpgradeFarePage = () => {

    const { t } = useTranslation(['common']);

    return (
        <>
            <h3 className="text-center">{t('plan:planUpgrade')}</h3>
            <Row className="d-flex justify-content-center">
                <div className="col-lg-6 mt-4">
                    <UpgradeFare/>
                </div>
            </Row>
        </>
    );
}

export default UpgradeFarePage;