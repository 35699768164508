import React from 'react';
import * as Yup from 'yup';

export const PeerActivityValidatorSchema = Yup.lazy((values) => {
    const shapes = {};

    Object.keys(values).map((item)=>{
        if (item.match(/\S+_privacy$/)) {
            const uuid = item.match(/(\S+)_privacy$/).pop();

            if ((uuid !== undefined) && (values[uuid+'_enabled'] !== undefined) && (values[uuid+'_enabled'])){
                shapes[item]=(Yup.object().required('required'));
            }
        }
        if (item.match(/\S+_cta_enabled_\S+/)) {
            const arMatches = item.match(/(\S+)_cta_enabled_(\S+)/);

            if (values[item]){
                if (arMatches[1]!== undefined && arMatches[2]!==undefined) {
                    if (Object.keys(values).indexOf(arMatches[1] + '_cta_name_' + arMatches[2])!==-1) {
                        shapes[arMatches[1] + '_cta_name_' + arMatches[2]] = (Yup.string().min(1, 'tooShort').required('required'));
                    }
                    if (Object.keys(values).indexOf(arMatches[1] + '_cta_sale_integration_' + arMatches[2])!==-1 && values[arMatches[1] + '_cta_sale_integration_' + arMatches[2]]===null){
                        shapes[arMatches[1] + '_cta_url_' + arMatches[2]] = (Yup.string().min(1, 'tooShort').required('required'));
                    } else if (Object.keys(values).indexOf(arMatches[1] + '_cta_sale_integration_' + arMatches[2])!==-1) {
                        shapes[arMatches[1] + '_cta_sale_integration_' + arMatches[2]] = (Yup.object().required('required'));
                    } else if (Object.keys(values).indexOf(arMatches[1] + '_cta_url_' + arMatches[2])!==-1) {
                        shapes[arMatches[1] + '_cta_url_' + arMatches[2]] = (Yup.string().min(1, 'tooShort').required('required'));
                    }
                }
            }
        }
        return item;
    })

    shapes['type']=Yup.object().required('required');
    shapes['activeFrom']=Yup.date()
        .typeError("invalidDate")
        .required('required');

    return Yup.object().shape(shapes);
});

export const PeerActivityIdeaValidatorSchema = Yup.object().shape({
    type: Yup.object()
        .required('required')
});