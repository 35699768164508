import React from 'react';
import * as Yup from 'yup';

export const ContentQuickAnswerValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    text: Yup.string()
        .required('required'),
});