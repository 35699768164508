import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    getSaleCurrencies,
    hideRightModalSaleCurrency,
    onSendDataSaleCurrency
} from "../../../redux/Sale/Currency/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {SaleCurrencyValidatorSchema} from "./validator";
import ButtonSpinner from "../../../components/ButtonSpinner";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        saleCurrencyState,
        isSending
    } = useAppSelector(state => ({
        saleCurrencyState: state.SaleCurrency,
        isSending: state.SaleCurrency.isSending
    }));

    const uuid = saleCurrencyState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<CurrencyType>(saleCurrencyState.selectedItem);

    useEffect(() => {

        setFormValues(saleCurrencyState.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalSaleCurrency());

    const onDataSubmit = (formData:CurrencyType) => {

        let params: CurrencyType = {
            uuid: uuid && uuid,
            enabled: formData.enabled ? formData.enabled : false,
            name: formData.name && formData.name,
            code: formData.code && formData.code,
            order: formData.order && formData.order,
            currencyFormat: formData.currencyFormat && formData.currencyFormat,
            isDefault: formData.isDefault ? formData.isDefault : false,
        };

        dispatch(onSendDataSaleCurrency(params));

    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleCurrencyState.rightModalShow}
                    id="saleCurrencyEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleCurrencyValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('currency:'+saleCurrencyState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-isDefault-input"
                                            placeholder={t('common:default')}
                                            name="isDefault"
                                            component={InputSwitch}
                                            value = {getFieldProps('isDefault').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('isDefault', !getFieldProps('isDefault').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:nameTitle')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="name"
                                        placeholder={t('common:enterNameTitle')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.code ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-code-input">{t('common:code')}</Label>
                                            <Field
                                                id="form-code-input"
                                                name="code"
                                                placeholder={t('currency:enterCurrencyCodePlaceholder')}
                                                component={InputText}
                                                value = {getFieldProps('code').value}
                                                onChange={(name:string) => {
                                                    setFieldValue('code', name);
                                                }}
                                                className={errors.code && touched.code && 'form-control is-invalid'}
                                            />
                                            {errors.code && touched.code ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.code)}
                                                </div>
                                            ) :  touched.code && !errors.code ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.currencyFormat ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('currency:currencyFormatTitle')}</Label>
                                            <Field
                                                id="form-currencyFormat-input"
                                                name="currencyFormat"
                                                placeholder={t('currency:currencyFormatPlaceholder')}
                                                component={InputText}
                                                value = {getFieldProps('currencyFormat').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('currencyFormat', value);
                                                }}
                                                className={errors.currencyFormat && touched.currencyFormat && 'form-control is-invalid'}
                                            />
                                            {errors.currencyFormat && touched.currencyFormat ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.currencyFormat)}
                                                </div>
                                            ) :  touched.currencyFormat && !errors.currencyFormat ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-order-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-order-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;