import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,} from 'reactstrap';

import InputText from "../../../components/InputText";
import InputPassword from "../../../components/InputPassword";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {MainUserSignUpType} from "../../../redux/Main/User/types";
import {NewMainUserSignUp} from "../../../redux/Main/User/reducer";
import {SignUpFormValidatorSchema} from "./SignUpFormValidator";
import {onSendSignUpUser, setSignUpStatus} from "../../../redux/Main/User/actions";
import {getCurrentLanguage, getParentPartner} from "../../../helpers/functions";

const SignUpForm = () => {

    const { t } = useTranslation(['user','validator']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        currentUser,
    } = useAppSelector(state => ({
        currentUser: state.MainUser,
    }));

    const [formValues, setFormValues] = useState<MainUserSignUpType>(NewMainUserSignUp);
    const formikObject = useRef(null);

    useEffect(() => {

        if (currentUser !== undefined && currentUser.isSendSignUp !== undefined) {
            setDisabled(currentUser.isSendSignUp);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.isSendSignUp]);

    const onDataSubmit = (formData:MainUserSignUpType) => {
        let data: any = {};

        dispatch(setSignUpStatus(true));

        if (formData.email) {
            data["email"] = formData.email;
        }
        if (formData.password) {
            data["password"] = formData.password;
        }

        let params: MainUserSignUpType = {
            email: data.email && data.email,
            password: data.password && data.password,
            language: getCurrentLanguage()
        };

        const parentPartnerMark = getParentPartner();

        if (parentPartnerMark?.partnerCode && parentPartnerMark?.partnerCode.length>0){
            params = {
                ...params,
                partnerCode: parentPartnerMark.partnerCode
            }
        }

        dispatch(onSendSignUpUser(params));
    };

    return (
        <>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SignUpFormValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    innerRef={formikObject}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) =>(
                    <Form noValidate={true} className="customValidation">
                            <Row>
                                <div className={ "mb-3"+ (touched.email ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-email-input">E-mail</Label>
                                    <Field
                                        id="form-email-input"
                                        name="email"
                                        placeholder={t('user:enterEmail')}
                                        component={InputText}
                                        value = {getFieldProps('email').value}
                                        onChange={(value:string) => {
                                            setFieldValue('email', value);
                                        }}
                                        className={errors.email && touched.email && 'form-control is-invalid'}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.email)}
                                        </div>
                                    ) :  touched.email && !errors.email ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                        <Row>
                            <div className={ "mb-3"+ (touched.password ? " was-validated" : '')}>
                                <Label className="form-label" htmlFor="form-password-input">{t('user:password')}</Label>
                                <Field
                                    id="form-password-input"
                                    name="password"
                                    placeholder={t('user:enterPassword')}
                                    component={InputPassword}
                                    value = {getFieldProps('password').value}
                                    onChange={(value:string) => {
                                        setFieldValue('password', value);
                                    }}
                                    className={errors.password && touched.password && 'form-control is-invalid'}
                                />
                                { (errors.password && touched.password) ? (
                                    <div className="invalid-feedback">
                                        {t('validator:'+errors.password)}
                                    </div>
                                ) :  (touched.password && !errors.password) ?
                                    <div className="valid-feedback">
                                        {t('common:looksGood')}
                                    </div>
                                    : ''
                                }
                            </div>
                        </Row>
                        <Row>
                            <div className={ "mb-3"+ (touched.confirmPassword ? " was-validated" : '')}>
                                <Label className="form-label" htmlFor="form-confirmpassword-input">{t('user:confirmPassword')}</Label>
                                <Field
                                    id="form-confirmpassword-input"
                                    name="confirmPassword"
                                    placeholder={t('user:enterConfirmPassword')}
                                    component={InputPassword}
                                    value = {getFieldProps('confirmPassword').value}
                                    onChange={(value:string) => {
                                        setFieldValue('confirmPassword', value);
                                    }}
                                    className={errors.confirmPassword && touched.confirmPassword && 'form-control is-invalid'}
                                />
                                {errors.confirmPassword && touched.confirmPassword ? (
                                    <div className="invalid-feedback">
                                        {t('validator:'+errors.confirmPassword)}
                                    </div>
                                ) :  touched.confirmPassword && !errors.confirmPassword ?
                                    <div className="valid-feedback">
                                        {t('common:looksGood')}
                                    </div>
                                    : ''
                                }
                            </div>
                        </Row>

                        <div className="mb-4">
                            <p className="mb-0 fs-12 text-muted fst-italic">{t('auth:byRegisteringYouAgree')} <a href="https://marketcells.io/license" className="text-primary text-decoration-underline fst-normal fw-medium">{t('auth:termsOfUse')}</a></p>
                        </div>

                        <div className="mt-4">
                            <Button
                                type="submit"
                                disabled={disabled}
                                color="success"
                                className="w-100 btn-success">{t('auth:signUp')}</Button>
                        </div>
                    </Form>
                        )}
                </Formik>
        </>
    );
}

export default SignUpForm;