import React, {useEffect, useState} from "react";
import { Label } from 'reactstrap';
import Form from "react-bootstrap/Form";

interface InputSwitchProps {
  id: string;
  value: boolean;
  placeholder?: string;
  onChange: () => void;
  className?: string;
    disabled?: boolean;
}
const InputSwitch = ({ id, placeholder, value, onChange, className, disabled = false }: InputSwitchProps) => {

    const [currentValue, setCurrentValue] = useState<boolean>( null );

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

  return (
              <Form.Check
                type="switch"
                className={ className || "form-switch-lg"}
                id={ id }
                checked = { currentValue || false }
                onChange={() => { onChange()}}
                label = {placeholder || null}
                disabled={disabled}
              />
  );
};
export default InputSwitch;
