import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useRedux } from "../../../hooks";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {setPeerActivityIdeaInitialized} from "../../../redux/Content/PeerActivityIdea/actions";
import {getContentProjects} from "../../../redux/Content/Project/actions";
import {getContentConnectorDataParams} from "../../../redux/Content/ConnectorDataParam/actions";
import {getContentTemplates} from "../../../redux/Content/Template/actions";
import {getContentConnectorHandlers} from "../../../redux/Content/ConnectorHandler/actions";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import PeerActivitySearchCard from "../PeerActivity/PeerActivitySearchCard";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import {
    getPeerActivityIdeasOnly,
    setActivityMode, setEditPeerActivityItems,
    setPeerActivityInitialized, showPeerActivityEditModal
} from "../../../redux/Content/PeerActivity/actions";
import Loader from "../../../components/Loader";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import Button from "react-bootstrap/Button";
import {PeerActivityModeCode, PeerActivityModeType} from "../../../redux/Content/PeerActivityMode/types";
import {PeerActivityStatus} from "../../../redux/Content/PeerActivityStatus/types";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {ContentConnectorIdea} from "../../../redux/Content/Connector/types";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {PeerActivityTypeType} from "../../../redux/Content/PeerActivityType/types";
import {Card, CardBody, Col, Input, Row} from 'reactstrap';

const PeerActivityIdeaIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);
    const [ disabled, setDisabled] = useState<boolean>(true);
    const [ peerActivities, setPeerActivities] = useState<PeerActivityType[]>([]);
    const [ isActivitiesLoading, setActivitiesLoading ] = useState<boolean>(false);
    const [ searchValue, setSearchValue] = useState<string>('');

    const {
        peerActivityIdea,
        isSending,
        peerActivityModes,
        peerActivityTypes,
        aiTextGenerateState,
        connectorDataParam,
        peerChannels,
        peerActivity
    } = useAppSelector(state => ({
        peerActivityIdea: state.PeerActivityIdea,
        isSending: state.PeerActivityIdea.isSending,
        peerActivityModes: state.PeerActivityMode,
        peerActivityTypes: state.PeerActivityType,
        aiTextGenerateState: state.AITextGenerateState,
        connectorDataParam: state.ContentConnectorDataParam,
        peerChannels: state.ContentPeerChannel,
        peerActivity: state.PeerActivity
    }));

    useEffect(() => {
        setDisabled(!peerActivityIdea.isInitialized);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivityIdea?.isInitialized]);

    const checkInitialDataLoaded = () => {

        if (
            (peerActivityModes && peerActivityModes.items && peerActivityModes.items.length>0)
            &&
            (peerActivityTypes && peerActivityTypes.items && peerActivityTypes.items.length>0)
            &&
            (connectorDataParam && connectorDataParam.items && connectorDataParam.items.length>0)
            &&
            (peerChannels && peerChannels.items && peerChannels.items.length>0)
        ){
            dispatch(setPeerActivityIdeaInitialized());
            dispatch(setPeerActivityInitialized());

            return true;
        }

        return false;
    }

    useEffect(() => {
        if (peerActivity.isNeedReload){
            dispatch(getPeerActivityIdeasOnly());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivity.isNeedReload]);


    useEffect(() => {
        dispatch(getContentProjects());
        dispatch(getContentPeerChannels());
        dispatch(getContentConnectorDataParams());
        dispatch(getContentTemplates({maxResults:1000}));
        dispatch(getContentConnectorHandlers());
    //    dispatch(getPeerActivityIdeas());
        dispatch(getPeerActivityIdeasOnly());
        setActivitiesLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        if (checkInitialDataLoaded()){
            // handleNewActivity();
        }
        // else {
        //     if (isFetched){
        //         if ((peerChannels.items || []).length<1) {
        //             setShowPeerChannelEmptyDanger(true);
        //         }
        //     }
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        peerActivityModes.items,
        peerActivityTypes.items,
        peerChannels.items,
        connectorDataParam.items
    ])


    useEffect(()=>{

        if ( peerActivity?.items &&  peerActivity.items.length>0){

            const regEx = new RegExp( searchValue, 'g' );
// eslint-disable-next-line
            setPeerActivities([...peerActivity.items.filter((item:PeerActivityType)=>{
                if (searchValue && searchValue.length>0){
                    if (item.text && item.text.length>0) {
                        return item.text.match(regEx);
                    }
                } else {
                    return item;
                }
            })]);
        } else {
            setPeerActivities([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        peerActivity.items,
        searchValue
    ])

    useEffect(() => {
        if (!peerActivity.isInitialized) {
            setActivitiesLoading(peerActivity.getPeerActivityLoading);
        }
        if (peerActivity?.isInitialized && isActivitiesLoading === true){
            setActivitiesLoading(peerActivity.getPeerActivityLoading);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivity?.getPeerActivityLoading, peerActivity?.isInitialized]);

    const handleNewIdea = (eventDate?:number, mode?: string) => {

        const selectedPeerActivityMode = mode ? mode : PeerActivityModeCode.ACTIVITY;

        const peerActivityMode = {...peerActivityModes.items.filter((item: PeerActivityModeType, key: number) => (item.code === selectedPeerActivityMode)).shift()};

        let peerActivityType = {...(peerActivityTypes.items || []).filter((item:PeerActivityTypeType)=>{return item.isDefault}).shift()};
        const draftStatus = PeerActivityStatus.STATUS_NEW;

        if (!peerActivityType?.uuid && ((peerActivityTypes.items || []).length>0)) {
            peerActivityType = {...peerActivityTypes.items.shift()};
        }

        const newPeerActivity: PeerActivityType = {
            enabled: true,
            type: peerActivityType,
            mode: peerActivityMode,
            name: peerActivityMode && peerActivityMode.code && peerActivityMode.code[0].toUpperCase() + peerActivityMode.code.slice(1),
            activeFrom: null,
        }

        let newPeerActivities=[];

        peerChannels.items.filter((peerChannel:ContentPeerChannelType)=>{return peerChannel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS}).map((item: ContentPeerChannelType, key: number) => {

            if (
                (item.isManaged)
                &&
                (item.connector.isConnected)
                &&
                (Object.values(ContentConnectorIdea) as string[]).includes(item.connector.handler.code)) {

                let addPeerActivity = false;

                if (selectedPeerActivityMode === PeerActivityModeCode.ACTIVITY && item.connector.handler && item.connector.handler.canAddPost === true) {
                    addPeerActivity = true;
                } else if (selectedPeerActivityMode === PeerActivityModeCode.SHORTS && item.connector.handler && item.connector.handler.canAddShort === true) {
                    addPeerActivity = true;
                } else if (selectedPeerActivityMode === PeerActivityModeCode.STORY && item.connector.handler && item.connector.handler.canAddStory === true) {
                    addPeerActivity = true;
                } else if (selectedPeerActivityMode === PeerActivityModeCode.BIO && item.connector.handler && item.connector.handler.canChangeBio === true) {
                    addPeerActivity = true;
                }

                if (addPeerActivity) {
                    newPeerActivities.push(
                        {   ...newPeerActivity,
                            enabled: true,
                            channel: item,
                            status: draftStatus
                        });
                }
            }
            return item;
        });


        if (newPeerActivities.length>0){
            dispatch(setActivityMode(mode));
            dispatch(setEditPeerActivityItems(newPeerActivities));
            dispatch(showPeerActivityEditModal());
        } else {
            dispatch(setActivityMode(mode));
            dispatch(setEditPeerActivityItems([]));
            dispatch(showPeerActivityEditModal());
        }
    }

    const columnsCountBreakPoints={
        350: 1,
        750: 2,
        900: 2,
        1250: 3,
        1500: 4
    };

    const handleSearch = (value:any) => {
        setSearchValue(value);
    }

    return (
        <>
            <BreadCrumb title={t('menu:activitiesIdeas')} pageTitle={t('menu:main')} />

            { isActivitiesLoading ?
                <Loader addClassNames=""/>
                :
                <>
                    <Card>
                        <CardBody>
                            <div className="d-flex flex-row flex-nowrap">
                                <div className="d-flex flex-shrink-0">
                                    <Button variant="secondary" onClick={()=>handleNewIdea(null,PeerActivityModeCode.ACTIVITY)} className="btn btn-success">
                                        <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                                    </Button>
                                </div>
                                <div className="d-flex flex-grow-1">
                                    <div className="search-box ms-3">
                                        <Input type="text" className="form-control" placeholder={t('search:searchYourIdea')}
                                               id="search-options"
                                               name="ideasSearch"
                                               value={searchValue}
                                               onChange={e => {
                                                   handleSearch(e.target.value);
                                               }} />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

            <Row className="pt-2">
                <ResponsiveMasonry
                    columnsCountBreakPoints={columnsCountBreakPoints}
                >
                    <Masonry gutter={'30px'}>
                        {(peerActivities || []).map((item:PeerActivityType, key:number)=>(
                            <PeerActivitySearchCard item={item} key={"activityIdea_" + key}/>))
                        }
                    </Masonry>
                </ResponsiveMasonry>
            </Row>
                </>
            }
        </>
    );
};

export default PeerActivityIdeaIndex;


