import {CurrencyType} from "../Currency/types";
import {PaymentType} from "../Payment/types";
import {OrderItemType} from "../OrderItem/types";
import {CustomerType} from "../Customer/types";
import {DeliveryType} from "../Delivery/types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {SaleOrderStatusType} from "../OrderStatus/types";
import {MainMetaType} from "../../Main/common";
import {ProductType} from "../Product/types";
import {EntityActionsType} from "../../types";
import {CommentType, MainCommentMetaType} from "../../Main/Comment/types";
import {DiscountType} from "../Discount/types";
import {CouponType} from "../Coupon/types";

export enum SaleOrderActionTypes {
    API_RESPONSE_SUCCESS = "@@saleOrder/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleOrder/API_RESPONSE_ERROR",
    GET_ORDERS = "@@saleOrder/GET_ORDERS",
    GET_ORDER_DETAIL = "@@saleOrder/GET_ORDER_DETAIL",
    CANCEL_MODAL_SHOW = "@@saleOrder/CANCEL_MODAL_SHOW",
    CANCEL_MODAL_HIDE= "@@saleOrder/CANCEL_MODAL_HIDE",
    REMOVE_MODAL_SHOW = "@@saleOrder/REMOVE_MODAL_SHOW",
    REMOVE_MODAL_HIDE= "@@saleOrder/REMOVE_MODAL_HIDE",
    REMOVE_ORDER = "@@saleOrder/REMOVE_ORDER",
    UPDATE_ORDER = "@@saleOrder/UPDATE_ORDER",
    CANCEL_ORDER = "@@saleOrder/CANCEL_ORDER",
    CLEAR_NEED_ORDER_RELOAD = "@@saleOrder/CLEAR_NEED_ORDER_RELOAD",
    SET_NEED_ORDER_RELOAD = "@@saleOrder/SET_NEED_ORDER_RELOAD",
    CHANGE_ORDER_STATUS = "@@saleOrder/CHANGE_ORDER_STATUS",
    SEARCH_SALE_PRODUCTS = "@@saleOrderCustomer/SEARCH_SALE_PRODUCTS",
    CHANGE_ORDER_ITEMS = "@@saleOrder/CHANGE_ORDER_ITEMS",

    MODAL_REMOVE_ORDERS_SHOW = "@@saleOrder/MODAL_REMOVE_ORDERS_SHOW",
    MODAL_REMOVE_ORDERS_HIDE = "@@saleOrder/MODAL_REMOVE_ORDERS_HIDE",
    REMOVE_GROUP_ORDERS= "@@saleOrder/REMOVE_GROUP_ORDERS",


    GET_COMMENTS = "@@saleOrder/GET_COMMENTS",
    READ_COMMENTS = "@@saleOrder/READ_COMMENTS",
    UPDATE_COMMENT = "@@saleOrder/UPDATE_COMMENT",
    REMOVE_COMMENT = "@@saleOrder/REMOVE_COMMENT",
    REMOVE_COMMENT_MODAL_SHOW = "@@saleOrder/REMOVE_COMMENT_MODAL_SHOW",
    REMOVE_COMMENT_MODAL_HIDE = "@@saleOrder/REMOVE_COMMENT_MODAL_HIDE",

}
export interface SaleOrderStateType extends MainMetaType{
    items: Array<OrderType>;
    current?: OrderType;
    isFetched: boolean,
    isLoading: boolean,
    cancelModalShow: boolean;
    removeModalShow: boolean;
    isNeedReload: boolean;
    searchProducts: ProductType[];
    isSearchProducts: boolean;
    isSending: boolean;
    modalEditTitle?:string;
    isModalGroupRemoveOrdersShow : boolean;

    isLoadingComments: boolean;
    isSendingComment: boolean;
    selectedComment?: CommentType;
    isNeedReloadComments: boolean;
    removeCommentModalShow: boolean;
    removeCommentModalTitle: string;
}

export interface OrderType extends AbstractOrderType{
    orderItems?: Array<OrderItemType>
    payments?: Array<PaymentType>
    customer?: CustomerType
    channel?: ContentPeerChannelType;
    actions?: EntityActionsType,

    comments?: Array<CommentType>
    commentsMeta?: MainCommentMetaType;
}

export interface AbstractOrderType{
    uuid?: string;
    enabled?: boolean;
    isPaid?: boolean;
    code?: string;
    sum?: number;
    discount?: number;
    discountPercent?: number;
    coupons?: Array<CouponType>;
    discounts?: Array<DiscountType>;
    currency?: CurrencyType;
    customerName?: string;
    customerPhone?: string;
    customerEmail?: string;
    customerTin?: string;
    customerComment?: string;
    locale?: string;
    paymentUrl?: string;
    cabinet?: string;
    createdAt?: string;
    updatedAt?: string;
    payments?: Array<PaymentType>
    deliveryItems?: Array<DeliveryType>
    status?: SaleOrderStatusType;
    ip?: string;
    city?: string;
    country?: string;
}

export interface OrderStatusType {
    label: string;
    class: string;
    status?: string;
}
export const OrderStatus = {
    ALL: {
        label: 'All',
        class: 'info',
        status: ''
    },
    PENDING: {
        label: 'Pending',
        class: 'warning',
        status: 'P'
    },
    CANCELLED: {
        label: 'Cancelled',
        class: 'danger',
        status: 'C'
    },
    NEW: {
        label: 'New',
        class: 'info',
        status: 'N'
    },
    INPROGRESS: {
        label: 'InProgress',
        class: 'secondary',
        status: 'I'
    },
    PICKUPS: {
        label: 'Pickups',
        class: 'info',
        status: 'K'
    },
    RETURNS: {
        label: 'Returns',
        class: 'primary',
        status: 'R'
    },
    DELIVERED: {
        label: 'Delivered',
        class: 'success',
        status: 'D'
    },
    FINAL: {
        label: 'Final',
        class: 'success',
        status: 'F'
    }
}

export interface SaleOrderPayload {
    data: Array<any>;
    modalEditTitle : string;
}

export interface GroupOrderPayloadType{
    orderUuids?: Array<string>
}
