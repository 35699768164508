import {OrderType, SaleOrderActionTypes, SaleOrderPayload} from "./types";
import {MainMetaType} from "../../Main/common";
import {CommentPayload} from "../../Main/Comment/types";

export const saleOrderApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleOrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleOrderApiResponseError = (actionType: string, error: string) => ({
  type: SaleOrderActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleOrders = (data: MainMetaType) => ({
  type: SaleOrderActionTypes.GET_ORDERS,
  payload: data,
});

export const getSaleOrderDetail = (data: any) => ({
  type: SaleOrderActionTypes.GET_ORDER_DETAIL,
  payload: data,
});

export const cancelSaleOrderModalShow = () => ({
  type: SaleOrderActionTypes.CANCEL_MODAL_SHOW,
});

export const cancelSaleOrderModalHide = () => ({
  type: SaleOrderActionTypes.CANCEL_MODAL_HIDE,
});

export const onRemoveSaleOrder = (data: any) => ({
  type: SaleOrderActionTypes.REMOVE_ORDER,
  payload: data,
});

export const onUpdateSaleOrder = (data: any) => ({
  type: SaleOrderActionTypes.UPDATE_ORDER,
  payload: data,
});

export const onCancelSaleOrder = (data: OrderType) => ({
  type: SaleOrderActionTypes.CANCEL_ORDER,
  payload: data,
});

export const clearSaleOrderNeedReload= () => ({
  type: SaleOrderActionTypes.CLEAR_NEED_ORDER_RELOAD,
});

export const setSaleOrderNeedReload= () => ({
  type: SaleOrderActionTypes.SET_NEED_ORDER_RELOAD,
});

export const onChangeSaleOrderStatus = (data: any) => ({
  type: SaleOrderActionTypes.CHANGE_ORDER_STATUS,
  payload: data,
});

export const onSearchSaleOrderProducts = (data:MainMetaType) => ({
  type: SaleOrderActionTypes.SEARCH_SALE_PRODUCTS,
  payload: data,
});

export const onChangeSaleOrderItems = (data: OrderType) => ({
  type: SaleOrderActionTypes.CHANGE_ORDER_ITEMS,
  payload: data,
});

export const removeSaleOrderModalShow = (data: SaleOrderPayload) => ({
  type: SaleOrderActionTypes.REMOVE_MODAL_SHOW,
  payload: data,
});

export const removeSaleOrderModalHide = () => ({
  type: SaleOrderActionTypes.REMOVE_MODAL_HIDE,
});

export const removeGroupOrders = (data: any) => ({
  type: SaleOrderActionTypes.REMOVE_GROUP_ORDERS,
  payload: data
});
export const groupRemoveOrdersModalShow = () => ({
  type: SaleOrderActionTypes.MODAL_REMOVE_ORDERS_SHOW
});
export const groupRemoveOrdersModalHide = () => ({
  type: SaleOrderActionTypes.MODAL_REMOVE_ORDERS_HIDE
});

// comments --->
export const getOrderComments = (data: any) => ({
  type: SaleOrderActionTypes.GET_COMMENTS,
  payload: data,
});

export const readOrderComments = (data: any) => ({
  type: SaleOrderActionTypes.READ_COMMENTS,
  payload: data,
});

export const sendOrderComment = (data: any) => ({
  type: SaleOrderActionTypes.UPDATE_COMMENT,
  payload: data,
});

export const showOrderCommentRemoveModal= (data: CommentPayload) => ({
  type: SaleOrderActionTypes.REMOVE_COMMENT_MODAL_SHOW,
  payload: data,
});

export const removeOrderComment = (data: CommentPayload) => ({
  type: SaleOrderActionTypes.REMOVE_COMMENT,
  payload: data,
});

export const hideOrderCommentRemoveModal= () => ({
  type: SaleOrderActionTypes.REMOVE_COMMENT_MODAL_HIDE,
});
// comments <---