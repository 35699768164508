import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getEventTypes = () => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_EVENT_TYPES);
};

const getWebhookEventTypes = () => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_EVENT_TYPES_WEBHOOK);
};


export {
    getEventTypes,
    getWebhookEventTypes
};
