// types
import moment from 'moment';
import {StatPeerChannelState} from "./types";
import {StatPeerChannelContentActionTypes} from "./types";

export const INIT_STATE_STAT_CHANNEL_CONTENT: StatPeerChannelState = {
  tiles: [],
  activitiesData: [],
  targets: [],
  activities: [],
  scheduleActivities:[],
  periodFrom: moment().add(-7, 'days').utc().unix(),
  periodTo: moment().utc().unix(),
  deltaDays: 7,
  isStatPeerChannelContentFetched: false,
  isContentPeerChannelTargetFetched: false
};

const StatPeerChannelContent = (state = INIT_STATE_STAT_CHANNEL_CONTENT, action: any) => {
  switch (action.type) {
    case StatPeerChannelContentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES:
          return {
            ...state,
            tiles: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA:
          return {
            ...state,
            activitiesData: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS:
          return {
            ...state,
            targets: action.payload.data.items,
            isContentPeerChannelTargetFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES:
          return {
            ...state,
            activities: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        case StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES:
          return {
            ...state,
            scheduleActivities: action.payload.data.items,
            isStatPeerChannelContentFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        default:
          return { ...state };
      }

    case StatPeerChannelContentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS:
          return {
            ...state,
            isContentPeerChannelTargetFetched: true,
            getStatPeerChannelContentLoading: false,
          };
        default:
          return { ...state };
      }

    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES:
    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA:
    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES:
    case StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES:
      return {
        ...state,
        isStatPeerChannelContentFetched: false,
        getStatPeerChannelContentLoading: true,
      };
    case StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS:
      return {
        ...state,
        isContentPeerChannelTargetFetched: false,
        getStatPeerChannelContentLoading: true,
      };
    case StatPeerChannelContentActionTypes.SET_PERIOD:

      let periodFrom = moment(action.payload.periodFrom,'X');
      let periodTo = moment(action.payload.periodTo,'X');

      return {
        ...state,
        periodFrom: action.payload.periodFrom,
        periodTo: action.payload.periodTo,
        deltaDays: moment.duration(periodTo.diff(periodFrom)).asDays(),
        selectedDate: action.payload.selectedDate && action.payload.selectedDate
      };
    default:
      return { ...state };
  }
};

export default StatPeerChannelContent;
