import React, {useEffect, useState, useCallback} from "react";
import { Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {StatOrderTileType} from "../../redux/Stat/DashboardSale/types";
import {capitalizeFirstLetter, convertToMomentLocale} from "../../helpers/functions";
import {OrderStatusTypes} from "../../redux/Sale/OrderStatus/types";

interface InputProps {
    items: [] | Array<StatOrderTileType>;
    color: string
}
const generateWeekDayNames = () => Array(7).fill(0).map((_, i) => moment().day(i).format("ddd"));

const SaleOrderHeatmapWeekly = ({ items, color }: InputProps) => {

    const { t, i18n } = useTranslation();
    moment.locale(convertToMomentLocale(i18n.language));

    const [shownStatus, setShownStatus] = useState<string>(OrderStatusTypes.ALL);

    const getShownItems = useCallback((items: StatOrderTileType[], status: string) =>
        items.filter(({ type })=> type === status), []);

    const [shownItems, setShownItems] = useState<StatOrderTileType[]>([]);

    useEffect(()=>{
        setShownItems(getShownItems(items, shownStatus));
    },[shownStatus, items, getShownItems]);

    const getSeriesData = useCallback((items: StatOrderTileType[]) => {
        const series = Array.from({ length: 7 }, () => []);
        const arDayWeekNames = generateWeekDayNames();
        // eslint-disable-next-line
        items.map(({ label, value }) => {
            const [day, hour] = label?.split('.') || [];
            const y = value?.order?.last || 0;
            series[day]?.push({ x: hour + 'h', y });
        });
        return arDayWeekNames.map((day, index) => ({ name: day, data: series[index] }));
    }, []);

    const [dataSeries, setDataSeries] = useState<any[]>(getSeriesData(shownItems));

    useEffect(()=>{
        setDataSeries(getSeriesData(shownItems));
    },[shownItems, getSeriesData]);

    const handleClickShownStatus = (status: string) => setShownStatus(status);

    const options:ApexOptions = {
        chart: {
            height: 400,
            type: 'heatmap',
            offsetX: 0,
            offsetY: -8,
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 20,
            markers: {
                width: 20,
                height: 6,
                radius: 2,
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            },
        },
        colors: [color],
        tooltip: {
            y: [{
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return (new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            notation: 'compact'
                        }).format(y));
                    }
                    return y;
                }
            }]
        }
    };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('stat:ordersHeatmap')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted">{t('orderStatus:orderStatusType'+capitalizeFirstLetter(shownStatus))}<i className="mdi mdi-chevron-down ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {(Object.keys(OrderStatusTypes).map((item)=>{
                            return(
                                <DropdownItem onClick={()=>{handleClickShownStatus(OrderStatusTypes[item])}} key={"ordersStatusCountriesFilter_"+OrderStatusTypes[item]}>{t('orderStatus:orderStatusType'+capitalizeFirstLetter(OrderStatusTypes[item]))}</DropdownItem>
                            )
                        }))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="apex-charts" >
                    <ReactApexChart
                        options={options}
                        series={dataSeries}
                        type="heatmap"
                        height="436"
                        className="apex-charts"
                    />
                </div>
            </CardBody>
        </Card>
    );
}

export default SaleOrderHeatmapWeekly;