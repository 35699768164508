import {
    editDataContentPeerTarget,
    getContentPeerTargets,
    onRemoveContentPeerTarget,
    removeContentPeerTargetModalHide,
    removeContentPeerTargetModalShow,
    showRightModalContentPeerTarget,
} from "../../../redux/Content/PeerTarget/actions";
import {ProductType} from "../../../redux/Sale/Product/types";
import {TaskType} from "../../../redux/Task/Task/types";
import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {handleTaskEdit} from "../../Task/Task/TaskActions";
import {ContentPeerTargetType} from "../../../redux/Content/PeerTarget/types";

const ContentPeerTargetOpenModal = (event:any, dispatch:any) => {
    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(editDataContentPeerTarget({data:event, modalEditTitle: 'changeTarget'}));
    } else {
        dispatch(showRightModalContentPeerTarget({data:[], modalEditTitle: 'newTarget'}));
    }
}

const ContentPeerTargetOpenRemoveModal = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(removeContentPeerTargetModalShow({data:event, modalEditTitle: 'deleteTarget'}));
    }

}

const ContentPeerTargetOnRemove = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(onRemoveContentPeerTarget({data:event, modalEditTitle: 'deleteTarget'}));

        setTimeout(() => {
            dispatch(getContentPeerTargets());
        }, 1000);
    }

}

const ContentPeerTargetHideRemoveModal = (dispatch:any) => {

    dispatch(removeContentPeerTargetModalHide())

}

export const handleCreateNewTaskByTarget = (entity: ContentPeerTargetType, dispatch: any) => {
    let newTask:TaskType = NewTaskItem;

    newTask = {
        ...newTask,
        text: entity?.name && entity.name
    }

    handleTaskEdit(newTask, dispatch);
}

export {
    ContentPeerTargetOpenModal,
    ContentPeerTargetOpenRemoveModal,
    ContentPeerTargetOnRemove,
    ContentPeerTargetHideRemoveModal
}