import {SaleCategoryActionTypes, SaleCategoryPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleCategoryApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleCategoryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleCategoryApiResponseError = (actionType: string, error: string) => ({
  type: SaleCategoryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleCategories = (data: MainMetaType) => ({
  type: SaleCategoryActionTypes.GET_SALE_CATEGORIES,
  payload: data,
});

export const showRightModalSaleCategory= (data: SaleCategoryPayload) => ({
  type: SaleCategoryActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleCategory= () => ({
  type: SaleCategoryActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleCategory = (data: any) => ({
  type: SaleCategoryActionTypes.UPDATE_SALE_CATEGORY,
  payload: data,
});

export const editDataSaleCategory = (data: SaleCategoryPayload) => ({
  type: SaleCategoryActionTypes.EDIT_SALE_CATEGORY,
  payload: data,
});

export const removeSaleCategoryModalShow = (data: SaleCategoryPayload) => ({
  type: SaleCategoryActionTypes.REMOVE_SALE_CATEGORY_MODAL_SHOW,
  payload: data,
});

export const removeSaleCategoryModalHide = () => ({
  type: SaleCategoryActionTypes.REMOVE_SALE_CATEGORY_MODAL_HIDE,
});

export const onRemoveSaleCategory = (data: SaleCategoryPayload) => ({
  type: SaleCategoryActionTypes.REMOVE_SALE_CATEGORY,
  payload: data,
});