import {ContentConnectorType} from "../Connector/types";
import {ContentProjectType} from "../Project/types";
import {EntityActionsType} from "../../types";
export enum ContentStatCounterActionTypes {
  API_RESPONSE_SUCCESS = "@@contentStatCounter/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentStatCounter/API_RESPONSE_ERROR",
  GET_CONTENT_STAT_COUNTERS = "@@contentStatCounter/GET_CONTENT_STAT_COUNTERS",
  UPDATE_CONTENT_STAT_COUNTER = "@@contentStatCounter/UPDATE_CONTENT_STAT_COUNTERS",
  ADD_CONTENT_STAT_COUNTER = "@@contentStatCounter/ADD_CONTENT_STAT_COUNTER",
  REMOVE_CONTENT_STAT_COUNTER_MODAL_SHOW = "@@contentStatCounter/REMOVE_CONTENT_STAT_COUNTER_MODAL_SHOW",
  REMOVE_CONTENT_STAT_COUNTER_MODAL_HIDE = "@@contentStatCounter/REMOVE_CONTENT_STAT_COUNTER_MODAL_HIDE",
  REMOVE_CONTENT_STAT_COUNTER = "@@contentStatCounter/REMOVE_CONTENT_STAT_COUNTER",
  EDIT_CONTENT_STAT_COUNTER = "@@contentStatCounter/EDIT_CONTENT_STAT_COUNTER",

  RIGHT_MODAL_SHOW = "@@contentStatCounter/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@contentStatCounter/RIGHT_MODAL_HIDE",
}

export interface ContentStatCounterState {
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: ContentStatCounterType;
  modalEditTitle: string;
  isSending: boolean;
}

export interface ContentStatCounterType{
  uuid?: string,
  enabled: boolean,
  name: string,
  avatar?: string,
  idPeer?: string,
  type?:string,
  order:number,
  project?: ContentProjectType,
  connector?: ContentConnectorType,
  isInitUpdate?: boolean,
  isConnectorConnected?: boolean,
  dateTime?: string,
  stat?:ContentStatCounterStatType,
  actions?: EntityActionsType,
  isReadOnly?:boolean;
}

export interface ContentStatCounterStatType{
  visits?:number,
  pageViews?:number,
  users?:number,
  bounceRate?:number,
  pageDepth?:number,
  avgVisitDurationSeconds?:number,
  sumGoalReachesAny?:number,
  dateTime?: string,
}

export interface ContentStatCounterPayload {
  data: Array<any>;
  modalEditTitle : string;
}
