// types
import {ContentConnectorDataParamActionTypes, ContentConnectorDataParamState, ContentConnectorDataParamType} from "./types";

export const INIT_STATE_CONTENT_CONNECTOR_DATA_PARAM: ContentConnectorDataParamState = {
  items: [],
};

const ContentConnectorDataParam = (state = INIT_STATE_CONTENT_CONNECTOR_DATA_PARAM, action: any) => {
  switch (action.type) {
    case ContentConnectorDataParamActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentConnectorDataParamActionTypes.GET_CONTENT_CONNECTOR_DATA_PARAMS:
          return {
            ...state,
            items: action.payload.data.items,
            isConnectorDataParamFetched: true,
            getConnectorDataParamLoading: false,
          };
        default:
          return { ...state };
      }

    case ContentConnectorDataParamActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentConnectorDataParamActionTypes.GET_CONTENT_CONNECTOR_DATA_PARAMS:
          return {
            ...state,
            isConnectorDataParamFetched: false,
            getConnectorDataParamLoading: false,
          };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default ContentConnectorDataParam;
