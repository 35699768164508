import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSalePriceTypes = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_PRICE_TYPES+"?"+query);
};

const sendDataSalePriceType = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_PRICE_TYPE, data);
};

const removeSalePriceType = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_PRICE_TYPE, data);
};

export {
    getSalePriceTypes,
    sendDataSalePriceType,
    removeSalePriceType
};
