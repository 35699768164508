import { APIClient } from "../apiCore";
import * as url from "../urls";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";
import {m9sGlobals} from "../../config";
import {StatTaskPayloadType} from "../../redux/Stat/DashboardTask/types";

const api = new APIClient();

const getTasks = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_TASKS+"?"+query);
};

const getTasksFiltered = (data:StatTaskPayloadType) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_TASKS, data);
};

const getTaskDetail = (data:any) => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_TASK_DETAIL.replace(/:uuid/,data.uuid));
};

const updateTask = (data: any) => {

    if (data.attachmentFiles !== undefined){
        return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.UPDATE_TASK,data);
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_TASK, data);
};

const removeTask = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_TASK, data);
};


// const rewardTask = (data: any) => {
//     return api.create(url.SEND_REWARD_TASK, data);
// };

const getTaskBadges = () => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_TASK_BADGES_SUMMARY);
};

const getTaskComments = (data:any) => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_TASK_DETAIL_COMMENTS.replace(/:uuid/,data.uuid));
};

const readTaskComments = (data:any) => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.READ_TASK_DETAIL_COMMENTS.replace(/:uuid/,data.uuid));
};

const copyTask = (data:any) => {

    let requestUrl = m9sGlobals.CURRENT_CABINET+url.COPY_TASK;
    requestUrl = requestUrl.replace(/:uuid/,data.task.uuid);

    return api.create(requestUrl,[]);
};

const closeGroupTask = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.CLOSE_TASK_GROUP, data);
};

const removeGroupTask = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_TASK_GROUP, data);
};

const changeTaskStatus = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.CHANGE_TASK_STATUS, data);
};

export {
    getTasks,
    updateTask,
    removeTask,
    getTaskDetail,
    getTaskBadges,
    getTaskComments,
    readTaskComments,
    copyTask,
    changeTaskStatus,
    closeGroupTask,
    removeGroupTask,
    getTasksFiltered
};
