import {ComparePeerChannelState, ComparePeerChannelActionTypes} from "./types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {StatPeerChannelState} from "../PeerChannel/types";
import {showErrorNotification, showSuccessNotification} from "../../../helpers/notifications";
import {saveCompareChannels} from "../../../helpers/functions";
import i18n from "i18next";

export const INIT_STATE_COMPARE_CHANNEL_CONTENT: ComparePeerChannelState = {
    items:[],
    itemsData:[]
};

const ComparePeerChannel = (state = INIT_STATE_COMPARE_CHANNEL_CONTENT, action: any) => {

    let index = null;

    switch (action.type) {
        case ComparePeerChannelActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TILES:

                    index = (state.itemsData || []).findIndex(obj => obj?.requestUuid !== undefined && obj.requestUuid === action.payload.data.requestUuid);

                    if (index !== undefined && index !== null){
                        state.itemsData[index]= {
                            ...state.itemsData[index],
                            tiles: action.payload.data.items,
                            isStatPeerChannelContentFetched: true

                        }
                    }

                    return {
                        ...state,
                    };
                case ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA:

                    index = (state.itemsData || []).findIndex(obj => obj?.requestUuid !== undefined && obj.requestUuid === action.payload.data.requestUuid);

                    if (index !== undefined && index !== null){
                        state.itemsData[index]= {
                            ...state.itemsData[index],
                            activitiesData: action.payload.data.items,
                            isStatPeerChannelContentFetched: true
                        }
                    }
                    return {
                        ...state,
                    };

                default:
                    return { ...state };
            }

        case ComparePeerChannelActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {

                default:
                    return { ...state };
            }

        case ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TILES:

            index = (state.itemsData || []).findIndex(obj => obj?.requestUuid !== undefined && obj.requestUuid === action.payload.requestUuid);

            if (index !== undefined && index !== null){

                state.itemsData[index]= {
                    ...state.itemsData[index],
                    tiles:[],
                    isStatPeerChannelContentFetched: false

                }
            }

            return {
                ...state,
            };
        case ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA:
            index = (state.itemsData || []).findIndex(obj => obj?.requestUuid !== undefined && obj.requestUuid === action.payload.requestUuid);

            if (index !== undefined && index !== null){
                state.itemsData[index]= {
                    ...state.itemsData[index],
                    activitiesData:[],
                    isStatPeerChannelContentFetched: false
                }
            }

            return {
                ...state,
            };

        case ComparePeerChannelActionTypes.SET_CHANNEL:

            state.items[action.payload.index]=action.payload.data.channel;

            saveCompareChannels(state.items);

            return {
                ...state,
                items: [...state.items]
            }

        case ComparePeerChannelActionTypes.SET_PERIOD:

            state.itemsData[action.payload.index]= {
                ...state.itemsData[action.payload.index],
                periodFrom: action.payload.data.periodFrom,
                periodTo: action.payload.data.periodTo

            }

            return {
                ...state
            }
        case ComparePeerChannelActionTypes.SET_REQUEST_UUID:

            state.itemsData[action.payload.index]= {
                ...state.itemsData[action.payload.index],
                requestUuid: action.payload.uuid,
            }

            return {
                ...state
            }
        case ComparePeerChannelActionTypes.REMOVE_CHANNEL:

            state.items = (state.items || []).filter((item:ContentPeerChannelType, key: number)=>{return key !== action.payload});
            state.itemsData = (state.itemsData || []).filter((item:StatPeerChannelState, key: number)=>{return key !== action.payload});

            showSuccessNotification(i18n.t('compare:removedFromChannelCollationList'));

            saveCompareChannels(state.items);

            return {
                ...state,
                items: [...state.items],
                itemsData: [...state.itemsData]
            }
        case ComparePeerChannelActionTypes.ADD_TO_COMPARE:

            if (state.items.length>3) {
                showErrorNotification(i18n.t('compare:cantCompareThanChannels'));
                return {
                    ...state
                }
            }

            state.items.push(action.payload);
            showSuccessNotification(i18n.t('compare:addedToChannelCollationList'));

            saveCompareChannels(state.items);

            return {
                ...state,
                items: [...state.items]
            }
        case ComparePeerChannelActionTypes.UPDATE_COMPARE_CHANNELS:

            return {
                ...state,
                items: [...action.payload]
            }
        default:
            return { ...state };
    }
};



export default ComparePeerChannel;