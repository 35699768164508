import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { DashboardSaleActionTypes } from "./types";
import { dashboardSaleApiResponseSuccess, dashboardSaleApiResponseError} from "./actions";

import {
  getStatOrdersTiles as getStatOrdersTilesApi,
  getStatOrdersCountriesTiles as getStatOrdersCountriesTilesApi,
  getStatOrdersCurrenciesTiles as getStatOrdersCurrenciesTilesApi,
  getStatOrdersStatusesTiles as getStatOrdersStatusesTilesApi,
  getStatOrdersBestProductsTiles as getStatOrdersBestProductsTilesApi,
  getStatOrdersBestChannelsTiles as getStatOrdersBestChannelsTilesApi,
  getStatOrdersBestCustomersTiles as getStatOrdersBestCustomersTilesApi,
  getStatOrdersLast12MonthTiles as getStatOrdersLast12MonthTilesApi,
  getRecentOrders as getRecentOrdersApi,
  getStatOrdersWeekdaysTiles as getStatOrdersWeekdaysTilesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getDashboardSaleOrderTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_TILES, error));
  }
}
function* getDashboardSaleOrdersCountriesTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersCountriesTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_COUNTRIES_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_COUNTRIES_TILES, error));
  }
}
function* getDashboardSaleOrdersCurrenciesTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersCurrenciesTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES, error));
  }
}

function* getDashboardSaleOrdersStatusesTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersStatusesTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_STATUSES_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_STATUSES_TILES, error));
  }
}

function* getDashboardSaleOrdersBestProductsTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersBestProductsTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_PRODUCTS_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_PRODUCTS_TILES, error));
  }
}

function* getDashboardSaleOrdersBestChannelsTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersBestChannelsTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CHANNELS_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CHANNELS_TILES, error));
  }
}

function* getDashboardSaleOrdersBestCustomersTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersBestCustomersTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CUSTOMERS_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CUSTOMERS_TILES, error));
  }
}

function* getDashboardSaleOrdersLast12MonthTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersLast12MonthTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_12MONTH_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_12MONTH_TILES, error));
  }
}

function* getDashboardSaleOrdersWeekDaysTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatOrdersWeekdaysTilesApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_ORDERS_WEEKDAYS_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_ORDERS_WEEKDAYS_TILES, error));
  }
}



function* getDashboardSaleRecentOrders({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getRecentOrdersApi, data);
    yield put(
        dashboardSaleApiResponseSuccess(DashboardSaleActionTypes.GET_STAT_RECENT_ORDERS, response)
    );
  } catch (error: any) {
    yield put(dashboardSaleApiResponseError(DashboardSaleActionTypes.GET_STAT_RECENT_ORDERS, error));
  }
}


export function* watchGetDashboardSaleOrderTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_TILES, getDashboardSaleOrderTiles);
}

export function* watchGetDashboardSaleOrdersCountriesTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_COUNTRIES_TILES, getDashboardSaleOrdersCountriesTiles);
}
export function* watchGetDashboardSaleOrdersCurrenciesTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES, getDashboardSaleOrdersCurrenciesTiles);
}
export function* watchGetDashboardSaleOrdersStatusesTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES, getDashboardSaleOrdersStatusesTiles);
}
export function* watchDashboardSaleOrdersBestProductsTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_PRODUCTS_TILES, getDashboardSaleOrdersBestProductsTiles);
}
export function* watchDashboardSaleOrdersBestChannelsTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CHANNELS_TILES, getDashboardSaleOrdersBestChannelsTiles);
}
export function* watchDashboardSaleOrdersBestCustomersTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CUSTOMERS_TILES, getDashboardSaleOrdersBestCustomersTiles);
}
export function* watchDashboardSaleOrdersLast12MonthTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_12MONTH_TILES, getDashboardSaleOrdersLast12MonthTiles);
}
export function* watchDashboardSaleOrdersWeekDaysTiles() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_ORDERS_WEEKDAYS_TILES, getDashboardSaleOrdersWeekDaysTiles);
}
export function* watchDashboardSaleRecentOrders() {
  yield takeEvery(DashboardSaleActionTypes.GET_STAT_RECENT_ORDERS, getDashboardSaleRecentOrders);
}

function* dashboardSaleSaga() {
  yield all([
      fork(watchGetDashboardSaleOrderTiles),
      fork(watchGetDashboardSaleOrdersCountriesTiles),
      fork(watchGetDashboardSaleOrdersCurrenciesTiles),
      fork(watchGetDashboardSaleOrdersStatusesTiles),
      fork(watchDashboardSaleOrdersBestProductsTiles),
      fork(watchDashboardSaleOrdersBestChannelsTiles),
      fork(watchDashboardSaleOrdersBestCustomersTiles),
      fork(watchDashboardSaleOrdersLast12MonthTiles),
      fork(watchDashboardSaleOrdersWeekDaysTiles),
      fork(watchDashboardSaleRecentOrders)
  ]);
}

export default dashboardSaleSaga;
