import React, { useEffect, useState } from 'react';

//i18n
import '../i18/config';
import {useTranslation} from "react-i18next";
import {Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import SimpleBar from "simplebar-react";
import * as route from "../api/routes";
import {useRedux} from "../hooks";
import {ContentPeerChannelType} from "../redux/Content/PeerChannel/types";
import {getCompareChannels, isNumeric, saveCompareChannels} from "../helpers/functions";
import {removeComparePeerChannel, updateComparePeerChannel} from "../redux/Stat/ComparePeerChannel/actions";
import ChannelItemLine from "../blocks/Content/PeerChannel/channelItemLine";
import {SalePlanParamValueType, SalePlanType} from "../redux/Sale/Plan/types";
import {LicenseProductParam} from "../redux/Sale/License/types";
import UpgradeFarePage from "./UpgradeFarePage";
import UpgradeFareBlock from "./UpgradeFareBlock";
import UpgradeFareNotice from "./UpgradeFareNotice";
import UpgradeFare from "./UpgradeFare";


const CompareChannelsTop = () => {

    const { t, i18n } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    const [isDropdown, setIsDropdown] = useState(false);
    const [count, setCount] = useState<number>(0);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);

    const {
        comparePeerChannel,
        salePlan,
        isPlanFetched
    } = useAppSelector(state => ({
        comparePeerChannel: state.ComparePeerChannel,
        salePlan: state.SalePlan,
        isPlanFetched: state.SalePlan.isFetched
    }));

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.STAT_COMPARE_COMPETITORS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    saveCompareChannels([]);
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                saveCompareChannels([]);
                setUpgradeFare(true);
            }
        }
    }

    const toggleDropdown = () => {
        setIsDropdown(!isDropdown);
    };

    useEffect(() => {

        const compareChannels = getCompareChannels();

        if (compareChannels !== undefined && compareChannels!== null && compareChannels.length>0){
            dispatch(updateComparePeerChannel(compareChannels));
        }

    },[dispatch]);

    useEffect(() => {

        if (comparePeerChannel?.items !== undefined && comparePeerChannel?.items !== null && comparePeerChannel.items.length>0) {
            setChannels([...comparePeerChannel.items]);
            setCount(comparePeerChannel.items.length);
        } else {
            setChannels([]);
            setCount(0);
        }

    },[comparePeerChannel.items]);


    const handleRemoveChannel = (index: number) => {
        dispatch(removeComparePeerChannel(index));
    }


    return (
        <>
                <Dropdown isOpen={isDropdown} toggle={toggleDropdown} className="topbar-head-dropdown ms-1 header-item">
                    <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                        <i className='bx bx-bar-chart-alt-2 fs-22'/>
                        { count>0 &&
                            <span
                                className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{count}
                                <span className="visually-hidden"/>
                        </span>
                        }
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                        <div className="dropdown-head bg-primary bg-pattern rounded-top">
                            <div className="p-3">
                                <Row className="align-items-center">
                                    <Col>
                                        <h6 className="m-0 fs-16 fw-semibold text-white">{t('compare:channelsCollationList')}</h6>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="py-2 ps-2">
                            {isPlanFetched &&
                                <>
                                    { upgradeFare ?
                                        <>
                                            <div className="pe-2 pt-3">
                                                <UpgradeFare/>
                                            </div>
                                        </>
                                        :
                                        <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">

                                            {(channels || []).map((item:ContentPeerChannelType, key: number)=>(
                                                <div className="p-2 d-flex flex-row flex-nowrap mw-0" key={key}>
                                                    <div className="d-flex flex-grow-1 align-items-center text-truncate mw-0">
                                                        <ChannelItemLine item={item} link={route.COMPARE_CONTENT_CHANNELS}/>
                                                    </div>
                                                    <div className="d-flex flex-grow-0 flex-shrink-1 ms-3">
                                                        <button type="button"
                                                                onClick={()=>{handleRemoveChannel(key)}}
                                                                className="btn btn-outline-primary btn-icon waves-effect waves-light">
                                                            <i className="ri-delete-bin-line"/></button>
                                                    </div>
                                                </div>
                                            ))}
                                                    <div className="my-3 text-center">
                                                        <a href={route.COMPARE_CONTENT_CHANNELS} className="btn btn-soft-success waves-effect waves-light">{t('compare:analyze')} <i className="ri-arrow-right-line align-middle"/></a>
                                                    </div>
                                        </SimpleBar>
                                    }
                                </>
                            }
                        </div>
                    </DropdownMenu>
                </Dropdown>

        </>
    );
};

export default CompareChannelsTop;