import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum SaleStoreActionTypes {
  API_RESPONSE_SUCCESS = "@@saleStore/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@saleStore/API_RESPONSE_ERROR",
  GET_SALE_STORES = "@@saleStore/GET_SALE_STORES",
  UPDATE_SALE_STORE = "@@saleStore/UPDATE_SALE_STORE",
  REMOVE_SALE_STORE_MODAL_SHOW = "@@saleStore/REMOVE_SALE_STORE_MODAL_SHOW",
  REMOVE_SALE_STORE_MODAL_HIDE = "@@saleStore/REMOVE_SALE_STORE_MODAL_HIDE",
  REMOVE_SALE_STORE = "@@saleStore/REMOVE_SALE_STORE",
  EDIT_SALE_STORE = "@@saleStore/EDIT_SALE_STORE",
  RIGHT_MODAL_SHOW = "@@saleStore/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@saleStore/RIGHT_MODAL_HIDE",
}

export interface SaleStoreState extends MainMetaType{
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: SaleStoreType;
  modalEditTitle: string;
  isSending: boolean;
  isFetched: boolean;
  isLoading: boolean;
  isNeedReload: boolean;
}

export interface SaleStoreType{
  uuid?: string,
  enabled?: boolean,
  name?: string,
  description?: string,
  phone?: string,
  address?: string,
  idRemote?: string,
  link?: string,
  externalLink?: string,
  order?: number,
  actions?: EntityActionsType
}

export interface SaleStorePayload {
  data: Array<any>;
  modalEditTitle : string;
}
