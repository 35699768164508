import {ProductType} from "../Product/types";
import {ProductParamType} from "../ProductParam/types";
import {ProductParamValueType} from "../ProductParamValue/types";
import {ProductPriceType} from "../Price/types";
import {PriceTypeType} from "../PriceType/types";

export enum SalePlanActionTypes {
  API_RESPONSE_SUCCESS = "@@salePlan/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@salePlan/API_RESPONSE_ERROR",
  GET_SALE_CURRENT_PLAN = "@@salePlan/GET_SALE_CURRENT_PLAN",
  GET_SALE_PLANS = "@@salePlan/GET_SALE_PLANS",
}

export interface SalePlanState {
  items: Array<SalePlanType>;
  currentPlan?: SalePlanType;
  isFetched: boolean,
  isLoading: boolean,
}

export interface SalePlanType extends ProductType{
  productPrices?: Array<SalePlanPriceType>
}

export interface SalePlanPayload {
  data: Array<any>;
}

export interface SalePlanParamValueType extends ProductParamValueType {

}

export interface SalePlanParamType extends ProductParamType{

}

export interface SalePlanPriceType extends ProductPriceType{
}


export interface SalePlanPriceTypeType extends PriceTypeType {
  period?: string;
  shortName?: string;
}


export enum SalePlanPricePeriod {
  _1m = 'month',
  _3m = '3months'
}

export interface PlanRibbonType {
  label: string;
  class:string;
  icon: string
}

export interface CartPlanType{
  product: SalePlanType;
  priceType: SalePlanPriceTypeType;
  quantity: number;
}

