import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks/UserHooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";
import {MessengerMessageType} from "../../../redux/Messenger/Chat/types";

interface IndexProps {
  isChannel: boolean;
}

const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    chatUserDetails,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted
  } = useAppSelector(state => ({
    chatUserDetails: state.MessengerChat.chatUserDetails,
    chatUserConversations: state.MessengerChat.chatUserConversations,
    isUserMessageSent: state.MessengerChat.isUserMessageSent,
    isMessageDeleted: state.MessengerChat.isMessageDeleted,
    isMessageForwarded: state.MessengerChat.isMessageForwarded,
    isUserMessagesDeleted: state.MessengerChat.isUserMessagesDeleted,
    isImageDeleted: state.MessengerChat.isImageDeleted,
  }));

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessengerMessageType | undefined
  >();
  const onSetReplyData = (reply: null | MessengerMessageType | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any) => {
    let params: any = {
      text: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      attachments: data.attachments && data.attachments,
      receiver: chatUserDetails.uuid,
      sender: userProfile.uuid,
    };
    if (replyData && replyData !== null) {
      params["replyTo"] = replyData;
    }

    if (data['attachmentFiles'] !== undefined){
      params.attachmentFiles = data['attachmentFiles'];
    }

    dispatch(onSendMessage(params));
    // if (!isChannel) {
    //   setTimeout(() => {
    //     dispatch(receiveMessage(chatUserDetails.uuid));
    //   }, 1000);
    //   setTimeout(() => {
    //     dispatch(readMessage(chatUserDetails.uuid));
    //   }, 1500);
    //   setTimeout(() => {
    //     dispatch(receiveMessageFromUser(chatUserDetails.uuid));
    //   }, 2000);
    // }
    setReplyData(null);
  };

  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted
    ) {
      dispatch(getChatUserConversations(chatUserDetails.uuid));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.uuid));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.uuid));
  };

  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        // pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
//        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;
