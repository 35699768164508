import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { MainRoleActionTypes } from "./types";
import { mainRoleApiResponseSuccess, mainRoleApiResponseError } from "./actions";

import {
  getMainRoles as getMainRolesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getMainRoles() {
  try {
    const response: Promise<any> = yield call(getMainRolesApi);
    yield put(mainRoleApiResponseSuccess(MainRoleActionTypes.GET_MAIN_ROLES, response));
  } catch (error: any) {
    yield put(mainRoleApiResponseError(MainRoleActionTypes.GET_MAIN_ROLES, error));
  }
}

export function* watchGetMainRoles() {
  yield takeEvery(MainRoleActionTypes.GET_MAIN_ROLES, getMainRoles);
}

function* mainRolesSaga() {
  yield all([
    fork(watchGetMainRoles),
  ]);
}

export default mainRolesSaga;
