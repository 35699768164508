import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { EventWebhookActionTypes } from "./types";
import { eventWebhookApiResponseSuccess, eventWebhookApiResponseError } from "./actions";

import {
  getEventWebhooks as getEventWebhooksApi,
  sendDataEventWebhook as sendDataEventWebhookApi,
  removeEventWebhook as removeEventWebhookApi,
  getWebhookEventTypes as getWebhookEventTypesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getEventWebhooks({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getEventWebhooksApi,data);
    yield put(eventWebhookApiResponseSuccess(EventWebhookActionTypes.GET_EVENT_WEBHOOKS, response));
  } catch (error: any) {
    yield put(eventWebhookApiResponseError(EventWebhookActionTypes.GET_EVENT_WEBHOOKS, error));
  }
}

function* getWebhookAvailableEventTypes() {
  try {
    const response: Promise<any> = yield call(getWebhookEventTypesApi);
    yield put(eventWebhookApiResponseSuccess(EventWebhookActionTypes.GET_AVAILABLE_EVENT_TYPES, response));
  } catch (error: any) {
    yield put(eventWebhookApiResponseError(EventWebhookActionTypes.GET_AVAILABLE_EVENT_TYPES, error));
  }
}

function* onSendDataEventWebhook({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataEventWebhookApi, data);
    yield put(
        eventWebhookApiResponseSuccess(EventWebhookActionTypes.UPDATE_EVENT_WEBHOOK, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(eventWebhookApiResponseError(EventWebhookActionTypes.UPDATE_EVENT_WEBHOOK, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveEventWebhook({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeEventWebhookApi, {uuid: data.data.uuid});
    yield put(
        eventWebhookApiResponseSuccess(EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(eventWebhookApiResponseError(EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetEventWebhooks() {
  yield takeEvery(EventWebhookActionTypes.GET_EVENT_WEBHOOKS, getEventWebhooks);
}
export function* watchGetWebhookAvailableEventTypes() {
  yield takeEvery(EventWebhookActionTypes.GET_AVAILABLE_EVENT_TYPES, getWebhookAvailableEventTypes);
}
export function* watchOnSendDataEventWebhook() {
  yield takeEvery(EventWebhookActionTypes.UPDATE_EVENT_WEBHOOK, onSendDataEventWebhook);
}
export function* watchOnRemoveEventWebhook() {
  yield takeEvery(EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK, onRemoveEventWebhook);
}

function* eventWebhookSaga() {
  yield all([
      fork(watchGetEventWebhooks),
      fork(watchOnSendDataEventWebhook),
      fork(watchOnRemoveEventWebhook),
      fork(watchGetWebhookAvailableEventTypes)
  ]);
}

export default eventWebhookSaga;
