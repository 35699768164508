import {SalePlanActionTypes, SalePlanPayload} from "./types";

export const salePlanApiResponseSuccess = (actionType: string, data: any) => ({
  type: SalePlanActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const salePlanApiResponseError = (actionType: string, error: string) => ({
  type: SalePlanActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleCurrentPlan = () => ({
  type: SalePlanActionTypes.GET_SALE_CURRENT_PLAN,
});

export const getSalePlans = () => ({
  type: SalePlanActionTypes.GET_SALE_PLANS,
});