// types
import {ContentConnectorActionTypes, ContentConnectorState, ContentConnectorType} from "./types";
import {ContentPeerChannelType} from "../PeerChannel/types";

export const NewContentConnectorItem:ContentConnectorType = {
  enabled: true,
  name: '',
  isConnected:false,
  isUnread: false,
  order: 500,
}

export const INIT_STATE_CONTENT_CONNECTOR: ContentConnectorState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewContentConnectorItem,
  modalEditTitle: '',
  isNeedReload: false,
  isNeedVerificationCode: false,
  isNeedAddCode: false,
  isConnecting: false,
  isSending: false,
  isSearchChannels: false,
  searchChannels: [],
  managedChannels:[],
  isContentConnectorFetched: false,
  getContentConnectorLoading: false,
  isContentConnectorChannelsFetched: false,
};

const ContentConnector = (state = INIT_STATE_CONTENT_CONNECTOR, action: any) => {
  switch (action.type) {
    case ContentConnectorActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentConnectorActionTypes.GET_CONTENT_CONNECTORS:
          return {
            ...state,
            items: action.payload.data.items,
            isContentConnectorFetched: true,
            getContentConnectorLoading: false,
            isNeedReload: false
          };
        case ContentConnectorActionTypes.UPDATE_CONTENT_CONNECTOR:

          if (action.payload.data.connector !== undefined){
              state.selectedItem = action.payload.data.connector;
          }

          return {
            ...state,
            isSending: false,
            rightModalShow: !state.selectedItem.isConnected
          };
        case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR:

          if (state.selectedItem !== undefined && state.selectedItem.uuid !== undefined && action.payload.data.uuid !== undefined) {
            if (state.selectedItem.uuid === action.payload.data.uuid) {
              if (
                  (action.payload.data.isConnected !== undefined)
                  &&
                  action.payload.data.isConnected === true) {
                state.isConnecting = false;
//                state.rightModalShow = false;
              } else if (action.payload.data.verificationCode !== undefined) {
                state.isNeedVerificationCode = true;
                state.isConnecting = false;
              } else {
              //  state.selectedItem.connectionParams = action.payload.data;

                if (action.payload.data.OAuthUrl !== undefined) {
                  state.selectedItem.OAuthUrl = action.payload.data.OAuthUrl;
                }
              }

              state.selectedItem.connectionCheckAttempts = 0;
            }
          } else if (action.payload.data.verificationCode !== undefined && action.payload.data.uuid === undefined) {
            state.isNeedVerificationCode = true;
            state.isConnecting = false;
          }

          return {
            ...state,
          };

        case ContentConnectorActionTypes.IS_CONNECTED_CONTENT_CONNECTOR:

          if (
              (action.payload.data.isConnected !== undefined)
              &&
              (action.payload.data.isConnected === true)
          ) {
              if (state?.selectedItem?.uuid === action.payload.data.uuid) {
                state.isConnecting = false;
                state.isNeedReload = true;
                state.isNeedVerificationCode = false;
                state.selectedItem.connectionSuccess = true;
                state.selectedItem.isConnected = true;
//                state.rightModalShow = false;
                delete state.selectedItem.connectionCheckAttempts;

                if (state.selectedItem.OAuthUrl !== undefined) {
                  delete state.selectedItem.OAuthUrl;
                }
              }
          } else {
              state.selectedItem.connectionCheckAttempts++;
          }

          return {
            ...state,
          };
        case ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR:
          return {
            ...state,
            selectedItem: NewContentConnectorItem,
            removeModalShow: false,
          };
        case ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS:

          if (action.payload.data.response && action.payload.data.response === 'wsock'){
              state.isSearchChannels = true;
              state.requestMetadata = action.payload.data.requestMetadata;
          } else {

            if (action.payload.data.requestMetadata.uuid === state?.requestMetadata?.uuid) {
              state.searchChannels = action.payload.data.items;
              state.isSearchChannels = false;
              state.requestMetadata = null;
            }
          }

          return {
            ...state
          };

        case ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_CHANNELS:
        case ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_COUNTERS:

          if (action.payload.data.response && action.payload.data.response === 'wsock'){
            state.isSearchChannels = true;
            state.isContentConnectorChannelsFetched = false;
            state.requestMetadata = action.payload.data.requestMetadata;
          } else {

            if (action.payload.data.requestMetadata.uuid === state?.requestMetadata?.uuid) {
              state.managedChannels = action.payload.data.items;
              state.isSearchChannels = false;
              state.requestMetadata = null;
              state.selectedItem.isConnected = true;
              state.isContentConnectorChannelsFetched = true;
            }
          }

          return {
            ...state
          };
        case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL:
        case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER:
          if (action.payload.data.uuid){
            (state.managedChannels || []).map((item:ContentPeerChannelType)=>{
                if (item?.uuid && item.uuid === action.payload.data.uuid){
                    item.isUpdating = false;
                    item.enabled = action.payload.data.enabled;
                } else if (action.payload.data?.idPeer && item?.idPeer && action.payload.data.idPeer.length>0 && action.payload.data.idPeer === item.idPeer){
                    item.uuid = action.payload.data.uuid;
                    item.isUpdating = false;
                    item.enabled = action.payload.data.enabled;
                }
              return item;
            })
          }

          return {
            ...state
          };

        default:
          return { ...state };
      }

    case ContentConnectorActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentConnectorActionTypes.GET_CONTENT_CONNECTORS:
          return {
            ...state,
            isContentConnectorFetched: false,
            getContentConnectorLoading: false,
          };
        case ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR:
          return {
            ...state,
            removeModalShow: false,
            isSending: false
          };
        case ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS:
          return {
            ...state,
            isSearchChannels: false,
          };
        case ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_CHANNELS:
        case ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_COUNTERS:
          return {
            ...state,
            isSearchChannels: false,
            isContentConnectorChannelsFetched: false,
            managedChannels: []
          };

        case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL:
        case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER:
            (state.managedChannels || []).map((item:ContentPeerChannelType)=>{
                item.isUpdating = false;
                return item;
            })

          return {
            ...state
          };
        case ContentConnectorActionTypes.UPDATE_CONTENT_CONNECTOR:
          return {
            ...state,
            isSending: false
          }
        default:
          return { ...state };


      }
    case ContentConnectorActionTypes.EDIT_CONTENT_CONNECTOR:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true,
        managedChannels: []
      }
    case ContentConnectorActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewContentConnectorItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true,
        managedChannels: []
      }
    case ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentConnectorItem,
        removeModalShow: false
      }
    case ContentConnectorActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentConnectorItem,
        isContentConnectorChannelsFetched: false,
        rightModalShow: false
      }
    case ContentConnectorActionTypes.SELECT_CONTENT_CONNECTOR:
      return {
        ...state,
        isSending: false,
        isConnecting: false,
        selectedItem: action.payload.data,
        managedChannels: []
      }
    case ContentConnectorActionTypes.SET_NEED_ADD_CODE_CONTENT_CONNECTOR:
      return {
        ...state,
        isNeedAddCode: true,
    }
    case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR:
      return {
        ...state,
        isConnecting: true
      }
    case ContentConnectorActionTypes.UPDATE_CONTENT_CONNECTOR:
      return {
        ...state,
        isConnecting: false,
        isSending: true
      }
    case ContentConnectorActionTypes.IS_CONNECTED_ERROR_CONTENT_CONNECTOR:
      return {
        ...state,
        isConnecting: false,
        selectedItem: {...state.selectedItem, OAuthUrl:''}
      }
    case ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS:
      return {
        ...state,
        isSearchChannels: true,
        isContentConnectorChannelsFetched: false,
      }
    case ContentConnectorActionTypes.REMOVE_OAUTH_LINK:

      if (state.selectedItem !== undefined && state.selectedItem.OAuthUrl !== undefined) {
        state.selectedItem.OAuthUrl = '';
      }

      return {
        ...state,
      }
    case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL:
    case ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER:

      if (action.payload?.uuid){
        (state.managedChannels || []).map((item:ContentPeerChannelType)=>{
          if (item?.uuid && item.uuid === action.payload.uuid){
            item.isUpdating = true;
          }
          return item;
        })
      } else if (action.payload?.peer?.id){
        (state.managedChannels || []).map((item:ContentPeerChannelType)=>{
          if (item?.idPeer && item.idPeer.length>0 && item.idPeer === action.payload.peer.id){
            item.isUpdating = true;
          }
          return item;
        })
      }

      return {
        ...state
      };

    default:
      return { ...state };
  }
};

export default ContentConnector;
