import React, {useState} from "react";
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { Icon } from "ts-react-feather-icons";

import {ContentConnectorStat, ContentConnectorType} from "../../../redux/Content/Connector/types";

import * as route from "../../../api/routes"
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import ChannelItem from "../PeerChannel/channelItem";
import ChannelItemAdd from "../PeerChannel/channeItemAdd";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getHandlerIconByCode} from "../../../helpers/functions";
import {BiCrown} from "react-icons/bi";
import {handleCreateNewTaskByConnector} from "./ContentConnectorActions";
import {useRedux} from "../../../hooks";

interface ItemProps {
    item: ContentConnectorType;
    onEdit: (event:any) => void;
    onRemove: (event:any) => void;
    onConnect?: (event:any) => void;
}
const ListItem = ({ item, onEdit, onRemove, onConnect }: ItemProps) => {

    const { t } = useTranslation(['common']);
    const { dispatch } = useRedux();

    const showActions = item.actions && (item.actions.canEdit || item.actions.canRemove);

    const renderStatus = () => item.isConnected
        ? <span className="badge bg-success">{t('common:active')}</span>
        : <span className="badge badge-outline-dark">{t('common:offline')}</span>;

    return (
            <Card className="card-height-100 card-animate">
                <CardBody>
                    <div className="d-flex flex-column h-100">
                        <div className="d-flex mb-4">
                            <div className="flex-grow-1 d-flex align-items-center">
                                <p className="text-muted mb-0">
                                    {t('connector:service')}
                                </p>
                            </div>
                            <div className="flex-shrink-0">
                                <div className="d-flex align-items-center">
                                    {/*<button type="button" className="btn avatar-xs p-0 favourite-btn active">*/}
                                    {/*                    <span className="avatar-title bg-transparent fs-15">*/}
                                    {/*                        <i className="ri-star-fill"></i>*/}
                                    {/*                    </span>*/}
                                    {/*</button>*/}
                                    <div>
                                        {renderStatus()}
                                    </div>
                                    <div className="d-flex">
                                        <UncontrolledDropdown direction='start'>
                                            <DropdownToggle tag="a" className="btn btn-sm btn-link text-muted text-decoration-none fs-15">
                                                <Icon name="more-horizontal" />
                                            </DropdownToggle>

                                            <DropdownMenu className="dropdown-menu-end">

                                                {item.handler.type === ContentConnectorStat.STAT ?
                                                <DropdownItem tag="a" href={route.CONTENT_STAT_COUNTERS_LIST} ><i className="ri-share-line align-bottom text-muted me-2"/> {t('counter:counters')}</DropdownItem>
                                                :
                                                    <DropdownItem tag="a" href={route.CONTENT_CHANNELS_LIST} ><i className="ri-share-line align-bottom text-muted me-2"/> {t('channel:channels')}</DropdownItem>
                                                }
                                                {showActions &&
                                                    <DropdownItem divider/>
                                                }
                                                {item.actions && item.actions.canEdit &&
                                                <DropdownItem className='edit-item-btn' onClick={() => onEdit(item)}><i
                                                    className="ri-pencil-fill align-bottom text-muted me-2"/> {t('common:edit')} {t('connector:service')}
                                                </DropdownItem>
                                                }
                                                <DropdownItem className='edit-item-btn' onClick={() => handleCreateNewTaskByConnector(item, dispatch)}><i
                                                    className="ri-survey-line align-bottom text-muted me-2"/> {t('task:addNewTask')}</DropdownItem>
                                                {item.actions && item.actions.canEdit && item.isConnected === true &&
                                                    <DropdownItem className='edit-item-btn' onClick={() => onEdit(item)}>
                                                        <BiCrown className="fs-16 me-1 mb-1 text-muted" title={t('channel:managedChannel')}/> {t('channel:managedChannels')}
                                                    </DropdownItem>
                                                }
                                                {item.actions && item.actions.canEdit && item.isConnected === false && item.handler.isFareRestriction !== true &&
                                                <DropdownItem className='edit-item-btn' onClick={() => onConnect(item)}><i
                                                    className="ri-share-circle-line align-bottom text-muted me-2"/> {t('connector:connect')}
                                                </DropdownItem>
                                                }

                                                {item.actions && item.actions.canRemove &&
                                                <DropdownItem className='remove-item-btn'
                                                              onClick={() => onRemove(item)}><i
                                                    className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')}
                                                </DropdownItem>
                                                }

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mb-2">
                            <div className="flex-shrink-0 me-3">
                                <div className="avatar-sm position-relative">
                                    <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                        { item.handler && item.handler.code ?
                                            <img src={getHandlerIconByCode(item.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                                            :
                                            item.name[0]
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="flex-grow-1 d-flex flex-row align-items-center">
                                <h5 className="mb-1 fs-16">
                                    {item.name}
                                </h5>

                            </div>
                        </div>
                    </div>
                </CardBody>

                <div className="card-footer bg-transparent border-top-dashed py-2">
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column w-50">
                            <div className="mb-1">{t('channel:ownChannels')}</div>
                            <div className="d-flex flex-row flex-wrap avatar-group">
                                {item.channels ? item.channels.map((channel: ContentPeerChannelType, key: number) => (
                                    channel.isManaged &&
                                    <ChannelItem item={channel} key={'managedChannel_' + channel.uuid}/>
                                )):''}
                                <ChannelItemAdd/>
                            </div>
                        </div>
                        <div className="d-flex flex-column w-50">
                            <div className="mb-1">{t('channel:competitors')}</div>
                            <div className="d-flex flex-row flex-wrap avatar-group">
                                {item.channels ? item.channels.map((channel: ContentPeerChannelType, key: number) => (
                                    !channel.isManaged &&
                                    <ChannelItem item={channel} key={'competitorsChannel_' + channel.uuid}/>
                                )):''}
                                <ChannelItemAdd/>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
    );
};

export default ListItem;
