import React from "react";
import {SaleIntegrationStatus, SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import ChannelItemLine from "../../Content/PeerChannel/channelItemLine";
import {SaleIntegrationHandlerTypes} from "../../../redux/Sale/IntegrationHandler/types";
import {getHandlerIconByCode} from "../../../helpers/functions";

const SaleIntegrationColumns = ({ onEdit, onRemove, onInvalidateCache }:EntityActionsHandlersType) => {

    const { t } = useTranslation(['common']);

    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block" direction="up">
                        <DropdownToggle className="btn btn-light btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            { cell.actions && cell.actions.canEdit &&
                                <DropdownItem className='edit-item-btn' onClick={() => onEdit(cell)}><i
                                    className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:edit')} </DropdownItem>
                            }
                            { cell.actions && cell.actions.canEdit &&
                                <DropdownItem className='edit-item-btn' onClick={() => onInvalidateCache(cell)}><i
                                    className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:clearCache')} </DropdownItem>
                            }
                            {cell.actions && cell.actions.canRemove &&
                                <DropdownItem className='remove-item-btn' onClick={() => onRemove(cell)}><i className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')} </DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            id: 'order',
            name: <span className='font-weight-bold fs-14'>{t('common:order')}</span>,
            selector: (row:SaleIntegrationType) => row.order,
            sortable: true,
            maxWidth: '50px',
            center: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }]
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('common:nameTitle')}</span>,
            selector: (row:SaleIntegrationType) => {
                return (
                    <>
                        <div className="d-flex flex-shrink-0" onClick={() => onEdit(row)}>
                            <div className="flex-row d-flex">
                                <div className="d-flex flex-column flex-shrink-0 justify-content-center">
                                        {row.handler.type===SaleIntegrationHandlerTypes.INBOUND &&
                                            <i className="text-primary ri-download-cloud-2-line align-bottom"/>
                                        }
                                        {row.handler.type===SaleIntegrationHandlerTypes.OUTBOUND &&
                                            <i className="text-primary ri-upload-cloud-2-line align-bottom"/>
                                        }
                                </div>
                                {row?.handler?.code &&
                                    <div className="avatar-xxs d-flex flex-column flex-shrink-0 justify-content-center">
                                        <img src={getHandlerIconByCode(row.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                                    </div>
                                }
                                <div className="d-flex flex-column flex-grow-1 justify-content-center mw-0">
                                    <p className={"lh-base mb-0 text-truncate"}>
                                        {row.name}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('channel:channel')}</span>,
            selector: (row:SaleIntegrationType) => {
                return (
                    <>
                        {row?.channel?.uuid &&
                        <div className={"p-1"}><ChannelItemLine item={row.channel}/></div>
                        }
                        {row?.param?.urlShortener?.url && row.param.urlShortener.url !== null && row.param.urlShortener.url.length>0 &&
                            <div className="text-muted text-truncate">
                                https://{row.param.urlShortener.domain.code}/{row.param.urlShortener.url}
                            </div>
                        }
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('price:priceType')}</span>,
            selector: (row:SaleIntegrationType) => {
                return (
                    <>
                        {row?.priceType?.uuid && row.priceType.name}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('currency:currency')}</span>,
            selector: (row:SaleIntegrationType) => {
                return (
                    <>
                        {row?.currency?.code && row.currency.code}
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('integration:integrationActionStatus')}</span>,
            selector: (row:SaleIntegrationType) => {
                let status = Object.keys(SaleIntegrationStatus).filter((statusKey: string)=>{
                     return SaleIntegrationStatus[statusKey].status===row.status;
                }).shift();

                if (status !== undefined && status.length>0){
                    return (<span className={"badge text-uppercase badge-soft-"+SaleIntegrationStatus[status].class}> {t('integration:integrationStatus'+SaleIntegrationStatus[status].label)} </span>)
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('store:store')}</span>,
            selector: (row:SaleIntegrationType) => {
                return (
                    <>
                        {row?.store?.name && row.store.name}
                    </>
                )
            },
        },
        // {
        //     name: <span className='font-weight-bold fs-14'>{t('common:code')}</span>,
        //     selector: (row:SaleIntegrationType) => row.code,
        //     sortable: true,
        //     wrap: true,
        //     conditionalCellStyles: [{
        //         when: (row:any) => row,
        //         classNames: ['fs-14', 'p-2', 'lh-md']
        //     }]
        // },
        // {
        //     name: <span className='font-weight-bold fs-14'>{t('common:description')}</span>,
        //     selector: (row:SaleIntegrationType) => row.text,
        //     sortable: true,
        //     wrap: true,
        //     conditionalCellStyles: [{
        //         when: (row:any) => row,
        //         classNames: ['fs-14', 'p-2', 'lh-md']
        //     }]
        // },
        {
            id: 'status',
            name: <span className='font-weight-bold fs-14'>{t('common:status')}</span>,
            sortable: true,
            maxWidth: '50px',
            center: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }],
            selector: (cell:any) => {
                switch (cell.enabled) {
                    case true:
                        return <span className="badge badge-soft-success"> {t('common:active')} </span>;
                    default:
                        return <span className="badge badge-soft-danger"> {t('common:inActive')} </span>;
                }
            },
        },
    ];

}

export default SaleIntegrationColumns;