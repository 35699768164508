import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ComparePeerChannelActionTypes } from "./types";
import { comparePeerChannelApiResponseSuccess, comparePeerChannelApiResponseError} from "./actions";

import {
    getPeerActivities as getPeerActivitiesApi,
    getStatChannelsTiles as getStatChannelsTilesApi,
    getStatPeerTargets as getStatPeerTargetsApi,
    getStatChannelsActivities as getStatChannelsActivitiesApi
} from "../../../api";

function* getComparePeerChannelContentTiles({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(getStatChannelsTilesApi, data);
        yield put(
            comparePeerChannelApiResponseSuccess(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TILES, response)
        );
    } catch (error: any) {
        yield put(comparePeerChannelApiResponseError(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TILES, error));
    }
}

function* getComparePeerChannelContentActivitiesData({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(getStatChannelsActivitiesApi, data);
        yield put(
            comparePeerChannelApiResponseSuccess(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA, response)
        );
    } catch (error: any) {
        yield put(comparePeerChannelApiResponseError(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA, error));
    }
}

function* getComparePeerChannelContentTargets({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(getStatPeerTargetsApi, data);
        yield put(
            comparePeerChannelApiResponseSuccess(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TARGETS, response)
        );
    } catch (error: any) {
        yield put(comparePeerChannelApiResponseError(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TARGETS, error));
    }
}

function* getComparePeerChannelContentActivities({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(getPeerActivitiesApi, data);
        yield put(
            comparePeerChannelApiResponseSuccess(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES, response)
        );
    } catch (error: any) {
        yield put(comparePeerChannelApiResponseError(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES, error));
    }
}

function* getComparePeerChannelContentScheduleActivities({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(getPeerActivitiesApi, data);
        yield put(
            comparePeerChannelApiResponseSuccess(ComparePeerChannelActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES, response)
        );
    } catch (error: any) {
        yield put(comparePeerChannelApiResponseError(ComparePeerChannelActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES, error));
    }
}

export function* watchGetComparePeerChannelContentTiles() {
    yield takeEvery(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TILES, getComparePeerChannelContentTiles);
}

export function* watchGetComparePeerChannelContentChannelsActivitiesData() {
    yield takeEvery(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA, getComparePeerChannelContentActivitiesData);
}

export function* watchGetComparePeerChannelContentTargets() {
    yield takeEvery(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TARGETS, getComparePeerChannelContentTargets);
}

export function* watchGetComparePeerChannelContentActivities() {
    yield takeEvery(ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES, getComparePeerChannelContentActivities);
}

export function* watchGetComparePeerChannelContentScheduleActivities() {
    yield takeEvery(ComparePeerChannelActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES, getComparePeerChannelContentScheduleActivities);
}

function* comparePeerChannelSaga() {
    yield all([
        fork(watchGetComparePeerChannelContentTiles),
        fork(watchGetComparePeerChannelContentTargets),
        fork(watchGetComparePeerChannelContentActivities),
        fork(watchGetComparePeerChannelContentChannelsActivitiesData),
        fork(watchGetComparePeerChannelContentScheduleActivities)
    ]);
}

export default comparePeerChannelSaga;
