import {useCallback, useContext, useEffect, useState} from 'react';
import {
    Contextable,
    SessionConnectHandler,
    SessionDisconnectHandler,
    SessionHook,
    SessionMessageHandler,
} from './webSocketTypes';

import * as url from "../urls";
import {getLoggedinUser} from "../apiCore";
import {useRedux} from "../../hooks";
import {setWSConnected, setWSDisconnected} from "../../redux/Main/Login/actions";

export function useSession(
    onOpen: SessionConnectHandler,
    onMessage: SessionMessageHandler,
    onClose: SessionDisconnectHandler
): SessionHook {

    const [session, setSession] = useState(null as unknown as WebSocket);
    const { dispatch, useAppSelector } = useRedux();

    const updateOpenHandler = () => {
        if (!session) return;
        session.addEventListener('open', onOpen);
        return () => {
            session.removeEventListener('open', onOpen);
        };
    };

    const updateMessageHandler = () => {
        if (!session) return;
        session.addEventListener('message', onMessage);
        return () => {
            session.removeEventListener('message', onMessage);
        };
    };

    const updateCloseHandler = () => {
        if (!session) return;
        session.addEventListener('close', onClose);
        return () => {
            session.removeEventListener('close', onClose);
        };
    };

    useEffect(()=>{
        if (session && session.readyState === 1 ) {
            dispatch(setWSConnected());
        } else {
            dispatch(setWSDisconnected());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session?.readyState]);

    useEffect(updateOpenHandler, [session, onOpen]);
    useEffect(updateMessageHandler, [session, onMessage]);
    useEffect(updateCloseHandler, [session, onClose]);

    const connect = useCallback(
        () => {
            let currentUser = getLoggedinUser()
            if ((currentUser.token !== undefined) && (currentUser.token.length > 0)) {
                    const uri = 'wss://' + url.WEB_SOCKET;
                    const ws = new WebSocket(uri + '?token=' + currentUser.token);
                    setSession(ws);
            }
        },
        []
    );

    const sendMessage = <T extends Contextable>(args: T) => {
        session.send(JSON.stringify(args));
    };

    const close = useCallback(() => {
        if (session.readyState === session.OPEN) session.close(1001);
    }, [session]);

    return [connect, sendMessage, close];
}