import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleProductActionTypes } from "./types";
import { saleProductApiResponseSuccess, saleProductApiResponseError } from "./actions";

import {
  getSaleProducts as getSaleProductsApi,
  sendDataSaleProduct as sendDataSaleProductApi,
  removeSaleProduct as removeSaleProductApi,
  getSaleProduct as getSaleProductApi,
  sendSaleProductIntegration as sendSaleProductIntegrationApi,
  sendSaleProductProperties as sendSaleProductPropertiesApi,
  copyProduct as copyProductApi,
  sendSaleProductGroupIntegration as sendSaleProductGroupIntegrationApi,
  setGroupSaleProduct as setGroupSaleProductApi,
  unSetGroupSaleProduct as unSetGroupSaleProductApi,
  removeGroupSaleProduct as removeGroupSaleProductApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getSaleProducts({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductsApi, data);
    yield put(saleProductApiResponseSuccess(SaleProductActionTypes.GET_PRODUCTS, response));
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.GET_PRODUCTS, error));
  }
}

function* getSaleProduct({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.GET_PRODUCT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.GET_PRODUCT, error));
    yield call(showErrorNotification, error);
  }
}

function* getSaleEditProduct({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductApi, data.data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.GET_EDIT_PRODUCT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.GET_EDIT_PRODUCT, error));
    yield call(showErrorNotification, error);
  }
}

function* onSendDataSaleProduct({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleProductApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.UPDATE_PRODUCT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.UPDATE_PRODUCT, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleProduct({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleProductApi, {uuid: data.data.uuid});
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.REMOVE_PRODUCT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.REMOVE_PRODUCT, error));
    yield call(showErrorNotification, error);
  }
}

function* updateProductIntegrationChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendSaleProductIntegrationApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.UPDATE_PRODUCT_INTEGRATION_CHANNEL, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.UPDATE_PRODUCT_INTEGRATION_CHANNEL, error));
    yield call(showErrorNotification, error);
  }
}

function* updateProductGroupIntegrationChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendSaleProductGroupIntegrationApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.UPDATE_GROUP_PRODUCT_INTEGRATIONS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.UPDATE_GROUP_PRODUCT_INTEGRATIONS, error));
    yield call(showErrorNotification, error);
  }
}

function* setProductGroup({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(setGroupSaleProductApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.SET_GROUP_PRODUCTS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.SET_GROUP_PRODUCTS, error));
    yield call(showErrorNotification, error);
  }
}

function* unSetProductGroup({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(unSetGroupSaleProductApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.UNSET_GROUP_PRODUCTS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.UNSET_GROUP_PRODUCTS, error));
    yield call(showErrorNotification, error);
  }
}

function* removeProductGroup({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeGroupSaleProductApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.REMOVE_GROUP_PRODUCTS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.REMOVE_GROUP_PRODUCTS, error));
    yield call(showErrorNotification, error);
  }
}

function* updateProductProperties({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendSaleProductPropertiesApi, data);
    yield put(
        saleProductApiResponseSuccess(SaleProductActionTypes.UPDATE_PRODUCT_PROPERTIES, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.UPDATE_PRODUCT_PROPERTIES, error));
    yield call(showErrorNotification, error);
  }
}

function* onCopyProduct({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(copyProductApi,data);
    yield put(saleProductApiResponseSuccess(SaleProductActionTypes.COPY_PRODUCT, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductApiResponseError(SaleProductActionTypes.COPY_PRODUCT, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleProducts() {
  yield takeEvery(SaleProductActionTypes.GET_PRODUCTS, getSaleProducts);
}
export function* watchGetSaleProduct() {
  yield takeEvery(SaleProductActionTypes.GET_PRODUCT, getSaleProduct);
}
export function* watchGetSaleEditProduct() {
  yield takeEvery(SaleProductActionTypes.GET_EDIT_PRODUCT, getSaleEditProduct);
}
export function* watchOnSendDataSaleProduct() {
  yield takeEvery(SaleProductActionTypes.UPDATE_PRODUCT, onSendDataSaleProduct);
}
export function* watchOnRemoveSaleProduct() {
  yield takeEvery(SaleProductActionTypes.REMOVE_PRODUCT, onRemoveSaleProduct);
}
export function* watchOnUpdateProductIntegrationChannel() {
  yield takeEvery(SaleProductActionTypes.UPDATE_PRODUCT_INTEGRATION_CHANNEL, updateProductIntegrationChannel);
}
export function* watchOnUpdateProductGroupIntegrationChannel() {
  yield takeEvery(SaleProductActionTypes.UPDATE_GROUP_PRODUCT_INTEGRATIONS, updateProductGroupIntegrationChannel);
}

export function* watchOnSetProductGroup() {
  yield takeEvery(SaleProductActionTypes.SET_GROUP_PRODUCTS, setProductGroup);
}
export function* watchOnUnSetProductGroup() {
  yield takeEvery(SaleProductActionTypes.UNSET_GROUP_PRODUCTS, unSetProductGroup);
}
export function* watchOnUpdateProductProperties() {
  yield takeEvery(SaleProductActionTypes.UPDATE_PRODUCT_PROPERTIES, updateProductProperties);
}
export function* watchOnCopyProduct() {
  yield takeEvery(SaleProductActionTypes.COPY_PRODUCT, onCopyProduct);
}
export function* watchOnRemoveProductGroup() {
  yield takeEvery(SaleProductActionTypes.REMOVE_GROUP_PRODUCTS, removeProductGroup);
}


function* saleProductSaga() {
  yield all([
      fork(watchGetSaleProducts),
      fork(watchOnSendDataSaleProduct),
      fork(watchOnRemoveSaleProduct),
      fork(watchGetSaleProduct),
      fork(watchGetSaleEditProduct),
      fork(watchOnUpdateProductIntegrationChannel),
      fork(watchOnUpdateProductProperties),
      fork(watchOnCopyProduct),
      fork(watchOnUpdateProductGroupIntegrationChannel),
      fork(watchOnSetProductGroup),
      fork(watchOnUnSetProductGroup),
      fork(watchOnRemoveProductGroup)
  ]);
}

export default saleProductSaga;
