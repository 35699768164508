import React, {useEffect, useState} from "react";

import {EventSubscribeUserType} from "../../../redux/Event/EventSubscribeUser/types";
import InputSwitch from "../../../components/InputSwitch";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {updateEventUserSubscription} from "../../../redux/Event/EventSubscribeUser/actions";
import {getMainCurrentUser} from "../../../redux/Main/User/actions";
import {useRedux} from "../../../hooks";

interface ItemProps {
    items: EventSubscribeUserType[];
}
const UserNotificationsChange = ({ items }: ItemProps) => {

    const { t } = useTranslation(['common']);
    const { dispatch } = useRedux();

    const [ notifications, setNotifications] = useState<EventSubscribeUserType[]>([]);

    useEffect(() => {
        setNotifications([...items]);
    },[items]);

    const onChangeSubscription = (data:EventSubscribeUserType) => {

        data.isSubscribed = !data.isSubscribed;

        dispatch(updateEventUserSubscription(data));

        setTimeout(() => {
            dispatch(getMainCurrentUser());
        }, 1000);
    }

    return (
        <ul className="list-unstyled mb-0">
            {(notifications || []).map((item, key)=>(
            <li className="d-flex mb-2" key={'notifications_'+key}>
                <div className="flex-grow-1">
                    <label htmlFor="directMessage"
                           className="text-muted form-check-label fs-15">{t('eventType:'+item.eventType.code)}</label>
                    {/*<p className="text-muted">{t('eventType:'+item.eventType.code)}</p>*/}
                </div>
                <div className="flex-shrink-0">
                    <div className="form-check form-switch">
                        <InputSwitch
                            id={"form-check-input-"+item.uuid}
                            value={item.isSubscribed}
                            onChange={()=>onChangeSubscription(item)}
                            className="form-switch-sm"/>
                    </div>
                </div>
            </li>
            ))}
        </ul>
    );
};

export default UserNotificationsChange;
