import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,} from 'reactstrap';

import InputText from "../../../components/InputText";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {MainUserResetType} from "../../../redux/Main/User/types";
import CurrentUser, {MainUserReset} from "../../../redux/Main/User/reducer";
import {onSendResetUser, onSendSignUpUser, setResetStatus} from "../../../redux/Main/User/actions";
import {ResetFormValidatorSchema} from "./ResetFormValidator";
import MainUser from "../../../redux/Main/User/reducer";

const ResetForm = () => {

    const { t } = useTranslation(['user','validator']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        currentUser,
    } = useAppSelector(state => ({
        currentUser: state.MainUser,
    }));

    const [formValues, setFormValues] = useState<MainUserResetType>(MainUserReset);
    const formikObject = useRef(null);

    useEffect(() => {
        if (currentUser !== undefined && currentUser.isSendReset !== undefined) {
            setDisabled(currentUser.isSendReset);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.isSendReset]);

    const onDataSubmit = (formData:MainUserResetType) => {
        let data: any = {};

        dispatch(setResetStatus(true));

        if (formData.email) {
            data["email"] = formData.email;
        }

        let params: MainUserResetType = {
            email: data.email && data.email,
        };

        dispatch(onSendResetUser(params));
    };

    return (
        <>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ResetFormValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    innerRef={formikObject}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                            <Row>
                                <div className={ "mb-3"+ (touched.email ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-email-input">E-mail</Label>
                                    <Field
                                        id="form-email-input"
                                        name="email"
                                        placeholder={t('user:enterEmail')}
                                        component={InputText}
                                        value = {getFieldProps('email').value}
                                        onChange={(value:string) => {
                                            setFieldValue('email', value);
                                        }}
                                        className={errors.email && touched.email && 'form-control is-invalid'}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.email)}
                                        </div>
                                    ) :  touched.email && !errors.email ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>

                        <div className="mt-4">
                            <Button
                                type="submit"
                                disabled={disabled}
                                color="success"
                                className="w-100 btn-success">{t('auth:sendResetLink')}</Button>
                        </div>
                    </Form>
                        )}
                </Formik>
        </>
    );
}

export default ResetForm;