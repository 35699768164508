import React, { useEffect, useState } from "react";
import {BrowserRouter, useParams} from 'react-router-dom'
import {Card, Col, Row} from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";

import { useRedux } from "../../../hooks";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import {getCurrentIdFromUrl} from "../../../helpers/functions";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {StatPeerChannelPayloadType} from "../../../redux/Stat/PeerChannel/types";
import {getStatPeerChannelActivities, setStatPeerChannelPeriod} from "../../../redux/Stat/PeerChannel/actions";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import PeerActivitySearchCard from "../PeerActivity/PeerActivitySearchCard";
import ListItem from "./ListItem";
import {getContentPeerTargetTypes} from "../../../redux/Content/PeerTargetType/actions";
import {default as RightModalTarget} from "../PeerTarget/modalForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import DatePeriodSelect from "../../../components/DatePeriodSelect";
import Loader from "../../../components/Loader";
import BreadCrumb from "../../../components/Common/BreadCrumb";

const PeerChannelActivities = () => {
    const { t } = useTranslation(['common']);
    const { uuidChannel } = useParams();
    const { dispatch, useAppSelector } = useRedux();

    const [peerChannel, setPeerChannel] = useState<ContentPeerChannelType>(null);
    const [activities, setActivities] = useState<PeerActivityType[]>([]);
    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ isActivitiesLoaded, setActivitiesLoaded ] = useState<boolean>(false);
    const [ peerChannelName, setPeerChannelName ] = useState<string>('');

    const {
        contentPeerChannels,
        statPeerChannel,
        isFetched,
        isStatPeerChannelContentFetched
    } = useAppSelector(state => ({
        contentPeerChannels: state.ContentPeerChannel,
        statPeerChannel: state.StatPeerChannelContent,
        isFetched: state.ContentPeerChannel.isFetched,
        isStatPeerChannelContentFetched: state.StatPeerChannelContent.isStatPeerChannelContentFetched
    }));

    useEffect(() => {
        dispatch(getContentPeerChannels());
        dispatch(getContentPeerTargetTypes());
    }, [dispatch]);

    useEffect(() => {
        setLoaded(isFetched);
    },[isFetched]);

    useEffect(() => {
        setActivitiesLoaded(isStatPeerChannelContentFetched);
    },[isStatPeerChannelContentFetched]);

    useEffect(() => {
        if ((peerChannel !== undefined) && (peerChannel!== null) && (peerChannel.uuid !== undefined)){

            const statPeriodPayload:StatPeerChannelPayloadType = {
                project: peerChannel.project,
                channel: peerChannel,
                limit: 100,
                order: {key : 'activeFrom', direction: 'desc'},
                periodFrom : statPeerChannel.periodFrom && statPeerChannel.periodFrom,
                periodTo: statPeerChannel.periodTo && statPeerChannel.periodTo
            }

            dispatch(getStatPeerChannelActivities(statPeriodPayload));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [peerChannel, statPeerChannel?.periodFrom, statPeerChannel?.periodTo]);

    useEffect(() => {

        (contentPeerChannels.items || []).map((channel:ContentPeerChannelType)=>{
                if (channel.uuid === uuidChannel)  {
                    setPeerChannel(channel);
                    setPeerChannelName(' - '+channel.name.substring(0, 15)+(channel.name.length>15 ? '...' : ''));
                }
                return channel;
        });

        // eslint-disable-next-line
    }, [contentPeerChannels?.items]);

    useEffect(() => {

        if ((statPeerChannel.activities !== undefined) && (statPeerChannel.activities.length>0)){
            setActivities([...statPeerChannel.activities]);
        } else {
            setActivities([]);
        }

    }, [statPeerChannel?.activities]);

    const columnsCountBreakPoints={
        350: 1,
        750: 2,
        900: 3,
        1250: 4,
        1500: 5
    };

    return (
        <>
            <BreadCrumb title={t('menu:channelStat')+peerChannelName} pageTitle={t('menu:stat')} />
                    <Row>
                        <Col xl={8}>
                            {!isLoaded &&
                            <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center" >
                                <div className="d-flex justify-content-center align-items-center calendarLoader">
                                    <Loader />
                                </div>
                            </div>
                            }
                        </Col>
                        <Col xl={4}>
                            <Card className="overflow-hidden">
                                <DatePeriodSelect onDispatch={setStatPeerChannelPeriod}/>
                            </Card>
                        </Col>
                    </Row>
            {peerChannel &&
                <>
                <Row>
                    <Col>
                        <ListItem item={peerChannel}/>
                    </Col>
                </Row>


                <Row className="pt-2 pb-4">
                    {isActivitiesLoaded ?
                        <>
                        <h5>{t('channel:channelActivities')}</h5>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={columnsCountBreakPoints}
                    >
                        <Masonry gutter={'30px'}>
                            {(activities || []).map((item:PeerActivityType, key:number)=>(
                                <PeerActivitySearchCard item={item} key={"activitiesChannel_"+key}/>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                        </>
                        :
                        <Loader />
                    }
                </Row>
                </>
                }
                <RightModalTarget/>
        </>
    );
};

export default PeerChannelActivities;


