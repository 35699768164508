import React, { useEffect, useState } from "react";
import {OrderItemType} from "../../redux/Sale/OrderItem/types";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {ProductParamValueType} from "../../redux/Sale/ProductParamValue/types";
import {ProductParamListValueType, ProductParamType, ProductParamTypes} from "../../redux/Sale/ProductParam/types";

interface InputProps {
    item: OrderItemType;
    productParams?: Array<ProductParamType>
}
const OrderItem = ({item, productParams}:InputProps) => {
    const { t, i18n } = useTranslation(['sale']);

    const [cartProductParamsValues, setCartProductParamsValues]= useState<string[]>([]);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    useEffect(()=>{

        let _cartProductParamsValues=[];

        (item?.product?.productParamValues || []).map((productParamValue:ProductParamValueType) => {

              if (productParamValue?.productParam?.isCart && productParamValue.productParam.isCart) {
                  switch (productParamValue.productParam.type) {
                      case ProductParamTypes.LIST:
                          let _productParam = (productParams || []).filter((productParam: ProductParamType)=>{
                              return productParam.uuid ===productParamValue?.productParam?.uuid
                          }).shift();

                          if (_productParam?.uuid){
                              let _productParamListValue = (_productParam?.values || []).filter((productParamListValue: ProductParamListValueType)=>{
                                  return productParamListValue.uuid === productParamValue.value
                              }).shift();

                              if (_productParamListValue?.value){
                                  _cartProductParamsValues.push(_productParamListValue.value);
                              }
                          }
                          break
                      default:
                          _cartProductParamsValues.push(productParamValue.value);
                  }
              }

              return productParamValue;
        })

        setCartProductParamsValues(_cartProductParamsValues);
    },[item, productParams]);

    return (
        <tr>
            <td>
                <div className="d-flex">
                    <div className="flex-shrink-0 avatar-md bg-light rounded d-flex align-items-center justify-content-center">
                        {item?.product?.media && (item.product.media || []).length>0 ?
                        <img
                            src={[...item.product.media].shift().url}
                            alt=""
                            className="img-fluid d-block rounded"
                        />
                            :
                            <i className="ri-camera-off-line text-muted fs-20"/>
                        }
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h5 className="fs-16">
                          {item?.name}
                            {(cartProductParamsValues || []).length>0 &&
                            <div className="text-muted fs-12 carProductProperties">
                                {
                                    (cartProductParamsValues || []).map((item:string, key:number)=>{
                                    return(<span key={'cartProductParamsValue_'+key}>{item}</span>)
                                    })
                                }
                            </div>
                            }
                        </h5>
                        <p className="text-muted mb-0">
                            {t('price:priceType')}: <span className="fw-medium">{item?.priceType?.name}</span>
                        </p>
                        {/*<p className="text-muted mb-0">*/}
                        {/*    Size: <span className="fw-medium">{props.product.size}</span>*/}
                        {/*</p>*/}
                    </div>
                </div>
            </td>
            <td className="text-end">
                <div>{item.currency.currencyFormat.replace(/#/,FormatValue(item.price))}</div>
                {item?.discount && (item.discount !== undefined && item.discount !== null && item.discount !== 0) ?
                    <div className="text-muted fs-12">{item.currency.currencyFormat.replace(/#/,FormatValue(item.discount * -1))}</div>
                    : <></>
                }
            </td>
            <td className="text-end">{item.quantity}</td>
            {/*<td>*/}
            {/*    <div className="text-warning fs-15">*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-half-fill"></i>*/}
            {/*    </div>*/}
            {/*</td>*/}
            <td className="fw-medium text-end">{item.currency.currencyFormat.replace(/#/,FormatValue(item.price*item.quantity))}</td>
        </tr>
    )
}

export default  OrderItem;