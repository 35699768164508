import {MainFileType} from "../../Main/File/types";
import {SupportTicketType} from "../Ticket/types";
import {EntityActionsType} from "../../types";
import {MainUserType} from "../../Main/User/types";

export enum SupportTicketMessageActionTypes {
  API_RESPONSE_SUCCESS = "@@supportTicketMessage/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@supportTicketMessage/API_RESPONSE_ERROR",
  GET_SUPPORT_TICKET_MESSAGES = "@@supportTicket/GET_SUPPORT_TICKET_MESSAGES",
  UPDATE_SUPPORT_TICKET_MESSAGE = "@@supportTicket/UPDATE_SUPPORT_TICKET_MESSAGE",
  EDIT_SUPPORT_TICKET_MESSAGE_MODAL_SHOW = "@@supportTicket/EDIT_SUPPORT_TICKET_MESSAGE_MODAL_SHOW",
  EDIT_SUPPORT_TICKET_MESSAGE_MODAL_HIDE = "@@supportTicket/EDIT_SUPPORT_TICKET_MESSAGE_MODAL_HIDE",
  REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_SHOW = "@@supportTicket/REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_SHOW",
  REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_HIDE = "@@supportTicket/REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_HIDE",
  REMOVE_SUPPORT_TICKET_MESSAGE = "@@supportTicket/REMOVE_SUPPORT_TICKET_MESSAGE",
  SEND_REWARD_SUPPORT_TICKET_MESSAGE = "@@supportTicket/SEND_REWARD_SUPPORT_TICKET_MESSAGE",
  GET_SUPPORT_SERVICE_TICKET_MESSAGES = "@@supportTicket/GET_SUPPORT_SERVICE_TICKET_MESSAGES",
  UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE = "@@supportTicket/UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE",
  REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE = "@@supportTicket/REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE",
  CLEAR_STATE_ITEMS= "@@supportTicket/CLEAR_STATE_ITEMS",
}

export interface SupportTicketMessageState {
  items: Array<any>;
  isLoading: boolean;
  isSending: boolean;
  selectedItem: SupportTicketMessageType;
  editShow: boolean;
  removeModalShow: boolean;
  modalEditTitle: string;
}

export interface SupportTicketMessageType{
  uuid?: string;
  enabled?: boolean;
  text?: string;
  status?: string;
  isSupportAnswer?: boolean;
  ticket?: SupportTicketType;
  media?:Array<MainFileType>;
  attachmentFiles?: any;
  dateTime?:string;
  owner?: MainUserType;
  actions?: EntityActionsType,
  score?: number;
}

export interface SupportTicketMessagePayload {
  data: SupportTicketMessageType;
  modalEditTitle : string;
}

export const TicketMessageStatus = {
  ALL: {
    label: 'All',
    class: 'info',
    status: ''
  },
  PENDING: {
    label: 'Pending',
    class: 'success',
    status: 'P'
  },
  CANCELLED: {
    label: 'Cancelled',
    class: 'danger',
    status: 'C'
  },
  NEW: {
    label: 'New',
    class: 'info',
    status: 'N'
  },
  INPROGRESS: {
    label: 'InProgress',
    class: 'secondary',
    status: 'I'
  },
  PICKUPS: {
    label: 'Pickups',
    class: 'info',
    status: 'K'
  },
  RETURNS: {
    label: 'Returns',
    class: 'primary',
    status: 'R'
  },
  DELIVERED: {
    label: 'Delivered',
    class: 'success',
    status: 'D'
  },
  FINAL: {
    label: 'Final',
    class: 'dark',
    status: 'F'
  }
}