import React from 'react';
import * as Yup from 'yup';
import {URL_REGEX} from "../../../data/settings";

export const EventWebhookValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    order: Yup.string()
        .matches(/^[0-9]+$/, "digitsOnly")
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    url: Yup.string()
        .min(2, 'tooShort')
        .max(500, 'tooLong')
        .matches(URL_REGEX, "invalidUrl")
        .required('required'),
});