import React from 'react';
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import DetailViewItem from "./DetailViewItem";

interface ItemProps {
    item: PeerActivityType;
}

const PeerActivitySearchCard = ({ item }: ItemProps) => {

    return (
        <div style={{maxWidth: '300px', minWidth:'240px'}}>
            <DetailViewItem item={item}/>
        </div>
    );
}

export default PeerActivitySearchCard;