import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TableList from "../TableList";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {OrderStatusTypes} from "../../redux/Sale/OrderStatus/types";
import {StatOrderTileType} from "../../redux/Stat/DashboardSale/types";
import * as route from "../../api/routes";
import SaleTableChannelsColumns from "./SaleTableChannelsColumns";
import {CurrencyType} from "../../redux/Sale/Currency/types";
import {ContentPeerChannelType} from "../../redux/Content/PeerChannel/types";
import {OrderType} from "../../redux/Sale/Order/types";
import SaleOrderColumns from "../../blocks/Sale/Order/tableColumns";
import {EntityActionsHandlersType} from "../../redux/types";


interface TableProps {
    items: [] | Array<OrderType>;
}

const SaleTableOrders= ({ items }: TableProps) => {

    const { t } = useTranslation();


    const onTableRowClick = (item:OrderType) => {
        window.location.href = route.SALE_ORDER_DETAIL.replace(/:uuid/g, item.uuid);
    }

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onDetail: onTableRowClick
    }

    const columns = SaleOrderColumns(EntityActionsHandlers);

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('sale:recentOrders')}</h4>
            </CardHeader>
            <CardBody>

                <TableList
                    columns={columns}
                    data={items}
                    key={'saleRecentOrders_'+Math.round(Math.random() * (99999 - 11111) + 11111)}
                    onRowClick={onTableRowClick}
                />

            </CardBody>
        </Card>
    );
}

export default SaleTableOrders;