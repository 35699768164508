import {EntityActionsType} from "../../types";
import {EventSubscribeUserType} from "../../Event/EventSubscribeUser/types";
import {MenuItem} from "../../../layouts/LayoutMenuData";
import {MainRoleType} from "../Role/types";
import {MainCabinetType} from "../Cabinet/types";
import {MainMetaType} from "../common";

export enum MainUserActionTypes {
  API_RESPONSE_SUCCESS = "@@mainUser/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainUser/API_RESPONSE_ERROR",
  GET_MAIN_USERS = "@@mainUser/GET_MAIN_USERS",
  GET_MAIN_CURRENT_USER = "@@mainUser/GET_MAIN_CURRENT_USER",
  GET_MAIN_CURRENT_USER_PROFILE = "@@mainUser/GET_MAIN_CURRENT_USER_PROFILE",
  UPDATE_MAIN_USER = "@@mainUser/UPDATE_MAIN_USER",
  UPDATE_MAIN_USER_AVATAR = "@@mainUser/UPDATE_MAIN_USER_AVATAR",
  MAIN_USER_CHANGE_PASSWORD = "@@mainUser/MAIN_USER_CHANGE_PASSWORD",

  ADD_MAIN_USER = "@@mainUser/ADD_MAIN_USER",
  REMOVE_MAIN_USER = "@@mainUser/REMOVE_MAIN_USER",
  EDIT_MAIN_USER = "@@mainUser/EDIT_MAIN_USER",

  REMOVE_MAIN_USER_MODAL_SHOW = "@@mainUser/REMOVE_MAIN_USER_MODAL_SHOW",
  REMOVE_MAIN_USER_MODAL_HIDE = "@@mainUser/REMOVE_MAIN_USER_MODAL_HIDE",

  RIGHT_MODAL_SHOW = "@@mainUser/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@mainUser/RIGHT_MODAL_HIDE",

  SIGNUP_COMPLETE = "@@mainUser/SIGNUP_COMPLETE",
  SIGNUP_USER = "@@mainUser/SIGNUP_USER",
  SIGNUP_RESEND_CONFIRMATION_EMAIL = "@@mainUser/SIGNUP_RESEND_CONFIRMATION_EMAIL",
  RESET_USER = "@@mainUser/RESET_USER",
  CHANGE_PASSWORD_USER = "@@mainUser/CHANGE_PASSWORD_USER",
  CHANGE_EMAIL_USER = "@@mainUser/CHANGE_EMAIL_USER",
  SEND_SIGNUP_STATUS = "@@mainUser/SEND_SIGNUP_STATUS",
  SEND_RESET_STATUS = "@@mainUser/SEND_RESET_STATUS",
  SEND_CHANGE_PASSWORD_STATUS = "@@mainUser/SEND_CHANGE_PASSWORD_STATUS",
  SEND_SET_USER_LANGUAGE = "@@mainUser/SEND_SET_USER_LANGUAGE",
  GET_MAIN_CONTACTS = "@@mainUser/GET_MAIN_CONTACTS",
  GET_CABINET_CONTACTS = "@@mainUser/GET_CABINET_CONTACTS",

  CLEAR_UPDATE_CABINET_SETTINGS = "@@mainUser/CLEAR_UPDATE_CABINET_SETTINGS",
}

export interface MainUserState extends MainMetaType{
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: MainUserType;
  modalEditTitle: string;
  currentUser?: MainUserType;
  isResendConfirmationEmail?:boolean;
  isSignUpComplete?:boolean;
  isSendSignUp?:boolean;
  isResetComplete?:boolean;
  isSendReset?: boolean;
  isSendChangeEmail?: boolean;
  isChangeEmailComplete?: boolean;
  isSending: boolean;
  error: string;
  contacts: Array<MainUserType>,
  isFetched: boolean;
  isLoading: boolean;
  isNeedReload: boolean;
  isNeedUpdateCabinetSettings: boolean;
}


export interface MainUserType{
  uuid?: string;
  enabled?: boolean;
  email: string;
  name: string;
  phone?: string;
  avatar?: string;
  confirmationToken?: string;
  description?: string;
  position?: string;
  language?: string;
  lastLogin?: number;
  roles?: Array<MainRoleType>;
  actions?: EntityActionsType;
  uploadAvatar?: any
  subscriptions?: Array<EventSubscribeUserType>
  permissions?: MainUserPermissionType
}

export interface MainUserPasswordChangeType{
  oldPassword?: string;
  password?: string;
  confirmPassword?: string;
  token?: string;
}

export interface MainUserPayload {
  data: Array<any>;
  modalEditTitle : string;
  cabinet?: MainCabinetType;
}


export interface MainUserPermissionType{
  permittedAreas?: Array<MenuItem>;
}

export interface MainUserSignUpType extends MainUserResetType, MainUserPasswordChangeType{
  language?: string;
  partnerCode?: string;
}

export interface MainUserResetType{
  email: string;
}

export interface MainCabinetUserType{
  uuid?: string;
  lastLogin?: number;
  roles?: Array<MainRoleType>;
  actions?: EntityActionsType;
  subscriptions?: Array<EventSubscribeUserType>
  permissions?: MainUserPermissionType
}

export interface MainUserBasicDetailsType{
  uuid?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  cabinet?: any;
  email: string;
  phone?: string;
  coverImage?: string;
  avatar?: string;
  profile?: string;
}

export interface MainContactsPayload {
    uuid?: Array<string>
}