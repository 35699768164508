// types
import {PaymentSystemActionTypes, PaymentSystemState} from "./types";

export const INIT_STATE_PAYMENT_SYSTEM: PaymentSystemState = {
  items: [],
  isFetched: false,
  isLoading: false
};

const PaymentSystem = (state = INIT_STATE_PAYMENT_SYSTEM, action: any) => {
  switch (action.type) {
    case PaymentSystemActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case PaymentSystemActionTypes.GET_PAYMENT_SYSTEMS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case PaymentSystemActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case PaymentSystemActionTypes.GET_PAYMENT_SYSTEMS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case PaymentSystemActionTypes.GET_PAYMENT_SYSTEMS:
      return {
        ...state,
        isFetched: false,
        isLoading: true,
      };
    default:
      return { ...state };
  }
};

export default PaymentSystem;
