import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import * as route from "../../../api/routes";
import '../../../i18/config';
import {useTranslation} from "react-i18next";

const ChannelItemAdd = () => {

    const { t } = useTranslation(['common']);

    return(
        <>
            <a href={route.CONTENT_CHANNELS_LIST} className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={t('common:addNew')}>
                <OverlayTrigger
                    placement={'right'}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip>
                            {t('common:addNew')}
                        </Tooltip>
                    }
                >
                    <div className="avatar-xxs">
                        <div className="avatar-title rounded-circle bg-light border-dashed border text-primary fs-16 "> +
                        </div>
                    </div>
                </OverlayTrigger>
            </a>
        </>
    )
}

export default ChannelItemAdd;