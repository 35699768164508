import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem, DropdownMenu, DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    UncontrolledButtonDropdown
} from 'reactstrap';
import classnames from "classnames";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import { useRedux } from "../../../hooks";

import {OrderStatus, OrderType} from "../../../redux/Sale/Order/types";
import {
    getSaleOrders, groupRemoveOrdersModalShow,
    onRemoveSaleOrder,
    removeSaleOrderModalHide,
    removeSaleOrderModalShow
} from "../../../redux/Sale/Order/actions";
import Loader from "../../../components/Loader"
import * as route from "../../../api/routes";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {getHistoryHash, getIsNeedRefresh, isNumeric, removeIsNeedRefresh} from "../../../helpers/functions";
import SaleOrderColumns from "./tableColumns";
import {EntityActionsHandlersType} from "../../../redux/types";
import TableList from "../../../components/TableList";
import {setHistoryHash as setHistoryHashFunc} from "../../../helpers/functions";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import UpgradeFarePage from "../../../components/UpgradeFarePage";
import {ProductType} from "../../../redux/Sale/Product/types";
import {MainMetaType} from "../../../redux/Main/common";
import MainListFilter from "../../../components/MainListFilter";
import {MenuItem} from "../../../layouts/LayoutMenuData";
import {readEventNotifications} from "../../../redux/Event/Notification/actions";
import {getEventUserBadges} from "../../../redux/Event/Badge/actions";
import {EventTypeCodes} from "../../../redux/Event/EventType/types";
import RemoveModal from "../../../components/RemoveForm";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {getSaleCurrencies} from "../../../redux/Sale/Currency/actions";
import DeleteProductsModal from "../Product/DeleteProductsModal";
import DeleteOrdersModal from "./DeleteOrdersModal";

const SaleOrderIndex = () => {

    const { t, i18n } = useTranslation(['common']);

    const componentType = 'saleOrderItemsType';
    const newOrderTab = 'newOrderTab';
    const finalOrderTab = 'finalOrderTab';
    const cancelledOrderTab = 'cancelledOrderTab';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [selectedItems, setSelectedItems] = useState<OrderType[]>([]);
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [searchPhrase, setSearchPhrase] = useState<string>(null);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults && historyHash.maxResults,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection,
            searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase
        }

        if (historyHash?.tab){
            switch (historyHash?.tab) {
                case newOrderTab:
                    mainMeta={
                        ...mainMeta,
                        status: OrderStatus.NEW.status
                    }
                    break;
                case finalOrderTab:
                    mainMeta={
                        ...mainMeta,
                        status: OrderStatus.FINAL.status
                    }
                    break;
                case cancelledOrderTab:
                    mainMeta={
                        ...mainMeta,
                        status: OrderStatus.CANCELLED.status
                    }
                    break;
            }
        }

        dispatch(getSaleOrders(mainMeta));
    }

    useEffect(() => {
        if (historyHash.tab !== undefined && historyHash.tab.length>0 && activeTab !== historyHash.tab){
            toggleTab(historyHash.tab);
        }
        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        loadItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { dispatch, useAppSelector } = useRedux();

    const [activeTab, setActiveTab] = useState("All");

    const {
        saleOrder,
        isFetched,
        salePlan,
        eventUserBadge,
        saleCurrency
    } = useAppSelector(state => ({
        saleOrder: state.SaleOrder,
        isFetched: state.SaleOrder.isFetched,
        salePlan: state.SalePlan,
        eventUserBadge: state.EventUserBadge,
        saleCurrency: state.SaleCurrency
    }));

    const [orders, setOrders] = useState<OrderType[]>([]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_PRODUCTS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }
    useEffect(() => {
        dispatch(getSaleCurrencies({maxResults: 1000}));
    }, [dispatch]);


    useEffect(() => {
        if ((saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleCurrency?.items]);

    useEffect(() => {
        if ((saleOrder.items !== undefined) && (saleOrder.items.length>0)){
            setOrders([...saleOrder.items]);

        } else {
            setOrders([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleOrder?.items])

    useEffect(() => {

        if (((eventUserBadge?.cabinetItems || []).filter((item)=>{return item.type===MenuItem.SALE_ORDERS}).pop()?.count || 0 )>0){

            setTimeout(() => {
                dispatch(readEventNotifications({code:EventTypeCodes.SALE_ORDER_ADDED}));
                setTimeout(() => {
                    dispatch(getEventUserBadges());
                }, 1000);
            }, 1000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventUserBadge?.cabinetItems])


    useEffect(() => {
        if (saleOrder.isNeedReload){
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saleOrder.isNeedReload]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleClickTab = (tab) => {
        if (activeTab !== tab) {
            if ( historyHash.tab !== tab) {
                historyHash.tab = tab;
                historyHash.page = 1;
                setHistoryHashFunc(historyHash);
            }
        }
    }

    const onTableRowClick = (item:OrderType) => {
        window.location.href = route.SALE_ORDER_DETAIL.replace(/:uuid/g, item.uuid);
    }

    const handleRemove = (item:OrderType) =>{
        dispatch(removeSaleOrderModalShow({data:Object(item), modalEditTitle:'deleteOrder'}));
    }

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onDetail: onTableRowClick,
        onRemove: handleRemove,
    }

    const columns = SaleOrderColumns(EntityActionsHandlers);

    const handleGroupActionSelectItems = (items:any[]) => {
        setSelectedItems(items);
    }

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }


    const handleSearchClick = (value:string) => {
        setSearchPhrase(value);
        if ( historyHash.searchPhrase !== value) {
            historyHash.searchPhrase = value;
            setHistoryHashFunc(historyHash);
        }
    }

    const OrderHideRemoveModal = (dispatch:any) => {
        dispatch(removeSaleOrderModalHide())
    }

    const OrderOnRemove = (event:any, dispatch:any) => {

        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(onRemoveSaleOrder({data:event, modalEditTitle: 'deleteOrder'}));
        }
    }

    const handleNewOrder = (currency: CurrencyType) => {
        let uuid='new_'+currency.uuid;
        window.location.href=route.SALE_ORDER_DETAIL.replace(/:uuid/,uuid);
    }

    const handleRemoveGroupAction = () =>{
        dispatch(groupRemoveOrdersModalShow());
    }

    if (getIsNeedRefresh()){
        removeIsNeedRefresh();
        loadItems();
    }

    return (
        <>
            <BreadCrumb title={t('menu:orders')} pageTitle={t('menu:main')} />

            { upgradeFare && isFetched ?
                <>
                    <div className="d-flex flex-row flex-grow-1">
                        <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionOrdersText')}
                        </div>
                    </div>
                    <UpgradeFarePage/>
                </>
                :
<>
            <Row>
                <Col lg={12}>
                    <Card id="orderList">
                        <CardHeader className="card-header border-0">
                            <Row className="align-items-center gy-3">
                                <div className="col-sm">
                                    <div className="d-flex flex-shrink-0">
                                        <UncontrolledButtonDropdown id="btnGroupVerticalAction">
                                            <DropdownToggle
                                                caret
                                                className={"btn-md btn-success"}
                                                disabled={(currencies ||[]).length<1}
                                            >
                                                <i className="ri-add-line align-bottom me-1 font-weight-normal"/>
                                                {t('common:addNew')}{" "}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {(currencies ||[]).map((currency:CurrencyType, num: number)=>{
                                                    return (
                                                        <DropdownItem
                                                            key={'orderCurrencyAdd_'+num}
                                                            onClick={()=>{handleNewOrder(currency)}}>{currency.name}
                                                        </DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                                <div className="col-sm text-end">
                                        <button type="button" className="btn btn-soft-primary me-2"><i
                                            className="ri-download-cloud-2-line align-bottom me-1"/> Import
                                        </button>
                                        <button type="button" className="btn btn-soft-primary"><i
                                            className="ri-upload-cloud-2-line align-bottom me-1"/> Export
                                        </button>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                            <MainListFilter
                                onClick={handleSearchClick}
                                value={searchPhrase}
                                onChange={onSearchInput}
                            />
                        </CardBody>
                    <CardBody className="pt-0">
                        <div>
                            <Nav
                                className="nav-tabs nav-tabs-custom nav-success"
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === "All" },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab( "All");
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-store-2-fill me-1 align-bottom font-weight-normal"></i>{" "}
                                        {t('sale:allOrders')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === newOrderTab },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab(newOrderTab);
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-checkbox-circle-line me-1 align-bottom font-weight-normal"></i>{" "}
                                        {t('sale:newOrders')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === finalOrderTab },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab(finalOrderTab);
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-checkbox-circle-line me-1 align-bottom font-weight-normal"></i>{" "}
                                        {t('sale:paidOrders')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === cancelledOrderTab },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab(cancelledOrderTab);
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-close-circle-line me-1 align-bottom font-weight-normal"></i>{" "}
                                        {t('sale:cancelledOrders')}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end pt-1 pb-1">
                                    <div className="d-flex flex-shrink-0">
                                        {((selectedItems && (selectedItems.length>0)) || (historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true)) &&
                                            <UncontrolledButtonDropdown id="btnGroupVerticalAction">
                                                <DropdownToggle
                                                    caret
                                                    className={"btn-sm btn-soft-primary"}
                                                    disabled={!(selectedItems && (selectedItems.length>0)) && !(historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true)}
                                                >
                                                    <i className="ri-checkbox-multiple-line align-bottom me-1 font-weight-normal"/>
                                                    {t('common:groupAction')}{" "}(
                                                    {historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true
                                                        ?
                                                        saleOrder?.meta?.totalCount > 0 ? saleOrder?.meta?.totalCount : ''
                                                        :
                                                        selectedItems.length
                                                    })
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={handleRemoveGroupAction}>{t('common:delete')}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        }
                                    </div>

                                </NavItem>
                            </Nav>
                            {isFetched ?
                                    <TableList
                                        columns={columns}
                                        data={orders || []}
                                        onRowClick={onTableRowClick}
                                        key={componentType}
                                        historyHash = {historyHash}
                                        selectableRows = {true}
                                        handleGroupActionSelectItems = {handleGroupActionSelectItems}
                                        paginationTotalRows={saleOrder?.meta?.totalCount || 0}
                                        paginationServer={true}
                                        isShowChangePageSize={true}
                                    />
                                :
                                <Loader />
                            }
                        </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
                <RemoveModal
                onRemove={OrderOnRemove}
                handleClose={OrderHideRemoveModal}
                removeItem={saleOrder.current}
                isOpen={saleOrder.removeModalShow}
                modalTitle={saleOrder.modalEditTitle}/>
                <DeleteOrdersModal
                    orders={selectedItems}
                    historyHash={historyHash}
                    isOpen={saleOrder.isModalGroupRemoveOrdersShow}
                />
            </>
            }
        </>
    );
};

export default SaleOrderIndex;


