import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from "prop-types";
import withRouter from "../components/WithRouter";
import { Container } from 'reactstrap';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';


//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarImageType,
    getContentProjects,
    getEventNotifications,
    getSaleCurrentPlan,
    getUserCabinets,
    getEventUserBadges,
    getSupportUserBadges,
    getSupportServiceBadges,
    getCabinetContacts,
    getUserDevices,
    clearUpdateCabinetSettings,
    setSettingsValues,
    getUserCabinetFromRemote,
    clearLayoutUpdateCabinetSettings, saveCurrentCabinetSettings
} from "../redux/actions";

//redux
import {useRedux} from "../hooks";
import {ToastContainer} from "react-toastify";
import * as route from "../api/routes";
import RightSidebar from "../blocks/Main/Settings/RightSidebar";
import MessengerIndexRightBar from "../blocks/Messenger/indexRightBar";
import RightSecondaryBar from "./RightSecondaryBar";
import RightModal from "../blocks/AI/TextGenerate/modalForm";
import {default as RightModalTarget} from "../blocks/Content/PeerTarget/modalForm";
import {default as RightModalTemplate} from "../blocks/Content/Template/modalForm";
import {default as RightModalPeerActivity} from "../blocks/Content/PeerActivity/PeerActivityModal";
import {default as RightModalTask} from "../blocks/Task/Task/modalForm";
import {layoutTypes} from "../constants/Layout";
import ApproveDeviceModal from "../components/User/ApproveDeviceForm";
import {handleDeviceApproveAction, handleDeviceRightModalHide} from "../blocks/Main/Device/DeviceActions";
import {getCurrentSettings, setCurrentSettings} from "../helpers/functions";

const Layout = (props) => {

    const {  dispatch, useAppSelector } = useRedux();

    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        isSupportServiceUser,
        mainDevice,
        isSendingMainDevice,
        mainUser,
        layout,
        cabinetState
    } = useAppSelector(state => ({
        layoutType: state.Layout.layoutType,
        leftSidebarType: state.Layout.leftSidebarType,
        layoutModeType: state.Layout.layoutModeType,
        layoutWidthType: state.Layout.layoutWidthType,
        layoutPositionType: state.Layout.layoutPositionType,
        topbarThemeType: state.Layout.topbarThemeType,
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        leftSidebarViewType: state.Layout.leftSidebarViewType,
        leftSidebarImageType: state.Layout.leftSidebarImageType,
        isSupportServiceUser: state.Login.isSupportService,
        mainDevice: state.MainDevice,
        isSendingMainDevice: state.MainDevice.isSending,
        mainUser: state.MainUser,
        layout: state.Layout,
        cabinetState: state.MainCabinet
    }));

    useEffect(()=>{

        if (layout?.isNeedUpdateCabinetSettings){
            dispatch(saveCurrentCabinetSettings(layout));
            dispatch(clearLayoutUpdateCabinetSettings());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[layout?.isNeedUpdateCabinetSettings])

    useEffect(()=>{

        if (mainUser?.isNeedUpdateCabinetSettings){
            dispatch(getUserCabinetFromRemote());
            dispatch(clearUpdateCabinetSettings());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mainUser?.isNeedUpdateCabinetSettings])

    useEffect(()=>{

        if (cabinetState?.currentCabinet?.settings !== undefined && cabinetState?.currentCabinet?.settings !== null){
            if (layout?.isNeedUpdateCabinetSettings!==true) {
                dispatch(setSettingsValues(cabinetState.currentCabinet.settings));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cabinetState?.currentCabinet])



    /*
    layout settings
    */
    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType
            ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType
            ) {
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebarTheme(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarImageType(leftSidebarImageType))
        }
    }, [
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        dispatch]);
    /*
    call dark/light mode
    */
    const onChangeLayoutMode = (value) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    const [headerClass, setHeaderClass] = useState("");
    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });
    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    useEffect(() => {

        let windowSize = document.documentElement.clientWidth;

        if ((windowSize < 767) && (layoutType===layoutTypes.TWOCOLUMN)){
            dispatch(changeLayout(layoutTypes.VERTICAL));
        }

        dispatch(getUserDevices());
        dispatch(getUserCabinets());
        dispatch(getContentProjects());
        dispatch(getEventNotifications({maxResults:10}));
        dispatch(getSaleCurrentPlan());
        dispatch(getEventUserBadges());
        dispatch(getSupportUserBadges());
        dispatch(getCabinetContacts({}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const pathname = window.location.pathname;

    useLayoutEffect(() => {

        if (layoutType===layoutTypes.TWOCOLUMN) {
            if (
                pathname.match(/^\/support/)
                ||
                pathname.match(/^\/funnel/)
            ) {
                document.body.classList.add('twocolumn-panel');
            }

        }
    }, [pathname, layoutType]);


    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header
                    headerClass={headerClass}
                    layoutModeType={layoutModeType}
                    onChangeLayoutMode={onChangeLayoutMode} />
                <Sidebar layoutType={layoutType} />
                <div className="main-content">
                    <div className={"page-content"+(pathname === route.MESSENGER_MESSENGER?' pageContentMessenger':'')}>
                        <div className="pageContentWrapper">
                        <Container fluid className={"pageContentContainer"+(pathname === route.MESSENGER_MESSENGER?' p-1':'')}>
                            {props.children}
                        </Container>
                        <RightSecondaryBar/>
                        </div>
                        {pathname !== route.MESSENGER_MESSENGER &&
                        <MessengerIndexRightBar/>
                        }
                    </div>
                    <Footer />
                </div>

            </div>
            <RightModal/>
            <RightModalPeerActivity/>
            <RightModalTarget/>
            <RightModalTemplate/>
            <RightSidebar/>
            <RightModalTask/>
            <ToastContainer autoClose={2000} />


            {mainDevice?.current?.fingerPrint && mainDevice?.current?.fingerPrint.length>0 &&
            <ApproveDeviceModal
                onUpdate={()=>{
                    handleDeviceApproveAction(mainDevice.current, dispatch);
                }}
                handleClose={
                    ()=>{
                        handleDeviceRightModalHide(dispatch);
                    }
                }
                item={mainDevice.current}
                isOpen={mainDevice.rightModalShow}
                modalTitle={mainDevice.modalEditTitle}
                isSending={isSendingMainDevice}
            />
            }
        </React.Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.object,
};

export default withRouter(Layout);
