import React, {useEffect, useState, useCallback} from 'react';
import {Button, Col, Input, Label} from "reactstrap";
import { Link } from 'react-router-dom'

import '../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../api/routes";
import {useRedux} from "../hooks";
import {logoutUser, setChangeEmailComplete} from "../redux/Main/Login/actions";
import {onResendConfirmationEmail} from "../redux/Main/User/actions";
import ChangeEmailForm from "../blocks/Main/Auth/ChangeEmailForm";

const SignInDisabled = () => {

    const { t } = useTranslation(['auth']);

    const { dispatch, useAppSelector } = useRedux();

    const [isNeedEmailConfirmation, setNeedEmailConfirmation] = useState<boolean>(false);
    const [isResendEmailConfirmation, setResendEmailConfirmation] = useState<boolean>(false);
    const [isNeedEmailChange, setNeedEmailChange] = useState<boolean>(false);

    const {
        loginState,
        currentUser
    } = useAppSelector(state => ({
        loginState: state.Login,
        currentUser: state.MainUser,
    }));

    useEffect(()=>{
        if (loginState?.change?.email && loginState.change.email === true) {
            setNeedEmailChange(true);
        } else {
            setNeedEmailChange(false);
        }

        if (loginState?.confirm?.email && loginState.confirm.email === false) {
            setNeedEmailConfirmation(true);
        } else {
            setNeedEmailConfirmation(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loginState?.confirm?.email,loginState?.change?.email])

    useEffect(()=>{

        if (currentUser?.isChangeEmailComplete && currentUser.isChangeEmailComplete===true){
            dispatch(setChangeEmailComplete());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentUser?.isChangeEmailComplete])

    const handleResendConfirmationEmail = useCallback(() => {
        setResendEmailConfirmation(true);
        dispatch(onResendConfirmationEmail());
    }, [dispatch]);

    const handleSignOut = useCallback(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    return (
        <>
                    <div className="p-lg-5 p-4 text-center">
                        <div className="avatar-lg mx-auto mt-2">
                            <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                                {isNeedEmailConfirmation === false ?
                                    <i className="ri-mail-close-fill"/>
                                    :
                                    <i className="ri-close-circle-fill"/>
                                }
                            </div>
                        </div>
                        <div className="mt-4 pt-2">
                            <h4>{t('auth:yourAccountSuspended')}!</h4>
                            {isNeedEmailConfirmation === false ?
                                (isResendEmailConfirmation === false ?
                                    isNeedEmailChange === true ?
                                        <>
                                            <p className="text-muted mx-4">You have to change your e-mail address</p>
                                            <ChangeEmailForm/>
                                        </>
                                        :
                                    <>
                                        <p className="text-muted mx-4">{t('auth:confirmYourEmailAddress')}</p>
                                    </>
                                    :
                                    <p className="text-muted mx-4">{t('auth:checkYourMailbox')}</p>
                                )
                                :
                                <p className="text-muted mx-4">{t('auth:contactReactivateAccount')}</p>
                            }
                            <div className="mt-4 d-flex d-flex flex-row">
                                { isNeedEmailChange === false &&
                                    (isNeedEmailConfirmation === false ?
                                    <>
                                    <Button to="#"  className="btn btn-primary w-100 m-1" onClick={handleSignOut}>{t('auth:signOut')}</Button>
                                    <Button to="#"  className="btn btn-primary w-100 m-1" onClick={handleResendConfirmationEmail} disabled={isResendEmailConfirmation}>{t('auth:sendAgain')}</Button>
                                    </>
                                    :
                                    <Link to={route.MAIN_AUTH_LOGIN}  className="btn btn-success w-100">{t('auth:backToLogin')}</Link>
                                    )
                                }
                            </div>
                        </div>
                    </div>

        </>
    );
};

export default SignInDisabled;