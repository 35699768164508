import React from "react";
import {Link} from "react-router-dom";
import * as route from "../../../api/routes";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {CustomerType} from "../../../redux/Sale/Customer/types";

interface ItemProps {
    item: CustomerType;
}
const CustomerItemLine = ({ item }: ItemProps) => {

    const { t } = useTranslation();

    return(
        <>
            <div className="d-flex align-items-center mt-2 mb-2">
                <div className="flex-shrink-0 me-3">
                    <Link to={route.SALE_CUSTOMERS_LIST.replace(/:uuid/g, item.uuid)} className="fw-medium link-primary">
                        <div className="d-flex avatar-sm bg-light rounded p-1 align-items-center justify-content-center">
                            {/*{(item.media && (item.media ||[]).length>0) ?*/}
                            {/*    <img*/}
                            {/*        src={[...item.media].shift().url} alt="" className="img-fluid d-block rounded"/>*/}
                            {/*    :*/}
                                <i className="ri-camera-off-line text-muted fs-18"/>
                            {/*}*/}
                        </div>
                    </Link>
                </div>
                <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1 text-truncate">
                        <Link to={route.SALE_CUSTOMERS_LIST.replace(/:uuid/g, item.uuid)} className="fw-medium link-primary">
                            {item.name}
                        </Link>
                    </h5>
                </div>
            </div>
        </>
    )
}

export default CustomerItemLine;