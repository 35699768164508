// Layout Type
import {
    layoutModeTypes,
    layoutPositionTypes,
    layoutTypes,
    layoutWidthTypes, leftsidbarSizeTypes, leftSidebarImageTypes,
    leftSidebarTypes, leftSidebarViewTypes, topbarThemeTypes
} from "../../../components/Constants";

export enum LayoutActionTypes {
    CHANGE_LAYOUT = "@@main/layout/CHANGE_LAYOUT",
    CHANGE_SIDEBAR_THEME = "@@main/layout/CHANGE_SIDEBAR_THEME",
    CHANGE_LAYOUT_MODE = "@@main/layout/CHANGE_LAYOUT_MODE",
    CHANGE_LAYOUT_WIDTH = "@@main/layout/CHANGE_LAYOUT_WIDTH",
    CHANGE_LAYOUT_POSITION = "@@main/layout/CHANGE_LAYOUT_POSITION",
    CHANGE_TOPBAR_THEME = "@@main/layout/CHANGE_TOPBAR_THEME",
    CHANGE_SIDEBAR_SIZE_TYPE = "@@main/layout/CHANGE_SIDEBAR_SIZE_TYPE",
    CHANGE_SIDEBAR_VIEW = "@@main/layout/CHANGE_SIDEBAR_VIEW",
    CHANGE_SIDEBAR_IMAGE_TYPE = "@@main/layout/CHANGE_SIDEBAR_IMAGE_TYPE",
    CHANGE_DEFAULT_DASHBOARD = "@@main/layout/CHANGE_DEFAULT_DASHBOARD",
    CHANGE_USER_NAME = "@@main/layout/CHANGE_USER_NAME",
    CHANGE_USER_DESCRIPTION = "@@main/layout/CHANGE_USER_DESCRIPTION",
    RESET_VALUE = "@@main/layout/RESET_VALUE",
    SAVE_VALUES = "@@main/layout/SAVE_VALUES",
    SET_VALUES = "@@main/layout/SET_VALUES",
    TOGGLE_OPEN = "@@main/layout/TOGGLE_OPEN",
    CLEAR_UPDATE_CABINET_SETTINGS = "@@main/layout/CLEAR_UPDATE_CABINET_SETTINGS",
}

export interface MainSettingsState {
    layoutType: string,
    leftSidebarType: string,
    layoutModeType: string,
    layoutWidthType: string,
    layoutPositionType: string,
    topbarThemeType: string,
    leftsidbarSizeType: string,
    leftSidebarViewType: string,
    leftSidebarImageType: string,
    defaultDashboard?: string,
    userName?:string,
    userDescription?:string,
    position?:string,
    isOpen: boolean;
    isNeedUpdateCabinetSettings: boolean;
}

