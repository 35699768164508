import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {
  getContentConnectorsByType as getContentConnectorsByTypeApi,
  sendAITextGenerateRequest as sendAITextGenerateRequestApi,
  getContentPeerChannelByIdPeer as getContentPeerChannelByIdPeerApi
} from "../../../api";

import {AITextGenerateActionTypes} from "./types";
import {aiTextGenerateApiResponseError, aiTextGenerateApiResponseSuccess} from "./actions";

function* getActiveConnectorsAITextGenerate({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getContentConnectorsByTypeApi,data);
    yield put(aiTextGenerateApiResponseSuccess(AITextGenerateActionTypes.GET_ACTIVE_CONNECTORS, response));
  } catch (error: any) {
    yield put(aiTextGenerateApiResponseError(AITextGenerateActionTypes.GET_ACTIVE_CONNECTORS, error));
  }
}

function* getIdeasPeerChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getContentPeerChannelByIdPeerApi,data);
    yield put(aiTextGenerateApiResponseSuccess(AITextGenerateActionTypes.GET_IDEAS_CHANNEL, response));
  } catch (error: any) {
    yield put(aiTextGenerateApiResponseError(AITextGenerateActionTypes.GET_IDEAS_CHANNEL, error));
  }
}

function* sendAITextGenerateRequest({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendAITextGenerateRequestApi,data);
    yield put(aiTextGenerateApiResponseSuccess(AITextGenerateActionTypes.SEND_TEXT_GENERATE_REQUEST, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(aiTextGenerateApiResponseError(AITextGenerateActionTypes.SEND_TEXT_GENERATE_REQUEST, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetActiveConnectorsAITextGenerate() {
  yield takeEvery(AITextGenerateActionTypes.GET_ACTIVE_CONNECTORS, getActiveConnectorsAITextGenerate);
}
export function* watchOnSendAITextGenerateRequest() {
  yield takeEvery(AITextGenerateActionTypes.SEND_TEXT_GENERATE_REQUEST, sendAITextGenerateRequest);
}
export function* watchOnGetIdeasPeerChannel() {
  yield takeEvery(AITextGenerateActionTypes.GET_IDEAS_CHANNEL, getIdeasPeerChannel);
}

function* aiTextGenerateSaga() {
  yield all([
    fork(watchGetActiveConnectorsAITextGenerate),
    fork(watchOnSendAITextGenerateRequest),
    fork(watchOnGetIdeasPeerChannel)
  ]);
}

export default aiTextGenerateSaga;
