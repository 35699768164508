import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import '../../i18/config';
import {useTranslation} from "react-i18next";

import {useProfile} from "../../hooks/UserHooks";
import {useRedux} from "../../hooks";

import {changeUserAccount, logoutUser} from "../../redux/Main/Login/actions";
import * as route from "../../api/routes";
import {toggleOpenSettings} from "../../redux/Main/Layout/actions";

const ProfileDropdown = () => {

    const { t } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    const { userProfile } = useProfile();

    const [userName, setUserName] = useState(userProfile.fullName);
    const [userAvatar, setUserAvatar] = useState(userProfile.avatar);

    const {
        mainUser,
        mainLogin,
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
        mainLogin: state.Login
    }));

    const [isOnline, setIsOnline] = useState<boolean>(mainLogin.isWSConnected);

    useEffect(() => {
        setIsOnline(mainLogin.isWSConnected);
    },[mainLogin?.isWSConnected]);

    useEffect(() => {

        setUserName(userProfile.fullName);
        setUserAvatar(userProfile.avatar);

    }, [userProfile.uuid, userProfile.fullName, userProfile.profile, userProfile.avatar]);

    // useEffect(() => {
    //
    //     if (mainUser.currentUser !== undefined) {
    //         setUserName(mainUser.currentUser.name);
    //         setUserAvatar(mainUser.currentUser.avatar);
    //     }
    //
    // }, [dispatch, mainUser]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const handlerLogOut = () => {
        dispatch(logoutUser());
    }

    const toggleSettingsDropdown = () => {
        dispatch(toggleOpenSettings());
    };

    const handlerChangeAccount = () => {
        dispatch(changeUserAccount());
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <div className={"flex-shrink-0 chat-user-img user-own-img align-self-center ms-0 "+(isOnline ? 'online' : 'offline')}>
                                                {userAvatar ?
                                                    <img className="rounded-circle header-profile-user" src={userAvatar} alt={userName}/>
                                                    :
                                                    <i className="bx bx-user-circle text-primary fs-23"/>
                                                }
                            <span className="user-status"></span>
                        </div>
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text text-truncate">
                                {userName!==undefined && userName!==null &&
                                    <>
                                        {userName.substring(0, 20)}{userName.length >= 20 && '...'}
                                    </>
                                }
                            </span>

                            { isOnline ?
                                <span className="d-none d-xl-block ms-1 fs-12 text-success user-name-sub-text">Online</span>
                                :
                                <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Offline</span>
                            }
                            {/*<span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span>*/}
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">{t('common:welcome')} {userName!==undefined && userName!==null && userName}!</h6>
                    <DropdownItem href={route.MAIN_PERSONAL_PROFILE}><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-2"/>
                        <span className="align-middle">{t('common:profile')}</span></DropdownItem>
                    {/*<DropdownItem href="/apps-chat"><i*/}
                    {/*    className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"/> <span*/}
                    {/*        className="align-middle">Messages</span></DropdownItem>*/}
                    {/*<DropdownItem href="/apps-tasks-kanban"><i*/}
                    {/*    className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"/> <span*/}
                    {/*        className="align-middle">Taskboard</span></DropdownItem>*/}
                    {/*<DropdownItem href="/pages-faqs"><i*/}
                    {/*    className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"/> <span*/}
                    {/*        className="align-middle">Help</span></DropdownItem>*/}
                    {/*<div className="dropdown-divider"/>*/}
                    {/*<DropdownItem href="/pages-profile"><i*/}
                    {/*    className="mdi mdi-wallet text-muted fs-16 align-middle me-1"/> <span*/}
                    {/*        className="align-middle">Balance : <b>$5971.67</b></span></DropdownItem>*/}
                    <DropdownItem onClick={toggleSettingsDropdown}><span
                        className="badge bg-soft-success text-success mt-1 float-end">New</span><i
                            className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"/> <span
                                className="align-middle">{t('common:settings')}</span></DropdownItem>
                    {/*<DropdownItem href="/auth-lockscreen-basic"><i*/}
                    {/*    className="mdi mdi-lock text-muted fs-16 align-middle me-1"/> <span className="align-middle">Lock screen</span></DropdownItem>*/}
                    <div className="dropdown-divider"></div>
                    <DropdownItem onClick={handlerChangeAccount}><i
                        className="mdi mdi-account-convert-outline text-muted fs-16 align-middle me-1"/> <span
                        className="align-middle" data-key="t-logout">{t('auth:changeAccount')}</span></DropdownItem>
                    <DropdownItem onClick={handlerLogOut}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"/> <span
                            className="align-middle" data-key="t-logout">{t('auth:signOut')}</span></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;