import React, {useEffect, useState} from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import {useRedux} from "../../hooks";
import {MainCabinetType} from "../../redux/Main/Cabinet/types";
import ListItem from "../../blocks/Main/Cabinet/ListItem";

import '../../i18/config';
import {useTranslation} from "react-i18next";
import {selectCurrentUserCabinet} from "../../redux/Main/Cabinet/actions";

const CabinetDropdown = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();

    const {
        mainCabinet,
    } = useAppSelector(state => ({
        mainCabinet: state.MainCabinet,
    }));

    const [isCabinetDropdown, setIsCabinetDropdown] = useState(false);
    const [cabinets, setCabinets] = useState<MainCabinetType[]>([]);

    useEffect(()=>{

        if (mainCabinet?.items && mainCabinet.items.length>0){
            setCabinets([...mainCabinet.items]);
        } else {
            setCabinets([]);
        }

    }, [mainCabinet?.items]);

    const toggleCabinetDropdown = () => {
        setIsCabinetDropdown(!isCabinetDropdown);
    };

    const handleSelectCurrentCabinet = (cabinet:MainCabinetType) => {
        if (!(cabinet?.isSuspended && cabinet.isSuspended)) {
            dispatch(selectCurrentUserCabinet(cabinet));
            window.location.reload();
        }
    }

    return (
        <React.Fragment>
            <Dropdown isOpen={isCabinetDropdown} toggle={toggleCabinetDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-cabinet fs-22'/>
                    {/*<span*/}
                    {/*    className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">3<span*/}
                    {/*        className="visually-hidden">unread messages</span></span>*/}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> {t('cabinet:myOnlineSuits')} </h6>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="m-2">
                    {(cabinets || []).map((item:MainCabinetType, key:number)=>(
                        <ListItem item={item} key={key} onClick={handleSelectCurrentCabinet}/>
                    ))}
                    </div>

                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default CabinetDropdown;