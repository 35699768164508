import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useRedux} from "../hooks";
import {useTranslation} from "react-i18next";
import '../i18/config';
import {MessengerChannelType} from "../redux/Messenger/Chat/types";
import AppSimpleBar from "../components/AppSimpleBar";
import ChatChannel from "../blocks/Messenger/Chats/ChatChannel";

import {
    changeSelectedChat,
    getChannelDetails, getChannels,
    getChatUserConversations,
    getChatUserDetails,
    readConversation, toggleCollapsedMessenger
} from "../redux/Messenger/Chat/actions";
import {toggleOpenSettings} from "../redux/Main/Layout/actions";
import {showRightModalSupportTicket} from "../redux/Support/Ticket/actions";
import {NewTicketItem} from "../redux/Support/Ticket/reducer";
import RightModal from "../blocks/Support/Ticket/modalForm";
import {handleTaskNew} from "../blocks/Task/Task/TaskActions";
import useScroll from "../hooks/useScroll";
import Loader from "../components/Loader";

const RightSecondaryBar = () => {
    const { dispatch, useAppSelector } = useRedux();
    const { t } = useTranslation(['chats','contacts']);

    const parentRef = useRef(null);
    const childRef = useRef(null);

    const {
        channels,
        selectedChat,
        isCollapsed,
        isChannelsFetched,
        channelsMeta,
        getChannelsLoading
    } = useAppSelector(state => ({
        channels: state.MessengerChat.channels,
        selectedChat: state.MessengerChat.selectedChat,
        isCollapsed: state.MessengerChat.isCollapsed,
        isChannelsFetched: state.MessengerChat.isChannelsFetched,
        channelsMeta: state.MessengerChat.channelsMeta,
        getChannelsLoading: state.MessengerChat.getChannelsLoading
    }));

    useEffect(()=>{
        if (!isCollapsed){
            dispatch(toggleCollapsedMessenger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const loaderIntersectHandler = () => {
        if (isChannelsFetched){
            if (channelsMeta?.totalCount > 0 && channels.length>0 && channels.length<channelsMeta?.totalCount){
                dispatch(getChannels({page: channelsMeta?.page && channelsMeta?.page>0 ? channelsMeta?.page+1 : 1}));
            }
        }
    }

    const loaderIntersect = useScroll(parentRef, childRef, loaderIntersectHandler);

    const onSelectChat = (id: string | number, isChannel?: boolean) => {
        if (isChannel) {
            dispatch(getChannelDetails(id));
        } else {
            dispatch(getChatUserDetails(id));
        }

        dispatch(readConversation(id));
        dispatch(getChatUserConversations(id));
        dispatch(changeSelectedChat(id));
    };

    const toggleSettingsDropdown = () => {
        dispatch(toggleOpenSettings());
    };

    const handleNewSupportTicket = () => {
        dispatch(showRightModalSupportTicket({data:NewTicketItem, modalEditTitle: 'newSupportTicket'}));
    }


    return (
    <React.Fragment>
      <div className="mainRightBar card">
          <AppSimpleBar className="chat-room-list" scrollRef={{ ref: parentRef }}>
              <div className="chat-message-list">
                  <ul className="list-unstyled chat-list chat-user-list users-list">
                      {(channels || []).map((channel: MessengerChannelType, key: number) => (
                          <ChatChannel
                              channel={channel}
                              key={key}
                              selectedChat={selectedChat}
                              onSelectChat={onSelectChat}
                              isRightBar={true}
                          />
                      ))}
                  </ul>
                  {getChannelsLoading &&
                      <div className="d-flex flex-row justify-content-center">
                          <Loader addClassNames="sm d-inline-block"/>
                      </div>
                  }
                  <div className={"loaderIntersect"} ref={childRef}></div>
              </div>
          </AppSimpleBar>
          <div
              className="mainRightBarBottom border-light border-top-outset d-flex flex-column flex-grow-1 align-items-center justify-content-end">
              <ul className="list-group mb-0">
                  <Link to="#" onClick={()=>{handleTaskNew(dispatch)}} className="btn btn-icon btn-lg p-2">
                      <i className="ri-survey-line fs-22 text-body"/>
                  </Link>
                  <Link to="#" onClick={toggleSettingsDropdown} className="btn btn-icon btn-lg p-2">
                      <i className="ri-list-settings-line fs-22 text-body"/>
                  </Link>
                  <Link to="#" onClick={handleNewSupportTicket} className="btn btn-icon btn-lg p-2">
                      <i className="ri-customer-service-2-fill fs-22 text-body"/>
                  </Link>
              </ul>


              {/*<div className="mainRightBarBottomSetting">*/}
              {/*    <div onClick={toggleSettingsDropdown} className="btn btn-icon btn-lg p-2">*/}
              {/*            /!*<i className='mdi mdi-cog-outline fs-22'></i>*!/*/}
              {/*        */}
              {/*    </div>*/}
              {/*</div>*/}
          </div>
      </div>
        <RightModal/>
    </React.Fragment>
  );
};

export default RightSecondaryBar;
