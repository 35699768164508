import {MainContactsPayload, MainUserActionTypes, MainUserPayload} from "./types";
import {MainCabinetType} from "../Cabinet/types";
import {MainMetaType} from "../common";

export const mainUserApiResponseSuccess = (actionType: string, data: any) => ({
  type: MainUserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const mainUserApiResponseError = (actionType: string, error: string) => ({
  type: MainUserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getMainUsers= (data:MainMetaType) => ({
  type: MainUserActionTypes.GET_MAIN_USERS,
  payload: data,
});

export const getMainCurrentUser= () => ({
  type: MainUserActionTypes.GET_MAIN_CURRENT_USER,
});

export const getMainCurrentUserProfile= () => ({
  type: MainUserActionTypes.GET_MAIN_CURRENT_USER_PROFILE,
});

export const showRightModalMainUser= (data: MainUserPayload) => ({
  type: MainUserActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalMainUser= () => ({
  type: MainUserActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataMainUser = (data: any) => ({
  type: MainUserActionTypes.UPDATE_MAIN_USER,
  payload: data,
});

export const onSendAvatarMainUser = (data: any) => ({
  type: MainUserActionTypes.UPDATE_MAIN_USER_AVATAR,
  payload: data,
});

export const onChangePasswordMainUser = (data: any) => ({
  type: MainUserActionTypes.MAIN_USER_CHANGE_PASSWORD,
  payload: data,
});

export const onChangeEmailMainUser = (data: any) => ({
  type: MainUserActionTypes.CHANGE_EMAIL_USER,
  payload: data,
});

export const editDataMainUser = (data: MainUserPayload) => ({
  type: MainUserActionTypes.EDIT_MAIN_USER,
  payload: data,
});

export const removeMainUserModalShow = (data: MainUserPayload) => ({
  type: MainUserActionTypes.REMOVE_MAIN_USER_MODAL_SHOW,
  payload: data,
});

export const removeMainUserModalHide = () => ({
  type: MainUserActionTypes.REMOVE_MAIN_USER_MODAL_HIDE,
});

export const onRemoveMainUser = (data: MainUserPayload) => ({
  type: MainUserActionTypes.REMOVE_MAIN_USER,
  payload: data,
});

export const setSignUpComplete= () => ({
  type: MainUserActionTypes.SIGNUP_COMPLETE,
});

export const setSignUpStatus= (status:boolean) => ({
  type: MainUserActionTypes.SEND_SIGNUP_STATUS,
  payload: {status: status}
});

export const onSendSignUpUser = (data: any) => ({
  type: MainUserActionTypes.SIGNUP_USER,
  payload: data,
});

export const setResetStatus= (status:boolean) => ({
  type: MainUserActionTypes.SEND_RESET_STATUS,
  payload: {status: status}
});

export const onSendResetUser = (data: any) => ({
  type: MainUserActionTypes.RESET_USER,
  payload: data,
});

export const setChangePasswordStatus= (status:boolean) => ({
  type: MainUserActionTypes.SEND_CHANGE_PASSWORD_STATUS,
  payload: {status: status}
});

export const onSendChangePasswordUser = (data: any) => ({
  type: MainUserActionTypes.CHANGE_PASSWORD_USER,
  payload: data,
})

export const onResendConfirmationEmail= () => ({
  type: MainUserActionTypes.SIGNUP_RESEND_CONFIRMATION_EMAIL,
});

export const onSendChangeUserLanguage = (data: any) => ({
  type: MainUserActionTypes.SEND_SET_USER_LANGUAGE,
  payload: data,
})

export const getContactsFromMain = (data: MainContactsPayload) => ({
  type: MainUserActionTypes.GET_MAIN_CONTACTS,
  payload: data,
})

export const getCabinetContacts = (data: MainContactsPayload) => ({
  type: MainUserActionTypes.GET_CABINET_CONTACTS,
  payload: data,
})

export const clearUpdateCabinetSettings= () => ({
  type: MainUserActionTypes.CLEAR_UPDATE_CABINET_SETTINGS,
});


