import {StatTileType} from "../DashboardSale/types";

export const emptyOrderTiles: StatTileType[] =
    [
        {
            label: "totalCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "successCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "newCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "failureCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "totalAmount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "successAmount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "newAmount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "failureAmount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        }
    ];
