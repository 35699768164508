import {
    editDataContentStatCounter,
    getContentStatCounters,
    onRemoveContentStatCounter,
    removeContentStatCounterModalHide,
    removeContentStatCounterModalShow,
    showRightModalContentStatCounter,
} from "../../../redux/Content/StatCounter/actions";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {TaskType} from "../../../redux/Task/Task/types";
import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {handleTaskEdit} from "../../Task/Task/TaskActions";
import {ContentStatCounterType} from "../../../redux/Content/StatCounter/types";

const ContentStatCounterOpenModal = (event:any, dispatch:any) => {
    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(editDataContentStatCounter({data:event, modalEditTitle: 'changeStatCounter'}));
    } else {
        dispatch(showRightModalContentStatCounter({data:[], modalEditTitle: 'newStatCounter'}));
    }
}

const ContentStatCounterOpenRemoveModal = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(removeContentStatCounterModalShow({data:event, modalEditTitle: 'deleteStatCounter'}));
    }

}

const ContentStatCounterOnRemove = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(onRemoveContentStatCounter({data:event, modalEditTitle: 'deleteStatCounter'}));

        setTimeout(() => {
            dispatch(getContentStatCounters());
        }, 1000);
    }

}

const ContentStatCounterHideRemoveModal = (dispatch:any) => {

    dispatch(removeContentStatCounterModalHide())

}

export const handleCreateNewTaskByStatCounter = (entity: ContentStatCounterType, dispatch: any) => {
    let newTask:TaskType = NewTaskItem;

    newTask = {
        ...newTask,
        text: entity?.name && entity.name
    }

    handleTaskEdit(newTask, dispatch);
}

export {
    ContentStatCounterOpenModal,
    ContentStatCounterOpenRemoveModal,
    ContentStatCounterOnRemove,
    ContentStatCounterHideRemoveModal
}