import React, {useEffect, useState} from "react";
import '../../../../i18/config';
import {useTranslation} from "react-i18next";
import {Collapse, Card, CardBody} from "reactstrap";


// hooks
import {useProfile} from "../../../../hooks/UserHooks";
import {MessengerMessageType} from "../../../../redux/Messenger/Chat/types";
import {getMessengerUserData} from "../../../../helpers/functions";
import {MediaFileType} from "../../../../data";
import MessageAttachmentSmallBlock from "../MessageAttachmentSmallBlock";

interface ReplyProps {
    reply: null | MessengerMessageType | undefined;
    onSetReplyData: (reply: null | MessengerMessageType | undefined) => void;
    chatUserDetails: any;
}

const Reply = ({reply, onSetReplyData, chatUserDetails}: ReplyProps) => {
    /*
    collapse handeling
    */
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const onClose = () => {
        setIsOpen(false);
        onSetReplyData(null);
    };
    useEffect(() => {
        setIsOpen(reply ? true : false);
    }, [reply]);

    const {userProfile} = useProfile();

    let replyUserName = undefined;

    if ((reply != null) && (reply?.userData)) {
        let {fullName} = getMessengerUserData(reply.userData);
        replyUserName = fullName;
    }

    const isReplyFromMe =
        reply && reply.userData.uuid + "" === userProfile.uid + "";

    return (
        <Collapse isOpen={isOpen} className="chat-input-collapse replyCollapse">
            <Card className="mb-0">
                <CardBody className="py-3 pb-0">
                    <div className="replymessage-block mb-0 d-flex align-items-start">
                        <div className="flex-grow-1">
                            <h5 className="conversation-name">
                                {isReplyFromMe ? t('common:you') : replyUserName}
                            </h5>
                            {reply?.text && <p className="mb-0">{reply?.text}</p>}

                            <MessageAttachmentSmallBlock message={reply}/>

                        </div>
                        <div className="flex-shrink-0">
                            <button
                                type="button"
                                className="btn btn-sm btn-link mt-n2 me-n3 font-size-18"
                                onClick={onClose}
                            >
                                <i className="bx bx-x align-middle"/>
                            </button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Collapse>
    );
};

export default Reply;
