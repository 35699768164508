import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getPeerActivities = (data:any) => {

    const sendData = {
        start: data.startDate ? data.startDate/1000 : data.periodFrom && data.periodFrom,
        end: data.endDate ? data.endDate/1000 : data.periodTo && data.periodTo,
        project: data.project && data.project,
        channel: data.channel && data.channel,
        order: data.order && data.order,
        limit: data.limit && data.limit,
        uuid: data.uuid && data.uuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_ACTIVITIES,sendData);
};

const copyPeerActivity = (data:any) => {

    //data {peerActivity: /// channel ///}

    const sendData = {
        channel: data.channel && data.channel,
    }

    let requestUrl = m9sGlobals.CURRENT_CABINET+url.COPY_CONTENT_PEER_ACTIVITY;

    requestUrl = requestUrl.replace(/:uuid/,data.peerActivity.uuid);

    return api.create(requestUrl,sendData);
};

const removePeerActivity = (data:any) => {

    let requestUrl = m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_PEER_ACTIVITY;

    requestUrl = requestUrl.replace(/:uuid/,data.uuid);

    return api.delete(requestUrl);
};

const sendActivityPeerActivity = (data:any) => {

    if (data.attachmentFiles !== undefined){
        return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_PEER_ACTIVITY,data);
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_PEER_ACTIVITY,data);
};

const publishActivityPeerActivity = (data:any) => {

    if (data.attachmentFiles !== undefined){
        return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.PUBLISH_CONTENT_PEER_ACTIVITY,data);
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.PUBLISH_CONTENT_PEER_ACTIVITY,data);
};

const unPublishActivityPeerActivity = (data:any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.PUBLISH_CONTENT_PEER_ACTIVITY,data);
};

const togglePinActivityPeerActivity = (payload:any) => {

    const sendData = {
        uuid: payload.data && payload.data.uuid && payload.data.uuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.PIN_CONTENT_PEER_ACTIVITY,sendData);
};

const getStatPeerActivityTiles = (data:any) => {

    const sendData = {
        activity :  data.activity && data.activity,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_ACTIVITY_TILES_LIST, sendData);
};

const getStatPeerActivityActivities = (data:any) => {

    const sendData = {
        activity :  data.activity && data.activity,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_ACTIVITY_ACTIVITIES_DATA, sendData);
};

const getStatPeerActivityEngagements = (data:any) => {

    const sendData = {
        activity :  data.activity && data.activity,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_ACTIVITY_ENGAGEMENTS_DATA, sendData);
};

const getPeerActivityTargets = (data:any) => {

    const sendData = {
        activity :  data.activity && data.activity,
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_TARGETS, sendData);
};

export {
    getPeerActivities,
    sendActivityPeerActivity,
    togglePinActivityPeerActivity,
    getStatPeerActivityTiles,
    getStatPeerActivityActivities,
    getStatPeerActivityEngagements,
    getPeerActivityTargets,
    publishActivityPeerActivity,
    unPublishActivityPeerActivity,
    copyPeerActivity,
    removePeerActivity
};
