import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentConnectorHandlerActionTypes } from "./types";
import { contentConnectorHandlerApiResponseSuccess, contentConnectorHandlerApiResponseError } from "./actions";

import {
  getContentConnectorHandlers as getContentConnectorHandlersApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getContentConnectorHandlers() {
  try {
    const response: Promise<any> = yield call(getContentConnectorHandlersApi);
    yield put(contentConnectorHandlerApiResponseSuccess(ContentConnectorHandlerActionTypes.GET_CONTENT_CONNECTOR_HANDLERS, response));
  } catch (error: any) {
    yield put(contentConnectorHandlerApiResponseError(ContentConnectorHandlerActionTypes.GET_CONTENT_CONNECTOR_HANDLERS, error));
  }
}

export function* watchGetContentConnectorHandlers() {
  yield takeEvery(ContentConnectorHandlerActionTypes.GET_CONTENT_CONNECTOR_HANDLERS, getContentConnectorHandlers);
}


function* contentConnectorHandlerSaga() {
  yield all([
    fork(watchGetContentConnectorHandlers),
  ]);
}

export default contentConnectorHandlerSaga;
