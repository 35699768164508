import {SalePlanType} from "../../Sale/Plan/types";
import {MainSettingsState} from "../Layout/types";
import {EntityActionsType} from "../../types";

export enum MainCabinetActionTypes {
  API_RESPONSE_SUCCESS = "@@mainCabinet/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainCabinet/API_RESPONSE_ERROR",
  GET_USER_CABINETS = "@@main/cabinet/GET_USER_CABINETS",
  CREATE_USER_CABINET = "@@main/cabinet/CREATE_USER_CABINET",
  RESUME_USER_CABINET = "@@main/cabinet/RESUME_USER_CABINET",
  SELECT_USER_CABINET = "@@main/cabinet/SELECT_USER_CABINET",
  UPDATE_CABINET = "@@main/cabinet/UPDATE_CABINET",
  SELECT_UPDATE_USER_CABINET = "@@main/cabinet/SELECT_UPDATE_USER_CABINET",
  CLEAR_SELECT_UPDATE_USER_CABINET = "@@main/cabinet/CLEAR_SELECT_UPDATE_USER_CABINET",
  UPDATE_CABINET_LASTLOGIN = "@@main/cabinet/UPDATE_CABINET_LASTLOGIN",
  GET_CURRENT_CABINET = "@@main/cabinet/GET_CURRENT_CABINET",
  SAVE_CURRENT_CABINET_SETTINGS = "@@main/cabinet/SAVE_CURRENT_CABINET_SETTINGS",
}

export interface MainCabinetState {
  items: Array<any>;
  isLoading: boolean;
  isCreating: boolean;
  currentCabinet?: MainCabinetType;
  isSending: boolean;
  isNeedReload: boolean;
}

export interface MainCabinetType{
  uuid?: string;
  enabled?: boolean;
  name: string;
  description?: string;
  url?: string;
  domain?: string;
  plan?: SalePlanType;
  settings?: MainSettingsState;
  actions?: EntityActionsType,
  isEdit?: boolean;
  isSuspended?: boolean
}

export interface MainCabinetPayload {
  data: Array<any>;
}
