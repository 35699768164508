import {LayoutActionTypes, MainSettingsState} from "./types";

export const changeLayout = layout => ({
  type: LayoutActionTypes.CHANGE_LAYOUT,
  payload: layout,
});

export const changeLayoutMode = mode => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_MODE,
  payload: mode,
});

export const changeSidebarTheme = theme => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeLayoutWidth = layoutWidth => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
  payload: layoutWidth,
});

export const changeLayoutPosition = layoutposition => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_POSITION,
  payload: layoutposition,
});

export const changeTopbarTheme = topbarTheme => ({
  type: LayoutActionTypes.CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const changeLeftsidebarSizeType = leftsidebarSizetype => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_SIZE_TYPE,
  payload: leftsidebarSizetype,
});

export const changeLeftsidebarViewType = leftsidebarViewtype => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_VIEW,
  payload: leftsidebarViewtype,
});

export const changeSidebarImageType = leftsidebarImagetype => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_IMAGE_TYPE,
  payload: leftsidebarImagetype,
});

export const changeDefaultDashboard = defaultDashboard => ({
  type: LayoutActionTypes.CHANGE_DEFAULT_DASHBOARD,
  payload: defaultDashboard,
});

export const changeUserName = userName => ({
  type: LayoutActionTypes.CHANGE_USER_NAME,
  payload: userName,
});

export const changeUserDescription = userDescription => ({
  type: LayoutActionTypes.CHANGE_USER_DESCRIPTION,
  payload: userDescription,
});

export const resetValue = value => ({
  type: LayoutActionTypes.RESET_VALUE,
  payload: value,
});

export const saveSettingsValues = () => ({
  type: LayoutActionTypes.SAVE_VALUES
});
export const setSettingsValues = (values:MainSettingsState) => ({
  type: LayoutActionTypes.SET_VALUES,
  payload:values
});

export const toggleOpenSettings = () => ({
  type: LayoutActionTypes.TOGGLE_OPEN
});

export const clearLayoutUpdateCabinetSettings = () => ({
  type: LayoutActionTypes.CLEAR_UPDATE_CABINET_SETTINGS
});
