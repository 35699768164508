// types
import {SaleLicenseActionTypes, SaleLicenseState} from "./types";

export const INIT_STATE_SALE_PLAN: SaleLicenseState = {
  isFetched: false,
  isLoading: false,
};

const SaleLicense = (state = INIT_STATE_SALE_PLAN, action: any) => {
  switch (action.type) {
    case SaleLicenseActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleLicenseActionTypes.ACTIVATE_LICENSE_KEY:
          return {
            ...state,
            isFetched: true,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case SaleLicenseActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleLicenseActionTypes.ACTIVATE_LICENSE_KEY:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case SaleLicenseActionTypes.ACTIVATE_LICENSE_KEY:
      return {
        ...state,
        isFetched: false,
        isLoading: true,
      };
    default:
      return { ...state };
  }
};

export default SaleLicense;
