import React, {useState, useEffect} from 'react';
import { Card, Col, Container, Row} from "reactstrap";
import AuthCarousel from "../../../components/Auth/AuthCarousel";
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";
import ListItemV2 from "./ListItemV2";

import {useRedux} from "../../../hooks";

import {getUserCabinets} from "../../../redux/Main/Cabinet/actions";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import CoverProfileSwitch from "../../../components/CoverProfileSwitch";
import {getCurrentLanguage} from "../../../helpers/functions";
import {ToastContainer} from "react-toastify";


const CoverCabinet = () => {

    const { t, i18n } = useTranslation(['cabinet']);

    const { dispatch, useAppSelector } = useRedux();

    const [cabinets, setCabinets] = useState<MainCabinetType[]>([]);
    const [language, setLanguage] = useState<string>(getCurrentLanguage());

    const {
        cabinetState,
        loginState
    } = useAppSelector(state => ({
        cabinetState: state.MainCabinet,
        loginState: state.Login
    }));

    useEffect(()=>{
        dispatch(getUserCabinets());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{

        if (loginState?.user?.language && loginState?.user?.language.length>0){
            if (loginState.user.language!== language){
                i18n.changeLanguage(loginState.user.language);
                setLanguage(loginState.user.language);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loginState?.user?.language, language]);

    useEffect(() => {
        if ((cabinetState.items !== undefined) && (cabinetState.items.length>0)){
            setCabinets([...cabinetState.items]);
        } else {
            setCabinets([]);
        }
    }, [cabinetState?.items]);

    const reloadCabinetsHandler = () => {
        dispatch(getUserCabinets());
    }

    document.title=t('cabinet:yourCabinets')+" | Marketcells.io - digital marketing platform";

    return (
        <>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"/>

                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthCarousel />

                                        <Col lg={6}>
                                            <CoverProfileSwitch/>
                                            <CoverLangSwitch/>

                                            <div className="p-lg-4 p-4">
                                                <div>
                                                    <h5 className="text-primary">{t('cabinet:chooseYourCabinet')}</h5>
                                                    <p className="text-muted">{t('cabinet:yourActiveCabinets')}</p>
                                                </div>
                                                <Row className="mt-4">
                                                {(cabinets || []).map((item:MainCabinetType, key:number)=>{
                                                    return(
                                                        <Col xxl={6} sm={6} className="project-card" key={"cabinets_"+key}>
                                                            <ListItemV2
                                                                item={item}
                                                                reloadCabinets={reloadCabinetsHandler}
                                                            />
                                                        </Col>
                                                    );
                                                })}
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">


                                    <p className="mb-0">&copy; {new Date().getFullYear()} © Marketcells. Be smart! <i className="mdi mdi-heart text-danger"/> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
            <ToastContainer autoClose={2000} />
        </>
    );
};

export default CoverCabinet;