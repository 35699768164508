import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ShortenerActionTypes } from "./types";
import { ShortenerApiResponseSuccess, ShortenerApiResponseError } from "./actions";

import {
  checkUrlShortener as checkUrlShortenerApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* checkShortener({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(checkUrlShortenerApi,data);
    yield put(ShortenerApiResponseSuccess(ShortenerActionTypes.CHECK_URL_SHORTENER, response));
  } catch (error: any) {
    yield put(ShortenerApiResponseError(ShortenerActionTypes.CHECK_URL_SHORTENER, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchCheckShortener() {
  yield takeEvery(ShortenerActionTypes.CHECK_URL_SHORTENER, checkShortener);
}

function* urlShortenerSaga() {
  yield all([
      fork(watchCheckShortener)
  ]);
}

export default urlShortenerSaga;
