import {
    editDataContentConnector,
    getContentConnectors, onConnectContentConnector,
    onGetContentConnectorManagedChannels,
    onIsConnectedContentConnector,
    onIsConnectedErrorContentConnector,
    onRemoveContentConnector,
    removeContentConnectorModalHide,
    removeContentConnectorModalShow,
    showRightModalContentConnector,
} from "../../../redux/Content/Connector/actions";
import {showErrorNotification, showSuccessNotification} from "../../../helpers/notifications";
import {ContentConnectorType} from "../../../redux/Content/Connector/types";
import {showUpgradeModal} from "../../../redux/Main/UpgradeFare/actions";
import {OrderType} from "../../../redux/Sale/Order/types";
import {TaskType} from "../../../redux/Task/Task/types";
import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {handleTaskEdit} from "../../Task/Task/TaskActions";

const ContentConnectorOpenModal = (event:any, dispatch:any) => {

    if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
        dispatch(editDataContentConnector({data:event, modalEditTitle: 'changeConnector'}));
    } else {
        dispatch(showRightModalContentConnector({data:[], modalEditTitle: 'newConnector'}));
    }
}

const contentConnectorOpenConnectModal = (event:any, dispatch:any) => {
    if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
        if ((event.handler !== undefined) && (event.handler.isFareRestriction===true)){
            dispatch(showUpgradeModal());
        } else {
            dispatch(editDataContentConnector({data:event, modalEditTitle: 'changeConnector'}));
            dispatch(onConnectContentConnector(event));
        }
    }
}


const ContentConnectorOpenRemoveModal = (event:any, dispatch:any) => {

    if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
        dispatch(removeContentConnectorModalShow({data:event, modalEditTitle: 'deleteConnector'}));
    }

}

const ContentConnectorOnRemove = (event:any, dispatch:any) => {

    if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
        dispatch(onRemoveContentConnector({data:event, modalEditTitle: 'deleteConnector'}));

        setTimeout(() => {
            dispatch(getContentConnectors());
        }, 1000);
    }

}

const ContentConnectorHideRemoveModal = (dispatch:any) => {

    dispatch(removeContentConnectorModalHide())

}

const contentConnectorCheckConnectionAttempts = (
    connection:ContentConnectorType,
    errorMessage: string,
    dispatch:any) => {

    if (connection.connectionCheckAttempts !== undefined){
        if (connection.connectionCheckAttempts>50){
            showErrorNotification({message: errorMessage+' ' + connection.name});
            dispatch(onIsConnectedErrorContentConnector());
        } else {
            setTimeout(() => {
                dispatch(onIsConnectedContentConnector(connection));
            }, 3000);
        }
    }
}

const contentConnectorConnectionSuccess = (
    connection:ContentConnectorType,
    successMessage: string) => {

    if (connection.connectionSuccess){
        showSuccessNotification({message: successMessage});
    }

}

export const handleCreateNewTaskByConnector = (entity: ContentConnectorType, dispatch: any) => {
    let newTask:TaskType = NewTaskItem;

    newTask = {
        ...newTask,
        text: entity?.name && entity.name
    }

    handleTaskEdit(newTask, dispatch);
}

export {
    ContentConnectorOpenModal,
    ContentConnectorOpenRemoveModal,
    ContentConnectorOnRemove,
    ContentConnectorHideRemoveModal,
    contentConnectorCheckConnectionAttempts,
    contentConnectorConnectionSuccess,
    contentConnectorOpenConnectModal
}