import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getStatOrdersTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_TILES_LIST, sendData);
};
const getStatOrdersCountriesTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_COUNTRY_TILES_LIST, sendData);
};

const getStatOrdersCurrenciesTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_CURRENCY_TILES_LIST, sendData);
};

const getStatOrdersStatusesTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_STATUS_TILES_LIST, sendData);
};

const getStatOrdersBestProductsTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_BEST_PRODUCTS_TILES_LIST, sendData);
};

const getStatOrdersBestChannelsTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_BEST_CHANNELS_TILES_LIST, sendData);
};

const getStatOrdersBestCustomersTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_BEST_CUSTOMERS_TILES_LIST, sendData);
};

const getStatOrdersLast12MonthTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_LAST_12MONTH_TILES_LIST, sendData);
};

const getRecentOrders = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        requestUuid: data.requestUuid && data.requestUuid,
        limit: 5
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_SALE_ORDERS, sendData);
};

const getStatOrdersWeekdaysTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        currency :  data.currency && data.currency,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_SALE_ORDER_WEEKDAYS_TILES_LIST, sendData);
};

export {
    getStatOrdersTiles,
    getStatOrdersCountriesTiles,
    getStatOrdersCurrenciesTiles,
    getStatOrdersStatusesTiles,
    getStatOrdersBestProductsTiles,
    getStatOrdersBestChannelsTiles,
    getStatOrdersBestCustomersTiles,
    getStatOrdersLast12MonthTiles,
    getRecentOrders,
    getStatOrdersWeekdaysTiles
};
