import React, {useEffect} from 'react';
import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import { useLocation } from 'react-router-dom'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import Scss
import './assets/scss/themes.scss';

//import Route
import Route from './routes/index';
import WebSocketContextProvider from "../src/api/WebSocket/webSocketProvider";
import CoverError from "./blocks/Main/Auth/CoverError";
import {setParentPartner} from "./helpers/functions";
import ReactGA from 'react-ga4';
import {m9sGlobals} from "./config";

const App = withErrorBoundary(({ children }) => {

    const location = useLocation()

    if (process.env.REACT_APP_VERSION !== 'local.dev'){
        ReactGA.initialize(m9sGlobals.GA_TRACKING_ID);

        useEffect(() => {
            ReactGA.send({
                hitType: 'pageview',
                page: location.pathname + location.search
            });
        }, [location]);
    }

    const [error, resetError] = useErrorBoundary(
        // You can optionally log the error to an error reporting service
        // (error, errorInfo) => logErrorToMyService(error, errorInfo)
    );

    if (error) {
        return (
            <CoverError errorCode={400}/>
        );
    }

    useEffect(() => {
        window.addEventListener('pageshow', historyNavigateReloadPage);
        return () => {
            window.removeEventListener('pageshow', historyNavigateReloadPage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const historyNavigateReloadPage = () => {
        window.addEventListener( "pageshow", function ( event ) {
            var historyTraversal = event.persisted ||
                ( typeof window.performance != "undefined" &&
                    window.performance.navigation.type === 2 );
            if ( historyTraversal ) {
                // Handle page restore.
                window.location.reload();
            }
        });
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const ref = queryParameters.get("ref");

    if (ref && ref.length>5){
        setParentPartner(ref);
    }

    return (
        <React.Fragment>
            <WebSocketContextProvider>
            <Route />
            </WebSocketContextProvider>
        </React.Fragment>
    );
});

export default App;
