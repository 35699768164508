import React, {useEffect, useState} from "react";

import {Button, UncontrolledPopover, PopoverBody, UncontrolledTooltip} from "reactstrap";
import {useRedux} from "../../../../hooks";
import ButtonSpinner from "../../../../components/ButtonSpinner";
import {aiTextGenerateOpenModal, aiTextGenerateTitleOpenModal} from "../../../AI/TextGenerate/TextGenerateActions";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import '../../../../i18/config';
import {useTranslation} from "react-i18next";
interface EndButtonsProps {
  onSubmit: () => void;
  disabled: boolean;
}
const EndButtons = ({ onSubmit, disabled }: EndButtonsProps) => {

    const { t } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();

    const [isSending, setIsSending] = useState<boolean>(false);

    const {
        messengerChat,
        messengerComment,
    } = useAppSelector(state => ({
        messengerChat: state.MessengerChat,
        messengerComment: state.MessengerComment,
    }));

    useEffect(()=>{

        if (messengerChat.isMessageSending || messengerComment.isMessageSending){
            setIsSending(true);
        } else {
            setIsSending(false);
        }

    },[messengerChat.isMessageSending, messengerComment.isMessageSending])

    const handleAITextGenerate = () =>{
        aiTextGenerateOpenModal(null,dispatch);
    }

  return (
    <div className="chat-input-links ms-2 gap-md-1">
        <div className="links-list-item d-none d-sm-block">
                <Button
                  color="none"
                  type="button"
                  className="btn btn-link text-decoration-none btn-lg waves-effect"
                  id="ai-btn"
                  onClick={handleAITextGenerate}
                >
                    <i className="mdi mdi-pencil-plus-outline label-icon align-middle"/>
                </Button>
                <UncontrolledTooltip target="ai-btn" placement="top">
                    Magic Writer
                </UncontrolledTooltip>
        </div>
      <div className="links-list-item d-none d-sm-block">
        {/*<Button*/}
        {/*  color="none"*/}
        {/*  type="button"*/}
        {/*  className="btn btn-link text-decoration-none btn-lg waves-effect"*/}
        {/*  id="audio-btn"*/}
        {/*>*/}
        {/*  <i className="bx bx-microphone align-middle"></i>*/}
        {/*</Button>*/}
      </div>
      {/*<UncontrolledPopover trigger="focus" placement="top" target="audio-btn">*/}
      {/*  <PopoverBody>*/}
      {/*    <div className="loader-line">*/}
      {/*      <div className="line"></div>*/}
      {/*      <div className="line"></div>*/}
      {/*      <div className="line"></div>*/}
      {/*      <div className="line"></div>*/}
      {/*      <div className="line"></div>*/}
      {/*    </div>*/}
      {/*  </PopoverBody>*/}
      {/*</UncontrolledPopover>*/}
      <div className="links-list-item">
        <Button
          color="primary"
          type="submit"
          disabled={disabled}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light d-inline-flex justify-content-center align-items-center"
        >
            {isSending ?
                <ButtonSpinner className="ms-0"/>
                :
                <i className="bx bxs-send align-middle"/>
            }
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
