import React, { useEffect, useState } from "react";
import { Col, Row } from 'reactstrap';
import {useRedux} from "../../../hooks";
import { v4 as uuidv4 } from 'uuid';

import PeriodSelect from "../../../components/PeriodSelect";
import {
    emptyChannelsTiles,
    StatPeerChannelPayloadType,
    StatPeerChannelTileType
} from "../../../redux/Stat/PeerChannel/types";

import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";

import StatTile from "../../../components/StatTile";
import ListItem from "../../Content/PeerChannel/ListItem";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    PeriodDurationType,
    StatChannelTilesPayloadType,
} from "../../../redux/Stat/DashboardContent/types";
import moment from "moment/moment";
import {setStatPeerActivityPeriod} from "../../../redux/Stat/PeerActivity/actions";
import InputSelect from "../../../components/InputSelect";
import {
    getComparePeerChannelActivitiesData,
    getComparePeerChannelTiles,
    setComparePeerChannel,
    setComparePeerChannelPeriod, setComparePeerChannelRequestUuid
} from "../../../redux/Stat/ComparePeerChannel/actions";
import {
    ComparePeerChannelPayloadType,
    ComparePeerChannelRequestPayloadType
} from "../../../redux/Stat/ComparePeerChannel/types";
import Loader from "../../../components/Loader";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";


interface ItemProps {
    index : number;
}

const ComparePeerChannelItem = ({index}:ItemProps) => {

    const { t } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();
    const [ periodType, setPeriodType ] = useState<PeriodDurationType>(PeriodDurationType.WEEK);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [periodFrom, setPeriodFrom] = useState<number>(null);
    const [periodTo, setPeriodTo] = useState<number>(null);
    const [currentChannel, setCurrentChannel] = useState<ContentPeerChannelType>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [channelTiles, setChannelTiles] = useState<StatPeerChannelTileType[]>(emptyChannelsTiles);

    const {
        comparePeerChannel,
        contentPeerChannels,
        isFetched
    } = useAppSelector(state => ({
        comparePeerChannel: state.ComparePeerChannel,
        contentPeerChannels: state.ContentPeerChannel,
        isFetched: state.ContentPeerChannel.isFetched
    }));

    useEffect(() => {
        if ((contentPeerChannels.items !== undefined) && (contentPeerChannels.items.length>0)){

            const channelItems = contentPeerChannels.items.filter((item:ContentPeerChannelType)=>{
                return item.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS
            })

            setChannels([...channelItems]);
        } else {
            setChannels([]);
        }
    }, [contentPeerChannels?.items])

    useEffect(() => {

        let isSetCurrentChannel = false;

        if ((comparePeerChannel.items !== undefined) && (comparePeerChannel.items.length>0)){
            if (comparePeerChannel.items[index] !== undefined
                && comparePeerChannel.items[index] !== null
                && comparePeerChannel.items[index].uuid !== undefined) {

                isSetCurrentChannel = true;
                if (
                    (currentChannel !== null && currentChannel.uuid !== undefined && currentChannel.uuid !== comparePeerChannel.items[index].uuid)
                    ||
                    (currentChannel === null)
                ) {
                    setCurrentChannel({...comparePeerChannel.items[index]});
                }
            }
        }

        if (!isSetCurrentChannel){
            setCurrentChannel(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, comparePeerChannel?.items])

    useEffect(() => {

        let isSetPeriodFrom = false;
        let isSetPeriodTo = false;
        let isSetChannelTiles = false;

        if (comparePeerChannel?.itemsData !== undefined) {
            if (
                (comparePeerChannel.itemsData[index] !== undefined)
                &&
                (comparePeerChannel.itemsData[index] !== null)
            ){
                if (
                    (comparePeerChannel.itemsData[index].periodFrom !== undefined)
                    &&
                    (comparePeerChannel.itemsData[index].periodFrom !== null)
                ) {
                    isSetPeriodFrom = true;
                    setPeriodFrom(comparePeerChannel.itemsData[index].periodFrom);
                    setIsLoaded(false);
                }

                if (
                    (comparePeerChannel.itemsData[index].periodTo !== undefined)
                    &&
                    (comparePeerChannel.itemsData[index].periodTo !== null)
                ) {
                    isSetPeriodTo = true;
                    setPeriodTo(comparePeerChannel.itemsData[index].periodTo);
                    setIsLoaded(false);
                }

                if (
                    (comparePeerChannel.itemsData[index].tiles !== undefined)
                    &&
                    (comparePeerChannel.itemsData[index].tiles !== null)
                ) {
                    setChannelTiles([...comparePeerChannel.itemsData[index].tiles]);
                    isSetChannelTiles =  true;
                    setIsLoaded(true);
                }

            }
        }

        if (!isSetPeriodFrom){
            setPeriodFrom(null);
        }
        if (!isSetPeriodTo){
            setPeriodTo(null);
        }
        if (!isSetChannelTiles){
            setChannelTiles(emptyChannelsTiles);
        }

// eslint-disable-next-line
    }, [comparePeerChannel?.itemsData[index]])


    useEffect(() => {
        handleLastWeekPeriod();
        // eslint-disable-next-line
    },[dispatch]);

    useEffect(() => {

        if (
            (periodFrom !== undefined && periodFrom !== null)
                &&
            (periodTo !== undefined && periodTo !== null)
            &&
            (currentChannel !== undefined && currentChannel !== null && currentChannel.uuid !== null)
            &&
            (!isLoaded)
        )
        {
            const statChannelTilesPayload:StatChannelTilesPayloadType = {
                channel : currentChannel,
                periodFrom : periodFrom,
                periodTo: periodTo,
                requestUuid : uuidv4()
            }

            const statPeerChannelPayloadType:StatPeerChannelPayloadType = {
                channel : currentChannel,
                periodFrom : periodFrom,
                periodTo: periodTo,
                requestUuid : statChannelTilesPayload.requestUuid
            }

            const comparePeerChannelRequestPayload:ComparePeerChannelRequestPayloadType = {
                index : index,
                uuid : statChannelTilesPayload.requestUuid
            }

            dispatch(setComparePeerChannelRequestUuid(comparePeerChannelRequestPayload));
            dispatch(getComparePeerChannelTiles(statChannelTilesPayload));
            dispatch(getComparePeerChannelActivitiesData(statPeerChannelPayloadType))
        }

    },[periodFrom, periodTo, currentChannel, isLoaded, index, dispatch]);

    const handleLastWeekPeriod = () => {

        let data : ComparePeerChannelPayloadType = {
            index : index,
            data: {
                periodFrom: moment().add(-1, 'weeks').utc().unix(),
                periodTo: moment().utc().unix(),
            }
        }

        dispatch(setComparePeerChannelPeriod(data))
        setPeriodType(PeriodDurationType.WEEK);
    }

    const handleLastMonthPeriod = () => {
        let data : ComparePeerChannelPayloadType = {
            index : index,
            data: {
                periodFrom: moment().add(-1, 'months').utc().unix(),
                periodTo: moment().utc().unix(),
            }
        }
        dispatch(setComparePeerChannelPeriod(data))
        setPeriodType(PeriodDurationType.MONTH);
    }

    const selectPeerChannel = (value) => {

        let data : ComparePeerChannelPayloadType = {
            index : index,
            data: {
                channel : (channels || []).filter((item:ContentPeerChannelType)=>{return item.uuid === value.value}).pop()
            }
        }

        dispatch(setComparePeerChannel(data));
        setIsLoaded(false);
    }

    return (
        <>
            <div className="compareItemCont">

                <div>
                    <div className="mb-3">
                        { channels && channels.length>0 ?
                            <InputSelect
                                items={channels}
                                placeholder={t('channel:chooseChannel')}
                                onChange={(value:string)=>{selectPeerChannel(value)}}
                                required={false}
                                isLoading={!isFetched}
                                value={currentChannel}
                                readonly={false}
                            />
                            :
                            <Loader/>
                        }
                    </div>

                    <div className="d-flex flex-column">
                    {periodType === PeriodDurationType.DATE_RANGE ?
                        <>
                            <PeriodSelect
                                onDispatch={setStatPeerActivityPeriod}
                                periodFrom={periodFrom}
                                periodTo={periodTo}
                            />
                        </>
                        :
                        <div className="btn-group" role="group">
                            <button type="button" className={"btn "+(periodType===PeriodDurationType.WEEK?'btn-primary':'btn-soft-primary') } onClick={handleLastWeekPeriod}>{t('common:week')}</button>
                            <button type="button" className={"btn "+(periodType===PeriodDurationType.MONTH?'btn-primary':'btn-soft-primary')} onClick={handleLastMonthPeriod}>{t('common:month')}</button>
                            <button type="button" className="btn btn-soft-primary" onClick={()=>{setPeriodType(PeriodDurationType.DATE_RANGE)}}>
                                <i className="ri-calendar-2-line"/>
                            </button>
                        </div>
                    }
                    </div>

                </div>
            <div className="mt-3">
                {currentChannel && currentChannel.uuid &&
                    <>
                        <div className="mt-3">
                            <ListItem item={currentChannel}/>
                        </div>

<Row>
                    {(channelTiles || []).map((tile:StatPeerChannelTileType, tileKey:number)=>(
                    <Col md={6} key = {'channelTile_'+currentChannel.uuid+'_'+tile.label+'_'+tileKey.toString()}>
                        <StatTile
                            label={tile.label}
                            start={0}
                            first={tile.value.first && tile.value.first}
                            last={tile.value.last}
                            isLoading={!isLoaded}
                            deltaLabel={moment(periodTo,'X').diff(moment(periodFrom,'X'),'days').toString()}
                        />
                    </Col>
                    ))
                    }
</Row>
                    </>
                }

            </div>
            </div>
        </>
    );
};

export default ComparePeerChannelItem;


