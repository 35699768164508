import {StatPeerChannelPayloadType, StatPeerChannelState} from "../PeerChannel/types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";

export enum ComparePeerChannelActionTypes {
    API_RESPONSE_SUCCESS = "@@comparePeerChannel/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@comparePeerChannel/API_RESPONSE_ERROR",
    GET_STAT_CHANNEL_TILES = "@@comparePeerChannel/GET_STAT_CHANNEL_TILES",
    GET_STAT_CHANNEL_ACTIVITIES_DATA = "@@comparePeerChannel/GET_STAT_CHANNEL_ACTIVITIES_DATA",
    GET_STAT_CHANNEL_TARGETS = "@@comparePeerChannel/GET_STAT_CHANNEL_TARGETS",
    GET_STAT_CHANNEL_ACTIVITIES = "@@comparePeerChannel/GET_STAT_CHANNEL_ACTIVITIES",
    GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES = "@@comparePeerChannel/GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES",
    SET_PERIOD = "@@comparePeerChannel/SET_PERIOD",
    SET_CHANNEL = "@@comparePeerChannel/SET_CHANNEL",
    REMOVE_CHANNEL = "@@comparePeerChannel/REMOVE_CHANNEL",
    SET_REQUEST_UUID = "@@comparePeerChannel/SET_REQUEST_UUID",
    ADD_TO_COMPARE = "@@comparePeerChannel/ADD_TO_COMPARE",
    UPDATE_COMPARE_CHANNELS = "@@comparePeerChannel/UPDATE_COMPARE_CHANNELS",
}

export interface ComparePeerChannelState {
    itemsData: Array<StatPeerChannelState>
    items: Array<ContentPeerChannelType>
}

export interface ComparePeerChannelPayloadType{
    index: number;
    data: StatPeerChannelPayloadType
}

export interface ComparePeerChannelRequestPayloadType{
    index: number;
    uuid: string;
}
