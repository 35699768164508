import React, {useCallback, useEffect, useState} from "react";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { BiCrown, BiStreetView, BiHelpCircle, BiKey } from "react-icons/bi";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getHandlerIconByCode} from "../../../helpers/functions";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {logoIdeaChannel} from "../../../data/images";
import ConnectorConnectionStatus from "../Connector/ConnectorConnectionStatus";
import ChannelLink from "./ChannelLink";

interface ItemProps {
    item: ContentPeerChannelType;
}
const ChannelItemBigLine = ({ item }: ItemProps) => {
    const { t } = useTranslation(['common']);

    const [channelName, setChannelName] = useState<string>(item.name)

    const shouldDisplayHandlerIcon = useCallback(() =>
        Boolean(item?.connector?.handler?.code?.length), [item]);

    const shouldDisplayIdeaLogo = useCallback(() =>
        item?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS, [item]);

    useEffect(()=>{
        const isIdeaChannel = item?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS;
        setChannelName(isIdeaChannel ? t('channel:channelIdeas') : item.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item.name])

    return(
        <>
            <div className="d-flex flex-grow-1 mw-0">
                <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm position-relative">
                        {shouldDisplayHandlerIcon() &&
                            <div className="avatar-xxs position-absolute handlerIconTopLeft">
                                <img src={getHandlerIconByCode(item.connector.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                            </div>
                        }
                        <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                            { item.avatar ?
                                <img src={item.avatar} className="img-fluid p-1 rounded-circle" alt=""/>
                                :
                                shouldDisplayIdeaLogo() ?
                                    <img src={logoIdeaChannel} className="img-fluid p-0 rounded-circle" alt=""/>
                                    :
                                    channelName[0]
                            }

                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column">
                            { item.isManaged ?
                                <BiCrown className="fs-16 me-2 mb-1 text-primary" title={t('channel:managedChannel')}/>
                                :
                                <BiStreetView className="fs-16 me-2 mb-1 text-primary" title={t('channel:competitorChannel')}/>
                            }
                        </div>

                        <div className="d-flex flex-column">
                            <h5 className="fs-16 text-truncate m-0 d-flex flex-row align-items-center">
                                {channelName}
                                <ChannelLink item={item}/>
                            </h5>
                            {item?.connector?.name &&
                                <div className="d-flex flex-row align-items-center position-relative">
                                    <ConnectorConnectionStatus item={item.connector} marginTop={"-2px"}/>
                                    <span className="text-muted text-truncate fs-10">
                                        {t('connector:service')}: {item.connector.name}
                                    </span>
                                    </div>
                            }
                            {item.project &&
                                <div className="d-flex flex-column mt-1">
                                    <div className="d-flex flex-row">
                                        <div className="badge badge-outline-primary fs-12 mb-1" ref={element => {
                                            if (element) {
                                                element.style.setProperty('color', item.project.color, 'important');
                                                element.style.setProperty('border-color', item.project.color, 'important');
                                            }
                                        }}>{item.project.name}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {item.isInPostsUpdate &&
                    <div className="text-muted text-truncate-two-lines mb-3 fs-14">
                        {t('channel:dataUpdate')}...
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip>
                                    {t('channel:dataUpdateDescription')}
                                </Tooltip>
                            }
                        >
                                            <span className="position-relative">
                                                <BiHelpCircle className="mb-1 text-primary fs-12"/>
                                            </span>
                        </OverlayTrigger>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ChannelItemBigLine;