//@ts-nocheck
import React from "react";
import {Spinner} from "react-bootstrap";

interface InputProps {
  spinnerSize?: null | string;
  className?: null | string
}
const ButtonSpinner = ({spinnerSize, className}: InputProps) => {

    if (spinnerSize === undefined || spinnerSize === null){
        spinnerSize = "sm";
    }

    let commonMargin = "ms-2";

    if (className && className.length>0 && className.match(/ms-/)){
        commonMargin = "";
    }

  return (
      <i className={(className && className+" ")+"label-icon align-middle pt-0 "+commonMargin}>
          <Spinner size={spinnerSize}/>
      </i>
  );
};
export default ButtonSpinner;
