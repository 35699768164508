import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from 'react-router-dom';
import {MainUserType} from "../../../redux/Main/User/types";

interface ItemProps {
    item: MainUserType;
}
const UserItem = ({ item }: ItemProps) => {

    return(
        <>
                <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={item.name}>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                {item.name}
                            </Tooltip>
                        }
                    >
                        <div className="avatar-xs">
                            { item.avatar
                                ?
                                <img src={item.avatar} alt="" className="rounded-circle img-fluid" />
                                :
                                <div className="avatar-title rounded-circle bg-light border-dashed border text-primary text-capitalize"> {item.name[0]} </div>
                            }
                        </div>
                    </OverlayTrigger>
                </Link>
        </>
    )
}

export default UserItem;