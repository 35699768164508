// types
import {ContentPeerUserActionTypes, ContentPeerUserState, ContentPeerUserType} from "./types";
import {ContentConnectorActionTypes} from "../Connector/types";

export const INIT_STATE_CONTENT_PEER_USER: ContentPeerUserState = {
  items: [],
  rightModalShow: false,
  unsubscribeModalShow: false,
  removeModalShow: false,
  modalEditTitle: ''
};

const ContentPeerUser = (state = INIT_STATE_CONTENT_PEER_USER, action: any) => {
  switch (action.type) {
    case ContentPeerUserActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentPeerUserActionTypes.GET_CONTENT_PEER_USERS:

          if ( state.selectedChannel !== undefined) {
            delete state.selectedChannel;
          }

          return {
            ...state,
            items: action.payload.data.items,
            isContentPeerUserFetched: true,
            getContentPeerUserLoading: false,
          };

        case ContentPeerUserActionTypes.GET_CONTENT_PEER_CHANNEL_MEMBERS:
          return {
            ...state,
            items: action.payload.data.items,
            selectedChannel: action.payload.data.channel,
            isContentPeerUserFetched: true,
            getContentPeerUserLoading: false,
          };
        case ContentPeerUserActionTypes.UNSUBSCRIBE_CONTENT_CHANNEL_MEMBER:
          return {
            ...state,
            unsubscribeModalShow: false
          };
        default:
          return { ...state };
      }

    case ContentPeerUserActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentPeerUserActionTypes.GET_CONTENT_PEER_USERS:
          return {
            ...state,
            isContentPeerUserFetched: false,
            getContentPeerUserLoading: false,
          };
        case ContentPeerUserActionTypes.GET_CONTENT_PEER_CHANNEL_MEMBERS:
          return {
            ...state,
            isContentPeerUserFetched: false,
            getContentPeerUserLoading: false,
          };
        default:
          return { ...state };
      }

    case ContentPeerUserActionTypes.UNSUBSCRIBE_MODAL_SHOW:

      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        unsubscribeModalShow: true
      }
    case ContentPeerUserActionTypes.UNSUBSCRIBE_MODAL_HIDE:
      return {
        ...state,
        unsubscribeModalShow: false
      }

    default:
      return { ...state };
  }
};

export default ContentPeerUser;
