import React, {useEffect, useState} from "react";

import { Input } from "reactstrap";

interface InputTextProps {
  value: null | string;
  id: string;
  placeholder: null | string;
  onChange: (value: string) => void;
  required: false | boolean;
  maxLength: null | number;
  className: null | string;
  autoFocus: false | boolean;
}
const InputPassword = ({ id, placeholder, value, onChange, required, maxLength, className, autoFocus}: InputTextProps) => {

    const [text, setText] = useState<string>(value || '');
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        setText(value);
    }, [value]);

    const onChangeText = (text:string) => {
        setText(text);
        onChange(text);
    }

  return (
      <div className="position-relative auth-pass-inputgroup">
        <Input
            type={passwordShown ? "text" : "password"}
            className={className || "form-control"}
            id={ id }
            placeholder={placeholder || ""}
            value={text || ''}
            onChange={(e: any) => {
                onChangeText(e.target.value);
            }}
            maxLength={maxLength || null}
            autoComplete="off"
            required={ required }
            autoFocus={autoFocus}
        />
          <button className="btn btn-link position-absolute top-0 end-0 text-decoration-none text-muted" type="button" tabIndex={-1} onClick={togglePassword}><i className="ri-eye-fill align-middle"/></button>
      </div>
  );
};
export default InputPassword;
