import React, { useState, useRef } from 'react';
import {Row} from 'reactstrap';
import {useRedux} from "../../../hooks";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {nameToEmoji} from "gemoji";
import {
    Formik,
    Form
} from 'formik';
import {NewRewardItem, RewardType} from "../../../data/reward";
import {RewardItemValidatorSchema} from "./validator";

interface ItemProps {
    onSend: (data: any, dispatch: any) => void;
    rewardItem: any;
    initScore: null|number;
}

const RewardFormSimple = ({onSend, rewardItem, initScore}:ItemProps) => {

    const { t, i18n } = useTranslation();
    const { dispatch, useAppSelector } = useRedux();
    const formRef = useRef(null);

    const [formValues, setFormValues] = useState<RewardType>(NewRewardItem);
    const [currentScore, setCurrentScore] = useState<number>(initScore);

    const handleScoreClick = (score:number) => {
        setCurrentScore(score);
        handleSubmit();
    }

    const onDataSubmit = (formData:RewardType) => {

        formData.score = currentScore;
        formData.uuid = rewardItem.uuid;

        onSend(formData, dispatch);
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.submitForm();
        }
    };

    return (
        <>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    innerRef={formRef}
                    validationSchema={RewardItemValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                        <Form noValidate={true}>
                            <Row>
                                <div className="d-flex flex-column">
                                                <div className="p-1">
                                                    <Row>
                                                        <div className="d-flex flex-row justify-content-center">
                                                            <div
                                                                className={"ratingSmile small me-3"+(currentScore===1 ? ' active':'')}
                                                                onClick={()=>{handleScoreClick(1)}}>{nameToEmoji.neutral_face}</div>
                                                            <div
                                                                className={"ratingSmile small me-3"+(currentScore===3 ? ' active':'')}
                                                                onClick={()=>{handleScoreClick(3)}}>{nameToEmoji.blush}</div>
                                                            <div
                                                                className={"ratingSmile small"+(currentScore===5 ? ' active':'')}
                                                                onClick={()=>{handleScoreClick(5)}}>{nameToEmoji.heart_eyes}</div>
                                                        </div>
                                                    </Row>
                                                </div>
                                </div>
                            </Row>
                    </Form>
                    )}
                </Formik>
        </>
    );
}

export default RewardFormSimple;