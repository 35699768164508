import {MetaType} from "./types";

export const newMetaItem: MetaType = {
  utmId:'',
  utmSource:'',
  utmCampaign:'',
  utmMedium:'',
  utmTerm:'',
  utmContent:''
};

