import React, {useEffect, useState} from 'react';
import {Modal, Button, ModalBody} from 'reactstrap';

import {
    Formik,
    Form,
    Field
} from 'formik';

import '../i18/config';
import {useTranslation} from "react-i18next";
import InputEmojiArea from "./InputEmojiArea";
import {ContentModalAddStringStepValidatorSchema} from "./ValidatorModalAddStringStep";
import {connectorHandlerCode} from "../constants/ConnectorHandler";

interface ModalAddValueStepProps {
    isShow : boolean,
    handleShow: ()=>void,
    onChange: (code:string) => void
}

interface ModalAddValueStepFormValues {
    value: string
}

const ModalAddValueStep = ({isShow, handleShow, onChange}:ModalAddValueStepProps) => {

    const { t } = useTranslation(['common']);

    const [isModalShow, setModalShow] = useState<boolean>(isShow);

    const [formValues, setFormValues] = useState<ModalAddValueStepFormValues>({value:''});

    useEffect(() => {
        setModalShow(isShow);
    },[isShow]);

    useEffect(() => {

        let newFormValues : ModalAddValueStepFormValues = {
            value:''
        };

        setFormValues({...newFormValues});

    },[]);


    const onDataSubmit = (formData:any) =>{
        onChange(formData.value)
    }

    return (
        <Modal
            isOpen={isModalShow}
            toggle={() => {
                handleShow();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <ModalBody className="p-5">

                    <h3 className="mb-3 text-center">{t('connector:enterCode')}</h3>

                    <div className="d-flex flex-grow-1 flex-row">
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ContentModalAddStringStepValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                        <>
                        <Form noValidate={true} className="w-100 customValidation">
                                    <div className={ "position-relative mb-3 w-100"+ (touched.value ? " was-validated" : '')}>
                                        <Field
                                            id={"form-value"}
                                            name={"value"}
                                            component={InputEmojiArea}
                                            value={getFieldProps('value').value}
                                            onChange={(value: string) => {
                                                setFieldValue('value', value);
                                            }}
                                            placeholder={t('connector:enterCodeDescriptionVk')}
                                            className="form-control bg-light border-light text-center"
                                            required={true}
                                        />
                                        {errors.value && touched.value ? (
                                            <div className="invalid-feedback">
                                                {t('validator:'+errors.value)}
                                            </div>
                                        ) : touched.value && !errors.value ?
                                            <div className="valid-feedback">
                                                {t('common:looksGood')}
                                            </div>
                                            : ''
                                        }
                                    </div>
                            <div className="hstack gap-2 justify-content-center mb-4">
                                <Button to="#" className="btn btn-light fw-medium" onClick={handleShow}>
                                    <i className="ri-close-line me-1 align-middle"/> {t('common:close')}</Button>
                                <Button to="#" className="btn btn-success" type="submit">{t('common:save')}</Button>
                            </div>

                            <div className="m-0 alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-3" role="alert">

                                <i className="ri-error-warning-line me-2 align-middle"/> <strong className="pe-2">{t('common:attention')}!</strong>

                                {t('connector:serviceConnectionGPDRDescription')}


                            </div>
                        </Form>
                        </>
                    )}
                </Formik>
                    </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalAddValueStep;