// types
import {MainRoleActionTypes, MainRoleState, MainRoleType} from "./types";
import {NewEmptyActionsItem} from "../../types";


export const INIT_STATE_MAIN_ROLE: MainRoleState = {
  items: [],
};

const MainRole = (state = INIT_STATE_MAIN_ROLE, action: any) => {
  switch (action.type) {
    case MainRoleActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MainRoleActionTypes.GET_MAIN_ROLES:
          return {
            ...state,
            items: action.payload.data.items,
            isMainRoleFetched: true,
            getMainRoleLoading: false,
          };
        default:
          return { ...state };
      }

    case MainRoleActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case MainRoleActionTypes.GET_MAIN_ROLES:
          return {
            ...state,
            isMainRoleFetched: false,
            getMainRoleLoading: false,
          };
        default:
          return { ...state };
      }

    default:
      return { ...state };
  }
};

export default MainRole;
