import {SaleIntegrationActionTypes, SaleIntegrationPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleIntegrationApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleIntegrationActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleIntegrationApiResponseError = (actionType: string, error: string) => ({
  type: SaleIntegrationActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleIntegrations = (data: MainMetaType) => ({
  type: SaleIntegrationActionTypes.GET_SALE_INTEGRATIONS,
  payload: data,
});

export const showRightModalSaleIntegration= (data: SaleIntegrationPayload) => ({
  type: SaleIntegrationActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleIntegration= () => ({
  type: SaleIntegrationActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleIntegration = (data: any) => ({
  type: SaleIntegrationActionTypes.UPDATE_SALE_INTEGRATION,
  payload: data,
});

export const editDataSaleIntegration = (data: SaleIntegrationPayload) => ({
  type: SaleIntegrationActionTypes.EDIT_SALE_INTEGRATION,
  payload: data,
});

export const removeSaleIntegrationModalShow = (data: SaleIntegrationPayload) => ({
  type: SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION_MODAL_SHOW,
  payload: data,
});

export const removeSaleIntegrationModalHide = () => ({
  type: SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION_MODAL_HIDE,
});

export const onRemoveSaleIntegration = (data: SaleIntegrationPayload) => ({
  type: SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION,
  payload: data,
});

export const getSaleProductsIntegrationParamFromRemote = (data:any) => ({
  type: SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE,
  payload: data,
});

export const responseSaleProductsIntegrationParamFromRemote = (data:any) => (
    saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE, data)
);

export const requestSaleIntegrationImport = (data:any) => ({
  type: SaleIntegrationActionTypes.REQUEST_SALE_INTEGRATION_IMPORT,
  payload: data,
});

export const onInvalidateCacheSaleIntegration = (data: SaleIntegrationPayload) => ({
  type: SaleIntegrationActionTypes.INVALIDATE_CACHE_SALE_INTEGRATION,
  payload: data,
});

export const getSaleProductsIntegrationMetaBusinessManagersFromRemote = (data:any) => ({
  type: SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE,
  payload: data,
});

export const getSaleProductsIntegrationMetaProductCatalogsFromRemote = (data:any) => ({
  type: SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE,
  payload: data,
});

export const responseSaleProductsIntegrationMetaBusinessManagersFromRemote = (data:any) => (
    saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE, data)
);

export const responseSaleProductsIntegrationMetaProductCatalogsFromRemote = (data:any) => (
    saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE, data)
);