import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const sendAITextGenerateRequest = (data:any) => {
  return api.create(m9sGlobals.CURRENT_CABINET+url.AI_TEXT_REQUEST, {'aiTextGenerate':data});
};

export {
    sendAITextGenerateRequest,
};
