import React, {useEffect, useState} from "react";
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {Link} from "react-router-dom";

import { Icon } from "ts-react-feather-icons";
import { BiKey } from "react-icons/bi";

import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import StatItem from "../../../components/StatItem";
import * as route from "../../../api/routes"
import ChannelItemBigLine from "./channelItemBigLine";
import {MainIcon} from "../../../redux/Main/icon";
import {ContentPeerTargetStatusTypes, ContentPeerTargetType} from "../../../redux/Content/PeerTarget/types";
import {useRedux} from "../../../hooks";
import {showRightModalContentPeerTarget} from "../../../redux/Content/PeerTarget/actions";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";
import {addToComparePeerChannel} from "../../../redux/Stat/ComparePeerChannel/actions";
import {handleCreateNewTaskByConnector} from "../Connector/ContentConnectorActions";
import {convertToMomentLocale} from "../../../helpers/functions";


interface ItemProps {
    item: ContentPeerChannelType;
    onEdit?: (event:any) => void;
    onRemove?: (event:any) => void;
    onConnect?: (event:any) => void;
}
const ListItem = ({ item, onEdit, onRemove, onConnect }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));
    const { dispatch } = useRedux();

    const showActions = (item.actions &&
        (
            item.actions.canEdit
            ||
            item.actions.canRemove
            ||
            item.actions.canTargetAdd
        ));

    let successTargetCount = 0;
    let failTargetCount = 0;
    let progressTargetCount = 0;

    (item.targets || []).map((targetItem:ContentPeerTargetType)=>{
        if (targetItem.targetStatus === ContentPeerTargetStatusTypes.SUCCESS){
            successTargetCount++;
        } else if (targetItem.targetStatus === ContentPeerTargetStatusTypes.PROGRESS){
            progressTargetCount++;
        } else if (targetItem.targetStatus === ContentPeerTargetStatusTypes.FAIL){
            failTargetCount++;
        }
        return targetItem;
    });

    const onTargetAdd = () => {
        let data = [];
        data['channel']=item;

        dispatch(showRightModalContentPeerTarget({data:data, modalEditTitle: 'newTarget'}));
    }

    const onAddToCompare = () =>{
        dispatch(addToComparePeerChannel(item));
    }
    
    return (
            <Card className="card-height-100">
                <CardBody>
                    <div className="d-flex flex-column h-100">
                        <div className="d-flex mb-4">
                            <div className="flex-grow-1 d-flex align-items-center mw-0">
                                <p className="text-muted mb-0 text-truncate">
                                    {t('common:lastUpdate')}:
                                    { item.stat && item.stat.dateTime ?
                                        <span className="ms-1">{moment(item.stat.dateTime).fromNow()}</span>
                                        :
                                        <span className="ms-1">{t('common:never')}</span>
                                    }

                                </p>
                            </div>
                            <div className="flex-shrink-0">
                                <div className="d-flex align-items-center">
                                    {/*<button type="button" className="btn avatar-xs p-0 favourite-btn active">*/}
                                    {/*                    <span className="avatar-title bg-transparent fs-15">*/}
                                    {/*                        <i className="ri-star-fill"></i>*/}
                                    {/*                    </span>*/}
                                    {/*</button>*/}
                                    <div className="d-flex align-items-center">
                                        { item.isNeedAccessToken === true ?
                                            item.accessTokenOAuthUrl ?
                                                    item.isFetchAccessTokenOAuthURL && item.isFetchAccessTokenOAuthURL === true ?
                                                        <Loader addClassNames="sm d-inline-block me-2"/>
                                                        :
                                                        <OverlayTrigger
                                                            placement={'right'}
                                                            delay={{show: 250, hide: 400}}
                                                            overlay={
                                                                <Tooltip>
                                                                    {t('channel:channelKeyNeeded')}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <span className="position-relative">
                                                            <Link to="#" onClick={() => onConnect(item)}>
                                                            <BiKey  className="text-danger fs-16 me-2"/>
                                                            </Link>
                                                        </span>

                                                        </OverlayTrigger>
                                                :
                                                <OverlayTrigger
                                                    placement={'right'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('channel:channelKeyActive')}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="position-relative">
                                                        <BiKey className="text-success fs-16 me-2"/>
                                                    </span>
                                                </OverlayTrigger>
                                            :''
                                        }

                                        { (Object(item).hasOwnProperty('isConnectorConnected') && item.connector.isConnected === false)
                                            ?
                                            <span className="badge badge-outline-dark">{t('common:offline')}</span>
                                            :

                                        item.isInPostsUpdate ?
                                            <span className="badge bg-info">{t('common:init')}</span>
                                            :
                                            item.enabled ? <span className="badge bg-success">{t('common:active')}</span> :
                                                <span className="badge bg-danger">{t('common:inActive')}</span>

                                        }
                                    </div>
                                    <div className="d-flex">
                                        <UncontrolledDropdown direction="start">
                                            <DropdownToggle tag="a" className="btn btn-sm btn-link text-muted text-decoration-none fs-15">
                                                <Icon name="more-horizontal" />
                                            </DropdownToggle>

                                            <DropdownMenu className="dropdown-menu-end">

                                                <DropdownItem tag="a" href={route.CONTENT_CHANNEL_ACTIVITIES.replace(/:uuidChannel/g, item.uuid)} ><i className="ri-stack-line align-bottom text-muted  me-2"/> {t('peerActivity:activities')} </DropdownItem>
                                                <DropdownItem tag="a" href={route.STAT_CONTENT_CHANNEL.replace(/:uuidChannel/g, item.uuid)} ><i className="ri-pie-chart-line align-bottom text-muted  me-2"/> {t('stat:stat')} </DropdownItem>
                                                <DropdownItem tag="a" href={route.CONTENT_ACTIVITY_PLAN} ><i className="ri-pages-line align-bottom text-muted  me-2"/> {t('peerActivity:activitiesPlan')} </DropdownItem>
                                                { item.stat && item.stat.memberCount>0 &&
                                                <DropdownItem tag="a" href={route.CONTENT_CHANNEL_MEMBERS.replace(/:uuidChannel/g, item.uuid)} ><i className="ri-group-line align-bottom text-muted me-2"/> {t('common:members')}</DropdownItem>
                                                }

                                                {showActions && onEdit && onRemove &&
                                                    <DropdownItem divider/>
                                                }
                                                { item.isNeedAccessToken===true &&
                                                    item.accessTokenOAuthUrl &&
                                                <DropdownItem className='edit-item-btn' onClick={() => onConnect(item)}>
                                                    <BiKey className="text-danger fs-16 me-1"/> {t('channel:getChannelKey')}
                                                </DropdownItem>
                                                }

                                                {item.actions && item.actions.canEdit && onEdit &&
                                                <DropdownItem className='edit-item-btn' onClick={() => onEdit(item)}><i
                                                    className="ri-pencil-fill align-bottom text-muted me-2"/> {t('common:edit')}
                                                </DropdownItem>
                                                }
                                                <DropdownItem className='edit-item-btn' onClick={() => handleCreateNewTaskByConnector(item, dispatch)}><i
                                                    className="ri-survey-line align-bottom text-muted me-2"/> {t('task:addNewTask')}</DropdownItem>
                                                {item.actions && item.actions.canTargetAdd &&
                                                <DropdownItem className='edit-item-btn' onClick={onTargetAdd}><i
                                                    className="ri-pencil-ruler-2-line align-bottom text-muted me-2"/> {t('target:newTarget')}
                                                </DropdownItem>
                                                }
                                                {item.actions && item.actions.canRemove && onRemove &&
                                                <DropdownItem className='remove-item-btn'
                                                              onClick={() => onRemove(item)}><i
                                                    className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')}
                                                </DropdownItem>
                                                }

                                                <DropdownItem className='add-to-compare-item-btn'
                                                              onClick={onAddToCompare}><i
                                                    className="bx bx-bar-chart-alt-2 align-middle me-2 text-muted"/>{t('compare:collate')}
                                                </DropdownItem>

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="mb-2">
                        <ChannelItemBigLine item={item} />
                        </div>


                    </div>
                </CardBody>
                {item.stat &&

                    (
                        (
                            (Object.keys(item.stat).length>1)
                            &&
                            (item.hasOwnProperty('timestamp'))
                        )
                        ||
                        (
                            ((Object.keys(item.stat) || []).filter((key:string)=>{return key!=='dateTime'}).length>0)
                            &&
                            (!item.hasOwnProperty('timestamp'))
                        )
                    ) &&

                <div className="card-footer bg-transparent border-top-dashed py-2">
                    <div className="d-flex">
                        <div>
                            <ul className="link-inline mb-0 ps-0">
                                {item.stat.memberCount > 0 &&
                                <StatItem value={item.stat.memberCount} icon={"align-bottom "+MainIcon.MEMBERS}/>
                                }
                                { item.stat.followsCount > 0 &&
                                <StatItem value={item.stat.followsCount} icon={"align-bottom "+MainIcon.FOLLOWS}/>
                                }
                                {item.stat.views > 0 &&
                                <StatItem value={item.stat.views} icon={"align-bottom "+MainIcon.VIEWS}/>
                                }
                                {item.stat.likes > 0 &&
                                <StatItem value={item.stat.likes} icon={"align-bottom "+MainIcon.LIKES}/>
                                }
                                {item.stat.comments > 0 &&
                                <StatItem value={item.stat.comments} icon={"align-bottom "+MainIcon.COMMENTS}/>
                                }
                            </ul>
                            <ul className="link-inline mb-0 ps-0">
                                {item.stat.er > 0 &&
                                <StatItem value={item.stat.er} label="ER"/>
                                }
                                {item.stat.erDay > 0 &&
                                <StatItem value={item.stat.erDay} label="ERDay"/>
                                }
                                {item.stat.erView > 0 &&
                                <StatItem value={item.stat.erView} label="ERView"/>
                                }
                            </ul>
                            {item.targets && item.targets.length>0 &&
                                <ul className="link-inline mb-0 ps-0">

                                    <li className="list-inline-item">
                                        <span className="text-info me-1"><i className="ri-pencil-ruler-2-line"/></span>
                                        <span className="text-muted">{progressTargetCount}</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <span className="text-danger me-1"><i className="ri-pencil-ruler-2-line"/></span>
                                        <span className="text-muted">{failTargetCount}</span>
                                    </li>
                                    <li className="list-inline-item">
                                        <span className="text-success me-1"><i className="ri-pencil-ruler-2-line"/></span>
                                        <span className="text-muted">{successTargetCount}</span>
                                    </li>
                                </ul>
                            }
                        </div>

                    </div>
                </div>
                }
            </Card>
    );
};

export default ListItem;
