import {ContentConnectorType} from "../Connector/types";
import {ContentProjectType} from "../Project/types";
import {EntityActionsType} from "../../types";
import {ContentPeerUserType} from "../PeerUser/types";
import {ContentPeerTargetType} from "../PeerTarget/types";

export enum ContentPeerChannelActionTypes {
  API_RESPONSE_SUCCESS = "@@contentPeerChannel/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentPeerChannel/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_CHANNELS = "@@contentPeerChannel/GET_CONTENT_PEER_CHANNELS",
  UPDATE_CONTENT_PEER_CHANNEL = "@@contentPeerChannel/UPDATE_CONTENT_PEER_CHANNELS",

  CONNECT_CONTENT_PEER_CHANNEL = "@@contentPeerChannel/CONNECT_CONTENT_PEER_CHANNEL",
  CONNECT_CONTENT_PEER_CHANNEL_CANCEL = "@@contentPeerChannel/CONNECT_CONTENT_PEER_CHANNEL_CANCEL",
  ADD_CONTENT_PEER_CHANNEL = "@@contentPeerChannel/ADD_CONTENT_PEER_CHANNEL",
  REMOVE_CONTENT_PEER_CHANNEL_MODAL_SHOW = "@@contentPeerChannel/REMOVE_CONTENT_PEER_CHANNEL_MODAL_SHOW",
  REMOVE_CONTENT_PEER_CHANNEL_MODAL_HIDE = "@@contentPeerChannel/REMOVE_CONTENT_PEER_CHANNEL_MODAL_HIDE",
  REMOVE_CONTENT_PEER_CHANNEL = "@@contentPeerChannel/REMOVE_CONTENT_PEER_CHANNEL",
  EDIT_CONTENT_PEER_CHANNEL = "@@contentPeerChannel/EDIT_CONTENT_PEER_CHANNEL",

  RIGHT_MODAL_SHOW = "@@contentPeerChannel/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@contentPeerChannel/RIGHT_MODAL_HIDE",

  CONTENT_PEER_CHANNEL_NOT_FOUND = "@@contentPeerChannel/CONTENT_PEER_CHANNEL_NOT_FOUND",
}

export interface ContentPeerChannelState {
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: ContentPeerChannelType;
  modalEditTitle: string;
  requestId? : string;
  accessTokenOAuthURL? :string;
  connectModalShow: boolean;
  isNeedReload: boolean;
  isSending: boolean;
  isFetched: boolean,
  getContentPeerChannelLoading: boolean,
}

export interface ContentPeerChannelType{
  uuid?: string,
  enabled: boolean,
  name: string,
  hashtag?: string,
  avatar?: string,
  isManaged: boolean,
  idPeer?: string,
  type?:string,
  order:number,
  postsCount?: number,
  channelLink?: string,
  project?: ContentProjectType,
  connector?: ContentConnectorType,
  targets?:Array<ContentPeerTargetType>,
  isInitUpdate?: boolean,
  isInPostsUpdate?: boolean,
  isNeedAccessToken?: boolean,
  isConnectorConnected?: boolean,
  accessTokenOAuthUrl?: string,
  dateTime?: string,
  stat?:ContentPeerChannelStatType,
  actions?: EntityActionsType,
  members?: Array<ContentPeerUserType>
  eTag?: string;
  isFetchAccessTokenOAuthURL?: boolean;
  isUpdating?:boolean;
  isReadOnly?:boolean;
  isExportRSS?:boolean;
  link?: string;
}

export interface ContentPeerChannelStatType{
  memberCount?:number,
  followsCount?:number,
  views?:number,
  likes?:number,
  comments?:number,
  er?:number,
  erDay?:number,
  erView?:number
  dateTime?: string,
}

export interface ContentPeerChannelPayload {
  data: Array<any>;
  modalEditTitle : string;
}
