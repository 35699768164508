import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentPeerTargetTypeActionTypes } from "./types";
import { contentPeerTargetTypeApiResponseSuccess, contentPeerTargetTypeApiResponseError } from "./actions";

import {
  getContentPeerTargetTypes as getContentPeerTargetTypesApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getContentPeerTargetTypes() {
  try {
    const response: Promise<any> = yield call(getContentPeerTargetTypesApi);
    yield put(contentPeerTargetTypeApiResponseSuccess(ContentPeerTargetTypeActionTypes.GET_CONTENT_PEER_TARGET_TYPES, response));
  } catch (error: any) {
    yield put(contentPeerTargetTypeApiResponseError(ContentPeerTargetTypeActionTypes.GET_CONTENT_PEER_TARGET_TYPES, error));
  }
}

export function* watchGetContentPeerTargetTypes() {
  yield takeEvery(ContentPeerTargetTypeActionTypes.GET_CONTENT_PEER_TARGET_TYPES, getContentPeerTargetTypes);
}


function* contentPeerTargetTypeSaga() {
  yield all([
    fork(watchGetContentPeerTargetTypes),
  ]);
}

export default contentPeerTargetTypeSaga;
