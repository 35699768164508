import {
    editDataContentPeerChannel,
    getContentPeerChannels, onConnectContentPeerChannel,
    onRemoveContentPeerChannel,
    removeContentPeerChannelModalHide,
    removeContentPeerChannelModalShow,
    showRightModalContentPeerChannel,
} from "../../../redux/Content/PeerChannel/actions";
import {ContentConnectorType} from "../../../redux/Content/Connector/types";
import {TaskType} from "../../../redux/Task/Task/types";
import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {handleTaskEdit} from "../../Task/Task/TaskActions";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";

const ContentPeerChannelOpenModal = (event:any, dispatch:any) => {
    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(editDataContentPeerChannel({data:event, modalEditTitle: 'changeChannel'}));
    } else {
        dispatch(showRightModalContentPeerChannel({data:[], modalEditTitle: 'newChannel'}));
    }
}

const ContentPeerChannelOpenRemoveModal = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(removeContentPeerChannelModalShow({data:event, modalEditTitle: 'deleteChannel'}));
    }

}

const ContentPeerChannelOnRemove = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(onRemoveContentPeerChannel({data:event, modalEditTitle: 'deleteChannel'}));

        setTimeout(() => {
            dispatch(getContentPeerChannels());
        }, 1000);
    }

}

const ContentPeerChannelOnConnect = (event:any, dispatch:any) => {
    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(onConnectContentPeerChannel({data:event, modalEditTitle: 'connectChannel'}));
    }
}


const ContentPeerChannelHideRemoveModal = (dispatch:any) => {

    dispatch(removeContentPeerChannelModalHide())

}

export const handleCreateNewTaskByChannel = (entity: ContentPeerChannelType, dispatch: any) => {
    let newTask:TaskType = NewTaskItem;

    newTask = {
        ...newTask,
        text: entity?.name && entity.name
    }

    handleTaskEdit(newTask, dispatch);
}

export {
    ContentPeerChannelOpenModal,
    ContentPeerChannelOpenRemoveModal,
    ContentPeerChannelOnRemove,
    ContentPeerChannelHideRemoveModal,
    ContentPeerChannelOnConnect
}