import React, {useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {PaymentType, PaymentStatus} from "../../../redux/Sale/Payment/types";
import {LocaleCurrencySymbol} from "../../../redux/Sale/Currency/types";

interface ItemProps {
    item: PaymentType;
    paymentUrl?: null | string;
}
const PaymentItem = ({ item, paymentUrl }: ItemProps) => {

    const { t } = useTranslation(['plan']);

    return (
        <>
            <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                    <p className="text-muted mb-0">{t('sale:paymentMethod')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">{item.paymentSystem.name}</h6>
                </div>
            </div>


            <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                    <p className="text-muted mb-0">{t('sale:totalAmount')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">{LocaleCurrencySymbol[item.currency.code]}{item.amount}</h6>
                </div>
            </div>


            <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                    <p className="text-muted mb-0">{t('sale:paymentStatus')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                    {// eslint-disable-next-line
                        (Object.keys(PaymentStatus) as Array<keyof typeof PaymentStatus>).map((key) => {
                            if (PaymentStatus[key].status===item.status){
                            return (
                                <span key={'tickerStatus_'+item.status} className={"badge rounded-pill fs-12 text-bg-"+PaymentStatus[key].class}> {t('support:ticketStatus'+PaymentStatus[key].label)} </span>
                            )}
                            }
                        )
                    }
                </div>
            </div>

            {item.status !== PaymentStatus.FINAL.status &&  paymentUrl &&
                <div className="d-flex align-items-center mt-3">
                    <div className="flex-shrink-0">
                        <a
                            href={paymentUrl}
                            target="_blank"
                            className="btn btn-success btn-sm"
                            rel = "noreferrer"
                        >
                            <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                            {t('sale:invoiceLink')}
                        </a>
                    </div>
                </div>
            }
        </>
    )
}

export default PaymentItem;