import React, { useState, useEffect } from 'react';
import {useRedux} from "../../../hooks";
import {
    Col, Label, Row, Spinner,
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import ButtonSpinner from "../../../components/ButtonSpinner";
import {TaskTimeValidatorSchema} from "./validator";
import {TaskTimeType} from "../../../redux/Task/TaskTime/types";
import InputText from "../../../components/InputText";
import {isNumber} from "lodash";

interface InputProps {
    item: TaskTimeType;
    isSending: boolean;
    onSend: (data:any)=>any,
    onClose: ()=>void
}

const TaskTimeForm = ({item, isSending, onSend, onClose}:InputProps) => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();

    const uuid = item.uuid || null;

    const [formValues, setFormValues] = useState<TaskTimeType>(item);

    useEffect(() => {
        setFormValues(item);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, dispatch, item]);

    const onDataSubmit = (formData:TaskTimeType) => {

        let params: TaskTimeType = {
            uuid: formData.uuid && formData.uuid,
            enabled: formData.enabled && formData.enabled,
            text: formData.text && formData.text,
            minutes: formData.minutes && formData.minutes,
            owner: item.owner && item.owner,
            actions: item.actions && item.actions
        };

        if (item?.task !== undefined && item?.task?.uuid !== null){
            params = {
                ...params,
                task:{
                    uuid:item.task.uuid
                }
            }
        }

        dispatch(onSend(params));
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={TaskTimeValidatorSchema}
                onSubmit={(values, actions) => {
                    onDataSubmit(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation d-flex flex-grow-1 flex-column">

                        <Row>
                        <div className="d-flex flex-row flex-nowrap">
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-row">
                                    <div className="me-2">
                                        <Field
                                            tabIndex={1}
                                            id="form-hrs-input"
                                            name="hrs"
                                            component={InputText}
                                            value = {getFieldProps('minutes').value !== undefined && getFieldProps('minutes').value !== null && getFieldProps('minutes').value / 60 | 0}
                                            onChange={(value:string) => {
                                                let h:number = 0;
                                                let m:number = 0;

                                                let mins = getFieldProps('minutes').value;

                                                if (mins !== undefined && mins !== null){
                                                    h = mins / 60 | 0
                                                    m = mins % 60 | 0
                                                }

                                                if (value.length>0 && isNumber(parseInt(value))) {
                                                    h = parseInt(value);
                                                } else {
                                                    h = 0;
                                                }


                                                setFieldValue('minutes', h*60+m);
                                            }}
                                            pattern={"[0-9]"}
                                            required={true}
                                            placeholder={t('common:hrs')}
                                        />
                                    </div>
                                    <div className="me-2">
                                        <Field
                                            tabIndex={2}
                                            id="form-mins-input"
                                            name="mins"
                                            component={InputText}
                                            value = {getFieldProps('minutes').value !== undefined && getFieldProps('minutes').value !== null && getFieldProps('minutes').value % 60 | 0}
                                            onChange={(value:string) => {
                                                let h:number = 0;
                                                let m:number = 0;

                                                let mins = getFieldProps('minutes').value;

                                                if (mins !== undefined && mins !== null){
                                                    h = mins / 60 | 0
                                                    m = mins % 60 | 0
                                                }

                                                if (value.length>0 && isNumber(parseInt(value))) {
                                                    m = parseInt(value);
                                                } else {
                                                    m = 0;
                                                }

                                                setFieldValue('minutes', h*60+m);
                                            }}
                                            pattern={"[0-9]"}
                                            required={true}
                                            placeholder={t('common:min')}
                                        />
                                    </div>
                                </div>
                                {errors.minutes && touched.minutes ? (
                                    <div className="invalid-feedback">
                                        {t('validator:'+errors.minutes)}
                                    </div>
                                ) :  touched.minutes && !errors.minutes ?
                                    <div className="valid-feedback">
                                        {t('common:looksGood')}
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="d-flex flex-column flex-grow-1">
                                <Field
                                    tabIndex={3}
                                    id="form-text-input"
                                    name="text"
                                    component={InputText}
                                    value = {getFieldProps('text').value}
                                    onChange={(text:string) => {
                                           setFieldValue('text', text);
                                    }}
                                    required={true}
                                    placeholder={t('comment:comment')}
                                    className={"flex-grow-1"}
                                />
                            </div>
                            <div className="d-flex flex-row flex-nowrap">
                                <button
                                    onClick={()=>{onClose()}}
                                    title={t('common:close')}
                                    className="btn btn-outline-primary btn-icon waves-effect waves-light ms-2">
                                    <i className="ri-close-line"/>
                                </button>
                                <button
                                    type="submit"
                                    disabled={isSending}
                                    title={t('common:Add')}
                                    className="btn btn-outline-success btn-icon waves-effect waves-light ms-2">
                                    {isSending ?
                                        <ButtonSpinner className={'ms-0'}/>
                                        :
                                        <i
                                            className="ri-check-double-line label-icon align-middle fs-16"/>
                                    }
                                </button>
                            </div>
                        </div>
                        </Row>

                    </Form>
                )}
            </Formik>
        </>
    );
}

export default TaskTimeForm;