import React from 'react';
import * as Yup from 'yup';
import {EMAIL_REGEX} from "../../../data/settings";

export const ResetFormValidatorSchema = Yup.object().shape({
    email: Yup.string()
        .email("invalidEmail")
        .min(2, 'tooShort')
        .matches(EMAIL_REGEX, "invalidEmail")
        .required("invalidEmail"),
});