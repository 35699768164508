const messengerIncomeAudio = require("../resources/media/newMessage.mp3");

const newMessageAudio = () => {

    let audio = new Audio(messengerIncomeAudio);

    let promise = audio.play();
    audio.loop = false;

    if (promise) {
        promise.catch(function(error) { console.error(error); });
    }

}


export { newMessageAudio }
