import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";

// hooks
import { useRedux } from "../../../hooks";

import {
    editDataSalePaymentSystem,
    getSalePaymentSystems,
    onRemoveSalePaymentSystem,
    removeSalePaymentSystemModalHide,
    removeSalePaymentSystemModalShow,
    showRightModalSalePaymentSystem
} from "../../../redux/actions";

import TableList from "../../../components/TableList";
import SalePaymentSystemColumns from "./tableColumns";
import {PaymentSystemType} from "../../../redux/Sale/PaymentSystem/types";
import {EntityActionsHandlersType} from "../../../redux/types";
import RemoveModal from "../../../components/RemoveForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import Loader from "../../../components/Loader";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {getHistoryHash, setHistoryHash as setHistoryHashFunc} from "../../../helpers/functions";
import {MainMetaType} from "../../../redux/Main/common";
import MainListFilter from "../../../components/MainListFilter";

const SalePaymentSystemIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const componentType = 'salePaymentSystemTable';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [searchPhrase, setSearchPhrase] = useState<string>('');

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults ? historyHash.maxResults : perPage,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection,
            searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length>0 && historyHash.searchPhrase
        }
        dispatch(getSalePaymentSystems(mainMeta));
    }

    useEffect(() => {
        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        loadItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        salePaymentSystem,
        isFetched
    } = useAppSelector(state => ({
        salePaymentSystem: state.SalePaymentSystem,
        isFetched: state.SalePaymentSystem.isFetched
    }));

    const openRightModal = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(editDataSalePaymentSystem({data:event, modalEditTitle: 'changePaymentSystem'}));
        } else {
            dispatch(showRightModalSalePaymentSystem({data:[], modalEditTitle: 'newPaymentSystem'}));
        }
    };

    const onEntityRemoveModalShow = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(removeSalePaymentSystemModalShow({data:event, modalEditTitle: 'deletePaymentSystem'}));
        }
    };

    const onEntityRemoveModalHide = () => dispatch(removeSalePaymentSystemModalHide());

    const onEntityRemove = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(onRemoveSalePaymentSystem({data:event, modalEditTitle: 'deletePaymentSystem'}));
        }
    };

    useEffect(() => {
        if(salePaymentSystem.isNeedReload){
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, salePaymentSystem.isNeedReload]);

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onEdit: openRightModal,
        onRemove: onEntityRemoveModalShow
    }

    const onTableRowClick = (item:PaymentSystemType) => {
        if (item.actions && item.actions.canEdit){
            openRightModal(item);
        }
    }

    const columns = SalePaymentSystemColumns(EntityActionsHandlers);


    const handleSearchClick = (value:string) => {
        setSearchPhrase(value);
        if ( historyHash.searchPhrase !== value) {
            historyHash.searchPhrase = value;
            setHistoryHashFunc(historyHash);
        }
    }

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }

    return (
        <>
            <BreadCrumb title={t('menu:paymentSystems')} pageTitle={t('menu:main')} />
                <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary" onClick={openRightModal} className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="border-bottom-0">
                                <h5 className="card-title mb-0">{t('payment:paymentSystems')}</h5>
                            </CardHeader>
                            <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                                <MainListFilter
                                    onClick={handleSearchClick}
                                    value={searchPhrase}
                                    onChange={onSearchInput}
                                />
                            </CardBody>
                            <CardBody>
                                {isFetched ?
                                    <TableList
                                    columns={columns}
                                    data={salePaymentSystem.items}
                                    onRowClick={onTableRowClick}
                                    key={componentType}
                                    historyHash = {historyHash}
                                    paginationTotalRows={salePaymentSystem?.meta?.totalCount || 0}
                                    paginationServer={true}
                                    isShowChangePageSize={true}
                                    />
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <RightModal/>
                <RemoveModal
                    onRemove={onEntityRemove}
                    handleClose={onEntityRemoveModalHide}
                    removeItem={salePaymentSystem.selectedItem}
                    isOpen={salePaymentSystem.removeModalShow}
                    modalTitle={salePaymentSystem.modalEditTitle}/>
        </>
    );
};

export default SalePaymentSystemIndex;


