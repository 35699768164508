import { all } from "typed-redux-saga";

import contentProjectsSaga from "./Content/Project/saga";
import contentTemplatesSaga from "./Content/Template/saga";
import contentQuickAnswerSaga from "./Content/QuickAnswer/saga";
import peerActivityTypeSaga from "./Content/PeerActivityType/saga";
import contentPeerActivityModeSaga from "./Content/PeerActivityMode/saga";
import contentPeerTargetTypeSaga from "./Content/PeerTargetType/saga";
import contentPeerTargetSaga from "./Content/PeerTarget/saga";
import contentConnectorHandlerSaga from "./Content/ConnectorHandler/saga";
import contentConnectorDataParamSaga from "./Content/ConnectorDataParam/saga";
import contentConnectorSaga from "./Content/Connector/saga";
import contentPeerChannelSaga from "./Content/PeerChannel/saga";
import contentPeerUserSaga from "./Content/PeerUser/saga";
import contentStatCounterSaga from "./Content/StatCounter/saga";
import peerActivitySaga from "./Content/PeerActivity/saga";
import peerActivityIdeaSaga from "./Content/PeerActivityIdea/saga";

import mainDeviceSaga from "./Main/Device/saga";
import mainRolesSaga from "./Main/Role/saga";
import mainUsersSaga from "./Main/User/saga";
import mainSearchSaga from "./Main/Search/saga";
import mainVersionHistoriesSaga from "./Main/VersionHistory/saga";
import mainCabinetsSaga from "./Main/Cabinet/saga";
import loginSaga from "./Main/Login/saga";
import layoutSaga from "./Main/Layout/saga";
import mainCurrentCountrySaga from "./Main/Country/saga";
import commentSaga from "./Main/Comment/saga";

import eventNotificationSaga from "./Event/Notification/saga";
import eventEventUserSubscriptionSaga from "./Event/EventSubscribeUser/saga";
import eventUserBadgeSaga from "./Event/Badge/saga";
import eventWebhookSaga from "./Event/Webhook/saga";

import dashboardContentSaga from "./Stat/DashboardContent/saga";
import dashboardSaleSaga from "./Stat/DashboardSale/saga";
import statPeerChannelContentSaga from "./Stat/PeerChannel/saga";
import statPeerActivityContentSaga from "./Stat/PeerActivity/saga";
import comparePeerChannelSaga from "./Stat/ComparePeerChannel/saga";
import dashboardTaskSaga from "./Stat/DashboardTask/saga";

import salePlanSaga from "./Sale/Plan/saga";
import planOrderSaga from "./Sale/PlanOrder/saga";
import paymentSystemSaga from "./Sale/PaymentSystem/saga";
import saleLicenseSaga from "./Sale/License/saga";
import saleStoreSaga from "./Sale/Store/saga";
import saleCurrencySaga from "./Sale/Currency/saga";
import saleCurrencyRateSaga from "./Sale/CurrencyRate/saga";
import salePriceTypeSaga from "./Sale/PriceType/saga";
import saleDeliverySystemSaga from "./Sale/DeliverySystem/saga";
import saleCategorySaga from "./Sale/Category/saga";
import saleProductParamSaga from "./Sale/ProductParam/saga";
import saleProductSaga from "./Sale/Product/saga";
import saleIntegrationSaga from "./Sale/Integration/saga";
import saleIntegrationHandlerSaga from "./Sale/IntegrationHandler/saga";
import saleCustomerSaga from "./Sale/Customer/saga";
import saleOrderStatusSaga from "./Sale/OrderStatus/saga";
import saleOrderSaga from "./Sale/Order/saga";
import saleOrderDeliveryItemSaga from "./Sale/Delivery/saga";
import saleOrderPaymentSaga from "./Sale/Payment/saga";
import saleOrderCustomerSaga from "./Sale/OrderCustomer/saga";
import couponSaga from "./Sale/Coupon/saga";

import messengerChatSaga from "./Messenger/Chat/saga";
import messengerCommentSaga from "./Messenger/Comment/saga";
import messengerContactSaga from "./Messenger/Contact/saga";

import supportTicketsSaga from "./Support/Ticket/saga";
import supportTicketMessagesSaga from "./Support/TicketMessage/saga";

import aiTextGenerateSaga from "./AI/TextGenerate/saga";
import urlShortenerSaga from "./Shortener/UrlShortener/saga";
import taskSaga from "./Task/Task/saga";

export default function* rootSaga() {
  yield all([
    contentProjectsSaga(),
    contentTemplatesSaga(),
    contentQuickAnswerSaga(),
    peerActivityTypeSaga(),
    contentPeerActivityModeSaga(),
    contentPeerTargetTypeSaga(),
    contentPeerTargetSaga(),
    contentConnectorHandlerSaga(),
    contentConnectorDataParamSaga(),
    contentConnectorSaga(),
    contentPeerChannelSaga(),
    contentPeerUserSaga(),
    contentStatCounterSaga(),
    peerActivitySaga(),
    peerActivityIdeaSaga(),

    mainDeviceSaga(),
    mainRolesSaga(),
    mainUsersSaga(),
    mainSearchSaga(),
    mainVersionHistoriesSaga(),
    mainCabinetsSaga(),
    loginSaga(),
    layoutSaga(),
    mainCurrentCountrySaga(),
    commentSaga(),

    eventNotificationSaga(),
    eventEventUserSubscriptionSaga(),
    eventUserBadgeSaga(),
    eventWebhookSaga(),

    dashboardContentSaga(),
    dashboardSaleSaga(),
    dashboardTaskSaga(),
    statPeerChannelContentSaga(),
    statPeerActivityContentSaga(),
    comparePeerChannelSaga(),

    salePlanSaga(),
    planOrderSaga(),
    paymentSystemSaga(),
    saleLicenseSaga(),
    saleStoreSaga(),
    saleCurrencySaga(),
    saleCurrencyRateSaga(),
    salePriceTypeSaga(),
    saleDeliverySystemSaga(),
    saleCategorySaga(),
    saleProductParamSaga(),
    saleProductSaga(),
    saleIntegrationSaga(),
    saleIntegrationHandlerSaga(),
    saleCustomerSaga(),
    saleOrderStatusSaga(),
    saleOrderSaga(),
    saleOrderDeliveryItemSaga(),
    saleOrderPaymentSaga(),
    saleOrderCustomerSaga(),
    couponSaga(),

    messengerChatSaga(),
    messengerCommentSaga(),
    messengerContactSaga(),

    supportTicketsSaga(),
    supportTicketMessagesSaga(),
    aiTextGenerateSaga(),
    urlShortenerSaga(),

    taskSaga()
  ]);
}
