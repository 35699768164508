import React, {useRef} from "react";



// components
import ChatChannel from "./ChatChannel";

// i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MessengerChannelType} from "../../../redux/Messenger/Chat/types";

//import {setUnreadMessagesCount} from "../../../api/apiCore";

interface ChannelsProps {
  channels: Array<MessengerChannelType>;
  // openCreateChannel: () => void;
  selectedChat: string | number;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}
const Channels = ({
  channels,
  // openCreateChannel,
  selectedChat,
  onSelectChat
}: ChannelsProps) => {

    const { t } = useTranslation(['messengerChannel']);

    let unreadMessagesCount = 0;

    (channels || []).map((channel: MessengerChannelType, key: number) => {
        if ((channel.unreadMessagesCount !== undefined) && (channel.unreadMessagesCount>0)){
            unreadMessagesCount+=channel.unreadMessagesCount;
        }
        return channel;
    })

//    setUnreadMessagesCount(unreadMessagesCount);


  return (
    <>
        <div className={"d-flex align-items-center mt-4 pt-2 mb-2"}>
            <div className="flex-grow-1">
                <h4 className={"mb-0 fs-11 text-muted text-uppercase ps-4"}>
                    {t('messengerChannel:title')}
                </h4>
            </div>
            {/*<div className="flex-shrink-0">*/}
            {/*    <UncontrolledTooltip*/}
            {/*        placement="bottom"*/}
            {/*        target="createnewmsg"*/}
            {/*    >*/}
            {/*        Create group*/}
            {/*    </UncontrolledTooltip>*/}
            {/*    <Button*/}
            {/*        color=""*/}
            {/*        id="createnewmsg"*/}
            {/*        className="btn btn-soft-success btn-sm"*/}
            {/*    >*/}
            {/*        <i className="ri-add-line align-bottom"/>*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list users-list">
          {(channels || []).map((channel: MessengerChannelType, key: number) => (
            <ChatChannel
              channel={channel}
              key={key}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default Channels;
