import { CommentsActionTypes } from "./types";
import {ChatsActionTypes} from "../Chat/types";
import {MainMetaType} from "../../Main/common";

// common success
export const commentsApiResponseSuccess = (actionType: string, data: any) => ({
  type: CommentsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const commentsApiResponseError = (actionType: string, error: string) => ({
  type: CommentsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const changeSelectedCommentChat = (selectedChat: string | number | null) => ({
  type: CommentsActionTypes.CHANGE_SELECTED_CHAT,
  payload: selectedChat,
});

// export const getFavourites = () => ({
//   type: CommentsActionTypes.GET_FAVOURITES,
// });

// export const getDirectMessages = () => ({
//   type: CommentsActionTypes.GET_DIRECT_MESSAGES,
// });

export const getCommentsChannels = (data: MainMetaType) => ({
  type: CommentsActionTypes.GET_COMMENTS_CHANNELS,
  payload: data
});

// // export const addContacts = (contacts: Array<string | number>) => ({
// //   type: CommentsActionTypes.ADD_CONTACTS,
// //   payload: contacts,
// // });
//
// export interface CreateChannelPostData {
//   name: string;
//   members: Array<string | number>;
//   description?: string;
// }
// // export const createChannel = (channelData: CreateChannelPostData) => ({
// //   type: CommentsActionTypes.CREATE_CHANNEL,
// //   payload: channelData,
// // });
//
export const changeSelectedCommentsChat = (selectedChat: string | number | null) => ({
  type: CommentsActionTypes.CHANGE_SELECTED_CHAT,
  payload: selectedChat,
});
//
// export const getChatUserDetails = (selectedChat: string | number | null) => ({
//   type: CommentsActionTypes.GET_CHAT_USER_DETAILS,
//   payload: selectedChat,
// });
//
export const getCommentsUserConversations = (
  selectedChat: string | number | null
) => ({
  type: CommentsActionTypes.GET_CHAT_USER_CONVERSATIONS,
  payload: selectedChat,
});
//
export const toggleCommentsUserDetailsTab = (value: boolean) => ({
  type: CommentsActionTypes.TOGGLE_USER_DETAILS_TAB,
  payload: value,
});

export const onSendCommentsMessage = (data: any) => ({
  type: CommentsActionTypes.ON_SEND_MESSAGE,
  payload: data,
});
//
export const receiveCommentsMessage = (uuid: number | string) => ({
  type: CommentsActionTypes.RECEIVE_MESSAGE,
  payload: uuid,
});

export const readCommentsMessage = (id: number | string) => ({
  type: CommentsActionTypes.READ_MESSAGE,
  payload: id,
});
//
// export const receiveMessageFromUser = (id: number | string) => ({
//   type: CommentsActionTypes.RECEIVE_MESSAGE_FROM_USER,
//   payload: id,
// });
//
export const deleteCommentsMessage = (
  messageId: number | string
) => ({
  type: CommentsActionTypes.DELETE_MESSAGE,
  payload: { messageId },
});
//
// export const forwardMessage = (data: object) => ({
//   type: CommentsActionTypes.FORWARD_MESSAGE,
//   payload: data,
// });
//
// export const deleteUserMessages = (userId: number | string) => ({
//   type: CommentsActionTypes.DELETE_USER_MESSAGES,
//   payload: userId,
// });
//
export const getCommentsChannelDetails = (id: number | string) => ({
  type: CommentsActionTypes.GET_COMMENTS_CHANNEL_DETAILS,
  payload: id,
});
//
// // export const toggleFavouriteContact = (id: number | string) => ({
// //   type: CommentsActionTypes.TOGGLE_FAVOURITE_CONTACT,
// //   payload: id,
// // });
//
export const getArchiveComments = (data: MainMetaType) => ({
  type: CommentsActionTypes.GET_ARCHIVE_CONTACT,
  payload: data,
});

export const toggleArchiveComments = (id: number | string) => ({
  type: CommentsActionTypes.TOGGLE_ARCHIVE_CONTACT,
  payload: id,
});
//
// export const readConversation = (id: number | string) => ({
//   type: CommentsActionTypes.READ_CONVERSATION,
//   payload: id,
// });
//
// export const deleteImage = (
//   userId: number | string,
//   messageId: number | string,
//   imageId: number | string
// ) => ({
//   type: CommentsActionTypes.DELETE_IMAGE,
//   payload: { userId, messageId, imageId },
// });
