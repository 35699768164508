import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CommentsActionTypes } from "./types";
import { commentsApiResponseSuccess, commentsApiResponseError } from "./actions";

import {
  // getFavourites as getFavouritesApi,
  // getDirectMessages as getDirectMessagesApi,
  getCommentsChannels as getCommentsChannelsApi,
  // addContacts as addContactsApi,
  // createChannel as createChannelApi,
  // getChatUserDetails as getChatUserDetailsApi,
  getCommentUserConversations as getCommentUserConversationsApi,
  sendCommentMessage as sendCommentMessageApi,
  receiveCommentMessage as receiveCommentMessageApi,
  readCommentMessage as readCommentMessageApi,
  // receiveMessageFromUser as receiveMessageFromUserApi,
  deleteCommentMessage as deleteCommentMessageApi,
  // forwardMessage as forwardMessageApi,
  // deleteUserMessages as deleteUserMessagesApi,
  getCommentsChannelDetails as getCommentsChannelDetailsApi,
  // toggleFavouriteContact as toggleFavouriteContactApi,
  getArchiveComments as getArchiveCommentsApi,
  toggleArchiveComments as toggleArchiveCommentsApi,
  // readConversation as readConversationApi,
  // deleteImage as deleteImageApi,
} from "../../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

//actions
import {
  // getDirectMessages as getDirectMessagesAction,
  // getFavourites as getFavouritesAction,
  getCommentsChannels as getCommentsChannelsAction,
} from "./actions";

// function* getFavourites() {
//   try {
//     const response: Promise<any> = yield call(getFavouritesApi);
//     yield put(
//       commentsApiResponseSuccess(CommentsActionTypes.GET_FAVOURITES, response)
//     );
//   } catch (error: any) {
//     yield put(commentsApiResponseError(CommentsActionTypes.GET_FAVOURITES, error));
//   }
// }
//
// function* getDirectMessages() {
//   try {
//     const response: Promise<any> = yield call(getDirectMessagesApi);
//     yield put(
//       commentsApiResponseSuccess(CommentsActionTypes.GET_DIRECT_MESSAGES, response)
//     );
//   } catch (error: any) {
//     yield put(
//       commentsApiResponseError(CommentsActionTypes.GET_DIRECT_MESSAGES, error)
//     );
//   }
// }

function* getCommentsChannels({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getCommentsChannelsApi, data);
    yield put(commentsApiResponseSuccess(CommentsActionTypes.GET_COMMENTS_CHANNELS, response));
  } catch (error: any) {
    yield put(commentsApiResponseError(CommentsActionTypes.GET_COMMENTS_CHANNELS, error));
  }
}

//
// function* addContacts({ payload: contacts }: any) {
//   try {
//     const response: Promise<any> = yield call(addContactsApi, contacts);
//     yield put(commentsApiResponseSuccess(CommentsActionTypes.ADD_CONTACTS, response));
//     yield call(showSuccessNotification, response + "");
//   } catch (error: any) {
//     yield call(showErrorNotification, error);
//     yield put(commentsApiResponseError(CommentsActionTypes.ADD_CONTACTS, error));
//   }
// }
// function* createChannel({ payload: channelData }: any) {
//   try {
//     const response: Promise<any> = yield call(createChannelApi, channelData);
//     yield put(
//       commentsApiResponseSuccess(CommentsActionTypes.CREATE_CHANNEL, response)
//     );
//     yield call(showSuccessNotification, response + "");
//   } catch (error: any) {
//     yield call(showErrorNotification, error);
//     yield put(commentsApiResponseError(CommentsActionTypes.CREATE_CHANNEL, error));
//   }
// }
//
// function* getChatUserDetails({ payload: id }: any) {
//   try {
//     const response: Promise<any> = yield call(getChatUserDetailsApi, id);
//     yield put(
//       commentsApiResponseSuccess(CommentsActionTypes.GET_CHAT_USER_DETAILS, response)
//     );
//   } catch (error: any) {
//     yield put(
//       commentsApiResponseError(CommentsActionTypes.GET_CHAT_USER_DETAILS, error)
//     );
//   }
// }
//
function* getCommentUserConversations({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getCommentUserConversationsApi, id);
    yield put(
      commentsApiResponseSuccess(
        CommentsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      commentsApiResponseError(CommentsActionTypes.GET_CHAT_USER_CONVERSATIONS, error)
    );
  }
}

function* onSendCommentsMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendCommentMessageApi, data);
    yield put(
      commentsApiResponseSuccess(CommentsActionTypes.ON_SEND_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(commentsApiResponseError(CommentsActionTypes.ON_SEND_MESSAGE, error));
  }
}

function* receiveCommentsMessage({ payload: uuid }: any) {
  try {
    const response: Promise<any> = yield call(receiveCommentMessageApi, uuid);
    yield put(
      commentsApiResponseSuccess(CommentsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(commentsApiResponseError(CommentsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readCommentsMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readCommentMessageApi, id);
    yield put(commentsApiResponseSuccess(CommentsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(commentsApiResponseError(CommentsActionTypes.READ_MESSAGE, error));
  }
}
//
// function* receiveMessageFromUser({ payload: id }: any) {
//   try {
//     const response: Promise<any> = yield call(receiveMessageFromUserApi, id);
//     yield put(
//       commentsApiResponseSuccess(
//         CommentsActionTypes.RECEIVE_MESSAGE_FROM_USER,
//         response
//       )
//     );
//   } catch (error: any) {
//     yield put(
//       commentsApiResponseError(CommentsActionTypes.RECEIVE_MESSAGE_FROM_USER, error)
//     );
//   }
// }
//
function* deleteCommentsMessage({ payload: { messageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteCommentMessageApi,
      messageId
    );
    yield put(
      commentsApiResponseSuccess(CommentsActionTypes.DELETE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(commentsApiResponseError(CommentsActionTypes.DELETE_MESSAGE, error));
  }
}
//
// function* forwardMessage({ payload: data }: any) {
//   try {
//     const response: Promise<any> = yield call(forwardMessageApi, data);
//     yield put(
//       commentsApiResponseSuccess(CommentsActionTypes.FORWARD_MESSAGE, response)
//     );
//     yield call(showSuccessNotification, response + "");
//   } catch (error: any) {
//     yield call(showErrorNotification, error + "");
//     yield put(commentsApiResponseError(CommentsActionTypes.FORWARD_MESSAGE, error));
//   }
// }
//
// function* deleteUserMessages({ payload: userId }: any) {
//   try {
//     const response: Promise<any> = yield call(deleteUserMessagesApi, userId);
//     yield put(
//       commentsApiResponseSuccess(CommentsActionTypes.DELETE_USER_MESSAGES, response)
//     );
//     yield call(showSuccessNotification, response + "");
//   } catch (error: any) {
//     yield call(showErrorNotification, error + "");
//     yield put(
//       commentsApiResponseError(CommentsActionTypes.DELETE_USER_MESSAGES, error)
//     );
//   }
// }
//
function* getCommentsChannelDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getCommentsChannelDetailsApi, id);
    yield put(
      commentsApiResponseSuccess(CommentsActionTypes.GET_COMMENTS_CHANNEL_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      commentsApiResponseError(CommentsActionTypes.GET_COMMENTS_CHANNEL_DETAILS, error)
    );
  }
}
//
// function* toggleFavouriteContact({ payload: id }: any) {
//   try {
//     const response: Promise<any> = yield call(toggleFavouriteContactApi, id);
//     yield put(
//       commentsApiResponseSuccess(
//         CommentsActionTypes.TOGGLE_FAVOURITE_CONTACT,
//         response
//       )
//     );
//     yield call(showSuccessNotification, response + "");
//   } catch (error: any) {
//     yield call(showErrorNotification, error + "");
//     yield put(
//       commentsApiResponseError(CommentsActionTypes.TOGGLE_FAVOURITE_CONTACT, error)
//     );
//   }
// }
//
function* getArchiveComments({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getArchiveCommentsApi, data);
    yield put(
      commentsApiResponseSuccess(CommentsActionTypes.GET_ARCHIVE_CONTACT, response)
    );
  } catch (error: any) {
    yield put(
      commentsApiResponseError(CommentsActionTypes.GET_ARCHIVE_CONTACT, error)
    );
  }
}

function* toggleArchiveComments({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleArchiveCommentsApi, id);
    yield put(
      commentsApiResponseSuccess(CommentsActionTypes.TOGGLE_ARCHIVE_CONTACT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      commentsApiResponseError(CommentsActionTypes.TOGGLE_ARCHIVE_CONTACT, error)
    );
  }
}
//
// function* readConversation({ payload: id }: any) {
//   try {
//     const response: Promise<any> = yield call(readConversationApi, id);
//     yield put(
//       commentsApiResponseSuccess(CommentsActionTypes.READ_CONVERSATION, response)
//     );
//     // yield put(getDirectMessagesAction());
//     // yield put(getFavouritesAction());
//     yield put(getChannelsAction());
//   } catch (error: any) {
//     yield put(commentsApiResponseError(CommentsActionTypes.READ_CONVERSATION, error));
//   }
// }
//
// function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
//   try {
//     const response: Promise<any> = yield call(
//       deleteImageApi,
//       userId,
//       messageId,
//       imageId
//     );
//     yield put(commentsApiResponseSuccess(CommentsActionTypes.DELETE_IMAGE, response));
//   } catch (error: any) {
//     yield put(commentsApiResponseError(CommentsActionTypes.DELETE_IMAGE, error));
//   }
// }

// export function* watchGetFavourites() {
//   yield takeEvery(CommentsActionTypes.GET_FAVOURITES, getFavourites);
// }
//
// export function* watchGetDirectMessages() {
//   yield takeEvery(CommentsActionTypes.GET_DIRECT_MESSAGES, getDirectMessages);
// }

export function* watchGetCommentsChannels() {
  yield takeEvery(CommentsActionTypes.GET_COMMENTS_CHANNELS, getCommentsChannels);
}
// export function* watchAddContacts() {
//   yield takeEvery(CommentsActionTypes.ADD_CONTACTS, addContacts);
// }
// export function* watchCreateChannel() {
//   yield takeEvery(CommentsActionTypes.CREATE_CHANNEL, createChannel);
// }
// export function* watchGetChatUserDetails() {
//   yield takeEvery(CommentsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
// }
export function* watchGetCommentUserConversations() {
  yield takeEvery(
    CommentsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getCommentUserConversations
  );
}
export function* watchOnSendCommentsMessage() {
  yield takeEvery(CommentsActionTypes.ON_SEND_MESSAGE, onSendCommentsMessage);
}
export function* watchReceiveCommentsMessage() {
  yield takeEvery(CommentsActionTypes.RECEIVE_MESSAGE, receiveCommentsMessage);
}
export function* watchReadCommentsMessage() {
  yield takeEvery(CommentsActionTypes.READ_MESSAGE, readCommentsMessage);
}
// export function* watchReceiveMessageFromUser() {
//   yield takeEvery(
//     CommentsActionTypes.RECEIVE_MESSAGE_FROM_USER,
//     receiveMessageFromUser
//   );
// }
export function* watchDeleteCommentsMessage() {
  yield takeEvery(CommentsActionTypes.DELETE_MESSAGE, deleteCommentsMessage);
}
// export function* watchForwardMessage() {
//   yield takeEvery(CommentsActionTypes.FORWARD_MESSAGE, forwardMessage);
// }
// export function* watchDeleteUserMessages() {
//   yield takeEvery(CommentsActionTypes.DELETE_USER_MESSAGES, deleteUserMessages);
// }
export function* watchGetCommentsChannelDetails() {
  yield takeEvery(CommentsActionTypes.GET_COMMENTS_CHANNEL_DETAILS, getCommentsChannelDetails);
}
// export function* watchToggleFavouriteContact() {
//   yield takeEvery(
//     CommentsActionTypes.TOGGLE_FAVOURITE_CONTACT,
//     toggleFavouriteContact
//   );
// }
export function* watchGetArchiveComments() {
  yield takeEvery(CommentsActionTypes.GET_ARCHIVE_CONTACT, getArchiveComments);
}
export function* watchToggleArchiveComments() {
  yield takeEvery(
    CommentsActionTypes.TOGGLE_ARCHIVE_CONTACT,
    toggleArchiveComments
  );
}
// export function* watchReadConversation() {
//   yield takeEvery(CommentsActionTypes.READ_CONVERSATION, readConversation);
// }
// export function* watchDeleteImage() {
//   yield takeEvery(CommentsActionTypes.DELETE_IMAGE, deleteImage);
// }

function* messengerCommentSaga() {
  yield all([
    // fork(watchGetFavourites),
    // fork(watchGetDirectMessages),
    fork(watchGetCommentsChannels),
    // fork(watchAddContacts),
    // fork(watchCreateChannel),
    // fork(watchGetChatUserDetails),
    fork(watchGetCommentUserConversations),
    fork(watchOnSendCommentsMessage),
    fork(watchReceiveCommentsMessage),
    fork(watchReadCommentsMessage),
    // fork(watchReceiveMessageFromUser),
    fork(watchDeleteCommentsMessage),
    // fork(watchForwardMessage),
    //fork(watchDeleteUserMessages),
    fork(watchGetCommentsChannelDetails),
    // fork(watchToggleFavouriteContact),
    fork(watchGetArchiveComments),
    fork(watchToggleArchiveComments),
    // fork(watchReadConversation),
    // fork(watchDeleteImage),
  ]);
}

export default messengerCommentSaga;
