import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSaleDeliverySystems = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_DELIVERY_SYSTEMS+"?"+query);
};

const sendDataSaleDeliverySystem = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_DELIVERY_SYSTEM, data);
};

const removeSaleDeliverySystem = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_DELIVERY_SYSTEM, data);
};

export {
    getSaleDeliverySystems,
    sendDataSaleDeliverySystem,
    removeSaleDeliverySystem
};
