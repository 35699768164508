import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const sendSaleProductIntegration = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_PRODUCT_INTEGRATION, data);
};

const sendSaleProductGroupIntegration = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_PRODUCT_GROUP_INTEGRATION, data);
};

const getSaleProductIntegrationParam = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_REMOTE_SALE_PRODUCT_INTEGRATION_PARAM, data);
};

export {
    sendSaleProductIntegration,
    sendSaleProductGroupIntegration,
    getSaleProductIntegrationParam
};
