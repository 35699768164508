import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { MainCabinetActionTypes } from "./types";
import { mainCabinetApiResponseSuccess, mainCabinetApiResponseError } from "./actions";

import {
  getUserCabinets as getUserCabinetsApi,
  sendDataMainCabinet as sendDataMainCabinetApi,
  createUserCabinet as createUserCabinetApi,
  updateCabinetLastLogin as updateCabinetLastLoginApi,
  resumeUserCabinet as resumeUserCabinetApi,
  getUserCabinet as getUserCabinetApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {getCurrentCabinet} from "../../../helpers/functions";

function* getUserCabinets() {
  try {
    const response: Promise<any> = yield call(getUserCabinetsApi);
    yield put(mainCabinetApiResponseSuccess(MainCabinetActionTypes.GET_USER_CABINETS, response));
  } catch (error: any) {
    yield put(mainCabinetApiResponseError(MainCabinetActionTypes.GET_USER_CABINETS, error));
  }
}

function* getUserCabinet() {
  try {
    let currentCabinet = getCurrentCabinet();
    const response: Promise<any> = yield call(getUserCabinetApi, currentCabinet);
    yield put(mainCabinetApiResponseSuccess(MainCabinetActionTypes.GET_CURRENT_CABINET, response));
  } catch (error: any) {
    yield put(mainCabinetApiResponseError(MainCabinetActionTypes.GET_CURRENT_CABINET, error));
  }
}

function* updateUserCabinet({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataMainCabinetApi,data);
    yield put(mainCabinetApiResponseSuccess(MainCabinetActionTypes.UPDATE_CABINET, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainCabinetApiResponseError(MainCabinetActionTypes.UPDATE_CABINET, error));
    yield call(showErrorNotification, error);
  }
}

function* createUserCabinet() {
  try {
    const response: Promise<any> = yield call(createUserCabinetApi);
    yield put(mainCabinetApiResponseSuccess(MainCabinetActionTypes.CREATE_USER_CABINET, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainCabinetApiResponseError(MainCabinetActionTypes.CREATE_USER_CABINET, error));
    yield call(showErrorNotification, error);
  }
}

function* updateCabinetLastLogin() {
  try {
    const response: Promise<any> = yield call(updateCabinetLastLoginApi);
    yield put(mainCabinetApiResponseSuccess(MainCabinetActionTypes.UPDATE_CABINET_LASTLOGIN, response));
  } catch (error: any) {
    yield put(mainCabinetApiResponseError(MainCabinetActionTypes.UPDATE_CABINET_LASTLOGIN, error));
  }
}

function* resumeUserCabinet({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(resumeUserCabinetApi,data);
    yield put(mainCabinetApiResponseSuccess(MainCabinetActionTypes.RESUME_USER_CABINET, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainCabinetApiResponseError(MainCabinetActionTypes.RESUME_USER_CABINET, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetMainCabinets() {
  yield takeEvery(MainCabinetActionTypes.GET_USER_CABINETS, getUserCabinets);
}
export function* watchUpdateUserCabinet() {
  yield takeEvery(MainCabinetActionTypes.UPDATE_CABINET, updateUserCabinet);
}

export function* watchCreateUserCabinet() {
  yield takeEvery(MainCabinetActionTypes.CREATE_USER_CABINET, createUserCabinet);
}

export function* watchUpdateCabinetLastLogin() {
  yield takeEvery(MainCabinetActionTypes.UPDATE_CABINET_LASTLOGIN, updateCabinetLastLogin);
}

export function* watchResumeUserCabinet() {
  yield takeEvery(MainCabinetActionTypes.RESUME_USER_CABINET, resumeUserCabinet);
}

export function* watchGetUserCabinet() {
  yield takeEvery(MainCabinetActionTypes.GET_CURRENT_CABINET, getUserCabinet);
}



function* mainCabinetsSaga() {
  yield all([
    fork(watchGetMainCabinets),
    fork(watchUpdateUserCabinet),
    fork(watchCreateUserCabinet),
    fork(watchUpdateCabinetLastLogin),
    fork(watchResumeUserCabinet),
      fork(watchGetUserCabinet)
  ]);
}

export default mainCabinetsSaga;
