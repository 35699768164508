import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import InputSelect from "../../../components/InputSelect";

import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';

import {
    ContentEditPeerChannelValidatorSchema,
    ContentNewPeerChannelValidatorSchema,
} from "./validator";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {
    getContentPeerChannels,
    hideRightModalContentPeerChannel,
    onSendDataContentPeerChannel
} from "../../../redux/Content/PeerChannel/actions";
import {
    ContentConnectorSocial,
    ContentConnectorType
} from "../../../redux/Content/Connector/types";
import {onGetContentConnectorSearchChannel, selectContentConnector} from "../../../redux/Content/Connector/actions";
import {showErrorNotification} from "../../../helpers/notifications";
import classnames from "classnames";

import ListButtonItem from "../Connector/ListButtonItem";
import UpgradeFare from "../../../components/UpgradeFare";
import {getCurrentCabinet, getHandlerIconByCode} from "../../../helpers/functions";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {m9sGlobals} from "../../../config";
import NoticeTip from "../../../components/NoticeTip";
import useDebounce from "../../../hooks/useDebounce";

const RightModal = () => {
    const { t, i18n } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [connector, setConnector] = useState<ContentConnectorType>(null);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([]);
    const [isSearchChannel, setIsSearchChannel] = useState<boolean>(false);
    const [searchChannels, setSearchChannels] = useState<ContentPeerChannelType[]>(null);

    const currentCabinet = getCurrentCabinet();

    const {
        contentPeerChannels,
        contentConnectors,
        contentProjects,
        isSending
    } = useAppSelector(state => ({
        contentPeerChannels: state.ContentPeerChannel,
        contentConnectors: state.ContentConnector,
        contentProjects: state.ContentProjects,
        isSending:  state.ContentPeerChannel.isSending
    }));

    const [uuid, setUuid] = useState(contentPeerChannels.selectedItem.uuid || null);

    const [formValues, setFormValues] = useState<ContentPeerChannelType>(contentPeerChannels.selectedItem);

    useEffect(() => {

        setIsSearchChannel(contentConnectors.isSearchChannels)

        if (contentConnectors.isSearchChannels === false) {
            setSearchChannels(contentConnectors.searchChannels || []);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentConnectors?.isSearchChannels])

    useEffect(() => {

        setUuid(contentPeerChannels.selectedItem.uuid || null);
        setFormValues(contentPeerChannels.selectedItem);

        if (contentPeerChannels.selectedItem.connector){
            setConnector(contentPeerChannels.selectedItem.connector);
            setActiveTab(2);
        } else if (
            (contentConnectors.selectedItem !== undefined)
            &&
            (contentConnectors.selectedItem.uuid !== undefined)
        ){
            setConnector(contentConnectors.selectedItem);
            setActiveTab(2);
        } else {
            setConnector(null);
            setActiveTab(1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerChannels.selectedItem?.uuid, dispatch]);

    useEffect(() => {
        if (contentPeerChannels?.selectedItem?.uuid){
            if ((uuid === contentPeerChannels.selectedItem.uuid) && (formValues.eTag !== contentPeerChannels.selectedItem.eTag)){
                setFormValues(contentPeerChannels.selectedItem);
            } else if (uuid !== contentPeerChannels.selectedItem.uuid) {
                setUuid(contentPeerChannels.selectedItem.uuid);
            }
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    }   ,[dispatch, contentPeerChannels?.selectedItem]);

    useEffect(() => {
        if (
            (contentPeerChannels.selectedItem.project === undefined)
            &&
            (contentProjects.items !== undefined && contentProjects.items.length>0)
        ){
            contentPeerChannels.selectedItem.project=contentProjects.items[0];
        }

        setFormValues(contentPeerChannels.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentProjects]);


    const handleClose = () => dispatch(hideRightModalContentPeerChannel());

    const onSelectConnector = (item:any) : void  => {
        dispatch(selectContentConnector({data : item}));
        setConnector(item);

        setActiveTab(2);
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {

            if ((tab===1) && (uuid!==undefined && uuid!==null)){
                showErrorNotification({message: t('channel:changeChannelService')});
                return
            }

            if ((tab===2) && (connector == null)) {
                showErrorNotification({message: t('connector:chooseService')});
                return
            }

            let modifiedSteps = [...passedSteps, tab];

            setActiveTab(tab);
            setPassedSteps(modifiedSteps);

        }
    }

    const handleDebouncedSearchChannel = () => {
        if (inputSearchChannelValue !== null && inputSearchChannelValue.length>2){
            dispatch(onGetContentConnectorSearchChannel({connector: connector, inputValue: inputSearchChannelValue}));
        }
    }

    const [inputSearchChannelValue, setInputSearchChannelValue] = useState(null);
    const debouncedSearch = useDebounce(handleDebouncedSearchChannel, 500);

    const handleSearchChannel = (event: any) => {
        if (event.length>0) {
            setInputSearchChannelValue(event);
            debouncedSearch(event);
        } else {
            setInputSearchChannelValue(null);
        }
    }

    const onDataSubmit = (formData:any) => {
        let data: any = {};

        if (uuid == null) {
            if (formData.name){
                if (formData.name.value !== undefined) {
                    data["peer"] = {
                        id: formData.name.value,
                        name: formData.name.label,
                        isManaged: formData.name.isManaged && formData.name.isManaged
                    }
                } else {
                    if (connector &&
                        (connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM
                            ||
                            connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT
                            ||
                            connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_OK
                            ||
                            connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_LINKEDIN
                        )){
                        data["peer"] = {
                            name: formData.name
                        }
                    } else {
                        data["peer"] = {
                            id: formData.name
                        }
                    }
                }
            }
            if ((formData.hashtag) && (formData.hashtag.length>1)){
                data["peer"] = {
                    name:formData.hashtag
                }
            }

        } else {
            data["uuid"] = uuid;
        }

        if (formData.project){
            if (formData.project.uuid !== undefined) {
                data["project"] = {uuid: formData.project.uuid};
            } else if (formData.project.value !== undefined) {
                data["project"] = {uuid: formData.project.value}
            }
        }


        data["enabled"] = formData.enabled;

        let params: any = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            peer: data.peer && data.peer,
            isExportRSS: formData.isExportRSS ? formData.isExportRSS : false
        };

        if (connector && connector.uuid){
            params.connector = {uuid : connector.uuid};
        }

        if (data.project && data.project.uuid){
            params.project = {uuid : data.project.uuid};
        }

        dispatch(onSendDataContentPeerChannel(params));

        setTimeout(() => {
            dispatch(getContentPeerChannels());
        }, 1000);
    };

    return (
        <>
            <Offcanvas
                direction="end"
                isOpen={contentPeerChannels.rightModalShow}
                id="channelEdit"
                toggle={handleClose}
            >

                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ uuid == null ? ContentNewPeerChannelValidatorSchema : ContentEditPeerChannelValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('channel:'+contentPeerChannels.modalEditTitle)}
                        </OffcanvasHeader>

                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row className="vertical-navs-step">
                                <Nav
                                    className="flex-column custom-nav nav-pills mb-3"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={
                                                (classnames({
                                                    active: activeTab === 1,
                                                    done: (activeTab > 1)
                                                }))
                                            }
                                            onClick={() => {
                                                toggleTab(1);
                                            }}
                                        >
                                            <div className="d-flex flex-row flex-nowrap flex-grow-1">
                              <span className="step-title me-2 text-muted d-flex align-items-center flex-shrink-1 text-nowrap">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 1
                              </span>

                                                <span className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end">
                {
                    (connector != null && connector.uuid && connector.uuid.length > 0)
                        ?
                        <>
                            <div className="avatar-xxs position-relative">
                                <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                    <img src={getHandlerIconByCode(connector.handler.code)} className="img-fluid p-1 rounded-circle"
                                    alt=""/>
                                </div>
                            </div>
                            <span className="step-title d-flex align-items-center text-truncate">{connector.name.substring(0,20)}{connector.name.length>20&&'...'}</span>
                            <span className="ms-2 step-title d-flex align-items-center">
                            { connector.isConnected ?
                                <span className="badge bg-success">{t('common:active')}</span>
                                :
                                <span className="badge badge-outline-dark">{t('common:offline')}</span>
                            }
                            </span>
                        </>
                        :
                        t('connector:chooseService')
                }
                 </span>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1}>
                                        <Row>
                                            {uuid == null &&
                                            (contentConnectors.items || []).map((item: ContentConnectorType, key: number) => (

                                                    item.isConnected &&
                                                    (Object.values(ContentConnectorSocial) as string[]).includes(item.handler.type) &&

                                                        <ListButtonItem
                                                            item={item}
                                                            key={'contentConnectorHandler_' + item.uuid}
                                                            onClick={onSelectConnector}
                                                            isSelected={connector != null && connector.uuid === item.uuid}
                                                        />

                                            ))
                                            }
                                        </Row>
                                    </TabPane>
                                </TabContent>



                                <Nav
                                    className="flex-column custom-nav nav-pills mb-3"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={
                                                (classnames({
                                                    active: activeTab === 2,
                                                    done: (activeTab > 2 )
                                                }))
                                            }
                                            onClick={() => {
                                                toggleTab(2);
                                            }}
                                        >
                                            <div className="d-flex flex-row flex-nowrap flex-grow-1">
                              <span className="tep-title me-2 text-muted d-flex align-items-center flex-shrink-1 text-nowrap">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 2
                              </span>


                                            <span className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end">
                {
                    (contentPeerChannels.selectedItem != null && contentPeerChannels.selectedItem.uuid && contentPeerChannels.selectedItem.uuid.length > 0)
                        ?
                        <div className="mw-0 d-flex flex-row ps-2">
                            <div className="avatar-xxs position-relative">
                                <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                    { contentPeerChannels.selectedItem.avatar ?
                                    <img src={contentPeerChannels.selectedItem.avatar} className="img-fluid p-1 rounded-circle" alt=""/> : contentPeerChannels.selectedItem.name[0]
                                    }
                                </div>
                            </div>
                            <div className="d-flex flex-row mw-0">
                            <span className="step-title d-flex align-items-center text-truncate">{contentPeerChannels.selectedItem.name.substring(0,30)}{contentPeerChannels.selectedItem.name.length>30 && '...'}</span>
                            </div>
                        </div>
                        :
                        t('channel:chooseChannel')
                }
                                            </span></div>


                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={2}>

                                        {
                                            connector && connector.handler && connector.handler.isFareRestriction === true && uuid == null
                                                ?
                                                <UpgradeFare/>
                                                :
                                                <>
                                                    <Row>
                                                        <div className="mb-3 d-flex flex-column me-5">

                                                            <Field
                                                                id="form-enabled-input"
                                                                placeholder={t('common:active')}
                                                                name="enabled"
                                                                component={InputSwitch}
                                                                value = {getFieldProps('enabled').value}
                                                                onChange={(enabled:boolean) => {
                                                                    setFieldValue('enabled', !getFieldProps('enabled').value);
                                                                }}
                                                                required={false}
                                                            />
                                                        </div>

                                                    </Row>

                                                    <Row>
                                                        <div className={ "mb-3"+ (touched.project ? " was-validated" : '')}>
                                                            <Label className="form-label" htmlFor="form-title-input">{t('project:project')}</Label>
                                                            <Field
                                                                id="form-project-select"
                                                                name="project"
                                                                placeholder={t('project:chooseProject')}
                                                                component={InputSelect}
                                                                value={getFieldProps('project').value}
                                                                onChange={(name: string) => {
                                                                    setFieldValue('project', name);
                                                                }}
                                                                items={contentProjects.items || []}
                                                                className={errors.project && touched.project && 'form-control is-invalid'}
                                                            />
                                                            {errors.project && touched.project ? (
                                                                <div className="invalid-feedback">
                                                                    {t('validator:'+errors.project)}
                                                                </div>
                                                            ) : touched.project && !errors.project ?
                                                                <div className="valid-feedback">
                                                                    {t('common:looksGood')}
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </Row>

                                                    {uuid == null &&

                                                    (connector && connector.handler.canChannelSearch ?
<>
                                                        <Row>
                                                            <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                                                <Label className="form-label" htmlFor="form-title-input">{t('channel:channel')}</Label>


                                                                <Field
                                                                    id="form-channel-input"
                                                                    name="name"
                                                                    placeholder={t('channel:chooseChannel')}
                                                                    component={InputSelect}
                                                                    value={getFieldProps('name').value}
                                                                    onChange={(value: string) => {
                                                                        setFieldValue('name', value);
                                                                    }}
                                                                    onKeyPress = {handleSearchChannel}
                                                                    isLoading={isSearchChannel}
                                                                    items={searchChannels || []}
                                                                    className={errors.name && touched.name && 'form-control is-invalid'}
                                                                />

                                                                {/*<Field*/}
                                                                {/*    id="form-channel-input"*/}
                                                                {/*    name="name"*/}
                                                                {/*    placeholder={t('channel:chooseChannel')}*/}
                                                                {/*    component={InputSelectAjax}*/}
                                                                {/*    value={getFieldProps('name').value}*/}
                                                                {/*    onChange={(value: string) => {*/}
                                                                {/*        setFieldValue('name', value);*/}
                                                                {/*    }}*/}
                                                                {/*    sendParams={{connector: connector}}*/}
                                                                {/*    onAjaxSearch={searchChannelFromRemote}*/}
                                                                {/*    required={false}*/}
                                                                {/*/>*/}
                                                                { connector && connector.handler && connector.handler.code && i18n.exists('channelAddCaptionInput_'+connector.handler.code,{ ns: 'channel'}) &&
                                                                <div className="text-muted mt-1 fs-10"
                                                                     dangerouslySetInnerHTML={{__html: t('channelAddCaptionInput_'+connector.handler.code, {ns: 'channel'})}}
                                                                />
                                                                }
                                                                {errors.name && touched.name ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.name)}
                                                                    </div>
                                                                ) : touched.name && !errors.name ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Row>
                                                        {connector.handler.code === 'instagram' &&
                                                            <>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="alert alert-light text-center" role="alert">
                                                                            {t('common:or')}
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                        <Row>
                                                            <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                                                <Label className="form-label" htmlFor="form-title-input">{t('channel:hashtag')}</Label>
                                                                <Field
                                                                    id="form-channel-input"
                                                                    name="hashtag"
                                                                    placeholder={t('channel:enterHashtagName')}
                                                                    component={InputText}
                                                                    value={getFieldProps('hashtag').value}
                                                                    onChange={(value: string) => {
                                                                        setFieldValue('hashtag', value);
                                                                    }}
                                                                    className={errors.hashtag && touched.hashtag && 'form-control is-invalid'}
                                                                />
                                                                { connector && connector.handler && connector.handler.code && i18n.exists('channelAddCaptionInput_hashtag_'+connector.handler.code,{ ns: 'channel'}) &&
                                                                <div className="text-muted mt-1 fs-10"
                                                                     dangerouslySetInnerHTML={{__html: t('channelAddCaptionInput_hashtag_'+connector.handler.code, {ns: 'channel'})}}
                                                                />
                                                                }
                                                                {errors.hashtag && touched.hashtag ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.hashtag)}
                                                                    </div>
                                                                ) : touched.hashtag && !errors.hashtag ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }

                                                            </div>
                                                        </Row>
                                                            </>
                                                        }

</>
                                                        :
<>
                                                        <Row>
                                                            <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                                                <Label className="form-label" htmlFor="form-title-input">{t('channel:channel')}</Label>
                                                                <Field
                                                                    id="form-channel-input"
                                                                    name="name"
                                                                    placeholder={t('channel:enterChannelName')}
                                                                    component={InputText}
                                                                    value={getFieldProps('name').value}
                                                                    onChange={(value: string) => {
                                                                        setFieldValue('name', value);
                                                                    }}
                                                                    className={errors.name && touched.name && 'form-control is-invalid'}
                                                                />
                                                                { connector && connector.handler && connector.handler.code && i18n.exists('channelAddCaptionInput_'+connector.handler.code,{ ns: 'channel'}) &&
                                                                <div className="text-muted mt-1 fs-10"
                                                                     dangerouslySetInnerHTML={{__html: t('channelAddCaptionInput_'+connector.handler.code, {ns: 'channel'})}}
                                                                />
                                                                }
                                                                {errors.name && touched.name ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.name)}
                                                                    </div>
                                                                ) : touched.name && !errors.name ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }

                                                            </div>
                                                        </Row>
                                                        { connector && connector.handler && (connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM || connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT) &&
                                                            <div className="m-0 alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-1" role="alert">
                                                                <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                                                {t('channel:channelCompetitorTelegramNotice')}
                                                            </div>
                                                        }
                                                        </>
                                                    )}
                                                    <Row>
                                                        <div className="mb-3 d-flex flex-column me-5">
                                                            <Field
                                                                id="form-isExportRSS-input"
                                                                placeholder={t('common:isExportRSS')}
                                                                name="isExportRSS"
                                                                component={InputSwitch}
                                                                value = {getFieldProps('isExportRSS').value}
                                                                onChange={(enabled:boolean) => {
                                                                    setFieldValue('isExportRSS', !getFieldProps('isExportRSS').value);
                                                                }}
                                                                required={false}
                                                            />
                                                        </div>
                                                    </Row>
                                                    {
                                                        getFieldProps('isExportRSS').value
                                                        &&
                                                        uuid !== undefined
                                                        &&
                                                        uuid !== null
                                                        &&
                                                        currentCabinet !== false
                                                        &&
                                                        currentCabinet?.uuid &&
                                                    <NoticeTip firstText={t('integration:integrationUrlText')} secondTextWithCopy={m9sGlobals.RSS_CHANNEL_URL.replace(/:cabinetUuid/,currentCabinet.uuid).replace(/:channelUuid/,uuid)}/>
                                                    }
                                                </>
                                        }
                                    </TabPane>
                                </TabContent>
                            </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                                <Button variant="light" onClick={handleClose} className="w-100">
                                    {t('common:close')}
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                        disabled={isSending}
                                        className="btn btn-primary w-100 chat-send waves-effect waves-light"
                                >
                                    {t('common:save')}
                                    {isSending &&
                                        <ButtonSpinner/>
                                    }
                                </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

            </Offcanvas>
        </>
    );
}

export default RightModal;