import {AuthLoginActionTypes, LoginByDeviceType} from "./types";

// common success
export const authLoginApiResponseSuccess = (actionType: string, data: any) => ({
  type: AuthLoginActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});


// common error
export const authLoginApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AuthLoginActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (user: any) => {
  return {
    type: AuthLoginActionTypes.LOGIN_USER,
    payload: { user },
  };
};

export const logoutUser = () => {
  return {
    type: AuthLoginActionTypes.LOGOUT_USER,
  };
};

export const socialLogin = (data: any) => {
  return {
    type: AuthLoginActionTypes.SOCIAL_LOGIN,
    payload: data ,
  };
};

export const checkLogInUser = () => {
  return {
    type: AuthLoginActionTypes.CHECK_LOGIN_USER,
  };
};

export const getJWTToken = () => {
  return {
    type: AuthLoginActionTypes.GET_JWT_TOKEN,
  };
};

export const setWSConnected = () => ({
    type: AuthLoginActionTypes.WS_CONNECTED
});

export const setWSDisconnected = () => ({
    type: AuthLoginActionTypes.WS_DISCONNECTED,
});

export const setChangeEmailComplete = () => ({
  type: AuthLoginActionTypes.CHANGE_EMAIL_COMPLETE
});

export const getDeviceUsers = () => {
  return {
    type: AuthLoginActionTypes.GET_DEVICE_USERS,
  };
};

export const changeLoginBy = (data: string) => {
  return {
    type: AuthLoginActionTypes.CHANGE_LOGIN_BY,
    payload: data
  };
};

export const loginUserByDevice = (data: LoginByDeviceType) => {
  return {
    type: AuthLoginActionTypes.LOGIN_USER_BY_DEVICE,
    payload: data
  };
};

export const changeUserAccount = () => {
  return {
    type: AuthLoginActionTypes.CHANGE_USER_ACCOUNT,
  };
};
