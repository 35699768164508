import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getStatCountersTiles = (data:any) => {

    const sendData = {
        project : {
            uuid : data.project.uuid
        },
        periodFrom: data.periodFrom,
        periodTo: data.periodTo
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_COUNTER_TILES_LIST, sendData);
};

const getStatCountersData = (data:any) => {

    const sendData = {
        project : {
            uuid : data.project.uuid
        },
        periodFrom: data.periodFrom,
        periodTo: data.periodTo
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_COUNTERS_DATA, sendData);
};

export {
    getStatCountersTiles,
    getStatCountersData
};
