import React, {useEffect, useState} from "react";
import {Link}  from 'react-router-dom';
import InputSwitch from "../../../components/InputSwitch";
import Loader from "../../../components/Loader";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {UncontrolledTooltip} from "reactstrap";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {getHandlerIconByCode} from "../../../helpers/functions";
interface ItemProps {
    item: SaleIntegrationType;
    onChange: (event: SaleIntegrationType) => void;
    currency?: CurrencyType;
    isEnabled: boolean;
    disabled?: boolean;
}
const SaleIntegrationItemProductIntegration = ({ item, onChange, isEnabled, currency, disabled = false }: ItemProps) => {

    const [isLoading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation(['common']);

    useEffect(()=>{

        if (item?.isUpdating && item.isUpdating === true){
            setLoading(true);
        } else {
            setLoading(false);
        }

    },[item?.isUpdating])


    return(
        <>
            <div className="d-flex flex-row">
                <div className="d-flex flex-row flex-grow-0">
                    <Link to="#" onClick={()=>onChange(item)}>
                    <div className="avatar-xs d-flex flex-shrink-0 position-relative" id={'saleIntegrationItemProductIntegration_'+item.uuid}>
                        {(item?.handler?.code && getHandlerIconByCode(item?.handler?.code) !== null) ?
                            <img src={getHandlerIconByCode(item.handler.code)} className="rounded-circle img-fluid" alt=""/>
                            :
                            <span className={"avatar-title rounded-circle"}>{item.name[0]}</span>
                        }
                        {currency && currency.code &&
                            <span className="position-absolute badge rounded-pill bg-info fs-8 currencyIconBottomRight">{currency.code}</span>
                        }
                    </div>
                    <UncontrolledTooltip target={'saleIntegrationItemProductIntegration_'+item.uuid} placement="top">
                        {item.name}
                    </UncontrolledTooltip>
                    </Link>
                </div>
                <div className="justify-content-end d-flex text-truncate flex-shrink-0 align-items-center flex-grow-0 ms-2">
                    {isLoading ?
                        <Loader addClassNames="sm d-inline-block"/>
                        :
                        <InputSwitch id={'id'}
                                     value={isEnabled}
                                     onChange={()=>onChange(item)}
                                     disabled={disabled}
                                    className="form-switch-xs d-flex flex-column justify-content-center align-items-end"/>
                    }
                </div>
            </div>
        </>
    )
}

export default SaleIntegrationItemProductIntegration;