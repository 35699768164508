import {EntityActionsType} from "../../types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {PriceTypeType} from "../PriceType/types";
import {CurrencyType} from "../Currency/types";
import {SaleStoreType} from "../Store/types";
import {MetaType} from "../../Main/Meta/types";
import {PaymentSystemType} from "../PaymentSystem/types";
import {DeliverySystemType} from "../DeliverySystem/types";
import {ProductIntegrationParamType} from "../ProductIntegration/types";
import {RequestMetadataType} from "../../Main/RequestMetadata/types";
import {SaleIntegrationHandlerType} from "../IntegrationHandler/types";
import {MainMetaType} from "../../Main/common";
import {MetaBusinessManagerType, MetaProductCatalogType} from "../Meta/types";
import {MainFileType} from "../../Main/File/types";
import {UrlShortenerType} from "../../Shortener/UrlShortener/types";

export enum SaleIntegrationActionTypes {
    API_RESPONSE_SUCCESS = "@@saleIntegration/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleIntegration/API_RESPONSE_ERROR",
    GET_SALE_INTEGRATIONS = "@@saleIntegration/GET_SALE_INTEGRATIONS",
    UPDATE_SALE_INTEGRATION = "@@saleIntegration/UPDATE_SALE_INTEGRATION",
    REMOVE_SALE_INTEGRATION_MODAL_SHOW = "@@saleIntegration/REMOVE_SALE_INTEGRATION_MODAL_SHOW",
    REMOVE_SALE_INTEGRATION_MODAL_HIDE = "@@saleIntegration/REMOVE_SALE_INTEGRATION_MODAL_HIDE",
    REMOVE_SALE_INTEGRATION = "@@saleIntegration/REMOVE_SALE_INTEGRATION",
    EDIT_SALE_INTEGRATION = "@@saleIntegration/EDIT_SALE_INTEGRATION",
    INVALIDATE_CACHE_SALE_INTEGRATION = "@@saleIntegration/INVALIDATE_CACHE_SALE_INTEGRATION",
    RIGHT_MODAL_SHOW = "@@saleIntegration/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleIntegration/RIGHT_MODAL_HIDE",
    GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE = "@@saleIntegration/GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE",
    RESPONSE_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE = "@@saleIntegration/RESPONSE_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE",
    REQUEST_SALE_INTEGRATION_IMPORT = "@@saleIntegration/REQUEST_SALE_INTEGRATION_IMPORT",
    GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE = "@@saleIntegration/GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE",
    RESPONSE_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE = "@@saleIntegration/RESPONSE_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE",
    GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE = "@@saleIntegration/GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE",
    RESPONSE_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE = "@@saleIntegration/RESPONSE_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE",
}

export interface SaleIntegrationState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: SaleIntegrationType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isImportRequest: boolean;
    isNeedReload: boolean;
    requestMetadata? : RequestMetadataType;
    productIntegrationParams?: Array<ProductIntegrationParamType>;
    isLoadingProductIntegrationParams: boolean;
    metaBusinessManagers: Array<MetaBusinessManagerType>;
    isLoadingMetaBusinessManagers: boolean;
    metaProductCatalogs: Array<MetaProductCatalogType>;
    isLoadingMetaProductCatalogs: boolean;
}

export interface SaleIntegrationType{
    uuid?: string;
    name?: string;
    order?: number;
    enabled?: boolean;
    channel?: ContentPeerChannelType;
    priceType?: PriceTypeType;
    currency?: CurrencyType;
    store?: SaleStoreType;
    meta?:MetaType;
    actions?: EntityActionsType,
    paymentSystems?: Array<PaymentSystemType>
    deliverySystems?: Array<DeliverySystemType>
    buttonName?: string;
    handler?: SaleIntegrationHandlerType;
    param?: SaleIntegrationParamType;
    status?: string;
    isUpdating?:boolean;
    isReadOnly?:boolean;

    attachmentFiles?: Array<MainFileType>
}


export interface SaleIntegrationPayload {
    data: Array<any>;
    modalEditTitle : string;
}

export interface SaleIntegrationRequestType{
    uuidCabinet: string;
    uuidIntegration: string
}

export interface SaleGroupIntegrationType{
    uuid?:string;
    enabled?: boolean;
    channel?: ContentPeerChannelType;
    priceType?: PriceTypeType;
    currency?: CurrencyType;
    store?: SaleStoreType;
    actions?: EntityActionsType,
    paymentSystems?: Array<PaymentSystemType>
    deliverySystems?: Array<DeliverySystemType>
    productUuids?: Array<string>
    param?: ProductIntegrationParamType;
    integration?: SaleIntegrationType;
}


export interface SaleIntegrationParamType{
    url?: string;
    isShowLanguages?: boolean;
    isShowLanguageFlags?: boolean;
    availableLanguages?: Array<string>;
    defaultLanguage?: string;
    metaBusinessManager?: MetaBusinessManagerType;
    metaProductCatalog?: MetaProductCatalogType;
    urlShortener?: UrlShortenerType;
}

export enum SaleIntegrationStatusTypes {
    STATUS_FINAL = 'F',
    STATUS_NEW= 'N',
    STATUS_ON_PUBLISH = 'OP',
    STATUS_PROCESSING = 'PR',
    STATUS_UNPUBLISHED = 'UP',
    STATUS_ERROR = 'E',
    STATUS_READY = 'R'
}

export const SaleIntegrationStatus = {
    ALL: {
        label: 'All',
        class: 'info',
        status: ''
    },
    PENDING: {
        label: 'Pending',
        class: 'warning',
        status: SaleIntegrationStatusTypes.STATUS_ON_PUBLISH
    },
    INPROGRESS: {
        label: 'InProgress',
        class: 'secondary',
        status: SaleIntegrationStatusTypes.STATUS_PROCESSING
    },
    ERROR: {
        label: 'Error',
        class: 'danger',
        status: SaleIntegrationStatusTypes.STATUS_ERROR
    },
    NEW: {
        label: 'New',
        class: 'primary',
        status: SaleIntegrationStatusTypes.STATUS_NEW
    },
    READY: {
        label: 'Ready',
        class: 'info',
        status: SaleIntegrationStatusTypes.STATUS_READY
    },
    FINAL: {
        label: 'Final',
        class: 'success',
        status: SaleIntegrationStatusTypes.STATUS_FINAL
    }
}