//@ts-nocheck
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {saveIsNeedRefresh} from "../helpers/functions";
interface InputProps {
  name?: string;
  id?: string;
  className?: string;
}
const BackButton = ({ name = '', id, className ='' }: InputProps) => {

    const navigate = useNavigate();

  return (
      <Link
          to={-1}
          onClick={()=>{saveIsNeedRefresh()}}
//          onClick={()=>navigate(-1)}
          id={id && id}
          className={"btn btn-primary waves-effect waves-light"+(name && name.length>0?' btn-label':'')+(className && className.length>0?' '+className:'')}>
          <i className={"ri-arrow-left-line align-middle fs-16"+(name && name.length>0?' me-2 label-icon':'')}/>{ name }
      </Link>
  );
};
export default BackButton;
