import { takeEvery, fork, put, all, call } from "typed-redux-saga";

import {
  getSupportTicketMessages as getSupportTicketMessagesApi,
  updateSupportTicketMessage as updateSupportTicketMessageApi,
  removeSupportTicketMessage as removeSupportTicketMessageApi,
  getSupportServiceTicketMessages as getSupportServiceTicketMessagesApi,
  updateSupportServiceTicketMessage as updateSupportServiceTicketMessageApi,
  removeSupportServiceTicketMessage as removeSupportServiceTicketMessageApi,
  rewardSupportTicketMessage as rewardSupportTicketMessageApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {supportTicketMessageApiResponseError, supportTicketMessageApiResponseSuccess} from "./actions";
import {SupportTicketMessageActionTypes} from "./types";
import {supportTicketApiResponseError, supportTicketApiResponseSuccess} from "../Ticket/actions";

function* getSupportTicketMessages({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSupportTicketMessagesApi,data);
    yield put(supportTicketMessageApiResponseSuccess(SupportTicketMessageActionTypes.GET_SUPPORT_TICKET_MESSAGES, response));
  } catch (error: any) {
    yield put(supportTicketMessageApiResponseError(SupportTicketMessageActionTypes.GET_SUPPORT_TICKET_MESSAGES, error));
  }
}

function* getSupportServiceTicketMessages({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSupportServiceTicketMessagesApi,data);
    yield put(supportTicketMessageApiResponseSuccess(SupportTicketMessageActionTypes.GET_SUPPORT_SERVICE_TICKET_MESSAGES, response));
  } catch (error: any) {
    yield put(supportTicketMessageApiResponseError(SupportTicketMessageActionTypes.GET_SUPPORT_SERVICE_TICKET_MESSAGES, error));
  }
}

function* onSendDataSupportTicketMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateSupportTicketMessageApi, data);
    yield put(
        supportTicketMessageApiResponseSuccess(SupportTicketMessageActionTypes.UPDATE_SUPPORT_TICKET_MESSAGE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketMessageApiResponseError(SupportTicketMessageActionTypes.UPDATE_SUPPORT_TICKET_MESSAGE, error));
    yield call(showErrorNotification, error);
  }
}

function* onSendDataSupportServiceTicketMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateSupportServiceTicketMessageApi, data);
    yield put(
        supportTicketMessageApiResponseSuccess(SupportTicketMessageActionTypes.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketMessageApiResponseError(SupportTicketMessageActionTypes.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSupportTicketMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSupportTicketMessageApi, {uuid: data.data.uuid});
    yield put(
        supportTicketMessageApiResponseSuccess(SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketMessageApiResponseError(SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSupportServiceTicketMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSupportServiceTicketMessageApi, {uuid: data.data.uuid});
    yield put(
        supportTicketMessageApiResponseSuccess(SupportTicketMessageActionTypes.REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketMessageApiResponseError(SupportTicketMessageActionTypes.REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRewardSupportTicketMessage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(rewardSupportTicketMessageApi, data);
    yield put(
        supportTicketApiResponseSuccess(SupportTicketMessageActionTypes.SEND_REWARD_SUPPORT_TICKET_MESSAGE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketMessageActionTypes.SEND_REWARD_SUPPORT_TICKET_MESSAGE, error));
    yield call(showErrorNotification, error);
  }
}


export function* watchGetSupportTicketMessages() {
  yield takeEvery(SupportTicketMessageActionTypes.GET_SUPPORT_TICKET_MESSAGES, getSupportTicketMessages);
}

export function* watchOnSendDataSupportTicketMessage() {
  yield takeEvery(SupportTicketMessageActionTypes.UPDATE_SUPPORT_TICKET_MESSAGE, onSendDataSupportTicketMessage);
}
export function* watchOnRemoveSupportTicketMessage() {
  yield takeEvery(SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE, onRemoveSupportTicketMessage);
}

export function* watchGetSupportServiceTicketMessages() {
  yield takeEvery(SupportTicketMessageActionTypes.GET_SUPPORT_SERVICE_TICKET_MESSAGES, getSupportServiceTicketMessages);
}

export function* watchOnSendDataSupportServiceTicketMessage() {
  yield takeEvery(SupportTicketMessageActionTypes.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE, onSendDataSupportServiceTicketMessage);
}
export function* watchOnRemoveSupportServiceTicketMessage() {
  yield takeEvery(SupportTicketMessageActionTypes.REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE, onRemoveSupportServiceTicketMessage);
}

export function* watchOnRewardSupportTicketMessage() {
  yield takeEvery(SupportTicketMessageActionTypes.SEND_REWARD_SUPPORT_TICKET_MESSAGE, onRewardSupportTicketMessage);
}

function* supportTicketMessagesSaga() {
  yield all([
      fork(watchGetSupportTicketMessages),
      fork(watchOnSendDataSupportTicketMessage),
      fork(watchOnRemoveSupportTicketMessage),
      fork(watchGetSupportServiceTicketMessages),
      fork(watchOnSendDataSupportServiceTicketMessage),
      fork(watchOnRemoveSupportServiceTicketMessage),
      fork(watchOnRewardSupportTicketMessage)
  ]);
}

export default supportTicketMessagesSaga;
