import React from "react";
import { Number } from "react-intl-number-format"

interface StatItemProps {
  value: number;
  icon?: string;
  label?: string;
}
const StatItem = ({ value, icon, label }: StatItemProps) => {

    const FormatValue = (value:number) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            notation: 'compact'
        }).format(value);

    let formattedValue = '0';

    if (value>0){
        formattedValue=FormatValue(value);
    }

  return (
      <li className="list-inline-item fs-11">
          {icon &&
            <span className="text-muted me-1"><i className={icon}/></span>
          }
          {label &&
            <span className="text-muted me-1">{label}</span>
          }
          <span className="text-muted">{formattedValue}</span>
      </li>
  );
};
export default StatItem;
