import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getMainRoles = () => {
  return api.get(url.GET_MAIN_ROLES);
};


export {
    getMainRoles,
};
