import React from "react";
import {ContentTemplateType} from "../../../redux/Content/Template/types";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

const ContentTemplateColumns = ({ onEdit, onRemove }:EntityActionsHandlersType) => {

    const { t } = useTranslation(['common']);

    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-light btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            { cell.actions && cell.actions.canEdit &&
                                <DropdownItem className='edit-item-btn' onClick={() => onEdit(cell)}><i
                                    className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:edit')} </DropdownItem>
                            }
                            {cell.actions && cell.actions.canRemove &&
                                <DropdownItem className='remove-item-btn' onClick={() => onRemove(cell)}><i className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')} </DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            id: 'name',
            name: <span className='font-weight-bold fs-14'>{t('common:name')}</span>,
            selector: (row:ContentTemplateType) => row.name,
            sortable: true,
            wrap: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }]
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('common:text')}</span>,
            selector: (row:ContentTemplateType) => row.text,
            sortable: false,
            wrap: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }]
        },
        {
            id: 'enabled',
            name: <span className='font-weight-bold fs-14'>{t('common:status')}</span>,
            sortable: true,
            maxWidth: '50px',
            center: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }],
            selector: (cell:any) => {
                switch (cell.enabled) {
                    case true:
                        return <span className="badge badge-soft-success"> {t('common:active')} </span>;
                    default:
                        return <span className="badge badge-soft-danger"> {t('common:inActive')} </span>;
                }
            },
        },

    ];

}

export default ContentTemplateColumns;