import {EntityActionsType} from "../../types";

export interface MetaType {
    uuid?: string;
    enabled?: boolean;
    utmId?: string;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
    actions?: EntityActionsType,
}

export const MetaAllowEditFields = {
    utmId : {},
    utmSource : {},
    utmMedium : {},
    utmCampaign : {},
    utmTerm : {},
    utmContent : {}
}
