import { APIClient } from "../apiCore";
import * as url from "../urls";
import {SEND_REWARD_SUPPORT_TICKET_MESSAGE} from "../urls";

const api = new APIClient();

const getSupportTicketMessages = (data:any) => {
  return api.get(url.GET_SUPPORT_TICKET_MESSAGES.replace(/:uuid/,data.uuid));
};

const updateSupportTicketMessage = (data: any) => {

    if (data.attachmentFiles !== undefined){
        return api.createWithFile(url.UPDATE_SUPPORT_TICKET_MESSAGE,data);
    }

    return api.create(url.UPDATE_SUPPORT_TICKET_MESSAGE, data);
};

const removeSupportTicketMessage = (data: any) => {
    return api.create(url.REMOVE_SUPPORT_TICKET_TICKET_MESSAGE, data);
};

const getSupportServiceTicketMessages = (data:any) => {
    return api.get(url.GET_SUPPORT_SERVICE_TICKET_MESSAGES.replace(/:uuid/,data.uuid));
};

const updateSupportServiceTicketMessage = (data: any) => {

    if (data.attachmentFiles !== undefined){
        return api.createWithFile(url.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE,data);
    }

    return api.create(url.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE, data);
};

const removeSupportServiceTicketMessage = (data: any) => {
    return api.create(url.REMOVE_SUPPORT_SERVICE_TICKET_TICKET_MESSAGE, data);
};

const rewardSupportTicketMessage = (data: any) => {
    return api.create(url.SEND_REWARD_SUPPORT_TICKET_MESSAGE, data);
};



export {
    getSupportTicketMessages,
    updateSupportTicketMessage,
    removeSupportTicketMessage,
    getSupportServiceTicketMessages,
    updateSupportServiceTicketMessage,
    removeSupportServiceTicketMessage,
    rewardSupportTicketMessage
};
