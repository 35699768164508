import { APIClient } from "../apiCore";
import * as url from "../urls";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";


const api = new APIClient();

const getPlanOrders = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(url.GET_PLAN_ORDERS+"?"+query);
};

const getPlanOrderDetail = (data:any) => {
    return api.get(url.GET_PLAN_ORDER_DETAIL+'/'+data.uuid);
};

const cancelPlanOrder = (data:any) => {
    return api.get(url.CANCEL_PLAN_ORDER+'/'+data.uuid);
};

const createPlanOrder = (data:any) => {
    return api.create(url.CREATE_PLAN_ORDER,data);
};


export {
    getPlanOrders,
    getPlanOrderDetail,
    cancelPlanOrder,
    createPlanOrder
};
