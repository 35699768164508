import React, {useState} from 'react';
import {
    AITextGenerateActionType,
    AITextGenerateLengthType,
} from "../../redux/AI/TextGenerate/types";
import {Input, Label} from 'reactstrap';
import '../../i18/config';
import {useTranslation} from "react-i18next";

interface InputProps {
    item: AITextGenerateLengthType | AITextGenerateActionType;
    onChange: (value:boolean) => void;
    isSelected: boolean;
    name: string;
}

const LengthItem = ({item,onChange,isSelected, name}:InputProps) => {

    const { t } = useTranslation();

    return (
        <>
                <div>
                    <div className="form-check card-radio">
                        <Input
                            id={"aiTextGenerateLengthType_"+item.code}
                            name={name}
                            type="radio"
                            checked={isSelected}
                            className="form-check-input"
                            onChange={(event: any)=>{
                                onChange(event.target.checked);
                            }}
                        />
                        <Label
                            className="form-check-label pe-1"
                            htmlFor={"aiTextGenerateLengthType_"+item.code}
                        >
                            <div className="fs-14 text-truncate">{t('ai:'+item.code+'Label')}</div>
                            { item.value ?
                            <div className="text-muted fs-10 text-wrap">~ {item.value} {t('ai:words')}</div>
                                :
                                item.description &&
                                <div className="text-muted fs-10 text-wrap">{t(item.description)}</div>
                            }
                        </Label>
                    </div>
                </div>
        </>
    )
}

export default LengthItem;