import React, {useEffect, useState, useMemo} from "react";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from 'react-router-dom';
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {logoIdeaChannel} from "../../../data/images";

interface ItemProps {
    item: ContentPeerChannelType;
}
const ChannelItem = ({ item }: ItemProps) => {

    const [channelName, setChannelName] = useState<string>(item.name)
    const { t } = useTranslation(['common']);

    useEffect(()=>{
        if (item?.connector?.handler?.code && item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS){
            setChannelName(t('channel:channelIdeas'));
        } else {
            setChannelName(item.name);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item.name])

    return(
        <>
                {item.uuid ? <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={channelName}>
                    <OverlayTrigger
                        placement={'right'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                {channelName}
                            </Tooltip>
                        }
                    >
                        <div className="avatar-xxs">
                            {item.avatar ?
                                <img src={item.avatar} alt="" className="rounded-circle img-fluid" />
                                :
                                <div className="avatar-title rounded-circle bg-light border-dashed border text-primary fs-16 ">
                                    {item.connector && item.connector.handler && item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS ?
                                    <img src={logoIdeaChannel} className="img-fluid p-0 rounded-circle" alt=""/>
                                    :
                                    item.name[0]
                                    }
                                </div>
                            }
                        </div>
                    </OverlayTrigger>
                </Link> : ''}
        </>
    )
}

export default ChannelItem;