import {SaleLicenseActionTypes, SaleLicenseKeyActivatePayload} from "./types";

export const saleLicenseApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleLicenseActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleLicenseApiResponseError = (actionType: string, error: string) => ({
  type: SaleLicenseActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const onActivateLicenseKey = (data: SaleLicenseKeyActivatePayload) => ({
  type: SaleLicenseActionTypes.ACTIVATE_LICENSE_KEY,
  payload: data
});
