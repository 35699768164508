import React, {useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as route from "../../../api/routes";
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {StatPeerActivityActivitiesDataType} from "../../../redux/Stat/PeerActivity/types";
import {convertToMomentLocale} from "../../../helpers/functions";

interface InputProps {
    items: [] | Array<StatPeerActivityActivitiesDataType>;
}

const ActivityEngagementsChart = ({ items }: InputProps) => {

    const { t, i18n } = useTranslation(['common']);

    moment.locale(convertToMomentLocale(i18n.language));


    let seriesLabels= [];
    let series = [];

    let arSeries = [];

    (items || []).map((item: StatPeerActivityActivitiesDataType)=>{

        seriesLabels.push(item.date);

        for(let itemKey in item){
            if (itemKey==='date') {continue;}

            if(arSeries[itemKey] === undefined){
                arSeries[itemKey]=[];
            }

            arSeries[itemKey].push(item[itemKey]);
        }

        return item;
    });

    for(let itemKey in arSeries){
        series.push({name:t('stat:'+itemKey), type:'bar', data: arSeries[itemKey]});
    }

    var options : ApexOptions = {
        chart: {
            height: 374,
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 3, 0],
            width: [0, 1, 0],
        },
        fill: {
            opacity: [1, 0.1, 1]
        },
        markers: {
            size: [0, 4, 0],
            strokeWidth: 2,
            hover: {
                size: 4,
            }
        },
        xaxis: {
            categories: seriesLabels,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                formatter: function (value) {
                    return moment(value,'YYYY-MM-DD').format('DD MMM YYYY');
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                barHeight: '70%'
            }
        },
//        colors: linechartcustomerColors,
        tooltip: {
            shared: true,
        }
    };



    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('peerActivity:activityEngagement')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('peerActivity:manageActivities')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="apex-charts" >
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height="436"
                        className="apex-charts"
                    />
                </div>
            </CardBody>
        </Card>
    );
}

export default ActivityEngagementsChart;