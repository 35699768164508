// types
import {
  MainUserActionTypes,
  MainUserState,
  MainUserType,
  MainUserPasswordChangeType,
  MainUserResetType,
  MainUserSignUpType,
  MainCabinetUserType, MainUserBasicDetailsType
} from "./types";
import {getLoggedinUser, setLoggeedInUser} from "../../../api/apiCore";
import jwt_decode from "jwt-decode";
import {LoginJWTType} from "../Login/types";

export const NewMainUserItem:MainUserType = {
  enabled: true,
  email: '',
  phone: '',
  name: '',
  roles: [],
  lastLogin: 0
}

export const NewMainUserSignUp:MainUserSignUpType = {
  email: '',
  password: '',
  confirmPassword: '',
}

export const MainUserReset:MainUserResetType = {
  email: ''
}

export const MainUserChangePassword:MainUserPasswordChangeType = {
  password: '',
  confirmPassword: ''
}

export const INIT_STATE_MAIN_USER: MainUserState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewMainUserItem,
  modalEditTitle: '',
  isResendConfirmationEmail: false,
  isSending: false,
  isLoading: false,
  error: "",
  contacts:[],
  isFetched: false,
  isNeedReload: false,
  isNeedUpdateCabinetSettings: false
};

const MainUser = (state = INIT_STATE_MAIN_USER, action: any) => {

  let loggedinUser;

  switch (action.type) {
    case MainUserActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MainUserActionTypes.GET_MAIN_USERS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case MainUserActionTypes.GET_MAIN_CURRENT_USER:

          const cabinetCurrentUserData:MainCabinetUserType = action.payload.data.items[0];

          loggedinUser = getLoggedinUser();
          let decodedJWTToken:LoginJWTType = jwt_decode(loggedinUser.token);

          let currentUser: MainUserType = {
            uuid: loggedinUser.uuid,
            enabled: decodedJWTToken.enabled && decodedJWTToken.enabled,
            email: loggedinUser.email && loggedinUser.email,
            name: loggedinUser.fullName && loggedinUser.fullName,
            phone: loggedinUser.phone && loggedinUser.phone,
            avatar: loggedinUser.avatar && loggedinUser.avatar,
            position: loggedinUser.position && loggedinUser.position,
            description: loggedinUser.description && loggedinUser.description,
            roles: cabinetCurrentUserData.roles && cabinetCurrentUserData.roles,
            lastLogin: cabinetCurrentUserData.lastLogin && cabinetCurrentUserData.lastLogin,
            subscriptions: cabinetCurrentUserData.subscriptions && cabinetCurrentUserData.subscriptions,
            permissions: cabinetCurrentUserData.permissions && cabinetCurrentUserData.permissions
          };

          return {
            ...state,
            currentUser: currentUser,
            contacts: ((state.contacts || []).filter((item:MainUserType)=>{
              return item.uuid === currentUser.uuid
            }).length<1) ? [...state.contacts, currentUser] : [...state.contacts],
            isFetched: true,
            isLoading: false,
          };
        case MainUserActionTypes.GET_MAIN_CURRENT_USER_PROFILE:
          const mainUserBasicDetails = action.payload.data.items[0]['basicDetails'];

          loggedinUser = getLoggedinUser();

          setLoggeedInUser({...loggedinUser, ...mainUserBasicDetails});

          let updatedUser: MainUserType = {
            ...state.currentUser,
            avatar: mainUserBasicDetails.avatar && mainUserBasicDetails.avatar,
            name: mainUserBasicDetails.user.fullName && mainUserBasicDetails.user.fullName,
            phone: mainUserBasicDetails.user.mobilePhone && mainUserBasicDetails.user.mobilePhone
          }

          state.contacts = (state.contacts || []).filter((item:MainUserType)=>{
            return item.uuid !== updatedUser.uuid
          });

          return {
            ...state,
            currentUser: {...updatedUser},
            contacts:  [...state.contacts, updatedUser],
            isFetched: true,
            isLoading: false,
          };
        case MainUserActionTypes.SEND_SET_USER_LANGUAGE:
          const language = action.payload.data.language;

          loggedinUser = getLoggedinUser();
          setLoggeedInUser({
            ...loggedinUser,
            language: language,
            token: action.payload.data.token.token,
            refresh_token: action.payload.data.token.refresh_token
          });

          return {
            ...state,
          };
        case MainUserActionTypes.UPDATE_MAIN_USER:

          loggedinUser = getLoggedinUser();

          return {
            ...state,
            selectedItem: NewMainUserItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true,
            isNeedUpdateCabinetSettings: state?.selectedItem?.uuid === loggedinUser.uuid
          };
        case MainUserActionTypes.REMOVE_MAIN_USER:
          return {
            ...state,
            selectedItem: NewMainUserItem,
            removeModalShow: false,
            isNeedReload: true
          };
        case MainUserActionTypes.SIGNUP_USER:
          return {
            ...state,
            isSignUpComplete: (action.payload && action.payload.data && action.payload.data.success) || false,
            isSendSignUp:false,
          };
        case MainUserActionTypes.RESET_USER:
          return {
            ...state,
            isResetComplete: (action.payload && action.payload.data && action.payload.data.success) || false,
            isSendReset:false,
          };
        case MainUserActionTypes.CHANGE_PASSWORD_USER:
          return {
            ...state,
            isChangePasswordComplete: (action.payload && action.payload.data && action.payload.data.success) || false,
            isSendChangePassword:false,
          };
        case MainUserActionTypes.CHANGE_EMAIL_USER:
          return {
            ...state,
            isSendChangeEmail: false,
            isChangeEmailComplete: true
          }
        case MainUserActionTypes.GET_MAIN_CONTACTS:
        case MainUserActionTypes.GET_CABINET_CONTACTS:

          let uniqueContacts = (action.payload.data.items || []).filter((contact:MainUserType)=>{
              return (state.contacts || []).filter((stateContact: MainUserType)=>{return stateContact.uuid === contact.uuid}).length<1;
          });

          return {
            ...state,
            contacts: [...state.contacts, ...uniqueContacts].sort((a, b) => {
                if (a?.name && a?.name.length>0 && b?.name && b?.name.length>0) {
                    return a.name.localeCompare(b.name);
                } else {
                    return a.email.localeCompare(b.email);
                }}),
            isLoading: false
          };
        default:
          return { ...state };
      }

    case MainUserActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case MainUserActionTypes.GET_MAIN_USERS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case MainUserActionTypes.GET_MAIN_CURRENT_USER:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        case MainUserActionTypes.REMOVE_MAIN_USER:
          return {
            ...state,
            removeModalShow: false,
          };
        case MainUserActionTypes.SIGNUP_USER:
          return {
            ...state,
            isSignUpComplete: false,
            isSendSignUp:false,
          };
        case MainUserActionTypes.RESET_USER:
          return {
            ...state,
            isResetComplete: false,
            isSendReset:false,
          };
        case MainUserActionTypes.CHANGE_PASSWORD_USER:
          if ((action.payload.error?.message) && (action.payload.error.message==='Invalid credentials.')){
            action.payload.error.message='invalidCredentials';
          }
          return {
            ...state,
            error: action.payload.error,
            isChangePasswordComplete: false,
            isSendChangePassword: false,
          };
        case MainUserActionTypes.CHANGE_EMAIL_USER:
          return {
            ...state,
            isSendChangeEmail: false,
          }
        case MainUserActionTypes.UPDATE_MAIN_USER:
          return {
            ...state,
            isSending: false
          };
        case MainUserActionTypes.GET_MAIN_CONTACTS:
        case MainUserActionTypes.GET_CABINET_CONTACTS:
          return {
            ...state,
            isLoading: false
          };
        default:
          return { ...state };
      }
    case MainUserActionTypes.UPDATE_MAIN_USER:
      return {
        ...state,
        isSending: true
      };
    case MainUserActionTypes.CHANGE_PASSWORD_USER:
      return {
        ...state,
        isSendChangePassword:true,
      };
    case MainUserActionTypes.EDIT_MAIN_USER:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case MainUserActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewMainUserItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case MainUserActionTypes.REMOVE_MAIN_USER_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case MainUserActionTypes.REMOVE_MAIN_USER_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewMainUserItem,
        removeModalShow: false
      }
    case MainUserActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case MainUserActionTypes.SIGNUP_COMPLETE:
      return {
        ...state,
        isSignUpComplete: true,
        isSendSignUp:false,
      }
    case MainUserActionTypes.SEND_SIGNUP_STATUS:
      return {
        ...state,
        isSendSignUp: action.payload.status,
      }
    case MainUserActionTypes.SEND_RESET_STATUS:
      return {
        ...state,
        isSendReset: action.payload.status,
      }
    case MainUserActionTypes.SEND_CHANGE_PASSWORD_STATUS:
      return {
        ...state,
        isSendChangePassword: action.payload.status,
      }
    case MainUserActionTypes.CHANGE_EMAIL_USER:
      return {
        ...state,
        isSendChangeEmail: true,
      }
    case MainUserActionTypes.SIGNUP_RESEND_CONFIRMATION_EMAIL:
      return {
        ...state,
        isResendConfirmationEmail: true,
      }
    case MainUserActionTypes.GET_MAIN_CONTACTS:
    case MainUserActionTypes.GET_MAIN_USERS:
    case MainUserActionTypes.GET_CABINET_CONTACTS:
    case MainUserActionTypes.GET_MAIN_CURRENT_USER_PROFILE:
      return {
        ...state,
        isLoading: true
      };
    case MainUserActionTypes.CLEAR_UPDATE_CABINET_SETTINGS:
      return {
        ...state,
        isNeedUpdateCabinetSettings: false
      };
    default:
      return { ...state };
  }
};

export default MainUser;
