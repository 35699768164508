// types
import {PeerActivityTypeActionTypes, PeerActivityTypeState, PeerActivityTypeType} from "./types";
import {NewEmptyActionsItem} from "../../types";

export const NewPeerActivityTypeItem:PeerActivityTypeType = {
  enabled: true,
  name: '',
  code: '',
  color: '#21a9e1',
  text: '',
  isDefault: false,
  order: 500,
}

export const INIT_STATE_CONTENT_DATAITEMTYPE: PeerActivityTypeState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewPeerActivityTypeItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const PeerActivityType = (state = INIT_STATE_CONTENT_DATAITEMTYPE, action: any) => {
  switch (action.type) {
    case PeerActivityTypeActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case PeerActivityTypeActionTypes.GET_CONTENT_PEER_ACTIVITY_TYPES:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case PeerActivityTypeActionTypes.UPDATE_CONTENT_DATAITEMTYPE:
          return {
            ...state,
            selectedItem: NewPeerActivityTypeItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE:
          return {
            ...state,
            selectedItem: NewPeerActivityTypeItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case PeerActivityTypeActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case PeerActivityTypeActionTypes.GET_CONTENT_PEER_ACTIVITY_TYPES:
          return {
            ...state,
            isLoading: false,
            isFetched: false,
          };
        case PeerActivityTypeActionTypes.UPDATE_CONTENT_DATAITEMTYPE:
          return {
            ...state,
            isSending: false
          };
        case PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE:
          return {
            ...state,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }
    case PeerActivityTypeActionTypes.EDIT_CONTENT_DATAITEMTYPE:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case PeerActivityTypeActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewPeerActivityTypeItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewPeerActivityTypeItem,
        removeModalShow: false
      }
    case PeerActivityTypeActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case PeerActivityTypeActionTypes.UPDATE_CONTENT_DATAITEMTYPE:
      return {
        ...state,
        isSending: true
      };
    case PeerActivityTypeActionTypes.GET_CONTENT_PEER_ACTIVITY_TYPES:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default PeerActivityType;
