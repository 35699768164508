import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getEventWebhooks = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_EVENT_WEBHOOKS+"?"+query);
};

const sendDataEventWebhook = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_EVENT_WEBHOOK, data);
};

const removeEventWebhook = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_EVENT_WEBHOOK, data);
};

export {
    getEventWebhooks,
    sendDataEventWebhook,
    removeEventWebhook
};
