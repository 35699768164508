import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum SaleDeliverySystemActionTypes {
    API_RESPONSE_SUCCESS = "@@saleDeliverySystem/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleDeliverySystem/API_RESPONSE_ERROR",
    GET_SALE_DELIVERY_SYSTEMS = "@@saleDeliverySystem/GET_SALE_DELIVERY_SYSTEMS",
    UPDATE_SALE_DELIVERY_SYSTEM = "@@saleDeliverySystem/UPDATE_SALE_DELIVERY_SYSTEM",
    REMOVE_SALE_DELIVERY_SYSTEM_MODAL_SHOW = "@@saleDeliverySystem/REMOVE_SALE_DELIVERY_SYSTEM_MODAL_SHOW",
    REMOVE_SALE_DELIVERY_SYSTEM_MODAL_HIDE = "@@saleDeliverySystem/REMOVE_SALE_DELIVERY_SYSTEM_MODAL_HIDE",
    REMOVE_SALE_DELIVERY_SYSTEM = "@@saleDeliverySystem/REMOVE_SALE_DELIVERY_SYSTEM",
    EDIT_SALE_DELIVERY_SYSTEM = "@@saleDeliverySystem/EDIT_SALE_DELIVERY_SYSTEM",
    RIGHT_MODAL_SHOW = "@@saleDeliverySystem/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleDeliverySystem/RIGHT_MODAL_HIDE",
}

export interface SaleDeliverySystemState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: DeliverySystemType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface DeliverySystemType {
    uuid?: string;
    name?: string;
    code?: string;
    enabled?: boolean;
    order?: number;
    isBase?: boolean;
    idRemote?: string;
    description?: string;
    actions?: EntityActionsType
    isAddressRequired?: boolean
}

export interface SaleDeliverySystemPayload {
    data: Array<any>;
    modalEditTitle : string;
}
