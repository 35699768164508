import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    hideRightModalSaleDeliverySystem,
    onSendDataSaleDeliverySystem
} from "../../../redux/Sale/DeliverySystem/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {DeliverySystemType} from "../../../redux/Sale/DeliverySystem/types";
import {SaleDeliverySystemValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);

    const {
        saleDeliverySystemState,
        isSending
    } = useAppSelector(state => ({
        saleDeliverySystemState: state.SaleDeliverySystem,
        isSending: state.SaleDeliverySystem.isSending
    }));

    const uuid = saleDeliverySystemState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<DeliverySystemType>(saleDeliverySystemState.selectedItem);

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    useEffect(() => {

        setFormValues(saleDeliverySystemState.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalSaleDeliverySystem());

    const onDataSubmit = (formData:DeliverySystemType) => {

        let params: DeliverySystemType = {
            uuid: formData.uuid && formData.uuid,
            enabled: formData.enabled ? formData.enabled : false,
            name: formData.name && formData.name,
            description: formData.description && formData.description,
            order: formData.order && formData.order,
            idRemote: formData.idRemote && formData.idRemote,
            isAddressRequired: formData.isAddressRequired ? formData.isAddressRequired : false,
        };

        dispatch(onSendDataSaleDeliverySystem(params));

    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleDeliverySystemState.rightModalShow}
                    id="saleDeliverySystemEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleDeliverySystemValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('delivery:'+saleDeliverySystemState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                    <Field
                                        id="form-name-input"
                                        name="name"
                                        placeholder={t('common:enterNameTitle')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isAddressRequired-input"
                                                    placeholder={t('delivery:isAddressRequired')}
                                                    name="isAddressRequired"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isAddressRequired').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isAddressRequired', !getFieldProps('isAddressRequired').value);
                                                    }}
                                                    className={"switch-sm"}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-order-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-order-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-description-input">{t('common:description')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-description-input"
                                                    name="description"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('description').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('description', text);
                                                    }}
                                                    required={false}
                                                    placeholder={t('common:enterDescription')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-0"}>
                                            <Label className="form-label" htmlFor="form-additionalSettings-input">{t('common:additionalSettings')} <a href="#" onClick={toggleShowAdditionalSettings}>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('ai:moreInfo')}
                                                        </Tooltip>
                                                    }
                                                ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                            </a></Label>
                                        </div>
                                    </Row>
                                    {isShowAdditionalSettings &&
                                    <Row>
                                        <div className={ "mb-3"}>
                                            <Label className="form-label" htmlFor="form-idRemote-input">{t('common:idRemote')}</Label>
                                            <Field
                                                id="form-idRemote-input"
                                                name="idRemote"
                                                placeholder={t('common:enterIdRemote')}
                                                component={InputText}
                                                value = {getFieldProps('idRemote').value}
                                                onChange={(order:string) => {
                                                    setFieldValue('idRemote', order);
                                                }}
                                            />
                                        </div>
                                    </Row>
                                    }
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;