import React from "react";
import { Button, Col, Row } from "reactstrap";

//i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";

const LogoDarkSVG = () => {
  return (
      <svg version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           x="0px" y="0px"
           viewBox="0 0 134.9 134.9"
           width="55"
           height="55"
      >
        <g>
          <circle fill="#39C3EF" cx="67.4" cy="14.5" r="14.5"/>
          <circle fill="#F9CD38" cx="67.4" cy="120.3" r="14.5"/>
          <circle fill="#F83F54" cx="14.5" cy="67.4" r="14.5"/>
          <circle fill="#34B47D" cx="120.3" cy="67.4" r="14.5"/>
          <circle fill="#F9CD38" cx="30" cy="104.8" r="14.5"/>
          <circle fill="#39C3EF" cx="104.8" cy="30" r="14.5"/>
          <circle fill="#34B47D" cx="104.8" cy="104.8" r="14.5"/>
          <circle fill="#F83F54" cx="30" cy="30" r="14.5"/>
        </g>
      </svg>
  );
};

const Welcome = () => {
  const { t } = useTranslation(['common']);

  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
              <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title bg-soft-primary rounded-circle">
                 <LogoDarkSVG/>
                </div>
              </div>
              <h4>{t('common:welcomeToMessenger')}</h4>
              <p className="text-muted mb-4">
                <span dangerouslySetInnerHTML={{ __html : t('common:welcomeToMessengerText')}} />
              </p>
              <Button type="button" className="btn btn-primary w-lg">
                {t('common:getStarted')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
