import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleCategoryActionTypes } from "./types";
import { saleCategoryApiResponseSuccess, saleCategoryApiResponseError } from "./actions";

import {
  getSaleCategories as getSaleCategoriesApi,
  sendDataSaleCategory as sendDataSaleCategoryApi,
  removeSaleCategory as removeSaleCategoryApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleCategories({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleCategoriesApi, data);
    yield put(saleCategoryApiResponseSuccess(SaleCategoryActionTypes.GET_SALE_CATEGORIES, response));
  } catch (error: any) {
    yield put(saleCategoryApiResponseError(SaleCategoryActionTypes.GET_SALE_CATEGORIES, error));
  }
}

function* onSendDataSaleCategory({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleCategoryApi, data);
    yield put(
        saleCategoryApiResponseSuccess(SaleCategoryActionTypes.UPDATE_SALE_CATEGORY, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCategoryApiResponseError(SaleCategoryActionTypes.UPDATE_SALE_CATEGORY, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleCategory({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleCategoryApi, {uuid: data.data.uuid});
    yield put(
        saleCategoryApiResponseSuccess(SaleCategoryActionTypes.REMOVE_SALE_CATEGORY, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCategoryApiResponseError(SaleCategoryActionTypes.REMOVE_SALE_CATEGORY, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleCategories() {
  yield takeEvery(SaleCategoryActionTypes.GET_SALE_CATEGORIES, getSaleCategories);
}
export function* watchOnSendDataSaleCategory() {
  yield takeEvery(SaleCategoryActionTypes.UPDATE_SALE_CATEGORY, onSendDataSaleCategory);
}
export function* watchOnRemoveSaleCategory() {
  yield takeEvery(SaleCategoryActionTypes.REMOVE_SALE_CATEGORY, onRemoveSaleCategory);
}

function* saleCategorySaga() {
  yield all([
    fork(watchGetSaleCategories),
    fork(watchOnSendDataSaleCategory),
    fork(watchOnRemoveSaleCategory)
  ]);
}

export default saleCategorySaga;
