import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    hideRightModalSaleProductParam,
    onSendDataSaleProductParam
} from "../../../redux/Sale/ProductParam/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';
import { v4 as uuidv4 } from 'uuid';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {ProductParamListValueType, ProductParamType, ProductParamTypes} from "../../../redux/Sale/ProductParam/types";
import {SaleProductParamValidatorSchema} from "./validator";
import ButtonSpinner from "../../../components/ButtonSpinner";
import InputSelect from "../../../components/InputSelect";
import {NewSaleProductParamListItem} from "../../../redux/Sale/ProductParam/reducer";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import NoticeTip from "../../../components/NoticeTip";
import InputColor from "../../../components/InputColor";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<ProductParamType>(null);
    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);

    const {
        saleProductParamState,
        isSending
    } = useAppSelector(state => ({
        saleProductParamState: state.SaleProductParam,
        isSending: state.SaleProductParam.isSending
    }));

    const uuid = saleProductParamState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<ProductParamType>(saleProductParamState.selectedItem);

    const productParamTypes = Object.keys(ProductParamTypes).map((item:string)=>{
        return {
            uuid: ProductParamTypes[item],
            name: t('common:propertyType'+item)
        }
    });

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    const addEmptyListItem = () => {
        if (currentItem.values === undefined || currentItem.values === null){
            currentItem.values = [];
        }
        currentItem.values.push({...NewSaleProductParamListItem, uuid: uuidv4()});

        setCurrentItem({...currentItem});
    }

    const removeListItem = (item: ProductParamListValueType) => {

        currentItem.values = (currentItem.values || []).filter((itemList:ProductParamListValueType)=>{
            return itemList.uuid !== item.uuid;
        });

        setCurrentItem({...currentItem});
    }

    const setListItemValue = (item: ProductParamListValueType, value: string) => {

        (currentItem.values || []).map((itemList:ProductParamListValueType)=>{

            if (itemList.uuid === item.uuid){
                itemList.value = value;
            }

            return itemList;
        });

        setCurrentItem({...currentItem});
    }
    const setListItemColorValue = (item: ProductParamListValueType, value: string) => {

        (currentItem.values || []).map((itemList:ProductParamListValueType)=>{

            if (itemList.uuid === item.uuid){
                itemList.color = value;
            }

            return itemList;
        });

        setCurrentItem({...currentItem});
    }
    const setListItemOrderValue = (item: ProductParamListValueType, value: string) => {

        (currentItem.values || []).map((itemList:ProductParamListValueType)=>{

            if (itemList.uuid === item.uuid){
                itemList.order = parseInt(value);
            }

            return itemList;
        });

        setCurrentItem({...currentItem});
    }
    const setListItemValueIdRemote = (item: ProductParamListValueType, value: string) => {

        (currentItem.values || []).map((itemList:ProductParamListValueType)=>{

            if (itemList.uuid === item.uuid){
                itemList.idRemote = value;
            }

            return itemList;
        });

        setCurrentItem({...currentItem});
    }

    useEffect(() => {

        setFormValues(currentItem);

    }, [currentItem]);

    useEffect(() => {

        if (saleProductParamState?.selectedItem?.type && saleProductParamState.selectedItem.type === ProductParamTypes.LIST){
            if (saleProductParamState.selectedItem.values === undefined || saleProductParamState.selectedItem.values === null){
                saleProductParamState.selectedItem.values = [];
            }
            saleProductParamState.selectedItem.values.push({...NewSaleProductParamListItem, uuid: uuidv4()});
        }

        setCurrentItem({...saleProductParamState.selectedItem});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const addEmptyListItemToForm = () => {
        addEmptyListItem();
    }

    const handleClose = () => dispatch(hideRightModalSaleProductParam());

    const onDataSubmit = (formData:any) => {

        let params: ProductParamType = {
            uuid: uuid && uuid,
            enabled: formData.enabled ? formData.enabled : false,
            name: formData.name && formData.name,
            order: formData.order && formData.order,
            idRemote: formData.idRemote && formData.idRemote,
            type: formData.type && formData.type,
            isBrand: formData.isBrand && formData.isBrand,
            isColor: formData.isColor && formData.isColor,
            isSize: formData.isSize && formData.isSize,
            isCart: formData.isCart && formData.isCart,
            isBarCode: formData.isBarCode && formData.isBarCode
        };

        if (formData?.type?.value !== undefined){
            params={
                ...params,
                type: formData.type.value
            }
        }

        if (params.type === ProductParamTypes.LIST){
            params.values = (currentItem.values || []).filter((itemList:ProductParamListValueType)=>{
                return itemList.value !== undefined && itemList.value !== null && itemList.value !== '' ;
            });
        }

        dispatch(onSendDataSaleProductParam(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleProductParamState.rightModalShow}
                    id="saleProductParamEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleProductParamValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('product:'+saleProductParamState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="name"
                                        placeholder={t('common:enterNameTitle')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row key={'type'} className="mb-3">
                                        <Label className="form-label" htmlFor="form-name-input">{t('common:type')}</Label>
                                        <Field
                                            id="form-type-select"
                                            name={"type"}
                                            placeholder={t('common:chooseType')}
                                            component={InputSelect}
                                            value={getFieldProps('type').value}
                                            onChange={(value: any) => {
                                                setFieldValue("type",value);
                                            }}
                                            items={productParamTypes || []}
                                            className={errors.type && touched.type && 'form-control is-invalid'}
                                        />
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isBrand-input"
                                                    placeholder={t('product:productPropertyAsBrand')}
                                                    name="isBrand"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isBrand').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isBrand', !getFieldProps('isBrand').value);
                                                    }}
                                                    className="form-switch-sm"
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isColor-input"
                                                    placeholder={t('product:productPropertyAsColor')}
                                                    name="isColor"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isColor').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isColor', !getFieldProps('isColor').value);
                                                    }}
                                                    className="form-switch-sm"
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isSize-input"
                                                    placeholder={t('product:productPropertyAsSize')}
                                                    name="isSize"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isSize').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isSize', !getFieldProps('isSize').value);
                                                    }}
                                                    className="form-switch-sm"
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isBarCode-input"
                                                    placeholder={t('product:productPropertyAsBarCode')}
                                                    name="isBarCode"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isBarCode').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isBarCode', !getFieldProps('isBarCode').value);
                                                    }}
                                                    className="form-switch-sm"
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-isCart-input"
                                                    placeholder={t('product:productPropertyAsIsCart')}
                                                    name="isCart"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('isCart').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('isCart', !getFieldProps('isCart').value);
                                                    }}
                                                    className="form-switch-sm"
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-0"}>
                                            <Label className="form-label" htmlFor="form-additionalSettings-input">{t('common:additionalSettings')} <a href="#" onClick={toggleShowAdditionalSettings}>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('ai:moreInfo')}
                                                        </Tooltip>
                                                    }
                                                ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                            </a></Label>
                                        </div>
                                    </Row>

                                    <NoticeTip firstText={t('product:productPropertyAddText')}/>

                                    {isShowAdditionalSettings &&
                                    <Row>
                                        <div className={ "mb-3"}>
                                            <Label className="form-label" htmlFor="form-idRemote-input">{t('common:idRemote')}</Label>
                                            <Field
                                                id="form-idRemote-input"
                                                name="idRemote"
                                                placeholder={t('common:enterIdRemote')}
                                                component={InputText}
                                                value = {getFieldProps('idRemote').value}
                                                onChange={(order:string) => {
                                                    setFieldValue('idRemote', order);
                                                }}
                                            />
                                        </div>
                                    </Row>
                                    }
                                    { (
                                        getFieldProps('type').value === ProductParamTypes.LIST
                                        ||
                                        getFieldProps('type').value?.value === ProductParamTypes.LIST
                                        ) &&
                                        <>
                                            <div className="d-flex flex-row mb-2">
                                                <div className="d-flex flex-grow-1">
                                                    {t('product:propertyValues')}
                                                </div>
                                                <div className="d-flex flex-grow-0 flex-shrink-0">
                                                    <Link to="#" onClick={addEmptyListItemToForm}>+{t('common:addNew')}</Link>
                                                </div>
                                            </div>

                                            {(getFieldProps('values').value || []).map((itemValue:ProductParamListValueType, key: number)=>(
                                                <div className="d-flex flex-row flex-nowrap position-relative mb-3" id={"form-paramListValue-cont_"+itemValue.uuid}
                                                     key={"form-paramListValue-cont_"+itemValue.uuid}
                                                >
                                                    <div className="position-absolute top-0 start-0">
                                                        <div className="ps-1 mt-1 text-muted fs-10">{key+1}</div>
                                                    </div>
                                                    <div className="d-flex flex-column flex-grow-1">
                                                        <div className="d-flex flex-row flex-grow-1 mb-1">
                                                            {getFieldProps('isColor').value &&
                                                                <div className="d-flex flex-column justify-content-center ms-3 me-1">
                                                                <Field
                                                                    id="form-color-input"
                                                                    name={"paramListColorValue_"+itemValue.uuid}
                                                                    component={InputColor}
                                                                    value = {itemValue?.color}
                                                                    onChange={(color:string) => {
                                                                        setListItemColorValue(itemValue, color);
                                                                    }}
                                                                    required={false}
                                                                />
                                                                </div>
                                                            }
                                                            <Field
                                                                id={"form-paramListValue-input_"+itemValue.uuid}
                                                                name={"paramListValue_"+itemValue.uuid}
                                                                placeholder={t('product:enterPropertyValue')}
                                                                component={InputText}
                                                                value = {itemValue.value}
                                                                onChange={(value:string) => {
                                                                    setListItemValue(itemValue, value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-row flex-grow-1">
                                                            <Field
                                                                id={"form-paramListValueIdRemote-input_"+itemValue.uuid}
                                                                name={"paramListValueIdRemote_"+itemValue.uuid}
                                                                placeholder={t('common:enterIdRemote')}
                                                                component={InputText}
                                                                value = {itemValue.idRemote}
                                                                onChange={(value:string) => {
                                                                    setListItemValueIdRemote(itemValue, value);
                                                                }}
                                                                className="me-2"
                                                            />
                                                            <Field
                                                                id={"form-paramListOrderValue-input_"+itemValue.uuid}
                                                                name={"paramListOrderValue_"+itemValue.uuid}
                                                                placeholder={t('common:order')}
                                                                component={InputText}
                                                                value = {itemValue.order}
                                                                onChange={(value:string) => {
                                                                    setListItemOrderValue(itemValue, value);
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-grow-0 flex-shrink-1 ms-3">
                                                        <button type="button"
                                                                onClick={()=>{removeListItem(itemValue)}}
                                                                className="btn btn-outline-primary btn-icon waves-effect waves-light">
                                                            <i className="ri-delete-bin-line"/></button>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    }
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;