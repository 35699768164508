
export enum SaleIntegrationHandlerActionTypes {
  API_RESPONSE_SUCCESS = "@@saleIntegrationHandler/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@saleIntegrationHandler/API_RESPONSE_ERROR",
  GET_SALE_INTEGRATION_HANDLERS = "@@saleIntegrationHandler/GET_SALE_INTEGRATION_HANDLERS",
  SELECT_SALE_INTEGRATION_HANDLER = "@@saleIntegrationHandler/SELECT_SALE_INTEGRATION_HANDLER",

}

export interface SaleIntegrationHandlerState {
  items: Array<any>;
  selectedItem?: SaleIntegrationHandlerType;
  isFetched: boolean;
  isLoading: boolean;
}

export interface SaleIntegrationHandlerType{
  uuid?: string,
  enabled?: boolean,
  code?: string,
  type?: string,
  order?: number,
  icon?: string,
}

export interface SaleIntegrationHandlerPayload {
  data: Array<any>;
  modalEditTitle?: string;
}

export enum SaleIntegrationHandlerTypes {
  INBOUND = "I",
  OUTBOUND= "O"
}

export enum SaleIntegrationHandlers {
  HANDLER_GOOGLE_FEED = 'googleFeed',
  HANDLER_WIDGET_TELEGRAM = 'widgetTelegram',
  HANDLER_NULL = 'null'
}

export enum SaleIntegrationHandlerCodes {
  GOOGLE_FEED = 'googleFeed',
  YANDEX_FEED = 'yandexFeed',
  WIDGET_TELEGRAM = 'widgetTelegram',
  WIDGET_WEB = 'widgetWeb',
  CSV_GOOGLE_FEED = 'csvGoogleFeed',
  META_BUSINESS_MANAGER = 'metaBusinessManager',
  FACEBOOK_WIDGET_WEB = 'facebookWidgetWeb',
  VK_SIMPLE = 'vkSimple',
  NULL = 'null'
}
