import {TaskActionTypes, TaskCopyPayload, TaskPayload, TaskType} from "./types";
import {RewardType} from "../../../data/reward";
import {MainMetaType} from "../../Main/common";
import {CommentPayload} from "../../Main/Comment/types";
import {TaskTimePayload} from "../TaskTime/types";
import {SaleProductActionTypes} from "../../Sale/Product/types";

export const taskApiResponseSuccess = (actionType: string, data: any) => ({
  type: TaskActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const taskApiResponseError = (actionType: string, error: string) => ({
  type: TaskActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTasks= (data: MainMetaType) => ({
  type: TaskActionTypes.GET_TASKS,
  payload: data
});

export const showRightModalTask= (data: TaskPayload) => ({
  type: TaskActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalTask= () => ({
  type: TaskActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataTask = (data: any) => ({
  type: TaskActionTypes.UPDATE_TASK,
  payload: data,
});

export const editDataTask = (data: TaskPayload) => ({
  type: TaskActionTypes.EDIT_TASK,
  payload: data,
});

export const changeTaskStatus = (data: TaskPayload) => ({
  type: TaskActionTypes.CHANGE_TASK_STATUS,
  payload: data,
});

export const removeTaskModalShow = (data: TaskPayload) => ({
  type: TaskActionTypes.REMOVE_TASK_MODAL_SHOW,
  payload: data,
});

export const removeTaskModalHide = () => ({
  type: TaskActionTypes.REMOVE_TASK_MODAL_HIDE,
});

export const onRemoveTask = (data: TaskPayload) => ({
  type: TaskActionTypes.REMOVE_TASK,
  payload: data,
});

export const getTaskDetail = (data: any) => ({
  type: TaskActionTypes.GET_TASK_DETAIL,
  payload: data,
});

export const showRewardTask= (data: TaskPayload) => ({
  type: TaskActionTypes.REWARD_TASK_MODAL_SHOW,
  payload: data,
});

export const hideRewardModalTask= () => ({
  type: TaskActionTypes.REWARD_TASK_MODAL_HIDE,
});

export const onSendTaskReward= (data: RewardType) => ({
  type: TaskActionTypes.SEND_REWARD_TASK,
  payload: data,
});

export const copyTask = (data: TaskCopyPayload) => ({
  type: TaskActionTypes.COPY_TASK,
  payload: data,
});

// task times --->
export const getTaskTimes = (data: any) => ({
  type: TaskActionTypes.GET_TASK_TIMES,
  payload: data,
});

export const sendTaskTime = (data: any) => ({
  type: TaskActionTypes.UPDATE_TASK_TIME,
  payload: data,
});

export const showTaskTimeRemoveModal= (data: TaskTimePayload) => ({
  type: TaskActionTypes.REMOVE_TASK_TIME_MODAL_SHOW,
  payload: data,
});

export const removeTaskTime = (data: TaskTimePayload) => ({
  type: TaskActionTypes.REMOVE_TASK_TIME,
  payload: data,
});

export const hideTaskTimeRemoveModal= () => ({
  type: TaskActionTypes.REMOVE_TASK_TIME_MODAL_HIDE,
});
// task times <---


// comments --->
export const getTaskComments = (data: any) => ({
  type: TaskActionTypes.GET_COMMENTS,
  payload: data,
});

export const readTaskComments = (data: any) => ({
  type: TaskActionTypes.READ_COMMENTS,
  payload: data,
});

export const sendTaskComment = (data: any) => ({
  type: TaskActionTypes.UPDATE_COMMENT,
  payload: data,
});

export const showTaskCommentRemoveModal= (data: CommentPayload) => ({
  type: TaskActionTypes.REMOVE_COMMENT_MODAL_SHOW,
  payload: data,
});

export const removeTaskComment = (data: CommentPayload) => ({
  type: TaskActionTypes.REMOVE_COMMENT,
  payload: data,
});

export const hideTaskCommentRemoveModal= () => ({
  type: TaskActionTypes.REMOVE_COMMENT_MODAL_HIDE,
});
// comments <---


export const closeTaskGroup = (data: any) => ({
  type: TaskActionTypes.CLOSE_TASK_GROUP,
  payload: data
});
export const closeTaskRemoveModalShow = () => ({
  type: TaskActionTypes.CLOSE_TASK_GROUP_MODAL_SHOW
});
export const closeTaskRemoveModalHide = () => ({
  type: TaskActionTypes.CLOSE_TASK_GROUP_MODAL_HIDE
});

export const removeTaskGroup = (data: any) => ({
  type: TaskActionTypes.REMOVE_TASK_GROUP,
  payload: data
});
export const groupTaskRemoveModalShow = () => ({
  type: TaskActionTypes.REMOVE_TASK_GROUP_MODAL_SHOW
});
export const groupTaskRemoveModalHide = () => ({
  type: TaskActionTypes.REMOVE_TASK_GROUP_MODAL_HIDE
});