import {SaleOrderPaymentActionTypes, SaleOrderPaymentPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleOrderPaymentApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleOrderPaymentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleOrderPaymentApiResponseError = (actionType: string, error: string) => ({
  type: SaleOrderPaymentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleOrderPayments = (data: MainMetaType) => ({
  type: SaleOrderPaymentActionTypes.GET_SALE_ORDER_PAYMENTS,
  payload: data,
});

export const showRightModalSaleOrderPayment= (data: SaleOrderPaymentPayload) => ({
  type: SaleOrderPaymentActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleOrderPayment= () => ({
  type: SaleOrderPaymentActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleOrderPayment = (data: any) => ({
  type: SaleOrderPaymentActionTypes.UPDATE_SALE_ORDER_PAYMENT,
  payload: data,
});

export const editDataSaleOrderPayment = (data: SaleOrderPaymentPayload) => ({
  type: SaleOrderPaymentActionTypes.EDIT_SALE_ORDER_PAYMENT,
  payload: data,
});

export const removeSaleOrderPaymentModalShow = (data: SaleOrderPaymentPayload) => ({
  type: SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT_MODAL_SHOW,
  payload: data,
});

export const removeSaleOrderPaymentModalHide = () => ({
  type: SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT_MODAL_HIDE,
});

export const onRemoveSaleOrderPayment = (data: SaleOrderPaymentPayload) => ({
  type: SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT,
  payload: data,
});

export const clearSaleOrderPaymentNeedReload= () => ({
  type: SaleOrderPaymentActionTypes.CLEAR_NEED_ORDER_RELOAD,
});