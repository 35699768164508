import {EntityActionsType} from "../../types";

export enum ContentPeerTargetTypeActionTypes {
  API_RESPONSE_SUCCESS = "@@contentPeerTargetType/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentPeerTargetType/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_TARGET_TYPES = "@@contentPeerTargetType/GET_CONTENT_PEER_TARGET_TYPES",

}

export interface ContentPeerTargetTypeState {
  items: Array<any>;
}

export interface ContentPeerTargetTypeType{
  uuid?: string,
  enabled: boolean,
  code: string,
  objectType?: string,
  text?: string,
  actions?: EntityActionsType
}

export interface ContentPeerTargetTypePayload {
  data: Array<any>;
  modalEditTitle : string;
}
