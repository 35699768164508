import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classnames from "classnames";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import { useRedux } from "../../../hooks";

import {ProductType} from "../../../redux/Sale/Product/types";
import {
    copyProduct,
    getSaleProducts, groupIntegrationModalShow, groupRemoveProductsModalShow, onRemoveSaleProduct,
    removeSaleProductModalHide,
    removeSaleProductModalShow, setGroupProductsModalShow, unSetGroupProductsModalShow
} from "../../../redux/Sale/Product/actions";
import {ProductStatus} from "../../../redux/Sale/Product/types";
import Loader from "../../../components/Loader"
import * as route from "../../../api/routes";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {getHistoryHash, getIsNeedRefresh, isNumeric, removeIsNeedRefresh} from "../../../helpers/functions";
import SaleProductColumns from "./tableColumns";
import {EntityActionsHandlersType} from "../../../redux/types";
import TableList from "../../../components/TableList";
import {setHistoryHash as setHistoryHashFunc} from "../../../helpers/functions";
import RemoveModal from "../../../components/RemoveForm";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import UpgradeFarePage from "../../../components/UpgradeFarePage";
import RightModal from "../Category/modalForm";
import {editDataSaleCategory, showRightModalSaleCategory} from "../../../redux/Sale/Category/actions";
import {SaleStoreType} from "../../../redux/Sale/Store/types";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {getSaleStores} from "../../../redux/Sale/Store/actions";
import {getSaleCurrencies} from "../../../redux/Sale/Currency/actions";
import {getSalePriceTypes} from "../../../redux/Sale/PriceType/actions";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import RightModalGroupIntegration from "./modalGroupIntegrationForm";
import {MainMetaType} from "../../../redux/Main/common";
import MainListFilter from "../../../components/MainListFilter";
import {getSaleProductParams} from "../../../redux/Sale/ProductParam/actions";
import GroupProductsModal from "./GroupProductsModal";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {getSaleIntegrations} from "../../../redux/Sale/Integration/actions";
import DeleteProductsModal from "./DeleteProductsModal";

const SaleProductIndex = () => {

    const { t, i18n } = useTranslation(['common']);

    const componentType = 'saleProductItemsType';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [selectedItems, setSelectedItems] = useState<ProductType[]>([]);
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [searchPhrase, setSearchPhrase] = useState<string>('');

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults ? historyHash.maxResults : perPage,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection,
            searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length>0 && historyHash.searchPhrase
        }

        if (historyHash?.tab){
            switch (historyHash?.tab) {
                case 'New':
                    mainMeta={
                        ...mainMeta,
                        status: ProductStatus.NEW.status
                    }
                    break;
                case 'Final':
                    mainMeta={
                        ...mainMeta,
                        status: ProductStatus.FINAL.status
                    }
                    break;
            }
        }

        dispatch(getSaleProducts(mainMeta));
    }

    useEffect(() => {
        if (historyHash.tab !== undefined && historyHash.tab.length>0 && activeTab !== historyHash.tab){
            toggleTab(historyHash.tab);
        }
        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        loadItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { dispatch, useAppSelector } = useRedux();

    const [activeTab, setActiveTab] = useState("All");
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [stores, setStores] = useState<SaleStoreType[]>([]);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
    const [priceTypes, setPriceTypes] = useState<PriceTypeType[]>([]);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [isGroupActionReady, setGroupActionReady] = useState<boolean>(false);
    const [saleIntegrations, setSaleIntegrations] = useState<SaleIntegrationType[]>([]);

    const {
        saleProduct,
        isFetched,
        saleStore,
        saleCurrency,
        salePriceType,
        contentPeerChannels,
        salePlan,
        saleProductParam,
        saleIntegration
    } = useAppSelector(state => ({
        isFetched: state.SaleProduct.isFetched,
        saleProduct: state.SaleProduct,
        saleStore: state.SaleStore,
        saleCurrency: state.SaleCurrency,
        salePriceType: state.SalePriceType,
        contentPeerChannels: state.ContentPeerChannel,
        salePlan: state.SalePlan,
        saleProductParam: state.SaleProductParam,
        saleIntegration: state.SaleIntegration
    }));

    const [products, setProducts] = useState<ProductType[]>([]);

    useEffect(() => {
        dispatch(getSaleStores({maxResults:1000}));
        dispatch(getSaleCurrencies({maxResults:1000}));
        dispatch(getSalePriceTypes({maxResults:1000}));
        dispatch(getSaleProductParams({maxResults: 1000}));
        dispatch(getSaleIntegrations({maxResults: 1000}));
        dispatch(getContentPeerChannels());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (isFetched) {
            setLoaded(isFetched);
        }
    }, [isFetched]);

    const checkIsAllFetched = () => {
        if (
            saleStore.isFetched
            &&
            saleCurrency.isFetched
            &&
            salePriceType.isFetched
            &&
            contentPeerChannels.isFetched
            &&
            saleProductParam.isFetched
            &&
            saleIntegration.isFetched
        ){
            setGroupActionReady(true);
        }
    }

    useEffect(() => {

        if (saleStore?.items && (saleStore?.items || []).length>0){
            setStores([...saleStore.items]);
        } else {
            setStores([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleStore?.items]);

    useEffect(() => {

        if (contentPeerChannels?.items && (contentPeerChannels?.items || []).length>0){
            setChannels([...contentPeerChannels.items]);
        } else {
            setChannels([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerChannels?.items]);

    useEffect(() => {

        if (saleIntegration?.items && (saleIntegration?.items || []).length>0){
            setSaleIntegrations([...saleIntegration.items]);
        } else {
            setSaleIntegrations([]);
        }

        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleIntegration?.items]);

    useEffect(() => {

        if (saleCurrency?.items && (saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleCurrency?.items]);

    useEffect(() => {

        if (salePriceType?.items && (salePriceType?.items || []).length>0){
            setPriceTypes([...salePriceType.items]);
        } else {
            setPriceTypes([]);
        }
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePriceType?.items]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_PRODUCTS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    useEffect(() => {
        if (saleProduct.isNeedReload){
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saleProduct.isNeedReload]);

    useEffect(() => {

        if ((saleProduct.items !== undefined) && (saleProduct.items.length>0)){
            setProducts([...saleProduct.items]);
        } else {
            setProducts([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProduct?.items])

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleClickTab = (tab) => {
        if (activeTab !== tab) {
            if ( historyHash.tab !== tab) {
                historyHash.tab = tab;
                historyHash.page = 1;
                setHistoryHashFunc(historyHash);
            }
        }
    }


    const handleSearchClick = (value:string) => {
        setSearchPhrase(value);
        if ( historyHash.searchPhrase !== value) {
            historyHash.searchPhrase = value;
            setHistoryHashFunc(historyHash);
        }
    }

    const onTableRowClick = (item:ProductType) => {
        window.location.href = route.SALE_PRODUCT_DETAIL.replace(/:uuid/g, item.uuid);
    }

    const onHandleEdit = (item:ProductType) => {
        window.location.href = route.SALE_PRODUCT_DETAIL_EDIT.replace(/:uuid/g, item.uuid);
    }
    const handleCopy = (currentItem:ProductType) =>{
        dispatch(copyProduct(currentItem));
    }

    const handleRemove = (currentItem:ProductType) =>{
        dispatch(removeSaleProductModalShow({data:Object(currentItem), modalEditTitle:'deleteProduct'}));
    }

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onDetail: onTableRowClick,
        onCopy: handleCopy,
        onRemove: handleRemove,
        onEdit: onHandleEdit,
    }

    const ProductOnRemove = (event:any, dispatch:any) => {

        if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
            dispatch(onRemoveSaleProduct({data:event, modalEditTitle: 'deleteProduct'}));
        }
    }

    const ProductHideRemoveModal = (dispatch:any) => {
        dispatch(removeSaleProductModalHide())
    }

    const openRightModalProductCategory = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(editDataSaleCategory({data:event, modalEditTitle: 'changeCategory'}));
        } else {
            dispatch(showRightModalSaleCategory({data:[], modalEditTitle: 'newCategory'}));
        }
    };

    const openRightModalGroupIntegration = () => {
        dispatch(groupIntegrationModalShow());
    };

    const handleGroupActionSelectItems = (items:any[]) => {
        setSelectedItems(items);
    }

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }

    const columns = SaleProductColumns({...EntityActionsHandlers});

    const handleSetGroupAction = () =>{
        dispatch(setGroupProductsModalShow());
    }
    const handleUnSetGroupAction = () =>{
        dispatch(unSetGroupProductsModalShow());
    }
    const handleRemoveGroupAction = () =>{
        dispatch(groupRemoveProductsModalShow());
    }

    if (getIsNeedRefresh()){
        removeIsNeedRefresh();
        loadItems();
    }

    return (
        <>
            <BreadCrumb title={t('menu:products')} pageTitle={t('menu:main')} />

            { upgradeFare && isFetched ?
                <>
                    <div className="d-flex flex-row flex-grow-1">
                        <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionProductsText')}
                        </div>
                    </div>
                    <UpgradeFarePage/>
                </>
                :
                <>
            <Row>
                <Col lg={12}>
                    <Card id="productList">
                        <CardHeader className="card-header border-bottom-0">
                            <Row className="align-items-center gy-3">
                                <div className="col-sm">
                                    <div className="d-flex flex-shrink-0">
                                        <UncontrolledButtonDropdown id="btnGroupVerticalDrop1">
                                            <DropdownToggle color="success" caret>
                                                <i className="ri-add-line align-bottom me-1 font-weight-normal"/>
                                                {t('common:addNew')}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem href={route.SALE_PRODUCT_DETAIL_EDIT.replace(/:uuid/,'new')}>
                                                    {t('product:product')}
                                                </DropdownItem>
                                                <DropdownItem onClick={openRightModalProductCategory}>{t('category:category')}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                                <div className="col-sm text-end">
                                    <button type="button" className="btn btn-soft-primary me-2"><i
                                        className="ri-download-cloud-2-line align-bottom me-1"/> Import
                                    </button>
                                    <button type="button" className="btn btn-soft-primary"><i
                                        className="ri-upload-cloud-2-line align-bottom me-1"/> Export
                                    </button>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                            <MainListFilter
                                onClick={handleSearchClick}
                                value={searchPhrase}
                                onChange={onSearchInput}
                            />
                        </CardBody>
                    <CardBody className="pt-0">
                        <div>
                            <Nav
                                className="nav-tabs nav-tabs-custom nav-success"
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === "All" },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab( "All");
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-shopping-cart-2-line me-1 align-bottom font-weight-normal"/>{" "}
                                        {t('product:allProducts')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === "Final" },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab("Final");
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-checkbox-circle-line me-1 align-bottom font-weight-normal"/>{" "}
                                        {t('product:activeProducts')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === "New" },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab("New");
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-edit-box-line me-1 align-bottom font-weight-normal"/>{" "}
                                        {t('product:draftProducts')}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end pt-1 pb-1">
                                    <div className="d-flex flex-shrink-0">
                                        {((selectedItems && (selectedItems.length>0)) || (historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true)) &&
                                        <UncontrolledButtonDropdown id="btnGroupVerticalAction">
                                            <DropdownToggle
                                                caret
                                                className={"btn-sm btn-soft-primary"}
                                                disabled={!(selectedItems && (selectedItems.length>0)) && !isGroupActionReady && !(historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true)}
                                            >
                                                <i className="ri-checkbox-multiple-line align-bottom me-1 font-weight-normal"/>
                                                {t('common:groupAction')}{" "}(
                                                {historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true
                                                    ?
                                                        saleProduct?.meta?.totalCount > 0 ? saleProduct?.meta?.totalCount : ''
                                                    :
                                                        selectedItems.length
                                                })
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={openRightModalGroupIntegration}>{t('integration:integrations')}</DropdownItem>
                                                <DropdownItem onClick={handleSetGroupAction}>{t('common:groupItems')}</DropdownItem>
                                                <DropdownItem onClick={handleUnSetGroupAction}>{t('common:ungroupItems')}</DropdownItem>
                                                <DropdownItem onClick={handleRemoveGroupAction}>{t('common:delete')}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        }
                                    </div>

                                </NavItem>
                            </Nav>
                            {isLoaded ?
                                    <TableList
                                        columns={columns}
                                        data={products || []}
                                        onRowClick={onTableRowClick}
                                        key={componentType}
                                        historyHash = {historyHash}
                                        selectableRows = {true}
                                        handleGroupActionSelectItems = {handleGroupActionSelectItems}
                                        paginationTotalRows={saleProduct?.meta?.totalCount || 0}
                                        paginationServer={true}
                                        isShowChangePageSize={true}
                                        isShowAllGroupAction={true}
                                    />
                                :
                                <Loader />
                            }
                        </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            <RightModal/>
                    <RightModalGroupIntegration
                        stores={stores}
                        currencies={currencies}
                        priceTypes={priceTypes}
                        channels={channels}
                        products={selectedItems}
                        historyHash={historyHash}
                        integrations={saleIntegrations}
                    />
            <RemoveModal
                onRemove={ProductOnRemove}
                handleClose={ProductHideRemoveModal}
                removeItem={saleProduct.selectedItem}
                isOpen={saleProduct.removeModalShow}
                modalTitle={saleProduct.modalEditTitle}/>
            <GroupProductsModal
                products={selectedItems}
                historyHash={historyHash}
                isOpen={saleProduct.isModalGroupProductsShow}
                    />
             <DeleteProductsModal
                 products={selectedItems}
                 historyHash={historyHash}
                 isOpen={saleProduct.isModalGroupRemoveProductsShow}
             />
            </>
            }
        </>
    );
};

export default SaleProductIndex;


