import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader } from 'reactstrap';
import '../../i18/config';
import {useTranslation} from "react-i18next";
import ReactApexChart from "react-apexcharts";
import {StatOrderTileType} from "../../redux/Stat/DashboardSale/types";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {OrderStatusTypes} from "../../redux/Sale/OrderStatus/types";
import {CurrencyType} from "../../redux/Sale/Currency/types";
import {ApexOptions} from "apexcharts";
import {convertToMomentLocale} from "../../helpers/functions";

interface InputProps {
    items: [] | Array<StatOrderTileType>;
    currency: CurrencyType;
}

const SaleMainChart = ({ items, currency }: InputProps) => {

    const { t, i18n } = useTranslation();
    moment.locale(convertToMomentLocale(i18n.language));

    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const FormatCurrencyValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            notation: 'compact'
        }).format(value);

    useEffect(()=>{

        if (currency?.uuid) {
            let currentDate = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
            let lastYearDate = moment().add(-12, 'M').set({hour: 0, minute: 0, second: 0, millisecond: 0});
            let _categories = [];
            let _successOrdersCount = [];
            let _failureOrdersCount = [];
            let _successOrdersCountAmount = [];
            let _failureOrdersCountAmount = [];

            while (lastYearDate <= currentDate) {
                _categories.push(lastYearDate.format('MMM YYYY'));

                let _successOrders = 0;
                let _failureOrders = 0;
                let _successOrdersAmount = 0;
                let _failureOrdersAmount = 0;

                // eslint-disable-next-line
                (items || []).map((item: StatOrderTileType) => {

                    if ((item.type === OrderStatusTypes.SUCCESS) && (item.label === lastYearDate.format('M.YYYY')) && (item.value.order.last)) {
                        _successOrders = item.value.order.last;
                    }

                    if ((item.type === OrderStatusTypes.FAILURE) && (item.label === lastYearDate.format('M.YYYY')) && (item.value.order.last)) {
                        _failureOrders = item.value.order.last;
                    }

                    if ((item.type === OrderStatusTypes.SUCCESS) && (item.label === lastYearDate.format('M.YYYY')) && (item.value.amount.last)) {
                        _successOrdersAmount = item.value.amount.last;
                    }

                    if ((item.type === OrderStatusTypes.FAILURE) && (item.label === lastYearDate.format('M.YYYY')) && (item.value.amount.last)) {
                        _failureOrdersAmount = item.value.amount.last;
                    }

                    return item;
                });

                _successOrdersCount.push(_successOrders);
                _failureOrdersCount.push(_failureOrders);
                _successOrdersCountAmount.push(_successOrdersAmount);
                _failureOrdersCountAmount.push(_failureOrdersAmount);

                lastYearDate = lastYearDate.add(1, 'M');
            }

            let _series = [
                {
                    name: t('menu:orders'),
                    type: "area",
                    data: _successOrdersCount
                },
                {
                    name: t('stat:ordersAmount'),
                    type: "bar",
                    data: _successOrdersCountAmount
                },
                {
                    name: t('stat:failsAmount'),
                    type: "line",
                    data: _failureOrdersCountAmount
                },
            ];

            setSeries([..._series]);
            setCategories([..._categories]);
        }
    },[items, currency, t])

    const options:ApexOptions = {
        chart: {
            height: 370,
            type: "line",
            toolbar: {
                show: false,
            },
        },
        stroke: {
            curve: "straight",
            dashArray: [0, 0, 8],
            width: [2, 0, 2.2],
        },
        fill: {
            opacity: [0.1, 0.9, 1],
        },
        markers: {
            size: [0, 0, 0],
            strokeWidth: 2,
            hover: {
                size: 4,
            },
        },
        xaxis: {
            categories: categories,
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis:{
            labels:{
                formatter: (value) => {
                    if (typeof value !== "undefined") {
                        return FormatValue(value);
                    }
                    return value;
                },
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10,
            },
        },
        legend: {
            show: true,
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                barHeight: "70%",
            },
        },
        colors: ['#008FFB','#00E396','#f672a7'],
        tooltip: {
            shared: true,
            y: [
                {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return FormatValue(y);
                        }
                        return y;
                    },
                },
                {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return currency.currencyFormat.replace(/#/,FormatCurrencyValue(y));
                        }
                        return y;
                    },
                },
                {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return currency.currencyFormat.replace(/#/,FormatCurrencyValue(y));
                        }
                        return y;
                    },
                },
            ],
        },
    };

    return(
        <Card>
            <CardHeader className="border-0 align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{t('stat:totally')}</h4>
                <div className="d-flex gap-1">
                    <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => {}}>
                        1Y
                    </button>
                </div>
            </CardHeader>
            <CardBody className="p-0 pb-2">
                <div className="w-100">
                    <div dir="ltr" className="pe-3">
                        <ReactApexChart
                            dir="ltr"
                            options={options}
                            series={series}
                            type="line"
                            height="370"
                            className="apex-charts"
                        />
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default SaleMainChart;