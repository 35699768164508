// types
import {SaleIntegrationHandlerActionTypes, SaleIntegrationHandlerState, SaleIntegrationHandlerType} from "./types";
import {getHandlerIconByCode} from "../../../helpers/functions";


export const INIT_STATE_SALE_INTEGRATION_HANDLER: SaleIntegrationHandlerState = {
    items: [],
    isFetched: false,
    isLoading: false
};

const SaleIntegrationHandler = (state = INIT_STATE_SALE_INTEGRATION_HANDLER, action: any) => {
  switch (action.type) {
    case SaleIntegrationHandlerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleIntegrationHandlerActionTypes.GET_SALE_INTEGRATION_HANDLERS:

          (action.payload.data.items || []).map((item:SaleIntegrationHandlerType)=>{
              item.icon = getHandlerIconByCode(item.code)
              return item;
          });

          return {
            ...state,
            items: action.payload.data.items,
            isFetched: true,
            isLoading: false,
          };
        default:
          return { ...state };
      }

    case SaleIntegrationHandlerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleIntegrationHandlerActionTypes.GET_SALE_INTEGRATION_HANDLERS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case SaleIntegrationHandlerActionTypes.SELECT_SALE_INTEGRATION_HANDLER:
      return {
        ...state,
        selectedItem: action.payload.data,
      }
    default:
      return { ...state };
  }
};

export default SaleIntegrationHandler;
