import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getFavourites = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_FAVOURITES);
};

// const getDirectMessages = () => {
//   return api.get(url.GET_DIRECT_MESSAGES);
// };

const getChannels = (data:MainMetaType) => {
  const query = createPaginationQuery(data);
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CHANNELS+"?"+query);
};

// const addContacts = (contacts: Array<string | number>) => {
//   return api.create(url.ADD_CONTACTS, contacts);
// };
//
// const createChannel = (data: object) => {
//   return api.create(url.CREATE_CHANNEL, data);
// };

const getChatUserDetails = (uuid: string | number) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CHAT_USER_DETAILS + "/" + uuid, { params: { uuid } });
};

const getChatUserConversations = (uuid: string | number) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CHAT_USER_CONVERSATIONS + "/" + uuid);
};

const sendMessage = (data: any) => {

  if (data.attachmentFiles !== undefined) {
    return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.SEND_MESSAGE, data);
  } else {
    return api.create(m9sGlobals.CURRENT_CABINET+url.SEND_MESSAGE, data);
  }
};

const receiveMessage = (uuid: string | number) => {
  return api.update(m9sGlobals.CURRENT_CABINET+url.RECEIVE_MESSAGE + "/" + uuid, { params: { uuid } });
};

const readMessage = (uuid: string | number) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_MESSAGE + "/" + uuid);
};

// const receiveMessageFromUser = (id: string | number) => {
//   return api.get(url.RECEIVE_MESSAGE_FROM_USER + "/" + id, {
//     params: { id },
//   });
// };

const deleteMessage = (uuidMessage: number | string) => {
  return api.delete(m9sGlobals.CURRENT_CABINET+url.DELETE_MESSAGE + "/" + uuidMessage);
};

const forwardMessage = (data: object) => {
  return api.create(m9sGlobals.CURRENT_CABINET+url.FORWARD_MESSAGE, data);
};

// const deleteUserMessages = (userId: number | string) => {
//   return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
//     params: { userId },
//   });
// };

const getChannelDetails = (uuid: string | number) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CHANNEL_DETAILS + "/" + uuid);
};

const toggleFavouriteContact = (uuid: string | number) => {
  return api.update(m9sGlobals.CURRENT_CABINET+url.TOGGLE_FAVOURITE_CONTACT + "/" + uuid, {
    params: { uuid },
  });
};

/*
archive
*/
const getArchiveContact = (data:MainMetaType) => {
  const query = createPaginationQuery(data);
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_ARCHIVE_CONTACT+"?"+query);
};

const toggleArchiveContact = (uuid: string | number) => {
  return api.update(m9sGlobals.CURRENT_CABINET+url.TOGGLE_ARCHIVE_CONTACT + "/" + uuid, { params: { uuid } });
};

const readConversation = (uuid: string | number) => {
  return api.update(m9sGlobals.CURRENT_CABINET+url.READ_CONVERSATION + "/" + uuid, { params: { uuid } });
};

const deleteImage = (
  uuidMessage: number | string,
  uuidFile: number | string
) => {
  return api.delete(m9sGlobals.CURRENT_CABINET+url.DELETE_IMAGE + "/" + uuidMessage, {
    params: { uuidMessage, uuidFile },
  });
};

export {
  getFavourites,
//  getDirectMessages,
  getChannels,
//  addContacts,
//  createChannel,
  getChatUserDetails,
  getChatUserConversations,
  sendMessage,
  receiveMessage,
  readMessage,
//  receiveMessageFromUser,
  deleteMessage,
  forwardMessage,
 // deleteUserMessages,
  getChannelDetails,
  toggleFavouriteContact,
  getArchiveContact,
  toggleArchiveContact,
  readConversation,
  deleteImage,
};
