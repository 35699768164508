import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Label, ModalBody, ModalHeader, OffcanvasHeader, OffcanvasBody} from 'reactstrap';
import UpgradeFare from "./UpgradeFare";
import LeftbarTitle from "./LeftbarTitle";

import '../i18/config';
import {useTranslation} from "react-i18next";

interface ItemProps {
    onClose : () => void
}

const UpgradeFareBlock = ({onClose}:ItemProps) => {

    const { t } = useTranslation(['common']);

    return (
        <>
            <OffcanvasHeader className="bg-light" toggle={onClose}>
                {t('plan:planUpgrade')}
            </OffcanvasHeader>

            <OffcanvasBody className="m-3 p-3 overflow-hidden">
                <div className="mt-3">
                    <UpgradeFare/>
                </div>
            </OffcanvasBody>

        </>
    );
}

export default UpgradeFareBlock;