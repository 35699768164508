import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getContentTemplates = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_TEMPLATES+"?"+query);
};

const sendDataContentTemplates = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_TEMPLATES, data);
};

const removeContentTemplates = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_TEMPLATES, data);
};

export {
    getContentTemplates,
    sendDataContentTemplates,
    removeContentTemplates
};
