import React from "react";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {PaymentType, PaymentStatus} from "../../redux/Sale/Payment/types";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {useRedux} from "../../hooks";
import {convertToMomentLocale} from "../../helpers/functions";
import {editDataSaleOrderPayment, removeSaleOrderPaymentModalShow} from "../../redux/Sale/Payment/actions";

interface ItemProps {
    item: PaymentType;
    paymentUrl?: null | string;
    number: number;
    isCanOrderEdit: boolean;
}
const PaymentItem = ({ item, paymentUrl, number, isCanOrderEdit = false }: ItemProps) => {

    const { t, i18n } = useTranslation(['plan']);
    const { dispatch } = useRedux();
    moment.locale(convertToMomentLocale(i18n.language));

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const handleEdit = () => {
        dispatch(editDataSaleOrderPayment({data: Object(item), modalEditTitle: 'changePayment'}));
    }

    const handleRemove = () => {
        dispatch(removeSaleOrderPaymentModalShow({data: Object(item), modalEditTitle: 'deletePayment'}));
    }

    return (
        <>
            {item?.paymentSystem &&
                <div className="orderPaymentCont mb-4">

                    <div className="d-flex align-items-center mb-2">
                        <div className="flex-grow-1 me-2">
                            <p className="text-muted mb-0">#{number} {t('sale:paymentMethod')}:</p>
                            <h6 className="mb-0">{item.paymentSystem.name}</h6>
                        </div>
                        {isCanOrderEdit &&
                        <div className="flex-shrink-0">
                            <button
                                onClick={handleEdit}
                                type="button"
                                title={t('common:edit')}
                                className="btn btn-outline-primary btn-icon waves-effect waves-light me-2">
                                <i className="ri-pencil-fill"/>
                            </button>
                            <button
                                onClick={handleRemove}
                                type="button"
                                title={t('common:delete')}
                                className="btn btn-outline-danger btn-icon waves-effect waves-light">
                                <i className="ri-delete-bin-5-line"/>
                            </button>
                        </div>
                        }
                    </div>

            <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                    <p className="text-muted mb-0">{t('sale:amount')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">{item.currency.currencyFormat.replace(/#/,FormatValue(item.amount))}</h6>
                </div>
            </div>

            <div className="d-flex align-items-center mb-2">
                <div className="flex-shrink-0">
                    <p className="text-muted mb-0">{t('sale:paymentStatus')}:</p>
                </div>
                <div className="flex-grow-1 ms-2">
                    {// eslint-disable-next-line
                        (Object.keys(PaymentStatus) as Array<keyof typeof PaymentStatus>).map((key) => {
                            if (PaymentStatus[key].status===item.status){
                            return (
                                <span key={'tickerStatus_'+item.status} className={"badge rounded-pill fs-12 text-bg-"+PaymentStatus[key].class}> {t('support:ticketStatus'+PaymentStatus[key].label)} </span>
                            )}
                            }
                        )
                    }
                </div>
            </div>

            {item.status !== PaymentStatus.FINAL.status &&  paymentUrl &&
                <div className="d-flex align-items-center mt-3">
                    <div className="flex-shrink-0">
                        <a
                            href={paymentUrl}
                            target="_blank"
                            className="btn btn-success btn-sm"
                            rel = "noreferrer"
                        >
                            <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                            {t('sale:invoiceLink')}
                        </a>
                    </div>
                </div>
            }
                    {item?.idPayment && item.idPayment.length>0 &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t('payment:idPayment')}:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">{item.idPayment}</div>
                        </div>
                    }
                    {item?.paymentDate && item.paymentDate.length>0 &&
                        <div className="d-flex align-items-center mb-2">
                            <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t('payment:paymentDate')}:</p>
                            </div>
                            <div className="flex-grow-1 ms-2">{moment(item.paymentDate).format('ddd, DD MMM YYYY')}</div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default PaymentItem;