import {EntityActionsType} from "../../types";
import {ContentConnectorHandlerType} from "../ConnectorHandler/types";
import {ContentPeerChannelType} from "../PeerChannel/types";

export enum ContentPeerUserActionTypes {
  API_RESPONSE_SUCCESS = "@@contentPeerUser/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentPeerUser/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_USERS = "@@contentPeerUser/GET_CONTENT_PEER_USERS",
  GET_CONTENT_PEER_CHANNEL_MEMBERS = "@@contentPeerUser/GET_CONTENT_PEER_CHANNEL_MEMBERS",

  UNSUBSCRIBE_CONTENT_CHANNEL_MEMBER = "@@contentPeerUser/UNSUBSCRIBE_CONTENT_CHANNEL_MEMBER",

  UNSUBSCRIBE_MODAL_SHOW = "@@contentPeerUser/UNSUBSCRIBE_MODAL_SHOW",
  UNSUBSCRIBE_MODAL_HIDE = "@@contentPeerUser/UNSUBSCRIBE_MODAL_HIDE",
}

export interface ContentPeerUserState {
  items: Array<any>;
  rightModalShow : boolean;
  unsubscribeModalShow : boolean;
  removeModalShow: boolean;
  selectedItem?: ContentPeerUserType;
  selectedChannel?: ContentPeerChannelType;
  modalEditTitle: string;
  currentPeerChannelId?: number
}

export interface ContentPeerUserType{
  uuid?: string,
  enabled?: boolean,
  name?: string,
  avatar?: string,
  gender?: string,
  country?: string,
  phone?: string,
  username?: string,
  activeTo?: number,
  dateTime?: string,
  handler?: ContentConnectorHandlerType,
  channels?: Array<ContentPeerChannelType>
  actions?: EntityActionsType,
  idPeer?: string,
}

export interface ContentPeerUserPayload {
  data: Array<any>;
  modalEditTitle : string;
}