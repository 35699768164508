import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {TaskType} from "../../../redux/Task/Task/types";
import {handleTaskEdit} from "../../Task/Task/TaskActions";


export const handleCreateNewTaskByPeerActivity = (entity: PeerActivityType, dispatch: any) => {
    let newTask:TaskType = NewTaskItem;

    newTask = {
        ...newTask,
        text: entity?.text && entity.text
    }

    handleTaskEdit(newTask, dispatch);
}