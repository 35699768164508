import {SaleCustomerSearchPayload, SaleOrderCustomerActionTypes, SaleOrderCustomerPayload} from "./types";
import {MainMetaType} from "../../Main/common";
import {ContentConnectorActionTypes, ContentConnectorSearchChannelPayload} from "../../Content/Connector/types";

export const saleOrderCustomerApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleOrderCustomerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleOrderCustomerApiResponseError = (actionType: string, error: string) => ({
  type: SaleOrderCustomerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleOrderCustomers = (data: MainMetaType) => ({
  type: SaleOrderCustomerActionTypes.GET_SALE_ORDER_CUSTOMERS,
  payload: data,
});

export const showRightModalSaleOrderCustomer= (data: SaleOrderCustomerPayload) => ({
  type: SaleOrderCustomerActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleOrderCustomer= () => ({
  type: SaleOrderCustomerActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleOrderCustomer = (data: any) => ({
  type: SaleOrderCustomerActionTypes.UPDATE_SALE_ORDER_CUSTOMER,
  payload: data,
});

export const editDataSaleOrderCustomer = (data: SaleOrderCustomerPayload) => ({
  type: SaleOrderCustomerActionTypes.EDIT_SALE_ORDER_CUSTOMER,
  payload: data,
});

export const removeSaleOrderCustomerModalShow = (data: SaleOrderCustomerPayload) => ({
  type: SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER_MODAL_SHOW,
  payload: data,
});

export const removeSaleOrderCustomerModalHide = () => ({
  type: SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER_MODAL_HIDE,
});

export const onRemoveSaleOrderCustomer = (data: SaleOrderCustomerPayload) => ({
  type: SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER,
  payload: data,
});

export const clearSaleOrderCustomerNeedReload= () => ({
  type: SaleOrderCustomerActionTypes.CLEAR_NEED_ORDER_RELOAD,
});

export const onSearchSaleOrderCustomer = (data: SaleCustomerSearchPayload) => ({
  type: SaleOrderCustomerActionTypes.SEARCH_SALE_CUSTOMERS,
  payload: data,
});