import {ContentPeerUserActionTypes, ContentPeerUserPayload} from "./types";
import {ContentProjectsActionTypes} from "../Project/types";
import {ContentConnectorActionTypes, ContentConnectorPayload} from "../Connector/types";

export const contentPeerUserApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentPeerUserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentPeerUserApiResponseError = (actionType: string, error: string) => ({
  type: ContentPeerUserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentPeerUsers = () => ({
  type: ContentPeerUserActionTypes.GET_CONTENT_PEER_USERS,
});

export const getContentPeerChannelMembers = (data: any) => ({
  type: ContentPeerUserActionTypes.GET_CONTENT_PEER_CHANNEL_MEMBERS,
  payload: data,
});

export const showModalContentUnsubscribeMember= (data: ContentPeerUserPayload) => ({
  type: ContentPeerUserActionTypes.UNSUBSCRIBE_MODAL_SHOW,
  payload: data,
});

export const hideModalContentUnsubscribeMember= () => ({
  type: ContentPeerUserActionTypes.UNSUBSCRIBE_MODAL_HIDE,
});

export const onSendUnsubscribeChannelMember = (data: any) => ({
  type: ContentPeerUserActionTypes.UNSUBSCRIBE_CONTENT_CHANNEL_MEMBER,
  payload: data,
});