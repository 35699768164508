// types
import {UpgradeModalActionTypes, UpgradeModalState} from "./types";

export const INIT_STATE_MAIN_ROLE: UpgradeModalState = {
  modalShow: false
};

const UpgradeModal = (state = INIT_STATE_MAIN_ROLE, action: any) => {
  switch (action.type) {
    case UpgradeModalActionTypes.MODAL_SHOW:
      return {
        ...state,
        modalShow: true
      }
    case UpgradeModalActionTypes.MODAL_HIDE:
      return {
        ...state,
        modalShow: false
      }
    default:
      return { ...state };
  }
};

export default UpgradeModal;
