import React from "react";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import { default as StatItemElement}  from "../../../components/StatItem";
import {MainIcon} from "../../../redux/Main/icon";

interface ItemProps {
    item: PeerActivityType;
}

const StatItem = ({ item }: ItemProps) => {

    return (
        <ul className="d-flex gap-1 ps-0">
            {item.stat.views > 0 &&
            <StatItemElement value={item.stat.views} icon={"align-bottom " + MainIcon.VIEWS}/>
            }
            {item.stat.likes > 0 &&
            <StatItemElement value={item.stat.likes} icon={"align-bottom " + MainIcon.LIKES}/>
            }
            {item.stat.comments > 0 &&
            <StatItemElement value={item.stat.comments} icon={"align-bottom " + MainIcon.COMMENTS}/>
            }
            {item.stat.reposts > 0 &&
            <StatItemElement value={item.stat.reposts} icon={"align-bottom " + MainIcon.REPOSTS}/>
            }
            {item.stat.reach > 0 &&
            <StatItemElement value={item.stat.reach} icon={"align-bottom " + MainIcon.REACH}/>
            }
            {item.stat.saved > 0 &&
            <StatItemElement value={item.stat.saved} icon={"align-bottom " + MainIcon.SAVED}/>
            }
            {item.stat.exits > 0 &&
            <StatItemElement value={item.stat.exits} icon={"align-bottom " + MainIcon.EXITS}/>
            }
            {item.stat.tapsForward > 0 &&
            <StatItemElement value={item.stat.tapsForward} icon={"align-bottom " + MainIcon.TAPS_FORWARD}/>
            }
            {item.stat.tapsBack > 0 &&
            <StatItemElement value={item.stat.tapsBack} icon={"align-bottom " + MainIcon.TAPS_BACK}/>
            }
            {item.stat.replies > 0 &&
            <StatItemElement value={item.stat.replies} icon={"align-bottom " + MainIcon.REPLIES}/>
            }
            {item.stat.answers > 0 &&
            <StatItemElement value={item.stat.answers} icon={"align-bottom " + MainIcon.ANSWERS}/>
            }
            {item.stat.members > 0 &&
            <StatItemElement value={item.stat.members} icon={"align-bottom " + MainIcon.MEMBERS}/>
            }
            {item.stat.bans > 0 &&
            <StatItemElement value={item.stat.bans} icon={"align-bottom " + MainIcon.BANS}/>
            }
            {item.stat.openLink > 0 &&
            <StatItemElement value={item.stat.openLink} icon={"align-bottom " + MainIcon.OPEN_LINK}/>
            }
            {item.stat.isPromo > 0 &&
            <StatItemElement value={item.stat.isPromo} icon={"align-bottom " + MainIcon.IS_PROMO}/>
            }
            {item.stat.erActivity > 0 &&
            <StatItemElement value={item.stat.erActivity} label="ER"/>
            }
            {item.stat.erView > 0 &&
            <StatItemElement value={item.stat.erView} label="ERV"/>
            }
            {item.stat.loveRate > 0 &&
            <StatItemElement value={item.stat.loveRate} label="LR"/>
            }
            {item.stat.talkRate > 0 &&
            <StatItemElement value={item.stat.talkRate} label="TR"/>
            }
        </ul>
    );
};

export default StatItem;