import React from 'react';
import * as Yup from 'yup';
import axios from "axios";
import * as url from "../../../api/urls";
import {validateEmail} from "../../../helpers/functions";
import {EMAIL_REGEX, PASSWORD_REGEX} from "../../../data/settings";

export const SignUpFormValidatorSchema = Yup.object().shape({
    email: Yup.string()
        .email("invalidEmail")
        .min(2, 'tooShort')
        .matches(EMAIL_REGEX, "invalidEmail")
        .required("invalidEmail")
        .test('Unique Email','emailAlreadyInUse',
            function(value){
                if (validateEmail(value)){
                    return new Promise((resolve, reject) => {
                        axios.post(url.MAIN_AUTH_SIGNUP_CHECK_EMAIL, {'email': value})
                        .then(res => {
                            if (res.data !== undefined && res.data.success !== undefined){
                                if(res.data.success === false){resolve(false)}
                            }
                            resolve(true)}
                        )
                     })
                }
        })
        ,
    password: Yup.string()
        .min(6, 'tooShort')
        .max(50, 'tooLong')
        .matches(
            PASSWORD_REGEX,
            "passwordPolicy"
        )
        .required('required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'passwordsMustMatch')
        .required('required')
        .matches(
            PASSWORD_REGEX,
            "passwordPolicy"
        )
});