import React, {useState, useEffect} from 'react';
import {Button, Col, Input, Label} from "reactstrap";
import { Link } from 'react-router-dom'
import CoverEmpty from "./CoverEmpty";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import * as route from "../../../api/routes";

const img404 = require('../../../resources/images/404.png');
const imgDog = require('../../../resources/images/dog.png');

interface InputProps {
    errorCode?: number,
}

const CoverError = ({errorCode}:InputProps) => {

    const { t } = useTranslation(['auth']);

    const [errorCodeNumber, setErrorCodeNumber] = useState<number>(null);
    const [errorDescription, setErrorDescription] = useState<string>(null);

    useEffect(()=>{
        if (errorCode !== undefined){
            setErrorCodeNumber(errorCode);
            switch (errorCode){
                case 404:
                    setErrorDescription('pageNotFound');
                    break;
                case 400:
                    setErrorDescription('serviceUnavailable');
                    break;
            }

        } else {
            setErrorCodeNumber(404);
            setErrorDescription('pageNotFound');
        }

    },[errorCode]);

    return (
        <>
            <CoverEmpty title={'Error '+(errorCodeNumber && errorCodeNumber.toString())+' | Marketcells.io - digital marketing platform'}>
                <Col lg={6}>
                    <div className="p-lg-5 p-4 h-100">
                    {errorCodeNumber && errorCodeNumber===404 ?
                        <div className="p-4">
                            <img className="w-100" src={img404} alt={(errorCodeNumber && errorCodeNumber.toString())}/>
                            {/*<div className="bg-overlay-blue"/>*/}
                        </div>
                    :
                            <div className="p-4">
                                <img className="w-100" src={imgDog} alt={(errorCodeNumber && errorCodeNumber.toString())}/>
                                {/*<div className="bg-overlay-blue"/>*/}
                            </div>
                    }
                    </div>
                </Col>
                <Col lg={6}>
                    <CoverLangSwitch/>
                    <div className="p-lg-5 p-4 text-center">
                        <div className="avatar-lg mx-auto mt-2">
                            <div className="avatar-title bg-light text-danger display-3 rounded-circle">
                                <i className="ri-error-warning-fill"/>
                            </div>
                        </div>
                        <div className="mt-4 pt-2">
                            <h1 className="text-primary mb-4">{t('validator:errorOops')} {errorCodeNumber && errorCodeNumber}!</h1>
                            {/*<h4>!#Error!</h4>*/}
                            <p className="text-muted mx-4">{errorDescription && t('validator:'+errorDescription)}</p>
                            <div className="mt-4">
                                <Link to={route.MAIN_AUTH_LOGIN} className="btn btn-success w-100">{t('auth:backToLogin')}</Link>
                            </div>
                        </div>
                    </div>
                </Col>
            </CoverEmpty>
        </>
    );
};

export default CoverError;