import {SaleIntegrationHandlerActionTypes, SaleIntegrationHandlerPayload} from "./types";

export const saleIntegrationHandlerApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleIntegrationHandlerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleIntegrationHandlerApiResponseError = (actionType: string, error: string) => ({
  type: SaleIntegrationHandlerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleIntegrationHandlers = () => ({
  type: SaleIntegrationHandlerActionTypes.GET_SALE_INTEGRATION_HANDLERS,
});

export const selectSaleIntegrationHandler = (actionType: string, data: SaleIntegrationHandlerPayload) => ({
  type: SaleIntegrationHandlerActionTypes.SELECT_SALE_INTEGRATION_HANDLER,
  payload: { actionType, data },
});