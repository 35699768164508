import {MainMetaType} from "../../Main/common";
import {OrderType} from "../Order/types";
import {CustomerType} from "../Customer/types";
import {ContentConnectorType} from "../../Content/Connector/types";

export enum SaleOrderCustomerActionTypes {
    API_RESPONSE_SUCCESS = "@@saleOrderCustomer/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleOrderCustomer/API_RESPONSE_ERROR",
    GET_SALE_ORDER_CUSTOMERS = "@@saleOrderCustomer/GET_SALE_ORDER_CUSTOMERS",
    UPDATE_SALE_ORDER_CUSTOMER = "@@saleOrderCustomer/UPDATE_SALE_ORDER_CUSTOMER",
    REMOVE_SALE_ORDER_CUSTOMER_MODAL_SHOW = "@@saleOrderCustomer/REMOVE_SALE_ORDER_CUSTOMER_MODAL_SHOW",
    REMOVE_SALE_ORDER_CUSTOMER_MODAL_HIDE = "@@saleOrderCustomer/REMOVE_SALE_ORDER_CUSTOMER_MODAL_HIDE",
    REMOVE_SALE_ORDER_CUSTOMER = "@@saleOrderCustomer/REMOVE_SALE_ORDER_CUSTOMER",
    EDIT_SALE_ORDER_CUSTOMER = "@@saleOrderCustomer/EDIT_SALE_ORDER_CUSTOMER",
    RIGHT_MODAL_SHOW = "@@saleOrderCustomer/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleOrderCustomer/RIGHT_MODAL_HIDE",
    CLEAR_NEED_ORDER_RELOAD = "@@saleOrderCustomer/CLEAR_NEED_ORDER_RELOAD",
    SEARCH_SALE_CUSTOMERS = "@@saleOrderCustomer/SEARCH_SALE_CUSTOMERS",
}

export interface SaleOrderCustomerState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: OrderCustomerType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
    isNeedOrderReload: boolean;
    searchCustomers: CustomerType[];
    isSearchCustomers: boolean;
}
export interface OrderCustomerType {
    customer?: CustomerType
    name?: string;
    phone?: string;
    email?: string;
    tin?: string;
    saleOrder?: OrderType;
}


export interface SaleOrderCustomerPayload {
    data: Array<any>;
    modalEditTitle : string;
}

export interface SaleCustomerSearchPayload {
    inputValue: string
}