import React, {useEffect, useState, useCallback} from "react";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getHandlerIconByCode} from "../../../helpers/functions";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {logoIdeaChannel} from "../../../data/images";

interface ItemProps {
    item: ContentPeerChannelType;
}
const ChannelAvatar = ({ item }: ItemProps) => {
    const { t } = useTranslation(['common']);

    const [channelName, setChannelName] = useState<string>(item.name)

    const shouldDisplayHandlerIcon = useCallback(() =>
        Boolean(item?.connector?.handler?.code?.length), [item]);

    const shouldDisplayIdeaLogo = useCallback(() =>
        item?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS, [item]);

    useEffect(()=>{
        const isIdeaChannel = item?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS;
        setChannelName(isIdeaChannel ? t('channel:channelIdeas') : item.name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item.name])

    return(
        <>
                    <div className="avatar-sm position-relative">
                        {shouldDisplayHandlerIcon() &&
                            <div className="avatar-xxs position-absolute handlerIconTopLeft">
                                <img src={getHandlerIconByCode(item.connector.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                            </div>
                        }
                        <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                            { item.avatar ?
                                <img src={item.avatar} className="img-fluid p-1 rounded-circle" alt=""/>
                                :
                                shouldDisplayIdeaLogo() ?
                                    <img src={logoIdeaChannel} className="img-fluid p-0 rounded-circle" alt=""/>
                                    :
                                    channelName[0]
                            }
                        </div>
                    </div>
        </>
    )
}

export default ChannelAvatar;