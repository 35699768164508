import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";

import { useProfile } from "../hooks/UserHooks";
import {logoutUser} from "../redux/actions";
import {useRedux} from "../hooks";
import * as route from "../api/routes";


const AuthProtected = (props) => {

  const { userProfile, loading } = useProfile();
  const { dispatch, useAppSelector } = useRedux();

  const {
    loginState,
  } = useAppSelector(state => ({
    loginState: state.Login,
  }));

  useEffect(() => {
    if (loginState.isUserLogout === true) {
        dispatch(logoutUser());
    }
  }, [dispatch, loginState?.isUserLogout]);

  if (!userProfile && loading) {
    return (
      <Navigate to={{ pathname: route.MAIN_AUTH_LOGIN}} state={{ from: props.location }}/>
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      element={<Component/>}
    />
  );
};

export { AuthProtected, AccessRoute };