import React from "react";
import {ContentPeerUserType} from "../../../redux/Content/PeerUser/types";
import {getHandlerIconByCode} from "../../../helpers/functions";

interface ItemProps {
    item: ContentPeerUserType;
}
const UserItemLine = ({ item }: ItemProps) => {
    return(
        <>
            <div className="d-flex flex-shrink-0 position-relative userItemLine">
                <div className="avatar-xxs me-2 d-flex flex-shrink-0 position-relative">
                    {item.avatar ?
                        <img src={item.avatar} className="rounded-circle img-fluid" alt=""/>
                    :
                        <span className={"avatar-title rounded-circle"}>{item.name[0]}</span>
                    }
                </div>
                { item.handler && item.handler.code &&
                    <div className="position-absolute handlerIconTopLeft">
                        <img src={getHandlerIconByCode(item.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                    </div>
                }
                <div className="flex-1 justify-content-center flex-column d-flex">
                    {item.name}
                </div>
            </div>
        </>
    )
}

export default UserItemLine;