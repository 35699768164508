import {StatPeerActivityTileType, StatPeerActivityContentActionTypes, StatPeerActivityPayloadType} from "./types";
import {PeriodType} from "../DashboardContent/types";

export const statPeerActivityApiResponseSuccess = (actionType: string, data: any) => ({
  type: StatPeerActivityContentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const statPeerActivityApiResponseError = (actionType: string, error: string) => ({
  type: StatPeerActivityContentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getStatPeerActivityTiles= (data: StatPeerActivityPayloadType) => ({
  type: StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TILES,
  payload: data,
});

export const getStatPeerActivityActivitiesData= (data: StatPeerActivityPayloadType) => ({
  type: StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ACTIVITIES_DATA,
  payload: data,
});

export const getStatPeerActivityEngagementsData= (data: StatPeerActivityPayloadType) => ({
  type: StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ENGAGEMENTS_DATA,
  payload: data,
});

export const getStatPeerActivityTargets= (data: StatPeerActivityPayloadType) => ({
  type: StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TARGETS,
  payload: data,
});

export const setStatPeerActivityPeriod= (data: PeriodType) => ({
  type: StatPeerActivityContentActionTypes.SET_PERIOD,
  payload: data,
});

export const getStatPeerActivity = (data:any) => ({
  type: StatPeerActivityContentActionTypes.GET_ACTIVITY,
  payload: data,
});