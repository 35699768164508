import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {wsMessageEventTypes} from "../../../api/WebSocket/webSocketTypes";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";
import {useRedux} from "../../../hooks";
import {TaskType} from "../../../redux/Task/Task/types";
import UserAvatar from "../../Main/User/userAvatar";
import {convertToMomentLocale,textTruncate} from "../../../helpers/functions";

interface ItemProps {
    item: TaskType;
    action?: string;
    cabinet?: MainCabinetType
}

function getLink(item: TaskType, externalCabinet: MainCabinetType): string {
    if (externalCabinet && externalCabinet.uuid){
        return null;
    }
    if (item?.uuid !== undefined && item.uuid !== null) {
        return route.TASK_DETAIL.replace(/:uuid/g, item.uuid);
    }

    return null;
}

const TaskItem = ({ item, cabinet, action }: ItemProps) => {

    const { t,i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));

    const { useAppSelector } = useRedux();
    const cabinetState = useAppSelector(state => state.MainCabinet);

    const [link, setLink] = useState<string>(null);

    const hasUuid = (cabinet?: MainCabinetType): boolean => cabinet?.uuid?.length > 0;
    const externalCabinet = cabinetState.items.find(hasUuid);

    useEffect(()=>{
        setLink(getLink(item, externalCabinet));
    },[item, externalCabinet])
    
    return (
        <>
            { externalCabinet && externalCabinet?.uuid &&
                <div className="position-absolute top-0 end-0 mt-1 me-1" style={{zIndex:1}}>
                    <span className="badge rounded-pill text-bg-info">{textTruncate(externalCabinet.name, 10)}</span>
                </div>
            }

            <Link to={link && link}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    {item?.owner &&
                        <UserAvatar item={item.owner}/>
                    }
                </div>
                <div className="flex-grow-1 ms-3">
                    <h5 className="fs-14 mb-2">
                        {item?.owner && item?.owner?.name && item.owner.name}
                        {item?.id !== undefined && item.id !== null &&
                            <>
                                {action === wsMessageEventTypes.TASK_ADDED &&
                                    " "+t('task:newTaskCreated').replace(/:id/g, item.id.toString())
                                }
                                {action === wsMessageEventTypes.TASK_UPDATED &&
                                    " "+t('task:taskUpdated').replace(/:id/g, item.id.toString())
                                }
                                {action === wsMessageEventTypes.TASK_CLOSED &&
                                    " "+t('task:taskClosed').replace(/:id/g, item.id.toString())
                                }
                                {action === wsMessageEventTypes.TASK_OVERDUE &&
                                    " "+t('task:taskOverdue').replace(/:id/g, item.id.toString())
                                }
                                {action === wsMessageEventTypes.TASK_RETURNED &&
                                    " "+t('task:taskReturned').replace(/:id/g, item.id.toString())
                                }
                            </>
                        }
                    </h5>
                    <p className="text-muted text-truncate p-0 m-0">
                        <span className="text-body">{textTruncate(item.text, 23)}</span>
                    </p>

                </div>
            </div>
            </Link>
        </>
    );
};

export default TaskItem;
