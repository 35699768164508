// types
import {SaleCategoryActionTypes, SaleCategoryState, SaleCategoryType} from "./types";

export const NewSaleCategoryItem:SaleCategoryType = {
  enabled: true,
  name: '',
  order: 500
}

export const INIT_STATE_SALE_CATEGORY: SaleCategoryState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleCategoryItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const SaleCategory = (state = INIT_STATE_SALE_CATEGORY, action: any) => {
  switch (action.type) {
    case SaleCategoryActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleCategoryActionTypes.GET_SALE_CATEGORIES:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleCategoryActionTypes.UPDATE_SALE_CATEGORY:
          return {
            ...state,
            selectedItem: NewSaleCategoryItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SaleCategoryActionTypes.REMOVE_SALE_CATEGORY:
          return {
            ...state,
            selectedItem: NewSaleCategoryItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case SaleCategoryActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleCategoryActionTypes.GET_SALE_CATEGORIES:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SaleCategoryActionTypes.UPDATE_SALE_CATEGORY:
          return {
            ...state,
            isSending: false
          };
        case SaleCategoryActionTypes.REMOVE_SALE_CATEGORY:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SaleCategoryActionTypes.EDIT_SALE_CATEGORY:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleCategoryActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleCategoryItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleCategoryActionTypes.REMOVE_SALE_CATEGORY_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleCategoryActionTypes.REMOVE_SALE_CATEGORY_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleCategoryItem,
        removeModalShow: false
      }
    case SaleCategoryActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleCategoryActionTypes.UPDATE_SALE_CATEGORY:
      return {
        ...state,
        isSending: true
      };
    case SaleCategoryActionTypes.GET_SALE_CATEGORIES:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default SaleCategory;
