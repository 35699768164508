import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentPeerChannels = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_CHANNELS);
};

const getContentPeerChannelByIdPeer = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_CHANNELS, {idPeer:data.idPeer, handlerCode:data.handlerCode});
};

const sendDataContentPeerChannel = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_PEER_CHANNEL, data);
};

const removeContentPeerChannel = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_PEER_CHANNEL, data);
};
const connectContentPeerChannel = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.CONNECT_CONTENT_PEER_CHANNEL, data);
};
export {
    getContentPeerChannels,
    sendDataContentPeerChannel,
    removeContentPeerChannel,
    connectContentPeerChannel,
    getContentPeerChannelByIdPeer
};
