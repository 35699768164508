import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import InputColor from "../../../components/InputColor";
import {
    getPeerActivityTypes,
    hideRightModalPeerActivityType,
    onSendDataPeerActivityType
} from "../../../redux/Content/PeerActivityType/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {PeerActivityTypeType} from "../../../redux/Content/PeerActivityType/types";
import {PeerActivityTypeValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        contentPeerActivityType,
        isSending
    } = useAppSelector(state => ({
        contentPeerActivityType: state.PeerActivityType,
        isSending: state.PeerActivityType.isSending
    }));

    const uuid = contentPeerActivityType.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<PeerActivityTypeType>(contentPeerActivityType.selectedItem);

    useEffect(() => {

        setFormValues(contentPeerActivityType.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalPeerActivityType());

    const onDataSubmit = (formData:PeerActivityTypeType) => {
        let data: any = {};

        if (formData.name) {
            data["name"] = formData.name;
        }
        // if (formData.code) {
        //     data["code"] = formData.code;
        // }
        if (formData.color) {
            data["color"] = formData.color;
        }
        if (formData.text) {
            data["text"] = formData.text;
        }

        if (formData.order) {
            data["order"] = formData.order;
        }

        data["enabled"] = formData.enabled;
        data["isDefault"] = formData.isDefault;

        if (uuid) {
            data["uuid"] = uuid;
        }

        let params: PeerActivityTypeType = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            name: data.name && data.name,
    //        code: data.code && data.code,
            color: data.color && data.color,
            text: data.text && data.text,
            isDefault: data.isDefault ? data.isDefault : false,
            order: data.order && data.order,
        };

        dispatch(onSendDataPeerActivityType(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={contentPeerActivityType.rightModalShow}
                    id="peerActivityTypeEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={PeerActivityTypeValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('peerActivityType:'+contentPeerActivityType.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                    <div className="mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-isDefault-input"
                                            placeholder={t('common:default')}
                                            name="isDefault"
                                            component={InputSwitch}
                                            value = {getFieldProps('isDefault').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('isDefault', !getFieldProps('isDefault').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column align-items-center">
                                        <div className="colorInputMt2px">
                                            <Field
                                                id="form-color-input"
                                                placeholder={t('common:color')}
                                                name="color"
                                                component={InputColor}
                                                value = {getFieldProps('color').value}
                                                onChange={(color:string) => {
                                                    setFieldValue('color', color);
                                                }}
                                                required={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:name')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="name"
                                        placeholder={t('peerActivityType:enterActivityTypeName')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            {/*<Row>*/}
                            {/*    <div className={ "mb-3"+ (touched.code ? " was-validated" : '')}>*/}
                            {/*        <Label className="form-label" htmlFor="form-title-input">{t('common:symbolicCode')}</Label>*/}
                            {/*        <Field*/}
                            {/*            id="form-title-input"*/}
                            {/*            name="code"*/}
                            {/*            placeholder={t('common:enterSymbolicCode')}*/}
                            {/*            component={InputText}*/}
                            {/*            value = {getFieldProps('code').value}*/}
                            {/*            onChange={(code:string) => {*/}
                            {/*                setFieldValue('code', code);*/}
                            {/*            }}*/}
                            {/*            className={errors.code && touched.code && 'form-control is-invalid'}*/}
                            {/*        />*/}
                            {/*        {errors.code && touched.code ? (*/}
                            {/*            <div className="invalid-feedback">*/}
                            {/*                {t('validator:'+errors.code)}*/}
                            {/*            </div>*/}
                            {/*        ) :  touched.code && !errors.code ?*/}
                            {/*            <div className="valid-feedback">*/}
                            {/*                {t('common:looksGood')}*/}
                            {/*            </div>*/}
                            {/*            : ''*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*</Row>*/}
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="form-description-input">{t('common:description')}</Label>
                                    <div className="textAreaEmojiBlock">
                                        <Field
                                            id="form-text-input"
                                            name="text"
                                            component={InputEmojiArea}
                                            value = {getFieldProps('text').value}
                                            onChange={(text:string) => {
                                                setFieldValue('text', text);
                                            }}
                                            required={false}
                                            placeholder={t('common:enterDescription')}
                                        />
                                    </div>
                                </div>
                            </Row>
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;