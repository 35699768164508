import { APIClient } from "../apiCore";
import * as url from "../urls";
import {UPDATE_CONTENT_PEER_USERS} from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentPeerUsers = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_USERS);
};

const getContentPeerChannelMembers = (data:any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_USERS, data);
};

const unsubscribeContentPeerChannelMember = (data:any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_PEER_USERS, data);
};

const getContentPeerUsersTop = (data:any) => {

    const sendData = {
        periodFrom: data.periodFrom && data.periodFrom,
        periodTo: data.periodTo && data.periodTo,
        project: data.project && data.project
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_TOP_PEER_USERS,sendData);
};

export {
    getContentPeerUsers,
    getContentPeerChannelMembers,
    unsubscribeContentPeerChannelMember,
    getContentPeerUsersTop,
};
