import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    hideRightModalSalePriceType,
    onSendDataSalePriceType
} from "../../../redux/Sale/PriceType/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import {SalePriceTypeValidatorSchema} from "./validator";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {isNumeric} from "../../../helpers/functions";
import {ContentProjectType} from "../../../redux/Content/Project/types";
import UpgradeFareBlock from "../../../components/UpgradeFareBlock";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

    const {
        salePriceTypeState,
        isSending,
        salePlan
    } = useAppSelector(state => ({
        salePriceTypeState: state.SalePriceType,
        isSending: state.SalePriceType.isSending,
        salePlan: state.SalePlan
    }));

    const uuid = salePriceTypeState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<PriceTypeType>(salePriceTypeState.selectedItem);

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    useEffect(() => {

        setFormValues(salePriceTypeState.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalSalePriceType());

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            setCurrentPlan({...salePlan.currentPlan});
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        checkFareRestriction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_PRICE_TYPES
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(true);
        } else if (salePriceTypeState) {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value) === (salePriceTypeState.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    //edit only
                    if (uuid === undefined || uuid === null) {
                        setUpgradeFare(true);
                    } else {
                        setUpgradeFare(false);
                    }
                } else if (parseInt(restrictionValue.value) < (salePriceTypeState.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    setUpgradeFare(true);
                } else {
                    setUpgradeFare(false);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    const onDataSubmit = (formData:PriceTypeType) => {
        let data: any = {};

        if (formData.name) {
            data["name"] = formData.name;
        }
        if (formData.isBase) {
            data["isBase"] = formData.isBase;
        }
        if (formData.order) {
            data["order"] = formData.order;
        }

        if (formData.idRemote) {
            data["idRemote"] = formData.idRemote;
        }

        data["enabled"] = formData.enabled;

        if (uuid) {
            data["uuid"] = uuid;
        }

        let params: PriceTypeType = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            name: data.name && data.name,
            isBase: data.isBase ? data.isBase : false,
            order: data.order && data.order,
            idRemote: data.idRemote && data.idRemote
        };

        dispatch(onSendDataSalePriceType(params));

    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={salePriceTypeState.rightModalShow}
                    id="salePriceTypeEdit"
                    toggle={handleClose}
                >
                    { upgradeFare ?
                        <UpgradeFareBlock onClose={handleClose}/>
                        :
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SalePriceTypeValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('price:'+salePriceTypeState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-isBase-input"
                                            placeholder={t('common:base')}
                                            name="isBase"
                                            component={InputSwitch}
                                            value = {getFieldProps('isBase').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('isBase', !getFieldProps('isBase').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                    <Field
                                        id="form-name-input"
                                        name="name"
                                        placeholder={t('common:enterNameTitle')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.order ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:order')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="order"
                                        placeholder={t('common:enterOrder')}
                                        component={InputText}
                                        value = {getFieldProps('order').value}
                                        onChange={(order:string) => {
                                            setFieldValue('order', order);
                                        }}
                                        className={errors.order && touched.order && 'form-control is-invalid'}
                                    />
                                    {errors.order && touched.order ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.order)}
                                        </div>
                                    ) :  touched.order && !errors.order ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-0"}>
                                            <Label className="form-label" htmlFor="form-additionalSettings-input">{t('common:additionalSettings')} <a href="#" onClick={toggleShowAdditionalSettings}>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('ai:moreInfo')}
                                                        </Tooltip>
                                                    }
                                                ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                            </a></Label>
                                        </div>
                                    </Row>
                                    {isShowAdditionalSettings &&
                                    <Row>
                                        <div className={ "mb-3"}>
                                            <Label className="form-label" htmlFor="form-idRemote-input">{t('common:idRemote')}</Label>
                                            <Field
                                                id="form-idRemote-input"
                                                name="idRemote"
                                                placeholder={t('common:enterIdRemote')}
                                                component={InputText}
                                                value = {getFieldProps('idRemote').value}
                                                onChange={(order:string) => {
                                                    setFieldValue('idRemote', order);
                                                }}
                                            />
                                        </div>
                                    </Row>
                                    }
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>
                    }
                </Offcanvas>
        </>
    );
}

export default RightModal;