import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentPeerTargetTypes = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_TARGET_TYPES);
};

export {
    getContentPeerTargetTypes,
};
