import React, {useEffect, useState } from 'react';

import { Row } from 'reactstrap';
import {useRedux} from "../../../hooks";
import {MainVersionHistoryType} from "../../../redux/Main/VersionHistory/types";
import {getMainVersionHistories} from "../../../redux/Main/VersionHistory/actions";
import ListItem from "./ListItem";
import BreadCrumb from "../../../components/Common/BreadCrumb";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

const MainVersionHistory = () => {

    const { dispatch, useAppSelector } = useRedux();
    const [items, setItems] = useState<MainVersionHistoryType[]>([]);

    const { t } = useTranslation(['common']);

    const {
        mainVersionHistory,
    } = useAppSelector(state => ({
        mainVersionHistory: state.MainVersionHistory,
    }));

    useEffect(() => {

        dispatch(getMainVersionHistories());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {

        if ((mainVersionHistory.items !== undefined) && (mainVersionHistory.items.length>0)){
            setItems([...mainVersionHistory.items]);
        } else {
            setItems([]);
        }

    },[mainVersionHistory?.items]);


    return (
        <>
            <BreadCrumb title={t('menu:versionUpdates')} pageTitle={t('menu:main')} />
        <Row>
            <div className="timeline">
                {(items || []).map((item:MainVersionHistoryType, key:number)=>(
                    <ListItem item={item} key={"listVersionHistory"+key} itemKey={key}/>
                ))}
            </div>
        </Row>
        </>
    );
}

export default MainVersionHistory;