
export enum MainCurrentCountryActionTypes {
  API_RESPONSE_SUCCESS = "@@mainCurrentCountry/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainCurrentCountry/API_RESPONSE_ERROR",
  GET_CURRENT_COUNTRY = "@@mainCurrentCountry/GET_CURRENT_COUNTRY",
}

export interface MainCurrentCountryState {
  currentCountry?: MainCountryType;
  isLoaded: boolean;
}

export interface MainCountryType{
  code?: string;
  country?: string;
}
