import React, {useEffect, useState} from "react";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {getHandlerIconByCode} from "../../../helpers/functions";
import InputSwitch from "../../../components/InputSwitch";
import Loader from "../../../components/Loader";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
interface ItemProps {
    item: ContentPeerChannelType;
    onChange: (event: ContentPeerChannelType) => void
}
const ChannelItemLineChecker = ({ item, onChange }: ItemProps) => {

    const [isLoading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation(['common']);

    const [channelName, setChannelName] = useState<string>(item.name)

    useEffect(()=>{
        if (item?.connector?.handler?.code && item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS){
            setChannelName(t('channel:channelIdeas'));
        } else {
            setChannelName(item.name);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item.name])

    useEffect(()=>{

        if (item?.isUpdating && item.isUpdating === true){
            setLoading(true);
        } else {
            setLoading(false);
        }

    },[item?.isUpdating])

    return(
        <>
            <div className={"d-flex flex-row mw-0"}>
                <div className="d-flex flex-row mw-0 flex-grow-1">
                    <div className="avatar-xs me-2 d-flex flex-shrink-0 position-relative">
                        {item.avatar ?
                            <img src={item.avatar} className="rounded-circle img-fluid" alt=""/>
                        :
                            <span className={"avatar-title rounded-circle"}>{item.name[0]}</span>
                        }
                        { item.connector && item.connector.handler && item.connector.handler.code &&
                        <div className="avatar-xs12 position-absolute handlerIconTopLeft">
                            <img src={getHandlerIconByCode(item.connector.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                        </div>
                        }
                    </div>
                    <div className="flex-1 justify-content-center flex-column d-flex text-truncate flex-grow-1 text-truncate mw-0">
                        {channelName.substring(0, 23)}{channelName.length >= 23 && '...'}
                    </div>
                </div>
                <div className="justify-content-end d-flex text-truncate flex-shrink-0 align-items-center flex-grow-0">
                    {isLoading ?
                        <Loader addClassNames="sm d-inline-block"/>
                        :
                        <InputSwitch id={'id'}
                                     value={item.enabled}
                                     onChange={()=>onChange(item)}
                                     disabled={(item?.isReadOnly && item.isReadOnly === true)}
                                 className={"form-switch-sm d-flex flex-column justify-content-center align-items-end"}/>
                    }
                </div>
            </div>
        </>
    )
}

export default ChannelItemLineChecker;