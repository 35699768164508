import React, { useState, createContext, useContext, useReducer, useEffect } from 'react';
import {useSession} from "./webSocket";
import {useConnectionCallback, useMessageCallback , useDisconnectionCallback } from './webSocketEventHandlers'
import {initialWSState, webSocketReducer} from "./webSocketReducer";
import {WebSocketContextValue, wsMessageEventTypes, wsMessageLifecycle, wsMessageType, wsState} from "./webSocketTypes";
import {useRedux} from "../../hooks";
import * as route from "../routes";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {
    aiTextGenerateApiResponseError,
    aiTextGenerateApiResponseSuccess,
    contentConnectorApiResponseSuccess,
    contentPeerChannelApiResponseSuccess,
    contentPeerChannelNotFound,
    getChannels,
    getChatUserConversations,
    getCommentsChannels,
    getCommentsUserConversations,
    getContentConnectors,
    getContentPeerChannels,
    getEventUserBadges,
    getSupportUserBadges,
    onIsConnectedErrorContentConnector,
    pinPeerActivityFailed,
    pinPeerActivitySuccessful,
    publishPeerActivityFailed,
    publishPeerActivitySuccessful,
    receiveAITextGenerateResponse,
    responseSaleProductsIntegrationMetaBusinessManagersFromRemote,
    responseSaleProductsIntegrationMetaProductCatalogsFromRemote,
    responseSaleProductsIntegrationParamFromRemote, ShortenerApiResponseError, ShortenerApiResponseSuccess,
    unPinPeerActivityFailed,
    unPinPeerActivitySuccessful,
    unPublishPeerActivityFailed,
    unPublishPeerActivitySuccessful
} from "../../redux/actions";
import {newMessageAudio} from "../../audio/AudioScheme";
import {ContentConnectorActionTypes, ContentConnectorType} from "../../redux/Content/Connector/types";
import {useLocation} from "react-router-dom";
import {ContentPeerChannelActionTypes, ContentPeerChannelType} from "../../redux/Content/PeerChannel/types";
import {showErrorNotification, showSuccessNotification} from "../../helpers/notifications";
import {toast} from "react-toastify";
import {default as ToastTicketMessageItem} from "../../blocks/Support/TicketMessage/Toast";
import {default as ToastPeerChannelItem} from "../../blocks/Content/PeerChannel/Toast";
import {default as ToastConnectorItem} from "../../blocks/Content/Connector/Toast";
import {default as ToastMessengerMessageItem} from "../../blocks/Messenger/ConversationUser/Toast";
import {SupportTicketMessageType} from "../../redux/Support/TicketMessage/types";
import {SupportTicketType} from "../../redux/Support/Ticket/types";
import {MessengerMessageType} from "../../redux/Messenger/Chat/types";
import {getCurrentCabinet} from "../../helpers/functions";
import {AITextGenerateActionTypes, AITextGenerateType} from "../../redux/AI/TextGenerate/types";
import {OrderType} from "../../redux/Sale/Order/types";
import OrderItem from "../../blocks/Sale/Order/Toast";
import {EventWebhookType} from "../../redux/Event/Webhook/types";
import WebhookItem from "../../blocks/Event/Webhook/Toast";
import {ShortenerActionTypes, UrlShortenerType} from "../../redux/Shortener/UrlShortener/types";
import {TaskType} from "../../redux/Task/Task/types";
import TaskItem from "../../blocks/Task/Task/Toast";
import CommentItem from "../../blocks/Main/Comment/Toast";
import {CommentType} from "../../redux/Main/Comment/types";

type Reducer = (state: wsState, action: any) => wsState;

export const WebSocketContext = createContext<WebSocketContextValue>(
    {} as WebSocketContextValue
);

export function useWebSocketContext() {
    return useContext(WebSocketContext);
}

export default function WebSocketContextProvider({ children }: any) {

    const { t, i18n } = useTranslation();

    const [state, dispatchReducer] = useReducer(
        webSocketReducer as Reducer,
        initialWSState as wsState
    );

    const { dispatch, useAppSelector } = useRedux();

    const {
        stateMessengerChat,
        stateMessengerComment,
        isSupportService,
        cabinetState
    } = useAppSelector(state => ({
        stateMessengerChat: state.MessengerChat,
        stateMessengerComment: state.MessengerComment,
        isSupportService: state.Login.isSupportService,
        cabinetState: state.MainCabinet
    }));


    const onOpen = useConnectionCallback(dispatchReducer);
    const onMessage = useMessageCallback(dispatchReducer);
    const onClose = useDisconnectionCallback(dispatchReducer);



    let location = useLocation();

    // const { settingsData } = useAppSelector(state => ({
    //     settingsData: state.Settings.settings,
    // }));


    useEffect(()=>{

        let isCurrentCabinetMessage = false;

        if (state.messages !== undefined){

            (state.messages || []).map((item: wsMessageType)=>{
                if ((item.status === undefined) || (item.status !== wsMessageLifecycle.FINAL)){
                    item.status = wsMessageLifecycle.FINAL;



                    if (item?.data?.cabinet?.uuid !== undefined && item?.data?.cabinet?.uuid !== null){

                        const currentCabinet = getCurrentCabinet();

                        if (currentCabinet !== false && currentCabinet?.uuid !== undefined && currentCabinet?.uuid !== null){
                            if (item.data.cabinet.uuid === currentCabinet.uuid){
                                isCurrentCabinetMessage = true;
                            }
                        }
                    }

                    switch (item.eventType){
                        case wsMessageEventTypes.CONNECTOR_CONNECTED:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.CONTENT_SERVICES_LIST) {
                                    if (item.data.connector !== undefined) {
                                        dispatch(contentConnectorApiResponseSuccess(ContentConnectorActionTypes.IS_CONNECTED_CONTENT_CONNECTOR, item.data.connector));
                                    }
                                }
                            }
                            break;
                        case wsMessageEventTypes.CONNECTOR_CONNECTION_ERROR:
                            let connector:ContentConnectorType = item.data.connector;
                            if (connector !== undefined && connector.uuid !== undefined) {
                                if (isCurrentCabinetMessage) {
                                    toast.error(<ToastConnectorItem
                                        item={connector}
                                        message={item.eventType}/>,
                                        {
                                            className: "cursor-pointer"
                                        });

                                    if (location.pathname === route.CONTENT_SERVICES_LIST) {
                                        dispatch(onIsConnectedErrorContentConnector());
                                        dispatch(getContentConnectors());
                                    }

                                } else {
                                    toast.error(<ToastConnectorItem
                                        item={connector}
                                        cabinet={item.data.cabinet}
                                        message={item.eventType}
                                    />, {
                                        className: "cursor-pointer",
                                        position: "bottom-left"
                                    });
                                }
                            }
                            break;
                        case wsMessageEventTypes.CONNECTOR_RESPONSE_OAUTH_URL:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.CONTENT_SERVICES_LIST) {
                                    if (item.data.connector !== undefined) {
                                        dispatch(contentConnectorApiResponseSuccess(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR, item.data.connector));
                                    }
                                }
                            }
                            break;
                        case wsMessageEventTypes.CONNECTOR_CHANNEL_SEARCH:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.CONTENT_STAT_COUNTERS_LIST) {
                                    dispatch(contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS, item.data));
                                } else if (location.pathname === route.CONTENT_CHANNELS_LIST) {
                                    dispatch(contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS, item.data));
                                }
                            }
                            break;
                        case wsMessageEventTypes.CONNECTOR_UPDATE_MANAGED_CHANNELS:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.CONTENT_SERVICES_LIST) {
                                    dispatch(contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_CHANNELS, item.data));
                                }
                            }
                            break;
                        case wsMessageEventTypes.CABINET_CHANNEL_ADDED:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.CONTENT_CHANNELS_LIST) {
                                    //TODO message channel added
                                    dispatch(contentPeerChannelApiResponseSuccess(ContentPeerChannelActionTypes.UPDATE_CONTENT_PEER_CHANNEL, item.data));
                                }
                            }
                            break;
                        case wsMessageEventTypes.PEER_CHANNEL_RESPONSE_OAUTH_URL:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.CONTENT_CHANNELS_LIST) {
                                    dispatch(contentPeerChannelApiResponseSuccess(ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL, item.data));
                                }
                            }
                            break;
                        case wsMessageEventTypes.PEER_CHANNEL_RESPONSE_HASHTAG_FROM_SERVICE_NOT_FOUND:
                        case wsMessageEventTypes.PEER_CHANNEL_RESPONSE_FROM_SERVICE_BY_CODE_NOT_FOUND:
                            if (isCurrentCabinetMessage) {
                                let channelName = '';
                                if (item?.data?.peerChannel?.name) {
                                    channelName = item.data.peerChannel.name + ' ';
                                }

                                showErrorNotification(t('channel:channelNotFound').replace(/#channelName#/, channelName));

                                if (location.pathname === route.CONTENT_CHANNELS_LIST) {
                                    dispatch(contentPeerChannelNotFound());
                                }
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_PUBLISH_TO_SERVICE_SUCCESSFUL:
                            if (isCurrentCabinetMessage) {
                                dispatch(publishPeerActivitySuccessful(item.data));
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_PUBLISH_TO_SERVICE_FAILED:
                            if (isCurrentCabinetMessage) {
                                dispatch(publishPeerActivityFailed(item.data));
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_UNPUBLISH_TO_SERVICE_SUCCESSFUL:
                            if (isCurrentCabinetMessage) {
                                dispatch(unPublishPeerActivitySuccessful(item.data));
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_UNPUBLISH_TO_SERVICE_FAILED:
                            if (isCurrentCabinetMessage) {
                                dispatch(unPublishPeerActivityFailed(item.data));
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_PIN_TO_SERVICE_SUCCESSFUL:
                            if (isCurrentCabinetMessage) {
                                dispatch(pinPeerActivitySuccessful(item.data));
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_PIN_TO_SERVICE_FAILED:
                            if (isCurrentCabinetMessage) {
                                dispatch(pinPeerActivityFailed(item.data));
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_UNPIN_TO_SERVICE_SUCCESSFUL:
                            if (isCurrentCabinetMessage) {
                                dispatch(unPinPeerActivitySuccessful(item.data));
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_UNPIN_TO_SERVICE_FAILED:
                            if (isCurrentCabinetMessage) {
                                dispatch(unPinPeerActivityFailed(item.data));
                            }
                            break;
                        case wsMessageEventTypes.STAT_COUNTER_RESPONSE_TILES_FROM_SERVICE:
                            //TODO display data in Dashboard by reducer

                            // if (location.pathname===route.CONTENT_STAT_COUNTERS_LIST) {
                            //     dispatch(contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS, item.data));
                            // }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_SEND_SUCCESS:
                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_DELETE_SUCCESS:
                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_MARK_AS_SPAM_SUCCESS:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.MESSENGER_COMMENTS) {

                                    if (stateMessengerComment.selectedChat !== undefined && stateMessengerComment.selectedChat !== null) {
                                        item.data.items.map((receiveItem: any) => {
                                            if (receiveItem?.channel?.uuid !== undefined) {
                                                if (stateMessengerComment.selectedChat === receiveItem.channel.uuid) {
                                                    dispatch(getCommentsUserConversations(receiveItem.channel.uuid));
                                                }
                                            }
                                            return receiveItem;
                                        });
                                    }

                                    switch (item.eventType) {
                                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_SEND_SUCCESS:
                                            showSuccessNotification(t('peerComment:publishSuccessful'));
                                            break;
                                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_DELETE_SUCCESS:
                                            showSuccessNotification(t('peerComment:deleteSuccessful'));
                                            break;
                                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_MARK_AS_SPAM_SUCCESS:
                                            showSuccessNotification(t('peerComment:markAsSpamSuccessful'));
                                            break;
                                    }

                                    dispatch(getCommentsChannels({page:1}));

                                    // if (settingsData.sound.soundNotification) {
                                    newMessageAudio();
                                    // }
                                }
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_SEND_FAIL:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.MESSENGER_COMMENTS) {
                                    showErrorNotification(t('peerComment:publishFailed'));
                                }
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_DELETE_FAIL:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.MESSENGER_COMMENTS) {
                                    showErrorNotification(t('peerComment:deleteFailed'));
                                }
                            }
                            break;
                        case wsMessageEventTypes.PEER_ACTIVITY_COMMENT_MARK_AS_SPAM_FAIL:
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.MESSENGER_COMMENTS) {
                                    showErrorNotification(t('peerComment:markAsSpamFailed'))
                                }
                            }
                            break;
                        case wsMessageEventTypes.SUPPORT_TICKET_MESSAGE_CREATED:
                                if (item?.data?.ticket?.code) {
                                    let ticket:SupportTicketType = item.data.ticket;
                                    let ticketMessage:SupportTicketMessageType = item.data.ticketMessage;

                                    toast(<ToastTicketMessageItem ticketMessage={ticketMessage} ticket={ticket}/>,{className: "cursor-pointer", progressClassName: "toast-notification"});

                                    dispatch(getSupportUserBadges());

                                    // if (isSupportService) {
                                    //     dispatch(getSupportServiceBadges());
                                    // } else {
                                    //     dispatch(getSupportUserBadges());
                                    // }
                                }
                            break;
                        case wsMessageEventTypes.SALE_ORDER_ADDED:
                        case wsMessageEventTypes.SALE_ORDER_PAID:
                        case wsMessageEventTypes.SALE_ORDER_CANCELLED:
                            if (item?.data?.order?.uuid) {
                                let order:OrderType = item.data.order;
                                toast(<OrderItem order={order} action={item.eventType}/>,{className: "cursor-pointer", progressClassName: "toast-notification"});
                                newMessageAudio();
                            }
                            break;
                        case wsMessageEventTypes.TASK_ADDED:
                        case wsMessageEventTypes.TASK_UPDATED:
                        case wsMessageEventTypes.TASK_CLOSED:
                        case wsMessageEventTypes.TASK_OVERDUE:
                        case wsMessageEventTypes.TASK_RETURNED:
                            if (item?.data?.task?.uuid) {
                                let task:TaskType = item.data.task;
                                toast(<TaskItem item={task} action={item.eventType}/>,{className: "cursor-pointer", progressClassName: "toast-notification"});
                                newMessageAudio();
                            }
                            break;
                        case wsMessageEventTypes.COMMENT_ADDED:
                        case wsMessageEventTypes.MENTIONED_IN_COMMENT:
                            if (item?.data?.comment?.uuid) {
                                let comment:CommentType = item.data.comment;
                                toast(<CommentItem item={comment} action={item.eventType}/>,{className: "cursor-pointer", progressClassName: "toast-notification"});
                                newMessageAudio();
                            }
                            break;
                        case wsMessageEventTypes.EVENT_WEBHOOK_ERROR:
                            if (item?.data?.webhook?.uuid) {
                                let webhook:EventWebhookType = item.data.webhook;
                                toast.error(<WebhookItem webhook={webhook} action={item.eventType}/>,{className: "cursor-pointer"});
                            }
                            break;
                        case wsMessageEventTypes.PEER_CHANNEL_INIT_SUCCESSFUL:
                        case wsMessageEventTypes.PEER_CHANNEL_CONNECTED:

                            if (item?.data?.peerChannel) {
                                    let peerChannel: ContentPeerChannelType = item.data.peerChannel;

                                    if (isCurrentCabinetMessage) {
                                        toast(<ToastPeerChannelItem item={peerChannel} message={item.eventType}/>, {
                                            className: "cursor-pointer",
                                            progressClassName: "toast-notification"
                                        });
                                    } else {
                                        toast(<ToastPeerChannelItem
                                            item={peerChannel}
                                            cabinet={item.data.cabinet}
                                            message={item.eventType}
                                        />, {
                                            className: "cursor-pointer",
                                            progressClassName: "toast-notification",
                                            position:"bottom-left"
                                        });
                                    }
                            }
                            if (isCurrentCabinetMessage) {
                                if (location.pathname === route.CONTENT_CHANNELS_LIST && item.eventType === wsMessageEventTypes.PEER_CHANNEL_CONNECTED) {
                                    dispatch(getContentPeerChannels());
                                }
                            }
                            break;
                        case wsMessageEventTypes.MESSENGER_MESSAGE_UPDATE_FROM_SERVICE:

                                let messengerChannelUuid = null;

                                if (item?.data?.items !== undefined && item?.data?.items !== null && item?.data?.items.length > 0) {

                                    (item.data.items || []).map((messageItem: MessengerMessageType) => {

                                        if (isCurrentCabinetMessage) {
                                            toast(<ToastMessengerMessageItem item={messageItem}
                                                                             message={wsMessageEventTypes.PEER_CHANNEL_INIT_SUCCESSFUL}/>, {
                                                className: "cursor-pointer",
                                                progressClassName: "toast-notification"
                                            });

                                            messengerChannelUuid = messageItem.receiver;
                                        } else {
                                            toast(<ToastMessengerMessageItem
                                                item={messageItem}
                                                cabinet={item.data.cabinet}
                                                message={wsMessageEventTypes.PEER_CHANNEL_INIT_SUCCESSFUL}/>, {
                                                className: "cursor-pointer",
                                                progressClassName: "toast-notification",
                                                position:"bottom-left"
                                            });
                                        }
                                        return messageItem;
                                    });

                                    // if (settingsData.sound.soundNotification) {
                                    newMessageAudio();
                                    // }
                                    if (isCurrentCabinetMessage) {
                                        dispatch(getChannels({page:1}));
                                        dispatch(getEventUserBadges());
                                    }
                                }

                            if (isCurrentCabinetMessage) {
                                if (
                                    (location.pathname === route.MESSENGER_MESSENGER
                                        ||
                                        !stateMessengerChat.isCollapsed
                                    )
                                    &&
                                    (messengerChannelUuid !== null)
                                ) {

                                    if (stateMessengerChat.selectedChat === messengerChannelUuid) {
                                        dispatch(getChatUserConversations(messengerChannelUuid));
                                    }
                                }
                            }
                            break;

                        case wsMessageEventTypes.PRODUCT_CATEGORIES_RESPONSE_FROM_REMOTE:
                                dispatch(responseSaleProductsIntegrationParamFromRemote(item.data));
                            break;
                        case wsMessageEventTypes.META_GET_USER_BUSINESSES_RESPONSE_FROM_REMOTE:
                            dispatch(responseSaleProductsIntegrationMetaBusinessManagersFromRemote(item.data));
                            break;
                        case wsMessageEventTypes.META_GET_BUSINESS_CATALOGS_RESPONSE_FROM_REMOTE:
                            dispatch(responseSaleProductsIntegrationMetaProductCatalogsFromRemote(item.data));
                            break;
                        case wsMessageEventTypes.AI_GENERATE_TEXT_SUCCESSFUL:
                        case wsMessageEventTypes.AI_REWRITE_TEXT_SUCCESSFUL:
                        case wsMessageEventTypes.AI_GENERATE_TITLE_SUCCESSFUL:
                        case wsMessageEventTypes.AI_TRANSLATE_TEXT_SUCCESSFUL:
                        case wsMessageEventTypes.AI_PREDICT_TEXT_SUCCESSFUL:
                        case wsMessageEventTypes.AI_PREDICT_TAGS_SUCCESSFUL:

                            if (item?.data?.items !== undefined && item?.data?.items !== null && item?.data?.items.length > 0) {
                                (item.data.items || []).map((aiTextGenerate: AITextGenerateType) => {
                                    dispatch(aiTextGenerateApiResponseSuccess(AITextGenerateActionTypes.RECEIVE_TEXT_GENERATE_RESPONSE, {item:aiTextGenerate, requestMetadata:item.data.requestMetadata}));
                                    return aiTextGenerate;
                                });
                            }

                            break
                        case wsMessageEventTypes.AI_GENERATE_TEXT_FAILED:
                        case wsMessageEventTypes.AI_REWRITE_TEXT_FAILED:
                        case wsMessageEventTypes.AI_GENERATE_TITLE_FAILED:
                        case wsMessageEventTypes.AI_TRANSLATE_TEXT_FAILED:
                        case wsMessageEventTypes.AI_PREDICT_TEXT_FAILED:
                        case wsMessageEventTypes.AI_PREDICT_TAGS_FAILED:

                            if (item?.data?.items !== undefined && item?.data?.items !== null && item?.data?.items.length > 0) {
                                (item.data.items || []).map((aiTextGenerate: AITextGenerateType) => {
                                    dispatch(aiTextGenerateApiResponseError(AITextGenerateActionTypes.RECEIVE_TEXT_GENERATE_RESPONSE, t('ai:aiGeneratingError')));
                                    return aiTextGenerate;
                                });
                            }
                            break
                        case wsMessageEventTypes.SHORTENER_URL_IS_AVAILABLE_RESPONSE:

                            if (item?.data?.items !== undefined && item?.data?.items !== null && item?.data?.items.length > 0) {
                                (item.data.items || []).map((urlShortener: UrlShortenerType) => {
                                    dispatch(
                                        ShortenerApiResponseSuccess(
                                            ShortenerActionTypes.SHORTENER_URL_IS_AVAILABLE_RESPONSE,
                                        {
                                                item:urlShortener,
                                                requestMetadata:item.data.requestMetadata
                                            }
                                        )
                                    );

                                    return urlShortener;
                                });
                            }

                            break;
                        case wsMessageEventTypes.SHORTENER_URL_IS_NOT_AVAILABLE_RESPONSE:

                            if (item?.data?.items !== undefined && item?.data?.items !== null && item?.data?.items.length > 0) {
                                (item.data.items || []).map((urlShortener: UrlShortenerType) => {

                                    showErrorNotification(t('validator:shortenerSetUnavailable'));

                                    dispatch(
                                        ShortenerApiResponseError(
                                            ShortenerActionTypes.SHORTENER_URL_IS_NOT_AVAILABLE_RESPONSE,
                                            t('validator:shortenerSetUnavailable')
                                        )
                                    );

                                    return urlShortener;
                                });
                            }

                            break;
                    }
                }
                return item;
            });


            // dispatch(getChannels());
            // dispatch(getCommentsChannels());
            // dispatch(getChatUserConversations(state.newMessageChannel));
            //
            // // if (settingsData.sound.soundNotification) {
            // //     newMessageAudio();
            // // }
            //
            // delete(state.newMessageChannel);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.messages]);

//    const [setConnectFn, onClose] = useConnectionPauseHandler(state, dispatch);
    const [connect, sendMessage, closeConn] = useSession(
        onOpen,
        onMessage,
        onClose
    );

    // wire everything
    // useEffect(() => {
    //     console.log('wiring everything...');
    //     setConnectFn(connect);
    // }, [setConnectFn, connect]);

    // deliver the context to the application

    const join = () => {
        dispatchReducer({type: 'CONNECTING'});
        connect();
    };
    const reconnect = () => {
        // dispatch({
        //     type: 'RECONNECTION_AFTER_RENDER',
        //     payload: {  },
        // });
        //connect();
    };
    const close = () => {
        dispatchReducer({ type: 'DISCONNECT' });
        closeConn();
    };
    // const start = () => sendMessage({ context: 'START' });
    // const overrideSkip = () => sendMessage({ context: 'SKIP' });
    // const requestNextRound = () => sendMessage({ context: 'NEXT' });
    // const manuallyEndGame = () => sendMessage({ context: 'STOP' });


    return (
        <WebSocketContext.Provider
            value={{
                ...state,
                join,
                reconnect,
                close,
            // start,
            // overrideSkip,
            // requestNextRound,
            // manuallyEndGame,
            // onCardClick,
            // onCardButtonClick,
            }}
        >
            {children}
        </WebSocketContext.Provider>
    );
}


