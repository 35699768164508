import {CurrencyType} from "../Currency/types";
import {PaymentSystemType} from "../PaymentSystem/types";
import {MainMetaType} from "../../Main/common";
import {OrderType} from "../Order/types";

export enum SaleOrderPaymentActionTypes {
    API_RESPONSE_SUCCESS = "@@saleOrderPayment/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleOrderPayment/API_RESPONSE_ERROR",
    GET_SALE_ORDER_PAYMENTS = "@@saleOrderPayment/GET_SALE_ORDER_PAYMENTS",
    UPDATE_SALE_ORDER_PAYMENT = "@@saleOrderPayment/UPDATE_SALE_ORDER_PAYMENT",
    REMOVE_SALE_ORDER_PAYMENT_MODAL_SHOW = "@@saleOrderPayment/REMOVE_SALE_ORDER_PAYMENT_MODAL_SHOW",
    REMOVE_SALE_ORDER_PAYMENT_MODAL_HIDE = "@@saleOrderPayment/REMOVE_SALE_ORDER_PAYMENT_MODAL_HIDE",
    REMOVE_SALE_ORDER_PAYMENT = "@@saleOrderPayment/REMOVE_SALE_ORDER_PAYMENT",
    EDIT_SALE_ORDER_PAYMENT = "@@saleOrderPayment/EDIT_SALE_ORDER_PAYMENT",
    RIGHT_MODAL_SHOW = "@@saleOrderPayment/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleOrderPayment/RIGHT_MODAL_HIDE",
    CLEAR_NEED_ORDER_RELOAD = "@@saleOrderPayment/CLEAR_NEED_ORDER_RELOAD",
}

export interface SaleOrderPaymentState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: PaymentType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
    isNeedOrderReload: boolean;
}
export interface PaymentType{
    uuid?: string;
    enabled?: boolean;
    amount?: number;
    currency?: CurrencyType;
    status?: string;
    comment?: string;
    paymentSystem?: PaymentSystemType
    createdAt?: string;
    updatedAt?: string;
    idPayment?: string;
    paymentDate?: string;
    saleOrder?: OrderType;
}

export const PaymentStatus = {
    NEW: {
        label: 'New',
        status: 'N',
        class: 'info',
    },
    CANCELLED: {
        label: 'Cancelled',
        status: 'C',
        class: 'dark',
    },
    ERROR: {
        label: 'Error',
        status: 'E',
        class: 'danger',
    },
    FINAL: {
        label: 'Final',
        status: 'F',
        class: 'success',
    },
}

export interface SaleOrderPaymentPayload {
    data: Array<any>;
    modalEditTitle : string;
}