import React, { useEffect, useState } from "react";
import {PlanOrderItemType} from "../../redux/Sale/PlanOrder/types";
import {LocaleCurrencySymbol} from "../../redux/Sale/Currency/types";
import '../../i18/config';
import {useTranslation} from "react-i18next";
const logo = require('../../resources/images/mcLogoCircle.svg');

interface InputProps {
    item: PlanOrderItemType;
}
const PlanOrderItem = ({item}:InputProps) => {
    const { t, i18n } = useTranslation(['sale']);

    return (
        <tr>
            <td>
                <div className="d-flex">
                    <div className="flex-shrink-0 avatar-md bg-light rounded p-1 d-flex align-items-center justify-content-center">
                        <img
                            width={35}
                            src={logo.default}
                            alt=""
                            className="img-fluid d-block"
                        />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <h5 className="fs-16">
                            <span className="text-muted">{t('sale:marketcellsLicense')}</span> {item.name}
                        </h5>
                        <p className="text-muted mb-0">
                            {t('sale:period')}: <span className="fw-medium">{t('sale:priceType_'+item.priceType.code)}</span>
                        </p>
                        {/*<p className="text-muted mb-0">*/}
                        {/*    Size: <span className="fw-medium">{props.product.size}</span>*/}
                        {/*</p>*/}
                    </div>
                </div>
            </td>
            <td className="text-end">
                {(item.discount !== undefined && item.discount !== null) &&
                    <div className="text-muted text-decoration-line-through">
                        {LocaleCurrencySymbol[item.currency.code]}{item.price+item.discount}
                    </div>
                }
                <div>
                {LocaleCurrencySymbol[item.currency.code]}{item.price}
                </div>
            </td>
            <td className="text-end">{item.quantity}</td>
            {/*<td>*/}
            {/*    <div className="text-warning fs-15">*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-fill"></i>*/}
            {/*        <i className="ri-star-half-fill"></i>*/}
            {/*    </div>*/}
            {/*</td>*/}
            <td className="fw-medium text-end">
                {(item.discount !== undefined && item.discount !== null) &&
                    <div className="text-muted text-decoration-line-through">
                        {LocaleCurrencySymbol[item.currency.code]}{(item.price+item.discount)*item.quantity}
                    </div>
                }
                <div>
                {LocaleCurrencySymbol[item.currency.code]}{item.quantity * item.price}
                </div>
            </td>
        </tr>
    )
}

export default  PlanOrderItem;