import React, { useEffect, useState } from "react";
import { BrowserRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useRedux } from "../../../hooks";

import {
    getContentPeerChannels,
    getContentProjects,
    getContentConnectors, getContentPeerTargetTypes, getContentConnectorHandlers, contentPeerChannelConnectCancel,

} from "../../../redux/actions";

import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import ListItem from "./ListItem";
import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";
import RemoveModal from "../../../components/RemoveForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    ContentPeerChannelHideRemoveModal, ContentPeerChannelOnConnect, ContentPeerChannelOnRemove,
    ContentPeerChannelOpenModal,
    ContentPeerChannelOpenRemoveModal
} from "./ContentPeerChannelActions";
import {default as RightModalTarget} from "../PeerTarget/modalForm";
import ConnectModal from "./ConnectModal";
import {NewContentPeerChannelItem} from "../../../redux/Content/PeerChannel/reducer";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {nameToEmoji} from "gemoji";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {isNumeric} from "../../../helpers/functions";
import * as route from "../../../api/routes";
import Loader from "../../../components/Loader";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";

const PeerChannelIndex = () => {
    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();

    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [disableAddButton, setDisableAddButton] = useState<boolean>(true);
    const [showConnect, setShowConnect] = useState<boolean>(false);
    const [connectPeerChannel, setConnectPeerChannel] = useState<ContentPeerChannelType>(NewContentPeerChannelItem);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

    const {
        contentPeerChannels,
        isFetched,
        salePlan
    } = useAppSelector(state => ({
        contentPeerChannels: state.ContentPeerChannel,
        isFetched: state.ContentPeerChannel.isFetched,
        salePlan: state.SalePlan
    }));

    useEffect(() => {
        dispatch(getContentProjects());
        dispatch(getContentConnectors());
        dispatch(getContentPeerChannels());
        dispatch(getContentPeerTargetTypes());
    }, [dispatch]);

    useEffect(() => {
        if (contentPeerChannels?.isNeedReload === true) {
            dispatch(getContentPeerChannels());
        }
    }, [dispatch, contentPeerChannels?.isNeedReload]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    useEffect(() => {
        if ((contentPeerChannels.items !== undefined) && (contentPeerChannels.items.length>0)){
            setChannels([...contentPeerChannels.items]);
        } else {
            setChannels([]);
        }
    }, [contentPeerChannels?.items])

    useEffect(() => {
        if (contentPeerChannels.connectModalShow === true){
            const reqItems = (contentPeerChannels.items || []).filter((item:ContentPeerChannelType)=>{
                return (item.accessTokenOAuthUrl && item.accessTokenOAuthUrl.length>0 && item.accessTokenOAuthUrl !=='request')
            });

            if (reqItems.length>0) {
                setConnectPeerChannel(reqItems.shift());
                setShowConnect(true);
            }
        } else {
            setShowConnect(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerChannels?.connectModalShow])

    setTimeout(() => {
        setDisableAddButton(false)
    }, 1000);

    const handleCloseConnectModal = ()=> {
        dispatch(contentPeerChannelConnectCancel());
    }

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.CONTENT_MAX_COMPETITORS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    return (
        <>
            <BreadCrumb title={t('menu:channels')} pageTitle={t('menu:main')} />
            {showConnect && <ConnectModal item={connectPeerChannel} handleClose={handleCloseConnectModal} isOpen={showConnect} modalTitle={'Connect '+connectPeerChannel.name}/>}
                <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary"
                                    onClick={(event)=>ContentPeerChannelOpenModal(event, dispatch)}
                                    className="btn btn-success"
                                    disabled={disableAddButton}
                            >
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>
                <div className="row">
                    <div>
                        <h5 className="mb-2">{t('channel:ownChannels')}</h5>
                        <div className="row">
                            {isFetched ?
                                (channels || []).filter((item: ContentPeerChannelType)=>item.isManaged).length<1
                                    ?
                                    <div className="d-flex flex-row flex-grow-1 mt-2">
                                        <div className="alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                            {t('channel:peerChannelAbsentError')}<span className="align-bottom ps-1">{nameToEmoji.upside_down_face}</span>
                                        </div>
                                    </div>
                                    :
                                    (channels || []).map((item: ContentPeerChannelType, key: number) => (
                                        item.isManaged && (item.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS ) &&
                                        <Col xxl={3}
                                             sm={6}
                                             className="project-card"
                                             key={'contentPeerChannel_'+item.uuid}
                                        >
                                        <ListItem
                                            item={item}
                                            onEdit={(event)=>ContentPeerChannelOpenModal(event, dispatch)}
                                            onRemove = {(event)=>ContentPeerChannelOpenRemoveModal(event, dispatch)}
                                            onConnect= {(event)=>ContentPeerChannelOnConnect(event, dispatch)}
                                        />
                                        </Col>
                                    ))
                                :
                                <Loader/>
                            }
                        </div>
                    </div>
                    <div>
                        <h5 className="mb-2">{t('channel:competitors')}</h5>
                        <div className="row">
                            { upgradeFare && isFetched
                                ?
                                <div className="d-flex flex-row flex-grow-1 mt-2">
                                    <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                        <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                        <span dangerouslySetInnerHTML={{__html: t('plan:restrictionCompetitorsText').replace(/%link%/,route.SALE_PLANS)}}/>
                                    </div>
                                </div>
                                :
                                <>
                                    {isFetched ?
                                        (channels || []).filter((item: ContentPeerChannelType)=>!item.isManaged).length<1
                                        ?
                                        <div className="d-flex flex-row flex-grow-1 mt-2">
                                            <div className="alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                                <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                                {t('channel:peerChannelCompetitorsAbsentError')}
                                            </div>
                                        </div>
                                        :
                                            (channels || []).map((item: ContentPeerChannelType, key: number) => (
                                                !item.isManaged &&
                                                <Col
                                                    xxl={3}
                                                    sm={6}
                                                    className="project-card"
                                                    key={'contentPeerChannel_'+item.uuid}
                                                >
                                                    <ListItem
                                                        item={item}

                                                        onEdit={(event)=>ContentPeerChannelOpenModal(event, dispatch)}
                                                        onRemove = {(event)=>ContentPeerChannelOpenRemoveModal(event, dispatch)}
                                                    />
                                                </Col>
                                            ))
                                        :
                                        <Loader/>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                <RightModal/>
                <RightModalTarget/>

                <RemoveModal
                    onRemove={(event)=>ContentPeerChannelOnRemove(event, dispatch)}
                    handleClose={(event)=>ContentPeerChannelHideRemoveModal(dispatch)}
                    removeItem={contentPeerChannels.selectedItem}
                    isOpen={contentPeerChannels.removeModalShow}
                    modalTitle={contentPeerChannels.modalEditTitle}/>
        </>
    );
};

export default PeerChannelIndex;


