import React from 'react';
import * as Yup from 'yup';
import {SaleIntegrationHandlerCodes, SaleIntegrationHandlerTypes} from "../../../redux/Sale/IntegrationHandler/types";
import {URL_REGEX_WITH_PAGES} from "../../../data/settings";
import {boolean} from "yup";


export const SaleIntegrationValidatorSchema = Yup.lazy((values) => {
    const shapes = {};

    let isSetValidator = false;

    shapes['name'] = (Yup.string().required('required'));

    if (Object.keys(values).indexOf('handler')!==-1 && values['handler']!==undefined){
        if (values['handler'].type===SaleIntegrationHandlerTypes.INBOUND){
            isSetValidator=true;
            if (values['handler'].code !== SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED) {
                shapes['url'] = (Yup.string()
                    .min(2, 'tooShort')
                    .max(500, 'tooLong')
                    .matches(URL_REGEX_WITH_PAGES, "invalidUrl")
                    .required('required'));
            }
        }
    }

    if (!isSetValidator){
        if (
            values['handler']!==undefined
            &&
            values['handler'].code!==SaleIntegrationHandlerCodes.WIDGET_WEB
            &&
            values['handler'].code!==SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
            ) {
            shapes['channel'] = (Yup.object().required('required'));
        }
        if (values['handler']!==undefined && values['handler'].code===SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER) {
            shapes['metaBusinessManager'] = (Yup.object().required('required'));
            shapes['metaProductCatalog'] = (Yup.object().required('required'));
        }

//        shapes['store'] = (Yup.object().required('required'));
        shapes['currency'] = (Yup.object().required('required'));
    }

    if (values['handler']!==undefined && values['handler'].code===SaleIntegrationHandlerCodes.WIDGET_WEB){
        if (values['urlShortener'] !== undefined) {
            let urlShortenerSchema = Yup.object().shape({
                isApproved: boolean(),
                url: Yup.string().matches(
                    /^([a-z0-9]*)$/gi,
                    'lowCaseLatinSymbolsAndNumbersOnly'
                ).min(4, 'tooShort').test(function (value){

                    if (value !== undefined && value!== null && value.length>0) {
                        if (!(values['urlShortener']?.isApproved)){
                            return this.createError({
                                message: 'shortenerLinkNotApproved',
                                path: "urlShortener", // Fieldname
                            });
                        }
                    }
                    return true;
                })
            });
            shapes['urlShortener']=urlShortenerSchema;
        }
    }

    shapes['priceType'] = (Yup.object().required('required'));

    return Yup.object().shape(shapes);
});
