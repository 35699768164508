import {ShortenerActionTypes, UrlShortenerType} from "./types";

export const ShortenerApiResponseSuccess = (actionType: string, data: any) => ({
  type: ShortenerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const ShortenerApiResponseError = (actionType: string, error: string) => ({
  type: ShortenerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const checkShortener = (data: UrlShortenerType) => ({
  type: ShortenerActionTypes.CHECK_URL_SHORTENER,
  payload: data
});
