// types
import {MainSearchActionTypes, MainSearchState} from "./types";

export const INIT_STATE_MAIN_SEARCH: MainSearchState = {
  channels: {items:[]},
  activities: {items:[]},
  peerUsers:{items:[]},
  comments:{items:[]},
  eTag: '',
  query:'',
  maxResults: 5,
  isMainSearchFetched: false,
  getMainSearchLoading: false
};

const MainSearch = (state = INIT_STATE_MAIN_SEARCH, action: any) => {
  switch (action.type) {
    case MainSearchActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MainSearchActionTypes.GET_SEARCH_LIST:
          return {
            ...state,
            channels:  action.payload.data.channels,
            activities:  action.payload.data.activities,
            peerUsers:  action.payload.data.peerUsers,
            comments:  action.payload.data.comments,
            isMainSearchFetched: true,
            getMainSearchLoading: false,
          };
        default:
          return { ...state };
      }

    case MainSearchActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case MainSearchActionTypes.GET_SEARCH_LIST:
          return {
            ...state,
            isMainSearchFetched: false,
            getMainSearchLoading: false,
          };
        default:
          return { ...state };
      }
    case MainSearchActionTypes.GET_SEARCH_LIST:
      return {
        ...state,
        getMainSearchLoading: true,
      };
    default:
      return { ...state };
  }
};

export default MainSearch;
