import React from 'react';
import {Button, Col, Input, Label} from "reactstrap";
import { Link } from 'react-router-dom'
import CoverAuth from "./CoverAuth";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import * as route from "../../../api/routes";

const CoverSuccess = () => {

    const { t } = useTranslation(['auth']);

    return (
        <>
            <CoverAuth title={'Success | Marketcells.io - digital marketing platform'}>
                <Col lg={6}>
                    <CoverLangSwitch/>
                    <div className="p-lg-5 p-4 text-center">
                        <div className="avatar-lg mx-auto mt-2">
                            <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                <i className="ri-checkbox-circle-fill"/>
                            </div>
                        </div>
                        <div className="mt-4 pt-2">
                            <h4>{t('auth:wellDone')}!</h4>
                            <p className="text-muted mx-4">{t('auth:successMessage')}</p>
                            <div className="mt-4">
                                <Link to={route.MAIN_AUTH_LOGIN} className="btn btn-success w-100">{t('auth:signIn')}</Link>
                            </div>
                        </div>
                    </div>
                </Col>
            </CoverAuth>
        </>
    );
};

export default CoverSuccess;