import * as React from 'react';
import {useMemo, useState} from 'react';
import {PaginationComponentProps, PaginationOptions} from "react-data-table-component"
import {Row, Col, Pagination, PaginationItem, PaginationLink, FormGroup, Label, Input} from "reactstrap";
import '../i18/config';
import {useTranslation} from "react-i18next";
import {PAGINATION_DOTS, PAGING_VARIANTS} from "../data/settings";
import InputSwitch from "./InputSwitch";


export interface TablePaginationProps extends PaginationComponentProps {
    paginationComponentOptions?: TablePaginationOptions;
}

export interface TablePaginationOptions extends PaginationOptions {
    selectedData?: any[];
    isShowChangePageSize?: boolean;
    isShowAllGroupAction?:boolean;
    allGroupActionSelectHandler?: (selected:boolean) => void
}

const range = (start, end) => {
    let length = end - start + 1;
    /*
        Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
};

export interface PaginationParam{
    totalCount: number,
    pageSize: number,
    siblingCount: number,
    currentPage: number
}

export const usePagination = ({
                                  totalCount,
                                  pageSize,
                                  siblingCount = 1,
                                  currentPage
                              }: PaginationParam) => {
    const totalPageCount = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);
    const DOTS = PAGINATION_DOTS;
    const leftSiblingIndex = useMemo(() => Math.max(currentPage - siblingCount, 1), [currentPage, siblingCount]);
    const rightSiblingIndex = useMemo(() => Math.min(currentPage + siblingCount, totalPageCount), [currentPage, siblingCount, totalPageCount]);
    const shouldShowLeftDots = useMemo(() => leftSiblingIndex > 2, [leftSiblingIndex]);
    const shouldShowRightDots = useMemo(() => rightSiblingIndex < totalPageCount - 2, [rightSiblingIndex, totalPageCount]);

    const caseOne = () => range(1, totalPageCount);
    const caseTwo = () => [...range(1, 3 + 2 * siblingCount), DOTS, totalPageCount];
    const caseThree = () => [1, DOTS, ...range(totalPageCount - 3 - 2 * siblingCount + 1, totalPageCount)];
    const caseFour = () => [1, DOTS, ...range(leftSiblingIndex, rightSiblingIndex), DOTS, totalPageCount];

    const paginationRange = useMemo(() => {
        const totalPageNumbers = siblingCount + 5;
        if (totalPageNumbers >= totalPageCount) {
            return caseOne();
        }
        if (!shouldShowLeftDots && shouldShowRightDots) {
            return caseTwo();
        }
        if (shouldShowLeftDots && !shouldShowRightDots) {
            return caseThree();
        }
        if (shouldShowLeftDots && shouldShowRightDots) {
            return caseFour();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCount, pageSize, siblingCount, currentPage, totalPageCount, shouldShowLeftDots, shouldShowRightDots]);

    return paginationRange;
};

function TableListPagination({
                                currentPage,
                                rowCount,
                                onChangePage,
                                rowsPerPage,
                                onChangeRowsPerPage,
                                 paginationComponentOptions

                    }: TablePaginationProps) {
    const { t } = useTranslation(['common']);
    const numPages = Math.ceil(rowCount/rowsPerPage);
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage + 1;
    const disabledLesser = currentPage === 1;
    const disabledGreater = currentPage === numPages;

    const [allGroupAction, setAllGroupAction] = useState<boolean>(false);

    const handlePrevious = React.useCallback(() => onChangePage(currentPage - 1, rowCount), [currentPage, onChangePage, rowCount]);
    const handleNext = React.useCallback(() => onChangePage(currentPage + 1, rowCount), [currentPage, onChangePage, rowCount]);
    const handleFirst = React.useCallback(() => onChangePage(1, rowCount), [onChangePage, rowCount]);
    const handleLast = React.useCallback(
        () => onChangePage(numPages, rowCount),
        [onChangePage, rowCount, numPages],
    );
    const handleRowsPerPage = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => onChangeRowsPerPage(Number(e.target.value), currentPage),
        [currentPage, onChangeRowsPerPage],
    );


    const paginationParam:PaginationParam = {
        currentPage: currentPage,
        pageSize: rowsPerPage,
        siblingCount: 1,
        totalCount: rowCount
    }

    const paginationRange = usePagination(
        paginationParam
    );

    const handleAllGroupAction = ()=>{
        if (paginationComponentOptions?.allGroupActionSelectHandler){
            paginationComponentOptions.allGroupActionSelectHandler(!allGroupAction);
        }
        setAllGroupAction(!allGroupAction);
    }

    return (
        <>
            <Row className={"mt-3"}>
                <Col md={4} className={"text-start d-flex"}>
                    <div className="d-flex justify-content-center  flex-column me-3">
                        <div className="text-muted">{t('common:tableShowing')} {firstIndex} - {lastIndex>rowCount?rowCount:lastIndex} {paginationComponentOptions.rangeSeparatorText} {rowCount}
                        </div>
                    </div>
                    {paginationComponentOptions?.isShowAllGroupAction && paginationComponentOptions.isShowAllGroupAction===true &&
                    <div className="d-flex justify-content-center flex-column">
                        <div className="d-inline-block me-2">
                            <FormGroup className="marginBottom0">
                                <InputSwitch
                                    id={'allGroupAction'}
                                    value={allGroupAction}
                                    onChange={handleAllGroupAction}
                                    className="switch-sm text-muted"
                                    placeholder={t('common:forAll')}
                                />

                            </FormGroup>
                        </div>
                    </div>
                    }
                </Col>
                <Col md={2} className={"text-start d-flex flex-grow-1"}>

                </Col>
                <Col md={6} className={"text-end col-sm-auto"}>
                    <div className="d-flex flex-row">
                        {rowCount && rowCount>0 &&
                        <div className="d-flex justify-content-center flex-grow-1 flex-column">
                            <div className="d-inline-block">
                                {allGroupAction ?
                                    <span className="me-2 badge bg-soft-primary text-primary">{t('common:selected')}: {rowCount}</span>
                                    :
                                    paginationComponentOptions?.selectedData && paginationComponentOptions?.selectedData.length>0 &&
                                    <span className="me-2 badge bg-soft-primary text-primary">{t('common:selected')}: {paginationComponentOptions.selectedData.length}</span>
                                }
                            </div>
                        </div>
                        }
                        {paginationComponentOptions.isShowChangePageSize &&
                        <div className="d-inline-block me-2">
                            <FormGroup className="marginBottom0">
                                <Input
                                    type="select"
                                    name="pageSize"
                                    className="formSelectPaging"
                                    value = {rowsPerPage}
                                    onChange={(event)=>{onChangeRowsPerPage(Number(event.target.value), currentPage)}}>
                                    {PAGING_VARIANTS.map((pageSizeItem:number)=>{
                                        return (
                                            <option key={'paginationSizeVariant_'+Math.round(Math.random() * (99999 - 11111) + 11111)+'_'+pageSizeItem}  value={pageSizeItem}>{pageSizeItem}</option>
                                        )
                                    })}
                                </Input>
                            </FormGroup>
                        </div>
                        }
                        <div className="d-inline-block">
                            <Pagination listClassName="mb-0">
                                <PaginationItem disabled={disabledLesser}>
                                    <PaginationLink to="#" onClick={handlePrevious}><i className="ri-arrow-left-s-line align-bottom"/></PaginationLink>
                                </PaginationItem>
                                {
                                    paginationRange.map((num:any)=>(
                                        <PaginationItem key={'pagination_'+Math.round(Math.random() * (99999 - 11111) + 11111)} active={currentPage===num} onClick={()=>{
                                            if (num !== PAGINATION_DOTS) {
                                                onChangePage(num, rowCount)
                                            }}
                                        }> <PaginationLink to="#"> {num} </PaginationLink> </PaginationItem>
                                    ))
                                }
                                <PaginationItem disabled={disabledGreater}>
                                    <PaginationLink to="#" onClick={handleLast}><i className="ri-arrow-right-s-line align-bottom"/> </PaginationLink>
                                </PaginationItem>
                            </Pagination>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default TableListPagination;