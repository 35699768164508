import {EntityActionsType} from "../../types";
import {ContentPeerChannelType} from "../PeerChannel/types";
import {PeerActivityTypeType} from "../PeerActivityType/types";
import {PeerActivityModeType} from "../PeerActivityMode/types";
import {ContentConnectorType} from "../Connector/types";
import {MainFileType} from "../../Main/File/types";
import {ContentPeerTargetType} from "../PeerTarget/types";
import {SaleIntegrationType} from "../../Sale/Integration/types";
import {ProductType} from "../../Sale/Product/types";
import {MainErrorType} from "../../Main/common";
export enum PeerActivityActionTypes {
  API_RESPONSE_SUCCESS = "@@peerActivity/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@peerActivity/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_ACTIVITIES = "@@peerActivity/GET_CONTENT_PEER_ACTIVITIES",
  UPDATE_PEER_ACTIVITIES = "@@peerActivity/UPDATE_PEER_ACTIVITIES",
  PUBLISH_PEER_ACTIVITIES = "@@peerActivity/PUBLISH_PEER_ACTIVITIES",
  UNPUBLISH_PEER_ACTIVITY = "@@peerActivity/UNPUBLISH_PEER_ACTIVITY",
  COPY_PEER_ACTIVITY = "@@peerActivity/COPY_PEER_ACTIVITY",
  REMOVE_PEER_ACTIVITY = "@@peerActivity/REMOVE_PEER_ACTIVITY",
  PUBLISH_PEER_ACTIVITY_SUCCESSFUL = "@@peerActivity/PUBLISH_PEER_ACTIVITY_SUCCESSFUL",
  PUBLISH_PEER_ACTIVITY_FAILED = "@@peerActivity/PUBLISH_PEER_ACTIVITY_FAILED",
  UNPUBLISH_PEER_ACTIVITY_SUCCESSFUL = "@@peerActivity/UNPUBLISH_PEER_ACTIVITY_SUCCESSFUL",
  UNPUBLISH_PEER_ACTIVITY_FAILED = "@@peerActivity/UNPUBLISH_PEER_ACTIVITY_FAILED",
  PIN_PEER_ACTIVITY_SUCCESSFUL = "@@peerActivity/PIN_PEER_ACTIVITY_SUCCESSFUL",
  PIN_PEER_ACTIVITY_FAILED = "@@peerActivity/PIN_PEER_ACTIVITY_FAILED",
  UNPIN_PEER_ACTIVITY_SUCCESSFUL = "@@peerActivity/UNPIN_PEER_ACTIVITY_SUCCESSFUL",
  UNPIN_PEER_ACTIVITY_FAILED = "@@peerActivity/UNPIN_PEER_ACTIVITY_FAILED",
  SET_EDIT_ITEMS = "@@peerActivity/SET_EDIT_ITEMS",
  SET_NEED_RELOAD = "@@peerActivity/SET_NEED_RELOAD",
  SET_INITIALIZED = "@@peerActivity/SET_INITIALIZED",
  TOGGLE_PIN = "@@peerActivity/TOGGLE_PIN",
  SHOW_MODAL = "@@peerActivity/SHOW_MODAL",
  HIDE_MODAL = "@@peerActivity/HIDE_MODAL",
  SET_ACTIVITY_MODE = "@@peerActivity/SET_ACTIVITY_MODE",
  SET_SHOW_MORE_CHANNELS = "@@peerActivity/SET_SHOW_MORE_CHANNELS",
  DONT_SET_RELOAD_AFTER_ACTION = "@@peerActivity/DONT_SET_RELOAD_AFTER_ACTION",
  SET_PREVIEW_ITEMS = "@@peerActivity/SET_PREVIEW_ITEMS",
  CLEAR_PREVIEW_ITEMS = "@@peerActivity/CLEAR_PREVIEW_ITEMS",
  SET_SHOW_PREVIEW_ITEMS = "@@peerActivity/SET_SHOW_PREVIEW_ITEMS",
  SET_HIDE_PREVIEW_ITEMS = "@@peerActivity/SET_HIDE_PREVIEW_ITEMS",
  SET_PREVIEW_MOBILE = "@@peerActivity/SET_PREVIEW_MOBILE",
  SET_PREVIEW_DESKTOP = "@@peerActivity/SET_PREVIEW_DESKTOP",
}

export interface PeerActivityState {
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem?: PeerActivityType;
  editItems?: Array<PeerActivityType>
  modalEditTitle: string;
  startDate?:any;
  endDate?:any;
  isNeedReload?:boolean
  isDontSetReloadAfterAction?:boolean
  isLoaded?:boolean
  isInitialized?:boolean
  isSending: boolean,
  requestId: string;
  getPeerActivityLoading: boolean;
  isPeerActivityFetched: boolean;
  isShowMoreChannels: boolean;
  mode?: string;
  previewItems?: Array<PeerActivityType>
  isShowPreviewItems: boolean;
  isShowPreviewMobile: boolean;
}

export interface PeerActivityType{
  uuid?: string,
  enabled?: boolean,
  name?: string,
  link?: string,
  title?: string,
  bio?: string,
  connector?: ContentConnectorType,
  channel?: ContentPeerChannelType,
  activeFrom?: string,
  start?: string,
  end?: string,
  isPin?: boolean,
  status?: string,
  targets?: Array<ContentPeerTargetType>,
  order?: number,
  actions?: EntityActionsType,
  stat?: PeerActivityStatType
  text?: string,
  description?: string,
  avatar?:Array<MainFileType>,
  cover?:Array<MainFileType>,
  media?: Array<MainFileType>;
  tags?:string;
  type?: PeerActivityTypeType,
  mode?: PeerActivityModeType,
  laneId? : string;  // kanban
  isHtml? : boolean;
  isPublicAccess? : boolean;
  isFriendsAccess? : boolean;
  isSelfAccess? : boolean;
  isLoggedInAccess?: boolean;
  isCommentsDisabled? : boolean;
  isDuetDisabled? : boolean;
  isStitchDisabled? : boolean;
  isForwardDisabled? : boolean;
  isSilent? :boolean;
  isSpoiler? : boolean;
  isBrandContent? : boolean;
  isBrandOrganic? : boolean;
  privacy?: any; // for validator
  cta?:Array<PeerActivityActionButton>;
  serviceMessages?: Array<ServiceMessageType>;
  isCheckMedia?: boolean;
  eTag?: string;
  errors?: Array<MainErrorType>;
  activityLink?: string;
}

export interface ServiceMessageType{
    type?: ServiceMessageTypes;
    message?: string;
}

export enum ServiceMessageTypes{
  INFO="info",
  WARNING="warning",
  ERROR="error",
}

export interface PeerActivityStatType{
  views?:number,
  likes?:number,
  comments?:number,
  reposts?:number,
  reach?: number,
  saved?: number,
  exits?: number,
  tapsForward?: number,
  tapsBack?: number,
  replies?: number,
  answers?: number,
  members?: number,
  bans?: number,
  openLink?: number,
  isPromo?: number,
  erActivity?: number,
  erView?: number,
  loveRate?: number,
  talkRate?: number,
  dateTime?: string,
}

export interface PeerActivityPayload {
  data: Array<any>;
  modalEditTitle : string;
}

export interface PeerActivityRequestPayload {
  startDate?: number; // for calendar division /1000
  endDate?: number; // for calendar division /1000
  periodFrom?: number;
  periodTo?: number;
}

export interface PeerActivityCopyPayload {
  peerActivity: PeerActivityType;
  channel?: ContentPeerChannelType;
}

export enum PeerActivityPrivacy {
  PUBLIC= 'PUBLIC',
  FRIENDS='FRIENDS',
  SELF='SELF',
  UNLISTED = 'UNLISTED', // friends
  PRIVATE = 'PRIVATE', //self
  CONNECTIONS = 'CONNECTIONS', // friends
  CONTAINER = 'CONTAINER', //self

  LOGGED_IN = 'LOGGED_IN' //self
}

export interface PeerActivityPrivacyItemType {
  uuid: string;
  name: string;
}

export interface PeerActivityActionButton {
  enabled?: boolean;
  name?: string;
  type?: string;
  url?: string;
  saleIntegration?: SaleIntegrationType; //TODO
  product?: ProductType //TODO
}

export const peerActivityPrivacyTikTok:PeerActivityPrivacyItemType[] = [
  {
      uuid: PeerActivityPrivacy.PUBLIC,
      name: 'canViewPublicTikTok'
  },
  {
      uuid: PeerActivityPrivacy.FRIENDS,
      name: 'canViewFriends'
  },
  {
      uuid: PeerActivityPrivacy.SELF,
      name: 'canViewSelf'
  },
]

export const peerActivityPrivacyYouTube:PeerActivityPrivacyItemType[] = [
  {
    uuid: PeerActivityPrivacy.PUBLIC,
    name: 'canViewPublic'
  },
  {
    uuid: PeerActivityPrivacy.FRIENDS,
    name: 'canViewUnlisted'
  },
  {
    uuid: PeerActivityPrivacy.SELF,
    name: 'canViewPrivate'
  },
]

export const peerActivityPrivacyLinkedId:PeerActivityPrivacyItemType[] = [
  {
    uuid: PeerActivityPrivacy.PUBLIC,
    name: 'canViewPublic'
  },
  {
    uuid: PeerActivityPrivacy.FRIENDS,
    name: 'canViewConnections'
  },
  {
    uuid: PeerActivityPrivacy.LOGGED_IN,
    name: 'canViewLoggedIn'
  },
  {
    uuid: PeerActivityPrivacy.SELF,
    name: 'canViewContainer'
  },
]