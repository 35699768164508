import { toast } from "react-toastify";

import '../i18/config';
import i18n from 'i18next';

const showSuccessNotification = (response: any) => {
  let message = response;

  if (response === 'emptyData') {
    message = i18n.t('common:emptyData');
  } else if (response === 'invalidCredentials'){
    message = i18n.t('common:invalidCredentials');
  } else if (response.message !== undefined) {
    message = response.message;
  }

  if ((response.success !== undefined) && (!response.success)){
    showErrorNotification(response);
    return
  }

  toast.success(message);
};

const showErrorNotification = (response: any) => {

  let message = response;

  if (response === 'emptyData') {
    message = i18n.t('common:emptyData');
  } else if (response === 'invalidCredentials'){
    message = i18n.t('common:invalidCredentials');
  } else if (response.message !== undefined) {
    if (i18n.exists('validator:'+response.message)){
      message = i18n.t('validator:'+response.message);
    } else {
      message = response.message;
    }
  }

  toast.error(message);
};

const showSuccessCopyNotification = () => {
  showSuccessNotification(i18n.t('common:copiedToClipboard'));
}


export { showSuccessNotification, showErrorNotification, showSuccessCopyNotification };
