import React from "react";
import { Card, CardBody, Col } from 'reactstrap';
import {
    ContentConnectorHandlerType
} from "../../../redux/Content/ConnectorHandler/types";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";

interface ItemProps {
    item: ContentConnectorHandlerType;
    onClick: (item:ContentConnectorHandlerType) => void,
    isSelected: boolean
}
const ListButtonItem = ({ item, onClick, isSelected }: ItemProps) => {
    const { t, i18n } = useTranslation();

    return (
        <>
        {(item.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS) &&
        <Col xxl={3} sm={6} className="project-card">
            <a onClick={() => onClick(item)}>
                <Card className={"card-height-100 card-animate" + (isSelected ? ' bg-soft-primary' : '')}>
                    <CardBody className={(item.isFareRestriction ? "disabledBlock" : "")}>
                        <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                            <div className="mb-2">
                                <div className="avatar-xs position-relative">
                                    <div
                                        className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                        {item.icon ?
                                            <img src={item.icon} className="img-fluid p-1 rounded-circle" alt=""/>
                                            :
                                            t('connectorHandler:' + item.code)[0]
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 d-flex align-items-center">
                                <h5 className="mb-0 fs-12 text-center">
                                    {t('connectorHandler:' + item.code)}
                                </h5>
                            </div>

                        </div>
                    </CardBody>
                </Card>
            </a>
        </Col>
    }
    </>
    );
};

export default ListButtonItem;
