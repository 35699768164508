import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { EventUserBadgeActionTypes } from "./types";
import { eventUserBadgeApiResponseSuccess, eventUserBadgeApiResponseError } from "./actions";

import {
  getUserBadges as getUserBadgesApi,
  getSupportBadgesTickets as getSupportBadgesTicketsApi,
  getSupportServiceBadgesTickets as getSupportServiceBadgesTicketsApi

} from "../../../api";

function* getEventUserBadges() {
  try {
    const response: Promise<any> = yield call(getUserBadgesApi);
    yield put(eventUserBadgeApiResponseSuccess(EventUserBadgeActionTypes.GET_USER_BADGES, response));
  } catch (error: any) {
    yield put(eventUserBadgeApiResponseError(EventUserBadgeActionTypes.GET_USER_BADGES, error));
  }
}

function* getSupportUserBadges() {
  try {
    const response: Promise<any> = yield call(getSupportBadgesTicketsApi);
    yield put(eventUserBadgeApiResponseSuccess(EventUserBadgeActionTypes.GET_SUPPORT_USER_BADGES, response));
  } catch (error: any) {
    yield put(eventUserBadgeApiResponseError(EventUserBadgeActionTypes.GET_SUPPORT_USER_BADGES, error));
  }
}

function* getSupportServiceBadges() {
  try {
    const response: Promise<any> = yield call(getSupportServiceBadgesTicketsApi);
    yield put(eventUserBadgeApiResponseSuccess(EventUserBadgeActionTypes.GET_SUPPORT_SERVICE_BADGES, response));
  } catch (error: any) {
    yield put(eventUserBadgeApiResponseError(EventUserBadgeActionTypes.GET_SUPPORT_SERVICE_BADGES, error));
  }
}

export function* watchGetEventUserBadges() {
  yield takeEvery(EventUserBadgeActionTypes.GET_USER_BADGES, getEventUserBadges);
}
export function* watchGetSupportUserBadges() {
  yield takeEvery(EventUserBadgeActionTypes.GET_SUPPORT_USER_BADGES, getSupportUserBadges);
}

export function* watchGetSupportServiceBadges() {
  yield takeEvery(EventUserBadgeActionTypes.GET_SUPPORT_SERVICE_BADGES, getSupportServiceBadges);
}

function* eventUserBadgeSaga() {
  yield all([
      fork(watchGetEventUserBadges),
      fork(watchGetSupportUserBadges),
      fork(watchGetSupportServiceBadges)
  ]);
}

export default eventUserBadgeSaga;
