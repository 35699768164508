import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContacts = (filters?: object) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTACTS, filters);
};

const inviteContact = (data: object) => {
  return api.create(m9sGlobals.CURRENT_CABINET+url.INVITE_CONTACT, data);
};
export { getContacts, inviteContact };
