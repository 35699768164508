import {ContentActivitiesFilterActionTypes } from "./types";

export const clearContentActivitiesFilter = () => ({
  type: ContentActivitiesFilterActionTypes.CLEAR_ALL,
});

export const setContentActivitiesProjectsFilter = (data: any) => ({
  type: ContentActivitiesFilterActionTypes.SET_PROJECTS,
  payload: data
});

export const setContentActivitiesModesFilter = (data: any) => ({
  type: ContentActivitiesFilterActionTypes.SET_MODES,
  payload: data
});

export const setContentActivitiesTypesFilter = (data: any) => ({
  type: ContentActivitiesFilterActionTypes.SET_TYPES,
  payload: data
});

export const setContentActivitiesChannelsFilter = (data: any) => ({
  type: ContentActivitiesFilterActionTypes.SET_CHANNELS,
  payload: data
});