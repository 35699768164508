import React, {useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import {useRedux} from "../../../hooks";
import classnames from "classnames";
import {
    getContentProjects,
} from "../../../redux/Content/Project/actions";
import {ContentProjectType} from "../../../redux/Content/Project/types";
import {default as RightModalProjects}  from "../../Content/Project/modalForm";
import {default as ProjectListItem} from "../../Content/Project/ListItem";
import {default as RightModalChannels}  from "../../Content/PeerChannel/modalForm";
import {default as RightModalCounters}  from "../../Content/StatCounter/modalForm";
import {default as RightModalTargets}  from "../../Content/PeerTarget/modalForm";
import {default as RightModalUsers}  from "../../Main/User/modalForm";
import Button from "react-bootstrap/Button";
import {LordIcon} from "../../../components/LordIcon";
import {ContentConnectorType} from "../../../redux/Content/Connector/types";
import {getContentConnectors} from "../../../redux/Content/Connector/actions";
import {default as ConnectorListItem} from "../../Content/Connector/ListItem";
import {default as ChannelListItem} from "../../Content/PeerChannel/ListItem";
import {default as CounterListItem} from "../../Content/StatCounter/ListItem";
import {default as TargetListItem} from "../../Content/PeerTarget/ListItem";
import {
    ContentConnectorOpenModal,
    ContentConnectorOpenRemoveModal,
    ContentConnectorHideRemoveModal,
    ContentConnectorOnRemove,
    contentConnectorCheckConnectionAttempts, contentConnectorConnectionSuccess
} from "../../Content/Connector/ContentConnectorActions";
import RemoveModal from "../../../components/RemoveForm";
import {default as RightModalConnectors} from "../../Content/Connector/modalForm";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getMainCurrentUser, getMainUsers} from "../../../redux/Main/User/actions";
import UserNotificationsChange from "../../Event/EventSubscribeUser/UserNotificationsChange";
import {MainUserType} from "../../../redux/Main/User/types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getContentConnectorHandlers} from "../../../redux/Content/ConnectorHandler/actions";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {
    ContentPeerChannelHideRemoveModal,
    ContentPeerChannelOnRemove,
    ContentPeerChannelOpenModal,
    ContentPeerChannelOpenRemoveModal
} from "../../Content/PeerChannel/ContentPeerChannelActions";
import {getContentStatCounters} from "../../../redux/Content/StatCounter/actions";
import {ContentStatCounterType} from "../../../redux/Content/StatCounter/types";
import {
    ContentStatCounterHideRemoveModal,
    ContentStatCounterOnRemove, ContentStatCounterOpenModal, ContentStatCounterOpenRemoveModal
} from "../../Content/StatCounter/ContentStatCounerActions";
import {
    ContentPeerTargetHideRemoveModal,
    ContentPeerTargetOnRemove, ContentPeerTargetOpenModal, ContentPeerTargetOpenRemoveModal
} from "../../Content/PeerTarget/ContentPeerTargetActions";
import {getContentPeerTargets} from "../../../redux/Content/PeerTarget/actions";
import {ContentPeerTargetType} from "../../../redux/Content/PeerTarget/types";
import {getContentPeerTargetTypes} from "../../../redux/Content/PeerTargetType/actions";
import {
    mainUserHideRemoveModal,
    mainUserOnRemove,
    mainUserOpenModal,
    mainUserOpenRemoveModal
} from "../User/MainUserActions";
import TableList from "../../../components/TableList";
import MainUserColumns from "../User/tableColumns";
import * as route from "../../../api/routes";
import {
    ContentProjectHideRemoveModal,
    ContentProjectOnRemove,
    ContentProjectOpenModal, ContentProjectOpenRemoveModal
} from "../../Content/Project/ContentProjectActions";
import {showErrorNotification} from "../../../helpers/notifications";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {EntityActionsHandlersType} from "../../../redux/types";
import {HistoryHashType} from "../../../data/settings";


const MainWizardInit = () => {
    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);

    const [projects, setProjects] = useState<ContentProjectType[]>([]);
    const [connectors, setConnectors] = useState<ContentConnectorType[]>([]);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [counters, setCounters] = useState<ContentStatCounterType[]>([]);
    const [targets, setTargets] = useState<ContentPeerTargetType[]>([]);
    const [currentUser, setCurrentUser] = useState<MainUserType>(null)
    const [allDone, setAllDone] = useState<boolean>(false);

    const infoSteps=[4,5,6,7];

    const {
        contentProjects,
        contentConnectors,
        contentPeerChannels,
        contentStatCounters,
        contentPeerTarget,
        mainUser,
        mainCabinet
    } = useAppSelector(state => ({
        contentProjects: state.ContentProjects,
        contentConnectors: state.ContentConnector,
        contentPeerChannels: state.ContentPeerChannel,
        contentStatCounters: state.ContentStatCounter,
        contentPeerTarget: state.ContentPeerTarget,
        mainUser: state.MainUser,
        mainCabinet: state.MainCabinet,
    }));

    useEffect(() => {
        dispatch(getContentProjects());
        dispatch(getContentConnectors());
        dispatch(getMainCurrentUser());
        dispatch(getContentConnectorHandlers());
        dispatch(getContentPeerChannels());
        dispatch(getContentStatCounters());
        dispatch(getContentPeerTargetTypes());
        dispatch(getContentPeerTargets());
        dispatch(getMainUsers({maxResults: 1000, cabinetUuid: mainCabinet?.currentCabinet && mainCabinet.currentCabinet.uuid}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        contentConnectorCheckConnectionAttempts(
            contentConnectors.selectedItem,
            t('connector:connectionError'),
            dispatch);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentConnectors.selectedItem.connectionCheckAttempts]);

    useEffect(() => {
        contentConnectorConnectionSuccess(
            contentConnectors.selectedItem,
            t('connector:service')+' ' + contentConnectors.selectedItem.name+ ' '+t('connector:connected').toString().toLowerCase(),
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentConnectors.selectedItem.connectionSuccess]);


    useEffect(()=>{
        const resultSteps = [...passedSteps.concat(infoSteps)];

        if ((resultSteps.length === 7) && (!allDone)){
            setAllDone(true);
        } else if ((resultSteps.length<7) && (allDone)) {
            setAllDone(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[passedSteps])

    const addPassedStep = (stepNum:number) => {
        if (passedSteps.indexOf(stepNum)<0){
            setPassedSteps([...passedSteps,stepNum]);
        }
    }

    const removePassedStep = (stepNum:number) => {
        if (passedSteps.indexOf(stepNum)>=0){
            setPassedSteps(passedSteps.filter((stepItem:number)=>(stepItem!==1)));
        }
    }

    useEffect(() => {

        if ((contentProjects.items !== undefined) && (contentProjects.items.length>0)){
            setProjects([...contentProjects.items]);
            addPassedStep(1);
        } else {
            removePassedStep(1);
            setProjects([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentProjects?.items]);

    useEffect(() => {

        if ((contentConnectors.items !== undefined) && (contentConnectors.items.length>0)){
            setConnectors([...contentConnectors.items]);

            let countConnectedConnectors = 0;

            contentConnectors.items.map((item:ContentConnectorType)=>{
                if (item.isConnected) {countConnectedConnectors++}
                return item;
            })

            if(countConnectedConnectors>0){
                addPassedStep(2);
            } else {
                removePassedStep(2);
            }

        } else {
            removePassedStep(2);
            setConnectors([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentConnectors?.items]);

    useEffect(() => {

        if ((contentPeerChannels.items !== undefined) && (contentPeerChannels.items.length>0)){
            setChannels([...contentPeerChannels.items]);
            addPassedStep(3);

        } else {
            removePassedStep(3);
            setChannels([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerChannels?.items]);

    useEffect(() => {

        if ((contentStatCounters.items !== undefined) && (contentStatCounters.items.length>0)){
            addPassedStep(4);
            setCounters([...contentStatCounters.items]);
        } else {
            removePassedStep(4);
            setCounters([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentStatCounters?.items]);

    useEffect(() => {

        if ((contentPeerTarget.items !== undefined) && (contentPeerTarget.items.length>0)){
            addPassedStep(5);
            setTargets([...contentPeerTarget.items]);
        } else {
            removePassedStep(5);
            setTargets([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerTarget?.items]);

    useEffect(() => {

        if (mainUser.currentUser !== undefined){
            addPassedStep(6);
            setCurrentUser(mainUser.currentUser);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainUser]);

    function toggleTab(tab) {
        if (activeTab !== tab) {

            if (tab>1) {
                if (infoSteps.indexOf(tab) < 0) {
                    if (passedSteps.indexOf(tab-1)<0){

                        showErrorNotification({message:t('wizard:incorrectStepDescription')});
                        return false;
                    }
                }
            }


            let modifiedSteps = [...passedSteps, tab];

            setActiveTab(tab);
            setPassedSteps(modifiedSteps);
        }
    }

    const handleSettingsIconClick = () => {
        const menuItem = document.getElementById('idMenuSettingsItem');
        if (menuItem !== undefined) {
            menuItem.dispatchEvent(new Event('click'));
        }
    }
    const handleServicesIconClick = () => {
        const menuItem = document.getElementById('idMenuServicesItem');
        if (menuItem !== undefined) {
            menuItem.dispatchEvent(new Event('click'));
        }
    }
    const handleTargetsIconClick = () => {
        const menuItem = document.getElementById('idMenuTargetsItem');
        if (menuItem !== undefined) {
            menuItem.dispatchEvent(new Event('click'));
        }
    }

    const UserEntityActionsHandlers:EntityActionsHandlersType = {
        onEdit: (event)=>mainUserOpenModal(event,dispatch),
        onRemove: (event)=>mainUserOpenRemoveModal(event,dispatch)
    }

    const onUserTableRowClick = (item:MainUserType) => {
        if (item.actions && item.actions.canEdit){
            mainUserOpenModal(item, dispatch);
        }
    }

    const userColumns = MainUserColumns(UserEntityActionsHandlers);

    const userHistoryHash : HistoryHashType = {
        type:'mainUserTable'
    }


    return (
  <>
      <BreadCrumb title={t('menu:wizard')} pageTitle={t('menu:main')} />
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0">{t('wizard:wizard')}</h4>
                    </CardHeader>
                    <CardBody className="form-steps">
                        <form className="vertical-navs-step">
                            <Row className="gy-5">
                                <Col lg={3}>
                                    <Nav
                                        className="flex-column custom-nav nav-pills"
                                    >
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={
                                                    (classnames({
                                                        active: activeTab === 1,
                                                        done: (activeTab > 1 || (passedSteps.indexOf(1)>=0) ),
                                                        info: infoSteps.indexOf(1)>=0
                                                    }))
                                                }
                                                onClick={() => {
                                                    toggleTab(1);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 1
                              </span>
                                                {t('project:projects')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={
                                                    (classnames({
                                                        active: activeTab === 2,
                                                        done: (activeTab > 2 || (passedSteps.indexOf(2)>=0)),
                                                        info: infoSteps.indexOf(2)>=0
                                                    }))
                                                }
                                                onClick={() => {
                                                    toggleTab(2);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 2
                              </span>
                                                {t('connector:services')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({
                                                    active: activeTab === 3,
                                                    done: (activeTab > 3 || (passedSteps.indexOf(3)>=0)),
                                                    info: infoSteps.indexOf(3)>=0
                                                })}
                                                onClick={() => {
                                                    toggleTab(3);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 3
                              </span>
                                                {t('channel:channels')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({
                                                    active: activeTab === 4,
                                                    done: (activeTab >4 || (passedSteps.indexOf(4)>=0)),
                                                    info: infoSteps.indexOf(4)>=0
                                                })}
                                                onClick={() => {
                                                    toggleTab(4);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 4
                              </span>
                                                {t('counter:counters')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({
                                                    active: activeTab === 5,
                                                    done: (activeTab >5 || (passedSteps.indexOf(5)>=0) ),
                                                    info: infoSteps.indexOf(5)>=0
                                                })}
                                                onClick={() => {
                                                    toggleTab(5);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 5
                              </span>
                                                {t('target:targets')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({
                                                    active: activeTab === 6,
                                                    done: (activeTab >6 || (passedSteps.indexOf(6)>=0)),
                                                    info: infoSteps.indexOf(6)>=0
                                                })}
                                                onClick={() => {
                                                    toggleTab(6);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 6
                              </span>
                                                {t('user:users')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({
                                                    active: activeTab === 7,
                                                    done: (activeTab >7 || (passedSteps.indexOf(7)>=0)),
                                                    info: infoSteps.indexOf(7)>=0
                                                })}
                                                onClick={() => {
                                                    toggleTab(7);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 7
                              </span>
                                                {t('notification:notifications')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({
                                                    active: activeTab === 8,
                                                    done: (activeTab >7 || (passedSteps.indexOf(8)>=0) || allDone),
                                                    info: infoSteps.indexOf(8)>=0
                                                })}
                                                onClick={() => {
                                                    toggleTab(8);
                                                }}
                                            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 8
                              </span>
                                                {t('common:done')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col lg={9}>
                                    <div className="px-lg-4">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={1}>
                                                <div>
                                                    <h5>{t('project:projects')} <span className="text-muted">- {t('wizard:step1Description')}</span></h5>
                                                    <p className="text-muted">{t('wizard:step1Text1')}.</p>
                                                    <p className="text-muted">{t('wizard:step1Text2')} <a type="button" className="btn btn-light btn-icon waves-effect waves-light ms-2" onClick={handleSettingsIconClick}><i className="ri-settings-3-line"/></a></p>


                                                </div>

                                                <div>
                                                    <Row className="g-4 mb-3">
                                                        <div className="col-sm-auto">
                                                            <div>
                                                                <Button variant="secondary" onClick={(event)=>ContentProjectOpenModal(event,dispatch)} className="btn btn-success">
                                                                    <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="g-3">
                                                        {(projects || []).map((item: ContentProjectType, key: number) => (
                                                            <Col xxl={4} sm={6} key={'contentProjectInit_'+item.uuid}>
                                                                <ProjectListItem
                                                                    item={item}
                                                                    onEdit={(event)=>ContentProjectOpenModal(event,dispatch)}
                                                                    onRemove = {(event)=>ContentProjectOpenRemoveModal(event,dispatch)}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>

                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>
                                                        {t('connector:services')}
                                                    </button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={2}>
                                                <div>
                                                    <h5>{t('connector:services')} <span className="text-muted">- {t('wizard:step2Description')}</span></h5>
                                                    <p className="text-muted">
                                                        {t('wizard:step2Text1')}
                                                    </p>
                                                    <p className="text-muted">{t('wizard:step2Text2')} <a type="button" className="btn btn-light btn-icon waves-effect waves-light ms-2" onClick={handleServicesIconClick}><i className="ri-share-line"/></a></p>
                                                </div>

                                                <div>
                                                    <Row className="g-4 mb-3">
                                                        <div className="col-sm-auto">
                                                            <div>
                                                                <Button variant="secondary" onClick={(event)=>ContentConnectorOpenModal(event,dispatch)} className="btn btn-success">
                                                                    <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="g-3">
                                                        {(connectors || []).map((item: ContentConnectorType, key: number) => (
                                                            <Col xxl={4} sm={6} key={'contentConnector_'+item.uuid}>
                                                                <ConnectorListItem

                                                                    item={item}
                                                                    onEdit={(event)=>ContentConnectorOpenModal(event,dispatch)}
                                                                    onRemove = {(event)=>ContentConnectorOpenRemoveModal(event,dispatch)}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>
                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"/>{" "}
                                                        {t('project:projects')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-label right ms-auto nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>
                                                        {t('channel:channels')}
                                                    </button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={3}>
                                                <div>
                                                    <h5>{t('channel:channels')} <span className="text-muted">- {t('wizard:step3Description')}</span></h5>
                                                    <p className="text-muted">{t('wizard:step3Text1')}</p>
                                                    <p className="text-muted">{t('wizard:step3Text2')} <a type="button" className="btn btn-light btn-icon waves-effect waves-light ms-2" onClick={handleServicesIconClick}><i className="ri-share-line"/></a></p>
                                                </div>

                                                <div>
                                                    <Row className="g-4 mb-3">
                                                        <div className="col-sm-auto">
                                                            <div>
                                                                <Button variant="secondary" onClick={(event)=>ContentPeerChannelOpenModal(event,dispatch)} className="btn btn-success">
                                                                    <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="g-3">
                                                        {(channels || []).map((item: ContentPeerChannelType, key: number) => (
                                                            <Col xxl={4} sm={6} key={'contentConnector_'+item.uuid}>
                                                                <ChannelListItem

                                                                    item={item}
                                                                    onEdit={(event)=>ContentPeerChannelOpenModal(event,dispatch)}
                                                                    onRemove = {(event)=>ContentPeerChannelOpenRemoveModal(event,dispatch)}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>


                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"/>{" "}
                                                        {t('connector:services')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-label right ms-auto nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>{" "}
                                                        {t('counter:counters')}
                                                    </button>
                                                </div>
                                            </TabPane>


                                            <TabPane tabId={4}>
                                                <div>
                                                    <h5>{t('counter:counters')} <span className="text-muted">- {t('wizard:step4Description')}</span></h5>
                                                    <p className="text-muted">{t('wizard:step4Text1')}</p>
                                                    <p className="text-muted">{t('wizard:step4Text2')} <a type="button" className="btn btn-light btn-icon waves-effect waves-light ms-2" onClick={handleServicesIconClick}><i className="ri-share-line"/></a></p>

                                                </div>

                                                <div>
                                                    <Row className="g-4 mb-3">
                                                        <div className="col-sm-auto">
                                                            <div>
                                                                <Button variant="secondary" onClick={(event)=>ContentStatCounterOpenModal(event,dispatch)} className="btn btn-success">
                                                                    <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="g-3">
                                                        {(counters || []).map((item: ContentStatCounterType, key: number) => (
                                                            <Col xxl={4} sm={6} key={'contentConnector_'+item.uuid}>
                                                                <CounterListItem

                                                                    item={item}
                                                                    onEdit={(event)=>ContentStatCounterOpenModal(event,dispatch)}
                                                                    onRemove = {(event)=>ContentStatCounterOpenRemoveModal(event,dispatch)}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>

                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"/>{" "}
                                                        {t('channel:channels')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-label right ms-auto nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>{" "}
                                                        {t('target:targets')}
                                                    </button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={5}>
                                                <div>
                                                    <h5>{t('target:targets')} <span className="text-muted">- {t('wizard:step5Description')}</span></h5>
                                                    <p className="text-muted">{t('wizard:step5Text1')}</p>
                                                    <p className="text-muted">{t('wizard:step5Text2')} <a type="button" className="btn btn-light btn-icon waves-effect waves-light ms-2" onClick={handleTargetsIconClick}><i className="ri-pencil-ruler-2-line"/></a></p>
                                                </div>

                                                <div>
                                                    <Row className="g-4 mb-3">
                                                        <div className="col-sm-auto">
                                                            <div>
                                                                <Button variant="secondary" onClick={(event)=>ContentPeerTargetOpenModal(event,dispatch)} className="btn btn-success">
                                                                    <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row className="g-3">
                                                        {(targets || []).map((item: ContentPeerTargetType, key: number) => (
                                                            <Col xxl={4} sm={6} key={'contentConnector_'+item.uuid}>
                                                                <TargetListItem

                                                                    item={item}
                                                                    actionHandlers={{
                                                                        onEdit:(event)=>ContentPeerTargetOpenModal(event,dispatch),
                                                                        onRemove : (event)=>ContentPeerTargetOpenRemoveModal(event,dispatch)}}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>



                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"/>{" "}
                                                        {t('counter:counters')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-label right ms-auto nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>{" "}
                                                        {t('user:users')}
                                                    </button>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={6}>
                                                <div>
                                                    <h5>{t('user:users')} <span className="text-muted">- {t('wizard:step6Description')}</span></h5>

                                                    <div dangerouslySetInnerHTML={{__html: t('wizard:step6Text1')}} />

                                                    <p className="text-muted">{t('wizard:step6Text2')} <a type="button" className="btn btn-light btn-icon waves-effect waves-light ms-2" onClick={handleSettingsIconClick}><i className="ri-settings-3-line"/></a></p>
                                                </div>


                                                <div>
                                                    <Row className="g-4 mb-3">
                                                        <div className="col-sm-auto">
                                                            <div>
                                                                <Button variant="secondary" onClick={(event)=>mainUserOpenModal(event,dispatch)} className="btn btn-success">
                                                                    <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row >
                                                        <Col>
                                                        <TableList
                                                            columns={userColumns}
                                                            onRowClick={onUserTableRowClick}
                                                            data={mainUser.items}
                                                            historyHash={userHistoryHash}
                                                            key={'mainUserTable'}/>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"/>{" "}
                                                        {t('target:targets')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-label right ms-auto nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>{" "}
                                                        {t('notification:notifications')}
                                                    </button>
                                                </div>
                                            </TabPane>


                                            <TabPane tabId={7}>
                                                <div>
                                                    <h5>{t('notification:notifications')} <span className="text-muted">- {t('wizard:step7Description')}</span></h5>

                                                    <p className="text-muted">{t('wizard:step7Text2')} <a type="button" className="btn btn-light btn-icon waves-effect waves-light ms-2" onClick={handleSettingsIconClick}><i className="ri-settings-3-line"/></a></p>
                                                </div>
                                                <div>
                                                    <Row className="g-4 mb-3">
                                                        <div>

                                                            {currentUser && currentUser.subscriptions &&
                                                            <UserNotificationsChange items={(currentUser.subscriptions || [])}/>
                                                            }

                                                        </div>
                                                    </Row>
                                                </div>


                                                <div className="d-flex align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-label previestab"
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"/>{" "}
                                                        {t('user:users')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-label right ms-auto nexttab"
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                        }}
                                                    >
                                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"/>{" "}
                                                        {t('common:done')}
                                                    </button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId={8}>
                                                <div className="text-center pt-4 pb-2">
                                                    <div className="mb-4">
                                                        <LordIcon
                                                            src="https://cdn.lordicon.com/lupuorrc.json"
                                                            trigger="loop"
                                                            colors={{primary: '#36c5f1',secondary:'#32b67d'}}
                                                            size={150}
                                                        />

                                                    </div>
                                                    <h5>{t('wizard:congratulations')}</h5>
                                                    <p className="text-muted">
                                                        {t('wizard:congratulationsText')}
                                                    </p>
                                                    <div className="col-auto">
                                                        <a href={route.CONTENT_ACTIVITY_PLAN} className="btn btn-primary"><i className="ri-pages-line align-middle me-1"/> {t('peerActivity:activityPlan')}</a>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Col>

        </Row>
            <RightModalProjects/>
            <RightModalConnectors/>
            <RightModalChannels/>
            <RightModalCounters/>
            <RightModalTargets/>
            <RightModalUsers/>

          <RemoveModal
              onRemove={(event)=>ContentProjectOnRemove(event, dispatch)}
              handleClose={()=>ContentProjectHideRemoveModal(dispatch)}
              removeItem={contentProjects.selectedItem}
              isOpen={contentProjects.removeModalShow}
              modalTitle={contentProjects.modalEditTitle}/>

            <RemoveModal
                onRemove={(event)=>ContentConnectorOnRemove(event, dispatch)}
                handleClose={()=>ContentConnectorHideRemoveModal(dispatch)}
                removeItem={contentConnectors.selectedItem}
                isOpen={contentConnectors.removeModalShow}
                modalTitle={contentConnectors.modalEditTitle}/>

          <RemoveModal
              onRemove={(event)=>ContentPeerChannelOnRemove(event, dispatch)}
              handleClose={()=>ContentPeerChannelHideRemoveModal(dispatch)}
              removeItem={contentPeerChannels.selectedItem}
              isOpen={contentPeerChannels.removeModalShow}
              modalTitle={contentPeerChannels.modalEditTitle}/>

          <RemoveModal
              onRemove={(event)=>ContentStatCounterOnRemove(event, dispatch)}
              handleClose={()=>ContentStatCounterHideRemoveModal(dispatch)}
              removeItem={contentStatCounters.selectedItem}
              isOpen={contentStatCounters.removeModalShow}
              modalTitle={contentStatCounters.modalEditTitle}/>

          <RemoveModal
              onRemove={(event)=>ContentPeerTargetOnRemove(event,dispatch)}
              handleClose={()=>ContentPeerTargetHideRemoveModal(dispatch)}
              removeItem={contentPeerTarget.selectedItem}
              isOpen={contentPeerTarget.removeModalShow}
              modalTitle={contentPeerTarget.modalEditTitle}/>

          <RemoveModal
              onRemove={(event)=>mainUserOnRemove(event,dispatch, mainCabinet.currentCabinet)}
              handleClose={()=>mainUserHideRemoveModal(dispatch)}
              removeItem={mainUser.selectedItem}
              isOpen={mainUser.removeModalShow}
              modalTitle={mainUser.modalEditTitle}/>
  </>
    );
}

export default MainWizardInit;