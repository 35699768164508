import React from "react";
import * as route from "../../../api/routes";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {getHandlerIconByCode} from "../../../helpers/functions";

interface ItemProps {
    item: SaleIntegrationType;
    link?: string;
    onClick?: (item:SaleIntegrationType)=>void;
}
const SaleIntegrationItemLine = ({ item, link, onClick }: ItemProps) => {

    const { t } = useTranslation(['common']);

    const handleClick = (event:any, saleIntegration:SaleIntegrationType) => {

        event.preventDefault();
        event.stopPropagation();

        if (link !== undefined && link !== null){
            window.location.href=link;
        } else {
            if (onClick !== undefined){
                onClick(saleIntegration);
            } else {
                window.location.href = route.SALE_INTEGRATIONS_LIST;
            }
        }
    }

    return(
        <>
            <div className="d-flex flex-shrink-0 mw-0 overflowInitial">
                <div className="avatar-xs me-2 d-flex flex-shrink-0 position-relative overflowInitial">
                    {(item?.handler?.code && getHandlerIconByCode(item?.handler?.code) !== null) ?
                        <img src={getHandlerIconByCode(item.handler.code)} className="rounded-circle img-fluid" alt=""/>
                    :
                        <span className={"avatar-title rounded-circle"}>{item.name[0]}</span>
                    }
                </div>
                <div className="flex-1 justify-content-center flex-column d-flex mw-0 me-1">
                    <span className="text-truncate">
                         {item.name.substring(0, 23)}{item.name.length >= 23 && '...'}
                    </span>
                </div>
                <div className="flex-shrink-1 justify-content-center align-items-center flex-column d-flex">
                    <a onClick={(e)=>handleClick(e, item)} className={"p-0 m-0 text-muted d-flex font-weight-normal"}>
                        <i className={"ri-share-box-line"}/>
                    </a>
                </div>
            </div>
        </>
    )
}

export default SaleIntegrationItemLine;