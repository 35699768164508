import {SaleProductParamActionTypes, SaleProductParamPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleProductParamApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleProductParamActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleProductParamApiResponseError = (actionType: string, error: string) => ({
  type: SaleProductParamActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleProductParams = (data: MainMetaType) => ({
  type: SaleProductParamActionTypes.GET_PRODUCT_PARAMS,
  payload: data
});

export const showRightModalSaleProductParam= (data: SaleProductParamPayload) => ({
  type: SaleProductParamActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleProductParam= () => ({
  type: SaleProductParamActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleProductParam = (data: any) => ({
  type: SaleProductParamActionTypes.UPDATE_PRODUCT_PARAM,
  payload: data,
});

export const editDataSaleProductParam = (data: SaleProductParamPayload) => ({
  type: SaleProductParamActionTypes.EDIT_PRODUCT_PARAM,
  payload: data,
});

export const removeSaleProductParamModalShow = (data: SaleProductParamPayload) => ({
  type: SaleProductParamActionTypes.REMOVE_PRODUCT_PARAM_MODAL_SHOW,
  payload: data,
});

export const removeSaleProductParamModalHide = () => ({
  type: SaleProductParamActionTypes.REMOVE_PRODUCT_PARAM_MODAL_HIDE,
});

export const onRemoveSaleProductParam = (data: SaleProductParamPayload) => ({
  type: SaleProductParamActionTypes.REMOVE_PRODUCT_PARAM,
  payload: data,
});