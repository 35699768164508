import React, {useEffect, useState} from "react";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {PeerActivityModeCode} from "../../../redux/Content/PeerActivityMode/types";
import {useRedux} from "../../../hooks";
import {ContentConnectorHandlerType} from "../../../redux/Content/ConnectorHandler/types";
import ListButtonSmallItem from "./ListButtonSmallItem";
import {showRightModalSupportTicket} from "../../../redux/Support/Ticket/actions";
import {NewTicketItem} from "../../../redux/Support/Ticket/reducer";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";

interface ItemProps {
    mode : string;
    link : string;
}
const AcceptedHandlerByMode = ({ mode, link }: ItemProps) => {
    const { t, i18n } = useTranslation();
    const { dispatch, useAppSelector } = useRedux();

    const [connectorHandlers, setConnectorHandlers] = useState<ContentConnectorHandlerType[]>([]);
    const [isShowAddNotification, setShowAddNotification] = useState<boolean>(false);


    const {
        contentConnectorHandler,
        isConnectorHandlerFetched
    } = useAppSelector(state => ({
        contentConnectorHandler: state.ContentConnectorHandler,
        isConnectorHandlerFetched: state.ContentConnectorHandler.isContentConnectorFetched
    }));

    useEffect(()=>{

        if(contentConnectorHandler?.items !== undefined && contentConnectorHandler?.items !== null && contentConnectorHandler?.items.length>0){
            setConnectorHandlers([...contentConnectorHandler.items]);
        } else {
            setConnectorHandlers([]);
        }

    },[contentConnectorHandler?.items])

    const handlerOnShowNotification = () => {
        setShowAddNotification(!isShowAddNotification);
    }

    const handleNewSupportTicket = () => {
        dispatch(showRightModalSupportTicket({data:NewTicketItem, modalEditTitle: 'newSupportTicket'}));
    }

    const renderConnectorHandlers = (handlers: ContentConnectorHandlerType[]): JSX.Element[] => handlers.map((item, key) => {
        if (
            (mode === PeerActivityModeCode.SHORTS && !item.canAddShort)
            ||
            (mode === PeerActivityModeCode.STORY && !item.canAddStory)
            ||
            (mode === PeerActivityModeCode.ACTIVITY && !item.canAddPost)
            ||
            (mode === PeerActivityModeCode.BIO && !item.canChangeBio)
            ||
            item.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS
        ) {
            // eslint-disable-next-line
            return;
        }
        return (
            <div key={'acceptedHandler_'+key}>
                <ListButtonSmallItem item={item} isBlank={true} link={link}/>
            </div>
        );
    });

    return (
        <>
            <div className="m-0 alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-3" role="alert">
                <div className="d-flex flex-column">
                    <p className="mb-1">{t('connector:supportedHandlers')}:</p>
                    <div className="d-flex flex-row flex-wrap">
                        {renderConnectorHandlers(connectorHandlers)}
                        <div key={'acceptedHandler_addNew'} className="addNewHandlerSmallButton">
                            <button type="button" onClick={handlerOnShowNotification}
                               className="btn btn-sm btn-outline-success btn-icon waves-effect waves-light"><i className="ri-add-line"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isShowAddNotification &&
            <div className="m-0 mt-2 alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-3" role="alert" data-aos="flip-left">
                <div className="d-flex flex-column">
                        <span dangerouslySetInnerHTML={{__html: t('connector:addNewHandlers')}}/>

                    <div className="d-flex flex-row justify-content-center mt-2">
                        <div className="d-flex d-inline-flex">
                        <button type="button" className="btn btn-sm btn-success btn-label waves-effect waves-light" onClick={handleNewSupportTicket}><i
                            className="ri-check-double-line label-icon align-middle fs-16 me-2"/> {t('common:myIdeas')}
                        </button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default AcceptedHandlerByMode;
