import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {
    Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';
import { v4 as uuidv4 } from 'uuid';
import {
    Formik,
    Form,
    Field
} from 'formik';
import ButtonSpinner from "../../../components/ButtonSpinner";
import {groupIntegrationModalHide, updateGroupIntegration} from "../../../redux/Sale/Product/actions";
import {SaleStoreType} from "../../../redux/Sale/Store/types";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {ProductType} from "../../../redux/Sale/Product/types";
import * as route from "../../../api/routes";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {SaleGroupIntegrationType, SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import ChannelItemLine from "../../Content/PeerChannel/channelItemLine";
import InputSwitch from "../../../components/InputSwitch";
import InputSelect from "../../../components/InputSelect";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {ProductIntegrationParamType} from "../../../redux/Sale/ProductIntegration/types";
import {getSaleProductsIntegrationParamFromRemote} from "../../../redux/Sale/Integration/actions";
import Loader from "../../../components/Loader";
import {SaleProductGroupIntegrationValidatorSchema} from "./groupIntegrationValidator";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {MainMetaType} from "../../../redux/Main/common";
import {SaleIntegrationHandlerCodes, SaleIntegrationHandlerTypes} from "../../../redux/Sale/IntegrationHandler/types";
import SaleIntegrationItemLine from "../Integration/SaleIntegrationItemLine";


interface InputProps {
    stores: SaleStoreType[],
    currencies: CurrencyType[],
    priceTypes: PriceTypeType[],
    channels: ContentPeerChannelType[],
    products: ProductType[],
    historyHash: HistoryHashType,
    integrations?: SaleIntegrationType[]
}


const RightModalGroupIntegration = ({stores, currencies, priceTypes, channels, products, historyHash, integrations}:InputProps) => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(true);
    const [openChannels, setOpenChannels] = useState<string[]>([]);
    const [currentItems, setCurrentItems] = useState<SaleGroupIntegrationType[]>([]);
    const [saleProductIntegrationParamsVk, setSaleProductIntegrationParamsVk] = useState<ProductIntegrationParamType[]>([]);

    const {
        saleIntegrationState,
        isLoadingProductIntegrationParams,
        saleProduct,
        isSending
    } = useAppSelector(state => ({
        saleIntegrationState: state.SaleIntegration,
        isLoadingProductIntegrationParams: state.SaleIntegration.isLoadingProductIntegrationParams,
        saleProduct: state.SaleProduct,
        isSending: state.SaleProduct.isSending
    }));

    const [formValues, setFormValues] = useState({});

    const handleClose = () => dispatch(groupIntegrationModalHide());

    const toggleChannel = (uuid) => {

        let enabled=false;
        const itemIndex = openChannels.indexOf(uuid);

        if (itemIndex > -1) {
            openChannels.splice(itemIndex, 1);

        } else {
            openChannels.push(uuid);
            enabled=true
        }

        (currentItems || []).map((item:SaleGroupIntegrationType, key: number)=>{
            if ((uuid === item?.channel?.uuid) || (uuid === item?.integration?.uuid)) {
                item.enabled = enabled;
            }
            return item;
        });

        setOpenChannels([...openChannels]);
        setCurrentItems([...currentItems]);
    };

    useEffect(()=>{

        if ((openChannels || []).length>0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    },[openChannels])

    useEffect(()=>{

        if ((saleProductIntegrationParamsVk || []).length<1){
            const vkChannel = (channels || []).filter((channel:ContentPeerChannelType)=>{return channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_VK && channel?.connector?.isConnected}).shift();

            if (vkChannel?.uuid){
                dispatch(getSaleProductsIntegrationParamFromRemote({connector:vkChannel.connector}));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, channels]);

    useEffect(()=> {

        if (saleIntegrationState?.productIntegrationParams && (saleIntegrationState.productIntegrationParams || []).length>0){

            let selectedParams = [];

            (saleIntegrationState.productIntegrationParams || []).map((item:ProductIntegrationParamType)=>{
                if (item.remoteCategoryId && item.remoteCategoryName){
                    selectedParams.push({id: item.remoteCategoryId, name: item.remoteCategoryName});
                }
                return item;
            });

            setSaleProductIntegrationParamsVk(selectedParams);
        } else {
            setSaleProductIntegrationParamsVk([]);
        }

    },[saleIntegrationState?.productIntegrationParams]);

    useEffect(()=>{

        let _currentItems: SaleGroupIntegrationType[] = [];
        let _productUuids: string[]=[];
        let _formValues = {};

        let defaultCurrency = (currencies || []).filter((currency: CurrencyType)=>{return currency.isDefault}).shift();
        let basePriceType = (priceTypes || []).filter((priceType: PriceTypeType)=>{return priceType.isBase}).shift();

        (products || []).map((product:ProductType)=>{
            _productUuids.push(product.uuid);
            return product;
        });

        (channels || []).map((channel:ContentPeerChannelType)=>{
            if (channel.connector.handler.canECommerce && channel.enabled && channel.isManaged) {

                let groupIntegrationItem: SaleGroupIntegrationType = {
                    uuid: uuidv4(),
                    channel: channel,
                    enabled: false,
                    currency: defaultCurrency,
                    priceType: basePriceType
                }

                if (_productUuids.length>0){
                    groupIntegrationItem = {
                        ...groupIntegrationItem,
                        productUuids: _productUuids
                    }
                }

                _currentItems.push(groupIntegrationItem);

                _formValues['store_' + groupIntegrationItem.uuid] = groupIntegrationItem.store;
                _formValues['priceType_' + groupIntegrationItem.uuid] = groupIntegrationItem.priceType;
                _formValues['currency_' + groupIntegrationItem.uuid] = groupIntegrationItem.currency;

                if (channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_VK) {
                    _formValues['productIntegrationParam_' + groupIntegrationItem.uuid] = '';
                }
            }
            return channel;
        });

        (integrations || []).map((integration:SaleIntegrationType)=>{
            if (integration.handler.type === SaleIntegrationHandlerTypes.OUTBOUND &&
                (
                    integration.handler.code === SaleIntegrationHandlerCodes.WIDGET_WEB
                    ||
                    integration.handler.code === SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                )
            ) {

                let groupIntegrationItem: SaleGroupIntegrationType = {
                    uuid: uuidv4(),
                    integration: integration,
                    enabled: false,
                    currency: defaultCurrency,
                    priceType: basePriceType
                }

                if (_productUuids.length>0){
                    groupIntegrationItem = {
                        ...groupIntegrationItem,
                        productUuids: _productUuids
                    }
                }

                _currentItems.push(groupIntegrationItem);

                _formValues['store_' + groupIntegrationItem.uuid] = groupIntegrationItem.store;
                _formValues['priceType_' + groupIntegrationItem.uuid] = groupIntegrationItem.priceType;
                _formValues['currency_' + groupIntegrationItem.uuid] = groupIntegrationItem.currency;

            }
            return integration;
        });

        setCurrentItems(_currentItems);
        setFormValues({..._formValues});
        setOpenChannels([]);

    }, [channels, products, stores, currencies, priceTypes, integrations]);

    const onDataSubmit = (formData:any) => {

        let mainMeta : MainMetaType = {}

        if (historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true) {
            mainMeta = {
                maxResults: historyHash.maxResults ? historyHash.maxResults : PAGINATION_DEFAULT_ITEMS_PER_PAGE,
                searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length > 0 && historyHash.searchPhrase
            }
        }

            let activeItems = (currentItems || []).filter((item: SaleGroupIntegrationType) => {
                return item.enabled
            });

            (activeItems || []).map((item: SaleGroupIntegrationType) => {
                if (formData['currency_' + item.uuid] !== undefined) {
                    if (formData['currency_' + item.uuid]['value'] !== undefined) {
                        item.currency = {uuid: formData['currency_' + item.uuid]['value']};
                    }
                }
                if (formData['store_' + item.uuid] !== undefined) {
                    if (formData['store_' + item.uuid] !== null && formData['store_' + item.uuid]['value'] !== undefined) {
                        item.store = {uuid: formData['store_' + item.uuid]['value']};
                    }
                }
                if (formData['priceType_' + item.uuid] !== undefined) {
                    if (formData['priceType_' + item.uuid]['value'] !== undefined) {
                        item.priceType = {uuid: formData['priceType_' + item.uuid]['value']};
                    }
                }
                if (formData['productIntegrationParam_' + item.uuid] !== undefined) {
                    if (formData['productIntegrationParam_' + item.uuid]['value'] !== undefined) {
                        item.param = {
                            remoteCategoryId: formData['productIntegrationParam_' + item.uuid]['value'],
                            remoteCategoryName: formData['productIntegrationParam_' + item.uuid]['label']
                        };
                    }
                }

                return item;
            });

            if ((activeItems || []).length > 0) {
                dispatch(updateGroupIntegration({items:activeItems, meta:mainMeta}));
            }

    };

    const onChange = () => {}

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleProduct.rightModalGroupIntegration}
                    id="saleGroupIntegrationEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleProductGroupIntegrationValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {

        //                console.dir(errors);

                        return (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('integration:integrations')}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                                    <Row>
                                        <div className="mb-3">
                                            <div className="d-flex flex-row flex-nowrap align-items-center mb-2">
                                                <div className="d-flex flex-row flex-grow-1 align-items-center">
                                                    <Label className="form-label mb-0" htmlFor="form-channels-input">{t('channel:channels')}</Label>
                                                </div>
                                                <div className="d-flex flex-row flex-grow-0">
                                                    <a type="button" target="_blank" rel="noreferrer"
                                                       href={route.CONTENT_CHANNELS_LIST}
                                                       className="btn btn-sm btn-outline-primary btn-icon waves-effect waves-light"><i className="ri-add-line"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <Accordion open={openChannels}     {...{
                                                toggle: (accordionItem) => {

                                                    let saleIntegrationItem = (currentItems || []).filter((currentItem:SaleGroupIntegrationType)=>{
                                                        return currentItem?.channel?.uuid === accordionItem || currentItem?.integration?.uuid === accordionItem
                                                    }).shift();

                                                    setFieldValue(saleIntegrationItem?.uuid + '_enabled', (openChannels || []).filter((channelUuid)=>{return channelUuid===accordionItem}).length===0);
                                                    toggleChannel(accordionItem)
                                                }
                                            }}
                                                       className="accordion-icon-none"
                                            >
                                                {currentItems.map((item: SaleGroupIntegrationType, key: number) => (
                                                    <AccordionItem key={(item?.channel?.uuid?item.channel.uuid:item.integration.uuid)}>
                                                        <AccordionHeader targetId={(item?.channel?.uuid?item.channel.uuid:item.integration.uuid)}>
                                                            <div className="d-flex flex-row flex-nowrap flex-grow-1 mw-0">
                                                                <div className="d-flex flex-row flex-grow-1 mw-0">
                                                                    {item?.channel?.uuid
                                                                        ?
                                                                        <ChannelItemLine item={item.channel} onClick={()=>{}}/>
                                                                        :
                                                                        <SaleIntegrationItemLine item={item.integration} onClick={()=>{}}/>
                                                                    }
                                                                </div>
                                                                <InputSwitch id={'id'} value={item.enabled} onChange={onChange}
                                                                             className="ms-3 form-switch-md d-flex flex-column justify-content-center align-items-end"/>
                                                            </div>
                                                        </AccordionHeader>
                                                        <AccordionBody accordionId={(item?.channel?.uuid?item.channel.uuid:item.integration.uuid)}>
                                                            <Row>
                                                                <div className={ "mb-3"+ (touched['priceType_'+item.uuid] ? " was-validated" : '')}>
                                                                    <Label className="form-label" htmlFor={"choices-priceType-input_"+item.uuid}>{t('price:priceType')}</Label>
                                                                    <Field
                                                                        id={"choices-priceType-input_"+item.uuid}
                                                                        name={'priceType_'+item.uuid}
                                                                        placeholder={t('price:choosePriceType')}
                                                                        component={InputSelect}
                                                                        value={getFieldProps('priceType_'+item.uuid).value}
                                                                        onChange={(value: any) => {
                                                                            setFieldValue("priceType_"+item.uuid,value);
                                                                        }}
                                                                        items={priceTypes || []}
                                                                        className={"flex-grow-1 "+ (errors['priceType_'+item.uuid] && touched['priceType_'+item.uuid] ? 'form-control is-invalid' : '')}
                                                                    />
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div className={ "mb-3"+ (touched['currency_'+item.uuid] ? " was-validated" : '')}>
                                                                    <Label className="form-label" htmlFor={"choices-currency-input_"+item.uuid}>{t('currency:currency')}</Label>
                                                                    <Field
                                                                        id={"choices-currency-input_"+item.uuid}
                                                                        name={'currency_'+item.uuid}
                                                                        placeholder={t('currency:chooseCurrency')}
                                                                        component={InputSelect}
                                                                        value={getFieldProps('currency_'+item.uuid).value}
                                                                        onChange={(value: any) => {
                                                                            setFieldValue("currency_"+item.uuid,value);
                                                                        }}
                                                                        items={currencies || []}
                                                                        className={"flex-grow-1 "+ (errors['currency_'+item.uuid] && touched['currency_'+item.uuid] ? 'form-control is-invalid' : '')}
                                                                    />
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div className={ "mb-3"+ (touched['store_'+item.uuid] ? " was-validated" : '')}>
                                                                    <Label className="form-label" htmlFor={"choices-store-input"+item.uuid}>{t('store:store')}</Label>
                                                                    <Field
                                                                        id={"choices-store-input"+item.uuid}
                                                                        name={"store_"+item.uuid}
                                                                        placeholder={t('store:chooseStore')}
                                                                        component={InputSelect}
                                                                        value={getFieldProps('store_'+item.uuid).value}
                                                                        onChange={(value: any) => {
                                                                            setFieldValue("store_"+item.uuid,value);
                                                                        }}
                                                                        isClearable={true}
                                                                        items={stores || []}
                                                                        className={"flex-grow-1 "+ (errors['store_'+item.uuid] && touched['store_'+item.uuid] ? 'form-control is-invalid' : '')}
                                                                    />
                                                                </div>
                                                            </Row>
                                                            {item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_VK &&
                                                                <>
                                                                    {isLoadingProductIntegrationParams ?
                                                                        <Loader/>
                                                                        :
                                                                        <Row>
                                                                            <div className={ "mb-3"+ (touched['productIntegrationParam_'+item.uuid] ? " was-validated" : '')}>
                                                                                <Label className="form-label" htmlFor={"productIntegrationParam_"+item.uuid}>{t('category:category')}</Label>
                                                                                <Field
                                                                                    id={"productIntegrationParam_"+item.uuid}
                                                                                    name={"productIntegrationParam_"+item.uuid}
                                                                                    placeholder={t('category:chooseCategory')}
                                                                                    component={InputSelect}
                                                                                    value={getFieldProps('productIntegrationParam_'+item.uuid).value}
                                                                                    onChange={(value: any) => {
                                                                                        setFieldValue("productIntegrationParam_"+item.uuid,value);
                                                                                    }}
                                                                                    items={saleProductIntegrationParamsVk || []}
                                                                                    className={"flex-grow-1 "+ (errors['productIntegrationParam_'+item.uuid] && touched['productIntegrationParam_'+item.uuid] ? 'form-control is-invalid' : '')}
                                                                                />
                                                                            </div>
                                                                        </Row>

                                                                    }
                                                                </>
                                                            }
                                                        </AccordionBody>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </Row>
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending || disabled}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:apply')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                    }
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModalGroupIntegration;