import React, {useCallback, useEffect, useState} from "react";
import {Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink} from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";
import {useLocation} from "react-router-dom";

// hooks
import { useRedux } from "../../../hooks";

import {
    editDataSupportTicket,
    getSupportTickets,
    onRemoveSupportTicket,
    removeSupportTicketModalHide,
    removeSupportTicketModalShow,
    showRightModalSupportTicket,
    getSupportServiceTickets, getSaleProducts
} from "../../../redux/actions";

import {SupportTicketType, TicketStatus} from "../../../redux/Support/Ticket/types";
import {EntityActionsHandlersType} from "../../../redux/types";
import RemoveModal from "../../../components/RemoveForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {NewTicketItem} from "../../../redux/Support/Ticket/reducer";
import * as route from "../../../api/routes";
import classnames from "classnames";
import Loader from "../../../components/Loader";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {
    getHistoryHash,
    getIsNeedRefresh,
    removeIsNeedRefresh,
    setHistoryHash as setHistoryHashFunc
} from "../../../helpers/functions";
import {PlanOrderType} from "../../../redux/Sale/PlanOrder/types";
import TicketColumns from "./tableColumns";
import TableList from "../../../components/TableList";
import {MainMetaType} from "../../../redux/Main/common";
import {ProductStatus} from "../../../redux/Sale/Product/types";
import MainListFilter from "../../../components/MainListFilter";
import flatpickr from "flatpickr";

const SupportTicketIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const componentType = 'supportTicketType';


    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [searchPhrase, setSearchPhrase] = useState<string>('');

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults ? historyHash.maxResults : perPage,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection,
            searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length>0 && historyHash.searchPhrase
        }

        if (historyHash?.tab){
            switch (historyHash?.tab) {
                case 'Final':
                    mainMeta={
                        ...mainMeta,
                        status: TicketStatus.FINAL.status
                    }
                    break;
                case 'Opened':
                    mainMeta={
                        ...mainMeta,
                        status: TicketStatus.OPEN.status
                    }
                    break;
                case 'InProgress':
                    mainMeta={
                        ...mainMeta,
                        status: TicketStatus.INPROGRESS.status
                    }
                    break;
                case 'Pending':
                    mainMeta={
                        ...mainMeta,
                        status: TicketStatus.PENDING.status
                    }
                    break;
            }
        }


        if (isSupportService) {
            dispatch(getSupportServiceTickets(mainMeta));
        } else {
            dispatch(getSupportTickets(mainMeta));
        }
    }

    useEffect(() => {

        if (historyHash.tab !== undefined && historyHash.tab.length>0 && activeTab !== historyHash.tab){
            toggleTab(historyHash.tab);
        }

        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        loadItems();

// eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();

        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [tickets, setTickets] = useState<SupportTicketType[]>([]);
    const [activeTab, setActiveTab] = useState("All");
    const [isSupportService, setIsSupportService] = useState<boolean>(false);

    const {
        supportTicket,
        isLoading,
    } = useAppSelector(state => ({
        supportTicket: state.SupportTicket,
        isLoading: state.SupportTicket.isLoading,
    }));

    const location = useLocation();

    if (location.pathname === route.SUPPORT_SERVICE_TICKETS_LIST ) {
        if (!isSupportService) {
            setIsSupportService(true);
        }
    } else {
        if (isSupportService) {
            setIsSupportService(false);
        }
    }

    useEffect(() => {
        if (isSupportService) {
           loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,isSupportService]);

    useEffect(() => {
        if (supportTicket.isNeedReload) {
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,supportTicket.isNeedReload]);

    useEffect(() => {
        if ((supportTicket.items !== undefined) && (supportTicket.items.length>0)){
            if (activeTab !=="All"){
                updateTicketsByStatus(activeTab);
            } else {
                setTickets([...supportTicket.items]);
            }
        } else {
            setTickets([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportTicket?.items])

    const openRightModal = (event:any) => {

        if ((event !== undefined) && (event?.uuid.length>0)){
            dispatch(editDataSupportTicket({data:event, modalEditTitle: 'changeSupportTicket'}));
        } else {
            dispatch(showRightModalSupportTicket({data:NewTicketItem, modalEditTitle: 'newSupportTicket'}));
        }
    };

    const handleNewSupportTicket = () => {
        dispatch(showRightModalSupportTicket({data:NewTicketItem, modalEditTitle: 'newSupportTicket'}));
    }

    const onEntityRemoveModalShow = (event:any) => {
        if ((event !== undefined) && (event?.uuid.length>0)){
            dispatch(removeSupportTicketModalShow({data: event, modalEditTitle: 'deleteSupportTicket'}));
        }
    };

    const onEntityRemoveModalHide = () => dispatch(removeSupportTicketModalHide());

    const onEntityRemove = (event:any) => {
        if ((event !== undefined) && (event?.uuid.length>0)){
            dispatch(onRemoveSupportTicket({data: event, modalEditTitle: 'deleteSupportTicket'}));
        }
    };

    const onTableRowClick = (item:PlanOrderType) => {
        if (isSupportService){
            window.location.href = route.SUPPORT_SERVICE_TICKET_DETAIL.replace(/:uuid/g, item.uuid);
        } else {
            window.location.href = route.SUPPORT_TICKET_DETAIL.replace(/:uuid/g, item.uuid);
        }
    }

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onEdit: openRightModal,
        onRemove: onEntityRemoveModalShow,
        onDetail: onTableRowClick
    }

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            updateTicketsByStatus(tab);
        }
    };

    const toggleClickTab = (tab) => {
        if (activeTab !== tab) {
            if ( historyHash.tab !== tab) {
                historyHash.tab = tab;
                historyHash.page = 1;
                setHistoryHashFunc(historyHash);
            }
        }
    }

    const updateTicketsByStatus = (tab) => {

        let filteredTickets = supportTicket.items;

        if (tab === "Final") {
            filteredTickets = supportTicket.items.filter((item:SupportTicketType) => item.status === TicketStatus.FINAL.status);
        } else if ( tab === 'Opened') {
            filteredTickets = supportTicket.items.filter((item:SupportTicketType) => item.status !== TicketStatus.FINAL.status);
        } else if ( tab === 'InProgress') {
            filteredTickets = supportTicket.items.filter((item:SupportTicketType) => (item.status === TicketStatus.NEW.status || item.status === TicketStatus.INPROGRESS.status))
        } else if ( tab === 'Pending') {
            filteredTickets = supportTicket.items.filter((item:SupportTicketType) => (item.status === TicketStatus.PENDING.status))
        }
        setTickets([...filteredTickets]);
    }

    const columns = TicketColumns(EntityActionsHandlers);

    const handleSearchClick = (value:string) => {
        setSearchPhrase(value);
        if ( historyHash.searchPhrase !== value) {
            historyHash.searchPhrase = value;
            setHistoryHashFunc(historyHash);
        }
    }

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }

    if (getIsNeedRefresh()){
        removeIsNeedRefresh();
        loadItems();
    }

    return (
        <>
            {!isSupportService &&
            <BreadCrumb title={t('menu:supportTicket')} pageTitle={t('menu:main')} />
            }
            <Row>
                <Col lg={12}>
                    <Card id="ticketList">
                        <CardHeader className="card-header border-0">
                            <Row className="align-items-center gy-3">
                                <div className="col-sm">
                                    <h5 className="card-title mb-0">{isSupportService ? t('support:supportService'): t('support:helpCenter')}</h5>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="d-flex gap-1 flex-wrap">
                                        {!isSupportService &&
                                        <button type="button"
                                                onClick={handleNewSupportTicket}
                                                className="btn btn-primary btn-label waves-effect waves-light"><i
                                            className="ri-customer-service-2-fill label-icon align-middle fs-16 me-2"/>{t('support:newSupportTicket')}
                                        </button>
                                        }
                                    </div>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                            <MainListFilter
                                onClick={handleSearchClick}
                                value={searchPhrase}
                                onChange={onSearchInput}
                            />
                        </CardBody>
                        <CardBody className="pt-0">
                            <div>
                                <Nav
                                    className="nav-tabs nav-tabs-custom nav-success"
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === "All" },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab( "All");
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-customer-service-2-line me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('support:allTickets')}
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === "Opened" },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab("Opened");
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-sun-line me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('support:openedTickets')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === "InProgress" },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab("InProgress");
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-chat-smile-2-line me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('support:inProgressTickets')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === "Pending" },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab("Pending");
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-chat-smile-2-fill me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('support:pendingTickets')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === "Final" },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab("Final");
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-checkbox-circle-line me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('support:closedTickets')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                {!isLoading ?
                                    <TableList
                                        columns={columns}
                                        data={tickets || []}
                                        onRowClick={onTableRowClick}
                                        key={componentType}
                                        historyHash = {historyHash}
                                        paginationTotalRows={supportTicket?.meta?.totalCount || 0}
                                        paginationServer={true}
                                        isShowChangePageSize={true}
                                    />
                                 :
                                    <Loader />
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

                {/*<RightModal/>*/}
                <RemoveModal
                    onRemove={onEntityRemove}
                    handleClose={onEntityRemoveModalHide}
                    removeItem={supportTicket.selectedItem}
                    isOpen={supportTicket.removeModalShow}
                    modalTitle={supportTicket.modalEditTitle}/>
        </>
    );
};

export default SupportTicketIndex;


