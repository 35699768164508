import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import {Row, Col} from "reactstrap";
import {formatBytes} from "../helpers/functions";
import {MainFileImageTypes, MainFileVideoTypes, MainFileType} from "../redux/Main/File/types";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {showErrorNotification} from "../helpers/notifications";
import {MediaDimensionType, MediaTypeIcon} from "../data";
import '../i18/config';
import {useTranslation} from "react-i18next";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

interface InputFileProps {
  value: null | MainFileType[];
  uuid: string;
  placeholder: null | string;
  onChange: (value: MainFileType[]) => void;
  required: null | boolean;
  className: null | string;
  isMultiple: null | boolean;
  acceptedFiles?: string;
  acceptedDimensions?: MediaDimensionType;
  maxFiles?: number

  disabled?: boolean
}
const InputFile = ({ uuid, placeholder, value, onChange, required, className, isMultiple, acceptedFiles, acceptedDimensions, maxFiles, disabled = false}: InputFileProps) => {
    const { t, i18n } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState<MainFileType[]>(value || []);
    const [isPopupShow, setPopupShow] = useState(null);
    const [isShowDropZone, setShowDropZone] = useState<boolean>(true);
    const maxFileNameLen = 25;

    const isShowCaption = acceptedFiles || acceptedDimensions;

    useEffect(() => {
        if (value === undefined) {
            setSelectedFiles([]);
        } else {
            value.map(file =>
                Object.assign(file, {
                    formattedSize: formatBytes(file.fileSize),
                })
            );

            setSelectedFiles(value);
        }
    }, [value]);

    useEffect(() => {

        showHideDropZone();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFiles]);

    const showHideDropZone = () => {

        let showDropZone = true;

        if (isMultiple) {

            if((maxFiles !== undefined) && (maxFiles<=selectedFiles.length)){
                showDropZone=false;
            }

        } else {
            if (selectedFiles.length>0) {
                showDropZone=false;
            }
        }

        setShowDropZone(showDropZone);
    }

    const handleAcceptedFiles = (files:any) => {

        if ((!isMultiple) && (selectedFiles.length>0)){
            showErrorNotification({message:t('notification:oneFileCanUploaded')});
            return false;
        }

        let rejectedFiles = [];

        let updFiles = files.filter(file => {

            //const contentType = file?.type && file.type.length>0 ? file.type : file.contentType;
            const fileType = file?.name && file.name.length>0 ? file.name.split('.').pop() : file.type

                if (acceptedFiles && acceptedFiles.length>0 && (acceptedFiles.indexOf(fileType.split("/").pop().toLowerCase())<0)){
                    showErrorNotification({message:t('notification:invalidFileType')});
                    return false;
                }

                if (acceptedFiles && acceptedFiles.length>0 && (acceptedFiles.indexOf(fileType.split("/").pop().toLowerCase())<0)){
                    showErrorNotification({message:t('notification:invalidFileType')});
                    return false;
                }

                if ((selectedFiles || []).filter((item:MainFileType)=>{return item.fileName.toLowerCase() === file.name.toLowerCase()}).length>0){
                    showErrorNotification({message:t('notification:fileAlreadyUploaded')});
                    return false;
                }

                Object.assign(file, {
                        url: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                        fileName: file.name,
                        fileSize: file.size,
                        contentType: file.type,
                        canRemove: true,
                    });

                if (acceptedDimensions) {
                    const img = document.createElement('img');
                    img.src = URL.createObjectURL(file);
                    img.onload = function () {
                        let w = img.width;
                        let h = img.height;

                        file.width = w;
                        file.height = h;

                        if (acceptedDimensions.minWidth !== undefined && acceptedDimensions.minWidth !== null && acceptedDimensions.minWidth > w){

                            showErrorNotification({message: t('notification:invalidFileType')+' '+t('notification:widthShouldMoreThan')+' '+acceptedDimensions.minWidth+t('common:px')});

                            return;
                        }
                        if (acceptedDimensions.minHeight !== undefined && acceptedDimensions.minHeight !== null && acceptedDimensions.minHeight > h){
                            showErrorNotification({message: t('notification:invalidFileType')+' '+t('notification:heightShouldMoreThan')+' '+acceptedDimensions.minHeight+t('common:px')});

                            return;
                        }

                        handleChangeData([...selectedFiles,file]);
                    }

                    return false;
                }

                return file;
            }
        );

        if ((rejectedFiles.length>0) && (updFiles.length>0)) {
            updFiles = updFiles.filter((item) => {
                return rejectedFiles.some((rejectItem) => {
                    return rejectItem.url === item.url;
                });
            });
        }

        handleChangeData([...selectedFiles,...updFiles]);
    }

    const handleCloseMediaPopup = () => {
        setPopupShow(null);
    }

    const handleOpenMediaPopup = (file:MainFileType) => {
        setPopupShow(file.fileName);
    }

    const handleRemoveFile= (file:MainFileType) => {

        const updatedFiles = selectedFiles.filter((selectedFile:MainFileType)=>{
                return selectedFile.fileName !== file.fileName
            }
        );

        handleChangeData([...updatedFiles]);
    }

    // show file extensions if needed
    // (selectedFiles || []).map((item)=>{
    //
    //     console.log(item.contentType);
    //
    //     console.dir((Object.keys(MediaTypeIcon).indexOf(item.contentType.split("/").pop())));
    // })

    const dragEnded = (param) => {

        const { source, destination } = param;
        let _arr = [...selectedFiles];
        //extracting the source item from the list
        const _item = _arr.splice(source.index, 1)[0];
        //inserting it at the destination index.
        _arr.splice(destination.index, 0, _item);

        setSelectedFiles(_arr);
        handleChangeData(_arr);
    }

    const handleChangeData = (data:MainFileType[]) => {
        onChange( (data || []).map((item:MainFileType, i:number)=>{ item.sort = 100+(i*10); return item;}));
    }

  return (
      <>
          {isShowDropZone &&
              <div className={disabled?"disabledBlock":""}>
          <Dropzone
              onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles);
              }}
              disabled={disabled}
          >
              {({getRootProps, getInputProps}) => (
                  <>
                      <div className="dropzoneMainCont position-relative">
                      <div className={"dropzone dz-clickable"+(isShowCaption?' borderBottomUnRounded border-bottom-0':'')}>
                          <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                          >
                              <div className="mb-0 mt-1">
                                  <i className="display-6 text-muted ri-upload-cloud-2-fill"/>
                              </div>
                              {placeholder &&
                              <h6>{placeholder}</h6>
                              }
                              <p className="fs-12 text-muted m-0">{t('notification:dropOrClickText')}</p>
                          </div>
                      </div>
                      {isShowCaption &&
                          <div className="alert alert-info alert-dismissible alert-label-icon label-arrow fade show fs-10 borderTopUnRounded" role="alert">
                              <i className="ri-alert-line label-icon fw-normal"></i>
                              {acceptedFiles &&
                                  <p className="m-0">{t('common:fileType')}: <strong>{acceptedFiles}</strong></p>
                              }
                              {acceptedDimensions && acceptedDimensions.minWidth &&
                                  <p className="m-0">
                                      {t('common:minWidth')}: <strong>{acceptedDimensions.minWidth}{t('common:px')}</strong>
                                  </p>
                              }
                              {acceptedDimensions && acceptedDimensions.minHeight &&
                                  <p className="m-0">
                                      {t('common:minHeight')}: <strong>{acceptedDimensions.minHeight}{t('common:px')}</strong>
                                  </p>
                              }
                          </div>
                      }
                      {(selectedFiles || []).length>0 &&
                        <span
                              className="position-absolute top-0 start-0 translate-middle badge bg-primary">
                                <i className="ri-upload-2-line align-bottom me-2 fw-normal"/>
                            {(selectedFiles || []).length}<span
                              className="visually-hidden"></span>
                        </span>
                      }
                      </div>
                  </>
              )}
          </Dropzone>
              </div>
          }


          <DragDropContext
              onDragEnd={dragEnded}
          >
              <Droppable droppableId="availableWidgetLanguagesWrapper">
                  {(provided, snapshot) => {
                      return (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                              {selectedFiles && ((selectedFiles || []).length>0) &&
                                  (selectedFiles || []).map((f, i)=>{
                                      return(
                                          <Draggable
                                              draggableId={`fileItem-${f.fileName}-${i}`}
                                              index={i}
                                              key={"fileItem_"+f.fileName+'-'+i}
                                          >
                                              {(_provided, _snapshot) => (
                                                  <div
                                                      ref={_provided.innerRef}
                                                      {..._provided.draggableProps}

                                                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete d-flex flex-row flex-nowrap rounded-2 card"
                                                                  key={i + "-file"}
                                                              >
                                                           <div {..._provided.dragHandleProps} className="d-flex flex-column flex-shrink-0 justify-content-center p-1">
                                                               <i className="ri-more-2-fill text-muted"/>
                                                           </div>

                                                                  <div className={"p-2 ps-0 d-flex flex-grow-1 mw-0 "+((Object.values(MainFileVideoTypes) as string[]).includes(f.fileName.split('.').pop().toLowerCase())?'flex-column':'flex-row ')}>
                                                                      {(Object.values(MainFileImageTypes) as string[]).includes(f.fileName.split('.').pop().toLowerCase()) &&
                                                                      <div className="align-items-center d-flex flex-nowrap flex-grow-1 flex-row mw-0">
                                                                          <div className="col-auto d-flex">
                                                                              <img
                                                                                  data-dz-thumbnail=""
                                                                                  height="80"
                                                                                  className="avatar-sm rounded bg-light me-3 object-cover"
                                                                                  alt={f.fileName}
                                                                                  src={f.url}
                                                                                  onClick={()=>handleOpenMediaPopup(f)}
                                                                              />
                                                                          </div>
                                                                          <div className="d-flex flex-row flex-grow-1 mw-0">
                                                                              <div className="text-truncate d-flex flex-column mw-0">
                                                                                  { isPopupShow && isPopupShow === f.fileName &&
                                                                                  <Lightbox mainSrc={f.downLoadUrl ? f.downLoadUrl : f.url} onCloseRequest={handleCloseMediaPopup} imageTitle={f.fileName}/>
                                                                                  }
                                                                                  <a
                                                                                      onClick={()=>handleOpenMediaPopup(f)}
                                                                                      href="#"
                                                                                      className="text-muted font-weight-bold image-popup text-truncate"
                                                                                  >
                                                                                      {f.fileName.substring(0, maxFileNameLen)}{f.fileName.length >= maxFileNameLen && '...'}
                                                                                  </a>
                                                                                  <p className="mb-0">
                                                                                      <strong>{f.formattedSize}</strong>
                                                                                      {f.downLoadUrl &&
                                                                                          <a className={"ps-1 fs-10"} href={f.downLoadUrl} target="_blank" rel="noreferrer">
                                                                                              {t('common:download')}
                                                                                          </a>
                                                                                      }
                                                                                  </p>
                                                                              </div>
                                                                          </div>
                                                                          {f?.canRemove && f.canRemove===true &&
                                                                          <div className="col-auto d-flex flex-shrink-1 ms-2">
                                                                              <a type="button"
                                                                                 onClick={()=>handleRemoveFile(f)}
                                                                                 className="btn btn-outline-danger btn-icon waves-effect waves-light"><i
                                                                                  className="ri-delete-bin-5-line"/></a>
                                                                          </div>
                                                                          }
                                                                      </div>

                                                                      }
                                                                      {(Object.values(MainFileVideoTypes) as string[]).includes(f.fileName.split('.').pop().toLowerCase()) &&
                                                                          <>
                                                                      <Row className="align-items-center d-flex d-flex-row flex-nowrap mb-1">
                                                                          <Col className="col-auto d-flex">
                                                                              <video controls width="auto" height="auto" style={{maxWidth:'100%'}} className="rounded">
                                                                                  <source src={f.downLoadUrl ? f.downLoadUrl : f.url} type={"video/"+f.contentType.split('/').pop()}/>
                                                                              </video>
                                                                          </Col>
                                                                      </Row>
                                                                      <div className="align-items-center d-flex flex-nowrap flex-grow-1 flex-row mw-0 ps-2">
                                                                          <div className="d-flex flex-row flex-grow-1 mw-0">
                                                                              <div className="d-flex flex-grow-0 flex-column mw-0">
                                                                                  <a
                                                                                      href="#"
                                                                                      className="text-muted font-weight-bold image-popup text-truncate"
                                                                                  >
                                                                                      {f.fileName.substring(0, maxFileNameLen)}{f.fileName.length >= maxFileNameLen && '...'}
                                                                                  </a>
                                                                                  <p className="mb-0">
                                                                                      <strong>{f.formattedSize}</strong>
                                                                                      {f.downLoadUrl &&
                                                                                          <a className={"ps-1 fs-10"} href={f.downLoadUrl} target="_blank" rel="noreferrer">
                                                                                              {t('common:download')}
                                                                                          </a>
                                                                                      }
                                                                                  </p>
                                                                              </div>
                                                                          </div>
                                                                          <div className="d-flex flex-shrink-0">
                                                                              <a type="button"
                                                                                 onClick={()=>handleRemoveFile(f)}
                                                                                 className="btn btn-outline-danger btn-icon waves-effect waves-light"><i
                                                                                  className="ri-delete-bin-5-line"/></a>
                                                                          </div>
                                                                      </div>
                                                                          </>
                                                                      }
                                                                      {
                                                                          !(Object.values(MainFileImageTypes) as string[]).includes(f.fileName.split('.').pop().toLowerCase())
                                                                          &&
                                                                          !(Object.values(MainFileVideoTypes) as string[]).includes(f.fileName.split('.').pop().toLowerCase())
                                                                          &&
                                                                          <>
                                                                              <div className="align-items-center d-flex flex-nowrap flex-grow-1 flex-row mw-0">
                                                                                  <div className="flex-shrink-0 avatar-sm me-3">
                                                                                      <div className="avatar-title bg-light rounded">
                                                                                          <i className={
                                                                                              (Object.keys(MediaTypeIcon).indexOf(f.fileName.split(".").pop().toLowerCase()) ===-1 ? 'ri-file-line' : MediaTypeIcon[f.fileName.split(".").pop()].icon)+" fs-20 text-primary"}></i>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="d-flex flex-row flex-grow-1 mw-0">
                                                                                      <div className="d-flex flex-grow-0 flex-column mw-0">
                                                                                          <a
                                                                                              href={f.downLoadUrl}
                                                                                              className="text-muted font-weight-bold text-truncate"
                                                                                          >
                                                                                              {f.fileName.substring(0, maxFileNameLen)}{f.fileName.length >= maxFileNameLen && '...'}
                                                                                          </a>
                                                                                          <p className="mb-0">
                                                                                              <strong>{f.formattedSize}</strong>
                                                                                              {f.downLoadUrl &&
                                                                                              <a className={"ps-1 fs-10"} href={f.downLoadUrl} target="_blank" rel="noreferrer">
                                                                                                  {t('common:download')}
                                                                                              </a>
                                                                                          }
                                                                                          </p>
                                                                                      </div>
                                                                                  </div>
                                                                                  {f?.canRemove && f.canRemove===true &&
                                                                                      <div className="d-flex flex-shrink-0 ms-2">
                                                                                          <a type="button"
                                                                                             onClick={()=>handleRemoveFile(f)}
                                                                                             className="btn btn-outline-danger btn-icon waves-effect waves-light"><i
                                                                                              className="ri-delete-bin-5-line"/></a>
                                                                                      </div>
                                                                                  }
                                                                              </div>
                                                                          </>
                                                                      }
                                                                  </div>
                                                              </div>

                                              )}
                                          </Draggable>
                                      );
                                      }
                                  )}
                              {provided.placeholder}
                          </div>
                      )}}
              </Droppable>
          </DragDropContext>
      </>
  );
};
export default InputFile;
