import React from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";

// hooks
import { useProfile } from "../../../hooks/UserHooks";
import {MessengerMessageType} from "../../../redux/Messenger/Chat/types";
import {Images} from "./Images";
import {MediaFileType} from "../../../data";
import MessageAttachmentSmallBlock from "./MessageAttachmentSmallBlock";
import {getMessengerUserData} from "../../../helpers/functions";

interface RepliedMessageProps {
  isFromMe: boolean;
  message: MessengerMessageType;
  fullName: string;
}
function RepliedMessage({ isFromMe, message }: RepliedMessageProps) {
  const { userProfile } = useProfile();
  const { t, i18n } = useTranslation();

  const isReplyFromMe = (message.replyTo?.userData?.uuid && message.replyTo.userData.uuid === userProfile.uuid)

  let {fullName, avatar, handlerImage} = getMessengerUserData(message.replyTo.userData);

  return (
    <div className="">
      <div className="replymessage-block mb-2 d-flex align-items-start">
        <div className="flex-grow-1">
          <h5 className="conversation-name">
            {isReplyFromMe ? t('common:you') : fullName}
          </h5>

          <MessageAttachmentSmallBlock message={message.replyTo}/>

          {message.replyTo?.text && (
            <p className="mb-0">{message.replyTo?.text}</p>
          )}

        </div>
      </div>
    </div>
  );
}

export default RepliedMessage;
