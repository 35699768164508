import React, {useEffect, useState} from "react";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from 'react-router-dom';
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {logoIdeaChannel} from "../../../data/images";
import {ContentPeerUserType} from "../../../redux/Content/PeerUser/types";
import {getHandlerIconByCode} from "../../../helpers/functions";

interface ItemProps {
    item: ContentPeerUserType;
}
const PeerUserAvatarItem = ({ item }: ItemProps) => {

    const { t } = useTranslation(['common']);

    return(
        <>
                {item.uuid ? <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={item.name}>
                    <OverlayTrigger
                        placement={'right'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                {item.name}
                            </Tooltip>
                        }
                    >
                        <div className="avatar-xs position-relative">
                            { item.handler && item.handler.code && item.handler.code.length>0 &&
                                <div className="avatar-xxs position-absolute handlerIconTopLeft">
                                    <img src={getHandlerIconByCode(item.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                                </div>
                            }
                            {item.avatar ?
                                <img src={item.avatar} alt="" className="rounded-circle img-fluid" />
                                :
                                <div className="avatar-title rounded-circle bg-light border-dashed border text-primary fs-16 ">
                                    {item.handler && item.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS ?
                                    <img src={logoIdeaChannel} className="img-fluid p-0 rounded-circle" alt=""/>
                                    :
                                    item.name[0]
                                    }
                                </div>
                            }
                        </div>
                    </OverlayTrigger>
                </Link> : ''}
        </>
    )
}

export default PeerUserAvatarItem;