import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentStatCounterActionTypes } from "./types";
import { contentStatCounterApiResponseSuccess, contentStatCounterApiResponseError } from "./actions";

import {
  getContentStatCounters as getContentStatCountersApi,
  sendDataContentStatCounter,
  removeContentStatCounter
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getContentStatCounters() {
  try {
    const response: Promise<any> = yield call(getContentStatCountersApi);
    yield put(contentStatCounterApiResponseSuccess(ContentStatCounterActionTypes.GET_CONTENT_STAT_COUNTERS, response));
  } catch (error: any) {
    yield put(contentStatCounterApiResponseError(ContentStatCounterActionTypes.GET_CONTENT_STAT_COUNTERS, error));
  }
}

function* onSendDataContentStatCounter({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentStatCounter, data);
    yield put(
        contentStatCounterApiResponseSuccess(ContentStatCounterActionTypes.UPDATE_CONTENT_STAT_COUNTER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentStatCounterApiResponseError(ContentStatCounterActionTypes.UPDATE_CONTENT_STAT_COUNTER, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveContentStatCounter({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeContentStatCounter, {uuid: data.data.uuid});
    yield put(
        contentStatCounterApiResponseSuccess(ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentStatCounterApiResponseError(ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetContentStatCounters() {
  yield takeEvery(ContentStatCounterActionTypes.GET_CONTENT_STAT_COUNTERS, getContentStatCounters);
}
export function* watchOnSendDataContentStatCounter() {
  yield takeEvery(ContentStatCounterActionTypes.UPDATE_CONTENT_STAT_COUNTER, onSendDataContentStatCounter);
}
export function* watchOnRemoveContentStatCounter() {
  yield takeEvery(ContentStatCounterActionTypes.REMOVE_CONTENT_STAT_COUNTER, onRemoveContentStatCounter);
}

function* contentStatCounterSaga() {
  yield all([
    fork(watchGetContentStatCounters),
    fork(watchOnSendDataContentStatCounter),
    fork(watchOnRemoveContentStatCounter)
  ]);
}

export default contentStatCounterSaga;
