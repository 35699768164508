import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleCurrencyActionTypes } from "./types";
import { saleCurrencyApiResponseSuccess, saleCurrencyApiResponseError } from "./actions";

import {
  getSaleCurrencies as getSaleCurrenciesApi,
  sendDataSaleCurrency as sendDataSaleCurrencyApi,
  removeSaleCurrency as removeSaleCurrencyApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleCurrencies({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleCurrenciesApi, data);
    yield put(saleCurrencyApiResponseSuccess(SaleCurrencyActionTypes.GET_SALE_CURRENCIES, response));
  } catch (error: any) {
    yield put(saleCurrencyApiResponseError(SaleCurrencyActionTypes.GET_SALE_CURRENCIES, error));
  }
}

function* onSendDataSaleCurrency({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleCurrencyApi, data);
    yield put(
        saleCurrencyApiResponseSuccess(SaleCurrencyActionTypes.UPDATE_SALE_CURRENCY, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCurrencyApiResponseError(SaleCurrencyActionTypes.UPDATE_SALE_CURRENCY, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleCurrency({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleCurrencyApi, {uuid: data.data.uuid});
    yield put(
        saleCurrencyApiResponseSuccess(SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCurrencyApiResponseError(SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleCurrencies() {
  yield takeEvery(SaleCurrencyActionTypes.GET_SALE_CURRENCIES, getSaleCurrencies);
}
export function* watchOnSendDataSaleCurrency() {
  yield takeEvery(SaleCurrencyActionTypes.UPDATE_SALE_CURRENCY, onSendDataSaleCurrency);
}
export function* watchOnRemoveSaleCurrency() {
  yield takeEvery(SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY, onRemoveSaleCurrency);
}

function* saleCurrencySaga() {
  yield all([
    fork(watchGetSaleCurrencies),
    fork(watchOnSendDataSaleCurrency),
    fork(watchOnRemoveSaleCurrency)
  ]);
}

export default saleCurrencySaga;
