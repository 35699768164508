import {MainFileType} from "../../Main/File/types";
import {EntityActionsType} from "../../types";
import {MainUserType} from "../../Main/User/types";
import {MainMetaType} from "../../Main/common";
import {CommentType, MainCommentMetaType} from "../../Main/Comment/types";
import {TaskMemberType} from "../TaskMember/types";
import {TaskTimeMetaType, TaskTimeType} from "../TaskTime/types";

export enum TaskActionTypes {
  API_RESPONSE_SUCCESS = "@@taskTask/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@taskTask/API_RESPONSE_ERROR",
  GET_TASKS = "@@taskTask/GET_TASKS",
  GET_TASK_DETAIL = "@@taskTask/GET_TASK_DETAIL",
  UPDATE_TASK = "@@taskTask/UPDATE_TASK",
  REMOVE_TASK_MODAL_SHOW = "@@taskTask/REMOVE_TASK_MODAL_SHOW",
  REMOVE_TASK_MODAL_HIDE = "@@taskTask/REMOVE_TASK_MODAL_HIDE",
  REWARD_TASK_MODAL_SHOW = "@@taskTask/REWARD_TASK_MODAL_SHOW",
  REWARD_TASK_MODAL_HIDE = "@@taskTask/REWARD_TASK_MODAL_HIDE",
  SEND_REWARD_TASK = "@@taskTask/SEND_REWARD_TASK",
  REMOVE_TASK = "@@taskTask/REMOVE_TASK",
  EDIT_TASK = "@@taskTask/EDIT_TASK",
  COPY_TASK = "@@taskTask/COPY_TASK",
  RIGHT_MODAL_SHOW = "@@taskTask/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@taskTask/RIGHT_MODAL_HIDE",

  CHANGE_TASK_STATUS = "@@taskTask/CHANGE_TASK_STATUS",
  GET_TASK_TIMES = "@@taskTask/GET_TASK_TIMES",
  UPDATE_TASK_TIME = "@@taskTask/UPDATE_TASK_TIME",
  REMOVE_TASK_TIME = "@@taskTask/REMOVE_TASK_TIME",
  REMOVE_TASK_TIME_MODAL_SHOW = "@@taskTask/REMOVE_TASK_TIME_MODAL_SHOW",
  REMOVE_TASK_TIME_MODAL_HIDE = "@@taskTask/REMOVE_TASK_TIME_MODAL_HIDE",


  GET_COMMENTS = "@@taskTask/GET_COMMENTS",
  READ_COMMENTS = "@@taskTask/READ_COMMENTS",
  UPDATE_COMMENT = "@@taskTask/UPDATE_COMMENT",
  REMOVE_COMMENT = "@@taskTask/REMOVE_COMMENT",
  REMOVE_COMMENT_MODAL_SHOW = "@@taskTask/REMOVE_COMMENT_MODAL_SHOW",
  REMOVE_COMMENT_MODAL_HIDE = "@@taskTask/REMOVE_COMMENT_MODAL_HIDE",


  CLOSE_TASK_GROUP= "@@taskTask/CLOSE_TASK_GROUP",
  CLOSE_TASK_GROUP_MODAL_SHOW = "@@taskTask/CLOSE_TASK_GROUP_MODAL_SHOW",
  CLOSE_TASK_GROUP_MODAL_HIDE = "@@taskTask/CLOSE_TASK_GROUP_MODAL_HIDE",
  REMOVE_TASK_GROUP= "@@taskTask/REMOVE_TASK_GROUP",
  REMOVE_TASK_GROUP_MODAL_SHOW = "@@taskTask/REMOVE_TASK_GROUP_MODAL_SHOW",
  REMOVE_TASK_GROUP_MODAL_HIDE = "@@taskTask/REMOVE_TASK_GROUP_MODAL_HIDE",
}

export interface TaskState extends MainMetaType{
  items: Array<TaskType>;
  members: Array<MainUserType>;
  isLoading: boolean;
  isSending: boolean;

  selectedItem: TaskType;
  selectedItemMembers: Array<MainUserType>;
  rightModalShow: boolean;
  removeModalShow: boolean;
  rewardModalShow: boolean;
  modalEditTitle: string;
  isNeedReload: boolean;


  isLoadingTaskTimes: boolean;
  isSendingTaskTime: boolean;
  selectedTaskTime?: TaskTimeType;
  isNeedReloadTaskTimes: boolean;
  removeTaskTimeModalShow: boolean;
  removeTaskTimeModalTitle: string;


  isLoadingComments: boolean;
  isSendingComment: boolean;
  selectedComment?: CommentType;
  isNeedReloadComments: boolean;
  removeCommentModalShow: boolean;
  removeCommentModalTitle: string;


  isModalGroupCloseShow: boolean;
  isModalGroupRemoveShow: boolean;
}

export interface TaskType{
  id?: number;
  uuid?: string
  enabled?: boolean;
  name?: string;
  text?: string;
  status?: string;
  members?: Array<TaskMemberType>

  media?:Array<MainFileType>;
  attachmentFiles?: any;
  updatedAt?:string;
  createdAt?:string;
  deadLine?:string;
  owner?: MainUserType;
  actions?: EntityActionsType,
  tier?: number;
  unread?: number;
  completionPercent?: number;
  isNotifyAssignerClosure?:boolean;

  taskTimes?: Array<TaskTimeType>
  taskTimesMeta?: TaskTimeMetaType;

  comments?: Array<CommentType>
  commentsMeta?: MainCommentMetaType;

}

export interface TaskPayload {
  data: TaskType;
  modalEditTitle : string;
}

export const TaskStatus = {
  ALL: {
    label: 'All',
    class: 'text-bg-info',
    status: '_',
    enabled: false
  },
  PENDING: {
    label: 'Pending',
    class: 'text-bg-secondary',
    colorClass: 'bg-secondary',
    colorHEX:'#5B71B9',
    status: 'P',
    enabled: true
  },
  CANCELLED: {
    label: 'Cancelled',
    class: 'text-bg-danger',
    status: 'C',
    enabled: false
  },
  NEW: {
    label: 'New',
    class: 'text-bg-warning',
    colorClass: 'bg-warning',
    colorHEX:'#FFC84B',
    status: 'N',
    enabled: true
  },
  INPROGRESS: {
    label: 'InProgress',
    class: 'text-bg-success',
    colorClass: 'bg-success',
    colorHEX:'#67B173',
    status: 'I',
    enabled: true
  },
  RETURNED: {
    label: 'Returned',
    class: 'text-bg-primary',
    colorClass: 'bg-primary',
    colorHEX:'#3D78E3',
    status: 'R',
    enabled: true
  },
  FINAL: {
    label: 'Closed',
    class: 'border border-dark text-dark',
    colorClass: 'bg-soft-dark',
    colorHEX:'#F3F6F9',
    status: 'F',
    enabled: true
  },
  OVERDUE: {
    label: 'OverDue',
    class: 'text-bg-danger',
    colorClass: 'bg-danger',
    colorHEX:'#F17171',
    status: 'OV',
    enabled: true
  },
}

export const TaskPriority = {
  ALL: {
    label: 'All',
    class: 'text-bg-info',
    status: null,
    enabled: false
  },
  TIER3: {
    label: 'Low',
    class: 'text-bg-secondary',
    status: 3,
    enabled: true
  },
  TIER2: {
    label: 'Medium',
    class: 'text-bg-success',
    status: 2,
    enabled: true
  },
  TIER1: {
    label: 'High',
    class: 'text-bg-danger',
    status: 1,
    enabled: true
  }
}

export interface TaskCopyPayload {
  task: TaskType;
}