import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentConnectorDataParams = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_CONNECTOR_DATA_PARAMS);
};

export {
    getContentConnectorDataParams,
};
