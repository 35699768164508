import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import {
    hideRightModalSupportTicket,
    onSendDataSupportTicket
} from "../../../redux/Support/Ticket/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {SupportTicketType} from "../../../redux/Support/Ticket/types";
import {SupportTicketValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {getCurrentCabinet} from "../../../helpers/functions";
import InputFile from "../../../components/InputFile";
import {MainFileType} from "../../../redux/Main/File/types";
import NoticeTip from "../../../components/NoticeTip";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();

    const {
        supportTicket,
        isSending
    } = useAppSelector(state => ({
        supportTicket: state.SupportTicket,
        isSending: state.SupportTicket.isSending
    }));

    const uuid = supportTicket.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<SupportTicketType>(supportTicket.selectedItem);

    useEffect(() => {
        setFormValues(supportTicket.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, dispatch, supportTicket?.selectedItem]);

    const handleClose = () => dispatch(hideRightModalSupportTicket());

    const onDataSubmit = (formData:SupportTicketType) => {
        const currentCabinet = getCurrentCabinet();
        let attachmentFiles=[];

        if (currentCabinet !== undefined && currentCabinet !== false && currentCabinet?.uuid!==null){
            formData.cabinet = currentCabinet.uuid;
        }

        let params: SupportTicketType = {
            uuid: formData.uuid && formData.uuid,
            name: formData.name && formData.name,
            text: formData.text && formData.text,
            cabinet: formData.cabinet && formData.cabinet,
            media: formData.media && formData.media
        };

        (formData.media|| []).map((fileItem:MainFileType)=>{
            if (fileItem.uuid === undefined){
                attachmentFiles.push(fileItem);
            }
            return fileItem;
        });

        if (attachmentFiles.length>0){
            params['attachmentFiles']=attachmentFiles;
        }

        dispatch(onSendDataSupportTicket(params));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={supportTicket.rightModalShow}
                    id="supportTicketEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SupportTicketValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('support:'+supportTicket.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('support:ticketSubject')}</Label>
                                    <Field
                                        tabIndex={1}
                                        id="form-title-input"
                                        name="name"
                                        placeholder={t('support:enterTicketSubject')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.text ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-text-input">{t('support:ticketText')}</Label>
                                    <div className="textAreaEmojiBlock">
                                        <Field
                                            id="form-text-input"
                                            tabIndex={2}
                                            name="text"
                                            component={InputEmojiArea}
                                            isShowEmoji={true}
                                            value = {getFieldProps('text').value}
                                            onChange={(text:string) => {
                                                setFieldValue('text', text);
                                            }}
                                            required={false}
                                            placeholder={t('support:enterRequest')}
                                        />
                                        {errors.text && touched.text ? (
                                            <div className="invalid-feedback">
                                                {t('validator:'+errors.text)}
                                            </div>
                                        ) :  touched.text && !errors.text ?
                                            <div className="valid-feedback">
                                                {t('common:looksGood')}
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </Row>

                                    <Row>
                                        <div className="mb-3">
                                            <Field
                                                id={"form-attachmentFiles-input"}
                                                name={'media'}
                                                placeholder={t('support:filePlaceholder')}
                                                isMultiple={true}
                                                component={InputFile}
                                                value={getFieldProps('media').value}
                                                onChange={(value: MainFileType[]) => {
                                                    setFieldValue('media', value);
                                                }}
                                                required={false}
                                            />
                                        </div>
                                    </Row>

                                    <Row>
                                        <div className="p-2">
                                            <NoticeTip icon={'ri-customer-service-2-line'} type={'success'} firstText={t('support:supportCouponMessage1')} secondText={t('support:supportCouponMessage2')}/>
                                        </div>
                                    </Row>


                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModal;