import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import { useRedux } from "../../../hooks";

import { PlanOrderType} from "../../../redux/Sale/PlanOrder/types";
import {getPlanOrders} from "../../../redux/Sale/PlanOrder/actions";
import Loader from "../../../components/Loader"
import * as route from "../../../api/routes";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {getHistoryHash} from "../../../helpers/functions";
import PlanOrderColumns from "./tableColumns";
import {EntityActionsHandlersType} from "../../../redux/types";
import TableList from "../../../components/TableList";
import {setHistoryHash as setHistoryHashFunc} from "../../../helpers/functions";
import {MainMetaType} from "../../../redux/Main/common";
import MainListFilter from "../../../components/MainListFilter";
import {OrderStatus} from "../../../redux/Sale/Order/types";

const PlanOrderIndex = () => {

    const { t, i18n } = useTranslation(['common']);

    const componentType = 'planOrderItemsType';
    const finalOrderTab = 'finalOrderTab';
    const cancelledOrderTab = 'cancelledOrderTab';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [searchPhrase, setSearchPhrase] = useState<string>('');

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults && historyHash.maxResults,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection,
            searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase
        }

        if (historyHash?.tab){
            switch (historyHash?.tab) {
                case finalOrderTab:
                    mainMeta={
                        ...mainMeta,
                        status: OrderStatus.FINAL.status
                    }
                    break;
                case cancelledOrderTab:
                    mainMeta={
                        ...mainMeta,
                        status: OrderStatus.CANCELLED.status
                    }
                    break;
            }
        }

        dispatch(getPlanOrders(mainMeta));
    }

    useEffect(() => {
        if (historyHash.tab !== undefined && historyHash.tab.length>0 && activeTab !== historyHash.tab){
            toggleTab(historyHash.tab);
        }
        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        loadItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { dispatch, useAppSelector } = useRedux();

    const [activeTab, setActiveTab] = useState("All");

    const {
        planOrder,
        isFetched
    } = useAppSelector(state => ({
        isFetched: state.PlanOrder.isFetched,
        planOrder: state.PlanOrder,
    }));

    const [orders, setOrders] = useState<PlanOrderType[]>([]);

    useEffect(() => {
        if ((planOrder.items !== undefined) && (planOrder.items.length>0)){
            setOrders([...planOrder.items]);
        } else {
            setOrders([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planOrder?.items])

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleClickTab = (tab) => {
        if (activeTab !== tab) {
            if ( historyHash.tab !== tab) {
                historyHash.tab = tab;
                historyHash.page = 1;
                setHistoryHashFunc(historyHash);
            }
        }
    }

    const onTableRowClick = (item:PlanOrderType) => {
        window.location.href = route.PLAN_ORDER_DETAIL.replace(/:uuid/g, item.uuid);
    }

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onDetail: onTableRowClick
    }

    const columns = PlanOrderColumns(EntityActionsHandlers);

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }


    const handleSearchClick = (value:string) => {
        setSearchPhrase(value);
        if ( historyHash.searchPhrase !== value) {
            historyHash.searchPhrase = value;
            setHistoryHashFunc(historyHash);
        }
    }

    return (
        <>
            <BreadCrumb title={t('menu:orders')} pageTitle={t('menu:main')} />

            <Row>
                <Col lg={12}>
                    <Card id="orderList">
                        <CardHeader className="card-header border-0">
                            <Row className="align-items-center gy-3">
                                <div className="col-sm">
                                    <h5 className="card-title mb-0">{t('sale:ordersHistory')}</h5>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="d-flex gap-1 flex-wrap">

                                    </div>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                            <MainListFilter
                                onClick={handleSearchClick}
                                value={searchPhrase}
                                onChange={onSearchInput}
                            />
                        </CardBody>
                    <CardBody className="pt-0">
                        <div>
                            <Nav
                                className="nav-tabs nav-tabs-custom nav-success"
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === "All" },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab( "All");
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-store-2-fill me-1 align-bottom font-weight-normal"></i>{" "}
                                        {t('sale:allOrders')}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === finalOrderTab },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab(finalOrderTab);
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-checkbox-circle-line me-1 align-bottom font-weight-normal"></i>{" "}
                                        {t('sale:paidOrders')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: activeTab === cancelledOrderTab },
                                            "fw-semibold"
                                        )}
                                        onClick={() => {
                                            toggleClickTab(cancelledOrderTab);
                                        }}
                                        href="#"
                                    >
                                        <i className="ri-close-circle-line me-1 align-bottom font-weight-normal"></i>{" "}
                                        {t('sale:cancelledOrders')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {isFetched ?
                                    <TableList
                                        columns={columns}
                                        data={orders || []}
                                        onRowClick={onTableRowClick}
                                        key={componentType}
                                        historyHash = {historyHash}
                                        paginationTotalRows={planOrder?.meta?.totalCount || 0}
                                        paginationServer={true}
                                        isShowChangePageSize={true}
                                    />
                                :
                                <Loader />
                            }
                        </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PlanOrderIndex;


