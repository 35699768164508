import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import InputDatePicker from "../../../components/InputDatePicker";
import {
    getContentPeerChannelMembers,
    hideModalContentUnsubscribeMember,
    onSendUnsubscribeChannelMember
} from "../../../redux/Content/PeerUser/actions";

import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {ContentPeerUserType} from "../../../redux/Content/PeerUser/types";
import {ContentPeerUserUnsubscribeValidatorSchema} from "./PeerUserUnsubscribeValidator";


const UnsubscribeModal = () => {
    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [item, setItem] = useState<ContentPeerUserType>(null);

    const {
        contentPeerUsers,
    } = useAppSelector(state => ({
        contentPeerUsers: state.ContentPeerUser,
    }));

    const [formValues, setFormValues] = useState<ContentPeerUserType>(contentPeerUsers.selectedItem);
    const [idPeerChannel, setIdPeerChannel] = useState(window.location.pathname.match( /\d+/g )[0] || null);

    useEffect(() => {

        setItem(contentPeerUsers.selectedItem);

        if (contentPeerUsers.selectedItem !== undefined && contentPeerUsers.selectedItem.activeTo === undefined){
            contentPeerUsers.selectedItem.activeTo=null;
        }

        setFormValues(contentPeerUsers.selectedItem);

    }, [contentPeerUsers.selectedItem]);

    const handleClose = () => dispatch(hideModalContentUnsubscribeMember());

    const onDataSubmit = (formData:ContentPeerUserType) => {
        let data: any = {};

        data["uuid"] = item.uuid;

        if (formData.activeTo) {
            data["activeTo"] = formData.activeTo;
        }

        data["idPeerChannel"]=contentPeerUsers.selectedChannel.uuid;

        const sendData = {
            uuid: data.uuid && data.uuid,
            activeTo: data.activeTo && data.activeTo,
            idPeerChannel: data.idPeerChannel && data.idPeerChannel
        }

        dispatch(onSendUnsubscribeChannelMember(sendData));

        setTimeout(() => {
            dispatch(getContentPeerChannelMembers({idPeerChannel:parseInt(idPeerChannel)}));
        }, 1000);
    };

    return (
        <>
            {item && item.uuid.length > 0 &&
            <Offcanvas
                direction="end"
                isOpen={contentPeerUsers.unsubscribeModalShow}
                id="subscribeEdit"
                toggle={handleClose}
            >

                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ContentPeerUserUnsubscribeValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating}) => (
                        <Form noValidate={true} className="customValidation">
                            <OffcanvasHeader className="bg-light" toggle={handleClose}>
                                {t('channel:'+contentPeerUsers.modalEditTitle)}
                            </OffcanvasHeader>

                            <OffcanvasBody className="p-0 overflow-hidden">
                                <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                    <div className="p-4">

                                <Row>
                                    <div className="d-flex flex-row flex-nowrap mb-4">
                                        <div
                                            className="avatar-lg img-thumbnail rounded-circle flex-shrink-0 position-relative">
                                            {item.avatar != null ?
                                                <img src={item.avatar} alt=""
                                                     className="img-fluid d-block rounded-circle"/>

                                                :
                                                <div className={"avatar-title rounded-circle"}>
                                                    {item.name[0]}
                                                </div>}
                                            {item.handler && item.handler && item.handler.icon &&
                                            <div className="avatar-xs position-absolute handlerIconTopLeft">
                                                <img src={item.handler.icon} className="img-fluid p-1 rounded-circle"
                                                alt=""/>
                                            </div>
                                            }
                                        </div>
                                        <div className="team-content ms-4 d-flex flex-row align-items-center">
                                            <h5 className="fs-16 mb-2">{item.name}</h5>
                                        </div>
                                    </div>
                                </Row>


                                <Row>
                                    <div className={ "mb-3"+ (touched.activeTo ? " was-validated" : '')}>
                                        <Label className="form-label" htmlFor="form-activeTo-input">{t('channel:subscribedUntil')}</Label>
                                        <Field
                                            id="form-activeTo-input"
                                            name="activeTo"
                                            placeholder={t('channel:chooseSubscribeUntilDate')}
                                            component={InputDatePicker}
                                            value = {getFieldProps('activeTo').value}
                                            onChange={(value:number) => {
                                                setFieldValue('activeTo', value);
                                            }}
                                            minDate="today"
                                            className={errors.activeTo && touched.activeTo && 'form-control is-invalid'}
                                        />
                                        {errors.activeTo && touched.activeTo ? (
                                            <div className="invalid-feedback">
                                                {t('validator:'+errors.activeTo)}
                                            </div>
                                        ) :  touched.activeTo && !errors.activeTo ?
                                            <div className="valid-feedback">
                                                {t('common:looksGood')}
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </Row>

                                    </div>
                                </SimpleBar>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                                <div className="d-flex flex-row flex-nowrap gap-2">
                                    <Button variant="light" onClick={handleClose} className="w-100">
                                        {t('common:close')}
                                    </Button>
                                    <Button variant="primary"
                                            type="submit"
                                            disabled={disabled}
                                            className="btn btn-primary w-100 chat-send waves-effect waves-light"
                                    >
                                        {t('common:save')}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Offcanvas>
            }
        </>
    );
}

export default UnsubscribeModal;