// types
import {PeerActivityActionTypes, PeerActivityState, PeerActivityType} from "./types";
import {PeerActivityStatus} from "../PeerActivityStatus/types";


export const INIT_STATE_CONTENT_PEER_ACTIVITY: PeerActivityState = {
  items: [],
  editItems: [],
  rightModalShow: false,
  removeModalShow: false,
//  selectedItem: NewPeerActivityItem,
  modalEditTitle: '',
  isInitialized: false,
  isSending: false,
  requestId:'',
  isNeedReload: false,
  isPeerActivityFetched: false,
  getPeerActivityLoading:false,
  mode: null,
  isShowMoreChannels: false,
  isDontSetReloadAfterAction: false,
  previewItems: [],
  isShowPreviewItems: false,
  isShowPreviewMobile: true
};

const PeerActivity = (state = INIT_STATE_CONTENT_PEER_ACTIVITY, action: any) => {
  switch (action.type) {
    case PeerActivityActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES:
          return {
            ...state,
            items: action.payload.data.items,
            isNeedReload: false,
            isPeerActivityFetched: true,
            getPeerActivityLoading: false,
          };
        case PeerActivityActionTypes.UPDATE_PEER_ACTIVITIES:
          return {
            ...state,
            editItems: action.payload.data.items,
            isDontSetReloadAfterAction: false,
            isSending: false,
            isNeedReload: !state.isDontSetReloadAfterAction,
          }
        case PeerActivityActionTypes.COPY_PEER_ACTIVITY:
        case PeerActivityActionTypes.REMOVE_PEER_ACTIVITY:
          return {
            ...state,
            isSending: false,
            isNeedReload: true,
          }
        case PeerActivityActionTypes.PUBLISH_PEER_ACTIVITIES:
        case PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY:

          if (action.payload.data.response && action.payload.data.response === 'wsock'){
              state.requestId = action.payload.data.requestId;
              return {
                ...state,
                isSending: true
              };
          }

          return {
            ...state,
            editItems: action.payload.data.items,
            isSending: false,
            isNeedReload: true,
          }
        case PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_SUCCESSFUL:
          (state.editItems || []).map((item:PeerActivityType)=>{
            (action.payload.data.items || []).map((responseItem:PeerActivityType)=>{
              if (item.uuid === responseItem.uuid){
                item.activeFrom = responseItem.activeFrom;
                item.status = PeerActivityStatus.STATUS_FINAL;
              }
              return responseItem;
            })
            return item;
          });

          return {
            ...state,
            editItems: [...state.editItems],
            isSending: false,
            isNeedReload: true,
          }
        case PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_SUCCESSFUL:

          (state.editItems || []).map((item:PeerActivityType)=>{
            (action.payload.data.items || []).map((responseItem:PeerActivityType)=>{
              if (item.uuid === responseItem.uuid){
                item.status = PeerActivityStatus.STATUS_UNPUBLISHED;
              }
              return responseItem;
            })
            return item;
          });

          return {
            ...state,
            editItems: [...state.editItems],
            isSending: false,
            isNeedReload: true,
          }
        case PeerActivityActionTypes.TOGGLE_PIN:

          if (action.payload.data.response && action.payload.data.response === 'wsock'){
            state.requestId = action.payload.data.requestId;
            return {
              ...state,
              isSending: true
            };
          }

          if (action.payload.data !== undefined && action.payload.data.items !== undefined) {
            (state.editItems || []).map((item: PeerActivityType) => {
              (action.payload.data.items || []).map((responseItem: PeerActivityType) => {
                if (item.uuid === responseItem.uuid) {
                  item.isPin = responseItem.isPin;
                }
                return responseItem;
              })
              return item;
            });
          }

          return {
            ...state,
            isSending: false,
            isNeedReload: true,
          }
        default:
          return { ...state };
      }

    case PeerActivityActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES:
          return {
            ...state,
            isNeedReload: false,
            isPeerActivityFetched: false,
            getPeerActivityLoading: false,
          };
        case PeerActivityActionTypes.UPDATE_PEER_ACTIVITIES:
        case PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY:

          (state.editItems || []).map((item: PeerActivityType)=>{
            if (item.status === PeerActivityStatus.STATUS_ON_PUBLISH || item.status === PeerActivityStatus.STATUS_PUBLISHING) {
              item.status=PeerActivityStatus.STATUS_READY
            }
            return item;
          });

          return {
            ...state,
            isDontSetReloadAfterAction: false,
            isSending: false
          }
        case PeerActivityActionTypes.PUBLISH_PEER_ACTIVITIES:

          (state.editItems || []).map((item: PeerActivityType)=>{
              if (item.status === PeerActivityStatus.STATUS_ON_PUBLISH || item.status === PeerActivityStatus.STATUS_PUBLISHING) {
                  item.status=PeerActivityStatus.STATUS_READY
              }
              return item;
          });

          return {
            ...state,
            isSending: false
          }
        case PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_SUCCESSFUL:
        case PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_SUCCESSFUL:
        case PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_FAILED:
        case PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_FAILED:

        (state.editItems || []).map((item:PeerActivityType)=>{
            (action.payload.error.items || []).map((responseItem:PeerActivityType)=>{
              if (item.uuid === responseItem.uuid){
                item.status = PeerActivityStatus.STATUS_ERROR;
              }
              return responseItem;
            })
            return item;
          });

          return {
            ...state,
            editItems: [...state.editItems],
            isSending: false,
            isNeedReload: true,
          }
        case PeerActivityActionTypes.TOGGLE_PIN:

          if (action.payload.error !== undefined && action.payload.error.items !== undefined) {
            (state.editItems || []).map((item: PeerActivityType) => {
              (action.payload.error.items || []).map((responseItem: PeerActivityType) => {
                if (item.uuid === responseItem.uuid) {
                  item.isPin = responseItem.isPin;
                }
                return responseItem;
              })
              return item;
            });
          }
          return {
            ...state,
            isSending: false,
            isNeedReload: true,
          }
        case PeerActivityActionTypes.COPY_PEER_ACTIVITY:
        case PeerActivityActionTypes.REMOVE_PEER_ACTIVITY:
          return {
            ...state,
            isSending: false,
            isNeedReload: false,
          }
        default:
          return { ...state };
      }
    case PeerActivityActionTypes.SET_NEED_RELOAD:
      return {
        ...state,
        isNeedReload: true,
        isLoaded: false
      }
    case PeerActivityActionTypes.SET_EDIT_ITEMS:
      return {
        ...state,
        editItems: action.payload
      }
    case PeerActivityActionTypes.SET_INITIALIZED:
      return {
        ...state,
        isInitialized: true,
        isLoaded: true
      }
    case PeerActivityActionTypes.SHOW_MODAL:
      return {
        ...state,
        rightModalShow: true
      }
    case PeerActivityActionTypes.HIDE_MODAL:
      return {
        ...state,
        rightModalShow: false,
        isShowMoreChannels: false
      }
    case PeerActivityActionTypes.UPDATE_PEER_ACTIVITIES:
      return {
        ...state,
        isSending: true
      }
    case PeerActivityActionTypes.PUBLISH_PEER_ACTIVITIES:
      return {
        ...state,
        isSending: true
      }
    case PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY:
      return {
        ...state,
        isSending: true
      }
    case PeerActivityActionTypes.TOGGLE_PIN:
      return {
        ...state,
        isSending: true
      }
    case PeerActivityActionTypes.SET_ACTIVITY_MODE:
      return {
        ...state,
        mode: action.payload
      }
    case PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES:
      return {
        ...state,
        isNeedReload: false,
        isPeerActivityFetched: false,
        getPeerActivityLoading: true,
      };
    case PeerActivityActionTypes.COPY_PEER_ACTIVITY:
    case PeerActivityActionTypes.REMOVE_PEER_ACTIVITY:
      return {
        ...state,
        isSending: true
      }
    case PeerActivityActionTypes.SET_SHOW_MORE_CHANNELS:
      return {
        ...state,
        isShowMoreChannels: true
      }
    case PeerActivityActionTypes.DONT_SET_RELOAD_AFTER_ACTION:
      return {
        ...state,
        isDontSetReloadAfterAction: true
      }
    case PeerActivityActionTypes.SET_SHOW_PREVIEW_ITEMS:
      return {
        ...state,
        isShowPreviewItems: true
      }
    case PeerActivityActionTypes.SET_HIDE_PREVIEW_ITEMS:
      return {
        ...state,
        isShowPreviewItems: false
      }
    case PeerActivityActionTypes.CLEAR_PREVIEW_ITEMS:
      return {
        ...state,
        previewItems: []
      }
    case PeerActivityActionTypes.SET_PREVIEW_ITEMS:
      return {
        ...state,
        previewItems: [...action.payload]
      }
    case PeerActivityActionTypes.SET_PREVIEW_MOBILE:
      return {
        ...state,
        isShowPreviewMobile: true
      }
    case PeerActivityActionTypes.SET_PREVIEW_DESKTOP:
      return {
        ...state,
        isShowPreviewMobile: false
      }
    // case PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_FAILED:
    //   (state.editItems || []).map((item:PeerActivityType)=>{
    //     (action.payload.items || []).map((responseItem:PeerActivityType)=>{
    //       if (item.uuid === responseItem.uuid){
    //         item.status = PeerActivityStatus.STATUS_ERROR;
    //       }
    //       return responseItem;
    //     })
    //     return item;
    //   });
    //
    //   return {
    //     ...state,
    //     editItems: [...state.editItems],
    //     isSending: false,
    //     isNeedReload: true,
    //   }
    // case PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_FAILED:
    //   (state.editItems || []).map((item:PeerActivityType)=>{
    //     (action.payload.items || []).map((responseItem:PeerActivityType)=>{
    //       if (item.uuid === responseItem.uuid){
    //         item.status = PeerActivityStatus.STATUS_FINAL;
    //       }
    //       return responseItem;
    //     })
    //     return item;
    //   });
    //
    //   return {
    //     ...state,
    //     editItems: [...state.editItems],
    //     isSending: false,
    //     isNeedReload: true,
    //   }
    default:
      return { ...state };
  }
};

export default PeerActivity;
