import {useState, useEffect} from "react";
import * as route from "../api/routes";

// hooks
import { useRedux } from "./useRedux";

// api
import { getLoggedinUser } from "../api/apiCore";
import {
  checkLogInUser,
} from "../redux/Main/Login/actions";

import {divideByKey} from "../utils";
import {publicRoutes} from "../routes/allRoutes";
import {m9sGlobals} from "../config";
import {selectCurrentUserCabinet} from "../redux/Main/Cabinet/actions";
import {getCurrentCabinet, getCurrentSettings} from "../helpers/functions";
import {getMainCurrentUser} from "../redux/Main/User/actions";

const useProfile = () => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    loginState,
    mainCabinet,
    layout
  } = useAppSelector(state => ({
    loginState: state.Login,
    mainCabinet: state.MainCabinet,
    layout: state.Layout
  }));

  const image = loginState?.settings && loginState.settings?.basicDetails && loginState.settings.basicDetails?.profile;

  const getParamValueFromLayout = (value:any) => {
    if (value !== undefined && value !== null && value.length>0){
      return value;
    }
    return null;
  }

  const userProfileSession = getLoggedinUser();
  const cabinetSettings = getCurrentSettings();

  const [loading] = useState(!userProfileSession);

  const [userProfile, setUserProfile] = useState(
    userProfileSession ? {
      ...userProfileSession,
      profileImage: image,
      fullName: getParamValueFromLayout(cabinetSettings?.userName) !== null ? getParamValueFromLayout(cabinetSettings?.userName) :  userProfileSession.fullName,
      name: getParamValueFromLayout(cabinetSettings?.userName) !== null ? getParamValueFromLayout(cabinetSettings?.userName) :  userProfileSession.name,
      position: getParamValueFromLayout(cabinetSettings?.position) !== null ? getParamValueFromLayout(cabinetSettings?.position) :  null,
    } : false
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();

    setUserProfile(
      userProfileSession ? {
        ...userProfileSession,
        profileImage: image,
        fullName: getParamValueFromLayout(layout?.userName) !== null ? getParamValueFromLayout(layout?.userName) :  userProfileSession.fullName,
        name: getParamValueFromLayout(layout?.userName) !== null ? getParamValueFromLayout(layout?.userName) :  userProfileSession.name,
        position: getParamValueFromLayout(layout?.position) !== null ? getParamValueFromLayout(layout?.position) :  null,
      } : false
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, layout?.userName, layout?.position]);

  useEffect(() => {

    if (loginState.isUserLogin === false) {
        dispatch(checkLogInUser());

        if (m9sGlobals.CURRENT_CABINET !== undefined && m9sGlobals.CURRENT_CABINET !== null && m9sGlobals.CURRENT_CABINET.length>10) {
          dispatch(getMainCurrentUser());
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (loginState.isUserLogout){

      let isNeedRedirect = true;

      publicRoutes.map((item)=>{
          if (item.path===window.location.pathname){
            isNeedRedirect = false;
          }
          return item;
      });

      if (
          (isNeedRedirect)
          &&
          (
              (window.location.pathname.includes('/auth/resetting/reset/')!==false)
              ||
              (window.location.pathname.includes('/auth/social/')!==false)
          )
      ){
        isNeedRedirect = false;
      }

      if (isNeedRedirect) {
        window.location.href = route.MAIN_AUTH_LOGIN;
      }
    }
  }, [loginState.isUserLogout]);

  useEffect(() => {

    if (loginState.isUserLogin === true){
      setUserProfile({
        ...loginState.user,
        fullName: getParamValueFromLayout(layout?.userName) !== null ? getParamValueFromLayout(layout?.userName) :  userProfileSession.fullName,
        name: getParamValueFromLayout(layout?.userName) !== null ? getParamValueFromLayout(layout?.userName) :  userProfileSession.name,
        position: getParamValueFromLayout(layout?.position) !== null ? getParamValueFromLayout(layout?.position) :  null,
      });

      if (loginState.isUserDisabled === true){
        window.location.href = route.MAIN_AUTH_LOGIN;
      }

      const currentCabinet = getCurrentCabinet();

      if (currentCabinet !== false && currentCabinet.uuid !== undefined){
        if (!(mainCabinet?.currentCabinet && mainCabinet?.currentCabinet?.uuid === currentCabinet.uuid)){
          dispatch(selectCurrentUserCabinet(currentCabinet));
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loginState, layout?.userName, layout?.position]);

  return { userProfile, loading };
};

const useContacts = () => {
  // global store
  const { useAppSelector } = useRedux();

  const { contactsList } = useAppSelector(state => ({
    contactsList: state.Contacts.contacts,
  }));

  const [contacts, setContacts] = useState<Array<any>>([]);
  const [categorizedContacts, setCategorizedContacts] = useState<Array<any>>(
      []
  );
  useEffect(() => {
    if (contactsList.length > 0) {
      setContacts(contactsList);
    }
  }, [contactsList]);

  useEffect(() => {
    if (contacts.length > 0) {
      const formattedContacts = divideByKey("firstName", contacts);
      setCategorizedContacts(formattedContacts);
    }
  }, [contacts]);

  const totalContacts = (categorizedContacts || []).length;
  return { categorizedContacts, totalContacts };
};

const useConversationUserType = () => {
  // global store
  const { useAppSelector } = useRedux();

  const { chatUserDetails } = useAppSelector(state => ({
    chatUserDetails: state.MessengerChat.chatUserDetails,
  }));

  const [isChannel, setIsChannel] = useState<boolean>(false);

  useEffect(() => {
    setIsChannel(chatUserDetails.isChannel);
  }, [chatUserDetails]);

  return { isChannel };
};

export { useProfile,  useContacts, useConversationUserType};
