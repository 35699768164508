export enum connectorHandlerCode{
    CONNECTOR_HANDLER_VK = 'vk',
    CONNECTOR_HANDLER_FACEBOOK = 'facebook',
    CONNECTOR_HANDLER_INSTAGRAM = 'instagram',
    CONNECTOR_HANDLER_TELEGRAM = 'telegram',
    CONNECTOR_HANDLER_TELEGRAM_BOT = 'telegramBot',
    CONNECTOR_HANDLER_PINTEREST = 'pinterest',
    CONNECTOR_HANDLER_LINKEDIN = 'linkedin',
    CONNECTOR_HANDLER_YOUTUBE = 'youtube',
    CONNECTOR_HANDLER_TWITTER = 'twitter',
    CONNECTOR_HANDLER_OK = 'ok',
    CONNECTOR_HANDLER_BITRIX = 'bitrix',
    CONNECTOR_HANDLER_VIBER = 'viber',
     CONNECTOR_HANDLER_WHATSAPP = 'whatsapp',
    CONNECTOR_HANDLER_AVITO = 'avito',
    CONNECTOR_HANDLER_OZON = 'ozon',
    CONNECTOR_HANDLER_YANDEX_METRIKA = 'yandexMetrika',
    CONNECTOR_HANDLER_GOOGLE_ANALYTICS = 'googleAnalytics',
    CONNECTOR_HANDLER_TIKTOK = 'tiktok',
    CONNECTOR_HANDLER_CHAT_GPT = 'chatGPT',
    CONNECTOR_HANDLER_GOOGLE_BARD = 'googleBard',
    CONNECTOR_HANDLER_NULL = 'null',
    CONNECTOR_HANDLER_IDEAS = 'ideas',
    CONNECTOR_HANDLER_WORDPRESS = 'wordPress'
}