import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSaleProducts = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_PRODUCTS+"?"+query);
};

const getSaleProduct = (data: any) => {

    if (data.uuid !== undefined){
        return api.create(m9sGlobals.CURRENT_CABINET+url.GET_SALE_PRODUCTS, {uuid: data.uuid});
    }

    return false;
};

const sendDataSaleProduct = (data: any) => {

    if (data.attachmentFiles !== undefined) {
        return api.createWithFile(m9sGlobals.CURRENT_CABINET + url.UPDATE_SALE_PRODUCT, data);
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_PRODUCT, data);
};

const removeSaleProduct = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_PRODUCT, data);
};

const copyProduct = (data:any) => {

    let requestUrl = m9sGlobals.CURRENT_CABINET+url.COPY_SALE_PRODUCT;

    requestUrl = requestUrl.replace(/:uuid/,data.uuid);

    return api.create(requestUrl);
};

const setGroupSaleProduct = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.SET_SALE_PRODUCT_GROUP, data);
};

const unSetGroupSaleProduct = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UNSET_SALE_PRODUCT_GROUP, data);
};

const removeGroupSaleProduct = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_PRODUCT_GROUP, data);
};

export {
    getSaleProducts,
    getSaleProduct,
    sendDataSaleProduct,
    removeSaleProduct,
    copyProduct,
    setGroupSaleProduct,
    unSetGroupSaleProduct,
    removeGroupSaleProduct
};
