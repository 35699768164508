import React from "react";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";

interface ItemProps {
    item: ContentPeerChannelType;
}

const ChannelLink = ({ item }: ItemProps) => {

    return(
        <>
            {item.link !== null &&
                <a href={item.link} target="_blank" rel="noreferrer"
                       className={"ps-1 fs-12 me-1 text-muted d-flex font-weight-normal"}>
                        <i className={"ri-send-plane-fill"}/>
                </a>
            }
        </>
    )
}
export default ChannelLink;