import React from 'react';
import * as Yup from 'yup';

export const SaleStoreValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    // code: Yup.string()
    //     .min(2, 'tooShort')
    //     .max(50, 'tooLong')
    //     .required('required'),
    order: Yup.string()
        .matches(/^[0-9]+$/, "digitsOnly")
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
});