import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { PeerActivityTypeActionTypes } from "./types";
import { peerActivityTypeApiResponseSuccess, peerActivityTypeApiResponseError } from "./actions";

import {
  getPeerActivityTypes as getPeerActivityTypesApi,
  sendDataPeerActivityType,
  removePeerActivityType
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getPeerActivityTypes({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivityTypesApi, data);
    yield put(peerActivityTypeApiResponseSuccess(PeerActivityTypeActionTypes.GET_CONTENT_PEER_ACTIVITY_TYPES, response));
  } catch (error: any) {
    yield put(peerActivityTypeApiResponseError(PeerActivityTypeActionTypes.GET_CONTENT_PEER_ACTIVITY_TYPES, error));
  }
}

function* onSendDataPeerActivityType({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataPeerActivityType, data);
    yield put(
        peerActivityTypeApiResponseSuccess(PeerActivityTypeActionTypes.UPDATE_CONTENT_DATAITEMTYPE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(peerActivityTypeApiResponseError(PeerActivityTypeActionTypes.UPDATE_CONTENT_DATAITEMTYPE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemovePeerActivityType({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removePeerActivityType, {uuid: data.data.uuid});
    yield put(
        peerActivityTypeApiResponseSuccess(PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(peerActivityTypeApiResponseError(PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetPeerActivityTypes() {
  yield takeEvery(PeerActivityTypeActionTypes.GET_CONTENT_PEER_ACTIVITY_TYPES, getPeerActivityTypes);
}
export function* watchOnSendDataPeerActivityType() {
  yield takeEvery(PeerActivityTypeActionTypes.UPDATE_CONTENT_DATAITEMTYPE, onSendDataPeerActivityType);
}
export function* watchOnRemovePeerActivityType() {
  yield takeEvery(PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE, onRemovePeerActivityType);
}

function* peerActivityTypeSaga() {
  yield all([
    fork(watchGetPeerActivityTypes),
    fork(watchOnSendDataPeerActivityType),
    fork(watchOnRemovePeerActivityType)
  ]);
}

export default peerActivityTypeSaga;
