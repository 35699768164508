import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getMainVersionHistories = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_MAIN_VERSION_HISTORY_LIST);
};


export {
    getMainVersionHistories,
};
