import React from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TableList from "../TableList";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../api/routes";
import {TaskType} from "../../redux/Task/Task/types";
import {MainUserType} from "../../redux/Main/User/types";
import TaskColumnsSimple from "../../blocks/Task/Task/tableColumnsSimple";

interface TableActivitiesProps {
    items: Array<TaskType>;
    members: Array<MainUserType>;
    title: string
}

const TaskTable = ({ items, members, title}: TableActivitiesProps) => {

    const { t } = useTranslation();

    const handleClick = (item:TaskType) => {
        if (item?.uuid) {
            window.location.href = route.TASK_DETAIL.replace(/:uuid/, item.uuid);
        }
    }

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{title}</h4>
            </CardHeader>
            <CardBody>

                <TableList
                    columns={TaskColumnsSimple({members: members, onDetail: handleClick})}
                    data={items}
                    key={'taskTable_'+Math.round(Math.random() * (99999 - 11111) + 11111)}
                    defaultSortFieldId={'amount'}
                    onRowClick={handleClick}
                    isShowPagination={false}
                />

            </CardBody>
        </Card>
    );
}

export default TaskTable;