import React from 'react';
import * as Yup from 'yup';
import {PHONE_REGEX} from "../../../data/settings";

export const MainUserValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    phone: Yup.string()
        .matches(PHONE_REGEX, 'invalidPhone').nullable()
        // .min(10, "tooShort")
        // .max(10, "tooLong"),
});