import React from "react";

import { Input } from "reactstrap";

//i18
import '../../../../i18/config';
import {useTranslation} from "react-i18next";

interface InputSectionProps {
  value: null | string;
  onChange: (value: string) => void;
}
const InputSection = ({ value, onChange }: InputSectionProps) => {
    const { t } = useTranslation(['common']);

  return (
    <div className="position-relative">
      <Input
        type="text"
        className="form-control chat-input bg-light border-light"
        id="chat-input"
        placeholder={t('common:typeYourMessage')+"..."}
        value={value || ""}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        autoComplete="off"
      />
    </div>
  );
};
export default InputSection;
