import {ContentPeerUserType} from "../../Content/PeerUser/types";
import {ContentPeerChannelStatType, ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {MainUserType} from "../../Main/User/types";
import {MediaFilesType} from "../../../data";
import {STATUS_TYPES} from "../../../components/Constants";
import {ContentConnectorHandlerType} from "../../Content/ConnectorHandler/types";
import {MainMetaType} from "../../Main/common";

export enum ChatsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_FAVOURITES = "@@chats/GET_FAVOURITES",
  GET_DIRECT_MESSAGES = "@@chats/GET_DIRECT_MESSAGES",
  GET_CHANNELS = "@@chats/GET_CHANNELS",

  ADD_CONTACTS = "@@chats/ADD_CONTACTS",
  CREATE_CHANNEL = "@@chats/CREATE_CHANNEL",
  CHANGE_SELECTED_CHAT = "@@chats/CHANGE_SELECTED_CHAT",
  GET_CHAT_USER_DETAILS = "@@chats/GET_CHAT_USER_DETAILS",
  GET_CHAT_USER_CONVERSATIONS = "@@chats/GET_CHAT_USER_CONVERSATIONS",
  TOGGLE_USER_DETAILS_TAB = "@@chats/TOGGLE_USER_DETAILS_TAB",

  // MESSAGE
  ON_SEND_MESSAGE = "@@chats/ON_SEND_MESSAGE",
  RECEIVE_MESSAGE = "@@chats/RECEIVE_MESSAGE",
  READ_MESSAGE = "@@chats/READ_MESSAGE",
  RECEIVE_MESSAGE_FROM_USER = "@@chats/RECEIVE_MESSAGE_FROM_USER",
  DELETE_MESSAGE = "@@chats/DELETE_MESSAGE",
  FORWARD_MESSAGE = "@@chats/FORWARD_MESSAGE",
  DELETE_USER_MESSAGES = "@@chats/DELETE_USER_MESSAGES",
  GET_CHANNEL_DETAILS = "@@chats/GET_CHANNEL_DETAILS",
  TOGGLE_FAVOURITE_CONTACT = "@@chats/TOGGLE_FAVOURITE_CONTACT",
  GET_ARCHIVE_CONTACT = "@@chats/GET_ARCHIVE_CONTACT",
  TOGGLE_ARCHIVE_CONTACT = "@@chats/TOGGLE_ARCHIVE_CONTACT",
  READ_CONVERSATION = "@@chats/READ_CONVERSATION",
  DELETE_IMAGE = "@@chats/DELETE_IMAGE",

  TOGGLE_COLLAPSED_MESSENGER = "@@chats/TOGGLE_COLLAPSED_MESSENGER",
}
export interface ChatsState {
  favourites: Array<any>;
  directMessages: Array<any>;
  channels: Array<MessengerChannelType>;
  channelsMeta?: MainMetaType;
  selectedChat: string | number | null;
  chatUserDetails: any;
  chatUserConversations: {};
  isOpenUserDetails: boolean;
  channelDetails: object;
  archiveContacts: Array<any>;
  archiveContactsMeta?: MainMetaType;
  isCollapsed : boolean;
  isMessageSending : boolean;
  isFavouritesFetched?: boolean;
  getFavouritesLoading?: boolean;
  isDirectMessagesFetched?: boolean;
  getDirectMessagesLoading?: boolean;
  isContactsAdded?: boolean;
  isChannelsFetched?: boolean;
  getChannelsLoading?: boolean;
  isChannelCreated?: boolean;
}

export interface MessengerChannelType{
  uuid: string;
  name: string;
  avatar?: string;
  isChannel: boolean;
  isArchive: boolean;
  description?: string;
  contentChannel?: ContentPeerChannelType;
  members?:Array<MessengerUserType>;
  unreadMessagesCount: number;
  lastMessage?: MessengerMessageType;
  dateTime?: string;
  messages?: Array<MessengerMessageType>;
  status?: STATUS_TYPES;
  images?:MediaFilesType;
  videos?:MediaFilesType;
  files?:MediaFilesType;
  handler?:ContentConnectorHandlerType
}

export interface MessengerChannelMessagesType{
  channel: MessengerChannelType;
  isGroupConversation: boolean;
  messages: Array<MessengerMessageType>;
}

export interface MessengerUserType{
  uuid?: string;
  platformUser?: MainUserType;
  contentUser?: ContentPeerUserType;
  contentChannel? :ContentPeerChannelType;
  messengerChannel? :MessengerChannelType;
  messengerChannels?: Array<MessengerChannelType>;
}

export interface MessengerMessageType{
  uuid: string;
  text: string;
  dateTime?: string;
  isCanDelete?: boolean;
  isError?: boolean;
  isRead?:boolean;
  isReceived?:boolean;
  isSend?: boolean;
  isForwarded?: boolean;
  sender?: string;
  receiver?: string;
  replyTo?: MessengerMessageType;
  userData?: MessengerUserType;
  images?:MediaFilesType;
  videos?:MediaFilesType;
  files?:MediaFilesType;

}
