import {ContentConnectorDataParamActionTypes, ContentConnectorDataParamPayload} from "./types";

export const contentConnectorDataParamApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentConnectorDataParamActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentConnectorDataParamApiResponseError = (actionType: string, error: string) => ({
  type: ContentConnectorDataParamActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentConnectorDataParams = () => ({
  type: ContentConnectorDataParamActionTypes.GET_CONTENT_CONNECTOR_DATA_PARAMS,
});
