import React from "react";
import { Card, CardBody, Col } from 'reactstrap';
import {
    ContentConnectorType
} from "../../../redux/Content/Connector/types";
import {getHandlerIconByCode} from "../../../helpers/functions";

interface ItemProps {
    item: ContentConnectorType;
    onClick: (item:ContentConnectorType) => void,
    isSelected: boolean
}
const ListButtonItem = ({ item, onClick, isSelected }: ItemProps) => {

    return (
        <Col xxl={3} sm={6} className="project-card">
            <a onClick={() => onClick(item)}>
            <Card className={"card-height-100 card-animate" + (isSelected ? ' bg-soft-primary' : '') }>
                <CardBody>
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                            <div className="mb-2">
                                <div className="avatar-xs position-relative">
                                    <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                        { getHandlerIconByCode(item.handler.code) !== null  ?
                                            <img src={getHandlerIconByCode(item.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                                            :
                                            item.name[0]
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 d-flex align-items-center">
                                <h5 className="mb-0 fs-12 text-center">
                                    {item.name}
                                </h5>
                            </div>

                    </div>
                </CardBody>
            </Card>
            </a>
        </Col>
    );
};

export default ListButtonItem;
