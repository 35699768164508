import {PeerActivityModeActionTypes, PeerActivityModePayload} from "./types";

export const contentPeerActivityModeApiResponseSuccess = (actionType: string, data: any) => ({
  type: PeerActivityModeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentPeerActivityModeApiResponseError = (actionType: string, error: string) => ({
  type: PeerActivityModeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPeerActivityModes = () => ({
  type: PeerActivityModeActionTypes.GET_CONTENT_PEER_ACTIVITY_MODES,
});
