import {SalePriceTypeActionTypes, SalePriceTypePayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const salePriceTypeApiResponseSuccess = (actionType: string, data: any) => ({
  type: SalePriceTypeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const salePriceTypeApiResponseError = (actionType: string, error: string) => ({
  type: SalePriceTypeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSalePriceTypes = (data: MainMetaType) => ({
  type: SalePriceTypeActionTypes.GET_SALE_PRICE_TYPES,
  payload: data,
});

export const showRightModalSalePriceType= (data: SalePriceTypePayload) => ({
  type: SalePriceTypeActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSalePriceType= () => ({
  type: SalePriceTypeActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSalePriceType = (data: any) => ({
  type: SalePriceTypeActionTypes.UPDATE_SALE_PRICE_TYPE,
  payload: data,
});

export const editDataSalePriceType = (data: SalePriceTypePayload) => ({
  type: SalePriceTypeActionTypes.EDIT_SALE_PRICE_TYPE,
  payload: data,
});

export const removeSalePriceTypeModalShow = (data: SalePriceTypePayload) => ({
  type: SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE_MODAL_SHOW,
  payload: data,
});

export const removeSalePriceTypeModalHide = () => ({
  type: SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE_MODAL_HIDE,
});

export const onRemoveSalePriceType = (data: SalePriceTypePayload) => ({
  type: SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE,
  payload: data,
});