import {PeerActivityType} from "../PeerActivity/types";

export enum PeerActivityIdeaActionTypes {
  API_RESPONSE_SUCCESS = "@@peerActivityIdea/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@peerActivityIdea/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_ACTIVITIES_IDEAS = "@@peerActivityIdea/GET_CONTENT_PEER_ACTIVITIES_IDEAS",
  SET_NEED_RELOAD = "@@peerActivityIdea/SET_NEED_RELOAD",
  SHOW_MODAL = "@@peerActivityIdea/SHOW_MODAL",
  HIDE_MODAL = "@@peerActivityIdea/HIDE_MODAL",
  SET_ACTIVITY_MODE = "@@peerActivityIdea/SET_ACTIVITY_MODE",
  SET_INITIALIZED = "@@peerActivityIdea/SET_INITIALIZED",
  UPDATE_PEER_ACTIVITIES = "@@peerActivityIdea/UPDATE_PEER_ACTIVITIES",
}

export interface PeerActivityIdeaState {
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem?: PeerActivityType;
  editItems?: Array<PeerActivityType>
  modalEditTitle: string;
  startDate?:any;
  endDate?:any;
  isNeedReload?:boolean
  isLoaded?:boolean
  isInitialized?:boolean
  isSending: boolean,
  requestId: string;
  getPeerActivityLoading: boolean;
  isPeerActivityFetched: boolean;
  mode?: string;
}
