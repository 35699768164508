import {ServiceMessageType} from "../../Content/PeerActivity/types";

export interface MainFileType{
  uuid?: string;
  url: string;
  fileName?: string;
  fileSize?: number;
  formattedSize?: string;
  downLoadUrl?: string;
  dateTime? : string;
  contentType?: string;
  canEdit?: boolean;
  canRemove?: boolean;
  sort?: number;
  enabled?: boolean;
  width?:number;
  height?:number;
  serviceMessages?: Array<ServiceMessageType>;
}

export enum MainFileImageTypes {
  JPG = "jpg",
  JPEG = "jpeg",
  PNG = "png",
  GIF = "gif",
}

export enum MainFileVideoTypes {
  MP4 = "mp4",
  AVI = "avi",
  MOV = "mov",
}
