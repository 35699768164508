import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import {
    hideRightModalSaleOrderCustomer, onSearchSaleOrderCustomer,
    onSendDataSaleOrderCustomer
} from "../../../redux/Sale/OrderCustomer/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import ButtonSpinner from "../../../components/ButtonSpinner";
import InputSelect from "../../../components/InputSelect";
import {SaleOrderCustomerValidatorSchema} from "./orderCustomerValidator";
import {OrderCustomerType} from "../../../redux/Sale/OrderCustomer/types";
import InputPhone from "../../../components/InputPhone";
import {CustomerType} from "../../../redux/Sale/Customer/types";

interface ItemProps {
}

const ModalCustomerForm = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [searchCustomers, setSearchCustomers] = useState<CustomerType[]>([]);

    const {
        saleOrderCustomerState,
        isSending
    } = useAppSelector(state => ({
        saleOrderCustomerState: state.SaleOrderCustomer,
        isSending: state.SaleOrderCustomer.isSending
    }));

    const uuid = saleOrderCustomerState.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<OrderCustomerType>(saleOrderCustomerState.selectedItem);

    useEffect(() => {

        if (saleOrderCustomerState?.rightModalShow && saleOrderCustomerState.rightModalShow) {
            setCurrentFormValues(saleOrderCustomerState.selectedItem);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, saleOrderCustomerState?.rightModalShow]);

    useEffect(() => {

        if (saleOrderCustomerState?.searchCustomers && (saleOrderCustomerState.searchCustomers ||[]).length>0) {

            let _searchCustomers = saleOrderCustomerState.searchCustomers.map((customer:CustomerType)=>{

                if (customer?.phone && customer.phone !== null){
                    customer.name += ' / '+customer.phone
                }
                if (customer?.email && customer.email !== null){
                    customer.name += ' / '+customer.email
                }

                return customer;
            })

            setSearchCustomers(_searchCustomers);
        } else {
            setSearchCustomers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleOrderCustomerState?.searchCustomers]);

    const setCurrentFormValues = (item: OrderCustomerType) => {
        let _formValues : any = item;


        setFormValues(_formValues);
    }

    const handleClose = () => dispatch(hideRightModalSaleOrderCustomer());

    const onDataSubmit = (formData:OrderCustomerType) => {

        let params: OrderCustomerType = {
            name: formData.name && formData.name,
            email: formData.email && formData.email,
            phone: formData.phone && formData.phone,
            tin: formData.tin && formData.tin,
        };

        if (uuid === null && saleOrderCustomerState?.selectedItem?.saleOrder?.uuid) {
            params = {
                ...params,
                saleOrder: {
                    uuid: saleOrderCustomerState.selectedItem.saleOrder.uuid
                }
            }
        }

        if (formData['customer'] !== undefined) {
            if (formData['customer']['value'] !== undefined) {
                params = {
                    ...params,
                    customer: {
                        uuid: formData['customer']['value']
                    }
                }
            } else {
                params = {
                    ...params,
                    customer: formData['customer']
                }
            }
        }

        dispatch(onSendDataSaleOrderCustomer(params));
    };

    const handleSearchCustomer = (event: any) => {
        if (event.length>1) {
            dispatch(onSearchSaleOrderCustomer({inputValue: event}));
        }
    }

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleOrderCustomerState.rightModalShow}
                    id="saleOrderCustomerEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleOrderCustomerValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('customer:'+saleOrderCustomerState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                                    <Row>
                                        <div className={ "mb-3"+ (touched.customer ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('customer:customer')}</Label>

                                            <Field
                                                id="form-customer-input"
                                                name="customer"
                                                placeholder={t('customer:chooseCustomer')}
                                                component={InputSelect}
                                                value={getFieldProps('customer').value}
                                                onChange={(value: string) => {
                                                    setFieldValue('customer', value);
                                                }}
                                                onKeyPress = {handleSearchCustomer}
                                                isLoading={saleOrderCustomerState.isSearchCustomers}
                                                items={searchCustomers || []}
                                                className={errors.customer && touched.customer && 'form-control is-invalid'}
                                            />

                                            {errors.customer && touched.customer ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.customer)}
                                                </div>
                                            ) : touched.customer && !errors.customer ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-name-input">{t('customer:customerName')}</Label>
                                            <Field
                                                id="form-name-input"
                                                name="name"
                                                placeholder={t('customer:enterCustomerName')}
                                                component={InputText}
                                                value = {getFieldProps('name').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('name', value);
                                                }}
                                                className={errors.name && touched.name && 'form-control is-invalid'}
                                            />
                                            {errors.name && touched.name ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.name)}
                                                </div>
                                            ) :  touched.name && !errors.name ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.phone ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-phone-input">{t('customer:customerPhone')}</Label>
                                            <Field
                                                id="form-phone-input"
                                                name="phone"
                                                placeholder={t('customer:enterCustomerPhone')}
                                                component={InputPhone}
                                                value = {getFieldProps('phone').value}
                                                onChange={(phone:string) => {
                                                    setFieldValue('phone', phone);
                                                }}
                                                inputClassName={errors.phone && touched.phone ? 'form-control is-invalid' : "form-control"}
                                            />
                                            {errors.phone && touched.phone ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.phone)}
                                                </div>
                                            ) :  touched.phone && !errors.phone ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.email ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-email-input">{t('customer:customerEmail')}</Label>
                                            <Field
                                                id="form-email-input"
                                                name="email"
                                                placeholder={t('customer:enterCustomerEmail')}
                                                component={InputText}
                                                value = {getFieldProps('email').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('email', value);
                                                }}
                                                className={errors.email && touched.email && 'form-control is-invalid'}
                                            />
                                            {errors.email && touched.email ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.email)}
                                                </div>
                                            ) :  touched.email && !errors.email ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.tin? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-tin-input">{t('customer:customerTin')}</Label>
                                            <Field
                                                id="form-tin-input"
                                                name="tin"
                                                placeholder={t('customer:enterCustomerTin')}
                                                component={InputText}
                                                value = {getFieldProps('tin').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('tin', value);
                                                }}
                                                className={errors.tin && touched.tin && 'form-control is-invalid'}
                                            />
                                            {errors.tin && touched.tin ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.tin)}
                                                </div>
                                            ) :  touched.tin && !errors.tin ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default ModalCustomerForm;