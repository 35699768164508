import React, { useEffect, useState } from "react";
import { BrowserRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// hooks
import { useRedux } from "../../../hooks";

import {
    editDataContentStatCounter,
    getContentStatCounters,
    onSendDataContentStatCounter,
    onRemoveContentStatCounter,
    removeContentStatCounterModalHide,
    removeContentStatCounterModalShow,
    showRightModalContentStatCounter,
    hideRightModalContentStatCounter,
    getContentProjects,
    getContentConnectorHandlers, getContentConnectors,

} from "../../../redux/actions";

import {ContentStatCounterActionTypes, ContentStatCounterType} from "../../../redux/Content/StatCounter/types";
import ListItem from "./ListItem";
import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";
import RemoveModal from "../../../components/RemoveForm";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    ContentStatCounterHideRemoveModal, ContentStatCounterOnRemove,
    ContentStatCounterOpenModal,
    ContentStatCounterOpenRemoveModal
} from "./ContentStatCounerActions";
import BreadCrumb from "../../../components/Common/BreadCrumb";

const StatCounterIndex = () => {
    const { dispatch, useAppSelector } = useRedux();
    const { t } = useTranslation(['common']);

    const [statCounters, setStatCounters] = useState<ContentStatCounterType[]>([]);

    const {
        contentStatCounters,
    } = useAppSelector(state => ({
        contentStatCounters: state.ContentStatCounter,
    }));

    useEffect(() => {
        dispatch(getContentProjects());
        dispatch(getContentConnectors());
        dispatch(getContentStatCounters());
    }, [dispatch]);

    useEffect(() => {
        if ((contentStatCounters.items !== undefined) && (contentStatCounters.items.length>0)){
            setStatCounters([...contentStatCounters.items]);
        } else {
            setStatCounters([]);
        }
    }, [contentStatCounters?.items])


    return (
        <>
            <BreadCrumb title={t('menu:counters')} pageTitle={t('menu:main')} />
                <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary" onClick={(event)=>ContentStatCounterOpenModal(event,dispatch)} className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>
                <div className="row">
                    <div>
                        <h5 className="mb-2">{t('statCounter:myStatCounters')}</h5>
                        <div className="row">
                            {(statCounters || []).map((item: ContentStatCounterType, key: number) => (
                                <Col xxl={3} sm={6} className="project-card" key={'contentStatCounter_'+item.uuid}>
                                <ListItem
                                    item={item}
                                    onEdit={(event)=>ContentStatCounterOpenModal(event,dispatch)}
                                    onRemove = {(event)=>ContentStatCounterOpenRemoveModal(event,dispatch)}
                                />
                                </Col>
                            ))}
                        </div>
                    </div>
                </div>
                <RightModal/>
                <RemoveModal
                    onRemove={(event)=>ContentStatCounterOnRemove(event,dispatch)}
                    handleClose={()=>ContentStatCounterHideRemoveModal(dispatch)}
                    removeItem={contentStatCounters.selectedItem}
                    isOpen={contentStatCounters.removeModalShow}
                    modalTitle={contentStatCounters.modalEditTitle}/>
        </>
    );
};

export default StatCounterIndex;


