import React, { useEffect, useState } from "react";
import {useRedux} from "../../../hooks";

import {
    StatPeerChannelDataType,
} from "../../../redux/Stat/PeerChannel/types";

import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import ChannelActivitiesChartItem from "../../../components/Dashboard/ChannelActivitiesChartItem";
import ChannelItemLine from "../../Content/PeerChannel/channelItemLine";

interface ItemProps {
    index : number;
    group? : string;
    id?: string;
}

const ComparePeerChannelItemChart = ({index, group, id}:ItemProps) => {

    const { t } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();

    const [currentChannel, setCurrentChannel] = useState<ContentPeerChannelType>(null);
    const [activitiesData, setActivitiesData] = useState<StatPeerChannelDataType[]>([]);

    const {
        comparePeerChannel,
        contentPeerChannels,
        isFetched
    } = useAppSelector(state => ({
        comparePeerChannel: state.ComparePeerChannel,
        contentPeerChannels: state.ContentPeerChannel,
        isFetched: state.ContentPeerChannel.isFetched
    }));

    useEffect(() => {

        let isSetActivitiesData = false;

        if (comparePeerChannel?.itemsData !== undefined) {
            if (
                (comparePeerChannel.itemsData[index] !== undefined)
                &&
                (comparePeerChannel.itemsData[index] !== null)
            ){

                if (
                    (comparePeerChannel.itemsData[index].activitiesData !== undefined)
                    &&
                    (comparePeerChannel.itemsData[index].activitiesData !== null)
                ) {
                    setActivitiesData([...comparePeerChannel.itemsData[index].activitiesData]);
                    isSetActivitiesData =  true;
                }

            }
        }

        if (!isSetActivitiesData){
            setActivitiesData([]);
        }

// eslint-disable-next-line
    }, [comparePeerChannel?.itemsData[index]])

    useEffect(() => {

        let isSetCurrentChannel = false;

        if (comparePeerChannel?.items !== undefined) {
            if (
                (comparePeerChannel.items[index] !== undefined)
                &&
                (comparePeerChannel.items[index] !== null)
            ){

                if (
                    (comparePeerChannel.items[index].uuid !== undefined)
                    &&
                    (comparePeerChannel.items[index].uuid !== null)
                ) {
                    setCurrentChannel({...comparePeerChannel.items[index]});
                    isSetCurrentChannel =  true;
                }

            }
        }

        if (!isSetCurrentChannel){
            setCurrentChannel(null);
        }

// eslint-disable-next-line
    }, [comparePeerChannel?.items[index]])

    return (
        <>
            {activitiesData && activitiesData.length>0 &&
                <>
            {currentChannel && currentChannel.uuid &&
                <ChannelItemLine item={currentChannel}/>
            }
            <ChannelActivitiesChartItem items={activitiesData} chartType={'line'} group={group} id={id}/>
                </>
            }
        </>
    );
};

export default ComparePeerChannelItemChart;


