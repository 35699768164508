import React from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {getHandlerIconByCode} from "../../../helpers/functions";
import {MessengerChannelType, MessengerMessageType} from "../../../redux/Messenger/Chat/types";
import {
    changeSelectedChat,
    getChannelDetails,
    getChatUserConversations,
    readConversation,
    toggleCollapsedMessenger
} from "../../../redux/Messenger/Chat/actions";
import {useRedux} from "../../../hooks";
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";

interface ItemProps {
    item: MessengerMessageType;
    message?: string;
    cabinet?: MainCabinetType
}
const ToastItem = ({ item, message, cabinet }: ItemProps) => {

    const { t } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    const {
        messengerChat,
        messengerComment,
        isCollapsed,
        cabinetState
    } = useAppSelector(state => ({
        messengerChat: state.MessengerChat,
        messengerComment: state.MessengerComment,
        isCollapsed: state.MessengerChat.isCollapsed,
        cabinetState: state.MainCabinet
    }));

    const hasImages = item?.images && item?.images?.total !== undefined && item?.images?.total > 0;
    const hasVideos = item?.videos && item?.videos?.total !== undefined && item?.videos?.total > 0;
    const hasFiles = item?.files && item?.files?.total !== undefined && item?.files?.total > 0;

    const externalCabinet = (cabinetState.items || []).filter((cabinetItem:MainCabinetType)=>{
        return cabinet?.uuid && cabinet.uuid.length>0 && cabinetItem.uuid === cabinet.uuid
    }).pop();

    const handleClick = () => {

        if (externalCabinet && externalCabinet.uuid !== undefined){
            return;
        }

        const chatChannel = (messengerChat.channels || []).filter((itemChannel: MessengerChannelType)=>itemChannel.uuid === item.receiver).pop();
        const commentChannel = (messengerComment.channels || []).filter((itemChannel: MessengerChannelType)=>itemChannel.uuid === item.receiver).pop();

        if (chatChannel !== undefined){
            if (isCollapsed){
                dispatch(toggleCollapsedMessenger());
            }

            dispatch(readConversation(item.receiver));
            dispatch(getChatUserConversations(item.receiver));
            dispatch(changeSelectedChat(item.receiver));
            dispatch(getChannelDetails(item.receiver));

        } else if (commentChannel !== undefined){

            window.location.href = route.MESSENGER_COMMENTS;

        //    dispatch(getCommentsChannelDetails(commentChannel));
        }

    }




    return (
        <>{ externalCabinet && externalCabinet?.uuid &&
            <div className="position-absolute top-0 end-0 mt-1 me-1" style={{zIndex:1}}>
                <span className="badge rounded-pill text-bg-info">{externalCabinet.name.substring(0, 10)}{externalCabinet.name.length >= 10 && '...'}</span>
            </div>
        }
            <Link to="#" onClick={handleClick}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    <span className="avatar-sm rounded-circle bg-gradient">
                        { item?.userData?.contentUser?.avatar && true ?
                            <>
                                <img src={item.userData.contentUser.avatar} alt="" className="avatar-sm rounded-circle" />
                            </>
                        :
                            <>
                                <span className="fs-23 text-muted">
                                     { item?.userData?.contentUser?.name && true
                                         ?
                                         item.userData.contentUser.name[0]
                                         :
                                         '#'
                                     }
                                </span>
                            </>
                        }
                    </span>
                    { item?.userData?.contentUser?.handler && item.userData.contentUser.handler?.code ?
                        <div className="avatar-xxs position-absolute toastLabelIcon">
                            <img src={getHandlerIconByCode(item.userData.contentUser.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                        </div>
                    :
                    <span
                        className="toastLabelIcon badge rounded-pill bg-info">
                        <i className="ri-question-answer-line fs-10"/>
                    </span>
                    }
                </div>
                <div className="flex-grow-1 ms-3 mw-0">
                    <h5 className="fs-14 mb-2 text-truncate">
                        { item?.userData?.contentUser?.name && true ?
                            <>
                            {item.userData.contentUser.name.substring(0, 23)}
                            {item.userData.contentUser.name.length >= 23 && '...'}
                            </>
                        :
                            <>
                                unknown
                            </>
                        }
                    </h5>
                    <p className="text-muted text-truncate p-0 m-0">
                        {hasImages ?
                            <span className="align-bottom pe-2"><i className="ri-image-line"/></span>
                            :
                            hasVideos ?
                                <span className="align-bottom pe-2"><i className="ri-video-line"/></span>
                                :
                                hasFiles &&
                                <span className="align-bottom pe-2"><i className="ri-file-line"/></span>
                        }
                    {message &&
                        <>
                        {item.text.substring(0, 25)}{item.text.length >= 25 && '...'}
                        </>
                    }
                    </p>
                </div>
            </div>
            </Link>
        </>
    );
};

export default ToastItem;
