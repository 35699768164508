
import {
    hideRightModalMainDevice,
    onApproveMainDevice,
    onRemoveMainDevice,
    removeMainDeviceModalHide,
    removeMainDeviceModalShow, showRightModalMainDevice
} from "../../../redux/Main/Device/actions";

export const handleApproveDevice = (event:any, dispatch: any) => {
    if ((event !== undefined) && (event?.fingerPrint !== undefined) && (event?.fingerPrint.length>0)){
        dispatch(showRightModalMainDevice({data:event, modalEditTitle: 'newDeviceApproval'}));
    }
};

export const handleDeviceRemoveShow = (event:any, dispatch: any) => {
    if ((event !== undefined) && (event?.uuid.length>0)){
        dispatch(removeMainDeviceModalShow({data: event, modalEditTitle: 'removeApprovedDevice'}));
    }
};
export const handleDeviceRightModalHide = (dispatch: any) => {
    dispatch(hideRightModalMainDevice())
};

export const handleDeviceRemoveHide = (dispatch: any) => {
    dispatch(removeMainDeviceModalHide())
};

export const handleDeviceRemoveAction = (event:any, dispatch: any) => {
    if ((event !== undefined) && (event?.uuid.length>0)){
        dispatch(onRemoveMainDevice({data: event, modalEditTitle: 'removeApprovedDevice'}));
    }
};
export const handleDeviceApproveAction = (event:any, dispatch: any) => {
    if ((event !== undefined) && (event?.fingerPrint !== undefined) && (event?.fingerPrint.length>0)){
        dispatch(onApproveMainDevice({data: event, modalEditTitle: 'newDeviceApproval'}));
    }
};