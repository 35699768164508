import React, {useEffect, useRef, useState} from "react";
import {Col, Row} from "reactstrap";
import useDebounce from "../hooks/useDebounce";

interface ItemProps {
    onChange: (value:any) => void;
    value: string;
    onClick: (value:any)=>void;
}

const MainListFilter = ({value, onChange, onClick}) => {

    const [searchValue, setSearchValue] = useState<string>('')
    const inputRef = useRef(null);


    const handleKeyDown = (event)=> {
        if (event.key === "Enter"){
            onClick(searchValue);
        }
    }
    const handleClick = () => {
        onClick(inputRef.current.value);
    }

    const debouncedSearch = useDebounce(handleClick, 500);

    const onChangeInput = (_value:string)=> {
        setSearchValue(_value);
        onChange(_value);
        debouncedSearch(_value);
    }

    useEffect(()=>{
        let _searchValue = '';
        if (value !== undefined && value!== null) {
            _searchValue = value;
        }

        if (_searchValue !== searchValue) {
            setSearchValue(_searchValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])


    return(
        <Row className="g-3">
            <Col>
                <div className="d-flex flex-row align-items-center">
                    <div className="search-box me-2 d-inline-block">
                        <input
                            onChange={(event)=>{onChangeInput(event.target.value)}}
                            id="search-bar-0"
                            type="text"
                            className="form-control search"
                            placeholder={''}
                            value={searchValue}
                            onKeyDown={handleKeyDown}
                            ref={inputRef}
                        />
                        <i className="bx bx-search-alt search-icon"/>
                    </div>
                    <button
                        type="button"
                        className="btn btn-outline-primary btn-icon waves-effect waves-light"
                        onClick={onClick}
                    ><i
                        className="bx bx-search-alt"/></button>
                </div>
            </Col>
        </Row>
    );
}

export default MainListFilter;