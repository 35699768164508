// types
import {ContentTemplatesActionTypes, ContentTemplatesState, ContentTemplateType} from "./types";
import {NewEmptyActionsItem} from "../../types";

export const NewContentTemplateItem:ContentTemplateType = {
  enabled: true,
  name: '',
  text: ''
}

export const INIT_STATE_CONTENT_TEMPLATES: ContentTemplatesState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewContentTemplateItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const ContentTemplates = (state = INIT_STATE_CONTENT_TEMPLATES, action: any) => {
  switch (action.type) {
    case ContentTemplatesActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentTemplatesActionTypes.GET_CONTENT_TEMPLATES:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload:false
          };
        case ContentTemplatesActionTypes.UPDATE_CONTENT_TEMPLATES:
          return {
            ...state,
            selectedItem: NewContentTemplateItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES:
          return {
            ...state,
            selectedItem: NewContentTemplateItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case ContentTemplatesActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentTemplatesActionTypes.GET_CONTENT_TEMPLATES:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        case ContentTemplatesActionTypes.UPDATE_CONTENT_TEMPLATES:
          return {
            ...state,
            isSending: false
          };
        case ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES:
          return {
            ...state,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }
    case ContentTemplatesActionTypes.EDIT_CONTENT_TEMPLATES:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentTemplatesActionTypes.UPDATE_CONTENT_TEMPLATES:
      return {
        ...state,
        isSending: true
      };
    case ContentTemplatesActionTypes.RIGHT_MODAL_SHOW:

      let newItem = NewContentTemplateItem;

      if ((action.payload.data !== undefined) && (action.payload.data.text !== undefined)){

        if (action.payload.data.text === null){
          action.payload.data.text='';
        }

        newItem={...action.payload.data};
      }

      return {
        ...state,
        selectedItem: newItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentTemplateItem,
        removeModalShow: false
      }
    case ContentTemplatesActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case ContentTemplatesActionTypes.GET_CONTENT_TEMPLATES:
      return {
        ...state,
        isLoading: false
      }
    default:
      return { ...state };
  }
};

export default ContentTemplates;
