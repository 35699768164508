// types
import moment from 'moment';
import {StatPeerActivityState} from "./types";
import {StatPeerActivityContentActionTypes} from "./types";

export const INIT_STATE_STAT_ACTIVITY_CONTENT: StatPeerActivityState = {
  tiles: [],
  activitiesData: [],
  engagementsData: [],
  targets: [],
  periodFrom: moment().add(-7, 'days').utc().unix(),
  periodTo: moment().utc().unix(),
  deltaDays: 7,
  isStatPeerActivityContentFetched: false,
  isStatPeerActivityContentTargetFetched: false
};

const StatPeerActivityContent = (state = INIT_STATE_STAT_ACTIVITY_CONTENT, action: any) => {
  switch (action.type) {
    case StatPeerActivityContentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TILES:
          return {
            ...state,
            tiles: action.payload.data.items,
            isStatPeerActivityContentFetched: true,
            getStatPeerActivityContentLoading: false,
          };
        case StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ACTIVITIES_DATA:
          return {
            ...state,
            activitiesData: action.payload.data.items,
            isStatPeerActivityContentFetched: true,
            getStatPeerActivityContentLoading: false,
          };
        case StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ENGAGEMENTS_DATA:
          return {
            ...state,
            engagementsData: action.payload.data.items,
            isStatPeerActivityContentFetched: true,
            getStatPeerActivityContentLoading: false,
          };
        case StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TARGETS:
          return {
            ...state,
            targets: action.payload.data.items,
            isStatPeerActivityContentTargetFetched: true,
            getStatPeerActivityContentLoading: false,
          };
        case StatPeerActivityContentActionTypes.GET_ACTIVITY:

          if (action.payload.data.items.length>0) {
            return {
              ...state,
              activity: action.payload.data.items.pop(),
              isStatPeerActivityContentFetched: true,
              getStatPeerActivityContentLoading: false,
            };
          } else {
            return {
              ...state,
              isStatPeerActivityContentFetched: true,
              getStatPeerActivityContentLoading: false,
            };
          }
        default:
          return { ...state };
      }

    case StatPeerActivityContentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TARGETS:
          return {
            ...state,
            isStatPeerActivityContentTargetFetched: true,
            getStatPeerActivityContentLoading: false,
          };
        default:
          return { ...state };
      }
    case StatPeerActivityContentActionTypes.GET_ACTIVITY:
      return {
        ...state,
        isStatPeerActivityContentFetched: false,
        getStatPeerActivityContentLoading: false,
      };
    case StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TARGETS:
      return {
        ...state,
        isStatPeerActivityContentTargetFetched: false,
        getStatPeerActivityContentLoading: true,
      };
    case StatPeerActivityContentActionTypes.SET_PERIOD:

      let periodFrom = moment(action.payload.periodFrom,'X');
      let periodTo = moment(action.payload.periodTo,'X');

      return {
        ...state,
        periodFrom: action.payload.periodFrom,
        periodTo: action.payload.periodTo,
        deltaDays: moment.duration(periodTo.diff(periodFrom)).asDays(),
        selectedDate: action.payload.selectedDate && action.payload.selectedDate
      };

    default:
      return { ...state };
  }
};

export default StatPeerActivityContent;
