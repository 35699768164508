import React from "react";
import {Card, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import * as route from "../api/routes";
import {useTranslation} from "react-i18next";

const WizardNotice = () => {
    const robot_butterfly = require("../resources/images/robot_butterfly.svg");
    const robot_goal = require("../resources/images/robot_goal.svg");
    const { t } = useTranslation();

  return (
      <Card className="overflow-hidden card-animate card-height-100">
          <CardBody className="d-flex">
              <div className="flex-grow-1">
                  <h4 className="fs-16 lh-base mb-0 ">{t('wizard:wizardDashboardTitle')}</h4>

                  <p className="mb-0 mt-2 pt-1 text-muted">{t('wizard:wizardDashboardDescription')}</p>
                  <div className="mt-2">
                      <small className="text-muted">{t('wizard:wizardDashboardNotice')}</small>
                  </div>
                  {/*<p className="mb-0 mt-2 pt-1 text-muted">The world's first and largest digital marketplace.</p>*/}
                  <div className="d-flex gap-3 mt-4">
                      <div className="d-flex flex-row align-items-center">
                          <Link to={route.MAIN_WIZARD_INIT} className="btn btn-primary">{t('wizard:wizardDashboardButton')}</Link>
                      </div>
                  </div>
              </div>
              <div className="d-flex flex-column justify-content-end wizardNoticeImageCont flex-shrink-0">
                    <img src={robot_goal.default} alt="" className="img-fluid"/>
              </div>
          </CardBody>
      </Card>
  );
};
export default WizardNotice;
