import React from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {wsMessageEventTypes} from "../../../api/WebSocket/webSocketTypes";
import {EventWebhookType} from "../../../redux/Event/Webhook/types";

interface ItemProps {
    webhook: EventWebhookType;
    action?: string;

}
const WebhookItem = ({ webhook, action }: ItemProps) => {

    const { t } = useTranslation(['common']);

    return (
        <>
            <Link to={route.EVENT_WEBHOOKS_LIST}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    <span className="avatar-sm rounded-circle bg-gradient">
                        <i className={"bx bx-reset fs-20"}/>
                    </span>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h5 className="fs-14 mb-2">
                        {webhook.name}
                    </h5>
                    <p className="text-muted text-truncate p-0 m-0">
                        {action === wsMessageEventTypes.EVENT_WEBHOOK_ERROR &&
                            t('webhook:errorWebhookValidation') //code // creationDate
                        }
                    </p>
                </div>
            </div>
            </Link>
        </>
    );
};

export default WebhookItem;
