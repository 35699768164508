import React, {useEffect, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { Link } from 'react-router-dom';
import {MainUserType} from "../../../redux/Main/User/types";
import {textTruncate} from "../../../helpers/functions";

interface ItemProps {
    item: MainUserType;
}
const UserItemLine = ({ item }: ItemProps) => {

    const [name, setName] = useState<string>(item.name)

    useEffect(()=>{

        if (item.name.length<1){
            setName(item.email);
        } else {
            setName(item.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item?.name, item?.uuid])

    return(
        <>
            <div className="d-flex flex-shrink-0 mw-0 overflowInitial">
                <div className="avatar-xs me-2 d-flex flex-shrink-0 position-relative overflowInitial">
                <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title={item.name}>
                    <OverlayTrigger
                        placement={'top'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip>
                                {item.name}
                            </Tooltip>
                        }
                    >
                        <div className="avatar-xs">
                            { item.avatar
                                ?
                                <img src={item.avatar} alt="" className="rounded-circle img-fluid" />
                                :
                                <div className="avatar-title rounded-circle bg-light border-dashed border text-primary text-capitalize"> {item.name[0]} </div>
                            }
                        </div>
                    </OverlayTrigger>
                </Link>
                </div>
                <div className="flex-1 justify-content-center flex-column d-flex mw-0 me-1">
                    <span className="text-truncate">
                        {textTruncate(name,23)}
                    </span>
                </div>
            </div>
        </>
    )
}

export default UserItemLine;