import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentStatCounters = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_STAT_COUNTERS);
};

const sendDataContentStatCounter = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_STAT_COUNTER, data);
};

const removeContentStatCounter = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_STAT_COUNTER, data);
};

export {
    getContentStatCounters,
    sendDataContentStatCounter,
    removeContentStatCounter
};
