import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleOrderStatusActionTypes } from "./types";
import { saleOrderStatusApiResponseSuccess, saleOrderStatusApiResponseError } from "./actions";

import {
  getSaleOrderStatuses as getSaleOrderStatusesApi,
  sendDataSaleOrderStatus as sendDataSaleOrderStatusApi,
  removeSaleOrderStatus as removeSaleOrderStatusApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleOrderStatuses({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleOrderStatusesApi, data);
    yield put(saleOrderStatusApiResponseSuccess(SaleOrderStatusActionTypes.GET_SALE_ORDER_STATUSES, response));
  } catch (error: any) {
    yield put(saleOrderStatusApiResponseError(SaleOrderStatusActionTypes.GET_SALE_ORDER_STATUSES, error));
  }
}

function* onSendDataSaleOrderStatus({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleOrderStatusApi, data);
    yield put(
        saleOrderStatusApiResponseSuccess(SaleOrderStatusActionTypes.UPDATE_SALE_ORDER_STATUS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderStatusApiResponseError(SaleOrderStatusActionTypes.UPDATE_SALE_ORDER_STATUS, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleOrderStatus({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleOrderStatusApi, {uuid: data.data.uuid});
    yield put(
        saleOrderStatusApiResponseSuccess(SaleOrderStatusActionTypes.REMOVE_SALE_ORDER_STATUS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderStatusApiResponseError(SaleOrderStatusActionTypes.REMOVE_SALE_ORDER_STATUS, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleOrderStatuses() {
  yield takeEvery(SaleOrderStatusActionTypes.GET_SALE_ORDER_STATUSES, getSaleOrderStatuses);
}
export function* watchOnSendDataSaleOrderStatus() {
  yield takeEvery(SaleOrderStatusActionTypes.UPDATE_SALE_ORDER_STATUS, onSendDataSaleOrderStatus);
}
export function* watchOnRemoveSaleOrderStatus() {
  yield takeEvery(SaleOrderStatusActionTypes.REMOVE_SALE_ORDER_STATUS, onRemoveSaleOrderStatus);
}

function* saleOrderStatusSaga() {
  yield all([
    fork(watchGetSaleOrderStatuses),
    fork(watchOnSendDataSaleOrderStatus),
    fork(watchOnRemoveSaleOrderStatus)
  ]);
}

export default saleOrderStatusSaga;
