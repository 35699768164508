import {MainCabinetActionTypes, MainCabinetPayload, MainCabinetType} from "./types";
import {MainSettingsState} from "../Layout/types";

export const mainCabinetApiResponseSuccess = (actionType: string, data: any) => ({
  type: MainCabinetActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const mainCabinetApiResponseError = (actionType: string, error: string) => ({
  type: MainCabinetActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getUserCabinets = () => ({
  type: MainCabinetActionTypes.GET_USER_CABINETS,
});

export const createUserCabinet = () => ({
  type: MainCabinetActionTypes.CREATE_USER_CABINET,
});

export const selectCurrentUserCabinet = (cabinet:MainCabinetType) => ({
  type: MainCabinetActionTypes.SELECT_USER_CABINET,
  payload: { data: cabinet },
});

export const updateUserCabinet = (cabinet:MainCabinetType) => ({
  type: MainCabinetActionTypes.UPDATE_CABINET,
  payload: cabinet
});

export const selectUpdateUserCabinet = (cabinet:MainCabinetType) => ({
  type: MainCabinetActionTypes.SELECT_UPDATE_USER_CABINET,
  payload: cabinet
});

export const clearSelectUserCabinet = () => ({
  type: MainCabinetActionTypes.CLEAR_SELECT_UPDATE_USER_CABINET,
});

export const updateCabinetLastLogin = () => ({
  type: MainCabinetActionTypes.UPDATE_CABINET_LASTLOGIN,
});
export const resumeUserCabinet = (cabinet:MainCabinetType) => ({
  type: MainCabinetActionTypes.RESUME_USER_CABINET,
  payload: cabinet
});

export const getUserCabinetFromRemote = () => ({
  type: MainCabinetActionTypes.GET_CURRENT_CABINET
});

export const saveCurrentCabinetSettings = (settings:MainSettingsState) => ({
  type: MainCabinetActionTypes.SAVE_CURRENT_CABINET_SETTINGS,
  payload: settings
});