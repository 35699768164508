import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum SalePriceTypeActionTypes {
    API_RESPONSE_SUCCESS = "@@salePriceType/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@salePriceType/API_RESPONSE_ERROR",
    GET_SALE_PRICE_TYPES = "@@salePriceType/GET_SALE_PRICE_TYPES",
    UPDATE_SALE_PRICE_TYPE = "@@salePriceType/UPDATE_SALE_PRICE_TYPE",
    REMOVE_SALE_PRICE_TYPE_MODAL_SHOW = "@@salePriceType/REMOVE_SALE_PRICE_TYPE_MODAL_SHOW",
    REMOVE_SALE_PRICE_TYPE_MODAL_HIDE = "@@salePriceType/REMOVE_SALE_PRICE_TYPE_MODAL_HIDE",
    REMOVE_SALE_PRICE_TYPE = "@@salePriceType/REMOVE_SALE_PRICE_TYPE",
    EDIT_SALE_PRICE_TYPE = "@@salePriceType/EDIT_SALE_PRICE_TYPE",
    RIGHT_MODAL_SHOW = "@@salePriceType/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@salePriceType/RIGHT_MODAL_HIDE",
}

export interface SalePriceTypeState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: PriceTypeType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface PriceTypeType {
    uuid?: string;
    name?: string;
    code?: string;
    enabled?: boolean;
    order?: number;
    isBase?: boolean;
    idRemote?: string;
    actions?: EntityActionsType
}

export interface SalePriceTypePayload {
    data: Array<any>;
    modalEditTitle : string;
}
