import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { PeerActivityModeActionTypes } from "./types";
import { contentPeerActivityModeApiResponseSuccess, contentPeerActivityModeApiResponseError } from "./actions";

import {
  getPeerActivityModes as getPeerActivityModesApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getPeerActivityModes() {
  try {
    const response: Promise<any> = yield call(getPeerActivityModesApi);
    yield put(contentPeerActivityModeApiResponseSuccess(PeerActivityModeActionTypes.GET_CONTENT_PEER_ACTIVITY_MODES, response));
  } catch (error: any) {
    yield put(contentPeerActivityModeApiResponseError(PeerActivityModeActionTypes.GET_CONTENT_PEER_ACTIVITY_MODES, error));
  }
}


export function* watchGetPeerActivityModes() {
  yield takeEvery(PeerActivityModeActionTypes.GET_CONTENT_PEER_ACTIVITY_MODES, getPeerActivityModes);
}


function* contentPeerActivityModeSaga() {
  yield all([
    fork(watchGetPeerActivityModes),
  ]);
}

export default contentPeerActivityModeSaga;
