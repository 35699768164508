import React from 'react';
import * as Yup from 'yup';

export const ContentPeerTargetValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    targetType: Yup.object()
        .required('required'),
    targetDate: Yup.date()
        .typeError("invalidDate")
        .required('required'),
    targetValue: Yup.string()
        .typeError("required")
        .matches(/^[0-9]+$/, "digitsOnly")
        .required('required'),
    isRecurrent: Yup.boolean(),
    recurrentValue: Yup.string().nullable()
        .when("isRecurrent", {
            is: true,
            then: Yup.string().required("required").matches(/^[0-9]+$/, "digitsOnly"),
            otherwise: Yup.string().nullable().notRequired(),
        }),
    recurrentPeriod: Yup.object().nullable()
        .when("isRecurrent", {
            is: true,
            then: Yup.object().required("required"),
            otherwise: Yup.object().nullable().notRequired(),
        })
});

export const ContentPeerTargetChannelValidatorSchema = ContentPeerTargetValidatorSchema.shape({
    channel: Yup.object()
        .typeError("required")
        .required('required'),
});

export const ContentPeerTargetActivityValidatorSchema = ContentPeerTargetValidatorSchema.shape({
    activity: Yup.object()
        .typeError("required")
        .required('required'),
});