export type SessionConnectHandler = (ev: Event) => any;
export type SessionMessageHandler = (ev: MessageEvent<any>) => any;
export type SessionDisconnectHandler = (ev: Event) => any;

export interface Contextable {
    context: string;
}

export interface wsState {
    ws: any[];
    isConnected : boolean;
    isConnecting: boolean;
    messages? : wsMessageType[];
}

export interface WebSocketContextValue extends wsState {
    join(): void;
    reconnect(): void;
    close(): void;
    // start(): void;
    // overrideSkip(): void;
    // requestNextRound(): void;
    // manuallyEndGame(): void;
    // onCardClick(cardId: string): void;
    // onCardButtonClick(): void;
}

export enum ConnectionLifecycle {
    PRE_CREATED,
    GOING_AWAY,
    REFRESHING,
    RESUMED,
    PAUSED,
    FINISHED,
    DESTROYED,
}

// export type ConnectFN = (
//     gameCode: string,
//     name: string | null,
//     playerId?: string
// ) => void;
//
export type SessionHook = [
    // ConnectFN,
    () => void,
    <T extends Contextable>(args: T) => void,
    () => void
];
// export type PauseHandlerHook = [(fn: ConnectFN) => void, () => void];

export interface wsMessageType {
    eTag: string,
    eventType: string,
    data: any,
    status?: string
}

export enum wsMessageLifecycle {
    FINAL= 'F'
}

export enum wsMessageEventTypes {
    CONNECTOR_CONNECTED = "connectorConnected",
    CONNECTOR_RESPONSE_OAUTH_URL = "connectorReturnOAuthUrl",
    CONNECTOR_CHANNEL_SEARCH = "connectorChannelSearch",
    STAT_COUNTER_RESPONSE_TILES_FROM_SERVICE = 'statCounterResponseTilesFromService',
    PEER_CHANNEL_RESPONSE_OAUTH_URL = "peerChannelReturnOAuthUrl",
    SEND_MESSAGE_WEBSOCKET = "messengerMessage.sendWebsocket",
    PEER_ACTIVITY_PUBLISH_TO_SERVICE_SUCCESSFUL = 'peerActivityPublishToServiceSuccessful',
    PEER_ACTIVITY_PUBLISH_TO_SERVICE_FAILED = 'peerActivityPublishToServiceFailed',
    PEER_ACTIVITY_UNPUBLISH_TO_SERVICE_SUCCESSFUL = 'peerActivityUnPublishToServiceSuccessful',
    PEER_ACTIVITY_UNPUBLISH_TO_SERVICE_FAILED = 'peerActivityUnPublishToServiceFailed',
    PEER_ACTIVITY_PIN_TO_SERVICE_SUCCESSFUL = 'peerActivityPinToServiceSuccessful',
    PEER_ACTIVITY_PIN_TO_SERVICE_FAILED = 'peerActivityPinToServiceFailed',
    PEER_ACTIVITY_UNPIN_TO_SERVICE_SUCCESSFUL = 'peerActivityUnPinToServiceSuccessful',
    PEER_ACTIVITY_UNPIN_TO_SERVICE_FAILED = 'peerActivityUnPinToServiceFailed',
    PEER_ACTIVITY_COMMENT_UPDATE_FROM_SERVICE = 'peerActivityCommentUpdateFromService',
    PEER_ACTIVITY_COMMENT_SEND_TO_SERVICE = 'peerActivityCommentSendToService',
    PEER_ACTIVITY_COMMENT_SEND_SUCCESS = 'peerActivityCommentSendSuccess',
    PEER_ACTIVITY_COMMENT_SEND_FAIL = 'peerActivityCommentSendFail',
    PEER_ACTIVITY_COMMENT_DELETE_TO_SERVICE = 'peerActivityCommentDeleteToService',
    PEER_ACTIVITY_COMMENT_DELETE_SUCCESS = 'peerActivityCommentDeleteSuccess',
    PEER_ACTIVITY_COMMENT_DELETE_FAIL = 'peerActivityCommentDeleteFail',
    PEER_ACTIVITY_COMMENT_MARK_AS_SPAM_TO_SERVICE = 'peerActivityCommentMarkAsSpamToService',
    PEER_ACTIVITY_COMMENT_MARK_AS_SPAM_SUCCESS = 'peerActivityCommentMarkAsSpamSuccess',
    PEER_ACTIVITY_COMMENT_MARK_AS_SPAM_FAIL = 'peerActivityCommentMarkAsSpamFail',
    CONNECTOR_UPDATE_MANAGED_CHANNELS = 'connectorUpdateManagedChannels',
    CABINET_CHANNEL_ADDED = 'cabinetChannelAdded',
    PEER_CHANNEL_RESPONSE_HASHTAG_FROM_SERVICE_NOT_FOUND = 'peerChannelResponseHashtagFromServiceNotFound',
    PEER_CHANNEL_RESPONSE_FROM_SERVICE_BY_CODE_NOT_FOUND = 'peerChannelResponseFromServiceByCodeNotFound',
    SUPPORT_TICKET_MESSAGE_CREATED = 'support.ticketMessageCreated',
    PEER_CHANNEL_INIT_SUCCESSFUL = 'peerChannelInitSuccessful',
    MESSENGER_MESSAGE_UPDATE_FROM_SERVICE = 'messengerMessageUpdateFromService',
    PEER_CHANNEL_CONNECTED = 'peerChannelConnected',
    CONNECTOR_CONNECTION_ERROR = 'connectorConnectionError',

    AI_GENERATE_TEXT = 'AIgenerateText',
    AI_GENERATE_TEXT_SUCCESSFUL = 'AIgenerateTextSuccessful',
    AI_GENERATE_TEXT_FAILED= 'AIgenerateTextFailed',
    AI_REWRITE_TEXT = 'AIrewriteText',
    AI_REWRITE_TEXT_SUCCESSFUL = 'AIrewriteTextSuccessful',
    AI_REWRITE_TEXT_FAILED= 'AIrewriteTextFailed',
    AI_GENERATE_TITLE = 'AIgenerateTitle',
    AI_GENERATE_TITLE_SUCCESSFUL = 'AIgenerateTitleSuccessful',
    AI_GENERATE_TITLE_FAILED= 'AIgenerateTitleFailed',
    AI_TRANSLATE_TEXT = 'AItranslateText',
    AI_TRANSLATE_TEXT_SUCCESSFUL = 'AItranslateTextSuccessful',
    AI_TRANSLATE_TEXT_FAILED= 'AItranslateTextFailed',
    AI_PREDICT_TEXT = 'AIpredictText',
    AI_PREDICT_TEXT_SUCCESSFUL = 'AIpredictTextSuccessful',
    AI_PREDICT_TEXT_FAILED= 'AIpredictTextFailed',
    AI_PREDICT_TAGS = 'AIpredictTags',
    AI_PREDICT_TAGS_SUCCESSFUL = 'AIpredictTagsSuccessful',
    AI_PREDICT_TAGS_FAILED= 'AIpredictTagsFailed',
    SALE_ORDER_ADDED = 'saleOrderAdded',
    SALE_ORDER_PAID = 'saleOrderPaid',
    SALE_ORDER_CANCELLED = 'saleOrderCancelled',
    EVENT_WEBHOOK_ERROR = 'webhookApproveError',
    PRODUCT_CATEGORIES_RESPONSE_FROM_REMOTE = 'productCategoriesResponseFromRemote',
    META_GET_USER_BUSINESSES_RESPONSE_FROM_REMOTE = 'metaGetUserBusinessesResponseFromRemote',
    META_GET_BUSINESS_CATALOGS_RESPONSE_FROM_REMOTE = 'metaGetBusinessCatalogsResponseFromRemote',
    SHORTENER_URL_UPDATE_SUCCESSFUL = 'shortenerUrlUpdateSuccessFul',
    SHORTENER_URL_UPDATE_FAILED = 'shortenerUrlUpdateFailed',
    SHORTENER_URL_IS_AVAILABLE_RESPONSE = 'shortenerUrlIsAvailableResponse',
    SHORTENER_URL_IS_NOT_AVAILABLE_RESPONSE = 'shortenerUrlIsNotAvailableResponse',
    TASK_ADDED = 'taskAdded',
    TASK_UPDATED = 'taskUpdated',
    TASK_CLOSED = 'taskClosed',
    TASK_OVERDUE = 'taskOverDue',
    TASK_RETURNED = 'taskReturned',
    COMMENT_ADDED = 'commentAdded',
    MENTIONED_IN_COMMENT = 'mentionedInComment',
}