import { takeEvery, fork, put, all, call } from "typed-redux-saga";

import { supportTicketApiResponseSuccess, supportTicketApiResponseError } from "./actions";

import {
  getSupportTickets as getSupportTicketsApi,
  removeSupportTicket as removeSupportTicketApi,
  updateSupportTicket as updateSupportTicketApi,
  getSupportTicketDetail as getSupportTicketDetailApi,
  rewardSupportTicket as rewardSupportTicketApi,
  getSupportServiceTickets as getSupportServiceTicketsApi,
  getSupportServiceTicketDetail as getSupportServiceTicketDetailApi,
  readSupportTicketMessages as readSupportTicketMessagesApi,
  readSupportServiceTicketMessages as readSupportServiceTicketMessagesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {SupportTicketActionTypes} from "./types";

function* getSupportTickets({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSupportTicketsApi, data);
    yield put(supportTicketApiResponseSuccess(SupportTicketActionTypes.GET_SUPPORT_TICKETS, response));
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.GET_SUPPORT_TICKETS, error));
  }
}
function* getSupportServiceTickets({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSupportServiceTicketsApi, data);
    yield put(supportTicketApiResponseSuccess(SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKETS, response));
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKETS, error));
  }
}
function* getSupportTicketDetail({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSupportTicketDetailApi, data);
    yield put(
        supportTicketApiResponseSuccess(SupportTicketActionTypes.GET_SUPPORT_TICKET_DETAIL, response)
    );
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.GET_SUPPORT_TICKET_DETAIL, error));
  }
}
function* getSupportServiceTicketDetail({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSupportServiceTicketDetailApi, data);
    yield put(
        supportTicketApiResponseSuccess(SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKET_DETAIL, response)
    );
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKET_DETAIL, error));
  }
}
function* onSendDataSupportTicket({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateSupportTicketApi, data);
    yield put(
        supportTicketApiResponseSuccess(SupportTicketActionTypes.UPDATE_SUPPORT_TICKET, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.UPDATE_SUPPORT_TICKET, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSupportTicket({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSupportTicketApi, {uuid: data.data.uuid});
    yield put(
        supportTicketApiResponseSuccess(SupportTicketActionTypes.REMOVE_SUPPORT_TICKET, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.REMOVE_SUPPORT_TICKET, error));
    yield call(showErrorNotification, error);
  }
}

function* onRewardSupportTicket({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(rewardSupportTicketApi, data);
    yield put(
        supportTicketApiResponseSuccess(SupportTicketActionTypes.SEND_REWARD_SUPPORT_TICKET, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.SEND_REWARD_SUPPORT_TICKET, error));
    yield call(showErrorNotification, error);
  }
}

function* onReadSupportTicketMessages({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(readSupportTicketMessagesApi, data);
    yield put(
        supportTicketApiResponseSuccess(SupportTicketActionTypes.READ_SUPPORT_TICKET_MESSAGES, response)
    );
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.READ_SUPPORT_TICKET_MESSAGES, error));
  }
}

function* onReadSupportServiceTicketMessages({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(readSupportServiceTicketMessagesApi, data);
    yield put(
        supportTicketApiResponseSuccess(SupportTicketActionTypes.READ_SUPPORT_SERVICE_TICKET_MESSAGES, response)
    );
  } catch (error: any) {
    yield put(supportTicketApiResponseError(SupportTicketActionTypes.READ_SUPPORT_SERVICE_TICKET_MESSAGES, error));
  }
}

export function* watchGetSupportTickets() {
  yield takeEvery(SupportTicketActionTypes.GET_SUPPORT_TICKETS, getSupportTickets);
}
export function* watchGetSupportTicketDetail() {
  yield takeEvery(SupportTicketActionTypes.GET_SUPPORT_TICKET_DETAIL, getSupportTicketDetail);
}
export function* watchGetSupportServiceTickets() {
  yield takeEvery(SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKETS, getSupportServiceTickets);
}
export function* watchGetSupportServiceTicketDetail() {
  yield takeEvery(SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKET_DETAIL, getSupportServiceTicketDetail);
}
export function* watchOnSendDataSupportTicket() {
  yield takeEvery(SupportTicketActionTypes.UPDATE_SUPPORT_TICKET, onSendDataSupportTicket);
}
export function* watchOnRemoveSupportTicket() {
  yield takeEvery(SupportTicketActionTypes.REMOVE_SUPPORT_TICKET, onRemoveSupportTicket);
}

export function* watchOnRewardSupportTicket() {
  yield takeEvery(SupportTicketActionTypes.SEND_REWARD_SUPPORT_TICKET, onRewardSupportTicket);
}

export function* watchOnReadSupportTicketMessages() {
  yield takeEvery(SupportTicketActionTypes.READ_SUPPORT_TICKET_MESSAGES, onReadSupportTicketMessages);
}
export function* watchOnReadSupportServiceTicketMessages() {
  yield takeEvery(SupportTicketActionTypes.READ_SUPPORT_SERVICE_TICKET_MESSAGES, onReadSupportServiceTicketMessages);
}

function* supportTicketsSaga() {
  yield all([
      fork(watchGetSupportTickets),
      fork(watchGetSupportTicketDetail),
      fork(watchOnSendDataSupportTicket),
      fork(watchOnRemoveSupportTicket),
      fork(watchOnRewardSupportTicket),
      fork(watchGetSupportServiceTickets),
      fork(watchGetSupportServiceTicketDetail),
      fork(watchOnReadSupportTicketMessages),
      fork(watchOnReadSupportServiceTicketMessages)
  ]);
}

export default supportTicketsSaga;
