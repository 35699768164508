// types
import {ContentPeerTargetActionTypes, ContentPeerTargetState, ContentPeerTargetType} from "./types";
import {NewEmptyActionsItem} from "../../types";

export const NewContentPeerTargetItem:ContentPeerTargetType = {
  enabled: true,
  name: '',
  startDate: Date(),
  targetStatus: 'P', //progress
  isUnread:false,
  isRecurrent: false,
  targetValue: null,
  recurrentValue: null,
  recurrentPeriod: null,
  targetDate: null,
  channel: null,
}

export const INIT_STATE_CONTENT_PEER_TARGET: ContentPeerTargetState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewContentPeerTargetItem,
  modalEditTitle: '',
  isNeedReload: false,
  isSending: false,
  isContentPeerTargetFetched: false,
  getContentPeerTargetLoading: false,
};

const ContentPeerTarget = (state = INIT_STATE_CONTENT_PEER_TARGET, action: any) => {
  switch (action.type) {
    case ContentPeerTargetActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentPeerTargetActionTypes.GET_CONTENT_PEER_TARGETS:
          return {
            ...state,
            items: action.payload.data.items,
            isContentPeerTargetFetched: true,
            getContentPeerTargetLoading: false,
            isNeedReload: false
          };
        case ContentPeerTargetActionTypes.UPDATE_CONTENT_PEER_TARGET:
          return {
            ...state,
            rightModalShow: false,
            isNeedReload: true,
            isSending: false
          };
        case ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET:
          return {
            ...state,
            selectedItem: NewContentPeerTargetItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case ContentPeerTargetActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentPeerTargetActionTypes.GET_CONTENT_PEER_TARGETS:
          return {
            ...state,
            isContentPeerTargetFetched: false,
            getContentPeerTargetLoading: false,
          };
        case ContentPeerTargetActionTypes.UPDATE_CONTENT_PEER_TARGET:
          return {
            ...state,
            rightModalShow: false,
            isSending: false
          };
        case ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET:
          return {
            ...state,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }
    case ContentPeerTargetActionTypes.EDIT_CONTENT_PEER_TARGET:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentPeerTargetActionTypes.RIGHT_MODAL_SHOW:

      let newTarget = NewContentPeerTargetItem;

      if (action.payload.data.channel) {
        newTarget.channel = action.payload.data.channel;
      }
      if (action.payload.data.activity) {


        newTarget.activity = action.payload.data.activity;
      }

      return {
        ...state,
        selectedItem: newTarget,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case ContentPeerTargetActionTypes.REMOVE_CONTENT_PEER_TARGET_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentPeerTargetItem,
        removeModalShow: false
      }
    case ContentPeerTargetActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false,
        selectedItem: NewContentPeerTargetItem
      }
    case ContentPeerTargetActionTypes.UPDATE_CONTENT_PEER_TARGET:
      return {
        ...state,
        isSending: true
      };
    default:
      return { ...state };
  }
};

export default ContentPeerTarget;
