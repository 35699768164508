import React, { useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";

import { useRedux } from "../../../hooks";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    getContentPeerTargets,
    getContentPeerTargetTypes,
    getContentPeerChannels
} from "../../../redux/actions";

import {ContentPeerTargetStatusTypes, ContentPeerTargetType} from "../../../redux/Content/PeerTarget/types";
import {EntityActionsHandlersType} from "../../../redux/types";
import RemoveModal from "../../../components/RemoveForm";
import ListItem from "./ListItem";
import {
    ContentPeerTargetHideRemoveModal,
    ContentPeerTargetOnRemove,
    ContentPeerTargetOpenModal, ContentPeerTargetOpenRemoveModal
} from "./ContentPeerTargetActions";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {isNumeric} from "../../../helpers/functions";
import UpgradeFarePage from "../../../components/UpgradeFarePage";
import Loader from "../../../components/Loader";
import {nameToEmoji} from "gemoji";

const PeerTargetIndex = () => {
    const { dispatch, useAppSelector } = useRedux();
    const { t } = useTranslation(['common']);

    const [peerTargets, setPeerTargets] = useState<ContentPeerTargetType[]>([]);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

    const {
        contentPeerTarget,
        isContentPeerTargetFetched,
        salePlan
    } = useAppSelector(state => ({
        contentPeerTarget: state.ContentPeerTarget,
        isContentPeerTargetFetched: state.ContentPeerTarget.isContentPeerTargetFetched,
        salePlan: state.SalePlan
    }));

    useEffect(() => {
        dispatch(getContentPeerTargetTypes());
        dispatch(getContentPeerTargets());
        dispatch(getContentPeerChannels());
    }, [dispatch]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onEdit: (event)=>ContentPeerTargetOpenModal(event,dispatch),
        onRemove: (event)=>ContentPeerTargetOpenRemoveModal(event,dispatch)
    }

    useEffect(() => {
        if ((contentPeerTarget.items !== undefined) && (contentPeerTarget.items.length>0)){
            setPeerTargets([...contentPeerTarget.items]);
        } else {
            setPeerTargets([]);
        }

    }, [contentPeerTarget?.items]);


    useEffect(() => {

        if (contentPeerTarget?.isNeedReload && contentPeerTarget.isNeedReload === true){
            dispatch(getContentPeerTargets());
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerTarget?.isNeedReload]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.CONTENT_MAX_TARGETS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    return (
        <>
            <BreadCrumb title={t('menu:goals')} pageTitle={t('menu:main')} />

            { upgradeFare && isContentPeerTargetFetched ?
                <>
                    <div className="d-flex flex-row flex-grow-1">
                        <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionGoalText')}
                        </div>
                    </div>
                    <UpgradeFarePage/>
                </>
                :
            <>
                <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary" onClick={(event)=>ContentPeerTargetOpenModal(event,dispatch)} className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>

                { isContentPeerTargetFetched ?
                    (peerTargets || []).length<1
                        ?
                        <div className="d-flex flex-row flex-grow-1 mt-2">
                            <div className="alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                {t('target:targetAbsentError')}<span className="align-bottom ps-1">{nameToEmoji.upside_down_face}</span>
                            </div>
                        </div>
                        :
                        <>

                {(peerTargets || []).filter((item: ContentPeerTargetType, key: number) => {
                    return item.targetStatus === ContentPeerTargetStatusTypes.PROGRESS
                }).length > 0 &&
                <>
                    <Row>
                        <h5 className="mb-2">{t('target:activeTargets')}</h5>
                    </Row>
                    <Row className="mb-4">
                        {(peerTargets || []).map((item:ContentPeerTargetType, key:number)=>{
                            return(
                                item.targetStatus === ContentPeerTargetStatusTypes.PROGRESS &&
                                <Col xxl={3} xl={4} sm={6} className="project-card" key={"targetProgress_"+key}>
                                    <ListItem
                                        item={item}
                                        actionHandlers={EntityActionsHandlers}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </>
                }

                    {(peerTargets || []).filter((item: ContentPeerTargetType, key: number) => {
                        return item.targetStatus === ContentPeerTargetStatusTypes.SUCCESS
                    }).length > 0 &&
                    <>
                        <Row>
                            <h5 className="mb-2">{t('target:successTargets')}</h5>
                        </Row>
                        <Row className="mb-4">
                        {(peerTargets || []).map((item:ContentPeerTargetType, key:number)=>{
                            return(
                            item.targetStatus === ContentPeerTargetStatusTypes.SUCCESS &&
                                <Col xxl={3} xl={4} sm={6} className="project-card" key={"targetSuccess_"+key}>
                                    <ListItem
                                    item={item}
                                    actionHandlers={EntityActionsHandlers}
                                    />
                                </Col>
                            );
                        })}
                        </Row>
                    </>
                    }

                    {(peerTargets || []).filter((item: ContentPeerTargetType, key: number) => {
                        return item.targetStatus === ContentPeerTargetStatusTypes.FAIL
                    }).length > 0 &&
                    <>
                        <Row>
                            <h5 className="mb-2">{t('target:failTargets')}</h5>
                        </Row>
                        <Row className="mb-4">
                            {(peerTargets || []).map((item:ContentPeerTargetType, key:number)=>{
                                return(
                                    item.targetStatus === ContentPeerTargetStatusTypes.FAIL &&
                                    <Col xxl={3} xl={4} sm={6} className="project-card" key={"targetFail_"+key}>
                                        <ListItem
                                            item={item}
                                            actionHandlers={EntityActionsHandlers}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </>
                    }
                        </>
                    :
                    <Loader/>
                }
                <RightModal/>
                <RemoveModal
                    onRemove={(event)=>ContentPeerTargetOnRemove(event,dispatch)}
                    handleClose={()=>ContentPeerTargetHideRemoveModal(dispatch)}
                    removeItem={contentPeerTarget.selectedItem}
                    isOpen={contentPeerTarget.removeModalShow}
                    modalTitle={contentPeerTarget.modalEditTitle}/>
            </>
            }
        </>
    );
};

export default PeerTargetIndex;


