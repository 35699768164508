import {EventSubscribeUserActionTypes, EventSubscribeUserPayload, EventSubscribeUserType} from "./types";
import {MainUserActionTypes} from "../../Main/User/types";

export const eventUserSubscriptionApiResponseSuccess = (actionType: string, data: any) => ({
  type: EventSubscribeUserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const eventUserSubscriptionApiResponseError = (actionType: string, error: string) => ({
  type: EventSubscribeUserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getEventUserSubscriptions = () => ({
  type: EventSubscribeUserActionTypes.GET_USER_EVENT_SUBSCRIPTIONS,
});

export const updateEventUserSubscription = (data: EventSubscribeUserType) => ({
  type: EventSubscribeUserActionTypes.UPDATE_USER_EVENT_SUBSCRIPTION,
  payload: data,
});

