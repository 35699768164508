export interface MediaFilesType{
    items: Array<MediaFileType>;
    total: number;
}

export interface MediaFileType{
    uuid: string,
    name: string,
    url?: string;
    downloadUrl?: string;
    size: number;
    description?: string;
    contentType?: string;
    dateTime?: string;
}

export interface MediaDimensionType{
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
}

export const MediaTypeIcon  = {
    'ppt' : {
        icon: 'ri-file-ppt-line'
    },
    'pptx' : {
        icon: 'ri-file-ppt-line'
    },
    'doc' : {
        icon: 'ri-file-word-2-line'
    },
    'docx' : {
        icon: 'ri-file-word-2-line'
    },
    'vnd.openxmlformats-officedocument.wordprocessingml.document': {
        icon: 'ri-file-word-2-line'
    },
    'pdf' : {
        icon: 'ri-file-pdf-line'
    },
    'xls' : {
        icon: 'ri-file-excel-2-line'
    },
    'xlsx' : {
        icon: 'ri-file-excel-2-line'
    },
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        icon: 'ri-file-excel-2-line'
    },
    'mp4' : {
        icon: 'ri-video-line'
    },
    'mov' : {
        icon: 'ri-video-line'
    },
    'avi' : {
        icon: 'ri-video-line'
    },
    'quicktime' : {
        icon: 'ri-video-line'
    },
}