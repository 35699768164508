import { APIClient } from "../apiCore";
import * as url from "../urls";
import {MainSettingsState} from "../../redux/Main/Layout/types";
import {m9sGlobals} from "../../config";
import {getCurrentCabinet} from "../../helpers/functions";
import {MainCabinetType} from "../../redux/Main/Cabinet/types";
const api = new APIClient();

const getUserCabinets = () => {
  return api.get(url.GET_USER_CABINETS);
};

const sendDataMainCabinet = (data: MainCabinetType) => {
    return api.create(url.UPDATE_USER_CABINET, data);
};

const createUserCabinet = () => {
    return api.get(url.CREATE_USER_CABINET);
};

const resumeUserCabinet = (data: MainCabinetType) => {
    if (data !== undefined && data !== null && data.uuid !== undefined) {
        return api.get(url.RESUME_USER_CABINET+'/'+data.uuid);
    }
};

const getUserCabinet = (data: MainCabinetType) => {
    if (data !== undefined && data !== null && data.uuid !== undefined) {
        const sendData = {
            uuid: data.uuid
        }
        return api.create(url.GET_USER_CABINETS,sendData);
    }
};

const updateUserCabinetSettings = (data: MainSettingsState) => {
    if (m9sGlobals.CURRENT_CABINET !== undefined && m9sGlobals.CURRENT_CABINET !== null) {

        let currentCabinet = getCurrentCabinet();

        if (currentCabinet !== undefined && currentCabinet !== null && currentCabinet !== false && currentCabinet.uuid !== undefined) {

            const sendData = {
                cabinet:
                    {uuid: currentCabinet.uuid},
                settings: data
            }

            return api.create(url.UPDATE_USER_CABINET_SETTINGS, sendData);
        }
    }
};

const updateCabinetLastLogin = () => {
    if (m9sGlobals.CURRENT_CABINET !== undefined && m9sGlobals.CURRENT_CABINET !== null) {

        let currentCabinet = getCurrentCabinet();

        if (currentCabinet !== undefined && currentCabinet !== null && currentCabinet !== false && currentCabinet.uuid !== undefined) {

            return api.get(url.CABINET_SET_LASTLOGIN+'/'+currentCabinet.uuid);
        }
    }
};


export {
    getUserCabinets,
    updateUserCabinetSettings,
    sendDataMainCabinet,
    createUserCabinet,
    updateCabinetLastLogin,
    resumeUserCabinet,
    getUserCabinet
};
