import { APIClient } from "../apiCore";
import * as url from "../urls";
import {EventSubscribeUserType} from "../../redux/Event/EventSubscribeUser/types";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getEventUserSubscriptions = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_USER_EVENT_SUBSCRIPTIONS);
};

const updateEventUserSubscription = (data: EventSubscribeUserType) => {

    const sendData={
        uuid : data.uuid,
        isSubscribed : data.isSubscribed
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_USER_EVENT_SUBSCRIPTION, sendData);
};

export {
    getEventUserSubscriptions,
    updateEventUserSubscription,
};
