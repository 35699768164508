import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import * as route from "../../../api/routes";

import FeatherIcon from "feather-icons-react";

// hooks
import { useRedux } from "../../../hooks";

// components
// import AudioCallModal from "../../../components/AudioCallModal";
// import VideoCallModal from "../../../components/VideoCallModal";
// import AddPinnedTabModal from "../../../components/AddPinnedTabModal";

// actions
import {changeSelectedChat, changeSelectedCommentChat} from "../../../redux/actions";

//i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";

// constants
import { STATUS_TYPES } from "../../../components/Constants";
import {MessengerChannelType} from "../../../redux/Messenger/Chat/types";
import {getHandlerIconByCode} from "../../../helpers/functions";
import {BiKey} from "react-icons/bi";

interface ProfileImageProps {
  chatUserDetails: MessengerChannelType;
  onCloseConversation: () => any;
  onOpenUserDetails: () => any;
  isChannel: boolean;
}

const ProfileImage = ({
  chatUserDetails,
  onCloseConversation,
  onOpenUserDetails,
  isChannel,
}: ProfileImageProps) => {

  const imagePlaceholder = require("../../../resources/images/user/profile-placeholder.png");

  const { t } = useTranslation(['common']);

  // const fullName = !isChannel
  //   ? chatUserDetails.firstName
  //     ? `${chatUserDetails.firstName} ${chatUserDetails.lastName}`
  //     : "-"
  //   : chatUserDetails.name;

  const fullName = chatUserDetails?.name ? chatUserDetails?.name : "-";

  // const shortName = !isChannel
  //   ? chatUserDetails.firstName
  //     ? `${chatUserDetails.firstName.charAt(
  //         0
  //       )}${chatUserDetails.lastName.charAt(0)}`
  //     : "-"
  //   : "#";

  const shortName  = fullName ? fullName.charAt(0) : "#";

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));

  const isOnline =
    chatUserDetails.status && chatUserDetails.status === STATUS_TYPES.ACTIVE;

  const members = (chatUserDetails.members || []).length;

  const handleNeedChannelAccessToken = () => {
    window.location.href = route.CONTENT_CHANNELS_LIST;
  }

  return (
      <>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 d-block d-lg-none me-3">
          <Link
              to="#"
              className="user-chat-remove fs-18 p-1"
              onClick={onCloseConversation}
          >
            <i className="ri-arrow-left-s-line align-bottom"></i>
          </Link>
        </div>
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
              {chatUserDetails?.handler ? (
                      <>
                      {chatUserDetails?.handler?.code && chatUserDetails.handler.code.length>0 ? (
                          <div className="handlerIcon">
                            <img src={getHandlerIconByCode(chatUserDetails.handler.code)} alt=""/>
                          </div>
                      ) : ''}

                          <img
                              src={chatUserDetails.avatar ? chatUserDetails.avatar : imagePlaceholder }
                              className="rounded-circle avatar-sm"
                              alt=""
                          />

                        <span
                            className={classnames(
                                "user-status",
                                {
                                  "bg-success":
                                      chatUserDetails.status === STATUS_TYPES.ACTIVE,
                                },
                                {
                                  "bg-warning":
                                      chatUserDetails.status === STATUS_TYPES.AWAY,
                                },
                                {
                                  "bg-danger":
                                      chatUserDetails.status === STATUS_TYPES.DO_NOT_DISTURB,
                                }
                            )}
                        />
                      </>) : (

                  <div className="avatar-sm align-self-center">
                    <span
                        className={classnames(
                            "avatar-title",
                            "rounded-circle",
                            "text-uppercase",
                            "text-white",
                            colors[color]
                        )}
                    >
                      <span className="username"> {shortName}</span>
                      <span className="user-status"/>
                    </span>
                  </div>
              )}
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate mb-0 fs-16">
                <a
                    className="text-reset username"
                    data-bs-toggle="offcanvas"
                    href="#userProfileCanvasExample"
                    aria-controls="userProfileCanvasExample"
                    onClick={onOpenUserDetails}
                >
                  {fullName}
                  {chatUserDetails?.contentChannel && chatUserDetails.contentChannel?.isNeedAccessToken ? chatUserDetails.contentChannel.isNeedAccessToken ? <span className="needAccessKey" title={t('channel:channelKeyNeeded')} onClick={handleNeedChannelAccessToken}><BiKey className="text-danger fs-16 me-2"/></span> :'':''}
                </a>
              </h5>
              <p className="text-truncate text-muted fs-14 mb-0">
                {isChannel ? (
                    <small>{chatUserDetails.description}</small>
                ) : (
                    <small>{chatUserDetails.status}</small>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      </>
  );
};

const Search = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const { t } = useTranslation(['common']);

  return (
      <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
      >
        <DropdownToggle
            className="btn btn-ghost-secondary btn-icon"
            tag="button"
        >
          <FeatherIcon
              icon="search"
              className="icon-sm"
          />
        </DropdownToggle>
        <DropdownMenu className="p-0 dropdown-menu-end dropdown-menu-lg">
          <div className="p-2">
            <div className="search-box">
              <Input
//                  onKeyUp={searchUsers}
                  type="text"
                  className="form-control bg-light border-light"
                  placeholder={t('common:search') + ".."}
                  id="searchMessage"
              />
              <i className="ri-search-2-line search-icon"></i>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
  );
};
interface MoreProps {
  // onOpenAudio: () => void;
  // onOpenVideo: () => void;
//  onDelete: () => void;
  isArchive: boolean;
  onToggleArchive: () => void;
}
const More = ({
  // onOpenAudio,
  // onOpenVideo,
//  onDelete,
  isArchive,
  onToggleArchive,
}: MoreProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const { t } = useTranslation(['common','profile']);

  return (

        <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
        >
          <DropdownToggle
              className="btn btn-ghost-secondary btn-icon"
              tag="button"
          >
            <FeatherIcon
                icon="more-vertical"
                className="icon-sm"
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
                href="#"
                className="d-block d-lg-none user-profile-show"
            >
              <i className="ri-user-2-fill align-bottom text-muted me-2"/>{" "}
              {t('profile:viewProfile')}
            </DropdownItem>
            <DropdownItem href="#" onClick={onToggleArchive}>
              {isArchive ? (
                  <>
                    <i className="ri-inbox-unarchive-line align-bottom text-muted me-2"/>{" "}
                    {t('common:unArchive')}
                  </>
              ) : (
                  <>
                    <i className="ri-inbox-archive-line align-bottom text-muted me-2"/>{" "}
                    {t('common:archive')}
                  </>
              )}
            </DropdownItem>
            {/*<DropdownItem href="#">*/}
            {/*  <i className="ri-mic-off-line align-bottom text-muted me-2"></i>{" "}*/}
            {/*  Muted*/}
            {/*</DropdownItem>*/}
            {/*<DropdownItem href="#">*/}
            {/*  {" "}*/}
            {/*  <i className="ri-delete-bin-5-line align-bottom text-muted me-2"></i>{" "}*/}
            {/*  Delete*/}
            {/*</DropdownItem>*/}
          </DropdownMenu>
        </Dropdown>

    // <Dropdown isOpen={dropdownOpen} toggle={toggle}>
    //   <DropdownToggle color="none" className="btn nav-btn" type="button">
    //     <FeatherIcon
    //         icon="more-vertical"
    //         className="icon-sm"
    //     />
    //   </DropdownToggle>
    //   <DropdownMenu className="dropdown-menu-end">
    //     <DropdownItem
    //       className="d-flex justify-content-between align-items-center d-lg-none user-profile-show"
    //       to="#"
    //     >
    //       {t('profile:viewProfile')} <i className="bx bx-user text-muted"/>
    //     </DropdownItem>
    //     {/*<DropdownItem*/}
    //     {/*  className="d-flex justify-content-between align-items-center d-lg-none"*/}
    //     {/*  to="#"*/}
    //     {/*  onClick={onOpenAudio}*/}
    //     {/*>*/}
    //     {/*  Audio <i className="bx bxs-phone-call text-muted"></i>*/}
    //     {/*</DropdownItem>*/}
    //     {/*<DropdownItem*/}
    //     {/*  className="d-flex justify-content-between align-items-center d-lg-none"*/}
    //     {/*  to="#"*/}
    //     {/*  onClick={onOpenVideo}*/}
    //     {/*>*/}
    //     {/*  Video <i className="bx bx-video text-muted"></i>*/}
    //     {/*</DropdownItem>*/}
    //     <DropdownItem
    //       className="d-flex justify-content-between align-items-center"
    //       to="#"
    //       onClick={onToggleArchive}
    //     >
    //       {isArchive ? (
    //         <>
    //           {t('common:unArchive')} <i className="bx bx-archive-out text-muted"/>
    //         </>
    //       ) : (
    //         <>
    //           {t('common:archive')} <i className="bx bx-archive text-muted"/>
    //         </>
    //       )}
    //     </DropdownItem>
    //     <DropdownItem
    //       className="d-flex justify-content-between align-items-center"
    //       to="#"
    //     >
    //       {t('common:muted')} <i className="bx bx-microphone-off text-muted"/>
    //     </DropdownItem>
    //     {/*<DropdownItem*/}
    //     {/*  className="d-flex justify-content-between align-items-center"*/}
    //     {/*  to="#"*/}
    //     {/*  onClick={onDelete}*/}
    //     {/*>*/}
    //     {/*  Delete <i className="bx bx-trash text-muted"></i>*/}
    //     {/*</DropdownItem>*/}
    //   </DropdownMenu>
    // </Dropdown>
  );
};

interface PinnedAlertProps {
  onOpenPinnedTab: () => void;
}
const PinnedAlert = ({ onOpenPinnedTab }: PinnedAlertProps) => {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);
  const { t } = useTranslation(['common']);

  return (
    <Alert
      color="warning"
      isOpen={visible}
      toggle={onDismiss}
      className="topbar-bookmark p-1 px-3 px-lg-4 pe-lg-5 pe-5 alert-dismiss-custom"
      role="alert"
    >
      <div className="d-flex align-items-start bookmark-tabs">
        <div className="tab-list-link">
          <Link to="#" className="tab-links" onClick={onOpenPinnedTab}>
            <i className="ri-pushpin-fill align-middle me-1"/> 10 Pinned
          </Link>
        </div>
        <div id="add-bookmark">
          <Link to="#" className="tab-links border-0 px-3">
            <i className="ri-add-fill align-middle"/>
          </Link>
        </div>
        <UncontrolledTooltip target="add-bookmark" placement="bottom">
          {t('common:addBookmark')}
        </UncontrolledTooltip>
      </div>
    </Alert>
  );
};
interface UserHeadProps {
  chatUserDetails: any;
//  pinnedTabs: Array<PinTypes>;
  onOpenUserDetails: () => void;
//  onDelete: () => void;
  isChannel: boolean;
  onToggleArchive: () => void;
}
const UserHead = ({
  chatUserDetails,
//  pinnedTabs,
  onOpenUserDetails,
//  onDelete,
  isChannel,
  onToggleArchive,
}: UserHeadProps) => {
  // global store
  const { dispatch } = useRedux();
  /*
    video call modal
    */
  // const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
  // const onOpenVideo = () => {
  //   setIsOpenVideoModal(true);
  // };
  // const onCloseVideo = () => {
  //   setIsOpenVideoModal(false);
  // };

  /*
        audio call modal
    */
  // const [isOpenAudioModal, setIsOpenAudioModal] = useState<boolean>(false);
  // const onOpenAudio = () => {
  //   setIsOpenAudioModal(true);
  // };
  // const onCloseAudio = () => {
  //   setIsOpenAudioModal(false);
  // };

  /*
  pinned tab modal
  */
  const [isOpenPinnedTabModal, setIsOpenPinnedTabModal] =
    useState<boolean>(false);
  const onOpenPinnedTab = () => {
    setIsOpenPinnedTabModal(true);
  };
  const onClosePinnedTab = () => {
    setIsOpenPinnedTabModal(false);
  };

  /*
  mobile menu chat conversation close
  */
  const onCloseConversation = () => {

    if (chatUserDetails?.type && chatUserDetails.type === 'comments'){
      dispatch(changeSelectedCommentChat(null));
    } else {
      dispatch(changeSelectedChat(null));
    }

  };

  return (
      <div className="p-3 user-chat-topbar">
        <Row className="align-items-center">
          <Col sm={4} xs={8}>
            <ProfileImage
                chatUserDetails={chatUserDetails}
                onCloseConversation={onCloseConversation}
                onOpenUserDetails={onOpenUserDetails}
                isChannel={isChannel}
            />
          </Col>
          <Col sm={8} xs={4}>
            <ul className="list-inline user-chat-nav text-end mb-0">
              <li className="list-inline-item">
                <Search />
              </li>

              {/*{!isChannel && (*/}
              {/*  <>*/}
              {/*    <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">*/}
              {/*      <Button*/}
              {/*        type="button"*/}
              {/*        color="none"*/}
              {/*        className="btn nav-btn"*/}
              {/*        onClick={onOpenAudio}*/}
              {/*      >*/}
              {/*        <i className="bx bxs-phone-call"></i>*/}
              {/*      </Button>*/}
              {/*    </li>*/}

              {/*    <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">*/}
              {/*      <Button*/}
              {/*        type="button"*/}
              {/*        color="none"*/}
              {/*        className="btn nav-btn"*/}
              {/*        onClick={onOpenVideo}*/}
              {/*      >*/}
              {/*        <i className="bx bx-video"></i>*/}
              {/*      </Button>*/}
              {/*    </li>*/}
              {/*  </>*/}
              {/*)}*/}

              {/*<li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">*/}
              {/*  <button*/}
              {/*    onClick={onOpenUserDetails}*/}
              {/*    type="button"*/}
              {/*    className="btn nav-btn user-profile-show"*/}
              {/*  >*/}
              {/*    <i className="bx bxs-info-circle"/>*/}
              {/*  </button>*/}
              {/*</li>*/}

              <li className="list-inline-item d-none d-lg-inline-block m-0">
                <button
                    type="button"
                    className="btn btn-ghost-secondary btn-icon"
                    onClick={onOpenUserDetails}
                >
                  <FeatherIcon icon="info" className="icon-sm" />
                </button>
              </li>

              <li className="list-inline-item">
                <More
                    // onOpenAudio={onOpenAudio}
                    // onOpenVideo={onOpenVideo}
                    //                onDelete={onDelete}
                    isArchive={chatUserDetails.isArchive}
                    onToggleArchive={onToggleArchive}
                />
              </li>
            </ul>
          </Col>
        </Row>
      </div>

      // {/*<PinnedAlert onOpenPinnedTab={onOpenPinnedTab} />*/}
      // {/*{isOpenAudioModal && (*/}
      // {/*  <AudioCallModal*/}
      // {/*    isOpen={isOpenAudioModal}*/}
      // {/*    onClose={onCloseAudio}*/}
      // {/*    user={chatUserDetails}*/}
      // {/*  />*/}
      // {/*)}*/}
      // {/*{isOpenVideoModal && (*/}
      // {/*  <VideoCallModal*/}
      // {/*    isOpen={isOpenVideoModal}*/}
      // {/*    onClose={onCloseVideo}*/}
      // {/*    user={chatUserDetails}*/}
      // {/*  />*/}
      // {/*)}*/}
      // {/*{isOpenPinnedTabModal && (*/}
      // {/*  <AddPinnedTabModal*/}
      // {/*    isOpen={isOpenPinnedTabModal}*/}
      // {/*    onClose={onClosePinnedTab}*/}
      // {/*    pinnedTabs={pinnedTabs}*/}
      // {/*  />*/}
      // {/*)}*/}
  );
};

export default UserHead;
