import React, {useState} from "react";
import { Link } from 'react-router-dom';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
    PlanRibbonType,
    SalePlanParamValueType,
    SalePlanPricePeriod,
    SalePlanPriceType,
    SalePlanType
} from "../../../redux/Sale/Plan/types";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {addM9SCartProduct, clearM9SCartProduct, getCurrentCurrencyCode} from "../../../helpers/functions";
import {LocaleCurrencySymbol} from "../../../redux/Sale/Currency/types";
import * as route from "../../../api/routes";
import {LicenseProductParam} from "../../../redux/Sale/License/types";

interface ItemProps {
    item: SalePlanType;
    priceType: string;
    planRibbon?: PlanRibbonType
}

const ListItem = ({ item, priceType, planRibbon }: ItemProps) => {

    const { t, i18n } = useTranslation(['plan']);

    const currency = getCurrentCurrencyCode();

    const price=(item.productPrices.filter((price:SalePlanPriceType)=>{
            return price.priceType.code===SalePlanPricePeriod[priceType] && price.currency.code === currency
        }
    )).pop();

    const mainMaxUsers = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.MAIN_MAX_USERS
        }
    )).pop();

    const contentMaxSocialServices = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.CONTENT_MAX_SOCIAL_SERVICES
        }
    )).pop();

    const contentMaxOwnerPeerChannels = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.CONTENT_MAX_OWNER_PEER_CHANNELS
        }
    )).pop();

    const contentMaxCompetitors = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.CONTENT_MAX_COMPETITORS
        }
    )).pop();

    const contentMaxProjects = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.CONTENT_MAX_PROJECTS
        }
    )).pop();

    const saleMaxProducts = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.SALE_MAX_PRODUCTS
        }
    )).pop();

    const saleMaxPriceTypes = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.SALE_MAX_PRICE_TYPES
        }
    )).pop();

    const saleMaxWidgets = (item.productParamValues.filter((param:SalePlanParamValueType)=>{
            return param.productParam.code === LicenseProductParam.SALE_MAX_WIDGETS
        }
    )).pop();


    const handleSelectPlan = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        clearM9SCartProduct(); //empty cart
        addM9SCartProduct({product: item, priceType: price.priceType, quantity: 1});
        window.location.href = route.PLAN_ORDER_CHECKOUT;
    }

    enum planDescription {
        Lite = 'liteSecondLabel',
        Standard = 'standardSecondLabel',
        Expert = 'expertSecondLabel',
        Maximum = 'maximumSecondLabel',
    }

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        //    notation: 'compact',
        }).format(value);

    return(
        <Card className="pricing-box ribbon-box ribbon-fill text-center">
            {planRibbon !== undefined ?
                <div className={"ribbon shadow-none ribbon-"+planRibbon.class}>
                    {planRibbon.icon !== undefined && planRibbon.icon !== null ?
                        <i className={planRibbon.icon}/>
                        :
                        planRibbon.label
                    }
                </div>
                : ""
            }
            <Row className="g-0">
                <Col lg={6}>
                    <CardBody className="h-100">

                        <div>
                            <h5 className="mb-1">{item.name}</h5>
                            {planDescription[item.name] !== undefined &&
                            <p className="text-muted">{t('plan:'+planDescription[item.name])}</p>
                            }
                        </div>

                        <div className="py-4">
                            { price === undefined ? <h2>Is free</h2> :
                            <h2><sup><small>{LocaleCurrencySymbol[currency]}</small></sup>{price.price}
                                <span className="fs-13 text-muted"> /{priceType === '_3m' ? t('plan:perQuarter') : t('plan:perMonth')}</span>
                            </h2>
                            }
                        </div>

                        <div className="text-center plan-btn mt-2">
                            <Link to="#" className="btn btn-success w-sm waves-effect waves-light" onClick={(event)=>handleSelectPlan(event)}>{t('plan:signUp')}</Link>
                        </div>
                    </CardBody>
                </Col>
                <Col lg={6}>
                    <CardBody className="border-start mt-4 mt-lg-0">
                        <CardHeader className="bg-light">
                            <h5 className="fs-15 mb-0">{t('plan:planFeatures')}:</h5>
                        </CardHeader>
                        <CardBody className="pb-0">
                            <ul className="list-unstyled vstack gap-3 mb-0">
                                { mainMaxUsers !== undefined &&
                                    <li>{t('plan:mainMaxUsers')}:{" "}<span className="d-block text-success fw-semibold">{mainMaxUsers.value}</span></li>
                                }
                                { contentMaxProjects !== undefined &&
                                    <li>{t('plan:contentMaxProjects')}:{" "}<span className="d-block text-success fw-semibold">{contentMaxProjects.value}</span></li>
                                }
                                { contentMaxSocialServices !== undefined &&
                                    <li>{t('plan:contentMaxSocialServices')}:{" "}<span className="d-block text-success fw-semibold">{contentMaxSocialServices.value}</span></li>
                                }
                                { contentMaxOwnerPeerChannels !== undefined &&
                                    <li>{t('plan:contentMaxOwnerPeerChannels')}:{" "}<span className="d-block text-success fw-semibold">{contentMaxOwnerPeerChannels.value}</span></li>
                                }
                                { saleMaxProducts !== undefined &&
                                    <li>{t('plan:saleMaxProducts')}:{" "}
                                        {saleMaxProducts.value === 'N' ?
                                            <span className="d-block text-danger fw-semibold"><i className="ri-close-circle-fill fs-15 align-middle"></i></span>
                                            :
                                            <span className="d-block text-success fw-semibold">{ FormatValue(parseInt(saleMaxProducts.value)) }</span>
                                        }
                                    </li>
                                }
                                { saleMaxPriceTypes !== undefined &&
                                    <li>{t('plan:saleMaxPriceTypes')}:{" "}
                                        {saleMaxPriceTypes.value === 'N' ?
                                            <span className="d-block text-danger fw-semibold"><i className="ri-close-circle-fill fs-15 align-middle"></i></span>
                                            :
                                            <span className="d-block text-success fw-semibold">{ saleMaxPriceTypes.value }</span>
                                        }
                                    </li>
                                }
                                { saleMaxWidgets !== undefined &&
                                    <li>{t('plan:saleMaxWidgets')}:{" "}
                                        {saleMaxWidgets.value === 'N' ?
                                            <span className="d-block text-danger fw-semibold"><i className="ri-close-circle-fill fs-15 align-middle"></i></span>
                                            :
                                            <span className="d-block text-success fw-semibold">{ saleMaxWidgets.value }</span>
                                        }
                                    </li>
                                }
                                { contentMaxCompetitors !== undefined &&
                                    <li>{t('plan:contentMaxCompetitors')}:{" "}
                                        {contentMaxCompetitors.value === 'N' ?
                                            <span className="d-block text-danger fw-semibold"><i className="ri-close-circle-fill fs-15 align-middle"></i></span>
                                            :
                                            <span className="d-block text-success fw-semibold">{ contentMaxCompetitors.value }</span>
                                        }
                                    </li>
                                }
                            </ul>
                        </CardBody>
                    </CardBody>
                </Col>
            </Row>
        </Card>
    )
}

export default ListItem;