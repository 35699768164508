import React from "react";
import {MainVersionHistoryType} from "../../../redux/Main/VersionHistory/types";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {convertToMomentLocale} from "../../../helpers/functions";

interface ItemProps {
    item: MainVersionHistoryType;
    itemKey: number
}

const ListItem = ({ item, itemKey }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));


    return (
        <div className={"timeline-item "+((itemKey%2) === 0?'left':'right')}>
            <i className={"icon "+item.icon}/>
            <div className="date">{moment(item.dateTime).format('ddd, DD MMM YYYY')}</div>
            <div className="content text-muted">
                <div className="d-flex">
                    <div className="flex-shrink-0">
                        {/*<img src={avatar5} alt="" className="avatar-sm rounded" />*/}
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <div
                            dangerouslySetInnerHTML={{__html: item.text}}
                        />

                        <div className="hstack gap-2">
                            {/*<Link to="#" className="btn btn-sm btn-light"><span className="me-1">&#128293;</span> 19</Link>*/}
                            {/*<Link to="#" className="btn btn-sm btn-light"><span className="me-1">&#129321;</span> 22</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListItem;