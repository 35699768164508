
export interface RewardType{
    uuid?: string;
    score: number;
    scoreComment: string;
}

export const NewRewardItem:RewardType = {
    score: 3,
    scoreComment: '',
}

