import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentPeerTargets = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_TARGETS);
};

const sendDataContentPeerTarget = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_PEER_TARGET, data);
};

const removeContentPeerTarget = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_PEER_TARGET, data);
};

export {
    getContentPeerTargets,
    sendDataContentPeerTarget,
    removeContentPeerTarget
};
