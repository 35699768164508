import {SaleCustomerActionTypes, SaleCustomerPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleCustomerApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleCustomerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleCustomerApiResponseError = (actionType: string, error: string) => ({
  type: SaleCustomerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleCustomers = (data: MainMetaType) => ({
  type: SaleCustomerActionTypes.GET_SALE_CUSTOMERS,
  payload: data,
});

export const showRightModalSaleCustomer= (data: SaleCustomerPayload) => ({
  type: SaleCustomerActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleCustomer= () => ({
  type: SaleCustomerActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleCustomer = (data: any) => ({
  type: SaleCustomerActionTypes.UPDATE_SALE_CUSTOMER,
  payload: data,
});

export const editDataSaleCustomer = (data: SaleCustomerPayload) => ({
  type: SaleCustomerActionTypes.EDIT_SALE_CUSTOMER,
  payload: data,
});

export const removeSaleCustomerModalShow = (data: SaleCustomerPayload) => ({
  type: SaleCustomerActionTypes.REMOVE_SALE_CUSTOMER_MODAL_SHOW,
  payload: data,
});

export const removeSaleCustomerModalHide = () => ({
  type: SaleCustomerActionTypes.REMOVE_SALE_CUSTOMER_MODAL_HIDE,
});

export const onRemoveSaleCustomer = (data: SaleCustomerPayload) => ({
  type: SaleCustomerActionTypes.REMOVE_SALE_CUSTOMER,
  payload: data,
});