import {HelperItemType} from "../../Helper/HelperItem/types";
import {TaskType} from "../../Task/Task/types";
import {MainUserType} from "../../Main/User/types";

export enum DashboardTaskActionTypes {
  API_RESPONSE_SUCCESS = "@@dashboardTask/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@dashboardTask/API_RESPONSE_ERROR",
  SET_PERIOD = "@@dashboardTask/SET_PERIOD",
  GET_STAT_TASKS_TILES  = "@@dashboardTask/GET_STAT_TASKS_TILES",
  SELECT_TASK_MEMBER_TYPE  = "@@dashboardTask/SELECT_TASK_MEMBER_TYPE",
  GET_STAT_TASKS_LAST_COMPLETED  = "@@dashboardTask/GET_STAT_TASKS_LAST_COMPLETED",
  GET_STAT_TASKS_LAST_OVERDUE  = "@@dashboardTask/GET_STAT_TASKS_LAST_OVERDUE",
  GET_STAT_TASKS_LAST_PENDING  = "@@dashboardTask/GET_STAT_TASKS_LAST_PENDING",
  GET_STAT_TASKS_LAST_RETURNED  = "@@dashboardTask/GET_STAT_TASKS_LAST_RETURNED",
  GET_STAT_TASKS_IN_PROGRESS  = "@@dashboardTask/GET_STAT_TASKS_IN_PROGRESS",
}

export interface DashboardTaskState {
  tiles: Array<StatTaskTileType>;

  lastCompleted: Array<TaskType>;
  lastCompletedMembers: Array<MainUserType>;
  lastOverdue: Array<TaskType>;
  lastOverdueMembers: Array<MainUserType>;
  lastPending: Array<TaskType>;
  lastPendingMembers: Array<MainUserType>;
  lastReturned: Array<TaskType>;
  lastReturnedMembers: Array<MainUserType>;
  currentInProgress: Array<TaskType>;
  currentInProgressMembers: Array<MainUserType>;

  isFetchedLastCompleted: boolean;
  isFetchedLastOverdue: boolean;
  isFetchedLastPending: boolean;
  isFetchedLastReturned: boolean;
  isFetchedCurrentInProgress: boolean;

  periodFrom: number,
  selectedDate?: number,
  periodTo?: number,
  eTagTasks?: string,
  isFetched: boolean;
  isFetchedTasksTiles: boolean;

  memberType?: string
  isLoading: boolean;
}

export interface StatTaskPayloadType{
  periodFrom?: number,
  periodTo?: number,
  limit?: number,
  requestUuid?: string
  memberType?: string,
  status?: string
}


export interface StatTaskTileType{
  label?: string;
  value?: StatTaskTileValueType;
  helperItem?: HelperItemType;
  isLoading?: boolean;
  type?:string;
  entity?: any
}

export interface StatTaskTileValueType{
  last?: number,
  first?: number,
}