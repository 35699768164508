import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getStatChannelsTiles = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_CHANNEL_TILES_LIST, sendData);
};

const getStatChannelsActivities = (data:any) => {

    const sendData = {
        project : data.project && data.project,
        channel :  data.channel && data.channel,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo,
        requestUuid: data.requestUuid && data.requestUuid
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_CHANNEL_ACTIVITIES, sendData);
};

export {
    getStatChannelsTiles,
    getStatChannelsActivities
};
