import React from "react";
import {
    Alert,
    Card,
    CardBody,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import {Link} from "react-router-dom";
import * as route from "../api/routes";

import '../i18/config';
import {useTranslation} from "react-i18next";

const upgradeImage = require("../resources/images/cookies.png");

interface InputUpgradeFareProps {
  title?: string;
  text?: string;
  buttonTitle?: string;
  buttonLink?: string;
}
const UpgradeFareNotice = ({ title, text, buttonLink, buttonTitle }: InputUpgradeFareProps) => {

    const { t } = useTranslation();

    if (title === undefined) {
        title=t('plan:planUpgrade');
    } else {
        title=t('plan:'+title);
    }
    if (text === undefined) {
        text=t('plan:planUpgradeText');
    } else {
        text=t('plan:'+text);
    }
    if (buttonTitle === undefined) {
        buttonTitle=t('plan:planUp');
    } else {
        buttonTitle=t('plan:'+buttonTitle);
    }
    if (buttonLink === undefined) {
        buttonLink=route.SALE_PLANS;
    }

  return (
      <>
                  <Card className="card-height-100 card-animate">
                      <CardBody className="p-0 d-flex flex-column flex-grow-1">
                          <Alert className="alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center" role="alert">
                              <FeatherIcon
                                  icon="alert-triangle"
                                  className="text-warning me-2 icon-sm"
                              />
                              <div className="flex-grow-1 text-truncate">
                                  {t('plan:unCommercialPlanInUse')}
                              </div>
                              <div className="flex-shrink-0">
                                  <Link to={buttonLink} className="text-reset text-decoration-underline"><b>{buttonTitle}</b></Link>
                              </div>
                          </Alert>

                          <div className="d-flex flex-column flex-grow-1 p-3 pt-1">
                              <div className="d-flex flex-row flex-grow-1">
                                  <div className="d-flex flex-grow-1">
                                      <div className="d-flex flex-column flex-grow-1">
                                         <p className="mb-0 mt-2 pt-1 text-muted">{text}</p>
                                          <div className="mt-3 d-flex flex-column justify-content-end align-items-start flex-grow-1">
                                              <Link to={route.SALE_PLANS} className="btn btn-success">{t('plan:upgradeNow')}</Link>
                                          </div>
                                      </div>

                                  </div>
                                  <div className="d-flex flex-column justify-content-end align-items-end upgradeNoticeImageCont flex-shrink-0">
                                      <img src={upgradeImage} className="img-fluid" alt="" style={{maxWidth:'100px'}}/>
                                  </div>
                              </div>
                          </div>


                      </CardBody>
                  </Card>
      </>
  );
};
export default UpgradeFareNotice;
