// types
import {EventUserBadgeActionTypes, EventUserBadgeState, EventUserBadgeType} from "./types";

export const INIT_STATE_EVENT_USER_BADGES: EventUserBadgeState = {
  cabinetItems: [],
  supportItems: []
};

const EventUserBadge = (state = INIT_STATE_EVENT_USER_BADGES, action: any) => {
  switch (action.type) {
    case EventUserBadgeActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case EventUserBadgeActionTypes.GET_USER_BADGES:
          return {
            ...state,
            cabinetItems: action.payload.data.items,
          };
        case EventUserBadgeActionTypes.GET_SUPPORT_USER_BADGES:
        case EventUserBadgeActionTypes.GET_SUPPORT_SERVICE_BADGES:
          return {
            ...state,
            supportItems: action.payload.data.items,
          };
        default:
          return { ...state };
      }
    case EventUserBadgeActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default EventUserBadge;
