import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {SupportTicketType, TicketPriority, TicketStatus} from "../../../redux/Support/Ticket/types";
import {convertToMomentLocale} from "../../../helpers/functions";


const TicketColumns = ({ onDetail, onEdit, onRemove }:EntityActionsHandlersType) => {

    const { t, i18n } = useTranslation(['common']);

    moment.locale(convertToMomentLocale(i18n.language));


    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-light btn-sm" tag="a">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => onDetail(cell)}><i
                                className="ri-eye-fill align-bottom me-2 text-muted"/> {t('support:ticketDetails')} </DropdownItem>
                            { cell.actions && cell.actions.canEdit &&
                                <DropdownItem className='edit-item-btn' onClick={() => onEdit(cell)}><i
                                    className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:edit')} </DropdownItem>
                            }
                            {cell.actions && cell.actions.canRemove &&
                                <DropdownItem className='remove-item-btn' onClick={() => onRemove(cell)}><i className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')} </DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            id: 'code',
            name: <span className='font-weight-bold fs-14'>{t('sale:code')}</span>,
            maxWidth: '50px',
            sortable: true,
            selector: (row:SupportTicketType) => {
                return <Link to="#"  onClick={()=>{onDetail(row)}} className="fw-medium link-primary">{row.code}</Link>
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('support:status')}</span>,
            maxWidth: '200px',
            selector: (row:SupportTicketType) => {
                let renderTicketStatus = null;
                for (let ticketStatusKey in TicketStatus) {
                    if (TicketStatus[ticketStatusKey].status===row.status){
                        renderTicketStatus = <span className={"badge rounded-pill fs-12 "+TicketStatus[ticketStatusKey].class}> {t('support:ticketStatus'+TicketStatus[ticketStatusKey].label)} </span>;
                    }
                }
                if (renderTicketStatus === null) {
                    renderTicketStatus =
                        <span className="badge rounded-pill fs-12 bg-info"> {row.status} </span>;
                }
                let renderTicketPriority = null;
                for (let ticketPriorityKey in TicketPriority) {
                    if (TicketPriority[ticketPriorityKey].status===row.tier){
                        renderTicketPriority = <span className={"badge rounded-pill fs-12 "+TicketPriority[ticketPriorityKey].class}> {t('support:ticketPriority'+TicketPriority[ticketPriorityKey].label)} </span>;
                    }
                }
                if (renderTicketPriority === null) {
                    renderTicketPriority =
                        <span className="badge rounded-pill fs-12 bg-info"> {row.tier} </span>;
                }

                return <> {renderTicketStatus} {renderTicketPriority} </>;
            },
        },
        {
            id: 'name',
            name: <span className='font-weight-bold fs-14'>{t('support:ticketSubject')}</span>,
            sortable: true,
            selector: (row:SupportTicketType) => {
                let ticketBadge = null;
                if (row.unread !== undefined && row.unread !== null && row.unread >0){
                    ticketBadge = <span className={"badge rounded-pill fs-10 bg-primary me-1"}>{row.unread}</span>
                }

                return <div className="pt-2 pb-2" onClick={() => onDetail(row)}><h6>{ticketBadge && ticketBadge}{row.name}</h6><span className="text-truncate text-muted" style={{whiteSpace: "pre-wrap"}}>{row.text.substring(0, 50)}{row.text.length >= 50 && '...'}</span></div>;
            },
        },
        {
            id: 'createdAt',
            name: <span className='font-weight-bold fs-14'>{t('support:createdAt')}</span>,
            maxWidth: '200px',
            sortable: true,
            selector: (row:SupportTicketType) => {
                return moment(row.createdAt).format('llll');
            },
        },
        {
            id: 'updatedAt',
            name: <span className='font-weight-bold fs-14'>{t('support:updatedAt')}</span>,
            maxWidth: '200px',
            sortable: true,
            selector: (row:SupportTicketType) => {
                if (row.updatedAt !== undefined && row.updatedAt  !== null) {
                    return moment(row.updatedAt).format('llll');
                }
            },
        },
    ];
}

export default TicketColumns;