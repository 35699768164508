// types
import {PeerActivityModeActionTypes, PeerActivityModeState, PeerActivityModeType} from "./types";


export const INIT_STATE_CONTENT_DATAITEM_MODE: PeerActivityModeState = {
  items: [],
};

const PeerActivityMode = (state = INIT_STATE_CONTENT_DATAITEM_MODE, action: any) => {
  switch (action.type) {
    case PeerActivityModeActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case PeerActivityModeActionTypes.GET_CONTENT_PEER_ACTIVITY_MODES:
          return {
            ...state,
            items: action.payload.data.items,
            isPeerActivityModeFetched: true,
            getPeerActivityModeLoading: false,
          };
        default:
          return { ...state };
      }

    case PeerActivityModeActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case PeerActivityModeActionTypes.GET_CONTENT_PEER_ACTIVITY_MODES:
          return {
            ...state,
            isPeerActivityModeFetched: false,
            getPeerActivityModeLoading: false,
          };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default PeerActivityMode;
