import {MainDeviceActionTypes, MainDevicePayload} from "../Device/types";
import {MainUserActionTypes, MainUserPayload} from "../User/types";

export const mainDeviceApiResponseSuccess = (actionType: string, data: any) => ({
    type: MainDeviceActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const mainDeviceApiResponseError = (actionType: string, error: string) => ({
    type: MainDeviceActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCurrentDevice = () => ({
    type: MainDeviceActionTypes.GET_CURRENT_DEVICE
});

export const showRightModalMainDevice = (data: MainDevicePayload) => ({
    type: MainDeviceActionTypes.RIGHT_MODAL_SHOW,
    payload: data,
});

export const hideRightModalMainDevice= () => ({
    type: MainDeviceActionTypes.RIGHT_MODAL_HIDE,
});

export const getUserDevices = () => ({
    type: MainDeviceActionTypes.GET_MAIN_ALLOWED_DEVICES
});

export const removeMainDeviceModalShow = (data: MainDevicePayload) => ({
    type: MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE_MODAL_SHOW,
    payload: data,
});

export const removeMainDeviceModalHide = () => ({
    type: MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE_MODAL_HIDE,
});

export const onRemoveMainDevice = (data: MainDevicePayload) => ({
    type: MainDeviceActionTypes.REMOVE_MAIN_ALLOWED_DEVICE,
    payload: data,
});

export const onApproveMainDevice = (data: MainDevicePayload) => ({
    type: MainDeviceActionTypes.UPDATE_MAIN_ALLOWED_DEVICE,
    payload: data,
});