// types
import {EventNotificationActionTypes, EventNotificationState, EventNotificationType} from "./types";
import {NewEmptyActionsItem} from "../../types";


export const INIT_STATE_EVENT_NOTIFICATION: EventNotificationState = {
  items: [],
  isEventNotificationFetched: false,
  getEventNotificationLoading: false,
  isNeedReload: false,
  isLoading: false
};

const EventNotification = (state = INIT_STATE_EVENT_NOTIFICATION, action: any) => {
  switch (action.type) {
    case EventNotificationActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case EventNotificationActionTypes.GET_EVENT_NOTIFICATIONS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            unreadCount:  action.payload.data.unreadCount,
            isEventNotificationFetched: true,
            getEventNotificationLoading: false,
            isNeedReload: false,
            isLoading: false
          };
        case EventNotificationActionTypes.READ_EVENT_NOTIFICATIONS:
          return {
            ...state,
            isNeedReload: true
          }
        default:
          return { ...state };
      }

    case EventNotificationActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case EventNotificationActionTypes.GET_EVENT_NOTIFICATIONS:
          return {
            ...state,
            isEventNotificationFetched: false,
            getEventNotificationLoading: false,
            isNeedReload: false,
            isLoading: false
          };
        default:
          return { ...state };
      }

    case EventNotificationActionTypes.GET_EVENT_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true
      };

    default:
      return { ...state };
  }
};

export default EventNotification;
