import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    UncontrolledDropdown
} from 'reactstrap';

import '../../i18/config';
import {useTranslation} from "react-i18next";
import {StatTileType} from "../../redux/Stat/DashboardSale/types";
import VectorMapItem from "../VectorMap";
import {OrderStatusTypes} from "../../redux/Sale/OrderStatus/types";
import {isNumeric} from "../../helpers/functions";
import {countries} from 'country-data';
import {capitalizeFirstLetter} from "../../helpers/functions";


const createMapItems = (items: StatTileType[], status: string) => items.filter((item: StatTileType) => item.type === status);

// utility function to calculate maxValue
const calculateMaxValue = (items: StatTileType[])=>
    items.reduce((accum, item) => isNumeric(item.value?.last) ? accum + item.value?.last : accum, 0);

interface ItemProps {
    items: [] | Array<StatTileType>;
}

const OrdersCountriesMap = ({ items }: ItemProps) => {

    const { t } = useTranslation();
    const [shownStatus, setShownStatus] = useState<string>(OrderStatusTypes.ALL);

    const [shownItems, setShownItems] = useState<StatTileType[]>(createMapItems(items || [], shownStatus));
    const [maxValue, setMaxValue] = useState<number>(calculateMaxValue(shownItems));

    useEffect(() => setShownItems(createMapItems(items || [], shownStatus)), [shownStatus, items]);

    useEffect(() => setMaxValue(calculateMaxValue(shownItems)), [shownItems]);

    const handleClickShownStatus = (status: string) => setShownStatus(status);

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('common:geoData')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                       <span className="text-muted">{t('orderStatus:orderStatusType'+capitalizeFirstLetter(shownStatus))}<i className="mdi mdi-chevron-down ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {(Object.keys(OrderStatusTypes).map((item)=>{
                            return(
                                <DropdownItem onClick={()=>{handleClickShownStatus(OrderStatusTypes[item])}} key={"ordersStatusCountriesFilter_"+OrderStatusTypes[item]}>{t('orderStatus:orderStatusType'+capitalizeFirstLetter(OrderStatusTypes[item]))}</DropdownItem>
                            )
                        }))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>
                <VectorMapItem items={shownItems}/>
                {maxValue>0 &&
                <div>
                    {(shownItems || []).map((item:StatTileType, key:number)=>{
                        return(
                            <div key={'ordersProgressCountries_'+key} className="mb-2">
                                <div className="d-flex mb-2 align-items-center flex-row">
                                    <div className="flex-1 flex-grow-1">
                                        <span className="fs-12 text-truncate">{(countries[item.label] !== undefined && countries[item.label] !== null) ? countries[item.label].name : item.label}</span>
                                    </div>
                                    <div className="d-flex flex-row align-items-center flex-shrink-0">
                                        <span className="fs-12 text-muted">{item.value.last}</span>
                                        <span className={"badge ms-2 bg-primary"}>{Math.ceil(item.value.last/maxValue*100)}%</span>
                                    </div>
                                </div>
                                <Progress
                                    className={"d-flex flex-grow-1 flex-row w-100 progress-sm countryProgress_"+key}
                                    value={Math.ceil(item.value.last/maxValue*100)}
                                    barClassName={'bg-primary'}
                                    striped={false}
                                    animated={true}
                                />
                            </div>
                        )
                    })}
                </div>
                }
            </CardBody>
        </Card>
    );
}

export default OrdersCountriesMap;