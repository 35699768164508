import {EventNotificationActionTypes, EventNotificationPayload} from "./types";
import {EventTypeType} from "../EventType/types";

export const eventNotificationApiResponseSuccess = (actionType: string, data: any) => ({
  type: EventNotificationActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const eventNotificationApiResponseError = (actionType: string, error: string) => ({
  type: EventNotificationActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getEventNotifications = (data: any) => ({
  type: EventNotificationActionTypes.GET_EVENT_NOTIFICATIONS,
  payload: data,
});

export const readEventNotifications = (data: EventTypeType) => ({
  type: EventNotificationActionTypes.READ_EVENT_NOTIFICATIONS,
  payload: data
});