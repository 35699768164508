import {
    changeTaskStatus,
    closeTaskGroup,
    closeTaskRemoveModalHide, closeTaskRemoveModalShow,
    copyTask,
    editDataTask, groupTaskRemoveModalHide, groupTaskRemoveModalShow, onRemoveTask, removeTaskGroup,
    removeTaskModalHide,
    removeTaskModalShow,
    showRightModalTask
} from "../../../redux/Task/Task/actions";
import {NewTaskItem} from "../../../redux/Task/Task/reducer";
import {TaskStatus, TaskType} from "../../../redux/Task/Task/types";

export const handleTaskEdit = (event:any, dispatch: any) => {

    if ((event !== undefined) && (event?.uuid !== undefined) && (event?.uuid.length>0)){
        dispatch(editDataTask({data:event, modalEditTitle: 'changeTask'}));
    } else {

        let newTask:TaskType = NewTaskItem;

       if (event?.text !== undefined || event?.name !== undefined){
           newTask = {
               ...newTask,
               ...event
           }
       }

        dispatch(showRightModalTask({data:newTask, modalEditTitle: 'newTask'}));
    }
};

export const handleTaskNew = (dispatch: any) => {
    dispatch(showRightModalTask({data:NewTaskItem, modalEditTitle: 'newTask'}));
}

export const handleTaskCopy = (task:TaskType, dispatch: any) =>{
    dispatch(copyTask({task: task}));
}

export const handleTaskChangeStatus = (task:TaskType, newStatus: string,  dispatch: any) =>{
    dispatch(changeTaskStatus({data: {uuid:task.uuid, status:newStatus},modalEditTitle:''}));
}

export const handleTaskRemoveShow = (event:any, dispatch: any) => {
    if ((event !== undefined) && (event?.uuid.length>0)){
        dispatch(removeTaskModalShow({data: event, modalEditTitle: 'deleteTask'}));
    }
};

export const handleTaskRemoveHide = (dispatch: any) => {
    dispatch(removeTaskModalHide())
};

export const handleTaskRemoveAction = (event:any, dispatch: any) => {
    if ((event !== undefined) && (event?.uuid.length>0)){
        dispatch(onRemoveTask({data: event, modalEditTitle: 'deleteTask'}));
    }
};


export const handleTaskGroupCloseAction = (event:any, dispatch: any) => {
    dispatch(closeTaskGroup(event));
};

export const handleTaskGroupCloseShow = (dispatch: any) => {
    dispatch(closeTaskRemoveModalShow())
};

export const handleTaskGroupCloseHide = (dispatch: any) => {
    dispatch(closeTaskRemoveModalHide())
};

export const handleTaskGroupRemoveAction = (event:any, dispatch: any) => {
    dispatch(removeTaskGroup(event));
};

export const handleTaskGroupRemoveShow = (dispatch: any) => {
    dispatch(groupTaskRemoveModalShow())
};

export const handleTaskGroupRemoveHide = (dispatch: any) => {
    dispatch(groupTaskRemoveModalHide())
};