import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleDeliverySystemActionTypes } from "./types";
import { saleDeliverySystemApiResponseSuccess, saleDeliverySystemApiResponseError } from "./actions";

import {
  getSaleDeliverySystems as getSaleDeliverySystemsApi,
  sendDataSaleDeliverySystem as sendDataSaleDeliverySystemApi,
  removeSaleDeliverySystem as removeSaleDeliverySystemApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleDeliverySystems({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleDeliverySystemsApi,data);
    yield put(saleDeliverySystemApiResponseSuccess(SaleDeliverySystemActionTypes.GET_SALE_DELIVERY_SYSTEMS, response));
  } catch (error: any) {
    yield put(saleDeliverySystemApiResponseError(SaleDeliverySystemActionTypes.GET_SALE_DELIVERY_SYSTEMS, error));
  }
}

function* onSendDataSaleDeliverySystem({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleDeliverySystemApi, data);
    yield put(
        saleDeliverySystemApiResponseSuccess(SaleDeliverySystemActionTypes.UPDATE_SALE_DELIVERY_SYSTEM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleDeliverySystemApiResponseError(SaleDeliverySystemActionTypes.UPDATE_SALE_DELIVERY_SYSTEM, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleDeliverySystem({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleDeliverySystemApi, {uuid: data.data.uuid});
    yield put(
        saleDeliverySystemApiResponseSuccess(SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleDeliverySystemApiResponseError(SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleDeliverySystems() {
  yield takeEvery(SaleDeliverySystemActionTypes.GET_SALE_DELIVERY_SYSTEMS, getSaleDeliverySystems);
}
export function* watchOnSendDataSaleDeliverySystem() {
  yield takeEvery(SaleDeliverySystemActionTypes.UPDATE_SALE_DELIVERY_SYSTEM, onSendDataSaleDeliverySystem);
}
export function* watchOnRemoveSaleDeliverySystem() {
  yield takeEvery(SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM, onRemoveSaleDeliverySystem);
}

function* saleDeliverySystemSaga() {
  yield all([
    fork(watchGetSaleDeliverySystems),
    fork(watchOnSendDataSaleDeliverySystem),
    fork(watchOnRemoveSaleDeliverySystem)
  ]);
}

export default saleDeliverySystemSaga;
