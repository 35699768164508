import { APIClient } from "../apiCore";
import * as url from "../urls";
import {
    GET_SUPPORT_BADGES_SUMMARY,
    GET_SUPPORT_SERVICE_BADGES_SUMMARY,
    READ_SUPPORT_SERVICE_TICKET_MESSAGES, READ_SUPPORT_TICKET_MESSAGES
} from "../urls";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSupportTickets = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(url.GET_SUPPORT_TICKETS+"?"+query);
};

const getSupportTicketDetail = (data:any) => {
    return api.get(url.GET_SUPPORT_TICKET_DETAIL.replace(/:uuid/,data.uuid));
};

const updateSupportTicket = (data: any) => {

    if (data.attachmentFiles !== undefined){
        return api.createWithFile(url.UPDATE_SUPPORT_TICKET,data);
    }

    return api.create(url.UPDATE_SUPPORT_TICKET, data);
};

const removeSupportTicket = (data: any) => {
    return api.create(url.REMOVE_SUPPORT_TICKET, data);
};

const getSupportServiceTickets = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(url.GET_SUPPORT_SERVICE_TICKETS+"?"+query);
};

const getSupportServiceTicketDetail = (data:any) => {
    return api.get(url.GET_SUPPORT_SERVICE_TICKET_DETAIL.replace(/:uuid/,data.uuid));
};

const rewardSupportTicket = (data: any) => {
    return api.create(url.SEND_REWARD_SUPPORT_TICKET, data);
};

const getSupportBadgesTickets = () => {
    return api.get(url.GET_SUPPORT_BADGES_SUMMARY);
};
const getSupportServiceBadgesTickets = () => {
    return api.get(url.GET_SUPPORT_SERVICE_BADGES_SUMMARY);
};

const readSupportTicketMessages = (data:any) => {
    return api.get(url.READ_SUPPORT_TICKET_MESSAGES.replace(/:uuid/,data.uuid));
};
const readSupportServiceTicketMessages = (data:any) => {
    return api.get(url.READ_SUPPORT_SERVICE_TICKET_MESSAGES.replace(/:uuid/,data.uuid));
};

export {
    getSupportTickets,
    updateSupportTicket,
    removeSupportTicket,
    getSupportTicketDetail,
    rewardSupportTicket,
    getSupportServiceTickets,
    getSupportServiceTicketDetail,
    getSupportBadgesTickets,
    getSupportServiceBadgesTickets,
    readSupportTicketMessages,
    readSupportServiceTicketMessages
};
