import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentConnectorActionTypes } from "./types";
import {
  contentConnectorApiResponseSuccess,
  contentConnectorApiResponseError
} from "./actions";

import {
  getContentConnectors as getContentConnectorsApi,
  sendDataContentConnector,
  connectContentConnector,
  isConnectedContentConnector,
  removeContentConnector,
  searchChannelFromRemote as searchChannelFromRemoteApi,
  getManagedChannelsFromRemote as getManagedChannelsFromRemoteApi,
  sendDataContentPeerChannel as sendDataContentPeerChannelApi,
  sendDataContentStatCounter as sendDataContentStatCounterApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getContentConnectors() {
  try {
    const response: Promise<any> = yield call(getContentConnectorsApi);
    yield put(contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTORS, response));
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.GET_CONTENT_CONNECTORS, error));
  }
}

function* onSendDataContentConnector({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentConnector, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.UPDATE_CONTENT_CONNECTOR, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.UPDATE_CONTENT_CONNECTOR, error));
    yield call(showErrorNotification, error);
  }
}

function* onConnectContentConnector({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(connectContentConnector, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR, error));
    yield call(showErrorNotification, error);
  }
}

function* onIsConnectedContentConnector({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(isConnectedContentConnector, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.IS_CONNECTED_CONTENT_CONNECTOR, response)
    );
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.IS_CONNECTED_CONTENT_CONNECTOR, error));
  }
}

function* onRemoveContentConnector({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeContentConnector, {uuid: data.data.uuid});
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR, error));
    yield call(showErrorNotification, error);
  }
}

function* onGetContentConnectorSearchChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(searchChannelFromRemoteApi, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS, response)
    );
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS, error));
  }
}

function* onGetContentConnectorManagedChannels({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getManagedChannelsFromRemoteApi, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_CHANNELS, response)
    );
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_CHANNELS, error));
  }
}

function* onGetContentConnectorManagedCounters({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getManagedChannelsFromRemoteApi, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_COUNTERS, response)
    );
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_COUNTERS, error));
  }
}

function* onConnectContentConnectorManagedChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentPeerChannelApi, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL, error));
    yield call(showErrorNotification, error);
  }
}

function* onConnectContentConnectorManagedCounter({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentStatCounterApi, data);
    yield put(
        contentConnectorApiResponseSuccess(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentConnectorApiResponseError(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetContentConnectors() {
  yield takeEvery(ContentConnectorActionTypes.GET_CONTENT_CONNECTORS, getContentConnectors);
}
export function* watchOnSendDataContentConnector() {
  yield takeEvery(ContentConnectorActionTypes.UPDATE_CONTENT_CONNECTOR, onSendDataContentConnector);
}
export function* watchOnConnectContentConnector() {
  yield takeEvery(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR, onConnectContentConnector);
}
export function* watchOnIsConnectedContentConnector() {
  yield takeEvery(ContentConnectorActionTypes.IS_CONNECTED_CONTENT_CONNECTOR, onIsConnectedContentConnector);
}

export function* watchOnRemoveContentConnector() {
  yield takeEvery(ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR, onRemoveContentConnector);
}
export function* watchOnGetContentConnectorSearchChannel() {
  yield takeEvery(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS, onGetContentConnectorSearchChannel);
}
export function* watchOnGetContentConnectorManagedChannels() {
  yield takeEvery(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_CHANNELS, onGetContentConnectorManagedChannels);
}

export function* watchOnGetContentConnectorManagedCounters() {
  yield takeEvery(ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_COUNTERS, onGetContentConnectorManagedCounters);
}
export function* watchOnConnectContentConnectorManagedChannel() {
  yield takeEvery(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL, onConnectContentConnectorManagedChannel);
}

export function* watchOnConnectContentConnectorManagedCounter() {
  yield takeEvery(ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER, onConnectContentConnectorManagedCounter);
}


function* contentConnectorSaga() {
  yield all([
    fork(watchGetContentConnectors),
    fork(watchOnSendDataContentConnector),
    fork(watchOnConnectContentConnector),
    fork(watchOnRemoveContentConnector),
    fork(watchOnIsConnectedContentConnector),
    fork(watchOnGetContentConnectorSearchChannel),
    fork(watchOnGetContentConnectorManagedChannels),
      fork(watchOnConnectContentConnectorManagedChannel),
      fork(watchOnGetContentConnectorManagedCounters),
      fork(watchOnConnectContentConnectorManagedCounter)
  ]);
}

export default contentConnectorSaga;
