import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import {useTranslation} from "react-i18next";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {useRedux} from "../../hooks";

import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails";
import Welcome from "./ConversationUser/Welcome";
import {useConversationUserType} from "../../hooks/UserHooks";
import ChatsIndex from "./Chats";
import {
  changeSelectedChat,
  getChannelDetails, getChannels,
  getChatUserConversations,
  getChatUserDetails,
  readConversation, toggleCollapsedMessenger
} from "../../redux/Messenger/Chat/actions";
import {getMainUsers} from "../../redux/Main/User/actions";
import {MessengerChannelType} from "../../redux/Messenger/Chat/types";
import {getEventUserBadges} from "../../redux/Event/Badge/actions";
import {convertToMomentLocale} from "../../helpers/functions";

const MessengerIndex = () => {

  const ref = useRef();

  const { t, i18n } = useTranslation(['common']);

  moment.locale(convertToMomentLocale(i18n.language));


  const { dispatch, useAppSelector } = useRedux();

  const {
    mainCabinet,
    messengerChat,
    isCollapsed
  } = useAppSelector(state => ({
    mainCabinet: state.MainCabinet,
    messengerChat: state.MessengerChat,
    isCollapsed: state.MessengerChat.isCollapsed
  }));

  const { isChannel } = useConversationUserType();

  const onSelectChat = (id: string | number, isChannel?: boolean) => {
    if (isChannel) {
      dispatch(getChannelDetails(id));
    } else {
      dispatch(getChatUserDetails(id));
    }

    dispatch(getChatUserConversations(id));
    dispatch(changeSelectedChat(id));

    let currentChannel = (messengerChat.channels || []).filter((item:MessengerChannelType)=>{return item.uuid === id}).pop();

    if (currentChannel === undefined){
      currentChannel = (messengerChat.archiveContacts || []).filter((item:MessengerChannelType)=>{return item.uuid === id}).pop();
    }

    if (currentChannel?.uuid !== undefined && currentChannel?.uuid !== null && currentChannel?.unreadMessagesCount>0){
      dispatch(readConversation(id));
      setTimeout(() => {
        dispatch(getChannels({page:1}));
        dispatch(getEventUserBadges());
      },2000);
    }
  };

  useEffect(() => {

    if (mainCabinet?.currentCabinet && mainCabinet?.currentCabinet?.uuid) {
      dispatch(getMainUsers({maxResults: 1000, cabinetUuid: mainCabinet?.currentCabinet && mainCabinet.currentCabinet.uuid}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mainCabinet?.currentCabinet?.uuid]);

  // useEffect(() => {
  //   setCollapsed(messengerChat.isCollapsed);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, messengerChat.isCollapsed]);

  const handleMessengerCollapsed= () =>{
    dispatch(toggleCollapsedMessenger())
  }

  return (
    <React.Fragment>

          <div className={"chat-wrapper d-lg-flex"+(isCollapsed ? ' collapsedMessenger':'')}>
            <div className="chat-leftsidebar">
              <ChatsIndex onSelectChat={onSelectChat}/>
            </div>

            <div
                className={classnames("user-chat", "w-100", "overflow-hidden", {
                  "user-chat-show": messengerChat.selectedChat,
                },{"chatCollapsed": isCollapsed})}
                id="user-chat"
            >
              <div className="user-chat-overlay" id="user-chat-overlay"></div>
              {messengerChat.selectedChat !== null ? (
                  <div className="chat-content d-lg-flex">
                    <div className="w-100 overflow-hidden position-relative">
                      <ConversationUser isChannel={isChannel} />
                    </div>
                      <UserProfileDetails isChannel={isChannel} onSelectChat={onSelectChat}/>
                  </div>
              ) : (
                  <Welcome />
              )}
            </div>
          </div>

    </React.Fragment>
  );
};

export default MessengerIndex;
