import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getEventNotifications = (data: any) => {
    const query = createPaginationQuery(data)
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_EVENT_NOTIFICATIONS+'?'+query);
};

const readEventNotifications = (data: any) => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.READ_EVENT_NOTIFICATIONS+'?eventType='+data.code);
};

export {
    getEventNotifications,
    readEventNotifications
};
