import React from 'react';
import * as Yup from 'yup';

export const SaleCurrencyRateValidatorSchema = Yup.object().shape({
    baseRate: Yup.number()
        .required('required'),
    rate: Yup.number()
        .required('required'),
    currencyFirst:Yup.object().required('required'),
    currencySecond:Yup.object().required('required'),
    dateTime: Yup.date().required('required')
});