import React from 'react';
import * as Yup from 'yup';

export const SaleOrderItemsValidatorSchema = Yup.object().shape({
    orderItems: Yup.array()
        .of(
            Yup.object().shape({
                product: Yup.object()
                    .typeError('required')
                    .required('required'),
                priceType: Yup.object()
                    .typeError('required')
                    .required('required'),
                price: Yup.number()
                    .typeError('digitsOnly')
                    .required('required'),
                discount: Yup.number()
                    .nullable(true)
                    .typeError('digitsOnly'),
                quantity: Yup.number()
                    .typeError('digitsOnly')
                    .required('required')
            })
        )
        .required('Required'),
})
