import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row, Form } from 'reactstrap';
import LeftbarTitle from "./LeftbarTitle";
import {useRedux} from "../hooks";
import '../i18/config';
import {useTranslation} from "react-i18next";
import ButtonSpinner from "./ButtonSpinner";

interface ItemProps {
    onRemove: (data: any, dispatch: any) => void;
    handleClose: (dispatch: any) => void;
    removeItem: any;
    isOpen: boolean;
    modalTitle: string;
}

const RemoveModal = ({ onRemove, removeItem, handleClose, isOpen, modalTitle }: ItemProps) => {

    const { t, i18n } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();

    const [isModalShow, setModalShow] = useState<boolean>(isOpen);
    const [title, setTitle] = useState<string>(modalTitle);
    const [removeName, setRemoveName] = useState<string>(null);
    const [inProcess, setInProcess] = useState<boolean>(false);
    const namespaces = ['connector', 'counter', 'comment', 'activity', 'project', 'target', 'peerActivityType', 'group', 'user', 'channel', 'statCounter', 'template', 'quickAnswer', 'support', 'currency', 'price', 'payment', 'delivery', 'store', 'category', 'product', 'integration', 'customer', 'webhook', 'orderStatus', 'sale', 'device'];

    useEffect(() => {
        setModalShow(isOpen);
        setInProcess(false);
    }, [isOpen]);

    useEffect(() => {
        const _removeName = removeItem?.name || removeItem?.code || '';
        setRemoveName(_removeName);
    }, [removeItem]);

    useEffect(() => {
        const namespace = namespaces.find(ns => i18n.exists(modalTitle, { ns }));
        setTitle(namespace ? t(modalTitle, { ns: namespace }) : modalTitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalTitle]);


    const onDataSubmit = (e:any) => {
        e.preventDefault();
        setInProcess(true);
        onRemove(removeItem, dispatch);
    };

    return (
        <>
            <Modal show={isModalShow} onHide={()=>handleClose(dispatch)}>
                    <Form noValidate={true} onSubmit={onDataSubmit}>
                        <Modal.Header closeButton>
                            <LeftbarTitle title={title}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column align-items-center me-5">
                                        {t('common:doYouWantDelete')} {removeName}?
                                    </div>
                                </div>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={()=>handleClose(dispatch)}>
                                {t('common:cancel')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={inProcess}
                                    className="btn btn-danger chat-send waves-effect waves-light"
                            >
                                {t('common:delete')}
                                {inProcess &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                        </Modal.Footer>
                    </Form>
            </Modal>
        </>
    );
}

export default RemoveModal;