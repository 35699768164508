import React, { useState } from "react";

import { Button, UncontrolledTooltip, PopoverBody, Popover } from "reactstrap";

// emoji picker
import Picker from "emoji-picker-react";

//i18
import '../../../../i18/config';
import {useTranslation} from "react-i18next";

interface StartButtonsProps {
  onToggle: () => void;
  onChange: (value: string) => void;
  text: null | string;
}
const StartButtons = ({ onToggle, onChange, text }: StartButtonsProps) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggleEmoji = () => {
    setIsOpen(!isOpen);
  };

  if (text == null) {text = '';}

  const onEmojiClick = (event: any, emojiObject: any) => {
    onChange( text + emojiObject.emoji);
  };

    const { t } = useTranslation(['common']);

  return (
    <div className="chat-input-links me-md-2">
      {/*<div className="links-list-item" id="more-menu">*/}
      {/*  <Button*/}
      {/*    type="button"*/}
      {/*    className="btn btn-link text-decoration-none btn-lg waves-effect"*/}
      {/*    onClick={onToggle}*/}
      {/*    color="none"*/}
      {/*  >*/}
      {/*    <i className="bx bx-dots-horizontal-rounded align-middle"></i>*/}
      {/*  </Button>*/}
      {/*</div>*/}
      {/*<UncontrolledTooltip target="more-menu" placement="top">*/}
      {/*    {t('common:more')}*/}
      {/*</UncontrolledTooltip>*/}
      <div className="links-list-item" id="emoji">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect emoji-btn"
          id="emoji-btn"
          color="none"
          onClick={onToggleEmoji}
        >
          <i className="bx bx-smile align-middle"></i>
        </Button>
      </div>
      <UncontrolledTooltip target="emoji" placement="top">
        Emoji
      </UncontrolledTooltip>
        {isOpen === true &&
            <div className="emojiPickerMessengerCont">
              <Picker onEmojiClick={onEmojiClick} />
            </div>
        }
    </div>
  );
};

export default StartButtons;
