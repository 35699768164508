import {
  AuthLoginActionTypes,
  AuthLoginState,
  LoginByTypes,
  LoginCabinetRolesType,
  LoginJWTType,
  LoginType
} from "./types";
import {getLoggedinUser, setLoggeedInUser, setLogOutUser} from "../../../api/apiCore";
import jwt_decode from "jwt-decode";
import {ROLE_SUPPORT_ADMIN, ROLE_SUPPORT_USER} from "../../Support/types";
import {removeApprovedDeviceUser} from "../../../helpers/functions";

const emptyAvatar = require("../../../assets/images/users/user-dummy-img.jpg");

export const INIT_LOGIN: LoginType = {
  email: '',
  password: '',
};

export const INIT_STATE: AuthLoginState = {
  error: "",
  isLoading: false,
  isUserLogin: false,
  isUserLogout: false,
  isWSConnected: false,
  isSupportService: false,
  user: null,
  deviceUsers:[],
  loginBy:LoginByTypes.PASSWORD
};

const Login = (state = INIT_STATE, action: any) => {

  let decodedJWTToken:LoginJWTType= undefined;

  switch (action.type) {
    case AuthLoginActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
        case AuthLoginActionTypes.GET_JWT_TOKEN:
        case AuthLoginActionTypes.SOCIAL_LOGIN:
        case AuthLoginActionTypes.LOGIN_USER_BY_DEVICE:

          if (action.payload.data.avatar === undefined){
            action.payload.data.avatar=emptyAvatar;
          }

          setLoggeedInUser(action.payload.data);
          getLoggedinUser();

          decodedJWTToken = jwt_decode(action.payload.data.token);

          return {
            ...state,
            error: '',
            user: action.payload.data,
            isLoading: false,
            isUserLogin: decodedJWTToken.enabled,
            isUserLogout: false,
            isUserDisabled: !decodedJWTToken.enabled,
            confirm: decodedJWTToken.confirm && decodedJWTToken.confirm,
            change: decodedJWTToken.change && decodedJWTToken.change,
            isSupportService: ((decodedJWTToken?.roles && decodedJWTToken.roles.length>0 && (decodedJWTToken.roles.indexOf(ROLE_SUPPORT_ADMIN)!==-1)) || (decodedJWTToken?.roles && decodedJWTToken.roles.length>0 && (decodedJWTToken.roles.indexOf(ROLE_SUPPORT_USER)!==-1)))
          };

        case AuthLoginActionTypes.LOGOUT_USER:
          setLogOutUser();
          removeApprovedDeviceUser(state.user.uuid);

          return {
            ...state,
            isLoading: false,
            isUserLogin: false,
            isUserLogout: state.isUserLogin && true,
            isUserDisabled: false,
            isSupportService: false
          };

        case AuthLoginActionTypes.CHECK_LOGIN_USER:

          let localUser = getLoggedinUser();

          decodedJWTToken = jwt_decode(localUser.token);

          return {
            ...state,
            error: '',
            user: localUser,
            isLoading: false,
            isUserLogin: decodedJWTToken.enabled,
            isUserLogout: false,
            isUserDisabled: !decodedJWTToken.enabled,
            confirm: decodedJWTToken.confirm && decodedJWTToken.confirm,
            change: decodedJWTToken.change && decodedJWTToken.change,
            isSupportService: ((decodedJWTToken?.roles && decodedJWTToken.roles.length>0 && (decodedJWTToken.roles.indexOf(ROLE_SUPPORT_ADMIN)!==-1)) || (decodedJWTToken?.roles && decodedJWTToken.roles.length>0 && (decodedJWTToken.roles.indexOf(ROLE_SUPPORT_USER)!==-1)))
          };
        case AuthLoginActionTypes.GET_DEVICE_USERS:
          return {
            ...state,
            deviceUsers: action.payload.data.items,
            loginBy: ((action.payload.data.items || []).length>0) ? LoginByTypes.DEVICE : LoginByTypes.PASSWORD
          }

        default:
          return { ...state };
      }

    case AuthLoginActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
        case AuthLoginActionTypes.GET_JWT_TOKEN:
        case AuthLoginActionTypes.SOCIAL_LOGIN:
        case AuthLoginActionTypes.LOGIN_USER_BY_DEVICE:

          if ((action.payload.error?.message) && (action.payload.error.message==='Invalid credentials.')){
            action.payload.error.message='invalidCredentials';
          }

          return {
            ...state,
            error: action.payload.error,
            isUserLogin: false,
            isLoading: false,
            isUserDisabled: false,
            isSupportService: false
          };
        case AuthLoginActionTypes.CHECK_LOGIN_USER:

          setLogOutUser();

          return {
            ...state,
            isLoading: false,
            isUserLogin: false,
            isUserLogout: true,
            isUserDisabled: false,
            isSupportService: false
          };
        case AuthLoginActionTypes.GET_DEVICE_USERS:
          return {
            ...state,
            deviceUsers: []
          }
        default:
          return { ...state };
      }

    case AuthLoginActionTypes.LOGIN_USER:
    case AuthLoginActionTypes.GET_JWT_TOKEN:
    case AuthLoginActionTypes.SOCIAL_LOGIN:
    case AuthLoginActionTypes.LOGIN_USER_BY_DEVICE:
    {
      return {
        ...state,
        isLoading: true,
        isUserLogin: false,
      };
    }

    case AuthLoginActionTypes.LOGOUT_USER:
      return {
        ...state,
        isLoading: false,
      };

    case AuthLoginActionTypes.WS_CONNECTED:
      return {
        ...state,
        isWSConnected: true,
      };
    case AuthLoginActionTypes.WS_DISCONNECTED:
      return {
        ...state,
        isWSConnected: false,
      };
    case AuthLoginActionTypes.CHANGE_EMAIL_COMPLETE:
      return {
        ...state,
        change: {...state.change, email: false},
      };
    case AuthLoginActionTypes.CHANGE_LOGIN_BY:
      return {
        ...state,
        loginBy: action.payload,
      };
    case AuthLoginActionTypes.CHANGE_USER_ACCOUNT:
      setLogOutUser();

      return {
        ...state,
        isLoading: false,
        isUserLogin: false,
        isUserLogout: state.isUserLogin && true,
        isUserDisabled: false,
        isSupportService: false
      };
    default:
      return { ...state };
  }
};

export default Login;
