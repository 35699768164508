import {SupportTicketMessageType} from "../TicketMessage/types";
import {MainFileType} from "../../Main/File/types";
import {EntityActionsType} from "../../types";
import {MainUserType} from "../../Main/User/types";
import {MainMetaType} from "../../Main/common";

export enum SupportTicketActionTypes {
  API_RESPONSE_SUCCESS = "@@supportTicket/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@supportTicket/API_RESPONSE_ERROR",
  GET_SUPPORT_TICKETS = "@@supportTicket/GET_SUPPORT_TICKETS",
  GET_SUPPORT_TICKET_DETAIL = "@@supportTicket/GET_SUPPORT_TICKET_DETAIL",

  GET_SUPPORT_SERVICE_TICKETS = "@@supportTicket/GET_SUPPORT_SERVICE_TICKETS",
  GET_SUPPORT_SERVICE_TICKET_DETAIL = "@@supportTicket/GET_SUPPORT_SERVICE_TICKET_DETAIL",
  UPDATE_SUPPORT_TICKET = "@@supportTicket/UPDATE_SUPPORT_TICKET",
  REMOVE_SUPPORT_TICKET_MODAL_SHOW = "@@supportTicket/REMOVE_SUPPORT_TICKET_MODAL_SHOW",
  REMOVE_SUPPORT_TICKET_MODAL_HIDE = "@@supportTicket/REMOVE_SUPPORT_TICKET_MODAL_HIDE",
  REWARD_SUPPORT_TICKET_MODAL_SHOW = "@@supportTicket/REWARD_SUPPORT_TICKET_MODAL_SHOW",
  REWARD_SUPPORT_TICKET_MODAL_HIDE = "@@supportTicket/REWARD_SUPPORT_TICKET_MODAL_HIDE",
  SEND_REWARD_SUPPORT_TICKET = "@@supportTicket/SEND_REWARD_SUPPORT_TICKET",
  REMOVE_SUPPORT_TICKET = "@@supportTicket/REMOVE_SUPPORT_TICKET",
  EDIT_SUPPORT_TICKET = "@@supportTicket/EDIT_SUPPORT_TICKET",
  RIGHT_MODAL_SHOW = "@@supportTicket/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@supportTicket/RIGHT_MODAL_HIDE",
  READ_SUPPORT_TICKET_MESSAGES = "@@supportTicket/READ_SUPPORT_TICKET_MESSAGES",
  READ_SUPPORT_SERVICE_TICKET_MESSAGES = "@@supportTicket/READ_SUPPORT_SERVICE_TICKET_MESSAGES",
}

export interface SupportTicketState extends MainMetaType{
  items: Array<any>;
  isLoading: boolean;
  isSending: boolean;
  selectedItem: SupportTicketType;
  rightModalShow: boolean;
  removeModalShow: boolean;
  rewardModalShow: boolean;
  modalEditTitle: string;
  isNeedReload: boolean
}

export interface SupportTicketType{
  uuid?: string
  enabled?: boolean;
  code?: string;
  name: string;
  text?: string;
  cabinet?: string;
  status?: string;
  messages?: Array<SupportTicketMessageType>
  media?:Array<MainFileType>;
  attachmentFiles?: any;
  updatedAt?:string;
  createdAt?:string;
  owner?: MainUserType;
  actions?: EntityActionsType,
  tier?: number;
  unread?: number;
}

export interface SupportTicketPayload {
  data: SupportTicketType;
  modalEditTitle : string;
}

export const TicketStatus = {
  ALL: {
    label: 'All',
    class: 'text-bg-info',
    status: ''
  },
  PENDING: {
    label: 'Pending',
    class: 'text-bg-success',
    status: 'P'
  },
  CANCELLED: {
    label: 'Cancelled',
    class: 'text-bg-danger',
    status: 'C'
  },
  NEW: {
    label: 'New',
    class: 'text-bg-info',
    status: 'N'
  },
  INPROGRESS: {
    label: 'InProgress',
    class: 'text-bg-secondary',
    status: 'I'
  },
  PICKUPS: {
    label: 'Pickups',
    class: 'text-bg-info',
    status: 'K'
  },
  RETURNS: {
    label: 'Returns',
    class: 'text-bg-primary',
    status: 'R'
  },
  DELIVERED: {
    label: 'Delivered',
    class: 'text-bg-success',
    status: 'D'
  },
  FINAL: {
    label: 'Final',
    class: 'border border-dark text-dark',
    status: 'F'
  },
  OPEN: {
    label: 'Open',
    class: 'badge-soft-dark',
    status: 'O'
  }
}

export const TicketPriority = {
  ALL: {
    label: 'All',
    class: 'text-bg-info',
    status: null
  },
  TIER3: {
    label: 'Low',
    class: 'text-bg-primary',
    status: 3
  },
  TIER2: {
    label: 'Medium',
    class: 'badge-soft-primary',
    status: 2
  },
  TIER1: {
    label: 'High',
    class: 'text-bg-danger',
    status: 1
  }
}