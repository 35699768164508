import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleOrderActionTypes } from "./types";
import { saleOrderApiResponseSuccess, saleOrderApiResponseError } from "./actions";

import {
  getSaleOrders as getSaleOrdersApi,
  getSaleOrderDetail as getSaleOrderDetailApi,
  removeSaleOrder as removeSaleOrderApi,
  updateSaleOrder as updateSaleOrderApi,
  cancelSaleOrder as cancelSaleOrderApi,
  changeSaleOrderStatus as changeSaleOrderStatusApi,
  getSaleProducts as getSaleProductsApi,
  changeSaleOrderItems as changeSaleOrderItemsApi,
  getComments as getCommentsApi,
  updateComment as updateCommentApi,
  removeComment as removeCommentApi, removeGroupSaleOrder as removeGroupSaleOrderApi
} from "../../../api";
import {showErrorNotification, showSuccessNotification} from "../../../helpers/notifications";

function* getSaleOrders({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleOrdersApi, data);
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.GET_ORDERS, response));
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.GET_ORDERS, error));
  }
}

function* getSaleOrderDetail({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleOrderDetailApi, data);
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.GET_ORDER_DETAIL, response));
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.GET_ORDER_DETAIL, error));
  }
}

function* removeSaleOrder({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleOrderApi, {uuid: data.data.uuid});
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.REMOVE_ORDER, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.REMOVE_ORDER, error));
    yield call(showSuccessNotification, error);
  }
}
function* updateSaleOrder({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateSaleOrderApi, data);
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.UPDATE_ORDER, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.UPDATE_ORDER, error));
    yield call(showSuccessNotification, error);
  }
}

function* cancelSaleOrder({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(cancelSaleOrderApi, data);
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.CANCEL_ORDER, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.CANCEL_ORDER, error));
    yield call(showSuccessNotification, error);
  }
}

function* changeSaleOrderStatus({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(changeSaleOrderStatusApi, data);
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.CHANGE_ORDER_STATUS, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.CHANGE_ORDER_STATUS, error));
    yield call(showSuccessNotification, error);
  }
}

function* changeSaleOrderItems({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(changeSaleOrderItemsApi, data);
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.CHANGE_ORDER_ITEMS, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.CHANGE_ORDER_ITEMS, error));
    yield call(showSuccessNotification, error);
  }
}

function* searchSaleOrderProducts({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductsApi, data);
    yield put(saleOrderApiResponseSuccess(SaleOrderActionTypes.SEARCH_SALE_PRODUCTS, response));
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.SEARCH_SALE_PRODUCTS, error));
  }
}


function* getOrderComments({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getCommentsApi, {saleOrder:data});
    yield put(
        saleOrderApiResponseSuccess(SaleOrderActionTypes.GET_COMMENTS, response)
    );
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.GET_COMMENTS, error));
  }
}
function* onSendDataOrderComment({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateCommentApi, data);
    yield put(
        saleOrderApiResponseSuccess(SaleOrderActionTypes.UPDATE_COMMENT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.UPDATE_COMMENT, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveOrderComment({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeCommentApi, {uuid: data.data.uuid});
    yield put(
        saleOrderApiResponseSuccess(SaleOrderActionTypes.REMOVE_COMMENT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.REMOVE_COMMENT, error));
    yield call(showErrorNotification, error);
  }
}

function* removeOrderGroup({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeGroupSaleOrderApi, data);
    yield put(
        saleOrderApiResponseSuccess(SaleOrderActionTypes.REMOVE_GROUP_ORDERS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderApiResponseError(SaleOrderActionTypes.REMOVE_GROUP_ORDERS, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleOrders() {
  yield takeEvery(SaleOrderActionTypes.GET_ORDERS, getSaleOrders);
}
export function* watchGetSaleOrderDetail() {
  yield takeEvery(SaleOrderActionTypes.GET_ORDER_DETAIL, getSaleOrderDetail);
}
export function* watchRemoveSaleOrder() {
  yield takeEvery(SaleOrderActionTypes.REMOVE_ORDER, removeSaleOrder);
}

export function* watchUpdateSaleOrder() {
  yield takeEvery(SaleOrderActionTypes.UPDATE_ORDER, updateSaleOrder);
}

export function* watchOnCancelSaleOrder() {
  yield takeEvery(SaleOrderActionTypes.CANCEL_ORDER, cancelSaleOrder);
}

export function* watchOnChangeSaleOrderStatus() {
  yield takeEvery(SaleOrderActionTypes.CHANGE_ORDER_STATUS, changeSaleOrderStatus);
}

export function* watchOnSearchSaleOrderProducts() {
  yield takeEvery(SaleOrderActionTypes.SEARCH_SALE_PRODUCTS, searchSaleOrderProducts);
}

export function* watchOnChangeSaleOrderItems() {
  yield takeEvery(SaleOrderActionTypes.CHANGE_ORDER_ITEMS, changeSaleOrderItems);
}


export function* watchOnSendDataOrderComment() {
  yield takeEvery(SaleOrderActionTypes.UPDATE_COMMENT, onSendDataOrderComment);
}
export function* watchOnGetOrderComments() {
  yield takeEvery(SaleOrderActionTypes.GET_COMMENTS, getOrderComments);
}

export function* watchOnRemoveOrderComment() {
  yield takeEvery(SaleOrderActionTypes.REMOVE_COMMENT, onRemoveOrderComment);
}

export function* watchOnRemoveOrderGroup() {
  yield takeEvery(SaleOrderActionTypes.REMOVE_GROUP_ORDERS, removeOrderGroup);
}

function* saleOrderSaga() {
  yield all([
      fork(watchGetSaleOrders),
      fork(watchGetSaleOrderDetail),
      fork(watchRemoveSaleOrder),
      fork(watchUpdateSaleOrder),
      fork(watchOnCancelSaleOrder),
      fork(watchOnChangeSaleOrderStatus),
      fork(watchOnSearchSaleOrderProducts),
      fork(watchOnChangeSaleOrderItems),
      fork(watchOnSendDataOrderComment),
      fork(watchOnGetOrderComments),
      fork(watchOnRemoveOrderComment),
      fork(watchOnRemoveOrderGroup)
  ]);
}

export default saleOrderSaga;
