import React from 'react';
import * as Yup from 'yup';

export const SaleProductGroupIntegrationValidatorSchema = Yup.lazy((values) => {
    const shapes = {};

    Object.keys(values).map((item)=>{
        if (item.match(/(\S+)_enabled$/)) {

            if (values[item]===true){
                const uuid = item.match(/(\S+)_enabled$/).pop();

                shapes['priceType_'+uuid]=Yup.object().required();
                shapes['currency_'+uuid]=Yup.object().required();
//                shapes['store_'+uuid]=Yup.object().required();

                if (item.match(/productIntegrationParam_(\S+)/)) {
                    shapes[item]=Yup.object().required();
                }
            }
        }

        return item;
    })

    return Yup.object().shape(shapes);
});

