
export enum MainRoleActionTypes {
  API_RESPONSE_SUCCESS = "@@mainRole/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainRole/API_RESPONSE_ERROR",
  GET_MAIN_ROLES = "@@mainRole/GET_MAIN_ROLES",
  
}

export interface MainRoleState {
  items: Array<any>;
}

export interface MainRoleType{
  code: string;
  color?: string;
}

export interface MainRolePayload {
  data: Array<any>;
}
