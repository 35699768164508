import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader
} from 'reactstrap';

import '../../i18/config';
import {useTranslation} from "react-i18next";
import {StatTileType} from "../../redux/Stat/DashboardSale/types";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {capitalizeFirstLetter, getHexBackgroundColorByClass, getHexColorByClass, rgb2hex} from "../../helpers/functions";
import {TaskStatus} from "../../redux/Task/Task/types";

interface ItemProps {
    items: [] | Array<StatTileType>;
    title: string
}

const TaskStatusChart = ({ items, title }: ItemProps) => {

    const { t } = useTranslation();
    const [chartLabels, setChartLabels] = useState<any[]>([]);
    const [chartColors, setChartColors] = useState<any[]>([]);
    const [series, setSeries] = useState<any[]>([]);

    useEffect(()=>{

        const _chartLabels=[];
        const _chartSeries=[];
        const _chartColors=[];

        (items || []).map((item:StatTileType)=>{

                _chartLabels.push(t('stat:task'+capitalizeFirstLetter(item.label)));
                _chartSeries.push(item.value.last || 0);

                let color = null;

                switch (item.label) {
                    case 'inProgressCount':
                        color = TaskStatus.INPROGRESS.colorHEX;
                        break;
                    case 'returnedCount':
                        color = TaskStatus.RETURNED.colorHEX;
                        break;
                    case 'pendingCount':
                        color = TaskStatus.PENDING.colorHEX;
                        break;
                    case 'overdueCount':
                        color = TaskStatus.OVERDUE.colorHEX;
                        break;
                    case 'closedCount':
                        color = TaskStatus.FINAL.colorHEX;
                        break;
                }
                
                if (color !== null) {
                    _chartColors.push(color);
                }

            return item;
        });

        setChartLabels([..._chartLabels]);
        setSeries([..._chartSeries]);
        setChartColors([..._chartColors]);

    },[t,items])

    const options:ApexOptions =
        {
            labels:chartLabels,
            chart: {
                type: "donut",
            },
            plotOptions: {
                pie: {
                    offsetX: 0,
                    offsetY: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: false,
                        }
                    },
                },
            },
            dataLabels: {
                enabled: true,
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center'
            },
            stroke: {
                lineCap: "round",
                width: 0
            },
            colors: chartColors,
        };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{title}</h4>
            </CardHeader>
            <CardBody>

                <div id="prjects-status" className="apex-charts" dir="ltr">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        height="300"
                        className="apex-charts"
                    />
                </div>


            </CardBody>
        </Card>
    );
}

export default TaskStatusChart;