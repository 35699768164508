import {SupportTicketMessageActionTypes, SupportTicketMessagePayload} from "./types";
import {RewardType} from "../../../data/reward";

export const supportTicketMessageApiResponseSuccess = (actionType: string, data: any) => ({
  type: SupportTicketMessageActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const supportTicketMessageApiResponseError = (actionType: string, error: string) => ({
  type: SupportTicketMessageActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSupportTicketMessages= (data: any) => ({
  type: SupportTicketMessageActionTypes.GET_SUPPORT_TICKET_MESSAGES,
  payload: data,
});

export const getSupportServiceTicketMessages= (data: any) => ({
  type: SupportTicketMessageActionTypes.GET_SUPPORT_SERVICE_TICKET_MESSAGES,
  payload: data,
});

export const showEditSupportTicketMessage= () => ({
  type: SupportTicketMessageActionTypes.EDIT_SUPPORT_TICKET_MESSAGE_MODAL_SHOW,
});

export const hideEditSupportTicketMessage= () => ({
  type: SupportTicketMessageActionTypes.EDIT_SUPPORT_TICKET_MESSAGE_MODAL_HIDE,
});

export const onSendDataSupportTicketMessage = (data: any) => ({
  type: SupportTicketMessageActionTypes.UPDATE_SUPPORT_TICKET_MESSAGE,
  payload: data,
});

export const onSendDataSupportServiceTicketMessage = (data: any) => ({
  type: SupportTicketMessageActionTypes.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE,
  payload: data,
});

export const removeSupportTicketMessageModalShow = (data: SupportTicketMessagePayload) => ({
  type: SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_SHOW,
  payload: data,
});

export const removeSupportTicketMessageModalHide = () => ({
  type: SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_HIDE,
});

export const onRemoveSupportTicketMessage = (data: SupportTicketMessagePayload) => ({
  type: SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE,
  payload: data,
});

export const onRemoveSupportServiceTicketMessage = (data: SupportTicketMessagePayload) => ({
  type: SupportTicketMessageActionTypes.REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE,
  payload: data,
});

export const onSendSupportTicketMessageReward= (data: RewardType) => ({
  type: SupportTicketMessageActionTypes.SEND_REWARD_SUPPORT_TICKET_MESSAGE,
  payload: data,
});

export const clearSupportTicketMessageStateItems = () => ({
  type: SupportTicketMessageActionTypes.CLEAR_STATE_ITEMS,
});