import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleIntegrationActionTypes } from "./types";
import { saleIntegrationApiResponseSuccess, saleIntegrationApiResponseError } from "./actions";

import {
  getSaleIntegrations as getSaleIntegrationsApi,
  removeSaleIntegration as removeSaleIntegrationApi,
  sendDataSaleIntegration as sendDataSaleIntegrationApi,
  getSaleProductIntegrationParam as getSaleProductIntegrationParamsApi,
  requestSaleIntegrationImport as requestSaleIntegrationImportApi,
  invalidateCacheSaleIntegration as invalidateCacheSaleIntegrationApi
} from "../../../api";

import {showErrorNotification, showSuccessNotification} from "../../../helpers/notifications";

function* getSaleIntegrations({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleIntegrationsApi, data);
    yield put(saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.GET_SALE_INTEGRATIONS, response));
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.GET_SALE_INTEGRATIONS, error));
  }
}

function* onSendDataSaleIntegration({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleIntegrationApi, data);
    yield put(
        saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.UPDATE_SALE_INTEGRATION, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.UPDATE_SALE_INTEGRATION, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleIntegration({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleIntegrationApi, {uuid: data.data.uuid});
    yield put(
        saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION, error));
    yield call(showErrorNotification, error);
  }
}

function* getSaleProductsIntegrationParamFromRemote({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductIntegrationParamsApi, data);
    yield put(
        saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE, error));
    yield call(showErrorNotification, error);
  }
}

function* getSaleProductsIntegrationMetaBusinessManagersFromRemote({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductIntegrationParamsApi, data);
    yield put(
        saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE, error));
    yield call(showErrorNotification, error);
  }
}

function* getSaleProductsIntegrationMetaProductCatalogsFromRemote({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductIntegrationParamsApi, data);
    yield put(
        saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRequestSaleIntegrationImport({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(requestSaleIntegrationImportApi, data);
    yield put(
        saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.REQUEST_SALE_INTEGRATION_IMPORT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.REQUEST_SALE_INTEGRATION_IMPORT, error));
    yield call(showErrorNotification, error);
  }
}

function* onInvalidateCacheSaleIntegration({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(invalidateCacheSaleIntegrationApi, {uuid: data.data.uuid});
    yield put(
        saleIntegrationApiResponseSuccess(SaleIntegrationActionTypes.INVALIDATE_CACHE_SALE_INTEGRATION, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleIntegrationApiResponseError(SaleIntegrationActionTypes.INVALIDATE_CACHE_SALE_INTEGRATION, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleIntegrations() {
  yield takeEvery(SaleIntegrationActionTypes.GET_SALE_INTEGRATIONS, getSaleIntegrations);
}
export function* watchOnSendDataSaleIntegration() {
  yield takeEvery(SaleIntegrationActionTypes.UPDATE_SALE_INTEGRATION, onSendDataSaleIntegration);
}
export function* watchOnRemoveSaleIntegration() {
  yield takeEvery(SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION, onRemoveSaleIntegration);
}

export function* watchGetSaleProductsIntegrationParamFromRemote() {
  yield takeEvery(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE, getSaleProductsIntegrationParamFromRemote);
}

export function* watchGetSaleProductsIntegrationMetaBusinessManagersFromRemote() {
  yield takeEvery(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE, getSaleProductsIntegrationMetaBusinessManagersFromRemote);
}

export function* watchGetSaleProductsIntegrationMetaProductCatalogsFromRemote() {
  yield takeEvery(SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE, getSaleProductsIntegrationMetaProductCatalogsFromRemote);
}

export function* watchOnRequestSaleIntegrationImport() {
  yield takeEvery(SaleIntegrationActionTypes.REQUEST_SALE_INTEGRATION_IMPORT, onRequestSaleIntegrationImport);
}

export function* watchOnInvalidateCacheSaleIntegration() {
  yield takeEvery(SaleIntegrationActionTypes.INVALIDATE_CACHE_SALE_INTEGRATION, onInvalidateCacheSaleIntegration);
}

function* saleIntegrationSaga() {
  yield all([
      fork(watchGetSaleIntegrations),
      fork(watchOnSendDataSaleIntegration),
      fork(watchOnRemoveSaleIntegration),
      fork(watchGetSaleProductsIntegrationParamFromRemote),
      fork(watchOnRequestSaleIntegrationImport),
      fork(watchOnInvalidateCacheSaleIntegration),
      fork(watchGetSaleProductsIntegrationMetaBusinessManagersFromRemote),
      fork(watchGetSaleProductsIntegrationMetaProductCatalogsFromRemote)
  ]);
}

export default saleIntegrationSaga;
