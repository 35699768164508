import {ContentConnectorHandlerParamType, ContentConnectorHandlerType} from "../ConnectorHandler/types";
import {ContentPeerChannelType} from "../PeerChannel/types";
import {EntityActionsType} from "../../types";
import {RequestMetadataType} from "../../Main/RequestMetadata/types";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";

export enum ContentConnectorActionTypes {
  API_RESPONSE_SUCCESS = "@@contentConnector/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentConnector/API_RESPONSE_ERROR",
  GET_CONTENT_CONNECTORS = "@@contentConnector/GET_CONTENT_CONNECTORS",
  UPDATE_CONTENT_CONNECTOR = "@@contentConnector/UPDATE_CONTENT_CONNECTORS",
  ADD_CONTENT_CONNECTOR = "@@contentConnector/ADD_CONTENT_CONNECTOR",
  REMOVE_CONTENT_CONNECTOR_MODAL_SHOW = "@@contentConnector/REMOVE_CONTENT_CONNECTOR_MODAL_SHOW",
  REMOVE_CONTENT_CONNECTOR_MODAL_HIDE = "@@contentConnector/REMOVE_CONTENT_CONNECTOR_MODAL_HIDE",
  REMOVE_CONTENT_CONNECTOR = "@@contentConnector/REMOVE_CONTENT_CONNECTOR",

  REMOVE_OAUTH_LINK = "@@contentConnector/REMOVE_OAUTH_LINK",
  EDIT_CONTENT_CONNECTOR = "@@contentConnector/EDIT_CONTENT_CONNECTOR",

  CONNECT_CONTENT_CONNECTOR = "@@contentConnector/CONNECT_CONTENT_CONNECTOR",
  IS_CONNECTED_CONTENT_CONNECTOR = "@@contentConnector/IS_CONNECTED_CONTENT_CONNECTOR",
  IS_CONNECTED_ERROR_CONTENT_CONNECTOR = "@@contentConnector/IS_CONNECTED_ERROR_CONTENT_CONNECTOR",
  SELECT_CONTENT_CONNECTOR = "@@contentConnector/SELECT_CONTENT_CONNECTOR",

  GET_CONTENT_CONNECTOR_REMOTE_CHANNELS = "@@contentConnector/GET_CONTENT_CONNECTOR_REMOTE_CHANNELS",
  GET_CONTENT_CONNECTOR_MANAGED_CHANNELS = "@@contentConnector/GET_CONTENT_CONNECTOR_MANAGED_CHANNELS",
  GET_CONTENT_CONNECTOR_MANAGED_COUNTERS = "@@contentConnector/GET_CONTENT_CONNECTOR_MANAGED_COUNTERS",
  CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL = "@@contentConnector/CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL",
  CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER = "@@contentConnector/CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER",
  SET_NEED_ADD_CODE_CONTENT_CONNECTOR = "@@contentConnector/SET_NEED_ADD_CODE_CONTENT_CONNECTOR",

  RIGHT_MODAL_SHOW = "@@contentConnector/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@contentConnector/RIGHT_MODAL_HIDE",
}

export interface ContentConnectorState {
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: ContentConnectorType;
  modalEditTitle: string;
  isNeedReload: boolean;
  isNeedVerificationCode: boolean;
  isNeedAddCode: boolean;
  isConnecting: boolean;
  isSending: boolean;
  searchChannels: Array<any>
  isSearchChannels: boolean;
  requestMetadata? : RequestMetadataType;
  managedChannels: Array<ContentPeerChannelType>
  isContentConnectorFetched: boolean;
  getContentConnectorLoading: boolean;
  isContentConnectorChannelsFetched: boolean;
}

export interface ContentConnectorType{
  id?: number,
  uuid?: string,
  enabled?: boolean,
  name?: string,
  isConnected?: boolean,
  handler?: ContentConnectorHandlerType
  description?: string,
  isUnread?: boolean,
  order?: number,
  channels?: Array<ContentPeerChannelType>,
  actions?: EntityActionsType,
  params?: Array<ContentConnectorParam>,
  connectionParams?: ContentConnectorConnectionParams,
  connectionCheckAttempts?: number,
  connectionSuccess?: boolean,
  verificationCode?: string
  OAuthUrl?: string;
}

export interface ContentConnectorPayload {
  data: Array<any>;
  modalEditTitle? : string;
}

export interface ContentConnectorConnectionParams {
  OAuthUrl?: string,
}

export interface ContentConnectorParam {
    param: ContentConnectorHandlerParamType,
    value: string
}

export enum ContentConnectorSocialCalendar{
  SOCIAL = 'social',
  WEB = 'web',
  VIDEO = 'video',
}

export enum ContentConnectorSocial{
    SOCIAL = 'social',
    MESSENGER = 'messenger',
    WEB = 'web',
    VIDEO = 'video',
}

export enum ContentConnectorMessenger{
    MESSENGER = 'messenger',
}

export enum ContentConnectorWeb{
    WEB = 'web',
}

export enum ContentConnectorVideo{
    VIDEO = 'video',
}

export enum ContentConnectorStat{
    STAT = 'stat',
}

export enum ContentConnectorAI{
  AI = 'ai',
}

export enum ContentConnectorIdea{
  IDEA = connectorHandlerCode.CONNECTOR_HANDLER_IDEAS,
}

export interface ContentConnectorSearchChannelPayload {
  connector: ContentConnectorType,
  inputValue: string
}

export enum ContentConnectorTelegramType{
  USER = 'user',
  BOT = 'bot',
}
