import React from "react";
import {SaleStoreType} from "../../../redux/Sale/Store/types";

interface ItemProps {
    item: SaleStoreType;
}
const StoreItemLine = ({ item }: ItemProps) => {
    return(
        <>
            <div className="d-flex flex-shrink-0">
                <div className="flex-1 justify-content-center flex-column d-flex text-truncate">
                    <span className="text-info"><i className="ri-store-3-line align-bottom"></i></span>
                    {item.name}
                </div>
            </div>
        </>
    )
}

export default StoreItemLine;