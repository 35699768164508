import {MainMetaType} from "../../Main/common";

export enum CommentsActionTypes {
  API_RESPONSE_SUCCESS = "@@comments/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@comments/API_RESPONSE_ERROR",

  GET_FAVOURITES = "@@comments/GET_FAVOURITES",
  GET_DIRECT_MESSAGES = "@@comments/GET_DIRECT_MESSAGES",
  GET_COMMENTS_CHANNELS = "@@comments/GET_COMMENTS_CHANNELS",

  ADD_CONTACTS = "@@comments/ADD_CONTACTS",
  CREATE_CHANNEL = "@@comments/CREATE_CHANNEL",
  CHANGE_SELECTED_CHAT = "@@comments/CHANGE_SELECTED_CHAT",
  GET_CHAT_USER_DETAILS = "@@comments/GET_CHAT_USER_DETAILS",
  GET_CHAT_USER_CONVERSATIONS = "@@comments/GET_CHAT_USER_CONVERSATIONS",
  TOGGLE_USER_DETAILS_TAB = "@@comments/TOGGLE_USER_DETAILS_TAB",

  // MESSAGE
  ON_SEND_MESSAGE = "@@comments/ON_SEND_MESSAGE",
  RECEIVE_MESSAGE = "@@comments/RECEIVE_MESSAGE",
  READ_MESSAGE = "@@comments/READ_MESSAGE",
  RECEIVE_MESSAGE_FROM_USER = "@@comments/RECEIVE_MESSAGE_FROM_USER",
  DELETE_MESSAGE = "@@comments/DELETE_MESSAGE",
  FORWARD_MESSAGE = "@@comments/FORWARD_MESSAGE",
  DELETE_USER_MESSAGES = "@@comments/DELETE_USER_MESSAGES",
  GET_COMMENTS_CHANNEL_DETAILS = "@@comments/GET_COMMENTS_CHANNEL_DETAILS",
  TOGGLE_FAVOURITE_CONTACT = "@@comments/TOGGLE_FAVOURITE_CONTACT",
  GET_ARCHIVE_CONTACT = "@@comments/GET_ARCHIVE_CONTACT",
  TOGGLE_ARCHIVE_CONTACT = "@@comments/TOGGLE_ARCHIVE_CONTACT",
  READ_CONVERSATION = "@@comments/READ_CONVERSATION",
  DELETE_IMAGE = "@@comments/DELETE_IMAGE",
}
export interface CommentsState {
//  favourites: Array<any>;
//  directMessages: Array<any>;
  channels: Array<any>;
  channelsMeta?: MainMetaType;
  archiveContacts: Array<any>;
  archiveContactsMeta?: MainMetaType;
  selectedChat: string | number | null;
  chatUserDetails: object;
  chatUserConversations: {};
  isOpenUserDetails: boolean;
  channelDetails: object;
 // archiveContacts: Array<any>;
  isMessageSending : boolean;
}
