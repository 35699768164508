import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { AuthLoginActionTypes } from "./types";
import {
  authLoginApiResponseSuccess,
  authLoginApiResponseError,
} from "./actions";

import {
    getJWTToken,
    logoutUser,
    postJwtLogin,
    socialCheck,
    getDeviceUsers as getDeviceUsersApi,
    loginUserByDevice as loginUserByDeviceApi
} from "../../../api";
import {all, fork} from "typed-redux-saga";
import {checkJwtToken, getLoggedinUser} from "../../../api/apiCore";

function* onLoginUser({ payload: { user } }: any) {
  try {

      const response: Promise<any> = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });

      yield put(
        authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER, response)
      );
  } catch (error: any) {
    yield put(
      authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER, error)
    );
  }
}

function* onCheckLoginUser() {
    try {
        const currentUser = getLoggedinUser();

        if (currentUser===false){
            throw Error(JSON.stringify({message:'notLoggedIn'}));
        }

        const response: Promise<any> = yield call(checkJwtToken);

        yield put(
            authLoginApiResponseSuccess(AuthLoginActionTypes.CHECK_LOGIN_USER, response)
        );
    } catch (error: any) {
        yield put(
            authLoginApiResponseError(AuthLoginActionTypes.CHECK_LOGIN_USER, error)
        );
    }
}

function* onLogoutUser() {
    try {

        const response: Promise<any> = yield call(logoutUser);
        yield put(
            authLoginApiResponseSuccess(AuthLoginActionTypes.LOGOUT_USER, response)
        );

    } catch (error: any) {
        yield put(
            authLoginApiResponseError(AuthLoginActionTypes.LOGOUT_USER, error)
        );
    }
}

function* onGetJWTToken() {
    try {
        const response: Promise<any> = yield call(getJWTToken);
        yield put(
            authLoginApiResponseSuccess(AuthLoginActionTypes.GET_JWT_TOKEN, response)
        );
    } catch (error: any) {
        yield put(
            authLoginApiResponseError(AuthLoginActionTypes.GET_JWT_TOKEN, error)
        );
    }
}

function* onSocialLogin(data:any) {
    try {
        const response: Promise<any> = yield call(socialCheck,data.payload);
        yield put(
            authLoginApiResponseSuccess(AuthLoginActionTypes.SOCIAL_LOGIN, response)
        );
    } catch (error: any) {
        yield put(
            authLoginApiResponseError(AuthLoginActionTypes.SOCIAL_LOGIN, error)
        );
    }
}

function* onLoginByDevice(data:any) {
    try {
        const response: Promise<any> = yield call(loginUserByDeviceApi,data.payload);
        yield put(
            authLoginApiResponseSuccess(AuthLoginActionTypes.LOGIN_USER_BY_DEVICE, response)
        );
    } catch (error: any) {
        yield put(
            authLoginApiResponseError(AuthLoginActionTypes.LOGIN_USER_BY_DEVICE, error)
        );
    }
}


function* onGetDeviceUsers() {
    try {
        const response: Promise<any> = yield call(getDeviceUsersApi);
        yield put(
            authLoginApiResponseSuccess(AuthLoginActionTypes.GET_DEVICE_USERS, response)
        );
    } catch (error: any) {
        yield put(
            authLoginApiResponseError(AuthLoginActionTypes.GET_DEVICE_USERS, error)
        );
    }
}

function* watchOnLoginUser() {
  yield takeEvery(AuthLoginActionTypes.LOGIN_USER, onLoginUser);
}

function* watchOnLogoutUser() {
    yield takeEvery(AuthLoginActionTypes.LOGOUT_USER, onLogoutUser);
}

function* watchOnCheckLoginUser() {
    yield takeEvery(AuthLoginActionTypes.CHECK_LOGIN_USER, onCheckLoginUser);
}
function* watchOnGetJWTToken() {
    yield takeEvery(AuthLoginActionTypes.GET_JWT_TOKEN, onGetJWTToken);
}
function* watchOnSocialLogin() {
    yield takeEvery(AuthLoginActionTypes.SOCIAL_LOGIN, onSocialLogin);
}
function* watchOnGetDeviceUsers() {
    yield takeEvery(AuthLoginActionTypes.GET_DEVICE_USERS, onGetDeviceUsers);
}
function* watchOnLoginByDevice() {
    yield takeEvery(AuthLoginActionTypes.LOGIN_USER_BY_DEVICE, onLoginByDevice);
}
function* loginSaga() {
    yield all([
        fork(watchOnLoginUser),
        fork(watchOnLogoutUser),
        fork(watchOnCheckLoginUser),
        fork(watchOnGetJWTToken),
        fork(watchOnSocialLogin),
        fork(watchOnGetDeviceUsers),
        fork(watchOnLoginByDevice)
    ]);
}

export default loginSaga;
