import {
    editDataMainUser,
    onRemoveMainUser,
    removeMainUserModalHide,
    removeMainUserModalShow,
    showRightModalMainUser,
} from "../../../redux/Main/User/actions";
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";

const mainUserOpenModal = (event:any, dispatch:any) => {
    if ((event !== undefined) && (event?.uuid?.length>0)){
        dispatch(editDataMainUser({data:event, modalEditTitle: 'changeUser'}));
    } else {
        dispatch(showRightModalMainUser({data:[], modalEditTitle: 'newUser'}));
    }
}

const mainUserOpenRemoveModal = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event?.uuid?.length>0)){
        dispatch(removeMainUserModalShow({data:event, modalEditTitle: 'deleteUser'}));
    }

}

const mainUserOnRemove = (event:any, dispatch:any, currentCabinet:MainCabinetType) => {

    if ((event !== undefined) && (event?.uuid?.length>0)){
        dispatch(onRemoveMainUser({data:event, cabinet: currentCabinet, modalEditTitle: 'deleteUser'}));
    }

}

const mainUserHideRemoveModal = (dispatch:any) => {
    dispatch(removeMainUserModalHide())
}

export {
    mainUserOpenModal,
    mainUserOpenRemoveModal,
    mainUserOnRemove,
    mainUserHideRemoveModal
}