import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {
    Label, Nav, NavItem, NavLink, Row, TabContent, TabPane,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";

import {Formik, Form, Field } from 'formik';

import {ContentConnectorValidatorSchema} from "./validator";
import {
    ContentConnectorAI,
    ContentConnectorParam,
    ContentConnectorStat, ContentConnectorTelegramType,
    ContentConnectorType
} from "../../../redux/Content/Connector/types";
import {
    getContentConnectors,
    hideRightModalContentConnector,
    onConnectContentConnector,
    onConnectContentConnectorManagedChannel, onConnectContentConnectorManagedCounter,
    onGetContentConnectorManagedChannels, onGetContentConnectorManagedCounters,
    onSendDataContentConnector,
} from "../../../redux/Content/Connector/actions";

import ListButtonItem from "../ConnectorHandler/ListButtonItem";
import {
    ContentConnectorHandlerActionTypes, ContentConnectorHandlerParamType,
    ContentConnectorHandlerType
} from "../../../redux/Content/ConnectorHandler/types";
import {
    getContentConnectorHandlers,
    selectContentConnectorHandler
} from "../../../redux/Content/ConnectorHandler/actions";
import classnames from "classnames";
import {showErrorNotification, showSuccessCopyNotification} from "../../../helpers/notifications";
import UpgradeFare from "../../../components/UpgradeFare";
import ModalTwoStep from "../../../components/ModalTwoStep";
import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import ModalAddValueStep from "../../../components/ModalAddValueStep";
import UpgradeFareModal from "../../../components/UpgradeFareModal";
import {showUpgradeModal} from "../../../redux/Main/UpgradeFare/actions";
import ConnectModal from "./ConnectModal";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import ChannelItemLineChecker from "../PeerChannel/channelItemLineChecker";
import Loader from "../../../components/Loader";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {capitalizeFirstLowercaseRest} from "../../../helpers/functions";
import InputPhone from "../../../components/InputPhone";
import InputPassword from "../../../components/InputPassword";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {config} from "../../../config";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import InputRadio from "../../../components/InputRadio";
import NoticeTip from "../../../components/NoticeTip";

const RightModal = () => {

    const { dispatch, useAppSelector } = useRedux();
    const [disableConnect, setDisableConnect] = useState<boolean>(false);
    const [isManagedChannelNoticeShow, setManagedChannelNoticeShow] = useState<boolean>(false);

    const {
        contentConnectors,
        contentConnectorHandlers,
        isSending
    } = useAppSelector(state => ({
        contentConnectors: state.ContentConnector,
        contentConnectorHandlers: state.ContentConnectorHandler,
        isSending: state.ContentConnector.isSending
    }));

    const [connectorHandler, setConnectorHandler] = useState<ContentConnectorHandlerType>(null);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([]);
    const [showCodeVerification, setShowCodeVerification] = useState<boolean>(false);
    const [showAddValueModal, setShowAddValueModal] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<ContentConnectorType>(contentConnectors.selectedItem);
    const [openLink, setOpenLink] = useState<string>('');
    const [managedChannels, setManagedChannels] = useState<ContentPeerChannelType[]>(contentConnectors.managedChannels);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChannelsFetched, setIsChannelsFetched] = useState<boolean>(false);

    const { t } = useTranslation(['common']);

    const hiddenInnerSubmitFormRef = useRef(null);

    const id = contentConnectors.selectedItem.uuid || null;

    useEffect(()=>{
        if (contentConnectors.selectedItem !== undefined
            &&
            contentConnectors.selectedItem.OAuthUrl !== undefined
            &&
            (contentConnectors.selectedItem.OAuthUrl.length > 0)
            &&
            contentConnectors.selectedItem.isConnected === false
        ){
            setOpenLink(contentConnectors.selectedItem.OAuthUrl);
        } else {
            setOpenLink('');
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    },[contentConnectors?.selectedItem?.OAuthUrl]);


    useEffect(() => {

        if (
            (contentConnectors.selectedItem.uuid !== undefined)
        ){
            setConnectorHandler(contentConnectors.selectedItem.handler);
            setDisableConnect(false);
            setActiveTab(2);

            if (contentConnectors.selectedItem.isConnected){
                if (contentConnectors.selectedItem.handler.type === ContentConnectorStat.STAT){
                    dispatch(onGetContentConnectorManagedCounters(contentConnectors.selectedItem));
                } else if (
                    contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS
                    ||
                    contentConnectors.selectedItem?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_CHAT_GPT
                    ||
                    contentConnectors.selectedItem?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_GOOGLE_BARD
                ){

                } else {
                    dispatch(onGetContentConnectorManagedChannels(contentConnectors.selectedItem));
                }
            }

        } else {
            setConnectorHandler(null);
            setDisableConnect(true);
            setActiveTab(1);
        }

        setFormValues(contentConnectors.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch, contentConnectors?.selectedItem?.isConnected]);

    useEffect(() => {

        let _formValues = contentConnectors.selectedItem;

        if (contentConnectors.selectedItem?.actions?.canRemove && contentConnectors.selectedItem.actions.canRemove){
            if (contentConnectors.selectedItem?.handler && contentConnectors.selectedItem?.handler?.params === undefined){
                const selectedHandler = contentConnectorHandlers.items.filter((item:ContentConnectorHandlerType)=> {return item.code === contentConnectors.selectedItem.handler.code}).shift();

                if (selectedHandler && selectedHandler.params){
                    contentConnectors.selectedItem.handler.params = selectedHandler.params;
                }
            }
        }

        if (contentConnectors?.selectedItem?.params && ((contentConnectors.selectedItem.params || []).length>0)){

            (contentConnectors.selectedItem.params || []).map((param: ContentConnectorParam)=>{
                _formValues[param.param.code] = param.value;
                return param;
            })
        }

        setFormValues(_formValues);

    }, [contentConnectors?.selectedItem, contentConnectorHandlers?.items]);

    useEffect(() => {

        setShowCodeVerification(contentConnectors.isNeedVerificationCode);

    }, [contentConnectors.isNeedVerificationCode]);

    useEffect(() => {

        setShowAddValueModal(contentConnectors.isNeedAddCode);

    }, [contentConnectors?.isNeedAddCode]);

    useEffect(() => {

        setIsLoading(contentConnectors.isSearchChannels);

    }, [contentConnectors?.isSearchChannels]);

    useEffect(() => {
        setManagedChannels([...contentConnectors.managedChannels]);

    }, [contentConnectors?.managedChannels]);

    useEffect(() => {

        setIsChannelsFetched(contentConnectors.isContentConnectorChannelsFetched);

    }, [contentConnectors?.isContentConnectorChannelsFetched]);

    const handleClose = () => {

        setConnectorHandler(null);
        setDisableConnect(true);
        setActiveTab(1);

        dispatch(hideRightModalContentConnector());
    }


    const handleVerificationCodeModal = () => {
        setShowCodeVerification(!showCodeVerification);
    }
    const handleAddValueCodeModal = () => {
        setShowAddValueModal(!showAddValueModal);
    }

    const onPeerChannelChange = (item:ContentPeerChannelType) => {

        let params: any;

        if (item.uuid !== undefined && item.uuid !== null){
            params = {
                uuid: item.uuid && item.uuid,
                enabled: !item.enabled,
                type: item.type && item.type,
                connector: {
                    uuid: contentConnectors.selectedItem.uuid
                }
            };
        } else {
            params = {
                peer: {
                    id: item.idPeer,
                    name: item.name,
                    isManaged: item.isManaged
                },
                type: item.type && item.type,
                connector: {
                    uuid: contentConnectors.selectedItem.uuid
                }
            };
        }

        if (contentConnectors.selectedItem.handler.type === ContentConnectorStat.STAT){
            dispatch(onConnectContentConnectorManagedCounter(params));
        } else {
            dispatch(onConnectContentConnectorManagedChannel(params));
        }
    }

    const onSelectConnectorHandler = (item:any) : void  => {
        dispatch(selectContentConnectorHandler(ContentConnectorHandlerActionTypes.SELECT_CONTENT_CONNECTOR_HANDLER, {data : item}));
        setConnectorHandler(item);
        contentConnectors.selectedItem.handler=item;
        contentConnectors.selectedItem.name = t('connectorHandler:'+item.code);

        setFormValues({...contentConnectors.selectedItem});

        setActiveTab(2);
    }

    const isCanSave = ((id != null) || (id == null && ((connectorHandler !== undefined && connectorHandler !== null && connectorHandler.isFareRestriction === undefined) || (connectorHandler !== undefined && connectorHandler !== null && connectorHandler.isFareRestriction!== true))));

    const handleExternalButtonClick = () => {
        hiddenInnerSubmitFormRef.current.click();
    }

    const handleVerificationCodeSubmit = (code:string) => {

        contentConnectors.selectedItem.verificationCode=code;

        handleExternalButtonClick();

        if (showCodeVerification) {setShowCodeVerification(!showCodeVerification);}
        if (showAddValueModal) {setShowAddValueModal(!showAddValueModal);}

    }

    function toggleTab(tab) {
        if (activeTab !== tab) {

            if ((tab===1) && (id !== undefined && id !== null)){
                showErrorNotification({message: t('connector:changeOnNewServiceOnly')});
                return
            }

            if ((tab===2) && (connectorHandler == null)) {
                showErrorNotification({message: t('connector:chooseService')});
                return
            }

            let modifiedSteps = [...passedSteps, tab];

            setActiveTab(tab);
            setPassedSteps(modifiedSteps);

        }
    }

    const onDataSubmit = (formData:any) => {

        let data: any = {};

        if (formData.name) {
            data["name"] = formData.name;
        }
        if (connectorHandler !== undefined) {
            data["handler"] = connectorHandler;
        }
        if (formData.enabled) {
            data["enabled"] = formData.enabled;
        }
        if (id) {
            data["uuid"] = id;
        }
        if (formData._action){
            data["_action"] = formData._action;
        }

        if (
            contentConnectors.selectedItem.handler !== undefined
            &&
            contentConnectors.selectedItem.handler.params !== undefined){

            contentConnectors.selectedItem.handler.params.map((param: ContentConnectorHandlerParamType, paramKey:number) => {
                if (formData[param.code]){
                    if (data['params'] === undefined) {data['params']=[];}
                    data['params'].push({param:param, value: formData[param.code]});

                }
                return param;
            })
        }

        let params: ContentConnectorType = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            name: data.name && data.name,
            verificationCode: contentConnectors.selectedItem.verificationCode && contentConnectors.selectedItem.verificationCode
        };

        if (data.handler){
            params.handler=data.handler
        }
        if (data.params){
            params.params = data.params;
        }

        if ((contentConnectors.selectedItem.verificationCode!==undefined) && (contentConnectors.selectedItem.verificationCode.length>0)){

            dispatch(onConnectContentConnector(contentConnectors.selectedItem));

        } else {
            dispatch(onSendDataContentConnector(params));

            setTimeout(() => {
                dispatch(getContentConnectorHandlers());
                dispatch(getContentConnectors());
                setDisableConnect(false);
            }, 1000);
        }

    };

    const handleConnect = (event:any) => {
        if ((contentConnectors.selectedItem.handler !== undefined) && (contentConnectors.selectedItem.handler.isFareRestriction===true)){
            dispatch(showUpgradeModal());
        } else {
            dispatch(onConnectContentConnector(contentConnectors.selectedItem));

            // if (contentConnectors.selectedItem.handler.code==='vk'){
            //     dispatch(setContentConnectorModalAddCodeShow());
            // }
        }
    }

    const handleOnChange = () => {
        setDisableConnect(true);
    }

    const handleCloseConnectModal = ()=> {
        setOpenLink('');
    }

    const handleManagedChannelsNoticeToggle = () => {
        setManagedChannelNoticeShow(!isManagedChannelNoticeShow);
    }

    return (
        <>
            { openLink.length > 0 &&
                <ConnectModal item={contentConnectors.selectedItem} handleClose={handleCloseConnectModal} isOpen={true} modalTitle={'Connect to '+contentConnectors.selectedItem.name}/>
            }
            <Offcanvas
                direction="end"
                isOpen={contentConnectors.rightModalShow}
                id="connectorEdit"
                toggle={handleClose}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ContentConnectorValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {
                        return(
                    <Form noValidate={true} className='customValidation'>
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('connector:'+contentConnectors.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">


<Row className="vertical-navs-step">
    <Nav
        className="flex-column custom-nav nav-pills mb-3"
    >
        <NavItem>
            <NavLink
                href="#"
                className={
                    (classnames({
                        active: activeTab === 1,
                        done: (activeTab > 1)
                    }))
                }
                onClick={() => {
                    toggleTab(1);
                }}
            >
                <div className="d-flex flex-row flex-nowrap flex-grow-1">
                              <span className="step-title me-2 text-muted d-flex align-items-center flex-shrink-1">
                                <i className="ri-close-circle-fill step-icon me-2"></i>
                                  {t('common:step')} 1
                              </span>

                    <span className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end">
                {
                    (connectorHandler != null && connectorHandler.uuid.length > 0)
                        ?
                            <>
                            <div className="avatar-xxs position-relative">
                                <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                    <img src={connectorHandler.icon} className="img-fluid p-1 rounded-circle" alt=""/>
                                </div>
                            </div>
                            <span className="step-title d-flex align-items-center">{t('connectorHandler:'+connectorHandler.code)}</span>
                            </>
                        :
                        t('connector:chooseProvider')
                }
                 </span>
                </div>
            </NavLink>
        </NavItem>
    </Nav>

    <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
            <Row>
                {id == null &&
                    (contentConnectorHandlers.items || []).map((item: ContentConnectorHandlerType, key: number) => (
                        <ListButtonItem
                            item={item}
                            key={'contentConnectorHandler_' + item.uuid}
                            onClick={onSelectConnectorHandler}
                            isSelected={connectorHandler != null && connectorHandler.uuid === item.uuid}
                        />
                    ))
                }
            </Row>
        </TabPane>
    </TabContent>



    <Nav
        className="flex-column custom-nav nav-pills mb-3"
    >
        <NavItem>
            <NavLink
                href="#"
                className={
                    (classnames({
                        active: activeTab === 2,
                        done: (activeTab > 2 )
                    }))
                }
                onClick={() => {
                    toggleTab(2);
                }}
            >
                              <span className="step-title me-2">
                                <i className="ri-close-circle-fill step-icon me-2"></i>
                                  {t('common:step')} 2
                              </span>
                {t('connector:connection')}
            </NavLink>
        </NavItem>
    </Nav>

    <TabContent activeTab={activeTab}>
        <TabPane tabId={2}>

            {
                connectorHandler && connectorHandler.isFareRestriction === true && id == null
                    ?
                    <UpgradeFare/>
                    :
                    <>
                        <Row>
                            <div className="mb-3 d-flex flex-column me-5">
                                <Field
                                    id="form-enabled-input"
                                    name="enabled"
                                    placeholder={t('common:active')}
                                    component={InputSwitch}
                                    value = {getFieldProps('enabled').value}
                                    onChange={(enabled:boolean) => {
                                        handleOnChange();
                                        setFieldValue('enabled', !getFieldProps('enabled').value);
                                    }}
                                    className="ms-1 form-switch-lg"
                                    required={false}
                                />
                            </div>

                        </Row>

                        <Row>
                            <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                            <Label className="form-label" htmlFor="form-title-input">{t('common:name')}</Label>
                            <Field
                            id="form-title-input"
                            name="name"
                            placeholder={t('connector:enterConnectorName')}
                            component={InputText}
                            value={getFieldProps('name').value}
                            onChange={(name: string) => {
                                if (getFieldProps('name').value!==name){
                                    handleOnChange();
                                    setFieldValue('name', name);
                                }
                        }}
                            className={errors.name && touched.name && 'form-control is-invalid'}
                            />
                        {errors.name && touched.name ? (
                            <div className="invalid-feedback">
                                {t('validator:'+errors.name)}
                            </div>
                            ) : touched.name && !errors.name ?
                            <div className="valid-feedback">
                                {t('common:looksGood')}
                            </div>
                            : ''
                        }
                            </div>
                        </Row>

                        {contentConnectors.selectedItem.handler
                            && contentConnectors.selectedItem.handler.params
                            && !contentConnectors.selectedItem.isConnected
                            &&
                        contentConnectors.selectedItem.handler.params.map((param: ContentConnectorHandlerParamType, paramKey:number) => {
                            if (getFieldProps(param.code).value === undefined){
                                if (contentConnectors.selectedItem.params !== undefined){
                                    contentConnectors.selectedItem.params.map((connectorParam:ContentConnectorParam, connectorParamKey:number)=>{
                                       if (
                                           (connectorParam.param.code === param.code)
                                           &&
                                           (connectorParam.value !== undefined)
                                       ){
                                           setFieldValue(param.code, connectorParam.value);
                                       }
                                       return connectorParam;
                                    });
                                }
                            }

                            if (contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM){
                                if (getFieldProps('TYPE').value === ContentConnectorTelegramType.BOT){
                                    if (param.code === 'PHONE' || param.code === 'PASSWORD'){
                                        // eslint-disable-next-line
                                        return;
                                    }
                                } else {
                                    if (param.code === 'ACCESS_TOKEN'){
                                        // eslint-disable-next-line
                                        return;
                                    }
                                }
                                if (param.code === 'TYPE') {
                                    if (getFieldProps('TYPE').value !== ContentConnectorTelegramType.BOT) {
                                        if (getFieldProps('TYPE').value !== ContentConnectorTelegramType.USER) {
                                            setFieldValue(param.code, ContentConnectorTelegramType.USER);
                                        }
                                    }
// eslint-disable-next-line
                                    return (
                                        <Row key={paramKey}>
                                            <div className={ "mb-3"+ (touched[param.code] ? " was-validated" : '')}>
                                                <Row className="g-3 mb-0 align-items-center">
                                                    <div className="col-sm-auto">
                                                <Field
                                                    id={"form-"+param.code+"-input-"+ContentConnectorTelegramType.USER}
                                                    name={param.code}
                                                    placeholder={t('connector:connectorTelegramType'+capitalizeFirstLowercaseRest(ContentConnectorTelegramType.USER))}
                                                    component={InputRadio}
                                                    value={ContentConnectorTelegramType.USER}
                                                    isChecked={getFieldProps(param.code).value === ContentConnectorTelegramType.USER}
                                                    onChange={(value: string) => {
                                                        if (getFieldProps(param.code).value!==ContentConnectorTelegramType.USER) {
                                                            handleOnChange();
                                                            setFieldValue(param.code, ContentConnectorTelegramType.USER);
                                                        }
                                                    }}
                                                    className={errors[param.code] && touched[param.code] && 'form-control is-invalid'}
                                                />
                                                    </div>
                                                {/*    <div className="col-sm-auto">*/}
                                                {/*<Field*/}
                                                {/*    id={"form-"+param.code+"-input-"+ContentConnectorTelegramType.BOT}*/}
                                                {/*    name={param.code}*/}
                                                {/*    placeholder={t('connector:connectorTelegramType'+capitalizeFirstLowercaseRest(ContentConnectorTelegramType.BOT))}*/}
                                                {/*    component={InputRadio}*/}
                                                {/*    value={ContentConnectorTelegramType.BOT}*/}
                                                {/*    isChecked={getFieldProps(param.code).value === ContentConnectorTelegramType.BOT}*/}
                                                {/*    onChange={(value: string) => {*/}
                                                {/*        if (getFieldProps(param.code).value!==ContentConnectorTelegramType.BOT) {*/}
                                                {/*            handleOnChange();*/}
                                                {/*            setFieldValue(param.code, ContentConnectorTelegramType.BOT);*/}
                                                {/*        }*/}
                                                {/*    }}*/}
                                                {/*    className={errors[param.code] && touched[param.code] && 'form-control is-invalid'}*/}
                                                {/*/>*/}
                                                {/*    </div>*/}
                                                </Row>
                                            </div>
                                        </Row>
                                    );
                                }
                            }

                            return (
                            <Row key={paramKey}>
                                <div className={ "mb-3"+ (touched[param.code] ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor={"form-"+param.code+"-input"}>
                                    {contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_GOOGLE_BARD && param.code === 'CLIENT_ID' ?
                                        t('connectorHandlerParam:PROJECT_ID')
                                        :
                                        contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_CHAT_GPT && param.code === 'CLIENT_ID' ?
                                            t('connectorHandlerParam:ORGANIZATION_ID')
                                            :
                                        t('connectorHandlerParam:'+param.code)
                                    }
                                    </Label>
                                        {param.code === 'PHONE'
                                            ?
                                            <Field
                                                id="form-phone-input"
                                                name={param.code}
                                                placeholder={t('user:enterPhone')}
                                                component={InputPhone}
                                                value={getFieldProps(param.code).value}
                                                onChange={(value: string) => {
                                                    handleOnChange();
                                                    setFieldValue(param.code, value);
                                                }}
                                                inputClassName={errors[param.code] && touched[param.code] ? 'form-control is-invalid' : "form-control"}
                                            />
                                            :
                                            param.code === 'PASSWORD' ?
                                                <Field
                                                    id="form-password-input"
                                                    name="password"
                                                    placeholder={t('user:enterPassword')}
                                                    component={InputPassword}
                                                    value = {getFieldProps(param.code).value}
                                                    onChange={(value:string) => {
                                                        handleOnChange();
                                                        setFieldValue(param.code, value);
                                                    }}
                                                    className={errors[param.code] && touched[param.code] && 'form-control is-invalid'}
                                                />
                                                :
                                            <Field
                                        id={"form-"+param.code+"-input"}
                                        name={param.code}
                                        placeholder={contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_GOOGLE_BARD && param.code === 'CLIENT_ID' ?
                                            t('connectorHandlerParam:PROJECT_ID')
                                            :
                                            contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_CHAT_GPT && param.code === 'CLIENT_ID' ?
                                                t('connectorHandlerParam:ORGANIZATION_ID')
                                                :
                                                t('connectorHandlerParam:'+param.code)}
                                        component={InputText}
                                        value={getFieldProps(param.code).value}
                                        onChange={(value: string) => {
                                            if (getFieldProps(param.code).value!==value) {
                                                handleOnChange();
                                                setFieldValue(param.code, value);
                                            }
                                        }}
                                        className={errors[param.code] && touched[param.code] && 'form-control is-invalid'}
                                    />
                                        }
                                    {errors[param.code] && touched[param.code] ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors[param.code])}
                                        </div>
                                    ) : touched[param.code] && !errors[param.code] ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            )
                        })
                        }

                        {contentConnectors?.selectedItem?.handler && contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_INSTAGRAM && contentConnectors?.selectedItem?.isConnected !== true &&
                            <NoticeTip type={'success'} icon={'ri-instagram-line'} firstText={t('connector:connectorInstagramDescription1')} secondText={t('connector:connectorInstagramDescription2')}/>
                        }
                        {contentConnectors?.selectedItem?.handler && contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT && contentConnectors?.selectedItem?.isConnected !== true &&
                            <NoticeTip type={'success'} icon={'ri-telegram-line'} firstText={t('connector:connectorTelegramBotDescription1')} secondText={t('connector:connectorTelegramBotDescription2')}/>
                        }
                        {contentConnectors?.selectedItem?.handler && contentConnectors.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM && contentConnectors?.selectedItem?.isConnected !== true &&
                            <NoticeTip type={'success'} icon={'ri-telegram-line'} firstText={t('connector:connectorTelegramDescription1')} secondText={t('connector:connectorTelegramDescription2')}/>
                        }
                        {contentConnectors?.selectedItem?.isConnected && contentConnectors.selectedItem.isConnected === true &&
                            <>
                                { (contentConnectors.selectedItem.handler.type !== ContentConnectorAI.AI) &&
                        <Row>
                            <div className="mb-3">
                                <p className="mb-2">{t('channel:managedChannels')} <a href="#" onClick={handleManagedChannelsNoticeToggle}><i className="ps-1 text-muted align-bottom ri-information-line"/></a></p>

                                {isManagedChannelNoticeShow &&
                                <div className=" alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                    {t('connector:managedChannelsActivationNotice')}
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={handleManagedChannelsNoticeToggle}></button>
                                </div>
                                }

                                { isLoading ?
                                    <div className="m-1 mt-0 d-flex justify-content-center">
                                        <Loader addClassNames="d-inline-block"/>
                                    </div>
                                        :
                                        isChannelsFetched &&
                                        <>
                                        {(managedChannels || []).length>0 ?
                                        (managedChannels || []).map((item:ContentPeerChannelType, key: number)=>(
                                            <div className="mb-3 rounded-2 border-primary border p-2 border-opacity-25" key={'managedChannels_'+key}>
                                                <ChannelItemLineChecker item={item} onChange={onPeerChannelChange} />
                                            </div>
                                        ))
                                        :
                                        <div className="m-0 alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-1" role="alert">
                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                            {t('connector:managedChannelsNotPresent')}
                                        </div>
                                        }
                                        </>
                                }
                            </div>
                        </Row>
                                }
                                {contentConnectors?.selectedItem.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_OZON &&
                                    <div className="m-0 alert alert-primary alert-border-left alert-dismissible fade show d-flex flex-column flex-grow-1 pe-1" role="alert">
                                        <div className="d-flex flex-row pb-2">
                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                        </div>
                                        <div className="d-flex flex-row">
                                            {t('connector:connectorOzonNotice')}
                                        </div>
                                        <div className="d-flex flex-row mw-0">
                                            <div className="d-flex flex-row align-items-center mw-0">
                            <span className="text-truncate">
                        {config.ID_API_URL+'/callback/connectors/'+contentConnectors.selectedItem.uuid}
                            </span>
                                            </div>
                                            <div className="btn">
                                                <CopyToClipboard text={config.ID_API_URL+'/callback/connectors/'+contentConnectors.selectedItem.uuid}>
                                                    <button type="button" className="btn btn-outline-primary btn-icon waves-effect waves-light" onClick={showSuccessCopyNotification}><i
                                                        className="ri-file-copy-2-line"/></button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                }
                                </>
                        }
                    </>
            }

        </TabPane>
    </TabContent>
</Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                                <>
                                    {isCanSave &&
                                    <Button variant="primary"
                                            type="submit"
                                            ref={hiddenInnerSubmitFormRef}
                                            disabled={isSending}
                                            value="save"
                                            className={"btn btn-primary chat-send waves-effect waves-light w-100"+((disableConnect || contentConnectors.selectedItem.isConnected === true)?'':' d-none')}
                                    >{t('common:save')}
                                        {isSending &&
                                            <ButtonSpinner/>
                                        }
                                    </Button>
                                    }
                                    { id != null && contentConnectors.selectedItem.isConnected === false && showCodeVerification!==true && !disableConnect &&
                                    <Button variant="primary"
                                            type="button"
                                            disabled={contentConnectors.isConnecting}
                                            value="connect"
                                            className="btn btn-info chat-send waves-effect waves-light w-100"
                                            onClick={handleConnect}
                                    >
                                        {t('connector:connect')}
                                        {contentConnectors.isConnecting &&
                                            <ButtonSpinner/>
                                        }
                                    </Button>
                                    }
                                </>
                            </div>
                        </div>
                    </Form>
                        )}}
                </Formik>

            </Offcanvas>

            <ModalTwoStep
                    isShow={showCodeVerification}
                    handleShow={handleVerificationCodeModal}
                    onChange={handleVerificationCodeSubmit}/>

            <ModalAddValueStep
                isShow={showAddValueModal}
                handleShow={handleAddValueCodeModal}
                onChange={handleVerificationCodeSubmit}/>

            <UpgradeFareModal/>
        </>
    );
}

export default RightModal;