import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {
    Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    NavItem,
    NavLink,
    Nav, TabContent, TabPane
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    getSaleProductsIntegrationMetaBusinessManagersFromRemote, getSaleProductsIntegrationMetaProductCatalogsFromRemote,
    getSaleProductsIntegrationParamFromRemote,
    hideRightModalSaleIntegration,
    onSendDataSaleIntegration, requestSaleIntegrationImport
} from "../../../redux/Sale/Integration/actions";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';
import {m9sGlobals} from "../../../config";
import {
    Formik,
    Form,
    Field, useFormikContext
} from 'formik';
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {SaleIntegrationValidatorSchema} from "./validator";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {SaleStoreType} from "../../../redux/Sale/Store/types";
import {PriceTypeType} from "../../../redux/Sale/PriceType/types";
import {MetaAllowEditFields} from "../../../redux/Main/Meta/types";
import InputSelect from "../../../components/InputSelect";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {newMetaItem} from "../../../redux/Main/Meta/reducer";
import {PaymentSystemType} from "../../../redux/Sale/PaymentSystem/types";
import {DeliverySystemType} from "../../../redux/Sale/DeliverySystem/types";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {getCurrentCabinet, isNumeric} from "../../../helpers/functions";
import {ContentProjectType} from "../../../redux/Content/Project/types";
import UpgradeFareBlock from "../../../components/UpgradeFareBlock";
import NoticeTip from "../../../components/NoticeTip";
import lz from 'lz-string';
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import ChannelItemBigLine from "../../Content/PeerChannel/channelItemBigLine";
import {
    SaleIntegrationHandlerActionTypes, SaleIntegrationHandlerCodes,
    SaleIntegrationHandlerType, SaleIntegrationHandlerTypes
} from "../../../redux/Sale/IntegrationHandler/types";
import {selectSaleIntegrationHandler} from "../../../redux/Sale/IntegrationHandler/actions";
import classnames from "classnames";
import {showErrorNotification, showSuccessCopyNotification} from "../../../helpers/notifications";
import ListButtonAddItem from "../../../components/ListButtonItem";
import {LanguageOptionType, widgetLanguages} from "../../../constants/Languages";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Loader from "../../../components/Loader";
import {MetaBusinessManagerType, MetaProductCatalogType} from "../../../redux/Sale/Meta/types";
import InputFile from "../../../components/InputFile";
import {MainFileType} from "../../../redux/Main/File/types";
import UrlShortener, {NewShortenerItem} from "../../../redux/Shortener/UrlShortener/reducer";
import {UrlShortenerType} from "../../../redux/Shortener/UrlShortener/types";
import {checkShortener} from "../../../redux/Shortener/UrlShortener/actions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import PeerChannel from "../../Content/PeerChannel";

const UrlShortenerEdit = () =>{
    const { dispatch, useAppSelector } = useRedux();
    const {setFieldValue, getFieldProps, touched, errors} = useFormikContext();

    const { t } = useTranslation(['common']);

    const {
        urlShortener,
        isSendingShortener
    } = useAppSelector(state => ({
        urlShortener: state.UrlShortener,
        isSendingShortener: state.UrlShortener.isSending
    }));

    const handleUrlShortenerCheck = (urlShortener: UrlShortenerType) => {
        if (urlShortener?.url && urlShortener.url.length>4){
            dispatch(checkShortener(urlShortener));
        } else {
            showErrorNotification(t('validator:min5symbols'));
        }

    }

    const handleUrlShortenerRemove = () => {
        setFieldValue('urlShortener', NewShortenerItem);
    }

    useEffect(()=>{
        if (urlShortener?.selectedItem?.url.length>4) {
            setFieldValue('urlShortener', urlShortener?.selectedItem);
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[urlShortener?.selectedItem])

    return (
        <Row>
            <div className={ "mb-3"+ (touched['urlShortener'] ? " was-validated" : '')}>
                <Label className="form-label" htmlFor="form-url-shortener-input">
                {getFieldProps('urlShortener').value && getFieldProps('urlShortener').value?.isApproved ? t('shortener:formInputTitleReady') : t('shortener:formInputTitle')}
                </Label>
                <div className="d-flex flex-row">
                    <div className="d-flex flex-column flex-grow-1 justify-content-center">
                        {getFieldProps('urlShortener').value && getFieldProps('urlShortener').value?.isApproved ?
                            <div className="d-flex flex-column flex-shrink-0 me-1 justify-content-center text-muted">
                                https://m9s.shop/{getFieldProps('urlShortener').value.url}
                            </div>
                            :
                        <div className="d-flex flex-row flex-grow-1 flex-nowrap">
                            <div className="d-flex flex-column flex-shrink-0 me-1 justify-content-start text-muted pt10px" >
                                https://m9s.shop/
                            </div>
                            <div className="d-flex flex-column flex-grow-1">
                                <Field
                                    id="form-url-shortener-input"
                                    name="url-shortener"
                                    placeholder={t('shortener:formInputExample')}
                                    component={InputText}
                                    disabled = {getFieldProps('urlShortener').value && getFieldProps('urlShortener').value?.isApproved}
                                    value = {getFieldProps('urlShortener').value && getFieldProps('urlShortener').value.url}
                                    onChange={(value:string) => {
                                        setFieldValue('urlShortener', {
                                            ...getFieldProps('urlShortener').value,
                                            url: value
                                        });
                                    }}
                                    className={errors['urlShortener'] && touched['urlShortener'] && 'form-control is-invalid'}
                                    pattern={"[a-z0-9]"}
                                />
                                {errors['urlShortener'] && touched['urlShortener'] ? (
                                    <div className="invalid-feedback">
                                        {errors['urlShortener']['url'] !== undefined && errors['urlShortener']['url'] !==null ?
                                            t('validator:'+errors['urlShortener']['url'])
                                            :
                                            t('validator:'+errors['urlShortener'])
                                        }
                                    </div>
                                ) :  touched['urlShortener'] && !errors['urlShortener'] ?
                                    <div className="valid-feedback">
                                        {t('common:looksGood')}
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>
                        }
                    </div>
                    <div className="d-flex flex-column flex-shrink-0">
                        {getFieldProps('urlShortener').value && getFieldProps('urlShortener').value?.isApproved
                            ?
                            <>
                                <div className="d-flex flex-row flex-shrink-0">
                                <CopyToClipboard text={'https://m9s.shop/'+getFieldProps('urlShortener').value.url}>
                                    <button type="button" className="btn btn-outline-primary btn-icon waves-effect waves-light" onClick={showSuccessCopyNotification}><i
                                        className="ri-file-copy-2-line"/></button>
                                </CopyToClipboard>
                                <button
                                    onClick={()=>{handleUrlShortenerRemove()}}
                                    title={t('common:delete')}
                                    className="btn btn-outline-danger btn-icon waves-effect waves-light ms-2">
                                    <i className="ri-delete-bin-5-line"/>
                                </button>
                                </div>
                            </>
                            :
                            <button
                                onClick={()=>{
                                    handleUrlShortenerCheck(getFieldProps('urlShortener').value)}
                                }
                                type="button"
                                disabled={isSendingShortener}
                                className="ms-1 btn btn-outline-primary btn-icon waves-effect waves-light text-align-center">
                                {isSendingShortener
                                    ?
                                    <Spinner size='sm'/>
                                    :
                                    <i className="ri-check-line"/>
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>
        </Row>
    )
}

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [disabledImport, setDisabledImport] = useState<boolean>(false);

    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);

    const [integration, setIntegration] = useState<SaleIntegrationType>(null);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
    const [stores, setStores] = useState<SaleStoreType[]>([]);
    const [priceTypes, setPriceTypes] = useState<PriceTypeType[]>([]);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [paymentSystems, setPaymentSystems] = useState<PaymentSystemType[]>([]);
    const [deliverySystems, setDeliverySystems] = useState<DeliverySystemType[]>([]);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [currentChannel, setCurrentChannel] = useState<ContentPeerChannelType>(null);
    const [integrationHandler, setIntegrationHandler] = useState<SaleIntegrationHandlerType>(null);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([]);
    const [integrationDirection, setIntegrationDirection] = useState<string>(null);
    const [languageOptions, setLanguageOptions] = useState<any>([]);
    const [availableWidgetLanguages, setAvailableWidgetLanguages] = useState<LanguageOptionType[]>([]);
    const [metaBusinessManagers, setMetaBusinessManagers] = useState<any[]>([]);
    const [metaProductCatalogs, setMetaProductCatalogs] = useState<any[]>([]);
    const [importMedia, setImportMedia] = useState<MainFileType[]>([]);

    const currentCabinet = getCurrentCabinet();

    const {
        saleIntegrationState,
        saleIntegrationHandler,
        saleCurrency,
        saleStore,
        salePriceType,
        contentPeerChannels,
        isSending,
        salePaymentSystem,
        saleDeliverySystem,
        salePlan,
        isLoadingMetaBusinessManagers,
        isLoadingMetaProductCatalogs,
        isImportRequest
    } = useAppSelector(state => ({
        saleIntegrationState: state.SaleIntegration,
        saleIntegrationHandler: state.SaleIntegrationHandler,
        saleCurrency: state.SaleCurrency,
        saleStore: state.SaleStore,
        salePriceType: state.SalePriceType,
        contentPeerChannels: state.ContentPeerChannel,
        isSending: state.SaleIntegration.isSending,
        salePaymentSystem: state.SalePaymentSystem,
        saleDeliverySystem: state.SaleDeliverySystem,
        salePlan: state.SalePlan,
        isLoadingMetaBusinessManagers: state.SaleIntegration.isLoadingMetaBusinessManagers,
        isLoadingMetaProductCatalogs: state.SaleIntegration.isLoadingMetaProductCatalogs,
        isImportRequest: state.SaleIntegration.isImportRequest
    }));

    const [formValues, setFormValues] = useState<SaleIntegrationType>(saleIntegrationState.selectedItem);

    useEffect(() => {

        if (integration?.handler?.uuid) {

            const direction = Object.keys(SaleIntegrationHandlerTypes).filter((key:string)=>{
                return SaleIntegrationHandlerTypes[key] === integration.handler.type;
            }).shift();

            setIntegrationDirection(direction);
            setIntegrationHandler(integration.handler);
            setPassedSteps([1,2]);
            setActiveTab(3);

            if (integration?.handler?.code === SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER) {
                setChannels((contentPeerChannels.items || []).filter((item:ContentPeerChannelType)=>{return item?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK && item.isManaged && item.connector.handler.canECommerce}));
            }

        } else {
            if (integrationDirection!==null){
                setPassedSteps([1]);
                setActiveTab(2);
            } else {
                setPassedSteps([]);
                setActiveTab(1);
            }
        }

        setCurrentFormValues({...integration});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integration]);

    useEffect(() => {
        if (integration?.uuid && integration.uuid.length>0) {
            if (integration?.handler?.code === SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED && integration?.handler?.type === SaleIntegrationHandlerTypes.INBOUND){
                if ((importMedia || []).length>0){
                    setDisabledImport(false);
                } else {
                    setDisabledImport(true);
                }
            } else {
                setDisabledImport(false);
            }
        }

    },[integration, importMedia]);

    useEffect(() => {

        let _languageOptions=[];
        let _availableWidgetLanguages=[];

        Object.keys(widgetLanguages).map((item:string)=>{
            _languageOptions.push({uuid: widgetLanguages[item].code, value: widgetLanguages[item].label});
            _availableWidgetLanguages.push(widgetLanguages[item]);
            return item;
        })

        setLanguageOptions([..._languageOptions]);
        setAvailableWidgetLanguages([..._availableWidgetLanguages]);

    },[dispatch]);

    const onSelectIntegrationHandler = (item:any) : void  => {
        dispatch(selectSaleIntegrationHandler(SaleIntegrationHandlerActionTypes.SELECT_SALE_INTEGRATION_HANDLER, {data : item}));
        setIntegrationHandler(item);
        setActiveTab(3);
        setPassedSteps([...passedSteps, 2]);
        setIntegration({...integration, handler: item});
    }

    const onSelectIntegrationHandlerDirection = (item:any) : void  => {
        setIntegrationDirection(item);
        setActiveTab(2);
        setPassedSteps([...passedSteps, 1]);
    }

    const setCurrentFormValues = (item: SaleIntegrationType) => {

        let formValues = {...item};

        setCurrentChannel(item.channel);
        setImportMedia([]);

        if (!item?.currency || !item?.currency?.uuid ||  item.currency.uuid === null){
            item.currency = (currencies || []).filter((filterItem:CurrencyType)=>{return filterItem.isDefault}).shift();
        }
        if (!item?.priceType || !item?.priceType?.uuid ||  item.priceType.uuid === null){
            item.priceType = (priceTypes || []).filter((filterItem:PriceTypeType)=>{return filterItem.isBase}).shift();
        }
        // if (!item?.store || !item?.store?.uuid ||  item.store.uuid === null){
        //     item.store = (stores || []).filter((filterItem:SaleStoreType)=>{return filterItem.enabled}).shift();
        // }
        if (!item?.meta || !item?.meta?.uuid || item.meta === null || item.meta.uuid === null){
            item.meta = newMetaItem;
        }

        if (item?.meta && item.meta !== null){
            Object.keys(item.meta).map((metaKey)=>{
                if (Object.keys(MetaAllowEditFields).includes(metaKey)) {
                    formValues['meta_' + metaKey] = item.meta[metaKey];
                }
                return metaKey;
            })
        }

        if (item?.paymentSystems && item.paymentSystems !== null){
            (item.paymentSystems || []).map((item:PaymentSystemType)=>{
                formValues['paymentSystem_' + item.uuid]=item.enabled;
                return item;
            });
        }

        if (item?.deliverySystems && item.deliverySystems !== null){
            (item.deliverySystems || []).map((item:DeliverySystemType)=>{
                formValues['deliverySystem_' + item.uuid]=item.enabled;
                return item;
            });
        }

        if (item?.handler?.type===SaleIntegrationHandlerTypes.INBOUND){
            formValues['url']='';
            if (item?.param?.url && item.param.url.length>0){
                formValues['url']=item.param.url;
            }
        } else {
            formValues['isShowLanguages']=true
            if (item?.param?.isShowLanguages !== undefined && item?.param?.isShowLanguages !== null){
                formValues['isShowLanguages']=item?.param?.isShowLanguages;
            }

            formValues['isShowLanguageFlags']=true
            if (item?.param?.isShowLanguageFlags !== undefined && item?.param?.isShowLanguageFlags !== null){
                formValues['isShowLanguageFlags']=item?.param?.isShowLanguageFlags;
            }

            formValues['defaultLanguage']=true
            if (item?.param?.defaultLanguage){
                formValues['defaultLanguage']=item?.param?.defaultLanguage;
            }

            formValues['availableLanguages']=[];
            if (item?.param?.availableLanguages){
                formValues['availableLanguages']=item?.param?.availableLanguages;

                let _availableLanguages = item?.param?.availableLanguages.slice(0).reverse();

                let _availableWidgetLanguages = availableWidgetLanguages;

                _availableLanguages.map((availableLanguage:string)=>{

                    let currentWidgetLanguage:LanguageOptionType;
                    Object.keys(widgetLanguages).map((item:string)=>{
                        if (widgetLanguages[item].code === availableLanguage) {
                                currentWidgetLanguage = widgetLanguages[item];
                        }
                        return item;
                    })

                    _availableWidgetLanguages=[
                        currentWidgetLanguage,
                        ...(_availableWidgetLanguages || []).filter((availableWidgetLanguage:LanguageOptionType)=>{
                            return availableWidgetLanguage.code !== availableLanguage;
                        })
                    ]

                    return availableLanguage;
                });

                setAvailableWidgetLanguages(_availableWidgetLanguages);

            }

            if ((formValues['availableLanguages']).length<1){
                Object.keys(widgetLanguages).map((item:string)=>{
                    formValues['availableLanguages'].push(widgetLanguages[item].code);
                    return item;
                });
            }

            if (item?.param?.metaBusinessManager){
                formValues['metaBusinessManager']=item?.param?.metaBusinessManager;
            } else {
                formValues['metaBusinessManager']=null;
            }
            if (item?.param?.metaProductCatalog){
                formValues['metaProductCatalog']=item?.param?.metaProductCatalog;
            } else {
                formValues['metaProductCatalog']=null;
            }

            if (item?.channel?.uuid){
                formValues['channel']=item?.channel;
            } else {
                formValues['channel']=null;
            }

            if (currentCabinet !== undefined
                &&
                currentCabinet !==null
                &&
                currentCabinet !== false
                &&
                currentCabinet?.uuid
                &&
                integration?.uuid) {



                if (item?.param?.urlShortener?.isApproved){
                    formValues['urlShortener']=item.param.urlShortener;
                } else {
                    formValues['urlShortener'] = {
                        ...NewShortenerItem,
                        cabinet: currentCabinet.uuid,
                        objectOwner: integration.uuid,
                        redirectUrl: m9sGlobals.W7_WIDGET_URL.replace(/:widgetData/, lz.compressToBase64(JSON.stringify({
                            uuidCabinet: currentCabinet.uuid,
                            uuidIntegration: integration.uuid
                        })))
                    };
                }
            }
        }
        
        setFormValues(formValues);
    }

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            setCurrentPlan({...salePlan.currentPlan});
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        checkFareRestriction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        let restrictionValue;

        let restrictionValue1 = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_WIDGETS
            }
        )).pop();

        let restrictionValue2 = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_IMPORTS
            }
        )).pop();

        restrictionValue = restrictionValue1;

        if (restrictionValue === undefined){
            setUpgradeFare(true);
        } else if (saleIntegrationState) {
            if (isNumeric(restrictionValue.value)) {

                if (isNumeric(restrictionValue2.value)){
                    restrictionValue.value= restrictionValue1.value + restrictionValue2.value;
                }

                if (parseInt(restrictionValue.value) === (saleIntegrationState.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    //edit only
                    if (saleIntegrationState?.selectedItem?.uuid === undefined || saleIntegrationState?.selectedItem?.uuid === null) {
                        setUpgradeFare(true);
                    } else {
                        setUpgradeFare(false);
                    }
                } else if (parseInt(restrictionValue.value) < (saleIntegrationState.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    setUpgradeFare(true);
                } else {
                    setUpgradeFare(false);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }


    useEffect(() => {

        if (saleIntegrationState?.selectedItem) {

            if (!saleIntegrationState?.selectedItem?.meta || !saleIntegrationState?.selectedItem?.meta?.uuid || saleIntegrationState?.selectedItem.meta === null || saleIntegrationState?.selectedItem.meta.uuid === null){
                saleIntegrationState.selectedItem.meta = newMetaItem;
            }

            setDisabledImport(false);
            setIntegration({...saleIntegrationState.selectedItem});
        } else {
            setIntegration(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleIntegrationState?.selectedItem]);

    useEffect(() => {

        if (saleCurrency?.items && (saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }
    }, [saleCurrency?.items]);

    useEffect(() => {
        if (saleStore?.items && (saleStore?.items || []).length>0){
            setStores([...saleStore.items]);
        } else {
            setStores([]);
        }
    }, [saleStore?.items]);

    useEffect(() => {
        if (salePaymentSystem?.items && (salePaymentSystem?.items || []).length>0){
            setPaymentSystems([...salePaymentSystem.items]);
        } else {
            setPaymentSystems([]);
        }
    }, [salePaymentSystem.items]);

    useEffect(() => {
        if (saleDeliverySystem?.items && (saleDeliverySystem?.items || []).length>0){
            setDeliverySystems([...saleDeliverySystem.items]);
        } else {
            setDeliverySystems([]);
        }
    }, [saleDeliverySystem?.items]);

    useEffect(() => {
        if (salePriceType?.items && (salePriceType?.items || []).length>0){
            setPriceTypes([...salePriceType.items]);
        } else {
            setPriceTypes([]);
        }
    }, [salePriceType?.items]);

    useEffect(() => {
        if (contentPeerChannels?.items && (contentPeerChannels?.items || []).length>0){

            if (integration?.handler?.code === SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER) {
                setChannels((contentPeerChannels.items || []).filter((item:ContentPeerChannelType)=>{return item?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK && item.isManaged && item.connector.handler.canECommerce}));
            } else {
                setChannels(contentPeerChannels.items.filter((item: ContentPeerChannelType) => {
                    return item.isManaged && item.connector.handler.canECommerce
                }));
            }
        } else {
            setChannels([]);
        }
    }, [contentPeerChannels?.items, integration]);

    const handleClose = () => dispatch(hideRightModalSaleIntegration());

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    const onDataSubmit = (formData:any) => {

        let data: any = {};

        data = {
            enabled: formData.enabled && formData.enabled,
            name: formData.name,
            handler: integrationHandler,
        }

        if (integration.uuid) {
            data.uuid= integration.uuid;
        }

        if (integrationHandler.type === SaleIntegrationHandlerTypes.OUTBOUND) {
            data.channel = integration.channel;
            data.priceType = integration.priceType;
            data.store = integration.store;
            data.meta = integration.meta;
            data.paymentSystems = [];
            data.deliverySystems = [];
            data.buttonName = formData.buttonName;


            if (formData['currency'] !== undefined) {
                if (formData['currency']['value'] !== undefined) {
                    data.currency = {uuid: formData['currency']['value']};
                } else {
                    data.currency = formData['currency'];
                }
            }
            if (formData['store'] !== undefined) {
                if ((formData['store']!==null) && (formData['store']['value'] !== undefined)) {
                    data.store = {uuid: formData['store']['value']};
                } else {
                    data.store = formData['store'];
                }
            }

            if (
                integrationHandler.code !== SaleIntegrationHandlerCodes.WIDGET_WEB
                &&
                integrationHandler.code !== SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
            ) {

                if (data.uuid === undefined || data.uuid === null) {
                    if (formData['channel'] !== undefined) {
                        if (formData['channel']['value'] !== undefined) {
                            data.channel = {uuid: formData['channel']['value']};
                        } else {
                            data.channel = formData['channel'];
                        }
                    }
                }
            }

            if (integration?.meta && integration.meta !== null) {
                Object.keys(integration.meta).map((metaKey) => {
                    if (Object.keys(MetaAllowEditFields).includes(metaKey)) {
                        if (formData['meta_' + metaKey] && formData['meta_' + metaKey] !== null && formData['meta_' + metaKey].length > 0) {
                            data.meta[metaKey] = formData['meta_' + metaKey];
                        } else {
                            data.meta[metaKey] = null;
                        }
                    }
                    return metaKey;
                })
            }

            if (paymentSystems !== null) {
                (paymentSystems || []).map((item: PaymentSystemType) => {
                    if (formData['paymentSystem_' + item.uuid]) {
                        data.paymentSystems = [
                            ...data.paymentSystems,
                            item
                        ];
                    }
                    return item;
                });
            }

            if (deliverySystems !== null) {
                (deliverySystems || []).map((item: DeliverySystemType) => {
                    if (formData['deliverySystem_' + item.uuid]) {
                        data.deliverySystems = [
                            ...data.deliverySystems,
                            item
                        ];
                    }
                    return item;
                });
            }

            let defaultLanguage = null;
            let availableLanguages= [];

            if (formData['defaultLanguage'] !== undefined) {
                if (formData['defaultLanguage'] !== null && formData['defaultLanguage']['value'] !== undefined) {
                    defaultLanguage = formData['defaultLanguage']['value'];
                } else {
                    defaultLanguage = formData['defaultLanguage'];
                }
            }

            if (formData['availableLanguages'] && ((formData['availableLanguages'] || []).length>0)) {
                (availableWidgetLanguages || []).map((availableWidgetLanguage: LanguageOptionType) => {
                    if (formData['availableLanguages'].indexOf(availableWidgetLanguage.code) !== -1) {
                        availableLanguages.push(availableWidgetLanguage.code);
                    }
                    return availableWidgetLanguage;
                })
            }

            data = {
                ...data,
                param: {
                    isShowLanguages: formData['isShowLanguages'] && formData['isShowLanguages'],
                    isShowLanguageFlags: formData['isShowLanguageFlags'] && formData['isShowLanguageFlags'],
                    defaultLanguage: defaultLanguage && defaultLanguage,
                    availableLanguages: availableLanguages
                }
            };

            if (formData['metaBusinessManager'] !== undefined && formData['metaBusinessManager'] !== null){
                if (formData['metaBusinessManager']?.value && formData['metaBusinessManager']?.label){
                    data = {
                        ...data,
                        param: {
                            ...data.param,
                            metaBusinessManager:{
                                idPeer: formData['metaBusinessManager'].value,
                                name: formData['metaBusinessManager'].label
                            }
                        }
                    }
                } else {
                    data = {
                        ...data,
                        param: {
                            ...data.param,
                            metaBusinessManager:formData['metaBusinessManager']
                        }
                    }
                }
            }

            if (formData['metaProductCatalog'] !== undefined && formData['metaProductCatalog'] !== null) {
                if (formData['metaProductCatalog']?.value && formData['metaProductCatalog']?.label){
                    data = {
                        ...data,
                        param: {
                            ...data.param,
                            metaProductCatalog:{
                                idPeer: formData['metaProductCatalog'].value,
                                name: formData['metaProductCatalog'].label
                            }
                        }
                    }
                } else{
                    data = {
                        ...data,
                        param: {
                            ...data.param,
                            metaProductCatalog:formData['metaProductCatalog']
                        }
                    }
                }
            }

        } else {
            data = {
                ...data,
                param: {
                    url: formData['url']
                }
            };
        }

        if (formData['priceType'] !== undefined) {
            if (formData['priceType']['value'] !== undefined) {
                data.priceType = {uuid: formData['priceType']['value']};
            } else {
                data.priceType = formData['priceType'];
            }
        }

        if (formData['urlShortener'] !== undefined){
            if (formData['urlShortener']['url'] !== undefined
            &&
            formData['urlShortener']['isApproved']) {
                data = {
                    ...data,
                    param: {
                        ...data.param,
                        urlShortener: formData['urlShortener']
                    }
                };
            } else {
                data = {
                    ...data,
                    param: {
                        ...data.param,
                        urlShortener: {enabled: false}
                    }
                };
            }
        }

        dispatch(onSendDataSaleIntegration(data));
    };

    const handleRequestImport = () => {
        if (integration?.uuid && integration.uuid.length>0) {
            if (integration?.handler?.code === SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED && integration?.handler?.type === SaleIntegrationHandlerTypes.INBOUND){

                if ((importMedia || []).length>0){

                    integration.attachmentFiles=[];

                    (importMedia|| []).map((fileItem:MainFileType)=>{
                        if (fileItem.uuid === undefined){
                            integration.attachmentFiles.push(fileItem);
                        }
                        return fileItem;
                    });

                    dispatch(requestSaleIntegrationImport(integration));

                } else {
                    showErrorNotification(t('integration:fileNotFound'));
                }

            } else {
                dispatch(requestSaleIntegrationImport(integration));
            }
        }
    }

    const onChangeChannel = (channelValue:any) => {

        if (channelValue?.value){
            const channel = (channels || []).filter((item:ContentPeerChannelType)=>{return item.uuid === channelValue.value}).shift();

            if (channel?.uuid){
                setCurrentChannel(channel);
            }
        }
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {

            if ((tab===1) && (integration.uuid !== undefined && integration.uuid  !== null)){
                showErrorNotification({message: t('integration:changeTypeOnNewIntegrationOnly')});
                return
            }
            if ((tab===2) && (integration.uuid !== undefined && integration.uuid  !== null)) {
                showErrorNotification({message: t('integration:changeProviderOnNewIntegrationOnly')});
                return
            }

            if ((tab===2) || (tab===1)){
                setIntegration({...integration, handler:null});
                setIntegrationHandler(null);
            }
            if (tab===1){
                setIntegration({...integration, handler:null});
                setIntegrationDirection(null);
            }

            setActiveTab(tab);
            setPassedSteps([...(passedSteps || []).filter((item)=>{return item<tab})]);
        }
    }

    const dragEnded = (param) => {

        const { source, destination } = param;
        let _arr = [...availableWidgetLanguages];
        //extracting the source item from the list
        const _item = _arr.splice(source.index, 1)[0];
        //inserting it at the destination index.
        _arr.splice(destination.index, 0, _item);

        setAvailableWidgetLanguages(_arr);
    }

    useEffect(()=>{
        if (currentChannel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK
            &&
            integration?.handler?.code === SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER
            &&
            (!(integration?.uuid !== undefined && integration?.uuid !== null))
        )
        {
            if (currentChannel?.uuid){
                dispatch(getSaleProductsIntegrationMetaBusinessManagersFromRemote({
                    connector:currentChannel.connector
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentChannel]);

    useEffect(()=> {

        if (
            saleIntegrationState?.metaBusinessManagers && (saleIntegrationState.metaBusinessManagers || []).length>0
        ){
            let selectedParams = [];

            (saleIntegrationState.metaBusinessManagers || []).map((item:MetaBusinessManagerType)=>{
                if (item.idPeer && item.name){
                    selectedParams.push({id: item.idPeer, name: item.name});
                }
                return item;
            });

            setMetaBusinessManagers(selectedParams);
        } else {
            setMetaBusinessManagers([]);
        }

    },[saleIntegrationState?.metaBusinessManagers, integration]);

    useEffect(()=> {

        if (saleIntegrationState?.metaProductCatalogs && (saleIntegrationState.metaProductCatalogs || []).length>0){

            let selectedParams = [];

            (saleIntegrationState.metaProductCatalogs || []).map((item:MetaProductCatalogType)=>{
                if (item.idPeer && item.name){
                    selectedParams.push({id: item.idPeer, name: item.name});
                }
                return item;
            });

            setMetaProductCatalogs(selectedParams);
        } else {
            setMetaProductCatalogs([]);
        }

    },[saleIntegrationState?.metaProductCatalogs]);

    const handleRequestMetaProductCatalogs = (idPeer: string) => {

        if (currentChannel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK
            &&
            integration?.handler?.code === SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER
        )
        {
            if (currentChannel?.uuid){
                if (idPeer !== undefined && idPeer !== null && idPeer.length>0) {
                    dispatch(getSaleProductsIntegrationMetaProductCatalogsFromRemote({
                        connector: currentChannel.connector,
                        metaBusinessManager: {idPeer: idPeer}
                    }));
                }
            }
        }
    }

    const getActualPeerChannelsByIntegrationHandler = (integrationHandler : SaleIntegrationHandlerType) => {

        switch (integrationHandler.code) {
            case SaleIntegrationHandlerCodes.FACEBOOK_WIDGET_WEB:
            case SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER:
                return (channels || []).filter((channelItem:ContentPeerChannelType)=>{ return channelItem.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK});
            case SaleIntegrationHandlerCodes.WIDGET_TELEGRAM:
                return (channels || []).filter((channelItem:ContentPeerChannelType)=>{
                    return channelItem.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT
                });
            case SaleIntegrationHandlerCodes.VK_SIMPLE:
                return (channels || []).filter((channelItem:ContentPeerChannelType)=>{
                    return channelItem.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_VK
                });
        }

        return channels;
    }

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleIntegrationState.rightModalShow}
                    id="saleIntegrationEdit"
                    toggle={handleClose}
                >
                    { upgradeFare ?
                        <UpgradeFareBlock onClose={handleClose}/>
                        :
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleIntegrationValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {

                        return(
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('integration:'+saleIntegrationState.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                                    <Row className="vertical-navs-step">
                                        <Nav
                                            className="flex-column custom-nav nav-pills mb-3"
                                        >
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={
                                                        (classnames({
                                                            active: activeTab === 1,
                                                            done: (activeTab > 1)
                                                        }))
                                                    }
                                                    onClick={() => {
                                                        toggleTab(1);
                                                    }}
                                                >
                                                    <div className="d-flex flex-row flex-nowrap flex-grow-1">
                                                      <span className="step-title me-2 text-muted d-flex align-items-center flex-shrink-1">
                                                        <i className="ri-close-circle-fill step-icon me-2"/>
                                                          {t('common:step')} 1
                                                      </span>
                                                        <span className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end">
                                                            {integrationDirection !== null
                                                                ?
                                                                <>
                                                                    {t('integration:integrationType'+integrationDirection)}
                                                                </>
                                                                :
                                                                t('integration:selectIntegrationType')
                                                            }
                                                        </span>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={1}>
                                                <Row>
                                                    {(Object.keys(SaleIntegrationHandlerTypes) ||[]).map((item:string)=>{
                                                        return(
                                                            <ListButtonAddItem key={"saleIntegrationHandlerDirectionKey_"+item}
                                                                name={t('integration:integrationType'+item)}
                                                                iconClass={
                                                                    SaleIntegrationHandlerTypes[item]===SaleIntegrationHandlerTypes.OUTBOUND
                                                                    ?
                                                                        "ri-upload-cloud-2-line align-bottom"
                                                                    :
                                                                        "ri-download-cloud-2-line align-bottom"
                                                                }
                                                                onClick={()=>{onSelectIntegrationHandlerDirection(item)}}
                                                            />
                                                        )
                                                    })}
                                                </Row>
                                            </TabPane>
                                        </TabContent>

                                        <Nav
                                            className="flex-column custom-nav nav-pills mb-3"
                                        >
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={
                                                        (classnames({
                                                            active: activeTab === 2,
                                                            done: (activeTab > 2)
                                                        }))
                                                    }
                                                    onClick={() => {
                                                        toggleTab(2);
                                                    }}
                                                >
                                                    <div className="d-flex flex-row flex-nowrap flex-grow-1">
                                                      <span className="step-title me-2 text-muted d-flex align-items-center flex-shrink-1">
                                                        <i className="ri-close-circle-fill step-icon me-2"/>
                                                          {t('common:step')} 2
                                                      </span>
                                                        <span className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end">
                                                            {integrationHandler !== null
                                                                ?
                                                                <>
                                                                    {t('integration:provider'+integrationHandler.code)}
                                                                </>
                                                                :
                                                                t('integration:selectIntegrationProvider')
                                                            }
                                                        </span>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={2}>
                                                <Row>
                                                    {(saleIntegrationHandler.items ||[]).map((item:SaleIntegrationHandlerType)=>{
                                                        return(
                                                            item.type === SaleIntegrationHandlerTypes[integrationDirection] &&
                                                            <ListButtonAddItem key={"saleIntegrationHandlerKey_"+item.uuid}
                                                                               name={t('integration:provider'+item.code)}
                                                                               icon={item.icon}
                                                                               onClick={()=>{onSelectIntegrationHandler(item)}}
                                                            />
                                                        )
                                                    })}
                                                </Row>
                                            </TabPane>
                                        </TabContent>

                                    </Row>

                                    {passedSteps.indexOf(2) !== -1 &&
                                        <>

                                    <Row>
                                        <div className="d-flex">
                                            <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                <Field
                                                    id="form-enabled-input"
                                                    placeholder={t('common:active')}
                                                    name="enabled"
                                                    component={InputSwitch}
                                                    value = {getFieldProps('enabled').value}
                                                    onChange={(enabled:boolean) => {
                                                        setFieldValue('enabled', !getFieldProps('enabled').value);
                                                        setDisabledImport(true);
                                                    }}
                                                    required={false}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-name-input">{t('common:nameTitle')}</Label>
                                            <Field
                                                id="form-name-input"
                                                name="name"
                                                placeholder={t('common:enterNameTitle')}
                                                component={InputText}
                                                value = {getFieldProps('name').value}
                                                onChange={(name:string) => {
                                                    setFieldValue('name', name);
                                                }}
                                                className={errors.name && touched.name && 'form-control is-invalid'}
                                            />
                                            {errors.name && touched.name ? (
                                                <div className="invalid-feedback">
                                                    {t('validator:'+errors.name)}
                                                </div>
                                            ) :  touched.name && !errors.name ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </Row>
                                            <Row>
                                                <div className={ "mb-3"+ (touched.priceType ? " was-validated" : '')}>
                                                    <Label className="form-label" htmlFor={"choices-priceType-input"}>{t('price:priceType')}</Label>
                                                    <Field
                                                        id="choices-priceType-input"
                                                        name={"priceType"}
                                                        placeholder={t('price:choosePriceType')}
                                                        component={InputSelect}
                                                        value={getFieldProps('priceType').value}
                                                        onChange={(value: any) => {
                                                            setFieldValue("priceType",value);
                                                            setDisabledImport(true);
                                                        }}
                                                        items={priceTypes || []}
                                                        className={"flex-grow-1 "+ (errors.priceType && touched.priceType ? 'form-control is-invalid' : '')}
                                                    />
                                                </div>
                                            </Row>
                                            {
                                                integration?.handler?.type === SaleIntegrationHandlerTypes.INBOUND
                                                    ?
                                                    <>
                                                        {integration?.handler?.code === SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                                            ?
                                                            <>
                                                                <Field
                                                                    id={"form-attachmentFiles-input"}
                                                                    name={'media'}
                                                                    placeholder={t('integration:uploadGoogleCSVMediaPlaceholder')}
                                                                    isMultiple={false}
                                                                    component={InputFile}
                                                                    value={importMedia}
                                                                    onChange={(value: MainFileType[]) => {
                                                                        setImportMedia(value);
                                                                    }}
                                                                    required={true}
                                                                    maxFiles={1}
                                                                    disabled={!(integration?.uuid && integration.uuid !== undefined && integration.uuid !==null)}
                                                                    acceptedFiles=".csv, .zip"
                                                                />
                                                            </>
                                                            :
                                                        <Row>
                                                            <div className={ "mb-3"+ (touched['url'] ? " was-validated" : '')}>
                                                                <Label className="form-label" htmlFor="form-url-input">{t('common:url')}</Label>
                                                                <Field
                                                                    id="form-url-input"
                                                                    name="url"
                                                                    placeholder={t('common:enterUrl')}
                                                                    component={InputText}
                                                                    value = {getFieldProps('url').value}
                                                                    onChange={(name:string) => {
                                                                        setFieldValue('url', name);
                                                                        setDisabledImport(true);
                                                                    }}
                                                                    className={errors['url'] && touched['url'] && 'form-control is-invalid'}
                                                                />
                                                                {errors['url'] && touched['url'] ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors['url'])}
                                                                    </div>
                                                                ) :  touched['url'] && !errors['url'] ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Row>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                    {integration.uuid && integration.channel ?
                                        <Row>
                                            <div className={"mb-3"}>
                                                <Label className="form-label" htmlFor={"choices-channel-input"}>{t('channel:channel')}</Label>
                                                <ChannelItemBigLine item={integration.channel}/>
                                            </div>
                                        </Row>
                                        :
                                        (
                                            integration?.handler?.code !== SaleIntegrationHandlerCodes.WIDGET_WEB
                                            &&
                                            integration?.handler?.code !== SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                        ) &&
                                    <Row>
                                        <div className={ "mb-3"+ (touched.currency ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-channel-input"}>{t('channel:channel')}</Label>
                                            <Field
                                                id="choices-channel-input"
                                                name={"channel"}
                                                placeholder={t('channel:chooseChannel')}
                                                component={InputSelect}
                                                value={getFieldProps('channel').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("channel",value);
                                                    onChangeChannel(value);
                                                }}
                                                items={ getActualPeerChannelsByIntegrationHandler(integration.handler) || []}
                                                className={"flex-grow-1 "+ (errors.channel && touched.channel ? 'form-control is-invalid' : '')}
                                            />
                                        </div>
                                    </Row>
                                    }
                                    {integration?.handler?.code === SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER
                                        &&
                                        currentChannel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK &&
                                                            <>
                                                                {(integration?.uuid !== undefined && integration?.uuid !== null) ?
                                                                    <>
                                                                        {getFieldProps('metaBusinessManager')?.value?.name &&
                                                                            <>
                                                                    <Label>{t('integration:metaBusinessManager')}</Label>
        <p>{getFieldProps('metaBusinessManager')?.value?.name} <span className={"text-muted"}>({getFieldProps('metaBusinessManager')?.value?.idPeer})</span> </p>
                                                                            </>
                                                                        }
                                                                        {getFieldProps('metaProductCatalog')?.value?.name &&
                                                                            <>
                                                                                <Label>{t('integration:metaProductCatalog')}</Label>
                                                                                <p>{getFieldProps('metaProductCatalog')?.value?.name} <span className={"text-muted"}>({getFieldProps('metaProductCatalog')?.value?.idPeer})</span> </p>
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                {isLoadingMetaBusinessManagers ?
                                                                    <Loader/>
                                                                    :
                                                                    <Row>
                                                                        <div className={ "mb-3"+ (touched['metaBusinessManager'] ? " was-validated" : '')}>
                                                                            <Label className="form-label" htmlFor={"metaBusinessManager"}>{t('integration:metaBusinessManager')}</Label>
                                                                            <Field
                                                                                id={"metaBusinessManager"}
                                                                                name={"metaBusinessManager"}
                                                                                placeholder={t('integration:chooseMetaBusinessManager')}
                                                                                component={InputSelect}
                                                                                value={getFieldProps('metaBusinessManager').value}
                                                                                onChange={(value: any) => {
                                                                                    setFieldValue("metaBusinessManager",value);
                                                                                    if (value?.value){
                                                                                        handleRequestMetaProductCatalogs(value.value);
                                                                                    }
                                                                                }}
                                                                                items={metaBusinessManagers || []}
                                                                                className={"flex-grow-1 "+ (errors['metaBusinessManager'] && touched['metaBusinessManager'] ? 'form-control is-invalid' : '')}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                }
                                                                { (metaBusinessManagers || []).length>0 &&
                                                                <>
                                                                {isLoadingMetaProductCatalogs ?
                                                                    <Loader/>
                                                                    :
                                                                    <Row>
                                                                        <div className={ "mb-3"+ (touched['metaProductCatalog'] ? " was-validated" : '')}>
                                                                            <Label className="form-label" htmlFor={"metaProductCatalog"}>{t('integration:metaProductCatalog')}</Label>
                                                                            <Field
                                                                                id={"metaProductCatalog"}
                                                                                name={"metaProductCatalog"}
                                                                                placeholder={t('integration:chooseMetaProductCatalog')}
                                                                                component={InputSelect}
                                                                                value={getFieldProps('metaProductCatalog').value}
                                                                                onChange={(value: any) => {
                                                                                    setFieldValue("metaProductCatalog",value);
                                                                                }}
                                                                                items={metaProductCatalogs || []}
                                                                                className={"flex-grow-1 "+ (errors['metaProductCatalog'] && touched['metaProductCatalog'] ? 'form-control is-invalid' : '')}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                }
                                                                </>}
                                                                    </>}
                                                            </>
                                                        }
                                    {(
                                        currentChannel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM
                                        ||
                                        currentChannel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT
                                    ) &&
                                        <Row>
                                            <div className={ "mb-3"+ (touched.buttonName ? " was-validated" : '')}>
                                                <Label className="form-label" htmlFor="form-name-input">{t('common:buttonName')}</Label>
                                                <Field
                                                    id="form-buttonName-input"
                                                    name="buttonName"
                                                    placeholder={t('common:enterButtonName')}
                                                    component={InputText}
                                                    value = {getFieldProps('buttonName').value}
                                                    onChange={(name:string) => {
                                                        setFieldValue('buttonName', name);
                                                    }}
                                                    className={errors.buttonName && touched.buttonName && 'form-control is-invalid'}
                                                />
                                                {errors.buttonName && touched.buttonName ? (
                                                    <div className="invalid-feedback">
                                                        {t('validator:'+errors.buttonName)}
                                                    </div>
                                                ) :  touched.buttonName && !errors.buttonName ?
                                                    <div className="valid-feedback">
                                                        {t('common:looksGood')}
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                        </Row>
                                    }
                                    <Row>
                                        <div className={ "mb-3"+ (touched.currency ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-currency-input"}>{t('currency:currency')}</Label>
                                            <Field
                                                id="choices-currency-input"
                                                name={"currency"}
                                                placeholder={t('currency:chooseCurrency')}
                                                component={InputSelect}
                                                value={getFieldProps('currency').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("currency",value);
                                                }}
                                                items={currencies || []}
                                                className={"flex-grow-1 "+ (errors.currency && touched.currency ? 'form-control is-invalid' : '')}
                                            />

                                        </div>
                                    </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.store ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-store-input"}>{t('store:store')}</Label>
                                            <Field
                                                id="choices-store-input"
                                                name={"store"}
                                                placeholder={t('store:chooseStore')}
                                                component={InputSelect}
                                                value={getFieldProps('store').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("store",value);
                                                }}
                                                items={stores || []}
                                                isClearable = {true}
                                                className={"flex-grow-1 "+ (errors.store && touched.store ? 'form-control is-invalid' : '')}
                                            />

                                        </div>
                                    </Row>
                                    {paymentSystems && (
                                        integration?.handler?.code !== SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER
                                        &&
                                        integration?.handler?.code !== SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                        ) &&
                                    <Row>
                                        <div>
                                            <Label className="form-label" htmlFor={"choices-paymentSystems-input"}>{t('payment:paymentSystems')}</Label>

                                        { (paymentSystems || []).map((item:PaymentSystemType)=>{
                                            return (
                                                <div key={"form-paymentSystem-input_"+item.uuid}>
                                                {item.enabled &&
                                                    <div className="ms-1 mb-3 d-flex flex-column">
                                                        <Field
                                                            id={"form-paymentSystem-input_"+item.uuid}
                                                            placeholder={item.name}
                                                            name={"paymentSystem_"+item.uuid}
                                                            component={InputSwitch}
                                                            value = {getFieldProps("paymentSystem_"+item.uuid).value}
                                                            onChange={(enabled:boolean) => {
                                                                setFieldValue("paymentSystem_"+item.uuid, !getFieldProps("paymentSystem_"+item.uuid).value);
                                                            }}
                                                            className={"switch-sm"}
                                                            required={false}
                                                        />
                                                    </div>
                                                }
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </Row>
                                    }
                                    {deliverySystems && (
                                        integration?.handler?.code !== SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER
                                        &&
                                        integration?.handler?.code !== SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                        ) &&
                                        <Row>
                                            <div>
                                                <Label className="form-label" htmlFor={"choices-deliverySystems-input"}>{t('delivery:deliveryServices')}</Label>

                                                { (deliverySystems || []).map((item:DeliverySystemType)=>{
                                                    return (
                                                        <div key={"form-deliverySystem-input_"+item.uuid}>
                                                            {item.enabled &&
                                                                <div className="ms-1 mb-3 d-flex flex-column">
                                                                    <Field
                                                                        id={"form-deliverySystems-input_"+item.uuid}
                                                                        placeholder={item.name}
                                                                        name={"deliverySystem_"+item.uuid}
                                                                        component={InputSwitch}
                                                                        value = {getFieldProps("deliverySystem_"+item.uuid).value}
                                                                        onChange={(enabled:boolean) => {
                                                                            setFieldValue("deliverySystem_"+item.uuid, !getFieldProps("deliverySystem_"+item.uuid).value);
                                                                        }}
                                                                        className={"switch-sm"}
                                                                        required={false}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Row>
                                    }
                                    {integration?.handler?.code === SaleIntegrationHandlerCodes.WIDGET_WEB &&
                                        <UrlShortenerEdit/>
                                    }
                                    <Row>
                                        <div className={ "mb-0"}>
                                            <Label className="form-label" htmlFor="form-additionalSettings-input">{t('common:additionalSettings')} <a href="#" onClick={toggleShowAdditionalSettings}>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('ai:moreInfo')}
                                                        </Tooltip>
                                                    }
                                                ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                            </a></Label>
                                        </div>
                                    </Row>
                                    {isShowAdditionalSettings &&
                                        <div className={"mt-3"}>
                                            {(
                                                integration?.handler?.code !== SaleIntegrationHandlerCodes.META_BUSINESS_MANAGER
                                                &&
                                                integration?.handler?.code !== SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                                ) &&
                                                <>
                                            <Row>
                                                <div className="d-flex">
                                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                        <Field
                                                            id="form-isShowLanguages-input"
                                                            placeholder={t('integration:isShowLanguages')}
                                                            name="isShowLanguages"
                                                            component={InputSwitch}
                                                            value = {getFieldProps('isShowLanguages').value}
                                                            onChange={(enabled:boolean) => {
                                                                setFieldValue('isShowLanguages', !getFieldProps('isShowLanguages').value);
                                                            }}
                                                            required={false}
                                                            className={"switch-sm"}
                                                        />
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="d-flex">
                                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                        <Field
                                                            id="form-isShowLanguageFlags-input"
                                                            placeholder={t('integration:isShowLanguageFlags')}
                                                            name="isShowLanguageFlags"
                                                            component={InputSwitch}
                                                            value = {getFieldProps('isShowLanguageFlags').value}
                                                            onChange={(enabled:boolean) => {
                                                                setFieldValue('isShowLanguageFlags', !getFieldProps('isShowLanguageFlags').value);
                                                            }}
                                                            required={false}
                                                            className={"switch-sm"}
                                                        />
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className={ "mb-3"+ (touched.status ? " was-validated" : '')}>
                                                    <Label className="form-label" htmlFor={"choices-defaultLanguage-input"}>{t('integration:defaultLanguage')}</Label>
                                                    <Field
                                                        id="choices-defaultLanguage-input"
                                                        name={"defaultLanguage"}
                                                        placeholder={t('integration:chooseDefaultLanguage')}
                                                        component={InputSelect}
                                                        value={getFieldProps('defaultLanguage').value}
                                                        onChange={(value: any) => {
                                                            setFieldValue("defaultLanguage",value);
                                                        }}
                                                        isClearable={true}
                                                        items={languageOptions || []}
                                                        className={"flex-grow-1 "}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <Label className="form-label" htmlFor={"choices-availableLanguages-input"}>{t('integration:availableLanguages')}</Label>

                                                <DragDropContext
                                                    onDragEnd={dragEnded}
                                                >
                                                    <Droppable droppableId="availableWidgetLanguagesWrapper">
                                                        {(provided, snapshot) => {
                                                            return (
                                                            <ul ref={provided.innerRef} {...provided.droppableProps} className="list-unstyled">
                                                                {availableWidgetLanguages && ((availableWidgetLanguages || []).length>0) &&
                                                                    (availableWidgetLanguages || []).map((availableWidgetLanguage:LanguageOptionType, index:number)=>{
                                                                       return(
                                                                           <Draggable
                                                                               draggableId={`comment-${availableWidgetLanguage.code}`}
                                                                               index={index}
                                                                               key={"languageOption_"+availableWidgetLanguage.code}
                                                                           >
                                                                               {(_provided, _snapshot) => (
                                                                               <li
                                                                                   ref={_provided.innerRef}
                                                                                   {..._provided.draggableProps}
                                                                                   className={"" + (snapshot.isDragging ? "hovering" : "")}
                                                                               >
                                                                                   <div className="d-flex">
                                                                                       <span {..._provided.dragHandleProps}>
                                                                                          <i className="ri-more-2-fill text-muted me-2"/>
                                                                                        </span>

                                                                                       <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                                                                           <Field
                                                                                               id={"form-languageOption-input_"+availableWidgetLanguage.code}
                                                                                               placeholder={availableWidgetLanguage.label}
                                                                                               name={"languageOption_"+availableWidgetLanguage.code}
                                                                                               component={InputSwitch}
                                                                                               value = {Array.isArray(getFieldProps('availableLanguages').value) && getFieldProps('availableLanguages').value.indexOf(availableWidgetLanguage.code) !== -1}
                                                                                               onChange={(enabled:boolean) => {

                                                                                                   let _availableLanguages = [];

                                                                                                   if (Array.isArray(getFieldProps('availableLanguages').value) && getFieldProps('availableLanguages').value.indexOf(availableWidgetLanguage.code) !== -1){
                                                                                                       _availableLanguages = getFieldProps('availableLanguages').value.filter((availableLanguage)=>{
                                                                                                           return availableLanguage !== availableWidgetLanguage.code
                                                                                                       });
                                                                                                   } else {
                                                                                                       _availableLanguages = getFieldProps('availableLanguages').value || [];
                                                                                                       _availableLanguages=[..._availableLanguages, availableWidgetLanguage.code]
                                                                                                   }

                                                                                                   setFieldValue('availableLanguages',[..._availableLanguages]);
                                                                                               }}
                                                                                               required={false}
                                                                                               className={"switch-sm"}
                                                                                           />
                                                                                       </div>
                                                                                   </div>
                                                                               </li>
                                                                               )}
                                                                           </Draggable>
                                                                       );
                                                                    }
                                                                    )}
                                                                {provided.placeholder}
                                                            </ul>
                                                        )}}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Row>
                                            </>
                                            }
                                    <Row>
                                        {integration?.meta && Object.keys(integration.meta).map((metaKey:string, key: number)=>(
                                            Object.keys(MetaAllowEditFields).includes(metaKey) &&
                                                <div key={'metaProductIntegration_'+key} className={"mb-3"}>
                                                    <Label className="form-label" htmlFor={"form-"+metaKey+"-input"}>{t('common:'+metaKey)}</Label>
                                                    <Field
                                                        id={"form-"+metaKey+"-input"}
                                                        name={metaKey}
                                                        placeholder={metaKey}
                                                        component={InputText}
                                                        value = {getFieldProps('meta_'+metaKey).value}
                                                        onChange={(value:string) => {
                                                            setFieldValue('meta_'+metaKey, value);
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </Row>
                                        </div>
                                        }
                                    </>}
                                        </>
                                    }
                                    {formValues?.uuid && formValues.uuid.length>0 && currentCabinet !== false && currentCabinet?.uuid && integration?.handler?.code === SaleIntegrationHandlerCodes.WIDGET_TELEGRAM &&
                                    <NoticeTip firstText={t('integration:integrationUrlText')} secondTextWithCopy={m9sGlobals.W7_WIDGET_URL.replace(/:widgetData/,lz.compressToBase64(JSON.stringify({uuidCabinet: currentCabinet.uuid, uuidIntegration: formValues.uuid})))}/>
                                    }
                                    {formValues?.uuid && formValues.uuid.length>0 && currentCabinet !== false && currentCabinet?.uuid &&
                                        (
                                            integration?.handler?.code === SaleIntegrationHandlerCodes.WIDGET_WEB
                                        ) &&
                                        <NoticeTip
                                            firstText={t('integration:integrationWebWidgetText')}
                                            secondTextWithCopy={'<script> let _widgetUrl = \''+m9sGlobals.W7_WIDGET_URL.replace(/:widgetData/,lz.compressToBase64(JSON.stringify({uuidCabinet: currentCabinet.uuid, uuidIntegration: formValues.uuid})))+'\';\n' +
                                            'let _cabinet = \''+currentCabinet.uuid+'\';</script>\n' +
                                            '<script type="text/javascript" src="https://api.marketcells.io/cabinet_'+currentCabinet.uuid+'/js/widget.js" charset="UTF-8"></script>'}
                                            secondTextWithCopyTruncate={true}
                                        />
                                    }
                                    {formValues?.uuid && formValues.uuid.length>0 && currentCabinet !== false && currentCabinet?.uuid &&
                                        (
                                            integration?.handler?.code === SaleIntegrationHandlerCodes.GOOGLE_FEED
                                            ||
                                            (
                                                integration?.handler?.code === SaleIntegrationHandlerCodes.CSV_GOOGLE_FEED
                                                &&
                                                integration?.handler?.type === SaleIntegrationHandlerTypes.OUTBOUND
                                            )
                                        )
                                        &&
                                        <NoticeTip
                                            firstText={t('integration:integrationUrlText')}
                                            secondTextWithCopy={m9sGlobals.GOOGLE_FEED_URL.replace(/:cabinetUuid/,currentCabinet.uuid).replace(/:integrationUuid/,formValues.uuid)}
                                            secondTextWithCopyTruncate={true}
                                        />
                                    }
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                                {integration?.handler?.type === SaleIntegrationHandlerTypes.INBOUND && integration?.enabled && integration.enabled &&
                                    <Button
                                        variant="success"
                                        onClick={handleRequestImport}
                                        className="w-100"
                                        disabled={disabledImport || isImportRequest===true}
                                    >
                                        {t('integration:import')}
                                        {isImportRequest &&
                                            <ButtonSpinner/>
                                        }
                                    </Button>
                                }
                            </div>
                        </div>
                    </Form>
                        )
                    }}
                </Formik>
                    }
                </Offcanvas>
        </>
    );
}

export default RightModal;