import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum ContentQuickAnswerActionTypes {
  API_RESPONSE_SUCCESS = "@@contentQuickAnswer/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentQuickAnswer/API_RESPONSE_ERROR",
  GET_CONTENT_QUICK_ANSWER = "@@contentQuickAnswer/GET_CONTENT_QUICK_ANSWER",
  UPDATE_CONTENT_QUICK_ANSWER = "@@contentQuickAnswer/UPDATE_CONTENT_QUICK_ANSWER",
  ADD_CONTENT_QUICK_ANSWER = "@@contentQuickAnswer/ADD_CONTENT_QUICK_ANSWER",
  REMOVE_CONTENT_QUICK_ANSWER_MODAL_SHOW = "@@contentQuickAnswer/REMOVE_CONTENT_QUICK_ANSWER_MODAL_SHOW",
  REMOVE_CONTENT_QUICK_ANSWER_MODAL_HIDE = "@@contentQuickAnswer/REMOVE_CONTENT_QUICK_ANSWER_MODAL_HIDE",
  REMOVE_CONTENT_QUICK_ANSWER = "@@contentQuickAnswer/REMOVE_CONTENT_QUICK_ANSWER",
  EDIT_CONTENT_QUICK_ANSWER = "@@contentQuickAnswer/EDIT_CONTENT_QUICK_ANSWER",

  RIGHT_MODAL_SHOW = "@@contentQuickAnswer/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@contentQuickAnswer/RIGHT_MODAL_HIDE",
}

export interface ContentQuickAnswerState extends MainMetaType{
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: ContentQuickAnswerType;
  modalEditTitle: string;
  isSending: boolean;
  isFetched: boolean;
  isLoading: boolean;
  isNeedReload: boolean;
}

export interface ContentQuickAnswerType{
  uuid?: string,
  enabled: boolean,
  name: string,
  text: string,
  actions?: EntityActionsType
}

export interface ContentQuickAnswerPayload {
  data: Array<any>;
  modalEditTitle : string;
}
