import React, {useState} from "react";
import {MainFileImageTypes, MainFileType, MainFileVideoTypes} from "../../../redux/Main/File/types";
import {formatBytes, isImage, isVideo} from "../../../helpers/functions";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

interface InputProps {
    file: MainFileType
}

const FileItem = ({file}:InputProps) => {
    const [isPopupShow, setPopupShow] = useState<MainFileType>(null);

    const handleOpenMediaPopup = (file:MainFileType) => {
        setPopupShow(file);
    }
    const handleCloseMediaPopup = () => {
        setPopupShow(null);
    }

    return (
        <>
            { isPopupShow!==null &&
                <>
                    <Lightbox mainSrc={isPopupShow.downLoadUrl} onCloseRequest={handleCloseMediaPopup} imageTitle={isPopupShow.fileName}/>
                </>
            }
        <div className="d-flex align-items-center border border-dashed p-2 rounded mt-2">
            <div className="flex-shrink-0 avatar-sm">
                {isImage(file)
                    &&
                        <img
                            height="80"
                            className="avatar-sm rounded bg-light me-3 object-cover"
                            alt={file.fileName}
                            src={file.url}
                            onClick={()=>handleOpenMediaPopup(file)}
                        />
                }
                {isVideo(file)
                    &&
                        <div className="avatar-title bg-light rounded">
                            <i className={"ri-file-"+file.contentType.split("/").pop()+"-line fs-20 text-danger"}></i>
                        </div>
                }
                {
                    !isImage(file)
                    &&
                    !isVideo(file)
                    &&
                        <div className="avatar-title bg-light rounded">
                            <i className={"ri-file-"+file.contentType.split("/").pop()+"-line fs-20 text-danger"}></i>
                        </div>
                }

            </div>
            <div className="flex-grow-1 ms-3 mw-0">
                <h6 className="mb-1 text-truncate"><a href={file.downLoadUrl} target="_blank" rel="noreferrer" className="text-truncate">{file.fileName}</a></h6>
                <small className="text-muted">{formatBytes(file.fileSize)}</small>
            </div>
            <div className="hstack gap-3">
                <a href={file.downLoadUrl} className="text-muted" target="_blank" rel="noreferrer"><i className="ri-download-2-line"></i></a>
            </div>
        </div>
        </>
    );
}

export default FileItem;