import React from 'react';
import {Col} from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import { Link } from 'react-router-dom'
import '../../i18/config';
import {useTranslation} from "react-i18next";

const logoLight = require("../../resources/images/mclogow.svg");

const AuthCarousel = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Col lg={6}>
                <div className="p-lg-5 p-4 auth-one-bg h-100">
                    <div className="bg-overlay"/>
                    <div className="position-relative h-100 d-flex flex-column">
                        <div className="mb-4">
                            <Link to="/" className="d-block">
                                <img src={logoLight.default} alt="" height="30" />
                            </Link>
                        </div>
                        <div className="mt-auto">
                            <div className="mb-3">
                                <i className="ri-double-quotes-l display-4 text-success"/>
                            </div>

                            <Carousel showThumbs={false} autoPlay={true} showArrows={false} showStatus={false} infiniteLoop={true} className="carousel slide">
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">{t('auth:coverAuthPhrase1')}</p>
                                    </div>
                                </div>
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">{t('auth:coverAuthPhrase2')}</p>
                                    </div>
                                </div>
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">{t('auth:coverAuthPhrase3')}</p>
                                    </div>
                                </div>
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">{t('auth:coverAuthPhrase4')}</p>
                                    </div>
                                </div>
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">{t('auth:coverAuthPhrase5')}</p>
                                    </div>
                                </div>
                            </Carousel>

                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}

export default AuthCarousel;