// types
import {EventWebhookActionTypes, EventWebhookState, EventWebhookType} from "./types";

export const NewEventWebhookItem:EventWebhookType = {
  enabled: true,
  name: '',
  order: 500,
  url: '',
  isApproved: false
}

export const INIT_STATE_EVENT_WEBHOOK: EventWebhookState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewEventWebhookItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false,
  availableEventTypes: [],
  isEventTypesFetched: false
};

const EventWebhook = (state = INIT_STATE_EVENT_WEBHOOK, action: any) => {
  switch (action.type) {
    case EventWebhookActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case EventWebhookActionTypes.GET_EVENT_WEBHOOKS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case EventWebhookActionTypes.GET_AVAILABLE_EVENT_TYPES:
          return {
            ...state,
            availableEventTypes: action.payload.data.items,
            isEventTypesFetched: true
          };
        case EventWebhookActionTypes.UPDATE_EVENT_WEBHOOK:
          return {
            ...state,
            selectedItem: NewEventWebhookItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK:
          return {
            ...state,
            selectedItem: NewEventWebhookItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case EventWebhookActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case EventWebhookActionTypes.GET_EVENT_WEBHOOKS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case EventWebhookActionTypes.GET_AVAILABLE_EVENT_TYPES:
          return {
            ...state,
            isEventTypesFetched: true
          };
        case EventWebhookActionTypes.UPDATE_EVENT_WEBHOOK:
          return {
            ...state,
            isSending: false
          };
        case EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case EventWebhookActionTypes.EDIT_EVENT_WEBHOOK:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case EventWebhookActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewEventWebhookItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewEventWebhookItem,
        removeModalShow: false
      }
    case EventWebhookActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case EventWebhookActionTypes.UPDATE_EVENT_WEBHOOK:
      return {
        ...state,
        isSending: true
      };
    case EventWebhookActionTypes.GET_AVAILABLE_EVENT_TYPES:
      return {
        ...state,
        isEventTypesFetched: false
      };
    case EventWebhookActionTypes.GET_EVENT_WEBHOOKS:
      return {
        ...state,
        isLoading: false
      };
    default:
      return { ...state };
  }
};

export default EventWebhook;
