import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import * as route from "../../../api/routes";
import {ProductType} from "../../../redux/Sale/Product/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {ProductParamValueType} from "../../../redux/Sale/ProductParamValue/types";
import {ProductParamListValueType, ProductParamType, ProductParamTypes} from "../../../redux/Sale/ProductParam/types";

interface ItemProps {
    item: ProductType;
    productParams: ProductParamType[]
}
const ProductItemLine = ({ item, productParams }: ItemProps) => {

    const [cartProductParamsValues, setCartProductParamsValues] = useState<any[]>([]);
    const { t } = useTranslation();

    useEffect(()=>{

        let _cartProductParamsValues=[];

        (item?.productParamValues || []).map((productParamValue:ProductParamValueType) => {

            if (productParamValue?.productParam?.isCart && productParamValue.productParam.isCart) {
                switch (productParamValue.productParam.type) {
                    case ProductParamTypes.LIST:
                        let _productParam = (productParams || []).filter((productParam: ProductParamType)=>{
                            return productParam.uuid ===productParamValue?.productParam?.uuid
                        }).shift();

                        if (_productParam?.uuid){
                            let _productParamListValue = (_productParam?.values || []).filter((productParamListValue: ProductParamListValueType)=>{
                                return productParamListValue.uuid === productParamValue.value
                            }).shift();

                            if (_productParamListValue?.value){
                                _cartProductParamsValues.push(_productParamListValue.value);
                            }
                        }
                        break
                    default:
                        _cartProductParamsValues.push(productParamValue.value);
                }
            }

            return productParamValue;
        });

        setCartProductParamsValues(_cartProductParamsValues);

    },[item, productParams])

    return(
        <>
            <div className="d-flex align-items-center mt-2 mb-2">
                <div className="flex-shrink-0 me-3">
                    <Link to={route.SALE_PRODUCT_DETAIL.replace(/:uuid/g, item.uuid)} className="fw-medium link-primary">
                        <div className="d-flex avatar-sm bg-light rounded p-1 align-items-center justify-content-center">
                            {(item.media && (item.media ||[]).length>0) ?
                                <img
                                    src={[...item.media].shift().url} alt="" className="img-fluid d-block rounded"/>
                                :
                                <i className="ri-camera-off-line text-muted fs-18"/>
                            }
                        </div>
                    </Link>
                </div>
                <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1 text-truncate">
                        <Link to={route.SALE_PRODUCT_DETAIL.replace(/:uuid/g, item.uuid)} className="fw-medium link-primary">
                            {item.name}
                        </Link>
                        {(cartProductParamsValues || []).length>0 &&
                            <div className="text-muted fs-10 carProductProperties text-truncate">
                                                {
                                                    (cartProductParamsValues || []).map((item:string, key:number)=>{
                                                        return(<span key={'cartProductParamsValue_'+key}>{item}</span>)
                                                    })
                                                }
                                            </div>
                        }
                    </h5>
                    <p className="text-muted mb-0">{t('category:category')}: <span
                        className="fw-medium text-truncate">
                                    {item?.category?.name
                                        ?
                                        item.category.name
                                        :
                                        <>{t('category:rootCategory')}</>
                                    }
                                    </span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ProductItemLine;