import {PaymentSystemActionTypes, SalePaymentSystemPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const paymentSystemApiResponseSuccess = (actionType: string, data: any) => ({
  type: PaymentSystemActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const paymentSystemApiResponseError = (actionType: string, error: string) => ({
  type: PaymentSystemActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPaymentSystems = (data: MainMetaType) => ({
  type: PaymentSystemActionTypes.GET_PAYMENT_SYSTEMS,
  payload: data,
});

export const getSalePaymentSystems = (data: MainMetaType) => ({
  type: PaymentSystemActionTypes.GET_SALE_PAYMENT_SYSTEMS,
  payload: data,
});

export const showRightModalSalePaymentSystem= (data: SalePaymentSystemPayload) => ({
  type: PaymentSystemActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSalePaymentSystem= () => ({
  type: PaymentSystemActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSalePaymentSystem = (data: any) => ({
  type: PaymentSystemActionTypes.UPDATE_SALE_PAYMENT_SYSTEM,
  payload: data,
});

export const editDataSalePaymentSystem = (data: SalePaymentSystemPayload) => ({
  type: PaymentSystemActionTypes.EDIT_SALE_PAYMENT_SYSTEM,
  payload: data,
});

export const removeSalePaymentSystemModalShow = (data: SalePaymentSystemPayload) => ({
  type: PaymentSystemActionTypes.REMOVE_SALE_PAYMENT_SYSTEM_MODAL_SHOW,
  payload: data,
});

export const removeSalePaymentSystemModalHide = () => ({
  type: PaymentSystemActionTypes.REMOVE_SALE_PAYMENT_SYSTEM_MODAL_HIDE,
});

export const onRemoveSalePaymentSystem = (data: SalePaymentSystemPayload) => ({
  type: PaymentSystemActionTypes.REMOVE_SALE_PAYMENT_SYSTEM,
  payload: data,
});