import React from "react";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {useRedux} from "../../hooks";
import {OrderCustomerType} from "../../redux/Sale/OrderCustomer/types";
import {ContentPeerUserType} from "../../redux/Content/PeerUser/types";
import PeerUserAvatarItem from "../../blocks/Content/PeerUser/PeerUserAvatarItem";
import {editDataSaleOrderCustomer, removeSaleOrderCustomerModalShow} from "../../redux/Sale/OrderCustomer/actions";
import {convertToMomentLocale} from "../../helpers/functions";

interface ItemProps {
    item: OrderCustomerType;
    number: number
    isCanOrderEdit: boolean
}
const OrderCustomerItem = ({ item, number, isCanOrderEdit=false }: ItemProps) => {

    const { t, i18n } = useTranslation(['plan']);
    const { dispatch } = useRedux();
    moment.locale(convertToMomentLocale(i18n.language));

    const handleEdit = () => {
        dispatch(editDataSaleOrderCustomer({data: Object(item), modalEditTitle: 'changeCustomer'}));
    }

    const handleRemove = () => {
        dispatch(removeSaleOrderCustomerModalShow({data: Object(item), modalEditTitle: 'deleteCustomer'}));
    }

    return (
        <>
            <ul className="list-unstyled mb-0 vstack gap-3">
                <li>
                    <div className="d-flex align-items-center">
                        {/*<div className="flex-shrink-0 me-3">*/}
                        {/*    {userAvatar &&*/}
                        {/*        <img className="avatar-sm rounded" src={userAvatar}*/}
                        {/*             alt={order.customerName}/>*/}
                        {/*    }*/}
                        {/*</div>*/}
                        <div className="flex-grow-1">
                            <h6 className="fs-15 mb-1">{item.name}</h6>
                            <p className="text-muted mb-0">{t('sale:customer')}</p>
                        </div>
                        {isCanOrderEdit &&
                        <div className="flex-shrink-0">
                            <button
                                onClick={handleEdit}
                                type="button"
                                title={t('common:edit')}
                                className="btn btn-outline-primary btn-icon waves-effect waves-light">
                                <i className="ri-pencil-fill"/>
                            </button>
                            {/*<button*/}
                            {/*    onClick={handleRemove}*/}
                            {/*    type="button"*/}
                            {/*    title={t('common:delete')}*/}
                            {/*    className="btn btn-outline-danger btn-icon waves-effect waves-light">*/}
                            {/*    <i className="ri-delete-bin-5-line"/>*/}
                            {/*</button>*/}
                        </div>
                        }
                    </div>
                </li>
                {(item?.customer?.peerUsers || []).length>0 &&
                    <li>
                        <div className={ "mb-3"}>
                            <div className="d-flex flex-row flex-wrap avatar-group">
                                {item.customer.peerUsers.map((peerUser: ContentPeerUserType, key: number) => (
                                    <PeerUserAvatarItem item={peerUser} key={'peerUser_' + peerUser.uuid}/>
                                ))}
                            </div>
                        </div>
                    </li>
                }
                {item?.email && item?.email.length>0 &&
                    <li>
                        <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                        {item.email}
                    </li>
                }
                {item?.phone && item.phone.toString().length > 0 &&
                    <li>
                        <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                        {item.phone}
                    </li>
                }
                {item?.tin && item.tin.toString().length > 0 &&
                    <li>
                        <i className="ri-briefcase-4-line me-2 align-middle text-muted fs-16"></i>
                        {item.tin}
                    </li>
                }
            </ul>
        </>
    )
}

export default OrderCustomerItem;