import React from "react";
import { Card, CardBody, Col } from 'reactstrap';

import '../i18/config';
import {useTranslation} from "react-i18next";

interface ItemProps {
    onClick?: () => void;
    link?: string;
    isBlank? : boolean;
    icon?: string;
    iconClass?: string;
    name?: string;
}

const ListButtonRender = ({name, icon, iconClass }:ItemProps) => {

    const { t } = useTranslation(['common']);

    return (
        <>
            <Card className={"card-height-100 card-animate"}>
                <CardBody>
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                        <div className="mb-2">
                            <div className="avatar-xs position-relative">
                                <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                    {icon ?
                                        <img src={icon} alt={name && name} className="img-fluid p-1 rounded-circle"/>
                                        :
                                        iconClass ?
                                            <i className={iconClass}/>
                                            :
                                        <>+</>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow-1 d-flex align-items-center">
                            <h5 className="mb-0 fs-12 text-center">
                                {name ?
                                    name
                                    :
                                    t('common:addNew')
                                }
                            </h5>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

const ListButtonAddItem = ({ name, icon, iconClass, isBlank, link, onClick }: ItemProps) => {

    return (
        <Col xxl={3} sm={6} className="project-card">
            { link ?
                <a href={link} target={isBlank ? '_blank' : '_self'} rel="noreferrer">
                    <ListButtonRender
                        name={name && name}
                        icon={icon && icon}
                        iconClass={iconClass && iconClass}
                    />
                </a>
            :
                <a onClick={onClick}>
                    <ListButtonRender
                        name={name && name}
                        icon={icon && icon}
                        iconClass={iconClass && iconClass}
                    />
                </a>
            }

        </Col>
    );
};

export default ListButtonAddItem;
