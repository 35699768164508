

export const logoVk = require('../resources/images/connectors/vk.svg');
export const logoViber = require('../resources/images/connectors/viber.svg');
export const logoAvito = require('../resources/images/connectors/avito.svg');
export const logoBitrix = require('../resources/images/connectors/bitrix.svg');
export const logoFacebook = require('../resources/images/connectors/facebook.svg');
export const logoGoogleAnalytics = require('../resources/images/connectors/googleAnalytics.svg');
export const logoInstagram = require('../resources/images/connectors/instagram.svg');
export const logoLinkedIn = require('../resources/images/connectors/linkedin.svg');
export const logoOk = require('../resources/images/connectors/ok.svg');
export const logoOzon = require('../resources/images/connectors/ozon.svg');
export const logoPinterest = require('../resources/images/connectors/pinterest.svg');
export const logoTelegram = require('../resources/images/connectors/telegram.svg');
export const logoTwitter = require('../resources/images/connectors/twitter.svg');
export const logoWhatsApp = require('../resources/images/connectors/whatsapp.svg');
export const logoYandexMetrika = require('../resources/images/connectors/yandex.svg');
export const logoYouTube = require('../resources/images/connectors/youtube.svg');
export const logoTikTok = require('../resources/images/connectors/tiktok.svg');
export const logoChatGPT = require('../resources/images/connectors/chatGPT.svg');
export const logoGoogleBard = require('../resources/images/connectors/googleBard.svg');
export const logoIdea = require('../resources/images/connectors/idea3.svg');

export const logoIdeaChannel = require('../resources/images/channels/idea1.jpg');
export const logoGoogleMerchant = require('../resources/images/connectors/googleMerchant.svg');
export const logoYandexEn = require('../resources/images/connectors/yandexEn.svg');
export const safariLogo = require('../resources/images/connectors/safari.svg');
export const logoCSVGoogleFeed = require('../resources/images/connectors/csv.svg');
export const logoWordPress = require('../resources/images/connectors/wordpress.svg');