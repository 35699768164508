import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {hideRightModalMainUser, onSendDataMainUser} from "../../../redux/Main/User/actions";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {MainUserType} from "../../../redux/Main/User/types";
import {UserEditValidatorSchema} from "./UserEditValidator";
import SimpleBar from 'simplebar-react';
import {MainRoleType} from "../../../redux/Main/Role/types";
import {NewMainUserItem} from "../../../redux/Main/User/reducer";
import ButtonSpinner from "../../../components/ButtonSpinner";
import InputPhone from "../../../components/InputPhone";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {isNumeric} from "../../../helpers/functions";
import UpgradeFareBlock from "../../../components/UpgradeFareBlock";
import InputEmojiArea from "../../../components/InputEmojiArea";

const RightModal = () => {

    const { dispatch, useAppSelector } = useRedux();
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

    const { t } = useTranslation(['common']);

    const {
        mainUser,
        mainRole,
        mainCabinet,
        isSending,
        salePlan
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
        mainRole: state.MainRole,
        mainCabinet: state.MainCabinet,
        isSending: state.MainUser.isSending,
        salePlan: state.SalePlan
    }));

    const [uuid, setUuid] = useState<string>(null);
    const [formValues, setFormValues] = useState<MainUserType>(mainUser.selectedItem);

    useEffect(() => {

        if (mainUser?.selectedItem && mainUser?.selectedItem?.uuid !== uuid) {
            setUuid(mainUser.selectedItem.uuid);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainUser?.selectedItem]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            setCurrentPlan({...salePlan.currentPlan});
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {

        checkFareRestriction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    useEffect(() => {
        checkFareRestriction();
        if (uuid?.length>1) {
            setFormValues(mainUser.selectedItem);
        } else {
            setFormValues(NewMainUserItem);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);

    const handleClose = () => dispatch(hideRightModalMainUser());

    const formikObject = useRef(null);

    const onDataSubmit = (formData:MainUserType) => {
        let data: any = {};

        if (formData.name) {
            data["name"] = formData.name;
        }
        if (formData.email) {
            data["email"] = formData.email;
        }
        if (formData.phone) {
            data["phone"] = formData.phone;
        }
        if (formData.description) {
            data["description"] = formData.description;
        }
        if (formData.position) {
            data["position"] = formData.position;
        }
        if (formData.roles) {
            data["roles"]=formData.roles;
        }

        data["enabled"] = formData.enabled;

        if (uuid) {
            data["uuid"] = uuid;
        }

        let params = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            email: data.email && data.email,
            name: data.name && data.name,
            description: data.description && data.description,
            position: data.position && data.position,
            phone: data.phone && data.phone,
            roles: data.roles && data.roles,
            cabinet: {uuid : mainCabinet.currentCabinet.uuid}
        };

        dispatch(onSendDataMainUser(params));

    };

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.MAIN_MAX_USERS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(true);
        } else if (mainUser) {
            if (isNumeric(restrictionValue.value)) {

                if (parseInt(restrictionValue.value) === (mainUser.items || []).filter((item: MainUserType) => item.enabled).length) {
                    //edit only

                    if (uuid === undefined || uuid === null) {
                        setUpgradeFare(true);
                    } else {
                        setUpgradeFare(false);
                    }
                } else if (parseInt(restrictionValue.value) < (mainUser.items || []).filter((item: MainUserType) => item.enabled).length) {
                    setUpgradeFare(true);
                } else {
                    setUpgradeFare(false);
                }
            } else {
                setUpgradeFare(true);
            }
        }
    }

    return (
        <>
            <Offcanvas
                direction="end"
                isOpen={mainUser.rightModalShow}
                id="userEdit"
                toggle={handleClose}
            >
                { upgradeFare ?
                    <UpgradeFareBlock onClose={handleClose}/>
                    :
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={UserEditValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    innerRef={formikObject}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('user:'+mainUser.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.email ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-email-input">E-mail</Label>
                                    <Field
                                        id="form-email-input"
                                        name="email"
                                        placeholder={t('user:enterEmail')}
                                        component={InputText}
                                        value = {getFieldProps('email').value}
                                        onChange={(email:string) => {
                                            setFieldValue('email', email);
                                        }}
                                        className={errors.email && touched.email && 'form-control is-invalid'}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.email)}
                                        </div>
                                    ) :  touched.email && !errors.email ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:name')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="name"
                                        placeholder={t('user:enterName')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.position ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor="form-position-input">{t('user:position')}</Label>
                                            <Field
                                                id="form-position-input"
                                                name="position"
                                                placeholder={t('user:enterPosition')}
                                                component={InputText}
                                                value = {getFieldProps('position').value}
                                                onChange={(value:string) => {
                                                    setFieldValue('position', value);
                                                }}
                                            />
                                        </div>
                                    </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.phone ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-phone-input">{t('user:phone')}</Label>
                                    <Field
                                        id="form-phone-input"
                                        name="phone"
                                        placeholder={t('user:enterPhone')}
                                        component={InputPhone}
                                        value = {getFieldProps('phone').value}
                                        onChange={(phone:string) => {
                                            setFieldValue('phone', phone);
                                        }}
                                        inputClassName={errors.phone && touched.phone ? 'form-control is-invalid' : "form-control"}
                                    />
                                    {errors.phone && touched.phone ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.phone)}
                                        </div>
                                    ) :  touched.phone && !errors.phone ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                                    <Row>
                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="form-description-input">{t('common:description')}</Label>
                                            <div className="textAreaEmojiBlock">
                                                <Field
                                                    id="form-description-input"
                                                    name="description"
                                                    component={InputEmojiArea}
                                                    value = {getFieldProps('description').value}
                                                    onChange={(text:string) => {
                                                        setFieldValue('description', text);
                                                    }}
                                                    required={false}
                                                    placeholder={t('common:enterDescription')}
                                                />
                                            </div>
                                        </div>
                                    </Row>
                            <Row>
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="form-roles-input">{t('role:roles')}</Label>
                                    { mainRole.items.map((role: MainRoleType, key: number)=>{
                                        return(
                                            <Field
                                                key={"key-form-roles-input-" + role.code}
                                                id={"form-roles-input-" + role.code}
                                                placeholder={t('role:' + role.code)}
                                                name="roles"
                                                component={InputSwitch}
                                                value={(getFieldProps('roles').value.filter((item: MainRoleType) => item.code === role.code).length > 0)}
                                                onChange={(enabled: boolean) => {
                                                    let foundRole = getFieldProps('roles').value.filter((roleItem:MainRoleType)=>{
                                                        return roleItem.code === role.code
                                                    }).shift();

                                                    if (foundRole?.code){
                                                        setFieldValue('roles',getFieldProps('roles').value.filter((roleItem:MainRoleType)=>{
                                                            return roleItem.code !== role.code
                                                        }));
                                                    } else {
                                                        let rolesArray = getFieldProps('roles').value;
                                                        rolesArray.push(role);
                                                        setFieldValue('roles',[...rolesArray]);
                                                    }
                                                }}
                                                className={"switch-sm"}
                                                required={false}
                                            />
                                            //
                                            // <Field
                                            // id={"form-roles-input-" + role.code}
                                            // type="checkbox"
                                            // key={key}
                                            // name="roles"
                                            // component={InputCheckbox}
                                            // placeholder={t('role:'+role.code)}
                                            // value = {role.code}
                                            // isChecked = { (getFieldProps('roles').value.filter((item: MainRoleType) => item.code === role.code).length > 0) }
                                            // onChange={handleRoleChange}
                                            // />
                                        )}
                                        )
                                    }
                                </div>
                            </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>
                }
            </Offcanvas>
        </>
    );
}

export default RightModal;