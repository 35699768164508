import { takeEvery, fork, put, all, call } from "typed-redux-saga";

import { commentApiResponseSuccess, commentApiResponseError } from "./actions";

import {
  updateComment as updateCommentApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {CommentActionTypes} from "./types";

function* onSendDataComment({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateCommentApi, data);
    yield put(
        commentApiResponseSuccess(CommentActionTypes.UPDATE_COMMENT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(commentApiResponseError(CommentActionTypes.UPDATE_COMMENT, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchOnSendDataComment() {
  yield takeEvery(CommentActionTypes.UPDATE_COMMENT, onSendDataComment);
}


function* commentSaga() {
  yield all([
      fork(watchOnSendDataComment),
  ]);
}

export default commentSaga;
