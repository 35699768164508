import React from "react";
import {MainUserType} from "../../../redux/Main/User/types";

interface ItemProps {
    item: MainUserType;
    size?: string
}
const UserAvatar = ({ item, size = 'xs' }: ItemProps) => {

    return(
        <>
            <div className={"avatar-"+size}>
                { item.avatar
                    ?
                    <img src={item.avatar} alt="" className="rounded-circle img-fluid" />
                    :
                    <div className="avatar-title rounded-circle bg-light border-dashed border text-primary text-capitalize"> {item.name[0]} </div>
                }
            </div>
        </>
    )
}

export default UserAvatar;