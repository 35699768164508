
export const MAIN_USERS_LIST = "/admin/users";
export const MAIN_SEARCH = "/search";
export const MAIN_WIZARD_INIT = "/admin/wizard/init";
export const MAIN_PERSONAL_PROFILE = "/personal/profile";
export const MAIN_VERSION_UPDATES = "/version/updates";
export const MAIN_DASHBOARD_CONTENT_PROJECT = "/dashboard/content/#idProject#";
export const MAIN_DASHBOARD_CONTENT_MARKETING = "/dashboard/content";
export const MAIN_DASHBOARD_SALES = "/dashboard/sales";
export const MAIN_DASHBOARD_TASKS = "/dashboard/tasks";
export const MAIN_DASHBOARD_CONTENT_MARKETING_UUID = "/dashboard/content/:uuid";
export const MAIN_AUTH_LOGIN= "/auth/login";
export const MAIN_CABINET_LIST= "/auth/cabinet/list";
export const MAIN_AUTH_SIGN_UP= "/auth/signup";
export const MAIN_AUTH_SIGN_UP_CONFIRMED="/auth/register/confirmed"
export const MAIN_AUTH_REQUEST_RESET_PASSWORD= "/auth/reset";
export const MAIN_AUTH_RESET_PASSWORD= "/auth/resetting/reset/:token";
export const MAIN_AUTH_SUCCESS= "/auth/success";
export const MAIN_AUTH_SOCIAL= "/auth/social/:token";

export const MAIN_AUTH_SUCCESSFUL_CONNECT_MARKETCELLS_SERVICE= "/connect/service/marketcells";
export const MAIN_OAUTH_CONNECT_MARKETCELLS_SERVICE= "/connect/marketcells";

export const CONTENT_PROJECTS_LIST = "/admin/projects";
export const CONTENT_PEER_ACTIVITY_TYPES_LIST = "/admin/activity/types";
export const CONTENT_SERVICES_LIST = "/services";
export const CONTENT_CHANNELS_LIST = "/channels";
export const CONTENT_STAT_COUNTERS_LIST = "/counters";
export const CONTENT_PATTERNS_LIST = "/templates/patterns";
export const CONTENT_QUICKANSWERS_LIST = "/templates/quickanswers";
export const CONTENT_PEERTARGETS_LIST = "/goals";
export const CONTENT_ACTIVITY_PLAN = "/activity/plan";
export const CONTENT_ACTIVITY_KANBAN = "/activity/kanban";
export const CONTENT_ACTIVITY_IDEAS = "/activity/ideas";
export const CONTENT_CHANNEL_MEMBERS = "/channel/:uuidChannel/members";
export const CONTENT_CHANNEL_ACTIVITIES = "/channel/:uuidChannel/activities";

export const STAT_CONTENT_CHANNELS = "/stat/channels";
export const STAT_CONTENT_CHANNEL = "/stat/channel/:uuidChannel";
export const STAT_CONTENT_ACTIVITY = "/stat/activity/:uuidActivity";
export const COMPARE_CONTENT_CHANNELS = "/stat/channels/compare";
export const EVENT_PERSONAL_NOTIFICATIONS = "/notification/list";

export const MESSENGER_MESSENGER = "/messenger/chats";
export const MESSENGER_COMMENTS = "/messenger/comments";

export const SALE_PLANS = "/admin/plans";
export const PLAN_ORDERS = "/admin/orders";
export const PLAN_ORDER_DETAIL = "/admin/orders/detail/:uuid";
export const PLAN_ORDER_CHECKOUT = "/admin/orders/checkout";

export const CABINET_COMING_SOON = "/cabinet/coming_soon";

export const ERROR_404 = "/404";

export const SUPPORT_TICKETS_LIST = "/support/tickets";
export const SUPPORT_TICKET_DETAIL = "/support/ticket/:uuid";

export const SUPPORT_SERVICE_TICKETS_LIST = "/support/service/tickets";
export const SUPPORT_SERVICE_TICKET_DETAIL = "/support/service/ticket/:uuid";

export const MAIN_ABOUT = "/about";

export const SALE_STORES_LIST = "/sale/settings/stores";
export const SALE_CURRENCIES_LIST = "/sale/settings/currencies";
export const SALE_CURRENCY_RATES_LIST = "/sale/settings/currencyrates";
export const SALE_PRICE_TYPES_LIST = "/sale/settings/pricetypes";
export const SALE_PAYMENT_SYSTEMS_LIST = "/sale/settings/paymentsystems";
export const SALE_DELIVERY_SYSTEMS_LIST = "/sale/settings/deliverysystems";
export const SALE_PRODUCTS_LIST = "/sale/products";
export const SALE_CATEGORIES_LIST = "/sale/categories";
export const SALE_PRODUCT_PARAMS_LIST = "/sale/settings/productparams";
export const SALE_ORDERS_LIST = "/sale/orders";
export const SALE_ORDER_DETAIL = "/sale/order/:uuid";
export const SALE_SETTINGS = "/sale/settings";
export const SALE_INTEGRATIONS_LIST = "/sale/settings/integrations";
export const SALE_PRODUCT_DETAIL = "/sale/product/detail/:uuid";
export const SALE_PRODUCT_DETAIL_EDIT = "/sale/product/detail/:uuid/edit";
export const SALE_CUSTOMERS_LIST = "/sale/settings/customers";
export const SALE_ORDER_STATUSES_LIST = "/sale/settings/orderstatuses";
export const EVENT_WEBHOOKS_LIST = "/event/webhooks";

export const TASK_LIST = "/task/tasks";
export const TASK_DETAIL = "/task/task/:uuid";

export const FUNNELS = "/funnels";