import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";

import { useRedux } from "../../../hooks";
import {
    getMainRoles,
    getMainUsers, getUserCabinetFromRemote,
} from "../../../redux/actions";

import TableList from "../../../components/TableList";
import MainUserColumns from "./tableColumns";
import {EntityActionsHandlersType} from "../../../redux/types";
import RemoveModal from "../../../components/RemoveForm";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {mainUserHideRemoveModal, mainUserOnRemove, mainUserOpenModal, mainUserOpenRemoveModal} from "./MainUserActions";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {getHistoryHash, setHistoryHash as setHistoryHashFunc} from "../../../helpers/functions";
import {MainUserType} from "../../../redux/Main/User/types";
import Loader from "../../../components/Loader";
import {MainMetaType} from "../../../redux/Main/common";
import MainListFilter from "../../../components/MainListFilter";

const MainUserIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const componentType = 'mainUserTable';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [searchPhrase, setSearchPhrase] = useState<string>('');

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        if (mainCabinet?.currentCabinet && mainCabinet?.currentCabinet?.uuid) {
            let mainMeta: MainMetaType = {
                page: historyHash.page || 1,
                maxResults: historyHash.maxResults ? historyHash.maxResults : perPage,
                sortField: historyHash.sortField && historyHash.sortField,
                sortDirection: historyHash.sortDirection && historyHash.sortDirection,
                searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length > 0 && historyHash.searchPhrase,
                cabinetUuid: mainCabinet?.currentCabinet && mainCabinet?.currentCabinet?.uuid
            }

            dispatch(getMainUsers(mainMeta));
        }
    }

    useEffect(() => {
        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        loadItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        mainUser,
        mainCabinet,
        isFetched,
        currentPlan
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
        mainCabinet: state.MainCabinet,
        isFetched: state.MainUser.isFetched,
        currentPlan: state.SalePlan.currentPlan
    }));

    useEffect(()=>{
        dispatch(getMainRoles());
    },[dispatch]);

    useEffect(()=>{
        loadItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch,mainCabinet?.currentCabinet?.uuid]);

    useEffect(()=>{
        if (mainUser.isNeedReload) {
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mainUser.isNeedReload]);

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onEdit: (event)=>mainUserOpenModal(event,dispatch),
        onRemove: (event)=>mainUserOpenRemoveModal(event,dispatch)
    }

    const onTableRowClick = (item:MainUserType) => {
        if (item.actions && item.actions.canEdit){
            mainUserOpenModal(item, dispatch);
        }
    }

    const columns = MainUserColumns(EntityActionsHandlers);

    const handleSearchClick = (value:string) => {
        if ( historyHash.searchPhrase !== searchPhrase) {
            historyHash.searchPhrase = searchPhrase;
            setHistoryHashFunc(historyHash);
        }
    }

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }

    return (
        <>
            <BreadCrumb title={t('menu:users')} pageTitle={t('menu:main')} />
               <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary" onClick={(event)=>mainUserOpenModal(event,dispatch)} className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="border-bottom-0">
                                <h5 className="card-title mb-0">{t('user:users')}</h5>
                            </CardHeader>
                            <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                                <MainListFilter
                                    onClick={handleSearchClick}
                                    value={searchPhrase}
                                    onChange={onSearchInput}
                                />
                            </CardBody>
                            <CardBody>
                                {isFetched ?
                                    <TableList
                                        columns={columns}
                                        data={mainUser.items}
                                        onRowClick={onTableRowClick}
                                        key={componentType}
                                        historyHash = {historyHash}
                                        paginationTotalRows={mainUser?.meta?.totalCount || 0}
                                        paginationServer={true}
                                        isShowChangePageSize={true}
                                    />
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <RightModal/>
                <RemoveModal
                    onRemove={(event)=>mainUserOnRemove(event,dispatch, mainCabinet.currentCabinet)}
                    handleClose={()=>mainUserHideRemoveModal(dispatch)}
                    removeItem={mainUser.selectedItem}
                    isOpen={mainUser.removeModalShow}
                    modalTitle={mainUser.modalEditTitle}/>
        </>
    );
};

export default MainUserIndex;


