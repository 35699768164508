// types
import {PaymentStatus, PaymentType, SaleOrderPaymentActionTypes, SaleOrderPaymentState} from "./types";

export const NewSaleOrderPaymentItem:PaymentType = {
  enabled: true,
  status: PaymentStatus.NEW.status,
  paymentSystem: null
}

export const INIT_STATE_SALE_ORDER_PAYMENT: SaleOrderPaymentState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleOrderPaymentItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false,
  isNeedOrderReload: false
};

const SaleOrderPayment = (state = INIT_STATE_SALE_ORDER_PAYMENT, action: any) => {
  switch (action.type) {
    case SaleOrderPaymentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleOrderPaymentActionTypes.UPDATE_SALE_ORDER_PAYMENT:
          return {
            ...state,
            selectedItem: NewSaleOrderPaymentItem,
            rightModalShow: false,
            isSending: false,
            isNeedOrderReload: true,
          };
        case SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT:
          return {
            ...state,
            selectedItem: NewSaleOrderPaymentItem,
            removeModalShow: false,
            isNeedOrderReload: true,
            isSending: false
          };
        default:
          return { ...state };
      }

    case SaleOrderPaymentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleOrderPaymentActionTypes.UPDATE_SALE_ORDER_PAYMENT:
          return {
            ...state,
            isSending: false
          };
        case SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SaleOrderPaymentActionTypes.EDIT_SALE_ORDER_PAYMENT:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleOrderPaymentActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleOrderPaymentItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleOrderPaymentItem,
        removeModalShow: false
      }
    case SaleOrderPaymentActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleOrderPaymentActionTypes.UPDATE_SALE_ORDER_PAYMENT:
    case SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT:
      return {
        ...state,
        isSending: true
      };
    case SaleOrderPaymentActionTypes.CLEAR_NEED_ORDER_RELOAD:
      return {
        ...state,
        isNeedOrderReload: false
      }
    default:
      return { ...state };
  }
};

export default SaleOrderPayment;
