import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { EventSubscribeUserActionTypes } from "./types";
import { eventUserSubscriptionApiResponseSuccess, eventUserSubscriptionApiResponseError } from "./actions";

import {
  getEventUserSubscriptions as getEventUserSubscriptionsApi,
  updateEventUserSubscription as  updateEventUserSubscriptionApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {mainUserApiResponseError, mainUserApiResponseSuccess} from "../../Main/User/actions";
import {MainUserActionTypes} from "../../Main/User/types";

function* getEventUserSubscriptions() {
  try {
    const response: Promise<any> = yield call(getEventUserSubscriptionsApi);
    yield put(eventUserSubscriptionApiResponseSuccess(EventSubscribeUserActionTypes.GET_USER_EVENT_SUBSCRIPTIONS, response));
  } catch (error: any) {
    yield put(eventUserSubscriptionApiResponseError(EventSubscribeUserActionTypes.GET_USER_EVENT_SUBSCRIPTIONS, error));
  }
}

function* updateEventUserSubscription({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(updateEventUserSubscriptionApi, data);
    yield put(
        eventUserSubscriptionApiResponseSuccess(EventSubscribeUserActionTypes.UPDATE_USER_EVENT_SUBSCRIPTION, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(eventUserSubscriptionApiResponseError(EventSubscribeUserActionTypes.UPDATE_USER_EVENT_SUBSCRIPTION, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetEventUserSubscriptions() {
  yield takeEvery(EventSubscribeUserActionTypes.GET_USER_EVENT_SUBSCRIPTIONS, getEventUserSubscriptions);
}

export function* watchUpdateEventUserSubscription() {
  yield takeEvery(EventSubscribeUserActionTypes.UPDATE_USER_EVENT_SUBSCRIPTION, updateEventUserSubscription);
}

function* eventEventUserSubscriptionSaga() {
  yield all([
    fork(watchGetEventUserSubscriptions),
    fork(watchUpdateEventUserSubscription)
  ]);
}

export default eventEventUserSubscriptionSaga;
