import React from "react";
import { Link } from "react-router-dom";

// hooks
import { useRedux } from "../../../hooks";

// actions
import {
  getChannelDetails,
  getChatUserDetails,
  getChatUserConversations,
  changeSelectedChat,
} from "../../../redux/actions";

//i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MessengerChannelType, MessengerUserType} from "../../../redux/Messenger/Chat/types";

interface GroupProps {
  group: MessengerChannelType;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}

const Group = ({ group, onSelectChat }: GroupProps) => {

  return (
    <li>
      <Link to="#" onClick={() => onSelectChat(group.uuid, true)}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            <span className="avatar-title rounded-circle bg-soft-light text-dark">
              #
            </span>
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-truncate mb-0">{group.name}</p>
          </div>
        </div>
      </Link>
    </li>
  );
};
interface GroupsProps {
  chatUserDetails: MessengerUserType;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}
const Groups = ({ chatUserDetails, onSelectChat }: GroupsProps) => {
  const groups =
    chatUserDetails.messengerChannels &&
    chatUserDetails.messengerChannels.length &&
    chatUserDetails.messengerChannels;
  const { t } = useTranslation(['common']);

  return (
    <div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h5 className="font-size-11 text-muted text-uppercase">
            {t('common:groupInCommon')}
          </h5>
        </div>
      </div>

      {groups ? (
        <ul className="list-unstyled chat-list mx-n4">
          {(groups || []).map((group: MessengerChannelType, key: number) => (
            <Group group={group} key={key} onSelectChat={onSelectChat}/>
          ))}
        </ul>
      ) : (
        <p>{t('common:noGroups')}</p>
      )}
    </div>
  );
};

export default Groups;
