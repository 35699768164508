import React, {useState, useEffect, useCallback} from "react";
import {Card, CardBody, Progress, Row} from 'reactstrap';

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {MainUserType} from "../../../redux/Main/User/types";
import UserAvatar from "./userAvatar";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {convertToMomentLocale} from "../../../helpers/functions";

interface ItemProps {
    item: MainUserType;
    buttonTitle?: string;
    buttonIcon?: string;
    buttonClass?: string;
    onClick?: (item:MainUserType)=>void;
    isProcessing?: boolean
}

const ListItemV2 = ({ item, buttonTitle, buttonIcon, buttonClass = 'primary', onClick, isProcessing = false }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));

    return (
        <>
            <Card className={"card-height-100 card-animate profile-project-card profile-project-primary"}>
                <CardBody>
                <div className="d-flex flex-shrink-0 position-relative align-items-center">
                    <div className="avatar-sm me-3 d-flex flex-shrink-0 position-relative align-items-center">
                        <span className={"avatar-title rounded-circle"}>
                             <UserAvatar item={item} size={"sm"}/>
                        </span>
                    </div>
                    <div className="flex-1 justify-content-center flex-column d-flex mw-0">
                        <div className="text-truncate">{item?.name && item.name !== null && item.name.length>0 ? item.name : item.email}</div>
                        <small className="text-truncate text-muted">{item.email}</small>
                    </div>
                    {buttonTitle && buttonIcon && buttonClass && onClick &&
                    <div className="d-flex flex-shrink-0  align-items-center">
                            <button

                                onClick={()=>{onClick(item)}}
                                title={buttonTitle}
                                disabled={isProcessing}
                                className={"btn btn-icon waves-effect waves-light ms-2 btn-outline-"+buttonClass}>
                                {isProcessing ?
                                    <ButtonSpinner
                                        className={'ms-0'}
                                    />
                                    :
                                    <i className={buttonIcon}/>
                                }
                            </button>
                    </div>
                    }
                </div>
            </CardBody>
            </Card>
        </>
    );
};

export default ListItemV2;
