import React, {useEffect, useState} from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as route from '../../api/routes';

//SimpleBar
import SimpleBar from "simplebar-react";
import {useRedux} from "../../hooks";
import {EventNotificationType} from "../../redux/Event/Notification/types";
import ListItem from "../../blocks/Event/Notification/ListItem";

import '../../i18/config';
import {useTranslation} from "react-i18next";

const NotificationDropdown = () => {

    const maxTopNotifications = 10;

    const { t } = useTranslation(['common']);

    const { useAppSelector } = useRedux();

    const {
        eventNotification,
    } = useAppSelector(state => ({
        eventNotification: state.EventNotification,
    }));

    const [notifications, setNotifications] = useState<EventNotificationType[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);

    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    useEffect(()=>{

        if (eventNotification?.items && eventNotification.items.length>0){

            let notificationItems = eventNotification.items;

            if (notificationItems.length>maxTopNotifications) {
                notificationItems = notificationItems.slice(0,maxTopNotifications);
            }

            setNotifications([...notificationItems]);
        } else {
            setNotifications([]);
        }

    }, [eventNotification?.items]);

    useEffect(()=>{

        if (eventNotification?.unreadCount && eventNotification.unreadCount>0){
            setUnreadCount(eventNotification.unreadCount);
        } else {
            setUnreadCount(0);
        }

    }, [eventNotification?.unreadCount]);

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'/>
                    { unreadCount>0 &&
                        <span
                            className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{unreadCount}
                            <span className="visually-hidden">{t('notification:unreadNotificationsCount',{count: unreadCount})}</span>
                        </span>
                    }
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> {t('notification:notifications')} </h6>
                                </Col>
                                { unreadCount>0 &&
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge badge-soft-light fs-13"> {unreadCount} {t('notification:newNotificationsCount', {count: unreadCount})}</span>
                                </div>
                                }
                            </Row>
                        </div>

                        <div className="px-2 pt-2">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom overflow-hidden">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                        style={{cursor:'default'}}
                                    >
                                        {t('common:all')} { unreadCount>0 && <>({unreadCount})</>}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">

                                {(notifications || []).map((item:EventNotificationType, key: number)=>(
                                    <ListItem item={item} key={key}/>
                                ))}

                                <div className="my-3 text-center">
                                    <a href={route.EVENT_PERSONAL_NOTIFICATIONS} className="btn btn-soft-success waves-effect waves-light">{t('notification:viewAllNotifications')} <i className="ri-arrow-right-line align-middle"/></a>
                                </div>
                            </SimpleBar>

                        </TabPane>

                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;