import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {GET_SALE_PRODUCT_PARAMS} from "../urls";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSaleProductParams = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_PRODUCT_PARAMS+"?"+query);
};

const sendDataSaleProductParam = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_PRODUCT_PARAM, data);
};

const removeSaleProductParam = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_PRODUCT_PARAM, data);
};

export {
    getSaleProductParams,
    sendDataSaleProductParam,
    removeSaleProductParam
};
