import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const checkUrlShortener = (data:any) => {
    let requestUrl = m9sGlobals.CURRENT_CABINET+url.CHECK_URL_SHORTENER;
    return api.create(requestUrl, {urlShortener:data});
};

export {
    checkUrlShortener
};
