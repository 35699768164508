// types
import {MainCurrentCountryActionTypes, MainCurrentCountryState} from "./types";

export const INIT_STATE_MAIN_CURRENT_COUNTRY: MainCurrentCountryState = {
  isLoaded:false
};

const MainCountry = (state = INIT_STATE_MAIN_CURRENT_COUNTRY, action: any) => {
  switch (action.type) {
    case MainCurrentCountryActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MainCurrentCountryActionTypes.GET_CURRENT_COUNTRY:

          if (action.payload.data.country !== undefined && action.payload.data.country !== null){
            return {
              ...state,
              currentCountry: {code: action.payload.data.country.toLowerCase()},
              isLoaded: true,
            };
          }
          return {
            ...state,
          };
        default:
          return { ...state };
      }

    case MainCurrentCountryActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case MainCurrentCountryActionTypes.GET_CURRENT_COUNTRY:

          return {
            ...state,
            isLoaded: false,
          };
        default:
          return { ...state };
      }
    case MainCurrentCountryActionTypes.GET_CURRENT_COUNTRY:
      return {
        ...state,
        isLoaded: false,
      };
    default:
      return { ...state };
  }
};

export default MainCountry;
