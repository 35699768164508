import React, { useEffect, useState } from "react";
import {BrowserRouter, useParams} from 'react-router-dom'
import { Col, Row } from 'reactstrap';
import {useRedux} from "../../../hooks";

import * as route from "../../../api/routes";
import PeriodSelect from "../../../components/PeriodSelect";
import {getPeerActivityTypes} from "../../../redux/Content/PeerActivityType/actions";
import {getPeerActivityModes} from "../../../redux/Content/PeerActivityMode/actions";
import {getContentPeerChannels} from "../../../redux/Content/PeerChannel/actions";
import {
    emptyChannelsTiles,
    StatPeerChannelDataType,
    StatPeerChannelPayloadType,
    StatPeerChannelTileType
} from "../../../redux/Stat/PeerChannel/types";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";

import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {
    getStatPeerChannelActivities,
    getStatPeerChannelActivitiesData,
    getStatPeerChannelTargets,
    getStatPeerChannelTiles, setStatPeerChannelPeriod
} from "../../../redux/Stat/PeerChannel/actions";
import StatTile from "../../../components/StatTile";
import PeerTargetTableSmall from "../../../components/Dashboard/PeerTargetTableSmall";
import ChannelActivitiesChart from "../../../components/Dashboard/ChannelActivitiesChart";
import HeatmapWeeklyActivities from "../../../components/Dashboard/HeatmapWeeklyActivities";
import SchedulesCalendar from "./SchedulesCalendar";
import SchedulesModes from "../../../components/Dashboard/SchedulesModes";
import SchedulesTypes from "../../../components/Dashboard/SchedulesTypes";
import TableActivities from "../../../components/Dashboard/TableActivities";
import ListItem from "../../Content/PeerChannel/ListItem";
import {getContentPeerTargetTypes} from "../../../redux/Content/PeerTargetType/actions";
import {default as RightModalTarget} from "../../Content/PeerTarget/modalForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";
import BackButton from "../../../components/BackButton";
import {PeriodDurationType, PeriodType, StatProjectActivitiesType} from "../../../redux/Stat/DashboardContent/types";
import moment from "moment/moment";
import {setStatPeerActivityPeriod} from "../../../redux/Stat/PeerActivity/actions";


const StatPeerChannelIndex = () => {

    const { t } = useTranslation();
    const { uuidChannel } = useParams();

    const { dispatch, useAppSelector } = useRedux();
    const [peerChannel, setPeerChannel] = useState<ContentPeerChannelType>(null);
    const [tiles, setTiles] = useState<StatPeerChannelTileType[]>(emptyChannelsTiles);
    const [activitiesData, setActivitiesData] = useState<StatPeerChannelDataType[]>([]);
    const [activities, setActivities] = useState<PeerActivityType[]>([]);
    const [scheduleActivities, setScheduleActivities] = useState<PeerActivityType[]>([]);
    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ periodType, setPeriodType ] = useState<PeriodDurationType>(PeriodDurationType.WEEK);
    const [ isTargetLoaded, setTargetLoaded ] = useState<boolean>(false);

    const {
        statPeerChannel,
        contentPeerActivityModes,
        peerActivityTypes,
        contentPeerChannels,
        isFetched,
        isContentPeerChannelTargetFetched,
        periodFrom,
        periodTo
    } = useAppSelector(state => ({
        statPeerChannel: state.StatPeerChannelContent,
        contentPeerActivityModes: state.PeerActivityMode,
        peerActivityTypes: state.PeerActivityType,
        contentPeerChannels: state.ContentPeerChannel,
        isFetched: state.ContentPeerChannel.isFetched,
        isContentPeerChannelTargetFetched: state.StatPeerChannelContent.isContentPeerChannelTargetFetched,
        periodFrom: state.StatPeerChannelContent.periodFrom,
        periodTo: state.StatPeerChannelContent.periodTo
    }));

    useEffect(() => {
        dispatch(getPeerActivityTypes({maxResults:1000}));
        dispatch(getPeerActivityModes());
        dispatch(getContentPeerChannels());
        dispatch(getContentPeerTargetTypes());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if ((statPeerChannel.activities !== undefined) && (statPeerChannel.activities.length>0)){
            setActivities([...statPeerChannel.activities]);
        } else {
            setActivities([]);
        }

    }, [statPeerChannel?.activities]);

    useEffect(() => {

        if ((statPeerChannel.scheduleActivities !== undefined) && (statPeerChannel.scheduleActivities.length>0)){
            setScheduleActivities([...statPeerChannel.scheduleActivities]);
        } else {
            setScheduleActivities([]);
        }

    }, [statPeerChannel?.scheduleActivities]);


    useEffect(() => {
        setLoaded(isFetched);
    },[isFetched]);

    useEffect(() => {
        setTargetLoaded(isContentPeerChannelTargetFetched);
    },[isContentPeerChannelTargetFetched]);

    useEffect(() => {

        if ((statPeerChannel.tiles !== undefined) && (statPeerChannel.tiles.length>0)){
            setTiles([...statPeerChannel.tiles]);
        } else {
            setTiles(emptyChannelsTiles);
        }

    }, [statPeerChannel?.tiles]);

    useEffect(() => {

        if ((statPeerChannel.activitiesData !== undefined) && (statPeerChannel.activitiesData.length>0)){
            setActivitiesData([...statPeerChannel.activitiesData]);
        } else {
            setActivitiesData([]);
        }

    }, [statPeerChannel?.activitiesData]);

    useEffect(() => {
        if (uuidChannel && uuidChannel.length>0){
            (contentPeerChannels.items || []).map((channel:ContentPeerChannelType)=>{
                if (channel.uuid === uuidChannel)  {
                    setPeerChannel(channel);
                }
                return channel;
            });
        }

    }, [uuidChannel, contentPeerChannels?.items]);

    useEffect(() => {

        if (peerChannel && peerChannel.uuid){

            const statPeriodPayload:StatPeerChannelPayloadType = {
                project: peerChannel.project,
                channel: peerChannel,
                periodFrom : statPeerChannel.periodFrom,
                periodTo: statPeerChannel.periodTo
            }

            dispatch(getStatPeerChannelTargets(statPeriodPayload));
            dispatch(getStatPeerChannelTiles(statPeriodPayload));
            dispatch(getStatPeerChannelActivitiesData(statPeriodPayload));
            dispatch(getStatPeerChannelActivities(statPeriodPayload));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerChannel?.uuid, statPeerChannel?.periodFrom, statPeerChannel?.periodTo])

    const handleLastWeekPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'weeks').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setStatPeerChannelPeriod(periodData));
        setPeriodType(PeriodDurationType.WEEK);
    }

    const handleLastMonthPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'months').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setStatPeerChannelPeriod(periodData));
        setPeriodType(PeriodDurationType.MONTH);
    }


    return (
        <>
                <Row className="mb-3 pb-1">
                    <Col xl={2}>
                        <BackButton name={t('common:backNavigation')}/>
                    </Col>
                    <Col xl={4}>
                        {!isLoaded &&
                            <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center" >
                                <div className="d-flex justify-content-center align-items-center calendarLoader">
                                    <Loader addClassNames=""/>
                                </div>
                            </div>
                        }
                    </Col>
                    <Col xl={6}>
                        <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                            <div className="flex-grow-1"/>
                            <div className="mt-3 mt-lg-0">
                                <Row className="g-3 mb-0 align-items-center">
                                    <div className="col-sm-auto">
                                        {periodType === PeriodDurationType.DATE_RANGE ?
                                            <>
                                                <PeriodSelect
                                                    onDispatch={setStatPeerChannelPeriod}
                                                    periodFrom={periodFrom}
                                                    periodTo={periodTo}/>
                                            </>
                                            :
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className={"btn "+(periodType===PeriodDurationType.WEEK?'btn-primary':'btn-soft-primary') }onClick={handleLastWeekPeriod}>{t('common:week')}</button>
                                            <button type="button" className={"btn "+(periodType===PeriodDurationType.MONTH?'btn-primary':'btn-soft-primary')} onClick={handleLastMonthPeriod}>{t('common:month')}</button>
                                            <button type="button" className="btn btn-soft-primary" onClick={()=>{setPeriodType(PeriodDurationType.DATE_RANGE)}}>
                                                <i className="ri-calendar-2-line"/>
                                            </button>
                                        </div>
                                        }
                                    </div>
{/*                                    <div className="col-sm-auto">*/}
{/*<PeriodSelect onDispatch={setStatPeerChannelPeriod}/>*/}
{/*                                    </div>*/}
                                    <div className="col-auto">
                                        <a href={route.CONTENT_ACTIVITY_PLAN} className="btn btn-primary"><i className="ri-pages-line align-middle me-1"/> {t('peerActivity:activityPlan')}</a>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                {peerChannel &&
                    <>
                        <Row>
                            <h5>{t('channel:channelStat')}: {peerChannel.name}</h5>
                        </Row>
                        <Row>
                            <Col xl={8}>
                                <Row>
                                    {(tiles || []).map((tile: StatPeerChannelTileType, key:number)=>(
                                            <Col md={3} key = {'projectTile_'+tile.label+'_'+key.toString()}>
                                                <StatTile
                                                    label={tile.label}
                                                    start={0}
                                                    first={tile.value.first && tile.value.first}
                                                    last={tile.value.last}
                                                    isLoading={!isLoaded}
                                                    deltaLabel={moment(statPeerChannel.periodTo,'X').diff(moment(statPeerChannel.periodFrom,'X'),'days').toString()}
                                                />
                                            </Col>
                                    ))}
                                </Row>
                            </Col>
                            <Col xl={4}>
                                <Row>
                                    <Col>
                                        <ListItem item={peerChannel}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        { isTargetLoaded ?
                                            <PeerTargetTableSmall items={statPeerChannel.targets}/>
                                            :
                                            <Loader/>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6}>
                                <ChannelActivitiesChart items={activitiesData}/>
                            </Col>
                            <Col xl={6}>
                                <HeatmapWeeklyActivities items={activities} color={peerChannel.project.color}/>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={4}>
                                <SchedulesCalendar items={scheduleActivities} channel={peerChannel}/>
                            </Col>
                            <Col xl={4}>
                                <SchedulesModes items={activities} modes={contentPeerActivityModes.items || []}/>
                            </Col>
                            <Col xl={4}>
                                <SchedulesTypes items={activities} types={peerActivityTypes.items || []}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TableActivities items={activities}/>
                            </Col>
                        </Row>
                    </>
                }
            <RightModalTarget/>
        </>
    );
};

export default StatPeerChannelIndex;


