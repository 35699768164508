import React, {useEffect, useRef, useState} from "react";

import { Button, Form, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
// hooks
import { useRedux } from "../../../hooks";

// actions
import {
  // inviteContact,
  resetContacts,
  // getFavourites,
  // getDirectMessages,
  getChannels,
  // addContacts,
  // createChannel,
  changeSelectedChat,
  getChatUserDetails,
  getChatUserConversations,
  getChannelDetails,
  getArchiveContact,
  readConversation, getCommentsChannels, getArchiveComments,
} from "../../../redux/actions";

import {CHATS_TABS} from "../../../components/Constants";


// interfaces
import { CreateChannelPostData } from "../../../redux/actions";

// components
import AppSimpleBar from "../../../components/AppSimpleBar";
//import AddGroupModal from "../../../components/AddGroupModal";
// import InviteContactModal from "../../../components/InviteContactModal";
//import AddButton from "../../../components/AddButton";
//import ContactModal from "../../../components/ContactModal";

//import Favourites from "./Favourites";
//import DirectMessages from "./DirectMessages";
import Channels from "./Channels";
import Archive from "./Archive";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MessengerChannelType} from "../../../redux/Messenger/Chat/types";
import useScroll from "../../../hooks/useScroll";

interface IndexProps {
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}

const CommentsIndex = ({onSelectChat}: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const { t } = useTranslation(['chats','contacts']);

  const parentRef = useRef(null);
  const childRef = useRef(null);

  const {
    isContactInvited,
    // favourites,
    // directMessages,
    channels,
    // isContactsAdded,
    // isChannelCreated,
    selectedChat,
    // isFavouriteContactToggled,
    archiveContacts,
    isContactArchiveToggled,
    chatUserDetails,
    isChannelsFetched,
    channelsMeta,
    isArchiveContactFetched,
    archiveContactsMeta
  } = useAppSelector(state => ({
    isContactInvited: state.MessengerContact.isContactInvited,
    // favourites: state.Chats.favourites,
    // directMessages: state.Chats.directMessages,
    channels: state.MessengerComment.channels,
    // isContactsAdded: state.Chats.isContactsAdded,
    // isChannelCreated: state.Chats.isChannelCreated,
    selectedChat: state.MessengerComment.selectedChat,
    // isFavouriteContactToggled: state.Chats.isFavouriteContactToggled,
    archiveContacts: state.MessengerComment.archiveContacts,
    isContactArchiveToggled: state.MessengerComment.isContactArchiveToggled,
    chatUserDetails: state.MessengerComment.chatUserDetails,
    isChannelsFetched: state.MessengerChat.isChannelsFetched,
    channelsMeta: state.MessengerChat.channelsMeta,
    isArchiveContactFetched: state.MessengerChat.isArchiveContactFetched,
    archiveContactsMeta: state.MessengerChat.archiveContactsMeta,
  }));

  const [channelSearch, setChannelSearch] = useState<string>('');

  const handleChannelSearch = (text:string) => {
      setChannelSearch(text);
  }
  /*
  get data
  */
  useEffect(() => {
    // dispatch(getFavourites());
    // dispatch(getDirectMessages());
    dispatch(getCommentsChannels({page:1}));
  }, [dispatch]);
  // useEffect(() => {
  //   if (isFavouriteContactToggled) {
  //     dispatch(getFavourites());
  //     dispatch(getDirectMessages());
  //   }
  // }, [dispatch, isFavouriteContactToggled]);

  /*
  invite contact modal handeling
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /*
  onInvite handeling
  */
  // const onInviteContact = (data: any) => {
  //   dispatch(inviteContact(data));
  // };
  // useEffect(() => {
  //   if (isContactInvited) {
  //     setIsOpen(false);
  //     setTimeout(() => {
  //       dispatch(resetContacts("isContactInvited", false));
  //     }, 1000);
  //   }
  // }, [dispatch, isContactInvited]);

  /*
  contact add handeling
  */
  // const [isOpenAddContact, setIsOpenAddContact] = useState<boolean>(false);
  // const openAddContactModal = () => {
  //   setIsOpenAddContact(true);
  // };
  // const closeAddContactModal = () => {
  //   setIsOpenAddContact(false);
  // };
  // const onAddContact = (contacts: Array<string | number>) => {
  //   dispatch(addContacts(contacts));
  // };
  // useEffect(() => {
  //   if (isContactsAdded) {
  //     setIsOpenAddContact(false);
  //     dispatch(getDirectMessages());
  //   }
  // }, [dispatch, isContactsAdded]);

  /*
  channel creation handeling
  */
  // const [isOpenCreateChannel, setIsOpenCreateChannel] =
  //   useState<boolean>(false);
  // const openCreateChannelModal = () => {
  //   setIsOpenCreateChannel(true);
  // };
  // const closeCreateChannelModal = () => {
  //   setIsOpenCreateChannel(false);
  // };
  // const onCreateChannel = (channelData: CreateChannelPostData) => {
  //   dispatch(createChannel(channelData));
  // };
  // useEffect(() => {
  //   if (isChannelCreated) {
  //     setIsOpenCreateChannel(false);
  //     dispatch(getChannels());
  //   }
  // }, [dispatch, isChannelCreated]);

  /*
  select chat handeling :
    get conversations
    get chat user details
  */

  /*
  tab handeling
  */
  const [active, setActive] = useState(CHATS_TABS.DEFAULT);
  const onChangeTab = (tab: CHATS_TABS) => {
    setActive(tab);
  };

  /*
  archive contacts
  */
  useEffect(() => {
    dispatch(getArchiveComments({page:1}));
  }, [dispatch]);
  useEffect(() => {
    if (isContactArchiveToggled) {
      dispatch(getArchiveComments({page:1}));
//      dispatch(getFavourites());
//      dispatch(getDirectMessages());
      dispatch(getCommentsChannels({page:1}));
      dispatch(getChatUserDetails(chatUserDetails.uuid));
    }
  }, [dispatch, isContactArchiveToggled, chatUserDetails.uuid]);

  const loaderIntersectHandler = () => {

    if (active !== CHATS_TABS.ARCHIVE){
      if (isChannelsFetched){
        if (channelsMeta?.totalCount > 0 && channels.length>0 && channels.length<channelsMeta?.totalCount){
          dispatch(getChannels({page: channelsMeta?.page && channelsMeta?.page>0 ? channelsMeta?.page+1 : 1}));
        }
      }
    } else {
      if (isArchiveContactFetched){
        if (archiveContactsMeta?.totalCount > 0 && archiveContacts.length>0 && archiveContacts.length<archiveContactsMeta?.totalCount){
          dispatch(getArchiveContact({page: archiveContactsMeta?.page && archiveContactsMeta?.page>0 ? archiveContactsMeta?.page+1 : 1}));
        }
      }
    }
  }

  const loaderIntersect = useScroll(parentRef, childRef, loaderIntersectHandler);

  return (
    <>
      <div>
        <div className="px-4 pt-4 topChannelMenuTitle">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h5 className="mb-4">{t('messengerComment:title')}</h5>
            </div>
            {/*<div className="flex-shrink-0">*/}
            {/*  <div id="add-contact">*/}
            {/*    /!* Button trigger modal *!/*/}
            {/*    <AddButton onClick={openModal} />*/}
            {/*  </div>*/}
            {/*  <UncontrolledTooltip target="add-contact" placement="bottom">*/}
            {/*    Add Contact*/}
            {/*  </UncontrolledTooltip>*/}
            {/*</div>*/}
          </div>
            <div className="search-box">
              <input
                  id="search-user"
                  type="text"
                  className="form-control bg-light border-light"
                  placeholder={t('search:search')}
                  value={channelSearch}
                  onChange={(e: any) => {
                    handleChannelSearch(e.target.value);
                  }}
              />
              <i className="ri-search-2-line search-icon"/>
            </div>
        </div>{" "}
        {/* .p-4 */}
        <AppSimpleBar className="chat-room-list">
          {/* Start chat-message-list */}
          {active === CHATS_TABS.DEFAULT && (
            <>
              {/* favourite */}
              {/*<Favourites*/}
              {/*  users={favourites}*/}
              {/*  selectedChat={selectedChat}*/}
              {/*  onSelectChat={onSelectChat}*/}
              {/*/>*/}

              {/* direct messages */}
              {/*<DirectMessages*/}
              {/*  users={directMessages}*/}
              {/*  openAddContact={openAddContactModal}*/}
              {/*  selectedChat={selectedChat}*/}
              {/*  onSelectChat={onSelectChat}*/}
              {/*/>*/}

              {/* channels list */}
              <Channels
                channels={channels.filter((item:MessengerChannelType)=>{
                  return item.name.toLowerCase().includes(channelSearch.toLowerCase());
                })}
                // openCreateChannel={openCreateChannelModal}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />

              <div className="text-center  mb-2 mt-2">
                <Link
                  to="#"
                  className="text-secondary"
                  onClick={() => onChangeTab(CHATS_TABS.ARCHIVE)}
                >
                  <i className="ri-inbox-archive-line align-bottom me-2"/>{" "}
                  {t('messengerComment:archivedComments')}
                </Link>
              </div>
            </>
          )}
          {active === CHATS_TABS.ARCHIVE && (
            <>
              <Archive
                archiveContacts={archiveContacts}
                selectedChat={selectedChat}
                onSelectChat={onSelectChat}
              />
              <div className="text-center mb-2 mt-2">
                <Link
                  to="#"
                  className="text-secondary"
                  onClick={() => onChangeTab(CHATS_TABS.DEFAULT)}
                >
                  <i className="ri-inbox-unarchive-line align-bottom me-2"/>{" "}
                  {t('messengerComment:title')}
                </Link>
              </div>
            </>
          )}

          {/* End chat-message-list */}
        </AppSimpleBar>
      </div>
      {/* add group Modal */}
      {/*{isOpenCreateChannel && (*/}
      {/*  <AddGroupModal*/}
      {/*    isOpen={isOpenCreateChannel}*/}
      {/*    onClose={closeCreateChannelModal}*/}
      {/*    onCreateChannel={onCreateChannel}*/}
      {/*  />*/}
      {/*)}*/}

      {/* add contact modal */}
      {/*{isOpen && (*/}
      {/*  <InviteContactModal*/}
      {/*    isOpen={isOpen}*/}
      {/*    onClose={closeModal}*/}
      {/*    onInvite={onInviteContact}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{isOpenAddContact && (*/}
      {/*  <ContactModal*/}
      {/*    isOpen={isOpenAddContact}*/}
      {/*    onClose={closeAddContactModal}*/}
      {/*    onAddContact={onAddContact}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default CommentsIndex;