// types
import {SaleStoreActionTypes, SaleStoreState, SaleStoreType} from "./types";

export const NewSaleStoreItem:SaleStoreType = {
  enabled: true,
  name: '',
  order: 500
}

export const INIT_STATE_SALE_STORE: SaleStoreState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleStoreItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const SaleStore = (state = INIT_STATE_SALE_STORE, action: any) => {
  switch (action.type) {
    case SaleStoreActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleStoreActionTypes.GET_SALE_STORES:
          return {
            ...state,
            items: action.payload.data.items,
            meta:  action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleStoreActionTypes.UPDATE_SALE_STORE:
          return {
            ...state,
            selectedItem: NewSaleStoreItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SaleStoreActionTypes.REMOVE_SALE_STORE:
          return {
            ...state,
            selectedItem: NewSaleStoreItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case SaleStoreActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleStoreActionTypes.GET_SALE_STORES:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SaleStoreActionTypes.UPDATE_SALE_STORE:
          return {
            ...state,
            isSending: false
          };
        case SaleStoreActionTypes.REMOVE_SALE_STORE:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SaleStoreActionTypes.EDIT_SALE_STORE:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleStoreActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleStoreItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleStoreActionTypes.REMOVE_SALE_STORE_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleStoreActionTypes.REMOVE_SALE_STORE_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleStoreItem,
        removeModalShow: false
      }
    case SaleStoreActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleStoreActionTypes.UPDATE_SALE_STORE:
      return {
        ...state,
        isSending: true
      };
    case SaleStoreActionTypes.GET_SALE_STORES:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default SaleStore;
