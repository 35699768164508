import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import {useRedux} from "../hooks";
import {ModalBody} from 'reactstrap';
import {hideUpgradeModal} from "../redux/Main/UpgradeFare/actions";
import UpgradeFare from "./UpgradeFare";
import LeftbarTitle from "./LeftbarTitle";

import '../i18/config';
import {useTranslation} from "react-i18next";

const UpgradeFareModal = () => {

    const { dispatch, useAppSelector } = useRedux();
    const { t } = useTranslation(['common']);
    const upgradeFareModal = useAppSelector(state => state.UpgradeModal);
    const [ isShowModal, setShowModal ] = useState<boolean>(upgradeFareModal.modalShow);

    useEffect(() => {
        setShowModal(upgradeFareModal.modalShow);
    }, [upgradeFareModal.modalShow]);


    const handleClose = () => dispatch(hideUpgradeModal());

    return (
        <>
            <Modal show={isShowModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <LeftbarTitle title={ t('plan:planUpgrade') }/>
                </Modal.Header>

                <ModalBody>
                    <UpgradeFare/>
                </ModalBody>
            </Modal>
        </>
    );
}

export default UpgradeFareModal;