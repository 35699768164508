import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";
import {OrderType} from "../Order/types";

export enum SaleOrderStatusActionTypes {
  API_RESPONSE_SUCCESS = "@@saleOrderStatus/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@saleOrderStatus/API_RESPONSE_ERROR",
  GET_SALE_ORDER_STATUSES = "@@saleOrderStatus/GET_SALE_ORDER_STATUSES",
  UPDATE_SALE_ORDER_STATUS = "@@saleOrderStatus/UPDATE_SALE_ORDER_STATUS",
  REMOVE_SALE_ORDER_STATUS_MODAL_SHOW = "@@saleOrderStatus/REMOVE_SALE_ORDER_STATUS_MODAL_SHOW",
  REMOVE_SALE_ORDER_STATUS_MODAL_HIDE = "@@saleOrderStatus/REMOVE_SALE_ORDER_STATUS_MODAL_HIDE",
  REMOVE_SALE_ORDER_STATUS = "@@saleOrderStatus/REMOVE_SALE_ORDER_STATUS",
  EDIT_SALE_ORDER_STATUS = "@@saleOrderStatus/EDIT_SALE_ORDER_STATUS",
  RIGHT_MODAL_SHOW = "@@saleOrderStatus/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@saleOrderStatus/RIGHT_MODAL_HIDE",
}

export interface SaleOrderStatusState extends MainMetaType{
  items: Array<SaleOrderStatusType>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: SaleOrderStatusType;
  modalEditTitle: string;
  isSending: boolean;
  isFetched: boolean;
  isLoading: boolean;
  isNeedReload: boolean;
}

export interface SaleOrderStatusType{
  uuid?: string,
  enabled?: boolean,
  name?: string,
  description?: string,
  isNew?: boolean;
  isSuccess?: boolean;
  isFailure?: boolean;
  idRemote?: string,
  isNotifyCustomer? : boolean;
  order?: number,
  actions?: EntityActionsType
}

export interface SaleOrderStatusPayload {
  data: Array<any>;
  modalEditTitle : string;
}

export enum OrderStatusTypes {
    ALL =  'all',
    SUCCESS =  'success',
    NEW =  'new',
    FAILURE =  'failure',
}

export interface SaleOrderStatusItemType{
  status?:SaleOrderStatusType,
  saleOrder?: OrderType;
  isPaid?: boolean;
}