import React, {useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {SalePlanParamValueType} from "../../../redux/Sale/Plan/types";
import {Progress} from "reactstrap";
import {formatBytes} from "../../../helpers/functions";
import {LicenseProductParam} from "../../../redux/Sale/License/types";

interface ItemProps {
    item: SalePlanParamValueType;
}
const ListItem = ({ item }: ItemProps) => {

    const { t } = useTranslation(['plan']);

    let progressBarTemplate = 'bg-success';

    if (item.percent !== undefined){

        if (item.percent>100){
            item.percent=100;
        }

        if (item.percent>80) {
            progressBarTemplate = 'bg-danger';
        } else if (item.percent>60) {
            progressBarTemplate = 'bg-warning';
        }
    }

    const FormatValue = (value:number) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            notation: 'compact',
        }).format(value);



    return (
        <div className="text-reset notification-item d-block dropdown-item position-relative">
            <div className="d-flex">
                <div className="flex-1">
                    <a href={(item.isPromo === true)? undefined:"#"} target="_blank" rel="noreferrer">
                        <h6 className="mt-0 mb-2 lh-base">
                            {t('plan:'+item.productParam.code)}
                        </h6>
                    </a>
                </div>
                {item.percent !== undefined &&
                <div className="px-2 fs-15">
                    {item.count !== undefined &&
                    <span className="mb-0 fs-12 text-muted">{
                        item.productParam.code === LicenseProductParam.MAIN_STORAGE ?
                            formatBytes(item.count)
                            :
                            FormatValue(item.count)
                        } / </span>
                    }
                    {item.limit !== undefined &&
                    <span className="mb-0 fs-12 text-muted">{
                        item.productParam.code === LicenseProductParam.MAIN_STORAGE ?
                            formatBytes(item.limit)
                            :
                            FormatValue(item.limit)}</span>
                    }
                    <span className={"badge ms-2 "+progressBarTemplate}>{item.percent}%</span>
                </div>
                }
                {item.isPromo === true &&
                    <div className="px-2 fs-15 mb-2">
                        <a href="#" type="button" target="_blank"
                           rel="noreferrer"
                           className="btn btn-success btn-sm">{t('plan:activate')}</a>
                    </div>
                }
            </div>

            {item.percent !== undefined &&
                <Progress
                    className={"d-flex flex-grow-1 flex-row w-100 "}
                    value={Math.ceil(item.percent)}
                    barClassName={progressBarTemplate}
                    striped={false}
                    animated={true}
                />
            }

        </div>
    );
};

export default ListItem;
