import {ContentPeerUserType} from "../../Content/PeerUser/types";
import {DeliverySystemType} from "../DeliverySystem/types";
import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum SaleCustomerActionTypes {
    API_RESPONSE_SUCCESS = "@@saleCustomer/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleCustomer/API_RESPONSE_ERROR",
    GET_SALE_CUSTOMERS = "@@saleCustomer/GET_SALE_CUSTOMERS",
    UPDATE_SALE_CUSTOMER = "@@saleCustomer/UPDATE_SALE_CUSTOMER",
    REMOVE_SALE_CUSTOMER_MODAL_SHOW = "@@saleCustomer/REMOVE_SALE_CUSTOMER_MODAL_SHOW",
    REMOVE_SALE_CUSTOMER_MODAL_HIDE = "@@saleCustomer/REMOVE_SALE_CUSTOMER_MODAL_HIDE",
    REMOVE_SALE_CUSTOMER = "@@saleCustomer/REMOVE_SALE_CUSTOMER",
    EDIT_SALE_CUSTOMER = "@@saleCustomer/EDIT_SALE_CUSTOMER",
    RIGHT_MODAL_SHOW = "@@saleCustomer/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleCustomer/RIGHT_MODAL_HIDE",
}

export interface SaleCustomerState extends MainMetaType{
    items: Array<CustomerType>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: CustomerType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface CustomerType {
    uuid?: string;
    enabled?: boolean;
    name?: string;
    phone?: string;
    email?: string;
    tin?: string;
    description?: string;
    createdAt?: string;
    updatedAt?: string;
    peerUsers?: Array<ContentPeerUserType>
    order?: number;
    actions?: EntityActionsType
}

export interface SaleCustomerPayload {
    data: Array<any>;
    modalEditTitle : string;
}
