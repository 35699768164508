import React, { useState, useEffect } from 'react';
import {useRedux} from "../../../hooks";
import {
    Col, Label, Row, Spinner,
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {SupportTicketMessageType} from "../../../redux/Support/TicketMessage/types";
import {SupportTicketMessageValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";
import InputFile from "../../../components/InputFile";
import {MainFileType} from "../../../redux/Main/File/types";
import {
    getSupportServiceTicketMessages,
    getSupportTicketMessages, onSendDataSupportServiceTicketMessage,
    onSendDataSupportTicketMessage,
} from "../../../redux/Support/TicketMessage/actions";
import {TicketStatus} from "../../../redux/Support/Ticket/types";
import {getSupportServiceTicketDetail, getSupportTicketDetail} from "../../../redux/Support/Ticket/actions";

interface InputProps {
    isSupportService:boolean
}

const TicketMessageForm = ({isSupportService}:InputProps) => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();

    const {
        supportTicket,
        supportTicketMessage,
        isSending
    } = useAppSelector(state => ({
        supportTicket: state.SupportTicket,
        supportTicketMessage: state.SupportTicketMessage,
        isSending: state.SupportTicketMessage.isSending
    }));

    const uuid = supportTicketMessage.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<SupportTicketMessageType>(supportTicketMessage.selectedItem);
    const [isShowAttachForm, setShowAttachForm] = useState<boolean>(false);

    useEffect(() => {
        setFormValues(supportTicketMessage.selectedItem);
        setShowAttachForm(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, dispatch, supportTicketMessage?.selectedItem]);

    const handleShowAttachForm = () => {
        setShowAttachForm(true);
    }

    const onDataSubmit = (formData:SupportTicketMessageType) => {
        let attachmentFiles=[];

        let params: SupportTicketMessageType = {
            uuid: formData.uuid && formData.uuid,
            text: formData.text && formData.text,
            media: formData.media && formData.media,
            ticket: supportTicket?.selectedItem?.uuid && supportTicket.selectedItem
        };

        (formData.media|| []).map((fileItem:MainFileType)=>{
            if (fileItem.uuid === undefined){
                attachmentFiles.push(fileItem);
            }
            return fileItem;
        });

        if (attachmentFiles.length>0){
            params['attachmentFiles']=attachmentFiles;
        }

        if (isSupportService) {
            dispatch(onSendDataSupportServiceTicketMessage(params));
        } else {
            dispatch(onSendDataSupportTicketMessage(params));
        }
        if (supportTicket.selectedItem.status===TicketStatus.FINAL.status){
            if (isSupportService) {
                setTimeout(() => {
                    dispatch(getSupportServiceTicketDetail({uuid: supportTicket.selectedItem.uuid}));
                }, 1000);
            } else {
                setTimeout(() => {
                    dispatch(getSupportTicketDetail({uuid: supportTicket.selectedItem.uuid}));
                }, 1000);
            }
        } else {
            if (isSupportService) {
                setTimeout(() => {
                    dispatch(getSupportServiceTicketMessages(supportTicket.selectedItem));
                }, 1000);
            } else {
                setTimeout(() => {
                    dispatch(getSupportTicketMessages(supportTicket.selectedItem));
                }, 1000);
            }
        }
    };

    return (
        <>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SupportTicketMessageValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                                <div className="p-1">
                            <Row>
                                <div className={ "mb-3"+ (touched.text ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-text-input">{t('support:yourMessage')}</Label>
                                    <div className="textAreaEmojiBlock">
                                        <Field
                                            id="form-text-input"
                                            name="text"
                                            component={InputEmojiArea}
                                            value = {getFieldProps('text').value}
                                            onChange={(text:string) => {
                                                setFieldValue('text', text);
                                            }}
                                            required={true}
                                            placeholder={t('support:textHere')}
                                        />
                                        {errors.text && touched.text ? (
                                            <div className="invalid-feedback">
                                                {t('validator:'+errors.text)}
                                            </div>
                                        ) :  touched.text && !errors.text ?
                                            <div className="valid-feedback">
                                                {t('common:looksGood')}
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </Row>
                                    {isShowAttachForm &&
                                    <Row>
                                        <div className="mb-3">
                                            <Field
                                                id={"form-attachmentFiles-input"}
                                                name={'media'}
                                                placeholder={t('support:filePlaceholder')}
                                                isMultiple={true}
                                                component={InputFile}
                                                value={getFieldProps('media').value}
                                                onChange={(value: MainFileType[]) => {
                                                    setFieldValue('media', value);
                                                }}
                                                required={false}
                                            />
                                        </div>
                                    </Row>
                                    }

                        <Row>
                            <Col xs={12} className="text-end">
                                {!isShowAttachForm &&
                                <button type="button"
                                        onClick={handleShowAttachForm}
                                        className="btn btn-ghost-secondary btn-icon waves-effect me-1"><i className="ri-attachment-line fs-16"></i></button>
                                }
                                <button type="submit"
                                        disabled={isSending}
                                        className="btn btn-success btn-label waves-effect waves-light">
                                    {isSending ?
                                        <ButtonSpinner className={'ms-0'}/>
                                        :
                                        <i
                                            className="ri-mail-send-line label-icon align-middle fs-16 me-2"/>
                                    }
                                    {t('support:sendMessage')}
                                </button>
                            </Col>
                        </Row>
                                </div>
                    </Form>
                        )}
                </Formik>
        </>
    );
}

export default TicketMessageForm;