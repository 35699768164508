import React, {useEffect, useState} from "react";

import { Input } from "reactstrap";

interface InputTextProps {
  value: null | string;
  id: string;
  placeholder: null | string;
  onChange: (value: string) => void;
  required: false | boolean;
  maxLength: null | number;
  className: null | string;
  autoFocus: false | boolean;
  addon: string;
}
const InputTextAddon = ({ id, placeholder, value, onChange, required, maxLength, className, autoFocus, addon}: InputTextProps) => {

    const [text, setText] = useState<string>(value || '');

    useEffect(() => {
        setText(value);
    }, [value]);

    const onChangeText = (text:string) => {
        setText(text);
        onChange(text);
    }

  return (
      <div className="input-group">
          <span className="input-group-text" id="product-price-addon">{addon}</span>
        <Input
        type="text"
        className={className || "form-control"}
        id={ id }
        placeholder={placeholder || ""}
        value={text || ''}
        onChange={(e: any) => {
            onChangeText(e.target.value);
        }}
        maxLength={maxLength || null}
        autoComplete="off"
        required={ required }
        autoFocus={autoFocus}
      />
      </div>
  );
};
export default InputTextAddon;
