import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { MainUserActionTypes } from "./types";
import {
  mainUserApiResponseSuccess,
  mainUserApiResponseError,
  onChangeEmailMainUser,
  getCabinetContacts
} from "./actions";

import {
  getMainUsers as getMainUsersApi,
  removeMainUser,
  getMainCurrentUser as getMainCurrentUserApi,
  getMainCurrentUserProfile as getMainCurrentUserProfileApi,
  sendDataMainUser,
  sendAvatarMainUser,
  onSignUpUserSend,
  onResetUserSend,
  onChangePasswordUserSend,
  onResendConfirmationEmail as onResendConfirmationEmailApi,
  onChangeUserLanguage as onChangeUserLanguageApi, onChangeEmailUserSend,
  getMainContacts as getMainContactsApi,
  getCabinetContacts as getCabinetContactsApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getMainUsers({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getMainUsersApi, data);
    yield put(mainUserApiResponseSuccess(MainUserActionTypes.GET_MAIN_USERS, response));
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.GET_MAIN_USERS, error));
  }
}

function* getMainCurrentUser() {
  try {
    const response: Promise<any> = yield call(getMainCurrentUserApi);
    yield put(mainUserApiResponseSuccess(MainUserActionTypes.GET_MAIN_CURRENT_USER, response));
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.GET_MAIN_CURRENT_USER, error));
  }
}

function* getMainCurrentUserProfile() {
  try {
    const response: Promise<any> = yield call(getMainCurrentUserProfileApi);
    yield put(mainUserApiResponseSuccess(MainUserActionTypes.GET_MAIN_CURRENT_USER_PROFILE, response));
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.GET_MAIN_CURRENT_USER_PROFILE, error));
  }
}



function* onSendDataMainUser({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataMainUser, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.UPDATE_MAIN_USER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.UPDATE_MAIN_USER, error));
    yield call(showErrorNotification, error);
  }
}

function* onSendAvatarMainUser({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendAvatarMainUser, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.UPDATE_MAIN_USER_AVATAR, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.UPDATE_MAIN_USER_AVATAR, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveMainUser({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeMainUser, {uid: data.data.uuid, cabinet: data.cabinet});
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.REMOVE_MAIN_USER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.REMOVE_MAIN_USER, error));
    yield call(showErrorNotification, error);
  }
}

function* onSendSignUpUser({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(onSignUpUserSend, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.SIGNUP_USER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.SIGNUP_USER, error));
    yield call(showErrorNotification, error);
  }
}

function* onSendResetUser({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(onResetUserSend, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.RESET_USER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.RESET_USER, error));
    yield call(showErrorNotification, error);
  }
}

function* onSendChangePasswordUser({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(onChangePasswordUserSend, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.CHANGE_PASSWORD_USER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.CHANGE_PASSWORD_USER, error));
    yield call(showErrorNotification, error);
  }
}

function* onSendChangeEmailUser({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(onChangeEmailUserSend, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.CHANGE_EMAIL_USER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.CHANGE_EMAIL_USER, error));
    yield call(showErrorNotification, error);
  }
}

function* onResendConfirmationEmail() {
  try {
    const response: Promise<any> = yield call(onResendConfirmationEmailApi);
    yield put(mainUserApiResponseSuccess(MainUserActionTypes.SIGNUP_RESEND_CONFIRMATION_EMAIL, response));
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.SIGNUP_RESEND_CONFIRMATION_EMAIL, error));
  }
}

function* onSendChangeUserLanguage({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(onChangeUserLanguageApi, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.SEND_SET_USER_LANGUAGE, response)
    );
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.SEND_SET_USER_LANGUAGE, error));
  }
}

function* onGetMainContacts({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getMainContactsApi, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.GET_MAIN_CONTACTS, response)
    );
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.GET_MAIN_CONTACTS, error));
  }
}

function* onGetCabinetContacts({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getCabinetContactsApi, data);
    yield put(
        mainUserApiResponseSuccess(MainUserActionTypes.GET_CABINET_CONTACTS, response)
    );
  } catch (error: any) {
    yield put(mainUserApiResponseError(MainUserActionTypes.GET_CABINET_CONTACTS, error));
  }
}

export function* watchOnSendSignUpUser() {
  yield takeEvery(MainUserActionTypes.SIGNUP_USER, onSendSignUpUser);
}
export function* watchOnGetMainContacts() {
  yield takeEvery(MainUserActionTypes.GET_MAIN_CONTACTS, onGetMainContacts);
}
export function* watchOnGetCabinetContacts() {
  yield takeEvery(MainUserActionTypes.GET_CABINET_CONTACTS, onGetCabinetContacts);
}
export function* watchOnSendResetUser() {
  yield takeEvery(MainUserActionTypes.RESET_USER, onSendResetUser);
}

export function* watchOnSendChangePasswordUser() {
  yield takeEvery(MainUserActionTypes.CHANGE_PASSWORD_USER, onSendChangePasswordUser);
}

export function* watchOnSendChangeEmailUser() {
  yield takeEvery(MainUserActionTypes.CHANGE_EMAIL_USER, onSendChangeEmailUser);
}

export function* watchGetMainUsers() {
  yield takeEvery(MainUserActionTypes.GET_MAIN_USERS, getMainUsers);
}
export function* watchGetMainCurrentUser() {
  yield takeEvery(MainUserActionTypes.GET_MAIN_CURRENT_USER, getMainCurrentUser);
}
export function* watchGetMainCurrentUserProfile() {
  yield takeEvery(MainUserActionTypes.GET_MAIN_CURRENT_USER_PROFILE, getMainCurrentUserProfile);
}

export function* watchOnSendDataMainUser() {
  yield takeEvery(MainUserActionTypes.UPDATE_MAIN_USER, onSendDataMainUser);
}
export function* watchOnSendAvatarMainUser() {
  yield takeEvery(MainUserActionTypes.UPDATE_MAIN_USER_AVATAR, onSendAvatarMainUser);
}

export function* watchOnRemoveMainUser() {
  yield takeEvery(MainUserActionTypes.REMOVE_MAIN_USER, onRemoveMainUser);
}

export function* watchOnResendConfirmationEmail() {
  yield takeEvery(MainUserActionTypes.SIGNUP_RESEND_CONFIRMATION_EMAIL, onResendConfirmationEmail);
}

export function* watchOnSendChangeUserLanguage() {
  yield takeEvery(MainUserActionTypes.SEND_SET_USER_LANGUAGE, onSendChangeUserLanguage);
}



function* mainUsersSaga() {
  yield all([
    fork(watchGetMainUsers),
    fork(watchOnSendDataMainUser),
    fork(watchOnSendAvatarMainUser),
    fork(watchOnRemoveMainUser),
    fork(watchGetMainCurrentUser),
    fork(watchOnSendSignUpUser),
    fork(watchOnSendResetUser),
    fork(watchOnSendChangePasswordUser),
      fork(watchOnResendConfirmationEmail),
      fork(watchGetMainCurrentUserProfile),
      fork(watchOnSendChangeUserLanguage),
      fork(watchOnSendChangeEmailUser),
      fork(watchOnGetMainContacts),
      fork(watchOnGetCabinetContacts)
  ]);
}

export default mainUsersSaga;
