import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom'
import {useRedux} from "../../../../hooks";
import {Col, Row} from 'reactstrap';
import '../../../../i18/config';
import {useTranslation} from "react-i18next";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {
    PeriodDurationType,
    PeriodType
} from "../../../../redux/Stat/DashboardContent/types";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import PeriodSelect from "../../../../components/PeriodSelect";
import * as route from "../../../../api/routes";
import {
    getDashboardTaskLastCompleted, getDashboardTaskLastInProgress, getDashboardTaskLastOverdue,
    getDashboardTaskLastPending, getDashboardTaskLastReturned,
    getDashboardTaskTiles, selectDashboardTaskMemberType,
    setDashboardTaskPeriod
} from "../../../../redux/Stat/DashboardTask/actions";
import {StatTaskPayloadType} from "../../../../redux/Stat/DashboardTask/types";
import {emptyTaskTiles} from "../../../../redux/Stat/Task/types";
import StatTile from "../../../../components/StatTile";
import {capitalizeFirstLetter} from "../../../../helpers/functions";
import {StatTileType} from "../../../../redux/Stat/DashboardSale/types";
import {TaskMemberTypeType} from "../../../../redux/Task/TaskMember/types";
import {TaskStatus} from "../../../../redux/Task/Task/types";
import TaskTable from "../../../../components/Dashboard/TaskTable";
import TaskStatusChart from "../../../../components/Dashboard/TaskStatusChart";
import {MenuItem} from "../../../../layouts/LayoutMenuData";
import {changeDefaultDashboard, saveSettingsValues} from "../../../../redux/Main/Layout/actions";
import {useProfile} from "../../../../hooks/UserHooks";


interface TaskMemberTypeButtonProps {
    label: string;
    value: string;
}

const TaskDashboardIndex = (props) => {
    const { t } = useTranslation();
    const { uuid } = useParams();

    const { dispatch, useAppSelector } = useRedux();

    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ periodType, setPeriodType ] = useState<PeriodDurationType>(PeriodDurationType.WEEK);
    const [currentTiles, setCurrentTiles] = useState<StatTileType[]>(emptyTaskTiles);

    const taskMemberTypes:Array<TaskMemberTypeButtonProps> = [
        {
            label: t('task:assignedToMe'),
            value: TaskMemberTypeType.TYPE_RESPONSIBLE
        },
        {
            label: t('task:assignedByMe'),
            value: TaskMemberTypeType.TYPE_ASSIGNER
        },
    ];


    const { userProfile } = useProfile();

    const {
        dashboardTask,
        isFetched,
        isFetchedTasksTiles,
        periodFrom,
        periodTo,
        lastPending,
        lastPendingMembers,
        lastOverdue,
        lastOverdueMembers,
        lastCompleted,
        lastCompletedMembers,
        lastReturned,
        lastReturnedMembers,
        currentInProgress,
        currentInProgressMembers,
        defaultDashboard
    } = useAppSelector(state => ({
        dashboardTask: state.DashboardTask,
        isFetched: state.DashboardTask.isFetched,
        isFetchedTasksTiles: state.DashboardTask.isFetchedTasksTiles,
        periodFrom: state.DashboardTask.periodFrom,
        periodTo: state.DashboardTask.periodTo,
        lastPending: state.DashboardTask.lastPending,
        lastPendingMembers: state.DashboardTask.lastPendingMembers,
        lastOverdue: state.DashboardTask.lastOverdue,
        lastOverdueMembers: state.DashboardTask.lastOverdueMembers,
        lastCompleted: state.DashboardTask.lastCompleted,
        lastCompletedMembers: state.DashboardTask.lastCompletedMembers,
        lastReturned: state.DashboardTask.lastReturned,
        lastReturnedMembers: state.DashboardTask.lastReturnedMembers,
        currentInProgress: state.DashboardTask.currentInProgress,
        currentInProgressMembers: state.DashboardTask.currentInProgressMembers,
        defaultDashboard: state.Layout.defaultDashboard
    }));

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoaded(isFetched);
    },[isFetched]);

    const handleLastWeekPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'weeks').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setDashboardTaskPeriod(periodData));
        setPeriodType(PeriodDurationType.WEEK);
    }

    const handleLastMonthPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'months').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setDashboardTaskPeriod(periodData));
        setPeriodType(PeriodDurationType.MONTH);
    }

    useEffect(() => {

        const statTaskPayloadType:StatTaskPayloadType = {
            periodFrom : dashboardTask.periodFrom,
            periodTo: dashboardTask.periodTo,
            memberType: dashboardTask.memberType,
            limit: 10
        }

        dispatch(getDashboardTaskTiles(statTaskPayloadType));

        dispatch(getDashboardTaskLastPending(
            {...statTaskPayloadType,
                    status: [
                        TaskStatus.PENDING.status,
                        TaskStatus.NEW.status
                    ].join(',')
                }
        ));
        dispatch(getDashboardTaskLastCompleted(
            {...statTaskPayloadType,
                status: TaskStatus.FINAL.status
            }
        ));
        dispatch(getDashboardTaskLastOverdue(
            {...statTaskPayloadType,
                status: [
                    TaskStatus.PENDING.status,
                    TaskStatus.OVERDUE.status
                ].join(',')
            }
        ));
        dispatch(getDashboardTaskLastReturned(
            {...statTaskPayloadType,
                status: TaskStatus.RETURNED.status
            }
        ));
        dispatch(getDashboardTaskLastInProgress(
            {...statTaskPayloadType,
                status: TaskStatus.INPROGRESS.status
            }
        ));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dashboardTask?.periodFrom, dashboardTask?.periodTo, dashboardTask?.memberType])

    useEffect(() => {
        if (dashboardTask?.tiles && (dashboardTask.tiles || []).length>0) {
           setCurrentTiles([...dashboardTask.tiles]);
        } else {
            setCurrentTiles(emptyTaskTiles);
        }
    }, [dashboardTask?.tiles]);


    const handleChangeTaskMemberType = (item:TaskMemberTypeButtonProps) => {
        dispatch(selectDashboardTaskMemberType(item.value));
    }

    return (
        <>
            <BreadCrumb title={t('menu:dashboard')} pageTitle={t('menu:main')} />
            <Row className="mb-3 pb-1">
            <Col xs={12}>
                <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                    <div className="flex-grow-1">
                        <h4 className="fs-16 mb-1">{t('common:welcome')}, {userProfile.fullName}!</h4>
                        <p className="text-muted mb-0">{t('common:welcomeTaskDashboardText')}</p>
                    </div>

                    {/*{!isLoaded &&*/}
                    {/*    <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center" >*/}
                    {/*        <div className="d-flex justify-content-center align-items-center calendarLoader">*/}
                    {/*            <Loader addClassNames=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    <div className="mt-3 mt-lg-0">
                        <Row className="g-3 mb-0 align-items-center">
                            <div className="col-sm-auto">
                                {periodType === PeriodDurationType.DATE_RANGE ?
                                    <>
                                        <PeriodSelect
                                            onDispatch={setDashboardTaskPeriod}
                                            periodFrom={periodFrom}
                                            periodTo={periodTo}/>
                                    </>
                                    :
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className={"btn "+(periodType===PeriodDurationType.WEEK?'btn-primary':'btn-soft-primary') }onClick={handleLastWeekPeriod}>{t('common:week')}</button>
                                        <button type="button" className={"btn "+(periodType===PeriodDurationType.MONTH?'btn-primary':'btn-soft-primary')} onClick={handleLastMonthPeriod}>{t('common:month')}</button>
                                        <button type="button" className="btn btn-soft-primary" onClick={()=>{setPeriodType(PeriodDurationType.DATE_RANGE)}}>
                                            <i className="ri-calendar-2-line align-bottom"/>
                                        </button>
                                        <button type="button" className={"btn "+(defaultDashboard===MenuItem.DASHBOARD_TASKS?'btn-primary':'btn-soft-primary')}
                                                onClick={()=>{
                                                    if (defaultDashboard!==MenuItem.DASHBOARD_TASKS) {
                                                        dispatch(changeDefaultDashboard(MenuItem.DASHBOARD_TASKS));
                                                        dispatch(saveSettingsValues());
                                                    }
                                                }}
                                        >
                                            <i className="ri-star-line align-bottom"/>
                                        </button>
                                    </div>
                                }
                            </div>

                            <div className="col-auto">
                                <a href={route.TASK_LIST} className="btn btn-primary"><i className="ri-survey-line align-bottom me-1"/> {t('menu:tasks')}</a>
                            </div>
                        </Row>
                        <Row>
                            <div className="btn-group mt-3" role="group" aria-label="Currency select">
                                {taskMemberTypes.map((item:TaskMemberTypeButtonProps)=>{
                                    return (
                                        <button key={"taskTypeSelectButton_"+item.value}
                                                type="button"
                                                onClick={()=>{handleChangeTaskMemberType(item)}}
                                                className={"btn "+(item.value === dashboardTask?.memberType ?'btn-primary': 'btn-soft-primary')}>{item.label}</button>
                                    )
                                })}

                            </div>
                        </Row>
                    </div>
                </div>
            </Col>
            </Row>
            <Row>
                <Col xl={8}>
                    <Row>
                        {(currentTiles || []).map((item: StatTileType, key:number)=>(
                                <Col md={4} key = {'taskTile_'+key}>
                                    <StatTile
                                        label={'task'+capitalizeFirstLetter(item.label)}
                                        start={0}
                                        first={item.value.first && item.value.first}
                                        last={item.value.last}
                                        isLoading={!isFetchedTasksTiles}
                                        deltaLabel={moment(dashboardTask.periodTo,'X').diff(moment(dashboardTask.periodFrom,'X'),'days').toString()}
//                                        customLabel={item.label.match(/Amount/) && selectedCurrencyCode}
                                        isFullNumber={true}
                                    />
                                </Col>
                        ))}
                    </Row>
                </Col>
                <Col xl={4}>
                    <TaskStatusChart
                        items={(currentTiles || []).filter((tile:StatTileType)=>{return tile.label !== 'newCount'})}
                        title={t('common:pieChart')}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <TaskTable
                        items={currentInProgress}
                        members={currentInProgressMembers}
                        title={t('task:taskStatusInProgress')}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TaskTable
                        items={lastPending}
                        members={lastPendingMembers}
                        title={t('task:taskStatusNew')}
                    />
                </Col>
                <Col md={6}>
                    <TaskTable
                        items={lastOverdue}
                        members={lastOverdueMembers}
                        title={t('task:taskStatusOverDue')}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <TaskTable
                        items={lastCompleted}
                        members={lastCompletedMembers}
                        title={t('task:taskStatusClosed')}
                    />
                </Col>
                <Col md={6}>
                    <TaskTable
                        items={lastReturned}
                        members={lastReturnedMembers}
                        title={t('task:taskStatusReturned')}
                    />
                </Col>
            </Row>

        </>
    );
};

export default TaskDashboardIndex;


