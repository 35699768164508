import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum SaleCurrencyActionTypes {
    API_RESPONSE_SUCCESS = "@@saleCurrency/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleCurrency/API_RESPONSE_ERROR",
    GET_SALE_CURRENCIES = "@@saleCurrency/GET_SALE_CURRENCIES",
    UPDATE_SALE_CURRENCY = "@@saleCurrency/UPDATE_SALE_CURRENCY",
    REMOVE_SALE_CURRENCY_MODAL_SHOW = "@@saleCurrency/REMOVE_SALE_CURRENCY_MODAL_SHOW",
    REMOVE_SALE_CURRENCY_MODAL_HIDE = "@@saleCurrency/REMOVE_SALE_CURRENCY_MODAL_HIDE",
    REMOVE_SALE_CURRENCY = "@@saleCurrency/REMOVE_SALE_CURRENCY",
    EDIT_SALE_CURRENCY = "@@saleCurrency/EDIT_SALE_CURRENCY",
    RIGHT_MODAL_SHOW = "@@saleCurrency/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleCurrency/RIGHT_MODAL_HIDE",
}

export interface SaleCurrencyState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: CurrencyType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface CurrencyType {
    uuid?: string;
    name?: string;
    code?: string;
    enabled?: boolean;
    isDefault?: boolean;
    order?: number;
    currencyFormat?: string;
    actions?: EntityActionsType
}

export enum LocaleCurrency {
    en = 'USD',
    ru = 'USD',
    ka = 'GEL'
}

export enum LocaleCurrencySymbol {
    USD = '$',
    GEL = '₾'
}

export interface SaleCurrencyPayload {
    data: Array<any>;
    modalEditTitle : string;
}


export const defaultCurrency = 'USD';