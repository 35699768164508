import React from "react";
import {ContentConnectorType} from "../../../redux/Content/Connector/types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import '../../../i18/config';

interface ItemProps {
    item: ContentConnectorType;
    marginTop?: string;
}

const ConnectorConnectionStatus = ({ item, marginTop = null }: ItemProps) => {

    const { t } = useTranslation(['common']);

    return (
        <div className="avatar-xxxxs d-flex flex-shrink-0 me-1" style={{marginTop:marginTop===null?'':marginTop}}>
            <OverlayTrigger
                placement={'right'}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip>
                        {item.isConnected ?
                            t('common:online')
                            :
                            t('common:offline')
                        }
                    </Tooltip>
                }
            >
                <span className={"statusIndicator rounded-circle d-flex flex-grow-1 bg-" + (item.isConnected ? 'success' :'danger')}/>
            </OverlayTrigger>
        </div>
    )
}

export default ConnectorConnectionStatus;