import {
  DashboardTaskActionTypes, StatTaskPayloadType
} from "./types";
import {PeriodType} from "../DashboardContent/types";

export const dashboardTaskApiResponseSuccess = (actionType: string, data: any) => ({
  type: DashboardTaskActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const dashboardTaskApiResponseError = (actionType: string, error: string) => ({
  type: DashboardTaskActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const setDashboardTaskPeriod= (data: PeriodType) => ({
  type: DashboardTaskActionTypes.SET_PERIOD,
  payload: data,
});

export const getDashboardTaskTiles= (data: StatTaskPayloadType) => ({
  type: DashboardTaskActionTypes.GET_STAT_TASKS_TILES,
  payload: data,
});

export const getDashboardTaskLastCompleted= (data: StatTaskPayloadType) => ({
  type: DashboardTaskActionTypes.GET_STAT_TASKS_LAST_COMPLETED,
  payload: data,
});

export const getDashboardTaskLastOverdue= (data: StatTaskPayloadType) => ({
  type: DashboardTaskActionTypes.GET_STAT_TASKS_LAST_OVERDUE,
  payload: data,
});

export const getDashboardTaskLastReturned= (data: StatTaskPayloadType) => ({
  type: DashboardTaskActionTypes.GET_STAT_TASKS_LAST_RETURNED,
  payload: data,
});

export const getDashboardTaskLastPending= (data: StatTaskPayloadType) => ({
  type: DashboardTaskActionTypes.GET_STAT_TASKS_LAST_PENDING,
  payload: data,
});

export const getDashboardTaskLastInProgress= (data: StatTaskPayloadType) => ({
  type: DashboardTaskActionTypes.GET_STAT_TASKS_IN_PROGRESS,
  payload: data,
});

export const selectDashboardTaskMemberType= (data: any) => ({
  type: DashboardTaskActionTypes.SELECT_TASK_MEMBER_TYPE,
  payload: data,
});
