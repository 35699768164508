import React, {useState, useEffect} from 'react';
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    UncontrolledTooltip
} from 'reactstrap';
import {useRedux} from "../../../hooks";

import DetailViewItem from "./DetailViewItem";
import ModalPreloader from "../../../components/ModalPreloader";
import {PeerActivityModeCode} from "../../../redux/Content/PeerActivityMode/types";

import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {
    hidePeerActivityEditModal,
    setHidePreviewItems,
    setShowPreviewItems
} from "../../../redux/Content/PeerActivity/actions";
import PeerActivityEdit from "./PeerActivityEdit";
import {Spinner} from "react-bootstrap";
import AcceptedHandlerByMode from "../ConnectorHandler/AcceptedHandlerByMode";
import * as route from "../../../api/routes";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";


const getActivityItemsInfo = (editItems: PeerActivityType[]) => {
    let isEditable = true;
    let activityTitle = 'Post';
    let isNewItem = true;
    let isOnlyIdeas = true;

    editItems.map((item: PeerActivityType) => {
        if (item.channel.isManaged === false) {
            isEditable = false;
        }
        if (item?.channel?.connector?.handler?.code && item.channel.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS){
            isOnlyIdeas = false;
        }
        activityTitle = getActivityTypeTitle(item.mode.code);
        if (item.uuid !== undefined && item.uuid !== null) {
            isNewItem = false;
        }
        return item;
    });
    return { isEditable, activityTitle, isNewItem, isOnlyIdeas };
}

// Extracted Function
const getActivityTypeTitle = (mode: string) => {
    let title;
    switch(mode) {
        case PeerActivityModeCode.BIO:
            return 'BIO';
        case PeerActivityModeCode.STORY:
            return 'Story';
        case PeerActivityModeCode.SHORTS:
            return 'Reel';
        case PeerActivityModeCode.ACTIVITY:
        default:
            return 'Post';
    }
}

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [ isModalShow, setModalShow ] = useState<boolean>(false);
    const [ isEdit, setEdit ] = useState<boolean>(false);
    const [ isNew, setNew ] = useState<boolean>(true);
    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ title, setTitle ] = useState<string>('');
    const [ currentItems, setCurrentItems ] = useState<PeerActivityType[]>([]);
    const [isSending, setSending] = useState<boolean>(false);
    const [showAbsentManagedChannelMessage, setShowAbsentManagedChannelMessage] = useState<boolean>(false);
    const [currentActivityMode, setCurrentActivityMode] = useState<string>(null);
    const [isOnlyIdeasInForm, setIsOnlyIdeasInForm] = useState<boolean>(false);
    // const [isShowMoreChannels, setShowMoreChannels] = useState<boolean>(false);
    const [isExpandEditView, setExpandEditView] = useState<boolean>(false);
    const [isMultiPosting, setIsMultiPosting] = useState<boolean>(true);

    const {
        peerActivity,
        isShowPreviewItems,
    } = useAppSelector(state => ({
        peerActivity: state.PeerActivity,
        isShowPreviewItems: state.PeerActivity.isShowPreviewItems
    }));

    useEffect(() => {

        setLoaded(peerActivity.isLoaded);

    },[peerActivity.isLoaded])

    useEffect(() => {
        setSending(peerActivity.isSending);
    },[peerActivity.isSending])

    useEffect(() => {
        if ((currentItems || []).length>0){
            let _currentActivityMode=[...currentItems].shift()?.mode?.code;

            if (_currentActivityMode !== undefined && _currentActivityMode !== null){
                setCurrentActivityMode(_currentActivityMode);
            }
        }
    },[dispatch, currentItems])

    useEffect(() => {
        setModalShow(peerActivity.rightModalShow);
    },[peerActivity?.rightModalShow])

    useEffect(() => {
        setCurrentActivityMode(peerActivity.mode);
    },[peerActivity?.mode])

    useEffect(()=>{
        if (
            currentActivityMode === PeerActivityModeCode.ACTIVITY
            ||
            currentActivityMode === PeerActivityModeCode.STORY
            ||
            currentActivityMode === PeerActivityModeCode.SHORTS
        ) {

        } else {
            dispatch(setHidePreviewItems());
        }
    },[dispatch, currentActivityMode])


    useEffect(() => {
        const editItems = peerActivity?.editItems || [];
        if (editItems.length > 0) {
            const { isEditable, activityTitle, isNewItem, isOnlyIdeas } = getActivityItemsInfo(editItems);
            setCurrentItems([...editItems]);
            setEdit(isEditable);
            setNew(isNewItem);
            setTitle(activityTitle);
            setIsOnlyIdeasInForm(isOnlyIdeas);
            setIsMultiPosting(isNewItem && editItems.length > 1);
            setShowAbsentManagedChannelMessage(false);
            if (isOnlyIdeas){
                setTitle('Idea');
                setIsMultiPosting(false);
            } else {
                setIsOnlyIdeasInForm(false);
            }
        } else {
            setTitle(getActivityTypeTitle(peerActivity.mode));
            setShowAbsentManagedChannelMessage(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [peerActivity.editItems]);

    useEffect(() => {

        if ((peerActivity.isNeedReload) && (peerActivity?.editItems && peerActivity.editItems.length > 0)){
            //TODO reload multiple items
 //           dispatch(getContentActivityDataItem({id: contentActivity.dataItemId}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerActivity.isNeedReload]);

    const handleClose = () => {
        dispatch(hidePeerActivityEditModal());
    }

    const handleSubmit = () => {
        const submitButton = document.getElementById('idPeerActivityEditSubmit');
        if (submitButton !== undefined){
            submitButton.click();
        }
    }

    const togglePreview = () => {
        if (peerActivity.isShowPreviewItems){
            dispatch(setHidePreviewItems());
        } else {
            dispatch(setShowPreviewItems());
        }
    }

    const toggleExpandEditView = () => {
        setExpandEditView(!isExpandEditView);
    }

    const toggleMultiPosting = () => {
        setIsMultiPosting(!isMultiPosting);
    }

    return (
    <Offcanvas
        direction="end"
        isOpen={isModalShow}
        id="peerActivityEdit"
        toggle={handleClose}
        className={(isShowPreviewItems ?' showPreviewPopup':'')+(isExpandEditView?' showExpandEditView':'')+(isMultiPosting?' showMultiPostingView':'')}
    >
        <OffcanvasHeader className="bg-light" toggle={handleClose}>
            <div className="offcanvasTopCont">
                <div className="offcanvasTopTitle">
            {showAbsentManagedChannelMessage ?
                t('peerActivity:new'+title)
                :
                t('peerActivity:'+(isEdit === true ?
                    (!isNew ?
                        'change'+title
                        :
                        'new'+title)
                    :
                'view'+title)
                )
            }
                </div>
                <div className="offcanvasTopActions">
                    <a type="button" href="#" rel="noreferrer"
                       id={"idPeerActivityEditMultiPostingAction"}
                       onClick={toggleMultiPosting}
                       className={"btn btn-sm btn-icon waves-effect waves-light me-2 "+(isMultiPosting?'btn-primary':'btn-outline-primary')}><i className="ri-table-line"/>
                    </a>
                    <UncontrolledTooltip target={'idPeerActivityEditMultiPostingAction'} placement="bottom">
                        {t('peerActivity:multiPosting')}
                    </UncontrolledTooltip>
                    <a type="button" href="#" rel="noreferrer"
                       onClick={toggleExpandEditView}
                       id={"idPeerActivityEditExpandViewAction"}
                       className={"btn btn-sm btn-icon waves-effect waves-light me-2 "+(isExpandEditView?'btn-primary':'btn-outline-primary')}><i className="ri-arrow-left-right-line"/>
                    </a>
                    <UncontrolledTooltip target={'idPeerActivityEditExpandViewAction'} placement="bottom">
                        {t('peerActivity:expandedView')}
                    </UncontrolledTooltip>
                    {(
                        currentActivityMode === PeerActivityModeCode.ACTIVITY
                        ||
                        currentActivityMode === PeerActivityModeCode.STORY
                        ||
                        currentActivityMode === PeerActivityModeCode.SHORTS
                        ) &&
                        <>
                            <a type="button" href="#" rel="noreferrer"
                               onClick={togglePreview}
                               id={"idPeerActivityEditPreviewAction"}
                               className={"btn btn-sm btn-icon waves-effect waves-light me-2 "+(isShowPreviewItems?'btn-primary':'btn-outline-primary')}><i className="ri-slideshow-3-line"/>
                            </a>
                            <UncontrolledTooltip target={'idPeerActivityEditPreviewAction'} placement="bottom">
                                {t('peerActivity:preview')}
                            </UncontrolledTooltip>
                            </>
                    }
                </div>
            </div>
        </OffcanvasHeader>

        <div className={(isShowPreviewItems ?' showPreviewCont':'')}>


        <div className={'showEditItem'}>
        <OffcanvasBody className="p-0 overflow-hidden">

                    {showAbsentManagedChannelMessage ?
                        <>
                            { currentActivityMode &&
                                <div className="p-4">
                                    <div className="d-flex flex-row flex-grow-1">
                                        <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('channel:absentChannelForThisPublishMode')}
                                        </div>
                                    </div>
                                <AcceptedHandlerByMode mode={currentActivityMode} link={route.CONTENT_SERVICES_LIST} />
                                </div>
                            }
                        </>
                        :
                        isEdit === true ?
                            <>
                                {isLoaded ?

                                    <PeerActivityEdit
                                        items={currentItems}
                                        isShowMoreChannels={peerActivity.isShowMoreChannels}
                                        isCopyFromIdeas={isOnlyIdeasInForm}
                                        isMultiPosting={isMultiPosting}
                                    />

                                    :
                                    <ModalPreloader/>
                                }
                            </>
                            :
                            (currentItems || []).map((item:PeerActivityType, key: number)=> (
                                <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                    <div className="p-4">
                                <DetailViewItem item={item}/>
                                    </div>
                                </SimpleBar>
                            ))
                    }
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0 peerActivityModalFooter">
            <div className="d-flex flex-row flex-nowrap gap-2">
                <Button variant="light" onClick={handleClose} className="w-100">
                    {t('common:close')}
                </Button>
                {isEdit &&
                <Button
                    onClick={handleSubmit}
                    className="btn btn-primary w-100 chat-send waves-effect waves-light text-truncate"
                    type="button"
                    disabled={isSending}
                >
                    {(isOnlyIdeasInForm && !peerActivity.isShowMoreChannels) ?
                        t('common:saveIdea')
                        :
                        t('common:saveDraft')
                    }

                    {isSending &&
                        <i className="label-icon align-middle ms-2">
                            <Spinner size='sm'/>
                        </i>
                    }
                </Button>
                }
            </div>
        </div>
        </div>
        </div>
    </Offcanvas>
    );
}

export default RightModal;