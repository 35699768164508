import {EventWebhookActionTypes, EventWebhookPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const eventWebhookApiResponseSuccess = (actionType: string, data: any) => ({
  type: EventWebhookActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const eventWebhookApiResponseError = (actionType: string, error: string) => ({
  type: EventWebhookActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getEventWebhooks = (data: MainMetaType) => ({
  type: EventWebhookActionTypes.GET_EVENT_WEBHOOKS,
  payload: data
});

export const getWebhookAvailableEventTypes = () => ({
  type: EventWebhookActionTypes.GET_AVAILABLE_EVENT_TYPES,
});
export const showRightModalEventWebhook= (data: EventWebhookPayload) => ({
  type: EventWebhookActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalEventWebhook= () => ({
  type: EventWebhookActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataEventWebhook = (data: any) => ({
  type: EventWebhookActionTypes.UPDATE_EVENT_WEBHOOK,
  payload: data,
});

export const editDataEventWebhook = (data: EventWebhookPayload) => ({
  type: EventWebhookActionTypes.EDIT_EVENT_WEBHOOK,
  payload: data,
});

export const removeEventWebhookModalShow = (data: EventWebhookPayload) => ({
  type: EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK_MODAL_SHOW,
  payload: data,
});

export const removeEventWebhookModalHide = () => ({
  type: EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK_MODAL_HIDE,
});

export const onRemoveEventWebhook = (data: EventWebhookPayload) => ({
  type: EventWebhookActionTypes.REMOVE_EVENT_WEBHOOK,
  payload: data,
});