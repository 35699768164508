// types
import {SalePlanActionTypes, SalePlanState, SalePlanType} from "./types";

export const INIT_STATE_SALE_PLAN: SalePlanState = {
  items: [],
  isFetched: false,
  isLoading: false,
};

const SalePlan = (state = INIT_STATE_SALE_PLAN, action: any) => {
  switch (action.type) {
    case SalePlanActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SalePlanActionTypes.GET_SALE_CURRENT_PLAN:
          return {
            ...state,
            currentPlan: action.payload.data.currentPlan,
            isFetched: true,
            isLoading: false,
          };
        case SalePlanActionTypes.GET_SALE_PLANS:
          return {
            ...state,
            items: action.payload.data.items,
            isFetched: true,
            isLoading: false,
          };
        default:
          return { ...state };
      }

    case SalePlanActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SalePlanActionTypes.GET_SALE_CURRENT_PLAN:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case SalePlanActionTypes.GET_SALE_CURRENT_PLAN:
      return {
        ...state,
        currentPlan: {},
        isFetched: false,
        isLoading: true,
      };
    case SalePlanActionTypes.GET_SALE_PLANS:
      return {
        ...state,
        isFetched: false,
        isLoading: true,
      };
    default:
      return { ...state };
  }
};

export default SalePlan;
