import {ContentPeerChannelActionTypes, ContentPeerChannelPayload} from "./types";

export const contentPeerChannelApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentPeerChannelActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentPeerChannelApiResponseError = (actionType: string, error: string) => ({
  type: ContentPeerChannelActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentPeerChannels = () => ({
  type: ContentPeerChannelActionTypes.GET_CONTENT_PEER_CHANNELS,
});

export const showRightModalContentPeerChannel= (data: ContentPeerChannelPayload) => ({
  type: ContentPeerChannelActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalContentPeerChannel= () => ({
  type: ContentPeerChannelActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataContentPeerChannel = (data: any) => ({
  type: ContentPeerChannelActionTypes.UPDATE_CONTENT_PEER_CHANNEL,
  payload: data,
});

export const editDataContentPeerChannel = (data: ContentPeerChannelPayload) => ({
  type: ContentPeerChannelActionTypes.EDIT_CONTENT_PEER_CHANNEL,
  payload: data,
});

export const removeContentPeerChannelModalShow = (data: ContentPeerChannelPayload) => ({
  type: ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL_MODAL_SHOW,
  payload: data,
});

export const removeContentPeerChannelModalHide = () => ({
  type: ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL_MODAL_HIDE,
});

export const onRemoveContentPeerChannel = (data: ContentPeerChannelPayload) => ({
  type: ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL,
  payload: data,
});

export const onConnectContentPeerChannel = (data: ContentPeerChannelPayload) => ({
  type: ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL,
  payload: data,
});

export const contentPeerChannelNotFound = () => ({
  type: ContentPeerChannelActionTypes.CONTENT_PEER_CHANNEL_NOT_FOUND,
});

export const contentPeerChannelConnectCancel = () => ({
  type: ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL_CANCEL,
});
