import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { EventNotificationActionTypes } from "./types";
import { eventNotificationApiResponseSuccess, eventNotificationApiResponseError } from "./actions";

import {
  getEventNotifications as getEventNotificationsApi,
  readEventNotifications as readEventNotificationsApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getEventNotifications({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getEventNotificationsApi, data);
    yield put(eventNotificationApiResponseSuccess(EventNotificationActionTypes.GET_EVENT_NOTIFICATIONS, response));
  } catch (error: any) {
    yield put(eventNotificationApiResponseError(EventNotificationActionTypes.GET_EVENT_NOTIFICATIONS, error));
  }
}

function* readEventNotifications({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(readEventNotificationsApi, data);
    yield put(eventNotificationApiResponseSuccess(EventNotificationActionTypes.READ_EVENT_NOTIFICATIONS, response));
  } catch (error: any) {
    yield put(eventNotificationApiResponseError(EventNotificationActionTypes.READ_EVENT_NOTIFICATIONS, error));
  }
}

export function* watchGetEventNotifications() {
  yield takeEvery(EventNotificationActionTypes.GET_EVENT_NOTIFICATIONS, getEventNotifications);
}

export function* watchReadEventNotifications() {
  yield takeEvery(EventNotificationActionTypes.READ_EVENT_NOTIFICATIONS, readEventNotifications);
}

function* eventNotificationSaga() {
  yield all([
      fork(watchGetEventNotifications),
      fork(watchReadEventNotifications)
  ]);
}

export default eventNotificationSaga;
