import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, UncontrolledDropdown,
} from "reactstrap";

import { Link } from "react-router-dom";

// components
import SectionTitle from "../blocks/Messenger/Profile/SectionTitle";

//i18
import '../i18/config';
import {useTranslation} from "react-i18next";

// interface

import { Card } from "reactstrap";
import {MediaFilesType, MediaFileType} from "../data";
import {formatBytes} from "../helpers/functions";


interface AttachedFileItemProps {
  attachedFile: MediaFileType;
}
const AttachedFileItem = ({ attachedFile }: AttachedFileItemProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);
  const { t } = useTranslation(['common']);

  return (
      <>
        <div className="border rounded border-dashed p-2">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-xs">
                <div className="avatar-title bg-light text-secondary rounded fs-20">
                  <i className="ri-file-line"/>
                </div>
              </div>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="fs-13 mb-1">
                <Link to="#" className="text-body text-truncate d-block">
                  {attachedFile.name}
                </Link>
              </h5>
              <div className="text-muted">{formatBytes(attachedFile.size)}</div>
            </div>
            <div className="flex-shrink-0 ms-2">
              <div className="d-flex gap-1">
                <a
                    type="button"
                    className="btn btn-icon text-muted btn-sm fs-18"
                    target="_blank"
                    rel="noreferrer"
                    href={attachedFile.url}
                >
                  <i className="ri-download-2-line"/>
                </a>
                <UncontrolledDropdown>
                  <DropdownToggle
                      tag="button"
                      className="btn btn-icon text-muted btn-sm fs-18 dropdown"
                  >
                    <i className="ri-more-fill"/>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to={attachedFile.url}>
                      <i className="ri-download-2-line align-bottom me-2 text-muted"></i>{" "}
                      {t('common:download')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </div>
        </>
  );
};
interface AttachedFilesProps {
  attachedFiles: MediaFilesType;
  limit: number;
}

const AttachedFiles = ({ attachedFiles, limit }: AttachedFilesProps) => {
  const [files, setFiles] = useState<MediaFileType[]>([]);

  useEffect(() => {
    if (attachedFiles && attachedFiles.items) setFiles(attachedFiles.items);
  }, [attachedFiles]);

  const { t } = useTranslation(['common']);

  return (
      <div className="border-top border-top-dashed p-3">
        <div className="d-flex">
          <div className="flex-grow-1">
            <h5 className="fs-15 mb-3">{t('common:media')}</h5>
          </div>
          <div className="flex-shrink-0">
            <Link to="#" className="font-size-12 d-block mb-2">
              {t('common:showAll')}
            </Link>
          </div>
        </div>

      <div>
        {attachedFiles
          ? files.map((attachedFile: MediaFileType, key: number) => {
              if (key < limit) {
                return (
                    <AttachedFileItem attachedFile={attachedFile} key={key} />
                )
              }
              return (<></>);
        })
          : t('common:noFiles')}
      </div>
    </div>
  );
};
export default AttachedFiles;
