import {AbstractOrderType} from "../Order/types";
import {AbstractOrderItemType} from "../OrderItem/types";
import {CartPlanType, SalePlanPriceTypeType, SalePlanType} from "../Plan/types";
import {PaymentType} from "../Payment/types";
import {LicenseKeyType} from "../License/types";
import {PaymentSystemType} from "../PaymentSystem/types";
import {MainCabinetType} from "../../Main/Cabinet/types";
import {MainMetaType} from "../../Main/common";
import {CouponType} from "../Coupon/types";

export enum PlanOrderActionTypes {
    API_RESPONSE_SUCCESS = "@@planOrder/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@planOrder/API_RESPONSE_ERROR",
    GET_ORDERS = "@@planOrder/GET_ORDERS",
    GET_ORDER_DETAIL = "@@planOrder/GET_ORDER_DETAIL",
    CANCEL_MODAL_SHOW = "@@planOrder/CANCEL_MODAL_SHOW",
    CANCEL_MODAL_HIDE= "@@planOrder/CANCEL_MODAL_HIDE",
    CANCEL_ORDER = "@@planOrder/CANCEL_ORDER",
    CREATE_ORDER = "@@planOrder/CREATE_ORDER",
}

export interface PlanOrderState extends MainMetaType{
    items: Array<PlanOrderType>;
    current?: PlanOrderType;
    isFetched: boolean,
    isLoading: boolean,
    cancelModalShow: boolean;
}

export interface PlanOrderType extends AbstractOrderType{
    orderItems?: Array<PlanOrderItemType>
    payments?: Array<PaymentType>
    customer?: string;
}

export interface PlanOrderItemType extends AbstractOrderItemType{
    product?: SalePlanType;
    priceType: SalePlanPriceTypeType;
    licenseKey?:Array<LicenseKeyType>
}

export interface PlanOrderFormType{
    customerName: string;
    customerEmail: string;
    customerPhone?: string;
    customerCompany?: string;
    customerTin?: string;
    orderItems: Array<CartPlanType>
    currencyCode: string;
    paymentSystem?: PaymentSystemType;
    cabinet?: MainCabinetType;
    coupon?: CouponType;
    amount?: number;
}