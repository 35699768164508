// types
import {DeliveryStatus, DeliveryType, SaleOrderDeliveryItemActionTypes, SaleOrderDeliveryItemState} from "./types";

export const NewSaleOrderDeliveryItemItem:DeliveryType = {
  enabled: true,
  status: DeliveryStatus.NEW.status,
  deliverySystem: null
}

export const INIT_STATE_SALE_ORDER_DELIVERY_ITEM: SaleOrderDeliveryItemState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleOrderDeliveryItemItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false,
  isNeedOrderReload: false
};

const SaleOrderDeliveryItem = (state = INIT_STATE_SALE_ORDER_DELIVERY_ITEM, action: any) => {
  switch (action.type) {
    case SaleOrderDeliveryItemActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleOrderDeliveryItemActionTypes.UPDATE_SALE_ORDER_DELIVERY_ITEM:
          return {
            ...state,
            selectedItem: NewSaleOrderDeliveryItemItem,
            rightModalShow: false,
            isSending: false,
            isNeedOrderReload: true,
          };
        case SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM:
          return {
            ...state,
            selectedItem: NewSaleOrderDeliveryItemItem,
            removeModalShow: false,
            isNeedOrderReload: true,
            isSending: false
          };
        default:
          return { ...state };
      }

    case SaleOrderDeliveryItemActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleOrderDeliveryItemActionTypes.UPDATE_SALE_ORDER_DELIVERY_ITEM:
          return {
            ...state,
            isSending: false
          };
        case SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SaleOrderDeliveryItemActionTypes.EDIT_SALE_ORDER_DELIVERY_ITEM:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleOrderDeliveryItemActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleOrderDeliveryItemItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleOrderDeliveryItemItem,
        removeModalShow: false
      }
    case SaleOrderDeliveryItemActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleOrderDeliveryItemActionTypes.UPDATE_SALE_ORDER_DELIVERY_ITEM:
    case SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM:
      return {
        ...state,
        isSending: true
      };
    case SaleOrderDeliveryItemActionTypes.CLEAR_NEED_ORDER_RELOAD:
      return {
        ...state,
        isNeedOrderReload: false
      }
    default:
      return { ...state };
  }
};

export default SaleOrderDeliveryItem;
