
export enum MainIcon {
    VIEWS = "ri-eye-line",
    LIKES = "ri-heart-line",
    COMMENTS = "ri-question-answer-line",
    REPOSTS = "ri-share-forward-line",
    REACH = "ri-globe-line",
    SAVED = "ri-bookmark-line",
    EXITS= "ri-logout-box-r-line",
    TAPS_FORWARD = "ri-skip-forward-line",
    TAPS_BACK = "ri-skip-back-line",
    REPLIES = "ri-message-2-line",
    ANSWERS = "ri-chat-smile-2-lin",
    MEMBERS = "ri-group-line",
    BANS = "ri-forbid-2-line",
    OPEN_LINK = "ri-links-line",
    IS_PROMO = "ri-advertisement-line",
    FOLLOWS = "ri-share-forward-line",
    POSTS = "ri-pages-line",
    VISITS = "ri-footprint-line",
    BOUNCE_RATE = "ri-arrow-go-back-line",
    AVG_VISIT_DURATION_SECONDS = "ri-history-line",
    PAGE_DEPTH = "ri-align-bottom",
    GOAL_REACH = "ri-focus-3-line",
    ORDERS = "ri-shopping-cart-2-line",
    PRICE = "ri-price-tag-2-line",
    SUMMARY = "ri-functions",
    TASK_PENDING = "ri-loader-2-line",
    TASK_CLOSED = "ri-checkbox-circle-line",
    TASK_OVERDUE = "ri-timer-flash-line",
    TASK_NEW = "ri-flag-2-line",
    TASK_RETURNED = "ri-arrow-go-back-line",
    TASK_INPROGRESS = "ri-sun-line",
}