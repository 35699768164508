import React, {useState} from "react";
import {
    Button,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";
import classnames from "classnames";

//i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";

// constants
import {STATUS_TYPES} from "../../../components/Constants";
import {getMessengerUserData} from "../../../helpers/functions";
import {Link} from "react-router-dom";
import {MessengerChannelType} from "../../../redux/Messenger/Chat/types";


interface ProfileUserProps {
    onCloseUserDetails: () => void;
    onToggleArchive: () => void;
    chatUserDetails: MessengerChannelType;
    // onOpenVideo: () => void;
    // onOpenAudio: () => void;
}

const ProfileUser = ({
                         onCloseUserDetails,
                         chatUserDetails,
                         onToggleArchive,
                         // onOpenAudio,
                         // onOpenVideo,
                     }: ProfileUserProps) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    const profileTopCover = require('../../../assets/images/small/img-1.jpg');

    const {t} = useTranslation(['common', 'profile']);

    let {fullName, avatar, handlerImage} = getMessengerUserData(chatUserDetails);




    return (
        <>
            <div className="profileTopCont">
            <div className="team-cover">
                <img src={profileTopCover} alt="" className="img-fluid"/>
            </div>
            <div className="p-1 pb-4 pt-0">
                <div className="team-settings">
                    <div className="row g-0">
                        <div className="col">
                            <div className="btn nav-btn">
                                <Button
                                    onClick={onCloseUserDetails}
                                    color=""
                                    className="btn-close btn-close-white"
                                ></Button>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="user-chat-nav d-flex">
                                {/*<button*/}
                                {/*    type="button"*/}
                                {/*    className="btn nav-btn favourite-btn active"*/}
                                {/*>*/}
                                {/*  <i className="ri-star-fill"></i>*/}
                                {/*</button>*/}

                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle className="btn nav-btn" tag="a">
                                        <i className="ri-more-2-fill"/>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem href="#"
                                                      onClick={onToggleArchive}
                                        >
                                            {chatUserDetails.isArchive ? (
                                                <>
                                                    <i className="ri-inbox-unarchive-line align-bottom text-muted me-2"/>{" "}
                                                    {t('common:unArchive')}
                                                </>
                                            ) : (
                                                <>
                                                    <i className="ri-inbox-archive-line align-bottom text-muted me-2"/>{" "}
                                                    {t('common:archive')}
                                                </>
                                            )}
                                        </DropdownItem>
                                        {/*<DropdownItem href="#">*/}
                                        {/*  <i className="ri-mic-off-line align-bottom text-muted me-2"></i>*/}
                                        {/*  {t('common:muted')}*/}
                                        {/*</DropdownItem>*/}
                                        {/*<DropdownItem href="#">*/}
                                        {/*  <i className="ri-delete-bin-5-line align-bottom text-muted me-2"></i>*/}
                                        {/*  Delete*/}
                                        {/*</DropdownItem>*/}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3 d-flex flex-column justify-content-center align-items-center text-center">
                <div className="d-flex flex-row flex-shrink-1 justify-around position-relative">
                <img
                    src={avatar}
                    alt=""
                    className="avatar-lg img-thumbnail rounded-circle mx-auto"
                />
              {handlerImage ? (
                  <div className="handlerIcon">
                    <img src={handlerImage} alt=""/>
                  </div>
              ) : ''}
              </div>
                <div className="mt-3">
                    <h5 className="fs-16 mb-1">
                        <Link to="#" className="link-primary username">
                            {fullName}
                        </Link>
                    </h5>

                        {chatUserDetails.status && (
                            <p className="text-muted font-size-14 text-truncate">
                                <i
                                    className={classnames(
                                        "ri-checkbox-blank-circle-fill",
                                        "align-bottom",
                                        "me-1",
                                        {
                                            "text-success":
                                                chatUserDetails.status === STATUS_TYPES.ACTIVE,
                                        },
                                        {
                                            "text-warning":
                                                chatUserDetails.status === STATUS_TYPES.AWAY,
                                        },
                                        {
                                            "text-danger":
                                                chatUserDetails.status === STATUS_TYPES.DO_NOT_DISTURB,
                                        }
                                    )}
                                />{" "}
                                {chatUserDetails.status}
                            </p>
                        )}
                </div>
                <div className="d-flex gap-2 justify-content-center">
                    <button
                        type="button"
                        className="btn avatar-xs p-0"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Message"
                    >
              <span className="avatar-title rounded bg-light text-body">
                <i className="ri-question-answer-line"></i>
              </span>
                    </button>

                    <button
                        type="button"
                        className="btn avatar-xs p-0"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Favourite"
                    >
              <span className="avatar-title rounded bg-light text-body">
                <i className="ri-star-line"></i>
              </span>
                    </button>

                    <button
                        type="button"
                        className="btn avatar-xs p-0"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Phone"
                    >
              <span className="avatar-title rounded bg-light text-body">
                <i className="ri-phone-line"></i>
              </span>
                    </button>
                </div>
            </div>



            {/*  <div className="border-top border-top-dashed p-3">*/}
            {/*  <h5 className="fs-15 mb-3">Personal Details</h5>*/}
            {/*  <div className="mb-3">*/}
            {/*    <p className="text-muted text-uppercase fw-medium fs-12 mb-1">*/}
            {/*      Number*/}
            {/*    </p>*/}
            {/*    <h6>+(256) 2451 8974</h6>*/}
            {/*  </div>*/}
            {/*  <div className="mb-3">*/}
            {/*    <p className="text-muted text-uppercase fw-medium fs-12 mb-1">*/}
            {/*      Email*/}
            {/*    </p>*/}
            {/*    <h6>lisaparker@gmail.com</h6>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <p className="text-muted text-uppercase fw-medium fs-12 mb-1">*/}
            {/*      Location*/}
            {/*    </p>*/}
            {/*    <h6 className="mb-0">California, USA</h6>*/}
            {/*  </div>*/}
            {/*</div>*/}
            </div>
        </>
    );
};

export default ProfileUser;
