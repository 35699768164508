import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getContentQuickAnswers = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_QUICK_ANSWER+"?"+query);
};

const sendDataContentQuickAnswer = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_QUICK_ANSWER, data);
};

const removeContentQuickAnswer = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_QUICK_ANSWER, data);
};

export {
    getContentQuickAnswers,
    sendDataContentQuickAnswer,
    removeContentQuickAnswer
};
