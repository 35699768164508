import React, {useEffect, useState} from "react";
import {Input, Label} from 'reactstrap';
import Form from "react-bootstrap/Form";

interface InputCountProps {
  id: string;
  value: number;
  onChange: (value: number) => void;
  className?: string;
  min: 0 | number;
  max: 100 | number;
}
const InputCount = ({ id, value, onChange, className, min, max }: InputCountProps) => {

    const [currentValue, setCurrentValue] = useState<number>( 1 );

    useEffect(() => {
        if (value!==undefined) {
            setCurrentValue(value);
        }
    }, [value]);

    function countUP() {
        if (currentValue<max) {
            onChangeText(currentValue + 1);
        }
    }

    function countDown() {
        if (currentValue>min){
            onChangeText(currentValue-1);
        }
    }

    const onChangeText = (value:number) => {
        setCurrentValue(value);
        onChange(value);
    }

    return (
      <>
          <div className="input-step border-0" id={id}>
              <button
                  type="button"
                  className="minus"
                  onClick={() => {
                      countDown();
                  }}
              >
                  –
              </button>
              <Input
                  type="number"
                  className=""
                  value={currentValue}
                  min={min}
                  max={max}
                  readOnly
              />
              <button
                  type="button"
                  className="plus"
                  onClick={() => {
                      countUP();
                  }}
              >
                  +
              </button>
          </div>
      </>
  );
};
export default InputCount;
