import { takeEvery, fork, put, all, call } from "typed-redux-saga";

import {
  getCurrentCountry as getCurrentCountryApi,
} from "../../../api";

import {mainCountryApiResponseError, mainCountryApiResponseSuccess} from "./actions";
import {MainCurrentCountryActionTypes} from "./types";

function* getMainCurrentCountry() {
  try {
    const response: Promise<any> = yield call(getCurrentCountryApi);
    yield put(mainCountryApiResponseSuccess(MainCurrentCountryActionTypes.GET_CURRENT_COUNTRY, response));
  } catch (error: any) {
    yield put(mainCountryApiResponseError(MainCurrentCountryActionTypes.GET_CURRENT_COUNTRY, error));
  }
}

export function* watchGetMainCurrentCountry() {
  yield takeEvery(MainCurrentCountryActionTypes.GET_CURRENT_COUNTRY, getMainCurrentCountry);
}

function* mainCurrentCountrySaga() {
  yield all([
    fork(watchGetMainCurrentCountry),
  ]);
}

export default mainCurrentCountrySaga;
