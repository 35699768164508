// types
import {ContentConnectorHandlerActionTypes, ContentConnectorHandlerState, ContentConnectorHandlerType} from "./types";
import {getHandlerIconByCode} from "../../../helpers/functions";


export const INIT_STATE_CONTENT_CONNECTOR_HANDLER: ContentConnectorHandlerState = {
    items: [],
    isConnectorHandlerFetched: false
};

const ContentConnectorHandler = (state = INIT_STATE_CONTENT_CONNECTOR_HANDLER, action: any) => {
  switch (action.type) {
    case ContentConnectorHandlerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentConnectorHandlerActionTypes.GET_CONTENT_CONNECTOR_HANDLERS:

          (action.payload.data.items || []).map((item:ContentConnectorHandlerType)=>{
              item.icon = getHandlerIconByCode(item.code)
              return item;
          });

          return {
            ...state,
            items: action.payload.data.items,
            isConnectorHandlerFetched: true,
            getConnectorHandlerLoading: false,
          };
        default:
          return { ...state };
      }

    case ContentConnectorHandlerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentConnectorHandlerActionTypes.GET_CONTENT_CONNECTOR_HANDLERS:
          return {
            ...state,
            isConnectorHandlerFetched: false,
            getConnectorHandlerLoading: false,
          };
        default:
          return { ...state };
      }
    case ContentConnectorHandlerActionTypes.SELECT_CONTENT_CONNECTOR_HANDLER:
      return {
        ...state,
        selectedItem: action.payload.data,
      }
    default:
      return { ...state };
  }
};

export default ContentConnectorHandler;
