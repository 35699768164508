import React, { useCallback } from 'react';

export const useConnectionCallback = (dispatch: React.Dispatch<any>) => {
    const callback = useCallback((ev: Event) => {
        dispatch({ type: 'CONNECT', payload: {} });
    }, [dispatch]);
    return callback;
};

export const useDisconnectionCallback = (dispatch: React.Dispatch<any>) => {
    const callback = useCallback((ev: Event) => {
        dispatch({ type: 'DISCONNECT', payload: {} });
    }, [dispatch]);
    return callback;
};

export const useMessageCallback = (dispatch: React.Dispatch<any>) => {

    const callback = useCallback(
        (ev: MessageEvent<any>) => {
            dispatch({ type: 'NEW_MESSAGE', payload: ev.data});
        },
        [dispatch]
    );

    return callback;
};