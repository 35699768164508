import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentProjects = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PROJECTS);
};

const sendDataContentProjects = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_PROJECTS, data);
};

const removeContentProject = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_PROJECTS, data);
};

export {
  getContentProjects,
  sendDataContentProjects,
  removeContentProject
};
