import React, {useCallback, useEffect, useState} from "react";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../../hooks";
import {OrderType} from "../../redux/Sale/Order/types";
import {newOrderItem, OrderItemType} from "../../redux/Sale/OrderItem/types";
import {
    Formik,
    Form,
    Field
} from 'formik';
import InputSelect from "../InputSelect";
import InputText from "../InputText";
import InputTextAddon from "../InputTextAddon";
import {CURRENCY_SYMBOL_EXTRACT_REGEX} from "../../data/settings";
import {PriceTypeType} from "../../redux/Sale/PriceType/types";
import {ProductPriceType} from "../../redux/Sale/Price/types";
import {ProductType} from "../../redux/Sale/Product/types";
import {onChangeSaleOrderItems, onSearchSaleOrderProducts} from "../../redux/Sale/Order/actions";
import {MainMetaType} from "../../redux/Main/common";
import {ProductParamValueType} from "../../redux/Sale/ProductParamValue/types";
import {ProductParamListValueType, ProductParamType, ProductParamTypes} from "../../redux/Sale/ProductParam/types";
import {isNumeric} from "../../helpers/functions";
import {DeliveryType} from "../../redux/Sale/Delivery/types";
import Button from "react-bootstrap/Button";
import ButtonSpinner from "../ButtonSpinner";
import {SaleOrderItemsValidatorSchema} from "./orderItemsValidator";


interface ItemPriceProps {
    item: OrderItemType;
}

const ShowTotalItemPriceBlock = ({item}: ItemPriceProps) => {

    const { t, i18n } = useTranslation(['plan']);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    return (
        <>
            {item?.currency?.currencyFormat &&
                <>
                <div className="text-end text-nowrap">
                    {item?.price && item.currency.currencyFormat.replace(/#/,
                        FormatValue(item.price * item.quantity))
                    }
                </div>
                {item?.discount>0 &&
                    <div className="text-muted text-end fs-12 text-nowrap">
                        {item.currency.currencyFormat.replace(/#/,
                            FormatValue(item.discount * item.quantity * -1))
                        }
                    </div>
                }
                </>
            }
        </>
    );
}

interface OrderCartTotalProps {
    order: OrderType;
    orderItems: OrderItemType[];
}

const ShowTotalCartBlock = ({order, orderItems}: OrderCartTotalProps) => {

    const { t, i18n } = useTranslation(['plan']);

    const [cartSum, setCartSum] = useState<number>(0);
    const [cartDiscountSum, setCartDiscountSum] = useState<number>(0);
    const [deliverySum, setDeliverySum] = useState<number>(0);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);


    useEffect(()=>{

        let _cartSum = 0;
        let _cartDiscountSum = 0;

        if ((orderItems || []).length>0){
            orderItems.map((orderItem:OrderItemType)=>{
                if (orderItem?.price && isNumeric(orderItem.price)){
                    _cartSum+=orderItem.price * orderItem.quantity;
                }
                if (orderItem?.discount && isNumeric(orderItem.discount)){
                    _cartDiscountSum+=orderItem.discount * orderItem.quantity;
                }
                return orderItem;
            })
        }

        setCartSum(_cartSum);
        setCartDiscountSum(_cartDiscountSum);


    },[orderItems])



    useEffect(() => {

        let _deliverySum = 0;

        if ((order?.deliveryItems || []).length>0){
            order.deliveryItems.map((deliveryItem:DeliveryType)=>{
                if (deliveryItem?.amount && isNumeric(deliveryItem.amount)){
                    _deliverySum+=deliveryItem.amount;
                }
                return deliveryItem;
            })
        }

        setDeliverySum(_deliverySum);

    },[order]);

    return (
        <>
            <table className="table table-borderless mb-0">
                <tbody>
                <tr>
                    <td>{t('sale:subTotal')}:</td>
                    <td className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(cartSum))}</td>
                </tr>
                {cartDiscountSum && cartDiscountSum>0 ?
                    <tr>
                        <td>
                            {t('sale:discount')}:
                        </td>
                        <td className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(cartDiscountSum * -1))}</td>
                    </tr>
                    :''
                }
                {deliverySum && deliverySum>0 ?
                    <tr>
                        <td>
                            {t('delivery:deliveryTotal')}:
                        </td>
                        <td className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(deliverySum))}</td>
                    </tr>
                    :''
                }
                <tr className="border-top border-top-dashed">
                    <th scope="row">{t('sale:total')} ({order.currency.code}):
                    </th>
                    <th className="text-end">{order.currency.currencyFormat.replace(/#/,FormatValue(cartSum-cartDiscountSum+deliverySum))}</th>
                </tr>
                </tbody>
            </table>
        </>
    );
}

interface ItemProps {
    item: OrderType;
    isLoading: boolean;
    handleEditCancel: ()=>void
    isStatusEdit: boolean;
    priceTypes: PriceTypeType[];
    productParams: ProductParamType[];
}

const OrderItemEdit = ({ item, isLoading, handleEditCancel, isStatusEdit, priceTypes, productParams }: ItemProps) => {

    const { t, i18n } = useTranslation(['plan']);
    const { dispatch, useAppSelector } = useRedux();

    const [formValues, setFormValues] = useState<OrderType>(null);
    const [searchProducts, setSearchProducts] = useState<any>([]);
    const [focusedProduct, setFocusedProduct] = useState<number>(0);
    const [order, setOrder] = useState<OrderType>(null);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const {
        saleOrder
    } = useAppSelector(state => ({
        saleOrder: state.SaleOrder
    }));

    useEffect(()=>{
        setFormValues({...order});
    },[order])

    const handleSearchProduct = (event: any, focusedProduct: number) => {
        setFocusedProduct(focusedProduct);

        if (event.length>1) {

            let mainMeta: MainMetaType={
                searchPhrase: event,
                maxResults: 10,
                page: 1
            }

            dispatch(onSearchSaleOrderProducts(mainMeta));
        }
    }

    const updateProduct = (product: ProductType) => {

        let _cartProductParamsValues=[];

        (product?.productParamValues || []).map((productParamValue:ProductParamValueType) => {

            if (productParamValue?.productParam?.isCart && productParamValue.productParam.isCart) {
                switch (productParamValue.productParam.type) {
                    case ProductParamTypes.LIST:
                        let _productParam = (productParams || []).filter((productParam: ProductParamType)=>{
                            return productParam.uuid ===productParamValue?.productParam?.uuid
                        }).shift();

                        if (_productParam?.uuid){
                            let _productParamListValue = (_productParam?.values || []).filter((productParamListValue: ProductParamListValueType)=>{
                                return productParamListValue.uuid === productParamValue.value
                            }).shift();

                            if (_productParamListValue?.value){
                                _cartProductParamsValues.push(_productParamListValue.value);
                            }
                        }
                        break
                    default:
                        _cartProductParamsValues.push(productParamValue.value);
                }
            }

            return productParamValue;
        })

        if ((_cartProductParamsValues || []).length>0){

            let offerData = " / "+_cartProductParamsValues.join(" / ");

            if (product.name.indexOf(offerData) === -1){
                product.name += offerData;
            }

        }

        return product;
    }

    useEffect(()=>{

        let _item = item;

        (_item.orderItems || []).map((orderItem:OrderItemType)=>{
            orderItem.product = updateProduct(orderItem.product);
            return orderItem;
        });

        setOrder(_item);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item])

    useEffect(() => {

        let _searchProducts = searchProducts;

        if (saleOrder?.searchProducts && (saleOrder.searchProducts ||[]).length>0) {
            _searchProducts[focusedProduct]=(saleOrder.searchProducts || []).map((product:ProductType)=>{

                product = updateProduct(product);

                return product;
            });
        } else {
            _searchProducts[focusedProduct]=[];
        }

        setSearchProducts(_searchProducts);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleOrder?.searchProducts]);


    const handleAddOrderItem = () => {

        let _newOrderItem:OrderItemType = {
            ...newOrderItem,
            currency: order.currency
        }
        let _order : OrderType = order;

        _order={
            ..._order,
            orderItems:[
                ..._order.orderItems,
                _newOrderItem
            ]
        };

        setOrder({..._order});
    }

    const handleRemoveOrderItem = (removeProductLine: number) => {

        let _order : OrderType = order;

        _order= {
            ..._order,
            orderItems:(_order.orderItems || []).filter((orderItem:OrderItemType, key: number)=>{
                return key!==removeProductLine;
            })
        };

        setOrder({..._order});
    }


    const onDataSubmit = (formData:OrderType) =>{

        let params:OrderType={
            uuid: order?.uuid ? (order.uuid.match(/new_/)?null:order.uuid) : null,
            orderItems: (formData.orderItems || []).map((orderItem:OrderItemType)=>{

                if (orderItem['product']['uuid'] !== undefined) {
                    orderItem={...orderItem,
                        name: orderItem['product']['name'] && orderItem['product']['name'],
                        product:{
                            uuid:orderItem['product']['uuid']
                        }
                    }
                } else if (orderItem['product']['value'] !== undefined){
                    orderItem={...orderItem,
                        name: orderItem['product']['name'] && orderItem['product']['name'],
                        product:{
                            uuid:orderItem['product']['value']
                        }
                    }
                } else if (orderItem['product'] !== undefined) {
                    orderItem={...orderItem,
                        name: orderItem['product']['name'] && orderItem['product']['name'],
                        product:orderItem['product']
                    }
                }

                if (orderItem['currency']['uuid'] !== undefined) {
                    orderItem={...orderItem,
                        currency:{
                            uuid:orderItem['currency']['uuid']
                        }
                    }
                } else if (orderItem['currency']['value'] !== undefined){
                    orderItem={...orderItem,
                        currency:{
                            uuid:orderItem['currency']['value']
                        }
                    }
                } else if (orderItem['currency'] !== undefined) {
                    orderItem={...orderItem,
                        currency:orderItem['currency']
                    }
                }

                if (orderItem['priceType']['uuid'] !== undefined) {
                    orderItem={...orderItem,
                        priceType:{
                            uuid:orderItem['priceType']['uuid']
                        }
                    }
                } else if (orderItem['priceType']['value'] !== undefined){
                    orderItem={...orderItem,
                        priceType:{
                            uuid:orderItem['priceType']['value']
                        }
                    }
                } else if (orderItem['priceType'] !== undefined) {
                    orderItem={...orderItem,
                        priceType:orderItem['priceType']
                    }
                }

                return orderItem;
            })
        };

        if (params?.uuid === null){
            params={
                ...order,
                ...params
            }
        }

        dispatch(onChangeSaleOrderItems(params));
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            handleEditCancel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (isStatusEdit) {
            document.addEventListener("keydown", escFunction, false);

            return () => {
                document.removeEventListener("keydown", escFunction, false);
            };
        }
    }, [escFunction, item, isStatusEdit]);

    return (
        <>
            {(formValues?.orderItems || []).length>0 && order?.currency !== undefined && order?.currency !== null &&
            <Formik
                enableReinitialize={true}
                initialValues={formValues}
                validationSchema={SaleOrderItemsValidatorSchema}
                onSubmit={(values, actions) => {
                    onDataSubmit(values);
                    actions.setSubmitting(false);
                }}
            >
                {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {

                    return(
                    <Form noValidate={true} className="customValidation d-flex flex-grow-1 flex-column">
                        <div className="table-card">
                            <table className="table table-nowrap align-middle table-borderless mb-0">
                                <thead className="table-light text-muted">
                                <tr>
                                    <th scope="col">{t('sale:productDetails')}</th>
                                    <th scope="col" className="text-center w130px">{t('price:priceType')}</th>
                                    <th scope="col" className="text-center w130px">{t('sale:itemPrice')}</th>
                                    <th scope="col" className="text-center w130px">{t('sale:discount')}</th>
                                    <th scope="col" className="text-center w130px">{t('sale:quantity')}</th>
                                    <th scope="col" className="text-center w100px">{t('sale:amount')}</th>
                                    <th scope="col" className="text-end"></th>
                                </tr>
                                </thead>
                                <tbody>
                        {(getFieldProps('orderItems')?.value || []).map((orderItem:OrderItemType, orderItemKey:number)=>{
                            return(
                                <tr key={"orderItemEdit_"+orderItemKey}>
                                    <td>
                                        <div className={ "d-flex flex-grow-1 flex-column "+ ((touched?.orderItems && touched.orderItems[orderItemKey]?.product) ? " was-validated" : '')}>
                                            <Field
                                                id={"form-orderItems-product-input_"+orderItemKey}
                                                name={"orderItems-product_"+orderItemKey}
                                                placeholder={t('product:chooseProduct')}
                                                component={InputSelect}
                                                value={getFieldProps('orderItems')?.value[orderItemKey]?.product}
                                                onChange={(value: any) => {

                                                    let _orderItemData:OrderItemType=null;

                                                    let _orderItems = (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{
                                                        if (orderItemFormKey === orderItemKey){

                                                            let foundProduct = (searchProducts[orderItemKey] || []).filter((product: ProductType)=>{
                                                                return product.uuid === value.value
                                                            }).shift();

                                                            if (foundProduct?.uuid){
                                                                let foundProductPrice = (foundProduct.productPrices || []).filter((productPrice: ProductPriceType)=>{
                                                                    return productPrice.priceType.uuid === orderItemForm?.priceType?.uuid && productPrice.currency.uuid === orderItemForm.currency.uuid
                                                                }).shift();

                                                                orderItemForm={
                                                                    ...orderItemForm,
                                                                    product: foundProduct,
                                                                    price: foundProductPrice?.uuid && foundProductPrice.price
                                                                }

                                                                _orderItemData = orderItemForm;
                                                            }
                                                        }

                                                        return orderItemForm;
                                                    })

                                                    if (_orderItemData !== null){
                                                        order.orderItems[orderItemKey]=_orderItemData;
                                                    }

                                                    setFieldValue('orderItems', _orderItems);
                                                }}
                                                onKeyPress = {(event)=>{handleSearchProduct(event, orderItemKey)}}
                                                isLoading={ focusedProduct === orderItemKey && saleOrder.isSearchProducts}
                                                items={searchProducts[orderItemKey] || []}
                                                className={"position-relative flex-grow-1"}
                                            />
                                            {// @ts-ignore: Unreachable code error
                                                errors?.orderItems && errors.orderItems[orderItemKey]?.product && touched?.orderItems && touched.orderItems[orderItemKey]?.product ? (
                                                <div className="invalid-feedback">
                                                    {// @ts-ignore: Unreachable code error
                                                        t('validator:'+errors.orderItems[orderItemKey]?.product)}
                                                </div>
                                            ) : touched?.orderItems && touched.orderItems[orderItemKey]?.product && (!errors?.orderItems ||
                                                    // @ts-ignore: Unreachable code error
                                                    !errors.orderItems[orderItemKey]?.product) ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className={"d-flex flex-grow-1 flex-column mw-100 "+(touched?.orderItems && touched['orderItems'][orderItemKey]?.priceType ? " was-validated" : '')}>
                                            <Field
                                                id={"form-orderItems-priceType-input_"+orderItemKey}
                                                name={"orderItems-priceType_"+orderItemKey}
                                                placeholder={t('price:priceType')}
                                                component={InputSelect}
                                                value={
                                                    getFieldProps('orderItems')?.value[orderItemKey]?.priceType
                                                }
                                                onChange={(value: any) => {

                                                    let _orderItemData:OrderItemType=null;

                                                    let _orderItems = (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{

                                                        if (orderItemFormKey === orderItemKey){

                                                            let foundProductPrice = (orderItemForm?.product?.productPrices || []).filter((productPrice: ProductPriceType)=>{
                                                                return productPrice.priceType.uuid === value.value && productPrice.currency.uuid === orderItemForm.currency.uuid
                                                            }).shift();

                                                            orderItemForm={
                                                                ...orderItemForm,
                                                                priceType: (priceTypes || []).filter((priceType:PriceTypeType)=>{return priceType.uuid === value.value}).shift(),
                                                                price: foundProductPrice?.uuid && foundProductPrice.price
                                                            }

                                                            _orderItemData = orderItemForm;
                                                        }

                                                        return orderItemForm;
                                                    })

                                                    if (_orderItemData !== null){
                                                        order.orderItems[orderItemKey]=_orderItemData;
                                                    }

                                                    setFieldValue('orderItems', _orderItems);
                                                }}
                                                items={priceTypes || []}
                                                className={"flex-grow-1 "}
                                            />
                                            {
                                                // @ts-ignore: Unreachable code error
                                                errors?.orderItems && errors.orderItems[orderItemKey]?.priceType && touched?.orderItems && touched.orderItems[orderItemKey]?.priceType ? (
                                                <div className="invalid-feedback">
                                                    {// @ts-ignore: Unreachable code error
                                                        t('validator:'+errors.orderItems[orderItemKey]?.priceType)}
                                                </div>
                                            ) : touched?.orderItems && touched.orderItems[orderItemKey]?.priceType && (!errors?.orderItems ||
                                                    // @ts-ignore: Unreachable code error
                                                    !errors.orderItems[orderItemKey]?.priceType) ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>

                                    </td>
                                    <td className="text-center">
                                                <Field
                                                    id={"form-orderItems-price-input_"+orderItemKey}
                                                    name={"orderItems-price_"+orderItemKey}
                                                    placeholder={t('price:price')}
                                                    component={InputTextAddon}
                                                    value = {getFieldProps('orderItems')?.value[orderItemKey]?.price}
                                                    onChange={(value:string) => {

                                                        let _orderItemData:OrderItemType=null;

                                                        let _orderItems = (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{

                                                            if (orderItemFormKey === orderItemKey){
                                                                orderItemForm={
                                                                    ...orderItemForm,
                                                                    price: value.length>0 ? parseFloat(value) : 0
                                                                }

                                                                _orderItemData = orderItemForm;
                                                            }

                                                            return orderItemForm;
                                                        })

                                                        if (_orderItemData !== null){
                                                            order.orderItems[orderItemKey]=_orderItemData;
                                                        }

                                                        setFieldValue('orderItems', _orderItems);
                                                    }}
                                                    addon={order.currency.currencyFormat.replace(CURRENCY_SYMBOL_EXTRACT_REGEX,'')}
                                                    className={"text-end "+
                                                        // @ts-ignore: Unreachable code error
                                                        ((errors?.orderItems && errors.orderItems[orderItemKey]?.price && touched?.orderItems && touched.orderItems[orderItemKey]?.price) ? 'form-control is-invalid':'')}
                                                />
                                            <div className={ ""+ (
                                                (touched?.orderItems && touched['orderItems'][orderItemKey]?.price) ? " was-validated" : '')}>
                                                {errors?.orderItems &&
                                                // @ts-ignore: Unreachable code error
                                                errors.orderItems[orderItemKey]?.price && touched?.orderItems && touched.orderItems[orderItemKey]?.price ? (
                                                    <div className="invalid-feedback">
                                                        {// @ts-ignore: Unreachable code error
                                                            t('validator:'+errors.orderItems[orderItemKey]?.price)}
                                                    </div>
                                                ) :  (touched?.orderItems && touched.orderItems[orderItemKey]?.price) && (!errors?.orderItems ||
                                                    // @ts-ignore: Unreachable code error
                                                    !errors.orderItems[orderItemKey]?.price) ?
                                                    <div className="valid-feedback">
                                                        {t('common:looksGood')}
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                    </td>
                                    <td className="text-center">
                                            <Field
                                                id={"form-orderItems-discount-input_"+orderItemKey}
                                                name={"orderItems-discount"+orderItemKey}
                                                placeholder={t('sale:discount')}
                                                component={InputTextAddon}
                                                value = {getFieldProps('orderItems')?.value[orderItemKey]?.discount}
                                                onChange={(value:string) => {

                                                    let _orderItemData:OrderItemType=null;

                                                    let _orderItems = (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{

                                                        if (orderItemFormKey === orderItemKey){
                                                            orderItemForm={
                                                                ...orderItemForm,
                                                                discount: value.length>0 ? parseFloat(value) : 0
                                                            }

                                                            _orderItemData=orderItemForm;
                                                        }

                                                        return orderItemForm;
                                                    })

                                                    if (_orderItemData !== null){
                                                        order.orderItems[orderItemKey]=_orderItemData;
                                                    }

                                                    setFieldValue('orderItems', _orderItems);
                                                }}
                                                addon={order.currency.currencyFormat.replace(CURRENCY_SYMBOL_EXTRACT_REGEX,'')}
                                                className={"text-end "+
                                                    // @ts-ignore: Unreachable code error
                                                    ((errors?.orderItems && errors.orderItems[orderItemKey]?.discount && touched?.orderItems && touched.orderItems[orderItemKey]?.discount) ? 'form-control is-invalid':'')}
                                            />
                                        <div className={ ""+ (
                                            (touched?.orderItems && touched['orderItems'][orderItemKey]?.discount) ? " was-validated" : '')}>
                                            {errors?.orderItems &&
                                            // @ts-ignore: Unreachable code error
                                            errors.orderItems[orderItemKey]?.discount && touched?.orderItems && touched.orderItems[orderItemKey]?.discount ? (
                                                <div className="invalid-feedback">
                                                    {// @ts-ignore: Unreachable code error
                                                        t('validator:'+errors.orderItems[orderItemKey]?.discount)}
                                                </div>
                                            ) :  (touched?.orderItems && touched.orderItems[orderItemKey]?.discount) && (!errors?.orderItems ||
                                                // @ts-ignore: Unreachable code error
                                                !errors.orderItems[orderItemKey]?.discount) ?
                                                <div className="valid-feedback">
                                                    {t('common:looksGood')}
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <div className={"d-flex flex-nowrap flex-row"}>
                                        <div className="d-flex flex-shrink-0 flex-column me-1 justify-content-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-icon waves-effect waves-light btn-sm"
                                                onClick={()=>{

                                                    let _orderItemData:OrderItemType=null;

                                                    let _orderItems = (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{

                                                        if (orderItemFormKey === orderItemKey){
                                                            orderItemForm={
                                                                ...orderItemForm,
                                                                quantity: orderItemForm.quantity>1 ? orderItemForm.quantity-1 : 1
                                                            }

                                                            _orderItemData = orderItemForm;
                                                        }

                                                        return orderItemForm;
                                                    })

                                                    if (_orderItemData !== null){
                                                        order.orderItems[orderItemKey]=_orderItemData;
                                                    }

                                                    setFieldValue('orderItems', _orderItems);
                                                }}
                                            >
                                                <i className="ri-subtract-fill"/>
                                            </button>
                                        </div>
                                        <div>
                                            <Field
                                                id={"form-orderItems-quantity-input_"+orderItemKey}
                                                name={"orderItems-quantity_"+orderItemKey}
                                                placeholder={t('sale:quantity')}
                                                component={InputText}
                                                value = {getFieldProps('orderItems')?.value[orderItemKey]?.quantity}
                                                onChange={(value:string) => {

                                                    let _orderItemData:OrderItemType=null;

                                                    let _orderItems = (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{

                                                        if (orderItemFormKey === orderItemKey){
                                                            orderItemForm={
                                                                ...orderItemForm,
                                                                quantity: parseFloat(value)
                                                            }

                                                            _orderItemData = orderItemForm;
                                                        }

                                                        return orderItemForm;
                                                    })

                                                    if (_orderItemData !== null){
                                                        order.orderItems[orderItemKey]=_orderItemData;
                                                    }

                                                    setFieldValue('orderItems', _orderItems);
                                                }}

                                                className={"text-center "+
                                                    // @ts-ignore: Unreachable code error
                                                    ((errors?.orderItems && errors.orderItems[orderItemKey]?.quantity && touched?.orderItems && touched.orderItems[orderItemKey]?.quantity) ? 'form-control is-invalid':'')}
                                            />
                                        </div>
                                        <div className="d-flex flex-shrink-0 flex-column ms-1 justify-content-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-icon waves-effect waves-light btn-sm"
                                                onClick={()=>{

                                                    let _orderItemData:OrderItemType=null;

                                                    let _orderItems = (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{

                                                        if (orderItemFormKey === orderItemKey){
                                                            orderItemForm={
                                                                ...orderItemForm,
                                                                quantity: orderItemForm.quantity+1
                                                            }

                                                            _orderItemData = orderItemForm;
                                                        }

                                                        return orderItemForm;
                                                    })

                                                    if (_orderItemData !== null){
                                                        order.orderItems[orderItemKey]=_orderItemData;
                                                    }

                                                    setFieldValue('orderItems', _orderItems);
                                                    }
                                                }
                                            >
                                                <i className="ri-add-fill"/>
                                            </button>
                                        </div>
                                        </div>
                                        <div className={(
                                            (touched?.orderItems && touched['orderItems'][orderItemKey]?.quantity) ? " was-validated" : '')}>
                                        {errors?.orderItems &&
                                        // @ts-ignore: Unreachable code error
                                        errors.orderItems[orderItemKey]?.quantity && touched?.orderItems && touched.orderItems[orderItemKey]?.quantity ? (
                                            <div className="invalid-feedback">
                                                {// @ts-ignore: Unreachable code error
                                                    t('validator:'+errors.orderItems[orderItemKey]?.quantity)}
                                            </div>
                                        ) :  (touched?.orderItems && touched.orderItems[orderItemKey]?.quantity) && (!errors?.orderItems ||
                                            // @ts-ignore: Unreachable code error
                                            !errors.orderItems[orderItemKey]?.quantity) ?
                                            <div className="valid-feedback">
                                                {t('common:looksGood')}
                                            </div>
                                            : ''
                                        }
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        {
                                            // eslint-disable-next-line
                                            (getFieldProps('orderItems').value || []).map((orderItemForm:OrderItemType, orderItemFormKey:number)=>{
                                            if (orderItemFormKey === orderItemKey){
                                                return (
                                                    <div key={"orderItemTotal_"+orderItemKey} className="d-flex flex-column">
                                                        <ShowTotalItemPriceBlock
                                                            item={orderItemForm}
                                                        />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </td>
                                    <td className="text-end">
                                        {(getFieldProps('orderItems').value || []).length>1 &&
                                        <button
                                            onClick={()=>{handleRemoveOrderItem(orderItemKey)}}
                                            type="button"
                                            title={t('common:delete')}
                                            className="btn btn-outline-danger btn-icon waves-effect waves-light">
                                            <i className="ri-delete-bin-5-line"/>
                                        </button>
                                        }
                                    </td>
                                </tr>
                            )
                            })
                        }
                                <tr>
                                    <td colSpan={7}>
                                        <button
                                            onClick={handleAddOrderItem}
                                            type="button"
                                            title={t('common:add')}
                                            className="btn btn-outline-primary btn-icon waves-effect waves-light btn-sm">
                                            <i className="ri-add-line"/>
                                        </button>
                                    </td>
                                </tr>
                                <tr className="border-top border-top-dashed">
                                    <td colSpan={3}></td>
                                    <td colSpan={4} className="fw-medium p-0">
                                        <ShowTotalCartBlock
                                            order={order}
                                            orderItems={getFieldProps('orderItems').value}
                                        />
                                    </td>
                                </tr>
                                 <tr>
                                    <td colSpan={7} className="text-end">
                                        <div className="d-flex flex-row flex-nowrap gap-2 justify-content-end">
                                            <Button variant="light"
                                                    onClick={handleEditCancel}
                                                   >
                                                {t('common:cancel')}
                                            </Button>
                                            <Button variant="primary"
                                                    type="submit"
                                                    disabled={saleOrder.isSending}
                                                    className="btn btn-primary chat-send waves-effect waves-light"
                                            >
                                                {t('common:save')}
                                                {saleOrder.isSending &&
                                                    <ButtonSpinner/>
                                                }
                                            </Button>
                                        </div>
                                    </td>
                                 </tr>
                                </tbody>
                            </table>
                        </div>
                    </Form>
                )}}
            </Formik>
            }
        </>
    )
}

export default OrderItemEdit;