import React, {useState, useEffect, useCallback} from "react";
import {Card, CardBody, Progress, Row} from 'reactstrap';

import {Link} from "react-router-dom";
import {
    Formik,
    Form,
    Field
} from 'formik';
import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {MainCabinetType} from "../../../redux/Main/Cabinet/types";
import {useRedux} from "../../../hooks";
import {
    clearSelectUserCabinet,
    createUserCabinet, getUserCabinets, resumeUserCabinet,
    selectCurrentUserCabinet, selectUpdateUserCabinet, updateCabinetLastLogin,
    updateUserCabinet
} from "../../../redux/Main/Cabinet/actions";
import * as route from "../../../api/routes";
import {redirectToDefaultRoute, setCurrentSettings} from "../../../helpers/functions";
import {setSettingsValues} from "../../../redux/Main/Layout/actions";
import InputText from "../../../components/InputText";
import {CabinetNameValidatorSchema} from "./CabinetNameValidator";
import {Spinner} from "react-bootstrap";
import {nameToEmoji} from "gemoji";


const logoCircle = require("../../../resources/images/mcLogoCircle.svg");

interface ItemProps {
    item: MainCabinetType;
    reloadCabinets: () => void;
}

interface ItemCabinetBlockProps {
    item: MainCabinetType;
    url: string;
}

const ListItemCabinetBlock = ({item, url}: ItemCabinetBlockProps) => {

    const { t } = useTranslation(['cabinet']);
    const { dispatch, useAppSelector } = useRedux();
    const [formValues, setFormValues] = useState<MainCabinetType>(null);

    const {
        isSending,
    } = useAppSelector(state => ({
        isSending: state.MainCabinet.isSending,
    }));

    useEffect(()=>{
        setFormValues(item);
    },[item]);

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            dispatch(clearSelectUserCabinet());
        }
    }, [dispatch]);

    useEffect(() => {
        if (item.isEdit) {
            document.addEventListener("keydown", escFunction, false);

            return () => {
                document.removeEventListener("keydown", escFunction, false);
            };
        }
    }, [escFunction, item]);

    const onDataSubmit = (formData:MainCabinetType) => {
        let data: any = {};

        if (formData.name) {
            data["name"] = formData.name;
        }

        let params: MainCabinetType = {
            uuid: item.uuid,
            name: data.name && data.name
        };

        dispatch(updateUserCabinet(params));

        setTimeout(() => {
            dispatch(getUserCabinets());
        },1000);
    };

    return (
        <>
            <div className="flex-shrink-0">
                <img src={logoCircle.default} alt="" className="avatar-xs rounded-circle"/>
            </div>
            <div className={"flex-grow-1 ms-3 mw-0"}>
                <h6 className={"fs-15 mb-1"+(!item.isEdit && ' text-truncate pe-3')}>
                    {url.length>0 ?
                            item.isEdit ?
                                <>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={formValues}
                                    validationSchema={CabinetNameValidatorSchema}
                                    onSubmit={(values, actions) => {
                                        onDataSubmit(values);
                                        actions.setSubmitting(false);
                                    }}
                                >
                                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {
                                        return (
                                        <Form noValidate={true} className="customValidation">
                                            <Row>
                                                <div className="d-flex flex-row">
                                                <div className={''+(touched.name ? " was-validated" : '')}>
                                                    <Field
                                                        id="form-name-input"
                                                        name="name"
                                                        placeholder={t('cabinet:enterCabinetName')}
                                                        component={InputText}
                                                        value = {getFieldProps('name').value}
                                                        onChange={(value:string) => {
                                                            setFieldValue('name', value);
                                                        }}
                                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                                    />
                                                    {errors.name && touched.name ? (
                                                        <div className="invalid-feedback">
                                                            {t('validator:'+errors.name)}
                                                        </div>
                                                    ) :  touched.name && !errors.name ?
                                                        <div className="valid-feedback">
                                                            {t('common:looksGood')}
                                                        </div>
                                                        : ''
                                                    }
                                                </div>
                                                <div>
                                                        <button
                                                            type="submit"
                                                            disabled={isSending}
                                                            className="ms-1 btn btn-outline-primary btn-icon waves-effect waves-light text-align-center">
                                                            {isSending
                                                                ?
                                                                <Spinner size='sm'/>
                                                                :
                                                                <i className="ri-check-double-line"/>
                                                            }
                                                            </button>
                                                </div>
                                                </div>
                                            </Row>
                                        </Form>)}}
                                    </Formik>
                                </>
                                :
                                <>
                                    <span className="text-muted">{t('cabinet:cabinet')}</span> {item.name}
                                </>
                        :
                        t('cabinet:createNewCabinet')
                    }
                </h6>
                {!item.isEdit &&
                <p className="text-info mb-0 fs-12">

                    {  item.plan.activeTo === undefined ?
                        <span className="badge badge-soft-info fs-12">{t('plan:'+item.plan.name)}</span>
                        :
                        <span className="badge badge-soft-success fs-12">{t('plan:'+item.plan.name)}</span>
                    }
                    <span className="float-end">
                                     {url.length>0 ?
                                         <>
                                             {item?.isSuspended === true ?
                                                 <>
                                                     {nameToEmoji.sleeping}
                                                 </>
                                                 :
                                                 <>
                                            <span className="me-1 align-middle">
                                                {t('cabinet:open')}
                                            </span>
                                             <i className="ri-arrow-right-line text-info fs-12 align-middle"/>
                                                     </>
                                             }
                                         </>
                                         :
                                         <>
                                             <i className="ri-add-line text-info fs-12 align-middle"/>
                                             <span className="ms-1 align-middle">
                                                {t('cabinet:addNew')}
                                             </span>
                                         </>
                                     }
                                </span>
                </p>
                }
            </div>
        </>
    );
}

const ListItemV2 = ({ item, reloadCabinets }: ItemProps) => {

    const { t } = useTranslation(['cabinet']);

    const { dispatch, useAppSelector } = useRedux();

    const url = item.domain && item.domain.length>0 ? item.domain : '';
    const [progressValue, setProgressValue] = useState<number>(0);
    const [startProgress, setStartProgress] = useState<boolean>(false);
    const [intervalHandler, setIntervalHandler] = useState<any>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [showEditButton, setShowEditButton] = useState<boolean>(false);


    const {
        cabinetState,
        defaultDashboard
    } = useAppSelector(state => ({
        cabinetState: state.MainCabinet,
        defaultDashboard: state.Layout.defaultDashboard
    }));

    useEffect(() => {
        if (!cabinetState.isCreating && startProgress){
            stopProgressHandler();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cabinetState.isCreating]);

    useEffect(() => {
        if (startProgress) {
            const interval = setInterval(() => {
                setProgressValue(progressValue => progressValue + 1);
            }, 600);

            setIntervalHandler(interval);

            return () => clearInterval(interval);
        } else {
            setProgressValue(0);
        }
    }, [startProgress]);

    const stopProgressHandler = () => {
        clearInterval(intervalHandler);
        reloadCabinets();
        setDisabled(false);
        setProgressValue(0);
        setStartProgress(false);
    }

    useEffect(() => {
        if (progressValue>=100){
            stopProgressHandler();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressValue]);

    const createNewCabinetHandle = () => {
        setStartProgress(true);
        setDisabled(true);
        dispatch(createUserCabinet());
    }

    const handleSelectCurrentCabinet = (cabinet:MainCabinetType) => {

        if (cabinet?.isSuspended === true) {
            setStartProgress(true);
            setDisabled(true);

            dispatch(resumeUserCabinet(cabinet));

        } else {
            dispatch(selectCurrentUserCabinet(cabinet));

            if (cabinet.settings !== undefined && cabinet.settings !== null) {
                setCurrentSettings(cabinet.settings);
                dispatch(setSettingsValues(cabinet.settings));
            }

            dispatch(updateCabinetLastLogin());

            setTimeout(() => {
                redirectToDefaultRoute(defaultDashboard);
            },500);

        }
    }

    const handleCabinetEdit = (item:MainCabinetType) => {
        dispatch(selectUpdateUserCabinet(item));
    }

    return (
        <>
            <Card
                className={"mb-4 ribbon-box ribbon-fill ribbon-sm card-animate position-relative"+((item?.isSuspended === true) ? ' disabledBlock': '')}
                onClick={(url.length<1 && !disabled) ? createNewCabinetHandle : undefined }
                onMouseEnter={e => {
                    setShowEditButton(true);
                }}
                onMouseLeave={e => {
                    setShowEditButton(false);
                }}
            >
                {  item.plan.activeTo === undefined ?
                    <>
                    {/*// <div className="ribbon ribbon-info">*/}
                    {/*//     <i className="ri-flashlight-fill"/>*/}
                    {/*// </div>*/}
                    </>
                    :
                    <div className="ribbon ribbon-info">
                        <i className="ri-star-s-fill"/>
                    </div>
                }
                <CardBody>
                    {url.length>0
                        ?
                        item.isEdit ?
                            <>
                                <div className="d-flex align-items-center">
                                    <ListItemCabinetBlock item={item} url={url}/>
                                </div>
                            </>
                            :
                            <>
                                <Link to='#' onClick={()=>{handleCabinetEdit(item)}} className={"text-primary fs-17 cabinetEditButton"+(showEditButton ? ' visible':'')}>
                                    <i className="ri-edit-line"/>
                                </Link>
                                <Link
                                    to='#'
                                    className="d-flex align-items-center"
                                    id={"leadInnerDiscovered" + url}
                                    onClick = {()=>handleSelectCurrentCabinet(item)}
                                >
                                    <ListItemCabinetBlock item={item} url={url}/>
                                </Link>
                            </>
                        :
                        <Link
                            to='#'
                            className="d-flex align-items-center"
                            id={"leadInnerDiscovered" + url}
                        >
                            <ListItemCabinetBlock item={item} url={url}/>
                        </Link>
                    }
                </CardBody>
                <Progress
                    className="progress-sm bg-soft-info w-100"
                    value={progressValue}
                    color="success"
                />
            </Card>
        </>
    );
};

export default ListItemV2;
