import {ContentPeerTargetTypeActionTypes, ContentPeerTargetTypePayload} from "./types";

export const contentPeerTargetTypeApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentPeerTargetTypeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentPeerTargetTypeApiResponseError = (actionType: string, error: string) => ({
  type: ContentPeerTargetTypeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentPeerTargetTypes = () => ({
  type: ContentPeerTargetTypeActionTypes.GET_CONTENT_PEER_TARGET_TYPES,
});
