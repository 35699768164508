import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getPaymentSystems = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(url.GET_PAYMENT_SYSTEMS+"?"+query);
};

const getSalePaymentSystems = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_PAYMENT_SYSTEMS+"?"+query);
};

const sendDataSalePaymentSystem = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_PAYMENT_SYSTEM, data);
};

const removeSalePaymentSystem = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_PAYMENT_SYSTEM, data);
};

export {
    getPaymentSystems,
    getSalePaymentSystems,
    sendDataSalePaymentSystem,
    removeSalePaymentSystem
};
