import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const sendDataSaleOrderPayment = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_ORDER_PAYMENT, data);
};

const removeSaleOrderPayment = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_ORDER_PAYMENT, data);
};

export {
    sendDataSaleOrderPayment,
    removeSaleOrderPayment
};
