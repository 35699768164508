//@ts-nocheck
import React, {useEffect, useState} from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import {useTranslation} from "react-i18next";
interface InputTextProps {
    value: null | string;
    placeholder: null | string;
    onChange: (value: string) => void;
    className: null | string;
    inputClassName: null | string;
}
const InputPhone= ({ placeholder, value, onChange, className, inputClassName}: InputTextProps) => {

    const { t, i18n } = useTranslation();
    const [text, setText] = useState<string>('');
    const [currentLanguage, setCurrentLanguage] = useState<string>(null);

    useEffect(()=>{
        let _currentLanguage = i18n.language;

        if (_currentLanguage === undefined || _currentLanguage === null || _currentLanguage === 'en' ){
            _currentLanguage = 'gb';
        } else if (_currentLanguage === 'ka'){
            _currentLanguage = 'ge';
        }

        setCurrentLanguage(_currentLanguage);

    },[i18n.language])

    useEffect(() => {
        if (value === undefined || value === null){
            setText('');
        } else {
            setText(value.toString());
        }
    }, [value]);

    const onChangeText = (text:string) => {
        setText(text);
        onChange(text);
    }

    return (
        <>
            {(currentLanguage || (text !== undefined && text!==null && text.length>0)) &&
             <>
        {text === undefined || text === null || text.length<1 ?
            <PhoneInput
                className={className && className}
                placeholder={placeholder || ""}
                defaultCountry={currentLanguage}
                disableDialCodePrefill={true}
                value={text || ''}
                inputClassName={inputClassName && inputClassName}
                onChange={(phone: any) => {
                    onChangeText(phone);
                }}
            />
            :
            <PhoneInput
                className={className && className}
                placeholder={placeholder || ""}
                disableDialCodePrefill={true}
                inputClassName={inputClassName && inputClassName}
                value={text || ''}
                onChange={(phone: any) => {
                    onChangeText(phone);
                }}
            />
        }
             </>}
        </>
    );
};
export default InputPhone;
