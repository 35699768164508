import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentTemplatesActionTypes } from "./types";
import { contentTemplatesApiResponseSuccess, contentTemplatesApiResponseError } from "./actions";

import {
  getContentTemplates as getContentTemplatesApi,
  sendDataContentTemplates,
  removeContentTemplates
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getContentTemplates({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getContentTemplatesApi, data);
    yield put(contentTemplatesApiResponseSuccess(ContentTemplatesActionTypes.GET_CONTENT_TEMPLATES, response));
  } catch (error: any) {
    yield put(contentTemplatesApiResponseError(ContentTemplatesActionTypes.GET_CONTENT_TEMPLATES, error));
  }
}

function* onSendDataContentTemplates({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentTemplates, data);
    yield put(
        contentTemplatesApiResponseSuccess(ContentTemplatesActionTypes.UPDATE_CONTENT_TEMPLATES, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentTemplatesApiResponseError(ContentTemplatesActionTypes.UPDATE_CONTENT_TEMPLATES, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveContentTemplates({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeContentTemplates, {uuid: data.data.uuid});
    yield put(
        contentTemplatesApiResponseSuccess(ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentTemplatesApiResponseError(ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetContentTemplates() {
  yield takeEvery(ContentTemplatesActionTypes.GET_CONTENT_TEMPLATES, getContentTemplates);
}
export function* watchOnSendDataContentTemplates() {
  yield takeEvery(ContentTemplatesActionTypes.UPDATE_CONTENT_TEMPLATES, onSendDataContentTemplates);
}
export function* watchOnRemoveContentTemplates() {
  yield takeEvery(ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES, onRemoveContentTemplates);
}

function* contentTemplatesSaga() {
  yield all([
    fork(watchGetContentTemplates),
    fork(watchOnSendDataContentTemplates),
    fork(watchOnRemoveContentTemplates)
  ]);
}

export default contentTemplatesSaga;
