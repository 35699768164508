import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,} from 'reactstrap';
import { Link } from 'react-router-dom'

import InputText from "../../../components/InputText";
import InputPassword from "../../../components/InputPassword";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {LoginFormValidatorSchema} from "./LoginFormValidator";
import {LoginType} from "../../../redux/Main/Login/types";
import {INIT_LOGIN} from "../../../redux/Main/Login/reducer";
import {loginUser} from "../../../redux/Main/Login/actions";
import * as route from "../../../api/routes";

const LoginForm = () => {

    const { t } = useTranslation(['user','validator']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        loginState,
    } = useAppSelector(state => ({
        loginState: state.Login,
    }));

    const [formValues, setFormValues] = useState<LoginType>(INIT_LOGIN);
    const formikObject = useRef(null);

    useEffect(() => {
        if (loginState !== undefined && loginState.isLoading !== undefined) {
            setDisabled(loginState.isLoading);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginState?.isLoading]);

    const onDataSubmit = (formData:LoginType) => {
        let data: any = {};

        if (formData.email) {
            data["email"] = formData.email;
        }
        if (formData.password) {
            data["password"] = formData.password;
        }

        let params: LoginType = {
            email: data.email && data.email,
            password: data.password && data.password,
        };

        dispatch(loginUser(params));
    };

    return (
        <>

                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={LoginFormValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    innerRef={formikObject}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                            <Row>
                                <div className={ "mb-3"+ (touched.email ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-email-input">E-mail</Label>
                                    <Field
                                        id="form-email-input"
                                        name="email"
                                        placeholder={t('user:enterEmail')}
                                        component={InputText}
                                        value = {getFieldProps('email').value}
                                        onChange={(value:string) => {
                                            setFieldValue('email', value);
                                        }}
                                        className={errors.email && touched.email && 'form-control is-invalid'}
                                    />
                                    {errors.email && touched.email ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.email)}
                                        </div>
                                    ) :  touched.email && !errors.email ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                        <Row>
                            <div className={ "mb-3"+ (touched.password ? " was-validated" : '')}>
                                <div className="float-end">
                                    <Link to={route.MAIN_AUTH_REQUEST_RESET_PASSWORD} tabIndex={-1} className="text-muted">{t('auth:forgotPassword')}?</Link>
                                </div>
                                <Label className="form-label" htmlFor="form-password-input">{t('user:password')}</Label>
                                <Field
                                    id="form-password-input"
                                    name="password"
                                    placeholder={t('user:enterPassword')}
                                    component={InputPassword}
                                    value = {getFieldProps('password').value}
                                    onChange={(value:string) => {
                                        setFieldValue('password', value);
                                    }}
                                    className={errors.password && touched.password && 'form-control is-invalid'}
                                />
                                {errors.password && touched.password ? (
                                    <div className="invalid-feedback">
                                        {t('validator:'+errors.password)}
                                    </div>
                                ) :  touched.password && !errors.password ?
                                    <div className="valid-feedback">
                                        {t('common:looksGood')}
                                    </div>
                                    : ''
                                }
                            </div>
                        </Row>

                        <div className="mt-4">
                            <Button
                                type="submit"
                                disabled={disabled}
                                color="success"
                                className="w-100 btn-success">{t('auth:signIn')}</Button>
                        </div>
                    </Form>
                        )}
                </Formik>
        </>
    );
}

export default LoginForm;