import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getSaleCurrentPlan = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CURRENT_PLAN);
};

const getSalePlans = () => {
    return api.get(url.GET_PLANS);
};

export {
    getSaleCurrentPlan,
    getSalePlans
};
