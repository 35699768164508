import React, {useState} from "react";

interface PeerActivityModeChartLabelProps {
    name: string;
    label: string,
    color: string,
    count: 0 | number
}

const PeerActivityModeChartLabel = ({ name, color, count, label }: PeerActivityModeChartLabelProps) => {

    return (
        <div className="d-flex justify-content-between border-bottom border-bottom-dashed py-2">
            <p className="fw-semibold mb-0">
                <i className="ri-checkbox-blank-circle-fill align-middle me-2" ref={element => {
                    if (element) element.style.setProperty('color', color, 'important');
                }}/> {name}</p>
            <div>
                <span className="text-muted pe-5">{count} {label}</span>
            </div>
        </div>
    );

}

export default PeerActivityModeChartLabel;