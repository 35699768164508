import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row, Col,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import {
    getContentPeerTargets,
    hideRightModalContentPeerTarget,
    onSendDataContentPeerTarget
} from "../../../redux/Content/PeerTarget/actions";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {
    ContentPeerTargetRecurrentPeriods,
    ContentPeerTargetRecurrentPeriodType,
    ContentPeerTargetType
} from "../../../redux/Content/PeerTarget/types";
import {ContentPeerTargetChannelValidatorSchema, ContentPeerTargetActivityValidatorSchema} from "./validator";
import InputSelect from "../../../components/InputSelect";
import InputDatePicker from "../../../components/InputDatePicker";
import {ContentPeerTargetTypeType} from "../../../redux/Content/PeerTargetType/types";
import PeerActivityLine from "../PeerActivity/PeerActivityLine";
import SimpleBar from 'simplebar-react';
import {Spinner} from "react-bootstrap";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import {isNumeric} from "../../../helpers/functions";
import {ContentProjectType} from "../../../redux/Content/Project/types";
import UpgradeFareBlock from "../../../components/UpgradeFareBlock";

const RightModal = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [peerTargetTypes, setPeerTargetTypes] = useState<ContentPeerTargetTypeType[]>([]);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

    const {
        contentPeerTarget,
        contentPeerChannel,
        contentPeerTargetType,
        isSending,
        salePlan
    } = useAppSelector(state => ({
        contentPeerTarget: state.ContentPeerTarget,
        contentPeerChannel: state.ContentPeerChannel,
        contentPeerTargetType: state.ContentPeerTargetType,
        isSending: state.ContentPeerTarget.isSending,
        salePlan: state.SalePlan
    }));

    const uuid = contentPeerTarget.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<ContentPeerTargetType>(contentPeerTarget.selectedItem);

    useEffect(() => {
        checkFareRestriction();

        if (contentPeerTarget.selectedItem.recurrentValue === undefined){
            contentPeerTarget.selectedItem.recurrentValue=1;
        }
        if (contentPeerTarget.selectedItem.recurrentPeriod === undefined){
            contentPeerTarget.selectedItem.recurrentPeriod = {id: 'month', code: 'month'}
        }

        if ((contentPeerTarget.selectedItem.targetType === undefined) || (contentPeerTarget.selectedItem.targetType.code.length === 0 )){
            contentPeerTarget.selectedItem.targetType=contentPeerTargetType.items.filter((targetType:ContentPeerTargetTypeType)=>targetType.objectType === 'channel')[0];
        }

        setFormValues(contentPeerTarget.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid,dispatch, contentPeerTarget?.selectedItem?.eTag]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            setCurrentPlan({...salePlan.currentPlan});
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        checkFareRestriction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    useEffect( () => {

        if ((contentPeerTargetType.items !== undefined) && (contentPeerTargetType.items.length>0)){
            const peerTargetTypes = (contentPeerTargetType.items || []).map((item:ContentPeerTargetTypeType)=>{
                return {
                    ...item,
                    name: t('targetType:'+item.code)
                };
            });

            setPeerTargetTypes([...peerTargetTypes]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentPeerTargetType?.items, t]);

    useEffect( () => {

        if (peerTargetTypes.length>0) {
            if ((contentPeerTarget.selectedItem.targetType === undefined) || (contentPeerTarget.selectedItem.targetType.length === 0)) {
                contentPeerTarget.selectedItem.targetType = peerTargetTypes.filter((targetType: ContentPeerTargetTypeType) => targetType.objectType === 'channel')[0];
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[peerTargetTypes]);


    const handleClose = () => dispatch(hideRightModalContentPeerTarget());

    const onDataSubmit = (formData:ContentPeerTargetType) => {
        let data: any = {};

        if (uuid) {
            data["uuid"] = uuid;
        } else {
            if (formData.targetType) {
                data["targetType"] = formData.targetType;
            }
            if (formData.channel) {
                data["channel"] = formData.channel;
            }
        }

        if (formData.name) {
            data["name"] = formData.name;
        }
        if (formData.targetDate) {
            data["targetDate"] = formData.targetDate;
        }
        if (formData.targetValue) {
            data["targetValue"] = formData.targetValue;
        }
        if (formData.recipientEmails) {
            data["recipientEmails"] = formData.recipientEmails;
        }
        if (formData.isRecurrent) {
            data["isRecurrent"] = formData.isRecurrent;

            if (formData.recurrentPeriod) {
                data["recurrentPeriod"] = formData.recurrentPeriod;
            }
            if (formData.recurrentValue) {
                data["recurrentValue"] = formData.recurrentValue;
            }
        }

        data["enabled"] = formData.enabled;

        let params: any = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            name: data.name && data.name,
            targetDate: data.targetDate && data.targetDate,
            targetValue: data.targetValue && data.targetValue,
            recipientEmails: data.recipientEmails && data.recipientEmails,
            isRecurrent: data.isRecurrent ? data.isRecurrent : false,
            recurrentValue: data.recurrentValue && data.recurrentValue,
        };

        if ((contentPeerTarget.selectedItem.activity !== undefined) && (contentPeerTarget.selectedItem.activity !== null) && (contentPeerTarget.selectedItem.activity.uuid.length>0)){
            data.activity = contentPeerTarget.selectedItem.activity;
        }

        if (data.recurrentPeriod){
            if ((data.recurrentPeriod.value !== undefined) && (data.recurrentPeriod.value !== null)){
                params.recurrentPeriod = { uuid: data.recurrentPeriod.value }
            } else {
                params.recurrentPeriod = { uuid: data.recurrentPeriod.uuid }
            }
        }
        if (data.targetType){
            if ((data.targetType.value !== undefined) && (data.targetType.value !== null)){
                params.targetType = { uuid: data.targetType.value }
            } else {
                params.targetType = { uuid: data.targetType.uuid }
            }
        }
        if (data.channel){
            if ((data.channel.value !== undefined) && (data.channel.value !== null)){
                params.channel = { uuid: data.channel.value }
            } else {
                params.channel = { uuid: data.channel.uuid }
            }
        }
        if (data.activity){
            if ((data.activity.value !== undefined) && (data.activity.value !== null)){
                params.activity = { uuid: data.activity.value }
            } else {
                params.activity = { uuid: data.activity.uuid }
            }
        }

        dispatch(onSendDataContentPeerTarget(params));

    };

    const peerTargetRecurrentPeriods = (ContentPeerTargetRecurrentPeriods.items || []).map((item:ContentPeerTargetRecurrentPeriodType)=>{
        return {
             ...item,
             name: t('recurrentPeriod:'+item.code)
         };
    });


    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.CONTENT_MAX_TARGETS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(true);
        } else if (contentPeerTarget) {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value) === (contentPeerTarget.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    //edit only
                    if (uuid === undefined || uuid === null) {
                        setUpgradeFare(true);
                    } else {
                        setUpgradeFare(false);
                    }
                } else if (parseInt(restrictionValue.value) < (contentPeerTarget.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    setUpgradeFare(true);
                } else {
                    setUpgradeFare(false);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }

    return (
        <>
            <Offcanvas
                direction="end"
                isOpen={contentPeerTarget.rightModalShow}
                id="targetEdit"
                toggle={handleClose}
                className="border-bottom"
            >
                { upgradeFare ?
                    <UpgradeFareBlock onClose={handleClose}/>
                    :
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={formValues.activity ? ContentPeerTargetActivityValidatorSchema : ContentPeerTargetChannelValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => {
                        return (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('target:'+contentPeerTarget.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>

                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-name-input">{t('common:name')}</Label>
                                    <Field
                                        id="form-name-input"
                                        name="name"
                                        placeholder={t('target:enterTargetName')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.targetType ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-type-input">{t('target:targetType')}</Label>
                                    <Field
                                        id="form-type-input"
                                        name="targetType"
                                        placeholder={t('target:selectTargetType')}
                                        component={InputSelect}
                                        value = {getFieldProps('targetType').value}
                                        onChange={(value:string) => {
                                            setFieldValue('targetType', value);
                                        }}
                                        items={(peerTargetTypes || []).filter((itemTargetType:ContentPeerTargetTypeType, key:number) => { return itemTargetType.objectType === contentPeerTarget.selectedItem.targetType.objectType})}
                                        className={'z-index-auto'+errors.targetType && touched.targetType && ' form-control is-invalid'}
                                        readonly={uuid != null}
                                    />
                                    {errors.targetType && touched.targetType ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.targetType)}
                                        </div>
                                    ) :  touched.targetType && !errors.targetType ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>

                            <Row>
                                <div className={ "mb-3"+ (touched.activity ? " was-validated" : '')}>
                                    { contentPeerTarget.selectedItem.activity && contentPeerTarget.selectedItem.activity.uuid.length>0 ?
                                        <>
                                            <PeerActivityLine item={contentPeerTarget.selectedItem.activity}/>
                                        </>
                                        :
                                        <>
                                    <Label className="form-label" htmlFor="form-channel-input">{t('channel:channel')}</Label>
                                    <Field
                                        id="form-channel-input"
                                        name="channel"
                                        placeholder={t('channel:chooseChannel')}
                                        component={InputSelect}
                                        value = {getFieldProps('channel').value}
                                        onChange={(value:string) => {
                                            setFieldValue('channel', value);
                                        }}
                                        items={contentPeerChannel.items || []}
                                        className={'z-index-auto'+errors.activity && touched.activity && ' form-control is-invalid'}
                                        readonly={uuid != null}
                                    />
                                    {errors.activity && touched.activity ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.activity)}
                                        </div>
                                    ) :  touched.activity && !errors.activity ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                        </>
                                    }
                                </div>
                            </Row>

                            <Row>
                                <div className={ "mb-3"+ (touched.targetDate ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-targetdate-input"> {t('target:targetCloseDate')}</Label>
                                    <Field
                                        id="form-targetdate-input"
                                        name="targetDate"
                                        component={InputDatePicker}
                                        value = {getFieldProps('targetDate').value}
                                        onChange={(value:string) => {
                                            setFieldValue('targetDate', value);
                                        }}
                                        minDate="today"
                                        className={errors.targetDate && touched.targetDate && 'form-control is-invalid'}
                                    />
                                    {errors.targetDate && touched.targetDate ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.targetDate)}
                                        </div>
                                    ) :  touched.targetDate && !errors.targetDate ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.targetValue ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-target-value-input">{t('target:targetValue')}</Label>
                                    <Field
                                        id="form-target-value-input"
                                        name="targetValue"
                                        placeholder={t('target:enterTargetValue')}
                                        component={InputText}
                                        value = {getFieldProps('targetValue').value}
                                        onChange={(value:number) => {
                                            setFieldValue('targetValue', value);
                                        }}
                                        className={errors.targetValue && touched.targetValue && 'form-control is-invalid'}
                                    />
                                    {errors.targetValue && touched.targetValue ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.targetValue)}
                                        </div>
                                    ) :  touched.targetValue && !errors.targetValue ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="form-target-value-input"> {t('target:recipientEmails')}</Label>
                                    <Field
                                        id="form-recipient-emails-input"
                                        name="recipient-emails"
                                        placeholder={t('target:enterRecipientEmails')}
                                        component={InputText}
                                        value = {getFieldProps('recipientEmails').value}
                                        onChange={(value:number) => {
                                            setFieldValue('recipientEmails', value);
                                        }}
                                        required={false}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="form-recurrent-input">{t('target:repeat')}</Label>

                                    <Row>
                                        <div className="d-flex flex-row flex-nowrap justify-content-between">
                                            <div className="d-flex flex-row flex-shrink-0 align-items-center">
                                    <Field
                                        id="form-is-recurrent-input"
                                        name="isRecurrent"
                                        component={InputSwitch}
                                        value = {getFieldProps('isRecurrent').value}
                                        onChange={(enabled:boolean) => {
                                            setFieldValue('isRecurrent', !getFieldProps('isRecurrent').value);
                                        }}
                                        className="form-switch-sm"
                                        required={false}
                                    />
                                            </div>
                                            <div className="d-flex flex-row align-items-center justify-content-center">
                                            {t('target:repeatEach')}
                                            </div>
                                            <div className={"d-flex flex-row align-items-center flex-shrink-0 w-25 justify-content-center"+ ((touched.recurrentValue && getFieldProps('isRecurrent').value===true) ? " was-validated" : '')}>
                                    <Field
                                        id="form-recurrent-value-input"
                                        name="recurrentValue"
                                        placeholder=""
                                        component={InputText}
                                        value = {getFieldProps('recurrentValue').value}
                                        onChange={(value:number) => {
                                            setFieldValue('recurrentValue', value);
                                        }}
                                        required={false}
                                    />
                                            </div>
                                            <div className={"d-flex flex-row align-items-center w-35 justify-content-end"+ ((touched.recurrentPeriod && getFieldProps('isRecurrent').value===true) ? " was-validated" : '')}>
                                    <Field
                                        id="form-recurrent-period-input"
                                        name="recurrentPeriod"
                                        placeholder={t('target:selectRepeatPeriod')}
                                        component={InputSelect}
                                        value = {getFieldProps('recurrentPeriod').value}
                                        onChange={(value:string) => {
                                            setFieldValue('recurrentPeriod', value);
                                        }}
                                        items={peerTargetRecurrentPeriods || []}
                                        required={true}
                                    />
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-foorter border-top p-3 text-center position-sticky bottom-0" >
                            <div className="d-flex flex-row flex-nowrap gap-2">
                                <Button variant="light" onClick={handleClose} className="w-100">
                                    {t('common:close')}
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                        disabled={isSending}
                                        className="btn btn-primary chat-send waves-effect waves-light w-100"
                                >
                                    {t('common:save')}
                                    {isSending &&
                                        <ButtonSpinner/>
                                    }
                                </Button>
                            </div>
                        </div>
                    </Form>
                    )}}
                </Formik>
                }
            </Offcanvas>
        </>
    );
}

export default RightModal;