import React from 'react';
import * as Yup from 'yup';
import {EMAIL_REGEX, PHONE_REGEX} from "../../../data/settings";

export const SaleCustomerValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    order: Yup.string()
        .matches(/^[0-9]+$/, "digitsOnly")
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    email: Yup.string()
        .nullable()
        .email("invalidEmail")
        .min(2, 'tooShort')
        .matches(EMAIL_REGEX, "invalidEmail"),
    phone: Yup.string()
        .matches(PHONE_REGEX, 'invalidPhone')
});