import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum ContentTemplatesActionTypes {
  API_RESPONSE_SUCCESS = "@@contentTemplates/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentTemplates/API_RESPONSE_ERROR",
  GET_CONTENT_TEMPLATES = "@@contentTemplates/GET_CONTENT_TEMPLATES",
  UPDATE_CONTENT_TEMPLATES = "@@contentTemplates/UPDATE_CONTENT_TEMPLATES",
  ADD_CONTENT_TEMPLATES = "@@contentTemplates/ADD_CONTENT_TEMPLATES",
  REMOVE_CONTENT_TEMPLATES_MODAL_SHOW = "@@contentTemplates/REMOVE_CONTENT_TEMPLATES_MODAL_SHOW",
  REMOVE_CONTENT_TEMPLATES_MODAL_HIDE = "@@contentTemplates/REMOVE_CONTENT_TEMPLATES_MODAL_HIDE",
  REMOVE_CONTENT_TEMPLATES = "@@contentTemplates/REMOVE_CONTENT_TEMPLATES",
  EDIT_CONTENT_TEMPLATES = "@@contentTemplates/EDIT_CONTENT_TEMPLATES",

  RIGHT_MODAL_SHOW = "@@contentTemplates/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@contentTemplates/RIGHT_MODAL_HIDE",
}

export interface ContentTemplatesState extends MainMetaType{
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: ContentTemplateType;
  modalEditTitle: string;
  isSending: boolean;
  isFetched: boolean;
  isLoading: boolean;
  isNeedReload: boolean;
}

export interface ContentTemplateType{
  uuid?: string,
  enabled: boolean,
  name: string,
  text: string,
  actions?: EntityActionsType

  isHtml? : boolean;
}

export interface ContentTemplatePayload {
  data?: ContentTemplateType;
  modalEditTitle : string;
}
