import {ContentConnectorHandlerType} from "../ConnectorHandler/types";
import {PeerActivityModeType} from "../PeerActivityMode/types";

export enum ContentConnectorDataParamActionTypes {
  API_RESPONSE_SUCCESS = "@@contentConnectorDataParam/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentConnectorDataParam/API_RESPONSE_ERROR",
  GET_CONTENT_CONNECTOR_DATA_PARAMS = "@@contentConnectorDataParam/GET_CONTENT_CONNECTOR_DATA_PARAMS",
}

export interface ContentConnectorDataParamState {
  items: Array<any>;
}

export interface ContentConnectorDataParamType{
  uuid: string,
  enabled: boolean,
  code: string,
  type: ContentConnectorDataParamTypeType,
  isMultiple: boolean,
  isRequired: boolean,
  handler?: ContentConnectorHandlerType,
  mode?: PeerActivityModeType,
  params:  ContentConnectorDataAdditionalParamType
  order: number,
}

export interface ContentConnectorDataParamPayload {
  data: Array<any>;
}

export interface ContentConnectorDataAdditionalParamType{
  class?: string,
  dataRemote?: string,
  placeholder?: string,
  canAddToTemplate?: boolean,
  canUseTemplates?: boolean,
  canUseHtml?: boolean,
  acceptedFiles?: string,
  maxLength?: number,
  acceptedDimensions?: ContentConnectorDataAdditionalParamAcceptedDimensionType
}


export interface ContentConnectorDataAdditionalParamAcceptedDimensionType{
  minWidth?: number,
  maxWidth?: number,
  minHeight?: number,
  maxHeight?: number
}

export enum ContentConnectorDataParamTypeType {
  FILE = "F",
  STRING = "S",
  LIST = "L",
  TEXT = "T"
}

export enum ContentConnectorDataParamExclude {
  CHANNEL = "CHANNEL",
}