import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();
const updateComment = (data: any) => {

    let sendData = {...data};

    if (sendData?.owner){
        delete sendData.owner;
    }
    if (sendData?.actions){
        delete sendData.actions;
    }

    if (sendData.attachmentFiles !== undefined){
        return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.UPDATE_COMMENT,sendData);
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_COMMENT, sendData);
};

const removeComment = (data: any) => {
    let sendData = {};

    if (data?.uuid){
        sendData = {
            uuid: data.uuid
        }
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_COMMENT, sendData);
};

const getComments = (data: any) => {

    let sendData = {};

    if (data?.task?.uuid){
        sendData = {
            task:{
                uuid: data?.task.uuid
            }
        }
    } else if (data?.saleOrder?.uuid){
        sendData = {
            saleOrder:{
                uuid: data?.saleOrder.uuid
            }
        }
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_COMMENTS, sendData);
};


export {
    getComments,
    updateComment,
    removeComment
};
