import {RequestMetadataType} from "../../Main/RequestMetadata/types";

export enum ShortenerActionTypes {
    API_RESPONSE_SUCCESS = "@@urlShortener/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@urlShortener/API_RESPONSE_ERROR",
    GET_URL_SHORTENER = "@@urlShortener/GET_URL_SHORTENER",
    CHECK_URL_SHORTENER = "@@urlShortener/CHECK_URL_SHORTENER",
    SHORTENER_URL_UPDATE_SUCCESSFUL = "@@urlShortener/SHORTENER_URL_UPDATE_SUCCESSFUL",
    SHORTENER_URL_UPDATE_FAILED = "@@urlShortener/SHORTENER_URL_UPDATE_FAILED",
    SHORTENER_URL_IS_AVAILABLE_RESPONSE = "@@urlShortener/SHORTENER_URL_IS_AVAILABLE_RESPONSE",
    SHORTENER_URL_IS_NOT_AVAILABLE_RESPONSE = "@@urlShortener/SHORTENER_URL_IS_NOT_AVAILABLE_RESPONSE",
}

export interface UrlShortenerState {
    selectedItem: UrlShortenerType;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
    requestMetadata? : RequestMetadataType;
}

export interface DomainType {
    uuid?: string;
    enabled?: boolean;
    name?: string;
    code?: string;
    createdAt?: string;
    updatedAt?: string;
    order?: number;
}

export interface UrlShortenerType {
    uuid?: string;
    enabled?: boolean;
    domain?: DomainType,
    url?: string;
    redirectUrl?: string;
    createdAt?: string;
    updatedAt?: string;
    order?: number;
    type?: string;
    cabinet?: string;
    objectOwner?: string;
    isApproved?: boolean
}

export interface UrlShortenerScriptParamType {
    uuid?: string;
    enabled?: boolean;
    widgetData?: string,
    createdAt?: string;
    updatedAt?: string;
}
