import React, {useEffect, useRef, useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {PeerActivityType} from "../../redux/Content/PeerActivity/types";
import * as route from "../../api/routes";
import Flatpickr from "react-flatpickr";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import PeerActivityLine from "../../blocks/Content/PeerActivity/PeerActivityLine";
import {ContentProjectType} from "../../redux/Content/Project/types";
import {ContentPeerChannelType} from "../../redux/Content/PeerChannel/types";
import {PeriodType, StatProjectActivitiesPayloadType} from "../../redux/Stat/DashboardContent/types";
import {
    getDashboardContentActivities,
    getDashboardContentScheduleActivities
} from "../../redux/Stat/DashboardContent/actions";
import {useRedux} from "../../hooks";
import {convertToMomentLocale} from "../../helpers/functions";
import '../../i18/config';
import {useTranslation} from "react-i18next";

interface SchedulesCalendarProps {
    items: [] | Array<PeerActivityType>;
    project?: ContentProjectType,
    channel?: ContentPeerChannelType,
}

const SchedulesCalendar = ({ items, project, channel }: SchedulesCalendarProps) => {

    const { t, i18n } = useTranslation();
    moment.locale(convertToMomentLocale(i18n.language));

    const calendarRef = useRef(null);

    const [currentPeriod, setCurrentPeriod] = useState<PeriodType>(null);
    const [currentPeriodItems, setCurrentPeriodItems] = useState<PeerActivityType[]>([]);
    const [newActivitiesDates, setNewActivitiesDates] = useState([]);

    const { dispatch, useAppSelector } = useRedux();

    const {
        dashboardContent,
    } = useAppSelector(state => ({
        dashboardContent: state.DashboardContent,
    }));

    const [selectedTimestamp, setSelectedTimestamp] = useState(moment().utc().unix());

    useEffect(() => {

        setCurrentPeriod({
            periodFrom: dashboardContent.periodFrom && dashboardContent.periodFrom,
            periodTo: dashboardContent.periodTo && dashboardContent.periodTo,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {

        if (dashboardContent.selectedDate>0){

            calendarRef.current.flatpickr.setDate(moment(dashboardContent.selectedDate,'X').toDate());
            setSelectedTimestamp(dashboardContent.selectedDate);
        }

    },[dashboardContent?.selectedDate]);

    // useEffect(() => {
    //     if ((dashboardContent.periodFrom !== undefined) && (dashboardContent.periodTo !== undefined)){
    //         if (calendarRef !== undefined && calendarRef.current !== undefined){
    //             if (currentPeriod !== null && (dashboardContent.periodFrom !== currentPeriod.periodFrom)){
    //
    //                 setCurrentPeriod({
    //                     periodFrom: dashboardContent.periodFrom && dashboardContent.periodFrom,
    //                     periodTo: dashboardContent.periodTo && dashboardContent.periodTo,
    //                 });
    //
    //                 const statProjectActivitiesPayload:StatProjectActivitiesPayloadType = {
    //                     project : project,
    //                     periodFrom : dashboardContent.periodFrom,
    //                     periodTo: dashboardContent.periodTo
    //                 }
    //
    //                 dispatch(getDashboardContentScheduleActivities(statProjectActivitiesPayload));
    //             }
    //         }
    //     }
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[dashboardContent?.periodFrom, dashboardContent?.periodTo]);

    useEffect(()=>{
        setNewActivitiesDates(items?.map(item => moment(item.activeFrom).toDate().setHours(0, 0, 0, 0)) || []);
        setCurrentPeriodItems(
            (items || []).filter((activityItem:PeerActivityType)=>{
                return moment(activityItem.activeFrom).format('DD-MM-YYYY') === moment(selectedTimestamp, 'X').format('DD-MM-YYYY')}));

    },[selectedTimestamp, items]);

    const onCalendarChangeView = (instance : any) => {

        const periodFrom = instance.days.firstChild.dateObj.getTime()/1000;
        const periodTo = instance.days.lastChild.dateObj.getTime()/1000;

        const statProjectActivitiesPayload:StatProjectActivitiesPayloadType = {
            project : project,
            periodFrom : periodFrom,
            periodTo: periodTo
        }

        dispatch(getDashboardContentScheduleActivities(statProjectActivitiesPayload));
    }

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('peerActivity:activities')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('peerActivity:manageActivities')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="upcoming-scheduled">
                    <Flatpickr
                        ref = {calendarRef}
                        className="form-control"
                        onDayCreate={(dObj, dStr, fp, dayElem)=>{

                            if (newActivitiesDates.indexOf(+dayElem.dateObj) !== -1) {
                                dayElem.className += " position-relative";

                                dayElem.innerHTML+='<span class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1"><span class="visually-hidden">'+t('peerActivity:manageActivities')+'</span></span>';
                            }
                        }}
                        options={{
                            altFormat: "F j, Y",
                            dateFormat: "U",
                            inline: true,
                            altInput: false,
                            allowInput: false,
                            mode: 'single',
                            monthSelectorType: 'static',
                            locale : {...FlatpickrLanguages[i18n.language]}
                        }}
                        onReady={(selectedDates, dateStr, instance) =>{
                            onCalendarChangeView(instance);
                        }}
                        onYearChange={(selectedDates, dateStr, instance) =>{
                            onCalendarChangeView(instance);
                        }}
                        onMonthChange={(selectedDates, dateStr, instance) =>{
                            onCalendarChangeView(instance);
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                            setSelectedTimestamp(dateStr);
                        }}
                    />
                </div>

                <h6 className="text-uppercase fw-semibold mt-4 mb-3 text-muted">{t('peerActivity:activitiesOn')} {moment(selectedTimestamp, 'X').format('ddd, DD MMM YYYY')}{(currentPeriodItems || []).length>0 ? "("+((currentPeriodItems || []).length)+")" : ''}: </h6>

                {(currentPeriodItems || []).map((item:PeerActivityType, key:number)=>(
                   key<4 &&
                    <PeerActivityLine item={item} key={'calendarActivity_'+key}/>
                ))}

                <div className="mt-3 text-center">
                    {(currentPeriodItems || []).length>3 &&
                    <a href={route.CONTENT_ACTIVITY_PLAN} className="text-muted text-decoration-underline">{t('peerActivity:showMoreActivities')}</a>
                    }
                    {(currentPeriodItems || []).length<1 &&
                        <>{t('peerActivity:noActivities')}</>
                    }
                </div>

            </CardBody>
        </Card>
    );
}

export default SchedulesCalendar;