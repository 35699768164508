
import {MainFileType} from "../File/types";
import {MainUserType} from "../User/types";
import {EntityActionsType} from "../../types";
import {TaskType} from "../../Task/Task/types";
import {MainMetaType} from "../common";
import {OrderType} from "../../Sale/Order/types";

export enum CommentActionTypes {
    API_RESPONSE_SUCCESS = "@@comment/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@comment/API_RESPONSE_ERROR",
    UPDATE_COMMENT= "@@comment/UPDATE_COMMENT",
}

export interface CommentState extends MainMetaType{
    items: Array<TaskType>;
    isLoading: boolean;
    isSending: boolean;
    selectedItem: CommentType;
    rightModalShow: boolean;
    removeModalShow: boolean;
    rewardModalShow: boolean;
    modalEditTitle: string;
    isNeedReload: boolean
}


export interface CommentType{
    uuid?: string;
    enabled?: boolean;
    text?: string;
    status?: string;
    task?: TaskType;

    saleOrder?: OrderType;
    media?:Array<MainFileType>;
    attachmentFiles?: any;
    dateTime?:string;
    owner?: MainUserType;
    actions?: EntityActionsType,
    score?: number;
    parentComment?: CommentType
}

export interface CommentPayload {
    data: CommentType;
    removeCommentModalTitle : string;
}

export interface MainCommentMetaType{
    totalCount?: number;
}