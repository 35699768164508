import {CurrencyType} from "../Currency/types";
import {DeliverySystemType} from "../DeliverySystem/types";
import {MainMetaType} from "../../Main/common";
import {OrderType} from "../Order/types";

export enum SaleOrderDeliveryItemActionTypes {
    API_RESPONSE_SUCCESS = "@@saleOrderDeliveryItem/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleOrderDeliveryItem/API_RESPONSE_ERROR",
    GET_SALE_ORDER_DELIVERY_ITEMS = "@@saleOrderDeliveryItem/GET_SALE_ORDER_DELIVERY_ITEMS",
    UPDATE_SALE_ORDER_DELIVERY_ITEM = "@@saleOrderDeliveryItem/UPDATE_SALE_ORDER_DELIVERY_ITEM",
    REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_SHOW = "@@saleOrderDeliveryItem/REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_SHOW",
    REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_HIDE = "@@saleOrderDeliveryItem/REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_HIDE",
    REMOVE_SALE_ORDER_DELIVERY_ITEM = "@@saleOrderDeliveryItem/REMOVE_SALE_ORDER_DELIVERY_ITEM",
    EDIT_SALE_ORDER_DELIVERY_ITEM = "@@saleOrderDeliveryItem/EDIT_SALE_ORDER_DELIVERY_ITEM",
    RIGHT_MODAL_SHOW = "@@saleOrderDeliveryItem/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleOrderDeliveryItem/RIGHT_MODAL_HIDE",
    CLEAR_NEED_ORDER_RELOAD = "@@saleOrderDeliveryItem/CLEAR_NEED_ORDER_RELOAD",
}

export interface SaleOrderDeliveryItemState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: DeliveryType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
    isNeedOrderReload: boolean;
}

export interface DeliveryType{
    uuid?: string;
    enabled?: boolean;
    amount?: number;
    currency?: CurrencyType;
    status?: string;
    comment?: string;
    deliverySystem?: DeliverySystemType
    createdAt?: string;
    updatedAt?: string;
    address?: string;
    weight?: number;
    idTracking?: string;
    trackingNumber?: string;
    shipmentDate?: string;
    deliveryDate?: string;
    saleOrder?: OrderType;
}

export const DeliveryStatus = {
    NEW: {
        label: 'New',
        status: 'N',
        class: 'info',
    },
    CANCELLED: {
        label: 'Cancelled',
        status: 'C',
        class: 'dark',
    },
    ERROR: {
        label: 'Error',
        status: 'E',
        class: 'danger',
    },
    FINAL: {
        label: 'Final',
        status: 'F',
        class: 'success',
    },
    PENDING: {
        label: 'Pending',
        status: 'P',
        class: 'primary',
    },
}


export interface SaleOrderDeliveryItemPayload {
    data: Array<any>;
    modalEditTitle : string;
}