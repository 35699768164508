import {SaleOrderStatusActionTypes, SaleOrderStatusPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleOrderStatusApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleOrderStatusActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleOrderStatusApiResponseError = (actionType: string, error: string) => ({
  type: SaleOrderStatusActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleOrderStatuses = (data: MainMetaType) => ({
  type: SaleOrderStatusActionTypes.GET_SALE_ORDER_STATUSES,
  payload: data
});

export const showRightModalSaleOrderStatus= (data: SaleOrderStatusPayload) => ({
  type: SaleOrderStatusActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleOrderStatus= () => ({
  type: SaleOrderStatusActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleOrderStatus = (data: any) => ({
  type: SaleOrderStatusActionTypes.UPDATE_SALE_ORDER_STATUS,
  payload: data,
});

export const editDataSaleOrderStatus = (data: SaleOrderStatusPayload) => ({
  type: SaleOrderStatusActionTypes.EDIT_SALE_ORDER_STATUS,
  payload: data,
});

export const removeSaleOrderStatusModalShow = (data: SaleOrderStatusPayload) => ({
  type: SaleOrderStatusActionTypes.REMOVE_SALE_ORDER_STATUS_MODAL_SHOW,
  payload: data,
});

export const removeSaleOrderStatusModalHide = () => ({
  type: SaleOrderStatusActionTypes.REMOVE_SALE_ORDER_STATUS_MODAL_HIDE,
});

export const onRemoveSaleOrderStatus = (data: SaleOrderStatusPayload) => ({
  type: SaleOrderStatusActionTypes.REMOVE_SALE_ORDER_STATUS,
  payload: data,
});