import {EntityActionsType} from "../../types";
import {MainFileType} from "../../Main/File/types";
import {MainMetaType} from "../../Main/common";

export enum SaleCategoryActionTypes {
    API_RESPONSE_SUCCESS = "@@saleCategory/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleCategory/API_RESPONSE_ERROR",
    GET_SALE_CATEGORIES = "@@saleCategory/GET_SALE_CATEGORIES",
    UPDATE_SALE_CATEGORY = "@@saleCategory/UPDATE_SALE_CATEGORY",
    REMOVE_SALE_CATEGORY_MODAL_SHOW = "@@saleCategory/REMOVE_SALE_CATEGORY_MODAL_SHOW",
    REMOVE_SALE_CATEGORY_MODAL_HIDE = "@@saleCategory/REMOVE_SALE_CATEGORY_MODAL_HIDE",
    REMOVE_SALE_CATEGORY = "@@saleCategory/REMOVE_SALE_CATEGORY",
    EDIT_SALE_CATEGORY = "@@saleCategory/EDIT_SALE_CATEGORY",
    RIGHT_MODAL_SHOW = "@@saleCategory/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleCategory/RIGHT_MODAL_HIDE",
}

export interface SaleCategoryState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: SaleCategoryType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface SaleCategoryType {
    uuid?: string;
    name: string;
    code?: string;
    enabled?: boolean;
    order?: number;
    description?: string;
    text?: string;
    link?: string;
    externalLink?: string;
    idRemote?: string;
    isHtml?: boolean;
    media?: Array<MainFileType>
    parentCategory?: SaleCategoryType;
    actions?: EntityActionsType;
}

export interface SaleCategoryPayload {
    data: Array<any>;
    modalEditTitle : string;
}
