import { DISPLAY_TYPES, STATUS_TYPES } from "../components/Constants/";

export interface BasicDetailsTypes {
  firstName: string;
  lastName: string;
  profile: string;
  coverImage: string;
  email: string;
  location: string;
  cabinet: string;
}

export interface PrivacyTypes {
  displayprofilePhoto: string;
  displayLastSeen: boolean;
  displayStatus:
    | DISPLAY_TYPES.EVERYONE
    | DISPLAY_TYPES.SELECTED
    | DISPLAY_TYPES.EVERYONE;
  readReceipts: boolean;
  displayGroups:
    | DISPLAY_TYPES.EVERYONE
    | DISPLAY_TYPES.SELECTED
    | DISPLAY_TYPES.EVERYONE;
}
export interface SecurityTypes {
  securityNotification: boolean;
}

export interface SoundTypes {
  soundNotification: boolean;
}

export interface SettingsTypes {
  basicDetails: BasicDetailsTypes;
  privacy: PrivacyTypes;
  security: SecurityTypes;
  sound: SoundTypes;
  status: STATUS_TYPES.ACTIVE | STATUS_TYPES.AWAY | STATUS_TYPES.DO_NOT_DISTURB;
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;

export const PHONE_REGEX1 = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const PHONE_REGEX =    /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g

export const URL_REGEX = /https:\/\/(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const URL_REGEX_WITH_PAGES = /https:\/\/(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/;

export const CURRENCY_SYMBOL_EXTRACT_REGEX = /#+|\s+/g;

export const PAGINATION_DOTS = '...';

export const PAGINATION_DEFAULT_ITEMS_PER_PAGE = 10;

export const PAGING_VARIANTS = [10,20,40,100];

export interface HistoryHashType {
  type?: string;
  tab?: string;
  page?: number;
  maxResults? : number;
  sortField?: string;
  sortDirection?: string;
  startDate?: string;
  endDate?: string;
  periodFrom?: number;
  periodTo?: number;
  searchPhrase?:string;
  allGroupActionSelect?:boolean;
  status?: string;
}
