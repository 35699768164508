import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row } from 'reactstrap';
import LeftbarTitle from "../../../components/LeftbarTitle";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {ContentConnectorType} from "../../../redux/Content/Connector/types";
import {useRedux} from "../../../hooks";

import {removeOAuthLink, setContentConnectorModalAddCodeShow} from "../../../redux/Content/Connector/actions";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";


interface ItemProps {
    item: ContentConnectorType,
    handleClose: () => void;
    isOpen: boolean;
    modalTitle: string;
}

const ConnectModal = ({ item, handleClose, isOpen, modalTitle }: ItemProps) => {

    const { t, i18n } = useTranslation();
    const { dispatch } = useRedux();

    const [isModalShow, setModalShow] = useState<boolean>(isOpen);
    const [title, setTitle] = useState<string>(t('connector:serviceConnection').replace(/%handlerName%/g,item.handler.code.charAt(0).toUpperCase() + item.handler.code.slice(1)));

    useEffect(() => {
        setModalShow(isOpen);
    }, [isOpen]);

    const handleClick = (item: ContentConnectorType) => {
        if (item.handler.code === 'vk'){
            setTimeout(()=>{
                dispatch(removeOAuthLink());
                dispatch(setContentConnectorModalAddCodeShow());
            }, 1000);
        }
    }

    return (
        <>
            <Modal show={isModalShow} onHide={()=>handleClose()}>
                        <Modal.Header closeButton>
                            <LeftbarTitle title={title}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <div className="d-flex flex-column">
                                    <div className="mb-3 d-flex flex-column">

                                        {item.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_VK ?
                                            t('connector:serviceConnectionDescriptionVk').replace(/%handlerName%/g,item.handler.code.charAt(0).toUpperCase() + item.handler.code.slice(1))
                                            :
                                            item.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_INSTAGRAM ?
                                                t('connector:instagramConnectionNotice')
                                                :
                                            t('connector:serviceConnectionDescription').replace(/%handlerName%/g,item.handler.code.charAt(0).toUpperCase() + item.handler.code.slice(1))
                                        }

                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <div className="d-flex flex-row flex-grow-1 mt-2">
                                            <div className="m-0 alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                                <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                                {item.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_VK ?
                                                    t('connector:serviceConnectionGPDRDescriptionVk')
                                                    :
                                                    t('connector:serviceConnectionGPDRDescription')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleClose}>
                                {t('common:cancel')}
                            </Button>
                            <a href={item.OAuthUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-success chat-send waves-effect waves-light"
                                    onClick={()=>handleClick(item)}
                            >
                                {t('connector:connect')}
                            </a>
                        </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConnectModal;