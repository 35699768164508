import React, {useEffect, useState} from "react";
import { ContentTemplateType } from "../redux/Content/Template/types";
import '../i18/config';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import InputEditorDraftArea from "./InputEditorDraftArea";
import InputEmojiArea from "./InputEmojiArea";

interface InputEditorProps {
    id: string;
    placeholder: null | string
    value: null | string;
    onChange: (value: string) => void;
    maxLength?: number;
    templates?: Array<ContentTemplateType>
    isAddToTemplate?: true | boolean
    isShowEmoji?: true | boolean,
    isHtml?: false | boolean,

    isShowAITextGenerate?: false | boolean
}
const InputEditorSwitch = ({ id, placeholder, value, onChange, maxLength, templates, isAddToTemplate, isShowEmoji, isHtml, isShowAITextGenerate }: InputEditorProps) => {

    const [isHtmlEditor, setHtmlEditor] = useState<boolean>(false);

    useEffect(()=>{
        setHtmlEditor(isHtml);
    },[isHtml])

    return (
      <>
          {isHtmlEditor
              ?
              <InputEditorDraftArea
                 id={id && id}
                 placeholder={placeholder && placeholder}
                 value={value && value}
                 onChange={onChange}
                 maxLength={maxLength && maxLength}
                 templates={templates && templates}
                 isAddToTemplate={isAddToTemplate && isAddToTemplate}
                 isShowEmoji={isShowEmoji && isShowEmoji}
               />
              :
              <InputEmojiArea
                  id={id && id}
                  placeholder={placeholder&&placeholder}
                  value={value && value}
                  onChange={onChange}
                  maxLength={maxLength && maxLength}
                  templates={templates && templates}
                  isAddToTemplate={isAddToTemplate && isAddToTemplate}
                  isShowEmoji={isShowEmoji && isShowEmoji}
                  isShowAITextGenerate={isShowAITextGenerate && isShowAITextGenerate}
              />
          }
      </>
  );
};
export default InputEditorSwitch;
