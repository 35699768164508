import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

interface LightBoxProps {
  isOpen: boolean;
  onClose: () => any;
  images: Array<any>;
  defaultIdx?: number;
}
const LightBox = ({ isOpen, onClose, defaultIdx, images }: LightBoxProps) => {
  const calcImageIndex = (indexChange: number) => (photoIndex + indexChange + images.length) % images.length;
  const [photoIndex, setPhotoIndex] = useState(calcImageIndex(defaultIdx));

  const onPrev = () => setPhotoIndex(calcImageIndex(-1));
  const onNext = () => setPhotoIndex(calcImageIndex(1));

  const lightboxProps = {
    mainSrc: images[photoIndex].url,
    prevSrc: images[calcImageIndex(-1)].url,
    nextSrc: images[calcImageIndex(1)].url,
    onCloseRequest: onClose,
    onMovePrevRequest: onPrev,
    onMoveNextRequest: onNext,
  };

  return isOpen && <Lightbox {...lightboxProps} />;
};

export default LightBox;
