import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { DashboardContentActionTypes } from "./types";
import { dashboardContentApiResponseSuccess, dashboardContentApiResponseError} from "./actions";

import {
  getPeerActivities as getPeerActivitiesApi, getContentPeerChannels,
  getStatChannelsTiles as getStatChannelsTilesApi,
  getStatPeerTargets as getStatPeerTargetsApi,
  getContentPeerChannels as getContentPeerChannelsApi,
  getContentPeerUsersTop as getContentPeerUsersTopApi,
  getStatCountersTiles as getStatCountersTilesApi,
  getStatCountersData as getStatCountersDataApi,
  getStatChannelsActivities as getStatChannelsActivitiesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {getStatCountersTiles} from "../../../api/Stat/StatCounter";

function* getDashboardContentChannelTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatChannelsTilesApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_CHANNELS_TILES, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_CHANNELS_TILES, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentCounterTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatCountersTilesApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_COUNTERS_TILES, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_COUNTERS_TILES, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentCountersData({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatCountersDataApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_COUNTERS_DATA, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_COUNTERS_DATA, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentChannelsActivitiesData({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatChannelsActivitiesApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_CHANNELS_ACTIVITIES_DATA, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_CHANNELS_ACTIVITIES_DATA, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentTargets({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatPeerTargetsApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_TARGETS, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_TARGETS, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentActivities({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivitiesApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_ACTIVITIES, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_ACTIVITIES, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentScheduleActivities({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivitiesApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_SCHEDULE_STAT_ACTIVITIES, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_SCHEDULE_STAT_ACTIVITIES, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentTopUsers({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getContentPeerUsersTopApi, data);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_TOP_USERS, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_TOP_USERS, error));
    // yield call(showErrorNotification, error);
  }
}

function* getDashboardContentChannels() {
  try {
    const response: Promise<any> = yield call(getContentPeerChannelsApi);
    yield put(
        dashboardContentApiResponseSuccess(DashboardContentActionTypes.GET_STAT_CHANNELS, response)
    );
    // yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(dashboardContentApiResponseError(DashboardContentActionTypes.GET_STAT_CHANNELS, error));
    // yield call(showErrorNotification, error);
  }
}

export function* watchGetDashboardContentChannelTiles() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_CHANNELS_TILES, getDashboardContentChannelTiles);
}
export function* watchGetDashboardContentCounterTiles() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_COUNTERS_TILES, getDashboardContentCounterTiles);
}
export function* watchGetDashboardContentCountersData() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_COUNTERS_DATA, getDashboardContentCountersData);
}

export function* watchGetDashboardContentChannelsActivitiesData() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_CHANNELS_ACTIVITIES_DATA, getDashboardContentChannelsActivitiesData);
}

export function* watchGetDashboardContentTargets() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_TARGETS, getDashboardContentTargets);
}

export function* watchGetDashboardContentActivities() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_ACTIVITIES, getDashboardContentActivities);
}

export function* watchGetDashboardContentScheduleActivities() {
  yield takeEvery(DashboardContentActionTypes.GET_SCHEDULE_STAT_ACTIVITIES, getDashboardContentScheduleActivities);
}
export function* watchGetDashboardContentTopUsers() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_TOP_USERS, getDashboardContentTopUsers);
}
export function* watchGetDashboardContentChannels() {
  yield takeEvery(DashboardContentActionTypes.GET_STAT_ACTIVITIES, getDashboardContentChannels);
}

function* dashboardContentSaga() {
  yield all([
    fork(watchGetDashboardContentChannelTiles),
    fork(watchGetDashboardContentCounterTiles),
    fork(watchGetDashboardContentTargets),
    fork(watchGetDashboardContentActivities),
    fork(watchGetDashboardContentChannels),
    fork(watchGetDashboardContentTopUsers),
    fork(watchGetDashboardContentCountersData),
    fork(watchGetDashboardContentChannelsActivitiesData),
      fork(watchGetDashboardContentScheduleActivities)
  ]);
}

export default dashboardContentSaga;
