// types
import {SupportTicketMessageActionTypes, SupportTicketMessageState, SupportTicketMessageType} from "./types";

export const NewTicketMessageItem:SupportTicketMessageType = {
  enabled: true,
  text: '',
  isSupportAnswer: false
}

export const INIT_STATE_SUPPORT_TICKET_MESSAGE: SupportTicketMessageState = {
  items: [],
  isLoading: false,
  isSending: false,
  selectedItem: NewTicketMessageItem,
  editShow: false,
  removeModalShow: false,
  modalEditTitle:''
};

const SupportTicketMessage = (state = INIT_STATE_SUPPORT_TICKET_MESSAGE, action: any) => {
  switch (action.type) {
    case SupportTicketMessageActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SupportTicketMessageActionTypes.GET_SUPPORT_TICKET_MESSAGES:
        case SupportTicketMessageActionTypes.GET_SUPPORT_SERVICE_TICKET_MESSAGES:
          return {
            ...state,
            items: action.payload.data.items,
            isLoading: false
          };
        case SupportTicketMessageActionTypes.UPDATE_SUPPORT_TICKET_MESSAGE:
        case SupportTicketMessageActionTypes.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE:
          return {
            ...state,
            selectedItem: NewTicketMessageItem,
            editShow: false,
            isSending: false
          };
        case SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE:
        case SupportTicketMessageActionTypes.REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE:
          return {
            ...state,
            selectedItem: NewTicketMessageItem,
            removeModalShow: false,
            isSending: false
          };;
        default:
          return { ...state };
      }

    case SupportTicketMessageActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SupportTicketMessageActionTypes.GET_SUPPORT_TICKET_MESSAGES:
        case SupportTicketMessageActionTypes.GET_SUPPORT_SERVICE_TICKET_MESSAGES:
          return {
            ...state,
            isLoading: false
          };
        case SupportTicketMessageActionTypes.UPDATE_SUPPORT_TICKET_MESSAGE:
        case SupportTicketMessageActionTypes.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE:
          return {
            ...state,
            isSending: false
          };
        case SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE:
        case SupportTicketMessageActionTypes.REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE:
          return {
            ...state,
            removeModalShow: false,
            isSending: false
          };
        default:
          return { ...state };
      }
    case SupportTicketMessageActionTypes.EDIT_SUPPORT_TICKET_MESSAGE_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewTicketMessageItem,
        editShow: true
      }
    case SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewTicketMessageItem,
        removeModalShow: false
      }
    case SupportTicketMessageActionTypes.EDIT_SUPPORT_TICKET_MESSAGE_MODAL_HIDE:
      return {
        ...state,
        editShow: false
      }
    case SupportTicketMessageActionTypes.UPDATE_SUPPORT_TICKET_MESSAGE:
    case SupportTicketMessageActionTypes.UPDATE_SUPPORT_SERVICE_TICKET_MESSAGE:
      return {
        ...state,
        isSending: true
      };
    case SupportTicketMessageActionTypes.GET_SUPPORT_TICKET_MESSAGES:
    case SupportTicketMessageActionTypes.GET_SUPPORT_SERVICE_TICKET_MESSAGES:
      return {
        ...state,
        isLoading: true
      };
    case SupportTicketMessageActionTypes.REMOVE_SUPPORT_TICKET_MESSAGE:
    case SupportTicketMessageActionTypes.REMOVE_SUPPORT_SERVICE_TICKET_MESSAGE:
      return {
        ...state,
        isSending: true
      };
    case SupportTicketMessageActionTypes.CLEAR_STATE_ITEMS:
      return {
        ...state,
        items: []
      };
    default:
      return { ...state };
  }
};

export default SupportTicketMessage;
