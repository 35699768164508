import {ContentConnectorHandlerActionTypes, ContentConnectorHandlerPayload} from "./types";

export const contentConnectorHandlerApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentConnectorHandlerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentConnectorHandlerApiResponseError = (actionType: string, error: string) => ({
  type: ContentConnectorHandlerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentConnectorHandlers = () => ({
  type: ContentConnectorHandlerActionTypes.GET_CONTENT_CONNECTOR_HANDLERS,
});

export const selectContentConnectorHandler = (actionType: string, data: ContentConnectorHandlerPayload) => ({
  type: ContentConnectorHandlerActionTypes.SELECT_CONTENT_CONNECTOR_HANDLER,
  payload: { actionType, data },
});