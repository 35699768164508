import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { PeerActivityActionTypes } from "./types";
import { peerActivityApiResponseSuccess, peerActivityApiResponseError } from "./actions";

import {
  publishActivityPeerActivity as publishActivityPeerActivityApi,
  sendActivityPeerActivity as sendActivityPeerActivityApi,
  getPeerActivities as getPeerActivitiesApi,
  togglePinActivityPeerActivity as togglePinActivityPeerActivityApi,
  unPublishActivityPeerActivity as unPublishActivityPeerActivityApi,
  copyPeerActivity as copyPeerActivityApi,
  removePeerActivity as removePeerActivityApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

import '../../../i18/config';
import i18n from 'i18next';

function* getPeerActivities({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivitiesApi,data);
    yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES, response));
  } catch (error: any) {
    yield put(peerActivityApiResponseError(PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES, error));
  }
}

function* onUpdatePeerActivityItems({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendActivityPeerActivityApi,data);
    yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.UPDATE_PEER_ACTIVITIES, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(peerActivityApiResponseError(PeerActivityActionTypes.UPDATE_PEER_ACTIVITIES, error));
    yield call(showErrorNotification, error);
  }
}

function* onPublishPeerActivityItems({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(publishActivityPeerActivityApi,data);
    yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.PUBLISH_PEER_ACTIVITIES, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(peerActivityApiResponseError(PeerActivityActionTypes.PUBLISH_PEER_ACTIVITIES, error));
    yield call(showErrorNotification, error);
  }
}

function* onUnPublishPeerActivityItem({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(unPublishActivityPeerActivityApi,data);
    yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(peerActivityApiResponseError(PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY, error));
    yield call(showErrorNotification, error);
  }
}
function* onCopyPeerActivity({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(copyPeerActivityApi,data);
    yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.COPY_PEER_ACTIVITY, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(peerActivityApiResponseError(PeerActivityActionTypes.COPY_PEER_ACTIVITY, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemovePeerActivity({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removePeerActivityApi,data);
    yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.REMOVE_PEER_ACTIVITY, response));
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(peerActivityApiResponseError(PeerActivityActionTypes.REMOVE_PEER_ACTIVITY, error));
    yield call(showErrorNotification, error);
  }
}

function* togglePinPeerActivity({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(togglePinActivityPeerActivityApi,data);
    yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.TOGGLE_PIN, response));
  } catch (error: any) {
    yield put(peerActivityApiResponseError(PeerActivityActionTypes.TOGGLE_PIN, error));
  }
}

function* publishPeerActivitySuccessful({ payload: data }: any) {
  yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_SUCCESSFUL, data));
  yield call(showSuccessNotification, i18n.t('peerActivity:publishSuccessful'));
}

function* publishPeerActivityFailed({ payload: data }: any) {
  yield put(peerActivityApiResponseError(PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_FAILED, data));
  yield call(showErrorNotification, i18n.t('peerActivity:publishFailed'));
}

function* unPublishPeerActivitySuccessful({ payload: data }: any) {
  yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_SUCCESSFUL, data));
  yield call(showSuccessNotification, i18n.t('peerActivity:unPublishSuccessful'));
}

function* unPublishPeerActivityFailed({ payload: data }: any) {
  yield put(peerActivityApiResponseError(PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_FAILED, data));
  yield call(showErrorNotification, i18n.t('peerActivity:unPublishFailed'));
}

function* pinPeerActivitySuccessful({ payload: data }: any) {
  yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.TOGGLE_PIN, data));
  yield call(showSuccessNotification, i18n.t('peerActivity:pinSuccessful'));
}

function* pinPeerActivityFailed({ payload: data }: any) {
  yield put(peerActivityApiResponseError(PeerActivityActionTypes.TOGGLE_PIN, data));
  yield call(showErrorNotification, i18n.t('peerActivity:pinFailed'));
}

function* unPinPeerActivitySuccessful({ payload: data }: any) {
  yield put(peerActivityApiResponseSuccess(PeerActivityActionTypes.TOGGLE_PIN, data));
  yield call(showSuccessNotification, i18n.t('peerActivity:unPinSuccessful'));
}

function* unPinPeerActivityFailed({ payload: data }: any) {
  yield put(peerActivityApiResponseError(PeerActivityActionTypes.TOGGLE_PIN, data));
  yield call(showErrorNotification, i18n.t('peerActivity:unPinFailed'));
}

export function* watchGetPeerActivitys() {
  yield takeEvery(PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES, getPeerActivities);
}

export function* watchTogglePinPeerActivity() {
  yield takeEvery(PeerActivityActionTypes.TOGGLE_PIN, togglePinPeerActivity);
}
export function* watchOnUpdatePeerActivityItems() {
  yield takeEvery(PeerActivityActionTypes.UPDATE_PEER_ACTIVITIES, onUpdatePeerActivityItems);
}

export function* watchOnPublishPeerActivityItems() {
  yield takeEvery(PeerActivityActionTypes.PUBLISH_PEER_ACTIVITIES, onPublishPeerActivityItems);
}
export function* watchOnUnPublishPeerActivityItem() {
  yield takeEvery(PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY, onUnPublishPeerActivityItem);
}

export function* watchOnPublishPeerActivitySuccessful() {
  yield takeEvery(PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_SUCCESSFUL, publishPeerActivitySuccessful);
}

export function* watchOnPublishPeerActivityFailed() {
  yield takeEvery(PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_FAILED, publishPeerActivityFailed);
}

export function* watchOnUnPublishPeerActivitySuccessful() {
  yield takeEvery(PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_FAILED, unPublishPeerActivityFailed);
}

export function* watchOnUnPublishPeerActivityFailed() {
  yield takeEvery(PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_SUCCESSFUL, unPublishPeerActivitySuccessful);
}

export function* watchOnPinPeerActivitySuccessful() {
  yield takeEvery(PeerActivityActionTypes.PIN_PEER_ACTIVITY_SUCCESSFUL, pinPeerActivitySuccessful);
}

export function* watchOnPinPeerActivityFailed() {
  yield takeEvery(PeerActivityActionTypes.PIN_PEER_ACTIVITY_FAILED, pinPeerActivityFailed);
}

export function* watchOnUnPinPeerActivitySuccessful() {
  yield takeEvery(PeerActivityActionTypes.UNPIN_PEER_ACTIVITY_SUCCESSFUL, unPinPeerActivitySuccessful);
}

export function* watchOnUnPinPeerActivityFailed() {
  yield takeEvery(PeerActivityActionTypes.UNPIN_PEER_ACTIVITY_FAILED, unPinPeerActivityFailed);
}

export function* watchOnCopyPeerActivity() {
  yield takeEvery(PeerActivityActionTypes.COPY_PEER_ACTIVITY, onCopyPeerActivity);
}

export function* watchOnRemovePeerActivity() {
  yield takeEvery(PeerActivityActionTypes.REMOVE_PEER_ACTIVITY, onRemovePeerActivity);
}


function* peerActivitySaga() {
  yield all([
      fork(watchGetPeerActivitys),
      fork(watchTogglePinPeerActivity),
      fork(watchOnUpdatePeerActivityItems),
      fork(watchOnPublishPeerActivityItems),
      fork(watchOnPublishPeerActivitySuccessful),
      fork(watchOnPublishPeerActivityFailed),
      fork(watchOnUnPublishPeerActivitySuccessful),
      fork(watchOnUnPublishPeerActivityFailed),
      fork(watchOnPinPeerActivitySuccessful),
      fork(watchOnPinPeerActivityFailed),
      fork(watchOnUnPinPeerActivitySuccessful),
      fork(watchOnUnPinPeerActivityFailed),
      fork(watchOnUnPublishPeerActivityItem),
      fork(watchOnCopyPeerActivity),
      fork(watchOnRemovePeerActivity)
  ]);
}

export default peerActivitySaga;
