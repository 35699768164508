import React, {useEffect, useState} from "react";
import {MessengerMessageType} from "../../../redux/Messenger/Chat/types";
import {MediaFileType} from "../../../data";

interface InputProps {
    message: MessengerMessageType;
}

const MessageAttachmentSmallBlock = ({message}:InputProps) => {

    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);

    useEffect(()=>{
        if (message?.images && message?.images?.total !== undefined && message?.images?.total > 0){
            setImages([...message.images.items]);
        } else {
            setImages([]);
        }
        if (message?.videos && message?.videos?.total !== undefined && message?.videos?.total > 0){
            setVideos([...message.videos.items]);
        } else {
            setVideos([]);
        }
        if (message?.files && message?.files?.total !== undefined && message?.files?.total > 0){
            setFiles([...message.files.items]);
        } else {
            setFiles([]);
        }
        // eslint-disable-next-line
    },[message?.uuid]);

    return (
        <>
            {
                (images || []).map((file:MediaFileType, key: number)=> (<span key={'attachment_'+message.uuid+'_images_'+key} >
                                                   <img
                                                       height="80"
                                                       className="avatar-sm rounded bg-light me-2 mb-2"
                                                       alt={file.name}
                                                       src={file.url}
                                                   />
                                        </span>))
            }
            {(videos || []).map((file:MediaFileType, key: number)=> (<div className="text-muted" key={'attachment_'+message.uuid+'_videos_'+key}><span className="pe-1"><i className="ri-video-line align-bottom"/> </span><small>{file.name}</small></div>))
            }
            {(files || []).map((file:MediaFileType, key: number)=> (<div className="text-muted" key={'attachment_'+message.uuid+'_files_'+key}><span className="pe-1"><i className="ri-file-line align-bottom"/> </span><small>{file.name}</small></div>))
            }
        </>
    )
};

export default MessageAttachmentSmallBlock;