import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import {getCurrentLanguage} from "../helpers/functions";

const en_common = require("./en/common.json");
const en_project = require("./en/project.json");
const en_channel = require("./en/channel.json");
const en_connectorHandler = require("./en/connectorHandler.json");
const en_connector = require("./en/connector.json");
const en_counter = require("./en/counter.json");
const en_target = require("./en/target.json");
const en_targetStatus = require("./en/targetStatus.json");
const en_targetType = require("./en/targetType.json");
const en_validator = require("./en/validator.json");
const en_user = require("./en/user.json");
const en_notification = require("./en/notification.json");
const en_notificationStatus = require("./en/notificationStatus.json");
const en_wizard = require("./en/wizard.json");
const en_search = require("./en/search.json");
const en_peerActivity = require("./en/peerActivity.json");
const en_comment = require("./en/comment.json");
const en_stat = require("./en/stat.json");
const en_peerActivityStatus = require("./en/peerActivityStatus.json");
const en_eventType = require("./en/eventType.json");
const en_peerActivityType = require("./en/peerActivityType.json");
const en_peerActivityMode = require("./en/peerActivityMode.json");
const en_recurrentPeriod = require("./en/recurrentPeriod.json");
const en_template = require("./en/template.json");
const en_quickAnswer = require("./en/quickAnswer.json");
const en_statCounter = require("./en/statCounter.json");
const en_connectorDataParam = require("./en/connectorDataParam.json");
const en_plan = require("./en/plan.json");
const en_role = require("./en/role.json");
const en_auth = require("./en/auth.json");
const en_cabinet = require("./en/cabinet.json");
const en_menu = require("./en/menu.json");
const en_messengerChannel = require("./en/messengerChannel.json");
const en_messengerChat = require("./en/messengerChat.json");
const en_messengerComment = require("./en/messengerComment.json");
const en_messengerContact = require("./en/messengerContact.json");
const en_customer = require("./en/customer.json");
const en_settings = require("./en/settings.json");
const en_sale = require("./en/sale.json");
const en_peerComment = require("./en/peerComment.json");
const en_support = require("./en/support.json");
const en_page = require("./en/page.json");
const en_connectorHandlerParam = require("./en/connectorHandlerParam.json");
const en_compare = require("./en/compare.json");
const en_ai = require("./en/ai.json");
const en_privacy = require("./en/privacy.json");
const en_engagement = require("./en/engagement.json");
const en_currency = require("./en/currency.json");
const en_price = require("./en/price.json");
const en_store = require("./en/store.json");
const en_product = require("./en/product.json");
const en_integration = require("./en/integration.json");
const en_payment = require("./en/payment.json");
const en_delivery = require("./en/delivery.json");
const en_category = require("./en/category.json");
const en_webhook = require("./en/webhook.json");
const en_orderStatus = require("./en/orderStatus.json");
const en_shortener = require("./en/shortener.json");
const en_task = require("./en/task.json");
const en_device = require("./en/device.json");

const ru_common = require("./ru/common.json");
const ru_project = require("./ru/project.json");
const ru_channel = require("./ru/channel.json");
const ru_connectorHandler = require("./ru/connectorHandler.json");
const ru_connector = require("./ru/connector.json");
const ru_counter = require("./ru/counter.json");
const ru_target = require("./ru/target.json");
const ru_targetStatus = require("./ru/targetStatus.json");
const ru_targetType = require("./ru/targetType.json");
const ru_validator = require("./ru/validator.json");
const ru_user = require("./ru/user.json");
const ru_notification = require("./ru/notification.json");
const ru_notificationStatus = require("./ru/notificationStatus.json");
const ru_wizard = require("./ru/wizard.json");
const ru_search = require("./ru/search.json");
const ru_peerActivity = require("./ru/peerActivity.json");
const ru_comment = require("./ru/comment.json");
const ru_stat = require("./ru/stat.json");
const ru_peerActivityStatus = require("./ru/peerActivityStatus.json");
const ru_eventType = require("./ru/eventType.json");
const ru_peerActivityType = require("./ru/peerActivityType.json");
const ru_peerActivityMode = require("./ru/peerActivityMode.json");
const ru_recurrentPeriod = require("./ru/recurrentPeriod.json");
const ru_template = require("./ru/template.json");
const ru_quickAnswer = require("./ru/quickAnswer.json");
const ru_statCounter = require("./ru/statCounter.json");
const ru_connectorDataParam = require("./ru/connectorDataParam.json");
const ru_plan = require("./ru/plan.json");
const ru_role = require("./ru/role.json");
const ru_auth = require("./ru/auth.json");
const ru_cabinet = require("./ru/cabinet.json");
const ru_menu = require("./ru/menu.json");
const ru_messengerChannel = require("./ru/messengerChannel.json");
const ru_messengerChat = require("./ru/messengerChat.json");
const ru_messengerComment = require("./ru/messengerComment.json")
const ru_messengerContact = require("./ru/messengerContact.json");
const ru_customer = require("./ru/customer.json");
const ru_settings = require("./ru/settings.json");
const ru_sale = require("./ru/sale.json");
const ru_peerComment = require("./ru/peerComment.json");
const ru_support = require("./ru/support.json");
const ru_page = require("./ru/page.json");
const ru_connectorHandlerParam = require("./ru/connectorHandlerParam.json");
const ru_compare = require("./ru/compare.json");
const ru_ai = require("./ru/ai.json");
const ru_privacy = require("./ru/privacy.json");
const ru_engagement = require("./ru/engagement.json");
const ru_currency = require("./ru/currency.json");
const ru_price = require("./ru/price.json");
const ru_store = require("./ru/store.json");
const ru_product = require("./ru/product.json");
const ru_integration = require("./ru/integration.json");
const ru_payment = require("./ru/payment.json");
const ru_delivery = require("./ru/delivery.json");
const ru_category = require("./ru/category.json");
const ru_webhook = require("./ru/webhook.json");
const ru_orderStatus = require("./ru/orderStatus.json");
const ru_shortener = require("./ru/shortener.json");
const ru_task = require("./ru/task.json");
const ru_device = require("./ru/device.json");

const ua_common = require("./ua/common.json");
const ua_project = require("./ua/project.json");
const ua_channel = require("./ua/channel.json");
const ua_connectorHandler = require("./ua/connectorHandler.json");
const ua_connector = require("./ua/connector.json");
const ua_counter = require("./ua/counter.json");
const ua_target = require("./ua/target.json");
const ua_targetStatus = require("./ua/targetStatus.json");
const ua_targetType = require("./ua/targetType.json");
const ua_validator = require("./ua/validator.json");
const ua_user = require("./ua/user.json");
const ua_notification = require("./ua/notification.json");
const ua_notificationStatus = require("./ua/notificationStatus.json");
const ua_wizard = require("./ua/wizard.json");
const ua_search = require("./ua/search.json");
const ua_peerActivity = require("./ua/peerActivity.json");
const ua_comment = require("./ua/comment.json");
const ua_stat = require("./ua/stat.json");
const ua_peerActivityStatus = require("./ua/peerActivityStatus.json");
const ua_eventType = require("./ua/eventType.json");
const ua_peerActivityType = require("./ua/peerActivityType.json");
const ua_peerActivityMode = require("./ua/peerActivityMode.json");
const ua_recurrentPeriod = require("./ua/recurrentPeriod.json");
const ua_template = require("./ua/template.json");
const ua_quickAnswer = require("./ua/quickAnswer.json");
const ua_statCounter = require("./ua/statCounter.json");
const ua_connectorDataParam = require("./ua/connectorDataParam.json");
const ua_plan = require("./ua/plan.json");
const ua_role = require("./ua/role.json");
const ua_auth = require("./ua/auth.json");
const ua_cabinet = require("./ua/cabinet.json");
const ua_menu = require("./ua/menu.json");
const ua_messengerChannel = require("./ua/messengerChannel.json");
const ua_messengerChat = require("./ua/messengerChat.json");
const ua_messengerComment = require("./ua/messengerComment.json")
const ua_messengerContact = require("./ua/messengerContact.json");
const ua_customer = require("./ua/customer.json");
const ua_settings = require("./ua/settings.json");
const ua_sale = require("./ua/sale.json");
const ua_peerComment = require("./ua/peerComment.json");
const ua_support = require("./ua/support.json");
const ua_page = require("./ua/page.json");
const ua_connectorHandlerParam = require("./ua/connectorHandlerParam.json");
const ua_compare = require("./ua/compare.json");
const ua_ai = require("./ua/ai.json");
const ua_privacy = require("./ua/privacy.json");
const ua_engagement = require("./ua/engagement.json");
const ua_currency = require("./ua/currency.json");
const ua_price = require("./ua/price.json");
const ua_store = require("./ua/store.json");
const ua_product = require("./ua/product.json");
const ua_integration = require("./ua/integration.json");
const ua_payment = require("./ua/payment.json");
const ua_delivery = require("./ua/delivery.json");
const ua_category = require("./ua/category.json");
const ua_webhook = require("./ua/webhook.json");
const ua_orderStatus = require("./ua/orderStatus.json");
const ua_shortener = require("./ua/shortener.json");
const ua_task = require("./ua/task.json");
const ua_device = require("./ua/device.json");

const ka_common = require("./ka/common.json");
const ka_project = require("./ka/project.json");
const ka_channel = require("./ka/channel.json");
const ka_connectorHandler = require("./ka/connectorHandler.json");
const ka_connector = require("./ka/connector.json");
const ka_counter = require("./ka/counter.json");
const ka_target = require("./ka/target.json");
const ka_targetStatus = require("./ka/targetStatus.json");
const ka_targetType = require("./ka/targetType.json");
const ka_validator = require("./ka/validator.json");
const ka_user = require("./ka/user.json");
const ka_notification = require("./ka/notification.json");
const ka_notificationStatus = require("./ka/notificationStatus.json");
const ka_wizard = require("./ka/wizard.json");
const ka_search = require("./ka/search.json");
const ka_peerActivity = require("./ka/peerActivity.json");
const ka_comment = require("./ka/comment.json");
const ka_stat = require("./ka/stat.json");
const ka_peerActivityStatus = require("./ka/peerActivityStatus.json");
const ka_eventType = require("./ka/eventType.json");
const ka_peerActivityType = require("./ka/peerActivityType.json");
const ka_peerActivityMode = require("./ka/peerActivityMode.json");
const ka_recurrentPeriod = require("./ka/recurrentPeriod.json");
const ka_template = require("./ka/template.json");
const ka_quickAnswer = require("./ka/quickAnswer.json");
const ka_statCounter = require("./ka/statCounter.json");
const ka_connectorDataParam = require("./ka/connectorDataParam.json");
const ka_plan = require("./ka/plan.json");
const ka_role = require("./ka/role.json");
const ka_auth = require("./ka/auth.json");
const ka_cabinet = require("./ka/cabinet.json");
const ka_menu = require("./ka/menu.json");
const ka_messengerChannel = require("./ka/messengerChannel.json");
const ka_messengerChat = require("./ka/messengerChat.json");
const ka_messengerComment = require("./ka/messengerComment.json")
const ka_messengerContact = require("./ka/messengerContact.json");
const ka_customer = require("./ka/customer.json");
const ka_settings = require("./ka/settings.json");
const ka_sale = require("./ka/sale.json");
const ka_peerComment = require("./ka/peerComment.json");
const ka_support = require("./ka/support.json");
const ka_page = require("./ka/page.json");
const ka_connectorHandlerParam = require("./ka/connectorHandlerParam.json");
const ka_compare = require("./ka/compare.json");
const ka_ai = require("./ka/ai.json");
const ka_privacy = require("./ka/privacy.json");
const ka_engagement = require("./ka/engagement.json");
const ka_currency = require("./ka/currency.json");
const ka_price = require("./ka/price.json");
const ka_store = require("./ka/store.json");
const ka_product = require("./ka/product.json");
const ka_integration = require("./ka/integration.json");
const ka_payment = require("./ka/payment.json");
const ka_delivery = require("./ka/delivery.json");
const ka_category = require("./ka/category.json");
const ka_webhook = require("./ka/webhook.json");
const ka_orderStatus = require("./ka/orderStatus.json");
const ka_shortener = require("./ka/shortener.json");
const ka_task = require("./ka/task.json");
const ka_device = require("./ka/device.json");

export const resources = {
    en: {
        auth: en_auth,
        common: en_common,
        cabinet: en_cabinet,
        validator: en_validator,
        project: en_project,
        channel: en_channel,
        connectorHandler: en_connectorHandler,
        connector: en_connector,
        counter: en_counter,
        target: en_target,
        user: en_user,
        notification: en_notification,
        wizard: en_wizard,
        search: en_search,
        peerActivity: en_peerActivity,
        comment: en_comment,
        stat: en_stat,
        peerActivityStatus: en_peerActivityStatus,
        eventType: en_eventType,
        peerActivityType:en_peerActivityType,
        notificationStatus:en_notificationStatus,
        recurrentPeriod:en_recurrentPeriod,
        targetStatus:en_targetStatus,
        targetType:en_targetType,
        peerActivityMode:en_peerActivityMode,
        template:en_template,
        quickAnswer:en_quickAnswer,
        statCounter: en_statCounter,
        connectorDataParam:en_connectorDataParam,
        plan:en_plan,
        role:en_role,
        menu:en_menu,
        messengerChannel: en_messengerChannel,
        messengerChat: en_messengerChat,
        messengerComment: en_messengerComment,
        messengerContact: en_messengerContact,
        customer: en_customer,
        settings: en_settings,
        sale: en_sale,
        peerComment: en_peerComment,
        support: en_support,
        page:en_page,
        connectorHandlerParam: en_connectorHandlerParam,
        compare: en_compare,
        ai: en_ai,
        privacy: en_privacy,
        engagement: en_engagement,
        currency: en_currency,
        price:en_price,
        store: en_store,
        product: en_product,
        integration: en_integration,
        payment: en_payment,
        delivery: en_delivery,
        category: en_category,
        webhook: en_webhook,
        orderStatus: en_orderStatus,
        shortener: en_shortener,
        task: en_task,
        device: en_device

    },
    ru: {
        auth: ru_auth,
        common: ru_common,
        cabinet: ru_cabinet,
        validator: ru_validator,
        project: ru_project,
        channel: ru_channel,
        connectorHandler: ru_connectorHandler,
        connector: ru_connector,
        counter: ru_counter,
        target: ru_target,
        user: ru_user,
        notification: ru_notification,
        wizard: ru_wizard,
        search: ru_search,
        peerActivity: ru_peerActivity,
        comment: ru_comment,
        stat: ru_stat,
        peerActivityStatus: ru_peerActivityStatus,
        eventType: ru_eventType,
        peerActivityType:ru_peerActivityType,
        notificationStatus:ru_notificationStatus,
        recurrentPeriod:ru_recurrentPeriod,
        targetStatus:ru_targetStatus,
        targetType:ru_targetType,
        peerActivityMode:ru_peerActivityMode,
        template:ru_template,
        quickAnswer:ru_quickAnswer,
        statCounter: ru_statCounter,
        connectorDataParam:ru_connectorDataParam,
        plan:ru_plan,
        role:ru_role,
        menu:ru_menu,
        messengerChannel: ru_messengerChannel,
        messengerChat: ru_messengerChat,
        messengerComment: ru_messengerComment,
        messengerContact: ru_messengerContact,
        customer: ru_customer,
        settings: ru_settings,
        sale: ru_sale,
        peerComment: ru_peerComment,
        support: ru_support,
        page:ru_page,
        connectorHandlerParam: ru_connectorHandlerParam,
        compare: ru_compare,
        ai: ru_ai,
        privacy: ru_privacy,
        engagement: ru_engagement,
        currency: ru_currency,
        price: ru_price,
        store: ru_store,
        product: ru_product,
        integration: ru_integration,
        payment: ru_payment,
        delivery: ru_delivery,
        category: ru_category,
        webhook: ru_webhook,
        orderStatus: ru_orderStatus,
        shortener: ru_shortener,
        task: ru_task,
        device: ru_device
    },
    ua:{
        auth: ua_auth,
        common: ua_common,
        cabinet: ua_cabinet,
        validator: ua_validator,
        project: ua_project,
        channel: ua_channel,
        connectorHandler: ua_connectorHandler,
        connector: ua_connector,
        counter: ua_counter,
        target: ua_target,
        user: ua_user,
        notification: ua_notification,
        wizard: ua_wizard,
        search: ua_search,
        peerActivity: ua_peerActivity,
        comment: ua_comment,
        stat: ua_stat,
        peerActivityStatus: ua_peerActivityStatus,
        eventType: ua_eventType,
        peerActivityType:ua_peerActivityType,
        notificationStatus:ua_notificationStatus,
        recurrentPeriod:ua_recurrentPeriod,
        targetStatus:ua_targetStatus,
        targetType:ua_targetType,
        peerActivityMode:ua_peerActivityMode,
        template:ua_template,
        quickAnswer:ua_quickAnswer,
        statCounter: ua_statCounter,
        connectorDataParam:ua_connectorDataParam,
        plan:ua_plan,
        role:ua_role,
        menu:ua_menu,
        messengerChannel: ua_messengerChannel,
        messengerChat: ua_messengerChat,
        messengerComment: ua_messengerComment,
        messengerContact: ua_messengerContact,
        customer: ua_customer,
        settings: ua_settings,
        sale: ua_sale,
        peerComment: ua_peerComment,
        support: ua_support,
        page:ua_page,
        connectorHandlerParam: ua_connectorHandlerParam,
        compare: ua_compare,
        ai: ua_ai,
        privacy: ua_privacy,
        engagement: ua_engagement,
        currency: ua_currency,
        price: ua_price,
        store: ua_store,
        product: ua_product,
        integration: ua_integration,
        payment: ua_payment,
        delivery: ua_delivery,
        category: ua_category,
        webhook: ua_webhook,
        orderStatus: ua_orderStatus,
        shortener: ua_shortener,
        task: ua_task,
        device: ua_device

    },
    ka:{
        auth: ka_auth,
        common: ka_common,
        cabinet: ka_cabinet,
        validator: ka_validator,
        project: ka_project,
        channel: ka_channel,
        connectorHandler: ka_connectorHandler,
        connector: ka_connector,
        counter: ka_counter,
        target: ka_target,
        user: ka_user,
        notification: ka_notification,
        wizard: ka_wizard,
        search: ka_search,
        peerActivity: ka_peerActivity,
        comment: ka_comment,
        stat: ka_stat,
        peerActivityStatus: ka_peerActivityStatus,
        eventType: ka_eventType,
        peerActivityType:ka_peerActivityType,
        notificationStatus:ka_notificationStatus,
        recurrentPeriod:ka_recurrentPeriod,
        targetStatus:ka_targetStatus,
        targetType:ka_targetType,
        peerActivityMode:ka_peerActivityMode,
        template:ka_template,
        quickAnswer:ka_quickAnswer,
        statCounter: ka_statCounter,
        connectorDataParam:ka_connectorDataParam,
        plan:ka_plan,
        role:ka_role,
        menu:ka_menu,
        messengerChannel: ka_messengerChannel,
        messengerChat: ka_messengerChat,
        messengerComment: ka_messengerComment,
        messengerContact: ka_messengerContact,
        customer: ka_customer,
        settings: ka_settings,
        sale: ka_sale,
        peerComment: ka_peerComment,
        support: ka_support,
        page:ka_page,
        connectorHandlerParam: ka_connectorHandlerParam,
        compare: ka_compare,
        ai: ka_ai,
        privacy: ka_privacy,
        engagement: ka_engagement,
        currency: ka_currency,
        price: ka_price,
        store: ka_store,
        product: ka_product,
        integration: ka_integration,
        payment: ka_payment,
        delivery: ka_delivery,
        category: ka_category,
        webhook: ka_webhook,
        orderStatus: ka_orderStatus,
        shortener: ka_shortener,
        task: ka_task,
        device: ka_device

    }
} as const;

const language = getCurrentLanguage();

i18next.use(initReactI18next).init({
    lng: language || "en",
    fallbackLng: "en",
    ns: [
        'common',
        'cabinet',
        'project',
        'validator',
        'channel',
        'connectorHandler',
        'connector',
        'counter',
        'target',
        'user',
        'notification',
        'wizard',
        'search',
        'peerActivity',
        'comment',
        'stat',
        'peerActivityStatus',
        'eventType',
        'peerActivityType',
        'notificationStatus',
        'recurrentPeriod',
        'targetStatus',
        'targetType',
        'peerActivityMode',
        'template',
        'statCounter',
        'connectorDataParam',
        'plan',
        'role',
        'auth',
        'menu',
        'messengerChannel',
        'messengerChat',
        'messengerComment',
        'messengerContact',
        'customer',
        'settings',
        'sale',
        'peerComment',
        'support',
        'page',
        'connectorHandlerParam',
        'compare',
        'ai',
        'privacy',
        'engagement',
        'currency',
        'price',
        'store',
        'product',
        'integration',
        'payment',
        'delivery',
        'category',
        'webhook',
        'orderStatus',
        'shortener',
        'task',
        'device'
    ],
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
});