import {StatTileType} from "../DashboardSale/types";

export const emptyTaskTiles: StatTileType[] =
    [
        {
            label: "newCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "pendingCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "closedCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "overdueCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "inProgressCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "returnedCount",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        }
    ];
