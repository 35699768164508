import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

import '../../i18/config';
import {useTranslation} from "react-i18next";
import {StatTileType} from "../../redux/Stat/DashboardSale/types";
import {OrderStatusTypes} from "../../redux/Sale/OrderStatus/types";
import {capitalizeFirstLetter} from "../../helpers/functions";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {CurrencyType} from "../../redux/Sale/Currency/types";


interface ItemProps {
    items: [] | Array<StatTileType>;
    currencies: Array<CurrencyType>
}

const OrdersCurrenciesChart = ({ items, currencies }: ItemProps) => {

    const { t } = useTranslation();

    const [{ shownStatus, shownItems, chartLabels, series }, setState ] = useState({
        shownStatus: OrderStatusTypes.ALL,
        shownItems: [],
        chartLabels: [],
        series: [],
    });

    const fetchChartItems = (shownItems: StatTileType[]) => {
        shownItems.map((item: StatTileType)=>{
            const currency = (currencies || []).filter((currencyItem: CurrencyType)=>{
                return currencyItem.uuid === item.label
            }).shift();
            if (currency?.uuid) {
                chartLabels.push(currency.name);
                series.push(item.value.last || 0);
            }
            return item;
        });
    }

    useEffect(()=>{
        const newShownItems=(items || []).filter((item:StatTileType)=> item.type===shownStatus);
        setState(prevState => ({ ...prevState, shownItems: [...newShownItems]}));
    },[shownStatus, items]);

    useEffect(()=>{
        fetchChartItems(shownItems);
        setState(prevState => ({ ...prevState, chartLabels: [...chartLabels], series: [...series]}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shownItems, currencies])

    const handleClickShownStatus = (status:string) => {
        // @ts-ignore: Unreachable code error
        setState(prevState => ({ ...prevState, shownStatus: status}));
    }

    const options:ApexOptions =
        {
            labels:chartLabels,
            chart: {
                type: "donut",
            },
            plotOptions: {
                pie: {
                    offsetX: 0,
                    offsetY: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: false,
                        }
                    },
                },
            },
            dataLabels: {
                enabled: true,
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center'
            },
            stroke: {
                lineCap: "round",
                width: 0
            },
//            colors: ['#29badb','#589662','#f17171','#f1c40f'],
        };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('stat:orderCurrencies')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted">{t('orderStatus:orderStatusType'+capitalizeFirstLetter(shownStatus))}<i className="mdi mdi-chevron-down ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {(Object.keys(OrderStatusTypes).map((item)=>{
                            return(
                                <DropdownItem onClick={()=>{handleClickShownStatus(OrderStatusTypes[item])}} key={"ordersStatusCountriesFilter_"+OrderStatusTypes[item]}>{t('orderStatus:orderStatusType'+capitalizeFirstLetter(OrderStatusTypes[item]))}</DropdownItem>
                            )
                        }))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div id="prjects-status" className="apex-charts" dir="ltr">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        height="230"
                        className="apex-charts"
                    />
                </div>


            </CardBody>
        </Card>
    );
}

export default OrdersCurrenciesChart;