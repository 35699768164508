import {SaleCurrencyActionTypes, SaleCurrencyPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleCurrencyApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleCurrencyActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleCurrencyApiResponseError = (actionType: string, error: string) => ({
  type: SaleCurrencyActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleCurrencies = (data: MainMetaType) => ({
  type: SaleCurrencyActionTypes.GET_SALE_CURRENCIES,
  payload: data,
});

export const showRightModalSaleCurrency= (data: SaleCurrencyPayload) => ({
  type: SaleCurrencyActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleCurrency= () => ({
  type: SaleCurrencyActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleCurrency = (data: any) => ({
  type: SaleCurrencyActionTypes.UPDATE_SALE_CURRENCY,
  payload: data,
});

export const editDataSaleCurrency = (data: SaleCurrencyPayload) => ({
  type: SaleCurrencyActionTypes.EDIT_SALE_CURRENCY,
  payload: data,
});

export const removeSaleCurrencyModalShow = (data: SaleCurrencyPayload) => ({
  type: SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY_MODAL_SHOW,
  payload: data,
});

export const removeSaleCurrencyModalHide = () => ({
  type: SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY_MODAL_HIDE,
});

export const onRemoveSaleCurrency = (data: SaleCurrencyPayload) => ({
  type: SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY,
  payload: data,
});