import React, {useEffect, useState} from "react";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import {hexToRGB} from "../helpers/functions";

interface InputColorProps {
  id: string;
  value: string;
  onChange: (colorHex:string) => void;
  placeholder?: string;
}

interface ColorProps {
    r: number;
    g: number;
    b: number;
    a: number;
}

const InputColor = ({ id, value="#007aff" , onChange, placeholder }: InputColorProps) => {

    const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
    const onChangeDisplayColorPicker = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    if (value===null) {value = "#007aff";}

    const [color, setColor] = useState<ColorProps>(hexToRGB(value,1));
    const onChangeColor = (color: ColorProps) => {
        setColor(color);
    };

    useEffect(() => {
        setColor(hexToRGB(value,1));
    }, [value]);

    const styles = reactCSS({
        'default': {
            color: {
                width: '20px',
                height: '20px',
                borderRadius: '15px',
                background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
            },
            swatch: {
                padding: '2px',
                // background: '#fff',
                // borderRadius: '15px',
                // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                // display: 'inline-block',
                // cursor: 'pointer',
                // borderColor: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
            },
            popover: {
                 position: 'absolute' as 'absolute',
                 zIndex: '2',
            },
            cover: {
                position: 'fixed' as 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const handleChange = (color:any) => {
        setColor(color.rgb);
        onChange(color.hex);
    };

  return (
      <div>
          <div style={ styles.swatch } onClick={ onChangeDisplayColorPicker } className="d-flex flex-row">
              <div style={ styles.color } />
              {placeholder &&
                <label className="form-check-label ms-2">{placeholder}</label>
              }
          </div>
          { displayColorPicker &&
          <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ onChangeDisplayColorPicker }/>
              <SketchPicker color={ color } onChange={ handleChange } />
          </div>
          }


      </div>
  );
};
export default InputColor;
