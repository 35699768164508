// types
import {DomainType, ShortenerActionTypes, UrlShortenerState, UrlShortenerType} from "./types";

export const m9sShopDomainItem:DomainType = {
  enabled: true,
  name: 'm9s.shop',
  code: 'm9s.shop',
}

export const NewShortenerItem:UrlShortenerType = {
  domain: m9sShopDomainItem,
  enabled: false,
  url: '',
  redirectUrl: '',
  isApproved: false
}

export const INIT_STATE_SHORTENER: UrlShortenerState = {
  selectedItem: NewShortenerItem,
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false,
};

const UrlShortener = (state = INIT_STATE_SHORTENER, action: any) => {
  switch (action.type) {
    case ShortenerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ShortenerActionTypes.CHECK_URL_SHORTENER:
          if (action.payload.data.response && action.payload.data.response === 'wsock'){
            state.requestMetadata = action.payload.data.requestMetadata;

            return {
              ...state,
            };
          }

          return {
            ...state,
            isSending: false
          };

        case ShortenerActionTypes.SHORTENER_URL_IS_AVAILABLE_RESPONSE:

          if (state?.requestMetadata?.uuid && state.requestMetadata.uuid === action.payload.data.requestMetadata.uuid){
            return {
              ...state,
              selectedItem: {...action.payload.data.item},
              isSending: false
            };
          }

          return {
            ...state,
          };
        case ShortenerActionTypes.SHORTENER_URL_IS_NOT_AVAILABLE_RESPONSE:

          return {
            ...state,
            isSending: false
          };

        default:
          return { ...state };
      }

    case ShortenerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ShortenerActionTypes.CHECK_URL_SHORTENER:
        case ShortenerActionTypes.SHORTENER_URL_IS_AVAILABLE_RESPONSE:
        case ShortenerActionTypes.SHORTENER_URL_IS_NOT_AVAILABLE_RESPONSE:
          return {
            ...state,
            isSending: false
          };

        default:
          return { ...state };
      }
    case ShortenerActionTypes.CHECK_URL_SHORTENER:
      return {
        ...state,
        selectedItem: action.payload.data,
        isSending: true
      }

    default:
      return { ...state };
  }
};

export default UrlShortener;
