// types
import {MainVersionHistoryActionTypes, MainVersionHistoryState, MainVersionHistoryType} from "./types";

export const INIT_STATE_MAIN_VERSION_HISTORY: MainVersionHistoryState = {
  items: [],
};

const MainVersionHistory = (state = INIT_STATE_MAIN_VERSION_HISTORY, action: any) => {
  switch (action.type) {
    case MainVersionHistoryActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MainVersionHistoryActionTypes.GET_VERSION_HISTORY_LIST:
          return {
            ...state,
            items: action.payload.data.items,
            isMainVersionHistoryFetched: true,
            getMainVersionHistoryLoading: false,
          };
        default:
          return { ...state };
      }

    case MainVersionHistoryActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case MainVersionHistoryActionTypes.GET_VERSION_HISTORY_LIST:
          return {
            ...state,
            isMainVersionHistoryFetched: false,
            getMainVersionHistoryLoading: false,
          };
        default:
          return { ...state };
      }

    default:
      return { ...state };
  }
};

export default MainVersionHistory;
