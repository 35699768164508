import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as route from "../../api/routes";
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../i18/config';
import {useTranslation} from "react-i18next";

import {StatStatCounterDataType} from "../../redux/Stat/StatCounter/types";
import {ChartTypes} from "../../data/chartTypes";
import {StatPeerChannelDataType} from "../../redux/Stat/PeerChannel/types";
import {convertToMomentLocale} from "../../helpers/functions";

interface InputProps {
    items: [] | Array<StatStatCounterDataType>;
}

const CountersChart = ({ items }: InputProps) => {

    const { t, i18n } = useTranslation();
    moment.locale(convertToMomentLocale(i18n.language));

    const [ seriesLabels, setSeriesLabels ] = useState<any[]>([]);
    const [ series, setSeries ] = useState<any[]>([]);
    const [ chartType, setChartType ] = useState<string>(ChartTypes.LINE);

    const prepareData = (items: StatPeerChannelDataType[]) => {
        let seriesLabelsData= [];
        let seriesData = [];
        let arSeries = items.reduce((acc, item) => {
            seriesLabelsData.push(item.date);
            for(let itemKey in item) {
                if (itemKey === 'date') continue;
                if(!acc[itemKey]) acc[itemKey]=[];
                acc[itemKey].push({x: item.date, y:item[itemKey]});
            }
            return acc;
        }, {} as {[key: string]: any[]});
        seriesData = Object.entries(arSeries)
            .map(([itemKey, value]) => ({name: t('stat:' + itemKey), type: chartType, data: value}));
        setSeriesLabels([...seriesLabelsData]);
        setSeries([...seriesData]);
    }

    useEffect(() => {
        prepareData(items || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[t, items, chartType]);

    let options : ApexOptions = {
        chart: {
            height: 374,
            type: 'line',
            toolbar: {
                show: false,
            }
        },
        stroke: chartType === ChartTypes.LINE ? { curve: 'smooth', width: 3 } : {},
        fill: {
            opacity: 1
        },
        // markers: {
        //   size: 3,
        //     strokeWidth: 2,
        //     hover: {
        //         size: 4,
        //     }
        // },
        xaxis: {
            categories: seriesLabels,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                formatter: function (value) {
                    return moment(value,'YYYY-MM-DD').format('DD MMM YYYY');
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                barHeight: '80%',
                rangeBarOverlap: false,

                columnWidth: '80%',
            }
        },
    };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('counter:counters')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_STAT_COUNTERS_LIST}>{t('statCounter:manageCounters')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="d-flex flex-row justify-content-end">
                    <button type="button" className={"btn btn-icon waves-effect waves-light me-2 "+(chartType === ChartTypes.BAR ? 'btn-primary': 'btn-outline-primary')} onClick={()=>setChartType(ChartTypes.BAR)}><i
                        className="ri-bar-chart-2-fill"/>
                    </button>
                    <button type="button" className={"btn btn-icon waves-effect waves-light me-2 "+(chartType === ChartTypes.LINE ? 'btn-primary': 'btn-outline-primary')} onClick={()=>setChartType(ChartTypes.LINE)}><i
                        className="ri-line-chart-line"/>
                    </button>
                </div>

                <div className="apex-charts" >
                    <ReactApexChart
                        key={'channelActivitiesChart_'+chartType}
                        options={options}
                        series={series}
                        // @ts-ignore: Unreachable code error
                        type={chartType}
                        height="436"
                        className="apex-charts"
                    />
                </div>
            </CardBody>
        </Card>
    );
}

export default CountersChart;