import {EntityActionsType} from "../../types";
import {EventTypeType} from "../EventType/types";
import {MainMetaType} from "../../Main/common";

export enum EventNotificationActionTypes {
  API_RESPONSE_SUCCESS = "@@mainNotification/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainNotification/API_RESPONSE_ERROR",
  GET_EVENT_NOTIFICATIONS = "@@mainNotification/GET_EVENT_NOTIFICATIONS",
  READ_EVENT_NOTIFICATIONS = "@@mainNotification/READ_EVENT_NOTIFICATIONS",
}

export interface EventNotificationState extends MainMetaType{
  items: Array<any>;
  selectedItem?: EventNotificationType;
  unreadCount?:number;
  isEventNotificationFetched: boolean;
  getEventNotificationLoading: boolean;
  isNeedReload: boolean;
  isLoading: boolean;
}

export interface EventNotificationType{
  uuid: string,
  enabled: boolean,
  isImportant: boolean,
  status: string,
  dateTime: string,
  eventType?: EventTypeType
  actions?: EntityActionsType
}

export interface EventNotificationPayload {
  data: Array<any>;
}

export enum EventNotificationStatus {
  ERROR = "E",
  FINAL = "F",
  SEND = "S",
  NEW = "N",
  CANCELED = "C"
}
