import React from "react";
import {ContentProjectType} from "../../../redux/Content/Project/types";

interface ItemProps {
    item: ContentProjectType;
}
const ProjectItemLine = ({ item }: ItemProps) => {
    return(
        <>
            <div className="d-flex flex-shrink-0">
                <div className="avatar-xxs me-2 d-flex flex-shrink-0 position-relative">
                    <div className="avatar-title rounded-circle bg-light text-primary fs-16"
                         ref={element => {
                             if (element) element.style.setProperty('background-color', item.color, 'important');
                         }}/>
                </div>
                <div className="flex-1 justify-content-center flex-column d-flex text-truncate">
                    {item.name}
                </div>
            </div>
        </>
    )
}

export default ProjectItemLine;