import React, { useCallback } from 'react';
import { Col, Container, Input, InputGroup, Row } from 'reactstrap'
import type { Container as ParticlesContainer, Engine } from "tsparticles-engine";
import Countdown from "react-countdown"
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import {useTranslation} from "react-i18next";

const comingSoon = require('../../../assets/images/comingsoon.png');

interface InputProps {
    launchDate?: string;
}

const CabinetComingSoon = (inputProps?:InputProps) => {
    const { t,i18n  } = useTranslation(['common']);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>You are good to go!</span>
        } else {
            return (
                <>
                    <div className="countdownlist">
                        <div className="countdownlist-item">
                            <div className="count-title">Days</div>
                            <div className="count-num">{days}</div></div>
                        <div className="countdownlist-item">
                            <div className="count-title">Hours</div>
                            <div className="count-num">{hours}</div></div>
                        <div className="countdownlist-item"><div className="count-title">Minutes</div>
                            <div className="count-num">{minutes}</div></div><div className="countdownlist-item">
                        <div className="count-title">Seconds</div>
                        <div className="count-num">{seconds}</div></div>
                    </div>
                </>
            )
        }
    }

    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: ParticlesContainer | undefined) => {
    //    await console.log(container);
    }, []);

    return (
        <>
            <div className="cabinetComingSoonCont">

                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
                    <div className="bg-overlay"></div>
                    <Particles
                        id="tsparticles"
                        init={particlesInit}
                        loaded={particlesLoaded}
                        options={{
                            fpsLimit: 120,
                            // interactivity: {
                            //     events: {
                            //         onClick: {
                            //             enable: true,
                            //             mode: "push",
                            //         },
                            //         onHover: {
                            //             enable: true,
                            //             mode: "repulse",
                            //         },
                            //         resize: true,
                            //     },
                            //     modes: {
                            //         push: {
                            //             quantity: 4,
                            //         },
                            //         repulse: {
                            //             distance: 80,
                            //             duration: 0.4,
                            //         },
                            //     },
                            // },
                            particles: {
                                color: {
                                    value: "#ffffff",
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outModes: {
                                        default: "bounce",
                                    },
                                    random: false,
                                    speed: 2,
                                    straight: false,
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        area: 800,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    value: { min: 1, max: 4 },
                                },
                            },
                            detectRetina: true,
                            fullScreen: { enable: false }
                        }}
                    />

                    <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                        </svg>
                    </div>
                </div>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 pt-4 mb-4">
                                    <div className="mb-sm-5 pb-sm-4 pb-5">
                                        <img src={comingSoon} alt="" height="120" className="move-animation" />
                                    </div>
                                    <div className="mb-5">
                                        <h1 className="display-2 coming-soon-text">Coming Soon</h1>
                                    </div>
                                    <div>
                                        <Row className="justify-content-center mt-5">
                                            <Col lg={8}>
                                                <Countdown date={inputProps.launchDate} renderer={renderer} />
                                            </Col>
                                        </Row>

                                        <div className="mt-5">
                                            <h3>{t('common:comingSoonTitle')}</h3>
                                            <p className="text-muted">{t('common:comingSoonDescription')} 😊</p>
                                        </div>

                                        {/*<InputGroup className="countdown-input-group mx-auto my-4">*/}
                                        {/*    <Input type="email" className="form-control border-light shadow" placeholder="Enter your email address" />*/}
                                        {/*    <button className="btn btn-success" type="button" >Send*/}
                                        {/*        <i className="ri-send-plane-2-fill align-bottom ms-2"></i>*/}
                                        {/*    </button>*/}
                                        {/*</InputGroup>*/}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>
        </>
    );
};

export default CabinetComingSoon;