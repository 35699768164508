//@ts-nocheck
import React, {useEffect, useState} from "react";
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../i18/config';
import {useTranslation} from "react-i18next";

import {StatPeerChannelDataType} from "../../redux/Stat/PeerChannel/types";
import {ChartTypes} from "../../data/chartTypes";
import {convertToMomentLocale} from "../../helpers/functions";

interface InputProps {
    items: [] | Array<StatPeerChannelDataType>;
    chartType: string;
    group?: string;
    id?: string;
}

const ChannelActivitiesChartItem = ({ items, chartType, group, id }: InputProps) => {
    const { t, i18n } = useTranslation();

    const [ seriesLabels, setSeriesLabels ] = useState<any[]>([]);
    const [ series, setSeries ] = useState<any[]>([]);

    moment.locale(convertToMomentLocale(i18n.language));

    const generateSeriesLabelsData = (items: StatPeerChannelDataType[]): any[] => {
        return items ? items.map(item => item.date) : [];
    };

    const generateSeriesData = (items: StatPeerChannelDataType[], t: any): any[] => {
        return items ? items.reduce((series, item) => {
            Object.keys(item).forEach(key => {
                if (key === 'date') return;
                if (series[key] === undefined) series[key] = [];
                series[key].push(item[key]);
            });
            return series;
        }, []) : [];
    };

    useEffect(() => {
        setSeriesLabels(generateSeriesLabelsData(items));
        setSeries(generateSeriesData(items, t));
    }, [t, items, chartType]);

    let options : ApexOptions = {
        chart: {
//            id: id ? id : undefined,
            height: 374,
            type: 'line',
            group: group ? group : undefined,
            toolbar: {
                show: false,
            }
        },
        stroke: chartType === ChartTypes.LINE ? { curve: 'smooth', width: 3 } : {},
        fill: {
             opacity: 1
        },
        // markers: {
        //   size: 3,
        //     strokeWidth: 2,
        //     hover: {
        //         size: 4,
        //     }
        // },
        xaxis: {
            categories: seriesLabels,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            labels: {
                formatter: function (value) {
                    return moment(value,'YYYY-MM-DD').format('DD MMM YYYY');
                }
            }
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10
            },
        },
        legend: {
            show: true,
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                barHeight: '80%',
                rangeBarOverlap: false,

                columnWidth: '80%',
            }
        },
    };

    return(
        <>
                    <ReactApexChart
                        key={'channelActivitiesChart_'+chartType}
                        options={options}
                        series={series}
                        type={chartType}
                        height="436"
                        className="apex-charts"
                    />
        </>
    );
}

export default ChannelActivitiesChartItem;