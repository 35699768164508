import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getUserBadges = () => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_BADGES_SUMMARY);
};

export {
    getUserBadges,
};
