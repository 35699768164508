import React, {useEffect, useState, useRef} from "react";
import CreatableSelect from 'react-select/creatable';
import Select, { StylesConfig } from 'react-select';
import chroma from 'chroma-js';
import AIGenerateButton from "./AIGenerateButton";
import {
    aiTextGeneratePredictTagOpenModal
} from "../blocks/AI/TextGenerate/TextGenerateActions";
import {useRedux} from "../hooks";
import {NumericFormat} from "react-number-format";
import {showSuccessCopyNotification} from "../helpers/notifications";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Input} from "reactstrap";

interface InputTagProps {
    id: string;
    value: null | any;
    placeholder: null | string;
    onChange: (value: string) => void;
    required: null | boolean;
    readonly: false | boolean;
    className: null | string;
    isMultiple: true | boolean;
    isShowAITextGenerate: false | boolean;
    text: null | string,
    isShowCopy?: boolean
}

interface Option {
    readonly label: string;
    readonly value: string;
    readonly color?: string;
}

const InputTagText = ({ id, placeholder, value, onChange, required, className, readonly, isMultiple, isShowAITextGenerate, text, isShowCopy=false}: InputTagProps) => {

    const { dispatch } = useRedux();

    const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
    const [isShowToolbar, setShowToolbar] = useState<boolean>(false);
    const [selectedOptionsLength, setSelectedOptionsLength] = useState<number>(0);
    const [selectedOptionsText, setSelectedOptionsText] = useState<string>(null);
    const [inputValue, setInputValue] = useState<string>('');

    useEffect(() => {

        if (isShowAITextGenerate){
            setShowToolbar(true);
        } else {
            setShowToolbar(false);
        }

    }, [isShowAITextGenerate]);

    useEffect(() => {

        createTagsFromInputValue(value,',');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const updateOnChangeAction = () => {

        const selectedValues = (selectedOptions || []).map((option) => (option.value));

        if (selectedValues.length > 0) {
            onChange(selectedValues.join(','));
        } else {
            if (value !== undefined) {
                onChange('');
            }
        }

    }

    useEffect(() => {
        if ((selectedOptions || []).length>0){

            let _selectedOptionsText='';

            (selectedOptions || []).map((item:Option)=>{

                _selectedOptionsText+=item.value+' ';

                return item;
            });

            setSelectedOptionsLength(_selectedOptionsText.length+(selectedOptions || []).length);
            setSelectedOptionsText(_selectedOptionsText);
        } else {
            setSelectedOptionsLength(0);
            setSelectedOptionsText(null);
        }

        updateOnChangeAction();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    const handleAITextGenerate = () =>{
        aiTextGeneratePredictTagOpenModal({text:text},dispatch);
    }

    const createTagsFromInputValue = (inputData:string, delimiter: string)=>{

        onChangeText("");

        if (inputData !== undefined && inputData!== null && inputData.length>0) {
            if (delimiter === " ") {
                inputData.replace(/,/, " ")
            }

            const arTags = [...new Set(inputData.split(delimiter))];

            if (arTags !== undefined && arTags !== null && (arTags || []).length > 0) {

                let _selectedOptions = selectedOptions;

                arTags.map((tag: string) => {
                    let checkTag = tag.trim();
                    checkTag = checkTag.replace(/^#/,'');

                    if (checkTag.length > 0) {
                        if (!((_selectedOptions || []).filter((option: Option) => {
                            return option.value === checkTag
                        }).length > 0)) {
                            _selectedOptions.push(
                                {
                                    label: checkTag,
                                    value: checkTag
                                }
                            );
                        }
                    }

                    return tag;
                });
                setSelectedOptions([..._selectedOptions]);
            }
        }

    }

    const handleKeyDown = event => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
            case ' ':
                createTagsFromInputValue(inputValue," ");
                event.preventDefault();
        }
    }

    const onChangeText = (inputData:string) => {
        setInputValue(inputData);
    }

    const handleRemoveTag = (inputTag:Option) => {
        setSelectedOptions((selectedOptions || []).filter((item:Option)=>{return item.value !== inputTag.value}));
    }
    const handleRemoveAllTags = () => {
        setSelectedOptions([]);
    }

  return (
      <>
          {selectedOptions && (selectedOptions || []).length>0 &&
              <div className="position-relative d-flex flex-row flex-nowrap mb-2">
                  <div className="d-flex flex-row flex-grow-1 flex-wrap">
                      {(selectedOptions || []).map((item:Option, num:number)=>{
                          return (
                              <span key={"option_"+id+"_"+num}>
                                  <span className="badge bg-primary me-1 fw-normal">
                                                   {item.value}
                                                   <span className="ps-1 pe-1">|</span>
                                                   <a
                                                       href={"#"}
                                                       className="text-light fw-normal"
                                                       onClick={()=>{handleRemoveTag(item)}}
                                                   ><i className="ri-close-fill align-bottom"/></a>
                                               </span>
                                          </span>
                          );
                      })
                      }
                  </div>
                  {isShowCopy && selectedOptionsText &&
                      <div className="ms-1">
                          <CopyToClipboard text={selectedOptionsText}>
                              <button type="button" className="btn btn-outline-primary btn-icon waves-effect waves-light" onClick={showSuccessCopyNotification}><i
                                  className="ri-file-copy-2-line"/></button>
                          </CopyToClipboard>
                      </div>
                  }
                  <div className="ms-1">
                      <button type="button" className="btn btn-outline-danger btn-icon waves-effect waves-light" onClick={handleRemoveAllTags}><i
                              className="ri-delete-bin-line"/></button>
                  </div>
              </div>
          }
          <div className="position-relative d-flex flex-row flex-nowrap">
              <div className="d-flex flex-row flex-grow-1">
                  <div className="d-flex flex-column w-100">
                          <Input
                              type="text"
                              className={className || "form-control"}
                              id={ id }
                              placeholder={placeholder || ""}
                              value={inputValue || ''}
                              autoComplete="off"
                              required={ required }
                              onChange={(e: any) => {
                                  onChangeText(e.target.value);
                              }}
                              onKeyDown={handleKeyDown}
                              onPaste={(e: any) => {
                                  const pasteText = e.clipboardData
                                      .getData('text')
                                      .trim();
                                  createTagsFromInputValue(pasteText," ");
                              }}
                          />
                          <div className="lengthCount text-muted fs-10 d-flex flex-row flex-nowrap justify-content-end">
                              <span className="text-muted ms-2">
                                  <NumericFormat thousandSeparator={' '} displayType={'text'} value={selectedOptionsLength}/>
                              </span>
                          </div>
                  </div>
              </div>
              {isShowToolbar &&
                  <>
                      <div className="rightToolBarCont d-flex flex-row position-relative">
                          {isShowAITextGenerate &&
                              <div className="ms-1">
                                  <AIGenerateButton onChange={handleAITextGenerate}/>
                              </div>
                          }
                      </div>
                  </>
              }
          </div>
      </>
  );
};
export default InputTagText;
