import {StatPeerChannelTileType, StatPeerChannelContentActionTypes, StatPeerChannelPayloadType} from "./types";
import {PeriodType} from "../DashboardContent/types";

export const statPeerChannelApiResponseSuccess = (actionType: string, data: any) => ({
  type: StatPeerChannelContentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const statPeerChannelApiResponseError = (actionType: string, error: string) => ({
  type: StatPeerChannelContentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getStatPeerChannelTiles= (data: StatPeerChannelPayloadType) => ({
  type: StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES,
  payload: data,
});

export const getStatPeerChannelActivitiesData= (data: StatPeerChannelPayloadType) => ({
  type: StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA,
  payload: data,
});

export const getStatPeerChannelTargets= (data: StatPeerChannelPayloadType) => ({
  type: StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS,
  payload: data,
});

export const getStatPeerChannelActivities= (data: StatPeerChannelPayloadType) => ({
  type: StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES,
  payload: data,
});

export const getStatPeerChannelScheduleActivities= (data: StatPeerChannelPayloadType) => ({
  type: StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES,
  payload: data,
});

export const setStatPeerChannelPeriod= (data: PeriodType) => ({
  type: StatPeerChannelContentActionTypes.SET_PERIOD,
  payload: data,
});