import React, { useState, useEffect } from 'react';
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import {useRedux} from "../hooks";
import {ContentProjectType} from "../redux/Content/Project/types";
import ProjectItemLine from "../blocks/Content/Project/ProjectItemLine";
import InputSwitch from "./InputSwitch";
import {PeerActivityModeCode, PeerActivityModeType} from "../redux/Content/PeerActivityMode/types";
import PeerActivityModeItemLine from "../blocks/Content/PeerActivityMode/PeerActivityModeItemLine";
import {PeerActivityTypeType} from "../redux/Content/PeerActivityType/types";
import PeerActivityTypeItemLine from "../blocks/Content/PeerActivityType/PeerActivityTypeItemLine";
import {ContentPeerChannelType} from "../redux/Content/PeerChannel/types";
import ChannelItemLine from "../blocks/Content/PeerChannel/channelItemLine";
import {
    clearContentActivitiesFilter,
    setContentActivitiesChannelsFilter,
    setContentActivitiesModesFilter,
    setContentActivitiesProjectsFilter, setContentActivitiesTypesFilter
} from "../redux/Content/ActivitiesFilter/actions";
import {ContentConnectorSocialCalendar} from "../redux/Content/Connector/types";
import SimpleBar from 'simplebar-react';

import '../i18/config';
import {useTranslation} from "react-i18next";
import {connectorHandlerCode} from "../constants/ConnectorHandler";

interface ItemProps {
    isShow : boolean;
    handleClose: ()=>void,
}

const CalendarFilterModal = ({ isShow, handleClose }: ItemProps) => {

    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const [ isModalShow, setModalShow ] = useState<boolean>(isShow);
    const [ filterProjects, setFilterProjects] = useState<ContentProjectType[]>([]);
    const [ filterModes, setFilterModes] = useState<PeerActivityModeType[]>([]);
    const [ filterTypes, setFilterTypes] = useState<PeerActivityTypeType[]>([]);
    const [ filterChannels, setFilterChannels] = useState<ContentPeerChannelType[]>([]);

    useEffect(() => {setModalShow(isShow)},[isShow]);

    const {
        contentPeerActivityModes,
        peerActivityTypes,
        contentPeerChannels,
        contentProject,
        contentActivitiesFilter
    } = useAppSelector(state => ({
        contentPeerActivityModes: state.PeerActivityMode,
        peerActivityTypes: state.PeerActivityType,
        contentPeerChannels: state.ContentPeerChannel,
        contentProject: state.ContentProjects,
        contentActivitiesFilter: state.ContentActivitiesFilter
    }));

    const onFilterItemModesChange = (uuid:string) => {
        dispatch(setContentActivitiesModesFilter({uuid: uuid}));
    }
    const onFilterItemTypesChange = (uuid:string) => {
        dispatch(setContentActivitiesTypesFilter({uuid:uuid}));
    }
    const onFilterItemProjectsChange = (uuid:string) => {
        dispatch(setContentActivitiesProjectsFilter({uuid: uuid}));
    }
    const onFilterItemChannelsChange = (uuid:string) => {
        dispatch(setContentActivitiesChannelsFilter({uuid: uuid}));
    }
    const onFilterClear = () => {
        dispatch(clearContentActivitiesFilter());
    }

    useEffect(() => {
        if (contentProject.items !== undefined) {
            setFilterProjects(contentProject.items)
        } else {
            setFilterProjects([]);
        }
    },[contentProject?.items]);

    useEffect(() => {
        if (contentPeerActivityModes.items !== undefined) {
            setFilterModes((contentPeerActivityModes.items || []).filter((item:PeerActivityModeType)=>{return item.code !== PeerActivityModeCode.STAT}))
        } else {
            setFilterModes([]);
        }
    },[contentPeerActivityModes?.items]);

    useEffect(() => {
        if (peerActivityTypes.items !== undefined) {
            setFilterTypes(peerActivityTypes.items)
        } else {
            setFilterTypes([]);
        }
    },[peerActivityTypes?.items]);

    useEffect(() => {
        if (contentPeerChannels.items !== undefined) {

            let _filterChannels = contentPeerChannels.items.filter((item:ContentPeerChannelType)=>{
                if (item.connector && item.connector.handler && item.connector.handler.type ){
                    return (Object.values(ContentConnectorSocialCalendar) as string[]).includes(item.connector.handler.type)
                }
                return true;
            });

            let ideasChannels = contentPeerChannels.items.filter((item:ContentPeerChannelType)=>{
                return item?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS
            });

            if (ideasChannels !== undefined && ((ideasChannels || []).length>0)){
                _filterChannels=[
                    ..._filterChannels,
                    ...ideasChannels
                ]
            }


            setFilterChannels(_filterChannels);
        } else {
            setFilterChannels([]);
        }
    },[contentPeerChannels?.items]);

    return (
        <Offcanvas
            direction="end"
            isOpen={isModalShow}
            id="calendarFilter"
            toggle={handleClose}
        >
            <OffcanvasHeader className="bg-light" toggle={handleClose}>
                {t('peerActivity:activitiesFilter')}
            </OffcanvasHeader>

            <OffcanvasBody className="p-0 overflow-hidden">
                <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                    <div className="p-4">
                <div className="mb-4">
                    <h5>{t('project:projects')}:</h5>
                    {(filterProjects || []).map((item:ContentProjectType, key:number)=>(
                        <div key={'filterProjects_'+key} className="d-flex flex-row flex-grow-1 flex-nowrap mb-2">
                            <div className="d-flex flex-row flex-grow-1 text-truncate">
                                <ProjectItemLine item={item}/>
                            </div>
                            <div className="d-flex flex-row flex-shrink-0 align-items-center">
                                <InputSwitch id={'filterProjects_'+key} value={contentActivitiesFilter.projects.indexOf(item.uuid) === -1} onChange={()=>onFilterItemProjectsChange(item.uuid)} className={"form-switch-sm"}/>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="mb-4">
                    <h5>{t('peerActivityMode:activityModes')}:</h5>

                    {(filterModes || []).map((item:PeerActivityModeType, key:number)=>(
                        <div key={'filterModes_'+key} className="d-flex flex-row flex-grow-1 flex-nowrap mb-2">
                            <div className="d-flex flex-row flex-grow-1 text-truncate">
                                <PeerActivityModeItemLine item={item}/>
                            </div>
                            <div className="d-flex flex-row flex-shrink-0 align-items-center">
                                <InputSwitch id={'filterModes_'+key} value={contentActivitiesFilter.modes.indexOf(item.uuid) === -1} onChange={()=>onFilterItemModesChange(item.uuid)} className={"form-switch-sm"}/>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="mb-4">
                    <h5>{t('peerActivityType:activityTypes')}:</h5>

                    {(filterTypes || []).map((item:PeerActivityTypeType, key:number)=>(
                        <div key={'filterTypes_'+key} className="d-flex flex-row flex-grow-1 flex-nowrap mb-2">
                            <div className="d-flex flex-row flex-grow-1 text-truncate">
                                <PeerActivityTypeItemLine item={item}/>
                            </div>
                            <div className="d-flex flex-row flex-shrink-0 align-items-center">
                                <InputSwitch id={'filterTypes_'+key} value={contentActivitiesFilter.types.indexOf(item.uuid) === -1} onChange={()=>onFilterItemTypesChange(item.uuid)} className={"form-switch-sm"}/>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="mb-4">
                    <h5>{t('channel:channels')}:</h5>

                    {(filterChannels || []).map((item:ContentPeerChannelType, key:number)=>(
                        <div key={'filterChannels_'+key} className="d-flex flex-row flex-grow-1 flex-nowrap mb-2">
                            <div className="d-flex flex-row flex-grow-1 text-truncate">
                                <ChannelItemLine item={item}/>
                            </div>
                            <div className="d-flex flex-row flex-shrink-0 align-items-center">
                                <InputSwitch id={'filterChannels_'+key} value={contentActivitiesFilter.channels.indexOf(item.uuid) === -1} onChange={()=>onFilterItemChannelsChange(item.uuid)} className={"form-switch-sm"}/>
                            </div>
                        </div>
                    ))
                    }
                </div>
                    </div>
                </SimpleBar>
            </OffcanvasBody>
            <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                <div className="d-flex flex-row flex-nowrap gap-2">
                <button
                    className="btn btn-light w-100"
                    onClick={onFilterClear}
                >
                    {t('common:clear')}
                </button>
                <button
                    className="btn btn-primary w-100"
                    onClick={handleClose}
                >
                    {t('common:close')}
                </button>
                </div>
            </div>
        </Offcanvas>
    );
}

export default CalendarFilterModal