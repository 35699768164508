import {CurrencyType} from "../Currency/types";
import {CouponType} from "../Coupon/types";

export interface DiscountType {
    uuid?: string;
    name?: string;
    enabled?: boolean;
    order?: number;
    type?: DiscountTypeType;
    value?: number;
    limit?: number;
    currency?: CurrencyType;
    activeFrom?: string;
    activeTo?: string;
    priority?: number;
    isLast?: boolean;
    comment?: string;
    coupons?: Array<CouponType>
}

export enum DiscountTypeType {
    TYPE_FIXED = "F",
    TYPE_PERCENT = "P",
}