import React, {useEffect, useRef, useState, memo} from 'react';
import { Card, Form, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, Pagination, PaginationItem, PaginationLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {ContentPeerUserType} from "../../../redux/Content/PeerUser/types";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {ContentPeerCommentType} from "../../../redux/Content/PeerComment/types";

import {useRedux} from "../../../hooks";
import {getMainSearch} from "../../../redux/Main/Search/actions";

import PeerChannelSearchCard from "../../Content/PeerChannel/PeerChannelSearchCard";
import PeerActivitySearchCard from "../../Content/PeerActivity/PeerActivitySearchCard";
import PeerUserSearchCard from "../../Content/PeerUser/PeerUserSearchCard";
import CommentSearchCard from "../../Content/PeerComment/CommentSearchCard";
import {getQueryVariable} from "../../../helpers/functions";
import {getContentPeerTargetTypes} from "../../../redux/Content/PeerTargetType/actions";
import {default as RightModalTarget} from "../../Content/PeerTarget/modalForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import ButtonSpinner from "../../../components/ButtonSpinner";
import Loader from "../../../components/Loader";

const SearchIndex = () => {

    const { t } = useTranslation(['common']);

    const maxResults = 500;
    const { dispatch, useAppSelector } = useRedux();
    const [value, setValue] = useState("");
    const [initialSearch, setInitialSearch] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [isLoading, setLoading] = useState(false);

    const activitiesGrid = useRef(null);
    const channelsGrid = useRef(null);

    const columnsCountBreakPoints={
            350: 1,
            750: 2,
            900: 3,
            1250: 4,
            1500: 5
    };

    useEffect(() => {
        setSearchData();
        setShowResults(false);
        if ((initialSearch === true) && (value.length>0)){
            setInitialSearch(false);
            startSearch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value]);

    useEffect(() => {

        const query = getQueryVariable('q');

        if ((query !== undefined) && (query !== false) && (query.length>0)){
            setInitialSearch(true);
            setValue(decodeURIComponent(query));
        }
        dispatch(getContentPeerTargetTypes());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);

            if ((tab === 1) && (activitiesGrid != null)){
                activitiesGrid.current.updateLayout();
            }
            if ((tab === 2) && (channelsGrid != null)){
                channelsGrid.current.updateLayout();
            }
        }
    };

    const {
        mainSearch,
    } = useAppSelector(state => ({
        mainSearch: state.MainSearch,
    }));

    useEffect(() => {
        setLoading(mainSearch.getMainSearchLoading);
    },[mainSearch.getMainSearchLoading])

    const onDataSubmit = (e:React.SyntheticEvent) => {
        e.preventDefault();
        startSearch();
    }

    const setSearchData = () => {
        mainSearch.maxResults = maxResults;
        mainSearch.query = value;
        mainSearch.isMainSearchFetched = false;
    }

    const startSearch = () => {
        setShowResults(false);
        dispatch(getMainSearch(mainSearch));
    }

    const onChangeData = (e: React.FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
        setSearchData();
    };

    useEffect(() => {

        let isDropDownShow = (
            (mainSearch.channels && mainSearch.channels.count>0)
            ||
            (mainSearch.activities && mainSearch.activities.count>0)
            ||
            (mainSearch.peerUsers && mainSearch.peerUsers.count>0)
            ||
            (mainSearch.comments && mainSearch.comments.count>0)
        );

        if (value.length<1){
            isDropDownShow = false;
        }

        setShowResults(isDropDownShow);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mainSearch]);


    return (
        <>
            <BreadCrumb title={t('menu:search')} pageTitle={t('menu:main')} />
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Form onSubmit={onDataSubmit}>
                        <Row className="justify-content-center mb-4">
                            <Col lg={6}>
                                <Row className="g-2">
                                    <Col>
                                        <div className="position-relative mb-3">
                                            <Input type="text" className="form-control form-control-lg bg-light border-light" placeholder={t('search:enterSearch')} defaultValue={value} onChange={onChangeData}/>
                                        </div>
                                    </Col>
                                    <div className="col-auto">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-lg waves-effect waves-light"
                                            disabled={isLoading}
                                        >
                                            <i className="mdi mdi-magnify me-1"/> {t('search:search')}
                                            {isLoading &&
                                                <ButtonSpinner/>
                                            }
                                        </button>
                                    </div>
                                </Row>
                            </Col>
                            {value && value.length > 0 && showResults === true &&
                            <Col lg={12}>
                                <h5 className="fs-16 fw-bold text-center mb-0">{t('search:showingResultsFor')} "<span className="text-primary fw-medium fst-italic">{value}</span>"</h5>
                            </Col>
                            }
                        </Row>
                        </Form>
                    </CardHeader>
                    <div>
                        <Nav className="nav-tabs nav-tabs-custom" role="tablist">
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activeTab === 1 })}
                                    onClick={() => { toggleTab(1); }}
                                >
                                    <i className="ri-pages-line text-muted align-bottom me-1"/> {t('peerActivity:activities')} ({mainSearch.activities.count || 0})
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activeTab === 2 })}
                                    onClick={() => { toggleTab(2); }}
                                >
                                    <i className="ri-share-line text-muted align-bottom me-1"/> {t('channel:channels')} ({mainSearch.channels.count || 0})
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activeTab === 3 })}
                                    onClick={() => { toggleTab(3); }}
                                >
                                    <i className="ri-group-line text-muted align-bottom me-1"/> {t('user:users')} ({mainSearch.peerUsers.count || 0})
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href="#"
                                    className={classnames({ active: activeTab === 4 })}
                                    onClick={() => { toggleTab(4); }}
                                >
                                    <i className="ri-question-answer-line text-muted align-bottom me-1"/> {t('comment:comments')} ({mainSearch.comments.count || 0})
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </Card>

                {isLoading ?
                    <Loader/>
                    :
                        <TabContent activeTab={activeTab} className="text-muted mb-3">
                            <TabPane tabId={1}>
                                <Row className="pt-2">
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={columnsCountBreakPoints}
                                    >
                                        <Masonry gutter={'30px'}>
                                        {(mainSearch.activities.items || []).map((item:PeerActivityType, key:number)=>(
                                            <PeerActivitySearchCard item={item} key={"activitiesSearch_"+key}/>
                                        ))}
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </Row>
                            </TabPane>

                            <TabPane tabId={2}>
                                <Row className="pt-2">
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={columnsCountBreakPoints}
                                    >
                                        <Masonry
                                            gutter={'30px'}
                                        >
                                            {(mainSearch.channels.items || []).map((item:ContentPeerChannelType, key:number)=>(
                                                <PeerChannelSearchCard item={item} key={"channelsSearch_"+key}/>
                                            ))}
                                        </Masonry>
                                    </ResponsiveMasonry>

                                </Row>
                            </TabPane>

                            <TabPane tabId={3}>
                                <Row className="pt-2">
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={columnsCountBreakPoints}
                                    >
                                        <Masonry
                                            gutter={'30px'}
                                        >
                                            {(mainSearch.peerUsers.items || []).map((item:ContentPeerUserType, key:number)=>(
                                                <PeerUserSearchCard item={item} key={"usersSearch_"+key}/>
                                            ))}
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </Row>
                            </TabPane>

                            <TabPane tabId={4}>
                                <Row className="pt-2">
                                    <ResponsiveMasonry
                                        columnsCountBreakPoints={columnsCountBreakPoints}
                                    >
                                        <Masonry
                                            gutter={'30px'}
                                        >
                                            {(mainSearch.comments.items || []).map((item:ContentPeerCommentType, key:number)=>(
                                                <CommentSearchCard item={item} key={"commentsSearch_"+key}/>
                                            ))}
                                        </Masonry>
                                    </ResponsiveMasonry>
                                </Row>
                            </TabPane>

                        </TabContent>
                }
            </Col>
        </Row>
        <RightModalTarget/>
        </>
    );
};

export default SearchIndex;