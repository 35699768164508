import {PlanOrderActionTypes} from "./types";
import {MainMetaType} from "../../Main/common";

export const planOrderApiResponseSuccess = (actionType: string, data: any) => ({
  type: PlanOrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const planOrderApiResponseError = (actionType: string, error: string) => ({
  type: PlanOrderActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPlanOrders = (data: MainMetaType) => ({
  type: PlanOrderActionTypes.GET_ORDERS,
  payload: data
});

export const getPlanOrderDetail = (data: any) => ({
  type: PlanOrderActionTypes.GET_ORDER_DETAIL,
  payload: data,
});

export const cancelPlanOrderModalShow = () => ({
  type: PlanOrderActionTypes.CANCEL_MODAL_SHOW,
});

export const cancelPlanOrderModalHide = () => ({
  type: PlanOrderActionTypes.CANCEL_MODAL_HIDE,
});

export const onCancelPlanOrder = (data: any) => ({
  type: PlanOrderActionTypes.CANCEL_ORDER,
  payload: data,
});

export const onCreatePlanOrder = (data: any) => ({
  type: PlanOrderActionTypes.CREATE_ORDER,
  payload: data,
});