// types
import {OrderCustomerType, SaleOrderCustomerActionTypes, SaleOrderCustomerState} from "./types";

export const NewSaleOrderCustomerItem:OrderCustomerType = {
  name:null,
  phone:null,
  email:null,
  tin:null
}

export const INIT_STATE_SALE_ORDER_CUSTOMER: SaleOrderCustomerState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleOrderCustomerItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false,
  isNeedOrderReload: false,
  searchCustomers:[],
  isSearchCustomers: false
};

const SaleOrderCustomer = (state = INIT_STATE_SALE_ORDER_CUSTOMER, action: any) => {
  switch (action.type) {
    case SaleOrderCustomerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleOrderCustomerActionTypes.UPDATE_SALE_ORDER_CUSTOMER:
          return {
            ...state,
            selectedItem: NewSaleOrderCustomerItem,
            rightModalShow: false,
            isSending: false,
            isNeedOrderReload: true,
          };
        case SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER:
          return {
            ...state,
            selectedItem: NewSaleOrderCustomerItem,
            removeModalShow: false,
            isNeedOrderReload: true,
            isSending: false
          };
        case SaleOrderCustomerActionTypes.SEARCH_SALE_CUSTOMERS:
          return {
            ...state,
            searchCustomers: action.payload.data.items,
            isSearchCustomers: false
          }
        default:
          return { ...state };
      }

    case SaleOrderCustomerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleOrderCustomerActionTypes.UPDATE_SALE_ORDER_CUSTOMER:
          return {
            ...state,
            isSending: false
          };
        case SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER:
          return {
            ...state,
            removeModalShow: false
          };
        case SaleOrderCustomerActionTypes.SEARCH_SALE_CUSTOMERS:
          return {
            ...state,
            isSearchCustomers: false
          }
        default:
          return { ...state };
      }
    case SaleOrderCustomerActionTypes.EDIT_SALE_ORDER_CUSTOMER:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleOrderCustomerActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleOrderCustomerItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleOrderCustomerItem,
        removeModalShow: false
      }
    case SaleOrderCustomerActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleOrderCustomerActionTypes.UPDATE_SALE_ORDER_CUSTOMER:
    case SaleOrderCustomerActionTypes.REMOVE_SALE_ORDER_CUSTOMER:
      return {
        ...state,
        isSending: true
      };
    case SaleOrderCustomerActionTypes.CLEAR_NEED_ORDER_RELOAD:
      return {
        ...state,
        isNeedOrderReload: false
      }
    case SaleOrderCustomerActionTypes.SEARCH_SALE_CUSTOMERS:
      return {
        ...state,
        isSearchCustomers: true
      }
    default:
      return { ...state };
  }
};

export default SaleOrderCustomer;
