import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSaleOrders = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_ORDERS+"?"+query);
};

const getSaleOrderDetail = (data:any) => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_ORDER_DETAIL.replace(/:uuid/,data.uuid));
};

const removeSaleOrder = (data:any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_ORDER, data);
};

const updateSaleOrder = (data:any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_ORDER, data);
};
const cancelSaleOrder = (data:any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.CANCEL_SALE_ORDER, data);
};

const changeSaleOrderStatus = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.CHANGE_SALE_ORDER_STATUS, data);
};

const changeSaleOrderItems = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.CHANGE_SALE_ORDER_ITEM, data);
};

const removeGroupSaleOrder = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_ORDER_GROUP, data);
};

export {
    getSaleOrders,
    getSaleOrderDetail,
    removeSaleOrder,
    updateSaleOrder,
    cancelSaleOrder,
    changeSaleOrderStatus,
    changeSaleOrderItems,
    removeGroupSaleOrder
};
