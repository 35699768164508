import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";

// hooks
import { useProfile } from "../../../hooks/UserHooks";

// utils
import RepliedMessage from "./RepliedMessage";

//i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {
  MessengerMessageType
} from "../../../redux/Messenger/Chat/types";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {convertToMomentLocale, getMessengerUserData} from "../../../helpers/functions";
import {Attachments, Images} from "./Images";
import {handleCreateNewTaskByMessage} from "./MessageActions";
import {useRedux} from "../../../hooks";

interface MenuProps {
  onDelete: () => any;
  onReply: () => any;
  onTaskCreate: () => any;
  onForward: () => void;
  isFromMe: boolean;
  isCanDelete: boolean;
}

const Menu = ({ onDelete, onReply, onForward, isFromMe, onTaskCreate, isCanDelete }: MenuProps) => {

  const { t, i18n } = useTranslation(['common']);
  moment.locale(convertToMomentLocale(i18n.language));

  return (
    <UncontrolledDropdown className="align-self-start message-box-drop">
      <DropdownToggle className="btn btn-toggle" role="button" tag={"a"}>
        <i className="ri-more-2-fill"/>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="reply-message"
          to="#"
          onClick={onReply}
        >
          <i className="ri-reply-line me-2 text-muted align-bottom"/>
          {t('common:reply')}
        </DropdownItem>

        <DropdownItem
            className="reply-message"
            to="#"
            onClick={onTaskCreate}
        >
          <i className="ri-survey-line me-2 text-muted align-bottom"/>
          {t('task:addNewTask')}
        </DropdownItem>

        { isCanDelete ?
          isFromMe ?
        <DropdownItem
            onClick={onDelete}
        >
          <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"/>
          {t('common:delete')}
        </DropdownItem>
       : '' : ''}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const Typing = () => {
  return (
    <p className="mb-0">
      typing
      <span className="animate-typing">
        <span className="dot mx-1"/>
        <span className="dot me-1"/>
        <span className="dot"/>
      </span>
    </p>
  );
};

interface MessageProps {
  message: MessengerMessageType;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessengerMessageType | undefined) => void;
  isFromMe: boolean;
  onOpenForward: (message: MessengerMessageType) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
}

const Message = ({
  message,
  chatUserDetails,
  onDelete,
  onSetReplyData,
  isFromMe,
  onOpenForward,
  isChannel,
  onDeleteImage,
}: MessageProps) => {
  const { userProfile } = useProfile();
  const hasImages = message.images && message.images.items && message.images.items.length;
  const hasAttachments = message.files && message.files.items && message.files.items.length;
  const hasText = message.text;
  const isTyping = false;

  const { dispatch } = useRedux();

  const { t, i18n } = useTranslation(['common']);
  
  if (i18n.language !== 'en') {
    moment.locale(convertToMomentLocale(i18n.language));
  }

  let {fullName, avatar, handlerImage} = getMessengerUserData(message.userData);

  let messageDateText = '';

  if (moment(message.dateTime).isSame(moment(), 'day')){
      messageDateText = t('common:today')+' ';
  } else {
      messageDateText=moment(message.dateTime).format('llll');
  }

  const date = messageDateText;

  const isSent = message.isSend;
  const isReceived = message.isReceived;
  const isRead = message.isRead;
  const isError = message.isError ? message.isError : false;
  const isForwarded = message.isForwarded;

  const onDeleteMessage = () => {
    onDelete(message.uuid);
  };

  const onClickReply = () => {
    onSetReplyData(message);
  };
  const isRepliedMessage = message.replyTo;

  const onForwardMessage = () => {
    onOpenForward(message);
  };

  const onTaskCreate = () => {
    handleCreateNewTaskByMessage(message, dispatch);
  };

  const onDeleteImg = (imageId: number | string) => {
    onDeleteImage(message.uuid, imageId);
  };
  return (
    <li
      className={classnames(
        "chat-list",
        { right: isFromMe },
        { reply: isRepliedMessage }
      )}
    >
      <div className="conversation-list">
        <div className="chat-avatar">

          { handlerImage ? (
              <div className="handlerIcon">
                <img src={handlerImage} alt=""/>
              </div>
          ) : '' }

          <img src={isFromMe ? userProfile.avatar : avatar} alt="" />

        </div>

        <div className="user-chat-content">
          {hasImages && message.text && (
            <div className="ctext-wrap">
              <div className="ctext-wrap-content">
                <p className="mb-0 ctext-content">{message.text}</p>
              </div>
            </div>
          )}
          {isForwarded && (
            <span
              className={classnames(
                "me-1",
                "text-muted",
                "font-size-13",
                "mb-1",
                "d-block"
              )}
            >
              <i
                className={classnames(
                  "ri",
                  "ri-share-forward-line",
                  "align-middle",
                  "me-1"
                )}
              />
              {t('common:forwarded')}
            </span>
          )}

          <div className="ctext-wrap">
            {/* text message end */}

            {/* image message start */}
            {hasImages ? (
              <>
                <Images images={message.images!} onDeleteImg={onDeleteImg} isFromMe={isFromMe}/>
              </>
            ) : (
              <>
                <div className="ctext-wrap-content">
                  {isRepliedMessage && (
                    <RepliedMessage
                      fullName={fullName}
                      message={message}
                      isFromMe={isFromMe}
                    />
                  )}

                  {hasText && (
                    <p className="mb-0 ctext-content">{message.text}</p>
                  )}

                  {/* typing start */}
                  {isTyping && <Typing />}

                  {/* typing end */}
                  {/* files message start */}
                  {hasAttachments && (
                    <Attachments attachments={message.files} isFromMe={isFromMe}/>
                  )}
                  {/* files message end */}
                </div>
              </>
            )}
            <Menu
                onForward={onForwardMessage}
                onDelete={onDeleteMessage}
                onReply={onClickReply}
                onTaskCreate={onTaskCreate}
                isFromMe={isFromMe}
                isCanDelete = { message.isCanDelete }
            />
            {/* image message end */}
          </div>
          <div className="conversation-name">
            {isFromMe ? (
              <>
                <span
                  className={classnames(
                    "me-1",
                    { "text-success": isRead && (!isError) },
                    { "text-muted": ((isSent || isReceived) && !isRead) && (!isError) },
                    { "text-danger": isError }
                  )}
                >
                  <i
                    className={classnames(
                      "bx",
                      { "bx-check-double": (isRead || isReceived) && (!isError) },
                      { "bx-check": (isSent && !isError) },
                      { "bx-error-circle": isError }
                    )}
                  />
                </span>
                <small className={classnames("text-muted", "mb-0", "me-2")}>
                  {date}
                </small>
                {t('common:you')}
              </>
            ) : (
              <>
                {fullName}
                <small className={classnames("text-muted", "mb-0", "ms-2")}>
                  {date}
                </small>
              </>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default Message;
