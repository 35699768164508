import {MainRoleActionTypes, MainRolePayload} from "./types";

export const mainRoleApiResponseSuccess = (actionType: string, data: any) => ({
  type: MainRoleActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const mainRoleApiResponseError = (actionType: string, error: string) => ({
  type: MainRoleActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getMainRoles= () => ({
  type: MainRoleActionTypes.GET_MAIN_ROLES,
});