import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import { useRedux } from "../../../hooks";

import {
    getEventNotifications, getEventUserBadges, getSaleProducts, readEventNotifications,
} from "../../../redux/actions";

import TableList from "../../../components/TableList";
import EventNotificationColumns from "./tableColumns";
import {EntityActionsHandlersType} from "../../../redux/types";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {getHistoryHash} from "../../../helpers/functions";
import Loader from "../../../components/Loader";
import {EventNotificationType} from "../../../redux/Event/Notification/types";
import {MainMetaType} from "../../../redux/Main/common";

const NotificationIndex = () => {

    const { dispatch, useAppSelector } = useRedux();
    const { t } = useTranslation(['common']);

    const componentType = 'eventNotificationTable';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [messages, setMessages] = useState<EventNotificationType[]>([]);

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults ? historyHash.maxResults : perPage,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection
        }

        dispatch(getEventNotifications(mainMeta));
    }

    useEffect(() => {
        loadItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        eventNotification,
        isEventNotificationFetched,
        unreadCount
    } = useAppSelector(state => ({
        eventNotification: state.EventNotification,
        isEventNotificationFetched: state.EventNotification.isEventNotificationFetched,
        unreadCount: state.EventNotification.unreadCount
    }));

    useEffect(() => {
        if (eventNotification?.isNeedReload) {
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, eventNotification?.isNeedReload]);

    useEffect(() => {
        if (eventNotification?.items !== undefined && eventNotification?.items.length>0){
            setMessages([...eventNotification.items]);

            if (unreadCount>0){
                setTimeout(() => {
                    dispatch(readEventNotifications({code:'all'}));
                    setTimeout(() => {
                        dispatch(getEventUserBadges());
                    }, 1000);
                }, 1000);
            }
        } else {
            setMessages([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventNotification?.items]);

    const EntityActionsHandlers:EntityActionsHandlersType = {
    }

    const onTableRowClick = (item:EventNotificationType) => {}

    const columns = EventNotificationColumns(EntityActionsHandlers);

    return (
        <>
            <BreadCrumb title={t('menu:myNotifications')} pageTitle={t('menu:main')} />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader>
                                <h5 className="card-title mb-0">{t('notification:myNotifications')}</h5>
                            </CardHeader>
                            <CardBody>
                                {isEventNotificationFetched ?
                                    <TableList
                                        columns={columns}
                                        data={messages || []}
                                        onRowClick={onTableRowClick}
                                        key={componentType}
                                        historyHash = {historyHash}
                                        paginationTotalRows={eventNotification?.meta?.totalCount || 0}
                                        paginationServer={true}
                                    />
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </>
    );
};

export default NotificationIndex;


