import {
  AITextGenerateStateType,
  AITextGenerateActionTypes,
  AITextGenerateParamType,
  AITextGenerateLength,
  AITextGenerateType, AITextGenerateAction, AITextGenerateFormat, AITextGenerateResultType
} from "./types";

const aiTextGenerateParamCommon: AITextGenerateParamType = {
  generateCount: 1,
  maxLength: AITextGenerateLength.SMALL,
  enabled: true
}

const aiTextGenerateParamTitle: AITextGenerateParamType = {
  generateCount: 7,
  maxLength: AITextGenerateLength.SMALL,
  enabled: true
}

export const aiTextGenerateTranslateCommon: AITextGenerateType = {
  action: AITextGenerateAction.TRANSLATE_ACTION,
  generateParams: [aiTextGenerateParamCommon],
  textFormat: AITextGenerateFormat.FORMAT_TEXT,
  prompt: '',
  enabled: true
}

export const aiTextGenerateRewriteCommon: AITextGenerateType = {
  action: AITextGenerateAction.REWRITE_ACTION,
  generateParams: [aiTextGenerateParamCommon],
  textFormat: AITextGenerateFormat.FORMAT_TEXT,
  prompt: '',
  enabled: true
}

export const aiTextGenerateCreateCommon: AITextGenerateType = {
  action: AITextGenerateAction.GENERATE_ACTION,
  generateParams: [aiTextGenerateParamCommon],
  textFormat: AITextGenerateFormat.FORMAT_TEXT,
  prompt: '',
  enabled: true
}

export const aiTextGeneratePredictTagCommon: AITextGenerateType = {
  action: AITextGenerateAction.PREDICT_TAG_ACTION,
  generateParams: [aiTextGenerateParamCommon],
  textFormat: AITextGenerateFormat.FORMAT_TEXT,
  prompt: '',
  enabled: true
}

export const aiTextGenerateTitleCommon: AITextGenerateType = {
  action: AITextGenerateAction.GENERATE_TITLE_ACTION,
  generateParams: [aiTextGenerateParamTitle],
  textFormat: AITextGenerateFormat.FORMAT_TEXT,
  prompt: '',
  enabled: true
}

export const INIT_STATE_AI_TEXT_GENERATE_CONNECTOR: AITextGenerateStateType = {
  connectors: [],
  items:[],
  rightModalShow: false,
  modalEditTitle: '',
  isNeedReload: false,
  isSending: false,
  isConnectorsFetched: false,
  isConnectorsLoading: false,
  isInitialized: false,
  isOpenSettings: false
};

const AITextGenerateState = (state = INIT_STATE_AI_TEXT_GENERATE_CONNECTOR, action: any) => {
  switch (action.type) {
    case AITextGenerateActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {

        case AITextGenerateActionTypes.GET_ACTIVE_CONNECTORS:

          return {
            ...state,
            connectors: action.payload.data.items,
            isConnectorsFetched: true,
            isConnectorsLoading: false,
            isNeedReload: false
          };
        case AITextGenerateActionTypes.SEND_TEXT_GENERATE_REQUEST:

          if (action.payload.data.response && action.payload.data.response === 'wsock'){
            state.requestMetadata = action.payload.data.requestMetadata;

            return {
              ...state,
            };
          }

          return {
            ...state,
            isSending: false
          };

        case AITextGenerateActionTypes.RECEIVE_TEXT_GENERATE_RESPONSE:

          if (state?.requestMetadata?.uuid && state.requestMetadata.uuid === action.payload.data.requestMetadata.uuid){
            return {
              ...state,
              selectedItem: {...action.payload.data.item},
              isSending: false
            };
          }

          return {
            ...state,
          };
        case AITextGenerateActionTypes.GET_IDEAS_CHANNEL:

          return {
            ...state,
            ideasChannel: ((action.payload.data.items || []).length>0) && {...action.payload.data.items.shift()}
          }

        default:
          return { ...state };
      }

    case AITextGenerateActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {

        case AITextGenerateActionTypes.GET_ACTIVE_CONNECTORS:
          return {
            ...state,
            connectors: [],
            isConnectorsFetched: true,
            isConnectorsLoading: false,
            isNeedReload: false
          };
        case AITextGenerateActionTypes.SEND_TEXT_GENERATE_REQUEST:
          return {
            ...state,
            isSending: false
          };

        case AITextGenerateActionTypes.RECEIVE_TEXT_GENERATE_RESPONSE:

          return {
            ...state,
            isSending: false
          };

        case AITextGenerateActionTypes.GET_IDEAS_CHANNEL:
          return {
            ...state,
            ideasChannel: null
          }

        default:
          return { ...state };

      }
    case AITextGenerateActionTypes.GET_ACTIVE_CONNECTORS:
      return {
        ...state,
        isConnectorsFetched: false,
        isConnectorsLoading: true
      };
    case AITextGenerateActionTypes.CHANGE_SELECTED_ITEM_CONNECTOR:
      return {
        ...state,
        selectedItem : {
          ...state.selectedItem,
          connector: action.payload
        }
      };
    case AITextGenerateActionTypes.SEND_TEXT_GENERATE_REQUEST:
      return {
        ...state,
        isSending: true
      };
    case AITextGenerateActionTypes.RIGHT_MODAL_SHOW:

      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true,
        managedChannels: [],
        isOpenSettings: action.payload.data.action && (
            action.payload.data.action === AITextGenerateAction.GENERATE_TITLE_ACTION
            ||
            action.payload.data.action === AITextGenerateAction.GENERATE_ACTION
        )
      }
    case AITextGenerateActionTypes.REMOVE_SELECTED_ITEM_RESULT_VARIANT:

      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          generateParams: (state.selectedItem.generateParams || []).map((generateParam: AITextGenerateParamType)=>{
            return {
              ...generateParam,
              resultItems : (generateParam.resultItems || []).filter((resultItem: AITextGenerateResultType)=>{
                return resultItem.uuid !== action.payload.uuid
              })
            };
          })
        }
      }
    case AITextGenerateActionTypes.EDIT_SELECTED_ITEM_RESULT_VARIANT:

      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          generateParams: (state.selectedItem.generateParams || []).map((generateParam: AITextGenerateParamType)=>{
            return {
              ...generateParam,
              resultItems : (generateParam.resultItems || []).filter((resultItem: AITextGenerateResultType)=>{
                if (resultItem.uuid === action.payload.uuid){
                  resultItem.text =  action.payload.text;
                }
                return resultItem
              })
            };
          })
        }
      }
    case AITextGenerateActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false,
        isOpenSettings: false
      }
    case AITextGenerateActionTypes.GET_IDEAS_CHANNEL:
      return {
        ...state,
        ideasChannel: null
      }
    case AITextGenerateActionTypes.SET_INITIALIZED:
      return {
        ...state,
        isInitialized: true,
      }
    default:
      return { ...state };
  }
};

export default AITextGenerateState;
