import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Row, Label, Col} from 'reactstrap';
import LeftbarTitle from "../../../components/LeftbarTitle";
import {useRedux} from "../../../hooks";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {nameToEmoji} from "gemoji";
import {
    Formik,
    Form,
    Field
} from 'formik';
import InputEmojiArea from "../../../components/InputEmojiArea";
import {NewRewardItem, RewardType} from "../../../data/reward";
import {RewardItemValidatorSchema} from "./validator";

interface ItemProps {
    onSend: (data: any, dispatch: any) => void;
    handleClose: (dispatch: any) => void;
    rewardItem: any;
    isOpen: boolean;
    modalTitle: string;
}

const RewardForm = ({isOpen,handleClose, onSend, modalTitle, rewardItem}:ItemProps) => {

    const { t, i18n } = useTranslation();
    const { dispatch, useAppSelector } = useRedux();

    const [isModalShow, setModalShow] = useState<boolean>(isOpen);
    const [inProcess, setInProcess] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<RewardType>(NewRewardItem);
    const [currentScore, setCurrentScore] = useState<number>(NewRewardItem.score);

    useEffect(() => {
        setModalShow(isOpen);
        setInProcess(false);
    }, [isOpen]);

    const handleScoreClick = (score:number) => {
        setCurrentScore(score);
    }

    const onDataSubmit = (formData:RewardType) => {

        formData.score = currentScore;
        formData.uuid = rewardItem.uuid;

        setInProcess(true);

        onSend(formData, dispatch);
    };

    return (
        <>
            <Modal show={isModalShow} onHide={()=>handleClose(dispatch)}>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={RewardItemValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                        <Form noValidate={true} className="customValidation">
                        <Modal.Header closeButton>
                            <LeftbarTitle title={modalTitle}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <div className="d-flex flex-column">
                                                <div className="p-1">
                                                    <Row>
                                                        <div className="d-flex flex-row justify-content-center mb-4">
                                                            <div
                                                                className={"ratingSmile me-3"+(currentScore===1 ? ' active':'')}
                                                                onClick={()=>{handleScoreClick(1)}}>{nameToEmoji.neutral_face}</div>
                                                            <div
                                                                className={"ratingSmile me-3"+(currentScore===3 ? ' active':'')}
                                                                onClick={()=>{handleScoreClick(3)}}>{nameToEmoji.blush}</div>
                                                            <div
                                                                className={"ratingSmile"+(currentScore===5 ? ' active':'')}
                                                                onClick={()=>{handleScoreClick(5)}}>{nameToEmoji.heart_eyes}</div>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className={(touched.scoreComment ? " was-validated" : '')}>
                                                            <Label className="form-label" htmlFor="form-text-input">{t('support:rewardDescription')}</Label>
                                                            <div className="textAreaEmojiBlock">
                                                                <Field
                                                                    id="form-text-input"
                                                                    name="scoreComment"
                                                                    component={InputEmojiArea}
                                                                    value = {getFieldProps('scoreComment').value}
                                                                    onChange={(text:string) => {
                                                                        setFieldValue('scoreComment', text);
                                                                    }}
                                                                    required={false}
                                                                    placeholder={t('support:textHere')}
                                                                    className={errors.scoreComment && touched.scoreComment && 'form-control is-invalid'}
                                                                />
                                                                {errors.scoreComment && touched.scoreComment ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.scoreComment)}
                                                                    </div>
                                                                ) :  touched.scoreComment && !errors.scoreComment ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                </div>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={()=>handleClose(dispatch)}>
                                {t('common:cancel')}
                            </Button>
                            <button type="submit"
                                    className="btn btn-success btn-label waves-effect waves-light">
                                {inProcess ?
                                    <ButtonSpinner className="ms-0"/>
                                    :
                                    <i
                                        className="ri-check-double-line label-icon align-middle fs-16 me-2"/>
                                }
                                {t('support:closeTicket')}
                            </button>
                        </Modal.Footer>
                    </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
}

export default RewardForm;