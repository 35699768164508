import {SaleDeliverySystemActionTypes, SaleDeliverySystemPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleDeliverySystemApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleDeliverySystemActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleDeliverySystemApiResponseError = (actionType: string, error: string) => ({
  type: SaleDeliverySystemActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleDeliverySystems = (data: MainMetaType) => ({
  type: SaleDeliverySystemActionTypes.GET_SALE_DELIVERY_SYSTEMS,
  payload: data,
});

export const showRightModalSaleDeliverySystem= (data: SaleDeliverySystemPayload) => ({
  type: SaleDeliverySystemActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleDeliverySystem= () => ({
  type: SaleDeliverySystemActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleDeliverySystem = (data: any) => ({
  type: SaleDeliverySystemActionTypes.UPDATE_SALE_DELIVERY_SYSTEM,
  payload: data,
});

export const editDataSaleDeliverySystem = (data: SaleDeliverySystemPayload) => ({
  type: SaleDeliverySystemActionTypes.EDIT_SALE_DELIVERY_SYSTEM,
  payload: data,
});

export const removeSaleDeliverySystemModalShow = (data: SaleDeliverySystemPayload) => ({
  type: SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM_MODAL_SHOW,
  payload: data,
});

export const removeSaleDeliverySystemModalHide = () => ({
  type: SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM_MODAL_HIDE,
});

export const onRemoveSaleDeliverySystem = (data: SaleDeliverySystemPayload) => ({
  type: SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM,
  payload: data,
});