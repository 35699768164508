import React from 'react';
import * as Yup from 'yup';

export const SaleProductValidatorSchema = Yup.lazy((values) => {
    const shapes = {};

    Object.keys(values).map((item)=>{
        if (item.match(/price_(\S+)$/)) {
            shapes[item]=(Yup.number());
        }
        return item;
    })

    Object.keys(values).map((item)=>{
        if (item.match(/store_(\S+)$/)) {
            shapes[item]=(Yup.number());
        }
        return item;
    })

    shapes['name']=Yup.string()
        .min(2, 'tooShort')
        .max(200, 'tooLong')
        .required('required');
    shapes['order']=Yup.string()
        .matches(/^[0-9]+$/, "digitsOnly")
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required');

    shapes['width']=Yup.number().nullable(true);
    shapes['height']=Yup.number().nullable(true);
    shapes['depth']=Yup.number().nullable(true);
    shapes['weight']=Yup.number().nullable(true);

    return Yup.object().shape(shapes);
});

export const SaleProductIntegrationValidatorSchema = Yup.lazy((values) => {
    const shapes = {};

//    shapes['store']=Yup.object().required();
    shapes['currency']=Yup.object().required();

    Object.keys(values).map((item)=>{
        if (item.match(/productIntegrationParam/)) {
            shapes[item]=Yup.object().required();
        }
        return item;
    })

    return Yup.object().shape(shapes);
});
