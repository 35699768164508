import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { get, map } from "lodash";
import languages from "../constants/Languages";

//i18n
import '../i18/config';
import {useTranslation} from "react-i18next";
import {getCurrentCabinet, getCurrentLanguage, setCurrentLanguage} from "../helpers/functions";
import {useRedux} from "../hooks";
import {selectCurrentUserCabinet} from "../redux/Main/Cabinet/actions";
import {useProfile} from "../hooks/UserHooks";
import {onSendChangeUserLanguage} from "../redux/Main/User/actions";

const LangSwitch = () => {

    const { t, i18n } = useTranslation(['auth']);

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const { dispatch, useAppSelector } = useRedux();

    const { loginState } = useAppSelector(state => ({
        loginState: state.Login
    }));

    const { userProfile } = useProfile();

    const [selectedLang, setSelectedLang] = useState('en');

    useEffect(() => {

        if (loginState.isUserLogin === true){

            setIsLoggedIn(true);
            setCurrentLanguage(userProfile.language || 'en');
            setSelectedLang(userProfile.language || 'en');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginState?.isUserLogin]);

    useEffect(() => {
        const currentLanguage = getCurrentLanguage();

        setSelectedLang(currentLanguage || 'en');
        i18n.changeLanguage(currentLanguage || 'en');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeLanguageAction = lang => {

        setCurrentLanguage(lang || 'en');
        setSelectedLang(lang || 'en');
        i18n.changeLanguage(lang || 'en');
        if (isLoggedIn) {
            dispatch(onSendChangeUserLanguage({'language':lang || 'en'}))
        }
    };

    const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
    const toggleLanguageDropdown = () => {
        setIsLanguageDropdown(!isLanguageDropdown);
    };

    return (
        <>
            <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                    <img
                        src={get(languages, `${selectedLang}.flag`)}
                        alt="Language"
                        height="20"
                        className="rounded"
                    />
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                    {map(Object.keys(languages), key => (
                        <DropdownItem
                            key={key}
                            onClick={() => changeLanguageAction(key)}
                            className={`notify-item ${selectedLang === key ? "active" : "none"
                            }`}
                        >
                            <img
                                src={get(languages, `${key}.flag`)}
                                alt="Language"
                                className="me-2 rounded"
                                height="18"
                            />
                            <span className="align-middle">
                                {get(languages, `${key}.label`)}
                            </span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default LangSwitch;