import {EntityActionsType} from "../../types";
import {CurrencyType} from "../Currency/types";
import {MainMetaType} from "../../Main/common";

export enum SaleCurrencyRateActionTypes {
    API_RESPONSE_SUCCESS = "@@saleCurrencyRate/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleCurrencyRate/API_RESPONSE_ERROR",
    GET_SALE_CURRENCY_RATES = "@@saleCurrencyRate/GET_SALE_CURRENCY_RATES",
    UPDATE_SALE_CURRENCY_RATE = "@@saleCurrencyRate/UPDATE_SALE_CURRENCY_RATE",
    REMOVE_SALE_CURRENCY_RATE_MODAL_SHOW = "@@saleCurrencyRate/REMOVE_SALE_CURRENCY_RATE_MODAL_SHOW",
    REMOVE_SALE_CURRENCY_RATE_MODAL_HIDE = "@@saleCurrencyRate/REMOVE_SALE_CURRENCY_RATE_MODAL_HIDE",
    REMOVE_SALE_CURRENCY_RATE = "@@saleCurrencyRate/REMOVE_SALE_CURRENCY_RATE",
    EDIT_SALE_CURRENCY_RATE = "@@saleCurrencyRate/EDIT_SALE_CURRENCY_RATE",
    RIGHT_MODAL_SHOW = "@@saleCurrencyRate/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleCurrencyRate/RIGHT_MODAL_HIDE",
}

export interface SaleCurrencyRateState extends MainMetaType{
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: CurrencyRateType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface CurrencyRateType {
    uuid?: string;
    baseRate?: number;
    rate?: number;
    enabled?: boolean;
    order?: number;
    currencyFirst? : CurrencyType;
    currencySecond? : CurrencyType;
    actions?: EntityActionsType;
    dateTime?: string;
}


export interface SaleCurrencyRatePayload {
    data: Array<any>;
    modalEditTitle : string;
}
