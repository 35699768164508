import React, {useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as route from "../../api/routes";
import {PeerActivityType} from "../../redux/Content/PeerActivity/types";
import TableList from "../TableList";
import TableActivitiesColumns from "./TableActivitiesColumns";
import '../../i18/config';
import {useTranslation} from "react-i18next";

interface TableActivitiesProps {
    items: [] | Array<PeerActivityType>;
}

const TableActivities = ({ items }: TableActivitiesProps) => {
    const { t } = useTranslation();

    const handleClick = (peerActivity:PeerActivityType) => {
        window.location.href=route.STAT_CONTENT_ACTIVITY.replace(/:uuidActivity/,peerActivity.uuid);
    }


    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('peerActivity:topActivities')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('peerActivity:manageActivities')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <TableList
                    columns={TableActivitiesColumns()}
                    data={items}
                    key={'contentActivitiesTable_'+Math.round(Math.random() * (99999 - 11111) + 11111)}
                    defaultSortFieldId={'views'}
                    onRowClick={handleClick}
                />

            </CardBody>
        </Card>
    );
}

export default TableActivities;