import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TableList from "../TableList";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {OrderStatusTypes} from "../../redux/Sale/OrderStatus/types";
import {StatOrderTileType} from "../../redux/Stat/DashboardSale/types";
import * as route from "../../api/routes";
import {CurrencyType} from "../../redux/Sale/Currency/types";
import {CustomerType} from "../../redux/Sale/Customer/types";
import SaleTableCustomersColumns from "./SaleTableCustomersColumns";
import {capitalizeFirstLetter} from "../../helpers/functions";

interface TableActivitiesProps {
    items: [] | Array<StatOrderTileType>;
    entities: Array<CustomerType>;
    currency: CurrencyType;
}

const SaleTableCustomers = ({ items, entities, currency }: TableActivitiesProps) => {

    const { t } = useTranslation();
    const [shownStatus, setShownStatus] = useState<string>(OrderStatusTypes.ALL);
    const [shownItems, setShownItems] = useState<StatOrderTileType[]>([]);





    useEffect(()=>{

        const _shownItems=(items || []).filter((item:StatOrderTileType)=>{return item.type===shownStatus});

        (_shownItems || []).map((item:StatOrderTileType)=>{

            item.entity = (entities || []).filter((entityItem:CustomerType)=>{return item.label === entityItem.uuid }).shift();

            return item;
        })

        setShownItems([..._shownItems]);

    },[shownStatus, items, entities]);

    const handleClickShownStatus = (status:string) => {
        setShownStatus(status);
    }

    const handleClick = (item:StatOrderTileType) => {
        if (item?.entity?.uuid) {
            window.location.href = route.SALE_CUSTOMERS_LIST.replace(/:uuid/, item.entity.uuid);
        }
    }

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('stat:topCustomers')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted">{t('orderStatus:orderStatusType'+capitalizeFirstLetter(shownStatus))}<i className="mdi mdi-chevron-down ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {(Object.keys(OrderStatusTypes).map((item)=>{
                            return(
                                <DropdownItem onClick={()=>{handleClickShownStatus(OrderStatusTypes[item])}} key={"ordersStatusCountriesFilter_"+OrderStatusTypes[item]}>{t('orderStatus:orderStatusType'+capitalizeFirstLetter(OrderStatusTypes[item]))}</DropdownItem>
                            )
                        }))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <TableList
                    columns={SaleTableCustomersColumns({currency: currency})}
                    data={shownItems}
                    key={'saleBestProducts_'+Math.round(Math.random() * (99999 - 11111) + 11111)}
                    defaultSortFieldId={'amount'}
                    onRowClick={handleClick}
                />

            </CardBody>
        </Card>
    );
}

export default SaleTableCustomers;