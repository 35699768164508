import {StatPeerChannelPayloadType} from "../PeerChannel/types";
import {PeriodType, StatChannelTilesPayloadType} from "../DashboardContent/types";
import {
    ComparePeerChannelActionTypes,
    ComparePeerChannelPayloadType,
    ComparePeerChannelRequestPayloadType
} from "./types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";

export const comparePeerChannelApiResponseSuccess = (actionType: string, data: any) => ({
    type: ComparePeerChannelActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const comparePeerChannelApiResponseError = (actionType: string, error: string) => ({
    type: ComparePeerChannelActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getComparePeerChannelTiles= (data: StatChannelTilesPayloadType) => ({
    type: ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TILES,
    payload: data,
});

export const getComparePeerChannelActivitiesData= (data: StatPeerChannelPayloadType) => ({
    type: ComparePeerChannelActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA,
    payload: data,
});

export const getComparePeerChannelTargets= (data: StatPeerChannelPayloadType) => ({
    type: ComparePeerChannelActionTypes.GET_STAT_CHANNEL_TARGETS,
    payload: data,
});

export const setComparePeerChannelPeriod= (data: ComparePeerChannelPayloadType) => ({
    type: ComparePeerChannelActionTypes.SET_PERIOD,
    payload: data,
});

export const setComparePeerChannel= (data: ComparePeerChannelPayloadType) => ({
    type: ComparePeerChannelActionTypes.SET_CHANNEL,
    payload: data,
});

export const removeComparePeerChannel= (index: number) => ({
    type: ComparePeerChannelActionTypes.REMOVE_CHANNEL,
    payload: index,
});

export const setComparePeerChannelRequestUuid= (data: ComparePeerChannelRequestPayloadType) => ({
    type: ComparePeerChannelActionTypes.SET_REQUEST_UUID,
    payload: data,
});

export const addToComparePeerChannel= (data: ContentPeerChannelType) => (
    {
        type: ComparePeerChannelActionTypes.ADD_TO_COMPARE,
        payload: data,
    }
);

export const updateComparePeerChannel= (data: ContentPeerChannelType[]) => (
    {
        type: ComparePeerChannelActionTypes.UPDATE_COMPARE_CHANNELS,
        payload: data,
    }
);