import React from "react";

import {ServiceMessageType, ServiceMessageTypes} from "../../../redux/Content/PeerActivity/types";
import {useTranslation} from "react-i18next";

interface ItemProps {
    item: ServiceMessageType;
}

const ServiceMessageItem = ({ item }: ItemProps) => {
    const { t } = useTranslation(['common']);

    return (
     <>
         {item.type === ServiceMessageTypes.ERROR &&
         <div className="alert alert-danger alert-dismissible alert-label-icon label-arrow fade show" role="alert">
             <i className="ri-error-warning-line label-icon"></i><strong>{t('common:attention')}!</strong> {item.message}
         </div>
         }
     </>
    );
};

export default ServiceMessageItem;
