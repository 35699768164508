import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { StatPeerActivityContentActionTypes } from "./types";
import { statPeerActivityApiResponseSuccess, statPeerActivityApiResponseError} from "./actions";

import {
  getPeerActivities as getPeerActivitiesApi,
  getStatPeerActivityTiles as getStatPeerActivityTilesApi,
  getStatPeerActivityActivities as getStatPeerActivityActivitiesApi,
  getStatPeerActivityEngagements as getStatPeerActivityEngagementsApi,
  getPeerActivityTargets as getPeerActivityTargetsApi,
  getStatChannelsActivities as getStatChannelsActivitiesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getStatPeerActivityContentTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatPeerActivityTilesApi, data);
    yield put(
        statPeerActivityApiResponseSuccess(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TILES, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerActivityApiResponseError(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TILES, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerActivityContentActivitiesData({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatPeerActivityActivitiesApi, data);
    yield put(
        statPeerActivityApiResponseSuccess(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ACTIVITIES_DATA, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerActivityApiResponseError(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ACTIVITIES_DATA, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerActivityContentEngagementsData({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatPeerActivityEngagementsApi, data);
    yield put(
        statPeerActivityApiResponseSuccess(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ENGAGEMENTS_DATA, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerActivityApiResponseError(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ENGAGEMENTS_DATA, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerActivityContentTargets({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivityTargetsApi, data);
    yield put(
        statPeerActivityApiResponseSuccess(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TARGETS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerActivityApiResponseError(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TARGETS, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerActivityActivity({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivitiesApi, data);
    yield put(
        statPeerActivityApiResponseSuccess(StatPeerActivityContentActionTypes.GET_ACTIVITY, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerActivityApiResponseError(StatPeerActivityContentActionTypes.GET_ACTIVITY, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetStatPeerActivityContentTiles() {
  yield takeEvery(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TILES, getStatPeerActivityContentTiles);
}

export function* watchGetStatPeerActivityContentChannelsActivitiesData() {
  yield takeEvery(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ACTIVITIES_DATA, getStatPeerActivityContentActivitiesData);
}

export function* watchGetStatPeerActivityContentChannelsEngagementsData() {
  yield takeEvery(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_ENGAGEMENTS_DATA, getStatPeerActivityContentEngagementsData);
}

export function* watchGetStatPeerActivityContentTargets() {
  yield takeEvery(StatPeerActivityContentActionTypes.GET_STAT_ACTIVITY_TARGETS, getStatPeerActivityContentTargets);
}

export function* watchGetStatPeerActivityActivity() {
  yield takeEvery(StatPeerActivityContentActionTypes.GET_ACTIVITY, getStatPeerActivityActivity);
}

function* statPeerActivityContentSaga() {
  yield all([
    fork(watchGetStatPeerActivityContentTiles),
    fork(watchGetStatPeerActivityContentTargets),
    fork(watchGetStatPeerActivityContentChannelsEngagementsData),
    fork(watchGetStatPeerActivityContentChannelsActivitiesData),
    fork(watchGetStatPeerActivityActivity)
  ]);
}

export default statPeerActivityContentSaga;
