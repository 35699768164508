import React, {useEffect, useState} from "react";
import {useParams, Link} from 'react-router-dom'
import {Col, Row} from 'reactstrap';
import UpgradeFareNotice from "../../../../components/UpgradeFareNotice";
import {useRedux} from "../../../../hooks";
import {getContentProjects} from "../../../../redux/Content/Project/actions";
import {ContentProjectType} from "../../../../redux/Content/Project/types";
import {
    getDashboardContentActivities,
    getDashboardContentChannels,
    getDashboardContentChannelsActivitiesData,
    getDashboardContentChannelTiles,
    getDashboardContentCountersData,
    getDashboardContentCounterTiles,
    getDashboardContentTargets,
    getDashboardContentTopUsers,
    setDashboardContentPeriod
} from "../../../../redux/Stat/DashboardContent/actions";
import {
    PeriodDurationType,
    PeriodType,
    StatPeriodPayloadType, StatProjectActivitiesPayloadType,
    StatProjectActivitiesType,
    StatProjectChannelsDataType,
    StatProjectCountersDataType,
    StatProjectCounterTilesPayloadType,
    StatProjectCounterTilesType,
    StatProjectTilesPayloadType,
    StatProjectTilesType,
    StatProjectTopUsersPayloadType
} from "../../../../redux/Stat/DashboardContent/types";
import StatTile from "../../../../components/StatTile";
import {
    emptyChannelsTiles,
    emptyProjectChannelsTiles, emptyProjectsChannelsTiles,
    StatPeerChannelTileType
} from "../../../../redux/Stat/PeerChannel/types";
import SchedulesCalendar from "../../../../components/Dashboard/SchedulesCalendar";
import {getPeerActivityTypes} from "../../../../redux/Content/PeerActivityType/actions";
import {getPeerActivityModes} from "../../../../redux/Content/PeerActivityMode/actions";
import SchedulesModes from "../../../../components/Dashboard/SchedulesModes";
import SchedulesTypes from "../../../../components/Dashboard/SchedulesTypes";
import {getContentPeerChannels} from "../../../../redux/Content/PeerChannel/actions";
import TableActivities from "../../../../components/Dashboard/TableActivities";
import TablePeerChannel from "../../../../components/Dashboard/TablePeerChannel";
import {ContentPeerChannelType} from "../../../../redux/Content/PeerChannel/types";
import {emptyProjectsCountersTiles, StatStatCounterTileType} from "../../../../redux/Stat/StatCounter/types";
import HeatmapWeeklyActivities from "../../../../components/Dashboard/HeatmapWeeklyActivities";
import CountersChart from "../../../../components/Dashboard/CountersChart";
import ChannelActivitiesChart from "../../../../components/Dashboard/ChannelActivitiesChart";
import PeerTargetTableSmall from "../../../../components/Dashboard/PeerTargetTableSmall";
import * as route from "../../../../api/routes";

import '../../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../../components/Common/BreadCrumb";
import {SalePlanParamValueType, SalePlanType} from "../../../../redux/Sale/Plan/types";
import Loader from "../../../../components/Loader";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import PeriodSelect from "../../../../components/PeriodSelect";
import WizardNotice from "../../../../components/WizardNotice";
import {LicenseProductParam} from "../../../../redux/Sale/License/types";
import {isNumeric} from "../../../../helpers/functions";
import {connectorHandlerCode} from "../../../../constants/ConnectorHandler";
import {MenuItem} from "../../../../layouts/LayoutMenuData";
import {changeDefaultDashboard, saveSettingsValues} from "../../../../redux/Main/Layout/actions";
import {useProfile} from "../../../../hooks/UserHooks";

const ContentDashboardIndex = (props) => {
    const { t } = useTranslation();
    const { uuid } = useParams();

    const { dispatch, useAppSelector } = useRedux();
    const [projectActivities, setProjectActivities] = useState<StatProjectActivitiesType[]>([]);
    const [projectScheduleActivities, setProjectScheduleActivities] = useState<StatProjectActivitiesType[]>([]);
    const [currentProject, setCurrentProject] = useState<ContentProjectType>(null);

    const [currentTiles, setCurrentTiles] = useState<StatProjectTilesType[]>(emptyProjectsChannelsTiles);
    const [currentCounterTiles, setCurrentCounterTiles] = useState<StatProjectCounterTilesType[]>(emptyProjectsCountersTiles);
    const [countersData, setCountersData] = useState<StatProjectCountersDataType[]>([]);
    const [activitiesData, setActivitiesData] = useState<StatProjectChannelsDataType[]>([]);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ isTargetLoaded, setTargetLoaded ] = useState<boolean>(false);
    const [ isTargetRestriction, setTargetRestriction ] = useState<boolean>(false);
    const [ periodType, setPeriodType ] = useState<PeriodDurationType>(PeriodDurationType.WEEK);
    const { userProfile } = useProfile();

    const {
        dashboardContent,
        contentProjects,
        contentPeerActivityModes,
        peerActivityTypes,
        contentPeerChannels,
        salePlan,
        isDashboardContentFetched,
        isDashboardContentTargetFetched,
        periodFrom,
        periodTo,
        defaultDashboard
    } = useAppSelector(state => ({
        dashboardContent: state.DashboardContent,
        contentProjects: state.ContentProjects,
        contentPeerActivityModes: state.PeerActivityMode,
        peerActivityTypes: state.PeerActivityType,
        contentPeerChannels: state.ContentPeerChannel,
        salePlan: state.SalePlan,
        isDashboardContentFetched: state.DashboardContent.isDashboardContentFetched,
        isDashboardContentTargetFetched: state.DashboardContent.isDashboardContentTargetFetched,
        periodFrom: state.DashboardContent.periodFrom,
        periodTo: state.DashboardContent.periodTo,
        defaultDashboard: state.Layout.defaultDashboard
    }));

    useEffect(() => {

        dispatch(getPeerActivityTypes({maxResults:1000}));
        dispatch(getPeerActivityModes());
        dispatch(getContentProjects());
        dispatch(getContentPeerChannels());
        dispatch(getDashboardContentChannels());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLoaded(isDashboardContentFetched);
    },[isDashboardContentFetched]);

    useEffect(() => {
        setTargetLoaded(isDashboardContentTargetFetched);
    },[isDashboardContentTargetFetched]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {

        setProjectActivities([...dashboardContent.activities]);

    }, [dashboardContent?.activities]);

    useEffect(() => {

        setProjectScheduleActivities([...dashboardContent.scheduleActivities]);

    }, [dashboardContent?.scheduleActivities]);

    useEffect(() => {
        if (dashboardContent?.tiles && (dashboardContent.tiles || []).length>0) {
            setCurrentTiles([...dashboardContent.tiles]);
        } else {
            setCurrentTiles([...emptyProjectsChannelsTiles]);
        }
    }, [dashboardContent?.tiles]);

    useEffect(() => {

        if (dashboardContent?.counterTiles && (dashboardContent.counterTiles || []).length>0) {
            setCurrentCounterTiles([...dashboardContent.counterTiles]);
        } else {
            setCurrentCounterTiles([...emptyProjectsCountersTiles]);
        }
    }, [dashboardContent?.counterTiles]);

    useEffect(() => {
        setCountersData([...dashboardContent.countersData]);
    }, [dashboardContent?.countersData]);

    useEffect(() => {
        setActivitiesData([...dashboardContent.channelsActivitiesData]);
    }, [dashboardContent?.channelsActivitiesData]);

    useEffect(() => {

        const statPeriodPayload:StatPeriodPayloadType = {
            periodFrom : dashboardContent.periodFrom,
            periodTo: dashboardContent.periodTo
        }

        dispatch(getDashboardContentTargets(statPeriodPayload));

        if (currentProject && currentProject.uuid){

                const statProjectTilesPayload:StatProjectTilesPayloadType = {
                    project : currentProject,
                    periodFrom : dashboardContent.periodFrom,
                    periodTo: dashboardContent.periodTo
                }

                dispatch(getDashboardContentChannelTiles(statProjectTilesPayload));

                const statProjectCounterTilesPayload:StatProjectCounterTilesPayloadType = {
                    project : currentProject,
                    periodFrom : dashboardContent.periodFrom,
                    periodTo: dashboardContent.periodTo
                }

                dispatch(getDashboardContentCounterTiles(statProjectCounterTilesPayload));
                dispatch(getDashboardContentCountersData(statProjectCounterTilesPayload));
                dispatch(getDashboardContentChannelsActivitiesData(statProjectCounterTilesPayload));

                const statProjectTopUsersPayload:StatProjectTopUsersPayloadType = {
                    project : currentProject,
                    periodFrom : dashboardContent.periodFrom,
                    periodTo: dashboardContent.periodTo
                }

                dispatch(getDashboardContentTopUsers(statProjectTopUsersPayload));

                const statProjectActivitiesPayload:StatProjectActivitiesPayloadType = {
                    project : currentProject,
                    periodFrom : dashboardContent.periodFrom,
                    periodTo: dashboardContent.periodTo
                }

                dispatch(getDashboardContentActivities(statProjectActivitiesPayload));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentProject?.uuid, dashboardContent?.periodFrom, dashboardContent?.periodTo])

    useEffect(() => {

        if (uuid && uuid.length>0){
            (contentProjects.items || []).map((project:ContentProjectType)=>{
                if (project.uuid === uuid)  {
                    setCurrentProject(project);
                }
                return project;
            });
        } else if ((contentProjects.items || []).length>0) {
            setCurrentProject(contentProjects.items.shift());
        } else {
            // no projects
        }

    }, [uuid, contentProjects?.items]);

    const handleLastWeekPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'weeks').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setDashboardContentPeriod(periodData));
        setPeriodType(PeriodDurationType.WEEK);
    }

    const handleLastMonthPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'months').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setDashboardContentPeriod(periodData));
        setPeriodType(PeriodDurationType.MONTH);
    }

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.CONTENT_MAX_TARGETS
            }
        )).pop();

        if (restrictionValue === undefined){
            setTargetRestriction(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setTargetRestriction(false);
                } else {
                    setTargetRestriction(true);
                }
            } else if (restrictionValue.value === "Y") {
                setTargetRestriction(false);
            } else {
                setTargetRestriction(true);
            }
        }
    }

    return (
        <>
            <BreadCrumb title={t('menu:dashboard')} pageTitle={t('menu:main')} />
                <Row className="mb-3 pb-1">
                    <Col xs={12}>
                        <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                            <div className="flex-grow-1">
                                <h4 className="fs-16 mb-1">{t('common:welcome')}, {userProfile.fullName}!</h4>
                                <p className="text-muted mb-0">{t('common:welcomeDashboardText')}</p>
                            </div>

                            {!isLoaded &&
                                <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center" >
                                    <div className="d-flex justify-content-center align-items-center calendarLoader">
                                        <Loader addClassNames=""/>
                                    </div>
                                </div>
                            }

                            <div className="mt-3 mt-lg-0">
                                <Row className="g-3 mb-0 align-items-center">
                                    <div className="col-sm-auto">
                                        {periodType === PeriodDurationType.DATE_RANGE ?
                                            <>
                                                <PeriodSelect
                                                    onDispatch={setDashboardContentPeriod}
                                                    periodFrom={periodFrom}
                                                    periodTo={periodTo}/>
                                            </>
                                            :
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className={"btn "+(periodType===PeriodDurationType.WEEK?'btn-primary':'btn-soft-primary') }onClick={handleLastWeekPeriod}>{t('common:week')}</button>
                                            <button type="button" className={"btn "+(periodType===PeriodDurationType.MONTH?'btn-primary':'btn-soft-primary')} onClick={handleLastMonthPeriod}>{t('common:month')}</button>
                                            <button type="button" className="btn btn-soft-primary" onClick={()=>{setPeriodType(PeriodDurationType.DATE_RANGE)}}>
                                                <i className="ri-calendar-2-line align-bottom"/>
                                            </button>
                                            <button type="button" className={"btn "+(defaultDashboard===MenuItem.DASHBOARD_CONTENT_MARKETING?'btn-primary':'btn-soft-primary')}
                                                    onClick={()=>{
                                                        if (defaultDashboard!==MenuItem.DASHBOARD_CONTENT_MARKETING) {
                                                            dispatch(changeDefaultDashboard(MenuItem.DASHBOARD_CONTENT_MARKETING));
                                                            dispatch(saveSettingsValues());
                                                        }
                                                    }}
                                            >
                                                <i className="ri-star-line align-bottom"/>
                                            </button>
                                        </div>
                                        }
                                    </div>

{/*                                    <div className="col-sm-auto">*/}
{/*<PeriodSelect onDispatch={setDashboardContentPeriod}/>*/}
{/*                                    </div>*/}
                                    <div className="col-auto">
                                        <a href={route.CONTENT_ACTIVITY_PLAN} className="btn btn-primary"><i className="ri-pages-line align-bottom me-1"/> {t('peerActivity:activityPlan')}</a>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                {currentProject &&
                    <>
                    <Row>
                        <h5>{t('project:project')}: {currentProject.name}</h5>
                    </Row>
                        <Row>
                            {isLoaded &&
                                (((contentPeerChannels.items || []).length<1) || (contentPeerChannels.items.filter((channelFilterItem:ContentPeerChannelType)=>{return channelFilterItem?.connector?.handler?.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS}).length<1)) &&
                            <Col xxl={6}>
<WizardNotice/>
                            </Col>
                            }
                            {currentPlan && currentPlan.code === 'licStart202201' &&
                            <Col xxl={6}>
                                <UpgradeFareNotice text={'haveALotCoolFeatures'} />
                            </Col>
                            }
                        </Row>
                        <Row>
                            <Col xl={8}>
                                <Row>
                        {(currentTiles || []).map((item: StatProjectTilesType, key:number)=>(
                            (item.project === undefined || (item.project !== undefined && currentProject.uuid === item.project.uuid)) &&
                        (item.items || []).map((tile:StatPeerChannelTileType, tileKey:number)=>(
                        <Col md={3} key = {'projectTile_'+(item.project ? item.project.uuid : 'empty')+'_'+tile.label+'_'+tileKey.toString()}>
                        <StatTile
                            label={tile.label}
                            start={0}
                            first={tile.value.first && tile.value.first}
                            last={tile.value.last}
                            isLoading={!isLoaded}
                            deltaLabel={moment(dashboardContent.periodTo,'X').diff(moment(dashboardContent.periodFrom,'X'),'days').toString()}
                        />
                        </Col>
                        ))
                    ))}
                                </Row>
                            </Col>
                            <Col xl={4}>
                                { isTargetLoaded ?
                                    <PeerTargetTableSmall items={dashboardContent.targets} isFareRestriction={isTargetRestriction}/>
                                    :
                                    <Loader/>
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6}>
                                <ChannelActivitiesChart items={(activitiesData || []).filter((item:StatProjectChannelsDataType)=>{return item.project && item.project.uuid === currentProject.uuid }).pop()?.items || []}/>
                            </Col>
                            <Col xl={6}>
                                    <HeatmapWeeklyActivities items={(projectActivities || []).filter((activities:StatProjectActivitiesType)=>{return activities.project && activities.project.uuid === currentProject.uuid}).pop()?.items || []} color={currentProject.color}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <SchedulesCalendar items={(projectScheduleActivities || []).filter((activities:StatProjectActivitiesType)=>{return activities.project && activities.project.uuid === currentProject.uuid}).pop()?.items || []} project={currentProject}/>
                            </Col>
                            <Col xl={4}>
                                <SchedulesModes items={(projectActivities || []).filter((activities:StatProjectActivitiesType)=>{return activities.project && activities.project.uuid === currentProject.uuid}).pop()?.items || []} modes={contentPeerActivityModes.items || []}/>
                            </Col>
                            <Col xl={4}>
                                <SchedulesTypes items={(projectActivities || []).filter((activities:StatProjectActivitiesType)=>{return activities.project && activities.project.uuid === currentProject.uuid}).pop()?.items || []} types={peerActivityTypes.items || []}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6}>
                                <TableActivities items={(projectActivities || []).filter((activities:StatProjectActivitiesType)=>{return activities.project && activities.project.uuid === currentProject.uuid}).pop()?.items || []}/>
                            </Col>
                            <Col xl={6}>
                                <TablePeerChannel items={(dashboardContent.channels || []).filter((item:ContentPeerChannelType)=>{return item.project && item.project.uuid === currentProject.uuid && item.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS}) || []}/>
                            </Col>
                        </Row>

                        {currentCounterTiles && currentCounterTiles.length>0 &&
                            <>
                                <h5>{t('statCounter:projectCounters')}: {currentProject.name}</h5>
                        <Row>
                            {(currentCounterTiles || []).map((item: StatProjectCounterTilesType, key:number)=>(
                                (item.project === undefined || (item.project !== undefined && currentProject.uuid === item.project.uuid)) &&
                                (item.items || []).map((tile:StatStatCounterTileType, tileKey:number)=>(
                                    <Col md={3} key = {'projectCounterTile_'+(item.project ? item.project.uuid : 'empty')+'_'+tile.label+'_'+tileKey.toString()}>
                                        <StatTile
                                            label={tile.label}
                                            start={0}
                                            first={tile.value.first && tile.value.first}
                                            last={tile.value.last}
                                            isLoading={!isLoaded}
                                            deltaLabel={moment(dashboardContent.periodTo,'X').diff(moment(dashboardContent.periodFrom,'X'),'days').toString()}
                                        />
                                    </Col>
                                ))
                            ))}

                        </Row>
                        <Row>
                            <Col>
                                <CountersChart items={(countersData || []).filter((item:StatProjectCountersDataType)=>{return item.project && item.project.uuid === currentProject.uuid}).pop()?.items || []}/>
                            </Col>
                        </Row>
                            </>
                        }
                    </>
                }
        </>
    );
};

export default ContentDashboardIndex;


