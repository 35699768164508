import React, {useEffect, useState} from "react";

import { Input } from "reactstrap";

interface InputTextProps {
  value: null | string;
  id: string;
  placeholder: null | string;
  onChange: (value: string) => void;
  required: false | boolean;
  maxLength: null | number;
  className: null | string;
  autoFocus: false | boolean;
  disabled?: boolean;
  pattern?: string;
}
const InputText = ({ id, placeholder, value, onChange, required, maxLength, className, autoFocus, disabled = false, pattern}: InputTextProps) => {

    const [text, setText] = useState<string>(value || '');

    useEffect(() => {
        setText(value);
    }, [value]);

    const onChangeText = (text:string) => {
        setText(text);
        onChange(text);
    }

    const handleKey = (key: string) => {
        const ignoreKeys = ["Backspace", "Tab", "Enter"];
        if (!ignoreKeys.includes(key)) {
            const regexp = new RegExp(pattern);
            if (!key.match(regexp)) {
                return false;
            }
        }
        return true;
    }

    const handleKeyDown = (event) => {
        if (pattern?.length > 0 && !handleKey(event.key)){
            event.preventDefault();
            return false;
        }
    }

  return (
        <Input
        type="text"
        className={className || "form-control"}
        id={ id }
        placeholder={placeholder || ""}
        value={text || ''}
        onChange={(e: any) => {
            onChangeText(e.target.value);
        }}
        onKeyDown={(e)=>handleKeyDown(e)}
        maxLength={maxLength || null}
        autoComplete="off"
        required={ required }
        autoFocus={autoFocus}
        disabled={disabled}
      />
  );
};
export default InputText;
