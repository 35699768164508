import React from 'react';
import * as Yup from 'yup';

export const SaleCategoryValidatorSchema = Yup.lazy((values) => {
    const shapes = {};

    if (values?.parentCategory?.value && values?.uuid){
        if (values.parentCategory.value === values.uuid){
            shapes['parentCategory']=Yup.object().shape({
                value: Yup.string().max(0, 'shouldBeEmpty')
            })
        }
    }
    if (values?.parentCategory?.uuid && values?.uuid){
        if (values.parentCategory.uuid === values.uuid){
            shapes['parentCategory']=Yup.object().shape({
                uuid: Yup.string().max(0, 'shouldBeEmpty')
            })
        }
    }

    shapes['name']=Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required');
    shapes['order']=Yup.string()
        .matches(/^[0-9]+$/, "digitsOnly")
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required');

    return Yup.object().shape(shapes);
});