import {ContentTemplatesActionTypes, ContentTemplatePayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const contentTemplatesApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentTemplatesActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentTemplatesApiResponseError = (actionType: string, error: string) => ({
  type: ContentTemplatesActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentTemplates= (data: MainMetaType) => ({
  type: ContentTemplatesActionTypes.GET_CONTENT_TEMPLATES,
  payload: data
});

export const showRightModalContentTemplates= (data: ContentTemplatePayload) => ({
  type: ContentTemplatesActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalContentTemplates= () => ({
  type: ContentTemplatesActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataContentTemplates = (data: any) => ({
  type: ContentTemplatesActionTypes.UPDATE_CONTENT_TEMPLATES,
  payload: data,
});

export const editDataContentTemplates = (data: ContentTemplatePayload) => ({
  type: ContentTemplatesActionTypes.EDIT_CONTENT_TEMPLATES,
  payload: data,
});

export const removeContentTemplatesModalShow = (data: ContentTemplatePayload) => ({
  type: ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES_MODAL_SHOW,
  payload: data,
});

export const removeContentTemplatesModalHide = () => ({
  type: ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES_MODAL_HIDE,
});

export const onRemoveContentTemplates = (data: ContentTemplatePayload) => ({
  type: ContentTemplatesActionTypes.REMOVE_CONTENT_TEMPLATES,
  payload: data,
});