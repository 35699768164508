import React from "react";
import {MainIcon} from "../../redux/Main/icon";

import '../../i18/config';
import {useTranslation} from "react-i18next";
import {StatOrderTileType} from "../../redux/Stat/DashboardSale/types";
import {CurrencyType} from "../../redux/Sale/Currency/types";
import ChannelItemLine from "../../blocks/Content/PeerChannel/channelItemLine";

interface InputProps {
    currency: CurrencyType;
}

const SaleTableChannelsColumns = ({currency}:InputProps) => {

    const { t, i18n } = useTranslation();

    const FormatCurrencyValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const FormatValue = (value:number) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            notation: 'compact'
        }).format(value);

    const caseOrderCountSort = (rowA:StatOrderTileType, rowB:StatOrderTileType) => {

        const a = (rowA.value.order.last && rowA.value.order.last) || 0;
        const b = (rowB.value.order.last && rowB.value.order.last) || 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const caseAmountSort = (rowA:StatOrderTileType, rowB:StatOrderTileType) => {

        const a = (rowA.value.amount.last && rowA.value.amount.last) || 0;
        const b = (rowB.value.amount.last && rowB.value.amount.last) || 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    return [
        {
            name: <span className='font-weight-bold fs-14'>{t('channel:channel')}</span>,
            sortable: false,
            wrap: true,
            selector: (row:StatOrderTileType) => {
                return (
                    row?.entity?.uuid &&
                        <div className="p-1"><ChannelItemLine item={row.entity}/></div>
                );
            },
        },
        {
            id: 'orders',
            minWidth: '80px',
            maxWidth: '100px',
            name: <span className='text-muted fs-14' title={t('sale:orders')}><i className={MainIcon.ORDERS}/></span>,
            sortable: true,
            wrap: true,
            selector: (row:StatOrderTileType) => {
                return (
                    <>
                        <div className="text-nowrap text-truncate">
                            {row?.value?.order?.last && FormatValue(row.value.order.last)}
                        </div>
                        <div className="text-muted fs-12">{t('sale:orders')}</div>
                    </>
                );
            },
            sortFunction: caseOrderCountSort
        },
        {
            id: 'amount',
            minWidth: '120px',
            maxWidth: '150px',
            name: <span className='text-muted fs-14' title={t('sale:amount')}><i className={MainIcon.SUMMARY}/></span>,
            sortable: true,
            wrap: true,
            selector: (row:StatOrderTileType) => {
                return (
                    <>
                        <div className="text-nowrap text-truncate">
                            {row?.value?.amount?.last &&
                                currency.currencyFormat.replace(/#/,FormatCurrencyValue(row.value.amount.last))}
                        </div>
                        <div className="text-muted fs-12">{t('sale:amount')}</div>
                    </>
                );
            },
            sortFunction: caseAmountSort
        },


    ];

}

export default SaleTableChannelsColumns;