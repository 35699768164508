// types
import {ContentQuickAnswerActionTypes, ContentQuickAnswerState, ContentQuickAnswerType} from "./types";
import {NewEmptyActionsItem} from "../../types";

export const NewContentQuickAnswerItem:ContentQuickAnswerType = {
  enabled: true,
  name: '',
  text: ''
}

export const INIT_STATE_CONTENT_QUICK_ANSWER: ContentQuickAnswerState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewContentQuickAnswerItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const ContentQuickAnswer = (state = INIT_STATE_CONTENT_QUICK_ANSWER, action: any) => {
  switch (action.type) {
    case ContentQuickAnswerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentQuickAnswerActionTypes.GET_CONTENT_QUICK_ANSWER:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case ContentQuickAnswerActionTypes.UPDATE_CONTENT_QUICK_ANSWER:
          return {
            ...state,
            selectedItem: NewContentQuickAnswerItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER:
          return {
            ...state,
            selectedItem: NewContentQuickAnswerItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case ContentQuickAnswerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentQuickAnswerActionTypes.GET_CONTENT_QUICK_ANSWER:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        case ContentQuickAnswerActionTypes.UPDATE_CONTENT_QUICK_ANSWER:
          return {
            ...state,
            isSending: false
          };
        case ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER:
          return {
            ...state,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }
    case ContentQuickAnswerActionTypes.EDIT_CONTENT_QUICK_ANSWER:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentQuickAnswerActionTypes.RIGHT_MODAL_SHOW:

      let newItem = NewContentQuickAnswerItem;

      if ((action.payload.data !== undefined) && (action.payload.data.text !== undefined) && (action.payload.data.text.length>0)){
        newItem={...action.payload.data};
      }

      return {
        ...state,
        selectedItem: newItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentQuickAnswerItem,
        removeModalShow: false
      }
    case ContentQuickAnswerActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case ContentQuickAnswerActionTypes.UPDATE_CONTENT_QUICK_ANSWER:
      return {
        ...state,
        isSending: true
      };
    case ContentQuickAnswerActionTypes.GET_CONTENT_QUICK_ANSWER:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default ContentQuickAnswer;
