import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

// const getFavourites = () => {
//   return api.get(url.GET_FAVOURITES);
// };
//
// const getDirectMessages = () => {
//   return api.get(url.GET_DIRECT_MESSAGES);
// };

const getCommentsChannels = (data:MainMetaType) => {
  const query = createPaginationQuery(data);
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_COMMENTS_CHANNELS+"?"+query);
};

//
// const addContacts = (contacts: Array<string | number>) => {
//   return api.create(url.ADD_CONTACTS, contacts);
// };
//
// const createChannel = (data: object) => {
//   return api.create(url.CREATE_CHANNEL, data);
// };
//
// const getChatUserDetails = (id: string | number) => {
//   return api.get(url.GET_CHAT_USER_DETAILS + "/" + id, { params: { id } });
// };
//
const getCommentUserConversations = (id: string | number) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CHAT_USER_CONVERSATIONS + "/" + id, {
    params: { id },
  });
};
//
const sendCommentMessage = (data: any) => {

  if (data.attachmentFiles !== undefined) {
    return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.SEND_MESSAGE, data);
  } else {
    return api.create(m9sGlobals.CURRENT_CABINET+url.SEND_MESSAGE, data);
  }
};

const receiveCommentMessage = (uuid: string | number) => {
  return api.update(m9sGlobals.CURRENT_CABINET+url.RECEIVE_MESSAGE + "/" + uuid, { params: { uuid } });
};

const readCommentMessage = (uuid: string | number) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_MESSAGE + "/" + uuid);
};
//
// const receiveMessageFromUser = (id: string | number) => {
//   return api.get(url.RECEIVE_MESSAGE_FROM_USER + "/" + id, {
//     params: { id },
//   });
// };
//
const deleteCommentMessage = (messageId: number | string) => {
  return api.delete(m9sGlobals.CURRENT_CABINET+url.DELETE_MESSAGE + "/" + messageId, {
    params: { messageId },
  });
};
//
// const forwardMessage = (data: object) => {
//   return api.create(url.FORWARD_MESSAGE, data);
// };
//
// const deleteUserMessages = (userId: number | string) => {
//   return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
//     params: { userId },
//   });
// };
//
const getCommentsChannelDetails = (uuid: string | number) => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_COMMENTS_CHANNEL_DETAILS + "/" + uuid, { params: { uuid } });
};
//
// const toggleFavouriteContact = (id: string | number) => {
//   return api.update(url.TOGGLE_FAVOURITE_CONTACT + "/" + id, {
//     params: { id },
//   });
// };
//
// /*
// archive
// */
const getArchiveComments = (data:MainMetaType) => {
  const query = createPaginationQuery(data);
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_ARCHIVE_COMMENTS_CONTACT+"?"+query);
};

const toggleArchiveComments = (id: string | number) => {
  return api.update(m9sGlobals.CURRENT_CABINET+url.TOGGLE_ARCHIVE_CONTACT + "/" + id, { params: { id } });
};
//
// const readConversation = (id: string | number) => {
//   return api.update(url.READ_CONVERSATION + "/" + id, { params: { id } });
// };
//
// const deleteImage = (
//   userId: number | string,
//   messageId: number | string,
//   imageId: number | string
// ) => {
//   return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
//     params: { userId, messageId, imageId },
//   });
// };

export {
  // getFavourites,
  // getDirectMessages,
  getCommentsChannels,
  // addContacts,
  // createChannel,
  // getChatUserDetails,
  getCommentUserConversations,
  sendCommentMessage,
  receiveCommentMessage,
  readCommentMessage,
  // receiveMessageFromUser,
  deleteCommentMessage,
  // forwardMessage,
  // deleteUserMessages,
  getCommentsChannelDetails,
  // toggleFavouriteContact,
  getArchiveComments,
  toggleArchiveComments,
  // readConversation,
  // deleteImage,
};
