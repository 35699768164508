import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    UncontrolledButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";

// hooks
import { useRedux } from "../../../hooks";

import {
    getTasks
} from "../../../redux/actions";

import {TaskType, TaskStatus} from "../../../redux/Task/Task/types";
import {EntityActionsHandlersType} from "../../../redux/types";
import RemoveModal from "../../../components/RemoveForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import * as route from "../../../api/routes";
import classnames from "classnames";
import Loader from "../../../components/Loader";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {
    getHistoryHash,
    getIsNeedRefresh,
    removeIsNeedRefresh,
    setHistoryHash as setHistoryHashFunc
} from "../../../helpers/functions";
import {PlanOrderType} from "../../../redux/Sale/PlanOrder/types";
import TaskColumns from "./tableColumns";
import TableList from "../../../components/TableList";
import {MainMetaType} from "../../../redux/Main/common";
import MainListFilter from "../../../components/MainListFilter";
import {
    handleTaskCopy,
    handleTaskEdit, handleTaskGroupCloseAction, handleTaskGroupCloseHide,
    handleTaskGroupCloseShow,
    handleTaskGroupRemoveAction,
    handleTaskGroupRemoveHide,
    handleTaskGroupRemoveShow,
    handleTaskNew,
    handleTaskRemoveAction,
    handleTaskRemoveHide,
    handleTaskRemoveShow
} from "./TaskActions";

import { default as RemoveGroupActionModal} from "../../../components/GroupActionModal";
import { default as CloseGroupActionModal} from "../../../components/GroupActionModal";
import InputSwitch from "../../../components/InputSwitch";
import {TaskMemberTypeType} from "../../../redux/Task/TaskMember/types";

interface TaskStatusType  {
    value: string,
    label: string,
    enabled : boolean,
}

const TaskIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const componentType = 'taskStateType';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [selectedItems, setSelectedItems] = useState<TaskType[]>([]);
    const [searchPhrase, setSearchPhrase] = useState<string>('');

    const [statuses, setStatuses] = useState<TaskStatusType[]>(
        [
            {
                value: TaskStatus.ALL.status,
                label: t('common:all'),
                enabled : true
            },
            {
                value: TaskStatus.PENDING.status,
                label: t('task:taskStatus'+TaskStatus.PENDING.label),
                enabled : false
            },
            {
                value: TaskStatus.INPROGRESS.status,
                label: t('task:taskStatus'+TaskStatus.INPROGRESS.label),
                enabled : false
            },
            {
                value: TaskStatus.OVERDUE.status,
                label: t('task:taskStatus'+TaskStatus.OVERDUE.label),
                enabled : false
            },
            {
                value: TaskStatus.FINAL.status,
                label: t('task:taskStatus'+TaskStatus.FINAL.label),
                enabled : false
            },
        ]
    );

    const [memberTypes, setMemberTypes] = useState<TaskStatusType[]>(
        [
            {
                value: TaskMemberTypeType.TYPE_ALL,
                label: t('common:all'),
                enabled : true
            },
            {
                value: TaskMemberTypeType.TYPE_ASSIGNER,
                label: t('task:assigner'),
                enabled : false
            },
            {
                value: TaskMemberTypeType.TYPE_RESPONSIBLE,
                label: t('task:responsible'),
                enabled : false
            }
        ]
    );

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults ? historyHash.maxResults : perPage,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection,
            searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length>0 && historyHash.searchPhrase,
            status: TaskStatus.ALL.status,
            tab: TaskMemberTypeType.TYPE_ALL
        }

        if (historyHash?.status){
            mainMeta={
                ...mainMeta,
                status: historyHash?.status
            }
        }

        if (historyHash?.tab){
            mainMeta={
                ...mainMeta,
                tab: historyHash?.tab
            }
        }

        dispatch(getTasks(mainMeta));
    }

    useEffect(() => {

        if (historyHash.tab !== undefined && historyHash.tab.length>0 && activeTab !== historyHash.tab){
            toggleTab(historyHash.tab);
        }

        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        if (historyHash.status !== undefined && historyHash.status.length>0){
            let _selectedStatuses = historyHash.status.split(/,/);
            let _statuses = (statuses || []).map((itemStatus:TaskStatusType)=>{

                if (_selectedStatuses.indexOf(itemStatus.value)!==-1){
                    itemStatus.enabled = true;
                } else {
                    itemStatus.enabled = false;
                }

                return itemStatus;
            })

            setStatuses(_statuses);
        }

        loadItems();

// eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();

        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [tasks, setTasks] = useState<TaskType[]>([]);
    const [activeTab, setActiveTab] = useState("All");

    const {
        taskState,
        isLoading,
    } = useAppSelector(state => ({
        taskState: state.Task,
        isLoading: state.Task.isLoading,
    }));
    
    useEffect(() => {
        if (taskState.isNeedReload) {
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,taskState.isNeedReload]);

    useEffect(() => {
        if ((taskState.items !== undefined) && (taskState.items.length>0)){
            setTasks([...taskState.items]);
        } else {
            setTasks([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskState?.items])

    const onTableRowClick = (item:PlanOrderType) => {
        window.location.href = route.TASK_DETAIL.replace(/:uuid/g, item.uuid);
    }

    const EntityActionsHandlers:EntityActionsHandlersType = {
        onEdit: handleTaskEdit,
        onCopy: handleTaskCopy,
        onRemove: handleTaskRemoveShow,
        onDetail: onTableRowClick
    }

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleClickTab = (tab) => {
        if (activeTab !== tab) {
            if ( historyHash.tab !== tab) {
                historyHash.tab = tab;
                historyHash.page = 1;
                setHistoryHashFunc(historyHash);
            }
        }
    }

    const columns = TaskColumns(EntityActionsHandlers);


    const handleSearchClick = (value:string) => {
        setSearchPhrase(value);
        if ( historyHash.searchPhrase !== value) {
            historyHash.searchPhrase = value;
            setHistoryHashFunc(historyHash);
        }
    }

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }

    const handleGroupActionSelectItems = (items:any[]) => {
        setSelectedItems(items);
    }

    const handleChangeStatus = (status:TaskStatusType) => {
        let _statuses:TaskStatusType[] = [];
        let currentStatusEnabled = status.enabled

        _statuses = (statuses || []).map((itemStatus:TaskStatusType)=>{
            if (status.value === TaskStatus.ALL.status && currentStatusEnabled === false) {
                if (itemStatus.value === status.value) {
                    itemStatus.enabled = true;
                } else {
                    itemStatus.enabled = false;
                }
            } else if (status.value !== TaskStatus.ALL.status) {
                if (itemStatus.value === TaskStatus.ALL.status) {
                    itemStatus.enabled = false;
                } else if (status.value === itemStatus.value) {
                    itemStatus.enabled = !itemStatus.enabled;
                }
            }
            return itemStatus;
        });

        if (((_statuses || []).filter((itemStatus:TaskStatusType)=>{return itemStatus.enabled}) || []).length<1){
            _statuses.map((itemStatus:TaskStatusType)=>{

                if (itemStatus.value === TaskStatus.ALL.status){
                    itemStatus.enabled = true;
                }

                return itemStatus;
            })
        }

        let _selectedStatus = [];

        (_statuses || []).filter((itemStatus:TaskStatusType)=>{return itemStatus.enabled}).map((itemStatus:TaskStatusType)=>{_selectedStatus.push(itemStatus.value); return itemStatus.enabled})

        historyHash.status = (_selectedStatus || []).join(',');
        setHistoryHashFunc(historyHash);

    }

    if (getIsNeedRefresh()){
        removeIsNeedRefresh();
        loadItems();
    }

    return (
        <>
            <BreadCrumb title={t('menu:tasks')} pageTitle={t('menu:main')} />
            <Row>
                <Col lg={12}>
                    <Card id="taskList">
                        <CardHeader className="card-header border-0">
                            <Row className="align-items-center gy-3">
                                <div className="col-sm">
                                    <div className="d-flex flex-shrink-0">
                                    <button type="button"
                                            onClick={()=>{handleTaskNew(dispatch)}}
                                            className="btn btn-success btn-label waves-effect waves-light me-2">
                                        <i className="ri-add-line align-bottom label-icon me-1 font-weight-normal"/>{t('common:addNew')}
                                    </button>
                                    </div>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                            <MainListFilter
                                onClick={handleSearchClick}
                                value={searchPhrase}
                                onChange={onSearchInput}
                            />
                        </CardBody>
                        <CardBody className="pt-0">
                            <div>
                                <Nav
                                    className="nav-tabs nav-tabs-custom nav-success"
                                    role="tablist"
                                >
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === TaskMemberTypeType.TYPE_ALL },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab( TaskMemberTypeType.TYPE_ALL);
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-survey-line me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('task:allTasks')}
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === TaskMemberTypeType.TYPE_ASSIGNER },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab(TaskMemberTypeType.TYPE_ASSIGNER);
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-sun-line me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('task:assignedByMe')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames(
                                                { active: activeTab === TaskMemberTypeType.TYPE_RESPONSIBLE },
                                                "fw-semibold"
                                            )}
                                            onClick={() => {
                                                toggleClickTab(TaskMemberTypeType.TYPE_RESPONSIBLE);
                                            }}
                                            href="#"
                                        >
                                            <i className="ri-chat-smile-2-line me-1 align-bottom font-weight-normal"></i>{" "}
                                            {t('task:assignedToMe')}
                                        </NavLink>
                                    </NavItem>

                                    <NavItem className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end pt-1 pb-1">
                                        <div className="d-flex flex-shrink-0">
                                            {((selectedItems && (selectedItems.length>0)) || (historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true)) &&
                                                <UncontrolledButtonDropdown id="btnGroupVerticalAction">
                                                    <DropdownToggle
                                                        caret
                                                        className={"btn-sm btn-soft-primary"}
                                                        disabled={!(selectedItems && (selectedItems.length>0)) && !(historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true)}
                                                    >
                                                        <i className="ri-checkbox-multiple-line align-bottom me-1 font-weight-normal"/>
                                                        {t('common:groupAction')}{" "}(
                                                        {historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true
                                                            ?
                                                            taskState?.meta?.totalCount > 0 ? taskState?.meta?.totalCount : ''
                                                            :
                                                            selectedItems.length
                                                        })
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={()=>{handleTaskGroupCloseShow(dispatch)}}>{t('common:close')}</DropdownItem>
                                                        <DropdownItem onClick={()=>{handleTaskGroupRemoveShow(dispatch)}}>{t('common:delete')}</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            }
                                        </div>

                                    </NavItem>

                                </Nav>

                                <div>
                                    <div className="d-flex flex-column mt-2 pb-2">
                                        <div className="ps-3 d-flex flex-row border border-dashed border-top-0 border-start-0 border-end-0 mb-2 pb-2 pt-2">
                                            {t('common:status')}:
                                        </div>
                                        <div className="ps-3 d-flex flex-row flex-grow-1 border border-top-0 border-start-0 border-end-0">
                                        {(statuses || []).map((status:TaskStatusType)=>{
                                           return(
                                               <div className="d-flex flex-column me-3 mb-2" key={'taskStatus_'+status.value}>
                                               <InputSwitch
                                                id={"idTaskStatusSwitch_"+status.value}
                                                placeholder={status.label}
                                                value={status.enabled}
                                                onChange={
                                                    ()=>{
                                                        handleChangeStatus(status);
                                                    }
                                                }
                                                className="switch-sm"
                                           />
                                               </div>
                                           );
                                        })}
                                        </div>
                                    </div>

                                {!isLoading ?
                                    <TableList
                                        columns={columns}
                                        data={tasks || []}
                                        onRowClick={onTableRowClick}
                                        key={componentType}
                                        historyHash = {historyHash}
                                        selectableRows = {true}
                                        handleGroupActionSelectItems = {handleGroupActionSelectItems}
                                        paginationTotalRows={taskState?.meta?.totalCount || 0}
                                        paginationServer={true}
                                        isShowChangePageSize={true}
                                    />
                                 :
                                    <Loader />
                                }
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

                {/*<RightModal/>*/}
                <RemoveModal
                    onRemove={handleTaskRemoveAction}
                    handleClose={handleTaskRemoveHide}
                    removeItem={taskState.selectedItem}
                    isOpen={taskState.removeModalShow}
                    modalTitle={taskState.modalEditTitle}/>


            <CloseGroupActionModal
                items={selectedItems}
                historyHash={historyHash}
                isOpen={taskState.isModalGroupCloseShow}
                isSending={taskState.isSending}
                onClick={handleTaskGroupCloseAction}
                onClose={handleTaskGroupCloseHide}
                modalTitle={t('task:groupClose')}
                modalText={t('task:doYouWantClose')}
                modalActionButtonTitle={t('common:apply')}
            />

            <RemoveGroupActionModal
                items={selectedItems}
                historyHash={historyHash}
                isOpen={taskState.isModalGroupRemoveShow}
                isSending={taskState.isSending}
                onClick={handleTaskGroupRemoveAction}
                onClose={handleTaskGroupRemoveHide}
                modalTitle={t('task:groupDelete')}
                modalText={t('common:doYouWantDelete')}
                modalActionButtonTitle={t('common:delete')}
                modalActionButtonClass={'btn-danger'}
            />
        </>
    );
};

export default TaskIndex;


