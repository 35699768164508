import React, {useEffect, useState, useMemo} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {getHandlerIconByCode} from "../helpers/functions";

interface InputSelectProps {
    id?: string;
    items: any;
    value: null | any;
    placeholder: null | string;
    onChange: (value: string) => void;
    required: null | boolean;
    readonly: false | boolean;
    className?: null | string;
    onKeyPress?: (value: string) => void;
    isLoading: false | boolean,
    isClearable?: boolean;
}

const fillOption = (item:any) => {

    let _connectorHandlerItem = null;
    let _comment = null;

    if (item?.connector?.handler?.code !== undefined && item?.connector?.handler?.code !== null){
        _connectorHandlerItem=getHandlerIconByCode(item.connector.handler.code);
    }
    if (item?.connector?.name !== undefined && item?.connector?.name !== null){
        _comment=item.connector.name;
    }

    const option={
        label: item.name ? item.name : item.value && item.value,
        value: item.uuid ? item.uuid : item.id ? item.id : item.idPeer && item.idPeer,
        color: item.color && item.color,
        icon: item.icon ? item.icon : _connectorHandlerItem !==null && _connectorHandlerItem,
        avatar: item.avatar && item.avatar,
        colorClass: item.colorClass && item.colorClass,
        comment: _comment !== null && _comment
    }

    return option;
}

const InputSelect = ({ id, items, placeholder, value, onChange, required, className, readonly, onKeyPress, isLoading, isClearable = false}: InputSelectProps) => {

    const [currentValue, setCurrentValue] = useState(null);
    const [isOptionLoading, setIsOptionLoading] = useState<boolean>(isLoading);
    const animatedComponents = makeAnimated();

    const selectedOptions = useMemo(() => items.map(fillOption), [items]);

    useEffect(() => {

        setIsOptionLoading(isLoading);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoading]);

    useEffect(() => {
        if ((value !== undefined) && (value!==null)){
            if ((value.uuid !== undefined) && (value.uuid!==null)){

                const foundOption = (selectedOptions || []).filter((item:any, key: number)=>{
                    return item.value === value.uuid
                }).shift();

                if (foundOption!==undefined){
                    setCurrentValue(foundOption);
                } else {
                    setCurrentValue(fillOption(value));
                }
            } else {
                if (value.value === undefined){
                    const foundOption = (selectedOptions || []).filter((item:any, key: number)=>{
                        return item.value === value
                    });

                    if (foundOption!==undefined){
                        setCurrentValue(foundOption);
                    } else {
                        setCurrentValue(value);
                    }
                } else {
                    setCurrentValue(value);
                }
            }
        } else {
            setCurrentValue(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

  return (
     <Select
          value={currentValue}
          onChange={(event:any) => {
              setCurrentValue(event);
              onChange(event);
          }}
          onInputChange={(event:any) => {
              onKeyPress && onKeyPress(event);
          }}
          placeholder={placeholder && placeholder}
          isDisabled={readonly}
          options={selectedOptions}
          id={id && id}
          className={className || ' z-index-auto zindex-3'}
          isLoading={isOptionLoading}
          components={animatedComponents}
          isClearable={isClearable}
          classNames={{
              control: (state) =>
                  state.isFocused ? 'selectContUpdate' : 'selectContUpdate',
          }}
          formatOptionLabel={ (option:any) => (
              <div className="d-flex flex-shrink-0 align-items-center">
                  {option.color &&
                  <div className="avatar-xxs me-2 d-flex flex-shrink-0">
                      <span className={"avatar-title rounded-circle"}
                            ref={element => {
                                if (element) element.style.setProperty('background-color', option.color, 'important');
                            }}/>
                  </div>
                  }
                  {option.colorClass &&
                      <div className="avatar-xxs me-2 d-flex flex-shrink-0">
                      <span className={"avatar-title rounded-circle "+option.colorClass}/>
                      </div>
                  }
                  {option.icon &&
                  <div className="avatar-xxs me-2 d-flex flex-shrink-0">
                      <img src={option.icon} className="rounded-circle img-fluid" alt=""/>
                  </div>
                  }
                  {option.avatar &&
                  <div className="avatar-xxs me-2 d-flex flex-shrink-0">
                      <img src={option.avatar} className="rounded-circle img-fluid" alt=""/>
                  </div>
                  }
                  <div className="flex-1 justify-content-center flex-column d-flex">
                      {option.label}
                      {option?.comment && option.comment !== null &&
                        <small className="fs-9 text-muted text-truncate">{option.comment}</small>
                      }
                  </div>
              </div>
          )
          }
      />
  );
};
export default InputSelect;
