import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleIntegrationHandlerActionTypes } from "./types";
import { saleIntegrationHandlerApiResponseSuccess, saleIntegrationHandlerApiResponseError } from "./actions";

import {
  getSaleIntegrationHandlers as getSaleIntegrationHandlersApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getSaleIntegrationHandlers() {
  try {
    const response: Promise<any> = yield call(getSaleIntegrationHandlersApi);
    yield put(saleIntegrationHandlerApiResponseSuccess(SaleIntegrationHandlerActionTypes.GET_SALE_INTEGRATION_HANDLERS, response));
  } catch (error: any) {
    yield put(saleIntegrationHandlerApiResponseError(SaleIntegrationHandlerActionTypes.GET_SALE_INTEGRATION_HANDLERS, error));
  }
}

export function* watchGetSaleIntegrationHandlers() {
  yield takeEvery(SaleIntegrationHandlerActionTypes.GET_SALE_INTEGRATION_HANDLERS, getSaleIntegrationHandlers);
}


function* saleIntegrationHandlerSaga() {
  yield all([
    fork(watchGetSaleIntegrationHandlers),
  ]);
}

export default saleIntegrationHandlerSaga;
