// types
import {ChatsActionTypes, ChatsState, MessengerChannelType} from "./types";
import {changeSelectedChat} from "./actions";
import {MainMetaType} from "../../Main/common";
import {indexOf} from "lodash";

export const INIT_STATE: ChatsState = {
  favourites: [],
  directMessages: [],
  channels: [],
  selectedChat: null,
  chatUserDetails: {},
  chatUserConversations: {},
  isOpenUserDetails: false,
  channelDetails: {},
  archiveContacts: [],
  isCollapsed: true,
  isMessageSending: false
};

const MessengerChat = (state = INIT_STATE, action: any) => {

  let channelsUuids =[];

  switch (action.type) {
    case ChatsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ChatsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            favourites: action.payload.data,
            isFavouritesFetched: true,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            directMessages: action.payload.data,
            isDirectMessagesFetched: true,
            getDirectMessagesLoading: false,
            isContactsAdded: false,
          };
        case ChatsActionTypes.GET_CHANNELS:

          state.channels.map((channel: MessengerChannelType) => {
            channelsUuids.push(channel.uuid);
            return channel;
          });

          if (action.payload.data?.meta?.page === 1) {
            state.channels = [...action.payload.data.items, ...state.channels.filter((channel: MessengerChannelType) => {
              return indexOf(channelsUuids, channel.uuid) === -1
            })];

            if (state.channelsMeta === undefined
                ||
                state.channelsMeta === null
                ||
                state.channelsMeta.page ===undefined){
              state.channelsMeta = action.payload.data.meta;
            }

          } else {

            state.channels = state.channels.map((channel: MessengerChannelType) => {
              const foundChannel = action.payload.data.items.filter((channelResponse: MessengerChannelType) => {
                return channelResponse.uuid === channel.uuid
              }).shift();

              if (foundChannel?.uuid.length > 0) {
                return foundChannel;
              }
              return channel;
            });

            state.channelsMeta = action.payload.data.meta;
            state.channels = [...state.channels, ...action.payload.data.items.filter((channel: MessengerChannelType)=>{return indexOf(channelsUuids, channel.uuid) === -1}) ]
          }

          return {
              ...state,
              isChannelsFetched: true,
              getChannelsLoading: false,
              isChannelCreated: false,
          };

        case ChatsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: true,
            addContactsLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: true,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            chatUserDetails: action.payload.data,
            isUserDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: action.payload.data,
            isUserConversationsFetched: true,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
            isMessageDeleted: false,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: true,
            isMessageSending: false,
            chatUserConversations: [state.chatUserConversations, ...action.payload.data.items],
          };
        case ChatsActionTypes.RECEIVE_MESSAGE:
        case ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER:
          return {
            ...state,
            chatUserConversations: action.payload.data,
          };
        case ChatsActionTypes.READ_MESSAGE:
          return {
            ...state,
            isMessageRead: true,
            chatUserConversations: [state.chatUserConversations, ...action.payload.data.items],
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageSending: false,
            isMessageDeleted: true,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: true,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isMessageSending: false,
            isUserMessagesDeleted: true,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            chatUserDetails: { ...action.payload.data, isChannel: true },
            isChannelDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: true,
          };
        case ChatsActionTypes.GET_ARCHIVE_CONTACT:

          state.archiveContacts.map((channel: MessengerChannelType) => {
            channelsUuids.push(channel.uuid);
            return channel;
          });

          if (action.payload.data?.meta?.page === 1) {
            state.archiveContacts = [...action.payload.data.items, ...state.archiveContacts.filter((channel: MessengerChannelType) => {
              return indexOf(channelsUuids, channel.uuid) === -1
            })];

            if (state.archiveContactsMeta === undefined
                ||
                state.archiveContactsMeta === null
                ||
                state.archiveContactsMeta.page ===undefined){
              state.archiveContactsMeta = action.payload.data.meta;
            }

          } else {
            state.archiveContacts.map((channel: MessengerChannelType) => {
              const foundChannel = action.payload.data.items.filter((channelResponse: MessengerChannelType) => {
                return channelResponse.uuid === channel.uuid
              }).shift();

              if (foundChannel?.uuid.length > 0) {
                return foundChannel;
              }
              return channel;
            });

            state.archiveContactsMeta = action.payload.data.meta;
            state.archiveContacts = [...state.archiveContacts, ...action.payload.data.items.filter((channel: MessengerChannelType)=>{return indexOf(channelsUuids, channel.uuid) === -1}) ]
          }

          return {
            ...state,
            isArchiveContactFetched: true,
            isContactArchiveToggled: false,
          };

        case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: true,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        case ChatsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            channels: [...state.channels.map((channel:MessengerChannelType)=>{
              if (channel.uuid===action.payload.data.uuid){
                channel.unreadMessagesCount=0;
              }
              return channel;
            })],
            archiveContacts: [...state.archiveContacts.map((channel:MessengerChannelType)=>{
              if (channel.uuid===action.payload.data.uuid){
                channel.unreadMessagesCount=0;
              }
              return channel;
            })]
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ChatsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            isFavouritesFetched: false,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            isDirectMessagesFetched: false,
            getDirectMessagesLoading: false,
          };
        case ChatsActionTypes.GET_CHANNELS:
          return {
            ...state,
            isChannelsFetched: false,
            getChannelsLoading: false,
          };
        case ChatsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: false,
            addContactsLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            isUserDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: {},
            isUserConversationsFetched: false,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isMessageSending: false,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageSending: false,
            isMessageDeleted: false,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isMessageSending: false,
            isUserMessagesDeleted: false,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            isChannelDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: false,
          };
        case ChatsActionTypes.GET_ARCHIVE_CONTACT:
          return {
            ...state,
            isArchiveContactFetched: false,
          };
        case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: false,
          };
        case ChatsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            isRead: false,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.GET_FAVOURITES: {
      return {
        ...state,
        getFavouritesLoading: true,
        isFavouritesFetched: false,
      };
    }
    case ChatsActionTypes.GET_DIRECT_MESSAGES:
      return {
        ...state,
        isDirectMessagesFetched: false,
        getDirectMessagesLoading: true,
      };
    case ChatsActionTypes.GET_CHANNELS:
      return {
        ...state,
        isChannelsFetched: false,
        getChannelsLoading: true,
      };
    case ChatsActionTypes.ADD_CONTACTS:
      return {
        ...state,
        isContactsAdded: false,
        addContactsLoading: true,
      };
    case ChatsActionTypes.CREATE_CHANNEL:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.CHANGE_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
        isCollapsed: false
      };
    case ChatsActionTypes.GET_CHAT_USER_DETAILS:
      return {
        ...state,
        isUserDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
      return {
        ...state,
        isUserConversationsFetched: false,
        getUserConversationsLoading: true,
        isUserMessageSent: false,
      };
    case ChatsActionTypes.TOGGLE_USER_DETAILS_TAB:
      return {
        ...state,
        isOpenUserDetails: action.payload,
      };
    case ChatsActionTypes.ON_SEND_MESSAGE:
      return {
        ...state,
        isMessageSending: true,
        isUserMessageSent: false,
      };
    case ChatsActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        isMessageSending: true,
        isMessageDeleted: false,
      };
    case ChatsActionTypes.FORWARD_MESSAGE:
      return {
        ...state,
        isMessageForwarded: false,
      };
    case ChatsActionTypes.DELETE_USER_MESSAGES:
      return {
        ...state,
        isUserMessagesDeleted: false,
      };
    case ChatsActionTypes.GET_CHANNEL_DETAILS:
      return {
        ...state,
        isChannelDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
      return {
        ...state,
        isFavouriteContactToggled: false,
      };
    case ChatsActionTypes.GET_ARCHIVE_CONTACT:
      return {
        ...state,
        isArchiveContactFetched: false,
      };
    case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
      return {
        ...state,
        isContactArchiveToggled: false,
      };
    case ChatsActionTypes.DELETE_IMAGE:
      return {
        ...state,
        isImageDeleted: false,
      };
    case ChatsActionTypes.TOGGLE_COLLAPSED_MESSENGER:
      return {
        ...state,
        selectedChat: !state.isCollapsed ? null : state.selectedChat,
        isCollapsed: !state.isCollapsed,
      };
    default:
      return { ...state };
  }
};

export default MessengerChat;
