import React from "react";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import SupportTicketIndex from "./index";

const SupportServiceTicketIndex = () => {

    const { t } = useTranslation(['common']);


    return (
        <>
            <BreadCrumb title={t('menu:supportTicket')} pageTitle={t('menu:main')} />
            <SupportTicketIndex/>
        </>
    );
};

export default SupportServiceTicketIndex;


