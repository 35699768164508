import {CouponActionTypes, CouponType} from "./types";

export const CouponApiResponseSuccess = (actionType: string, data: any) => ({
    type: CouponActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const CouponApiResponseError = (actionType: string, error: string) => ({
    type: CouponActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const checkCoupon = (data: CouponType) => ({
    type: CouponActionTypes.CHECK_M9S_COUPON,
    payload: data
});

export const clearCoupon = () => ({
    type: CouponActionTypes.CLEAR_M9S_COUPON
});