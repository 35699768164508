import React from 'react';

interface LeftbarTitleProps {
  title: string;
}

const LeftbarTitle = ({ title }: LeftbarTitleProps) => {
  return (
      <div className="modal-title h4">{title}</div>
  );
};

export default LeftbarTitle;
