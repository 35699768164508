import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleOrderPaymentActionTypes } from "./types";
import { saleOrderPaymentApiResponseSuccess, saleOrderPaymentApiResponseError } from "./actions";

import {
  sendDataSaleOrderPayment as sendDataSaleOrderPaymentApi,
  removeSaleOrderPayment as removeSaleOrderPaymentApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* onSendDataSaleOrderPayment({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleOrderPaymentApi, data);
    yield put(
        saleOrderPaymentApiResponseSuccess(SaleOrderPaymentActionTypes.UPDATE_SALE_ORDER_PAYMENT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderPaymentApiResponseError(SaleOrderPaymentActionTypes.UPDATE_SALE_ORDER_PAYMENT, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleOrderPayment({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleOrderPaymentApi, {uuid: data.data.uuid});
    yield put(
        saleOrderPaymentApiResponseSuccess(SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleOrderPaymentApiResponseError(SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchOnSendDataSaleOrderPayment() {
  yield takeEvery(SaleOrderPaymentActionTypes.UPDATE_SALE_ORDER_PAYMENT, onSendDataSaleOrderPayment);
}
export function* watchOnRemoveSaleOrderPayment() {
  yield takeEvery(SaleOrderPaymentActionTypes.REMOVE_SALE_ORDER_PAYMENT, onRemoveSaleOrderPayment);
}

function* saleOrderPaymentSaga() {
  yield all([
    fork(watchOnSendDataSaleOrderPayment),
    fork(watchOnRemoveSaleOrderPayment)
  ]);
}

export default saleOrderPaymentSaga;
