import {HelperItemType} from "../../Helper/HelperItem/types";
import {ContentPeerTargetType} from "../../Content/PeerTarget/types";
import {PeerActivityType} from "../../Content/PeerActivity/types";
import {DataSetOrderType} from "../../Main/common";
export enum StatPeerActivityContentActionTypes {
    API_RESPONSE_SUCCESS = "@@statPeerActivity/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@statPeerActivity/API_RESPONSE_ERROR",
    GET_STAT_ACTIVITY_TILES = "@@statPeerActivity/GET_STAT_ACTIVITY_TILES",
    GET_STAT_ACTIVITY_ACTIVITIES_DATA = "@@statPeerActivity/GET_STAT_ACTIVITY_ACTIVITIES_DATA",
    GET_STAT_ACTIVITY_ENGAGEMENTS_DATA = "@@statPeerActivity/GET_STAT_ACTIVITY_ENGAGEMENTS_DATA",
    GET_STAT_ACTIVITY_TARGETS = "@@statPeerActivity/GET_STAT_ACTIVITY_TARGETS",
    GET_ACTIVITY = "@@statPeerActivity/GET_ACTIVITY",
    SET_PERIOD = "@@statPeerActivity/SET_PERIOD",
}

export interface StatPeerActivityState {
    tiles: Array<StatPeerActivityTileType>
    activitiesData: Array<StatPeerActivityActivitiesDataType>,
    engagementsData: Array<StatPeerActivityActivitiesDataType>,
    targets: Array<ContentPeerTargetType>,
    activity?: PeerActivityType,
    periodFrom: number,
    selectedDate?: number,
    periodTo?: number,
    deltaDays?: number,
    eTagActivities?: string,
    isStatPeerActivityContentFetched:boolean
    isStatPeerActivityContentTargetFetched:boolean
}

export interface StatPeerActivityTileType{
  label?: string;
  value?: StatPeerActivityTileValueType
  helperItem?: HelperItemType
}

export interface StatPeerActivityTileValueType{
    last?: number,
    first?: number,
    current?: number,
}

export interface StatPeerActivityPayloadType{
    activity: PeerActivityType
    periodFrom?: number,
    periodTo?: number,
    limit?: number,
    order?: DataSetOrderType
}

export interface StatPeerActivityActivitiesDataType{
    views?: number,
    comments?: number;
    likes?: number,
    reposts?: number,
    erPost?: number,
    erView?: number,
    loveRate?: number,
    talkRate?: number,
    date?: string
}

