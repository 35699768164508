import React, { useState } from "react";
import {Button, UncontrolledTooltip} from "reactstrap";
import RandomString from 'random-string';
// emoji picker
import Picker from "emoji-picker-react";
import '../i18/config';
import {useTranslation} from "react-i18next";

interface EmojiButtonsProps {
    onChange: (value: string) => void;
    text: null | string;
    cursorPosition?: number
}

const EmojiButtons = ({ onChange, text, cursorPosition }: EmojiButtonsProps) => {
    const { t, i18n } = useTranslation(['common']);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const idEmoji = RandomString({
      length: 8,
      numeric: true,
      letters: true,
      special: false,
  });

  const onToggleEmoji = () => {
    setIsOpen(!isOpen);
  };

  if (text === undefined){
      text='';
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
      if (cursorPosition === undefined || cursorPosition === null){
          text += emojiObject.emoji;
      } else {
          let textBeforeCursorPosition = text.substring(0, cursorPosition);
          let textAfterCursorPosition = text.substring(cursorPosition, text.length);
          text = textBeforeCursorPosition + emojiObject.emoji + textAfterCursorPosition
      }
      onChange(text);
      setIsOpen(false);
  };

  return (
    <>
      <button type="button"
              className="btn btn-outline-primary btn-icon waves-effect waves-light"
              onClick={onToggleEmoji}
              tabIndex={-1}
              id="content-emoji-btn"
      >
          <i className="bx bx-smile fs-16"/>
      </button>
        <UncontrolledTooltip target="content-emoji-btn" placement="top">
            {t('common:emoji')}
        </UncontrolledTooltip>
        {isOpen === true &&
            <div className="emojiButtonCont">
                <div className="position-absolute emojiButtonContWrapper">
                    <Picker onEmojiClick={onEmojiClick} />
                </div>
            </div>
        }
    </>
  );
};

export default EmojiButtons;
