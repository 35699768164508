import React, {useState} from 'react';
import {AITextGenerateFormat, AITextGenerateResultType} from "../../redux/AI/TextGenerate/types";
import {Card, CardBody, CardHeader, UncontrolledTooltip} from 'reactstrap';
import { Link } from 'react-router-dom';
import {showSuccessCopyNotification} from "../../helpers/notifications";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import InputEmojiArea from "../InputEmojiArea";
import '../../i18/config';
import {useTranslation} from "react-i18next";

interface InputProps {
    item: AITextGenerateResultType;
    onClose: (item:AITextGenerateResultType)=> void;
    onRefine?: (item:AITextGenerateResultType)=> void;
    onEdit?: (item:AITextGenerateResultType)=> void;
    onAddToIdeas? : (item:AITextGenerateResultType)=> void;
}

const ListItem = ({item, onClose, onRefine, onEdit, onAddToIdeas}:InputProps) => {

    const { t } = useTranslation();

    const [ isEdit, setEdit ] = useState<boolean>(false);
    const [ text, setText ] = useState<string>(item.text);

    const handleShowToggle = () => {
        setEdit(!isEdit);
    }

    const handleEdit= (text:string) => {
        setText(text);
    }

    const handleEditSave= () => {
        item.text = text;
        onEdit(item);
        handleShowToggle();
    }

    return (
        <>
            <Card className="card-border-info border">
                <CardHeader>
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <i className="mdi mdi-pencil-plus-outline label-icon align-middle text-info me-2"/>
                        </div>
                        <div className="flex-shrink-0">
                            <ul className="list-inline card-toolbar-menu d-flex align-items-center mb-0">
                                {onEdit !== undefined &&
                                    <li className="list-inline-item">
                                        <Link to="#" className="align-middle" role="button" onClick={handleShowToggle} id="ai-edit-btn">
                                            <i className="ri-edit-2-line align-middle"></i>
                                        </Link>
                                        <UncontrolledTooltip target="ai-edit-btn" placement="top">
                                            {t('common:edit')}
                                        </UncontrolledTooltip>
                                    </li>
                                }
                                {onRefine !== undefined &&
                                <li className="list-inline-item">
                                    <Link to="#" className="align-middle" role="button" onClick={() => {}}>
                                        <i className="mdi mdi-refresh align-middle"></i>
                                    </Link>
                                </li>
                                }
                                <li className="list-inline-item">
                                    <CopyToClipboard text={item.text}>
                                        <Link to="#" className="align-middle minimize-card" role="button" onClick={showSuccessCopyNotification} id="ai-copy-btn">
                                            <i className="ri-file-copy-2-line align-middle"/>
                                        </Link>
                                    </CopyToClipboard>
                                    <UncontrolledTooltip target="ai-copy-btn" placement="top">
                                        {t('common:copy')}
                                    </UncontrolledTooltip>
                                </li>
                                {onAddToIdeas !== undefined &&
                                <li className="list-inline-item">
                                    <Link to="#" className="align-middle" role="button" onClick={() => onAddToIdeas(item)} id="ai-copyIdeas-btn">
                                        <i className="mdi mdi-diamond-stone align-middle"/>
                                    </Link>
                                    <UncontrolledTooltip target="ai-copyIdeas-btn" placement="top">
                                        {t('common:copyToIdeas')}
                                    </UncontrolledTooltip>
                                </li>
                                }
                                <li className="list-inline-item">
                                    <button type="button" onClick={() => onClose(item)} className="btn-close fs-10 align-middle"></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </CardHeader>

                <CardBody className="card-body">
                    {isEdit ?
                        <>
                            <InputEmojiArea
                                id={'AITextGenerateResult_'+item.uuid}
                                placeholder={t('common:enterText')}
                                value={item.text}
                                onChange={handleEdit}
                                isShowAITextGenerate={false}
                            />
                            <div className="d-flex flex-row justify-content-end">
                                <button type="button" className="btn btn-outline-primary btn-icon waves-effect waves-light me-2" onClick={handleShowToggle}>
                                    <i className="ri-close-fill"></i></button>
                                <button type="button" className="btn btn-outline-success btn-icon waves-effect waves-light" onClick={handleEditSave}>
                                    <i className="ri-check-double-fill"></i></button>
                            </div>
                        </>
                        :
                        item.textFormat === AITextGenerateFormat.FORMAT_HTML ?
                            <span dangerouslySetInnerHTML={{__html: item.text}}/>
                            :
                            text.split('\n').map((str) => {
                                    return (<p className="mb-0">{str || '\u00A0'}</p>)})
                    }
                </CardBody>

            </Card>
        </>
    )
}

export default ListItem;