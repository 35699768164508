import React, {useEffect, useState, useCallback} from "react";
import { Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TableList from "../TableList";
import '../../i18/config';
import {useTranslation} from "react-i18next";
import {OrderStatusTypes} from "../../redux/Sale/OrderStatus/types";
import {ProductType} from "../../redux/Sale/Product/types";
import {StatOrderTileType} from "../../redux/Stat/DashboardSale/types";
import * as route from "../../api/routes";
import SaleTableProductsColumns from "./SaleTableProductsColumns";
import {CurrencyType} from "../../redux/Sale/Currency/types";
import {capitalizeFirstLetter} from "../../helpers/functions";
import {ProductParamType} from "../../redux/Sale/ProductParam/types";


interface TableActivitiesProps {
    items: [] | Array<StatOrderTileType>;
    entities: Array<ProductType>;
    currency: CurrencyType;

    productParams: ProductParamType[]
}

const SaleTableProducts = ({ items, entities, currency, productParams }: TableActivitiesProps) => {

    const { t } = useTranslation();
    const [shownStatus, setShownStatus] = useState<string>(OrderStatusTypes.ALL);
    const [shownItems, setShownItems] = useState<StatOrderTileType[]>([]);

    const handleShownItemsComputation = useCallback(() => {
        const _shownItems = (items || []).filter(item => item.type === shownStatus);
        _shownItems.map(item => {
            item.entity = (entities || []).find(entityItem => item.label === entityItem.uuid);
            return item;
        });
        setShownItems([..._shownItems]);
    }, [shownStatus, items, entities]);

    useEffect(handleShownItemsComputation, [handleShownItemsComputation]);

    const handleClickShownStatus = (status:string) => setShownStatus(status);

    const handleClick = (item:StatOrderTileType) => {
        if (item?.entity?.uuid) {
            window.location.href = route.SALE_PRODUCT_DETAIL.replace(/:uuid/, item.entity.uuid);
        }
    }

    const renderDropdownItem = (item: string) => {
        const key = `ordersStatusCountriesFilter_${OrderStatusTypes[item]}`;
        const text = t(`orderStatus:orderStatusType${capitalizeFirstLetter(OrderStatusTypes[item])}`);
        const handleClick = () => handleClickShownStatus(OrderStatusTypes[item]);

        return (<DropdownItem onClick={handleClick} key={key}>{text}</DropdownItem>);
    }

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('stat:topSales')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted">{t('orderStatus:orderStatusType'+capitalizeFirstLetter(shownStatus))}<i className="mdi mdi-chevron-down ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {Object.keys(OrderStatusTypes).map(renderDropdownItem)}
                        {/*{(Object.keys(OrderStatusTypes).map((item)=>{*/}
                        {/*    return(*/}
                        {/*        <DropdownItem onClick={()=>{handleClickShownStatus(OrderStatusTypes[item])}} key={"ordersStatusCountriesFilter_"+OrderStatusTypes[item]}>{t('orderStatus:orderStatusType'+capitalizeFirstLetter(OrderStatusTypes[item]))}</DropdownItem>*/}
                        {/*    )*/}
                        {/*}))}*/}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <TableList
                    columns={SaleTableProductsColumns({currency: currency, productParams:productParams})}
                    data={shownItems}
                    key={'saleBestProducts_'+Math.round(Math.random() * (99999 - 11111) + 11111)}
                    defaultSortFieldId={'amount'}
                    onRowClick={handleClick}
                />

            </CardBody>
        </Card>
    );
}

export default SaleTableProducts;