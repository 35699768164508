import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {PeerActivityType} from "../../redux/Content/PeerActivity/types";
import * as route from "../../api/routes";
import ReactApexChart from "react-apexcharts";
import {PeerActivityModeCode, PeerActivityModeType} from "../../redux/Content/PeerActivityMode/types";
import { ApexOptions } from 'apexcharts';
import PeerActivityModeChartLabel from "../../blocks/Content/PeerActivityMode/PeerActivityModeChartLabel";

import '../../i18/config';
import {useTranslation} from "react-i18next";

interface SchedulesModesProps {
    items: [] | Array<PeerActivityType>;
    modes: [] | Array<PeerActivityModeType>
}

const SchedulesModes = ({ items, modes }: SchedulesModesProps) => {

    const { t } = useTranslation();

    const [activityModes, setActivityModes] = useState<PeerActivityModeType[]>(modes);
    const [activities, setActivities] = useState<PeerActivityType[]>(items);

    const [chartLabels, setChartLabels] = useState<any[]>([]);
    const [chartColors, setChartColors] = useState<any[]>([]);
    const [chartSeries, setChartSeries] = useState<any[]>([]);
    const [series, setSeries] = useState<any[]>([]);

    useEffect(()=>{
        setActivityModes([...modes]);
    },[modes])
    useEffect(()=>{
        setActivities([...items]);
    },[items])

    useEffect(()=>{

        const _chartLabels=[];
        const _chartColors=[];
        const _chartSeries=[];

        (activityModes || []).map((item:PeerActivityModeType)=>{
            if (item.code !== PeerActivityModeCode.STAT){
                _chartLabels.push(t('peerActivityMode:'+item.code));
                _chartColors.push(item.color);
                _chartSeries.push({mode: item, count:0});
            }
            return item;
        });

        (activities || []).map((item:PeerActivityType, key:number)=>{
            (_chartSeries || []).map((chartSeriesItem:any)=>{
                if (chartSeriesItem.mode.uuid === item.mode.uuid){
                    chartSeriesItem.count++;
                }
                return chartSeriesItem;
            });
            return item;
        });

        const _series = (_chartSeries || []).map((chartSeriesItem:any)=>{
            return chartSeriesItem.count;
        });

        setChartLabels([..._chartLabels]);
        setChartColors([..._chartColors]);
        setChartSeries([..._chartSeries]);
        setSeries([..._series]);

    },[activities, activityModes, t])

    const options:ApexOptions =
        {
            labels:chartLabels,
            chart: {
                 type: "donut",
             },
            plotOptions: {
                pie: {
                    offsetX: 0,
                    offsetY: 0,
                    donut: {
                        size: "90%",
                        labels: {
                            show: false,
                        }
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            stroke: {
                lineCap: "round",
                width: 0
            },
            colors: chartColors,
        };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('peerActivityMode:activityModes')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('peerActivity:manageActivities')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div id="prjects-status" className="apex-charts" dir="ltr">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        height="230"
                        className="apex-charts"
                    />
                </div>
                <div className="mt-3">
                    <div className="d-flex justify-content-center align-items-center mb-4">
                        <h2 className="me-3 ff-secondary mb-0">{(items || []).length}</h2>
                        <div>
                            <p className="text-muted mb-0">{t('peerActivity:totalActivities')}</p>
                        </div>
                    </div>

                    {(chartSeries || []).map((chartSeriesItem:any, key: number)=>(
                       <PeerActivityModeChartLabel
                           key   = {'chartItemSeries_'+key+'_'+Math.round(Math.random() * (99999 - 11111) + 11111)}
                           name  = {t('peerActivityMode:'+chartSeriesItem.mode.code)}
                           label = {t('peerActivity:activities')}
                           color = {chartColors[key]}
                           count = {chartSeriesItem.count} />
                    ))}

                </div>

            </CardBody>
        </Card>
    );
}

export default SchedulesModes;