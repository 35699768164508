import {MainSearchActionTypes, MainSearchPayload} from "./types";

export const mainSearchApiResponseSuccess = (actionType: string, data: any) => ({
  type: MainSearchActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const mainSearchApiResponseError = (actionType: string, error: string) => ({
  type: MainSearchActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getMainSearch= (data: MainSearchPayload) => ({
  type: MainSearchActionTypes.GET_SEARCH_LIST,
  payload: data,
});
