import React from "react";
import {Link} from "react-router-dom";
import * as route from "../api/routes";

import '../i18/config';
import {useTranslation} from "react-i18next";

const upgradeImage = require("../resources/images/upgradePlan.png");

interface InputUpgradeFareProps {
  title?: string;
  text?: string;
  buttonTitle?: string;
  buttonLink?: string;
}

const UpgradeFare = ({
                         title = 'plan:planUpgrade',
                         text = 'plan:planUpgradeText',
                         buttonLink = route.SALE_PLANS,
                         buttonTitle = 'plan:planUp',
                     }: InputUpgradeFareProps) => {
    const {t} = useTranslation();
    title = t(title);
    text = t(text);
    buttonTitle = t(buttonTitle);

    return (
        <div className="d-flex flex-column align-items-center mb-5">
            <div className="w-75 d-flex flex-column align-items-center mb-3">
                <img src={upgradeImage} alt="" className="img-fluid d-block rounded-circle"/>
            </div>
            <div className="d-flex flex-column align-items-center mb-3">
                <h5 className="fs-16 mb-1">{title}</h5>
                <p className="text-muted mb-0 text-center">{text}</p>
            </div>
            <div className="d-flex flex-column align-items-center">
                <Link to={buttonLink} target="_blank" rel="noreferrer"
                      className="btn btn-primary waves-effect waves-light">{buttonTitle}</Link>
            </div>
        </div>
    );
};
export default UpgradeFare;
