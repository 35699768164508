// types
import {SaleCurrencyActionTypes, SaleCurrencyState, CurrencyType, defaultCurrency} from "./types";

export const NewSaleCurrencyItem:CurrencyType = {
  enabled: true,
  name: '',
  code: defaultCurrency,
  currencyFormat: '',
  order: 500
}

export const INIT_STATE_SALE_CURRENCY: SaleCurrencyState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleCurrencyItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const SaleCurrency = (state = INIT_STATE_SALE_CURRENCY, action: any) => {
  switch (action.type) {
    case SaleCurrencyActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleCurrencyActionTypes.GET_SALE_CURRENCIES:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleCurrencyActionTypes.UPDATE_SALE_CURRENCY:
          return {
            ...state,
            selectedItem: NewSaleCurrencyItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY:
          return {
            ...state,
            selectedItem: NewSaleCurrencyItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case SaleCurrencyActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleCurrencyActionTypes.GET_SALE_CURRENCIES:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SaleCurrencyActionTypes.UPDATE_SALE_CURRENCY:
          return {
            ...state,
            isSending: false
          };
        case SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SaleCurrencyActionTypes.EDIT_SALE_CURRENCY:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleCurrencyActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleCurrencyItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleCurrencyActionTypes.REMOVE_SALE_CURRENCY_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleCurrencyItem,
        removeModalShow: false
      }
    case SaleCurrencyActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleCurrencyActionTypes.UPDATE_SALE_CURRENCY:
      return {
        ...state,
        isSending: true
      };
    case SaleCurrencyActionTypes.GET_SALE_CURRENCIES:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default SaleCurrency;
