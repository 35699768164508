import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LeftbarTitle from "../../../components/LeftbarTitle";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../../../hooks";
import {GroupProductPayloadType, ProductType} from "../../../redux/Sale/Product/types";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {
    groupProductsModalHide,
    groupRemoveProductsModalHide, removeGroupProducts,
    setGroupProducts,
    unSetGroupProducts
} from "../../../redux/Sale/Product/actions";
import {MainMetaType} from "../../../redux/Main/common";
import ButtonSpinner from "../../../components/ButtonSpinner";


interface ItemProps {
    products: ProductType[],
    historyHash: HistoryHashType,
    isOpen: boolean
}

const DeleteProductsModal = ({ products, historyHash, isOpen }: ItemProps) => {

    const { t, i18n } = useTranslation();
    const { dispatch, useAppSelector } = useRedux();

    const {
        saleProduct,
        isSending
    } = useAppSelector(state => ({
        saleProduct: state.SaleProduct,
        isSending: state.SaleProduct.isSending
    }));

    const [isModalShow, setModalShow] = useState<boolean>(isOpen);

    useEffect(() => {
        setModalShow(isOpen);
    }, [isOpen, saleProduct.isModalGroupRemoveProductsShow, t]);

    const handleClick = () => {

        let mainMeta : MainMetaType = {}

        if (historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true) {
            mainMeta = {
                maxResults: historyHash.maxResults ? historyHash.maxResults : PAGINATION_DEFAULT_ITEMS_PER_PAGE,
                searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length > 0 && historyHash.searchPhrase
            }
        }

        let _productUuids: string[]=[];

        (products || []).map((product:ProductType)=>{
            _productUuids.push(product.uuid);
            return product;
        });

        let groupProductPayload:GroupProductPayloadType = {}

        if (_productUuids.length>0){
            groupProductPayload = {
                ...groupProductPayload,
                productUuids: _productUuids
            }
        }

        dispatch(removeGroupProducts({'items':[groupProductPayload], 'meta':mainMeta}));

    }

    const handleClose = () => {
        dispatch(groupRemoveProductsModalHide());
    }

    return (
        <>
            <Modal show={isModalShow} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <LeftbarTitle title={t('product:deleteProducts')}/>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<Row>*/}
                            {/*    <div className="d-flex flex-column">*/}
                            {/*        <div className="mb-3 d-flex flex-column">*/}
                            {/*    !# text about create product with offers */}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Row>*/}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleClose}>
                                {t('common:cancel')}
                            </Button>
                            <button
                                    className="btn btn-danger chat-send waves-effect waves-light"
                                    onClick={()=>handleClick()}
                                    disabled={isSending}
                            >
                                {isSending &&
                                    <ButtonSpinner className={"ms-0 me-2"}/>
                                }
                                {t('common:delete')}
                            </button>
                        </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteProductsModal;