import React from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {OrderType} from "../../../redux/Sale/Order/types";
import ChannelAvatar from "../../Content/PeerChannel/channelAvatar";
import {wsMessageEventTypes} from "../../../api/WebSocket/webSocketTypes";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {convertToMomentLocale} from "../../../helpers/functions";

interface ItemProps {
    order: OrderType;
    action?: string;

}
const OrderItem = ({ order, action }: ItemProps) => {

    const { t,i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(order?.locale ? order.locale : 'en-US', {
            style: 'decimal',
            currency: order.currency.code && order.currency.code,
        }).format(value);

    return (
        <>
            <Link to={route.SALE_ORDER_DETAIL.replace(/:uuid/g, order.uuid)}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    {order?.channel &&
                        <ChannelAvatar item={order.channel}/>
                    }
                </div>
                <div className="flex-grow-1 ms-3">
                    <h5 className="fs-14 mb-2">
                        {action === wsMessageEventTypes.SALE_ORDER_ADDED &&
                            t('sale:orderCreated') //code // creationDate
                        }
                        {action === wsMessageEventTypes.SALE_ORDER_PAID &&
                            t('sale:orderPaid') //code // creationDate
                        }
                        {action === wsMessageEventTypes.SALE_ORDER_CANCELLED &&
                            t('sale:orderCanceled') //code // creationDate
                        }
                    </h5>
                    <p className="text-muted text-truncate p-0 m-0">
                        <span className="text-body">#{order.code}</span> <small>{moment(order.createdAt).format('llll')}</small>
                    </p>
                    <p className="text-muted text-truncate p-0 m-0">
                        <span className="text-body">{order.currency.currencyFormat.replace(/#/,FormatValue(order.sum))}</span>{" "}
                        {(order.city || order.country) &&
                            <small>{order.city && order.city}, {order.country && order.country}</small>
                        }
                    </p>

                </div>
            </div>
            </Link>
        </>
    );
};

export default OrderItem;
