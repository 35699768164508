import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleCustomerActionTypes } from "./types";
import { saleCustomerApiResponseSuccess, saleCustomerApiResponseError } from "./actions";

import {
  getSaleCustomers as getSaleCustomersApi,
  sendDataSaleCustomer as sendDataSaleCustomerApi,
  removeSaleCustomer as removeSaleCustomerApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleCustomers({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleCustomersApi, data);
    yield put(saleCustomerApiResponseSuccess(SaleCustomerActionTypes.GET_SALE_CUSTOMERS, response));
  } catch (error: any) {
    yield put(saleCustomerApiResponseError(SaleCustomerActionTypes.GET_SALE_CUSTOMERS, error));
  }
}

function* onSendDataSaleCustomer({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleCustomerApi, data);
    yield put(
        saleCustomerApiResponseSuccess(SaleCustomerActionTypes.UPDATE_SALE_CUSTOMER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCustomerApiResponseError(SaleCustomerActionTypes.UPDATE_SALE_CUSTOMER, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleCustomer({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleCustomerApi, {uuid: data.data.uuid});
    yield put(
        saleCustomerApiResponseSuccess(SaleCustomerActionTypes.REMOVE_SALE_CUSTOMER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleCustomerApiResponseError(SaleCustomerActionTypes.REMOVE_SALE_CUSTOMER, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleCustomers() {
  yield takeEvery(SaleCustomerActionTypes.GET_SALE_CUSTOMERS, getSaleCustomers);
}
export function* watchOnSendDataSaleCustomer() {
  yield takeEvery(SaleCustomerActionTypes.UPDATE_SALE_CUSTOMER, onSendDataSaleCustomer);
}
export function* watchOnRemoveSaleCustomer() {
  yield takeEvery(SaleCustomerActionTypes.REMOVE_SALE_CUSTOMER, onRemoveSaleCustomer);
}

function* saleCustomerSaga() {
  yield all([
    fork(watchGetSaleCustomers),
    fork(watchOnSendDataSaleCustomer),
    fork(watchOnRemoveSaleCustomer)
  ]);
}

export default saleCustomerSaga;
