import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleStoreActionTypes } from "./types";
import { saleStoreApiResponseSuccess, saleStoreApiResponseError } from "./actions";

import {
  getSaleStores as getSaleStoresApi,
  sendDataSaleStore as sendDataSaleStoreApi,
  removeSaleStore as removeSaleStoreApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleStores({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleStoresApi, data);
    yield put(saleStoreApiResponseSuccess(SaleStoreActionTypes.GET_SALE_STORES, response));
  } catch (error: any) {
    yield put(saleStoreApiResponseError(SaleStoreActionTypes.GET_SALE_STORES, error));
  }
}

function* onSendDataSaleStore({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleStoreApi, data);
    yield put(
        saleStoreApiResponseSuccess(SaleStoreActionTypes.UPDATE_SALE_STORE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleStoreApiResponseError(SaleStoreActionTypes.UPDATE_SALE_STORE, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleStore({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleStoreApi, {uuid: data.data.uuid});
    yield put(
        saleStoreApiResponseSuccess(SaleStoreActionTypes.REMOVE_SALE_STORE, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleStoreApiResponseError(SaleStoreActionTypes.REMOVE_SALE_STORE, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleStores() {
  yield takeEvery(SaleStoreActionTypes.GET_SALE_STORES, getSaleStores);
}
export function* watchOnSendDataSaleStore() {
  yield takeEvery(SaleStoreActionTypes.UPDATE_SALE_STORE, onSendDataSaleStore);
}
export function* watchOnRemoveSaleStore() {
  yield takeEvery(SaleStoreActionTypes.REMOVE_SALE_STORE, onRemoveSaleStore);
}

function* saleStoreSaga() {
  yield all([
    fork(watchGetSaleStores),
    fork(watchOnSendDataSaleStore),
    fork(watchOnRemoveSaleStore)
  ]);
}

export default saleStoreSaga;
