import {
  DashboardContentActionTypes, PeriodType,
  StatPeriodPayloadType, StatProjectActivitiesPayloadType,
  StatProjectActivitiesType, StatProjectCounterTilesPayloadType,
  StatProjectTilesPayloadType, StatProjectTopUsersPayloadType
} from "./types";
import {ContentProjectType} from "../../Content/Project/types";

export const dashboardContentApiResponseSuccess = (actionType: string, data: any) => ({
  type: DashboardContentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const dashboardContentApiResponseError = (actionType: string, error: string) => ({
  type: DashboardContentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getDashboardContentChannelTiles= (data: StatProjectTilesPayloadType) => ({
  type: DashboardContentActionTypes.GET_STAT_CHANNELS_TILES,
  payload: data,
});

export const getDashboardContentCounterTiles= (data: StatProjectCounterTilesPayloadType) => ({
  type: DashboardContentActionTypes.GET_STAT_COUNTERS_TILES,
  payload: data,
});

export const getDashboardContentCountersData= (data: StatProjectCounterTilesPayloadType) => ({
  type: DashboardContentActionTypes.GET_STAT_COUNTERS_DATA,
  payload: data,
});

export const getDashboardContentChannelsActivitiesData= (data: StatProjectCounterTilesPayloadType) => ({
  type: DashboardContentActionTypes.GET_STAT_CHANNELS_ACTIVITIES_DATA,
  payload: data,
});

export const getDashboardContentTargets= (data: StatPeriodPayloadType) => ({
  type: DashboardContentActionTypes.GET_STAT_TARGETS,
  payload: data,
});

export const getDashboardContentActivities= (data: StatProjectActivitiesPayloadType) => ({
  type: DashboardContentActionTypes.GET_STAT_ACTIVITIES,
  payload: data,
});

export const getDashboardContentScheduleActivities = (data: StatProjectActivitiesPayloadType) => ({
  type: DashboardContentActionTypes.GET_SCHEDULE_STAT_ACTIVITIES,
  payload: data,
});

export const getDashboardContentChannels= () => ({
  type: DashboardContentActionTypes.GET_STAT_CHANNELS,
});

export const getDashboardContentTopUsers= (data: StatProjectTopUsersPayloadType) => ({
  type: DashboardContentActionTypes.GET_STAT_TOP_USERS,
  payload: data,
});

export const setDashboardContentPeriod= (data: PeriodType) => ({
  type: DashboardContentActionTypes.SET_PERIOD,
  payload: data,
});