import React from "react";
import {ContentPeerChannelType} from "../../redux/Content/PeerChannel/types";
import ChannelItemLine from "../../blocks/Content/PeerChannel/channelItemLine";
import {MainIcon} from "../../redux/Main/icon";

import '../../i18/config';
import {useTranslation} from "react-i18next";

const TablePeerChannelColumns = () => {

    const { t } = useTranslation();

    const FormatValue = (value:number) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            notation: 'compact'
        }).format(value);

    const caseViewsSort = (rowA:ContentPeerChannelType, rowB:ContentPeerChannelType) => {

        const a = (rowA.stat && rowA.stat.views) || 0;
        const b = (rowB.stat && rowB.stat.views) || 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const caseLikesSort = (rowA:ContentPeerChannelType, rowB:ContentPeerChannelType) => {

        const a = (rowA.stat && rowA.stat.likes) || 0;
        const b = (rowB.stat && rowB.stat.likes) || 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const caseCommentsSort = (rowA:ContentPeerChannelType, rowB:ContentPeerChannelType) => {

        const a = (rowA.stat && rowA.stat.comments) || 0;
        const b = (rowB.stat && rowB.stat.comments) || 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const caseMemberCountSort = (rowA:ContentPeerChannelType, rowB:ContentPeerChannelType) => {

        const a = (rowA.stat && rowA.stat.memberCount) || 0;
        const b = (rowB.stat && rowB.stat.memberCount) || 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    return [
        {
            name: <span className='font-weight-bold fs-14'>{t('channel:channel')}</span>,
            sortable: false,
            wrap: true,
            selector: (row:ContentPeerChannelType) => {
                return (
                    <ChannelItemLine item={row}/>
                );
            },
        },
        {
            id: 'views',
            minWidth: '50px',
            maxWidth: '70px',
            name: <span className='text-muted fs-14' title={t('stat:views')}><i className={MainIcon.VIEWS}/></span>,
            sortable: true,
            wrap: true,
            selector: (row:ContentPeerChannelType) => {
                return (
                    row.stat && row.stat.views && FormatValue(row.stat.views)
                );
            },
            sortFunction: caseViewsSort
        },
        {
            id: 'likes',
            minWidth: '50px',
            maxWidth: '70px',
            name: <span className='text-muted fs-14' title={t('stat:likes')}><i className={MainIcon.LIKES}/></span>,
            sortable: true,
            wrap: true,
            selector: (row:ContentPeerChannelType) => {
                return (
                    row.stat && row.stat.likes && FormatValue(row.stat.likes)
                );
            },
            sortFunction: caseLikesSort
        },
        {
            id: 'comments',
            minWidth: '50px',
            maxWidth: '70px',
            name: <span className='text-muted fs-14' title={t('stat:comments')}><i className={MainIcon.COMMENTS}/></span>,
            sortable: true,
            wrap: true,
            selector: (row:ContentPeerChannelType) => {
                return (
                    row.stat && row.stat.comments && FormatValue(row.stat.comments)
                );
            },
            sortFunction: caseCommentsSort
        },
        {
            id: 'memberCount',
            minWidth: '50px',
            maxWidth: '70px',
            name: <span className='text-muted fs-14' title={t('stat:members')}><i className={MainIcon.MEMBERS}/></span>,
            sortable: true,
            wrap: true,
            selector: (row:ContentPeerChannelType) => {
                return (
                    row.stat && row.stat.memberCount && FormatValue(row.stat.memberCount)
                );
            },
            sortFunction: caseMemberCountSort
        },
    ];

}

export default TablePeerChannelColumns;