import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SaleProductParamActionTypes } from "./types";
import { saleProductParamApiResponseSuccess, saleProductParamApiResponseError } from "./actions";

import {
  getSaleProductParams as getSaleProductParamsApi,
  sendDataSaleProductParam as sendDataSaleProductParamApi,
  removeSaleProductParam as removeSaleProductParamApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getSaleProductParams({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSaleProductParamsApi, data);
    yield put(saleProductParamApiResponseSuccess(SaleProductParamActionTypes.GET_PRODUCT_PARAMS, response));
  } catch (error: any) {
    yield put(saleProductParamApiResponseError(SaleProductParamActionTypes.GET_PRODUCT_PARAMS, error));
  }
}

function* onSendDataSaleProductParam({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSaleProductParamApi, data);
    yield put(
        saleProductParamApiResponseSuccess(SaleProductParamActionTypes.UPDATE_PRODUCT_PARAM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductParamApiResponseError(SaleProductParamActionTypes.UPDATE_PRODUCT_PARAM, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSaleProductParam({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSaleProductParamApi, {uuid: data.data.uuid});
    yield put(
        saleProductParamApiResponseSuccess(SaleProductParamActionTypes.REMOVE_PRODUCT_PARAM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(saleProductParamApiResponseError(SaleProductParamActionTypes.REMOVE_PRODUCT_PARAM, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetSaleProductParams() {
  yield takeEvery(SaleProductParamActionTypes.GET_PRODUCT_PARAMS, getSaleProductParams);
}
export function* watchOnSendDataSaleProductParam() {
  yield takeEvery(SaleProductParamActionTypes.UPDATE_PRODUCT_PARAM, onSendDataSaleProductParam);
}
export function* watchOnRemoveSaleProductParam() {
  yield takeEvery(SaleProductParamActionTypes.REMOVE_PRODUCT_PARAM, onRemoveSaleProductParam);
}

function* saleProductParamSaga() {
  yield all([
    fork(watchGetSaleProductParams),
    fork(watchOnSendDataSaleProductParam),
    fork(watchOnRemoveSaleProductParam)
  ]);
}

export default saleProductParamSaga;
