import React from "react";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";

interface ItemProps {
    item: PeerActivityType;
}

const PeerActivityLink = ({ item }: ItemProps) => {

    return(
        <>
            {item.activityLink !== null &&
                <a href={item.activityLink} target="_blank" rel="noreferrer"
                       className={"fs-12 text-muted d-flex font-weight-normal"}>
                        <i className={"ri-send-plane-fill"}/>
                </a>
            }
        </>
    )
}
export default PeerActivityLink;