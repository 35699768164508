import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum PaymentSystemActionTypes {
    API_RESPONSE_SUCCESS = "@@paymentSystem/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@paymentSystem/API_RESPONSE_ERROR",
    GET_PAYMENT_SYSTEMS = "@@paymentSystem/GET_PAYMENT_SYSTEMS",
    GET_SALE_PAYMENT_SYSTEMS = "@@paymentSystem/GET_SALE_PAYMENT_SYSTEMS",
    UPDATE_SALE_PAYMENT_SYSTEM = "@@salePaymentSystem/UPDATE_SALE_PAYMENT_SYSTEM",
    REMOVE_SALE_PAYMENT_SYSTEM_MODAL_SHOW = "@@salePaymentSystem/REMOVE_SALE_PAYMENT_SYSTEM_MODAL_SHOW",
    REMOVE_SALE_PAYMENT_SYSTEM_MODAL_HIDE = "@@salePaymentSystem/REMOVE_SALE_PAYMENT_SYSTEM_MODAL_HIDE",
    REMOVE_SALE_PAYMENT_SYSTEM = "@@salePaymentSystem/REMOVE_SALE_PAYMENT_SYSTEM",
    EDIT_SALE_PAYMENT_SYSTEM = "@@salePaymentSystem/EDIT_SALE_PAYMENT_SYSTEM",
    RIGHT_MODAL_SHOW = "@@salePaymentSystem/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@salePaymentSystem/RIGHT_MODAL_HIDE",
}

export interface PaymentSystemState extends MainMetaType{
    items: Array<PaymentSystemType>;
    isFetched: boolean,
    isLoading: boolean,
}

export interface SalePaymentSystemState {
    items: Array<any>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: PaymentSystemType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
}

export interface PaymentSystemType{
    enabled?: boolean;
    uuid?: string;
    name?: string;
    code?: string;
    description?: string;
    order?: number;
    idRemote?: string;
    actions?: EntityActionsType
}

export enum PaymentSystemIcon {
    INVOICE = 'ri-draft-line',
    CARD='ri-bank-card-2-line',
    PAYPAL='ri-paypal-line',
    BINANCE='binance-grey-logo-svg',
    TCLOUD='ri-bank-card-2-line',
}

export enum PaymentSystemCode {
    INVOICE = 'invoice',
    CARD='card',
    PAYPAL='paypal',
    BINANCE='binance',
    TRANSACTION_CLOUD='tCloud'
}

export interface SalePaymentSystemPayload {
    data: Array<any>;
    modalEditTitle : string;
}