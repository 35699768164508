import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getStatPeerTargets = (data:any) => {

    const sendData = {
        channel: data.channel && data.channel,
        activity: data.activity && data.activity,
        periodFrom: data.periodFrom,
        periodTo: data.periodTo
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_STAT_PEER_TARGETS, sendData);
};

export {
    getStatPeerTargets,
};
