import React from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {SupportTicketMessageType} from "../../../redux/Support/TicketMessage/types";
import * as route from "../../../api/routes";
import {SupportTicketType} from "../../../redux/Support/Ticket/types";

interface ItemProps {
    ticketMessage: SupportTicketMessageType;
    ticket: SupportTicketType;
}
const ToastItem = ({ ticketMessage, ticket }: ItemProps) => {

    const { t } = useTranslation(['common']);

    return (
        <>
            <Link to={ticketMessage.isSupportAnswer ? route.SUPPORT_TICKET_DETAIL.replace(/:uuid/g, ticket.uuid):route.SUPPORT_SERVICE_TICKET_DETAIL.replace(/:uuid/g, ticket.uuid)}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    <span className="avatar-sm rounded-circle bg-gradient">
                    {ticketMessage?.owner && ticketMessage.owner.avatar ?
                        <img src={ticketMessage.owner.avatar} alt="" className="avatar-sm rounded-circle" />
                        :
                        <span className="fs-23 text-muted">
                            {ticketMessage.owner.name ?
                                ticketMessage.owner.name[0]
                                :
                                '#'
                            }
                        </span>
                    }
                    </span>
                    <span
                        className="toastLabelIcon badge rounded-pill bg-info">
                        <i className="ri-customer-service-2-line fs-10"/>
                    </span>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h5 className="fs-14 mb-2">
                        {ticketMessage?.owner && ticketMessage.owner.name ?
                            ticketMessage.owner.name
                            :
                            ticketMessage.owner.email
                        }
                    </h5>
                    <p className="text-muted text-truncate p-0 m-0">{ticketMessage.text.substring(0, 25)}{ticketMessage.text.length >= 25 && '...'}</p>
                </div>
            </div>
            </Link>
        </>
    );
};

export default ToastItem;
