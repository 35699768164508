import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {createPaginationQuery, getCurrentCabinet} from "../../helpers/functions";
import {MainMetaType} from "../../redux/Main/common";

const api = new APIClient();

const getMainUsers = (data: MainMetaType) => {
    const query = createPaginationQuery(data);
    return api.get(url.GET_MAIN_USERS+'?'+query);
};

const getMainCurrentUser = () => {
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_MAIN_CURRENT_USER);
};

const getMainCurrentUserProfile = () => {
    let currentCabinet = getCurrentCabinet();
    let query='';

    if (currentCabinet !== false && currentCabinet.uuid !== undefined){
        query="?cabinet="+currentCabinet?.uuid;
    }

    return api.get(url.GET_MAIN_CURRENT_USER_PROFILE+query);
};

const sendDataMainUser = (data: any) => {

    let currentCabinet = getCurrentCabinet();

    if (currentCabinet !== false && currentCabinet.uuid !== undefined){
        data = {
            ...data,
            cabinet: {
                uuid: currentCabinet.uuid
            }
        }
    }

    return api.create(url.UPDATE_MAIN_USER, data);
};

const removeMainUser = (data: any) => {
    return api.create(url.REMOVE_MAIN_USER, data);
};

const sendAvatarMainUser = (data: any) => {

    const sendData = {
        uuid : data.uuid,
        attachmentFiles: [data.uploadAvatar]
    }

    return api.createWithFile(url.UPDATE_MAIN_USER_AVATAR, sendData);
};

const onSignUpUserSend = (data: any) => {
    return api.create(url.MAIN_AUTH_SIGNUP_CREATE, data);
};

const onResetUserSend = (data: any) => {
    return api.create(url.MAIN_AUTH_PASSWORD_RESET, data);
};

const onChangePasswordUserSend = (data: any) => {
    return api.create(url.MAIN_AUTH_CHANGE_PASSWORD, data);
};

const onChangeEmailUserSend = (data: any) => {
    return api.create(url.MAIN_AUTH_CHANGE_EMAIL, data);
};

const onLoginUserSend = (data: any) => {
    return api.create(url.POST_FAKE_JWT_LOGIN, data);
};

const onResendConfirmationEmail = () => {
    return api.get(url.MAIN_AUTH_SIGNUP_CONFIRM_EMAIL);
};

const onChangeUserLanguage = (data: any) => {
    return api.create(url.MAIN_USER_SET_LANGUAGE, data);
};

const getMainContacts = (data: any) => {
    return api.create(url.GET_MAIN_CONTACTS, data);
};

const getCabinetContacts = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_CABINET_CONTACTS, data);
};

export {
    getMainUsers,
    sendDataMainUser,
    removeMainUser,
    getMainCurrentUser,
    sendAvatarMainUser,
    onSignUpUserSend,
    onResetUserSend,
    onChangePasswordUserSend,
    onLoginUserSend,
    onResendConfirmationEmail,
    getMainCurrentUserProfile,
    onChangeUserLanguage,
    onChangeEmailUserSend,
    getMainContacts,
    getCabinetContacts
};
