import {MainUserType} from "../User/types";
import {MainDeviceType} from "../Device/types";

export enum AuthLoginActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/login/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/login/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/login/LOGIN_USER",

  LOGIN_USER_BY_DEVICE = "@@auth/login/LOGIN_USER_BY_DEVICE",
  LOGOUT_USER = "@@auth/login/LOGOUT_USER",

  CHANGE_USER_ACCOUNT = "@@auth/login/CHANGE_USER_ACCOUNT",
  SOCIAL_LOGIN = "@@auth/login/SOCIAL_LOGIN",
  GET_JWT_TOKEN = "@@auth/login/GET_JWT_TOKEN",
  CHECK_LOGIN_USER = "@@auth/login/CHECK_LOGIN_USER",
  CHANGE_EMAIL_COMPLETE = "@@auth/login/CHANGE_EMAIL_COMPLETE",
  WS_CONNECTED = "@@auth/login/WS_CONNECTED",
  WS_DISCONNECTED = "@@auth/login/WS_DISCONNECTED",
  GET_DEVICE_USERS = "@@auth/login/GET_DEVICE_USERS",

  CHANGE_LOGIN_BY = "@@auth/login/CHANGE_LOGIN_BY",
}

export interface AuthLoginState {
  error: string;
  isLoading: boolean;
  isUserLogin?: boolean;
  isWSConnected?: boolean;
  isUserLogout?: boolean;
  isUserDisabled?: boolean;
  settings?: any;
  confirm?: LoginConfirmType;
  change?: LoginChangeType;
  isSupportService: boolean;
  user?: MainUserType;
  deviceUsers?: Array<MainUserType>;
  loginBy: string;
}

export interface LoginType{
  email: string;
  password: string;
}

export interface LoginByDeviceType{
  user: MainUserType;
  device: MainDeviceType;
}

export interface LoginConfirmType{
  email: boolean;
}
export interface LoginChangeType{
  email: boolean;
}
export interface LoginJWTType{
  ip: string;
  uuid: string;
  roles: any;
  enabled: boolean;
  confirm?: LoginConfirmType;
  cabinets: Array<LoginCabinetType>;
  iat: number;
  exp: number;
  username: string;
  change?: LoginChangeType;
}

export interface LoginCabinetType{
  cabinet?: string;
  roles?: LoginCabinetRolesType
}

export interface LoginCabinetRolesType{
  isAdmin: boolean;
  isIntegrator: boolean;
  isAnalyst: boolean;
  isModerator: boolean;
  isContent:boolean;
  isSalesContent: boolean;
  isSalesManager: boolean;
  isSalesAdmin: boolean;
}

export interface LoginSocialType{
  token: string;
  partnerCode?: string;
}

export enum CabinetRoles{
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_INTEGRATOR = 'ROLE_INTEGRATOR',
  ROLE_ANALYTIC = 'ROLE_ANALYTIC',
  ROLE_MODERATOR = 'ROLE_MODERATOR',
  ROLE_CONTENT = 'ROLE_CONTENT',
  ROLE_SALES_CONTENT = 'ROLE_SALES_CONTENT',
  ROLE_SALES_MANAGER = 'ROLE_SALES_MANAGER',
  ROLE_SALES_ADMIN = 'ROLE_SALES_ADMIN'
}

export enum LoginByTypes{
  PASSWORD = 'userPasswordLogin',
  DEVICE = 'userDeviceLogin',
}