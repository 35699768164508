import React from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {SupportTicketMessageType} from "../../../redux/Support/TicketMessage/types";
import * as route from "../../../api/routes";
import {SupportTicketType} from "../../../redux/Support/Ticket/types";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {getHandlerIconByCode} from "../../../helpers/functions";
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";
import {useRedux} from "../../../hooks";

interface ItemProps {
    item: ContentPeerChannelType;
    message?: string;
    cabinet?: MainCabinetType
}
const ToastItem = ({ item, message, cabinet }: ItemProps) => {

    const { t } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    const {
        cabinetState
    } = useAppSelector(state => ({
        cabinetState: state.MainCabinet
    }));

    const externalCabinet = (cabinetState.items || []).filter((cabinetItem:MainCabinetType)=>{
        return cabinet?.uuid && cabinet.uuid.length>0 && cabinetItem.uuid === cabinet.uuid
    }).pop();

    return (
        <>
            { externalCabinet && externalCabinet?.uuid &&
                <div className="position-absolute top-0 end-0 mt-1 me-1" style={{zIndex:1}}>
                    <span className="badge rounded-pill text-bg-info">{externalCabinet.name.substring(0, 10)}{externalCabinet.name.length >= 10 && '...'}</span>
                </div>
            }
            <Link to={(externalCabinet && externalCabinet.uuid !== undefined) ? "#" : route.CONTENT_CHANNELS_LIST}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    <span className="avatar-sm rounded-circle bg-gradient">
                    {item?.avatar ?
                        <img src={item.avatar} alt="" className="avatar-sm rounded-circle" />
                        :
                        <span className="fs-23 text-muted">
                            {item.name ?
                                item.name[0]
                                :
                                '#'
                            }
                        </span>
                    }
                    </span>
                    { item.connector && item.connector.handler && item.connector.handler.code && item.connector.handler.code.length>0 ?
                        <div className="avatar-xxs position-absolute toastLabelIcon">
                            <img src={getHandlerIconByCode(item.connector.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                        </div>
                    :
                    <span
                        className="toastLabelIcon badge rounded-pill bg-info">
                        <i className="ri-share-line fs-10"/>
                    </span>
                    }
                </div>
                <div className="flex-grow-1 ms-3 mw-0">
                    <h5 className="fs-14 mb-2 text-truncate">
                        {item.name.substring(0, 23)}{item.name.length >= 23 && '...'}
                    </h5>
                    {message &&
                    <p className="text-muted text-truncate p-0 m-0">{t('channel:'+message).substring(0, 25)}{t('channel:'+message).length >= 25 && '...'}</p>
                    }
                </div>
            </div>
            </Link>
        </>
    );
};

export default ToastItem;
