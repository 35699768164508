import React, {useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    Row,
} from 'reactstrap';

const ListBar = () => {

    return (
        <Card>
            <CardBody>
                <Row className="g-2">
                    <Col sm={4}>
                        {/*<div className="search-box">*/}
                        {/*    <Input type="text" className="form-control" placeholder="Search for name or something..." />*/}
                        {/*    <i className="ri-search-line search-icon"/>*/}
                        {/*</div>*/}
                    </Col>
                    <Col className="col-sm-auto ms-auto">
                        <div className="list-grid-nav hstack gap-1">

                            <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"><i className="ri-grid-fill"/></Button>
                            <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"><i className="ri-list-unordered"/></Button>

                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default ListBar;
