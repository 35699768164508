import {PeerActivityActionTypes, PeerActivityCopyPayload, PeerActivityPayload, PeerActivityType} from "./types";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";

export const peerActivityApiResponseSuccess = (actionType: string, data: any) => ({
  type: PeerActivityActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const peerActivityApiResponseError = (actionType: string, error: string) => ({
  type: PeerActivityActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPeerActivities = (data: any) => ({
  type: PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES,
  payload: data,
});

export const setNeedReloadPeerActivities = () => ({
  type: PeerActivityActionTypes.SET_NEED_RELOAD,
});

export const togglePinPeerActivity = (data: any) => ({
  type: PeerActivityActionTypes.TOGGLE_PIN,
  payload: data,
});

export const setEditPeerActivityItems = (data: any) => ({
  type: PeerActivityActionTypes.SET_EDIT_ITEMS,
  payload: data,
});

export const setPeerActivityInitialized = () => ({
  type: PeerActivityActionTypes.SET_INITIALIZED,
});

export const showPeerActivityEditModal = () => ({
  type: PeerActivityActionTypes.SHOW_MODAL,
});

export const hidePeerActivityEditModal = () => ({
  type: PeerActivityActionTypes.HIDE_MODAL,
});

export const onUpdatePeerActivityItems = (data: any) => ({
  type: PeerActivityActionTypes.UPDATE_PEER_ACTIVITIES,
  payload: data
});

export const onPublishPeerActivityItems = (data: any) => ({
  type: PeerActivityActionTypes.PUBLISH_PEER_ACTIVITIES,
  payload: data
});

export const onUnPublishPeerActivityItems = (data: any) => ({
  type: PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY,
  payload: data
});

export const publishPeerActivitySuccessful = (data: any) => ({
  type: PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_SUCCESSFUL,
  payload: data
});

export const publishPeerActivityFailed = (data: any) => ({
  type: PeerActivityActionTypes.PUBLISH_PEER_ACTIVITY_FAILED,
  payload: data
});

export const unPublishPeerActivitySuccessful = (data: any) => ({
  type: PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_SUCCESSFUL,
  payload: data
});

export const unPublishPeerActivityFailed = (data: any) => ({
  type: PeerActivityActionTypes.UNPUBLISH_PEER_ACTIVITY_FAILED,
  payload: data
});

export const pinPeerActivitySuccessful = (data: any) => ({
  type: PeerActivityActionTypes.PIN_PEER_ACTIVITY_SUCCESSFUL,
  payload: data
});

export const pinPeerActivityFailed = (data: any) => ({
  type: PeerActivityActionTypes.PIN_PEER_ACTIVITY_FAILED,
  payload: data
});

export const unPinPeerActivitySuccessful = (data: any) => ({
  type: PeerActivityActionTypes.UNPIN_PEER_ACTIVITY_SUCCESSFUL,
  payload: data
});

export const unPinPeerActivityFailed = (data: any) => ({
  type: PeerActivityActionTypes.UNPIN_PEER_ACTIVITY_FAILED,
  payload: data
});

export const setActivityMode = (data: any) => ({
  type: PeerActivityActionTypes.SET_ACTIVITY_MODE,
  payload: data
});

export const getPeerActivityIdeasOnly = () => ({
  type: PeerActivityActionTypes.GET_CONTENT_PEER_ACTIVITIES,
  payload: {channel: {idPeer: 'ideas', handlerCode: connectorHandlerCode.CONNECTOR_HANDLER_IDEAS}},
});

export const copyActivity = (data: PeerActivityCopyPayload) => ({
  type: PeerActivityActionTypes.COPY_PEER_ACTIVITY,
  payload: data
});

export const removeActivity = (data: any) => ({
  type: PeerActivityActionTypes.REMOVE_PEER_ACTIVITY,
  payload: data
});

export const setShowMoreChannels = () => ({
  type: PeerActivityActionTypes.SET_SHOW_MORE_CHANNELS,
});

export const dontSetReloadAfterAction = () => ({
  type: PeerActivityActionTypes.DONT_SET_RELOAD_AFTER_ACTION,
});

export const setShowPreviewItems = () => ({
  type: PeerActivityActionTypes.SET_SHOW_PREVIEW_ITEMS,
});

export const setHidePreviewItems = () => ({
  type: PeerActivityActionTypes.SET_HIDE_PREVIEW_ITEMS,
});

export const clearPreviewItems = () => ({
  type: PeerActivityActionTypes.CLEAR_PREVIEW_ITEMS,
});

export const setPreviewPeerActivityItems = (data: PeerActivityType[]) => ({
  type: PeerActivityActionTypes.SET_PREVIEW_ITEMS,
  payload: data,
});

export const setPreviewMobile = () => ({
  type: PeerActivityActionTypes.SET_PREVIEW_MOBILE,
});

export const setPreviewDesktop = () => ({
  type: PeerActivityActionTypes.SET_PREVIEW_DESKTOP,
});