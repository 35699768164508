// types
import {SalePriceTypeActionTypes, SalePriceTypeState, PriceTypeType} from "./types";

export const NewSalePriceTypeItem:PriceTypeType = {
  enabled: true,
  name: '',
  order: 500
}

export const INIT_STATE_SALE_PRICE_TYPE: SalePriceTypeState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSalePriceTypeItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const SalePriceType = (state = INIT_STATE_SALE_PRICE_TYPE, action: any) => {
  switch (action.type) {
    case SalePriceTypeActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SalePriceTypeActionTypes.GET_SALE_PRICE_TYPES:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SalePriceTypeActionTypes.UPDATE_SALE_PRICE_TYPE:
          return {
            ...state,
            selectedItem: NewSalePriceTypeItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE:
          return {
            ...state,
            selectedItem: NewSalePriceTypeItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case SalePriceTypeActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SalePriceTypeActionTypes.GET_SALE_PRICE_TYPES:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SalePriceTypeActionTypes.UPDATE_SALE_PRICE_TYPE:
          return {
            ...state,
            isSending: false
          };
        case SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SalePriceTypeActionTypes.EDIT_SALE_PRICE_TYPE:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SalePriceTypeActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSalePriceTypeItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SalePriceTypeActionTypes.REMOVE_SALE_PRICE_TYPE_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSalePriceTypeItem,
        removeModalShow: false
      }
    case SalePriceTypeActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SalePriceTypeActionTypes.UPDATE_SALE_PRICE_TYPE:
      return {
        ...state,
        isSending: true
      };
    case SalePriceTypeActionTypes.GET_SALE_PRICE_TYPES:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default SalePriceType;
