import React from "react";
import { Navigate } from "react-router-dom";
import * as route from "../api/routes";

//Dashboard
import MainWizardInit from "../blocks/Main/Wizard/Init";
import MainUserIndex from "../blocks/Main/User/index";
import SearchIndex from "../blocks/Main/Search/index";
import MainVersionHistory from "../blocks/Main/VersionHistory/index";
import ContentDashboardIndex from "../blocks/Main/Dashboard/Content/ContentDashboard";
import ProjectIndex from "../blocks/Content/Project/index";
import PeerActivityTypeIndex from "../blocks/Content/PeerActivityType/index";
import ConnectorIndex from "../blocks/Content/Connector/index";
import PeerChannelIndex from "../blocks/Content/PeerChannel/index";
import StatCounterIndex from "../blocks/Content/StatCounter/index";
import TemplatesIndex from "../blocks/Content/Template/index";
import QuickAnswerIndex from "../blocks/Content/QuickAnswer/index";
import PeerTargetIndex from "../blocks/Content/PeerTarget/index";
import PeerUserIndex from "../blocks/Content/PeerUser/index";
import PeerChannelActivities from "../blocks/Content/PeerChannel/PeerChannelActivites";
import NotificationIndex from "../blocks/Event/Notification/index";
import StatPeerChannelIndex from "../blocks/Stat/PeerChannel/StatPeerChannel";
import StatPeerActivityIndex from "../blocks/Stat/PeerActivity/StatPeerActivity";
import MainUserProfileIndex from "../blocks/Main/User/profile";
import MessengerIndex from "../blocks/Messenger/index";
import CoverCabinet from "../blocks/Main/Cabinet/CoverCabinet";
import CoverLogin from "../blocks/Main/Auth/CoverLogin";
import CoverSignUp from "../blocks/Main/Auth/CoverSignUp";
import CoverReset from "../blocks/Main/Auth/CoverReset";
import CoverChangePassword from "../blocks/Main/Auth/CoverChangePassword";
import CoverSuccess from "../blocks/Main/Auth/CoverSuccess";
import CoverConfirmEmailSuccess from "../blocks/Main/Auth/CoverConfirmEmailSuccess";
import PlanIndex from "../blocks/Sale/Plan";
import PlanOrderIndex from "../blocks/Sale/PlanOrder";
import PlanOrderDetailIndex from "../blocks/Sale/PlanOrder/detail";
import PlanCheckoutIndex from "../blocks/Sale/Checkout";
import MessengerCommentIndex from "../blocks/Messenger/indexComments";
import CoverSocialLogin from "../blocks/Main/Auth/CoverSocialLogin";
import CabinetComingSoon from "../blocks/Main/Tools/CabinetComingSoon";
import CoverError from "../blocks/Main/Auth/CoverError";
import SupportTicketIndex from "../blocks/Support/Ticket";
import SupportTicketDetailIndex from "../blocks/Support/Ticket/detail";
import SupportServiceTicketIndex from "../blocks/Support/Ticket/indexService";
import SupportServiceTicketDetailIndex from "../blocks/Support/Ticket/detailService";
import MainAboutIndex from "../blocks/Main/About";
import KanBanActivityIndex from "../blocks/Content/KanBanPeerActivity";
import CalendarActivityIndex from "../blocks/Content/Calendar/index";
import ComparePeerChannelIndex from "../blocks/Stat/PeerChannel/ComparePeerChannel";
import PeerActivityIdeaIndex from "../blocks/Content/PeerActivityIdea";
import SaleStoreIndex from "../blocks/Sale/Store";
import SaleCurrencyIndex from "../blocks/Sale/Currency";
import SalePriceTypeIndex from "../blocks/Sale/PriceType";
import SalePaymentSystemIndex from "../blocks/Sale/PaymentSystem";
import SaleDeliverySystemIndex from "../blocks/Sale/DeliverySystem";
import SaleCategoryIndex from "../blocks/Sale/Category";
import SaleProductParamIndex from "../blocks/Sale/ProductParam";
import SaleProductIndex from "../blocks/Sale/Product";
import SaleProductDetail from "../blocks/Sale/Product/ProductDetail";
import ProductDetailEditIndex from "../blocks/Sale/Product/edit";
import SaleCurrencyRateIndex from "../blocks/Sale/CurrencyRate";
import SaleIntegrationIndex from "../blocks/Sale/Integration";
import SaleCustomerIndex from "../blocks/Sale/Customer";
import EventWebhookIndex from "../blocks/Event/Webhook";
import SaleOrderStatusIndex from "../blocks/Sale/OrderStatus";
import SaleOrderIndex from "../blocks/Sale/Order";
import SaleOrderDetailIndex from "../blocks/Sale/Order/detail";
import SaleDashboardIndex from "../blocks/Main/Dashboard/Sale/SaleDashboard";
import TaskIndex from "../blocks/Task/Task";
import TaskDetailIndex from "../blocks/Task/Task/detail";
import TaskDashboard from "../blocks/Main/Dashboard/Task/TaskDashboard";
import {FUNNELS} from "../api/routes";

export interface MainRoute {
  path: string;
  component?: React.FunctionComponent;
  exact?: boolean,
  props?: any;
}

const authProtectedRoutes:Array<MainRoute> = [
  { path: route.MAIN_CABINET_LIST, component: CoverCabinet },
  { path: route.MAIN_PERSONAL_PROFILE, component: MainUserProfileIndex },
  { path: route.MAIN_WIZARD_INIT, component: MainWizardInit },
  { path: route.MAIN_USERS_LIST, component: MainUserIndex },
  { path: route.MAIN_SEARCH, component: SearchIndex },
  { path: route.MAIN_VERSION_UPDATES, component: MainVersionHistory },
  { path: route.MAIN_DASHBOARD_CONTENT_MARKETING, component: ContentDashboardIndex },
  { path: route.MAIN_DASHBOARD_CONTENT_MARKETING_UUID, component: ContentDashboardIndex },

  { path: route.CONTENT_PROJECTS_LIST, component: ProjectIndex },
  { path: route.CONTENT_PEER_ACTIVITY_TYPES_LIST, component: PeerActivityTypeIndex },
  { path: route.CONTENT_SERVICES_LIST, component: ConnectorIndex },
  { path: route.CONTENT_CHANNELS_LIST, component: PeerChannelIndex },
  { path: route.CONTENT_STAT_COUNTERS_LIST, component: StatCounterIndex },
  { path: route.CONTENT_PATTERNS_LIST, component: TemplatesIndex },
  { path: route.CONTENT_QUICKANSWERS_LIST, component: QuickAnswerIndex },
  { path: route.CONTENT_PEERTARGETS_LIST, component: PeerTargetIndex },
  { path: route.CONTENT_ACTIVITY_PLAN, component: CalendarActivityIndex},
  { path: route.CONTENT_ACTIVITY_KANBAN, component: KanBanActivityIndex},
  { path: route.CONTENT_ACTIVITY_IDEAS, component: PeerActivityIdeaIndex},

  { path: route.CONTENT_CHANNEL_MEMBERS, component: PeerUserIndex },
  { path: route.CONTENT_CHANNEL_ACTIVITIES, component: PeerChannelActivities },

  { path: route.STAT_CONTENT_CHANNELS, component: PeerChannelIndex },
  { path: route.STAT_CONTENT_CHANNEL, component: StatPeerChannelIndex },
  { path: route.STAT_CONTENT_ACTIVITY, component: StatPeerActivityIndex },
  { path: route.COMPARE_CONTENT_CHANNELS, component: ComparePeerChannelIndex },

  { path: route.EVENT_PERSONAL_NOTIFICATIONS, component: NotificationIndex },
  { path: route.MESSENGER_MESSENGER, component: MessengerIndex },
  { path: route.MESSENGER_COMMENTS, component: MessengerCommentIndex },

  { path: route.SALE_PLANS, component: PlanIndex },
  { path: route.PLAN_ORDERS, component: PlanOrderIndex },
  { path: route.PLAN_ORDER_DETAIL, component: PlanOrderDetailIndex },
  { path: route.PLAN_ORDER_CHECKOUT, component: PlanCheckoutIndex },
  { path: route.CABINET_COMING_SOON, component: CabinetComingSoon },

  { path: route.MAIN_DASHBOARD_SALES, component: SaleDashboardIndex},
  { path: route.SALE_CATEGORIES_LIST, component: SaleCategoryIndex},
  { path: route.SALE_PRODUCTS_LIST, component: SaleProductIndex},
  { path: route.SALE_STORES_LIST, component: SaleStoreIndex},
  { path: route.SALE_CURRENCIES_LIST, component: SaleCurrencyIndex},
  { path: route.SALE_CURRENCY_RATES_LIST, component: SaleCurrencyRateIndex},
  { path: route.SALE_PRICE_TYPES_LIST, component: SalePriceTypeIndex},
  { path: route.SALE_PAYMENT_SYSTEMS_LIST, component: SalePaymentSystemIndex},
  { path: route.SALE_DELIVERY_SYSTEMS_LIST, component: SaleDeliverySystemIndex},
  { path: route.SALE_PRODUCT_PARAMS_LIST, component: SaleProductParamIndex},
  { path: route.SALE_ORDER_STATUSES_LIST, component: SaleOrderStatusIndex},
  { path: route.SALE_ORDERS_LIST, component: SaleOrderIndex},
  { path: route.SALE_INTEGRATIONS_LIST, component: SaleIntegrationIndex},
  { path: route.SALE_PRODUCT_DETAIL, component: SaleProductDetail},
  { path: route.SALE_PRODUCT_DETAIL_EDIT, component: ProductDetailEditIndex},
  { path: route.SALE_CUSTOMERS_LIST, component: SaleCustomerIndex},
  { path: route.EVENT_WEBHOOKS_LIST, component: EventWebhookIndex},

  { path: route.FUNNELS, component:  CabinetComingSoon ,props:{launchDate:'2024/02/15'}},

  // { path: route.MAIN_DASHBOARD_SALES, component: (process.env.REACT_APP_VERSION === 'local.dev')
  //       ?
  //       SaleDashboardIndex
  //       :
  //       CabinetComingSoon ,props:{launchDate:'2023/09/15'}
  // },

  { path: route.SALE_ORDER_DETAIL, component: SaleOrderDetailIndex },

  { path: route.SUPPORT_TICKETS_LIST, component: SupportTicketIndex },
  { path: route.SUPPORT_TICKET_DETAIL, component: SupportTicketDetailIndex },

  { path: route.SUPPORT_SERVICE_TICKETS_LIST, component: SupportServiceTicketIndex },
  { path: route.SUPPORT_SERVICE_TICKET_DETAIL, component: SupportServiceTicketDetailIndex },

  { path: route.MAIN_DASHBOARD_TASKS, component: TaskDashboard},
  { path: route.TASK_LIST, component: TaskIndex },
  { path: route.TASK_DETAIL, component: TaskDetailIndex },

  { path: route.MAIN_ABOUT, component: MainAboutIndex },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Navigate to={route.MAIN_CABINET_LIST} />,
  },
];

const publicRoutes:Array<MainRoute> = [
  // Authentication Page
  { path: route.MAIN_AUTH_LOGIN, component: CoverLogin },
  { path: route.MAIN_AUTH_SUCCESS, component: CoverSuccess },
  { path: route.MAIN_AUTH_SIGN_UP, component: CoverSignUp },
  { path: route.MAIN_AUTH_SIGN_UP_CONFIRMED, component: CoverConfirmEmailSuccess },
  { path: route.MAIN_AUTH_REQUEST_RESET_PASSWORD, component: CoverReset},
  { path: route.MAIN_AUTH_RESET_PASSWORD, component: CoverChangePassword},
  { path: route.MAIN_AUTH_SOCIAL, component: CoverSocialLogin},
  { path: route.ERROR_404, component: CoverError, props:{errorCode:404}},
];

export { authProtectedRoutes, publicRoutes };