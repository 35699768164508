import React from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getHandlerIconByCode} from "../../../helpers/functions";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";

interface ItemProps {
    item: SaleIntegrationType;
}
const SaleIntegrationItemBigLine = ({ item }: ItemProps) => {
    const { t } = useTranslation(['common']);

    return(
        <>
            <div className="d-flex flex-grow-1 mw-0">
                <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm position-relative">
                        {(item?.handler?.code && getHandlerIconByCode(item?.handler?.code) !== null) ?
                            <img src={getHandlerIconByCode(item.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                            :
                            <span className={"avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize"}>{item.name[0]}</span>
                        }
                    </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                    <h5 className="mb-1 fs-16 text-truncate">
                        {item.name}
                    </h5>
                </div>
            </div>
        </>
    )
}

export default SaleIntegrationItemBigLine;