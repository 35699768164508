import React from "react";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import SupportTicketDetailIndex from "./detail";

const SupportServiceTicketDetailIndex = () => {

    const { t } = useTranslation(['common']);

    return (
        <>
            <SupportTicketDetailIndex/>
        </>
    );
};

export default SupportServiceTicketDetailIndex;


