import React, { useEffect, useState } from "react";
import { BrowserRouter, Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useRedux } from "../../../hooks";

import {
    getContentPeerChannelMembers,
    onSendUnsubscribeChannelMember,
    showModalContentUnsubscribeMember
} from "../../../redux/actions";

import ListItem from "./ListItem";
import ListBar from "./ListBar";
import UnsubscribeModal from "./UnsubscribeModalForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

const PeerUserIndex = () => {
    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [idPeerChannel, setIdPeerChannel] = useState(window.location.pathname.match( /\d+/g )[0] || null);

    const {
        contentPeerUsers,
    } = useAppSelector(state => ({
        contentPeerUsers: state.ContentPeerUser,
    }));

    useEffect(() => {
        if (idPeerChannel!=null) {
            dispatch(getContentPeerChannelMembers({idPeerChannel:parseInt(idPeerChannel)}));
        }
    }, [dispatch, idPeerChannel]);

    useEffect(() => {
        const list = document.querySelectorAll(".team-list");
        const buttonGroups = document.querySelectorAll('.filter-button');
        for (let i = 0; i < buttonGroups.length; i++) {
            buttonGroups[i].addEventListener('click', onButtonGroupClick);
        }

        function onButtonGroupClick(event) {
            if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
                document.getElementById("list-view-button").classList.add("active");
                document.getElementById("grid-view-button").classList.remove("active");
                list.forEach(function (el) {
                    el.classList.add("list-view-filter");
                    el.classList.remove("grid-view-filter");
                });

            } else {
                document.getElementById("grid-view-button").classList.add("active");
                document.getElementById("list-view-button").classList.remove("active");
                list.forEach(function (el) {
                    el.classList.remove("list-view-filter");
                    el.classList.add("grid-view-filter");
                });
            }
        }
    }, []);

    const openUnsubscribeModal = (event:any) => {

        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(showModalContentUnsubscribeMember({data:event, modalEditTitle:  'changeSubscription'}));
        }
    };

    return (
        <>
                <div className="row">
                    <div>
                        <ListBar/>

                        <Row>
                            <Col lg={12}>
                                <div>
                                    <Row className="team-list grid-view-filter">
                                        {(contentPeerUsers.items || []).map((item, key : number) => (
                                            <ListItem
                                                item={item}
                                                key={'contentPeerUsers_' + key}
                                                channel={contentPeerUsers.selectedChannel}
                                                onUnsubscribe={openUnsubscribeModal}
                                            />
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <UnsubscribeModal/>
        </>
    );
};

export default PeerUserIndex;


