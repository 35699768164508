import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MessengerChannelType} from "../../../redux/Messenger/Chat/types";

import {getHandlerIconByCode} from "../../../helpers/functions";
import {useProfile} from "../../../hooks/UserHooks";

interface ChannelProps {
  channel: MessengerChannelType;
  selectedChat: string | number;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
  isRightBar?: boolean;
}
const ChatChannel = ({ channel, selectedChat, onSelectChat, isRightBar }: ChannelProps) => {

  const { t } = useTranslation(['common']);

  const unRead = channel.unreadMessagesCount;

  const { userProfile } = useProfile();

  const isSelectedChat: boolean =
    selectedChat && selectedChat === channel.uuid;
  const onClick = () => {
    onSelectChat(channel.uuid, true);
  };

  let lastMessageData = '';

  if (channel.lastMessage){
    lastMessageData = channel.lastMessage.text + '...';

    if (channel.lastMessage.userData.uuid === userProfile.uuid){
      lastMessageData =t('common:you')+': '+lastMessageData;
    }
  }

  if (isRightBar !== true){
    isRightBar = false;
  }

  return (
    <li className={classnames({ active: isSelectedChat , channelMenuItem:true})} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-user": unRead },"channelLinkBlock")}>
        <div className="d-flex align-items-center channelBlock">
          <div
              className={classnames(
                  "chat-user-img",
                  "align-self-center",
                  "me-2",
                  "ms-0",
                  // { online: isOnline }
              )}
          >
          {channel?.avatar ? (
              <>
                <img
                    src={channel.avatar}
                    className="rounded-circle avatar-sm"
                    alt=""
                />
                {channel?.handler?.code && channel.handler.code ? (
                    <div className="handlerIcon">
                      <img src={getHandlerIconByCode(channel.handler.code)} alt=""/>
                    </div>
                ) : ''}
                {/*{isOnline && <span className="user-status"></span>}*/}
              </>
          ) : (
              <div className="flex-shrink-0 avatar-sm">
            <span className="avatar-title rounded-circle bg-soft-success text-dark text-truncate">
              {channel?.name && channel.name.length>0 ? '#' : '#'}
            </span>
                {channel?.handler?.code && channel.handler.code ? (
                    <div className="handlerIcon">
                      <img src={getHandlerIconByCode(channel.handler.code)} alt=""/>
                    </div>
                ) : ''}
              </div>
          )}
          </div>
          {!isRightBar &&
          <div className={"flex-grow-1 overflow-hidden channelMenuItemName"}>
            <p className="mb-0">
              <span className="channelName text-truncate">{channel.name}</span>
              {channel.description?<span className="channelAbout text-truncate font-size-10 text-muted">{channel.description}</span>:''}
              <span className="channelLastMessage text-truncate font-size-10 channelFirstMessage  text-muted">{lastMessageData}</span>
            </p>
          </div>
          }
          {unRead && unRead !== 0 ? (
            <div className="flex-shrink-0 ms-2 channelMenuItemUnread">
              <span className="badge fs-10 rounded-pill text-bg-primary">
                {unRead}
              </span>
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
};

export default ChatChannel;
