import React, {useState} from "react";

const PeerActivityLaneHeader = ({updateTitle, canAddLanes, onDelete, onDoubleClick, editLaneTitle, label, title, titleStyle, labelStyle, t, laneDraggable
                    }) => {

    return (
        <div className="d-flex mb-2">
            <div className="flex-grow-1">
                <h6 className="fs-14 text-uppercase fw-semibold mb-0">{title}
                    {label !== '0' && label.length>0 &&
                    <small
                    className="badge bg-primary align-bottom ms-1 totaltask-badge">{label}</small>
                    }
                </h6>
            </div>
            <div className="flex-shrink-0">
                {/*<div className="dropdown card-header-dropdown">*/}
                {/*    <a className="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true"*/}
                {/*       aria-expanded="false">*/}
                {/*        <span className="fw-medium text-muted fs-14">Priority<i*/}
                {/*            className="mdi mdi-chevron-down ms-1"></i></span>*/}
                {/*    </a>*/}
                {/*    <div className="dropdown-menu dropdown-menu-end">*/}
                {/*        <a className="dropdown-item" href="#">Priority</a>*/}
                {/*        <a className="dropdown-item" href="#">Date Added</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default PeerActivityLaneHeader;
