import React, {useState} from "react";
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

import { Icon } from "ts-react-feather-icons";
import { BiHelpCircle } from "react-icons/bi";

import {ContentStatCounterType} from "../../../redux/Content/StatCounter/types";
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {handleCreateNewTaskByConnector} from "../Connector/ContentConnectorActions";
import {useRedux} from "../../../hooks";
import {convertToMomentLocale} from "../../../helpers/functions";

interface ItemProps {
    item: ContentStatCounterType;
    onEdit: (event:any) => void;
    onRemove: (event:any) => void;
}
const ListItem = ({ item, onEdit, onRemove }: ItemProps) => {

    const { t, i18n } = useTranslation(['common']);

    moment.locale(convertToMomentLocale(i18n.language));
    const { dispatch } = useRedux();

    const showActions = (item.actions &&
        (
            item.actions.canEdit
            ||
            item.actions.canRemove
        )) ? true : false;


    return (
            <Card className="card-height-100 card-animate">
                <CardBody>
                    <div className="d-flex flex-column h-100">
                        <div className="d-flex mb-4">
                            <div className="flex-grow-1 d-flex align-items-center">
                                <p className="text-muted mb-0">
                                    {t('common:lastUpdate')}:
                                    { item.stat && item.stat.dateTime ?
                                        <span className="ms-1">{moment(item.stat.dateTime).fromNow()}</span>
                                        :
                                        <span className="ms-1">{t('common:never')}</span>
                                    }
                                </p>
                            </div>
                            <div className="flex-shrink-0">
                                <div className="d-flex align-items-center">
                                    {/*<button type="button" className="btn avatar-xs p-0 favourite-btn active">*/}
                                    {/*                    <span className="avatar-title bg-transparent fs-15">*/}
                                    {/*                        <i className="ri-star-fill"></i>*/}
                                    {/*                    </span>*/}
                                    {/*</button>*/}

                                    { (Object(item).hasOwnProperty('isConnectorConnected') && item.isConnectorConnected === false)
                                        ?
                                        <span className="badge badge-outline-dark">{t('common:offline')}</span>
                                        :

                                        item.isInitUpdate ?
                                            <span className="badge bg-info">{t('common:init')}</span>
                                            :
                                            item.enabled ? <span className="badge bg-success">{t('common:active')}</span> :
                                                <span className="badge bg-danger">{t('common:inActive')}</span>

                                    }

                                    <div className="d-flex">
                                        <UncontrolledDropdown direction='start'>
                                            <DropdownToggle tag="a" className="btn btn-sm btn-link text-muted text-decoration-none fs-15">
                                                <Icon name="more-horizontal" />
                                            </DropdownToggle>

                                            <DropdownMenu className="dropdown-menu-end">

                                                <DropdownItem tag="a" onClick={() => onEdit(item)} ><i className="ri-pie-chart-line align-bottom text-muted  me-2"/> {t('stat:stat')} </DropdownItem>

                                                {showActions &&
                                                    <DropdownItem divider/>
                                                }
                                                {item.actions && item.actions.canEdit &&
                                                <DropdownItem tag="a" className='edit-item-btn' onClick={() => onEdit(item)}><i
                                                    className="ri-pencil-fill align-bottom text-muted me-2"/> {t('common:edit')}
                                                </DropdownItem>
                                                }
                                                <DropdownItem className='edit-item-btn' onClick={() => handleCreateNewTaskByConnector(item, dispatch)}><i
                                                    className="ri-survey-line align-bottom text-muted me-2"/> {t('task:addNewTask')}</DropdownItem>
                                                {item.actions && item.actions.canRemove &&
                                                <DropdownItem tag="a" className='remove-item-btn'
                                                              onClick={() => onRemove(item)}><i
                                                    className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')}
                                                </DropdownItem>
                                                }

                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mb-2">
                            <div className="flex-shrink-0 me-3">
                                <div className="avatar-sm position-relative">
                                    <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                        { item.avatar ?
                                            <img src={item.avatar} className="img-fluid p-1 rounded-circle" alt=""/>
                                            :
                                            item.name[0]
                                        }
                                    </div>
                                    { item.connector && item.connector.handler && item.connector.handler.icon &&
                                        <div className="avatar-xxs position-absolute handlerIconTopLeft">
                                            <img src={item.connector.handler.icon} className="img-fluid p-1 rounded-circle" alt=""/>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="flex-grow-1">
                                <h5 className="mb-1 fs-16">
                                    {item.name}
                                </h5>
                                {item.project &&
                                    <div className="badge badge-outline-primary fs-12 mb-1" ref={element => {
                                        if (element) {
                                            element.style.setProperty('color', item.project.color, 'important');
                                            element.style.setProperty('border-color', item.project.color, 'important');
                                        }
                                    }}>{item.project.name}</div>
                                }

                                {item.isInitUpdate &&
                                    <div className="text-muted text-truncate-two-lines mb-3 fs-14">
                                        {t('channel:dataUpdate')}...
                                        <OverlayTrigger
                                            placement={'right'}
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip>
                                                    {t('channel:dataUpdateDescription')}
                                                </Tooltip>
                                            }
                                        >
                                            <span className="position-relative">
                                                <BiHelpCircle className="mb-1 text-primary fs-12"/>
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
    );
};

export default ListItem;
