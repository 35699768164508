import React, { useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";

import { useRedux } from "../../../hooks";

import {
    getContentConnectors,
    getContentConnectorHandlers,
} from "../../../redux/actions";

import {ContentConnectorType} from "../../../redux/Content/Connector/types";

import ListItem from "./ListItem";

import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";
import RemoveModal from "../../../components/RemoveForm";
import UpgradeFareModal from "../../../components/UpgradeFareModal";
import {
    ContentConnectorOpenModal,
    ContentConnectorOpenRemoveModal,
    ContentConnectorHideRemoveModal,
    ContentConnectorOnRemove,
    contentConnectorCheckConnectionAttempts,
    contentConnectorConnectionSuccess,
    contentConnectorOpenConnectModal
} from "./ContentConnectorActions";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import Loader from "../../../components/Loader";
import {nameToEmoji} from "gemoji";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";

const ConnectorIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const [connectors, setConnectors] = useState<ContentConnectorType[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        contentConnectors,
        isContentConnectorFetched
    } = useAppSelector(state => ({
        contentConnectors: state.ContentConnector,
        isContentConnectorFetched: state.ContentConnector.isContentConnectorFetched
    }));

    useEffect(() => {
        dispatch(getContentConnectorHandlers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if ((contentConnectors.items !== undefined) && (contentConnectors.items.length>0)){
            setConnectors([...contentConnectors.items.filter((item:ContentConnectorType)=>{return item.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS})]);
        } else {
            setConnectors([]);
        }
    }, [contentConnectors?.items])


    useEffect(() => {
        dispatch(getContentConnectors());
    }, [dispatch, contentConnectors.isNeedReload]);

    useEffect(() => {
        contentConnectorCheckConnectionAttempts(
            contentConnectors.selectedItem,
            t('connector:connectionError'),
            dispatch);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentConnectors.selectedItem.connectionCheckAttempts]);

    useEffect(() => {
        contentConnectorConnectionSuccess(
            contentConnectors.selectedItem,
            t('connector:service')+' ' + contentConnectors.selectedItem.name+ ' '+t('connector:connected').toString().toLowerCase(),
            )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentConnectors.selectedItem.connectionSuccess]);

    useEffect(() => {

        if ((connectors || []).length>0){

            const selectedItemUuid = searchParams.get('service');

            if (selectedItemUuid!== undefined && selectedItemUuid!== null && selectedItemUuid.length>0) {

                const selectedItem = connectors.filter((item: ContentConnectorType) => item.uuid === selectedItemUuid).pop();

                if (selectedItem !== undefined && selectedItem !== null && selectedItem?.uuid){


                    if (searchParams.has('service')) {
                        searchParams.delete('service');
                        setSearchParams(searchParams);
                    }

                    ContentConnectorOpenModal(selectedItem,dispatch);
                }
            }
        }

    },[connectors, dispatch, searchParams, setSearchParams]);

    return (
        <>
            <BreadCrumb title={t('menu:servicesAccounts')} pageTitle={t('menu:main')} />
                <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary" onClick={(event)=>ContentConnectorOpenModal(event, dispatch)} className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>

                <div className="row">
                    <div>
                        <h5 className="mb-2">{t('connector:onlineServices')}</h5>
                        <div className="row">
                            {isContentConnectorFetched ?
                                <>
                                    {(connectors || []).filter((item:ContentConnectorType)=>item.isConnected).length<1 ?
                                    <div className="d-flex flex-row flex-grow-1 mt-2">
                                        <div className="alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                            {t('connector:servicesAbsentError')}<span className="align-bottom ps-1">{nameToEmoji.upside_down_face}</span>
                                        </div>
                                    </div>
                                    :
                                    (connectors || []).map((item: ContentConnectorType, key: number) => (
                                        item.isConnected &&
                                        <Col xxl={3} sm={6} className="project-card" key={'contentConnector_'+item.uuid}>
                                        <ListItem
                                            item={item}
                                            key={'contentConnector_'+item.uuid}
                                            onEdit={(event)=>ContentConnectorOpenModal(event, dispatch)}
                                            onRemove = {(event)=>ContentConnectorOpenRemoveModal(event, dispatch)}
                                            onConnect = {(event)=>contentConnectorOpenConnectModal(event, dispatch)}
                                        />
                                        </Col>
                                    ))}
                                </>
                                :
                                <Loader/>
                            }
                        </div>
                    </div>
                    <div>
                        <h5 className="mb-2">{t('connector:offlineServices')}</h5>
                        <div className="row">

                            {isContentConnectorFetched ?
                                <>
                                    {(connectors || []).filter((item:ContentConnectorType)=>!item.isConnected).length<1 && (connectors || []).filter((item:ContentConnectorType)=>item.isConnected).length>0 ?
                                        <div className="d-flex flex-row flex-grow-1 mt-2">
                                            <div className="alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                                                <i className="ri-check-line me-3 align-middle"></i> <strong className="pe-2">{t('common:congratulations')}!</strong>
                                                {t('connector:allServicesOnLine')}<span className="align-bottom ps-1">{nameToEmoji.airplane}</span>
                                            </div>
                                        </div>
                                        :
                                        (connectors || []).map((item: ContentConnectorType, key: number) => (
                                            !item.isConnected &&
                                            <Col xxl={3} sm={6} className="project-card" key={'contentConnector_'+item.uuid}>
                                                <ListItem
                                                    item={item}
                                                    key={'contentConnector_'+item.uuid}
                                                    onEdit={(event)=>ContentConnectorOpenModal(event, dispatch)}
                                                    onRemove = {(event)=>ContentConnectorOpenRemoveModal(event, dispatch)}
                                                    onConnect = {(event)=>contentConnectorOpenConnectModal(event, dispatch)}
                                                />
                                            </Col>
                                        ))}
                                </>
                                :
                                <Loader/>
                            }
                        </div>
                    </div>
                </div>

                <RightModal/>
                <RemoveModal
                    onRemove={ContentConnectorOnRemove}
                    handleClose={ContentConnectorHideRemoveModal}
                    removeItem={contentConnectors.selectedItem}
                    isOpen={contentConnectors.removeModalShow}
                    modalTitle={contentConnectors.modalEditTitle}/>
                <UpgradeFareModal/>
        </>
    );
};

export default ConnectorIndex;


