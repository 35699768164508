import {APIClient} from "../apiCore";
import * as url from "../urls";

const api = new APIClient();

const checkCoupon = (data: any) => {
    return api.create(url.M9S_COUPON_CHECK,{coupon:data});
};

export {
    checkCoupon
};