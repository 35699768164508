import {
  DashboardSaleActionTypes, StatOrderPayloadType
} from "./types";
import {PeriodType} from "../DashboardContent/types";
import {CurrencyType} from "../../Sale/Currency/types";

export const dashboardSaleApiResponseSuccess = (actionType: string, data: any) => ({
  type: DashboardSaleActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const dashboardSaleApiResponseError = (actionType: string, error: string) => ({
  type: DashboardSaleActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const setDashboardSalePeriod= (data: PeriodType) => ({
  type: DashboardSaleActionTypes.SET_PERIOD,
  payload: data,
});

export const getDashboardSaleOrderTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_TILES,
  payload: data,
});
export const getDashboardSaleOrdersCountriesTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_COUNTRIES_TILES,
  payload: data,
});
export const getDashboardSaleOrdersCurrenciesTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_CURRENCIES_TILES,
  payload: data,
});
export const getDashboardSaleOrdersStatusesTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_STATUSES_TILES,
  payload: data,
});
export const getDashboardSaleOrdersBestProductsTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_PRODUCTS_TILES,
  payload: data,
});
export const getDashboardSaleOrdersBestChannelsTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CHANNELS_TILES,
  payload: data,
});
export const getDashboardSaleOrdersBestCustomersTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_BEST_CUSTOMERS_TILES,
  payload: data,
});
export const getDashboardSaleOrdersLast12MonthTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_12MONTH_TILES,
  payload: data,
});
export const getDashboardSaleOrdersWeekDaysTiles= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_ORDERS_WEEKDAYS_TILES,
  payload: data,
});
export const getDashboardSaleRecentOrders= (data: StatOrderPayloadType) => ({
  type: DashboardSaleActionTypes.GET_STAT_RECENT_ORDERS,
  payload: data,
});
export const selectDashboardSaleCurrency= (data: CurrencyType) => ({
  type: DashboardSaleActionTypes.SELECT_CURRENCY,
  payload: data,
});