import {ProductType, SaleProductActionTypes, SaleProductPayload} from "./types";
import {SaleProductIntegrationPayload} from "../ProductIntegration/types";
import {SaleProductPropertiesPayload} from "../ProductParamValue/types";
import {PeerActivityActionTypes, PeerActivityCopyPayload} from "../../Content/PeerActivity/types";
import {MainMetaType} from "../../Main/common";

export const saleProductApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleProductActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleProductApiResponseError = (actionType: string, error: string) => ({
  type: SaleProductActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleProducts = (data: MainMetaType) => ({
  type: SaleProductActionTypes.GET_PRODUCTS,
  payload: data
});

export const getSaleProduct= (data: SaleProductPayload) => ({
  type: SaleProductActionTypes.GET_PRODUCT,
  payload: data,
});
export const getSaleEditProduct= (data: SaleProductPayload) => ({
  type: SaleProductActionTypes.GET_EDIT_PRODUCT,
  payload: data,
});

export const showRightModalSaleProduct= (data: SaleProductPayload) => ({
  type: SaleProductActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleProduct= () => ({
  type: SaleProductActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleProduct = (data: any) => ({
  type: SaleProductActionTypes.UPDATE_PRODUCT,
  payload: data,
});

export const editDataSaleProduct = (data: SaleProductPayload) => ({
  type: SaleProductActionTypes.EDIT_PRODUCT,
  payload: data,
});

export const removeSaleProductModalShow = (data: SaleProductPayload) => ({
  type: SaleProductActionTypes.REMOVE_PRODUCT_MODAL_SHOW,
  payload: data,
});

export const removeSaleProductModalHide = () => ({
  type: SaleProductActionTypes.REMOVE_PRODUCT_MODAL_HIDE,
});

export const onRemoveSaleProduct = (data: SaleProductPayload) => ({
  type: SaleProductActionTypes.REMOVE_PRODUCT,
  payload: data,
});

export const editSaleProductIntegrationChannel = (data: SaleProductIntegrationPayload) => ({
  type: SaleProductActionTypes.EDIT_PRODUCT_INTEGRATION_CHANNEL,
  payload: data,
});

export const saleProductIntegrationChannelModalHide = () => ({
  type: SaleProductActionTypes.PRODUCT_INTEGRATION_CHANNEL_MODAL_HIDE,
});

export const updateProductIntegrationChannel = (data: any) => ({
  type: SaleProductActionTypes.UPDATE_PRODUCT_INTEGRATION_CHANNEL,
  payload: data,
});


export const editSaleProductProperties = (data: SaleProductPropertiesPayload) => ({
  type: SaleProductActionTypes.EDIT_PRODUCT_PROPERTIES,
  payload: data,
});

export const saleProductPropertiesModalHide = () => ({
  type: SaleProductActionTypes.PRODUCT_PROPERTIES_MODAL_HIDE,
});

export const updateProductProperties = (data: any) => ({
  type: SaleProductActionTypes.UPDATE_PRODUCT_PROPERTIES,
  payload: data,
});

export const copyProduct = (data: ProductType) => ({
  type: SaleProductActionTypes.COPY_PRODUCT,
  payload: data
});

export const clearProductFetched = () => ({
  type: SaleProductActionTypes.CLEAR_PRODUCT_FETCHED
});

export const groupIntegrationModalShow = () => ({
  type: SaleProductActionTypes.RIGHT_MODAL_GROUP_INTEGRATION_SHOW
});
export const groupIntegrationModalHide = () => ({
  type: SaleProductActionTypes.RIGHT_MODAL_GROUP_INTEGRATION_HIDE
});

export const updateGroupIntegration = (data: any) => ({
  type: SaleProductActionTypes.UPDATE_GROUP_PRODUCT_INTEGRATIONS,
  payload: data
});

export const setGroupProducts = (data: any) => ({
  type: SaleProductActionTypes.SET_GROUP_PRODUCTS,
  payload: data
});

export const unSetGroupProducts = (data: any) => ({
  type: SaleProductActionTypes.UNSET_GROUP_PRODUCTS,
  payload: data
});

export const setGroupProductsModalShow = () => ({
  type: SaleProductActionTypes.MODAL_SET_GROUP_PRODUCTS_SHOW
});
export const unSetGroupProductsModalShow = () => ({
  type: SaleProductActionTypes.MODAL_UNSET_GROUP_PRODUCTS_SHOW
});
export const groupProductsModalHide = () => ({
  type: SaleProductActionTypes.MODAL_GROUP_PRODUCTS_HIDE
});
export const removeGroupProducts = (data: any) => ({
  type: SaleProductActionTypes.REMOVE_GROUP_PRODUCTS,
  payload: data
});
export const groupRemoveProductsModalShow = () => ({
  type: SaleProductActionTypes.MODAL_REMOVE_PRODUCTS_SHOW
});
export const groupRemoveProductsModalHide = () => ({
  type: SaleProductActionTypes.MODAL_REMOVE_PRODUCTS_HIDE
});