import React, {useState, useEffect} from "react";
import DataTable from 'react-data-table-component';
import {PaginationOptions} from "react-data-table-component";

import '../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../hooks";
import {HistoryHashType} from "../data/settings";
import {setHistoryHash} from "../helpers/functions";
import TableListPagination, {TablePaginationOptions} from "./TableListPagination";

interface InputTableListProps {
    columns: any;
    data: any;
    key: string;
    theme?: string;
    defaultSortFieldId? : string;
    paginationOptions?: PaginationOptions;
    onRowClick: (data:any) => void;
    historyHash?: HistoryHashType;
    selectableRows?: boolean;
    handleGroupActionSelectItems?: (data:any) => void;
    paginationTotalRows?: number;
    progressPending?: boolean;
    paginationServer?: boolean;
    isShowChangePageSize?: boolean;
    isShowAllGroupAction?: boolean;
    isShowPagination?: boolean;
}

const TableList = ({columns, data, theme, defaultSortFieldId, paginationOptions, onRowClick, historyHash, selectableRows = false, handleGroupActionSelectItems, paginationTotalRows, progressPending, paginationServer, isShowChangePageSize = false, isShowAllGroupAction = false, isShowPagination = true } :InputTableListProps) => {

    const { t } = useTranslation(['common']);

    const {  dispatch, useAppSelector } = useRedux();

    const {
        layoutModeType,
    } = useAppSelector(state => ({
        layoutModeType: state.Layout.layoutModeType,
    }));

    const [rows, setRows] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [toggleCleared, setToggleCleared] = useState<boolean>(false);
    
    const defaultPaginationComponentOptions:PaginationOptions = {
        rowsPerPageText: t('common:rowsPerPage'),
        rangeSeparatorText: t('common:rangeSeparator'),
        selectAllRowsItem: false
    }

    const [componentPaginationOptions, setComponentPaginationOptions] = useState<TablePaginationOptions>(defaultPaginationComponentOptions);
    
    const handleChange = (state) => {
        setSelectedData(state.selectedRows);
        if (typeof handleGroupActionSelectItems === 'function'){
            handleGroupActionSelectItems(state.selectedRows);
        }
    };

    const tableCustomStyles = {
        table: {
            style: {
                backgroundColor: 'transparent',
            },
        },
        headRow: {
            style: {
                backgroundColor: 'transparent',
            },
        },
        rows:{
            style: {
                backgroundColor: 'transparent',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgba(54, 197, 241,.05)',
                transitionDuration: '0.15s',
                transitionProperty: 'background-color',
            },
        },
        pagination: {
            style: {
                backgroundColor: 'transparent',
            },
        },
        // responsiveWrapper: {
        //     // style: {
        //     //     overflow: 'initial'
        //     // },
        // }
    }

    const tableCustomStylesBlack = {
        table: {
            style: {
                backgroundColor: 'transparent'
            },
        },
        headRow: {
            style: {
                backgroundColor: 'transparent',
            },
        },
        rows:{
            style: {
                backgroundColor: 'transparent',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgba(54, 197, 241,.1)',
                transitionDuration: '0.15s',
                transitionProperty: 'background-color',
            },
        },
        pagination: {
            style: {
                backgroundColor: 'transparent',
            },
        },
        // responsiveWrapper: {
        //     // style: {
        //     //     overflow: 'initial'
        //     // },
        // }
    }

    // let componentPaginationOptions = defaultPaginationComponentOptions;
    //
    // if (paginationOptions !== undefined){
    //     componentPaginationOptions:  = {
    //         ...defaultPaginationComponentOptions,
    //         ...paginationOptions,
    //         selectedData:selectedData
    //     }
    // }

    const allGroupActionSelectHandler = (selected: boolean) => {
        if (historyHash !== undefined) {
            historyHash.allGroupActionSelect = selected;
            setHistoryHash(historyHash);
        }
    }

    useEffect(() => {

        let _componentPaginationOptions = {
            ...defaultPaginationComponentOptions,
            selectedData:selectedData,
            isShowChangePageSize: isShowChangePageSize,
            isShowAllGroupAction: isShowAllGroupAction,
            allGroupActionSelectHandler: allGroupActionSelectHandler
        }

        if (paginationOptions !== undefined){
            _componentPaginationOptions = {
                ..._componentPaginationOptions,
                ...paginationOptions
            }
        }

        setComponentPaginationOptions(_componentPaginationOptions);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationOptions, selectedData]);

    useEffect(() => {
        setRows(data);
        setSelectedData([]);

        setToggleCleared(!toggleCleared);

        if (typeof handleGroupActionSelectItems === 'function'){
            handleGroupActionSelectItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

//For load icon / icon content in css
    const sortIconAsc = <i className="mdi mdi-sort-descending ms-2"/>;

    const onRowClicked = (row, event) => { onRowClick(row);};

    const onSort = (selectedColumn, sortDirection) => {

        if (historyHash !== undefined) {
            historyHash.sortField = selectedColumn.id;
            historyHash.sortDirection = sortDirection;

            setHistoryHash(historyHash);
        }
    }

    const onChangePageUpdateHash = (page, totalRows) => {
        if (historyHash !== undefined) {
            historyHash.page = page;
            setHistoryHash(historyHash);
        }
    }

    const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        if (historyHash !== undefined) {
            historyHash.page = currentPage;
            historyHash.maxResults = currentRowsPerPage;
            setHistoryHash(historyHash);
        }
    }

    return (
        <>
            {rows && rows.length>0 ?
                <>
                    <DataTable
                        columns={columns}
                        defaultSortFieldId={(historyHash && historyHash.sortField) ? historyHash.sortField : defaultSortFieldId && defaultSortFieldId}
                        defaultSortAsc={(historyHash && historyHash.sortDirection) && historyHash.sortDirection === 'asc'}
                        paginationDefaultPage = {historyHash && historyHash.page && historyHash.page}
                        paginationPerPage = {historyHash && historyHash.maxResults && historyHash.maxResults}
                        paginationComponentOptions={componentPaginationOptions}
                        onChangePage = {onChangePageUpdateHash}
                        onChangeRowsPerPage = {onChangeRowsPerPage}
                        sortIcon={<i className="mdi mdi-sort-ascending ms-2"/>}
                        data={rows}
                        pagination = {isShowPagination && isShowPagination}
                        pointerOnHover
                        striped={false}
                        persistTableHead={true}
                        responsive={true}
                        theme={ theme ? theme : layoutModeType}
                        noDataComponent = {t('common:emptyDataSet')}
                        highlightOnHover={true}
                        onRowClicked={onRowClicked}
                        onSort={onSort}
                        customStyles={layoutModeType === 'dark' ? tableCustomStylesBlack : tableCustomStyles}
                        selectableRows={selectableRows}
                        onSelectedRowsChange={(selectableRows && selectableRows===true) ? handleChange : ()=>{}}
                        paginationComponent={TableListPagination}
                        paginationServer={paginationServer && paginationServer}
                        progressPending={progressPending && progressPending}
                        paginationTotalRows={paginationTotalRows && paginationTotalRows}
                        clearSelectedRows={toggleCleared}
                    />
                    </>
                :
                <>
                    <div className="emptySet pt-3">{t('common:emptyDataSet')}</div>
                </>
            }
        </>
    );
}


export default TableList;
