export enum EventUserBadgeActionTypes {
  API_RESPONSE_SUCCESS = "@@eventBadgeUser/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@eventBadgeUser/API_RESPONSE_ERROR",
  GET_USER_BADGES = "@@eventBadgeUser/GET_USER_BADGES",
  GET_SUPPORT_USER_BADGES = "@@eventBadgeUser/GET_SUPPORT_USER_BADGES",
  GET_SUPPORT_SERVICE_BADGES = "@@eventBadgeUser/GET_SUPPORT_SERVICE_BADGES",
}

export interface EventUserBadgeState {
  cabinetItems: Array<EventUserBadgeType>;
  supportItems: Array<EventUserBadgeType>;
}

export interface EventUserBadgeType{
  type: string,
  count: number,
}
