import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks";

// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
  getCommentsUserConversations,
  toggleCommentsUserDetailsTab,
  deleteCommentsMessage,
  toggleArchiveComments, onSendCommentsMessage, receiveCommentsMessage, readCommentsMessage,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks/UserHooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";
import {MessengerMessageType} from "../../../redux/Messenger/Chat/types";
import ConversationComments from "./ConversationComments";

interface IndexProps {
  isChannel: boolean;
}

const CommentsIndex = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    chatUserDetails,
    chatUserConversations,
    isUserMessageSent,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  } = useAppSelector(state => ({
    chatUserDetails: state.MessengerComment.chatUserDetails,
    chatUserConversations: state.MessengerComment.chatUserConversations,
    isUserMessageSent: state.MessengerComment.isUserMessageSent,
    isMessageDeleted: state.MessengerComment.isMessageDeleted,
    isMessageForwarded: state.MessengerComment.isMessageForwarded,
    isUserMessagesDeleted: state.MessengerComment.isUserMessagesDeleted,
    isImageDeleted: state.MessengerComment.isImageDeleted,
  }));

  const onOpenUserDetails = () => {
    dispatch(toggleCommentsUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile } = useProfile();

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessengerMessageType | undefined
  >();
  const onSetReplyData = (reply: null | MessengerMessageType | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any) => {
    let params: any = {
      text: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      attachments: data.attachments && data.attachments,
      receiver: chatUserDetails.uuid,
      sender: userProfile.uuid,
    };
    if (replyData && replyData !== null) {
      params["replyTo"] = replyData;
    }

    if (data['attachmentFiles'] !== undefined){
      params.attachmentFiles = data['attachmentFiles'];
    }

    dispatch(onSendCommentsMessage(params));
    // if (!isChannel) {
    //   setTimeout(() => {
    //     dispatch(receiveCommentsMessage(chatUserDetails.uuid));
    //   }, 1000);
    //   setTimeout(() => {
    //     dispatch(readCommentsMessage(chatUserDetails.uuid));
    //   }, 1500);
    //   setTimeout(() => {
    //     dispatch(receiveMessageFromUser(chatUserDetails.uuid));
    //   }, 2000);
    // }
    setReplyData(null);
  };

  useEffect(() => {
    if (
      isUserMessageSent ||
      isMessageDeleted ||
      isMessageForwarded ||
      isUserMessagesDeleted ||
      isImageDeleted
    ) {
      dispatch(getCommentsUserConversations(chatUserDetails.uuid));
    }
  }, [
    dispatch,
    isUserMessageSent,
    chatUserDetails,
    isMessageDeleted,
    isMessageForwarded,
    isUserMessagesDeleted,
    isImageDeleted,
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteCommentsMessage(messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.uuid));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveComments(chatUserDetails.uuid));
  };

  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        // pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
//        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <ConversationComments
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default CommentsIndex;
