import { APIClient } from "../apiCore";
import * as url from "../urls";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getTaskTimes = (data: any) => {

    let sendData = {};
    let requestUrl = m9sGlobals.CURRENT_CABINET+url.GET_TASK_TIMES;

    if (data?.task?.uuid){
        sendData = {
            task:{
                uuid: data.task.uuid
            }
        }
        requestUrl = requestUrl.replace(/:uuid/,data.task.uuid);
    }

    return api.create(requestUrl, sendData);
};

const updateTaskTime = (data: any) => {

    let sendData = {...data};

    if (sendData?.owner){
        delete sendData.owner;
    }
    if (sendData?.actions){
        delete sendData.actions;
    }

    let requestUrl = m9sGlobals.CURRENT_CABINET+url.UPDATE_TASK_TIME;

    if (data?.task?.uuid){
        requestUrl = requestUrl.replace(/:uuid/,data.task.uuid);
    }

    return api.create(requestUrl, sendData);
};

const removeTaskTime = (data: any) => {

    let requestUrl = m9sGlobals.CURRENT_CABINET+url.REMOVE_TASK_TIME;

    if (data?.task?.uuid){
        requestUrl = requestUrl.replace(/:uuid/,data.task.uuid);
    }

    return api.create(requestUrl, data);
};

export {
    getTaskTimes,
    updateTaskTime,
    removeTaskTime
};
