import React, {useEffect, useState} from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import moment from 'moment';
import "moment/locale/ru";
import "moment/locale/en-gb";
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {OrderType} from "../../../redux/Sale/Order/types";
import * as route from "../../../api/routes";
import {Link} from "react-router-dom";
import {OrderStatus} from "../../../redux/Sale/Order/types";
import ChannelItemLine from "../../Content/PeerChannel/channelItemLine";
import {useRedux} from "../../../hooks";
import {MainRoleType} from "../../../redux/Main/Role/types";
import {CabinetRoles} from "../../../redux/Main/Login/types";
import {handleCreateNewTaskByOrder} from "./OrderActions";
import {convertToMomentLocale} from "../../../helpers/functions";

const SaleOrderColumns = ({ onDetail, onRemove }:EntityActionsHandlersType) => {

    const { t, i18n } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();

    moment.locale(convertToMomentLocale(i18n.language));

    const [isCanOrderEdit, setCanOrderEdit] = useState<boolean>(false);

    const {
        mainUser
    } = useAppSelector(state => ({
        mainUser: state.MainUser
    }));

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    useEffect(() => {

        let _isCanOrderEdit = false;

        if (mainUser?.currentUser?.roles){
            (mainUser?.currentUser?.roles || []).map((role:MainRoleType)=>{

                switch (role.code){
                    case CabinetRoles.ROLE_SALES_MANAGER:
                    case CabinetRoles.ROLE_ADMIN:
                    case CabinetRoles.ROLE_SALES_ADMIN:
                        _isCanOrderEdit=true;
                        break;
                }

                return role;
            })
        }

        setCanOrderEdit(_isCanOrderEdit);

    }, [dispatch, mainUser?.currentUser]);

    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {

                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-light btn-sm" tag="a">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            <DropdownItem tag="a" className='edit-item-btn' onClick={() => onDetail(cell)}><i
                                className="ri-eye-fill align-bottom me-2 text-muted"/> {t('sale:orderDetails')} </DropdownItem>
                            <DropdownItem tag="a" onClick={() => handleCreateNewTaskByOrder(cell, dispatch)}><i
                                className="ri-survey-line align-bottom text-muted me-2"/> {t('task:addNewTask')}</DropdownItem>
                            {isCanOrderEdit &&
                                <DropdownItem className='remove-item-btn'
                                              onClick={() => onRemove(cell)}><i
                                    className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')}
                                </DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            id: 'code',
            name: <span className='font-weight-bold fs-14'>{t('sale:code')}</span>,
            sortable: true,
            maxWidth: '50px',
            selector: (row:OrderType) => {
                return (
                    <>
                        <Link to={route.SALE_ORDER_DETAIL.replace(/:uuid/g, row.uuid)} className="fw-medium link-primary">{row.code}</Link>
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('sale:status')}</span>,
            maxWidth: '50px',
            selector: (row:OrderType) => {
                if (row.status.isSuccess === true) {
                    return <span className={"badge text-uppercase badge-soft-"+OrderStatus.FINAL.class}> {row.status.name} </span>
                } else if (row.status.isNew === true) {
                    return <span className={"badge text-uppercase badge-soft-"+OrderStatus.NEW.class}> {row.status.name} </span>
                } else if (row.status.isFailure === true) {
                    return <span className={"badge text-uppercase badge-soft-"+OrderStatus.CANCELLED.class}> {row.status.name} </span>
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('channel:channel')}</span>,
            sortable: false,
            selector: (row:OrderType) => {
                if (row?.channel?.uuid){
                    return <div className="p-1"><ChannelItemLine item={row.channel}/></div>
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('sale:customer')}</span>,
            sortable: false,
            selector: (row:OrderType) => {
                return <>{row?.customer?.uuid && <a href="#" target="_blank"><i className="ri-account-box-line fs-16 align-middle me-2"/></a>}{row.customerName}</>
            },
        },
        // {
        //     name: <span className='font-weight-bold fs-14'>{t('sale:product')}</span>,
        //     selector: (row:OrderType) => {
        //         let orderItems='';
        //
        //         (row.orderItems || []).map((item: OrderItemType, key: number)=>{
        //             // orderItems+='#'+(key+1)+' '+t('sale:plan')+': '+item.name;
        //             orderItems+='#'+(key+1)+' '+item.name;
        //             return item;
        //         });
        //
        //         return <span>{orderItems}</span>
        //     },
        // },
        {
            id: 'createdAt',
            name: <span className='font-weight-bold fs-14'>{t('sale:createdAt')}</span>,
            maxWidth: '200px',
            sortable: true,
            selector: (row:OrderType) => {
                return moment(row.createdAt).format('llll');
            },
        },
        {
            id: 'sum',
            name: <span className='font-weight-bold fs-14'>{t('sale:amount')}</span>,
            maxWidth: '100px',
            sortable: true,
            right: true,
            selector: (row:OrderType) => {
                return row.currency.currencyFormat.replace(/#/,FormatValue(row.sum))
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('sale:paymentStatus')}</span>,
            maxWidth: '150px',
            center: true,
            selector: (row:OrderType) => {
                let payments='';

                // (row.payments || []).map((item: PaymentType, key: number)=>{
                //     if (row.payments.length>1) {
                //         payments += '#' + (key + 1) + ' ' + item.paymentSystem.code + ' ';
                //     } else {
                //         payments += item.paymentSystem.code + ' ';
                //     }
                //     return item;
                // });

                return <span>{payments}  {row.isPaid === true ? <span className="badge text-uppercase badge-soft-success fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPaid')}</span> : <span className="badge text-uppercase badge-soft-danger fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPending')}</span>}</span>;
            },
        },

    ];

}

export default SaleOrderColumns;