import React from 'react';
import {Card, CardBody} from "reactstrap";
import ChannelItemBigLine from "./channelItemBigLine";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";

interface ItemProps {
    item: ContentPeerChannelType;
}

const PeerChannelSearchCard = ({ item }: ItemProps) => {

    return (
        <Card style={{maxWidth:'300px'}}>
            <CardBody>
                <ChannelItemBigLine item={item}/>
            </CardBody>
        </Card>
    );
}

export default PeerChannelSearchCard;