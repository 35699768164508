import React, {useEffect, useState} from "react";

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import {ContentTemplatePayload, ContentTemplateType} from "../redux/Content/Template/types";
import TemplateButton from "./TemplateButton";
import InputSelect from "./InputSelect";
import {useRedux} from "../hooks";
import {showRightModalContentTemplates} from "../redux/Content/Template/actions";
import '../i18/config';
import {useTranslation} from "react-i18next";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

interface InputEditorProps {
    id: string;
    placeholder: null | string
    value: null | string;
    onChange: (value: string) => void;
    maxLength?: number;
    templates?: Array<ContentTemplateType>
    isAddToTemplate?: true | boolean
    isShowEmoji?: true | boolean,
}
const InputEditorDraftArea = ({ id, placeholder, value, onChange, maxLength, templates, isAddToTemplate, isShowEmoji }: InputEditorProps) => {
    const { dispatch } = useRedux();
    const { t, i18n } = useTranslation(['template']);
    const [isShowTemplate, setShowTemplate] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(()=>{

        if (!focus) {
            if (value !== undefined && value !== null) {
                setEditorState(
                    EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            htmlToDraft(value)
                        )
                    )
                );
            }
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }

    const onChangeShowTemplate = () => {
        setShowTemplate(!isShowTemplate);
    }

    const onTemplateSelect = (item: any) => {
        if (item.value !== undefined){
            (templates || []).map((templateItem: ContentTemplateType)=>{
                if (item.value === templateItem.uuid){
                    setEditorState(
                        EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                                htmlToDraft(value + templateItem.text)
                            )
                        )
                    );
                }
                return templateItem;
            });
        }
    }

    const handleAddToTemplate = () =>{

        const newTemplate:ContentTemplateType ={
            enabled: true,
            name: '',
            text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }

        const contentTemplatePayload :ContentTemplatePayload={
            data: newTemplate,
            modalEditTitle: 'newTemplate'
        }

        dispatch(showRightModalContentTemplates(contentTemplatePayload))
    }

    const toolbar = {
        options: ['inline', 'list', 'link', isShowEmoji && 'emoji'],
        inline:{
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
        link: {
            popupClassName: 'editorLinkCont'
        },
        emoji:{
            popupClassName: 'editorEmojiCont'
        }
    }

    const onFocus = (event) => {
        setFocus(true);
    }
    const onBlur = (event, editorState) => {
        setFocus(false);
    }

    return (
      <>
          {templates && templates.length>0 &&
            <TemplateButton onChange={onChangeShowTemplate} />
          }

              <Editor
                  id={id}
                  toolbar={toolbar}
                  editorClassName={"editorDraftAreaCont"}
                  toolbarClassName={"editorDraftToolbarAreaCont"}
                  hashtag={{
                      separator: ' ',
                      trigger: '#',
                  }}
                  localization={{
                      locale: i18n.language,
                  }}
                  onEditorStateChange={onEditorStateChange}
                  editorState={editorState}
                  placeholder={placeholder && placeholder}
                  onBlur={onBlur}
                  onFocus={onFocus}
              />



          <div className="d-flex flex-row flex-nowrap flex-grow-1">
              <div className="d-flex flex-row flex-grow-1">
                  {isAddToTemplate &&
                  <a className="colorBlue fs-10" onClick={handleAddToTemplate}>+{" "}{t('template:toTemplates')}</a>
                  }
              </div>
          <div className="lengthCount text-muted fs-10 d-flex flex-row flex-nowrap justify-content-end">
              {/*{ maxLength ?*/}
              {/*    <>*/}
              {/*        <span className="colorBlue">*/}
              {/*            {text && text.length ?*/}
              {/*             <NumericFormat thousandSeparator={' '} displayType={'text'} value={text.length}/>*/}
              {/*        :*/}
              {/*        0*/}
              {/*            }*/}
              {/*        </span>*/}
              {/*        <span className="text-muted ms-2">*/}
              {/*            <NumericFormat thousandSeparator={' '} displayType={'text'} value={maxLength}/>*/}
              {/*        </span>*/}
              {/*    </>*/}
              {/*    :*/}
              {/*    <span className="colorBlue">*/}
              {/*        {text && text.length ?*/}
              {/*        <NumericFormat thousandSeparator={' '} displayType={'text'} value={text.length}/>*/}
              {/*        :*/}
              {/*        0*/}
              {/*        }*/}
              {/*    </span>*/}
              {/*}*/}
          </div>
          </div>
          {isShowTemplate &&
              <InputSelect
                  items={templates}
                  value={null}
                  placeholder={t('template:chooseTemplate')}
                  onChange={onTemplateSelect}
                  required={false}
                  readonly={false}
                  className={null}
                  isLoading={false}
              />
          }
      </>
  );
};
export default InputEditorDraftArea;
