import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {
    Label,
    Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody

} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import InputColor from "../../../components/InputColor";
import {
    getContentProjects,
    hideRightModalContentProjects,
    onSendDataContentProjects
} from "../../../redux/Content/Project/actions";
import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {ContentProjectType} from "../../../redux/Content/Project/types";
import {ContentProjectValidatorSchema} from "./validator";
import InputEmojiArea from "../../../components/InputEmojiArea";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import UpgradeFareBlock from "../../../components/UpgradeFareBlock";
import {isNumeric} from "../../../helpers/functions";

const RightModal = () => {

    const { dispatch, useAppSelector } = useRedux();
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

    const { t } = useTranslation(['common']);

    const {
        contentProjects,
        isSending,
        salePlan
    } = useAppSelector(state => ({
        contentProjects: state.ContentProjects,
        isSending: state.ContentProjects.isSending,
        salePlan: state.SalePlan
    }));

    const uuid = contentProjects.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<ContentProjectType>(contentProjects.selectedItem);

    useEffect(() => {
        checkFareRestriction();
        setFormValues(contentProjects.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid]);


    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            setCurrentPlan({...salePlan.currentPlan});
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        checkFareRestriction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const handleClose = () => dispatch(hideRightModalContentProjects());

    const onDataSubmit = (formData:ContentProjectType) => {
        let data: any = {};

        if (formData.description) {
            data["text"] = formData.description;
        }
        if (formData.name) {
            data["name"] = formData.name;
        }

        data["enabled"] = formData.enabled;

        if (formData.color) {
            data["color"] = formData.color;
        }

        if (uuid) {
            data["uuid"] = uuid;
        }

        let params: ContentProjectType = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            name: data.name && data.name,
            description: data.text && data.text,
            color: data.color && data.color,
        };

        dispatch(onSendDataContentProjects(params));

        setTimeout(() => {
            dispatch(getContentProjects());
        }, 1000);
    };

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.CONTENT_MAX_PROJECTS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(true);
        } else if (contentProjects) {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value) === (contentProjects.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    //edit only
                    if (uuid === undefined || uuid === null) {
                        setUpgradeFare(true);
                    } else {
                        setUpgradeFare(false);
                    }
                } else if (parseInt(restrictionValue.value) < (contentProjects.items || []).filter((item: ContentProjectType) => item.enabled).length) {
                    setUpgradeFare(true);
                } else {
                    setUpgradeFare(false);
                }
            } else {
                setUpgradeFare(true);
            }
        }
    }


    return (
        <>
            <Offcanvas
                direction="end"
                isOpen={contentProjects.rightModalShow}
                id="projectEdit"
                toggle={handleClose}
            >
                { upgradeFare ?
                    <UpgradeFareBlock onClose={handleClose}/>
                    :
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ContentProjectValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('project:'+contentProjects.modalEditTitle)}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                            <Row>
                                <div className="d-flex">
                                    <div className="mb-3 d-flex flex-column me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            className="ms-1 form-switch-lg"
                                            required={false}
                                        />
                                    </div>
                                    <div className="mb-3 d-flex flex-column align-items-center">
                                        <div className="colorInputMt2px">
                                        <Field
                                            id="form-color-input"
                                            name="color"
                                            placeholder={t('common:color')}
                                            component={InputColor}
                                            value = {getFieldProps('color').value}
                                            onChange={(color:string) => {
                                                setFieldValue('color', color);
                                            }}
                                            required={false}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('common:name')}</Label>
                                    <Field
                                        id="form-title-input"
                                        name="name"
                                        placeholder={t('project:enterProjectName')}
                                        component={InputText}
                                        value = {getFieldProps('name').value}
                                        onChange={(name:string) => {
                                            setFieldValue('name', name);
                                        }}
                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors.name)}
                                        </div>
                                    ) :  touched.name && !errors.name ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>

                            <Row>
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="form-description-input">{t('common:description')}</Label>
                                    <div className="textAreaEmojiBlock">

                                        <Field
                                            id="form-text-input"
                                            name="description"
                                            component={InputEmojiArea}
                                            value = {getFieldProps('description').value}
                                            onChange={(text:string) => {
                                                setFieldValue('description', text);
                                            }}
                                            required={false}
                                            placeholder={t('project:enterProjectDescription')}
                                        />
                                    </div>
                                </div>
                            </Row>
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                                <Button variant="light" onClick={handleClose} className="w-100">
                                    {t('common:close')}
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                        disabled={isSending}
                                        className="btn btn-primary w-100 chat-send waves-effect waves-light"
                                >
                                    {t('common:save')}
                                    {isSending &&
                                        <ButtonSpinner/>
                                    }
                                </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>
                }
            </Offcanvas>
        </>
    );
}

export default RightModal;