import {DiscountType} from "../Discount/types";
import {RequestMetadataType} from "../../Main/RequestMetadata/types";

export enum CouponActionTypes {
    API_RESPONSE_SUCCESS = "@@coupon/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@coupon/API_RESPONSE_ERROR",
    CHECK_M9S_COUPON = "@@coupon/CHECK_M9S_COUPON",
    CLEAR_M9S_COUPON = "@@coupon/CLEAR_M9S_COUPON",
}

export interface CouponState {
    selectedItem: CouponType;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
    requestMetadata? : RequestMetadataType;
}


export interface CouponType {
    uuid?: string;
    code?: string;
    enabled?: boolean;
    order?: number;
    maxUses?: number;
    usedCount?: number;
    status?: CouponStatus;
    activeFrom?: string;
    activeTo?: string;
    discount?: DiscountType;
}

export enum CouponStatus {
    STATUS_SUCCESS = "F",
    STATUS_NEW = "N",
    STATUS_ERROR = "E",
    STATUS_READY = 'R',
    STATUS_CANCELLED = 'C',
}