import React, {useEffect, useState} from 'react';
import {Col, Alert} from "reactstrap";
import {Link} from 'react-router-dom'
import CoverAuth from "./CoverAuth";
import {LordIcon} from "../../../components/LordIcon";
import {useRedux} from "../../../hooks";
import ResetForm from "./ResetForm";
import ResetSuccess from "../../../components/ResetSuccess";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import * as route from "../../../api/routes";

const CoverReset = () => {

    const { t } = useTranslation(['auth']);

    const { dispatch, useAppSelector } = useRedux();

    const [resetComplete, setResetComplete] = useState<boolean>(false);

    const {
        mainUser,
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
    }));

    useEffect(() => {
        if (mainUser !== undefined && mainUser.isResetComplete !== undefined) {
            setResetComplete(mainUser.isResetComplete);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainUser?.isResetComplete]);

    return (
        <>
            <CoverAuth title={t('auth:forgotPassword')+' | Marketcells.io - digital marketing platform'}>
                <Col lg={6}>
                    <CoverLangSwitch/>
                    <div className="p-lg-5 p-4">
                        <div>
                            <h5 className="text-primary">{t('auth:forgotPassword')}?</h5>
                            <p className="text-muted">{t('auth:resetPasswordMarketcells')}</p>
                        </div>

                        {!resetComplete ?
                            <>
                                <div className="mt-2 text-center">
                                    <LordIcon
                                        src="https://cdn.lordicon.com/rhvddzym.json"
                                        trigger="loop"
                                        colors={{primary: '#0ab39c',secondary:'#32b67d'}}
                                        size={120}
                                    />
                                </div>

                                <Alert className="alert-borderless alert-warning text-center mb-2" role="alert">
                                    {t('auth:resetPasswordMessage1')}!
                                </Alert>

                                <div className="mt-4">
                                    <ResetForm/>
                                </div>

                                <div className="mt-5 text-center">
                                    <p className="mb-0">{t('auth:IrememberMyPassword')}... <Link to={route.MAIN_AUTH_LOGIN} className="fw-bold text-primary text-decoration-underline">{t('auth:clickHere')}</Link>
                                    </p>
                                </div>
                            </>
                            :
                            <ResetSuccess/>
                        }
                    </div>
                </Col>
            </CoverAuth>
        </>
    );
};

export default CoverReset;