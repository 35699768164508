import React, { useState, useEffect, useRef } from 'react';
import {useRedux} from "../../../hooks";
import {Label, Row, Col} from 'reactstrap';
import { Link } from 'react-router-dom'

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MainUserType} from "../../../redux/Main/User/types";
import ListItemV2 from "../User/ListItemV2";
import {changeLoginBy, loginUserByDevice} from "../../../redux/Main/Login/actions";
import {LoginByDeviceType, LoginByTypes} from "../../../redux/Main/Login/types";

interface ItemProps {
    deviceUsers: MainUserType[];
}

const LoginByDevice = ({deviceUsers}:ItemProps) => {

    const { t } = useTranslation(['user','validator']);

    const { dispatch, useAppSelector } = useRedux();

    const { mainDevice, isSending } = useAppSelector(state => ({
        mainDevice: state.MainDevice,
        isSending: state.Login.isLoading
    }));

    const handleDeviceLogin = (item:MainUserType) => {

        const data: LoginByDeviceType = {
            user: item,
            device: mainDevice.current
        }

        dispatch(loginUserByDevice(data));
    }

    const handleBackToLogin = () => {
        dispatch(changeLoginBy(LoginByTypes.PASSWORD));
    }

    return (
        <>
            <Row>
            {(deviceUsers || []).map((item:MainUserType)=>{
                return(
                <Col xxl={12}
                     sm={12}
                     key={'deviceUser_'+item.uuid}
                >
                    <ListItemV2
                        item={item}
                        buttonTitle={t('user:login')}
                        buttonClass={"primary"}
                        buttonIcon={"ri-arrow-right-line"}
                        onClick={handleDeviceLogin}
                        isProcessing={isSending}
                    />
                </Col>
                )
            })
            }
            </Row>
            <div className="mt-4">
                <Link to={"#"} onClick={handleBackToLogin} className="btn btn-outline-primary w-100">{t('auth:backToLogin')}</Link>
            </div>
        </>
    );
}

export default LoginByDevice;