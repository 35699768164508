import {EntityActionsType} from "../../types";

export enum PeerActivityModeActionTypes {
  API_RESPONSE_SUCCESS = "@@contentPeerActivityMode/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentPeerActivityMode/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_ACTIVITY_MODES = "@@contentPeerActivityMode/GET_CONTENT_PEER_ACTIVITY_MODES",
}

export interface PeerActivityModeState {
  items: Array<any>;
}

export interface PeerActivityModeType{
  uuid: string,
  enabled: boolean,
  name?: string,
  code: string,
  color: string,
  order: number,
  actions?: EntityActionsType
}

export interface PeerActivityModePayload {
  data: Array<any>;
}

export enum PeerActivityModeCode {
  ACTIVITY = "post",
  STORY = "story",
  BIO = "bio",
  STAT = "stat",
  SHORTS = "shorts",
}