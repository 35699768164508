import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { SalePlanActionTypes } from "./types";
import { salePlanApiResponseSuccess, salePlanApiResponseError } from "./actions";

import {
  getSaleCurrentPlan as getSaleCurrentPlanApi,
  getSalePlans as getSalePlansApi
} from "../../../api";

function* getSaleCurrentPlan() {
  try {
    const response: Promise<any> = yield call(getSaleCurrentPlanApi);
    yield put(salePlanApiResponseSuccess(SalePlanActionTypes.GET_SALE_CURRENT_PLAN, response));
  } catch (error: any) {
    yield put(salePlanApiResponseError(SalePlanActionTypes.GET_SALE_CURRENT_PLAN, error));
  }
}

function* getSalePlans() {
  try {
    const response: Promise<any> = yield call(getSalePlansApi);
    yield put(salePlanApiResponseSuccess(SalePlanActionTypes.GET_SALE_PLANS, response));
  } catch (error: any) {
    yield put(salePlanApiResponseError(SalePlanActionTypes.GET_SALE_PLANS, error));
  }
}

export function* watchGetSaleCurrentPlan() {
  yield takeEvery(SalePlanActionTypes.GET_SALE_CURRENT_PLAN, getSaleCurrentPlan);
}

export function* watchGetSalePlans() {
  yield takeEvery(SalePlanActionTypes.GET_SALE_PLANS, getSalePlans);
}

function* salePlanSaga() {
  yield all([
    fork(watchGetSaleCurrentPlan),
    fork(watchGetSalePlans)
  ]);
}

export default salePlanSaga;
