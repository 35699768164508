import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//i18
import '../i18/config';
import {useTranslation} from "react-i18next";
import {MediaFilesType,MediaFileType} from "../data";


interface MediaProps {
  media: MediaFilesType;
  limit: number;
}

interface MediaItemProps {
  mediaItem: MediaFileType;
  hasMore: boolean;
  remainingItem: number;
}
const MediaItem = ({ mediaItem, hasMore, remainingItem }: MediaItemProps) => {
  return (
    <div className="media-img-list">
      <Link to="#">
        <img src={mediaItem.url} alt="media img" className="img-fluid" />
        {hasMore && <div className="bg-overlay">+ {remainingItem}</div>}
      </Link>
    </div>
  );
};
const Media = ({ media, limit }: MediaProps) => {
  const [mediaList, setMediaList] = useState<MediaFileType[]>([]);
  useEffect(() => {
    if (media && media.items) setMediaList(media.items);
  }, [media]);
  const total = mediaList.length;
  const remainingItem = total - limit;

  const { t } = useTranslation(['common']);

  return (
      <div className="border-top border-top-dashed p-3">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h5 className="fs-15 mb-3">{t('common:media')}</h5>
        </div>
        <div className="flex-shrink-0">
          <Link to="#" className="font-size-12 d-block mb-2">
            {t('common:showAll')}
          </Link>
        </div>
      </div>
      <div className="profile-media-img">
        {media
          ? (mediaList || []).map(
              (mediaItem: MediaFileType, key: number) => {
                const hasMore: boolean = key === limit;

                if (key <= limit) {
                  return (
                    <MediaItem
                      mediaItem={mediaItem}
                      key={key}
                      hasMore={hasMore}
                      remainingItem={remainingItem}
                    />
                  );
                } else return null;
              }
            )
          :  t('common:noMediaFiles') }
      </div>
    </div>
  );
};
export default Media;
