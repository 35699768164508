import {EntityActionsType} from "../../types";
import {EventTypeType} from "../EventType/types";
import {MainUserType} from "../../Main/User/types";

export enum EventSubscribeUserActionTypes {
  API_RESPONSE_SUCCESS = "@@eventSubscribeUser/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@eventSubscribeUser/API_RESPONSE_ERROR",
  GET_USER_EVENT_SUBSCRIPTIONS = "@@eventSubscribeUser/GET_USER_EVENT_SUBSCRIPTIONS",
  UPDATE_USER_EVENT_SUBSCRIPTION = "@@eventSubscribeUser/UPDATE_USER_EVENT_SUBSCRIPTION",

}

export interface EventSubscribeUserState {
  items: Array<any>;
}

export interface EventSubscribeUserType{
  uuid: string,
  enabled: boolean,
  isSubscribed: boolean,
  eventType: EventTypeType
  subscriber: MainUserType
  actions?: EntityActionsType
}

export interface EventSubscribeUserPayload {
  data: Array<any>;
}
