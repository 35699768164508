import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

// import PersonalInfo from "./PersonalInfo";

import {useTranslation} from "react-i18next";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {useRedux} from "../../hooks";

import ConversationUser from "./ConversationUser/indexComments";
import UserProfileDetails from "./UserProfileDetails/indexComments";
import Welcome from "./ConversationUser/Welcome";
import {useConversationUserType} from "../../hooks/UserHooks";
import ChatsIndex from "./Chats";
import {
  changeSelectedChat,
  getChannelDetails, getChannels,
  getChatUserConversations,
  getChatUserDetails,
  readConversation
} from "../../redux/Messenger/Chat/actions";
import {getMainUsers} from "../../redux/Main/User/actions";
import CommentsIndex from "./Chats/indexComments";
import {
  changeSelectedCommentsChat,
  getCommentsUserConversations,
  getCommentsChannelDetails,
  getCommentsChannels
} from "../../redux/Messenger/Comment/actions";
import {SalePlanParamValueType, SalePlanType} from "../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../redux/Sale/License/types";
import {convertToMomentLocale, isNumeric} from "../../helpers/functions";
import UpgradeFarePage from "../../components/UpgradeFarePage";
import {getEventUserBadges} from "../../redux/Event/Badge/actions";
import {MessengerChannelType} from "../../redux/Messenger/Chat/types";

const MessengerCommentIndex = () => {

  const ref = useRef();

  const { t, i18n } = useTranslation(['common']);

  moment.locale(convertToMomentLocale(i18n.language));

  const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);

  const { dispatch, useAppSelector } = useRedux();

  const {
    mainCabinet,
    messengerChat,
    salePlan
  } = useAppSelector(state => ({
    mainCabinet: state.MainCabinet,
    messengerChat: state.MessengerComment,
    salePlan: state.SalePlan
  }));

  const { isChannel } = useConversationUserType();

  const onSelectChat = (id: string | number, isChannel?: boolean) => {
    if (isChannel) {
      dispatch(getCommentsChannelDetails(id));
    } else {
      dispatch(getChatUserDetails(id));
    }

    dispatch(getCommentsUserConversations(id));
    dispatch(changeSelectedCommentsChat(id));

    const currentChannel = (messengerChat.channels || []).filter((item:MessengerChannelType)=>{return item.uuid === id}).pop();

    if (currentChannel?.uuid !== undefined && currentChannel?.uuid !== null && currentChannel?.unreadMessagesCount>0){
      dispatch(readConversation(id));
      setTimeout(() => {
        dispatch(getCommentsChannels({page:1}));
        dispatch(getEventUserBadges());
      },2000);
    }

  };

  useEffect(() => {

    if (mainCabinet?.currentCabinet && mainCabinet?.currentCabinet?.uuid) {
      dispatch(getMainUsers({maxResults: 1000, cabinetUuid: mainCabinet?.currentCabinet && mainCabinet.currentCabinet.uuid}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mainCabinet?.currentCabinet?.uuid]);

  useEffect(() => {
    if (salePlan?.currentPlan?.uuid !== undefined) {
      if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
        setCurrentPlan({...salePlan.currentPlan});
      }
    } else {
      setCurrentPlan(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salePlan?.currentPlan]);

  useEffect(() => {
    if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
      checkFareRestriction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan]);

  const checkFareRestriction = () => {

    const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
          return param.productParam.code === LicenseProductParam.CONTENT_COMMENTS_VIEW
        }
    )).pop();

    if (restrictionValue === undefined){
      setUpgradeFare(true);
    } else {
      if (isNumeric(restrictionValue.value)) {
        if (parseInt(restrictionValue.value)>0){
          setUpgradeFare(false);
        } else {
          setUpgradeFare(true);
        }
      } else if (restrictionValue.value === "Y") {
        setUpgradeFare(false);
      } else {
        setUpgradeFare(true);
      }
    }
  }

  return (
    <React.Fragment>
      { upgradeFare ?
          <>
            <div className="d-flex flex-row flex-grow-1">
              <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionCommentText')}
              </div>
            </div>
          <UpgradeFarePage/>
            </>
          :
          <>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="chat-leftsidebar">
              <CommentsIndex onSelectChat={onSelectChat}/>
            </div>

            <div
                className={classnames("user-chat", "w-100", "overflow-hidden", {
                  "user-chat-show": messengerChat.selectedChat,
                })}
                id="user-chat"
            >
              <div className="user-chat-overlay" id="user-chat-overlay"></div>
              {messengerChat.selectedChat !== null ? (
                  <div className="chat-content d-lg-flex">
                    <div className="w-100 overflow-hidden position-relative">
                      <ConversationUser isChannel={isChannel} />
                    </div>
                      <UserProfileDetails isChannel={isChannel} onSelectChat={onSelectChat}/>
                  </div>
              ) : (
                  <Welcome />
              )}
            </div>

          </div>
        </>
      }
    </React.Fragment>
  );
};

export default MessengerCommentIndex;
