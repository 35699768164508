import {RequestMetadataType} from "../../Main/RequestMetadata/types";
import {ContentConnectorType} from "../../Content/Connector/types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";

export enum AITextGenerateActionTypes {
  API_RESPONSE_SUCCESS = "@@AITextGenerate/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@AITextGenerate/API_RESPONSE_ERROR",
  GET_ACTIVE_CONNECTORS = "@@AITextGenerate/GET_ACTIVE_CONNECTORS",
  GET_IDEAS_CHANNEL = "@@AITextGenerate/GET_IDEAS_CHANNEL",
  CHANGE_SELECTED_ITEM_CONNECTOR = "@@AITextGenerate/CHANGE_SELECTED_ITEM_CONNECTOR",
  SEND_TEXT_GENERATE_REQUEST = "@@AITextGenerate/SEND_TEXT_GENERATE_REQUEST",
  RECEIVE_TEXT_GENERATE_RESPONSE = "@@AITextGenerate/RECEIVE_TEXT_GENERATE_RESPONSE",
  RIGHT_MODAL_SHOW = "@@AITextGenerate/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@AITextGenerate/RIGHT_MODAL_HIDE",
  REMOVE_SELECTED_ITEM_RESULT_VARIANT = "@@AITextGenerate/REMOVE_SELECTED_ITEM_RESULT_VARIANT",
  EDIT_SELECTED_ITEM_RESULT_VARIANT = "@@AITextGenerate/EDIT_SELECTED_ITEM_RESULT_VARIANT",
  SET_INITIALIZED = "@@AITextGenerate/SET_INITIALIZED",
}

export interface AITextGenerateStateType {
  connectors: Array<ContentConnectorType>
  items: Array<AITextGenerateType>;
  rightModalShow : boolean;
  selectedItem?: AITextGenerateType;
  modalEditTitle: string;
  isNeedReload: boolean;
  isSending: boolean;
  requestMetadata? : RequestMetadataType;
  isConnectorsFetched: boolean;
  isConnectorsLoading: boolean;
  ideasChannel?:ContentPeerChannelType;
  isInitialized:boolean;
  isOpenSettings:boolean;
}

export interface AITextGenerateType {
  id?: number;
  uuid?: string;
  enabled?: boolean;
  action: string;
  generateParams: Array<AITextGenerateParamType>;
  prompt?: string;
  text?: string;
  textFormat: string;
  model?: string;
  dateTime?: string,
  connector?: ContentConnectorType
}

export interface AITextGenerateParamType {
  id?: number;
  uuid?: string;
  enabled?: boolean;
  sourceLanguage?: string;
  targetLanguage?: string;
  maxLength?: number;
  generateCount?: number;
  model?: string;
  resultItems?: Array<AITextGenerateResultType>;
  dateTime?: string,
}

export interface AITextGenerateResultType {
  id?: number;
  uuid?: string;
  enabled?: boolean;
  idRemote?: string;
  text?: string;
  textFormat?: string;
  dateTime?: string,
}


export enum AITextGenerateFormat {
  FORMAT_HTML = 'html',
  FORMAT_TEXT = 'text'
}

export enum AITextChatGPTModels {
  BASE_MODEL = 'text-davinci-003'
}

export enum AITextBardModels {
  BASE_MODEL = 'base'
}

export enum AITextGenerateAction {
  TRANSLATE_ACTION = 'translate',
  REWRITE_ACTION = 'rewrite',
  GENERATE_ACTION = 'generate',
  GENERATE_TITLE_ACTION = 'generateTitle',
  PREDICT_ACTION = 'predict',
  PREDICT_TAG_ACTION = 'predictTag'
}

export interface AITextGeneratePayload {
  data: AITextGenerateType;
  modalEditTitle? : string;
}

export enum AITextGenerateLength {
  SMALL = 300,
  MEDIUM = 500,
  LARGE = 1000,
}

export interface AITextGenerateLengthType {
  code: string;
  value : number;
  description? : string;
}

export interface AITextGenerateActionType {
  code: string;
  value? : string;
  description? : string;
}

export interface AIActiveConnectorsPayloadType {
  handlerType: string;
}

export const AITextGenerateLengthItems: AITextGenerateLengthType[] = [
  {
    code: 'small',
    value: AITextGenerateLength.SMALL
  },
  {
    code: 'medium',
    value: AITextGenerateLength.MEDIUM
  },
  {
    code: 'large',
    value: AITextGenerateLength.LARGE
  }
]

export const AITextGenerateActionItems: AITextGenerateActionType[] = [
  {
    code: AITextGenerateAction.GENERATE_ACTION,
    description: 'ai:givenText'
  },
  {
    code: AITextGenerateAction.REWRITE_ACTION,
    description: 'ai:anyText'
  },
  {
    code: AITextGenerateAction.TRANSLATE_ACTION,
    description: 'ai:anyText'
  }
]