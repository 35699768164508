//constants
import {
  layoutTypes,
  leftSidebarTypes,
  layoutModeTypes,
  layoutWidthTypes,
  layoutPositionTypes,
  topbarThemeTypes,
  leftsidbarSizeTypes,
  leftSidebarViewTypes,  
  leftSidebarImageTypes  
} from "../../../components/Constants/layout";


import {LayoutActionTypes, MainSettingsState} from "./types";
import {getCurrentSettings, setCurrentSettings} from "../../../helpers/functions";
import {updateUserCabinetSettings} from "../../../api";
import {MenuItem} from "../../../layouts/LayoutMenuData";


let initialSettingState = getCurrentSettings();
let INIT_STATE:MainSettingsState;

if (initialSettingState !== undefined && initialSettingState !== null){
  INIT_STATE = {
    ...initialSettingState,
    isOpen: false
  };
} else {
  INIT_STATE = {
    layoutType: layoutTypes.TWOCOLUMN,
    leftSidebarType: leftSidebarTypes.LIGHT,
    layoutModeType: layoutModeTypes.LIGHTMODE,
    layoutWidthType: layoutWidthTypes.FLUID,
    layoutPositionType: layoutPositionTypes.FIXED,
    topbarThemeType: topbarThemeTypes.LIGHT,
    leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
    leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
    leftSidebarImageType: leftSidebarImageTypes.NONE,
    defaultDashboard: MenuItem.DASHBOARD_CONTENT_MARKETING,
    userName:'',
    userDescription:'',
    position: '',
    isOpen: false,
    isNeedUpdateCabinetSettings: false
  };
}
const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LayoutActionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload,
      };

    case LayoutActionTypes.CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSidebarType: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidthType: action.payload,
      };

    case LayoutActionTypes.CHANGE_LAYOUT_POSITION:
      return {
        ...state,
        layoutPositionType: action.payload,
      };

    case LayoutActionTypes.CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarThemeType: action.payload,
      };

    case LayoutActionTypes.CHANGE_SIDEBAR_SIZE_TYPE:
      return {
        ...state,
        leftsidbarSizeType: action.payload,
      };

    case LayoutActionTypes.CHANGE_SIDEBAR_VIEW:
      return {
        ...state,
        leftSidebarViewType: action.payload,
      }; 
      
     case LayoutActionTypes.CHANGE_SIDEBAR_IMAGE_TYPE:
      return {
        ...state,
        leftSidebarImageType: action.payload,
      };
     case LayoutActionTypes.CHANGE_DEFAULT_DASHBOARD:
      return {
        ...state,
        defaultDashboard: action.payload,
      };
     case LayoutActionTypes.CHANGE_USER_NAME:

       state = {
         ...state,
         userName: action.payload,
       }

       setCurrentSettings(state);
       updateUserCabinetSettings(state);

      return {...state,
        isNeedUpdateCabinetSettings: true
      };
    case LayoutActionTypes.CHANGE_USER_DESCRIPTION:
      return {
        ...state,
        userDescription: action.payload,
      };
      case LayoutActionTypes.RESET_VALUE:
      return {
        ...state,
        resetValue: INIT_STATE,
      };
      case LayoutActionTypes.SAVE_VALUES:

        setCurrentSettings(state);
        updateUserCabinetSettings(state);

        return {
          ...state,
          isNeedUpdateCabinetSettings: true
        };
      case LayoutActionTypes.SET_VALUES:

        state = {
          ...state,
          ...INIT_STATE
        };

        if (action.payload?.layoutType !== undefined && action.payload?.layoutType !== null){
          state = {
            ...state,
            layoutType: action.payload?.layoutType
          };
        }
        if (action.payload?.leftSidebarType !== undefined && action.payload.leftSidebarType !== null){
          state = {
            ...state,
            leftSidebarType: action.payload.leftSidebarType
          };
        }
        if (action.payload?.layoutModeType !== undefined && action.payload.layoutModeType !== null){
          state = {
            ...state,
            layoutModeType: action.payload.layoutModeType
          };
        }
        if (action.payload?.layoutWidthType !== undefined && action.payload.layoutWidthType !== null){
          state = {
            ...state,
            layoutWidthType: action.payload.layoutWidthType
          };
        }
        if (action.payload?.layoutPositionType !== undefined && action.payload.layoutPositionType !== null){
          state = {
            ...state,
            layoutPositionType: action.payload.layoutPositionType
          };
        }
        if (action.payload?.topbarThemeType !== undefined && action.payload.topbarThemeType !== null){
          state = {
            ...state,
            topbarThemeType: action.payload.topbarThemeType
          };
        }
        if (action.payload?.leftsidbarSizeType !== undefined && action.payload.leftsidbarSizeType !== null){
          state = {
            ...state,
            leftsidbarSizeType: action.payload.leftsidbarSizeType
          };
        }
        if (action.payload?.leftSidebarViewType !== undefined && action.payload.leftSidebarViewType !== null){
          state = {
            ...state,
            leftSidebarViewType: action.payload.leftSidebarViewType
          };
        }
        if (action.payload?.leftSidebarImageType !== undefined && action.payload.leftSidebarImageType !== null){
          state = {
            ...state,
            leftSidebarImageType: action.payload.leftSidebarImageType
          };
        }
        if (action.payload?.defaultDashboard !== undefined && action.payload.defaultDashboard !== null){
          state = {
            ...state,
            defaultDashboard: action.payload.defaultDashboard
          };
        }
        if (action.payload?.userName !== undefined && action.payload.userName !== null){
          state = {
            ...state,
            userName: action.payload.userName
          };
        }
        if (action.payload?.position !== undefined && action.payload.position !== null){
          state = {
            ...state,
            position: action.payload.position
          };
        }
        if (action.payload?.userDescription !== undefined && action.payload.userDescription !== null){
          state = {
            ...state,
            userDescription: action.payload.userDescription
          };
        }

        setCurrentSettings(state);

        return {
          ...state,
          isNeedUpdateCabinetSettings: false
        };
    case LayoutActionTypes.TOGGLE_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen
      };
    case LayoutActionTypes.CLEAR_UPDATE_CABINET_SETTINGS:
      return {
        ...state,
        isNeedUpdateCabinetSettings: false
      };
    default:
      return state;
  }
};

export default Layout;
