import {
  AIActiveConnectorsPayloadType,
  AITextGenerateActionTypes,
  AITextGeneratePayload, AITextGenerateResultType, AITextGenerateType,
} from "./types";
import {ContentConnectorType} from "../../Content/Connector/types";
import {RequestMetadataType} from "../../Main/RequestMetadata/types";
import ContentConnectorHandler from "../../Content/ConnectorHandler/reducer";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {PeerActivityActionTypes} from "../../Content/PeerActivity/types";

export const aiTextGenerateApiResponseSuccess = (actionType: string, data: any) => ({
  type: AITextGenerateActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const aiTextGenerateApiResponseError = (actionType: string, error: string) => ({
  type: AITextGenerateActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const showRightModalAITextGenerate= (data: AITextGeneratePayload) => ({
  type: AITextGenerateActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalAITextGenerate= () => ({
  type: AITextGenerateActionTypes.RIGHT_MODAL_HIDE,
});

export const getActiveConnectorsAITextGenerate= (data: AIActiveConnectorsPayloadType) => ({
  type: AITextGenerateActionTypes.GET_ACTIVE_CONNECTORS,
  payload: data,
});

export const changeSelectedItemConnectorAITextGenerate= (data: ContentConnectorType) => ({
  type: AITextGenerateActionTypes.CHANGE_SELECTED_ITEM_CONNECTOR,
  payload: data,
});

export const sendAITextGenerateRequest= (data: AITextGenerateType) => ({
  type: AITextGenerateActionTypes.SEND_TEXT_GENERATE_REQUEST,
  payload: data,
});

export const receiveAITextGenerateResponse= (data: AITextGenerateType, requestMetadata: RequestMetadataType) => ({
  type: AITextGenerateActionTypes.RECEIVE_TEXT_GENERATE_RESPONSE,
  payload: {data: data, requestMetadata: requestMetadata},
});

export const removeSelectedItemResultVariant= (data: AITextGenerateResultType) => ({
  type: AITextGenerateActionTypes.REMOVE_SELECTED_ITEM_RESULT_VARIANT,
  payload: data,
});
export const editSelectedItemResultVariant= (data: AITextGenerateResultType) => ({
  type: AITextGenerateActionTypes.EDIT_SELECTED_ITEM_RESULT_VARIANT,
  payload: data,
});

export const getIdeasPeerChannel= () => ({
  type: AITextGenerateActionTypes.GET_IDEAS_CHANNEL,
  payload: {idPeer: 'ideas', handlerCode: connectorHandlerCode.CONNECTOR_HANDLER_IDEAS},
});

export const setAITextGenerateInitialized = () => ({
  type: AITextGenerateActionTypes.SET_INITIALIZED,
});
