import {HelperItemType} from "../../Helper/HelperItem/types";
import {ContentProjectType} from "../../Content/Project/types";
import {ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {DataSetOrderType} from "../../Main/common";
import {CurrencyType} from "../../Sale/Currency/types";
import {ProductType} from "../../Sale/Product/types";
import {CustomerType} from "../../Sale/Customer/types";
import {OrderType} from "../../Sale/Order/types";

export enum DashboardSaleActionTypes {
  API_RESPONSE_SUCCESS = "@@dashboardSale/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@dashboardSale/API_RESPONSE_ERROR",
  SET_PERIOD = "@@dashboardSale/SET_PERIOD",
  GET_STAT_ORDERS_TILES  = "@@dashboardSale/GET_STAT_ORDERS_TILES",
  GET_STAT_ORDERS_COUNTRIES_TILES  = "@@dashboardSale/GET_STAT_ORDERS_COUNTRIES_TILES",
  GET_STAT_ORDERS_CURRENCIES_TILES  = "@@dashboardSale/GET_STAT_ORDERS_CURRENCIES_TILES",
  GET_STAT_ORDERS_STATUSES_TILES  = "@@dashboardSale/GET_STAT_ORDERS_STATUSES_TILES",
  GET_STAT_ORDERS_BEST_PRODUCTS_TILES  = "@@dashboardSale/GET_STAT_ORDERS_BEST_PRODUCTS_TILES",
  GET_STAT_ORDERS_BEST_CHANNELS_TILES  = "@@dashboardSale/GET_STAT_ORDERS_BEST_CHANNELS_TILES",
  GET_STAT_ORDERS_BEST_CUSTOMERS_TILES  = "@@dashboardSale/GET_STAT_ORDERS_BEST_CUSTOMERS_TILES",
  GET_STAT_ORDERS_12MONTH_TILES  = "@@dashboardSale/GET_STAT_ORDERS_12MONTH_TILES",
  GET_STAT_ORDERS_WEEKDAYS_TILES  = "@@dashboardSale/GET_STAT_ORDERS_WEEKDAYS_TILES",
  GET_STAT_RECENT_ORDERS  = "@@dashboardSale/GET_STAT_RECENT_ORDERS",
  SELECT_CURRENCY  = "@@dashboardSale/SELECT_CURRENCY",
}

export interface DashboardSaleState {
  tiles: Array<StatTileType>
  countriesTiles: Array<StatTileType>
  currenciesTiles: Array<StatTileType>
  statusesTiles: Array<StatTileType>
  bestProductsTiles: Array<StatOrderTileType>
  bestProducts: Array<ProductType>
  bestChannelsTiles: Array<StatOrderTileType>
  bestChannels: Array<ContentPeerChannelType>
  bestCustomersTiles: Array<StatOrderTileType>
  bestCustomers: Array<CustomerType>
  recentOrders: Array<OrderType>
  last12monthTiles: Array<StatOrderTileType>
  weekDaysTiles: Array<StatOrderTileType>
  periodFrom: number,
  selectedDate?: number,
  periodTo?: number,
  eTagOrders?: string,
  isFetched: boolean;
  isFetchedOrdersTiles: boolean;
  isFetchedCountriesTiles: boolean
  isFetchedCurrenciesTiles: boolean
  isFetchedStatusesTiles: boolean
  isFetchedBestProducts: boolean
  isFetchedBestChannels: boolean
  isFetchedBestCustomers: boolean
  isFetchedLast12monthTiles: boolean
  isFetchedWeekDaysTiles: boolean
  isFetchedRecentOrders: boolean
  isLoading: boolean;

  selectedCurrency?: CurrencyType;
}

export interface StatTileType{
  label?: string;
  value?: StatTileValueType;
  helperItem?: HelperItemType;
  isLoading?: boolean;
  type?:string;
}

export interface StatTileValueType{
  last?: number,
  first?: number,
  current?: number,
}

export interface StatOrderPayloadType{
  project?: ContentProjectType,
  channel?: ContentPeerChannelType
  currency?: CurrencyType
  periodFrom?: number,
  periodTo?: number,
  limit?: number,
  order?: DataSetOrderType,
  requestUuid?: string
}


export interface StatOrderTileType{
  label?: string;
  value?: StatOrderTileValueType;
  helperItem?: HelperItemType;
  isLoading?: boolean;
  type?:string;
  entity?: any
}

export interface StatOrderTileValueType{
    order?: StatTileValueType;
    count?: StatTileValueType;
    amount?: StatTileValueType;
    month?:StatTileValueType;
}