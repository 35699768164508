import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {createPaginationQuery} from "../../helpers/functions";
import {MainMetaType} from "../../redux/Main/common";

const api = new APIClient();

const getSaleCustomers = (data:MainMetaType) => {
    const query = createPaginationQuery(data)
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_CUSTOMERS+"?"+query);
};

const sendDataSaleCustomer = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_CUSTOMER, data);
};

const removeSaleCustomer = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_CUSTOMER, data);
};

const searchSaleCustomerFromRemote = (data: any) => {

    const sendData = {
        searchPhrase: data.inputValue,
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.SEARCH_SALE_CUSTOMER, sendData);
};

export {
    getSaleCustomers,
    sendDataSaleCustomer,
    removeSaleCustomer,
    searchSaleCustomerFromRemote
};
