import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentQuickAnswerActionTypes } from "./types";
import { contentQuickAnswerApiResponseSuccess, contentQuickAnswerApiResponseError } from "./actions";

import {
  getContentQuickAnswers as getContentQuickAnswersApi,
  sendDataContentQuickAnswer,
  removeContentQuickAnswer
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getContentQuickAnswers({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getContentQuickAnswersApi,data);
    yield put(contentQuickAnswerApiResponseSuccess(ContentQuickAnswerActionTypes.GET_CONTENT_QUICK_ANSWER, response));
  } catch (error: any) {
    yield put(contentQuickAnswerApiResponseError(ContentQuickAnswerActionTypes.GET_CONTENT_QUICK_ANSWER, error));
  }
}

function* onSendDataContentQuickAnswer({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentQuickAnswer, data);
    yield put(
        contentQuickAnswerApiResponseSuccess(ContentQuickAnswerActionTypes.UPDATE_CONTENT_QUICK_ANSWER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentQuickAnswerApiResponseError(ContentQuickAnswerActionTypes.UPDATE_CONTENT_QUICK_ANSWER, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveContentQuickAnswer({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeContentQuickAnswer, {uuid: data.data.uuid});
    yield put(
        contentQuickAnswerApiResponseSuccess(ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentQuickAnswerApiResponseError(ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetContentQuickAnswers() {
  yield takeEvery(ContentQuickAnswerActionTypes.GET_CONTENT_QUICK_ANSWER, getContentQuickAnswers);
}
export function* watchOnSendDataContentQuickAnswer() {
  yield takeEvery(ContentQuickAnswerActionTypes.UPDATE_CONTENT_QUICK_ANSWER, onSendDataContentQuickAnswer);
}
export function* watchOnRemoveContentQuickAnswer() {
  yield takeEvery(ContentQuickAnswerActionTypes.REMOVE_CONTENT_QUICK_ANSWER, onRemoveContentQuickAnswer);
}

function* contentQuickAnswerSaga() {
  yield all([
    fork(watchGetContentQuickAnswers),
    fork(watchOnSendDataContentQuickAnswer),
    fork(watchOnRemoveContentQuickAnswer)
  ]);
}

export default contentQuickAnswerSaga;
