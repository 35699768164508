import {EntityActionsType} from "../../types";
import {MainMetaType} from "../../Main/common";

export enum PeerActivityTypeActionTypes {
  API_RESPONSE_SUCCESS = "@@peerActivityType/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@peerActivityType/API_RESPONSE_ERROR",
  GET_CONTENT_PEER_ACTIVITY_TYPES = "@@peerActivityType/GET_CONTENT_PEER_ACTIVITY_TYPES",
  UPDATE_CONTENT_DATAITEMTYPE = "@@peerActivityType/UPDATE_CONTENT_PEER_ACTIVITY_TYPES",
  ADD_CONTENT_DATAITEMTYPE = "@@peerActivityType/ADD_CONTENT_DATAITEMTYPE",
  REMOVE_CONTENT_DATAITEMTYPE_MODAL_SHOW = "@@peerActivityType/REMOVE_CONTENT_DATAITEMTYPE_MODAL_SHOW",
  REMOVE_CONTENT_DATAITEMTYPE_MODAL_HIDE = "@@peerActivityType/REMOVE_CONTENT_DATAITEMTYPE_MODAL_HIDE",
  REMOVE_CONTENT_DATAITEMTYPE = "@@peerActivityType/REMOVE_CONTENT_DATAITEMTYPE",
  EDIT_CONTENT_DATAITEMTYPE = "@@peerActivityType/EDIT_CONTENT_DATAITEMTYPE",

  RIGHT_MODAL_SHOW = "@@peerActivityType/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@peerActivityType/RIGHT_MODAL_HIDE",
}

export interface PeerActivityTypeState extends MainMetaType{
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: PeerActivityTypeType;
  modalEditTitle: string;
  isSending: boolean;
  isLoading: boolean;
  isFetched: boolean;
  isNeedReload: boolean;
}

export interface PeerActivityTypeType{
  uuid?: string,
  enabled: boolean,
  name: string,
  code?: string,
  color: string,
  text: string,
  isDefault: boolean,
  order: number,
  actions?: EntityActionsType
}

export interface PeerActivityTypePayload {
  data: Array<any>;
  modalEditTitle : string;
}
