import React from "react";
import {CurrencyRateType} from "../../../redux/Sale/CurrencyRate/types";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {EntityActionsHandlersType} from "../../../redux/types";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {convertToMomentLocale} from "../../../helpers/functions";

const SaleCurrencyRateColumns = ({ onEdit, onRemove }:EntityActionsHandlersType) => {

    const { t, i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));

    return [
        {
            name: <i className="ri-settings-4-line text-muted"/>,
            width: '50px',
            allowOverflow: true,
            center: true,
            ignoreRowClick: true,
            selector: (cell:any) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-light btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end" container="root">
                            { cell.actions && cell.actions.canEdit &&
                                <DropdownItem className='edit-item-btn' onClick={() => onEdit(cell)}><i
                                    className="ri-pencil-fill align-bottom me-2 text-muted"/> {t('common:edit')} </DropdownItem>
                            }
                            {cell.actions && cell.actions.canRemove &&
                                <DropdownItem className='remove-item-btn' onClick={() => onRemove(cell)}><i className="ri-delete-bin-fill align-bottom me-2 text-muted"/> {t('common:delete')} </DropdownItem>
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
        {
            id: 'order',
            name: <span className='font-weight-bold fs-14'>{t('common:order')}</span>,
            selector: (row:CurrencyRateType) => row.order,
            sortable: true,
            maxWidth: '50px',
            center: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }]
        },
        {
            id: 'dateTime',
            sortable: true,
            name: <span className='font-weight-bold fs-14'>{t('common:date')}</span>,
            selector: (row:CurrencyRateType) => {
                return (
                    <>
                        <div className="d-flex flex-shrink-0">
                            <div className="flex-1 justify-content-center flex-column d-flex">
                                <p className={"lh-base mb-0"}>
                                    {moment(row.dateTime).format('llll')}
                                </p>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('currency:baseCurrency')}</span>,
            selector: (row:CurrencyRateType) => {
                return (
                    <>
                        <div className="d-flex flex-shrink-0">
                            <div className="flex-1 justify-content-center flex-column d-flex">
                                <p className={"lh-base mb-0"}>
                                    {row.currencyFirst.code}
                                </p>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            name: <span className='font-weight-bold fs-14'>{t('currency:quoteCurrency')}</span>,
            selector: (row:CurrencyRateType) => {
                return (
                    <>
                        <div className="d-flex flex-shrink-0">
                            <div className="flex-1 justify-content-center flex-column d-flex">
                                <p className={"lh-base mb-0"}>
                                    {row.currencySecond.code}
                                </p>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            id: 'baseRate',
            sortable: true,
            name: <span className='font-weight-bold fs-14'>{t('currency:baseRate')}</span>,
            selector: (row:CurrencyRateType) => {
                return (
                    <>
                        <div className="d-flex flex-shrink-0">
                            <div className="flex-1 justify-content-center flex-column d-flex">
                                <p className={"lh-base mb-0"}>
                                    {row.baseRate}
                                </p>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            id: 'rate',
            sortable: true,
            name: <span className='font-weight-bold fs-14'>{t('currency:rate')}</span>,
            selector: (row:CurrencyRateType) => {
                return (
                    <>
                        <div className="d-flex flex-shrink-0">
                            <div className="flex-1 justify-content-center flex-column d-flex">
                                <p className={"lh-base mb-0"}>
                                    {row.rate}
                                </p>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            id: 'enabled',
            name: <span className='font-weight-bold fs-14'>{t('common:status')}</span>,
            sortable: true,
            maxWidth: '50px',
            center: true,
            conditionalCellStyles: [{
                when: (row:any) => row,
                classNames: ['fs-14', 'p-2', 'lh-md']
            }],
            selector: (cell:any) => {
                switch (cell.enabled) {
                    case true:
                        return <span className="badge badge-soft-success"> {t('common:active')} </span>;
                    default:
                        return <span className="badge badge-soft-danger"> {t('common:inActive')} </span>;
                }
            },
        }
    ];

}

export default SaleCurrencyRateColumns;