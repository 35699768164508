import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getSaleIntegrations = (data:MainMetaType) => {
    const query = createPaginationQuery(data)
    return api.get(m9sGlobals.CURRENT_CABINET+url.GET_SALE_INTEGRATIONS+"?"+query);
};

const sendDataSaleIntegration = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_INTEGRATION, data);
};

const removeSaleIntegration = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_SALE_INTEGRATION, data);
};

const requestSaleIntegrationImport = (data: any) => {

    if (data.uuid !== undefined){
        if (data.attachmentFiles !== undefined){
            return api.createWithFile(m9sGlobals.CURRENT_CABINET+url.REQUEST_SALE_INTEGRATION_IMPORT,data);
        } else {
            return api.create(m9sGlobals.CURRENT_CABINET + url.REQUEST_SALE_INTEGRATION_IMPORT, {uuid: data.uuid});
        }
    }

    return false;
};

const invalidateCacheSaleIntegration = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.INVALIDATE_CACHE_SALE_INTEGRATION, data);
};

export {
    getSaleIntegrations,
    sendDataSaleIntegration,
    removeSaleIntegration,
    requestSaleIntegrationImport,
    invalidateCacheSaleIntegration
};
