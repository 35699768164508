import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LeftbarTitle from "../../../components/LeftbarTitle";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../../../hooks";
import {GroupOrderPayloadType, OrderType} from "../../../redux/Sale/Order/types";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {
    groupRemoveOrdersModalHide, removeGroupOrders
} from "../../../redux/Sale/Order/actions";
import {MainMetaType} from "../../../redux/Main/common";
import ButtonSpinner from "../../../components/ButtonSpinner";

interface ItemProps {
    orders: OrderType[],
    historyHash: HistoryHashType,
    isOpen: boolean
}

const DeleteOrdersModal = ({ orders, historyHash, isOpen }: ItemProps) => {

    const { t, i18n } = useTranslation();
    const { dispatch, useAppSelector } = useRedux();

    const {
        saleOrder,
        isSending
    } = useAppSelector(state => ({
        saleOrder: state.SaleOrder,
        isSending: state.SaleOrder.isSending
    }));

    const [isModalShow, setModalShow] = useState<boolean>(isOpen);

    useEffect(() => {
        setModalShow(isOpen);
    }, [isOpen, saleOrder.isModalGroupRemoveOrdersShow, t]);

    const handleClick = () => {

        let mainMeta : MainMetaType = {}

        if (historyHash?.allGroupActionSelect && historyHash.allGroupActionSelect === true) {
            mainMeta = {
                maxResults: historyHash.maxResults ? historyHash.maxResults : PAGINATION_DEFAULT_ITEMS_PER_PAGE,
                searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length > 0 && historyHash.searchPhrase
            }
        }

        let _OrderUuids: string[]=[];

        (orders || []).map((Order:OrderType)=>{
            _OrderUuids.push(Order.uuid);
            return Order;
        });

        let groupOrderPayload:GroupOrderPayloadType = {}

        if (_OrderUuids.length>0){
            groupOrderPayload = {
                ...groupOrderPayload,
                orderUuids: _OrderUuids
            }
        }

        dispatch(removeGroupOrders({'items':[groupOrderPayload], 'meta':mainMeta}));

    }

    const handleClose = () => {
        dispatch(groupRemoveOrdersModalHide());
    }

    return (
        <>
            <Modal show={isModalShow} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <LeftbarTitle title={t('sale:deleteOrders')}/>
                        </Modal.Header>
                        <Modal.Body>
                            {/*<Row>*/}
                            {/*    <div className="d-flex flex-column">*/}
                            {/*        <div className="mb-3 d-flex flex-column">*/}
                            {/*    !# text about create Order with offers */}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Row>*/}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="light" onClick={handleClose}>
                                {t('common:cancel')}
                            </Button>
                            <button
                                    className="btn btn-danger chat-send waves-effect waves-light"
                                    onClick={()=>handleClick()}
                                    disabled={isSending}
                            >
                                {isSending &&
                                    <ButtonSpinner className={"ms-0 me-2"}/>
                                }
                                {t('common:delete')}
                            </button>
                        </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteOrdersModal;