import React from "react";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import DetailViewItem from "../PeerActivity/DetailViewItem";

const PeerActivityCardViewItem = ({...props}) => {

    const peerActivity:PeerActivityType = props;

    return (
        <div className="mb-2" key={"peerActivity"+peerActivity.uuid}>
            <DetailViewItem item={peerActivity} dispatchExternal={props.dispatch}/>
        </div>
    );
};

export default PeerActivityCardViewItem;
