import {ProductType} from "../Product/types";
import {PriceTypeType} from "../PriceType/types";
import {CurrencyType} from "../Currency/types";
import {SalePlanType} from "../Plan/types";

export interface OrderItemType extends AbstractOrderItemType{
    product?: ProductType;
    priceType?: PriceTypeType;
}

export interface AbstractOrderItemType{
    uuid?: string;
    name?: string;
    quantity: number;
    price?: number;
    discount?: number;
    discountPercent?: number;
    currency: CurrencyType;
    createdAt?: string;
    updatedAt?: string;
}



export const newOrderItem: OrderItemType = {
    currency: null,
    quantity: 1,
    product: null,
    priceType: null,
    price:null,
    discount:null
}