import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row, Col,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from 'reactstrap';
import { Link } from 'react-router-dom'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {
    Formik,
    Form,
    Field
} from 'formik';


import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {
    AITextGenerateAction,
    AITextGenerateActionItems, AITextGenerateFormat,
    AITextGenerateLengthItems,
    AITextGenerateParamType,
    AITextGenerateResultType,
    AITextGenerateType
} from "../../../redux/AI/TextGenerate/types";
import {
    changeSelectedItemConnectorAITextGenerate,
    editSelectedItemResultVariant,
    getActiveConnectorsAITextGenerate, getIdeasPeerChannel,
    hideRightModalAITextGenerate,
    removeSelectedItemResultVariant,
    sendAITextGenerateRequest, setAITextGenerateInitialized,
} from "../../../redux/AI/TextGenerate/actions";
import {ContentConnectorAI, ContentConnectorType} from "../../../redux/Content/Connector/types";
import ListButtonItem from "../../Content/Connector/ListButtonItem";
import ReactFlagsSelect from "react-flags-select";
import InputEmojiArea from "../../../components/InputEmojiArea";
import InputCount from "../../../components/InputCount";
import ListButtonAddItem from "../../../components/ListButtonItem";
import * as route from "../../../api/routes";
import {getCurrentLanguage} from "../../../helpers/functions";
import ListItem from "../../../components/AI/ListItem";
import LengthItem from "../../../components/AI/LengthItem";
import {getPeerActivityTypes} from "../../../redux/Content/PeerActivityType/actions";
import {getPeerActivityModes} from "../../../redux/Content/PeerActivityMode/actions";
import {addPeerActivityIdeaText} from "../../Content/PeerActivityIdea/PeerActivityIdeaActions";
import {PeerActivityModeCode, PeerActivityModeType} from "../../../redux/Content/PeerActivityMode/types";
import {PeerActivityTypeType} from "../../../redux/Content/PeerActivityType/types";


const RightModal = () => {

    const { t } = useTranslation();

    const { dispatch, useAppSelector } = useRedux();
    const [ disabled, setDisabled] = useState<boolean>(true);
    const [ connectors, setConnectors] = useState<ContentConnectorType[]>([]);
    const [country, setCountry] = useState('');
    const [formValues, setFormValues] = useState(null);
    const [isShowCustomSettings, setShowCustomSettings] = useState<boolean>(false);
    const [ resultItems, setResultItems] = useState<AITextGenerateResultType[]>([]);
    const [ aiNotification, setAINotification] = useState<string>(null);
    const [ aiPromptPlaceholder, setAIPromptPlaceholder] = useState<string>(null);
    const [ aiCurrentAction, setAICurrentAction] = useState<string>(null);

    const {
        aiTextGenerateState,
        isSending,
        peerActivityModes,
        peerActivityTypes
    } = useAppSelector(state => ({
        aiTextGenerateState: state.AITextGenerateState,
        isSending: state.AITextGenerateState.isSending,
        peerActivityModes: state.PeerActivityMode,
        peerActivityTypes: state.PeerActivityType,
    }));

    useEffect(()=>{

        dispatch(getActiveConnectorsAITextGenerate({handlerType:ContentConnectorAI.AI}));
        dispatch(getIdeasPeerChannel());

        dispatch(getPeerActivityTypes({maxResults:1000}));
        dispatch(getPeerActivityModes());

        const currentLanguage = getCurrentLanguage();

        if (currentLanguage === 'en') {
            setCountry('GB');
        } else if (currentLanguage === 'ka') {
            setCountry('GE');
        } else {
            setCountry(currentLanguage.toUpperCase());
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{

        if (aiTextGenerateState?.connectors && ((aiTextGenerateState?.connectors || []).length>0)) {
            setConnectors([...aiTextGenerateState.connectors]);
        } else {
            setConnectors([]);
        }

    },[dispatch, aiTextGenerateState.connectors]);

    useEffect(() => {
        setDisabled(!aiTextGenerateState.isInitialized);
    },[aiTextGenerateState?.isInitialized]);


    useEffect(() => {
        if (aiTextGenerateState?.isOpenSettings && aiTextGenerateState.isOpenSettings) {
            setShowCustomSettings(aiTextGenerateState.isOpenSettings);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[aiTextGenerateState?.isOpenSettings]);

    useEffect(()=>{

        switch (aiCurrentAction){
            case AITextGenerateAction.GENERATE_TITLE_ACTION:
                setAINotification('ai:notificationCreateTitles');
                setAIPromptPlaceholder('ai:placeholderCreateTitles');
                break;
            case AITextGenerateAction.GENERATE_ACTION:
                setAINotification('ai:notificationCreateText');
                setAIPromptPlaceholder('ai:placeholderCreateText');
                break;
            case AITextGenerateAction.REWRITE_ACTION:
                setAINotification('ai:notificationRewriteText');
                setAIPromptPlaceholder('ai:placeholderRewriteText');
             break;
            case AITextGenerateAction.TRANSLATE_ACTION:
                setAINotification('ai:notificationTranslateText');
                setAIPromptPlaceholder('ai:placeholderTranslateText');
                break;
            case AITextGenerateAction.PREDICT_TAG_ACTION:
                setAINotification('ai:notificationPredictTag');
                setAIPromptPlaceholder('ai:placeholderPredictTag');
                break;
            default:
                setAINotification(null);
                setAIPromptPlaceholder(null);
        }

    },[aiCurrentAction]);

    useEffect(()=>{

        let arResultItems= [];

        if (aiTextGenerateState?.selectedItem?.uuid !== undefined){

            let newFormValues = {};

            if (aiTextGenerateState?.selectedItem?.action !== undefined){
                setAICurrentAction(aiTextGenerateState.selectedItem.action);
            }

            newFormValues = {...aiTextGenerateState.selectedItem};

            delete newFormValues['generateParams'];
            delete newFormValues['connector'];

            if (aiTextGenerateState.selectedItem?.connector?.uuid){
                newFormValues['connector']=aiTextGenerateState.selectedItem.connector.uuid;
                checkInitialDataLoaded();
            } else if ((connectors || []).length>0){
                const firstConnector = connectors.filter((item:ContentConnectorType)=> {return item.isConnected}).shift();
                if (firstConnector?.uuid){
                    newFormValues['connector']=firstConnector.uuid;
                    aiTextGenerateState.selectedItem.connector = firstConnector;
                    checkInitialDataLoaded();
                }
            }

            (aiTextGenerateState.selectedItem.generateParams || []).map((item:AITextGenerateParamType, key: number)=>{

                newFormValues['generateParams_'+key+'_maxLength'] = item.maxLength;
                newFormValues['generateParams_'+key+'_generateCount'] = item.generateCount;

                if (item?.targetLanguage) {
                    newFormValues['generateParams_' + key + '_targetLanguage'] = item.targetLanguage;
                } else {
                    newFormValues['generateParams_' + key + '_targetLanguage'] = country;
                }

                if (item.resultItems && ((item.resultItems || []).length>0)){

                    item.resultItems.map((resultItem:AITextGenerateResultType)=>{
                        arResultItems.push(resultItem);

                        return resultItem;
                    })
                }

                return item;
            });

            setFormValues({...newFormValues});
        }
        setResultItems([...arResultItems]);

// eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, aiTextGenerateState?.selectedItem, connectors]);


    const handleClose = () => dispatch(hideRightModalAITextGenerate());

    const onDataSubmit = (formData:any) => {

        (aiTextGenerateState.selectedItem.generateParams || []).map((item:AITextGenerateParamType, key: number)=>{

            item.maxLength = formData['generateParams_'+key+'_maxLength'];
            item.generateCount = formData['generateParams_'+key+'_generateCount'];
            item.targetLanguage = formData['generateParams_'+key+'_targetLanguage'];

            return item;
        });

        let aiTextGenerate: AITextGenerateType = {
            id: formData.id && formData.id,
            uuid: formData.uuid && formData.uuid,
            action: formData.action && formData.action,
            enabled: formData.enabled ? formData.enabled : false,
            prompt: formData.prompt && formData.prompt,
            text: formData.text && formData.text,
            textFormat: formData.textFormat && formData.textFormat,
            model: formData.model && formData.model,
            connector: formData.connector && {uuid:formData.connector},
            generateParams: aiTextGenerateState.selectedItem.generateParams
        };

        dispatch(sendAITextGenerateRequest(aiTextGenerate));
    };

    const handleChangeAIConnector = (item:ContentConnectorType) => {
        dispatch(changeSelectedItemConnectorAITextGenerate(item));
    }

    const handleShowCustomSettingsToggle = () => {
        setShowCustomSettings(!isShowCustomSettings);
    }
    const handleRemoveResultItem = (item:AITextGenerateResultType) => {
        dispatch(removeSelectedItemResultVariant(item));
    }
    const handleEditResultItem = (item:AITextGenerateResultType) => {
        dispatch(editSelectedItemResultVariant(item));
    }

    const handleAddResultItemToIdeas = (item:AITextGenerateResultType) => {

        addPeerActivityIdeaText(
                dispatch,
                aiTextGenerateState.ideasChannel,
                (peerActivityModes.items || []).filter((modeItem:PeerActivityModeType)=>{
                    return modeItem.code === PeerActivityModeCode.ACTIVITY
                }).shift(),
                (peerActivityTypes.items || []).filter((typeItem:PeerActivityTypeType)=>{
                    return typeItem.isDefault
                }).shift(),
                item.text,
            item.textFormat === AITextGenerateFormat.FORMAT_HTML
            );

    }

    useEffect(()=>{
        if (checkInitialDataLoaded()){
            // handleNewActivity();
        }
        // else {
        //     if (isFetched){
        //         if ((peerChannels.items || []).length<1) {
        //             setShowPeerChannelEmptyDanger(true);
        //         }
        //     }
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        peerActivityModes.items,
        peerActivityTypes.items,
        aiTextGenerateState?.ideasChannel,
        aiTextGenerateState?.selectedItem?.connector
    ])

    const checkInitialDataLoaded = () => {

        if (
            (peerActivityModes && peerActivityModes.items && peerActivityModes.items.length>0)
            &&
            (peerActivityTypes && peerActivityTypes.items && peerActivityTypes.items.length>0)
            &&
            (aiTextGenerateState?.ideasChannel && aiTextGenerateState?.ideasChannel?.uuid.length>0)
            &&
            (aiTextGenerateState?.selectedItem?.connector?.uuid && aiTextGenerateState.selectedItem.connector.uuid.length>0)
        ){
            dispatch(setAITextGenerateInitialized());
            return true;
        }

        return false;
    }

    const handleRefineResultItem = (item:AITextGenerateResultType) => {
        //TODO
   ///////     dispatch(removeSelectedItemResultVariant(item));
    }

    return (
        <>
            <Offcanvas
                direction="end"
                isOpen={aiTextGenerateState.rightModalShow}
                id="aiEdit"
                toggle={handleClose}
            >
                {formValues &&
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
//                    validationSchema={ContentTemplateValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('ai:'+aiTextGenerateState.modalEditTitle)}
                        </OffcanvasHeader>

                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                                    <Row>
                                        {
                                            (connectors || []).map((item: ContentConnectorType, key: number) => (
                                                <ListButtonItem
                                                    item={item}
                                                    key={'contentConnectorAI_' + item.uuid}
                                                    onClick={handleChangeAIConnector}
                                                    isSelected={getFieldProps('connector').value===item.uuid}
                                                />
                                            ))
                                        }
                                        <ListButtonAddItem link={route.CONTENT_SERVICES_LIST} isBlank={true}/>
                                    </Row>

                                    {(
                                        getFieldProps('action').value !== AITextGenerateAction.GENERATE_TITLE_ACTION
                                        &&
                                        getFieldProps('action').value !== AITextGenerateAction.PREDICT_TAG_ACTION
                                        ) &&
                                        <Row className="mb-3">
                                            {AITextGenerateActionItems.map((actionItem, actionItemKey: number)=>{
                                                return(
                                                    <Col lg={4} xs={12} key={'aiTextGenerateActionItem_' + actionItemKey}>
                                                        <LengthItem
                                                            item={actionItem}
                                                            isSelected={getFieldProps('action').value===actionItem.code}
                                                            onChange={(enabled:boolean) => {
                                                                setAICurrentAction(actionItem.code);
                                                                setFieldValue('action', actionItem.code);
                                                            }}
                                                            name={'aiTextGenerateAction'}
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    }
                                    <Row>
                                <div className="d-flex flex-row mb-2">
                                    <div className="d-flex flex-row align-items-center flex-grow-1">

                                        <ReactFlagsSelect
                                            className="countrySelectCont"
                                            selected={country}
                                            onSelect={(code) => {
                                                setFieldValue('generateParams_0_targetLanguage', code);
                                                setCountry(code)}
                                            }
                                            countries={[ "GB", "RU", "DE", "GE"]}
                                            customLabels={{ GB: "English", FR: "French", GE: "Georgian", DE: "German", IT: "Italian", RU: "Русский" }}
                                            placeholder="Select Language"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="d-flex flex-column align-items-center ms-2 justify-content-center">
                                        <Link to="#" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top">
                                            <OverlayTrigger
                                                placement={'left'}
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip>
                                                        {t('ai:responseLanguage')}
                                                    </Tooltip>
                                                }
                                            ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                        </Link>
                                    </div>
                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-0"}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('ai:generatorSettings')} <a href="#" onClick={handleShowCustomSettingsToggle}>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('ai:moreInfo')}
                                                        </Tooltip>
                                                    }
                                                ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                                </a></Label>
                                        </div>
                                    </Row>
                                    {isShowCustomSettings &&
                                        <>
                                    <Row>
                                        <div className={ "mb-3"}>
                                            <Field
                                                id="form-prompt-select"
                                                name="prompt"
                                                placeholder={aiPromptPlaceholder && t(aiPromptPlaceholder)}
                                                component={InputEmojiArea}
                                                value={getFieldProps('prompt').value}
                                                onChange={(text: string) => {
                                                    setFieldValue('prompt', text);
                                                }}
                                            />
                                        </div>
                                    </Row>

                                            <Row>
                                                {AITextGenerateLengthItems.map((lengthItem, lengthItemKey: number)=>{
                                                    return(
                                                        <Col lg={4} xs={12} key={'aiTextGenerateLengthItem_' + lengthItemKey}>
                                                            <LengthItem
                                                                item={lengthItem}
                                                                isSelected={getFieldProps('generateParams_0_maxLength').value===lengthItem.value}
                                                                onChange={(enabled:boolean) => {
                                                                    setFieldValue('generateParams_0_maxLength', lengthItem.value);
                                                                }}
                                                                name={'aiTextGenerateLength'}
                                                            />
                                                            {getFieldProps('generateParams_0_maxLength').value===lengthItem.value &&
                                                            <Field
                                                                id="form-num-select"
                                                                name="prompt"
                                                                component={InputCount}
                                                                value={getFieldProps('generateParams_0_generateCount').value}
                                                                onChange={(value: number) => {
                                                                    setFieldValue('generateParams_0_generateCount', value);
                                                                }}
                                                                min={1}
                                                                max={7}
                                                            />
                                                            }
                                                        </Col>
                                                    )
                                                })}

                                            </Row>

                                        </>
                                    }



                                </div>



                                    <div className="p-4 pt-0">
                                        { (resultItems || []).length>0 ?
                                            (resultItems || []).map((resultItem:AITextGenerateResultType, key:number)=>{
                                            return (
                                            <div key={'resultItem_'+key}>
                                                <ListItem item={resultItem}
                                                          onEdit={handleEditResultItem}
                                                          onClose={handleRemoveResultItem}
                                                          onAddToIdeas={handleAddResultItemToIdeas}
                                                />
                                            </div>
                                            )
                                        })
                                            :
                                            aiNotification !== null &&
                                            <div className="d-flex flex-row flex-grow-1">
                                                <div className="alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-1" role="alert">
                                                    {t(aiNotification)}
                                                </div>
                                            </div>
                                        }
                                    </div>


                            </SimpleBar>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0 bg-body-secondary">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                                <Button variant="light" onClick={handleClose} className="w-100">
                                    {t('common:close')}
                                </Button>
                                {aiTextGenerateState?.selectedItem?.action  &&
                                <Button variant="primary"
                                        type="submit"
                                        disabled={isSending || disabled}
                                        className="btn btn-primary w-100 chat-send waves-effect waves-light"
                                >
                                    {t('ai:'+aiCurrentAction+'Action')}
                                    {isSending &&
                                        <ButtonSpinner/>
                                    }
                                </Button>
                                }
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>
                }
            </Offcanvas>
        </>
    );
}

export default RightModal;