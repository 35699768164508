import {HelperItemType} from "../../Helper/HelperItem/types";
import {StatProjectCounterTilesType, StatProjectTilesType} from "../DashboardContent/types";
import {emptyChannelsTiles} from "../PeerChannel/types";

export interface StatStatCounterTileType{
  label?: string;
  value?: StatStatCounterTileValueType;
  helperItem?: HelperItemType;
  isLoading? :boolean
}

export interface StatStatCounterTileValueType{
    last?: number,
    first?: number,
    current?: number,
}

export interface StatStatCounterDataType{
    visits?: number;
    users?: number,
    pageViews? : number,
    bounceRate? : number,
    pageDepth?: number,
    avgVisitDurationSeconds?: number,
    sumGoalReachesAny?: number,
    date?: string
}


export const emptyCountersTiles: StatStatCounterTileType[] =
    [
        {
            label: "visits",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "views",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "users",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "bounceRate",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "pageDepth",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "avgVisitDurationSeconds",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
        {
            label: "sumGoalReachesAny",
            value :{
                first: 0,
                last: 0
            },
            isLoading: true
        },
    ];


export const emptyProjectCountersTiles: StatProjectCounterTilesType = {
    items: emptyChannelsTiles
};

export const emptyProjectsCountersTiles: StatProjectCounterTilesType[] = [emptyProjectCountersTiles];