import React from "react";
import Form from "react-bootstrap/Form";

interface InputCheckboxProps {
  id: string;
  name: string;
  value: string;
  isChecked: boolean;
  placeholder: string;
  onChange: (e:any) => void;
}
const InputRadio = ({ id, name, placeholder, value, onChange, isChecked }: InputCheckboxProps) => {

  return (
              <Form.Check
                type="radio"
                id= { id }
                name = { name }
                value = { value }
                checked={isChecked}
                onChange={(e:any) => {
                  onChange(e);
                }}
                label = {placeholder}
              />
  );
};
export default InputRadio;
