import React, {useEffect, useState, useCallback} from 'react';
import classnames from "classnames";
import {
    PeerActivityActionButton,
    PeerActivityPrivacy,
    PeerActivityPrivacyItemType, peerActivityPrivacyLinkedId,
    peerActivityPrivacyTikTok, peerActivityPrivacyYouTube,
    PeerActivityType, ServiceMessageType, ServiceMessageTypes
} from "../../../redux/Content/PeerActivity/types";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import SimpleBar from 'simplebar-react';
import {Field, Form, Formik} from 'formik';

import {Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Col, Label, Row, Nav, NavItem, NavLink} from "reactstrap";

import InputSelect from "../../../components/InputSelect";
import {useRedux} from "../../../hooks";
import InputDateTimePicker from "../../../components/InputDateTimePicker";
import InputSwitch from "../../../components/InputSwitch";
import ChannelItemLine from "../PeerChannel/channelItemLine";
import {
    ContentConnectorDataParamExclude,
    ContentConnectorDataParamType,
    ContentConnectorDataParamTypeType
} from "../../../redux/Content/ConnectorDataParam/types";
import InputText from "../../../components/InputText";
import InputFile from "../../../components/InputFile";
import InputEmojiArea from "../../../components/InputEmojiArea";
import {ContentConnectorSocialCalendar} from "../../../redux/Content/Connector/types";
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {PeerActivityModeCode} from "../../../redux/Content/PeerActivityMode/types";
import {MainFileType} from "../../../redux/Main/File/types";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {PeerActivityStatus} from "../../../redux/Content/PeerActivityStatus/types";
import {PeerActivityIdeaValidatorSchema, PeerActivityValidatorSchema} from "./PeerActivityValidator";
import {
    hidePeerActivityEditModal,
    onUnPublishPeerActivityItems, onUpdatePeerActivityItems,
    setPreviewDesktop,
    setPreviewMobile,
    togglePinPeerActivity
} from "../../../redux/Content/PeerActivity/actions";
import {isNumeric, isVideo} from "../../../helpers/functions";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {showErrorNotification} from "../../../helpers/notifications";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import InputEditorSwitch from "../../../components/InputEditorSwitch";
import * as route from "../../../api/routes";
import AcceptedHandlerByMode from "../ConnectorHandler/AcceptedHandlerByMode";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import NoticeTip from "../../../components/NoticeTip";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {SaleIntegrationHandlerCodes, SaleIntegrationHandlerTypes} from "../../../redux/Sale/IntegrationHandler/types";
import {facebookCTAButtons} from "../../../data/cta";
import PreviewItem from "./PreviewItem";
import MediaPreview from "./MediaPreview";
import ServiceMessageItem from "./ServiceMessageItem";
import InputTagText from "../../../components/InputTagText";
import {convertToMomentLocale} from "../../../helpers/functions";

interface ItemProps {
    items: PeerActivityType[];
    isShowMoreChannels: boolean;
    isCopyFromIdeas: boolean;
    isMultiPosting: boolean;
}

var debouncerTimeout = [];
var debouncerTimeoutValue = 1000;

function filterEnabledTelegramWidgets(saleIntegrationItems?: SaleIntegrationType[]): SaleIntegrationType[] {
    return saleIntegrationItems ? saleIntegrationItems.filter(isEnabledTelegramWidget) : [];
}

function isEnabledTelegramWidget(item: SaleIntegrationType): boolean {
    return item.enabled && item?.handler?.code === SaleIntegrationHandlerCodes.WIDGET_TELEGRAM && item.handler.type === SaleIntegrationHandlerTypes.OUTBOUND;
}

const PeerActivityEdit = ({ items, isShowMoreChannels = false, isCopyFromIdeas = false, isMultiPosting = true }: ItemProps) => {

    const { t,i18n  } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));

    const { dispatch, useAppSelector } = useRedux();
    const [currentItems, setCurrentItems] = useState<PeerActivityType[]>(items);
    const [previewItems, setPreviewItems] = useState<PeerActivityType[]>(items);
    const [openChannels, setOpenChannels] = useState<string[]>([]);
    const [formValues, setFormValues] = useState({});
    const [showMoreChannels, setShowMoreChannels] = useState<boolean>(isShowMoreChannels);
    const [isSending, setSending] = useState<boolean>(true);
    const [isNew, setNew] = useState<boolean>(true);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [isScheduledPosting, setIsScheduledPosting] = useState<boolean>(true);
    const [currentActivityMode, setCurrentActivityMode] = useState<string>(null);
    const [isOnlyIdeasInForm, setOnlyIdeasInForm] = useState<boolean>(false);
    const [saleIntegrations, setSaleIntegrations] = useState<SaleIntegrationType[]>([]);
    const [facebookCTAButtonsOptions, setFacebookCTAButtonsOptions] = useState<any>([]);
    const [isMultiPostingEdit, setMultiPostingEdit] = useState<boolean>(isMultiPosting);
    const [multiPostingPeerActivity, setMultiPostingPeerActivity] = useState<PeerActivityType>({status: PeerActivityStatus.STATUS_NEW});

    const FormatValue = (value:number) =>
        new Intl.NumberFormat(i18n.language, {
            style: 'decimal',
        }).format(value);

    const toggleChannel = (uuid) => {

        let enabled=false;
        const itemIndex = openChannels.indexOf(uuid);

        if (itemIndex > -1) {
            openChannels.splice(itemIndex, 1);

        } else {
            openChannels.push(uuid);
            enabled=true
        }

        (currentItems || []).map((item:PeerActivityType, key: number)=>{
            if (uuid === item.channel.uuid) {
                item.enabled = enabled;
            }
            return item;
        });

        setOpenChannels([...openChannels]);
        setCurrentItems([...currentItems]);
    };

    const {
        peerActivity,
        peerActivityTypes,
        contentConnectorDataParam,
        contentPeerChannels,
        contentTemplates,
        saleIntegration,
        salePlan,
        isShowPreviewItems,
        isShowPreviewMobile,
        isNeedReloadPeerActivity
    } = useAppSelector(state => ({
        peerActivity: state.PeerActivity,
        peerActivityTypes: state.PeerActivityType,
        contentConnectorDataParam: state.ContentConnectorDataParam,
        contentPeerChannels: state.ContentPeerChannel,
        contentTemplates: state.ContentTemplates,
        saleIntegration: state.SaleIntegration,
        salePlan: state.SalePlan,
        isShowPreviewItems: state.PeerActivity.isShowPreviewItems,
        isShowPreviewMobile: state.PeerActivity.isShowPreviewMobile,
        isNeedReloadPeerActivity:state.PeerActivity.isNeedReload
    }));

    useEffect(()=>{
        setMultiPostingEdit(isMultiPosting);
    },[isMultiPosting])

    useEffect(()=>{
        if (peerActivity?.rightModalShow===false){
            setCurrentItems([]);
        }
    },[peerActivity?.rightModalShow])

    const updateFacebookCTAButtonsOptions = useCallback(() => {
        setFacebookCTAButtonsOptions(Object.keys(facebookCTAButtons)
            .map((item:string) => ({ id: item, value: t('engagement:button'+item) })));
    }, [t]);

    useEffect(updateFacebookCTAButtonsOptions, [updateFacebookCTAButtonsOptions]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.CONTENT_SCHEDULED_ACTIVITIES
            }
        )).pop();

        if (restrictionValue === undefined){
            setIsScheduledPosting(false);
        } else  {
            if (restrictionValue.value === "Y") {
                setIsScheduledPosting(true);
            } else {
                setIsScheduledPosting(false);
            }
        }
    }

    const handlePublishButton = (publishItem:PeerActivityType)=>{

        if (publishItem.status === PeerActivityStatus.STATUS_FINAL){
            //unpublish
            dispatch(onUnPublishPeerActivityItems(publishItem));

        }else if (publishItem.status === PeerActivityStatus.STATUS_READY) {

            currentItems.map((item: PeerActivityType) => {
                if (publishItem.uuid === item.uuid) {
                    item.status = PeerActivityStatus.STATUS_PUBLISHING;
                }
                return item;
            });
            handleSubmit();
        }
    }

    const handleMultiPublishButton = () =>{

        if (multiPostingPeerActivity.status === PeerActivityStatus.STATUS_READY) {

            if ((currentItems.filter((item: PeerActivityType) => {return item.enabled}) || []).length<1){

                showErrorNotification(t('validator:noChannelsMarkedToPost'));

                return false;
            }

            currentItems.map((item: PeerActivityType) => {
                if (item.enabled) {
                    item.status = PeerActivityStatus.STATUS_PUBLISHING;
                }
                return item;
            });

            multiPostingPeerActivity.status = PeerActivityStatus.STATUS_PUBLISHING;

            handleSubmit();
        }
    }

    const onChangeReadyToMultiPostingPublish = () => {

        const readyStatus = PeerActivityStatus.STATUS_READY;
        const draftStatus = PeerActivityStatus.STATUS_NEW;

        if (multiPostingPeerActivity.status !== PeerActivityStatus.STATUS_FINAL) {
            if (multiPostingPeerActivity.status !== PeerActivityStatus.STATUS_READY) {
                multiPostingPeerActivity.status = readyStatus;
            } else {
                multiPostingPeerActivity.status = draftStatus;
            }
        }

    }

    const handlePinButton = (event:any, item:PeerActivityType)=>{
        event.stopPropagation();
        event.preventDefault();

        if (isSending){
            showErrorNotification(t('notification:waitUntilPreviousActionFinishes'));
        } else {
            dispatch(togglePinPeerActivity({data:item}));
        }
    }

    useEffect(() => {
        setSending(peerActivity.isSending);
    },[peerActivity.isSending]);

    const updateSaleIntegrations = useCallback(() => {
        setSaleIntegrations(filterEnabledTelegramWidgets(saleIntegration?.items));
    }, [saleIntegration?.items]);

    useEffect(updateSaleIntegrations, [updateSaleIntegrations, saleIntegration?.items]);

    const onChangeReadyToPublish = (channel:ContentPeerChannelType) => {

        const readyStatus = PeerActivityStatus.STATUS_READY;

        const draftStatus = PeerActivityStatus.STATUS_NEW;

        currentItems.map((item:PeerActivityType)=>{
            if (item.channel.uuid === channel.uuid){
                if (item.status !== PeerActivityStatus.STATUS_FINAL) {
                    if (item.status !== readyStatus) {
                        item.status = readyStatus;
                    } else {
                        item.status = draftStatus;
                    }
                }
            }
            return item;
        });

        setCurrentItems([...currentItems]);
    }

    const handleSubmit = () => {
        const submitButton = document.getElementById('idPeerActivityEditSubmit');
        if (submitButton !== undefined){
            submitButton.click();
        }
    }

    const updateItemByMultiItem = (item: PeerActivityType, isInitial:boolean = false) => {

        if (multiPostingPeerActivity?.text && multiPostingPeerActivity.text.length>0){
            item.text=multiPostingPeerActivity.text;
        }
        if (multiPostingPeerActivity?.title && multiPostingPeerActivity.title.length>0){
            item.title=multiPostingPeerActivity.title;
        }
        if (multiPostingPeerActivity?.description && multiPostingPeerActivity.description.length>0){
            item.description=multiPostingPeerActivity.description;
        }
        if (multiPostingPeerActivity?.bio && multiPostingPeerActivity.bio.length>0){
            item.bio=multiPostingPeerActivity.bio;
        }
        if (multiPostingPeerActivity?.tags && multiPostingPeerActivity.tags.length>0){
            item.tags=multiPostingPeerActivity.tags;
        }
        if (multiPostingPeerActivity?.link && multiPostingPeerActivity.link.length>0){
            item.link=multiPostingPeerActivity.link;
        }
        if (multiPostingPeerActivity?.status && item.status !== PeerActivityStatus.STATUS_FINAL){
            item.status=multiPostingPeerActivity.status;
        }

        if (multiPostingPeerActivity?.media !== undefined) {
            if (multiPostingPeerActivity?.media && (multiPostingPeerActivity.media || []).length > 0) {
                item.media = multiPostingPeerActivity.media.map((multiMediaItem: MainFileType) => {
                    let _multiMediaItem: MainFileType = (item.media || []).filter((mediaItem: MainFileType) => {
                        return mediaItem.fileName === multiMediaItem.fileName;
                    }).shift();
                    if (!(_multiMediaItem !== undefined && _multiMediaItem !== null)) {
                        _multiMediaItem = {
                            ...multiMediaItem,
                            enabled: false
                        }
                    }
                    return _multiMediaItem;
                });
            } else {
                item.media = [];
            }
        }

        if (multiPostingPeerActivity?.avatar !== undefined) {
            if (multiPostingPeerActivity?.avatar && (multiPostingPeerActivity.avatar || []).length > 0) {
                item.avatar = multiPostingPeerActivity.avatar.map((multiMediaItem: MainFileType) => {
                    let _multiMediaItem: MainFileType = (item.avatar || []).filter((mediaItem: MainFileType) => {
                        return mediaItem.fileName === multiMediaItem.fileName;
                    }).shift();
                    if (!(_multiMediaItem !== undefined && _multiMediaItem !== null)) {
                        _multiMediaItem = {
                            ...multiMediaItem,
                            enabled: false
                        }
                    }
                    return _multiMediaItem;
                });
            } else {
                item.avatar = [];
            }
        }

        if (multiPostingPeerActivity?.cover !== undefined) {
            if (multiPostingPeerActivity?.cover && (multiPostingPeerActivity.cover || []).length > 0) {
                item.cover = multiPostingPeerActivity.cover.map((multiMediaItem: MainFileType) => {
                    let _multiMediaItem: MainFileType = (item.cover || []).filter((mediaItem: MainFileType) => {
                        return mediaItem.fileName === multiMediaItem.fileName;
                    }).shift();
                    if (!(_multiMediaItem !== undefined && _multiMediaItem !== null)) {
                        _multiMediaItem = {
                            ...multiMediaItem,
                            enabled: false
                        }
                    }
                    return _multiMediaItem;
                });
            } else {
                item.cover = [];
            }
        }

        return item;
    }

    const fillSendData = (formData:any) => {
        let attachmentFiles=[];

        let sendData = {
            peerActivities: currentItems
        }

        sendData.peerActivities.map((item: PeerActivityType)=>{

            if ((formData.type !== undefined) && (formData.type.uuid !== undefined)){
                item.type=formData.type;
            }

            if (formData.activeFrom && formData.activeFrom !== null && formData.activeFrom !== 'NaN'){
                item.activeFrom=moment(formData.activeFrom,'X').utc().format();
            }

            if (isMultiPostingEdit){
                item = updateItemByMultiItem(item);
            }

            if ((item.enabled) && (item.channel !== undefined) && (item.channel.uuid.length>0)){

                contentConnectorDataParam.items.map((paramItem: ContentConnectorDataParamType, paramKey: number) => {

                    if (
                        (!(Object.values(ContentConnectorDataParamExclude) as string[]).includes(paramItem.code))
                        &&
                        paramItem.handler !== undefined
                        &&
                        paramItem.handler.uuid.length>0
                        &&
                        item.channel.connector !== undefined
                        &&
                        item.channel.connector.handler !==undefined
                        &&
                        paramItem.handler.uuid === item.channel.connector.handler.uuid
                        &&
                        paramItem.mode.code === item.mode.code) {

                        if (formData[item.channel.uuid + '_' + paramItem.code] !== undefined){

                            if (
                                (paramItem.code==='MORE_PHOTO')
                                ||
                                (paramItem.code==='COVER')
                                ||
                                (paramItem.code==='AVATAR')
                            ){

                                let objectMediaKey = paramItem.code.toLowerCase();

                                if (paramItem.code==='MORE_PHOTO') {
                                    objectMediaKey='media';
                                }

                                item[objectMediaKey]=formData[item.channel.uuid + '_' + paramItem.code];

                                (item[objectMediaKey] || []).map((fileItem:MainFileType)=>{
                                    if (fileItem.uuid === undefined){
                                        attachmentFiles.push(fileItem);
                                    }
                                    return fileItem;
                                });

                            } else  {

                                if ((paramItem.code.toLowerCase()==='link') || (paramItem.code.toLowerCase()==='external_link')){
                                    item['externalLink'] = formData[item.channel.uuid + '_' + paramItem.code];
                                } else {
                                    item[paramItem.code.toLowerCase()] = formData[item.channel.uuid + '_' + paramItem.code];
                                }
                                if (
                                    (formData['isHtml_'+item.channel.uuid + '_' + paramItem.code]!== undefined)
                                    &&
                                    (formData['isHtml_'+item.channel.uuid + '_' + paramItem.code]!== null)
                                ){
                                    item.isHtml = formData['isHtml_'+item.channel.uuid + '_' + paramItem.code];
                                }
                            }
                        }
                    }
                    return paramItem;
                })

                if (formData[item.channel.uuid + '_isCommentsDisabled'] !== undefined){
                    item.isCommentsDisabled = formData[item.channel.uuid + '_isCommentsDisabled'];
                }
                if (formData[item.channel.uuid + '_isDuetDisabled'] !== undefined){
                    item.isDuetDisabled = formData[item.channel.uuid + '_isDuetDisabled'];
                }
                if (formData[item.channel.uuid + '_isStitchDisabled'] !== undefined){
                    item.isStitchDisabled = formData[item.channel.uuid + '_isStitchDisabled'];
                }
                if (formData[item.channel.uuid + '_isForwardDisabled'] !== undefined){
                    item.isForwardDisabled = formData[item.channel.uuid + '_isForwardDisabled'];
                }
                if (formData[item.channel.uuid + '_isSpoiler'] !== undefined){
                    item.isForwardDisabled = formData[item.channel.uuid + '_isSpoiler'];
                }
                if (formData[item.channel.uuid + '_isSilent'] !== undefined){
                    item.isForwardDisabled = formData[item.channel.uuid + '_isSilent'];
                }
                if (formData[item.channel.uuid + '_isBrandContent'] !== undefined){
                    item.isBrandContent = formData[item.channel.uuid + '_isBrandContent'];
                }
                if (formData[item.channel.uuid + '_isBrandOrganic'] !== undefined){
                    item.isBrandOrganic = formData[item.channel.uuid + '_isBrandOrganic'];
                }

                if (formData[item.channel.uuid + '_privacy'] !== undefined
                    && formData[item.channel.uuid + '_privacy'] !== null
                    && formData[item.channel.uuid + '_privacy'].value !== undefined){
                    switch (formData[item.channel.uuid + '_privacy'].value){
                        case PeerActivityPrivacy.PUBLIC:
                            item.isPublicAccess =true;
                            break;
                        case PeerActivityPrivacy.FRIENDS:
                            item.isFriendsAccess =true;
                            break;
                        case PeerActivityPrivacy.LOGGED_IN:
                            item.isLoggedInAccess =true;
                            break;
                        case PeerActivityPrivacy.SELF:
                            item.isSelfAccess =true;
                            break;
                    }
                }

                if (item?.cta && (item.cta || []).length>0){
                    (item.cta || []).map((ctaItem:PeerActivityActionButton,ctaKey:number)=>{

                        if (formData[item.channel.uuid + "_cta_enabled_"+ctaKey]){
                            ctaItem.enabled=true;
                        } else {
                            ctaItem.enabled=false;
                        }

                        if (formData[item.channel.uuid + "_cta_name_"+ctaKey] !== undefined && formData[item.channel.uuid + "_cta_name_"+ctaKey] !== null){
                            if (
                                formData[item.channel.uuid + "_cta_name_"+ctaKey]['value']!== undefined
                                &&
                                formData[item.channel.uuid + "_cta_name_"+ctaKey]['value']!== null
                            ){
                                ctaItem.name=formData[item.channel.uuid + "_cta_name_"+ctaKey]['value'];
                            } else {
                                ctaItem.name=formData[item.channel.uuid + "_cta_name_"+ctaKey];
                            }
                        }
                        if (formData[item.channel.uuid + "_cta_url_"+ctaKey]){
                            ctaItem.url=formData[item.channel.uuid + "_cta_url_"+ctaKey];
                        } else {
                            ctaItem.url=null;
                        }
                        if (formData[item.channel.uuid + "_cta_sale_integration_"+ctaKey]){
                            if (
                                formData[item.channel.uuid + "_cta_sale_integration_"+ctaKey]['value']!== undefined
                                &&
                                formData[item.channel.uuid + "_cta_sale_integration_"+ctaKey]['value']!== null
                            ){
                                ctaItem.saleIntegration={uuid: formData[item.channel.uuid + "_cta_sale_integration_"+ctaKey]['value']};
                            } else if (formData[item.channel.uuid + "_cta_sale_integration_"+ctaKey]?.uuid){
                                ctaItem.saleIntegration={uuid: formData[item.channel.uuid + "_cta_sale_integration_"+ctaKey].uuid};
                            } else {
                                ctaItem.saleIntegration=null;
                            }
                        } else {
                            ctaItem.saleIntegration=null;
                        }

                        return ctaItem;
                    })
                }

            } else {
                if (item.status===PeerActivityStatus.STATUS_PUBLISHING){
                    item.status=PeerActivityStatus.STATUS_NEW
                }

            }
            return item;
        });

        if (attachmentFiles.length>0){
            sendData['attachmentFiles']=attachmentFiles;
        }

        return sendData;
    }


    const onDataSubmit = (formData:any) => {

        let sendData = fillSendData(formData);

        if (!(
            (sendData.peerActivities || []).length>0
            &&
            (sendData.peerActivities.filter((item: PeerActivityType)=>{return item.enabled || (item.uuid !== undefined && item.uuid!==null)}).length>0)))
        {
            showErrorNotification(t('channel:mustBeOneChannelSelected'));
            return false;
        }

        if (isMultiPostingEdit) {
            sendData.peerActivities.map((item: PeerActivityType) => {

                if (item?.media && (item.media || []).length > 0) {
                    item.media = item.media.filter((mediaItem: MainFileType) => {
                        return (mediaItem?.enabled && mediaItem.enabled === true)
                    });
                }

                if (item?.avatar && (item.avatar || []).length > 0) {
                    item.avatar = item.avatar.filter((mediaItem: MainFileType) => {
                        return (mediaItem?.enabled && mediaItem.enabled === true)
                    });
                }

                if (item?.cover && (item.cover || []).length > 0) {
                    item.cover = item.cover.filter((mediaItem: MainFileType) => {
                        return (mediaItem?.enabled && mediaItem.enabled === true)
                    });
                }

                return item;
            });

            let  _attachmentFiles =[];

            (multiPostingPeerActivity.media || []).map((mediaItem: MainFileType)=>{
                if (mediaItem?.uuid === undefined){
                    _attachmentFiles.push(mediaItem);
                }
                return mediaItem;
            });
            (multiPostingPeerActivity.avatar || []).map((mediaItem: MainFileType)=>{
                if (mediaItem?.uuid === undefined){
                    _attachmentFiles.push(mediaItem);
                }
                return mediaItem;
            });
            (multiPostingPeerActivity.cover || []).map((mediaItem: MainFileType)=>{
                if (mediaItem?.uuid === undefined){
                    _attachmentFiles.push(mediaItem);
                }
                return mediaItem;
            });

            if (_attachmentFiles.length>0){
                if (sendData['attachmentFiles'] === undefined || sendData['attachmentFiles']===null){
                    sendData['attachmentFiles']=_attachmentFiles;
                } else {
                    sendData['attachmentFiles']=[
                        ...sendData['attachmentFiles'],
                        ..._attachmentFiles
                    ];
                }
            }
        }

        dispatch(onUpdatePeerActivityItems(sendData));

        if (isMultiPostingEdit && multiPostingPeerActivity?.status === PeerActivityStatus.STATUS_PUBLISHING) {
            setTimeout(() => {
                dispatch(hidePeerActivityEditModal());
            },1000);
        }

    }

    const addMoreChannelsItems = () => {
        if (showMoreChannels===true && (currentItems || []).length>0) {
            const draftStatus = PeerActivityStatus.STATUS_NEW;
            const presentChannels = currentItems.map((item: PeerActivityType) => (item.channel.uuid));
            const _currentItems = [...currentItems];
            let isAdded = false;

            contentPeerChannels.items.map((item: ContentPeerChannelType, key: number) => {
                if (presentChannels.indexOf(item.uuid) === -1) {

                    if (
                        (item.isManaged)
                        &&
                        (item.connector.isConnected)
                        &&
                        (Object.values(ContentConnectorSocialCalendar) as string[]).includes(item.connector.handler.type)) {

                        let addDataItemConnector = false;

                        if ([...currentItems].shift().mode.code === PeerActivityModeCode.ACTIVITY && item.connector.handler && item.connector.handler.canAddPost === true) {
                            addDataItemConnector = true;
                        } else if ([...currentItems].shift().mode.code === PeerActivityModeCode.STORY && item.connector.handler && item.connector.handler.canAddStory === true) {
                            addDataItemConnector = true;
                        } else if ([...currentItems].shift().mode.code === PeerActivityModeCode.BIO && item.connector.handler && item.connector.handler.canChangeBio === true) {
                            addDataItemConnector = true;
                        }

                        if ((item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT) && (item.type === 'personal')) {
                            addDataItemConnector = false;
                        }

                        if (addDataItemConnector && (item.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS)) {

                            let newActivityItem: PeerActivityType = {
                                enabled: false,
                                channel: item,
                                status: draftStatus,
                                mode: [...currentItems].shift().mode
                            }

                            if (newActivityItem.mode.code === PeerActivityModeCode.ACTIVITY
                                &&
                                item.connector.handler
                                &&
                                item.connector.handler.canActivityButtons === true
                            ) {

                                let ctaButton: PeerActivityActionButton = {
                                    enabled: false,
                                    name: '',
                                    url: ''
                                }

                                if ((saleIntegrations || []).length > 0) {
                                    ctaButton = {
                                        ...ctaButton,
                                        saleIntegration: [...saleIntegrations].shift()
                                    }
                                }

                                newActivityItem = {
                                    ...newActivityItem,
                                    cta: [ctaButton]
                                }
                            }

                            if ((((items || []).length === 1) || isCopyFromIdeas) && ([...items].shift()?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS)) {

                                let ideaItem = [...items].shift();

                                if (ideaItem.text) {
                                    newActivityItem.text = ideaItem.text;
                                }
                                if (ideaItem.tags) {
                                    newActivityItem.tags = ideaItem.tags;
                                }
                                if (ideaItem.media) {
                                    newActivityItem.media = ideaItem.media;
                                }
                            }

                            _currentItems.push(newActivityItem);
                            isAdded = true;
                        }
                    }
                }
                return item;
            });

            if (isAdded) {
                setCurrentItems([..._currentItems]);
            }
        }
    }

    useEffect(() => {
        addMoreChannelsItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showMoreChannels]);

    const onChange = () => {}

    useEffect(() => {

        let _media=[];
        let _avatar=[];
        let _cover=[];

        let _items = (items || []).map((item:PeerActivityType)=>{

            if ((item.media || []).length>0){
                (item.media || []).map((mediaItem:MainFileType)=>{
                    let _checkMedia = (_media || []).filter((_mediaItem:MainFileType)=>{
                        return _mediaItem?.fileName === mediaItem?.fileName && _mediaItem?.fileSize === mediaItem?.fileSize;
                    });

                    if (_checkMedia === undefined || _checkMedia === null || (_checkMedia ||[]).length<1){
                        _media.push(mediaItem);
                    }
                    return mediaItem;
                });
            }
            if ((item.avatar || []).length>0){
                (item.avatar || []).map((mediaItem:MainFileType)=>{
                    let _checkMedia = (_avatar || []).filter((_mediaItem:MainFileType)=>{
                        return _mediaItem?.fileName === mediaItem?.fileName && _mediaItem?.fileSize === mediaItem?.fileSize;
                    });

                    if (_checkMedia === undefined || _checkMedia === null || (_checkMedia ||[]).length<1){
                        _avatar.push(mediaItem);
                    }
                    return mediaItem;
                });
            }
            if ((item.cover || []).length>0){
                (item.cover || []).map((mediaItem:MainFileType)=>{
                    let _checkMedia = (_cover || []).filter((_mediaItem:MainFileType)=>{
                        return _mediaItem?.fileName === mediaItem?.fileName && _mediaItem?.fileSize === mediaItem?.fileSize;
                    });

                    if (_checkMedia === undefined || _checkMedia === null || (_checkMedia ||[]).length<1){
                        _cover.push(mediaItem);
                    }
                    return mediaItem;
                });
            }

            if ((item.cta || []).length === 0) {
                if (item.mode.code === PeerActivityModeCode.ACTIVITY
                    && (
                        (item?.connector?.handler && item?.connector?.handler.canActivityButtons === true)
                        ||
                        (item?.channel?.connector?.handler && item?.channel?.connector?.handler.canActivityButtons === true)
                    )
                ) {
                    let ctaButton: PeerActivityActionButton = {
                        enabled: false,
                        name: '',
                        url: ''
                    }

                    if ((saleIntegrations || []).length>0) {
                        ctaButton = {
                            ...ctaButton,
                            saleIntegration: [...saleIntegrations].shift()
                        }
                    }

                    item = {
                        ...item,
                        cta: [ctaButton]
                    }
                }
            }

            return item;
        });

        let _multiPostingPeerActivity = {...multiPostingPeerActivity,
            mode: [...items].shift().mode,
            media: (_media || []).length>0 ? _media:[],
            avatar: (_avatar || []).length>0 ? _avatar:[],
            cover: (_cover || []).length>0 ? _cover:[],
        };

        const firstActiveItem = (items || []).filter((item:PeerActivityType)=>{
            return item.enabled &&
                (
                    (item?.uuid !== undefined && item.uuid !== null)
                    ||
                    (item?.eTag !== undefined && item.eTag !== null && item.eTag.length>0)
                )
        }).shift();

        if (firstActiveItem !== undefined && firstActiveItem !== null) {
            if (firstActiveItem?.text && firstActiveItem?.text.length>0
                &&
                (!(_multiPostingPeerActivity?.text && _multiPostingPeerActivity?.text.length>0))){
                _multiPostingPeerActivity = {
                    ..._multiPostingPeerActivity,
                    text: firstActiveItem.text
                }
            }
            if (firstActiveItem?.tags && firstActiveItem?.tags.length>0
                &&
                (!(_multiPostingPeerActivity?.tags && _multiPostingPeerActivity?.tags.length>0))){
                _multiPostingPeerActivity = {
                    ..._multiPostingPeerActivity,
                    tags: firstActiveItem.tags
                }
            }
            if (firstActiveItem?.title && firstActiveItem?.title.length>0
                &&
                (!(_multiPostingPeerActivity?.title && _multiPostingPeerActivity?.title.length>0))){
                _multiPostingPeerActivity = {
                    ..._multiPostingPeerActivity,
                    title: firstActiveItem.title
                }
            }
            if (firstActiveItem?.bio && firstActiveItem?.bio.length>0
                &&
                (!(_multiPostingPeerActivity?.bio && _multiPostingPeerActivity?.bio.length>0))){
                _multiPostingPeerActivity = {
                    ..._multiPostingPeerActivity,
                    bio: firstActiveItem.bio
                }
            }
            if (firstActiveItem?.description && firstActiveItem?.description.length>0
                &&
                (!(_multiPostingPeerActivity?.description && _multiPostingPeerActivity?.description.length>0))){
                _multiPostingPeerActivity = {
                    ..._multiPostingPeerActivity,
                    description: firstActiveItem.description
                }
            }
            if (firstActiveItem?.link && firstActiveItem?.link.length>0
                &&
                (!(_multiPostingPeerActivity?.link && _multiPostingPeerActivity?.link.length>0))){
                _multiPostingPeerActivity = {
                    ..._multiPostingPeerActivity,
                    link: firstActiveItem.link
                }
            }
        }

        setMultiPostingPeerActivity(_multiPostingPeerActivity);

        setCurrentItems([..._items]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[items, saleIntegrations]);

    const updateFormValues = () => {

        let newOpenChannels = [];
        let newFormValues = {};
        let activityMode=null;
        let isOnlyIdeas = true;

        if (isMultiPostingEdit){
            if (multiPostingPeerActivity?.text && multiPostingPeerActivity.text.length>0){
                newFormValues['multiposting-text']=multiPostingPeerActivity.text;
            }
            if (multiPostingPeerActivity?.tags && multiPostingPeerActivity.tags.length>0){
                newFormValues['multiposting-tags']=multiPostingPeerActivity.tags;
            }
            if (multiPostingPeerActivity?.link && multiPostingPeerActivity.link.length>0){
                newFormValues['multiposting-link']=multiPostingPeerActivity.link;
            }
            if (multiPostingPeerActivity?.title && multiPostingPeerActivity.title.length>0){
                newFormValues['multiposting-title']=multiPostingPeerActivity.title;
            }
            if (multiPostingPeerActivity?.bio && multiPostingPeerActivity.bio.length>0){
                newFormValues['multiposting-bio']=multiPostingPeerActivity.bio;
            }
            if (multiPostingPeerActivity?.description && multiPostingPeerActivity.description.length>0){
                newFormValues['multiposting-description']=multiPostingPeerActivity.description;
            }
            if (multiPostingPeerActivity?.media && (multiPostingPeerActivity.media || []).length>0){
                newFormValues['multiposting-media']=multiPostingPeerActivity.media;
            }
            if (multiPostingPeerActivity?.avatar && (multiPostingPeerActivity.avatar || []).length>0){
                newFormValues['multiposting-avatar']=multiPostingPeerActivity.avatar;
            }
            if (multiPostingPeerActivity?.cover && (multiPostingPeerActivity.cover || []).length>0){
                newFormValues['multiposting-cover']=multiPostingPeerActivity.cover;
            }
        }

        if (currentItems.length>0){

            if (isNumeric([...currentItems].shift().activeFrom)){
                newFormValues['activeFrom']=moment([...currentItems].shift().activeFrom,'X').unix();
            } else {
                newFormValues['activeFrom']=moment([...currentItems].shift().activeFrom).unix();
            }

            newFormValues['type']=[...currentItems].shift().type;

            currentItems.filter((item:PeerActivityType)=>{return item.enabled}).map((item:PeerActivityType, key: number)=>{

                newOpenChannels.push(item.channel.uuid);

                newFormValues[item.channel.uuid + '_enabled']=item.enabled;

                item.serviceMessages=[];

                if (item?.channel?.connector?.handler?.code && item.channel.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS){
                    isOnlyIdeas= false;
                }

                activityMode = item.mode.code;

                if (item.uuid !== undefined && item.uuid !== null) {
                    setNew(false);
                }

                if (item?.channel?.connector?.handler?.code){

                    let privacySettings = null;

                    switch (item.channel.connector.handler.code){
                        case connectorHandlerCode.CONNECTOR_HANDLER_TIKTOK:
                            if (activityMode === PeerActivityModeCode.ACTIVITY) {
                                newFormValues[item.channel.uuid + '_isCommentsDisabled'] = true;
                                newFormValues[item.channel.uuid + '_isDuetDisabled'] = true;
                                newFormValues[item.channel.uuid + '_isStitchDisabled'] = true;
                                newFormValues[item.channel.uuid + '_privacy'] = null;
                                privacySettings = peerActivityPrivacyTikTok;

                                if (item.isCommentsDisabled !== undefined) {
                                    newFormValues[item.channel.uuid + '_isCommentsDisabled'] = item.isCommentsDisabled;
                                }
                                if (item.isDuetDisabled !== undefined) {
                                    newFormValues[item.channel.uuid + '_isDuetDisabled'] = item.isDuetDisabled;
                                }
                                if (item.isStitchDisabled !== undefined) {
                                    newFormValues[item.channel.uuid + '_isStitchDisabled'] = item.isStitchDisabled;
                                }
                            }
                            break;
                        case connectorHandlerCode.CONNECTOR_HANDLER_VK:
                        case connectorHandlerCode.CONNECTOR_HANDLER_TWITTER:
                            if (activityMode === PeerActivityModeCode.ACTIVITY) {
                                newFormValues[item.channel.uuid + '_isCommentsDisabled'] = false;

                                if (item.isCommentsDisabled !== undefined) {
                                    newFormValues[item.channel.uuid + '_isCommentsDisabled'] = item.isCommentsDisabled;
                                }
                            }
                            break;
                        case connectorHandlerCode.CONNECTOR_HANDLER_LINKEDIN:
                            if (activityMode === PeerActivityModeCode.ACTIVITY) {
                                newFormValues[item.channel.uuid + '_privacy'] = null;
                                newFormValues[item.channel.uuid + '_isForwardDisabled'] = false;
                                if (item.isForwardDisabled !== undefined) {
                                    newFormValues[item.channel.uuid + '_isForwardDisabled'] = item.isForwardDisabled;
                                }

                                privacySettings = peerActivityPrivacyLinkedId;
                            }
                            break;
                        case connectorHandlerCode.CONNECTOR_HANDLER_YOUTUBE:
                            if (activityMode === PeerActivityModeCode.ACTIVITY || activityMode === PeerActivityModeCode.SHORTS) {
                                newFormValues[item.channel.uuid + '_privacy'] = null;
                                privacySettings = peerActivityPrivacyYouTube;
                            }
                            break;
                        case connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM:
                            if (activityMode === PeerActivityModeCode.ACTIVITY) {
                                newFormValues[item.channel.uuid + '_isSilent'] = false;
                                newFormValues[item.channel.uuid + '_isSpoiler'] = false;
                                newFormValues[item.channel.uuid + '_isForwardDisabled'] = false;

                                if (item.isSilent !== undefined) {
                                    newFormValues[item.channel.uuid + '_isSilent'] = item.isSilent;
                                }
                                if (item.isSpoiler !== undefined) {
                                    newFormValues[item.channel.uuid + '_isSpoiler'] = item.isSpoiler;
                                }
                                if (item.isForwardDisabled !== undefined) {
                                    newFormValues[item.channel.uuid + '_isForwardDisabled'] = item.isForwardDisabled;
                                }
                            }
                            break;
                        case connectorHandlerCode.CONNECTOR_HANDLER_PINTEREST:
                            if (item.enabled) {
                                if ((item.media || []).filter((mediaItem: MainFileType) => {
                                    return isVideo(mediaItem) && mediaItem.enabled
                                }).length > 0) {
                                    if ((item.cover || []).filter((mediaItem: MainFileType) => {
                                        return mediaItem.enabled
                                    }).length < 1) {
                                        item.serviceMessages.push({
                                            type: ServiceMessageTypes.ERROR,
                                            message: t('validator:coverRequired')
                                        });
                                    }
                                }
                            }
                            break;
                    }

                    if (privacySettings !== null) {
                        if (item.isPublicAccess !== undefined && item.isPublicAccess){
                            newFormValues[item.channel.uuid + '_privacy']=privacySettings.filter((privacyItem:PeerActivityPrivacyItemType)=>{
                                return privacyItem.uuid === PeerActivityPrivacy.PUBLIC && {...privacyItem, name:t('privacy:'+privacyItem.name)}
                            }).shift();
                        }
                        if (item.isFriendsAccess !== undefined && item.isFriendsAccess){
                            newFormValues[item.channel.uuid + '_privacy']=privacySettings.filter((privacyItem:PeerActivityPrivacyItemType)=>{
                                return privacyItem.uuid === PeerActivityPrivacy.FRIENDS && {...privacyItem, name:t('privacy:'+privacyItem.name)}
                            }).shift();
                        }
                        if (item.isSelfAccess !== undefined && item.isSelfAccess){
                            newFormValues[item.channel.uuid + '_privacy']=privacySettings.filter((privacyItem:PeerActivityPrivacyItemType)=>{
                                return privacyItem.uuid === PeerActivityPrivacy.SELF && {...privacyItem, name:t('privacy:'+privacyItem.name)}
                            }).shift();
                        }
                        if (item.isLoggedInAccess !== undefined && item.isLoggedInAccess){
                            newFormValues[item.channel.uuid + '_privacy']=privacySettings.filter((privacyItem:PeerActivityPrivacyItemType)=>{
                                return privacyItem.uuid === PeerActivityPrivacy.LOGGED_IN && {...privacyItem, name:t('privacy:'+privacyItem.name)}
                            }).shift();
                        }

                        newFormValues[item.channel.uuid + '_privacyItems'] = (privacySettings || []).map((privacyItem: PeerActivityPrivacyItemType) => {
                            return {...privacyItem, name: t('privacy:' + privacyItem.name)}
                        });

                        if (newFormValues[item.channel.uuid + '_privacy']===null && item.channel.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_TIKTOK){
                            item.isPublicAccess = true;
                            newFormValues[item.channel.uuid + '_privacy']=privacySettings.filter((privacyItem:PeerActivityPrivacyItemType)=>{
                                return privacyItem.uuid === PeerActivityPrivacy.PUBLIC && {...privacyItem, name:t('privacy:'+privacyItem.name)}
                            }).shift();
                        }
                    }

                }



                if (isMultiPostingEdit){
                    item = updateItemByMultiItem(item);
                }

                contentConnectorDataParam.items.map((paramItem: ContentConnectorDataParamType, paramKey: number) => {

                    if (
                        (!(Object.values(ContentConnectorDataParamExclude) as string[]).includes(paramItem.code))
                        &&
                        paramItem.handler !== undefined
                        &&
                        paramItem.handler.uuid.length>0
                        &&
                        item.channel.connector !== undefined
                        &&
                        item.channel.connector.handler !==undefined
                        &&
                        paramItem.handler.uuid === item.channel.connector.handler.uuid
                        &&
                        paramItem.mode.code === item.mode.code) {

                        if (paramItem.code === 'POST') {
                            paramItem.code = 'TEXT';
                        }

                        if (
                            (paramItem.code==='MORE_PHOTO')
                            ||
                            (paramItem.code==='COVER')
                            ||
                            (paramItem.code==='AVATAR')
                        ) {

                            let objectMediaKey = paramItem.code.toLowerCase();

                            if (paramItem.code === 'MORE_PHOTO') {
                                objectMediaKey = 'media';
                                if (item[objectMediaKey] !== undefined) {
                                    newFormValues[item.channel.uuid + '_' + paramItem.code] = item[objectMediaKey];
                                }

                            } else {
                                if (item[objectMediaKey] !== undefined) {
                                    newFormValues[item.channel.uuid + '_' + paramItem.code] = item[objectMediaKey];
                                }
                            }
                        } else if (((paramItem.code === 'LINK') || (paramItem.code === 'EXTERNAL_LINK')) && (item['externalLink']!== undefined)) {
                            newFormValues[item.channel.uuid + '_' + paramItem.code] = item['externalLink'];
                        } else if (item[paramItem.code.toLowerCase()] !== undefined) {
                            newFormValues[item.channel.uuid + '_' + paramItem.code] = item[paramItem.code.toLowerCase()];

                            if (paramItem?.params?.maxLength>0){
                                let maxLength = paramItem?.params?.maxLength;
                                let contentLength = 0;

                                if (paramItem.code === 'TEXT'){
                                    if (
                                            (
                                                (item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM)
                                                ||
                                                (item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT)
                                            )
                                            &&
                                            item?.mode?.code === PeerActivityModeCode.ACTIVITY
                                            &&
                                            item?.media && (item.media || []).length>0
                                    )
                                    {
                                        maxLength = 1024;
                                    }

                                    if (item?.text && item?.text.length>0){
                                        contentLength+=item?.text.length;
                                    }

                                    if (item?.tags && item?.tags.length>0){
                                        contentLength+=item?.tags.length;
                                    }

                                }

                                if (contentLength>maxLength){
                                    item.serviceMessages.push({
                                        type: ServiceMessageTypes.ERROR,
                                        message: t('validator:symbolLimitExceeded')+' ('+t('common:max')+': '+FormatValue(maxLength)+')'
                                    });
                                }
                            }

                        }
                    }

                    return paramItem;
                });

                if (item?.cta && (item.cta || []).length>0){
                    item.cta.map((ctaItem:PeerActivityActionButton, ctaKey:number)=>{

                        if (ctaItem?.enabled !== undefined && ctaItem?.enabled !== null){
                            newFormValues[item.channel.uuid + '_cta_enabled_' + ctaKey]=ctaItem.enabled;
                        }
                        if (ctaItem?.name !== undefined && ctaItem?.name !== null){
                            newFormValues[item.channel.uuid + '_cta_name_' + ctaKey]=ctaItem.name;
                        }
                        if (ctaItem?.url !== undefined && ctaItem?.url !== null){
                            newFormValues[item.channel.uuid + '_cta_url_' + ctaKey]=ctaItem.url;
                        }
                        if (ctaItem?.saleIntegration){
                            newFormValues[item.channel.uuid + '_cta_sale_integration_' + ctaKey]=ctaItem.saleIntegration;
                        }

                        return ctaItem;
                    });
                }

                return item;
            })
        }

        setOpenChannels([...newOpenChannels]);
        setFormValues({...newFormValues});
        setCurrentActivityMode(activityMode);
        handlePreview(newFormValues);

        if (isOnlyIdeas){
            setOnlyIdeasInForm(true);
        } else {
            setOnlyIdeasInForm(false);
        }
    }

    useEffect(() => {

        addMoreChannelsItems();
        updateFormValues();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItems]);

    const handlePreview = (formData: any) => {

        let sendData = fillSendData(formData);

        if (sendData?.peerActivities && ((sendData.peerActivities || []).length>0)) {
            setPreviewItems((sendData.peerActivities || []).filter((item: PeerActivityType)=>{return item.enabled}));
        } else {
            setPreviewItems([]);
        }

    }

    const handleChangeMultiPostingPeerActivity = (param, value) => {

        let _multiPostingPeerActivity:PeerActivityType;

        if (param === 'text'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                text: value
            }
        }
        if (param === 'tags'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                tags: value
            }
        }
        if (param === 'link'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                link: value
            }
        }
        if (param === 'title'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                title: value
            }
        }
        if (param === 'bio'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                bio: value
            }
        }
        if (param === 'description'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                description: value
            }
        }
        if (param === 'media'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                media: value
            }
        }
        if (param === 'avatar'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                avatar: value
            }
        }
        if (param === 'cover'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                cover: value
            }
        }
        if (param === 'status'){
            _multiPostingPeerActivity = {
                ...multiPostingPeerActivity,
                status: value
            }


        }
        setMultiPostingPeerActivity({..._multiPostingPeerActivity});
    }

    useEffect(()=>{
        if(isMultiPostingEdit){
           updateFormValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[multiPostingPeerActivity, isMultiPostingEdit])

    const setMobilePreview = () =>{
        dispatch(setPreviewMobile());
    }
    const setDesktopPreview = () =>{
        dispatch(setPreviewDesktop());
    }



    return (
        <>
            {currentItems && currentItems.length>0 &&
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ isOnlyIdeasInForm ? PeerActivityIdeaValidatorSchema : PeerActivityValidatorSchema }
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            getFieldProps,
                            isSubmitting,
                            isValidating}) => {

                        const handleChangePreviewData = (param, value) => {
                            let _values=values;
                            _values[param]=value;
                            handlePreview(_values);
                        }

                        return (
                        <Form noValidate={true} className={"customValidation"+(isMultiPostingEdit?' multiPostingFormCont':'')+(isShowPreviewItems?' showPreviewItemCont':'')}>
                            {isMultiPostingEdit &&
                                <div className="peerActivityFormColItem multiPostingFormMainEdit">
                                <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                                    {multiPostingPeerActivity?.mode?.code === PeerActivityModeCode.BIO &&
                                        <>
                                            <Row>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="form-title-input">{t('connectorDataParam:TITLE')}</Label>
                                                    <Field
                                                        id={"form-multiposting-title-input"}
                                                        name={"multiposting-title"}
                                                        placeholder={t('connectorDataParam:TITLE')}
                                                        component={InputText}
                                                        templates={contentTemplates.items || []}
                                                        value={getFieldProps('multiposting-title').value}
                                                        onChange={(value: string) => {
                                                            setFieldValue('multiposting-title', value);
                                                            handleChangeMultiPostingPeerActivity('title', value);
                                                        }}
                                                        isShowEmoji={true}
                                                        isShowAITextGenerate={true}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="mb-3">
                                                    <Field
                                                        id={"form-multiposting-avatar-input"}
                                                        name={'multiposting-avatar'}
                                                        placeholder={t('connectorDataParam:AVATAR')}
                                                        isMultiple={true}
                                                        component={InputFile}
                                                        value={getFieldProps('multiposting-avatar').value}
                                                        onChange={(value: MainFileType[]) => {
                                                            setFieldValue('multiposting-avatar', value);
                                                            handleChangeMultiPostingPeerActivity('avatar', value);
                                                        }}
                                                        required={false}
                                                        acceptedFiles=".jpg, .jpeg, .png"
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="mb-3">
                                                    <Field
                                                        id={"form-multiposting-cover-input"}
                                                        name={'multiposting-cover'}
                                                        placeholder={t('connectorDataParam:COVER')}
                                                        isMultiple={true}
                                                        component={InputFile}
                                                        value={getFieldProps('multiposting-cover').value}
                                                        onChange={(value: MainFileType[]) => {
                                                            setFieldValue('multiposting-cover', value);
                                                            handleChangeMultiPostingPeerActivity('cover', value);
                                                        }}
                                                        required={false}
                                                        acceptedFiles=".jpg, .jpeg, .png"
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="mb-3">
                                                    <Field
                                                        id={"form-multiposting-bio-input"}
                                                        name={"multiposting-bio"}
                                                        placeholder={t('connectorDataParam:BIO')}
                                                        component={InputEmojiArea}
                                                        templates={contentTemplates.items || []}
                                                        value={getFieldProps('multiposting-bio').value}
                                                        onChange={(value: string) => {

                                                            if (debouncerTimeout['bio'] !== undefined) {
                                                                clearTimeout(debouncerTimeout['bio']);
                                                            }
                                                            debouncerTimeout['bio'] = setTimeout(function() {
                                                                setFieldValue('multiposting-bio', value);
                                                                handleChangeMultiPostingPeerActivity('bio', value);
                                                            }, debouncerTimeoutValue);

                                                        }}
                                                        isShowEmoji={true}
                                                        isShowAITextGenerate={true}
                                                    />
                                                </div>
                                            </Row>
                                        </>
                                    }
                                    {multiPostingPeerActivity?.mode?.code === PeerActivityModeCode.ACTIVITY &&
                                            <Row>
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="form-text-input">{t('connectorDataParam:POST')}</Label>
                                                    <Field
                                                        id={"form-multiposting-text-input"}
                                                        name={"multiposting-text"}
                                                        placeholder={t('connectorDataParam:POST')}
                                                        component={InputEmojiArea}
                                                        templates={contentTemplates.items || []}
                                                        value={getFieldProps('multiposting-text').value}
                                                        onChange={(value: string) => {

                                                            if (debouncerTimeout['text'] !== undefined) {
                                                                clearTimeout(debouncerTimeout['text']);
                                                            }
                                                            debouncerTimeout['text'] = setTimeout(function() {
                                                                setFieldValue('multiposting-text', value);
                                                                handleChangeMultiPostingPeerActivity('text', value)
                                                            }, debouncerTimeoutValue);

                                                            //setFieldValue('multiposting-text', value);
                                                            //debouncedHandleChangeMultiPostingPeerActivity('text', value);
                                                            //handleChangeMultiPostingPeerActivity('text', value);
                                                        }}
                                                        isShowEmoji={true}
                                                        isShowAITextGenerate={true}
                                                    />
                                                </div>
                                            </Row>
                                    }
                                    {(
                                        multiPostingPeerActivity?.mode?.code === PeerActivityModeCode.ACTIVITY
                                        ||
                                        multiPostingPeerActivity?.mode?.code === PeerActivityModeCode.SHORTS
                                        ||
                                        multiPostingPeerActivity?.mode?.code === PeerActivityModeCode.STORY
                                    )
                                        &&
                                        <>
                                            <Row>
                                                <div className="mb-3">
                                                    <Field
                                                        id={"form-multiposting-media-input"}
                                                        name={'multiposting-media'}
                                                        placeholder={t('connectorDataParam:MORE_PHOTO')}
                                                        isMultiple={true}
                                                        component={InputFile}
                                                        value={getFieldProps('multiposting-media').value}
                                                        onChange={(value: MainFileType[]) => {
                                                            setFieldValue('multiposting-media', value);
                                                            handleChangeMultiPostingPeerActivity('media', value);
                                                        }}
                                                        required={false}
                                                        acceptedFiles=".jpg, .jpeg, .png, .mp4, .mov"
                                                    />
                                                </div>
                                            </Row>
                                            {getFieldProps('multiposting-media').value !== undefined && (getFieldProps('multiposting-media').value || []).filter((mediaItem:MainFileType)=>{
                                            return isVideo(mediaItem)}).length>0 &&
                                            <Row>
                                                <div className="mb-3">
                                                    <Field
                                                    id={"form-multiposting-cover-input"}
                                                    name={'multiposting-cover'}
                                                    placeholder={t('connectorDataParam:COVER')}
                                                    isMultiple={true}
                                                    component={InputFile}
                                                    value={getFieldProps('multiposting-cover').value}
                                                    onChange={(value: MainFileType[]) => {
                                                    setFieldValue('multiposting-cover', value);
                                                    handleChangeMultiPostingPeerActivity('cover', value);
                                                }}
                                                    required={false}
                                                    acceptedFiles=".jpg, .jpeg, .png"
                                                    />
                                                </div>
                                            </Row>
                                            }
                                        </>
                                    }
                                    {
                                        multiPostingPeerActivity?.mode?.code === PeerActivityModeCode.ACTIVITY &&
                                        <Row>
                                            <div className="mb-3">
                                                <Field
                                                    id={"form-multiposting-link-input"}
                                                    name={"multiposting-link"}
                                                    placeholder={t('connectorDataParam:LINK')}
                                                    component={InputText}
                                                    value={getFieldProps("multiposting-link").value}
                                                    onChange={(value: string) => {
                                                        setFieldValue("multiposting-link", value);
                                                        handleChangeMultiPostingPeerActivity('link', value);
                                                    }}
                                                />
                                            </div>
                                        </Row>
                                    }
                                    {multiPostingPeerActivity?.mode?.code !== PeerActivityModeCode.BIO &&
                                    <Row>
                                        <div className="mb-3">
                                                <Field
                                                    id={"form-multiposting-tags-input"}
                                                    name={'multiposting-tags'}
                                                    placeholder={t('connectorDataParam:TAGS')}
                                                    isMultiple={true}
                                                    component={InputTagText}
                                                    value={getFieldProps('multiposting-tags').value}
                                                    isShowAITextGenerate={true}
                                                    isShowCopy={true}
                                                    text={getFieldProps('multiposting-text').value} //for tags suggestion
                                                    onChange={(value: string) => {
                                                        setFieldValue('multiposting-tags', value);
                                                        handleChangeMultiPostingPeerActivity('tags', value);
                                                    }}
                                                    required={false}
                                                />
                                        </div>
                                    </Row>
                                    }

                                    { !(multiPostingPeerActivity?.mode?.code === PeerActivityModeCode.BIO && multiPostingPeerActivity.status === PeerActivityStatus.STATUS_FINAL) && (multiPostingPeerActivity.status !== PeerActivityStatus.STATUS_FINAL) &&
                                        <Row className="d-flex flex-row flex-nowrap border-dashed pt-3 mt-3 pb-3 border border-2 rounded-3 m-0">
                                            <Col className="d-flex flex-row flex-shrink-0 flex-grow-0 align-items-center">
                                                <InputSwitch id={'multiposting_publishReady'}
                                                             value={(multiPostingPeerActivity.status === PeerActivityStatus.STATUS_READY || multiPostingPeerActivity.status === PeerActivityStatus.STATUS_ON_PUBLISH || multiPostingPeerActivity.status === PeerActivityStatus.STATUS_PUBLISHING || multiPostingPeerActivity.status === PeerActivityStatus.STATUS_FINAL)}
                                                             onChange={() => {

                                                                 if (multiPostingPeerActivity.status === PeerActivityStatus.STATUS_READY){
                                                                     handleChangeMultiPostingPeerActivity('status', PeerActivityStatus.STATUS_NEW);
                                                                 } else {
                                                                     handleChangeMultiPostingPeerActivity('status', PeerActivityStatus.STATUS_READY);
                                                                 }

                                                             }}
                                                             className="form-switch-md d-flex flex-column justify-content-center align-items-end"/>
                                            </Col>
                                            <Col className="d-flex flex-row flex-grow-1 align-items-center">

                                                { (multiPostingPeerActivity.status === PeerActivityStatus.STATUS_READY || multiPostingPeerActivity.status === PeerActivityStatus.STATUS_ON_PUBLISH || multiPostingPeerActivity.status === PeerActivityStatus.STATUS_PUBLISHING || multiPostingPeerActivity.status === PeerActivityStatus.STATUS_FINAL) ?
                                                    <div className="d-flex flex-row justify-content-end flex-grow-1">
                                                        <button className="btn btn-primary btn-load" type="button" onClick={()=>{
                                                            handleMultiPublishButton()
                                                        }}>
                                                                            <span className="d-flex align-items-center">
                                                                                { (multiPostingPeerActivity.status === PeerActivityStatus.STATUS_ON_PUBLISH || multiPostingPeerActivity.status === PeerActivityStatus.STATUS_PUBLISHING ) ?
                                                                                    <>
                                                                                        <span className="spinner-border flex-shrink-0" role="status">
                                                                                            <span className="visually-hidden">
                                                                                                {t('common:processing')}...
                                                                                            </span>
                                                                                        </span>
                                                                                        <span className="flex-grow-1 ms-2">
                                                                                            {t('common:processing')}...
                                                                                        </span>
                                                                                    </>
                                                                                    :
                                                                                    <span className="flex-grow-1 ms-2">
                                                                                        { multiPostingPeerActivity.status === PeerActivityStatus.STATUS_FINAL
                                                                                            ?
                                                                                            <>
                                                                                            {t('peerActivity:unPublishNow')}{" "}
                                                                                                ({(currentItems || []).filter((item: PeerActivityType)=>{
                                                                                                return item.enabled && item.status === PeerActivityStatus.STATUS_FINAL
                                                                                            }).length})
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {t('peerActivity:publishNow')}{" "}
                                                                                                ({(currentItems || []).filter((item: PeerActivityType)=>{
                                                                                                    return item.enabled && item.status !== PeerActivityStatus.STATUS_FINAL
                                                                                            }).length})
                                                                                            </>
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </span>
                                                        </button>
                                                    </div>
                                                    :
                                                    t('peerActivity:publishReady')
                                                }
                                            </Col>
                                        </Row>
                                    }

                                </div>
                                </SimpleBar>
                                </div>
                            }
                            <div className="peerActivityFormColItem">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                            <div className="p-4">
                            <Row>
                                <div className={ "mb-3"+ (touched['type'] ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-title-input">{t('peerActivityType:activityType')}</Label>
                                    <Field
                                        id="form-project-select"
                                        name="dataItemType"
                                        placeholder={t('peerActivityType:chooseActivityType')}
                                        component={InputSelect}
                                        value={getFieldProps('type').value}
                                        onChange={(name: string) => {
                                            setFieldValue('type', name);
                                            handleChangePreviewData('type', name);

                                        }}
                                        items={peerActivityTypes.items || []}
                                        className={errors['type'] && touched['type'] && 'form-control is-invalid'}
                                    />
                                    {errors['type'] && touched['type'] ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors['type'])}
                                        </div>
                                    ) : touched['type'] && !errors['type'] ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched['activeFrom'] ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor="form-activeFrom-input">{t('peerActivity:activityDate')}</Label>
                                    <Field
                                        id="form-activeFrom-input"
                                        name="activeFrom"
                                        component={InputDateTimePicker}
                                        value={getFieldProps('activeFrom').value}
                                        onChange={(value: number) => {
                                            setFieldValue('activeFrom', value);
                                            handleChangePreviewData('activeFrom', value);
                                        }}
                                        className={errors['activeFrom'] && touched['activeFrom'] && 'form-control is-invalid'}
                                    />
                                    {errors['activeFrom'] && touched['activeFrom'] ? (
                                        <div className="invalid-feedback">
                                            {t('validator:'+errors['activeFrom'])}
                                        </div>
                                    ) :  touched['activeFrom'] && !errors['activeFrom'] ?
                                        <div className="valid-feedback">
                                            {t('common:looksGood')}
                                        </div>
                                        : ''
                                    }
                                    {!isScheduledPosting &&
                                        <div className="mt-2 alert alert-warning alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-2" role="alert">
                                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>
                                            <span dangerouslySetInnerHTML={{__html: t('plan:restrictionScheduledPostingText').replace(/%link%/,route.SALE_PLANS)}}/>
                                        </div>
                                    }
                                </div>
                            </Row>

                            <Row>
                                <div className="mb-3">
                                    <div className="d-flex flex-row flex-nowrap align-items-center mb-2">
                                        <div className="d-flex flex-row flex-grow-1 align-items-center">
                                         <Label className="form-label mb-0" htmlFor="form-channels-input">{t('channel:channels')}</Label>
                                        </div>
                                        <div className="d-flex flex-row flex-grow-0">
                                            <a type="button" target="_blank" rel="noreferrer"
                                               href={route.CONTENT_CHANNELS_LIST}
                                               className="btn btn-sm btn-outline-primary btn-icon waves-effect waves-light"><i className="ri-add-line"/>
                                            </a>
                                        </div>
                                    </div>

                                    <Accordion open={openChannels}     {...{
                                        toggle: (accordionItem) => {
                                            setFieldValue(accordionItem + '_enabled', (openChannels || []).filter((channelUuid)=>{return channelUuid===accordionItem}).length===0);
                                            toggleChannel(accordionItem)
                                        }
                                    }}
                                               className="accordion-icon-none"
                                    >
                                        {currentItems.map((item: PeerActivityType, key: number) => (
                                            <AccordionItem key={item.channel.uuid} onChange={()=>{
                                                handlePreview(values);
                                            }}>

                                                <AccordionHeader targetId={item.channel.uuid}>
                                                    <div className="d-flex flex-row flex-nowrap flex-grow-1 mw-0">
                                                        <div className="d-flex flex-row flex-grow-1 mw-0">
                                                            <ChannelItemLine item={item.channel} onClick={()=>{}}/>
                                                        </div>

                                                        {item.isPin && item.isPin === true ?
                                                            <a className="d-flex flex-column flex-shrink-0 fs-18 justify-content-center" onClick={(event)=>handlePinButton(event,item)}><i className="ri-pushpin-2-line me-1 align-middle"/></a>
                                                            :
                                                            item?.uuid
                                                            && item.uuid.length>0
                                                            && item.mode.code === PeerActivityModeCode.ACTIVITY
                                                            && item.channel
                                                            && item.channel.connector
                                                            && item.channel.connector.handler
                                                            && item.channel.connector.handler.canPinPost === true &&
                                                            <a className="d-flex flex-column flex-shrink-0 fs-18 justify-content-center text-muted"
                                                               onClick={(event)=>handlePinButton(event,item)}
                                                            ><i className="ri-pushpin-line me-1 align-middle"/></a>
                                                        }

                                                        <InputSwitch id={'id'} value={item.enabled} onChange={onChange}
                                                                     className="ms-3 form-switch-md d-flex flex-column justify-content-center align-items-end"/>
                                                    </div>
                                                </AccordionHeader>

                                                <AccordionBody accordionId={item.channel.uuid} className={(isMultiPostingEdit?'pb-0':'')}>

                                                    {item?.serviceMessages && (item.serviceMessages || []).length>0 &&
                                                        (item.serviceMessages || []).map((serviceMessage:ServiceMessageType, serviceMessageIndex:number)=>{

                                                            return(
                                                                <div key={'serviceMessage_'+item.channel.uuid+'_'+serviceMessageIndex}>
                                                                    <ServiceMessageItem item={serviceMessage}/>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {isMultiPostingEdit ?
                                                        <>
                                                            {getFieldProps(item.channel.uuid+'_AVATAR').value !== undefined && (getFieldProps(item.channel.uuid+'_AVATAR').value || []).length>0 &&
                                                                <>
                                                                    <Label className="form-label mb-0" htmlFor="form-channels-input">{t('connectorDataParam:AVATAR')}</Label>
                                                                    <ul className="d-flex list-unstyled mb-0">
                                                                        {(getFieldProps(item.channel.uuid+'_AVATAR').value || []).map((mediaItem:MainFileType,mediaKey:number)=>{

                                                                            let paramItem = (contentConnectorDataParam.items || []).filter((_paramItem: ContentConnectorDataParamType) => {
                                                                                return _paramItem?.handler?.uuid === item?.channel?.connector?.handler?.uuid
                                                                                    &&
                                                                                    _paramItem?.mode?.code === item?.mode?.code
                                                                                    &&
                                                                                    _paramItem.code === 'AVATAR'
                                                                            }).shift();

                                                                            return (
                                                                                <li key={item.channel.uuid+'_avatar_'+mediaKey} className={"d-flex flex-column align-items-center me-2 mb-2"}>
                                                                                    <MediaPreview
                                                                                        item={mediaItem}
                                                                                        isPopup={true}
                                                                                        isShowSwitch={true}
                                                                                        idSwitch={item.channel.uuid+'_avatar_'+mediaKey}

                                                                                        acceptedFiles = {paramItem?.params && paramItem?.params?.acceptedFiles && paramItem.params.acceptedFiles}
                                                                                        acceptedDimensions = {paramItem?.params && paramItem?.params?.acceptedDimensions && paramItem.params.acceptedDimensions}
                                                                                        isMultiple={paramItem?.isMultiple}

                                                                                        onChange={(value)=>{

                                                                                            let media = getFieldProps(item.channel.uuid+'_AVATAR').value.map((tmpMediaItem:MainFileType, tmpMediaItemKey:number)=>{

                                                                                                if (tmpMediaItemKey === mediaKey){
                                                                                                    tmpMediaItem.enabled = value;
                                                                                                } else if (paramItem?.isMultiple === false && value){
                                                                                                    tmpMediaItem.enabled = false;
                                                                                                }

                                                                                                return tmpMediaItem;
                                                                                            });

                                                                                            setFieldValue(item.channel.uuid+'_AVATAR', media);
                                                                                        }}
                                                                                    />
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </>
                                                            }
                                                            {
                                                                getFieldProps(item.channel.uuid+'_COVER').value !== undefined && (getFieldProps(item.channel.uuid+'_COVER').value || []).length>0 && (
                                                                    item?.mode?.code === PeerActivityModeCode.BIO
                                                                    ||
                                                                    (
                                                                        getFieldProps(item.channel.uuid+'_MORE_PHOTO').value !== undefined && (getFieldProps(item.channel.uuid+'_MORE_PHOTO').value || []).filter((mediaItem:MainFileType)=>{return isVideo(mediaItem) && mediaItem.enabled}).length>0
                                                                    )
                                                                ) &&
                                                                <>
                                                                    <Label className="form-label mb-0" htmlFor="form-channels-input">{t('connectorDataParam:COVER')}</Label>
                                                                    <ul className="d-flex list-unstyled mb-0">
                                                                        {(getFieldProps(item.channel.uuid+'_COVER').value || []).map((mediaItem:MainFileType,mediaKey:number)=>{

                                                                            let paramItem = (contentConnectorDataParam.items || []).filter((_paramItem: ContentConnectorDataParamType) => {
                                                                                return _paramItem?.handler?.uuid === item?.channel?.connector?.handler?.uuid
                                                                                    &&
                                                                                    _paramItem?.mode?.code === item?.mode?.code
                                                                                    &&
                                                                                    _paramItem.code === 'COVER'
                                                                            }).shift();

                                                                            return (
                                                                                <li key={item.channel.uuid+'_cover_'+mediaKey} className={"d-flex flex-column align-items-center me-2 mb-2"}>
                                                                                    <MediaPreview
                                                                                        item={mediaItem}
                                                                                        isPopup={true}
                                                                                        isShowSwitch={true}
                                                                                        idSwitch={item.channel.uuid+'_cover_'+mediaKey}

                                                                                        acceptedFiles = {paramItem?.params && paramItem?.params?.acceptedFiles && paramItem.params.acceptedFiles}
                                                                                        acceptedDimensions = {paramItem?.params && paramItem?.params?.acceptedDimensions && paramItem.params.acceptedDimensions}
                                                                                        isMultiple={paramItem?.isMultiple}

                                                                                        onChange={(value)=>{

                                                                                            let media = getFieldProps(item.channel.uuid+'_COVER').value.map((tmpMediaItem:MainFileType, tmpMediaItemKey:number)=>{

                                                                                                if (tmpMediaItemKey === mediaKey){
                                                                                                    tmpMediaItem.enabled = value;
                                                                                                } else if (paramItem?.isMultiple === false && value){
                                                                                                    tmpMediaItem.enabled = false;
                                                                                                }

                                                                                                return tmpMediaItem;
                                                                                            });

                                                                                            setFieldValue(item.channel.uuid+'_COVER', media);

                                                                                        }}
                                                                                    />
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </>
                                                            }
                                                            {getFieldProps(item.channel.uuid+'_MORE_PHOTO').value !== undefined && (getFieldProps(item.channel.uuid+'_MORE_PHOTO').value || []).length>0 &&
                                                                <>
                                                                <Label className="form-label mb-0" htmlFor="form-channels-input">{t('connectorDataParam:MORE_PHOTO')}</Label>
                                                                    <ul className="d-flex list-unstyled mb-0">
                                                                        {(getFieldProps(item.channel.uuid+'_MORE_PHOTO').value || []).map((mediaItem:MainFileType,mediaKey:number)=>{

                                                                            let paramItem = (contentConnectorDataParam.items || []).filter((_paramItem: ContentConnectorDataParamType) => {
                                                                                return _paramItem?.handler?.uuid === item?.channel?.connector?.handler?.uuid
                                                                                        &&
                                                                                    _paramItem?.mode?.code === item?.mode?.code
                                                                                        &&
                                                                                    _paramItem.code === 'MORE_PHOTO'
                                                                            }).shift();

                                                                            return (
                                                                                   <li key={item.channel.uuid+'_media_'+mediaKey} className={"d-flex flex-column align-items-center me-2 mb-2"}>
                                                                                       <MediaPreview
                                                                                           item={mediaItem}
                                                                                           isPopup={true}
                                                                                           isShowSwitch={true}
                                                                                           idSwitch={item.channel.uuid+'_media_'+mediaKey}

                                                                                           acceptedFiles = {paramItem?.params && paramItem?.params?.acceptedFiles && paramItem.params.acceptedFiles}
                                                                                           acceptedDimensions = {paramItem?.params && paramItem?.params?.acceptedDimensions && paramItem.params.acceptedDimensions}
                                                                                           isMultiple={paramItem?.isMultiple}

                                                                                           onChange={(value)=>{

                                                                                               let media = getFieldProps(item.channel.uuid+'_MORE_PHOTO').value.map((tmpMediaItem:MainFileType, tmpMediaItemKey:number)=>{

                                                                                                   if (tmpMediaItemKey === mediaKey){
                                                                                                       tmpMediaItem.enabled = value;
                                                                                                   } else if (paramItem?.isMultiple === false && value){
                                                                                                       tmpMediaItem.enabled = false;
                                                                                                   }

                                                                                                   return tmpMediaItem;
                                                                                               });

                                                                                               setFieldValue(item.channel.uuid+'_MORE_PHOTO', media);

                                                                                           }}
                                                                                       />
                                                                                   </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </>
                                                            }
                                                            {(
                                                                item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_YOUTUBE
                                                                ||
                                                                item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_PINTEREST
                                                                ||
                                                                item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_WORDPRESS
                                                                )
                                                                &&
                                                                (
                                                                    item?.mode?.code === PeerActivityModeCode.ACTIVITY
                                                                    ||
                                                                    item?.mode?.code === PeerActivityModeCode.SHORTS
                                                                )
                                                                &&
                                                                <Row key={'paramItem' + item.channel.uuid + '_TITLE'}>
                                                                    <div className="mb-3">
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + '_title-input'}
                                                                            name={item.channel.uuid + '_title'}
                                                                            placeholder={t('connectorDataParam:TITLE')}
                                                                            component={InputText}
                                                                            value={getFieldProps(item.channel.uuid + '_TITLE').value}
                                                                            onChange={(value: string) => {
                                                                                setFieldValue(item.channel.uuid + '_TITLE', value);

                                                                                handleChangePreviewData(item.channel.uuid + '_TITLE', value);
                                                                            }}
                                                                            required={true}
                                                                        />

                                                                    </div>
                                                                </Row>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                    {contentConnectorDataParam.items.map((paramItem: ContentConnectorDataParamType, paramKey: number) => (
                                                        paramItem.handler
                                                        &&
                                                        paramItem.handler.uuid
                                                        &&
                                                        item.channel.connector
                                                        &&
                                                        item.channel.connector.handler
                                                        &&
                                                        paramItem.handler.uuid === item.channel.connector.handler.uuid &&

                                                        paramItem.mode.code === item.mode.code &&
                                                        <div key={'paramItemBlock' + item.channel.uuid + '_' + paramKey}>
                                                            {paramItem.type === ContentConnectorDataParamTypeType.STRING &&
                                                                <Row key={'paramItem' + item.channel.uuid + '_' + paramKey}>
                                                                    <div className="mb-3">
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + '_' + paramItem.code + "-input"}
                                                                            name={item.channel.uuid + '_' + paramItem.code}
                                                                            placeholder={t('connectorDataParam:'+paramItem.code)}
                                                                            component={InputText}
                                                                            value={getFieldProps(item.channel.uuid + '_' + paramItem.code).value}
                                                                            onChange={(value: string) => {
                                                                                setFieldValue(item.channel.uuid + '_' + paramItem.code, value);

                                                                                handleChangePreviewData(item.channel.uuid + '_' + paramItem.code, value);
                                                                            }}
                                                                            required={paramItem.isRequired}
                                                                        />

                                                                    </div>
                                                                </Row>}
                                                            {paramItem.type === ContentConnectorDataParamTypeType.TEXT &&
                                                                <>
                                                                <Row key={'paramItem' + item.channel.uuid + '_' + paramKey}>
                                                                    <div className="mb-3">
                                                                        {paramItem.params?.canUseHtml && paramItem.params.canUseHtml ?
                                                                            <>
                                                                            <div className="d-flex justify-content-end">
                                                                                <div className="mb-2 d-flex flex-column align-items-center">
                                                                                    <Field
                                                                                        id="form-enabled-input"
                                                                                        placeholder={t('common:Html')}
                                                                                        name={'isHtml_'+item.channel.uuid + '_' + paramItem.code}
                                                                                        component={InputSwitch}
                                                                                        value = {getFieldProps('isHtml_'+item.channel.uuid + '_' + paramItem.code).value}
                                                                                        onChange={(enabled:boolean) => {
                                                                                            setFieldValue('isHtml_'+item.channel.uuid + '_' + paramItem.code, !getFieldProps('isHtml_'+item.channel.uuid + '_' + paramItem.code).value);

                                                                                            handleChangePreviewData('isHtml_'+item.channel.uuid + '_' + paramItem.code, getFieldProps('isHtml_'+item.channel.uuid + '_' + paramItem.code).value);
                                                                                        }}
                                                                                        className="sm"
                                                                                        required={false}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <Field
                                                                                id={"form-" + item.channel.uuid + '_' + paramItem.code + "-input"}
                                                                                name={item.channel.uuid + '_' + paramItem.code}
                                                                                placeholder={t('connectorDataParam:'+paramItem.code)}
                                                                                component={InputEditorSwitch}
                                                                                templates={contentTemplates.items || []}
                                                                                value={getFieldProps(item.channel.uuid + '_' + paramItem.code).value}
                                                                                onChange={(value: string) => {
                                                                                    setFieldValue(item.channel.uuid + '_' + paramItem.code, value);

                                                                                    handleChangePreviewData(item.channel.uuid + '_' + paramItem.code, value);
                                                                                }}
                                                                                isShowEmoji={true}
                                                                                isShowAITextGenerate={true}
                                                                                required={paramItem.isRequired}
                                                                                maxLength={(item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM || item?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM_BOT) && (getFieldProps(item.channel.uuid + '_MORE_PHOTO' )?.value || []).length>0 && item.mode.code === PeerActivityModeCode.ACTIVITY ? 1024 : paramItem.params && paramItem.params.maxLength && paramItem.params.maxLength}
                                                                                isHtml = {getFieldProps('isHtml_'+item.channel.uuid + '_' + paramItem.code).value}
                                                                            />
                                                                                </>
                                                                            :
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + '_' + paramItem.code + "-input"}
                                                                            name={item.channel.uuid + '_' + paramItem.code}
                                                                            placeholder={t('connectorDataParam:'+paramItem.code)}
                                                                            component={InputEmojiArea}
                                                                            templates={contentTemplates.items || []}
                                                                            value={getFieldProps(item.channel.uuid + '_' + paramItem.code).value}
                                                                            onChange={(value: string) => {

                                                                                let timerKey = item.channel.uuid + '_' + paramItem.code;

                                                                                if (debouncerTimeout[timerKey] !== undefined) {
                                                                                    clearTimeout(debouncerTimeout[timerKey]);
                                                                                }

                                                                                debouncerTimeout[timerKey] = setTimeout(function() {

                                                                                    setFieldValue(timerKey, value);
                                                                                    handleChangePreviewData(timerKey, value);

                                                                                }, debouncerTimeoutValue);

                                                                            }}
                                                                            isShowEmoji={true}
                                                                            isShowAITextGenerate={true}
                                                                            required={paramItem.isRequired}
                                                                            maxLength={paramItem.params && paramItem.params.maxLength && paramItem.params.maxLength}
                                                                        />
                                                                        }
                                                                    </div>
                                                                </Row>
                                                                </>
                                                            }
                                                            {paramItem.type === ContentConnectorDataParamTypeType.LIST && !(Object.values(ContentConnectorDataParamExclude) as string[]).includes(paramItem.code) &&
                                                                <Row key={'paramItem' + item.channel.uuid + '_' + paramKey}>
                                                                    <div className="mb-3">
                                                                        {paramItem.code === 'TAGS' &&
                                                                            <Field
                                                                                id={"form-" + item.channel.uuid + '_' + paramItem.code + "-input"}
                                                                                name={item.channel.uuid + '_' + paramItem.code}
                                                                                placeholder={t('connectorDataParam:'+paramItem.code)}
                                                                                isMultiple={paramItem.isMultiple}
                                                                                component={InputTagText}
                                                                                value={getFieldProps(item.channel.uuid + '_' + paramItem.code).value}
                                                                                isShowAITextGenerate={true}
                                                                                isShowCopy={true}
                                                                                text={getFieldProps(item.channel.uuid + '_TEXT').value}
                                                                                onChange={(value: string) => {
                                                                                    setFieldValue(item.channel.uuid + '_' + paramItem.code, value);

                                                                                    handleChangePreviewData(item.channel.uuid + '_' + paramItem.code, value);
                                                                                }}
                                                                                required={paramItem.isRequired}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </Row>
                                                            }
                                                            {paramItem.type === ContentConnectorDataParamTypeType.FILE && !(Object.values(ContentConnectorDataParamExclude) as string[]).includes(paramItem.code) &&
                                                                <Row key={'paramItem' + item.channel.uuid + '_' + paramKey}>
                                                                    <div className="mb-3">
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + '_' + paramItem.code + "-input"}
                                                                            name={item.channel.uuid + '_' + paramItem.code}
                                                                            placeholder={t('connectorDataParam:'+paramItem.code)}
                                                                            acceptedFiles = {paramItem.params && paramItem.params.acceptedFiles && paramItem.params.acceptedFiles}
                                                                            acceptedDimensions = {paramItem.params && paramItem.params.acceptedDimensions && paramItem.params.acceptedDimensions}
                                                                            isMultiple={paramItem.isMultiple}
                                                                            component={InputFile}
                                                                            value={getFieldProps(item.channel.uuid + '_' + paramItem.code).value}
                                                                            onChange={(value: MainFileType[]) => {
                                                                                setFieldValue(item.channel.uuid + '_' + paramItem.code, value);

                                                                                handleChangePreviewData(item.channel.uuid + '_' + paramItem.code, value);
                                                                            }}
                                                                            required={paramItem.isRequired}
                                                                        />
                                                                    </div>
                                                                </Row>
                                                            }
                                                        </div>
                                                    ))}
                                                        </>
                                                    }

                                                    {item?.cta && (item.cta || []).length>0 &&
                                                        <>
                                                            {(item.cta || []).map((ctaItem:PeerActivityActionButton, ctaKey:number)=>{
                                                                return (
                                                                    <div key={item.channel.uuid+'_additionalCTACont_'+ctaKey}>
                                                                    <Row key={'additionalCTACont_' + item.channel.uuid} className="mb-3 d-flex flex-row flex-nowrap">
                                                                        <div className="d-flex flex-row">
                                                                            <Field
                                                                                id={"form-" + item.channel.uuid + "_cta_enabled_input"+ctaKey}
                                                                                name={item.channel.uuid + "_cta_enabled_"+ctaKey}
                                                                                component={InputSwitch}
                                                                                value={getFieldProps(item.channel.uuid + "_cta_enabled_"+ctaKey).value}
                                                                                onChange={() => {
                                                                                    setFieldValue(item.channel.uuid + "_cta_enabled_"+ctaKey, !getFieldProps(item.channel.uuid + "_cta_enabled_"+ctaKey).value);

                                                                                    handleChangePreviewData(item.channel.uuid + "_cta_enabled_"+ctaKey, !getFieldProps(item.channel.uuid + "_cta_enabled_"+ctaKey).value);
                                                                                }}
                                                                                className="form-switch-xs d-flex flex-column justify-content-center"
                                                                            />
                                                                            <Label className="form-label mb-0" htmlFor={"form-" + item.channel.uuid + "_cta_enabled_input"+ctaKey}><small>{t('engagement:connectionButtons')}</small></Label>
                                                                        </div>
                                                                    </Row>

                                                                        {getFieldProps(item.channel.uuid + "_cta_enabled_"+ctaKey)?.value &&
                                                                            <>
                                                                        <Row key={'additionalCTAContButtonName_' + item.channel.uuid} className="d-flex flex-row flex-nowrap">
                                                                            <div className={ "mb-3"+ (touched[item.channel.uuid + "_cta_name_"+ctaKey] ? " was-validated" : '')}>

                                                                                {item.channel.connector.handler.code===connectorHandlerCode.CONNECTOR_HANDLER_FACEBOOK ?
                                                                                    <>
                                                                                        <Field
                                                                                            placeholder={t("engagement:chooseButton")}
                                                                                            id={"form-" + item.channel.uuid + "_cta_name_"+ctaKey}
                                                                                            name={item.channel.uuid + "_cta_name_"+ctaKey}
                                                                                            component={InputSelect}
                                                                                            items={facebookCTAButtonsOptions || []}
                                                                                            value={getFieldProps(item.channel.uuid + "_cta_name_"+ctaKey).value}
                                                                                            onChange={(value: any) => {
                                                                                                setFieldValue(item.channel.uuid + "_cta_name_"+ctaKey, value);

                                                                                                handleChangePreviewData(item.channel.uuid + "_cta_name_"+ctaKey, value);

                                                                                            }}
                                                                                            className={"flex-grow-1 "+ (errors[item.channel.uuid + "_cta_name_"+ctaKey] && touched[item.channel.uuid + "_cta_name_"+ctaKey] ? 'form-control is-invalid' : '')}
                                                                                        />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                <Field
                                                                                    placeholder={t('engagement:enterButtonName')}
                                                                                    id={"form-" + item.channel.uuid + "_cta_name_input"+ctaKey}
                                                                                    name={item.channel.uuid + "_cta_name_"+ctaKey}
                                                                                    component={InputText}
                                                                                    value={getFieldProps(item.channel.uuid + "_cta_name_"+ctaKey).value}
                                                                                    onChange={(value: string) => {
                                                                                        setFieldValue(item.channel.uuid + "_cta_name_"+ctaKey, value);

                                                                                        handleChangePreviewData(item.channel.uuid + "_cta_name_"+ctaKey, value);
                                                                                    }}
                                                                                    className={"flex-grow-1 "+ (errors[item.channel.uuid + "_cta_name_"+ctaKey] && touched[item.channel.uuid + "_cta_name_"+ctaKey] ? 'form-control is-invalid' : '')}
                                                                                />
                                                                                {errors[item.channel.uuid + "_cta_name_"+ctaKey] && touched[item.channel.uuid + "_cta_name_"+ctaKey] ? (
                                                                                    <div className="invalid-feedback">
                                                                                        {t('validator:'+errors[item.channel.uuid + "_cta_name_"+ctaKey])}
                                                                                    </div>
                                                                                ) :  touched[item.channel.uuid + "_cta_name_"+ctaKey] && !errors[item.channel.uuid + "_cta_name_"+ctaKey] ?
                                                                                    <div className="valid-feedback">
                                                                                        {t('common:looksGood')}
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                                </>
                                                                                }
                                                                            </div>
                                                                        </Row>


                                                                                    <Nav pills className="nav nav-pills custom-hover-nav-tabs smallTabs mb-1">
                                                                                        {(saleIntegrations || []).length>0&&
                                                                                        <NavItem>
                                                                                            <NavLink style={{ cursor: "pointer" }} className={"rounded-2 "+classnames({ active: (saleIntegrations || []).length>0 && getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey)?.value })}
                                                                                                     onClick = {()=>{
                                                                                                         setFieldValue(item.channel.uuid + "_cta_sale_integration_"+ctaKey, [...saleIntegrations].shift());
                                                                                                         setFieldValue(item.channel.uuid + "_cta_url_"+ctaKey, null);
                                                                                                     }
                                                                                                     }
                                                                                            >
                                                                                                <i className="ri-shopping-cart-2-line nav-icon nav-tab-position fs-14"></i>
                                                                                                <h6 className="nav-titl nav-tab-position m-0">{t('integration:integrations')}</h6>
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                        }
                                                                                        <NavItem>
                                                                                            <NavLink style={{ cursor: "pointer" }} className={"rounded-2 "+classnames({ active: (getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey) === undefined
                                                                                                    || getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey).value === undefined
                                                                                                    || getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey).value === null
                                                                                                ), })}
                                                                                                     onClick = {()=>{
                                                                                                         setFieldValue(item.channel.uuid + "_cta_sale_integration_"+ctaKey, null);
                                                                                                         setFieldValue(item.channel.uuid + "_cta_url_"+ctaKey, '');
                                                                                                     }
                                                                                                     }
                                                                                            >
                                                                                                <i className="ri-link-m nav-icon nav-tab-position fs-14"></i>
                                                                                                <h6 className="nav-titl nav-tab-position m-0">{t('common:externalLink')}</h6>
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    </Nav>


                                                                        {(saleIntegrations || []).length>0 && getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey)?.value &&
                                                                        <Row key={'additionalCTAContSaleIntegration_' + item.channel.uuid} className="d-flex flex-row flex-nowrap">
                                                                            <div className={ "mb-3"+ (touched[item.channel.uuid + "_cta_sale_integration_"+ctaKey] ? " was-validated" : '')}>
                                                                                {/*<div className={"text-end"}>*/}
                                                                                {/*    <small>*/}
                                                                                {/*        <a href={'#'}*/}
                                                                                {/*           onClick = {()=>{*/}
                                                                                {/*               setFieldValue(item.channel.uuid + "_cta_sale_integration_"+ctaKey, null);*/}
                                                                                {/*               setFieldValue(item.channel.uuid + "_cta_url_"+ctaKey, '');*/}
                                                                                {/*            }*/}
                                                                                {/*           }*/}
                                                                                {/*        >{t('common:externalLink')}?</a>*/}
                                                                                {/*    </small>*/}
                                                                                {/*</div>*/}

                                                                                <Field
                                                                                    placeholder={t('integration:chooseIntegration')}
                                                                                    id={"form-" + item.channel.uuid + "_cta_url_input"+ctaKey}
                                                                                    name={item.channel.uuid + "_cta_sale_integration_"+ctaKey}
                                                                                    component={InputSelect}
                                                                                    items={saleIntegrations || []}
                                                                                    value={getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey).value}
                                                                                    onChange={(value: any) => {
                                                                                        setFieldValue(item.channel.uuid + "_cta_sale_integration_"+ctaKey, value);

                                                                                        handleChangePreviewData(item.channel.uuid + "_cta_sale_integration_"+ctaKey, value);
                                                                                    }}
                                                                                    className={"flex-grow-1 "+ (errors[item.channel.uuid + "_cta_sale_integration_"+ctaKey] && touched[item.channel.uuid + "_cta_sale_integration_"+ctaKey] ? 'form-control is-invalid' : '')}
                                                                                />
                                                                            </div>
                                                                        </Row>
                                                                        }
                                                                        {(getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey) === undefined
                                                                                || getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey).value === undefined
                                                                                || getFieldProps(item.channel.uuid + "_cta_sale_integration_"+ctaKey).value === null
                                                                            ) &&
                                                                        <Row key={'additionalCTAContButtonUrl_' + item.channel.uuid} className="d-flex flex-row flex-nowrap">
                                                                            <div className={ "mb-3"+ (touched[item.channel.uuid + "_cta_url_"+ctaKey] ? " was-validated" : '')}>

                                                                                {/*{(saleIntegrations || []).length>0&&*/}
                                                                                {/*    <div className={"text-end"}>*/}
                                                                                {/*        <small>*/}
                                                                                {/*            <a href={'#'}*/}
                                                                                {/*               onClick = {()=>{*/}
                                                                                {/*                   setFieldValue(item.channel.uuid + "_cta_sale_integration_"+ctaKey, [...saleIntegrations].shift());*/}
                                                                                {/*                   setFieldValue(item.channel.uuid + "_cta_url_"+ctaKey, null);*/}
                                                                                {/*               }*/}
                                                                                {/*               }*/}
                                                                                {/*            >{t('integration:chooseIntegration')}?</a>*/}
                                                                                {/*        </small>*/}
                                                                                {/*    </div>*/}
                                                                                {/*}*/}

                                                                                <Field
                                                                                    placeholder={t('common:enterUrl')}
                                                                                    id={"form-" + item.channel.uuid + "_cta_url_input"+ctaKey}
                                                                                    name={item.channel.uuid + "_cta_url_"+ctaKey}
                                                                                    component={InputText}
                                                                                    value={getFieldProps(item.channel.uuid + "_cta_url_"+ctaKey).value}
                                                                                    onChange={(value: string) => {
                                                                                        setFieldValue(item.channel.uuid + "_cta_url_"+ctaKey, value);

                                                                                        handleChangePreviewData(item.channel.uuid + "_cta_url_"+ctaKey, value);
                                                                                    }}
                                                                                    className={"flex-grow-1 "+ (errors[item.channel.uuid + "_cta_url_"+ctaKey] && touched[item.channel.uuid + "_cta_url_"+ctaKey] ? 'form-control is-invalid' : '')}
                                                                                />
                                                                                {errors[item.channel.uuid + "_cta_url_"+ctaKey] && touched[item.channel.uuid + "_cta_url_"+ctaKey] ? (
                                                                                    <div className="invalid-feedback">
                                                                                        {t('validator:'+errors[item.channel.uuid + "_cta_url_"+ctaKey])}
                                                                                    </div>
                                                                                ) :  touched[item.channel.uuid + "_cta_url_"+ctaKey] && !errors[item.channel.uuid + "_cta_url_"+ctaKey] ?
                                                                                    <div className="valid-feedback">
                                                                                        {t('common:looksGood')}
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                        </Row>
                                                                        }
                                                                        </>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    }

                                                    {item?.channel?.connector?.handler?.code &&
                                                    <>
                                                        { (
                                                            item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM
                                                            ||
                                                            item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_LINKEDIN
                                                            ) && item.mode.code === PeerActivityModeCode.ACTIVITY &&
                                                            <>
                                                                <div key={'additionalOptionsContTitle_' + item.channel.uuid}>
                                                                    <small>{t('privacy:additionalOptions')}</small>
                                                                </div>
                                                                <Row key={'additionalOptionsCont_' + item.channel.uuid} className="mb-3 d-flex flex-row flex-nowrap">
                                                                    <div className="col-md-4 d-flex flex-row">
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + "_isForwardDisabled-input"}
                                                                            name={item.channel.uuid + "_isForwardDisabled-input"}
                                                                            component={InputSwitch}
                                                                            value={!getFieldProps(item.channel.uuid + "_isForwardDisabled").value}
                                                                            onChange={() => {
                                                                                setFieldValue(item.channel.uuid + "_isForwardDisabled", !getFieldProps(item.channel.uuid + "_isForwardDisabled").value);
                                                                            }}
                                                                            className="form-switch-xs d-flex flex-column justify-content-center"
                                                                        />
                                                                        <Label className="form-label mb-0" htmlFor={"form-" + item.channel.uuid + "_isForwardDisabled-input"}><small>{t('privacy:allowForward')}</small></Label>
                                                                    </div>
                                                                    {item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TELEGRAM &&
                                                                        <>
                                                                    <div className="col-md-4 d-flex flex-row">
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + "_isSilent-input"}
                                                                            name={item.channel.uuid + "_isSilent-input"}
                                                                            component={InputSwitch}
                                                                            value={getFieldProps(item.channel.uuid + "_isSilent").value}
                                                                            onChange={() => {
                                                                                setFieldValue(item.channel.uuid + "_isSilent", !getFieldProps(item.channel.uuid + "_isSilent").value);
                                                                            }}
                                                                            className="form-switch-xs d-flex flex-column justify-content-center"
                                                                        />
                                                                        <Label className="form-label mb-0" htmlFor={"form-" + item.channel.uuid + "_isSilent-input"}><small>{t('privacy:isSilent')}</small></Label>
                                                                    </div>
                                                                    <div className="col-md-4 d-flex flex-row">
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + "_isSpoiler-input"}
                                                                            name={item.channel.uuid + "_isSpoiler-input"}
                                                                            component={InputSwitch}
                                                                            value={getFieldProps(item.channel.uuid + "_isSpoiler").value}
                                                                            onChange={() => {
                                                                                setFieldValue(item.channel.uuid + "_isSpoiler", !getFieldProps(item.channel.uuid + "_isSpoiler").value);
                                                                            }}
                                                                            className="form-switch-xs d-flex flex-column justify-content-center"
                                                                        />
                                                                        <Label className="form-label mb-0" htmlFor={"form-" + item.channel.uuid + "_isSpoiler-input"}><small>{t('privacy:isSpoiler')}</small></Label>
                                                                    </div>
                                                                        </>
                                                                    }
                                                                </Row>
                                                            </>
                                                        }
                                                        {
                                                            (
                                                                item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TIKTOK
                                                                ||
                                                                item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_VK
                                                                ||
                                                                item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TWITTER
                                                            ) && item.mode.code === PeerActivityModeCode.ACTIVITY &&
<>
                                                            <div key={'engagementContTitle_' + item.channel.uuid}>
                                                                <small>{t('privacy:allowUsersTo')}</small>
                                                            </div>
                                                        <Row key={'engagementCont_' + item.channel.uuid} className="mb-3 d-flex flex-row">
                                                            <div className="col-md-4 d-flex flex-row">
                                                                <Field
                                                                    id={"form-" + item.channel.uuid + "_isComments-input"}
                                                                    name={item.channel.uuid + "_isComments-input"}
                                                                    component={InputSwitch}
                                                                    value={!getFieldProps(item.channel.uuid + "_isCommentsDisabled").value}
                                                                    onChange={() => {
                                                                        setFieldValue(item.channel.uuid + "_isCommentsDisabled", !getFieldProps(item.channel.uuid + "_isCommentsDisabled").value);
                                                                    }}
                                                                    className="form-switch-xs d-flex flex-column justify-content-center"
                                                                />
                                                                <Label className="form-label mb-0" htmlFor={"form-" + item.channel.uuid + "_isComments-input"}><small>{t('privacy:allowComments')}</small></Label>
                                                            </div>
                                                            {  item.channel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_TIKTOK &&
                                                                <>
                                                                    <div className={"col-md-4 d-flex flex-row"}>
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + "_isDuet-input"}
                                                                            name={item.channel.uuid + "_isDuet-input"}
                                                                            component={InputSwitch}
                                                                            value={!getFieldProps(item.channel.uuid + "_isDuetDisabled").value}
                                                                            onChange={() => {
                                                                                setFieldValue(item.channel.uuid + "_isDuetDisabled", !getFieldProps(item.channel.uuid + "_isDuetDisabled").value);
                                                                            }}
                                                                            className="form-switch-xs d-flex flex-column justify-content-center"
                                                                        />
                                                                        <Label className="form-label mb-0"
                                                                               htmlFor={"form-" + item.channel.uuid + "_isDuet-input"}><small>{t('privacy:allowDuet')}</small></Label>
                                                                    </div>
                                                                    <div className={"col-md-4 d-flex flex-row"}>
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + "_isStitch-input"}
                                                                            name={item.channel.uuid + "_isStitch-input"}
                                                                            component={InputSwitch}
                                                                            value={!getFieldProps(item.channel.uuid + "_isStitchDisabled").value}
                                                                            onChange={() => {
                                                                                setFieldValue(item.channel.uuid + "_isStitchDisabled", !getFieldProps(item.channel.uuid + "_isStitchDisabled").value);
                                                                            }}
                                                                            className="form-switch-xs d-flex flex-column justify-content-center"
                                                                        />
                                                                        <Label className="form-label mb-0"
                                                                               htmlFor={"form-" + item.channel.uuid + "_isStitch-input"}><small>{t('privacy:allowStitch')}</small></Label>
                                                                    </div>
                                                                    <div className={"col-md-4 d-flex flex-row"}>
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + "_isBrandContent-input"}
                                                                            name={item.channel.uuid + "_isBrandContent-input"}
                                                                            component={InputSwitch}
                                                                            value={getFieldProps(item.channel.uuid + "_isBrandContent").value}
                                                                            onChange={() => {
                                                                                setFieldValue(item.channel.uuid + "_isBrandContent", !getFieldProps(item.channel.uuid + "_isBrandContent").value);
                                                                            }}
                                                                            className="form-switch-xs d-flex flex-column justify-content-center"
                                                                        />
                                                                        <Label className="form-label mb-0"
                                                                               htmlFor={"form-" + item.channel.uuid + "_isBrandContent-input"}><small>{t('privacy:BrandContent')}</small></Label>
                                                                    </div>
                                                                    <div className={"col-md-4 d-flex flex-row"}>
                                                                        <Field
                                                                            id={"form-" + item.channel.uuid + "_isBrandOrganic-input"}
                                                                            name={item.channel.uuid + "_isBrandOrganic-input"}
                                                                            component={InputSwitch}
                                                                            value={getFieldProps(item.channel.uuid + "_isBrandOrganic").value}
                                                                            onChange={() => {
                                                                                setFieldValue(item.channel.uuid + "_isBrandOrganic", !getFieldProps(item.channel.uuid + "_isBrandOrganic").value);
                                                                            }}
                                                                            className="form-switch-xs d-flex flex-column justify-content-center"
                                                                        />
                                                                        <Label className="form-label mb-0"
                                                                               htmlFor={"form-" + item.channel.uuid + "_isBrandOrganic-input"}><small>{t('privacy:BrandOrganic')}</small></Label>
                                                                    </div>
                                                                </>
                                                            }
                                                        </Row>
</>
                                                        }
                                                        {(getFieldProps(item.channel.uuid + '_privacyItems').value || []).length > 0 &&
                                                            <>
                                                                <div key={'privacyContTitle_' + item.channel.uuid}>
                                                                    <small>{t('privacy:whoCanView')}</small>
                                                                </div>
                                                                <Row key={'privacyCont_' + item.channel.uuid}
                                                                     className="mb-3">
                                                                    <Field
                                                                        id="form-privacy-select"
                                                                        name={item.channel.uuid + "_privacy"}
                                                                        placeholder={t('privacy:choosePrivacy')}
                                                                        component={InputSelect}
                                                                        value={getFieldProps(item.channel.uuid + '_privacy').value}
                                                                        onChange={(value: any) => {
                                                                            setFieldValue(item.channel.uuid+"_privacy",value);
                                                                        }}
                                                                        items={getFieldProps(item.channel.uuid + '_privacyItems').value || []}
                                                                        className={errors[item.channel.uuid+"_privacy"] && touched[item.channel.uuid+"_privacy"] && 'form-control is-invalid'}
                                                                    />
                                                                </Row>
                                                            </>
                                                        }
                                                    </>
                                                    }

                                                    {item.channel.connector.handler.code !== connectorHandlerCode.CONNECTOR_HANDLER_IDEAS &&
                                                        <>
                                                    { !isMultiPostingEdit && !(item.mode.code === PeerActivityModeCode.BIO && item.status === PeerActivityStatus.STATUS_FINAL) &&
                                                        <Row className="d-flex flex-row flex-nowrap border-dashed pt-3 mt-3 pb-3 border border-2 rounded-3">
                                                            <Col className="d-flex flex-row flex-shrink-0 flex-grow-0 align-items-center">
                                                                <InputSwitch id={item.channel.uuid + '_publishReady'}
                                                                             value={(item.status === PeerActivityStatus.STATUS_READY || item.status === PeerActivityStatus.STATUS_ON_PUBLISH || item.status === PeerActivityStatus.STATUS_PUBLISHING || item.status === PeerActivityStatus.STATUS_FINAL)}
                                                                             onChange={() => onChangeReadyToPublish(item.channel)}
                                                                             className="form-switch-md d-flex flex-column justify-content-center align-items-end"/>
                                                            </Col>
                                                            <Col className="d-flex flex-row flex-grow-1 align-items-center">

                                                                { (item.status === PeerActivityStatus.STATUS_READY || item.status === PeerActivityStatus.STATUS_ON_PUBLISH || item.status === PeerActivityStatus.STATUS_PUBLISHING || item.status === PeerActivityStatus.STATUS_FINAL) ?
                                                                    <div className="d-flex flex-row justify-content-end flex-grow-1">
                                                                        <button className="btn btn-outline-primary btn-load" type="button" onClick={()=>handlePublishButton(item)}>
                                                                            <span className="d-flex align-items-center">
                                                                                { (item.status === PeerActivityStatus.STATUS_ON_PUBLISH || item.status === PeerActivityStatus.STATUS_PUBLISHING ) ?
                                                                                    <>
                                                                                        <span className="spinner-border flex-shrink-0" role="status">
                                                                                            <span className="visually-hidden">
                                                                                                {t('common:processing')}...
                                                                                            </span>
                                                                                        </span>
                                                                                        <span className="flex-grow-1 ms-2">
                                                                                            {t('common:processing')}...
                                                                                        </span>
                                                                                    </>
                                                                                    :
                                                                                    <span className="flex-grow-1">
                                                                                        { item.status === PeerActivityStatus.STATUS_FINAL
                                                                                            ?
                                                                                            t('peerActivity:unPublishNow')
                                                                                            :
                                                                                            t('peerActivity:publishNow')
                                                                                        }
                                                                                    </span>
                                                                                }
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    t('peerActivity:publishReady')
                                                                }
                                                            </Col>
                                                        </Row>
                                                    }
                                                    </>
                                                    }
                                                </AccordionBody>

                                            </AccordionItem>
                                        ))}
                                    </Accordion>

                                </div>
                            </Row>
                            { showMoreChannels===false &&
                                !isNew &&
                                <Row>
                                    <div className="mb-3 text-center">
                                        <a className="badge badge-soft-primary" onClick={()=>setShowMoreChannels(true)}>{t('channel:showMoreChannels')}</a>
                                    </div>
                                </Row>
                            }

                            <Button
                                id="idPeerActivityEditSubmit"
                                className="btn btn-light btn-primary w-100 d-none"
                                type="submit"
                                disabled={isSending || isNeedReloadPeerActivity}
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>

                                <div className="p-4">
                                {isOnlyIdeasInForm ?
                                    <NoticeTip
                                        firstText={t('peerActivity:ideaToActivityPlanNotice')}
                                        icon="mdi mdi-lightbulb-on-outline"
                                    />
                                    :
                                    currentActivityMode !== null &&
                                    <AcceptedHandlerByMode mode={currentActivityMode} link={route.CONTENT_SERVICES_LIST} />
                                }
                                </div>
                            </SimpleBar>
                            </div>

                            {isShowPreviewItems &&
                                <div className='showPreviewItem h-100 bg-light'>
                                    <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                        <PreviewItem items={previewItems} isShowPreviewMobile={isShowPreviewMobile}/>
                                    </SimpleBar>
                                    <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0 border-top-0">
                                        <div className="d-flex flex-row flex-nowrap gap-2 justify-content-center">
                                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                                <button type="button" className={"btn "+(isShowPreviewMobile?'btn-primary':'btn-soft-primary')} onClick={setMobilePreview}>
                                                    <i className="ri-smartphone-line align-bottom me-1"/>Mobile</button>
                                                <button type="button" className={"btn "+(!isShowPreviewMobile?'btn-primary':'btn-soft-primary')} onClick={setDesktopPreview}><i className="ri-macbook-line align-bottom me-1"/>Desktop</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Form>
                    )}}
                </Formik>
            }
        </>
    );
}

export default PeerActivityEdit;