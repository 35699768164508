import {SaleCurrencyRateActionTypes, SaleCurrencyRatePayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleCurrencyRateApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleCurrencyRateActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleCurrencyRateApiResponseError = (actionType: string, error: string) => ({
  type: SaleCurrencyRateActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleCurrencyRates = (data: MainMetaType) => ({
  type: SaleCurrencyRateActionTypes.GET_SALE_CURRENCY_RATES,
  payload: data,
});

export const showRightModalSaleCurrencyRate= (data: SaleCurrencyRatePayload) => ({
  type: SaleCurrencyRateActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleCurrencyRate= () => ({
  type: SaleCurrencyRateActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleCurrencyRate = (data: any) => ({
  type: SaleCurrencyRateActionTypes.UPDATE_SALE_CURRENCY_RATE,
  payload: data,
});

export const editDataSaleCurrencyRate = (data: SaleCurrencyRatePayload) => ({
  type: SaleCurrencyRateActionTypes.EDIT_SALE_CURRENCY_RATE,
  payload: data,
});

export const removeSaleCurrencyRateModalShow = (data: SaleCurrencyRatePayload) => ({
  type: SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE_MODAL_SHOW,
  payload: data,
});

export const removeSaleCurrencyRateModalHide = () => ({
  type: SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE_MODAL_HIDE,
});

export const onRemoveSaleCurrencyRate = (data: SaleCurrencyRatePayload) => ({
  type: SaleCurrencyRateActionTypes.REMOVE_SALE_CURRENCY_RATE,
  payload: data,
});