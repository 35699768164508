import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,} from 'reactstrap';

import InputPassword from "../../../components/InputPassword";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {MainUserPasswordChangeType} from "../../../redux/Main/User/types";
import CurrentUser, {MainUserChangePassword} from "../../../redux/Main/User/reducer";
import {onSendChangePasswordUser, setChangePasswordStatus} from "../../../redux/Main/User/actions";
import {ChangePasswordFormValidatorSchema} from "./ChangePasswordFormValidator";
import {useParams} from "react-router-dom";

const ChangePasswordForm = () => {

    const { t } = useTranslation(['user','validator']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);

    const {
        mainUser,
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
    }));

    const [formValues, setFormValues] = useState<MainUserPasswordChangeType>(MainUserChangePassword);
    const formikObject = useRef(null);

    useEffect(() => {

        setDisabled(mainUser.isSendChangePassword);

    }, [mainUser.isSendChangePassword]);

    const {token} = useParams();

    const onDataSubmit = (formData:MainUserPasswordChangeType) => {
        let data: any = {};

        dispatch(setChangePasswordStatus(true));

        if (formData.password) {
            data["password"] = formData.password;
        }

        if (token.length<1){
            return false;
        }

        let params: MainUserPasswordChangeType = {
            password: data.password && data.password,
            token: token && token
        };

        dispatch(onSendChangePasswordUser(params));
    };

    return (
        <>
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ChangePasswordFormValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    innerRef={formikObject}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <Row>
                            <div className={ "mb-3"+ (touched.password ? " was-validated" : '')}>
                                <Label className="form-label" htmlFor="form-password-input">{t('user:newPassword')}</Label>
                                <Field
                                    id="form-password-input"
                                    name="password"
                                    placeholder={t('user:enterNewPassword')}
                                    component={InputPassword}
                                    value = {getFieldProps('password').value}
                                    onChange={(value:string) => {
                                        setFieldValue('password', value);
                                    }}
                                    className={errors.password && touched.password && 'form-control is-invalid'}
                                />
                                {errors.password && touched.password ? (
                                    <div className="invalid-feedback">
                                        {t('validator:'+errors.password)}
                                    </div>
                                ) :  touched.password && !errors.password ?
                                    <div className="valid-feedback">
                                        {t('common:looksGood')}
                                    </div>
                                    : ''
                                }
                            </div>
                        </Row>
                        <Row>
                            <div className={ "mb-3"+ (touched.confirmPassword ? " was-validated" : '')}>
                                <Label className="form-label" htmlFor="form-confirmpassword-input">{t('user:confirmPassword')}</Label>
                                <Field
                                    id="form-confirmpassword-input"
                                    name="confirmpassword"
                                    placeholder={t('user:enterConfirmPassword')}
                                    component={InputPassword}
                                    value = {getFieldProps('confirmPassword').value}
                                    onChange={(value:string) => {
                                        setFieldValue('confirmPassword', value);
                                    }}
                                    className={errors.confirmPassword && touched.confirmPassword && 'form-control is-invalid'}
                                />
                                {errors.confirmPassword && touched.confirmPassword ? (
                                    <div className="invalid-feedback">
                                        {t('validator:'+errors.confirmPassword)}
                                    </div>
                                ) :  touched.confirmPassword && !errors.confirmPassword ?
                                    <div className="valid-feedback">
                                        {t('common:looksGood')}
                                    </div>
                                    : ''
                                }
                            </div>
                        </Row>
                        <div className="mt-4">
                            <Button
                                type="submit"
                                disabled={disabled}
                                color="success"
                                className="w-100 btn-success">{t('common:changePassword')}</Button>
                        </div>
                    </Form>
                        )}
                </Formik>
        </>
    );
}

export default ChangePasswordForm;