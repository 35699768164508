// types
import {ContentProjectsActionTypes, ContentProjectsState, ContentProjectType} from "./types";

export const NewContentProjectItem:ContentProjectType = {
  enabled: true,
  name: '',
  color: '#21a9e1'
}

export const INIT_STATE_CONTENT_PROJECTS: ContentProjectsState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewContentProjectItem,
  modalEditTitle: '',
  isSending: false
};

const ContentProjects = (state = INIT_STATE_CONTENT_PROJECTS, action: any) => {
  switch (action.type) {
    case ContentProjectsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentProjectsActionTypes.GET_CONTENT_PROJECTS:
          return {
            ...state,
            items: action.payload.data.items,
            isContentProjectsFetched: true,
            getContentProjectsLoading: false,
          };
        case ContentProjectsActionTypes.UPDATE_CONTENT_PROJECTS:
          return {
            ...state,
            selectedItem: NewContentProjectItem,
            rightModalShow: false,
            isSending: false
          };
        case ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT:
          return {
            ...state,
            selectedItem: NewContentProjectItem,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }

    case ContentProjectsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ContentProjectsActionTypes.GET_CONTENT_PROJECTS:
          return {
            ...state,
            isContentProjectsFetched: false,
            getContentProjectsLoading: false,
          };
        case ContentProjectsActionTypes.UPDATE_CONTENT_PROJECTS:
          return {
            ...state,
            isSending: false
          };
        case ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT:
          return {
            ...state,
            removeModalShow: false,
          };
        default:
          return { ...state };
      }
    case ContentProjectsActionTypes.EDIT_CONTENT_PROJECTS:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentProjectsActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewContentProjectItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentProjectsActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case ContentProjectsActionTypes.REMOVE_CONTENT_PROJECT_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentProjectItem,
        removeModalShow: false
      }
    case ContentProjectsActionTypes.UPDATE_CONTENT_PROJECTS:
      return {
        ...state,
        isSending: true
      };
    default:
      return { ...state };
  }
};

export default ContentProjects;
