// types
import {MainCabinetActionTypes, MainCabinetState, MainCabinetType} from "./types";
import {setCurrentCabinet} from "../../../helpers/functions";
import {setLoggeedInUser} from "../../../api/apiCore";
export const NewCabinet: MainCabinetType = {
  name: 'New cabinet',
  plan: {name: 'Start'}
}

export const INIT_STATE_MAIN_CABINET: MainCabinetState = {
  items: [NewCabinet],
  isLoading: false,
  isCreating: false,
  isSending:false,
  isNeedReload: false
};

const MainCabinet = (state = INIT_STATE_MAIN_CABINET, action: any) => {
  switch (action.type) {
    case MainCabinetActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case MainCabinetActionTypes.GET_USER_CABINETS:
          return {
            ...state,
            items: [...action.payload.data.items, NewCabinet],
            isLoading: false,
            isNeedReload: false
          };
        case MainCabinetActionTypes.UPDATE_CABINET:
          return {
            ...state,
            isSending: false,
          };
        case MainCabinetActionTypes.CREATE_USER_CABINET:

          if (action.payload?.data?.token?.token && action.payload?.data?.token?.token.length > 0) {
            setLoggeedInUser(action.payload.data.token);
          }

          return {
            ...state,
            isNeedReload: true
          };
        case MainCabinetActionTypes.RESUME_USER_CABINET:
          return {
            ...state,
            isNeedReload: true,
            isSending: false,
          };
        case MainCabinetActionTypes.GET_CURRENT_CABINET:
          if (action.payload.data.items.length > 0) {

            let cabinet = action.payload.data.items.pop();

            setCurrentCabinet(cabinet);
            return {
              ...state,
              currentCabinet: cabinet,
            };
          }

          return {
            ...state,
          };
      default:
        return {...state};
    }

    case MainCabinetActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case MainCabinetActionTypes.GET_USER_CABINETS:
          return {
            ...state,
            items: [NewCabinet],
            isLoading: false,
            isNeedReload:false
          };
        case MainCabinetActionTypes.UPDATE_CABINET:
          return {
            ...state,
            isSending: false,
          };
        case MainCabinetActionTypes.CREATE_USER_CABINET:
          return {
            ...state,
            isCreating: false
          };
        case MainCabinetActionTypes.RESUME_USER_CABINET:
          return {
            ...state,
            isSending: false,
          };
        default:
          return { ...state };
      }
    case MainCabinetActionTypes.GET_USER_CABINETS:
      return {
        ...state,
        isLoading: true,
      };
    case MainCabinetActionTypes.CREATE_USER_CABINET:{
      return {
        ...state,
        isCreating: true,
      };
    }
    case MainCabinetActionTypes.UPDATE_CABINET:
    case MainCabinetActionTypes.RESUME_USER_CABINET:
      return {
        ...state,
        isSending: true,
      };
    case MainCabinetActionTypes.SELECT_UPDATE_USER_CABINET:

      (state.items|| []).map((item:MainCabinetType) => {
        item.isEdit = item.uuid === action.payload.uuid;
        return item;
      })

      return {
        ...state,
      };
    case MainCabinetActionTypes.CLEAR_SELECT_UPDATE_USER_CABINET:

      (state.items|| []).map((item:MainCabinetType) => {
        if (item.isEdit){
          item.isEdit = false;
        }
        return item;
      })

      return {
        ...state,
      };
    case MainCabinetActionTypes.SELECT_USER_CABINET:{

      setCurrentCabinet(action.payload.data)

      return {
        ...state,
        currentCabinet: action.payload.data,
      };
    }
    case MainCabinetActionTypes.SAVE_CURRENT_CABINET_SETTINGS:{

      state={
        ...state,
        currentCabinet: {
          ...state.currentCabinet,
          settings: action.payload
        }
      }

      setCurrentCabinet(state.currentCabinet);

      return {
        ...state,
      };
    }

    default:
      return { ...state };
  }
};

export default MainCabinet;
