import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { DashboardTaskActionTypes } from "./types";
import { dashboardTaskApiResponseSuccess, dashboardTaskApiResponseError} from "./actions";

import {
  getStatTasksTiles as getStatTasksTilesApi,
  getTasksFiltered as getTasksFilteredApi
} from "../../../api";


function* getDashboardTaskTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatTasksTilesApi, data);
    yield put(
        dashboardTaskApiResponseSuccess(DashboardTaskActionTypes.GET_STAT_TASKS_TILES, response)
    );
  } catch (error: any) {
    yield put(dashboardTaskApiResponseError(DashboardTaskActionTypes.GET_STAT_TASKS_TILES, error));
  }
}

function* getDashboardTaskLastCompleted({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTasksFilteredApi, data);
    yield put(
        dashboardTaskApiResponseSuccess(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_COMPLETED, response)
    );
  } catch (error: any) {
    yield put(dashboardTaskApiResponseError(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_COMPLETED, error));
  }
}

function* getDashboardTaskLastOverdue({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTasksFilteredApi, data);
    yield put(
        dashboardTaskApiResponseSuccess(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_OVERDUE, response)
    );
  } catch (error: any) {
    yield put(dashboardTaskApiResponseError(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_OVERDUE, error));
  }
}

function* getDashboardTaskLastReturned({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTasksFilteredApi, data);
    yield put(
        dashboardTaskApiResponseSuccess(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_RETURNED, response)
    );
  } catch (error: any) {
    yield put(dashboardTaskApiResponseError(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_RETURNED, error));
  }
}

function* getDashboardTaskLastPending({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTasksFilteredApi, data);
    yield put(
        dashboardTaskApiResponseSuccess(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_PENDING, response)
    );
  } catch (error: any) {
    yield put(dashboardTaskApiResponseError(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_PENDING, error));
  }
}

function* getDashboardTaskCurrentInProgress({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getTasksFilteredApi, data);
    yield put(
        dashboardTaskApiResponseSuccess(DashboardTaskActionTypes.GET_STAT_TASKS_IN_PROGRESS, response)
    );
  } catch (error: any) {
    yield put(dashboardTaskApiResponseError(DashboardTaskActionTypes.GET_STAT_TASKS_IN_PROGRESS, error));
  }
}

export function* watchGetDashboardTaskTiles() {
  yield takeEvery(DashboardTaskActionTypes.GET_STAT_TASKS_TILES, getDashboardTaskTiles);
}

export function* watchGetDashboardTaskLastCompleted() {
  yield takeEvery(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_COMPLETED, getDashboardTaskLastCompleted);
}
export function* watchGetDashboardTaskLastOverdue() {
  yield takeEvery(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_OVERDUE, getDashboardTaskLastOverdue);
}
export function* watchGetDashboardTaskLastReturned() {
  yield takeEvery(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_RETURNED, getDashboardTaskLastReturned);
}
export function* watchGetDashboardTaskLastPending() {
  yield takeEvery(DashboardTaskActionTypes.GET_STAT_TASKS_LAST_PENDING, getDashboardTaskLastPending);
}
export function* watchGetDashboardTaskCurrentInProgress() {
  yield takeEvery(DashboardTaskActionTypes.GET_STAT_TASKS_IN_PROGRESS, getDashboardTaskCurrentInProgress);
}

function* dashboardTaskSaga() {
  yield all([
      fork(watchGetDashboardTaskTiles),
      fork(watchGetDashboardTaskLastCompleted),
      fork(watchGetDashboardTaskLastOverdue),
      fork(watchGetDashboardTaskLastReturned),
      fork(watchGetDashboardTaskLastPending),
      fork(watchGetDashboardTaskCurrentInProgress)
  ]);
}

export default dashboardTaskSaga;
