import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {ContentPeerUserType} from "../../../redux/Content/PeerUser/types";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {ContentPeerCommentType} from "../../../redux/Content/PeerComment/types";
import SimpleBar from "simplebar-react";
import * as routes from "../../../api/routes";
import {useRedux} from "../../../hooks";
import {getMainSearch} from "../../../redux/Main/Search/actions";
import ChannelItemLine from "../../Content/PeerChannel/channelItemLine";
import UserItemLine from "../../Content/PeerUser/UserItemLine";
import PeerActivityLine from "../../Content/PeerActivity/PeerActivityLine";
import CommentItemLine from "../../Content/PeerComment/CommentItemLine";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import useDebounce from "../../../hooks/useDebounce";

const TopSearch = () => {

    const { t } = useTranslation(['common']);

    const maxResults = 5;
    const { dispatch, useAppSelector } = useRedux();
    const [value, setValue] = useState("");
    const [showResults, setShowResults] = useState(false);


    const {
        mainSearch,
    } = useAppSelector(state => ({
        mainSearch: state.MainSearch,
    }));

    const onSearch = () => {
        mainSearch.isMainSearchFetched = false;
        dispatch(getMainSearch(mainSearch));
    }
    const debouncedSearch = useDebounce(onSearch, 500);

    const onChangeData = (value) => {
        mainSearch.maxResults = maxResults;
        mainSearch.query = value;
        setValue(value);
        debouncedSearch(value);
    };

    const onCloseClick = () => {
        setValue('');
        setShowResults(false);
    };

    useEffect(() => {

        let isDropDownShow = (
            (mainSearch.channels && mainSearch.channels.count>0)
            ||
            (mainSearch.activities && mainSearch.activities.count>0)
            ||
            (mainSearch.peerUsers && mainSearch.peerUsers.count>0)
            ||
            (mainSearch.comments && mainSearch.comments.count>0)
        );

        if (value.length<1){
            isDropDownShow = false;
        }

        setShowResults(isDropDownShow);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mainSearch]);

    return (
        <>
            <form className="app-search d-none d-md-block" action={routes.MAIN_SEARCH} method="GET">
                <div className="position-relative">
                    <Input type="text" className="form-control" placeholder={t('common:search')}
                           id="search-options"
                           name="q"
                           value={value}
                           onChange={e => {
                               onChangeData(e.target.value);
                           }} />
                    <span className="mdi mdi-magnify search-widget-icon"/>
                    <span className={"mdi mdi-close-circle search-widget-icon search-widget-icon-close "+(value.length<1 && 'd-none')}
                          id="search-close-options" onClick={onCloseClick}/>
                </div>

                {value.length > 0 &&
                <div className="dropdown-menu dropdown-menu-lg show" id="search-dropdown">
                    {showResults === true ?
                        <>
                            <SimpleBar style={{overflowX: 'hidden', height: "320px"}} autoHide={true}>

                                {mainSearch.channels && mainSearch.channels.count > 0 &&
                                <>
                                    <div className="dropdown-header mt-2">
                                        <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('channel:channels')}
                                            ({mainSearch.channels.count})
                                        </h6>
                                    </div>
                                    <div className="notification-list">
                                        {(mainSearch.channels.items.slice(0, maxResults).map((item: ContentPeerChannelType, key: number) => (
                                                <a className="dropdown-item notify-item py-2" key={key}
                                                   href={routes.CONTENT_CHANNELS_LIST}>
                                                    <ChannelItemLine item={item}/>
                                                </a>
                                            ))
                                        )}
                                        {mainSearch.channels.count > maxResults &&
                                        <div className="dropdown-item d-flex flex-row justify-content-center">
                                            <a className="badge badge-soft-primary"
                                               href={routes.MAIN_SEARCH + '?q=' + value}>{t('channel:showMoreChannels')}</a>
                                        </div>
                                        }
                                    </div>
                                </>
                                }

                                {mainSearch.activities && mainSearch.activities.count > 0 &&
                                <>
                                    <div className="dropdown-header mt-2">
                                        <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('peerActivity:activities')}
                                            ({mainSearch.activities.count})</h6>
                                    </div>
                                    <div className="notification-list">
                                        {(mainSearch.activities.items.slice(0, maxResults).map((item: PeerActivityType, key: number) => (
                                                <a className="dropdown-item notify-item py-2" key={key}
                                                   href={routes.CONTENT_CHANNELS_LIST}>
                                                    <PeerActivityLine item={item}/>
                                                </a>
                                            ))
                                        )}
                                        {mainSearch.activities.count > maxResults &&
                                        <div className="dropdown-item d-flex flex-row justify-content-center">
                                            <a className="badge badge-soft-primary"
                                               href={routes.MAIN_SEARCH + '?q=' + value}>{t('peerActivity:showMoreActivities')}</a>
                                        </div>
                                        }
                                    </div>

                                </>
                                }

                                {mainSearch.comments && mainSearch.comments.count > 0 &&
                                <>
                                    <div className="dropdown-header mt-2">
                                        <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('comment:comments')} ({mainSearch.comments.count})</h6>
                                    </div>
                                    <div className="notification-list">
                                        {(mainSearch.comments.items.slice(0, maxResults).map((item: ContentPeerCommentType, key: number) => (
                                                <a className="dropdown-item notify-item py-2" key={key}
                                                   href={routes.CONTENT_CHANNELS_LIST}>
                                                    <CommentItemLine item={item}/>
                                                </a>
                                            ))
                                        )}
                                        {mainSearch.comments.count > maxResults &&
                                        <div className="dropdown-item d-flex flex-row justify-content-center">
                                            <a className="badge badge-soft-primary"
                                               href={routes.MAIN_SEARCH + '?q=' + value}>{t('comment:showMoreComments')}</a>
                                        </div>
                                        }

                                    </div>
                                </>
                                }

                                {mainSearch.peerUsers && mainSearch.peerUsers.count > 0 &&
                                <>
                                    <div className="dropdown-header mt-2">
                                        <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('user:users')}
                                            ({mainSearch.peerUsers.count})</h6>
                                    </div>
                                    <div className="notification-list">
                                        {(mainSearch.peerUsers.items.slice(0, maxResults).map((item: ContentPeerUserType, key: number) => (
                                                <a className="dropdown-item notify-item py-2" key={key}
                                                   href={routes.CONTENT_CHANNELS_LIST}>
                                                    <UserItemLine item={item}/>
                                                </a>
                                            ))
                                        )}
                                        {mainSearch.peerUsers.count > maxResults &&
                                        <div className="dropdown-item d-flex flex-row justify-content-center">
                                            <a className="badge badge-soft-primary"
                                               href={routes.MAIN_SEARCH + '?q=' + value}>{t('user:showMoreUsers')}</a>
                                        </div>
                                        }
                                    </div>
                                </>
                                }

                            </SimpleBar>

                            <div className="text-center pt-3 pb-1">
                                <a href={routes.MAIN_SEARCH + '?q=' + value} className="btn btn-primary btn-sm"> {t('search:viewAllResults')}<i className="ri-arrow-right-line ms-1"/></a>
                            </div>
                        </>
                        :
                        <div className="dropdown-item d-flex flex-row justify-content-center">
                            <a className="badge badge-soft-primary"
                               href={routes.MAIN_SEARCH + '?q=' + value}>{t('search:foundNothing')}</a>
                        </div>
                    }
                </div>
                }
            </form>
        </>
    );
};

export default TopSearch;