// types
import {CommentActionTypes, CommentState, CommentType} from "./types";

export const NewCommentItem:CommentType = {
  enabled: true,
  text: ''
}

export const INIT_STATE_COMMENT: CommentState = {
  items: [],
  isLoading: false,
  selectedItem: NewCommentItem,
  rightModalShow: false,
  removeModalShow: false,
  rewardModalShow: false,
  isSending: false,
  modalEditTitle:'',
  isNeedReload: false
};

const Comment = (state = INIT_STATE_COMMENT, action: any) => {
  switch (action.type) {
    case CommentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CommentActionTypes.UPDATE_COMMENT:
          return {
            ...state,
            selectedItem: NewCommentItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }
    case CommentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CommentActionTypes.UPDATE_COMMENT:
          return {
            ...state,
            isSending: false
          };
        default:
          return { ...state };
      }
    case CommentActionTypes.UPDATE_COMMENT:
      return {
        ...state,
        isSending: true
      };
    default:
      return { ...state };
  }
};

export default Comment;
