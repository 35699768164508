import React from "react";
import {PeerActivityModeType} from "../../../redux/Content/PeerActivityMode/types";
import '../../../i18/config';
import {useTranslation} from "react-i18next";

interface ItemProps {
    item: PeerActivityModeType;
}
const PeerActivityModeItemLine = ({ item }: ItemProps) => {

    const { t, i18n } = useTranslation(['peerActivityMode']);



    return(
        <>
            <div className="d-flex flex-shrink-0">
                <div className="avatar-xxs me-2 d-flex flex-shrink-0 position-relative">
                    <div className="avatar-title rounded-circle bg-light text-primary fs-16"
                         ref={element => {
                             if (element) element.style.setProperty('background-color', item.color, 'important');
                         }}/>
                </div>
                <div className="flex-1 justify-content-center flex-column d-flex text-truncate">
                    {t('peerActivityMode:'+item.code)}
                </div>
            </div>
        </>
    )
}

export default PeerActivityModeItemLine;