import {PeerActivityTypeActionTypes, PeerActivityTypePayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const peerActivityTypeApiResponseSuccess = (actionType: string, data: any) => ({
  type: PeerActivityTypeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const peerActivityTypeApiResponseError = (actionType: string, error: string) => ({
  type: PeerActivityTypeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPeerActivityTypes = (data: MainMetaType) => ({
  type: PeerActivityTypeActionTypes.GET_CONTENT_PEER_ACTIVITY_TYPES,
  payload: data
});

export const showRightModalPeerActivityType= (data: PeerActivityTypePayload) => ({
  type: PeerActivityTypeActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalPeerActivityType= () => ({
  type: PeerActivityTypeActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataPeerActivityType = (data: any) => ({
  type: PeerActivityTypeActionTypes.UPDATE_CONTENT_DATAITEMTYPE,
  payload: data,
});

export const editDataPeerActivityType = (data: PeerActivityTypePayload) => ({
  type: PeerActivityTypeActionTypes.EDIT_CONTENT_DATAITEMTYPE,
  payload: data,
});

export const removePeerActivityTypeModalShow = (data: PeerActivityTypePayload) => ({
  type: PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE_MODAL_SHOW,
  payload: data,
});

export const removePeerActivityTypeModalHide = () => ({
  type: PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE_MODAL_HIDE,
});

export const onRemovePeerActivityType = (data: PeerActivityTypePayload) => ({
  type: PeerActivityTypeActionTypes.REMOVE_CONTENT_DATAITEMTYPE,
  payload: data,
});