import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { CouponActionTypes } from "./types";
import { CouponApiResponseSuccess, CouponApiResponseError } from "./actions";

import {
    checkCoupon as checkCouponApi
} from "../../../api";

import {
    showSuccessNotification,
    showErrorNotification,
} from "../../../helpers/notifications";

function* checkCoupon({ payload: data }: any) {
    try {
        const response: Promise<any> = yield call(checkCouponApi,data);

        if (response?.message !== undefined && response?.message !== null){
            throw response;
        }

        yield put(CouponApiResponseSuccess(CouponActionTypes.CHECK_M9S_COUPON, response));
    } catch (error: any) {
        yield put(CouponApiResponseError(CouponActionTypes.CHECK_M9S_COUPON, error));
        yield call(showErrorNotification, error);
    }
}

export function* watchCheckCoupon() {
    yield takeEvery(CouponActionTypes.CHECK_M9S_COUPON, checkCoupon);
}

function* couponSaga() {
    yield all([
        fork(watchCheckCoupon)
    ]);
}

export default couponSaga;
