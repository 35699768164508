
import {EntityActionsType} from "../../types";
import {TaskType} from "../../Task/Task/types";

export interface TaskMemberType{
    uuid?: string;
    enabled?: boolean;
    memberUuid?: string;
    type?: string;
    memberType?: string;
    task?: TaskType;
    taskAccess?: Array<TaskMemberAccess>;
    actions?: EntityActionsType;
}

export enum TaskMemberMemberType {
    PEER_TYPE_USER = 'user'
}

export enum TaskMemberTypeType {
    TYPE_ALL = '_',
    TYPE_ASSIGNER = 'A',
    TYPE_RESPONSIBLE = 'R',
    TYPE_OBSERVER = 'O'
}

export enum TaskMemberAccess {
    ACCESS_RO = 'RO',
    ACCESS_RW = 'RW',
    ACCESS_CHANGE_DEADLINE = 'DLC'
}

export const NewTaskMemberAssignerItem:TaskMemberType = {
    enabled: true,
    memberType: TaskMemberMemberType.PEER_TYPE_USER,
    type: TaskMemberTypeType.TYPE_ASSIGNER,
    taskAccess: [TaskMemberAccess.ACCESS_RW]
}

export const NewTaskMemberResponsibleItem:TaskMemberType = {
    enabled: true,
    memberType: TaskMemberMemberType.PEER_TYPE_USER,
    type: TaskMemberTypeType.TYPE_RESPONSIBLE,
    taskAccess: [TaskMemberAccess.ACCESS_RW]
}

