import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentConnectorDataParamActionTypes } from "./types";
import { contentConnectorDataParamApiResponseSuccess, contentConnectorDataParamApiResponseError } from "./actions";

import {
  getContentConnectorDataParams as getContentConnectorDataParamsApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getContentConnectorDataParams() {
  try {
    const response: Promise<any> = yield call(getContentConnectorDataParamsApi);
    yield put(contentConnectorDataParamApiResponseSuccess(ContentConnectorDataParamActionTypes.GET_CONTENT_CONNECTOR_DATA_PARAMS, response));
  } catch (error: any) {
    yield put(contentConnectorDataParamApiResponseError(ContentConnectorDataParamActionTypes.GET_CONTENT_CONNECTOR_DATA_PARAMS, error));
  }
}

export function* watchGetContentConnectorDataParams() {
  yield takeEvery(ContentConnectorDataParamActionTypes.GET_CONTENT_CONNECTOR_DATA_PARAMS, getContentConnectorDataParams);
}


function* contentConnectorDataParamSaga() {
  yield all([
    fork(watchGetContentConnectorDataParams),
  ]);
}

export default contentConnectorDataParamSaga;
