import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { PaymentSystemActionTypes } from "./types";
import { paymentSystemApiResponseSuccess, paymentSystemApiResponseError } from "./actions";

import {
  getPaymentSystems as getPaymentSystemsApi,
  getSalePaymentSystems as getSalePaymentSystemsApi,
  removeSalePaymentSystem as removeSalePaymentSystemApi,
  sendDataSalePaymentSystem as sendDataSalePaymentSystemApi,
} from "../../../api";

import {showErrorNotification, showSuccessNotification} from "../../../helpers/notifications";

function* getPaymentSystems({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPaymentSystemsApi,data);
    yield put(paymentSystemApiResponseSuccess(PaymentSystemActionTypes.GET_PAYMENT_SYSTEMS, response));
  } catch (error: any) {
    yield put(paymentSystemApiResponseError(PaymentSystemActionTypes.GET_PAYMENT_SYSTEMS, error));
  }
}

function* getSalePaymentSystems({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getSalePaymentSystemsApi,data);
    yield put(paymentSystemApiResponseSuccess(PaymentSystemActionTypes.GET_SALE_PAYMENT_SYSTEMS, response));
  } catch (error: any) {
    yield put(paymentSystemApiResponseError(PaymentSystemActionTypes.GET_SALE_PAYMENT_SYSTEMS, error));
  }
}

function* onSendDataSalePaymentSystem({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataSalePaymentSystemApi, data);
    yield put(
        paymentSystemApiResponseSuccess(PaymentSystemActionTypes.UPDATE_SALE_PAYMENT_SYSTEM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(paymentSystemApiResponseError(PaymentSystemActionTypes.UPDATE_SALE_PAYMENT_SYSTEM, error));
    yield call(showErrorNotification, error);
  }
}

function* onRemoveSalePaymentSystem({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeSalePaymentSystemApi, {uuid: data.data.uuid});
    yield put(
        paymentSystemApiResponseSuccess(PaymentSystemActionTypes.REMOVE_SALE_PAYMENT_SYSTEM, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(paymentSystemApiResponseError(PaymentSystemActionTypes.REMOVE_SALE_PAYMENT_SYSTEM, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetPaymentSystems() {
  yield takeEvery(PaymentSystemActionTypes.GET_PAYMENT_SYSTEMS, getPaymentSystems);
}
export function* watchGetSalePaymentSystems() {
  yield takeEvery(PaymentSystemActionTypes.GET_SALE_PAYMENT_SYSTEMS, getSalePaymentSystems);
}
export function* watchOnSendDataSalePaymentSystem() {
  yield takeEvery(PaymentSystemActionTypes.UPDATE_SALE_PAYMENT_SYSTEM, onSendDataSalePaymentSystem);
}
export function* watchOnRemoveSalePaymentSystem() {
  yield takeEvery(PaymentSystemActionTypes.REMOVE_SALE_PAYMENT_SYSTEM, onRemoveSalePaymentSystem);
}


function* paymentSystemSaga() {
  yield all([
      fork(watchGetPaymentSystems),
      fork(watchGetSalePaymentSystems),
      fork(watchOnSendDataSalePaymentSystem),
      fork(watchOnRemoveSalePaymentSystem)
  ]);
}

export default paymentSystemSaga;
