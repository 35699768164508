import {PeerActivityStatus} from "../PeerActivityStatus/types";
import {PeerActivityIdeaActionTypes, PeerActivityIdeaState} from "./types";
import {PeerActivityType} from "../PeerActivity/types";


export const INIT_STATE_CONTENT_PEER_ACTIVITY_IDEA: PeerActivityIdeaState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
//  selectedItem: NewPeerActivityItem,
  modalEditTitle: '',
  isInitialized: false,
  isSending: false,
  requestId:'',
  isNeedReload: false,
  isPeerActivityFetched: false,
  getPeerActivityLoading:false,
  mode: null
};

const PeerActivityIdea = (state = INIT_STATE_CONTENT_PEER_ACTIVITY_IDEA, action: any) => {
  switch (action.type) {
    case PeerActivityIdeaActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case PeerActivityIdeaActionTypes.GET_CONTENT_PEER_ACTIVITIES_IDEAS:
          return {
            ...state,
            items: action.payload.data.items,
            isNeedReload: false,
            isPeerActivityFetched: true,
            getPeerActivityLoading: false,
          };
        case PeerActivityIdeaActionTypes.UPDATE_PEER_ACTIVITIES:
          return {
            ...state,
            editItems: action.payload.data.items,
            isSending: false,
            isNeedReload: true,
          }
        default:
          return { ...state };
      }

    case PeerActivityIdeaActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case PeerActivityIdeaActionTypes.GET_CONTENT_PEER_ACTIVITIES_IDEAS:
          return {
            ...state,
            isNeedReload: false,
            isPeerActivityFetched: false,
            getPeerActivityLoading: false,
          };
        case PeerActivityIdeaActionTypes.UPDATE_PEER_ACTIVITIES:
          (state.editItems || []).map((item: PeerActivityType)=>{
            if (item.status === PeerActivityStatus.STATUS_ON_PUBLISH || item.status === PeerActivityStatus.STATUS_PUBLISHING) {
              item.status=PeerActivityStatus.STATUS_READY
            }
            return item;
          });

          return {
            ...state,
            isSending: false
          }

        default:
          return { ...state };
      }
    case PeerActivityIdeaActionTypes.SET_NEED_RELOAD:
      return {
        ...state,
        isNeedReload: true,
        isLoaded: false
      }
    // case PeerActivityIdeaActionTypes.SET_EDIT_ITEMS:
    //   return {
    //     ...state,
    //     editItems: action.payload
    //   }
    case PeerActivityIdeaActionTypes.SET_INITIALIZED:
      return {
        ...state,
        isInitialized: true,
        isLoaded: true
      }
    case PeerActivityIdeaActionTypes.SHOW_MODAL:
      return {
        ...state,
        rightModalShow: true
      }
    case PeerActivityIdeaActionTypes.HIDE_MODAL:
      return {
        ...state,
        rightModalShow: false
      }
    case PeerActivityIdeaActionTypes.UPDATE_PEER_ACTIVITIES:
      return {
        ...state,
        isSending: true
      }
    case PeerActivityIdeaActionTypes.SET_ACTIVITY_MODE:
      return {
        ...state,
        mode: action.payload
      }
    case PeerActivityIdeaActionTypes.GET_CONTENT_PEER_ACTIVITIES_IDEAS:
      return {
        ...state,
        isNeedReload: false,
        isPeerActivityFetched: false,
        getPeerActivityLoading: true,
      };
    default:
      return { ...state };
  }
};

export default PeerActivityIdea;
