import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input } from 'reactstrap';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {MainUserValidatorSchema} from "./validator";
import {MainUserPasswordValidatorSchema} from "./validatorPassword";
import Loader from "../../../components/Loader";

import {
    Formik,
    Form,
    Field
} from 'formik';

// hooks
import { useRedux } from "../../../hooks";

import {
    onSendDataMainUser,
    getMainCurrentUser,
    onSendAvatarMainUser,
    onChangePasswordMainUser,
    getMainCurrentUserProfile,
    onSendChangePasswordUser,
    showRightModalMainDevice,
    changeUserName, saveCurrentCabinetSettings
} from "../../../redux/actions";

import {MainUserType} from "../../../redux/Main/User/types";
import InputText from "../../../components/InputText";
import UserNotificationsChange from "../../Event/EventSubscribeUser/UserNotificationsChange";
import {MainRoleType} from "../../../redux/Main/Role/types";
import {showSuccessNotification} from "../../../helpers/notifications";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import ButtonSpinner from "../../../components/ButtonSpinner";
import InputPhone from "../../../components/InputPhone";
import ApproveDeviceModal from "../../../components/User/ApproveDeviceForm";
import UserDevices from "../Device/UserDevices";
import {useProfile} from "../../../hooks/UserHooks";
import {getCurrentSettings} from "../../../helpers/functions";
import InputPassword from "../../../components/InputPassword";

// Shape of form values
interface FormPasswordValuesType {
    oldpassword: string;
    password: string;
    confirmpassword: string;
}

const MainUserProfileIndex = () => {
    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const { userProfile } = useProfile();

    const [currentUser, setCurrentUser] = useState<MainUserType>(null)
    const [activeTab, setActiveTab] = useState<number>(1);

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const formPasswordValues : FormPasswordValuesType = {
        oldpassword:'',
        password: '',
        confirmpassword: ''
    }

    const {
        mainUser,
        mainRoles,
        mainDevice,
        isSending,
        isSendChangePassword,
        isUserLoading
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
        mainRoles: state.MainRole,
        mainDevice: state.MainDevice,
        isSending: state.MainUser.isSending,
        isSendChangePassword: state.MainUser.isSendChangePassword,
        isUserLoading: state.MainUser.isLoading
    }));

    useEffect(() => {
        dispatch(getMainCurrentUserProfile());
    }, [dispatch]);

    useEffect(() => {

        if (mainUser.currentUser !== undefined){
            let _currentUser = {...mainUser.currentUser};

            if (_currentUser.name !== userProfile.fullName){
                _currentUser = {
                    ..._currentUser,
                    cabinetName: userProfile.fullName
                }
            }

            setCurrentUser(_currentUser);
        }

        if (mainUser?.isChangePasswordComplete && mainUser.isChangePasswordComplete===true){
            showSuccessNotification(t('user:changedPassword'));
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, mainUser]);


    const onDataSubmit = (data: any) => {

        let params: any = {
            uuid: currentUser.uuid,
            name: data.name && data.name,
            phone: data.phone && data.phone,
        };

        if (data.cabinetName !== undefined && data.cabinetName !== null){
            params ={
                ...params,
                cabinetName: data.cabinetName
            }



        }

        dispatch(onSendDataMainUser(params));

        setTimeout(() => {
            if (data.cabinetName !== undefined && data.cabinetName !== null){

                let settings = getCurrentSettings();

                if (settings !== null){
                    settings = {
                        ...settings,
                        userName: data.cabinetName
                    }
                    dispatch(saveCurrentCabinetSettings(settings));
                }
            }
//            dispatch(getMainCurrentUser());
            dispatch(getMainCurrentUserProfile());
        }, 1000);
    };

    const onChangeAvatar = (data: any) => {

        if (data.target.files !== undefined) {
            currentUser.uploadAvatar = data.target.files[0];

            dispatch(onSendAvatarMainUser(currentUser));

            setTimeout(() => {
                // dispatch(getMainCurrentUser());
                dispatch(getMainCurrentUserProfile());
            }, 1000);
        }
    }

    const onChangePassword = (data: any) => {

        let params: any = {
            oldPassword: data.oldpassword && data.oldpassword,
            password: data.password && data.password,
        };

        dispatch(onSendChangePasswordUser(params));

    }

    return (
        <>
            <BreadCrumb title={t('menu:profile')} pageTitle={t('menu:main')} />
            {isUserLoading ?
                <Loader/>
                :
            <>
                {currentUser &&
                    <Row>
                        <Col xxl={3}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            {currentUser.avatar ?
                                            <img src={currentUser.avatar}
                                                 className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                 alt="user-profile"/>
                                                :
                                                <div
                                                     className="rounded-circle avatar-xl img-thumbnail user-profile-image d-flex justify-content-center align-items-center"
                                                >
                                                    <i className="lab las la-user-secret fs-3rem text-muted"></i>

                                                </div>
                                            }
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input id="profile-img-file-input" type="file"
                                                       className="profile-img-file-input" onChange={onChangeAvatar} />
                                                <Label htmlFor="profile-img-file-input"
                                                       className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"/>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <h5 className="mb-1">{userProfile.fullName}</h5>
                                        {userProfile?.position && userProfile.position !== null && userProfile.position.length>0 &&
                                            <p className="text-muted mb-0">{userProfile.position}</p>
                                        }
                                        {currentUser.name !== currentUser.email &&
                                        <p className="text-muted mb-0">{currentUser.email}</p>
                                        }
                                        <div className="mt-1">
                                        {(currentUser.roles || []).map((role:MainRoleType, key) =>
                                          (
                                            <span
                                            className="badge bg-primary me-1 mb-1"
                                            key={key}
                                            ref={
                                                element => {
                                                    if (element) element.style.setProperty('background-color',
                                                        (mainRoles.items || []).filter((roleItem:MainRoleType, key)=>{
                                                            return roleItem.code === role.code;
                                                        }).pop(), 'important');
                                                }
                                            }
                                            > {t('role:'+role.code)}
                                            </span>
                                            ))
                                        }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            {/*<Card>*/}
                            {/*    <CardBody>*/}
                            {/*        <div className="d-flex align-items-center mb-5">*/}
                            {/*            <div className="flex-grow-1">*/}
                            {/*                <h5 className="card-title mb-0">Complete Your Profile</h5>*/}
                            {/*            </div>*/}
                            {/*            <div className="flex-shrink-0">*/}
                            {/*                <Link to="#" className="badge bg-light text-primary fs-12"><i*/}
                            {/*                    className="ri-edit-box-line align-bottom me-1"></i> Edit</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="progress animated-progress custom-progress progress-label">*/}
                            {/*            <div className="progress-bar bg-danger" role="progressbar" style={{ "width": "30%" }}*/}
                            {/*                 aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">*/}
                            {/*                <div className="label">30%</div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </CardBody>*/}
                            {/*</Card>*/}
                            {/*<Card>*/}
                            {/*    <CardBody>*/}
                            {/*        <div className="d-flex align-items-center mb-4">*/}
                            {/*            <div className="flex-grow-1">*/}
                            {/*                <h5 className="card-title mb-0">Portfolio</h5>*/}
                            {/*            </div>*/}
                            {/*            <div className="flex-shrink-0">*/}
                            {/*                <Link to="#" className="badge bg-light text-primary fs-12"><i*/}
                            {/*                    className="ri-add-fill align-bottom me-1"></i> Add</Link>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="mb-3 d-flex">*/}
                            {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
                            {/*                <span className="avatar-title rounded-circle fs-16 bg-dark text-light">*/}
                            {/*                    <i className="ri-github-fill"></i>*/}
                            {/*                </span>*/}
                            {/*            </div>*/}
                            {/*            <Input type="email" className="form-control" id="gitUsername" placeholder="Username"*/}
                            {/*                   defaultValue="@daveadame" />*/}
                            {/*        </div>*/}
                            {/*        <div className="mb-3 d-flex">*/}
                            {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
                            {/*                <span className="avatar-title rounded-circle fs-16 bg-primary">*/}
                            {/*                    <i className="ri-global-fill"></i>*/}
                            {/*                </span>*/}
                            {/*            </div>*/}
                            {/*            <Input type="text" className="form-control" id="websiteInput"*/}
                            {/*                   placeholder="www.example.com" defaultValue="www.velzon.com" />*/}
                            {/*        </div>*/}
                            {/*        <div className="mb-3 d-flex">*/}
                            {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
                            {/*                <span className="avatar-title rounded-circle fs-16 bg-success">*/}
                            {/*                    <i className="ri-dribbble-fill"></i>*/}
                            {/*                </span>*/}
                            {/*            </div>*/}
                            {/*            <Input type="text" className="form-control" id="dribbleName" placeholder="Username"*/}
                            {/*                   defaultValue="@dave_adame" />*/}
                            {/*        </div>*/}
                            {/*        <div className="d-flex">*/}
                            {/*            <div className="avatar-xs d-block flex-shrink-0 me-3">*/}
                            {/*                <span className="avatar-title rounded-circle fs-16 bg-danger">*/}
                            {/*                    <i className="ri-pinterest-fill"></i>*/}
                            {/*                </span>*/}
                            {/*            </div>*/}
                            {/*            <Input type="text" className="form-control" id="pinterestName"*/}
                            {/*                   placeholder="Username" defaultValue="Advance Dave" />*/}
                            {/*        </div>*/}
                            {/*    </CardBody>*/}
                            {/*</Card>*/}
                        </Col>

                        <Col xxl={9}>
                            <Card>
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                         role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({active: activeTab === 1})}
                                                onClick={() => {
                                                    tabChange(1);
                                                }}>

                                                {t('user:personalDetails')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                     className={classnames({active: activeTab === 2})}
                                                     onClick={() => {
                                                         tabChange(2);
                                                     }}
                                                     type="button">

                                                {t('user:changePassword')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                     className={classnames({active: activeTab === 3})}
                                                     onClick={() => {
                                                         tabChange(3);
                                                     }}
                                                     type="button">

                                                {t('notification:notifications')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                     className={classnames({active: activeTab === 4})}
                                                     onClick={() => {
                                                         tabChange(4);
                                                     }}
                                                     type="button">
                                                {/*<i className="ri-device-line"/>*/}
                                                {t('device:myDevices')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={1}>

                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={currentUser}
                                                validationSchema={MainUserValidatorSchema}
                                                onSubmit={(values, actions) => {
                                                    onDataSubmit(values);
                                                    actions.setSubmitting(false);
                                                }}
                                            >
                                                {({
                                                      errors,
                                                      touched,
                                                      setFieldValue,
                                                      getFieldProps,
                                                      isSubmitting,
                                                      isValidating
                                                  }) => (
                                                    <Form noValidate={true}
                                                          className={(Object.keys(touched).length > 0) ? 'customValidation was-validated' : 'customValidation'}>

                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="firstnameInput"
                                                                           className="form-label">{t('common:name')}</Label>
                                                                    <Field
                                                                        id="form-name-input"
                                                                        name="name"
                                                                        placeholder={t('user:enterName')}
                                                                        component={InputText}
                                                                        value={getFieldProps('name').value}
                                                                        onChange={(name: string) => {
                                                                            setFieldValue('name', name);
                                                                        }}
                                                                        className={errors.name && touched.name && 'form-control is-invalid'}
                                                                    />
                                                                    {errors.name && touched.name ? (
                                                                        <div className="invalid-feedback">
                                                                            {t('validator:'+errors.name)}
                                                                        </div>
                                                                    ) : touched.name && !errors.name ?
                                                                        <div className="valid-feedback">
                                                                            {t('common:looksGood')}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                                {getFieldProps('cabinetName').value !== undefined && getFieldProps('cabinetName').value !== null &&
                                                                <div className="mb-3">
                                                                    <Label htmlFor="firstnameInput"
                                                                           className="form-label">{t('user:cabinetName')}</Label>
                                                                    <Field
                                                                        id="form-name-input"
                                                                        name="cabinetName"
                                                                        placeholder={t('user:enterName')}
                                                                        component={InputText}
                                                                        value={getFieldProps('cabinetName').value }
                                                                        onChange={(value: string) => {
                                                                            setFieldValue('cabinetName', value);
                                                                        }}
                                                                        className={errors['cabinetName'] && touched['cabinetName'] && 'form-control is-invalid'}
                                                                    />
                                                                    {errors['cabinetName'] && touched['cabinetName'] ? (
                                                                        <div className="invalid-feedback">
                                                                            {t('validator:'+errors['cabinetName'])}
                                                                        </div>
                                                                    ) : touched['cabinetName'] && !errors['cabinetName'] ?
                                                                        <div className="valid-feedback">
                                                                            {t('common:looksGood')}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                                }
                                                            </Col>

                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="phonenumberInput"
                                                                           className="form-label">{t('user:phoneNumber')}</Label>
                                                                    <Field
                                                                        id="form-phone-input"
                                                                        name="phone"
                                                                        placeholder={t('user:enterPhone')}
                                                                        component={InputPhone}
                                                                        value={getFieldProps('phone').value}
                                                                        onChange={(value: string) => {
                                                                            setFieldValue('phone', value);
                                                                        }}
                                                                        inputClassName={errors.phone && touched.phone ? 'form-control is-invalid' : "form-control"}
                                                                    />
                                                                    {errors.phone && touched.phone ? (
                                                                        <div className="invalid-feedback">
                                                                            {t('validator:'+errors.phone)}
                                                                        </div>
                                                                    ) : touched.phone && !errors.phone ?
                                                                        <div className="valid-feedback">
                                                                            {t('common:looksGood')}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12} className="d-flex flex-row justify-content-end">
                                                                <Button variant="primary"
                                                                        type="submit"
                                                                        disabled={isSending}
                                                                        className="btn btn-primary chat-send waves-effect waves-light"
                                                                >
                                                                    {t('common:save')}
                                                                    {isSending &&
                                                                        <ButtonSpinner/>
                                                                    }
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                    </Form>)}
                                            </Formik>
                                        </TabPane>

                                        <TabPane tabId={2}>

                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={formPasswordValues}
                                                validationSchema={MainUserPasswordValidatorSchema}
                                                onSubmit={(values, actions) => {
                                                    onChangePassword(values);
                                                    actions.setSubmitting(false);
                                                }}
                                            >
                                                {({
                                                      errors,
                                                      touched,
                                                      setFieldValue,
                                                      getFieldProps,
                                                      isSubmitting,
                                                      isValidating
                                                  }) => (
                                                    <Form noValidate={true} className={(Object.keys(touched).length > 0) ? 'customValidation was-validated' : 'customValidation'}>
                                                        <Row>
                                                            <Col lg={4}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="oldpasswordInput"
                                                                           className="form-label">{t('user:oldPassword')}*</Label>
                                                                    <Field
                                                                        id="oldpasswordInput"
                                                                        name="oldpassword"
                                                                        placeholder={t('user:enterOldPassword')}
                                                                        component={InputPassword}
                                                                        value={getFieldProps('oldpassword').value}
                                                                        onChange={(value: string) => {
                                                                            setFieldValue('oldpassword', value);
                                                                        }}
                                                                        className={errors.oldpassword && touched.oldpassword && 'form-control is-invalid'}
                                                                    />
                                                                    {errors.oldpassword && touched.oldpassword ? (
                                                                        <div className="invalid-feedback">
                                                                            {t('validator:'+errors.oldpassword)}
                                                                        </div>
                                                                    ) : touched.oldpassword && !errors.oldpassword ?
                                                                        <div className="valid-feedback">
                                                                            {t('common:looksGood')}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="newPasswordInput"
                                                                           className="form-label">{t('user:newPassword')}*</Label>
                                                                    <Field
                                                                        id="newPasswordInput"
                                                                        name="password"
                                                                        placeholder={t('user:enterNewPassword')}
                                                                        component={InputPassword}
                                                                        value={getFieldProps('password').value}
                                                                        onChange={(value: string) => {
                                                                            setFieldValue('password', value);
                                                                        }}
                                                                        className={errors.password && touched.password && 'form-control is-invalid'}
                                                                    />
                                                                    {errors.password && touched.password ? (
                                                                        <div className="invalid-feedback">
                                                                            {t('validator:'+errors.password)}
                                                                        </div>
                                                                    ) : touched.password && !errors.password ?
                                                                        <div className="valid-feedback">
                                                                            {t('common:looksGood')}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="confirmPasswordInput"
                                                                           className="form-label">{t('user:confirmPassword')}*</Label>
                                                                    <Field
                                                                        id="confirmPasswordInput"
                                                                        name="confirmpassword"
                                                                        placeholder={t('user:enterConfirmPassword')}
                                                                        component={InputPassword}
                                                                        value={getFieldProps('confirmpassword').value}
                                                                        onChange={(value: string) => {
                                                                            setFieldValue('confirmpassword', value);
                                                                        }}
                                                                        className={errors.confirmpassword && touched.confirmpassword && 'form-control is-invalid'}
                                                                    />
                                                                    {errors.confirmpassword && touched.confirmpassword ? (
                                                                        <div className="invalid-feedback">
                                                                            {t('validator:'+errors.confirmpassword)}
                                                                        </div>
                                                                    ) : touched.confirmpassword && !errors.confirmpassword ?
                                                                        <div className="valid-feedback">
                                                                            {t('common:looksGood')}
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12} className="d-flex flex-row justify-content-end">
                                                                <Button variant="primary"
                                                                        type="submit"
                                                                        disabled={isSendChangePassword}
                                                                        className="btn btn-primary chat-send waves-effect waves-light"
                                                                >
                                                                    {t('common:save')}
                                                                    {isSendChangePassword &&
                                                                        <ButtonSpinner/>
                                                                    }
                                                                </Button>
                                                            </Col>
                                                        </Row>

                                                    </Form>)}
                                            </Formik>

                                        </TabPane>

                                        <TabPane tabId={3}>
                                            <h5 className="card-title mb-3">{t('notification:notifications')}</h5>

                                            <UserNotificationsChange items={(currentUser.subscriptions || [])}/>
                                        </TabPane>
                                        <TabPane tabId={4}>
<UserDevices/>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
}
</>
            }
                <ToastContainer autoClose={2000}/>

        </>
    );
};

export default MainUserProfileIndex;


