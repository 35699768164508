import {ContentPeerChannelType} from "../PeerChannel/types";
import {EntityActionsType} from "../../types";

export enum ContentProjectsActionTypes {
  API_RESPONSE_SUCCESS = "@@contentProjects/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentProjects/API_RESPONSE_ERROR",
  GET_CONTENT_PROJECTS = "@@contentProjects/GET_CONTENT_PROJECTS",
  UPDATE_CONTENT_PROJECTS = "@@contentProjects/UPDATE_CONTENT_PROJECTS",
  ADD_CONTENT_PROJECTS = "@@contentProjects/ADD_CONTENT_PROJECTS",
  EDIT_CONTENT_PROJECTS = "@@contentProjects/EDIT_CONTENT_PROJECTS",
  REMOVE_CONTENT_PROJECT_MODAL_SHOW = "@@contentProjects/REMOVE_CONTENT_PEER_TARGET_MODAL_SHOW",
  REMOVE_CONTENT_PROJECT_MODAL_HIDE = "@@contentProjects/REMOVE_CONTENT_PEER_TARGET_MODAL_HIDE",
  REMOVE_CONTENT_PROJECT = "@@contentProjects/REMOVE_CONTENT_PROJECT",

  RIGHT_MODAL_SHOW = "@@contentProjects/RIGHT_MODAL_SHOW",
  RIGHT_MODAL_HIDE = "@@contentProjects/RIGHT_MODAL_HIDE",
}

export interface ContentProjectsState {
  items: Array<any>;
  rightModalShow : boolean;
  removeModalShow: boolean;
  selectedItem: ContentProjectType;
  modalEditTitle: string;
  isSending: boolean;
}

export interface ContentProjectType{
  uuid?: string;
  enabled: boolean;
  name: string;
  color: string;
  description?: string;
  channels?: Array<ContentPeerChannelType>;
  actions?: EntityActionsType;
}

export interface ContentProjectsPayload {
  data: Array<any>;
  modalEditTitle : string;
}
