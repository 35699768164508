import React, {useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import * as route from "../../api/routes";
import TableList from "../TableList";
import TablePeerChannelColumns from "./TablePeerChannelColumns";
import {ContentPeerChannelType} from "../../redux/Content/PeerChannel/types";

import '../../i18/config';
import {useTranslation} from "react-i18next";
import {connectorHandlerCode} from "../../constants/ConnectorHandler";

interface TableActivitiesProps {
    items: [] | Array<ContentPeerChannelType>;
}

const TablePeerChannel = ({ items }: TableActivitiesProps) => {
    const { t } = useTranslation();

    const handleClick = (peerChannel:ContentPeerChannelType) => {
        window.location.href=route.STAT_CONTENT_CHANNEL.replace(/:uuidChannel/,peerChannel.uuid);

        if (peerChannel?.connector?.handler?.code && peerChannel.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS){
            window.location.href=route.CONTENT_ACTIVITY_IDEAS;
        }
    }


    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('channel:topChannels')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_CHANNELS_LIST}>{t('channel:manageChannels')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <TableList
                    columns={TablePeerChannelColumns()}
                    data={items}
                    key={'contentPeerChannelTable_'+Math.round(Math.random() * (99999 - 11111) + 11111)}
                    defaultSortFieldId={'views'}
                    onRowClick={handleClick}
                />

            </CardBody>
        </Card>
    );
}

export default TablePeerChannel;