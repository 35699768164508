import React from 'react';
import * as Yup from 'yup';
import {TaskMemberType, TaskMemberTypeType} from "../../../redux/Task/TaskMember/types";

export const TaskValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    completionPercent: Yup.number()
        .nullable(true)
        .min(0, 'min0')
        .max(100, 'max100'),
    members: Yup.array().required('required')
        .test('taskNeedResponsible','taskNeedResponsible',
        function(value){
            return new Promise((resolve, reject) => {
                let _responsible = (value || []).filter((item: TaskMemberType) => {
                    return item.type === TaskMemberTypeType.TYPE_RESPONSIBLE
                });

                if ((_responsible || []).length > 0) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            });
        })
});