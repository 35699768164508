import {MainCabinetType} from "../../Main/Cabinet/types";

export enum SaleLicenseActionTypes {
    API_RESPONSE_SUCCESS = "@@saleLicense/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleLicense/API_RESPONSE_ERROR",
    ACTIVATE_LICENSE_KEY = "@@saleLicense/ACTIVATE_LICENSE_KEY",
}

export interface SaleLicenseState {
    isFetched: boolean,
    isLoading: boolean,
}

export interface LicenseKeyType {
    uuid: string;
    code: string;
    activeFrom?: string;
    activeTo?: string;
    createdAt: string;
    updatedAt?: string;
    cabinet?: string;
    daysLeft?: number;
    daysAll?: number;
    costLeft?: number;
    status: string;
}

export const LicenseKeyStatus = {
    N: {
        label: 'New',
        class: 'info',
    },
    A: {
        label: 'Active',
        class: 'success',
    },
    E: {
        label: 'Expired',
        class: 'dark',
    },
}

export interface SaleLicenseKeyActivatePayload {
    cabinet: MainCabinetType,
    licenseKey: LicenseKeyType
}

export enum LicenseProductParam {
    MAIN_MAX_USERS = 'mainMaxUsers',//	Доступное количество пользователей
    CONTENT_MAX_PROJECTS = 'contentMaxProjects',//	Доступное количество проектов
    CONTENT_SCHEDULED_ACTIVITIES = 'contentScheduledActivities',//	Отложенный постинг
    CONTENT_MAX_COMPETITORS ='contentMaxCompetitors',	// Доступное количество конкурентов
    CONTENT_HISTORY_ACTIVITIES = 'contentHistoryActivities',//	Глубина истории активностей
    CONTENT_PEER_USER_ACTIVITIES = 'contentPeerUserActivities', //История действий пользователей
    CONTENT_PEER_USER_MEMBER_MANAGE ='contentPeerUserMemberManage',	//Управление подписками пользователей
    CONTENT_MAX_TARGETS ='contentMaxTargets',//	Доступное количество активных целей
    CONTENT_COMMENTS_VIEW='contentCommentsView',//	Просмотр комментариев
    CONTENT_COMMENT_QUICK_ANSWERS='contentCommentQuickAnswers',//	Модуль быстрых ответов
    CONTENT_COMMENT_ANSWERS	='contentCommentAnswers',//Ответы на комментарии

    CONTENT_MAX_SOCIAL_SERVICES	='contentMaxSocialServices', //Доступное количество социальных сервисов
    CONTENT_MAX_VIDEO_SERVICES = 'contentMaxVideoServices',//Доступное количество видео сервисов
    CONTENT_MAX_COUNTER_SERVICES = 'contentMaxCounterServices',//	Доступное количество сервисов счетчиков
    CONTENT_MAX_SOCIAL_SERVICE_INSTAGRAM = 'contentMaxSocialServiceInstagram',//	Доступное количество подключенных сервисов Instagram
    CONTENT_MAX_SOCIAL_SERVICE_FACEBOOK ='contentMaxSocialServiceFacebook',//	Доступное количество подключенных сервисов Facebook
    CONTENT_MAX_SOCIAL_SERVICE_VK	='contentMaxSocialServiceVk',//Доступное количество подключенных сервисов Vk
    CONTENT_MAX_SOCIAL_SERVICE_TWITTER ='contentMaxSocialServiceTwitter',//	Доступное количество подключенных сервисов Twitter
    CONTENT_MAX_SOCIAL_SERVICE_OK='contentMaxSocialServiceOk',//	Доступное количество подключенных сервисов Ok
    CONTENT_MAX_SOCIAL_SERVICE_TELEGRAM='contentMaxSocialServiceTelegram',//	Доступное количество подключенных сервисов Telegram
    CONTENT_MAX_SOCIAL_SERVICE_TELEGRAM_BOT='contentMaxSocialServiceTelegramBot',//	Доступное количество подключенных сервисов TelegramBot

    CONTENT_MAX_SOCIAL_SERVICE_PINTEREST='contentMaxSocialServicePinterest',//	Доступное количество подключенных сервисов Pinterest
    CONTENT_MAX_SOCIAL_SERVICE_LINKEDIN='contentMaxSocialServiceLinkedIn',//	Доступное количество подключенных сервисов Pinterest

    CONTENT_MAX_WEB_SERVICE_BITRIX='contentMaxWebServiceBitrix',//	Доступное количество подключенных сервисов Bitrix
    CONTENT_MAX_WEB_SERVICE_WORDPRESS='contentMaxWebServiceWordPress',//	Доступное количество подключенных сервисов WordPress
    CONTENT_MAX_SOCIAL_SERVICE_VIBER='contentMaxSocialServiceViber',//	Доступное количество подключенных сервисов Viber
    CONTENT_MAX_SOCIAL_SERVICE_WHATSAPP='contentMaxSocialServiceWhatsApp',//	Доступное количество подключенных сервисов WhatsApp
    CONTENT_MAX_SOCIAL_SERVICE_AVITO='contentMaxSocialServiceAvito',//	Доступное количество подключенных сервисов Avito
    CONTENT_MAX_SOCIAL_SERVICE_OZON='contentMaxSocialServiceOzon',//	Доступное количество подключенных сервисов Ozon
    CONTENT_MAX_SOCIAL_SERVICE_TIKTOK='contentMaxSocialServiceTikTok',//	Доступное количество подключенных сервисов TikTok
    CONTENT_MAX_VIDEO_SERVICE_YOUTUBE='contentMaxVideoServiceYouTube',//	Доступное количество подключенных сервисов YouTube
    CONTENT_MAX_COUNTER_SERVICE_YANDEX='contentMaxCounterServiceYandex',//	Доступное количество подключенных сервисов Yandex.Metrika
    CONTENT_MAX_COUNTER_SERVICE_GOOGLE='contentMaxCounterServiceGoogle',//	Доступное количество подключенных сервисов Google.Analytics

    CONTENT_MAX_AI_SERVICE_CHAT_GPT='contentMaxAIServiceChatGPT',//	Доступное количество подключенных сервисов ChatGPT
    CONTENT_MAX_AI_SERVICE_GOOGLE_BARD='contentMaxAIServiceGoogleBard',//	Доступное количество подключенных сервисов Google Bard

    CONTENT_MAX_OWNER_PEER_CHANNELS='contentMaxOwnerPeerChannels',//	Доступное количество собственных каналов
    CONTENT_MAX_SOCIAL_SERVICE_POSTS='contentMaxSocialServicePosts',//	Доступное количество постов за период
    CONTENT_MAX_SERVICE_BIO_CHANGE='contentMaxServiceBIOChange',//	Доступное количество изменений BIO за период

    STAT_COMPARE_COMPETITORS = 'statCompareCompetitors',	//	Сравнение с конкурентами
    STAT_HISTORY_DATA='statHistoryData', //Глубина истории статистики
    STAT_TOP_ACTIVITIES	= 'statTopActivities', //Лучшие посты за период
    STAT_TOP_PEER_USER_ACTIVITIES	= 'statTopPeerUserActivities',//Наиболее активные пользователи за период
    STAT_POSTS_ON_DATA_APPLY='statPostsOnDataApply',//	Наложение постов на графики
    STAT_MAX_REPORTS = 'statMaxReports',//	Максимальное количество отчетов
    STAT_CSV_EXPORTS = 'statCSVExports',//Экспорт данных в CSV/XLSX
    SALE_MAX_PRODUCTS = 'saleMaxProducts',
    SALE_MAX_PRICE_TYPES = 'saleMaxPriceTypes',
    SALE_MAX_STORES = 'saleMaxStores',
    MAIN_MAX_WEBHOOKS = 'mainMaxWebhooks',
    SALE_MAX_IMPORTS = 'saleMaxImports',
    SALE_MAX_WIDGETS = 'saleMaxWidgets',
    MAIN_STORAGE = 'mainStorage'

}