import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { StatPeerChannelContentActionTypes } from "./types";
import { statPeerChannelApiResponseSuccess, statPeerChannelApiResponseError} from "./actions";

import {
  getPeerActivities as getPeerActivitiesApi, getContentPeerChannels,
  getStatChannelsTiles as getStatChannelsTilesApi,
  getStatPeerTargets as getStatPeerTargetsApi,
  getStatChannelsActivities as getStatChannelsActivitiesApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";
import {getDashboardContentScheduleActivities} from "../DashboardContent/actions";


function* getStatPeerChannelContentTiles({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatChannelsTilesApi, data);
    yield put(
        statPeerChannelApiResponseSuccess(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerChannelApiResponseError(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerChannelContentActivitiesData({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatChannelsActivitiesApi, data);
    yield put(
        statPeerChannelApiResponseSuccess(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerChannelApiResponseError(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerChannelContentTargets({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getStatPeerTargetsApi, data);
    yield put(
        statPeerChannelApiResponseSuccess(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerChannelApiResponseError(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerChannelContentActivities({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivitiesApi, data);
    yield put(
        statPeerChannelApiResponseSuccess(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerChannelApiResponseError(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES, error));
    yield call(showErrorNotification, error);
  }
}

function* getStatPeerChannelContentScheduleActivities({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivitiesApi, data);
    yield put(
        statPeerChannelApiResponseSuccess(StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(statPeerChannelApiResponseError(StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetStatPeerChannelContentTiles() {
  yield takeEvery(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TILES, getStatPeerChannelContentTiles);
}

export function* watchGetStatPeerChannelContentChannelsActivitiesData() {
  yield takeEvery(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES_DATA, getStatPeerChannelContentActivitiesData);
}

export function* watchGetStatPeerChannelContentTargets() {
  yield takeEvery(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_TARGETS, getStatPeerChannelContentTargets);
}

export function* watchGetStatPeerChannelContentActivities() {
  yield takeEvery(StatPeerChannelContentActionTypes.GET_STAT_CHANNEL_ACTIVITIES, getStatPeerChannelContentActivities);
}

export function* watchGetStatPeerChannelContentScheduleActivities() {
  yield takeEvery(StatPeerChannelContentActionTypes.GET_SCHEDULE_STAT_CHANNEL_ACTIVITIES, getStatPeerChannelContentScheduleActivities);
}

function* statPeerChannelContentSaga() {
  yield all([
    fork(watchGetStatPeerChannelContentTiles),
    fork(watchGetStatPeerChannelContentTargets),
    fork(watchGetStatPeerChannelContentActivities),
    fork(watchGetStatPeerChannelContentChannelsActivitiesData),
      fork(watchGetStatPeerChannelContentScheduleActivities)
  ]);
}

export default statPeerChannelContentSaga;
