import React, {useContext, useEffect} from 'react';
import { Navigate, Routes, Route } from "react-router-dom";
import * as appRoute from "../api/routes";

import NonAuthLayout from "../layouts/NonAuthLayout";
import TwoColumnLayout from "../layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected, AccessRoute } from './AuthProtected';
import {useRedux} from "../hooks";
import {WebSocketContext} from "../api/WebSocket/webSocketProvider";
import CoverError from "../blocks/Main/Auth/CoverError";
import {getCurrentDevice, getUserDevices} from "../redux/Main/Device/actions";

const Index = () => {

    const { dispatch, useAppSelector } = useRedux();
    const webSocket = useContext(WebSocketContext);

    const { mainLogin, mainDevice } = useAppSelector(state => ({
        mainLogin: state.Login,
        mainDevice: state.MainDevice
    }));



    useEffect(()=>{

        if (mainLogin.isUserLogin === true){
            webSocket.join();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mainLogin?.isUserLogin])

    useEffect(()=>{
        if (mainDevice?.isNeedReload === true){
            dispatch(getUserDevices());
        }
    },[dispatch, mainDevice?.isNeedReload])

    useEffect(()=>{

        const el = document.getElementById("idMainPreloader");
        if (el) {
            el.classList.add('mainPreloaderHidden');
            setTimeout(() => {
                document.getElementById("idMainPreloader").remove()
            }, 1000);
        }
        dispatch(getCurrentDevice());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);



    return (
        <>
            <Routes>
                {publicRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <NonAuthLayout>
                                <route.component {...route.props}/>
                            </NonAuthLayout>
                                }
                        key={idx}
                    />
                ))}
                {authProtectedRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <AuthProtected>
                                {route.path === appRoute.MAIN_CABINET_LIST ?
                                    <NonAuthLayout>
                                        <route.component {...route.props}/>
                                    </NonAuthLayout>
                                    :
                                <TwoColumnLayout>
                                    <route.component {...route.props}/>
                                </TwoColumnLayout>
                                }
                            </AuthProtected>
                        }
                        key={idx}
                    />
                ))}
                <Route path="*" element={<Navigate to={appRoute.ERROR_404} />}/>
            </Routes>
        </>
    );
};

export default Index;