import React from 'react';
import * as Yup from 'yup';
import {PHONE_REGEX} from "../../../data/settings";

export const UserEditValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    email: Yup.string()
        .min(5, "tooShort")
        .max(50, "tooLong")
        .email("invalidEmail")
        .required('required'),
    phone: Yup.string()
        .matches(PHONE_REGEX, 'invalidPhone')
});