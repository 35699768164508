import {EntityActionsType} from "../../types";

export interface EventTypeType{
  uuid?: string;
  enabled?: boolean;
  code: string;
  icon?: string;
  class?: string;
  text?: string;
  isManaged?: boolean;
  actions?: EntityActionsType;
  $isWebhookAvailable?: boolean;
}

export enum EventTypeCodes {
  CONTENT_SERVICE_CONNECTION_ERROR = 'CONTENT_SERVICE_CONNECTION_ERROR',
  CONTENT_SERVICE_PUBLISH_ACTIVITY_ERROR = 'CONTENT_SERVICE_PUBLISH_ACTIVITY_ERROR',
  CONTENT_SERVICE_PEERTARGET_CLOSE_SUCCESS = 'CONTENT_SERVICE_PEERTARGET_CLOSE_SUCCESS',
  CONTENT_SERVICE_PEERTARGET_CLOSE_FAIL = 'CONTENT_SERVICE_PEERTARGET_CLOSE_FAIL',
  CONTENT_NEW_PEERCOMMENT = 'CONTENT_NEW_PEERCOMMENT',
  CONTENT_NEW_PEERPOST = 'CONTENT_NEW_PEERPOST',
  CONTENT_NEW_BIO = 'CONTENT_NEW_BIO',
  CONTENT_NEW_STORY = 'CONTENT_NEW_STORY',
  MAIN_VERSION_UPDATE = 'MAIN_VERSION_UPDATE',
  CONTENT_CHANNEL_INIT = 'CONTENT_CHANNEL_INIT',
  REPORT_COMMON_WEEK_SHORT = 'REPORT_COMMON_WEEK_SHORT',
  NOTIFICATION_UNREAD = 'NOTIFICATION_UNREAD',
  SALE_ORDER_ADDED = 'SALE_ORDER_ADDED',
  SALE_ORDER_PAID = 'SALE_ORDER_PAID',
  SALE_ORDER_CANCELLED = 'SALE_ORDER_CANCELLED',
  EVENT_WEBHOOK_ERROR = 'EVENT_WEBHOOK_ERROR',
  SALE_ORDER_STATUS_CHANGED = 'SALE_ORDER_STATUS_CHANGED'
}

