import React, {useEffect, useState} from "react";
import {Link}  from 'react-router-dom';
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {getHandlerIconByCode} from "../../../helpers/functions";
import InputSwitch from "../../../components/InputSwitch";
import Loader from "../../../components/Loader";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {UncontrolledTooltip} from "reactstrap";
interface ItemProps {
    item: ContentPeerChannelType;
    onChange: (event: ContentPeerChannelType) => void;
    currency?: CurrencyType;
    isEnabled: boolean;
    disabled?: boolean;
}
const ChannelItemProductIntegration = ({ item, onChange, isEnabled, currency, disabled = false }: ItemProps) => {

    const [isLoading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation(['common']);

    const [channelName, setChannelName] = useState<string>(item.name)

    useEffect(()=>{
        if (item?.connector?.handler?.code && item.connector.handler.code === connectorHandlerCode.CONNECTOR_HANDLER_IDEAS){
            setChannelName(t('channel:channelIdeas'));
        } else {
            setChannelName(item.name);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[item.name])

    useEffect(()=>{

        if (item?.isUpdating && item.isUpdating === true){
            setLoading(true);
        } else {
            setLoading(false);
        }

    },[item?.isUpdating])

    return(
        <>
            <div className="d-flex flex-row">
                <div className="d-flex flex-row flex-grow-0">
                    <Link to="#" onClick={()=>onChange(item)}>
                    <div className="avatar-xs d-flex flex-shrink-0 position-relative" id={'contentChannelItemProductIntegration_'+item.uuid}>
                        {item.avatar ?
                            <img src={item.avatar} className="rounded-circle img-fluid" alt=""/>
                        :
                            <span className={"avatar-title rounded-circle"}>{item.name[0]}</span>
                        }
                        { item.connector && item.connector.handler && item.connector.handler.code &&
                        <div className="avatar-xs12 position-absolute handlerIconTopLeft">
                            <img src={getHandlerIconByCode(item.connector.handler.code)} className="img-fluid p-1 rounded-circle" alt=""/>
                        </div>
                        }
                        {currency && currency.code &&
                            <span className="position-absolute badge rounded-pill bg-info fs-8 currencyIconBottomRight">{currency.code}</span>
                        }
                    </div>
                    <UncontrolledTooltip target={'contentChannelItemProductIntegration_'+item.uuid} placement="top">
                        {channelName}
                    </UncontrolledTooltip>
                    </Link>
                </div>
                <div className="justify-content-end d-flex text-truncate flex-shrink-0 align-items-center flex-grow-0 ms-2">
                    {isLoading ?
                        <Loader addClassNames="sm d-inline-block"/>
                        :
                        <InputSwitch id={'id'}
                                     value={isEnabled}
                                     onChange={()=>onChange(item)}
                                     disabled={disabled}
                                    className="form-switch-xs d-flex flex-column justify-content-center align-items-end"/>
                    }
                </div>
            </div>
        </>
    )
}

export default ChannelItemProductIntegration;