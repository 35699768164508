import {SaleOrderDeliveryItemActionTypes, SaleOrderDeliveryItemPayload} from "./types";
import {MainMetaType} from "../../Main/common";

export const saleOrderDeliveryItemApiResponseSuccess = (actionType: string, data: any) => ({
  type: SaleOrderDeliveryItemActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const saleOrderDeliveryItemApiResponseError = (actionType: string, error: string) => ({
  type: SaleOrderDeliveryItemActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSaleOrderDeliveryItems = (data: MainMetaType) => ({
  type: SaleOrderDeliveryItemActionTypes.GET_SALE_ORDER_DELIVERY_ITEMS,
  payload: data,
});

export const showRightModalSaleOrderDeliveryItem= (data: SaleOrderDeliveryItemPayload) => ({
  type: SaleOrderDeliveryItemActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSaleOrderDeliveryItem= () => ({
  type: SaleOrderDeliveryItemActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSaleOrderDeliveryItem = (data: any) => ({
  type: SaleOrderDeliveryItemActionTypes.UPDATE_SALE_ORDER_DELIVERY_ITEM,
  payload: data,
});

export const editDataSaleOrderDeliveryItem = (data: SaleOrderDeliveryItemPayload) => ({
  type: SaleOrderDeliveryItemActionTypes.EDIT_SALE_ORDER_DELIVERY_ITEM,
  payload: data,
});

export const removeSaleOrderDeliveryItemModalShow = (data: SaleOrderDeliveryItemPayload) => ({
  type: SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_SHOW,
  payload: data,
});

export const removeSaleOrderDeliveryItemModalHide = () => ({
  type: SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM_MODAL_HIDE,
});

export const onRemoveSaleOrderDeliveryItem = (data: SaleOrderDeliveryItemPayload) => ({
  type: SaleOrderDeliveryItemActionTypes.REMOVE_SALE_ORDER_DELIVERY_ITEM,
  payload: data,
});

export const clearSaleOrderDeliveryItemNeedReload= () => ({
  type: SaleOrderDeliveryItemActionTypes.CLEAR_NEED_ORDER_RELOAD,
});