// types
import {SupportTicketActionTypes, SupportTicketState, SupportTicketType} from "./types";

export const NewTicketItem:SupportTicketType = {
  enabled: true,
  name: '',
  text: ''
}

export const INIT_STATE_SUPPORT_TICKET: SupportTicketState = {
  items: [],
  isLoading: false,
  selectedItem: NewTicketItem,
  rightModalShow: false,
  removeModalShow: false,
  rewardModalShow: false,
  isSending: false,
  modalEditTitle:'',
  isNeedReload: false
};

const SupportTicket = (state = INIT_STATE_SUPPORT_TICKET, action: any) => {
  switch (action.type) {
    case SupportTicketActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SupportTicketActionTypes.GET_SUPPORT_TICKETS:
        case SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKETS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isLoading: false,
            isNeedReload: false
          };
        case SupportTicketActionTypes.UPDATE_SUPPORT_TICKET:
          return {
            ...state,
            selectedItem: NewTicketItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SupportTicketActionTypes.GET_SUPPORT_TICKET_DETAIL:
        case SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKET_DETAIL:
          return {
            ...state,
            selectedItem: action.payload.data.items.pop(),
            isLoading: false
          };
        case SupportTicketActionTypes.REMOVE_SUPPORT_TICKET:
          return {
            ...state,
            selectedItem: NewTicketItem,
            removeModalShow: false,
            isNeedReload: true
          };
        case SupportTicketActionTypes.SEND_REWARD_SUPPORT_TICKET:
          return {
            ...state,
            rewardModalShow:false,
            isSending: false
          };
        default:
          return { ...state };
      }

    case SupportTicketActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SupportTicketActionTypes.GET_SUPPORT_TICKETS:
        case SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKETS:
          return {
            ...state,
            isLoading: false
          };
        case SupportTicketActionTypes.GET_SUPPORT_TICKET_DETAIL:
        case SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKET_DETAIL:
          return {
            ...state,
            isLoading: false
          }
        case SupportTicketActionTypes.UPDATE_SUPPORT_TICKET:
          return {
            ...state,
            isSending: false
          };
        case SupportTicketActionTypes.REMOVE_SUPPORT_TICKET:
          return {
            ...state,
            removeModalShow: false,
          };
        case SupportTicketActionTypes.SEND_REWARD_SUPPORT_TICKET:
          return {
            ...state,
            isSending: false
          };
        default:
          return { ...state };
      }
    case SupportTicketActionTypes.EDIT_SUPPORT_TICKET:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SupportTicketActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewTicketItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SupportTicketActionTypes.REMOVE_SUPPORT_TICKET_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SupportTicketActionTypes.REMOVE_SUPPORT_TICKET_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewTicketItem,
        removeModalShow: false
      }
    case SupportTicketActionTypes.REWARD_SUPPORT_TICKET_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rewardModalShow: true
      }
    case SupportTicketActionTypes.REWARD_SUPPORT_TICKET_MODAL_HIDE:
      return {
        ...state,
        rewardModalShow: false
      }
    case SupportTicketActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SupportTicketActionTypes.UPDATE_SUPPORT_TICKET:
      return {
        ...state,
        isSending: true
      };
    case SupportTicketActionTypes.GET_SUPPORT_TICKETS:
    case SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKETS:
    case SupportTicketActionTypes.GET_SUPPORT_TICKET_DETAIL:
    case SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKET_DETAIL:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default SupportTicket;
