import React, {useEffect, useState, useRef} from 'react';
import {Modal, Button, ModalBody, Row, Col} from 'reactstrap';

import {
    Formik,
    Form,
    Field
} from 'formik';

import InputText from "./InputText";

import '../i18/config';
import {useTranslation} from "react-i18next";
import {ContentModalTwoStepValidatorSchema} from "./ValidatorModalTwoStep";

interface ModalTwoStepProps {
    isShow : boolean,
    handleShow: ()=>void,
    onChange: (code:string) => void
}

interface ModalTwoStepFormValues {
    codeInput0: string,
    codeInput1: string,
    codeInput2: string,
    codeInput3: string,
    codeInput4: string,
}

const ModalTwoStep = ({isShow, handleShow, onChange}:ModalTwoStepProps) => {

    const { t } = useTranslation(['common']);

    const [isModalShow, setModalShow] = useState<boolean>(isShow);
    const [formValues, setFormValues] = useState<ModalTwoStepFormValues>(null);

    const codeFormPrefix = 'codeInput';

    const codeLength = 5;

    const setInitialValues = () => {
        let newFormValues : ModalTwoStepFormValues = {
            codeInput0:'',
            codeInput1:'',
            codeInput2:'',
            codeInput3:'',
            codeInput4:'',
        };

        setFormValues({...newFormValues});
    }


    useEffect(() => {

        setInitialValues();

    },[]);

    useEffect(() => {
        setModalShow(isShow);
        if (isShow) {
            setInitialValues();
        }
    },[isShow]);

    const onDataSubmit = (formData:any) =>{

        let verificationCode='';

        [...Array(codeLength)].map((x:number, i: number) => {
            if (formData[codeFormPrefix+i] !== undefined){
                verificationCode+=formData[codeFormPrefix+i];
            }
            return x;
        });

        if (verificationCode.length===codeLength){
            onChange(verificationCode)
            setInitialValues();
        }
    }

    return (
        <Modal
            isOpen={isModalShow}
            toggle={() => {
                handleShow();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            autoFocus={false}
        >
            <ModalBody className="p-5">

                    <h3 className="mb-3 text-center">{t('connector:enterCode')}</h3>

                    <div className="d-flex flex-grow-1 flex-row">
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ContentModalTwoStepValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) =>
                        (<>
                            <Form noValidate={true} className="customValidation">
                                <Row className="d-flex d-flex flex-grow-1 flex-row justify-content-center mb-5">
                                    {[...Array(codeLength)].map((x:number, i: number) => (
                                        <Col
                                            className={ "w-20"+ (touched[codeFormPrefix+i] ? " was-validated" : '')}
                                            key={'twoStep_'+i}
                                        >
                                            <Field
                                                id={"form-"+codeFormPrefix+i}
                                                autoFocus={i===0}
                                                name={codeFormPrefix+i}
                                                component={InputText}
                                                value={getFieldProps(codeFormPrefix+i).value}
                                                onChange={(value: string) => {
                                                    setFieldValue(codeFormPrefix+i, value);
                                                    if (i<codeLength-1) {
                                                        document.getElementById("form-"+codeFormPrefix+(i+1)).focus();
                                                    }
                                                }}
                                                className="form-control bg-light border-light text-center"
                                                maxLength="1"
                                                required={true}
                                            />
                                        </Col>
                                    ))
                                    }
                                </Row>
                                <div className="hstack gap-2 justify-content-center mb-4">
                                    <Button to="#" className="btn btn-light fw-medium" onClick={handleShow}>
                                        <i className="ri-close-line me-1 align-middle"/> {t('common:close')}</Button>
                                    <Button to="#" className="btn btn-success" type="submit">{t('common:confirm')}</Button>
                                </div>

                                <div className="m-0 alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-3" role="alert">

                                    <i className="ri-error-warning-line me-2 align-middle"/> <strong className="pe-2">{t('common:attention')}!</strong>

                                    {t('connector:serviceConnectionGPDRDescription')}
                                </div>
                            </Form>
                            </>
                        )
                    }
                </Formik>
                    </div>



            </ModalBody>
        </Modal>
    );
};

export default ModalTwoStep;