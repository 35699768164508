
export enum ContentActivitiesFilterActionTypes {

  SET_PROJECTS = "@@contentActivitiesFilter/SET_PROJECTS",
  SET_MODES = "@@contentActivitiesFilter/SET_MODES",
  SET_TYPES = "@@contentActivitiesFilter/SET_TYPES",
  SET_CHANNELS = "@@contentActivitiesFilter/SET_CHANNELS",

  CLEAR_ALL = "@@contentActivitiesFilter/CLEAR_ALL",
}

export interface ContentActivitiesFilterState {
  projects: Array<number>;
  modes: Array<number>;
  types: Array<number>;
  channels: Array<number>;
  isSet: boolean;
}
