import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputSwitch from "../../../components/InputSwitch";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import {ProductIntegrationParamType, ProductIntegrationType} from "../../../redux/Sale/ProductIntegration/types";
import ButtonSpinner from "../../../components/ButtonSpinner";
import {
    saleProductIntegrationChannelModalHide,
    updateProductIntegrationChannel
} from "../../../redux/Sale/Product/actions";
import ChannelItemBigLine from "../../Content/PeerChannel/channelItemBigLine";
import InputSelect from "../../../components/InputSelect";
import {CurrencyType} from "../../../redux/Sale/Currency/types";
import {SaleProductIntegrationValidatorSchema} from "./validator";
import {SaleStoreType} from "../../../redux/Sale/Store/types";
import InputText from "../../../components/InputText";
import {MetaAllowEditFields} from "../../../redux/Main/Meta/types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {connectorHandlerCode} from "../../../constants/ConnectorHandler";
import {getSaleProductsIntegrationParamFromRemote} from "../../../redux/Sale/Integration/actions";
import Loader from "../../../components/Loader";
import SaleIntegrationItemBigLine from "../Integration/SaleIntegrationItemBigLine";

const RightModalProductIntegrationChannel = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isShowAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false);
    const [channelIntegration, setChannelIntegration] = useState<ProductIntegrationType>(null);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
    const [stores, setStores] = useState<SaleStoreType[]>([]);
    const [saleProductIntegrationParamsVk, setSaleProductIntegrationParamsVk] = useState<ProductIntegrationParamType[]>([]);


    const {
        saleIntegrationState,
        isLoadingProductIntegrationParams,
        saleProduct,
        saleCurrency,
        saleStore,
        isSending
    } = useAppSelector(state => ({
        saleIntegrationState: state.SaleIntegration,
        isLoadingProductIntegrationParams: state.SaleIntegration.isLoadingProductIntegrationParams,
        saleProduct: state.SaleProduct,
        saleCurrency: state.SaleCurrency,
        saleStore: state.SaleStore,
        isSending: state.SaleProduct.isSending
    }));

    const [formValues, setFormValues] = useState<ProductIntegrationType>(saleProduct.selectedIntegrationChannel);

    useEffect(() => {

        if (channelIntegration?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_VK){
            if ((saleProductIntegrationParamsVk || []).length<1){
                dispatch(getSaleProductsIntegrationParamFromRemote({connector:channelIntegration.channel.connector}));
            }
        }

        setCurrentFormValues({...channelIntegration});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelIntegration]);

    const setCurrentFormValues = (item: ProductIntegrationType) => {
        let formValues = item;

        if (item?.meta && item.meta !== null){
            Object.keys(item.meta).map((metaKey)=>{
                if (Object.keys(MetaAllowEditFields).includes(metaKey)) {
                    formValues['meta_' + metaKey] = item.meta[metaKey];
                }
                return metaKey;
            })
        }

        if (item?.param?.remoteCategoryId){
            formValues['productIntegrationParam']={uuid: item.param.remoteCategoryId, name: item.param.remoteCategoryName};
        }

        setFormValues(formValues);
    }

    useEffect(()=> {

        if (saleIntegrationState?.productIntegrationParams && (saleIntegrationState.productIntegrationParams || []).length>0){

            let selectedParams = [];

            (saleIntegrationState.productIntegrationParams || []).map((item:ProductIntegrationParamType)=>{
                if (item.remoteCategoryId && item.remoteCategoryName){
                    selectedParams.push({id: item.remoteCategoryId, name: item.remoteCategoryName});
                }
                return item;
            });

            setSaleProductIntegrationParamsVk(selectedParams);
        } else {
            setSaleProductIntegrationParamsVk([]);
        }

    },[saleIntegrationState?.productIntegrationParams]);

    useEffect(() => {

        if (saleProduct?.selectedIntegrationChannel?.channel || saleProduct?.selectedIntegrationChannel?.saleIntegration) {
            setChannelIntegration({...saleProduct.selectedIntegrationChannel});
        } else {
            setChannelIntegration(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProduct.selectedIntegrationChannel]);
    const handleClose = () => dispatch(saleProductIntegrationChannelModalHide());

    const toggleShowAdditionalSettings = () => {
        setShowAdditionalSettings(!isShowAdditionalSettings);
    };

    useEffect(() => {

        if (saleCurrency?.items && (saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }
    }, [saleCurrency?.items]);

    useEffect(() => {
        if (saleStore?.items && (saleStore?.items || []).length>0){
            setStores([...saleStore.items]);
        } else {
            setStores([]);
        }
    }, [saleStore?.items]);

    const onDataSubmit = (formData:any) => {

        let data: any = {};

        if (channelIntegration?.uuid) {
            data.uuid= channelIntegration.uuid;
        }

        data.enabled = formData.enabled;

        if (channelIntegration?.channel) {
            data.channel = channelIntegration.channel;
        }
        if (channelIntegration?.saleIntegration) {
            data.saleIntegration = channelIntegration.saleIntegration;
        }

        data.priceType = channelIntegration.priceType;
        data.product =
            { uuid : saleProduct.selectedItem.uuid};
        data.meta = channelIntegration.meta;

        if (formData['currency']!== undefined){
            if (formData['currency']['value'] !== undefined) {
                data.currency = { uuid: formData['currency']['value']};
            } else {
                data.currency=formData['currency'];
            }
        }
        if (formData['store']!== undefined){
            if (formData['store'] !== null && formData['store']['value'] !== undefined) {
                data.store = { uuid: formData['store']['value']};
            } else {
                data.store=formData['store'];
            }
        }

        if (formData['productIntegrationParam']!== undefined){
            if (formData['productIntegrationParam']['value'] !== undefined) {
                data.param = {
                    remoteCategoryId: formData['productIntegrationParam']['value'],
                    remoteCategoryName: formData['productIntegrationParam']['label']
                };
            } else if (channelIntegration?.param) {
                data.param=channelIntegration.param;
            }
        }

        if (channelIntegration?.meta && channelIntegration.meta !== null){
            Object.keys(channelIntegration.meta).map((metaKey)=>{
                if (Object.keys(MetaAllowEditFields).includes(metaKey)) {
                    if (formData['meta_' + metaKey] && formData['meta_' + metaKey] !== null && formData['meta_' + metaKey].length > 0) {
                        data.meta[metaKey] = formData['meta_' + metaKey];
                    } else {
                        data.meta[metaKey] = null;
                    }
                }
                return metaKey;
            })
        }

        dispatch(updateProductIntegrationChannel(data));
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleProduct.rightModalIntegrationChannelShow}
                    id="salePriceTypeEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={SaleProductIntegrationValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('integration:changeIntegration')}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                                    {channelIntegration?.channel &&
                                    <Row>
                                        <div className="mb-3">
                                            <ChannelItemBigLine item={channelIntegration.channel}/>
                                        </div>
                                    </Row>
                                    }
                                    {channelIntegration?.saleIntegration &&
                                        <Row>
                                            <div className="mb-3">
                                                <SaleIntegrationItemBigLine item={channelIntegration.saleIntegration}/>
                                            </div>
                                        </Row>
                                    }
                            <Row>
                                <div className="d-flex">
                                    <div className="ms-1 mb-3 d-flex flex-column align-items-center me-5">
                                        <Field
                                            id="form-enabled-input"
                                            placeholder={t('common:active')}
                                            name="enabled"
                                            component={InputSwitch}
                                            value = {getFieldProps('enabled').value}
                                            onChange={(enabled:boolean) => {
                                                setFieldValue('enabled', !getFieldProps('enabled').value);
                                            }}
                                            required={false}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className={ "mb-3"+ (touched.currency ? " was-validated" : '')}>
                                    <Label className="form-label" htmlFor={"choices-currency-input"}>{t('currency:currency')}</Label>
                                    <Field
                                        id="choices-currency-input"
                                        name={"currency"}
                                        placeholder={t('currency:chooseCurrency')}
                                        component={InputSelect}
                                        value={getFieldProps('currency').value}
                                        onChange={(value: any) => {
                                            setFieldValue("currency",value);
                                        }}
                                        items={currencies || []}
                                        className={"flex-grow-1 "+ (errors.currency && touched.currency ? 'form-control is-invalid' : '')}
                                    />

                                </div>
                            </Row>
                                    <Row>
                                        <div className={ "mb-3"+ (touched.store ? " was-validated" : '')}>
                                            <Label className="form-label" htmlFor={"choices-store-input"}>{t('store:store')}</Label>
                                            <Field
                                                id="choices-store-input"
                                                name={"store"}
                                                placeholder={t('store:chooseStore')}
                                                component={InputSelect}
                                                value={getFieldProps('store').value}
                                                onChange={(value: any) => {
                                                    setFieldValue("store",value);
                                                }}
                                                isClearable={true}
                                                items={stores || []}
                                                className={"flex-grow-1 "+ (errors.store && touched.store ? 'form-control is-invalid' : '')}
                                            />

                                        </div>
                                    </Row>

                                    {channelIntegration?.channel?.connector?.handler?.code === connectorHandlerCode.CONNECTOR_HANDLER_VK &&
                                        <>
                                            {isLoadingProductIntegrationParams ?
                                                <Loader/>
                                                :
                                                <Row>
                                                    <div className={ "mb-3"+ (touched['productIntegrationParam'] ? " was-validated" : '')}>
                                                        <Label className="form-label" htmlFor={"productIntegrationParam"}>{t('category:category')}</Label>
                                                        <Field
                                                            id={"productIntegrationParam"}
                                                            name={"productIntegrationParam"}
                                                            placeholder={t('category:chooseCategory')}
                                                            component={InputSelect}
                                                            value={getFieldProps('productIntegrationParam').value}
                                                            onChange={(value: any) => {
                                                                setFieldValue("productIntegrationParam",value);
                                                            }}
                                                            items={saleProductIntegrationParamsVk || []}
                                                            className={"flex-grow-1 "+ (errors['productIntegrationParam'] && touched['productIntegrationParam'] ? 'form-control is-invalid' : '')}
                                                        />
                                                    </div>
                                                </Row>

                                            }
                                        </>
                                    }


                                    <Row>
                                        <div className={ "mb-0"}>
                                            <Label className="form-label" htmlFor="form-title-input">{t('common:additionalSettings')} <a href="#" onClick={toggleShowAdditionalSettings}>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip>
                                                            {t('ai:moreInfo')}
                                                        </Tooltip>
                                                    }
                                                ><i className="ps-1 text-muted align-bottom ri-information-line"/></OverlayTrigger>
                                            </a></Label>
                                        </div>
                                    </Row>
                                    {isShowAdditionalSettings &&
                                    <Row>
                                            {channelIntegration?.meta && Object.keys(channelIntegration.meta).map((metaKey:string, key: number)=>(
                                                Object.keys(MetaAllowEditFields).includes(metaKey) &&
                                                <div className={ "mb-3"}>
                                                <div key={'metaProductIntegration_'+key}>
                                                    <Label className="form-label" htmlFor={"form-"+metaKey+"-input"}>{t('common:'+metaKey)}</Label>
                                                    <Field
                                                        id={"form-"+metaKey+"-input"}
                                                        name={metaKey}
                                                        placeholder={metaKey}
                                                        component={InputText}
                                                        value = {getFieldProps('meta_'+metaKey).value}
                                                        onChange={(value:string) => {
                                                            setFieldValue('meta_'+metaKey, value);
                                                        }}
                                                    />
                                                </div>
                                                </div>
                                            ))
                                            }
                                    </Row>
                                    }
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModalProductIntegrationChannel;