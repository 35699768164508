import React, { useState, useEffect } from "react";
import classnames from "classnames";
import {
  Button,
  Offcanvas,
  OffcanvasBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";

// hooks
import { useRedux } from "../../../hooks";

// actions
import {
  toggleUserDetailsTab,
  // toggleFavouriteContact,
  getChatUserDetails,
  toggleArchiveContact,
} from "../../../redux/actions";

// components
//import AudioCallModal from "../../../components/AudioCallModal";
//import VideoCallModal from "../../../components/VideoCallModal";
//import AppSimpleBar from "../../../components/AppSimpleBar";
import AppSimpleBar from "../../../components/AppSimpleBar";
import Loader from "../../../components/Loader";
import ProfileUser from "./ProfileUser";
import Actions from "./Actions";
import BasicDetails from "./BasicDetails";
import Groups from "./Groups";
import Media from "../../../components/Media";
import AttachedFiles from "../../../components/AttachedFiles";
import Status from "./Status";
import Members from "./Members";

interface IndexProps {
  isChannel: boolean;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}
const Index = ({ isChannel, onSelectChat }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    chatUserDetails,
    getUserDetailsLoading,
    isOpenUserDetails,
    isFavouriteContactToggled,
  } = useAppSelector(state => ({
    chatUserDetails: state.MessengerChat.chatUserDetails,
    getUserDetailsLoading: state.MessengerChat.getUserDetailsLoading,
    isOpenUserDetails: state.MessengerChat.isOpenUserDetails,
    isFavouriteContactToggled: state.MessengerChat.isFavouriteContactToggled,
  }));

  useEffect(() => {
    if (isFavouriteContactToggled) {
      dispatch(getChatUserDetails(chatUserDetails.uuid));
    }
  }, [dispatch, isFavouriteContactToggled, chatUserDetails.uuid]);

  /*
  close tab
  */
  const onCloseUserDetails = () => {
    dispatch(toggleUserDetailsTab(false));
  };




  /*
    video call modal
    */
  // const [isOpenVideoModal, setIsOpenVideoModal] = useState<boolean>(false);
  // const onOpenVideo = () => {
  //   setIsOpenVideoModal(true);
  // };
  // const onCloseVideo = () => {
  //   setIsOpenVideoModal(false);
  // };

  /*
        audio call modal
    */
  // const [isOpenAudioModal, setIsOpenAudioModal] = useState<boolean>(false);
  // const onOpenAudio = () => {
  //   setIsOpenAudioModal(true);
  // };
  // const onCloseAudio = () => {
  //   setIsOpenAudioModal(false);
  // };

  /*
  favourite
  */
  // const onToggleFavourite = () => {
  //   dispatch(toggleFavouriteContact(chatUserDetails.id));
  // };

  /*
  archive
  */
  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.uuid));
  };

  return (
    <>
         <Offcanvas
               isOpen={isOpenUserDetails}
               direction="end"
               className="offcanvas-end border-0"
               toggle={onCloseUserDetails}
             >
               <OffcanvasBody className="offcanvas-body profile-offcanvas p-0">
                 {getUserDetailsLoading && <Loader />}

                 <ProfileUser
                     onCloseUserDetails={onCloseUserDetails}
                     chatUserDetails={chatUserDetails}
                     // onOpenVideo={onOpenVideo}
                     // onOpenAudio={onOpenAudio}
                     onToggleArchive = {onToggleArchive}
                 />

                   {!isChannel ? (
                       <>
                         <div className="border-top border-top-dashed p-3">
                         <BasicDetails chatUserDetails={chatUserDetails} />
                         </div>
                         <div className="border-top border-top-dashed p-3">
                         <Groups chatUserDetails={chatUserDetails} onSelectChat={onSelectChat}/>
                         </div>
                       </>
                   ) : (
                       <>
                         <div className="border-top border-top-dashed p-3">
                         <Members chatUserDetails={chatUserDetails} onSelectChat={onSelectChat} />
                         </div>
                       </>
                   )}

                 { chatUserDetails?.images &&  chatUserDetails?.images.items.length > 0 &&
                     <Media media={chatUserDetails.images} limit={3} />
                 }
                 { chatUserDetails?.images &&  chatUserDetails?.images.items.length > 0 &&
                     <AttachedFiles attachedFiles={chatUserDetails.images} limit={3} />
                 }

               </OffcanvasBody>
         </Offcanvas>
    </>
  );
};

export default Index;
