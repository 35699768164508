import React from 'react';
import * as Yup from 'yup';

export const PlanOrderFormValidatorSchema = Yup.object().shape({
    customerName: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('invalidName'),
    customerEmail: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('invalidName'),
    customerCompany: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('invalidName'),
});