import React, {useEffect, useState} from "react";
import {useParams} from 'react-router';
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {useRedux} from "../../../hooks";
import {
    cancelPlanOrderModalHide,
    cancelPlanOrderModalShow,
    getPlanOrderDetail,
    onCancelPlanOrder
} from "../../../redux/Sale/PlanOrder/actions";
import {PlanOrderItemType, PlanOrderType} from "../../../redux/Sale/PlanOrder/types";

import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader";
import {LocaleCurrencySymbol} from "../../../redux/Sale/Currency/types";
import {useProfile} from "../../../hooks/UserHooks";
import PlanOrderItem from "../../../components/Sale/PlanOrderItem";
import {LicenseKeyStatus, LicenseKeyType, SaleLicenseKeyActivatePayload} from "../../../redux/Sale/License/types";
import CancelPlanModal from "./CancelOrderModal";
import {LordIcon} from "../../../components/LordIcon";
import {onActivateLicenseKey} from "../../../redux/Sale/License/actions";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {PaymentType} from "../../../redux/Sale/Payment/types";
import PaymentItem from "./PaymentItem";
import {DiscountType, DiscountTypeType} from "../../../redux/Sale/Discount/types";
import {convertToMomentLocale} from "../../../helpers/functions";


const PlanOrderDetailIndex = () => {

    const { t, i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));
    const navigate = useNavigate();

    const { userProfile } = useProfile();
    const [userAvatar, setUserAvatar] = useState(userProfile.avatar);

    const { uuid } = useParams();

    const { dispatch, useAppSelector } = useRedux();

    const {
        isFetched,
        planOrder,
        mainCabinet
    } = useAppSelector(state => ({
        isFetched: state.PlanOrder.isFetched,
        planOrder: state.PlanOrder,
        mainCabinet: state.MainCabinet
    }));

    const [order, setOrder] = useState<PlanOrderType>(null);

    useEffect(() => {
        dispatch(getPlanOrderDetail({uuid: uuid}));
    }, [dispatch, uuid]);

    useEffect(() => {
        if ((planOrder.current !== undefined) && (planOrder.current !== null) && (planOrder.current.uuid !== undefined)){
            setOrder({...planOrder.current});
        } else {
            setOrder(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planOrder?.current])

    const handleActivateLicenseKey = (licenseKey: LicenseKeyType) => {

        const licenseKeyActivatePayload:SaleLicenseKeyActivatePayload={
            licenseKey: licenseKey,
            cabinet: mainCabinet.currentCabinet
        };

        dispatch(onActivateLicenseKey(licenseKeyActivatePayload));

        setTimeout(() => {
            dispatch(getPlanOrderDetail({uuid: uuid}));
        }, 1000);

    }

    const isLicenseCreated =
        order !== undefined &&
        order !== null &&
        order.orderItems !== undefined &&
        order.orderItems.length>0 &&
        order.orderItems[0].licenseKey !== undefined &&
        order.orderItems[0].licenseKey.length > 0 &&
        order.orderItems[0].licenseKey[0].uuid !== undefined &&
        order.orderItems[0].licenseKey[0].createdAt !== undefined;

    const isLicenseActivated =
        order !== undefined &&
        order !== null &&
        order.orderItems !== undefined &&
        order.orderItems.length>0 &&
        order.orderItems[0].licenseKey !== undefined &&
        order.orderItems[0].licenseKey.length > 0 &&
        order.orderItems[0].licenseKey[0].uuid !== undefined &&
        order.orderItems[0].licenseKey[0].activeFrom !== undefined &&
        order.orderItems[0].licenseKey[0].activeFrom !== null
    ;

    const onCancelOrder = (event:PlanOrderType, dispatch:any) => {
        if ((event !== undefined) && (event?.uuid?.length>0)){
            dispatch(onCancelPlanOrder(event));

            setTimeout(() => {
                dispatch(getPlanOrderDetail(event));
            }, 1000);
        }
    }

    const handleCancelOrder = () => {
        dispatch(cancelPlanOrderModalShow());
    };

    return (
        <>
            {isFetched ?
                <>
                {order !== null ?
                    <>
                        <BreadCrumb title={t('sale:order') + ' #' + order.code} pageTitle={t('menu:main')}/>
                        <Row>
                            <Col xl={9}>
                                <Card>
                                    <CardHeader>
                                        <div className="d-flex align-items-center">
                                            <h5 className="card-title flex-grow-0 mb-0">{t('sale:order')} #{order.code}</h5>


                                            <div className="card-title d-flex flex-grow-1 mb-0 ms-2">
                                                {order.isPaid === true ?
                                                    <span
                                                        className="badge text-uppercase badge-soft-success fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPaid')}</span>
                                                    :
                                                    <span
                                                        className="badge text-uppercase badge-soft-danger fs-10 md-2 d-inline-flex">{t('sale:paymentStatusPending')}</span>
                                                }
                                            </div>

                                            {order.paymentUrl &&
                                                <div className="flex-shrink-0">
                                                    <a
                                                        href={order.paymentUrl}
                                                        target="_blank"
                                                        className="btn btn-success btn-sm"
                                                        rel="noreferrer"
                                                    >
                                                        <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                                                        {t('sale:invoiceLink')}
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="table-responsive table-card">
                                            <table className="table table-nowrap align-middle table-borderless mb-0">
                                                <thead className="table-light text-muted">
                                                <tr>
                                                    <th scope="col">{t('sale:productDetails')}</th>
                                                    <th scope="col" className="text-end">{t('sale:itemPrice')}</th>
                                                    <th scope="col" className="text-end">{t('sale:quantity')}</th>
                                                    {/*<th scope="col">Rating</th>*/}
                                                    <th scope="col" className="text-end">
                                                        {t('sale:totalAmount')}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {order.orderItems.map((item: PlanOrderItemType, key) => (
                                                    <PlanOrderItem item={item} key={'orderItem_' + key}/>
                                                ))}
                                                <tr className="border-top border-top-dashed">
                                                    <td colSpan={3}></td>
                                                    <td colSpan={2} className="fw-medium p-0">
                                                        <table className="table table-borderless mb-0">
                                                            <tbody>
                                                            <tr>
                                                                <td>{t('sale:subTotal')} :</td>
                                                                <td className="text-end">
                                                                    {order?.discount !== undefined && order?.discount !== null && order.discount>0 ?
                                                                        <>
                                                                        {LocaleCurrencySymbol[order.currency.code]}{order.sum+order.discount}
                                                                        </>
                                                                        :
                                                                        <>
                                                                        {LocaleCurrencySymbol[order.currency.code]}{order.sum}
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            {(order.discounts || []).length>0 &&
                                                                (order.discounts || []).map((discount:DiscountType)=>{
                                                                    return (
                                                                        <tr key={"discountItem_"+discount.uuid}>
                                                                            <td>
                                                                                {t('sale:discount')}{" "}
                                                                                <span className="text-muted">({discount.name})</span>{" "}
                                                                                :
                                                                            </td>
                                                                            <td className="text-end">
                                                                                {discount.type === DiscountTypeType.TYPE_PERCENT  &&
                                                                                    <>
                                                                                        - {discount.value}%
                                                                                    </>
                                                                                }
                                                                                {discount.type === DiscountTypeType.TYPE_FIXED && discount?.currency?.code !== undefined &&
                                                                                    <>
                                                                                        - {LocaleCurrencySymbol[discount.currency.code]}{discount.value}
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            {/*<tr>*/}
                                                            {/*    <td>Shipping Charge :</td>*/}
                                                            {/*    <td className="text-end">$65.00</td>*/}
                                                            {/*</tr>*/}
                                                            {/*<tr>*/}
                                                            {/*    <td>Estimated Tax :</td>*/}
                                                            {/*    <td className="text-end">$44.99</td>*/}
                                                            {/*</tr>*/}
                                                            <tr className="border-top border-top-dashed">
                                                                <th scope="row">{t('sale:total')} ({order.currency.code})
                                                                    :
                                                                </th>
                                                                <th className="text-end">{LocaleCurrencySymbol[order.currency.code]}{order.sum}</th>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardHeader>
                                        <div className="d-sm-flex align-items-center">
                                            <h5 className="card-title flex-grow-1 mb-0">{t('sale:orderHistory')}</h5>
                                            <div className="flex-shrink-0 mt-2 mt-sm-0">
                                                {/*<Link*/}
                                                {/*    to="#"*/}
                                                {/*    className="btn btn-soft-info btn-sm mt-2 mt-sm-0"*/}
                                                {/*>*/}
                                                {/*    <i className="ri-map-pin-line align-middle me-1"></i>{" "}*/}
                                                {/*    Change Address*/}
                                                {/*</Link>{" "}*/}
                                                {order.isPaid === false && !order.status.isFailure &&
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            handleCancelOrder()
                                                        }}
                                                        className="btn btn-soft-danger btn-sm mt-2 mt-sm-0"
                                                    >
                                                        <i className="mdi mdi-archive-remove-outline align-middle me-1"></i>{" "}
                                                        {t('sale:cancelOrder')}
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="profile-timeline">
                                            <div
                                                className="accordion accordion-flush">


                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="heading1">
                                                        <div className=" accordion-button p-2 shadow-none">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div
                                                                        className="avatar-title bg-success rounded-circle">
                                                                        <i className="ri-shopping-bag-line fs-20"/>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-15 mb-0 fw-semibold">
                                                                        {t('sale:orderPlaced')}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-body ms-2 ps-5 pt-0" id="heading1">
                                                        <p className="text-muted mb-0">
                                                            {moment(order.createdAt).format('llll')}
                                                        </p>
                                                    </div>
                                                </div>

                                                {order.status.isFailure ?

                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="heading2">
                                                            <div className=" accordion-button p-2 shadow-none">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">

                                                                        <div
                                                                            className="avatar-title bg-danger rounded-circle">
                                                                            <i className="ri-close-circle-line fs-20"/>
                                                                        </div>

                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-15 mb-0 fw-semibold">
                                                                            {t('sale:orderCanceled')}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-body ms-2 ps-5 pt-0" id="heading1">
                                                            <p className="text-muted mb-0">
                                                                {
                                                                    moment(order.payments[0].updatedAt).format('llll')
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>

                                                    :
                                                    <>

                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="heading2">
                                                                <div className=" accordion-button p-2 shadow-none">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            {order.isPaid === true ?
                                                                                <div
                                                                                    className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-hand-heart-line fs-20"/>
                                                                                </div>
                                                                                :
                                                                                <div
                                                                                    className="avatar-title bg-light text-success rounded-circle">
                                                                                    <i className="ri-hand-heart-line fs-20"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-15 mb-0 fw-semibold">
                                                                                {t('sale:orderPaid')}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-body ms-2 ps-5 pt-0"
                                                                 id="heading1">
                                                                <p className="text-muted mb-0">
                                                                    {order.isPaid === true ?
                                                                        moment(order.payments[0].updatedAt).format('llll')
                                                                        :
                                                                        t('sale:orderNotPaidYet')
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>


                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="heading2">
                                                                <div className=" accordion-button p-2 shadow-none">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            {isLicenseCreated ?
                                                                                <div
                                                                                    className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-star-line fs-20"/>
                                                                                </div>
                                                                                :
                                                                                <div
                                                                                    className="avatar-title bg-light text-success rounded-circle">
                                                                                    <i className="ri-star-line fs-20"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-15 mb-0 fw-semibold">
                                                                                {t('sale:licenseKeyCreated')}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-body ms-2 ps-5 pt-0"
                                                                 id="heading1">
                                                                <p className="text-muted mb-0">
                                                                    {isLicenseCreated ?
                                                                        moment(order.orderItems[0].licenseKey[0].createdAt).format('llll')
                                                                        :
                                                                        t('sale:orderNotPaidYet')
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>


                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="heading2">
                                                                <div className=" accordion-button p-2 shadow-none">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            {isLicenseActivated
                                                                                ?
                                                                                <div
                                                                                    className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-star-smile-line fs-20"/>
                                                                                </div>
                                                                                :
                                                                                <div
                                                                                    className="avatar-title bg-light text-success rounded-circle">
                                                                                    <i className="ri-star-smile-line fs-20"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-15 mb-0 fw-semibold">
                                                                                {t('sale:licenseKeyActivated')}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-body ms-2 ps-5 pt-0"
                                                                 id="heading1">
                                                                <p className="text-muted mb-0">
                                                                    {isLicenseActivated ?
                                                                        moment(order.orderItems[0].licenseKey[0].activeFrom).format('llll')
                                                                        :
                                                                        t('sale:notActivated')
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                                }


                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl={3}>
                                {isLicenseCreated &&
                                    <Card>
                                        <CardHeader>
                                            <div className="d-flex">
                                                <h5 className="card-title flex-grow-1 mb-0">
                                                    <i className="ri-star-line align-middle me-2 text-muted"></i>
                                                    {t('sale:licenseKeysDetails')}
                                                </h5>
                                                <div className="flex-shrink-0">
                                                    {/*<Link to="#" className="badge badge-soft-primary fs-12">*/}
                                                    {/*    Track Order*/}
                                                    {/*</Link>*/}
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="text-center">

                                                <LordIcon
                                                    src="https://cdn.lordicon.com/lrelhbre.json"
                                                    trigger="loop"
                                                    colors={{primary: '#0ab39c', secondary: '#36c5f1'}}
                                                    size={70}
                                                />


                                                {/*<lord-icon*/}
                                                {/*    src="https://cdn.lordicon.com/uetqnvvg.json"*/}
                                                {/*    trigger="loop"*/}
                                                {/*    colors="primary:#405189,secondary:#0ab39c"*/}
                                                {/*    style={{width:"80px", height:"80px"}}*/}
                                                {/*></lord-icon>*/}
                                                {/*<h5 className="fs-16 mt-2">RQK Logistics</h5>*/}

                                                {
                                                    (order.orderItems || []).map((orderItem: PlanOrderItemType, orderItemKey: number) => (
                                                        (orderItem.licenseKey || []).map((licenseKeyItem: LicenseKeyType, licenceKeyKey: number) => (
                                                            <div key={'licenseKey_' + orderItemKey + '_' + licenceKeyKey}>
                                                            <div
                                                                className="d-flex align-items-center justify-content-center mb-2">
                                                                <span
                                                                    className="text-muted me-1">Key:</span>{licenseKeyItem.code}
                                                            </div>
                                                                {licenseKeyItem.activeFrom &&
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        {licenseKeyItem.status !== undefined &&
                                                                            <span
                                                                                className={"badge text-uppercase fs-10 me-2 d-inline-flex text-bg-" + LicenseKeyStatus[licenseKeyItem.status].class}>{t('sale:licenseKeyStatus' + LicenseKeyStatus[licenseKeyItem.status].label)}
                                                                            </span>
                                                                        }
                                                                        <span className="text-muted">
                                                                        <span>
                                                                            { moment(licenseKeyItem.activeFrom).format('L')}
                                                                        </span>
                                                                        <span className="me-1 ms-1">-</span>
                                                                        <span>
                                                                            {licenseKeyItem.activeTo ?
                                                                                moment(licenseKeyItem.activeTo).format('L')
                                                                                :
                                                                                <i className=" ri-battery-2-charge-line align-bottom fs-16"/>
                                                                            }
                                                                        </span>
                                                                        </span>
                                                                    </div>
                                                                }

                                                                {licenseKeyItem.status !== undefined && licenseKeyItem.status === 'N' &&
                                                                    <div className="m-3">
                                                                    <button type="button"
                                                                            className="btn btn-sm btn-outline-success waves-effect waves-light"
                                                                            onClick={() => {
                                                                                handleActivateLicenseKey(licenseKeyItem)
                                                                            }}>{t('sale:activate')}
                                                                    </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        ))
                                                    ))
                                                }

                                                {/*<p className="text-muted mb-0">ID: MFDS1400457854</p>*/}
                                                {/*<p className="text-muted mb-0">Payment Mode : Debit Card</p>*/}
                                            </div>
                                        </CardBody>
                                    </Card>
                                }

                                <Card>
                                    <CardHeader>
                                        <div className="d-flex">
                                            <h5 className="card-title flex-grow-1 mb-0">
                                                {t('sale:customerDetails')}
                                            </h5>
                                            {/*<div className="flex-shrink-0">*/}
                                            {/*    <Link to="#" className="link-secondary">*/}
                                            {/*        View Profile*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <ul className="list-unstyled mb-0 vstack gap-3">
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        {userAvatar &&
                                                            <img className="avatar-sm rounded" src={userAvatar}
                                                                 alt={order.customerName}/>
                                                        }
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6 className="fs-15 mb-1">{order.customerName}</h6>
                                                        <p className="text-muted mb-0">{t('sale:customer')}</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                                                {order.customerEmail}
                                            </li>
                                            {order.customerPhone && order.customerPhone.length > 0 &&
                                                <li>
                                                    <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                                                    {order.customerPhone}
                                                </li>
                                            }
                                        </ul>
                                    </CardBody>
                                </Card>

                                {/*<Card>*/}
                                {/*    <CardHeader>*/}
                                {/*        <h5 className="card-title mb-0">*/}
                                {/*            <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}*/}
                                {/*            Billing Address*/}
                                {/*        </h5>*/}
                                {/*    </CardHeader>*/}
                                {/*    <CardBody>*/}
                                {/*        <ul className="list-unstyled vstack gap-2 fs-14 mb-0">*/}
                                {/*            <li className="fw-semibold fs-15">Joseph Parker</li>*/}
                                {/*            <li>+(256) 245451 451</li>*/}
                                {/*            <li>2186 Joyce Street Rocky Mount</li>*/}
                                {/*            <li>New York - 25645</li>*/}
                                {/*            <li>United States</li>*/}
                                {/*        </ul>*/}
                                {/*    </CardBody>*/}
                                {/*</Card>*/}

                                {/*<Card>*/}
                                {/*    <CardHeader>*/}
                                {/*        <h5 className="card-title mb-0">*/}
                                {/*            <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}*/}
                                {/*            Shipping Address*/}
                                {/*        </h5>*/}
                                {/*    </CardHeader>*/}
                                {/*    <CardBody>*/}
                                {/*        <ul className="list-unstyled vstack gap-2 fs-13 mb-0">*/}
                                {/*            <li className="fw-semibold fs-15">Joseph Parker</li>*/}
                                {/*            <li>+(256) 245451 451</li>*/}
                                {/*            <li>2186 Joyce Street Rocky Mount</li>*/}
                                {/*            <li>California - 24567</li>*/}
                                {/*            <li>United States</li>*/}
                                {/*        </ul>*/}
                                {/*    </CardBody>*/}
                                {/*</Card>*/}

                                <Card>
                                    <CardHeader>
                                        <h5 className="card-title mb-0">
                                            <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
                                            {t('sale:paymentDetails')}
                                        </h5>
                                    </CardHeader>
                                    <CardBody>
                                        {(order.payments || []).map((paymentItem: PaymentType, key: number) => (
                                            <PaymentItem
                                                item={paymentItem}
                                                key={'paymentItem_' + key}
                                                paymentUrl={order.paymentUrl && order.paymentUrl}
                                            />
                                        ))}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <CancelPlanModal
                            onCancel={(event) => onCancelOrder(event, dispatch)}
                            handleClose={() => dispatch(cancelPlanOrderModalHide())}
                            cancelItem={order}
                            isOpen={planOrder.cancelModalShow}
                        />
                    </>
                    :
                    <>
                        {t('sale:orderNotFound')}
                    </>
                }
                    <Row>
                        <Col xxl={9}>
                            <div className="pb-4">
                                <Link
                                    to="#"
                                    onClick={()=>navigate(-1)}
                                    className="btn btn-primary btn-label waves-effect waves-light"><i
                                    className="ri-arrow-left-line label-icon align-middle fs-16 me-2"/>{t('sale:allOrders')}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <>
                    <BreadCrumb title={t('sale:order')} pageTitle={t('menu:main')} />
                    <Loader/>
                </>
            }
        </>
    );
};

export default PlanOrderDetailIndex;


