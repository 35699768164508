// types
import {ContentPeerChannelActionTypes, ContentPeerChannelState, ContentPeerChannelType} from "./types";
import {NewEmptyActionsItem} from "../../types";
import {ContentProjectType} from "../Project/types";
import {ContentConnectorType} from "../Connector/types";

export const NewContentPeerChannelItem:ContentPeerChannelType = {
  enabled: true,
  name: '',
  hashtag:'',
  isManaged: false,
  order:500,
}

export const INIT_STATE_CONTENT_PEER_CHANNEL: ContentPeerChannelState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewContentPeerChannelItem,
  modalEditTitle: '',
  connectModalShow: false,
  isNeedReload: false,
  isSending: false,
  isFetched: false,
  getContentPeerChannelLoading: false,
};

const ContentPeerChannel = (state = INIT_STATE_CONTENT_PEER_CHANNEL, action: any) => {
  switch (action.type) {
    case ContentPeerChannelActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ContentPeerChannelActionTypes.GET_CONTENT_PEER_CHANNELS:
          return {
            ...state,
            items: action.payload.data.items,
            isFetched: true,
            getContentPeerChannelLoading: false,
            connectModalShow: false,
            isNeedReload: false
          };
        case ContentPeerChannelActionTypes.UPDATE_CONTENT_PEER_CHANNEL:

          // if (action.payload.data.response && action.payload.data.response === 'wsock'){
          //     state.requestId = action.payload.data.requestId;
          // }
          //
          // if (action?.payload?.data?.requestMetadata?.uuid === state.requestId){
          //     state.rightModalShow = false;
          //     state.isNeedReload = true;
          //     state.isSending=false
          // }

          return {
            ...state,
            rightModalShow: false,
            isNeedReload: true,
            isSending: false
          };
        case ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL:
          return {
            ...state,
            selectedItem: NewContentPeerChannelItem,
            removeModalShow: false,
          };
        case ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL:

          if (action.payload.data.response && action.payload.data.response === 'wsock'){
            state.requestId = action.payload.data.requestId;
          }

          if (action?.payload?.data?.peerChannel?.uuid === state.requestId){

            (state.items || []).map((item:ContentPeerChannelType)=>{
              if (item.uuid === state.requestId){
                item.accessTokenOAuthUrl = action.payload.data.peerChannel.accessTokenOAuthURL;
              }
              return item;
            });

            state.connectModalShow = true;
          }

          return {
            ...state,
          };
        default:
          return { ...state };
      }

    case ContentPeerChannelActionTypes.API_RESPONSE_ERROR:

      switch (action.payload.actionType) {
        case ContentPeerChannelActionTypes.GET_CONTENT_PEER_CHANNELS:
          return {
            ...state,
            isFetched: false,
            getContentPeerChannelLoading: false,
            isNeedReload: false
          };
        case ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL:
          return {
            ...state,
            removeModalShow: false,
          };
        case ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL:
            (state.items || []).map((item:ContentPeerChannelType)=>{
              item.isFetchAccessTokenOAuthURL = false
              return item;
            });
          return {
            ...state,
            connectModalShow: false
          };
        default:
          return { ...state };
      }
    case ContentPeerChannelActionTypes.EDIT_CONTENT_PEER_CHANNEL:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentPeerChannelActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewContentPeerChannelItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewContentPeerChannelItem,
        removeModalShow: false
      }
    case ContentPeerChannelActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL:
      (state.items || []).map((item:ContentPeerChannelType)=>{
          if (item.uuid === action.payload.data.uuid){
            item.isFetchAccessTokenOAuthURL = true;
          }
          return item;
      });
      return {
        ...state
      }
    case ContentPeerChannelActionTypes.CONTENT_PEER_CHANNEL_NOT_FOUND:
      return {
        ...state,
        isNeedReload: true,
      }
    case ContentPeerChannelActionTypes.UPDATE_CONTENT_PEER_CHANNEL:
      return {
        ...state,
        isSending: true
      }
    case ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL_CANCEL:

      (state.items || []).map((item:ContentPeerChannelType)=>{
        if (item.isFetchAccessTokenOAuthURL){
          item.isFetchAccessTokenOAuthURL = false;
        }
        return item;
      });

      return {
        ...state,
        connectModalShow: false
      }

    default:
      return { ...state };
  }
};

export default ContentPeerChannel;
