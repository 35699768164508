import React from 'react';
import LangSwitch from "./LangSwitch";

const CoverLangSwitch = () => {
    return (
        <>
            <div className="float-end me-2">
                <LangSwitch/>
            </div>
        </>
    )
}

export default CoverLangSwitch