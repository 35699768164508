import React, {useEffect, useState} from "react";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import * as route from "../../../api/routes";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import PeerActivityLine from "../PeerActivity/PeerActivityLine";
import SimpleBar from 'simplebar-react';
import {Card, Input, Row} from "reactstrap";

interface ItemProps {
    items: PeerActivityType[];
}

const ExternalViewItems = ({ items}: ItemProps) => {

    const [ peerActivities, setPeerActivities] = useState<PeerActivityType[]>([]);
    const [ searchValue, setSearchValue] = useState<string>('');
    const { t } = useTranslation(['common']);

    const onDrag = (event) => {
        event.preventDefault();
    };

    useEffect(()=>{

        if ( items &&  items.length>0){
            const regEx = new RegExp( searchValue, 'g' );

            // eslint-disable-next-line
            setPeerActivities([...items.filter((item:PeerActivityType)=>{
                if (searchValue && searchValue.length>0){
                    if (item.text && item.text.length>0) {
                        return item.text.match(regEx);
                    }
                } else {
                    return item;
                }
            })]);

        } else {
            setPeerActivities([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        items,
        searchValue
    ])

    const handleSearch = (value:any) => {
        setSearchValue(value);
    }

    return (
        <div id="external-events">
            {(items || []).length>0 ?
                <>
                <div className="search-box mb-1">
                    <Input type="text" className="form-control" placeholder={t('search:searchYourIdea')}
                           id="search-options"
                           name="ideasSearch"
                           value={searchValue}
                           onChange={e => {
                               handleSearch(e.target.value);
                           }} />
                    <i className="ri-search-line search-icon"></i>
                </div>
            <Row>
                <div className="mt-1 mb-2 d-flex flex-row">
                    <span className="badge badge-soft-success text-wrap justify-content-start d-flex align-items-center text-start pe-3">
                                <i className="mdi mdi-lightbulb-on-outline fs-16 align-middle me-2 ms-1"/>
                                {t('peerActivity:dragDropIdeasCalendar')}
                    </span>
                </div>
            </Row>
        <div>
            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
            {(peerActivities || []).map((idea:PeerActivityType, key:number)=>{
                return(
                    <Card
                        className="external-event mb-1 mt-0"
                        key={"cat-" + key}
                        draggable
                        onDrag={(event) => {
                            onDrag(event);
                        }}
                        data-uuid={idea.uuid}
                    >
                        <PeerActivityLine item={idea}/>
                    </Card>
                )
            })
            }
            </SimpleBar>
        </div>
        </>
            :
                <div className="alert alert-success alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1 pe-3" role="alert">
                    <i className="mdi mdi-lightbulb-on-outline fs-16 align-middle me-2"/>
                    <span dangerouslySetInnerHTML={{__html: t('peerActivity:emptyActivityIdeasListNotice').replace(/%link%/,route.CONTENT_ACTIVITY_IDEAS)}}/>
                </div>
            }
        </div>
    );
};

export default ExternalViewItems;
