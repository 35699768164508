import React, {useEffect, useRef, useState} from "react";
import Flatpickr from "react-flatpickr";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import {useRedux} from "../hooks";
import {PeriodType} from "../redux/Stat/DashboardContent/types";
import '../i18/config';
import {useTranslation} from "react-i18next";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {convertToMomentLocale} from "../helpers/functions";

interface PeriodSelectProps {
    periodFrom?:number,
    periodTo?:number,
    onDispatch: (data:any) => void
}

const DatePeriodSelect = ({periodFrom, periodTo, onDispatch}:PeriodSelectProps) => {

    const [fromDate, setFromDate] = useState(periodFrom || moment().add(-30, 'days').unix());
    const [toDate, setToDate] = useState(periodTo || moment().unix());

    const { i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));

    const dateSelectRef = useRef(null);
    const { dispatch } = useRedux();

    useEffect(() => {
        if (periodFrom !== undefined && periodFrom>0){
            setFromDate(periodFrom);
        }
        if (periodTo !== undefined && periodTo>0){
            setToDate(periodTo);
        }

    }, [periodFrom, periodTo]);

    const handlePeriodChange = (selectedDates:any, instance : any) => {

        const dateArr = selectedDates.map(date => instance.formatDate(date, "U"));

        if (dateArr.length>1){
            const periodChange:PeriodType = {
                periodFrom : dateArr[0],
                periodTo: dateArr[1],
            }

            dispatch(onDispatch(periodChange));
        }

    }

    const handleDateSelectButton = () => {
        dateSelectRef.current.flatpickr.open();
    }
return(
    <div className="input-group">
        <Flatpickr
            className="form-control border-0 dash-filter-picker shadow rangePickerInput"
            ref={dateSelectRef}
            options={{
                altFormat: "F j, Y",
                dateFormat: "U",
                inline: false,
                enableTime: false,
                altInput: true,
                allowInput: true,
                mode: 'range',
                defaultDate: [moment(fromDate,'X').toDate(), moment(toDate,'X').toDate()],
                locale : {...FlatpickrLanguages[i18n.language]}
            }}
            onChange={(selectedDates, dateStr, instance) => {
                handlePeriodChange(selectedDates,instance);
            }}
        />
        <a className="input-group-text bg-primary border-primary text-white" onClick={handleDateSelectButton}><i className="ri-calendar-2-line"/></a>
    </div>
);


}

export default DatePeriodSelect;