import React from "react";
import { Spinner } from "reactstrap";

const LoaderImg = require('../resources/images/loading.svg');

interface ItemProps {
    addClassNames? : string | '';
}

const Loader = ({ addClassNames }: ItemProps) => {
  return (
    <div className={"loader-container mcLoader "+addClassNames}>
      <div className="status">
          <img src={LoaderImg.default} alt="Loading"></img>
        {/*<Spinner color="primary" />*/}
      </div>
    </div>
  );
};

export default Loader;
