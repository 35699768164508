import {
  ContentConnectorActionTypes,
  ContentConnectorPayload,
  ContentConnectorSearchChannelPayload,
  ContentConnectorType
} from "./types";

export const contentConnectorApiResponseSuccess = (actionType: string, data: any) => ({
  type: ContentConnectorActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const contentConnectorApiResponseError = (actionType: string, error: string) => ({
  type: ContentConnectorActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getContentConnectors = () => ({
  type: ContentConnectorActionTypes.GET_CONTENT_CONNECTORS,
});

export const showRightModalContentConnector= (data: ContentConnectorPayload) => ({
  type: ContentConnectorActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalContentConnector= () => ({
  type: ContentConnectorActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataContentConnector = (data: any) => ({
  type: ContentConnectorActionTypes.UPDATE_CONTENT_CONNECTOR,
  payload: data,
});

export const editDataContentConnector = (data: ContentConnectorPayload) => ({
  type: ContentConnectorActionTypes.EDIT_CONTENT_CONNECTOR,
  payload: data,
});

export const onConnectContentConnector = (data: any) => ({
  type: ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR,
  payload: data,
});

export const onIsConnectedContentConnector = (data: any) => ({
  type: ContentConnectorActionTypes.IS_CONNECTED_CONTENT_CONNECTOR,
  payload: data,
});
export const onIsConnectedErrorContentConnector = () => ({
  type: ContentConnectorActionTypes.IS_CONNECTED_ERROR_CONTENT_CONNECTOR,
});

export const removeContentConnectorModalShow = (data: ContentConnectorPayload) => ({
  type: ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR_MODAL_SHOW,
  payload: data,
});

export const removeContentConnectorModalHide = () => ({
  type: ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR_MODAL_HIDE,
});

export const onRemoveContentConnector = (data: ContentConnectorPayload) => ({
  type: ContentConnectorActionTypes.REMOVE_CONTENT_CONNECTOR,
  payload: data,
});

export const selectContentConnector = (data: ContentConnectorPayload) => ({
  type: ContentConnectorActionTypes.SELECT_CONTENT_CONNECTOR,
  payload:data ,
});

export const setContentConnectorModalAddCodeShow = () => ({
  type: ContentConnectorActionTypes.SET_NEED_ADD_CODE_CONTENT_CONNECTOR,
});


export const onGetContentConnectorSearchChannel = (data: ContentConnectorSearchChannelPayload) => ({
  type: ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_REMOTE_CHANNELS,
  payload: data,
});

export const removeOAuthLink = () => ({
  type: ContentConnectorActionTypes.REMOVE_OAUTH_LINK,
});

export const onGetContentConnectorManagedChannels = (data: ContentConnectorType) => ({
  type: ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_CHANNELS,
  payload: data,
});

export const onConnectContentConnectorManagedChannel = (data: any) => ({
  type: ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_CHANNEL,
  payload: data,
});

export const onGetContentConnectorManagedCounters = (data: ContentConnectorType) => ({
  type: ContentConnectorActionTypes.GET_CONTENT_CONNECTOR_MANAGED_COUNTERS,
  payload: data,
});

export const onConnectContentConnectorManagedCounter = (data: any) => ({
  type: ContentConnectorActionTypes.CONNECT_CONTENT_CONNECTOR_MANAGED_COUNTER,
  payload: data,
});
