import {AITextGeneratePayload, AITextGenerateType} from "../../../redux/AI/TextGenerate/types";
import {
    aiTextGenerateCreateCommon,
    aiTextGeneratePredictTagCommon, aiTextGenerateTitleCommon,
    aiTextGenerateTranslateCommon
} from "../../../redux/AI/TextGenerate/reducer";
import {showRightModalAITextGenerate} from "../../../redux/AI/TextGenerate/actions";
import { v4 as uuidv4 } from 'uuid';

const aiTextGenerateOpenModal = (event:any, dispatch:any) => {

    let aiTextGenerateTranslateItem : AITextGenerateType = aiTextGenerateCreateCommon;

    aiTextGenerateTranslateItem.uuid = uuidv4();

    aiTextGenerateTranslateItem.generateParams[0].generateCount=1;

    if ((event && event !== null && event.text && event.text !==null && event.text.length>0)){
        aiTextGenerateTranslateItem.text = event.text;
        aiTextGenerateTranslateItem.prompt = event.text;
    }

    const aiTextGeneratePayload : AITextGeneratePayload = {
        data: aiTextGenerateTranslateItem,
        modalEditTitle : 'contentGenerationTitle'
    }

    dispatch(showRightModalAITextGenerate(aiTextGeneratePayload));
}

const aiTextGeneratePredictTagOpenModal = (event:any, dispatch:any) => {

    let aiTextGenerateTranslateItem : AITextGenerateType = aiTextGeneratePredictTagCommon;

    aiTextGenerateTranslateItem.uuid = uuidv4();

    aiTextGenerateTranslateItem.generateParams[0].generateCount=1;

    if ((event && event !== null && event.text && event.text !==null && event.text.length>0)){
        aiTextGenerateTranslateItem.text = event.text;
        aiTextGenerateTranslateItem.prompt = event.text;
    }

    const aiTextGeneratePayload : AITextGeneratePayload = {
        data: aiTextGenerateTranslateItem,
        modalEditTitle : 'contentTagGeneration'
    }

    dispatch(showRightModalAITextGenerate(aiTextGeneratePayload));
}

const aiTextGenerateTitleOpenModal = (event:any, dispatch:any) => {

    let aiTextGenerateTranslateItem : AITextGenerateType = aiTextGenerateTitleCommon;

    aiTextGenerateTranslateItem.uuid = uuidv4();

    aiTextGenerateTranslateItem.generateParams[0].generateCount=1;

    if ((event && event !== null && event.text && event.text !==null && event.text.length>0)){
        aiTextGenerateTranslateItem.text = event.text;
        aiTextGenerateTranslateItem.prompt = event.text;
    }

    const aiTextGeneratePayload : AITextGeneratePayload = {
        data: aiTextGenerateTranslateItem,
        modalEditTitle : 'contentPlanGenerationTitle'
    }

    dispatch(showRightModalAITextGenerate(aiTextGeneratePayload));
}

export {
    aiTextGenerateOpenModal,
    aiTextGeneratePredictTagOpenModal,
    aiTextGenerateTitleOpenModal
}