import {ContentPeerChannelType} from "../../Content/PeerChannel/types";
import {PeerActivityType} from "../../Content/PeerActivity/types";
import {ContentPeerUserType} from "../../Content/PeerUser/types";

export enum MainSearchActionTypes {
  API_RESPONSE_SUCCESS = "@@mainUser/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainUser/API_RESPONSE_ERROR",
  GET_SEARCH_LIST = "@@mainUser/GET_SEARCH_LIST",
}

export interface MainSearchState {
  channels?: ChannelSearchType;
  activities?: ActivitySearchType;
  peerUsers?: PeerUserSearchType;
  comments?: CommentSearchType
  eTag: string;
  query: string;
  maxResults: number;
  isMainSearchFetched: boolean;
  getMainSearchLoading: boolean;
}

export interface MainSearchPayload {
  data: Array<any>;
}

export interface ChannelSearchType {
  items: Array<ContentPeerChannelType>;
  count?: number
}
export interface ActivitySearchType {
  items: Array<PeerActivityType>;
  count?: number
}
export interface PeerUserSearchType {
  items: Array<ContentPeerUserType>;
  count?: number
}
export interface CommentSearchType {
  items: Array<any>;
  count?: number
}