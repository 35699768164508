import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {
    Label, Row,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from 'reactstrap';
import 'emojionearea/dist/emojionearea';
import 'emojionearea/dist/emojionearea.css';
import InputSwitch from "../../../components/InputSwitch";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';

import {
    Formik,
    Form,
    Field
} from 'formik';
import ButtonSpinner from "../../../components/ButtonSpinner";
import {
    saleProductPropertiesModalHide,
    updateProductProperties
} from "../../../redux/Sale/Product/actions";
import InputSelect from "../../../components/InputSelect";
import InputText from "../../../components/InputText";
import {ProductParamType, ProductParamTypes} from "../../../redux/Sale/ProductParam/types";
import {ProductParamValueType} from "../../../redux/Sale/ProductParamValue/types";
import {editDataSaleProductParam, showRightModalSaleProductParam} from "../../../redux/Sale/ProductParam/actions";

const RightModalProductProperties = () => {

    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [productParamValues, setProductParamValues] = useState<ProductParamValueType[]>([]);
    const [productParams, setProductParams] = useState<ProductParamType[]>([]);

    const {
        saleProduct,
        saleProductParam,
        isSending
    } = useAppSelector(state => ({
        saleProduct: state.SaleProduct,
        saleProductParam: state.SaleProductParam,
        isSending: state.SaleProduct.isSending
    }));

    const [formValues, setFormValues] = useState<any[]>([]);

    useEffect(() => {
        setCurrentFormValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productParamValues]);

    const setCurrentFormValues = () => {
        let frmValues = {};

         (productParams || []).map((productParam:ProductParamType, paramKey: number)=> {
             productParam.enabled && productParamValues !== undefined && productParamValues !== null &&
            (productParamValues || []).map((productParamValue: ProductParamValueType) => {
                if (productParamValue?.value && productParamValue.value !== null && productParamValue.productParam.uuid === productParam.uuid) {
                    frmValues['productParam_' + productParamValue.productParam.uuid + '_' + paramKey] = productParamValue.value;
                    frmValues['productParamValueUuid_' + productParamValue.productParam.uuid + '_' + paramKey] = productParamValue.uuid;
                }
                return productParamValue;
            });
            return productParam;
        });

        setFormValues(Object(frmValues));

    }

    useEffect(() => {

        if (saleProduct?.selectedProductProperties && (saleProduct?.selectedProductProperties || []).length>0) {
            setProductParamValues([...saleProduct.selectedProductProperties]);
        } else {
            setProductParamValues([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProduct?.selectedProductProperties]);

    const handleClose = () => dispatch(saleProductPropertiesModalHide());

    useEffect(() => {
        if (saleProductParam?.items && (saleProductParam?.items || []).length>0){
            setProductParams([...saleProductParam.items]);
        } else {
            setProductParams([]);
        }
    }, [saleProductParam?.items]);

    const onDataSubmit = (formData:any) => {

        let params: any = {
            product: { uuid : saleProduct.selectedItem.uuid},
            productParamValues: []
        };

        let productParamValues:Array<ProductParamValueType> = [];

        Object.keys(formData).forEach(key => {
            if (key.match(/productParam_(\S+)_/)){
                const paramKeyField = key.match(/productParam_(\S+)_(\d+)/);
                if (paramKeyField[1] && paramKeyField[2]){

                    if (
                        (formData[key]['value'] && formData[key]['value'] !== null)
                        ||
                        (!formData[key]['value'] && (formData[key].length>0))
                    ) {
                        productParamValues.push({
                            uuid: formData['productParamValueUuid_' + paramKeyField[1] + '_' + paramKeyField[2]] && formData['productParamValueUuid_' + paramKeyField[1] + '_' + paramKeyField[2]],
                            productParam: {
                                uuid: paramKeyField[1]
                            },
                            value: formData[key]['value'] ? formData[key]['value'] : formData[key]
                        });
                    }
                }
            }
        });

        if (productParamValues.length>0){
            params = {
                ...params,
                productParamValues: productParamValues
            }
        }

        dispatch(updateProductProperties(params));
    };

    const openRightModalProductPropertiesTypes = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(editDataSaleProductParam({data:event, modalEditTitle: 'changeProperty'}));
        } else {
            dispatch(showRightModalSaleProductParam({data:[], modalEditTitle: 'newProperty'}));
        }
    };

    return (
        <>
                <Offcanvas
                    direction="end"
                    isOpen={saleProduct.rightModalPropertiesShow}
                    id="saleProductPropertiesEdit"
                    toggle={handleClose}
                >
                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
//                    validationSchema={SaleProductIntegrationValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('product:editProductProperties')}
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">
                                    {(productParams || []).map((productParam:ProductParamType, key: number)=>(
                                        productParam.enabled &&
                                            <div className="mb-3" key = {'productProperties_'+productParam.uuid+'_'+key}>
                                                <Label className="form-label" htmlFor={"form-param-input-"+productParam.uuid+'_'+key}>{productParam.name}</Label>
                                                {productParam.type === ProductParamTypes.STRING &&
                                                        <Field
                                                            id={"form-param-input-"+productParam.uuid+'_'+key}
                                                            name={"productParam_"+productParam.uuid+'_'+key}
                                                            placeholder={t('common:enterValue')}
                                                            component={InputText}
                                                            value = {getFieldProps("productParam_"+productParam.uuid+'_'+key).value}
                                                            onChange={(value:string) => {
                                                                setFieldValue("productParam_"+productParam.uuid+'_'+key, value);
                                                            }}
                                                        />
                                                }
                                                {productParam.type === ProductParamTypes.NUMBER &&
                                                        <Field
                                                            id={"form-param-input-"+productParam.uuid+'_'+key}
                                                            name={"productParam_"+productParam.uuid+'_'+key}
                                                            placeholder={t('common:enterValue')}
                                                            component={InputText}
                                                            value = {getFieldProps("productParam_"+productParam.uuid+'_'+key).value}
                                                            onChange={(value:string) => {
                                                               setFieldValue("productParam_"+productParam.uuid+'_'+key, value);
                                                            }}
                                                            className="text-end"
                                                        />
                                                }
                                                {productParam.type === ProductParamTypes.BOOLEAN &&
                                                        <Field
                                                            id={"form-param-input-"+productParam.uuid+'_'+key}
                                                            name={"productParam_"+productParam.uuid+'_'+key}
                                                            component={InputSwitch}
                                                            value = {getFieldProps("productParam_"+productParam.uuid+'_'+key).value}
                                                            onChange={(value:string) => {
                                                                 setFieldValue("productParam_"+productParam.uuid+'_'+key, !getFieldProps("productParam_"+productParam.uuid+'_'+key).value);
                                                            }}
                                                        />
                                                }
                                                {productParam.type === ProductParamTypes.LIST &&
                                                        <Field
                                                            id={"form-param-input-"+productParam.uuid+'_'+key}
                                                            name={"productParam_"+productParam.uuid+'_'+key}
                                                            placeholder={t('common:chooseValue')}
                                                            component={InputSelect}
                                                            value = {
                                                                getFieldProps("productParam_"+productParam.uuid+'_'+key).value
                                                            }
                                                            items = {[{id:null, value: t('common:selectNoneValue')},...(productParam.values || [])]}
                                                            onChange={(value:string) => {
                                                                setFieldValue("productParam_"+productParam.uuid+'_'+key, value);
                                                            }}
                                                        />
                                                }
                                            </div>
                                    ))}

                                    <Row>
                                        <div className="mb-3">

                                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                                <label className="mb-0 flex-grow-1 d-flex" onClick={openRightModalProductPropertiesTypes}>
                                                    {t('product:needAddNewProductProperty')}
                                                </label>
                                                <div className="d-flex flex-row flex-grow-0">
                                                    <a type="button" target="_blank" rel="noreferrer"
                                                       onClick={openRightModalProductPropertiesTypes}
                                                       className="btn btn-sm btn-outline-primary btn-icon waves-effect waves-light"><i className="ri-add-line"/>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </Row>
                                </div>
                            </SimpleBar>

                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                            <Button variant="light" onClick={handleClose} className="w-100">
                                {t('common:close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit"
                                    disabled={isSending}
                                    className="btn btn-primary w-100 chat-send waves-effect waves-light"
                            >
                                {t('common:save')}
                                {isSending &&
                                    <ButtonSpinner/>
                                }
                            </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

                </Offcanvas>
        </>
    );
}

export default RightModalProductProperties;