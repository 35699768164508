import {AITextGeneratePayload, AITextGenerateType} from "../../../redux/AI/TextGenerate/types";
import {
    aiTextGenerateCreateCommon,
    aiTextGeneratePredictTagCommon, aiTextGenerateTitleCommon,
    aiTextGenerateTranslateCommon
} from "../../../redux/AI/TextGenerate/reducer";
import {showRightModalAITextGenerate} from "../../../redux/AI/TextGenerate/actions";
import { v4 as uuidv4 } from 'uuid';
import {ContentPeerChannelType} from "../../../redux/Content/PeerChannel/types";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import {PeerActivityStatus} from "../../../redux/Content/PeerActivityStatus/types";
import {PeerActivityModeType} from "../../../redux/Content/PeerActivityMode/types";
import {PeerActivityTypeType} from "../../../redux/Content/PeerActivityType/types";
import {onUpdatePeerActivityItems} from "../../../redux/Content/PeerActivity/actions";


const addPeerActivityIdeaText = (
                                    dispatch:any,
                                    channel : ContentPeerChannelType,
                                    mode : PeerActivityModeType,
                                    type : PeerActivityTypeType,
                                    text: string,
                                    isHtml: boolean = false,
                                    tags?:string
                                 ) => {

    let peerActivityIdea : PeerActivityType = {
        channel: channel,
        connector: channel.connector,
        status: PeerActivityStatus.STATUS_NEW,
        enabled: true,
        text: text,
        isHtml: isHtml && isHtml,
        tags: tags && tags,
        mode: mode && mode,
        type: type && type
    }

    dispatch(onUpdatePeerActivityItems({peerActivities:[peerActivityIdea]}));

}


export {
    addPeerActivityIdeaText
}