// types
import {CouponActionTypes, CouponType, CouponState} from "./types";

export const INIT_STATE_COUPON: CouponState = {
    selectedItem: null,
    isSending: false,
    isFetched: false,
    isLoading: false,
    isNeedReload: false,
};

const Coupon = (state = INIT_STATE_COUPON, action: any) => {
    switch (action.type) {
        case CouponActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CouponActionTypes.CHECK_M9S_COUPON:
                    return {
                        ...state,
                        selectedItem: (action.payload.data.items || []).shift(),
                        isSending: false
                    };
                default:
                    return { ...state };
            }

        case CouponActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CouponActionTypes.CHECK_M9S_COUPON:
                    return {
                        ...state,
                        isSending: false
                    };

                default:
                    return { ...state };
            }
        case CouponActionTypes.CHECK_M9S_COUPON:
            return {
                ...state,
                selectedItem: action.payload.data,
                isSending: true
            }
        case CouponActionTypes.CLEAR_M9S_COUPON:
            return {
                ...state,
                selectedItem: null,
                isSending: false
            }
        default:
            return { ...state };
    }
};

export default Coupon;
