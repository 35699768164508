import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import '../../../i18/config';
import {
    handleDeviceRemoveAction, handleDeviceRemoveHide,
} from "./DeviceActions";
import { useRedux } from "../../../hooks";
import ListItem from "./ListItem";
import {MainDeviceType} from "../../../redux/Main/Device/types";
import {Row, Col} from 'reactstrap';
import RemoveModal from "../../../components/RemoveForm";

const UserDevices = () => {
    const { t } = useTranslation(['common']);

    const { dispatch, useAppSelector } = useRedux();

    const {
        mainDevice,
        isSending
    } = useAppSelector(state => ({
        mainDevice: state.MainDevice,
        isSending: state.MainDevice.isSending
    }));


    return (
        <>
            {(mainDevice.items || []).filter((item:MainDeviceType)=>{return item.fingerPrint === mainDevice.current.fingerPrint}).length<1 &&
            <Row>
                <h6>{t('device:currentDevice')}</h6>
                <Col xxl={6}
                     sm={6}
                     key={'currentDevice'}
                >
                    <ListItem
                        item={mainDevice.current}
                        isCurrent={true}
                    />
                </Col>
            </Row>
            }
            {(mainDevice.items || []).length>0 &&
            <Row>
                <h6>{t('device:approvedDevices')}</h6>
            {(mainDevice.items || []).map((item:MainDeviceType, num: number)=>{
                return (
                    <Col xxl={6}
                         sm={6}
                         key={'userDevice_'+num}
                    >
                        <ListItem item={item}
                                  isCurrent={mainDevice.current.fingerPrint === item.fingerPrint}
                        />
                    </Col>
                )
            })}
            </Row>
            }

            <RemoveModal
                isOpen={mainDevice.removeModalShow}
                removeItem={mainDevice.selectedItem}
                onRemove={handleDeviceRemoveAction}
                handleClose={handleDeviceRemoveHide}
                modalTitle={mainDevice.modalEditTitle}
            />

        </>

    )
}

export default UserDevices;