import React, {useEffect, useState} from "react";
import {useParams, Link} from 'react-router-dom'
import {useRedux} from "../../../../hooks";
import {Col, Row} from 'reactstrap';
import '../../../../i18/config';
import {useTranslation} from "react-i18next";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {
    PeriodDurationType,
    PeriodType
} from "../../../../redux/Stat/DashboardContent/types";
import BreadCrumb from "../../../../components/Common/BreadCrumb";

import PeriodSelect from "../../../../components/PeriodSelect";
import * as route from "../../../../api/routes";
import {
    getDashboardSaleOrdersBestChannelsTiles,
    getDashboardSaleOrdersBestCustomersTiles,
    getDashboardSaleOrdersBestProductsTiles,
    getDashboardSaleOrdersCountriesTiles,
    getDashboardSaleOrdersCurrenciesTiles,
    getDashboardSaleOrdersLast12MonthTiles,
    getDashboardSaleOrdersStatusesTiles, getDashboardSaleOrdersWeekDaysTiles,
    getDashboardSaleOrderTiles, getDashboardSaleRecentOrders,
    selectDashboardSaleCurrency,
    setDashboardSalePeriod
} from "../../../../redux/Stat/DashboardSale/actions";
import {StatOrderPayloadType, StatOrderTileType, StatTileType} from "../../../../redux/Stat/DashboardSale/types";
import {emptyOrderTiles} from "../../../../redux/Stat/Order/types";
import StatTile from "../../../../components/StatTile";
import {CurrencyType} from "../../../../redux/Sale/Currency/types";
import {getSaleCurrencies} from "../../../../redux/Sale/Currency/actions";
import {CURRENCY_SYMBOL_EXTRACT_REGEX} from "../../../../data/settings";
import CurrencySelect from "../../../../components/Sale/CurrencySelect";
import OrdersCountriesMap from "../../../../components/Dashboard/OrdersCountriesMap";
import OrdersCurrenciesChart from "../../../../components/Dashboard/OrdersCurrenciesChart";
import {SaleOrderStatusType} from "../../../../redux/Sale/OrderStatus/types";
import OrdersStatusChart from "../../../../components/Dashboard/OrdersStatusChart";
import {getSaleOrderStatuses} from "../../../../redux/Sale/OrderStatus/actions";
import {ProductType} from "../../../../redux/Sale/Product/types";
import {ContentPeerChannelType} from "../../../../redux/Content/PeerChannel/types";
import {CustomerType} from "../../../../redux/Sale/Customer/types";
import {OrderType} from "../../../../redux/Sale/Order/types";
import SaleTableProducts from "../../../../components/Dashboard/SaleTableProducts";
import SaleTableChannels from "../../../../components/Dashboard/SaleTableChannels";
import SaleTableCustomers from "../../../../components/Dashboard/SaleTableCustomers";
import SaleTableOrders from "../../../../components/Dashboard/SaleTableOrders";
import SaleMainChart from "../../../../components/Dashboard/SaleMainChart";
import {capitalizeFirstLetter} from "../../../../helpers/functions";
import SaleOrderHeatmapWeekly from "../../../../components/Dashboard/SaleOrderHeatmapWeekly";
import {ProductParamType} from "../../../../redux/Sale/ProductParam/types";
import {getSaleProductParams} from "../../../../redux/Sale/ProductParam/actions";
import {MenuItem} from "../../../../layouts/LayoutMenuData";
import {changeDefaultDashboard, saveSettingsValues} from "../../../../redux/Main/Layout/actions";
import {useProfile} from "../../../../hooks/UserHooks";

const SaleDashboardIndex = (props) => {
    const { t } = useTranslation();
    const { uuid } = useParams();

    const { dispatch, useAppSelector } = useRedux();

    const [ isLoaded, setLoaded ] = useState<boolean>(false);
    const [ periodType, setPeriodType ] = useState<PeriodDurationType>(PeriodDurationType.WEEK);
    const [currentTiles, setCurrentTiles] = useState<StatTileType[]>(emptyOrderTiles);
    const [currentCountriesTiles, setCurrentCountriesTiles] = useState<StatTileType[]>([]);
    const [currentCurrenciesTiles, setCurrentCurrenciesTiles] = useState<StatTileType[]>([]);
    const [currentOrderStatusesTiles, setCurrentOrderStatusesTiles] = useState<StatTileType[]>([]);
    const [currentOrderBestProductsTiles, setCurrentOrderBestProductsTiles] = useState<StatOrderTileType[]>([]);
    const [currentOrderBestChannelsTiles, setCurrentOrderBestChannelsTiles] = useState<StatOrderTileType[]>([]);
    const [currentOrderBestCustomersTiles, setCurrentOrderBestCustomersTiles] = useState<StatOrderTileType[]>([]);
    const [currentOrderLast12MonthTiles, setCurrentOrderLast12MonthTiles] = useState<StatOrderTileType[]>([]);
    const [currentOrderWeekDaysTiles, setCurrentOrderWeekDaysTiles] = useState<StatOrderTileType[]>([]);

    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);
    const [products, setProducts] = useState<ProductType[]>([]);
    const [channels, setChannels] = useState<ContentPeerChannelType[]>([]);
    const [customers, setCustomers] = useState<CustomerType[]>([]);
    const [recentOrders, setRecentOrders] = useState<OrderType[]>([]);
    const [orderStatuses, setOrderStatuses] = useState<SaleOrderStatusType[]>([]);
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyType>(null);
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string>('');
    const [productParams, setProductParams] = useState<ProductParamType[]>([]);

    const { userProfile } = useProfile();

    const {
        dashboardSale,
        isFetched,
        isFetchedOrdersTiles,
        periodFrom,
        periodTo,
        saleCurrency,
        saleOrderStatus,
        saleProductParam,
        isFetchedLast12monthTiles,
        isFetchedWeekDaysTiles,
        defaultDashboard
    } = useAppSelector(state => ({
        dashboardSale: state.DashboardSale,
        isFetched: state.DashboardSale.isFetched,
        isFetchedOrdersTiles: state.DashboardSale.isFetchedOrdersTiles,
        periodFrom: state.DashboardSale.periodFrom,
        periodTo: state.DashboardSale.periodTo,
        saleCurrency: state.SaleCurrency,
        saleOrderStatus: state.SaleOrderStatus,
        saleProductParam: state.SaleProductParam,
        isFetchedLast12monthTiles: state.DashboardSale.isFetchedLast12monthTiles,
        isFetchedWeekDaysTiles: state.DashboardSale.isFetchedWeekDaysTiles,
        defaultDashboard: state.Layout.defaultDashboard
    }));

    useEffect(() => {
        dispatch(getSaleCurrencies({maxResults:1000}));
        dispatch(getSaleOrderStatuses({maxResults:1000}));
        dispatch(getSaleProductParams({maxResults:1000}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if ((saleCurrency?.items || []).length>0){
            setCurrencies([...saleCurrency.items]);
        } else {
            setCurrencies([]);
        }

    }, [saleCurrency?.items]);

    useEffect(() => {

        if ((saleOrderStatus?.items || []).length>0){
            setOrderStatuses([...saleOrderStatus.items]);
        } else {
            setOrderStatuses([]);
        }

    }, [saleOrderStatus?.items]);

    useEffect(() => {
        if (saleProductParam?.items && (saleProductParam?.items || []).length>0){
            setProductParams([...saleProductParam.items]);
        } else {
            setProductParams([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleProductParam?.items]);

    useEffect(() => {
        setLoaded(isFetched);
    },[isFetched]);

    useEffect(() => {

        (currencies || []).map((item:CurrencyType)=>{

            if (item.isDefault){
                dispatch(selectDashboardSaleCurrency(item));
            }

            return item;
        })
    }, [currencies, dispatch]);

    useEffect(() => {
        if (dashboardSale?.selectedCurrency && dashboardSale?.selectedCurrency?.uuid) {
            setSelectedCurrency({...dashboardSale?.selectedCurrency});
            setSelectedCurrencyCode(dashboardSale?.selectedCurrency.currencyFormat.replace(CURRENCY_SYMBOL_EXTRACT_REGEX,''));

        } else {
            setSelectedCurrency(null);
            setSelectedCurrencyCode('');
        }

    },[dashboardSale?.selectedCurrency]);


    const handleLastWeekPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'weeks').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setDashboardSalePeriod(periodData));
        setPeriodType(PeriodDurationType.WEEK);
    }

    const handleLastMonthPeriod = () =>{

        const periodData: PeriodType ={
            periodFrom: moment().add(-1, 'months').utc().unix(),
            periodTo: moment().utc().unix(),
        }

        dispatch(setDashboardSalePeriod(periodData));
        setPeriodType(PeriodDurationType.MONTH);
    }

    useEffect(() => {

        // const statPeriodPayload:StatPeriodPayloadType = {
        //     periodFrom : dashboardSale.periodFrom,
        //     periodTo: dashboardSale.periodTo
        // }

        if (selectedCurrency?.uuid){
            const statOrderPayloadType:StatOrderPayloadType = {
                periodFrom : dashboardSale.periodFrom,
                periodTo: dashboardSale.periodTo,
                currency: selectedCurrency && selectedCurrency
            }

            dispatch(getDashboardSaleOrderTiles(statOrderPayloadType));
            dispatch(getDashboardSaleOrdersCountriesTiles(statOrderPayloadType))
            dispatch(getDashboardSaleOrdersCurrenciesTiles(statOrderPayloadType));
            dispatch(getDashboardSaleOrdersStatusesTiles(statOrderPayloadType));
            dispatch(getDashboardSaleOrdersBestProductsTiles(statOrderPayloadType));
            dispatch(getDashboardSaleOrdersBestChannelsTiles(statOrderPayloadType));
            dispatch(getDashboardSaleOrdersBestCustomersTiles(statOrderPayloadType));
            dispatch(getDashboardSaleOrdersLast12MonthTiles(statOrderPayloadType));
            dispatch(getDashboardSaleRecentOrders(statOrderPayloadType));
            dispatch(getDashboardSaleOrdersWeekDaysTiles(statOrderPayloadType));

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dashboardSale?.periodFrom, dashboardSale?.periodTo, selectedCurrency])

    useEffect(() => {
        if (dashboardSale?.tiles && (dashboardSale.tiles || []).length>0) {
           setCurrentTiles([...dashboardSale.tiles]);
            // TODO mock
            //setCurrentTiles(mockOrderTiles);
        } else {
            setCurrentTiles(emptyOrderTiles);
        }
    }, [dashboardSale?.tiles]);

    useEffect(() => {
        if (dashboardSale?.countriesTiles && (dashboardSale.countriesTiles || []).length>0) {
            setCurrentCountriesTiles([...dashboardSale.countriesTiles]);
            //TODO mock
            //setCurrentCountriesTiles(mockCountriesTiles);
        } else {
            setCurrentCountriesTiles([]);
        }
    }, [dashboardSale?.countriesTiles]);

    useEffect(() => {
        if (dashboardSale?.currenciesTiles && (dashboardSale.currenciesTiles || []).length>0) {
            setCurrentCurrenciesTiles([...dashboardSale.currenciesTiles]);
        } else {
            setCurrentCurrenciesTiles([]);
        }
    }, [dashboardSale?.currenciesTiles]);

    useEffect(() => {
        if (dashboardSale?.statusesTiles && (dashboardSale.statusesTiles || []).length>0) {
            setCurrentOrderStatusesTiles([...dashboardSale.statusesTiles]);
        } else {
            setCurrentOrderStatusesTiles([]);
        }
    }, [dashboardSale?.statusesTiles]);

    useEffect(() => {
        if (dashboardSale?.bestProductsTiles && (dashboardSale.bestProductsTiles || []).length>0) {
            setCurrentOrderBestProductsTiles([...dashboardSale.bestProductsTiles]);
        } else {
            setCurrentOrderBestProductsTiles([]);
        }
    }, [dashboardSale?.bestProductsTiles]);

    useEffect(() => {
        if (dashboardSale?.bestProducts && (dashboardSale.bestProducts || []).length>0) {
            setProducts([...dashboardSale.bestProducts]);
        } else {
            setProducts([]);
        }
    }, [dashboardSale?.bestProducts]);

    useEffect(() => {
        if (dashboardSale?.bestChannelsTiles && (dashboardSale.bestChannelsTiles || []).length>0) {
            setCurrentOrderBestChannelsTiles([...dashboardSale.bestChannelsTiles]);
        } else {
            setCurrentOrderBestChannelsTiles([]);
        }
    }, [dashboardSale?.bestChannelsTiles]);

    useEffect(() => {
        if (dashboardSale?.bestChannels && (dashboardSale.bestChannels || []).length>0) {
            setChannels([...dashboardSale.bestChannels]);
        } else {
            setChannels([]);
        }
    }, [dashboardSale?.bestChannels]);

    useEffect(() => {
        if (dashboardSale?.bestCustomersTiles && (dashboardSale.bestCustomersTiles || []).length>0) {
            setCurrentOrderBestCustomersTiles([...dashboardSale.bestCustomersTiles]);
        } else {
            setCurrentOrderBestCustomersTiles([]);
        }
    }, [dashboardSale?.bestCustomersTiles]);

    useEffect(() => {
        if (dashboardSale?.bestCustomers && (dashboardSale.bestCustomers || []).length>0) {
            setCustomers([...dashboardSale.bestCustomers]);
        } else {
            setCustomers([]);
        }
    }, [dashboardSale?.bestCustomers]);

    useEffect(() => {
        if (dashboardSale?.last12monthTiles && (dashboardSale.last12monthTiles || []).length>0) {
            setCurrentOrderLast12MonthTiles([...dashboardSale.last12monthTiles]);
            //TODO mock
            //setCurrentOrderLast12MonthTiles(mockOrderLast12MonthTiles);
        } else {
            setCurrentOrderLast12MonthTiles([]);
        }
    }, [dashboardSale?.last12monthTiles]);

    useEffect(() => {
        if (dashboardSale?.weekDaysTiles && (dashboardSale.weekDaysTiles || []).length>0) {
            setCurrentOrderWeekDaysTiles([...dashboardSale.weekDaysTiles]);
        } else {
            setCurrentOrderWeekDaysTiles([]);
        }
    }, [dashboardSale?.weekDaysTiles]);

    useEffect(() => {
        if (dashboardSale?.recentOrders && (dashboardSale.recentOrders || []).length>0) {
            setRecentOrders([...dashboardSale.recentOrders]);
        } else {
            setRecentOrders([]);
        }
    }, [dashboardSale?.recentOrders]);

    const handleChangeCurrency = (item:CurrencyType) => {
        dispatch(selectDashboardSaleCurrency(item));
    }

    return (
        <>
            <BreadCrumb title={t('menu:dashboard')} pageTitle={t('menu:main')} />
            <Row className="mb-3 pb-1">
            <Col xs={12}>
                <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                    <div className="flex-grow-1">
                        <h4 className="fs-16 mb-1">{t('common:welcome')}, {userProfile.fullName}!</h4>
                        <p className="text-muted mb-0">{t('common:welcomeSaleDashboardText')}</p>
                    </div>

                    {/*{!isLoaded &&*/}
                    {/*    <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center" >*/}
                    {/*        <div className="d-flex justify-content-center align-items-center calendarLoader">*/}
                    {/*            <Loader addClassNames=""/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    <div className="mt-3 mt-lg-0">
                        <Row className="g-3 mb-0 align-items-center">
                            <div className="col-sm-auto">
                                {periodType === PeriodDurationType.DATE_RANGE ?
                                    <>
                                        <PeriodSelect
                                            onDispatch={setDashboardSalePeriod}
                                            periodFrom={periodFrom}
                                            periodTo={periodTo}/>
                                    </>
                                    :
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className={"btn "+(periodType===PeriodDurationType.WEEK?'btn-primary':'btn-soft-primary') }onClick={handleLastWeekPeriod}>{t('common:week')}</button>
                                        <button type="button" className={"btn "+(periodType===PeriodDurationType.MONTH?'btn-primary':'btn-soft-primary')} onClick={handleLastMonthPeriod}>{t('common:month')}</button>
                                        <button type="button" className="btn btn-soft-primary" onClick={()=>{setPeriodType(PeriodDurationType.DATE_RANGE)}}>
                                            <i className="ri-calendar-2-line align-bottom"/>
                                        </button>
                                        <button type="button" className={"btn "+(defaultDashboard===MenuItem.DASHBOARD_SALES?'btn-primary':'btn-soft-primary')}
                                                onClick={()=>{
                                                    if (defaultDashboard!==MenuItem.DASHBOARD_SALES) {
                                                        dispatch(changeDefaultDashboard(MenuItem.DASHBOARD_SALES));
                                                        dispatch(saveSettingsValues());
                                                    }
                                                }}
                                        >
                                            <i className="ri-star-line align-bottom"/>
                                        </button>
                                    </div>
                                }
                            </div>

                            <div className="col-auto">
                                <a href={route.SALE_ORDERS_LIST} className="btn btn-primary"><i className="ri-shopping-cart-2-line align-bottom me-1"/> {t('menu:orders')}</a>
                            </div>
                        </Row>
                        <Row>
                            {(currencies || []).length>0 &&
                                <CurrencySelect items={currencies} selectedCurrency={selectedCurrency} onClick={handleChangeCurrency}/>
                            }
                        </Row>
                    </div>
                </div>
            </Col>
            </Row>
            <Row>
                <Col xl={8}>
                    <Row>
                        {(currentTiles || []).map((item: StatTileType, key:number)=>(
                                <Col md={3} key = {'orderTile_'+key}>
                                    <StatTile
                                        label={'order'+capitalizeFirstLetter(item.label)}
                                        start={0}
                                        first={item.value.first && item.value.first}
                                        last={item.value.last}
                                        isLoading={!isFetchedOrdersTiles}
                                        deltaLabel={moment(dashboardSale.periodTo,'X').diff(moment(dashboardSale.periodFrom,'X'),'days').toString()}
                                        customLabel={item.label.match(/Amount/) && selectedCurrencyCode}
                                        isFullNumber={true}
                                    />
                                </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <SaleMainChart items={(currentOrderLast12MonthTiles || [])} currency={selectedCurrency}/>
                        </Col>
                    </Row>
                </Col>
                <Col xl={4}>
                    <OrdersCountriesMap items={currentCountriesTiles || []}/>
                </Col>
            </Row>

            <Row>
                <Col xl={8}>
                    <SaleTableProducts
                        items={(currentOrderBestProductsTiles || [])}
                        entities={(products ||[])}
                        currency={selectedCurrency}
                        productParams={productParams ||[]}/>
                </Col>
                <Col xl={4}>
                    <OrdersCurrenciesChart  items={currentCurrenciesTiles || []} currencies={currencies}/>
                </Col>
            </Row>


            <Row>
                <Col xl={8}>
                    <SaleTableChannels items={(currentOrderBestChannelsTiles || [])} entities={(channels ||[])} currency={selectedCurrency}/>
                </Col>
                <Col xl={4}>
                    <OrdersStatusChart  items={currentOrderStatusesTiles || []} statuses={orderStatuses}/>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <SaleTableCustomers items={(currentOrderBestCustomersTiles || [])} entities={(customers ||[])} currency={selectedCurrency}/>
                </Col>
                <Col md={6}>
                    <SaleOrderHeatmapWeekly items={(currentOrderWeekDaysTiles) || []} color={'#007aff'}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SaleTableOrders items={(recentOrders || [])}/>
                </Col>
            </Row>
        </>
    );
};

export default SaleDashboardIndex;


