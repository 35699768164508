
export enum MainVersionHistoryActionTypes {
  API_RESPONSE_SUCCESS = "@@mainVersionHistory/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@mainVersionHistory/API_RESPONSE_ERROR",
  GET_VERSION_HISTORY_LIST = "@@mainVersionHistory/GET_VERSION_HISTORY_LIST",
}

export interface MainVersionHistoryState {
  items?: Array<MainVersionHistoryType>;
  eTag?: string;
}

export interface MainVersionHistoryType {
  uuid: string,
  name: string,
  code: string,
  module: string,
  version: string,
  icon: string,
  iconClass :string,
  text: string,
  tags: string,
  dateTime: string
}