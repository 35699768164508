import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//i18n
import '../i18/config';
import {useTranslation} from "react-i18next";
import {useRedux} from "../hooks/";
import {useProfile} from "../hooks/UserHooks";
import {logoutUser} from "../redux/Main/Login/actions";

const ProfileSwitch = () => {

    const { t } = useTranslation(['auth']);

    const { userProfile } = useProfile();

    const { dispatch } = useRedux();

    const [isProfileDropdown, setIsProfileDropdown] = useState(false);

    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const handlerLogOut = () => {
        dispatch(logoutUser());
    }

    return (
        <>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                    {userProfile?.avatar && userProfile.avatar ?
                        <img
                            src={ userProfile?.avatar && userProfile.avatar}
                            alt={userProfile.fullName}
                            height="30"
                            className="rounded-circle"
                        />
                        :
                        <i className="bx bx-user-circle text-primary fs-23"/>
                    }
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                        <DropdownItem
                            onClick={() => handlerLogOut()}
                            className={"notify-item"}
                        >
                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"/>
                            <span className="align-middle" data-key="t-logout">{t('auth:signOut')}</span>

                        </DropdownItem>

                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default ProfileSwitch;