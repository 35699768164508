import React, { useEffect, useRef, useCallback, useState } from "react";

// hooks
import { useRedux } from "../../../hooks";

// hooks
import { useProfile } from "../../../hooks/UserHooks";

// components
//import PerfectScrollbar from "react-perfect-scrollbar";
import AppSimpleBar from "../../../components/AppSimpleBar";

import Loader from "../../../components/Loader";
import Message from "./Message";
// import Day from "./Day";

// interface
//import ForwardModal from "../../../components/ForwardModal";

// actions
import { forwardMessage, deleteImage } from "../../../redux/actions";
import {MessengerMessageType} from "../../../redux/Messenger/Chat/types";
import MessengerChat from "../../../redux/Messenger/Chat/reducer";

interface ConversationProps {
  chatUserConversations: any;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessengerMessageType | undefined) => void;
  isChannel: boolean;
}
const ConversationComments = ({
  chatUserDetails,
  chatUserConversations,
  onDelete,
  onSetReplyData,
  isChannel,
}: ConversationProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const { userProfile } = useProfile();

  const { getUserConversationsLoading, isMessageForwarded } = useAppSelector(
    (state: any) => ({
      getUserConversationsLoading: state.MessengerComment.getUserConversationsLoading,
      isMessageForwarded: state.MessengerComment.isMessageForwarded,
    })
  );

  const messages =
    chatUserConversations.messages && chatUserConversations.messages.length
      ? chatUserConversations.messages
      : [];

  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 0;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current
          .getScrollElement()
          .scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  }, []);
  useEffect(() => {
    if (chatUserConversations.messages) {
      scrollElement();
    }
  }, [chatUserConversations.messages, scrollElement]);

  /*
  forward message
  */
  const [forwardData, setForwardData] = useState<
    null | MessengerMessageType | undefined
  >();
  const [isOpenForward, setIsOpenForward] = useState<boolean>(false);
  const onOpenForward = (message: MessengerMessageType) => {
    setForwardData(message);
    setIsOpenForward(true);
  };
  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data: any) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };
  useEffect(() => {
    if (isMessageForwarded) {
      setIsOpenForward(false);
    }
  }, [isMessageForwarded]);

  /*
  image delete
  */
  const onDeleteImage = (
    messageId: string | number,
    imageId: string | number
  ) => {
    dispatch(deleteImage(messageId, imageId));
  };
  return (
    <AppSimpleBar
        scrollRef={ref}
      className="chat-conversation p-3 p-lg-4 position-relative"
    >
      {getUserConversationsLoading && <Loader />}
      <ul
        className="list-unstyled chat-conversation-list"
        id="chat-conversation-list"
      >
        {(messages || []).map((message: MessengerMessageType, key: number) => {
          const isFromMe = message.sender + "" === userProfile.uuid + "";
          return (
            <Message
              message={message}
              key={key}
              chatUserDetails={chatUserDetails}
              onDelete={onDelete}
              onSetReplyData={onSetReplyData}
              isFromMe={isFromMe}
              onOpenForward={onOpenForward}
              isChannel={isChannel}
              onDeleteImage={onDeleteImage}
            />
          );
        })}
        {/*  <Day /> */}
      </ul>
      {/*{isOpenForward && (*/}
      {/*  <ForwardModal*/}
      {/*    isOpen={isOpenForward}*/}
      {/*    onClose={onCloseForward}*/}
      {/*    forwardData={forwardData}*/}
      {/*    chatUserDetails={chatUserDetails}*/}
      {/*    onForward={onForwardMessage}*/}
      {/*  />*/}
      {/*)}*/}
    </AppSimpleBar>
  );
};

export default ConversationComments;
