import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { MainSearchActionTypes } from "./types";
import { mainSearchApiResponseSuccess, mainSearchApiResponseError} from "./actions";

import {
  getMainSearch as getMainSearchApi,
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

function* getMainSearch({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getMainSearchApi, data);
    yield put(
        mainSearchApiResponseSuccess(MainSearchActionTypes.GET_SEARCH_LIST, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(mainSearchApiResponseError(MainSearchActionTypes.GET_SEARCH_LIST, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetMainSearch() {
  yield takeEvery(MainSearchActionTypes.GET_SEARCH_LIST, getMainSearch);
}

function* mainSearchSaga() {
  yield all([
    fork(watchGetMainSearch),
  ]);
}

export default mainSearchSaga;
