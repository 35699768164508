import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Label} from "reactstrap";
import { Link } from 'react-router-dom'
import CoverAuth from "./CoverAuth";
import SignUpForm from "./SignUpForm";
import {useRedux} from "../../../hooks";
import SignUpSuccess from "../../../components/SignUpSuccess";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import CoverLangSwitch from "../../../components/CoverLangSwitch";
import * as route from "../../../api/routes";
import MainUser from "../../../redux/Main/User/reducer";

const CoverSignUp = () => {

    const { t } = useTranslation(['auth']);

    const { dispatch, useAppSelector } = useRedux();

    const [signUpComplete, setSignUpComplete] = useState<boolean>(false);

    const {
        mainUser,
    } = useAppSelector(state => ({
        mainUser: state.MainUser,
    }));

    useEffect(() => {

        if (mainUser !== undefined && mainUser.isSignUpComplete !== undefined) {
            setSignUpComplete(mainUser.isSignUpComplete);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainUser?.isSignUpComplete]);

    return (
        <>
            <CoverAuth title={t('auth:signUp')+' | Marketcells.io - digital marketing platform'}>
                {!signUpComplete ?
                    <>
                        <Col lg={6}>
                            <CoverLangSwitch/>
                            <div className="p-lg-5 p-4">
                                <div>
                                    <h5 className="text-primary">{t('auth:registerAccount')}</h5>
                                    <p className="text-muted">{t('auth:getYourFreeAccount')}</p>
                                </div>

                                <div className="mt-4">
                                    <SignUpForm/>
                                </div>
                                <div className="mt-5 text-center">
                                    <p className="mb-0">{t('auth:alreadyHaveAccount')}? <Link to={route.MAIN_AUTH_LOGIN}
                                                                                       className="fw-bold text-primary text-decoration-underline">{t('auth:signIn')}</Link>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </>
                     :
                     <SignUpSuccess/>
                 }
            </CoverAuth>
        </>
    );
};

export default CoverSignUp;