import {SupportTicketActionTypes, SupportTicketPayload} from "./types";
import {RewardType} from "../../../data/reward";
import {MainMetaType} from "../../Main/common";

export const supportTicketApiResponseSuccess = (actionType: string, data: any) => ({
  type: SupportTicketActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const supportTicketApiResponseError = (actionType: string, error: string) => ({
  type: SupportTicketActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getSupportTickets= (data: MainMetaType) => ({
  type: SupportTicketActionTypes.GET_SUPPORT_TICKETS,
  payload: data
});

export const showRightModalSupportTicket= (data: SupportTicketPayload) => ({
  type: SupportTicketActionTypes.RIGHT_MODAL_SHOW,
  payload: data,
});

export const hideRightModalSupportTicket= () => ({
  type: SupportTicketActionTypes.RIGHT_MODAL_HIDE,
});

export const onSendDataSupportTicket = (data: any) => ({
  type: SupportTicketActionTypes.UPDATE_SUPPORT_TICKET,
  payload: data,
});

export const editDataSupportTicket = (data: SupportTicketPayload) => ({
  type: SupportTicketActionTypes.EDIT_SUPPORT_TICKET,
  payload: data,
});

export const removeSupportTicketModalShow = (data: SupportTicketPayload) => ({
  type: SupportTicketActionTypes.REMOVE_SUPPORT_TICKET_MODAL_SHOW,
  payload: data,
});

export const removeSupportTicketModalHide = () => ({
  type: SupportTicketActionTypes.REMOVE_SUPPORT_TICKET_MODAL_HIDE,
});

export const onRemoveSupportTicket = (data: SupportTicketPayload) => ({
  type: SupportTicketActionTypes.REMOVE_SUPPORT_TICKET,
  payload: data,
});

export const getSupportTicketDetail = (data: any) => ({
  type: SupportTicketActionTypes.GET_SUPPORT_TICKET_DETAIL,
  payload: data,
});

export const showRewardSupportTicket= (data: SupportTicketPayload) => ({
  type: SupportTicketActionTypes.REWARD_SUPPORT_TICKET_MODAL_SHOW,
  payload: data,
});

export const hideRewardModalSupportTicket= () => ({
  type: SupportTicketActionTypes.REWARD_SUPPORT_TICKET_MODAL_HIDE,
});

export const onSendSupportTicketReward= (data: RewardType) => ({
  type: SupportTicketActionTypes.SEND_REWARD_SUPPORT_TICKET,
  payload: data,
});

export const getSupportServiceTickets= (data: MainMetaType) => ({
  type: SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKETS,
  payload: data
});

export const getSupportServiceTicketDetail = (data: any) => ({
  type: SupportTicketActionTypes.GET_SUPPORT_SERVICE_TICKET_DETAIL,
  payload: data,
});

export const readSupportTicketMessages = (data: any) => ({
  type: SupportTicketActionTypes.READ_SUPPORT_TICKET_MESSAGES,
  payload: data,
});
export const readSupportServiceTicketMessages = (data: any) => ({
  type: SupportTicketActionTypes.READ_SUPPORT_SERVICE_TICKET_MESSAGES,
  payload: data,
});