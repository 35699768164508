export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN
export const config = {
  API_URL: "",
  ID_API_URL: "https://"+API_DOMAIN,
  LOCALSTORAGE_CURRENT_CABINET : 'currentCabinet',
  LOCALSTORAGE_AUTH_USER : 'authUser',
  LOCALSTORAGE_LANGUAGE: 'I18N_LANGUAGE',
  LOCALSTORAGE_M9S_CART: 'M9S_CART',
  LOCALSTORAGE_SETTINGS: 'M9S_SETTINGS',
  LOCALSTORAGE_COMPARE_CHANNELS: 'M9S_CMP_C',
  LOCALSTORAGE_PARTNER: 'M9S_PARTNER_CODE',
  LOCALSTORAGE_CANCELLED_DEVICES: 'M9S_CDP',
  LOCALSTORAGE_APPROVED_DEVICE_USERS: 'M9S_APR',
  LOCALSTORAGE_IS_NEED_REFRESH: 'M9S_INR'
};
export let m9sGlobals = {
  CURRENT_CABINET: "",
  W7_WIDGET_URL: "https://wt7.marketcells.io/:widgetData",
  GOOGLE_FEED_URL: "https://"+API_DOMAIN+"/cabinet_:cabinetUuid/sale/v1/integration/:integrationUuid",
  GA_TRACKING_ID: "G-LRJ1GW8Z9L",
  RSS_CHANNEL_URL: "https://"+API_DOMAIN+"/cabinet_:cabinetUuid/export/channel/rss/:channelUuid"
}
