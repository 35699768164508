import {
    editDataContentProjects,
    getContentProjects,
    onRemoveContentProject,
    removeContentProjectModalHide,
    removeContentProjectModalShow,
    showRightModalContentProjects,
} from "../../../redux/Content/Project/actions";

const ContentProjectOpenModal = (event:any, dispatch:any) => {
    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(editDataContentProjects({data:event, modalEditTitle: 'editProject'}));
    } else {
        dispatch(showRightModalContentProjects({data:[], modalEditTitle: 'newProject'}));
    }
}

const ContentProjectOpenRemoveModal = (event:any, dispatch:any) => {
    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(removeContentProjectModalShow({data:event, modalEditTitle: 'deleteProject'}));
    }
}

const ContentProjectOnRemove = (event:any, dispatch:any) => {

    if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
        dispatch(onRemoveContentProject({data:event, modalEditTitle: 'deleteProject'}));

        setTimeout(() => {
            dispatch(getContentProjects());
        }, 1000);
    }

}

const ContentProjectHideRemoveModal = (dispatch:any) => {

    dispatch(removeContentProjectModalHide())

}

export {
    ContentProjectOpenModal,
    ContentProjectOpenRemoveModal,
    ContentProjectOnRemove,
    ContentProjectHideRemoveModal
}