import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const sendSaleProductProperties = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_SALE_PRODUCT_PROPERTIES, data);
};


export {
    sendSaleProductProperties
};
