import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getContentConnectors = () => {
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_CONNECTORS);
};

const getContentConnectorsByType = (data:any) => {

    let requestUrl = m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_CONNECTORS_BY_TYPE;

    if (data.handlerType !== undefined){
        requestUrl = requestUrl.replace(/:handlerType/,data.handlerType);
    }

    return api.get(requestUrl);
};

const sendDataContentConnector = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_CONNECTOR, data);
};

const connectContentConnector = (data: any) => {

    const sendData = {
        uuid:data.uuid,
        verificationCode: data.verificationCode && data.verificationCode
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.CONNECT_CONTENT_CONNECTOR, sendData);
};

const isConnectedContentConnector = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.IS_CONNECTED_CONTENT_CONNECTOR, {uuid:data.uuid});
};

const removeContentConnector = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_CONNECTOR, data);
};

const searchChannelFromRemote = (data: any) => {

    const sendData = {
        connector: {uuid: data.connector.uuid},
        value: data.inputValue,
        remote: true
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.SEARCH_CHANNEL_CONTENT_CONNECTOR, sendData);
};

const getManagedChannelsFromRemote = (data: any) => {

    const sendData = {
        connector: {uuid: data.uuid},
        remote: true
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_MANAGED_CHANNELS_CONTENT_CONNECTOR, sendData);
};

export {
    getContentConnectors,
    getContentConnectorsByType,
    sendDataContentConnector,
    connectContentConnector,
    isConnectedContentConnector,
    removeContentConnector,
    searchChannelFromRemote,
    getManagedChannelsFromRemote
};
