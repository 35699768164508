import React, {useEffect, useState} from "react";
import { Card, CardBody, CardHeader, Col, Progress, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {PeerActivityType} from "../../redux/Content/PeerActivity/types";
import * as route from "../../api/routes";
import {PeerActivityTypeType} from "../../redux/Content/PeerActivityType/types";
import { ApexOptions } from 'apexcharts';
import ReactApexChart from "react-apexcharts";

import '../../i18/config';
import {useTranslation} from "react-i18next";

interface SchedulesTypesProps {
    items: [] | Array<PeerActivityType>;
    types: [] | Array<PeerActivityTypeType>
}

const SchedulesTypes = ({ items, types }: SchedulesTypesProps) => {
    const { t } = useTranslation();

    const [activityTypes, setActivityTypes] = useState<PeerActivityTypeType[]>(types);
    const [activities, setActivities] = useState<PeerActivityType[]>(items);

    const [chartLabels, setChartLabels] = useState<any[]>([]);
    const [chartColors, setChartColors] = useState<any[]>([]);
    const [chartSeries, setChartSeries] = useState<any[]>([]);
    const [series, setSeries] = useState<any[]>([]);

    useEffect(()=>{
        setActivityTypes([...types]);
    },[types])
    useEffect(()=>{
        setActivities([...items]);
    },[items])

    useEffect(()=>{

        const _chartLabels=[];
        const _chartColors=[];
        const _chartSeries=[];

        (activityTypes || []).map((item:PeerActivityTypeType)=>{
                _chartLabels.push(item.name);
                _chartColors.push(item.color);
                _chartSeries.push({type: item, count:0});
            return item;
        });

        (activities || []).map((item:PeerActivityType, key:number)=>{
            (_chartSeries || []).map((chartSeriesItem:any)=>{
                if (chartSeriesItem.type.uuid === item.type.uuid){
                    chartSeriesItem.count++;
                }
                return chartSeriesItem;
            });
            return item;
        });

        const _series = [
            { data: (_chartSeries || []).map((chartSeriesItem:any)=>{
                    return chartSeriesItem.count;
                }),
                name : t('peerActivity:activities')
            }
        ];

        setChartLabels([..._chartLabels]);
        setChartColors([..._chartColors]);
        setChartSeries([..._chartSeries]);
        setSeries([..._series]);

    },[activities, activityTypes, t])

    const options:ApexOptions =
        {
            chart: {
                type: 'bar',
                height: 436,
                toolbar: {
                    show: false,
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                    distributed: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            colors: chartColors,
            dataLabels: {
                enabled: false,
                offsetX: 32,
                style: {
                    fontSize: '12px',
                    fontWeight: 400,
                    colors: ['#adb5bd']
                }
            },

            legend: {
                show: false,
            },
            grid: {
                show: false,
            },
            xaxis: {
                categories: chartLabels,
                labels: {
                    show: false
                }
            },
        };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('peerActivityType:activityTypes')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted fs-16"><i className="mdi mdi-dots-vertical align-middle"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href={route.CONTENT_ACTIVITY_PLAN}>{t('peerActivity:manageActivities')}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div className="apex-charts" >
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height="436"
                        className="apex-charts"
                    />
                </div>
                <div className="mt-3">
                    <div className="d-flex justify-content-center align-items-center mb-4">
                        <h2 className="me-3 ff-secondary mb-0">{(items || []).length}</h2>
                        <div>
                            <p className="text-muted mb-0">{t('peerActivity:totalActivities')}</p>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default SchedulesTypes;