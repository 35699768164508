// types
import {PlanOrderActionTypes, PlanOrderState} from "./types";

export const INIT_STATE_SALE_PLAN: PlanOrderState = {
  items: [],
  current: null,
  isFetched: false,
  isLoading: false,
  cancelModalShow: false
};

const PlanOrder = (state = INIT_STATE_SALE_PLAN, action: any) => {
  switch (action.type) {
    case PlanOrderActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case PlanOrderActionTypes.GET_ORDERS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
          };
        case PlanOrderActionTypes.GET_ORDER_DETAIL:
          return {
            ...state,
            current: action.payload.data.items.pop(),
            isFetched: true,
            isLoading: false,
          };
        case PlanOrderActionTypes.CREATE_ORDER:
          return {
            ...state,
            current: action.payload.data.items.pop(),
            isFetched: true,
            isLoading: false,
          };
        case PlanOrderActionTypes.CANCEL_ORDER:
          return {
            ...state,
            isLoading: false,
            cancelModalShow: false,
          };
        default:
          return { ...state };
      }
    case PlanOrderActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case PlanOrderActionTypes.GET_ORDERS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
          };
        case PlanOrderActionTypes.CANCEL_ORDER:
          return {
            ...state,
            isFetched: true,
            isLoading: false,
            cancelModalShow: false,
          };
        case PlanOrderActionTypes.CREATE_ORDER:
          return {
            ...state,
            current: null,
            isFetched: true,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case PlanOrderActionTypes.GET_ORDERS:
      return {
        ...state,
        isFetched: false,
        isLoading: true,
      };
    case PlanOrderActionTypes.GET_ORDER_DETAIL:
      return {
        ...state,
        isFetched: false,
        isLoading: true,
      };
    case PlanOrderActionTypes.CANCEL_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case PlanOrderActionTypes.CREATE_ORDER:
      return {
        ...state,
        isLoading: true,
      };
    case PlanOrderActionTypes.CANCEL_MODAL_SHOW:
      return {
        ...state,
        cancelModalShow: true,
      };
    case PlanOrderActionTypes.CANCEL_MODAL_HIDE:
      return {
        ...state,
        cancelModalShow: false,
      };
    default:
      return { ...state };
  }
};

export default PlanOrder;
