import {PeerActivityTypeType} from "../PeerActivityType/types";

export enum ContentConnectorHandlerActionTypes {
  API_RESPONSE_SUCCESS = "@@contentConnectorHandler/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@contentConnectorHandler/API_RESPONSE_ERROR",
  GET_CONTENT_CONNECTOR_HANDLERS = "@@contentConnectorHandler/GET_CONTENT_CONNECTOR_HANDLERS",
  SELECT_CONTENT_CONNECTOR_HANDLER = "@@contentConnectorHandler/SELECT_CONTENT_CONNECTOR_HANDLER",

}

export interface ContentConnectorHandlerState {
  items: Array<any>;
  selectedItem?: ContentConnectorHandlerType;
  isConnectorHandlerFetched: boolean;
}

export interface ContentConnectorHandlerType{
  uuid: string,
  enabled: boolean,
  code: string,
  icon?: string,
  type: string,
  order: number,
  isFareRestriction?: boolean,
  params?: Array<ContentConnectorHandlerParamType>,
  canAddPost?:boolean,
  canPinPost?:boolean,
  canAddStory?:boolean,
  canChangeBio?:boolean,
  canAddComment?:boolean,
  canRestoreComment?:boolean,
  canECommerce?:boolean,
  canAddShort?:boolean,
  canUnSubscribeMember?:boolean,
  canChannelSearch?:boolean,
  canActivityButtons?: boolean
}

export interface ContentConnectorHandlerPayload {
  data: Array<any>;
  modalEditTitle?: string;
}

export interface ContentConnectorHandlerParamType{
  uuid: string,
  code: string,
}
