import React from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {showSuccessCopyNotification} from "../helpers/notifications";

interface ItemProps {
  icon?: string;
  type?: string;
  firstText: string;
  secondText?: string;
  secondTextWithCopy?: string;
  secondTextWithCopyTruncate?:boolean
}
const NoticeTip = ({ firstText, secondText, secondTextWithCopy, type = 'success', icon ='ri-information-line' , secondTextWithCopyTruncate = true}: ItemProps) => {

  return (
      <div className={"alert alert-dismissible alert-additional fade show alert-"+type+" "+type} role="alert">
          <div className="alert-body">
              <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                      <i className={"fs-16 align-middle "+icon}></i>
                  </div>
                  <div className="flex-grow-1">
                      <p className="mb-0">
                          <span dangerouslySetInnerHTML={{__html: firstText}}/>
                      </p>
                  </div>
              </div>
          </div>
          {secondText &&
              <div className="alert-content">
                  <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                          <i className="mdi mdi-lightbulb-on-outline fs-16 align-middle"/>
                      </div>
                      <div className="flex-grow-1">
                          <p className="mb-0">{secondText}</p>
                      </div>
                  </div>
              </div>
          }
          {secondTextWithCopy &&
              <div className="alert-body border-top border-success border-opacity-25">
                  <div className="d-flex flex-nowrap">
                      <div className="d-flex flex-shrink-0 me-3 align-items-center">
                          <i className="mdi mdi-lightbulb-on-outline fs-16 align-middle"/>
                      </div>
                      <div className="d-flex flex-grow-1 mw-0 align-items-center">
                          { secondTextWithCopyTruncate ?
                              <div className="mb-0 text-truncate">{secondTextWithCopy}</div>
                              :
                              <div className="mb-0">{secondTextWithCopy}</div>
                          }
                      </div>
                      <div className="flex-shrink-0">
                          <CopyToClipboard text={secondTextWithCopy}>
                              <button type="button" className="btn btn-soft-success btn-icon waves-effect waves-light" onClick={showSuccessCopyNotification}><i
                                  className="ri-file-copy-2-line"/></button>
                          </CopyToClipboard>
                      </div>
                  </div>
              </div>
          }
      </div>
  );
};
export default NoticeTip;
