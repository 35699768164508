export enum PeerActivityStatus {
    STATUS_FINAL = "F",
    STATUS_NEW = "N",
    STATUS_ON_PUBLISH = "OP",
    STATUS_ERROR = "E",
    STATUS_READY = 'R',
    STATUS_UNPUBLISHED = 'UP',
    STATUS_PUBLISHING = 'PI'
}

export enum PeerActivityStatusClass {
    STATUS_FINAL = "success",
    STATUS_NEW = "primary",
    STATUS_ON_PUBLISH = "info",
    STATUS_ERROR = "danger",
}