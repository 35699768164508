import React, {useEffect, useState} from "react";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import {useRedux} from "../../../hooks";
import { Card, CardBody, Col, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import {MainFileType} from "../../../redux/Main/File/types";
import FileItem from "./fileItem";

import {CommentType} from "../../../redux/Main/Comment/types";
import CommentForm from "./CommentForm";
import { convertToMomentLocale } from "../../../helpers/functions";


interface ItemProps {
    item: CommentType;
    onEdit: (event:any) => void;
    onRemove: (event:any) => void;
    isReward: boolean;
    isSending: boolean;
    selectedComment?: CommentType
}
const ListItem = ({ item, onEdit, onRemove, isReward, isSending, selectedComment }: ItemProps) => {

    const { t,i18n } = useTranslation(['common']);
    moment.locale(convertToMomentLocale(i18n.language));
    const { dispatch } = useRedux();

    const [isEdit, setEdit] = useState<boolean>(false);
    const [text, setText] = useState<string>(null);

    const showActions = (item?.actions &&
        (
            item.actions?.canEdit
            ||
            item.actions?.canRemove
        )) ? true : false;

    const handlerTicketMessageScoreSend = (event:any, dispatch:any) =>{
        // if ((event !== undefined && event.uuid !==undefined && event.uuid.length>0)){
        //     dispatch(onSendSupportTicketMessageReward(event));
        // }
    }

    useEffect(() => {
        if (isSending === false && isEdit && item?.uuid === selectedComment?.uuid) {
            setEdit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isSending, selectedComment]);

    const handleCloseEdit = () => {
        setEdit(false);
    }

    useEffect(() => {

        if (item?.text !== undefined && item.text !== null && item.text.length>0){

            let _text = item.text.replace(/(\]\([a-z0-9|-]+\))/gm, '</span>');
            _text = _text.replace(/(@\[)/g, '<span class="mentions__mention">');

            setText(_text);

        } else {
            setText(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, item]);

    return (
        <>
        {item?.uuid &&
        <div className={"d-flex mb-2 p-2 pt-3 ps-3 flex-column flex-grow-1"}>
            <div className="d-flex flex-row flex-grow-1">
                <div className="flex-shrink-0">
                    {item?.owner &&
                        <>
                            {item.owner.avatar ?
                                <img src={item.owner.avatar} alt="" className="avatar-xs rounded-circle"/>
                                :
                                <span className="fs-23 text-muted">
                                {item.owner.name ?
                                    item.owner.name[0]
                                    :
                                    '#'
                                }
                            </span>
                            }
                        </>
                    }
                </div>
                <div className="flex-grow-1 ms-3">
                    <h5 className="fs-14 d-inline-flex">
                        <div className="d-flex align-items-center">
                            {item?.owner &&
                                <>
                                    {item.owner.name ?
                                        item.owner.name
                                        :
                                        item.owner.email
                                    }
                                </>
                            }
                            <div className="vr ms-2"></div>

                            <small className="text-muted ms-2">{
                                moment(item.dateTime).add('days', 1).isBefore(moment()) ?
                                    moment(item.dateTime).format('llll')
                                    :
                                    moment(item.dateTime).fromNow()}</small>
                            {/*{isSupportService && !item.isSupportAnswer && item.status === TicketMessageStatus.NEW.status &&*/}
                            {/*    <span className="badge rounded-pill bg-info ms-2">{t('support:newTicketMessageStatus')}</span>*/}
                            {/*}*/}
                            {/*{!isSupportService && item.isSupportAnswer && item.status === TicketMessageStatus.NEW.status &&*/}
                            {/*    <span className="badge rounded-pill bg-info ms-2">{t('support:newTicketMessageStatus')}</span>*/}
                            {/*}*/}
                        </div>
                    </h5>
                    {text && text !== null &&
                    <p className="text-muted" style={{whiteSpace: "pre-wrap"}}>
                        <span dangerouslySetInnerHTML={{__html: text}}/>
                    </p>
                    }

                    {/*{isReward &&*/}
                    {/*    <RewardFormSimple*/}
                    {/*        rewardItem={item}*/}
                    {/*        onSend={handlerTicketMessageScoreSend}*/}
                    {/*        initScore={item.score}*/}
                    {/*    />*/}
                    {/*}*/}

                    {item.media && item.media.length > 0 &&
                        <Row>
                            {(item.media || []).map((item: MainFileType, key: number) => (
                                <Col xxl={4} sm={4} key={'fileItem_' + key}>
                                    <FileItem file={item}/>
                                </Col>
                            ))}
                        </Row>
                    }
                    {/*<Link to="#" className="badge text-muted bg-light"><i className="mdi mdi-reply"></i> Reply</Link>*/}
                </div>
                {
                    showActions &&
                    <div className="flex-shrink-0">
                        <div className="d-flex align-items-center gap-1">
                            <UncontrolledDropdown>
                                <DropdownToggle type="button" className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                                tag="button">
                                    <i className="ri-more-fill"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {item?.actions?.canEdit &&
                                        <li><DropdownItem onClick={() => {
                                            setEdit(!isEdit);
                                        }}>
                                            <i className="ri-pencil-fill text-muted me-2 align-bottom"/>{t('common:edit')}
                                        </DropdownItem></li>
                                    }
                                    {item?.actions?.canRemove &&
                                        <li><DropdownItem onClick={() => {
                                            onRemove(item)
                                        }}>
                                            <i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"/>{t('common:delete')}
                                        </DropdownItem></li>
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </div>
                }
            </div>
            {isEdit && item?.uuid !== undefined &&
                <div className="d-flex flex-row flex-grow-1">
                <CommentForm
                    isSending={isSending && selectedComment?.uuid === item?.uuid}
                    item={item}
                    onSend={onEdit}
                    onClose={handleCloseEdit}
                />
                </div>
            }
        </div>
}
        </>
    );
};

export default ListItem;
