import { APIClient } from "../apiCore";
import * as url from "../urls";
import {m9sGlobals} from "../../config";
import {MainMetaType} from "../../redux/Main/common";
import {createPaginationQuery} from "../../helpers/functions";

const api = new APIClient();

const getPeerActivityTypes = (data:MainMetaType) => {
    const query = createPaginationQuery(data);
  return api.get(m9sGlobals.CURRENT_CABINET+url.GET_CONTENT_PEER_ACTIVITY_TYPES+"?"+query);
};

const sendDataPeerActivityType = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.UPDATE_CONTENT_DATAITEMTYPE, data);
};

const removePeerActivityType = (data: any) => {
    return api.create(m9sGlobals.CURRENT_CABINET+url.REMOVE_CONTENT_DATAITEMTYPE, data);
};

export {
    getPeerActivityTypes,
    sendDataPeerActivityType,
    removePeerActivityType
};
