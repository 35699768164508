import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { PeerActivityIdeaActionTypes } from "./types";
import { peerActivityIdeaApiResponseSuccess, peerActivityIdeaApiResponseError } from "./actions";

import {
  publishActivityPeerActivity as publishActivityPeerActivityApi,
  sendActivityPeerActivity as sendActivityPeerActivityApi,
  getPeerActivities as getPeerActivitiesApi,
  togglePinActivityPeerActivity as togglePinActivityPeerActivityApi,
  unPublishActivityPeerActivity as unPublishActivityPeerActivityApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";

import '../../../i18/config';
import i18n from 'i18next';

function* getPeerActivityIdeas({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(getPeerActivitiesApi,data);
    yield put(peerActivityIdeaApiResponseSuccess(PeerActivityIdeaActionTypes.GET_CONTENT_PEER_ACTIVITIES_IDEAS, response));
  } catch (error: any) {
    yield put(peerActivityIdeaApiResponseError(PeerActivityIdeaActionTypes.GET_CONTENT_PEER_ACTIVITIES_IDEAS, error));
  }
}

export function* watchGetPeerActivityIdeas() {
  yield takeEvery(PeerActivityIdeaActionTypes.GET_CONTENT_PEER_ACTIVITIES_IDEAS, getPeerActivityIdeas);
}


function* peerActivityIdeaSaga() {
  yield all([
      fork(watchGetPeerActivityIdeas),

  ]);
}

export default peerActivityIdeaSaga;
