import React from "react";

//i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getMessengerUserData} from "../../../helpers/functions";
import {MessengerUserType} from "../../../redux/Messenger/Chat/types";

interface BasicDetailsProps {
  chatUserDetails: MessengerUserType;
}
const BasicDetails = ({ chatUserDetails }: BasicDetailsProps) => {

    let {fullName, avatar, handlerImage} = getMessengerUserData(chatUserDetails);


  const { t } = useTranslation(['common']);

  return (
    <div className="pb-2">
      <h5 className="font-size-11 text-uppercase mb-2">{t('common:info')} :</h5>
      <div>
        <div className="d-flex align-items-end">
          <div className="flex-grow-1">
            <p className="text-muted font-size-14 mb-1">{t('common:name')}</p>
          </div>
          <div className="flex-shrink-0">
            <button type="button" className="btn btn-sm btn-soft-primary">
                {t('common:edit')}
            </button>
          </div>
        </div>
        <h5 className="font-size-14">{fullName}</h5>
      </div>

      <div className="mt-4">
        <p className="text-muted font-size-14 mb-1">Email</p>
        <h5 className="font-size-14">
          {chatUserDetails?.platformUser && chatUserDetails.platformUser.email ? chatUserDetails.platformUser.email : "-"}
        </h5>
      </div>

      {/*<div className="mt-4">*/}
      {/*  <p className="text-muted font-size-14 mb-1">Location</p>*/}
      {/*  <h5 className="font-size-14 mb-0">*/}
      {/*    {chatUserDetails.location ? chatUserDetails.location : "-"}*/}
      {/*  </h5>*/}
      {/*</div>*/}
    </div>
  );
};

export default BasicDetails;
