import React, {useState} from "react";
import {PeerActivityType} from "../../../redux/Content/PeerActivity/types";
import DetailViewItem from "./DetailViewItem";

interface ItemProps {
    item: PeerActivityType;
}

const PopupItem = ({ item }: ItemProps) => {

    return (
            <div style={{maxWidth: '300px'}}>
                <DetailViewItem item={item}/>
            </div>
    );
};

export default PopupItem;
