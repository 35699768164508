import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentPeerChannelActionTypes } from "./types";
import { contentPeerChannelApiResponseSuccess, contentPeerChannelApiResponseError } from "./actions";

import {
  getContentPeerChannels as getContentPeerChannelsApi,
  sendDataContentPeerChannel,
  removeContentPeerChannel,
  connectContentPeerChannel as connectContentPeerChannelApi
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getContentPeerChannels() {
  try {
    const response: Promise<any> = yield call(getContentPeerChannelsApi);
    yield put(contentPeerChannelApiResponseSuccess(ContentPeerChannelActionTypes.GET_CONTENT_PEER_CHANNELS, response));
  } catch (error: any) {
    yield put(contentPeerChannelApiResponseError(ContentPeerChannelActionTypes.GET_CONTENT_PEER_CHANNELS, error));
  }
}

function* onSendDataContentPeerChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(sendDataContentPeerChannel, data);
    yield put(
        contentPeerChannelApiResponseSuccess(ContentPeerChannelActionTypes.UPDATE_CONTENT_PEER_CHANNEL, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentPeerChannelApiResponseError(ContentPeerChannelActionTypes.UPDATE_CONTENT_PEER_CHANNEL, error));
    yield call(showErrorNotification, error);
  }
}
function* onRemoveContentPeerChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(removeContentPeerChannel, {uuid: data.data.uuid});
    yield put(
        contentPeerChannelApiResponseSuccess(ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentPeerChannelApiResponseError(ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL, error));
    yield call(showErrorNotification, error);
  }
}

function* onConnectContentPeerChannel({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(connectContentPeerChannelApi, {uuid: data.data.uuid});
    yield put(
        contentPeerChannelApiResponseSuccess(ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield put(contentPeerChannelApiResponseError(ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL, error));
    yield call(showErrorNotification, error);
  }
}

export function* watchGetContentPeerChannels() {
  yield takeEvery(ContentPeerChannelActionTypes.GET_CONTENT_PEER_CHANNELS, getContentPeerChannels);
}
export function* watchOnSendDataContentPeerChannel() {
  yield takeEvery(ContentPeerChannelActionTypes.UPDATE_CONTENT_PEER_CHANNEL, onSendDataContentPeerChannel);
}
export function* watchOnRemoveContentPeerChannel() {
  yield takeEvery(ContentPeerChannelActionTypes.REMOVE_CONTENT_PEER_CHANNEL, onRemoveContentPeerChannel);
}
export function* watchOnConnectContentPeerChannel() {
  yield takeEvery(ContentPeerChannelActionTypes.CONNECT_CONTENT_PEER_CHANNEL, onConnectContentPeerChannel);
}
function* contentPeerChannelSaga() {
  yield all([
    fork(watchGetContentPeerChannels),
    fork(watchOnSendDataContentPeerChannel),
    fork(watchOnRemoveContentPeerChannel),
    fork(watchOnConnectContentPeerChannel)
  ]);
}

export default contentPeerChannelSaga;
