import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import {useRedux} from "../../../hooks";
import {Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody
} from 'reactstrap';
import InputText from "../../../components/InputText";
import InputSwitch from "../../../components/InputSwitch";
import InputSelect from "../../../components/InputSelect";
import InputSelectAjax from "../../../components/InputSelectAjax";

import SimpleBar from 'simplebar-react';

import '../../../i18/config';
import {useTranslation} from "react-i18next";

import {
    Formik,
    Form,
    Field
} from 'formik';
import {
    ContentEditStatCounterValidatorSchema,
    ContentNewStatCounterValidatorSchema,
} from "./validator";
import {ContentStatCounterType} from "../../../redux/Content/StatCounter/types";
import {
    getContentStatCounters,
    hideRightModalContentStatCounter,
    onSendDataContentStatCounter
} from "../../../redux/Content/StatCounter/actions";
import {
    ContentConnectorStat,
    ContentConnectorType
} from "../../../redux/Content/Connector/types";
import {onGetContentConnectorSearchChannel, selectContentConnector} from "../../../redux/Content/Connector/actions";
import {showErrorNotification} from "../../../helpers/notifications";
import classnames from "classnames";

import ListButtonItem from "../Connector/ListButtonItem";
import UpgradeFare from "../../../components/UpgradeFare";
import {searchChannelFromRemote} from "../../../api";
import ButtonSpinner from "../../../components/ButtonSpinner";

const RightModal = () => {
    const { t } = useTranslation(['common']);
    const { dispatch, useAppSelector } = useRedux();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [connector, setConnector] = useState<ContentConnectorType>(null);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([]);
    const [isSearchChannel, setIsSearchChannel] = useState<boolean>(false);

    const {
        contentStatCounters,
        contentConnectors,
        contentProjects,
        isSending
    } = useAppSelector(state => ({
        contentStatCounters: state.ContentStatCounter,
        contentConnectors: state.ContentConnector,
        contentProjects: state.ContentProjects,
        isSending: state.ContentStatCounter.isSending
    }));

    const uuid = contentStatCounters.selectedItem.uuid || null;

    const [formValues, setFormValues] = useState<ContentStatCounterType>(contentStatCounters.selectedItem);

    useEffect(() => {

        setIsSearchChannel(contentConnectors.isSearchChannels)

    }, [contentConnectors?.isSearchChannels])


    useEffect(() => {

        setFormValues(contentStatCounters.selectedItem);

        if (contentStatCounters.selectedItem.connector){
            setConnector(contentStatCounters.selectedItem.connector);
            setActiveTab(2);
        } else if (
            (contentConnectors.selectedItem !== undefined)
            &&
            (contentConnectors.selectedItem.uuid !== undefined)
        ){
            setConnector(contentConnectors.selectedItem);
            setActiveTab(2);
        } else {
            setConnector(null);
            setActiveTab(1);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, dispatch]);

    useEffect(() => {
        if (
            (contentStatCounters.selectedItem.project === undefined)
            &&
            (contentProjects.items !== undefined && contentProjects.items.length>0)
        ){
            contentStatCounters.selectedItem.project=contentProjects.items[0];
        }

        setFormValues(contentStatCounters.selectedItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentProjects]);


    const handleClose = () => dispatch(hideRightModalContentStatCounter());

    const onSelectConnector = (item:any) : void  => {
        dispatch(selectContentConnector({data : item}));
        setConnector(item);

        setActiveTab(2);
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {

            if ((tab===1) && (uuid!==undefined)){
                showErrorNotification({message: t('statCounter:changeStatCounterService')});
                return
            }

            if ((tab===2) && (connector == null)) {
                showErrorNotification({message: t('connector:chooseService')});
                return
            }

            let modifiedSteps = [...passedSteps, tab];

            setActiveTab(tab);
            setPassedSteps(modifiedSteps);

        }
    }

    const handleSearchChannel = (event: any) => {
        if (event.length>0) {
            dispatch(onGetContentConnectorSearchChannel({connector: connector, inputValue: event}));
        }
    }


    const onDataSubmit = (formData:any) => {
        let data: any = {};

        if (uuid == null) {
            if (formData.name){
                if (formData.name.value !== undefined) {
                    data["peer"] = formData.name.value
                    data["peerName"] = formData.name.label
                }
            }

            data["connector"]= {uuid: connector.uuid};

        } else {
            data["uuid"] = uuid;
        }

        if (formData.project){
            if (formData.project.uuid !== undefined) {
                data["project"] = {uuid: formData.project.uuid};
            } else if (formData.project.value !== undefined) {
                data["project"] = {uuid: formData.project.value}
            }
        }

        data["enabled"] = formData.enabled;

        let params: any = {
            uuid: data.uuid && data.uuid,
            enabled: data.enabled ? data.enabled : false,
            peer: data.peer && data.peer,
            peerName: data.peerName && data.peerName
        };

        if (data.connector && data.connector.uuid){
            params.connector = {uuid : data.connector.uuid};
        }
        if (data.project && data.project.uuid){
            params.project = {uuid : data.project.uuid};
        }

        dispatch(onSendDataContentStatCounter(params));

        setTimeout(() => {
            dispatch(getContentStatCounters());
        }, 1000);

    };

    return (
        <>
            <Offcanvas
                direction="end"
                isOpen={contentStatCounters.rightModalShow}
                id="statCounterEdit"
                toggle={handleClose}
            >

                <Formik
                    enableReinitialize={true}
                    initialValues={formValues}
                    validationSchema={ uuid == null ? ContentNewStatCounterValidatorSchema : ContentEditStatCounterValidatorSchema}
                    onSubmit={(values, actions) => {
                        onDataSubmit(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ errors, touched, setFieldValue, getFieldProps, isSubmitting, isValidating }) => (
                    <Form noValidate={true} className="customValidation">
                        <OffcanvasHeader className="bg-light" toggle={handleClose}>
                            {t('statCounter:'+contentStatCounters.modalEditTitle)}
                        </OffcanvasHeader>

                        <OffcanvasBody className="p-0 overflow-hidden">
                            <SimpleBar style={{height: 'calc(100vh - 140px)'}}>
                                <div className="p-4">

                            <Row className="vertical-navs-step">
                                <Nav
                                    className="flex-column custom-nav nav-pills mb-3"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={
                                                (classnames({
                                                    active: activeTab === 1,
                                                    done: (activeTab > 1)
                                                }))
                                            }
                                            onClick={() => {
                                                toggleTab(1);
                                            }}
                                        >
                                            <div className="d-flex flex-row flex-nowrap flex-grow-1">
                              <span className="step-title me-2 text-muted d-flex align-items-center flex-shrink-1">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 1
                              </span>

                                                <span className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end">
                {
                    (connector != null && connector.uuid && connector.uuid.length > 0)
                        ?
                        <>
                            <div className="avatar-xxs position-relative">
                                <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                    <img src={connector.handler.icon} className="img-fluid p-1 rounded-circle"
                                    alt=""
                                    />
                                </div>
                            </div>
                            <span className="step-title d-flex align-items-center">{connector.name}</span>
                        </>
                        :
                        t('connector:chooseService')
                }
                 </span>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={1}>
                                        <Row>
                                            {uuid == null &&
                                            (contentConnectors.items || []).map((item: ContentConnectorType, key: number) => (

                                                    item.isConnected &&
                                                    (Object.values(ContentConnectorStat) as string[]).includes(item.handler.type) &&

                                                        <ListButtonItem
                                                            item={item}
                                                            key={'contentConnectorHandler_' + item.uuid}
                                                            onClick={onSelectConnector}
                                                            isSelected={connector != null && connector.uuid === item.uuid}
                                                        />

                                            ))
                                            }
                                        </Row>
                                    </TabPane>
                                </TabContent>



                                <Nav
                                    className="flex-column custom-nav nav-pills mb-3"
                                >
                                    <NavItem>
                                        <NavLink
                                            href="#"
                                            className={
                                                (classnames({
                                                    active: activeTab === 2,
                                                    done: (activeTab > 2 )
                                                }))
                                            }
                                            onClick={() => {
                                                toggleTab(2);
                                            }}
                                        >
                                            <div className="d-flex flex-row flex-nowrap flex-grow-1">
                              <span className="tep-title me-2 text-muted d-flex align-items-center flex-shrink-1">
                                <i className="ri-close-circle-fill step-icon me-2"/>
                                  {t('common:step')} 2
                              </span>


                                            <span className="d-flex flex-row flex-nowrap flex-grow-1 justify-content-end">
                {
                    (contentStatCounters.selectedItem != null && contentStatCounters.selectedItem.uuid && contentStatCounters.selectedItem.uuid.length > 0)
                        ?
                        <>
                            <div className="avatar-xxs position-relative">
                                <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                    { contentStatCounters.selectedItem.avatar ?
                                    <img src={contentStatCounters.selectedItem.avatar} className="img-fluid p-1 rounded-circle" alt=""/> : contentStatCounters.selectedItem.name[0]
                                    }
                                </div>
                            </div>
                            <span className="step-title d-flex align-items-center">{contentStatCounters.selectedItem.name}</span>
                        </>
                        :
                        t('statCounter:chooseStatCounter')
                }
                                            </span></div>


                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId={2}>

                                        {
                                            connector && connector.handler && connector.handler.isFareRestriction === true && uuid == null
                                                ?
                                                <UpgradeFare/>
                                                :
                                                <>
                                                    <Row>
                                                        <div className="mb-3 d-flex flex-column me-5">
                                                            <Field
                                                                id="form-enabled-input"
                                                                placeholder={t('common:active')}
                                                                name="enabled"
                                                                component={InputSwitch}
                                                                value = {getFieldProps('enabled').value}
                                                                onChange={(enabled:boolean) => {
                                                                    setFieldValue('enabled', !getFieldProps('enabled').value);
                                                                }}
                                                                required={false}
                                                            />
                                                        </div>

                                                    </Row>

                                                    <Row>
                                                        <div className={ "mb-3"+ (touched.project ? " was-validated" : '')}>
                                                            <Label className="form-label" htmlFor="form-title-input">{t('project:project')}</Label>
                                                            <Field
                                                                id="form-project-select"
                                                                name="project"
                                                                placeholder={t('project:chooseProject')}
                                                                component={InputSelect}
                                                                value={getFieldProps('project').value}
                                                                onChange={(name: string) => {
                                                                    setFieldValue('project', name);
                                                                }}
                                                                items={contentProjects.items || []}
                                                                className={errors.project && touched.project && 'form-control is-invalid'}
                                                            />
                                                            {errors.project && touched.project ? (
                                                                <div className="invalid-feedback">
                                                                    {t('validator:'+errors.project)}
                                                                </div>
                                                            ) : touched.project && !errors.project ?
                                                                <div className="valid-feedback">
                                                                    {t('common:looksGood')}
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                    </Row>

                                                    {uuid == null &&

                                                    (connector && connector.handler.canChannelSearch ?

                                                        <Row>
                                                            <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                                                <Label className="form-label" htmlFor="form-title-input">{t('channel:channel')}</Label>

                                                                <Field
                                                                    id="form-channel-input"
                                                                    name="name"
                                                                    placeholder={t('channel:chooseChannel')}
                                                                    component={InputSelect}
                                                                    value={getFieldProps('name').value}
                                                                    onChange={(value: string) => {
                                                                        setFieldValue('name', value);
                                                                    }}
                                                                    onKeyPress = {handleSearchChannel}
                                                                    isLoading={isSearchChannel}
                                                                    items={contentConnectors.searchChannels || []}
                                                                    className={errors.name && touched.name && 'form-control is-invalid'}
                                                                />



                                                                {/*<Field*/}
                                                                {/*    id="form-channel-input"*/}
                                                                {/*    name="name"*/}
                                                                {/*    placeholder={t('channel:chooseChannel')}*/}
                                                                {/*    component={InputSelectAjax}*/}
                                                                {/*    value={getFieldProps('name').value}*/}
                                                                {/*    onChange={(value: string) => {*/}
                                                                {/*        setFieldValue('name', value);*/}
                                                                {/*    }}*/}
                                                                {/*    sendParams={{connector: connector}}*/}
                                                                {/*    onAjaxSearch={searchChannelFromRemote}*/}
                                                                {/*    required={false}*/}
                                                                {/*/>*/}
                                                                {errors.name && touched.name ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.name)}
                                                                    </div>
                                                                ) : touched.name && !errors.name ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Row>

                                                        :

                                                        <Row>
                                                            <div className={ "mb-3"+ (touched.name ? " was-validated" : '')}>
                                                                <Label className="form-label" htmlFor="form-title-input">{t('channel:channel')}</Label>
                                                                <Field
                                                                    id="form-channel-input"
                                                                    name="name"
                                                                    placeholder={t('channel:enterChannelName')}
                                                                    component={InputText}
                                                                    value={getFieldProps('name').value}
                                                                    onChange={(value: string) => {
                                                                        setFieldValue('name', value);
                                                                    }}
                                                                    className={errors.name && touched.name && 'form-control is-invalid'}
                                                                />
                                                                {errors.name && touched.name ? (
                                                                    <div className="invalid-feedback">
                                                                        {t('validator:'+errors.name)}
                                                                    </div>
                                                                ) : touched.name && !errors.name ?
                                                                    <div className="valid-feedback">
                                                                        {t('common:looksGood')}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </Row>
                                                    )}

                                                </>
                                        }
                                    </TabPane>
                                </TabContent>
                            </Row>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>

                        <div className="offcanvas-footer border-top p-3 text-center position-sticky bottom-0">
                            <div className="d-flex flex-row flex-nowrap gap-2">
                                <Button variant="light" onClick={handleClose} className="w-100">
                                    {t('common:close')}
                                </Button>
                                <Button variant="primary"
                                        type="submit"
                                        disabled={isSending}
                                        className="btn btn-primary w-100 chat-send waves-effect waves-light"
                                >
                                    {t('common:save')}
                                    {isSending &&
                                        <ButtonSpinner/>
                                    }
                                </Button>
                            </div>
                        </div>
                    </Form>
                        )}
                </Formik>

            </Offcanvas>
        </>
    );
}

export default RightModal;