import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

import '../../i18/config';
import {useTranslation} from "react-i18next";
import {StatTileType} from "../../redux/Stat/DashboardSale/types";
import {OrderStatusTypes, SaleOrderStatusType} from "../../redux/Sale/OrderStatus/types";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {capitalizeFirstLetter} from "../../helpers/functions";


interface ItemProps {
    items: [] | Array<StatTileType>;
    statuses: Array<SaleOrderStatusType>
}

const OrdersStatusChart = ({ items, statuses }: ItemProps) => {

    const { t } = useTranslation();
    const [shownStatus, setShownStatus] = useState<string>(OrderStatusTypes.ALL);
    const [shownItems, setShownItems] = useState<StatTileType[]>([]);
    const [chartLabels, setChartLabels] = useState<any[]>([]);
    const [series, setSeries] = useState<any[]>([]);

    useEffect(()=>{

        const _shownItems=(items || []).filter((item:StatTileType)=>{return item.type===shownStatus});
        setShownItems([..._shownItems]);

    },[shownStatus, items]);

    const handleClickShownStatus = (status:string) => {
        setShownStatus(status);
    }

    useEffect(()=>{

        const _chartLabels=[];
        const _chartSeries=[];

        (shownItems || []).map((item:StatTileType)=>{

            const status = (statuses || []).filter((statusItem:SaleOrderStatusType)=>{
                return statusItem.uuid === item.label
            }).shift();

            if (status?.uuid) {
                _chartLabels.push(status.name);
                _chartSeries.push(item.value.last || 0);
            }

            return item;
        });

        setChartLabels([..._chartLabels]);
        setSeries([..._chartSeries]);

    },[shownItems, statuses])

    const options:ApexOptions =
        {
            labels:chartLabels,
            chart: {
                type: "donut",
            },
            plotOptions: {
                pie: {
                    offsetX: 0,
                    offsetY: 0,
                    donut: {
                        size: "70%",
                        labels: {
                            show: false,
                        }
                    },
                },
            },
            dataLabels: {
                enabled: true,
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center'
            },
            stroke: {
                lineCap: "round",
                width: 0
            },
//            colors: chartColors,
        };

    return(
        <Card className="card-height-100">
            <CardHeader className="d-flex align-items-center">
                <h4 className="card-title flex-grow-1 mb-0">{t('stat:orderStatuses')}</h4>
                <UncontrolledDropdown className="card-header-dropdown">
                    <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                        <span className="text-muted">{t('orderStatus:orderStatusType'+capitalizeFirstLetter(shownStatus))}<i className="mdi mdi-chevron-down ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        {(Object.keys(OrderStatusTypes).map((item)=>{
                            return(
                                <DropdownItem onClick={()=>{handleClickShownStatus(OrderStatusTypes[item])}} key={"ordersStatusCountriesFilter_"+OrderStatusTypes[item]}>{t('orderStatus:orderStatusType'+capitalizeFirstLetter(OrderStatusTypes[item]))}</DropdownItem>
                            )
                        }))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            <CardBody>

                <div id="order-status" className="apex-charts" dir="ltr">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="donut"
                        height="230"
                        className="apex-charts"
                    />
                </div>


            </CardBody>
        </Card>
    );
}

export default OrdersStatusChart;