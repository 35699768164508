import React from 'react';
import * as Yup from 'yup';

export const SaleCurrencyValidatorSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
    code: Yup.string()
        .min(3, 'tooShort')
        .max(3, 'tooLong')
        .required('required'),
    currencyFormat: Yup
        .string()
        .required("required")
        .matches(
            /#/,
            "currencyFormat"
        ),
    order: Yup.string()
        .matches(/^[0-9]+$/, "digitsOnly")
        .min(2, 'tooShort')
        .max(50, 'tooLong')
        .required('required'),
});