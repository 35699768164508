import { takeEvery, fork, put, all, call } from "typed-redux-saga";

// Login Redux States
import { ContentPeerUserActionTypes } from "./types";
import { contentPeerUserApiResponseSuccess, contentPeerUserApiResponseError } from "./actions";

import {
  getContentPeerUsers as getContentPeerUsersApi,
  getContentPeerChannelMembers as getContentPeerChannelMembersApi,
  unsubscribeContentPeerChannelMember
} from "../../../api";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../../helpers/notifications";


function* getContentPeerUsers() {
  try {
    const response: Promise<any> = yield call(getContentPeerUsersApi);
    yield put(contentPeerUserApiResponseSuccess(ContentPeerUserActionTypes.GET_CONTENT_PEER_USERS, response));
  } catch (error: any) {
    yield put(contentPeerUserApiResponseError(ContentPeerUserActionTypes.GET_CONTENT_PEER_USERS, error));
  }
}

function* getContentPeerChannelMembers(data:any) {
  try {
    const response: Promise<any> = yield call(getContentPeerChannelMembersApi, data.payload);
    yield put(contentPeerUserApiResponseSuccess(ContentPeerUserActionTypes.GET_CONTENT_PEER_CHANNEL_MEMBERS, response));
  } catch (error: any) {
    yield put(contentPeerUserApiResponseError(ContentPeerUserActionTypes.GET_CONTENT_PEER_CHANNEL_MEMBERS, error));
  }
}

function* onSendUnsubscribeChannelMember(data:any) {
  try {
    const response: Promise<any> = yield call(unsubscribeContentPeerChannelMember, data.payload);
    yield put(contentPeerUserApiResponseSuccess(ContentPeerUserActionTypes.UNSUBSCRIBE_CONTENT_CHANNEL_MEMBER, response));
  } catch (error: any) {
    yield put(contentPeerUserApiResponseError(ContentPeerUserActionTypes.UNSUBSCRIBE_CONTENT_CHANNEL_MEMBER, error));
  }
}

export function* watchGetContentPeerUsers() {
  yield takeEvery(ContentPeerUserActionTypes.GET_CONTENT_PEER_USERS, getContentPeerUsers);
}
export function* watchGetContentPeerChannelMembers() {
  yield takeEvery(ContentPeerUserActionTypes.GET_CONTENT_PEER_CHANNEL_MEMBERS, getContentPeerChannelMembers);
}
export function* watchOnSendUnsubscribeChannelMember() {
  yield takeEvery(ContentPeerUserActionTypes.UNSUBSCRIBE_CONTENT_CHANNEL_MEMBER, onSendUnsubscribeChannelMember);
}

function* contentPeerUserSaga() {
  yield all([
    fork(watchGetContentPeerUsers),
    fork(watchGetContentPeerChannelMembers),
    fork(watchOnSendUnsubscribeChannelMember),
  ]);
}

export default contentPeerUserSaga;
