import React, {useEffect, useRef, useState} from "react";
import Flatpickr from "react-flatpickr";
import {Button} from "reactstrap";
import FlatpickrLanguages from "flatpickr/dist/l10n";
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/ka';
import 'moment/locale/uk';
import '../i18/config';
import {useTranslation} from "react-i18next";
import {convertToMomentLocale, isNumeric} from "../helpers/functions";

interface dateOptions{
    altInput: boolean;
    altFormat: string;
    dateFormat?: string;
    enableTime: boolean;
    defaultDate?: any;
    minDate?: string;
    locale? : any;
}

interface InputTextProps {
  value: null | number | string;
  id: string;
  placeholder: null | string;
  onChange: (value: number) => void;
  required: null | boolean;
  maxLength: null | number;
  className: null | string;
  minDate: null | any;
}

const getDefaultOptions = (translation: {language: string}, minDate: Date) => ({
    altInput: true,
    altFormat: "F j, Y H:i",
    dateFormat: "U",
    enableTime: true,
    minDate,
    locale: {...FlatpickrLanguages[translation.language]}
});

const InputDatePicker = ({ id, placeholder, value, onChange, required, maxLength, className, minDate}: InputTextProps) => {

    const { i18n } = useTranslation();
    moment.locale(convertToMomentLocale(i18n.language));
    const fp = useRef(null);

    const defaultOptions = getDefaultOptions(i18n, minDate);

    const [currentValue, setCurrentValue] = useState<any>(null);

    const openCalendar = () => {
        if (!fp?.current?.flatpickr) return;
        fp.current.flatpickr.open();
    }

    const handleDateChange = (selectedDates, dateStr) => {
        if (dateStr > 0) {
            onChange(dateStr);
        } else {
            onChange(null);
        }
    };

    useEffect(() => {

        if (value !== null) {
            if (isNumeric(value)) {
                setCurrentValue(parseInt(value.toString()) * 1000);
            } else {
                setCurrentValue(moment(value).format('X'));
            }
        }
    }, [value]);

  return (
      <>
          <div className="input-group">
              <Flatpickr
                  id={ id }
                  ref={fp}
                  className={className || "form-control"}
                  options={defaultOptions}
                  value={currentValue}
                  onChange={handleDateChange}
              >
              </Flatpickr>
              <Button data-toggle className="input-group-text bg-primary border-primary text-white z-index-auto"  onClick={openCalendar}>
                    <i className="ri-calendar-2-line"/>
              </Button>
          </div>
      </>
  );
};
export default InputDatePicker;
