import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Badge } from "reactstrap";

// hooks
import { useRedux } from "../../../hooks";

// actions
import {
  getChannelDetails,
  getChatUserDetails,
  getChatUserConversations,
  changeSelectedChat,
} from "../../../redux/actions";

//i18
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import {getMessengerUserData} from "../../../helpers/functions";
import {MainUserType} from "../../../redux/Main/User/types";

interface GroupProps {
  member: any;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}

const Member = ({ member, onSelectChat }: GroupProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const {
    mainUser,
  } = useAppSelector(state => ({
    mainUser: state.MainUser,
  }));

  let {fullName, avatar, handlerImage} = getMessengerUserData(member);

  const shortName = fullName ? `${fullName.charAt(0)}` : '-';

  if (member.type === 'user') {

    const platformUsers = (mainUser.items || []).filter((item:MainUserType)=>{
        return item.uuid === member.uuid;
    });

    if (platformUsers.length>0){
        const user = platformUsers.shift();

        if(user?.name && user.name.length>0) {
          fullName = user.name;
        }

        if(user?.avatar && user.avatar.length>0) {
          avatar = user.avatar;
        }
    }

  }

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));

  return (
    <li>
      <Link to="#" onClick={() => onSelectChat(member.uuid)}>
        <div className="d-flex align-items-center">
          <div className="flex-shrink-0 avatar-xs me-2">
            {avatar ? (
              <div
                className={classnames(
                  "chat-user-img",
                  "align-self-center",
                  "me-2",
                  "ms-0"
                )}
              >
                {handlerImage ? (
                    <div className="handlerIcon">
                      <img src={handlerImage} alt=""/>
                    </div>
                ) : ''}
                <img
                  src={avatar}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
              </div>
            ) : (
              <span
                className={classnames(
                  "avatar-title",
                  "rounded-circle",
                  "text-uppercase",
                  "text-white",
                  colors[color]
                )}
              >
                                {handlerImage ? (
                                    <div className="handlerIcon">
                                      <img src={handlerImage} alt=""/>
                                    </div>
                                ) : ''}
                {shortName}
              </span>
            )}
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-truncate mb-0">{fullName}</p>
          </div>
          {member.isAdmin && (
            <div className="ms-auto">
              <Badge className="badge badge-soft-primary rounded p-1">
                Admin
              </Badge>
            </div>
          )}
        </div>
      </Link>
    </li>
  );
};
interface GroupsProps {
  chatUserDetails: any;
  onSelectChat: (id: number | string, isChannel?: boolean) => void;
}
const Members = ({ chatUserDetails, onSelectChat }: GroupsProps) => {
  const groups =
    chatUserDetails.members &&
    chatUserDetails.members.length &&
    chatUserDetails.members;

  const { t } = useTranslation(['common']);

  return (
    <div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h5 className="fs-15 mb-3">{t('common:members')}</h5>
        </div>
      </div>

      {groups ? (
        <ul className="list-unstyled chat-list mx-n4 membersList">
          {(groups || []).map((member: any, key: number) => (
            <Member member={member} key={key} onSelectChat={onSelectChat} />
          ))}
        </ul>
      ) : (
        <p>{t('common:noGroups')}</p>
      )}
    </div>
  );
};

export default Members;
