// types
import {SaleDeliverySystemActionTypes, SaleDeliverySystemState, DeliverySystemType} from "./types";

export const NewSaleDeliverySystemItem:DeliverySystemType = {
  enabled: true,
  name: '',
  order: 500
}

export const INIT_STATE_SALE_DELIVERY_SYSTEM: SaleDeliverySystemState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleDeliverySystemItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false
};

const SaleDeliverySystem = (state = INIT_STATE_SALE_DELIVERY_SYSTEM, action: any) => {
  switch (action.type) {
    case SaleDeliverySystemActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleDeliverySystemActionTypes.GET_SALE_DELIVERY_SYSTEMS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleDeliverySystemActionTypes.UPDATE_SALE_DELIVERY_SYSTEM:
          return {
            ...state,
            selectedItem: NewSaleDeliverySystemItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM:
          return {
            ...state,
            selectedItem: NewSaleDeliverySystemItem,
            removeModalShow: false,
            isNeedReload: true
          };
        default:
          return { ...state };
      }

    case SaleDeliverySystemActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleDeliverySystemActionTypes.GET_SALE_DELIVERY_SYSTEMS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SaleDeliverySystemActionTypes.UPDATE_SALE_DELIVERY_SYSTEM:
          return {
            ...state,
            isSending: false
          };
        case SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM:
          return {
            ...state,
            removeModalShow: false
          };
        default:
          return { ...state };
      }
    case SaleDeliverySystemActionTypes.EDIT_SALE_DELIVERY_SYSTEM:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleDeliverySystemActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleDeliverySystemItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleDeliverySystemActionTypes.REMOVE_SALE_DELIVERY_SYSTEM_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleDeliverySystemItem,
        removeModalShow: false
      }
    case SaleDeliverySystemActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleDeliverySystemActionTypes.UPDATE_SALE_DELIVERY_SYSTEM:
      return {
        ...state,
        isSending: true
      };
    case SaleDeliverySystemActionTypes.GET_SALE_DELIVERY_SYSTEMS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
};

export default SaleDeliverySystem;
