import React, { useEffect } from 'react';
import {useRedux} from "../hooks";
import withRouter from "../components/WithRouter";
import {getApprovedDeviceUsers} from "../helpers/functions";
import {getDeviceUsers} from "../redux/Main/Login/actions";

const NonAuthLayout = ({ children }) => {

    const { dispatch, useAppSelector } = useRedux();

    const {
        layoutModeType,
    } = useAppSelector(state => ({
        layoutModeType: state.Layout.layoutModeType,
    }));

    useEffect(() => {

        const approvedDeviceUsers = getApprovedDeviceUsers();
        if ((approvedDeviceUsers || []).length>0){
            dispatch(getDeviceUsers());
        }

    },[dispatch]);

    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-layout-mode", "dark");
        } else {
            document.body.setAttribute("data-layout-mode", "light");
        }
        return () => {
            document.body.removeAttribute("data-layout-mode")
        }
    }, [layoutModeType]);

    return (
        <div>
            {children}
        </div>
    );
};

export default withRouter(NonAuthLayout);