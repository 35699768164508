import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import RightModal from "./modalForm";

// hooks
import { useRedux } from "../../../hooks";

import {
    editDataSaleIntegration,
    getContentPeerChannels,
    getSaleCurrencies,
    getSaleDeliverySystems,
    getSaleIntegrationHandlers,
    getSaleIntegrations,
    getSalePaymentSystems,
    getSalePriceTypes,
    getSaleStores, onInvalidateCacheSaleIntegration,
    onRemoveSaleIntegration,
    removeSaleIntegrationModalHide,
    removeSaleIntegrationModalShow,
    showRightModalSaleIntegration
} from "../../../redux/actions";

import TableList from "../../../components/TableList";
import SaleIntegrationColumns from "./tableColumns";
import {SaleIntegrationType} from "../../../redux/Sale/Integration/types";
import {EntityActionsHandlersType} from "../../../redux/types";
import RemoveModal from "../../../components/RemoveForm";

import '../../../i18/config';
import {useTranslation} from "react-i18next";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import Loader from "../../../components/Loader";
import {HistoryHashType, PAGINATION_DEFAULT_ITEMS_PER_PAGE} from "../../../data/settings";
import {getHistoryHash, isNumeric, setHistoryHash as setHistoryHashFunc} from "../../../helpers/functions";
import {SalePlanParamValueType, SalePlanType} from "../../../redux/Sale/Plan/types";
import {LicenseProductParam} from "../../../redux/Sale/License/types";
import UpgradeFarePage from "../../../components/UpgradeFarePage";
import SaleIntegrationHandler from "../../../redux/Sale/IntegrationHandler/reducer";
import {MainMetaType} from "../../../redux/Main/common";
import MainListFilter from "../../../components/MainListFilter";

const SaleIntegrationIndex = () => {
    const { dispatch, useAppSelector } = useRedux();

    const { t } = useTranslation(['common']);

    const componentType = 'saleIntegrationTable';

    const [historyHash, setHistoryHash] = useState<HistoryHashType>({type:componentType});
    const [isFetched, setFetched] = useState<boolean>(false);
    const [upgradeFare, setUpgradeFare] = useState<boolean>(false);
    const [currentPlan, setCurrentPlan] = useState<SalePlanType>(null);
    const [perPage, setPerPage] = useState(PAGINATION_DEFAULT_ITEMS_PER_PAGE);
    const [searchPhrase, setSearchPhrase] = useState<string>('');

    const updateHistoryHash = () => {
        let browserHistoryHash = getHistoryHash();

        if (browserHistoryHash !== null && browserHistoryHash.type !== null) {
            if (browserHistoryHash.type === componentType){
                setHistoryHash(browserHistoryHash)
            }
        }
    }

    const loadItems = () => {

        let mainMeta:MainMetaType = {
            page: historyHash.page || 1,
            maxResults: historyHash.maxResults ? historyHash.maxResults : perPage,
            sortField: historyHash.sortField && historyHash.sortField,
            sortDirection: historyHash.sortDirection && historyHash.sortDirection,
            searchPhrase: historyHash.searchPhrase && historyHash.searchPhrase.length>0 && historyHash.searchPhrase
        }

        dispatch(getSaleIntegrations(mainMeta));
    }

    useEffect(() => {
        if (historyHash.searchPhrase !== undefined && historyHash.searchPhrase.length>0 && searchPhrase !== historyHash.searchPhrase){
            setSearchPhrase(historyHash.searchPhrase);
        }

        loadItems();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historyHash]);

    useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        updateHistoryHash();
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hashChangeHandler = React.useCallback(() => {
        updateHistoryHash();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        saleIntegration,
        saleIntegrationHandler,
        contentPeerChannels,
        saleStore,
        saleCurrency,
        salePriceType,
        salePaymentSystem,
        saleDeliverySystem,
        salePlan
    } = useAppSelector(state => ({
        saleIntegration: state.SaleIntegration,
        saleIntegrationHandler: state.SaleIntegrationHandler,
        contentPeerChannels: state.ContentPeerChannel,
        saleStore: state.SaleStore,
        saleCurrency: state.SaleCurrency,
        salePriceType: state.SalePriceType,
        salePaymentSystem: state.SalePaymentSystem,
        saleDeliverySystem: state.SaleDeliverySystem,
        salePlan: state.SalePlan
    }));

    const checkIsAllFetched = () => {
        if (
            saleStore.isFetched
            &&
            saleCurrency.isFetched
            &&
            salePriceType.isFetched
            &&
            contentPeerChannels.isFetched
            &&
            salePaymentSystem.isFetched
            &&
            saleDeliverySystem.isFetched
            &&
            saleIntegrationHandler.isFetched
        ){
            setFetched(true);
        }
    }

    useEffect(() => {

        dispatch(getContentPeerChannels());
        dispatch(getSaleStores({maxResults:1000}));
        dispatch(getSaleCurrencies({maxResults:1000}));
        dispatch(getSalePriceTypes({maxResults: 1000}));
        dispatch(getSalePaymentSystems({maxResults:1000}));
        dispatch(getSaleDeliverySystems({maxResults:1000}));
        dispatch(getSaleIntegrationHandlers());
    }, [dispatch]);

    useEffect(() => {
        checkIsAllFetched();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        saleStore?.items,
        contentPeerChannels?.items,
        saleCurrency?.items,
        salePriceType?.items,
        salePaymentSystem?.items,
        saleDeliverySystem?.items,
        saleIntegrationHandler?.items
    ]);


    const openRightModal = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(editDataSaleIntegration({data:event, modalEditTitle: 'changeIntegration'}));
        } else {
            dispatch(showRightModalSaleIntegration({data:[], modalEditTitle: 'newIntegration'}));
        }
    };

    const onEntityRemoveModalShow = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(removeSaleIntegrationModalShow({data:event, modalEditTitle: 'deleteIntegration'}));
        }
    };

    const onEntityRemoveModalHide = () => dispatch(removeSaleIntegrationModalHide());

    const onEntityRemove = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(onRemoveSaleIntegration({data:event, modalEditTitle: 'deleteIntegration'}));
        }
    };

    const onInvalidateCache = (event:any) => {
        if ((event !== undefined) && (event.uuid !== undefined) && (event.uuid.length>0)){
            dispatch(onInvalidateCacheSaleIntegration({data:event, modalEditTitle: 'invalidateCache'}));
        }
    }

    useEffect(() => {
        if(saleIntegration.isNeedReload) {
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, saleIntegration.isNeedReload]);

    useEffect(() => {
        if (salePlan?.currentPlan?.uuid !== undefined) {
            if (currentPlan!== undefined && (salePlan.currentPlan.uuid !== currentPlan?.uuid)){
                setCurrentPlan({...salePlan.currentPlan});
            }
        } else {
            setCurrentPlan(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salePlan?.currentPlan]);

    useEffect(() => {
        if (currentPlan?.uuid !== undefined && currentPlan.uuid !== null) {
            checkFareRestriction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan]);

    const checkFareRestriction = () => {

        const restrictionValue = currentPlan?.productParamValues && (currentPlan.productParamValues.filter((param:SalePlanParamValueType)=>{
                return param.productParam.code === LicenseProductParam.SALE_MAX_WIDGETS
            }
        )).pop();

        if (restrictionValue === undefined){
            setUpgradeFare(false);
        } else  {
            if (isNumeric(restrictionValue.value)) {
                if (parseInt(restrictionValue.value)>0){
                    setUpgradeFare(false);
                } else {
                    setUpgradeFare(true);
                }
            } else if (restrictionValue.value === "Y") {
                setUpgradeFare(false);
            } else {
                setUpgradeFare(true);
            }
        }
    }


    const EntityActionsHandlers:EntityActionsHandlersType = {
        onEdit: openRightModal,
        onRemove: onEntityRemoveModalShow,
        onInvalidateCache: onInvalidateCache
    }

    const onTableRowClick = (item:SaleIntegrationType) => {
        if (item.actions && item.actions.canEdit){
            openRightModal(item);
        }
    }

    const columns = SaleIntegrationColumns(EntityActionsHandlers);


    const handleSearchClick = (value:string) => {
        setSearchPhrase(value);
        if ( historyHash.searchPhrase !== value) {
            historyHash.searchPhrase = value;
            setHistoryHashFunc(historyHash);
        }
    }

    const onSearchInput = (value:string) => {
        setSearchPhrase(value);
    }

    return (
        <>
            <BreadCrumb title={t('menu:integrations')} pageTitle={t('menu:main')} />

            { upgradeFare && isFetched ?
                <>
                    <div className="d-flex flex-row flex-grow-1">
                        <div className="alert alert-danger alert-border-left alert-dismissible fade show d-flex flex-row flex-grow-1" role="alert">
                            <i className="ri-error-warning-line me-2 align-middle"></i> <strong className="pe-2">{t('common:attention')}!</strong>{t('plan:restrictionWidgetsText')}
                        </div>
                    </div>
                    <UpgradeFarePage/>
                </>
                :
                <>
                <Row className="g-4 mb-3">
                    <div className="col-sm-auto">
                        <div>
                            <Button variant="secondary" onClick={openRightModal} className="btn btn-success">
                                <i className="ri-add-line align-bottom me-1"/> {t('common:addNew')}
                            </Button>
                        </div>
                    </div>
                </Row>

                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="border-bottom-0">
                                <h5 className="card-title mb-0">{t('integration:integrations')}</h5>
                            </CardHeader>
                            <CardBody className="border border-dashed border-end-0 border-start-0 pb-3">
                                    <MainListFilter
                                        onClick={handleSearchClick}
                                        value={searchPhrase}
                                        onChange={onSearchInput}
                                    />
                            </CardBody>
                            <CardBody>
                                {isFetched ?
                                    <TableList
                                    columns={columns}
                                    data={saleIntegration.items}
                                    onRowClick={onTableRowClick}
                                    key={componentType}
                                    historyHash = {historyHash}
                                    isShowChangePageSize={true}
                                    />
                                    :
                                    <Loader/>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <RightModal/>
                <RemoveModal
                    onRemove={onEntityRemove}
                    handleClose={onEntityRemoveModalHide}
                    removeItem={saleIntegration.selectedItem}
                    isOpen={saleIntegration.removeModalShow}
                    modalTitle={saleIntegration.modalEditTitle}/>
                    </>
            }
        </>
    );
};

export default SaleIntegrationIndex;


