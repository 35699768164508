import {MainCurrentCountryActionTypes} from "./types";

export const mainCountryApiResponseSuccess = (actionType: string, data: any) => ({
  type: MainCurrentCountryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const mainCountryApiResponseError = (actionType: string, error: string) => ({
  type: MainCurrentCountryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCurrentCountry= () => ({
  type: MainCurrentCountryActionTypes.GET_CURRENT_COUNTRY,
});