// types
import {SaleIntegrationActionTypes, SaleIntegrationState, SaleIntegrationType} from "./types";
import {newMetaItem} from "../../Main/Meta/reducer";

export const NewSaleIntegrationItem:SaleIntegrationType = {
  enabled: true,
  name:'',
  order: 500,
  meta: newMetaItem
}

export const INIT_STATE_SALE_INTEGRATION: SaleIntegrationState = {
  items: [],
  rightModalShow: false,
  removeModalShow: false,
  selectedItem: NewSaleIntegrationItem,
  modalEditTitle: '',
  isSending: false,
  isFetched: false,
  isLoading: false,
  isNeedReload: false,
  isLoadingProductIntegrationParams: false,
  metaBusinessManagers:[],
  metaProductCatalogs:[],
  isLoadingMetaBusinessManagers: false,
  isLoadingMetaProductCatalogs: false,
  isImportRequest:false
};

const SaleIntegration = (state = INIT_STATE_SALE_INTEGRATION, action: any) => {
  switch (action.type) {
    case SaleIntegrationActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SaleIntegrationActionTypes.GET_SALE_INTEGRATIONS:
          return {
            ...state,
            items: action.payload.data.items,
            meta: action.payload.data.meta,
            isFetched: true,
            isLoading: false,
            isNeedReload: false
          };
        case SaleIntegrationActionTypes.UPDATE_SALE_INTEGRATION:
          return {
            ...state,
            selectedItem: NewSaleIntegrationItem,
            rightModalShow: false,
            isSending: false,
            isNeedReload: true
          };
        case SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION:
          return {
            ...state,
            selectedItem: NewSaleIntegrationItem,
            removeModalShow: false,
            isNeedReload: true
          };
        case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE:
        case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE:
        case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE:
          if (action.payload.data.response && action.payload.data.response === 'wsock'){
            state.requestMetadata = action.payload.data.requestMetadata;

            return {
              ...state,
            };
          }
          return { ...state };

        case SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE:
          if (state?.requestMetadata?.uuid && state.requestMetadata.uuid === action.payload.data.requestMetadata.uuid){
            return {
              ...state,
              productIntegrationParams: [...action.payload.data.items],
              isLoadingProductIntegrationParams: false
            };
          }
          return { ...state };

        case SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE:

          if (state?.requestMetadata?.uuid && state.requestMetadata.uuid === action.payload.data.requestMetadata.uuid){

            return {
              ...state,
              metaBusinessManagers: [...action.payload.data.items],
              isLoadingMetaBusinessManagers: false
            };
          }
          return { ...state };

        case SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE:
          if (state?.requestMetadata?.uuid && state.requestMetadata.uuid === action.payload.data.requestMetadata.uuid){
            return {
              ...state,
              metaProductCatalogs: [...action.payload.data.items],
              isLoadingMetaProductCatalogs: false
            };
          }
          return { ...state };
        case SaleIntegrationActionTypes.REQUEST_SALE_INTEGRATION_IMPORT:
          return {
            ...state,
            isImportRequest: false
          };

        default:
          return { ...state };
      }
    case SaleIntegrationActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SaleIntegrationActionTypes.GET_SALE_INTEGRATIONS:
          return {
            ...state,
            isFetched: false,
            isLoading: false,
            isNeedReload: false
          };
        case SaleIntegrationActionTypes.UPDATE_SALE_INTEGRATION:
          return {
            ...state,
            isSending: false
          };
        case SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION:
          return {
            ...state,
            removeModalShow: false
          };
        case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE:
        case SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE:
          return {
            ...state,
            isLoadingProductIntegrationParams: false
          };
        case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE:
        case SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE:
          return {
            ...state,
            isLoadingMetaBusinessManagers: false
          };
        case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE:
        case SaleIntegrationActionTypes.RESPONSE_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE:
          return {
            ...state,
            isLoadingMetaProductCatalogs: false
          };
        case SaleIntegrationActionTypes.REQUEST_SALE_INTEGRATION_IMPORT:
          return {
            ...state,
            isImportRequest: false
          };
        default:
          return { ...state };
      }
    case SaleIntegrationActionTypes.EDIT_SALE_INTEGRATION:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleIntegrationActionTypes.RIGHT_MODAL_SHOW:
      return {
        ...state,
        selectedItem: NewSaleIntegrationItem,
        modalEditTitle: action.payload.modalEditTitle,
        rightModalShow: true
      }
    case SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION_MODAL_SHOW:
      return {
        ...state,
        selectedItem: action.payload.data,
        modalEditTitle: action.payload.modalEditTitle,
        removeModalShow: true
      }
    case SaleIntegrationActionTypes.REMOVE_SALE_INTEGRATION_MODAL_HIDE:
      return {
        ...state,
        selectedItem: NewSaleIntegrationItem,
        removeModalShow: false
      }
    case SaleIntegrationActionTypes.RIGHT_MODAL_HIDE:
      return {
        ...state,
        rightModalShow: false
      }
    case SaleIntegrationActionTypes.UPDATE_SALE_INTEGRATION:
      return {
        ...state,
        isSending: true
      };
    case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_PARAM_FROM_REMOTE:
      return {
        ...state,
        isLoadingProductIntegrationParams: true
      };
    case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_BUSINESS_MANAGERS_FROM_REMOTE:
      return {
        ...state,
        isLoadingMetaBusinessManagers: true
      };
    case SaleIntegrationActionTypes.GET_SALE_PRODUCT_INTEGRATION_META_PRODUCT_CATALOGS_FROM_REMOTE:
      return {
        ...state,
        isLoadingMetaProductCatalogs: true
      };
    case SaleIntegrationActionTypes.GET_SALE_INTEGRATIONS:
      return {
        ...state,
        isLoading: true
      };
    case SaleIntegrationActionTypes.REQUEST_SALE_INTEGRATION_IMPORT:
      return {
        ...state,
        isImportRequest: true
      };
    default:
      return { ...state };
  }
};

export default SaleIntegration;
