import React from "react";
import {Link} from "react-router-dom";
import '../../../i18/config';
import {useTranslation} from "react-i18next";
import * as route from "../../../api/routes";
import {getHandlerIconByCode, textTruncate} from "../../../helpers/functions";
import {MainCabinetType} from "../../../redux/Main/Cabinet/types";
import {useRedux} from "../../../hooks";
import {ContentConnectorType} from "../../../redux/Content/Connector/types";

interface ItemProps {
    item: ContentConnectorType;
    message?: string;
    cabinet?: MainCabinetType
}
const ToastItem = ({ item, message, cabinet }: ItemProps) => {

    const { t } = useTranslation(['common']);
    const { useAppSelector } = useRedux();
    const cabinetState = useAppSelector(state => state.MainCabinet);

    const hasUuid = (cabinet?: MainCabinetType): boolean => cabinet?.uuid?.length > 0;
    const externalCabinet = cabinetState.items.find(hasUuid);

    return (
        <>
            { externalCabinet && externalCabinet?.uuid &&
                <div className="position-absolute top-0 end-0 mt-1 me-1" style={{zIndex:1}}>
                    <span className="badge rounded-pill text-bg-info">{textTruncate(externalCabinet.name, 10)}</span>
                </div>
            }
            <Link to={(externalCabinet && externalCabinet.uuid !== undefined) ? "#" : route.CONTENT_SERVICES_LIST}
                  className="fw-medium link-primary">
            <div className={"d-flex mb-1 p-0 rounded"}>
                <div className="flex-shrink-0 avatar-sm position-relative">
                    <span className="avatar-sm rounded-circle bg-gradient">
                        { item.handler && item.handler.code && item.handler.code.length>0 ?
                            <img src={getHandlerIconByCode(item.handler.code)} alt="" className="avatar-sm rounded-circle" />
                            :
                            <span className="fs-23 text-muted">
                            {item.name ?
                                item.name[0]
                                :
                                '#'
                            }
                        </span>
                        }
                    </span>
                </div>
                <div className="flex-grow-1 ms-3 mw-0">
                    <h5 className="fs-14 mb-2 text-truncate">
                        {textTruncate(item.name, 23)}
                    </h5>
                    {message &&
                    <p className="text-muted text-truncate p-0 m-0">{textTruncate(t('connector:' + message), 25)}</p>
                    }
                </div>
            </div>
            </Link>
        </>
    );
};

export default ToastItem;
