import { combineReducers } from "redux";

import ContentProjects from "./Content/Project/reducer";
import ContentTemplates from "./Content/Template/reducer";
import ContentQuickAnswer from "./Content/QuickAnswer/reducer";
import PeerActivityType from "./Content/PeerActivityType/reducer";
import PeerActivityMode from "./Content/PeerActivityMode/reducer";
import ContentPeerTargetType from "./Content/PeerTargetType/reducer";
import ContentPeerTarget from "./Content/PeerTarget/reducer";
import ContentConnectorHandler from "./Content/ConnectorHandler/reducer";
import ContentConnectorDataParam from "./Content/ConnectorDataParam/reducer";
import ContentConnector from "./Content/Connector/reducer";
import ContentPeerChannel from "./Content/PeerChannel/reducer";
import ContentPeerUser from "./Content/PeerUser/reducer";
import ContentStatCounter from "./Content/StatCounter/reducer";
import PeerActivity from "./Content/PeerActivity/reducer";
import ContentActivitiesFilter from "./Content/ActivitiesFilter/reducer";
import PeerActivityIdea from "./Content/PeerActivityIdea/reducer";

import MainDevice from "./Main/Device/reducer";
import MainRole from "./Main/Role/reducer";
import MainUser from "./Main/User/reducer";
import UpgradeModal from "./Main/UpgradeFare/reducer";
import MainSearch from "./Main/Search/reducer";
import MainVersionHistory from "./Main/VersionHistory/reducer";
import MainCabinet from "./Main/Cabinet/reducer";
import Login from "./Main/Login/reducer";
import Layout from "./Main/Layout/reducer";
import MainCountry from "./Main/Country/reducer";
import Comment from "./Main/Comment/reducer";

import EventNotification from "./Event/Notification/reducer";
import EventUserSubscription from "./Event/EventSubscribeUser/reducer";
import EventUserBadge from "./Event/Badge/reducer";
import EventWebhook from "./Event/Webhook/reducer";

import DashboardContent from "./Stat/DashboardContent/reducer";
import StatPeerChannelContent from "./Stat/PeerChannel/reducer";
import StatPeerActivityContent from "./Stat/PeerActivity/reducer";
import ComparePeerChannel from "./Stat/ComparePeerChannel/reducer";
import DashboardSale from "./Stat/DashboardSale/reducer";
import DashboardTask from "./Stat/DashboardTask/reducer";

import SalePlan from "./Sale/Plan/reducer";
import PlanOrder from "./Sale/PlanOrder/reducer"
import PaymentSystem from "./Sale/PaymentSystem/reducer"
import SalePaymentSystem from "./Sale/PaymentSystem/saleReducer"
import SaleLicense from "./Sale/License/reducer"
import SaleStore from "./Sale/Store/reducer"
import SaleCurrency from "./Sale/Currency/reducer"
import SaleCurrencyRate from "./Sale/CurrencyRate/reducer"
import SalePriceType from "./Sale/PriceType/reducer"
import SaleDeliverySystem from "./Sale/DeliverySystem/reducer"
import SaleCategory from "./Sale/Category/reducer"
import SaleProductParam from "./Sale/ProductParam/reducer"
import SaleProduct from "./Sale/Product/reducer"
import SaleIntegration from "./Sale/Integration/reducer"
import SaleIntegrationHandler from "./Sale/IntegrationHandler/reducer"
import SaleCustomer from "./Sale/Customer/reducer"
import SaleOrderStatus from "./Sale/OrderStatus/reducer"
import SaleOrder from "./Sale/Order/reducer"
import SaleOrderDeliveryItem from "./Sale/Delivery/reducer"
import SaleOrderPayment from "./Sale/Payment/reducer"
import SaleOrderCustomer from "./Sale/OrderCustomer/reducer"
import Coupon from "./Sale/Coupon/reducer"

import MessengerChat from "./Messenger/Chat/reducer";
import MessengerComment from "./Messenger/Comment/reducer";
import MessengerContact from "./Messenger/Contact/reducer";

import SupportTicket from "./Support/Ticket/reducer";
import SupportTicketMessage from "./Support/TicketMessage/reducer";

import AITextGenerateState from "./AI/TextGenerate/reducer";
import UrlShortener from "./Shortener/UrlShortener/reducer";

import Task from "./Task/Task/reducer";

export default combineReducers({
  ContentProjects,
  ContentTemplates,
  ContentQuickAnswer,
  PeerActivityType,
  PeerActivityMode,
  ContentPeerTargetType,
  ContentPeerTarget,
  ContentConnectorHandler,
  ContentConnectorDataParam,
  ContentConnector,
  ContentPeerChannel,
  ContentPeerUser,
  ContentStatCounter,
  PeerActivity,
  ContentActivitiesFilter,
  PeerActivityIdea,

  MainDevice,
  MainRole,
  MainUser,
  UpgradeModal,
  MainSearch,
  MainVersionHistory,
  MainCabinet,
  Login,
  Layout,
  MainCountry,
  Comment,

  EventNotification,
  EventUserSubscription,
  EventUserBadge,
  EventWebhook,

  DashboardContent,
  DashboardSale,
  DashboardTask,
  StatPeerChannelContent,
  StatPeerActivityContent,
  ComparePeerChannel,

  SalePlan,
  PlanOrder,
  PaymentSystem,
  SalePaymentSystem,
  SaleLicense,
  SaleStore,
  SaleCurrency,
  SaleCurrencyRate,
  SalePriceType,
  SaleDeliverySystem,
  SaleCategory,
  SaleProductParam,
  SaleProduct,
  SaleIntegration,
  SaleIntegrationHandler,
  SaleCustomer,
  SaleOrderStatus,
  SaleOrder,
  SaleOrderDeliveryItem,
  SaleOrderPayment,
  SaleOrderCustomer,
  Coupon,

  MessengerChat,
  MessengerComment,
  MessengerContact,

  SupportTicket,
  SupportTicketMessage,

  AITextGenerateState,
  UrlShortener,
  Task

});
