import React from "react";
import { Card, CardBody, Col } from 'reactstrap';
import {
    ContentConnectorHandlerType
} from "../../../redux/Content/ConnectorHandler/types";

import '../../../i18/config';
import {useTranslation} from "react-i18next";

interface ItemProps {
    item: ContentConnectorHandlerType;
    link: string;
    isBlank: false | boolean;
}
const ListButtonSmallItem = ({ item, link, isBlank }: ItemProps) => {
    const { t, i18n } = useTranslation();

    return (
            <div className="d-flex d-inline-flex animate-small me-1 mb-1">
            <a href={link} target={isBlank && '_blank'}>
                <div className={"p-0 "+(item.isFareRestriction ? "disabledBlock" : "")}>
                    <div className="d-flex flex-column h-100 align-items-center justify-content-center">
                                <div className="avatar-xs position-relative">
                                    <div className="avatar-title rounded-circle bg-light text-primary fs-16 text-capitalize">
                                        { item.icon  ?
                                            <img src={item.icon} className="img-fluid p-1 rounded-circle" alt=""/>
                                            :
                                            t('connectorHandler:'+item.code)[0]
                                        }
                                    </div>
                            </div>
                    </div>
                </div>
            </a>
            </div>
    );
};

export default ListButtonSmallItem;
