//@ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import {VectorMap} from "@react-jvectormap/core";
import {worldMill} from "@react-jvectormap/world";
import {StatTileType} from "../redux/Stat/DashboardSale/types";

interface ItemProps {
    items: [] | Array<StatTileType>;
}

const useCountiesValues = (items: StatTileType[]): { [key: string]: string } => {
    const [countiesValues, setCountiesValues] = useState<{ [key: string]: string }>({});

    useEffect(()=>{
        if (items && items.length > 0){
            const newCountiesValues: { [key: string]: string } = {};
            items.forEach((item) => {
                newCountiesValues[item.label || ''] = "mainFill";
            });
            setCountiesValues(newCountiesValues);
        } else {
            setCountiesValues({});
        }
    }, [items]);

    return countiesValues;
};

const VectorMapItem = ({items}:ItemProps) => {

    const map = useRef(null);

    const countiesValues = useCountiesValues(items);

  return (

              <div style={{ width: '100%', height: 333 }}>
                  <VectorMap
                      mapRef={map}
                      map={worldMill}
                      zoomOnScroll={false}
                      //              zoomButtons={false}
                      markersSelectable={true}
                      markerStyle={{
                          initial: {
                              fill: "#67b173",
                          },
                          selected: {
                              fill: "#3d78e3",
                              "fill-opacity": 1,
                          },
                      }}
                      // markers={[
                      //     {latLng: [41.90, 12.45], name: 'Vatican City'}]}
                      // labels={{
                      //     markers: {
                      //         render: function (marker) {
                      //             return marker.name;
                      //         },
                      //     },
                      // }}
                      backgroundColor="transparent"
                      regionStyle={{
                          initial: {
                              fill: "#3D78E3",
                              "fill-opacity": 0.1,
                              stroke: "#3D78E3",
                              "stroke-width": 0,
                              "stroke-opacity": .2
                          },
                          selected: {
                              fill: "#3D78E3",
                              "fill-opacity": 1,
                          },
                          hover: {
                              fill: "#3D78E3",
                              "fill-opacity": 1,
                          },
                      }}
                      regionLabelStyle={{
                          initial: {
                              fill: "#B90E32"
                          },
                          hover: {
                              fill: "#00ff32"
                          }
                      }}
                      series={{
                          regions: [
                                  {
                                      values:countiesValues,
                                      scale: {
                                          mainFill: 1
                                      },
                                      attribute:"fill-opacity",
                                      normalizeFunction: "polynomial"
                                  }
                              ]
                      }}
                  />
              </div>
  );
};
export default VectorMapItem;
