import React from 'react';
import {Card, CardBody} from "reactstrap";
import {ContentPeerUserType} from "../../../redux/Content/PeerUser/types";
import UserItemLine from "./UserItemLine";

interface ItemProps {
    item: ContentPeerUserType;
}

const PeerUserSearchCard = ({ item }: ItemProps) => {

    return (
        <Card style={{maxWidth:'300px'}}>
            <CardBody>
                <UserItemLine item={item}/>
            </CardBody>
        </Card>
    );
}

export default PeerUserSearchCard;