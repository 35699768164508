import { APIClient } from "../apiCore";
import * as url from "../urls";
import {MainSearchState} from "../../redux/Main/Search/types";
import {m9sGlobals} from "../../config";

const api = new APIClient();

const getMainSearch = (data: MainSearchState) => {

    const sendData = {
        q: data.query,
        maxResults: data.maxResults
    }

    return api.create(m9sGlobals.CURRENT_CABINET+url.GET_SEARCH_LIST, sendData);
};


export {
    getMainSearch,
};
