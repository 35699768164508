import {EntityActionsType} from "../../types";
import {EventTypeType} from "../EventType/types";
import {MainMetaType} from "../../Main/common";

export enum EventWebhookActionTypes {
    API_RESPONSE_SUCCESS = "@@saleWebhook/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@saleWebhook/API_RESPONSE_ERROR",
    GET_EVENT_WEBHOOKS = "@@saleWebhook/GET_EVENT_WEBHOOKS",
    UPDATE_EVENT_WEBHOOK = "@@saleWebhook/UPDATE_EVENT_WEBHOOK",
    REMOVE_EVENT_WEBHOOK_MODAL_SHOW = "@@saleWebhook/REMOVE_EVENT_WEBHOOK_MODAL_SHOW",
    REMOVE_EVENT_WEBHOOK_MODAL_HIDE = "@@saleWebhook/REMOVE_EVENT_WEBHOOK_MODAL_HIDE",
    REMOVE_EVENT_WEBHOOK = "@@saleWebhook/REMOVE_EVENT_WEBHOOK",
    EDIT_EVENT_WEBHOOK = "@@saleWebhook/EDIT_EVENT_WEBHOOK",
    RIGHT_MODAL_SHOW = "@@saleWebhook/RIGHT_MODAL_SHOW",
    RIGHT_MODAL_HIDE = "@@saleWebhook/RIGHT_MODAL_HIDE",
    GET_AVAILABLE_EVENT_TYPES = "@@saleWebhook/GET_AVAILABLE_EVENT_TYPES",
}

export interface EventWebhookState extends MainMetaType{
    items: Array<EventWebhookType>;
    rightModalShow : boolean;
    removeModalShow: boolean;
    selectedItem: EventWebhookType;
    modalEditTitle: string;
    isSending: boolean;
    isFetched: boolean;
    isLoading: boolean;
    isNeedReload: boolean;
    availableEventTypes: Array<EventTypeType>
    isEventTypesFetched: boolean;
}

export interface EventWebhookType {
    uuid?: string;
    enabled?: boolean;
    name?: string;
    url?: string;
    createdAt?: string;
    updatedAt?: string;
    isApproved?: boolean;
    errorAttempts?: number;
    eventTypes?: Array<EventTypeType>
    order?: number;
    actions?: EntityActionsType
}

export interface EventWebhookPayload {
    data: Array<any>;
    modalEditTitle : string;
}
